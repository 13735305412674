<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread" class="bread">
                <span slot="second">酒店管理</span>
                <span slot="third">可运营酒店</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth" ></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-show="fold.showSearch">
            <top-search :topSearchShow="topSearchShow"
                        @condition="getCondition"
                        :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"
                        ref="topSearch" ></top-search>
        </el-card>
        <screen-type @screenTypeSearch="screenTypeSearch" v-if="auth.type!=='group'"
                            :showPartProp="showPart" :allLoadingState="allLoadingState"></screen-type>
        <el-card class="box-card" v-loading="showLoading" v-if="showFunModel('数据概览')">
            <div class=" rowBox vertical">
                <div class="titleBox">
                    <div class="title blueTitle">数据概览</div>
                </div>
                <el-row :gutter="10" style="margin-top:20px;">
                    <index-four-row-num-unit  :type="type" :parShowDate="showData.hotelNum">
                        <span slot="numTitle">酒店数量</span>
                        <span slot="num" >{{showDataCom(showData.hotelNum)}}</span>
                        <span slot="unit">家</span>
                        <span slot="time">{{showDataTime}}</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit  :type="type" :parShowDate="showData.roomNum">
                        <span slot="numTitle">客房数量</span>
                        <span slot="num" >{{showDataCom(showData.roomNum)}}</span>
                        <span slot="unit">间</span>
                        <span slot="time">{{showDataTime}}</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit  :type="type" :parShowDate="showData.activeTerminalNum">
                        <span slot="numTitle">激活终端数量</span>
                        <span slot="num" >{{showDataCom(showData.activeTerminalNum)}}</span>
                        <span slot="unit">台</span>
                        <span slot="time">{{showDataTime}}</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit  :type="type" :parShowDate="showData.dayActiveRate" parShowUnit="money">
                        <span slot="numTitle">日活率</span>
                        <span slot="num" >{{twoPointCom(showData.dayActiveRate)}}</span>
                        <span slot="unit">%</span>
                        <span slot="time">{{showDataTime}}</span>
                    </index-four-row-num-unit>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card" v-if=false>
            <div class="lineContent chart">
                <div class="outChartBox">
                    <one-yaxis-line-chart v-if="lineChart.flag && !lineChart.noData" :chart="lineChart"></one-yaxis-line-chart>
                </div>
                <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">暂无数据</div>
            </div>
        </el-card>
        <el-card class="box-card" v-if="showFunModel('列表')">
            <div class="quickDateBox">
                <div class="title blueTitle">酒店列表</div>
            </div>
            <el-row class="btnRow">
                <el-button type="success" @click="dialogExport.dialog = true" size="medium" class="largeBtn"  v-if="showFunModel('聚屏酒店导入')">聚屏酒店导入</el-button>
                <el-button type="danger" @click="operateConfirmMeth('del','删除')" size="medium" class="largeBtn" v-if="showFunModel('删除')">删除</el-button>
                <el-button type="danger" @click="operateConfirmMeth('recover','恢复')" size="medium" class="largeBtn" v-if="showFunModel('申请恢复')">恢复</el-button>
                <el-button type="primary" @click="newBuild" size="medium" class="largeBtn rightBtn" v-if="showFunModel('新建')">新建</el-button>
            </el-row>
            <table-list :table="table" ref="tablePar"
                        @tableMeth="tableMeth"
                        @showUploadDialog="showUploadDialog"
                        @page="getNewPage"
                        @limit="getNewLimit"
                        @tableSelect="getTableSelect"
                        v-loading="table.loading"
                        :topSearchSave="topSearchSave"></table-list>
        </el-card>
        <el-dialog title="导入" :visible.sync="dialogExport.dialog" :close-on-click-modal="false" width="60%">
            <el-steps :active="importData.active" align-center class="blueStep">
                <el-step title="上传Excel"></el-step>
                <el-step title="数据预览"></el-step>
                <el-step title="导入数据"></el-step>
            </el-steps>
            <div class="container">
                <div class="first stepItem" v-if="importData.active===0" >
                    <div class="uploadBox "  v-if="importData.active===0">
                        <el-upload
                                class="upload-demo"
                                drag
                                accept=".xlsx"
                                :action="global.upload"
                                :headers="headers"
                                :on-success="uploadSuccess"
                                :before-upload="beforeUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </div>
                    <el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning" v-if="importData.active===0 && importData.oneProgress===1"></el-progress>
                    <div class="notice" v-if="importData.active===0 && importData.oneProgress===0">
                        <div class="title">*Excel文件请符合以下标准</div>
                        <div class="item">请按照模板格式导入 <span class="downLoad"><a href="./static/template.xlsx" download="">下载导入模板</a></span></div>
                        <div class="item">后缀名需为xls或者xlsx</div>
                        <div class="item">数据请勿放在合并的单元格中</div>
                        <div class="item">单次导入条数最大不可超过100条</div>
                    </div>
                </div>
                <div class="two stepItem" v-if="importData.active===1 ">
                    <div class="top paddingBox">预览数据：{{importData.fileName}}</div>
                    <div class="showCon" >
                        <table-list :table="importTable" v-loading="importData.loading"></table-list>
                    </div>
                    <div class="bottom">
                        <div class="show paddingBox">
                            <span >信息补全：{{importData.infoComplete}}</span>
                            <span class="infoIncomplete">信息不完整：{{importData.infoIncomplete}}</span>
                        </div>
                        <div>酒店信息填写不完整会导致部分功能无法正常使用。可选择重新编辑文件后再次导入或导入后及时在系统中补全酒店信息。</div>
                    </div>
                </div>
                <div class="three stepItem" v-if="importData.active===2" v-loading="importData.loading">
                    <!--<el-progress :text-inside="true" :stroke-width="20" :percentage="importData.percentage" status="exception"></el-progress>-->
                    <!--<div class="dataShow">-->
                    <!--<span>准备导入数据：{{importData.readyCount}}</span>-->
                    <!--<span>成功：{{importData.successCount}}</span>-->
                    <!--<span class="error">失败：{{importData.errorCount}}</span>-->
                    <!--</div>-->
                </div>
                <div class="four stepItem" v-if="importData.active===3" >
                    <div class="iconBox">
                        <i class="el-icon-success"></i>
                    </div>
                    <div class="notice">
                        数据导入完成，已导入{{importData.successCount}}条数据，失败{{importData.errorCount}}条数据
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelMeth" :class="importData.active===3 ? 'none':''">取 消</el-button>
                <el-button type="primary" @click="next" v-if="importData.active>0 && importData.active<3">下一步</el-button>
                <el-button type="primary" @click="next" v-if="importData.active===3">完成</el-button>
            </div>
        </el-dialog>
         <hotelAllContent ref="hotelAllContent" :query="query"></hotelAllContent>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    </div>
</template>
<script>
    import  qs from 'querystring'
    import GLOBAL from '../../../api/globalVar.js'
      import hotelAllContent from '../content/hotelAllContent'
    import commonMeth from '../../../api/method.js'
    export default {
         components:{
          
            hotelAllContent
        },
        name: "hotelManAll",
        props:['pageInfo'],
        data: () => ({
	        accountType:'',
            type:'timeCenter',
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAll'},
                fourthPath:{path: '/hotelManAll'},
            },
            topAttr:{
                terminal:'',
                terminalOption:[
                    {label:'country1',value:'1'},
                    {label:'country2',value:'2'},
                    {label:'country3',value:'3'},
                ],
            },
            table:{
                tableHeader:[
                    {prop:'hotel',label:'酒店名称',width:'350'},
                    {prop:'hotel_brand',label:'集团品牌',width:''},
                    {prop:'hotel_use_state',label:'客户终端状态',width:''},
                    {prop:'h_province',label:'省份',width:'110'},
                    {prop:'h_city',label:'城市',width:'110'},
                    {prop:'h_county',label:'区县',width:'110'},
                    {prop:'hotel_room',label:'客房总量（间）',width:''},
                    {prop:'n_connected',label:'激活终端数量（台）',width:''},
                    {prop:'addtime',label:'上线时间',width:'180'},
                ],
                tableList:[],
                page:1,
                limit:5,
                total:0,
                fromUrl:'hotelManAll',
                name:'hotelManAll',
                otherFromUrl:'hotelManAllOperate',
                option:true,
                select:true,
                loading:true,
                specialCell:{
                    BD:false,
                    build_person:false,
                    jumpBtn:true,
                    dar:true,
                    typeshow:true,
                    darText:'日活率',
                }
            },
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px',
            dialogExport:{
                dialog: false,
            },
            importData:{
                active: 0,
                oneProgress:0,
                percentage:40,
                fileName:'',
                infoComplete:'',
                infoIncomplete:'',
                readyCount:'',
                successCount:'',
                errorCount:'',
                file:'',
                filePath:'',
                loading:true
            },
            topSearchShow:{ },
            condition:{
                startTime:'',
                endTime:'',
                screenType:'',
            },
            token:'',
            importTable:{ //没有区
                tableHeader:[
                    {prop:'province',label:'省',width:'80'},
                    {prop:'city',label:'城市',width:'80'},
                    {prop:'county',label:'区',width:'80'},
                    {prop:'lng',label:'经度',width:''},
                    {prop:'lat',label:'纬度',width:''},
                    {prop:'name',label:'酒店名称',width:''},
                    {prop:'address',label:'酒店地址',width:''},
                    {prop:'contact_name',label:'酒店联系人',width:''},
                    {prop:'contact_phone',label:'联系人电话',width:''},
                    {prop:'room_num',label:'房间数量（间）',width:''},
                    {prop:'box_num',label:'终端数量（台）',width:''},
                    {prop:'ota_price',label:'ota价格（元）',width:''},
                    {prop:'ota_hotel_level',label:'酒店标签',width:''},
                ],
                tableList:[
                    {hotel:11111,hotel_brand:'11111',hotel_use_state:'11111',h_province:'11111'},
                    {hotel:22222,hotel_brand:'22222',hotel_use_state:'22222',h_province:'22222'},
                    {hotel:33333,hotel_brand:'33333',hotel_use_state:'33333',h_province:'33333'},
                    {hotel:33333,hotel_brand:'33333',hotel_use_state:'33333',h_province:'33333'},
                    {hotel:33333,hotel_brand:'33333',hotel_use_state:'33333',h_province:'33333'},

                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'hotelManAssign',
                pageShow:false
            },
            headers:{
                token:'',
                Authorization:''
            },
            cache_key:'',
            auth:{},
            showData:{
                hotelNum:0,
                roomNum:0,
                activeTerminalNum:0,
                dayActiveRate:0,
            },
            showLoading:true,
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            belongPartnerCodeAllStr:'',
            allotPartnerCodeAllStr:'',
            funArr:[],
            allData:{},
            topSearchSave:{
                // hotel_brand:['2','3','1'],
                // BD:[189,187],
                // province:[
                //     // {label:'内蒙古自治区',value:'15'},
                //     {label:'河北省',value:'13'},
                // ],
                // city: {label: "保定市",value: "1306"},
                // country: {label: "满城区",value: "130607"},
                // keyword:'测试09-15',
                // hotel_use_state_tem:[
                //     {name:'待上线',value:6}
                // ],
                // coopType:'screen',
                // partner_id:["f6c142ebfe9b438fa442d44f25238b93", "HCC000106"],
                // coopPartnerType:'belongPartnerCode',
            },
            curDate:{
                yesDay:'',
                curDay:'',
                startWeek:'',
                endWeek:'',
                startMonth:'',
                endMonth:'',
                startYear:'',
                endYear:'',
            },
            operateIds:'',
            lineChart:{
                id:'lineChart',
                showTitle:true,
                flag:false,
                noData:false,
                fromUrl:'hotelMan',
                option:{
                    legend:[],
                    xData:[],
                    series:[]
                }
            },
            lineLoading:false,
            showPart:{
                date:false,
                self:true,
                other:true,
            },
            allLoadingState:false,
            query:{},
        }),
        methods: {
            showUploadDialog(val){
                this.query = val;
                this.$refs.hotelAllContent.contentShow=true;
                this.$refs.hotelAllContent.type="detail";
                if (this.query.id!==''){
                    this.$http.get(this.global.view, {
                        params: {
                            token: this.token,
                            id: this.query.id,
                        },
                    }).then((res) => {
                        if (res.data.code === 100000) {
                            this.query.hotel_use_state= res.data.data.hotel_use_state;
                            this.$refs.hotelAllContent.getHotelDetail(this.query);
                        }
                    });
                }else{
                    this.$refs.hotelAllContent.getHotelDetail(this.query);
                }
                this.$refs.hotelAllContent.getHotalContentList(this.query.hotel_contract);
            },
            SetConditionScreentype(){
                var param = qs.stringify({
                    token: this.token,
                });
                this.$http.post(this.global.getIndexHeader, param).then((res) => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.accountType = res.data.data.accountType;
                    } else {
                    }
                    switch (this.accountType) {
                        case 1:
                            this.condition.screenType='self'
                            break
                        case 2:
                            this.condition.screenType='self'
                            break
	                    case 3:
                            this.condition.screenType=''
		                    break
	                    case 4:
                            this.condition.screenType=''
		                    break
                        case 5:
                            this.condition.screenType='belong'
                            break
                        case 6:
                            this.condition.screenType='belong'
                    }
                });
            },
            tableMeth(orderVal,type) {
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                var params={
                    token: this.token,
                    page: this.table.page,
                    per_page: this.table.limit,
                    limit: this.table.limit,
                    hotel_brand: this.condition.hotel_brand,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                     check_state:this.condition.acceptanceType,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    hotel_use_state: this.condition.hotel_use_state,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    keyword: this.condition.keyword,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    sort:sortStr,
                    order:orderStr,
                    refresh: false
                };
                    params.screenType=this.condition.screenType
                this.$http.get(this.global.getOperationHotelList, {
                    params
                }).then(res => {
                    if(type==='mounted'){
                        this.table.page=sessionStorage.getItem('filterPage')*1;
                    }
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        this.table.total = parseInt(tableData.total);
                    } else {
                        // this.$message.error(res.data.msg);
                    }
                    this.table.loading = false;
                }).catch((res) => {
                    this.table.loading = false;
                })
            },
            tableMethfrist(orderVal,type) {
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                var params={
                    token: this.token,
                    page: this.table.page,
                    per_page: this.table.limit,
                    limit: this.table.limit,
                    hotel_brand: this.condition.hotel_brand,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                     check_state:this.condition.acceptanceType,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    hotel_use_state: this.condition.hotel_use_state,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    keyword: this.condition.keyword,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    sort:sortStr,
                    order:orderStr,
                    refresh: true
                };
                    params.screenType=this.condition.screenType
                this.$http.get(this.global.getOperationHotelList, {
                    params
                }).then(res => {
                    if(type==='mounted'){
                        this.table.page=sessionStorage.getItem('filterPage')*1;
                    }
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        this.table.total = parseInt(tableData.total);
                    } else {
                        // this.$message.error(res.data.msg);
                    }
                    this.table.loading = false;
                }).catch((res) => {
                    this.table.loading = false;
                })
            },
            getNewPage(newPage) {
                this.table.page = newPage;
                this.table.loading = true;
                this.tableMeth();
            },
            getNewLimit(newLimit) {
                this.table.limit = newLimit;
                this.table.loading = true;
                this.tableMeth();
            },
            newBuild() {
                this.$router.push('/hotelManNew');
            },
            next() {
                if (this.importData.active++ > 2) {
                    this.dialogExport.dialog = false;
                    this.importData.active = 0;
                }
                if (this.importData.active === 1) {
                    this.previewMeth();
                } else if (this.importData.active === 2) {
                    this.confirmMeth();
                } else if (this.importData.active === 3) {
                    this.dialogExport.dialog = false;
                }
            },
            previewMeth() {
                var param = qs.stringify({
                    token: this.token,
                    file_path: this.importData.file
                });
                this.$http.post(this.global.preview, param).then(res => {
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.importTable.tableList = tableData.items;
                        this.importData.infoComplete = tableData.success_count;
                        this.importData.infoIncomplete = tableData.error_count;
                        this.importData.loading = false;
                        this.cache_key = tableData.cache_key;
                    } else {
                        this.importData.loading = false;
                        this.$message.error('预览失败');
                    }
                }).catch(() => {
                    this.$message.error('返回错误');
                })
            },
            confirmMeth() {
                this.importData.loading = true;
                var param = qs.stringify({
                    token: this.token,
                    file_path: this.importData.file,
                    cache_key: this.cache_key,
                });
                this.$http.post(this.global.importAction, param).then(res => {
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.importData.successCount = tableData.success_count;
                        this.importData.errorCount = tableData.error_count;
                        this.importData.loading = false;
                        this.importData.active = 3;
                        // setTimeout(()=>{
                        //     this.importData.active=3;
                        // },300)
                    } else {
                        this.$message.error('导入失败');
                    }
                }).catch(() => {
                    this.$message.error('返回错误');
                })
            },
            getTopSearchShow(type) {
                var auth = JSON.parse(sessionStorage.getItem('auth'));
                var auth_type = auth.type;
                if (auth_type === 'channel' || auth_type === 'screen' || auth_type === 'factory') {
                    this.topSearchShow = {
                        hotel_brand: true,
                        province: true,
                        city: true,
                        country: true,
                        keyword: true,
                        coopType:true,
                            acceptanceType:true,
                    }
                }else if(auth_type === 'group') {
                    this.topSearchShow = {
                        province: true,
                        city: true,
                        country: true,
                            acceptanceType:true,
                        keyword: true,
                    }
                } else if (auth_type === 'htrip') {
                    this.topSearchShow = {
                        hotel_brand: true,
                        province: true,
                        city: true,
                        country: true,
                        coopType: true,
                        coopPartner: true,
                        BD: true,
                        keyword: true,
                        coopPartnerType:true,
                        screenType:true,
                            acceptanceType:true,
                    }
                }
                this.topSearchShow.date=true;
                this.topSearchShow.keywordPlaceholder='请输入酒店名称关键词';
            },
            getCondition(val,filterPage,filterLimit,type) {
                this.topSearchSave=val;
                var hotel_use_state_label=this.condition.hotel_use_state_label;
                this.condition.hotel_brand = Array.isArray(val.hotel_brand) ? val.hotel_brand.join(',') : ''; //ka
                this.condition.hotel_use_state_label= Array.isArray(val.hotel_use_state_label) ? val.hotel_use_state_label.join(',') : ''; //ka
                this.condition.hotel_use_state_label='（'+this.condition.hotel_use_state_label.replace(/,/g,'，')+'）';
                if(this.condition.hotel_use_state_label==='（）'){
                    this.condition.hotel_use_state_label='（全部状态）';
                }
                this.condition.BD = Array.isArray(val.BD) ? val.BD.join(',') : '';
                this.condition.partner_id = Array.isArray(val.partner_id) ? val.partner_id.join(',') : ''; //ka
                this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode) ? val.belongPartnerCode.join(',') : ''; //ka
                this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode) ? val.allotPartnerCode.join(',') : ''; //ka
                if (typeof val.citycode === 'string') {
                    this.condition.citycode = val.citycode;
                } else {
                    this.condition.citycode = Array.isArray(val.citycode) ? val.citycode.join(',') : ''; //citycode
                }
                this.condition.provinceLabel = Array.isArray(val.provinceLabel) ? val.provinceLabel.join(',') : ''; //provinceLabel
                this.condition.cityLabel = val.cityLabel;
                this.condition.countyLabel = val.countyLabel;
                  this.condition.acceptanceType =  val.acceptanceType,
                this.condition.startTime = val.startTime;
                this.condition.endTime = val.endTime;
                this.setTableDarText(this.condition.startTime,this.condition.endTime);
                this.condition.keyword = val.keyword;
                this.getFilterTableCon(filterPage,filterLimit,type);
                this.tableMeth('',type);
                this.getLineChart();
                this.showDataMeth();
            },
            getConditionfrist(val,filterPage,filterLimit,type) {
                this.topSearchSave=val;
                var hotel_use_state_label=this.condition.hotel_use_state_label;
                this.condition.hotel_brand = Array.isArray(val.hotel_brand) ? val.hotel_brand.join(',') : ''; //ka
                this.condition.hotel_use_state_label= Array.isArray(val.hotel_use_state_label) ? val.hotel_use_state_label.join(',') : ''; //ka
                this.condition.hotel_use_state_label='（'+this.condition.hotel_use_state_label.replace(/,/g,'，')+'）';
                if(this.condition.hotel_use_state_label==='（）'){
                    this.condition.hotel_use_state_label='（全部状态）';
                }
                this.condition.BD = Array.isArray(val.BD) ? val.BD.join(',') : '';
                this.condition.partner_id = Array.isArray(val.partner_id) ? val.partner_id.join(',') : ''; //ka
                this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode) ? val.belongPartnerCode.join(',') : ''; //ka
                this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode) ? val.allotPartnerCode.join(',') : ''; //ka
                if (typeof val.citycode === 'string') {
                    this.condition.citycode = val.citycode;
                } else {
                    this.condition.citycode = Array.isArray(val.citycode) ? val.citycode.join(',') : ''; //citycode
                }
                this.condition.provinceLabel = Array.isArray(val.provinceLabel) ? val.provinceLabel.join(',') : ''; //provinceLabel
                this.condition.cityLabel = val.cityLabel;
                this.condition.countyLabel = val.countyLabel;
                  this.condition.acceptanceType =  val.acceptanceType,
                this.condition.startTime = val.startTime;
                this.condition.endTime = val.endTime;
                this.setTableDarText(this.condition.startTime,this.condition.endTime);
                this.condition.keyword = val.keyword;
                this.getFilterTableCon(filterPage,filterLimit,type);
                this. tableMethfrist('',type);
                this.getLineChartfrist();
                this.showDataMethfrist();
            },
            beforeUpload(val) {
                this.importData.file = val;
                this.importData.fileName = val.name;
            },
            uploadSuccess(response, file, fileList) {
                this.importData.file = response.data.url;
                this.importData.file = response.data.url;
                this.importData.active = 1;
                this.previewMeth();
            },
            cancelMeth() {
                this.importData.active = 0;
                this.importData.fileName = '';
                this.importData.infoComplete = '';
                this.importData.infoIncomplete = '';
                this.importData.readyCount = '';
                this.importData.successCount = '';
                this.importData.errorCount = '';
                this.importData.file = '';
                this.importData.filePath = '';
                this.dialogExport.dialog = false;
            },
            //数据统计
            showDataMeth() {
                return new Promise((resolve,reject)=>{
                    this.showLoading = true;
                    var params = {
                        token: this.token,
                        hotel_brand: this.condition.hotel_brand,
                        hotelBrand: this.condition.hotel_brand,
                        citycode: this.condition.citycode,
                        province: this.condition.provinceLabel,
                         check_state:this.condition.acceptanceType,
                        city: this.condition.cityLabel,
                        county: this.condition.countyLabel,
                        hotel_use_state: this.condition.hotel_use_state,
                        accountManagerId: this.condition.BD,
                        partner_id: this.condition.partner_id,
                        keyword: this.condition.keyword,
                        startTime:this.condition.startTime,
                        endTime:this.condition.endTime,
                        belongPartnerCode: this.condition.belongPartnerCode,
                        allotPartnerCode: this.condition.allotPartnerCode,
                        isDeleted: this.condition.isDeleted,
                        refresh: false
                    };
                        params.screenType=this.condition.screenType
                    this.$http.post(this.global.getOperationHotelStatistics,qs.stringify(params)).then(res => {
                        var code = res.data.code;
                        if (code === 100000) {
                            this.showData = res.data.data;
                            this.showLoading = true;
                        }
                        this.showLoading = false;
                        resolve();
                    }).catch(() => {
                        this.showLoading = false;
                    })
                })
            },
            showDataMethfrist() {
                return new Promise((resolve,reject)=>{
                    this.showLoading = true;
                    var params = {
                        token: this.token,
                        hotel_brand: this.condition.hotel_brand,
                        hotelBrand: this.condition.hotel_brand,
                        citycode: this.condition.citycode,
                        province: this.condition.provinceLabel,
                         check_state:this.condition.acceptanceType,
                        city: this.condition.cityLabel,
                        county: this.condition.countyLabel,
                        hotel_use_state: this.condition.hotel_use_state,
                        accountManagerId: this.condition.BD,
                        partner_id: this.condition.partner_id,
                        keyword: this.condition.keyword,
                        startTime:this.condition.startTime,
                        endTime:this.condition.endTime,
                        belongPartnerCode: this.condition.belongPartnerCode,
                        allotPartnerCode: this.condition.allotPartnerCode,
                        isDeleted: this.condition.isDeleted,
                        refresh: true
                    };
                        params.screenType=this.condition.screenType
                    this.$http.post(this.global.getOperationHotelStatistics,qs.stringify(params)).then(res => {
                        var code = res.data.code;
                        if (code === 100000) {
                            this.showData = res.data.data;
                            this.showLoading = true;
                        }
                        this.showLoading = false;
                        resolve();
                    }).catch(() => {
                        this.showLoading = false;
                    })
                })
            },
            getFoldMeth(val){
                this.fold=val;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
            },

            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                if(filterCon.screenType){
                    this.condition.screenType=filterCon.screenType.join(',');
                }
                this.getConditionfrist(filterCon,filterPage*1,filterLimit*1,'mounted');
            },
            getYesDay(){
                var time=(new Date).getTime()-24*60*60*1000;
                var yesterday=new Date(time);
                var month=yesterday.getMonth()+1;
                var day=yesterday.getDate();
                this.curDate.yesDay=yesterday.getFullYear() + "-" + (month>9 ? (month) : "0" + (month)) + "-" +(yesterday.getDate()> 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
            },
            getCurDay(){
                var time=(new Date).getTime();
                var today=new Date(time);
                var month=today.getMonth()+1;
                var day=today.getDate();
                this.curDate.curDay=today.getFullYear() + "-" + (month> 9 ? (month) : "0" + (month)) + "-" +(today.getDate()> 9 ? (today.getDate()) : "0" + (today.getDate()));
            },
            add0(m){
                return m<10?'0'+m:m
            },
            getCurWeek(){
                var startStop = new Array();
                //获取当前时间
                var currentDate = new Date();
                //返回date是一周中的某一天
                var week = currentDate.getDay();
                //返回date是一个月中的某一天
                var month = currentDate.getDate();
                //一天的毫秒数
                var millisecond = 1000 * 60 * 60 * 24;
                //减去的天数
                var minusDay = week != 0 ? week - 1 : 6;
                //alert(minusDay);
                //本周 周一
                var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
                //本周 周日
                var sunday = new Date(monday.getTime() + (6 * millisecond));
                var sy = monday.getFullYear();
                var sm = monday.getMonth() + 1;
                var sd = monday.getDate();
                var ey = sunday.getFullYear();
                var em = sunday.getMonth() + 1;
                var ed = sunday.getDate();
                // var s = sy+'-'+add0(sm)+'-'+add0(sd)+' 00:00:00';//开始
                // var e = ey+'-'+add0(em)+'-'+add0(ed)+' 23:59:59';//结束
                var s = sy+'-'+this.add0(sm)+'-'+this.add0(sd);//开始
                var e = ey+'-'+this.add0(em)+'-'+this.add0(ed);//结束
                this.curDate.startWeek=s;
                this.curDate.endWeek=this.curDate.yesDay;
            },
            getCurMonth(type,months){
                var d = new Date();
                var year = d.getFullYear();
                var month = d.getMonth() + 1;
                if (Math.abs(months) > 12) {
                    months = months % 12;
                };
                if (months != 0) {
                    if (month + months > 12) {
                        year++;
                        month = (month + months) % 12;
                    } else if (month + months < 1) {
                        year--;
                        month = 12 + month + months;
                    } else {
                        month = month + months;
                    };
                };
                month = month < 10 ? "0" + month: month;
                var date = d.getDate();
                var firstday = year + "-" + month + "-" + "01";
                var lastday = "";
                if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
                    lastday = year + "-" + month + "-" + 31;
                } else if (month == "02") {
                    if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                        lastday = year + "-" + month + "-" + 29;
                    } else {
                        lastday = year + "-" + month + "-" + 28;
                    };
                } else {
                    lastday = year + "-" + month + "-" + 30;
                };
                var day = "";
                if (type == "s") {
                    day = firstday;
                } else {
                    // day = lastday;
                    day=this.curDate.curDay;
                };
                return day;
            },
            getCurYear(type,dates){
                var dd = new Date();
                var n = dates || 0;
                var year = dd.getFullYear() + Number(n);
                if (type == "s") {
                    var day = year + "-01-01";
                };
                if (type == "e") {
                    // var day = year + "-12-31";
                    var day=this.curDate.curDay;
                };
                if (!type) {
                    var day = year + "-01-01/" + year + "-12-31";
                };
                return day;
            },
            getAllTimeMeth(){
                this.getYesDay();
                this.getCurDay();
                this.getCurWeek();
                this.curDate.startMonth=this.getCurMonth("s",0);
                this.curDate.endMonth=this.getCurMonth("e",0);
                this.curDate.startYear=this.getCurYear("s",0);
                this.curDate.endYear=this.getCurYear("e",0);
            },
            getTableSelect(val){
                var operateIds='';
                var partnerCode='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+',';
                    partnerCode+=item.code+',';
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            operateConfirmMeth(type,str) {
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.operateMeth(type,str);
                    }).catch(() => {
                    });
                }else{
                    this.$message({
                        message: '请选择酒店',
                        type: 'warning'
                    });
                }
            },
            delMeth(){
                var param=qs.stringify({
                    token:this.token,
                    id:this.delIds,
                });
                this.$http.post(this.global.hotelDestroy,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('删除成功');
                        this.tableMeth();
                    }else{
                        this.$message.success('删除失败');
                    }
                })
            },
            operateMeth(type,str){
                var param='';
                var url='';
                param=qs.stringify({
                    token:this.token,
                    id:this.operateIds,
                });
                if(type==='del'){
                    url=this.global.hotelDestroy
                }else{
                    url=this.global.hotelRecover
                }
                this.$http.post(url,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success(`${str}成功`);
                        this.tableMeth();
                    }else{
                        this.$message.error(`${str}失败`);
                    }
                })
            },
            getLineChart(){
                this.lineChart.flag=false;
                this.lineLoading=true;
                var params={
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                     check_state:this.condition.acceptanceType,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    hotelCreateTimeStart:this.condition.hotelCreateTimeStart,
                    hotelCreateTimeEnd:this.condition.hotelCreateTimeEnd,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    refresh: false,
                };
                    params.screenType=this.condition.screenType
                this.$http.post(this.global.getHotelLineLegend,qs.stringify(params)).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        if(chartData.series){
                            this.lineChart.option.legend=chartData.legend;
                            this.lineChart.option.xData=chartData.xAxis;
                            var dataTransEd=commonMeth.dataTrans(chartData);
                            var arr=[];
                            var index=0;
                            for(let key in dataTransEd.series){
                                index++;
                                var obj={
                                    name: `${key}`,
                                    type: 'line',
                                    smooth:true,
                                    itemStyle: {
                                        normal: {
                                            color: GLOBAL.chartColor[index], //改变折线点的颜色
                                            lineStyle: {
                                                color: GLOBAL.chartColor[index]//改变折线颜色
                                            }
                                        }
                                    },
                                    data: dataTransEd.series[key]
                                };
                                arr.push(obj);
                            }
                            this.lineChart.option.series=arr;
                        }else{
                            this.lineChart.noData=true;
                        }
                        this.lineChart.flag=true;
                        this.lineLoading=false;
                    }else if(code===100001){
                        this.$message.error('折线图获取失败');
                    }
                    this.lineLoading=false;
                });
            },
            getLineChartfrist(){
                this.lineChart.flag=false;
                this.lineLoading=true;
                var params={
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                     check_state:this.condition.acceptanceType,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    hotelCreateTimeStart:this.condition.hotelCreateTimeStart,
                    hotelCreateTimeEnd:this.condition.hotelCreateTimeEnd,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    refresh: true
                };
                    params.screenType=this.condition.screenType
                this.$http.post(this.global.getHotelLineLegend,qs.stringify(params)).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        if(chartData.series){
                            this.lineChart.option.legend=chartData.legend;
                            this.lineChart.option.xData=chartData.xAxis;
                            var dataTransEd=commonMeth.dataTrans(chartData);
                            var arr=[];
                            var index=0;
                            for(let key in dataTransEd.series){
                                index++;
                                var obj={
                                    name: `${key}`,
                                    type: 'line',
                                    smooth:true,
                                    itemStyle: {
                                        normal: {
                                            color: GLOBAL.chartColor[index], //改变折线点的颜色
                                            lineStyle: {
                                                color: GLOBAL.chartColor[index]//改变折线颜色
                                            }
                                        }
                                    },
                                    data: dataTransEd.series[key]
                                };
                                arr.push(obj);
                            }
                            this.lineChart.option.series=arr;
                        }else{
                            this.lineChart.noData=true;
                        }
                        this.lineChart.flag=true;
                        this.lineLoading=false;
                    }else if(code===100001){
                        this.$message.error('折线图获取失败');
                    }
                    this.lineLoading=false;
                });
            },
            dataTrans(chartData){
                for(let key in chartData.series){
                    for(let a in chartData.series[key]){
                        if(!chartData.series[key][a]){
                            chartData.series[key][a]=0;
                        }
                    }
                }
                return chartData;
            },
            screenTypeSearch(screenType){
                this.condition.screenType=screenType;
                this.table.page=1;
                this.table.limit=5;
                this.table.per_page=5;
                this.tableMeth();
                this.getLineChart();
                this.showDataMeth();
            },
            setTableDarText(startTime,endTime){
                if(startTime!==endTime){
                    this.table.specialCell.darText=`日活率（%）（${endTime}）`
                }else{
                    this.table.specialCell.darText=`日活率（%）`
                }
            },
            getScreenTypeSearchShow(){
                    this.showPart={
                        date:false,
                        self:true,
                        other:true,
                    }
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.headers.token=this.token;
            this.headers.Authorization=this.token;
            var auth=JSON.parse(sessionStorage.getItem('auth'));
            this.auth=auth;
            this.condition.hotel_use_state_label='（全部状态）';
            this.SetConditionScreentype()
            this.getTopSearchShow();
            this.getScreenTypeSearchShow();
            this.getAllTimeMeth();
            this.getFilterCon();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
                this.table.option=res.flag;
            });


        },
        computed:{
            showDataCom(){
                return function(val){
                    if(val===null){
                        return 0;
                    }else{
                        return parseFloat(val).toLocaleString();
                    }
                }
            },
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            twoPointCom(){
                return function(x){
                    !x?x=0:'';
                    var f = parseFloat(x);
                    if (isNaN(f)) {
                        return false;
                    }
                    var f = Math.round(x*100)/100;
                    var s = f.toString();
                    var rs = s.indexOf('.');
                    if (rs < 0) {
                        rs = s.length;
                        s += '.';
                    }
                    while (s.length <= rs + 2) {
                        s += '0';
                    }
                    return s;
                }
            },
            showDataTime(){
                if(!this.condition.startTime ||  this.condition.startTime===''){
                    return '【昨日】'
                }else{
                    var time='【'+this.condition.startTime+'~'+this.condition.endTime+'】';
                    return time;
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading,false,this.showLoading);
            }
        },
        watch: {
            pageInfo: {
                handler: function (newVal) {
                    this.table.otherFromUrl=newVal.fromUrl;
                    console.log('当前from',newVal.fromUrl)
                },
                deep: true
            }
        },
    }
</script>

<style scoped lang="scss">
    .bread{
        flex:1;
    }
    .hotelBrand{
        width:100%;
    }
    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .container{
        /*height:400px;*/
        /*background:red;*/
        display:flex;
        /*justify-content: center;*/
        align-items:center;
        flex-direction:column;
    }
    .stepItem{
        width:100%;
    }
    .uploadBox{

        display:flex;
        justify-content: center;
        align-items: center;
        cursor:pointer;
        margin-top:30px;
    }
    .imgBox{
        width:50px;
        height:50px;
        margin-bottom:5px;
        img{
            width:100%;
            height:100%;
        }
    }
    .none{
        display:none;
    }
    .notice{
        width:100%;
        background:#eef2f7;
        padding:20px 20px;
        margin-top:30px;
        box-sizing:border-box;
        div{
            color:#909ca7;
        }
        .title{
            font-size:16px;
            padding-bottom:20px;
        }
        .item{
            margin-bottom:20px;
        }
        .item:last-child{
            margin-bottom:0;
        }
        .downLoad>a{
            color:#418efa;
            margin-left:20px;
            cursor:pointer;
        }
    }
    .two{
        .showCon{
            width:100%;
            height:300px;
            border-radius:4px;
            border:1px dashed #ddd;
            overflow:auto;
        }
        .bottom{
            .show{
                display:flex;
            }
            .infoIncomplete{
                margin-left:20px;
            }
            div{
                margin-bottom:15px;
            }
        }
    }
    .three{
        width:100%;
        height:300px;
        .dataShow{
            display:flex;
            justify-content:center;
        }
        .error{
            color:#dd2139;
        }
    }
    .four{
        display:flex;
        justify-content: center;
        align-content: center;
        flex-direction:column;
        .notice{
            width:100%;
            text-align: center;
            background:none;
        }
        .iconBox{
            text-align:center;
            font-size:80px;
            color:#20b759;
            margin-top:30px;
        }
    }
    .paddingBox{
        padding:15px 0;
    }
    .foldBtn{
        cursor:pointer;
    }
    .outTopBox{
        /*padding:0;*/
    }
    .btnRow{
        display:flex;
        margin-top:20px;
    }
    .rightBtn{
        margin-left:auto;
    }
    .quickDateBox{
        display:flex;
        align-items: center;
    }
</style>
