import request from "@/api/request";
import qs from "querystring";

// 参数配置列表
export function getList(param) {
  return request({
    url: `v2/batch_set/parameterManage`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 参数新增
export function add(param) {
  return request({
    url: `v2/batch_set/insertApkPackageNew`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 参数编辑
export function edit(param) {
  return request({
    url: `v2/batch_set/editApkPackageNew`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 参数删除
export function del(param) {
  return request({
    url: `v2/batch_set/delAllPackageName`,
    method: "post",
    data: qs.stringify(param),
  });
}
