import request from './request';
import qs from "qs"
//   一级渠道商创建账户
export function addSubset(param) {
    return request({
        url: `v1/Account/addSubset`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   一级渠道商编辑账户
export function editSubset(param) {
    return request({
        url: `v1/Account/editSubset`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取账户列表
export function getSubsetList(param) {
    return request({
        url: `v1/Partner/searchSubset`,
        method: 'post',
        data: qs.stringify(param),
    });
}