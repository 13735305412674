<template>
  <div>
    <!-- <div class="current_hotel">酒店名称：{{ query.hotel }}</div> -->
    <div class="btnRow btnRowbox">
      <el-input style="width:250px" placeholder="请输入房间号" v-model="roomStr" :clearable="true" class="input-with-select fullSelect ">
      </el-input>
      <el-input style="width:250px;margin-left:20px" placeholder="请输入设备名称" v-model="device_name" :clearable="true" class="input-with-select fullSelect ">
      </el-input>
      <el-select style="width:250px;margin-left:20px" v-model="is_online" placeholder="是否在线" class="hotelBrand fullSelect " filterable collapse-tags clearable>
        <el-option label="在线" value="1"></el-option>
        <el-option label="离线" value="2"></el-option>
      </el-select>
      <el-button type="primary" class="largeBtn marginLeft" @click="searchRoom">搜 索</el-button>
      <el-button class="largeBtn marginLeft" @click="resetRoom">重 置</el-button>
      <el-button type="primary" class="largeBtn marginLeft" @click="checkAll">全选</el-button>
      <span style="color:#999" class="marginLeft">已选房间{{ roomCheckList.length }}个</span>
      <span style="color:#999" class="marginLeft">
        <i class="el-icon-info"></i>
        绿色在线，蓝色选中</span>
    </div>
    <div class="roomAllBox">
      <!-- :class="['roomCard', roomCheckList.includes(item) ? 'active' : '']" -->
      <div class="roomCard" :class="{
        redLeft:getState(item),
          online_room: hotelState[item],
          active: roomCheckList.includes(item),
        }" v-if="(hotelState[item]&&is_online==1)||(!hotelState[item]&&is_online==2)||is_online==''" v-for="item in roomShowList" :key="item + '95'" @click="switchCheck(item)">
        <el-tooltip effect="dark" :content="item" placement="top">
          <div class="roomShow" style="width:80%">
            {{ item }}
          </div>
        </el-tooltip>
        <!-- <i class="el-icon-success" v-show="hotelState[item]"></i> -->
      </div>
    </div>
    <div class="btnRow btnRowbox">
      <!--  -->
      <el-select style="width:300px" :disabled="roomCheckList.length == 0" v-model="instructionForm.instruction" placeholder="请选中房间后选择指令" class="hotelBrand fullSelect " filterable collapse-tags clearable @change="checkInstruction" value-key="id">
        <el-option v-for="item of instructionList" :key="item.id" :label="item.cmdName" :value="item"></el-option>
      </el-select>
      <el-button type="primary" class="largeBtn marginLeft" :disabled="roomCheckList.length == 0" @click="toExecute">执行</el-button>

    </div>
    <div class="btnRow btnRowbox" v-if="instructionForm.instruction.cmdType=='cmd'">
      <el-select style="width:300px" v-model="selfInstruction" placeholder="请选中常用自定义指令" class="hotelBrand fullSelect " filterable collapse-tags clearable @change="checkSelfInstruction" value-key="id">
        <el-option v-for="item of selfinstructionList" :key="item.id+'self'" :label="item.content" :value="item"></el-option>
      </el-select>
      <el-button type="primary" class="largeBtn marginLeft" @click="openSelf">自定义管理</el-button>

    </div>
    <el-input style="margin-bottom:20px" v-if="instructionForm.instruction.if_param == 1" type="textarea" rows="2" resize="none" :placeholder="instructionForm.instruction.desc" v-model="instructionForm.descText">
    </el-input>

    <ReleaseList ref="ReleaseList" :query="query"></ReleaseList>
    <el-dialog title="指令发布" :visible.sync="instructionVisible" :before-close="instructionClose" width="40%" :append-to-body="true">
      <el-form ref="instructionForm" :model="instructionForm" label-width="100px">
        <el-form-item label="已选酒店">
          <el-input :readonly="true" v-model="query.hotel"></el-input>
        </el-form-item>
        <el-form-item label="分组名称">
          <el-input :readonly="true" v-model="query.hotel_alias"></el-input>
        </el-form-item>
        <el-form-item label="已选房间">
          <el-input type="textarea" rows="4" resize="none" v-model="instructionForm.checkRoomStr"></el-input>
        </el-form-item>
        <el-form-item label="已选指令">
          <el-input :readonly="true" v-model="instructionForm.instruction.cmdName"></el-input>
        </el-form-item>
        <el-form-item label="指令参数" v-if="instructionForm.instruction.if_param == 1">
          <el-input :readonly="true" type="textarea" rows="2" resize="none" v-model="instructionForm.descText"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" @click="toExecuteTrue">发 布</el-button>
        <el-button class="cancelBtn" @click="instructionClose">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="自定义指令" :visible.sync="instructionSelfVisible" :before-close="instructionSelfClose" width="40%" :append-to-body="true">
      <el-button class="trueBtn" style="margin-bottom:20px" type="primary" @click="openAdd">添 加</el-button>
      <el-table ref="table" :header-cell-style="headerStyle" :data="tableData" v-loading="table.loading" style="width: 100%" class="sixtable" height="315px">
        <el-table-column prop="content" label="指令" min-width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="" label="操作" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" style="color: #658bff" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="changePage" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </el-dialog>

    <el-dialog title="添加自定义指令" :visible.sync="addInstructionVisible" :before-close="addInstructionClose" width="40%" :append-to-body="true">
      <el-input placeholder="请填写指令内容" v-model="instructionString"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" @click="toAdd">提 交</el-button>
        <el-button class="cancelBtn" @click="addInstructionClose">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  instructionList,
  doInstruction,
  getRoomActive,
  getCustomList,
  addCustom,
  delCustom,
} from "../../../../operation/launcherSetting/api/launcherRealease";
// import {
//   instructionList,
//   getRoomActive,
//   getCustomList,
//   addCustom,
//   delCustom,
// } from "../../../../operation/launcherSetting/api/launcherRealease";

// import { doInstruction} from "../../api/PRO_REALEASE";
import ReleaseList from "./releaseList.vue";
export default {
  name: "InstructionRelease",
  components: { ReleaseList },
  props: ["query"],
  data() {
    return {
      addInstructionVisible: false,
      instructionString: "",
      // 表格头样式
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      is_online: "",
      tableData: [],
      table: {
        page: 1,
        limit: 20,
        total: 0,
        loading: false,
      },
      selfinstructionList: [],
      instructionSelfVisible: false,
      selfInstruction: {
        content: "",
      },
      instructionForm: {
        instruction: "",
        descText: "",
        checkRoomStr: "",
      },
      roomStr: "",
      device_name: "",
      descPlaceholder: "",
      roomAllList: [],
      roomShowList: [],
      roomCheckList: [],
      instructionList: [
        {
          value: "cmd",
          desc: "完整命令，例：ls  /data/",
          label: "自定义命令",
          param: "1",
        },
        {
          value: "meslog",
          desc: "",
          label: "上传log",
          param: "0",
        },
        {
          value: "screenshot",
          desc: "",
          label: "截屏",
          param: "0",
        },
        {
          value: "activityinfo",
          desc: "",
          label: "当前应用信息",
          param: "0",
        },
        {
          value: "uninstall",
          desc: "应用包名，例：com.tarvelink.ehotel",
          label: "卸载应用",
          param: "1",
        },
        {
          value: "uninstallAndInstall",
          desc: "应用包名|下载安装应用的完整网络路径，例：com.tarvelink.ehotel|http://jd.ctlife.tv:8000/hr500262/admin/images/apk/HtripEhotel_V3.6.3_2020050616_10_2.apk",
          label: "卸载再安装应用",
          param: "1",
        },
        {
          value: "install",
          desc: "下载安装应用的完整网络路径，例：http://jd.ctlife.tv:8000/hr500262/admin/images/apk/HtripEhotel_V3.6.3_2020050616_10_2.apk",
          label: "下载安装应用",
          param: "1",
        },
        {
          value: "configinfo",
          desc: "文件路径,例：/data/data/com.htrip.hihotel/shared_prefs/TLKupgrade.xml",
          label: "查看终端配置文件信息",
          param: "1",
        },
        {
          value: "pagversion",
          desc: "应用包名，例：com.tarvelink.ehotel",
          label: "查看指定应用版本信息",
          param: "1",
        },
        {
          value: "startapp",
          desc: "外网地址，默认：http://pv.sohu.com/cityjson?ie=utf-8",
          label: "获取外网地址",
          param: "1",
        },
        {
          value: "startservice",
          desc: "服务名，例：com.tarvelink.action",
          label: "启动服务",
          param: "1",
        },
        {
          value: "broadcast",
          desc: "资源地址，例：https://down.qq.com/qqweb/QQ_1/android_apk/Android_8.3.9.4635_537064751.apk",
          label: "获取平均网速",
          param: "1",
        },
        {
          value: "keyvalue",
          desc: "按键值，例：21",
          label: "执行按键值",
          param: "1",
        },
        {
          value: "clear",
          desc: "应用包名，例：com.travelink.ehotel",
          label: "清空应用缓存",
          param: "1",
        },
        {
          value: "memory",
          desc: "",
          label: "查看系统存储空间",
          param: "0",
        },
        {
          value: "system",
          desc: "",
          label: "查看系统信息",
          param: "0",
        },
        {
          value: "delete",
          desc: "删除的文件路径，例：/data/data/com.travelink.ehotel",
          label: "删除文件",
          param: "1",
        },
      ],
      checkedInstruction: {
        param: "",
        desc: "",
      },
      instructionVisible: false,
      hotelState: {},
      auth: {},
    };
  },
  watch: {
    // 酒店信息监听
    query: {
      handler: function (newVal) {
        this.query = newVal;
        // 酒店数据变化 重新获取房间数据
        this.getRooms();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    getState(val) {
      let arr = this.roomAllList.filter((i) => {
        if (i.room == val) {
          return i;
        }
      });
      if (arr[0].pic_state == 0) {
        return true;
      } else {
        return false;
      }
    },
    getAllSelfinstructionList() {
      let json = {
        page: 1,
        size: 9999,
      };
      getCustomList(json).then((res) => {
        if (res.data.code == 100000) {
          this.selfinstructionList = res.data.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    getSelfinstructionList() {
      this.table.loading = true;
      let json = {
        page: this.table.page,
        size: this.table.limit,
      };
      getCustomList(json).then((res) => {
        if (res.data.code == 100000) {
          this.tableData = res.data.data.data;
          this.table.total = res.data.data.total;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.table.loading = false;
      });
    },
    openAdd() {
      this.addInstructionVisible = true;
    },
    toAdd() {
      if (this.instructionString == "") {
        this.$message.warning("请填写指令内容");
      } else {
        this.$confirm("是否确认添加？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let json = {
            content: this.instructionString,
          };
          addCustom(json).then((res) => {
            if (res.data.code == 100000) {
              this.$message.success("添加成功");
              this.addInstructionClose();
              this.table.page = 1;
              this.getSelfinstructionList();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        });
      }
    },
    addInstructionClose() {
      this.addInstructionVisible = false;
      this.instructionString = "";
    },
    // 分页-- 页条目
    handleSizeChange(val) {
      this.table.limit = val;
      this.getSelfinstructionList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.table.page = val;
      this.getSelfinstructionList();
    },
    // 删除自定指令
    del(row) {
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let json = {
          id: row.id,
        };
        delCustom(json).then((res) => {
          if (res.data.code == 100000) {
            this.$message.success("删除成功");
            this.table.page = 1;
            this.getSelfinstructionList();
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      });
    },
    openSelf() {
      this.instructionSelfVisible = true;
    },
    instructionSelfClose() {
      this.instructionSelfVisible = false;
    },
    checkSelfInstruction() {
      console.log(this.selfInstruction);
      this.instructionForm.descText = this.selfInstruction.content;
    },
    // 获取房间列表
    getRooms() {},
    // 发布指令
    toExecuteTrue() {
      if (
        this.instructionForm.descText == "" &&
        this.checkedInstruction.param == "1"
      ) {
        this.$message.warning("请输入指令参数");
        return;
      }
      this.$confirm("是否确认当前操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          doInstruction({
            selected_hotel_name: this.query.hotel,
            selected_hotel_contract: this.query.hotel_contract,
            command_param: this.instructionForm.descText,
            command: this.instructionForm.instruction.cmdType,
            selected_room_num: this.instructionForm.checkRoomStr,
            user_name: this.auth.user_name,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.$refs.ReleaseList.search();
            } else {
              this.$message.error(data.data.msg);
            }
          });
          this.instructionVisible = false;
        })
        .catch(() => {});
    },
    // 取消发布指令
    instructionClose() {
      this.instructionVisible = false;
    },
    // 选择发布指令
    checkInstruction() {
      // let arr = this.instructionList.filter((i) => {
      //   if (i.value == this.instructionForm.instruction) {
      //     return i;
      //   }
      // });
      // this.checkedInstruction = arr[0];
      console.log(this.instructionForm.instruction);
      this.selfInstruction = {
        content: "",
      };
      this.instructionForm.descText = "";
      if (this.instructionForm.instruction.if_param == 1) {
        this.instructionForm.descText = this.instructionForm.instruction.desc;
      }
    },
    // 执行操作
    toExecute() {
      if (this.instructionForm.instruction == "") {
        this.$message.warning("请选择操作指令");
      } else {
        this.instructionVisible = true;
      }
    },
    //   全选与反选
    checkAll() {
      const showLength = this.roomShowList.length;
      let n = 0;
      this.roomShowList.forEach((i) => {
        if (this.roomCheckList.indexOf(i) !== -1) {
          n++;
        } else {
          this.roomCheckList.push(i);
        }
      });
      if (showLength == n) {
        this.roomShowList.forEach((i) => {
          if (this.roomCheckList.indexOf(i) !== -1) {
            this.roomCheckList.splice(this.roomCheckList.indexOf(i), 1);
          }
        });
      }
      this.instructionForm.checkRoomStr = this.roomCheckList.join(",");
    },
    // 选择房间
    switchCheck(item) {
      if (this.roomCheckList.indexOf(item) == -1) {
        this.roomCheckList.push(item);
      } else {
        this.roomCheckList.splice(this.roomCheckList.indexOf(item), 1);
      }
      this.instructionForm.checkRoomStr = this.roomCheckList.join(",");
    },
    getAllRoom() {
      this.roomCheckList = [];
      this.instructionForm.checkRoomStr = "";
      this.$http
        .get(this.global.getRoomsNew, {
          params: {
            token: sessionStorage.getItem("token"),
            // token:"e79a7cd8-8f38-41b8-ace6-768b40902658",
            keyword: this.roomStr,
            device_name: this.device_name,
            id: this.query.id,
            hotel_project: this.query.hotelName,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.roomAllList = res.data.data.details;
            this.roomShowList = this.roomAllList.map((item) => {
              return item.room;
            });
            this.hotelState = {};
            getRoomActive({
              hotel_contract: this.query.hotel_contract,
              rooms: this.roomShowList.join(","),
            }).then((data) => {
              if (Array.isArray(data.data.online)) {
                return;
              }
              this.hotelState = data.data.online;
            });
          }
        });
    },
    // 搜索房间
    searchRoom() {
      this.getAllRoom();
      // this.roomShowList = [];
      // let rooms = this.roomAllList.map((item) => {
      //   return item.room;
      // });
      // this.roomShowList = rooms.filter((i) => {
      //   if (i.indexOf(this.roomStr) != -1) {
      //     return i;
      //   }
      // });
    },
    // 重置
    resetRoom() {
      this.roomStr = "";
      this.device_name = "";
      this.is_online = "";
      // this.roomShowList = [];
      this.getAllRoom();
      // this.roomShowList = this.roomAllList.map((item) => {
      //   return item.room;
      // });
    },
  },
  mounted() {
    instructionList().then((data) => {
      this.instructionList = data.data.data;
    });
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getAllSelfinstructionList();
    this.getSelfinstructionList();
    this.getAllRoom();
  },
};
</script>

<style scoped>
.current_hotel {
  padding: 10px 0px;
  padding-top: 0px;
}
.marginLeft {
  margin-left: 20px;
}
.btnRowbox {
  display: flex;
  align-items: center;
}
.roomAllBox {
  margin-bottom: 20px;
  height: 200px;
  border: 1px solid #ddd;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}
.roomCard {
  display: inline-block;
  margin-bottom: 10px;
  width: 15%;
  margin-left: 2%;
  text-align: center;
  height: 40px;
  line-height: 38px;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  /* background: #d4d4d4; */
  border-radius: 4px;
  position: relative;
}
.roomCard.active {
  color: #fff;
  background: #3688f8;
}
.roomCard:nth-child(6n + 1) {
  margin-left: 0 !important;
}
.online_room {
  background: #78bf31;
  color: white;
  border-color: #78bf31;
}
.roomShow {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.roomCard i {
  position: absolute;
  bottom: -5px;
  right: -5px;
  color: #78bf31;
  background: white;
  border-radius: 100%;
}
.redLeft {
  border-left: 5px solid red;
}
.textAreaBox {
  height: 200px;
  width: 100%;
}
</style>
