<template>
    <div>
        <el-dialog
                title="选择房间"
                :visible.sync="drawer"
                width="70%"
                :modal="false">
            <div class="mainBox" style="height:100%;position:relative">
                <el-row :gutter="20" style="margin-top:20px">
                    <el-col :span="24">
                        <el-input placeholder="请输入房间号" class="input-with-select fullSelect" v-model="roomid" :clearable="true">
                            <el-button slot="append" icon="el-icon-search" @click="clickFilter"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
                <div class="roomBox">
                    <div ref="roomList">
                        <el-col :span="4" v-for="(item,index) in roomList" :key="index" >
                            <div class="room" @click="changeSpan(item.room)" :class="{'change':spanIndex.indexOf(item.room)>-1}">{{item.room}}</div>
                        </el-col>
                    </div>
                    <el-col :span="4" v-for="(item,index) in searchList" :key="index" class="allroom">
                        <div class="room" @click="changeSpan(item.room)" :class="{'change':spanIndex.indexOf(item.room)>-1}">{{item.room}}</div>
                    </el-col>
                </div>
                <div class="drawerFooter">
                    <div class="btnBox">
                        <el-button @click="showDrawer(false)" class="largeBtn">取 消</el-button>
                        <el-button type="primary" @click="submitMeth" class="largeBtn">保 存</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import Bus from '../../../../api/Bus'
    export default {
        isActive:false,
        name: "Online",
        props:['showData'],
        data: () => ({
            spanIndex:[],
            isActive:false,
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAll'},
            },
            type:'timeCenter',
            roomid:'',
            roomList:[],
            searchList:[],
            token:'',
            query:{},
            loading:false,
            drawer: false,
            direction: 'rtl',
        }),
        methods:{
            clickFilter(){
                this.$refs.roomList.style.display="none";
                var roomid=this.roomid;
                if (roomid){
                    this.searchList=this.roomList.filter(function (roomList) {
                        return Object.keys(roomList).some(function (key) {
                            return String(roomList[key]).toLowerCase().indexOf(roomid)>-1
                        })
                    })
                }else{
                    this.searchList=this.roomList
                }
            },
            changeSpan(room){
                let arrIndex = this.spanIndex.indexOf(room);
                if(arrIndex>-1){
                    this.spanIndex.splice(arrIndex,1);
                }else{
                    this.spanIndex.push(room);
                }
            },
            getRoom(){
                this.loading=true;
                this.$http.get(this.global.getRooms,{
                    params:{
                        token:this.token,
                        id:this.query.id
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.roomList=res.data.data.details;
                    }
                    this.loading=false;
                })
            },
            submitMeth(){
                if(this.spanIndex.length===0){
                    this.$message.warning('所选房间不能为空');
                }else{
                    sessionStorage.setItem('roomList',JSON.stringify(this.spanIndex.join(',')));
                    Bus.$emit('setPublishRoom',this.spanIndex.join(','));
                    this.drawer=false;
                }
            },
            showDrawer(val){
                this.drawer=val;
            }
        },
        mounted(){
           
            this.token=sessionStorage.getItem('token');
            this.query=this.$route.query;
            this.getRoom();
        },
        computed:{
            showDataCom(){
                return function(val){
                    if(val===null){
                        return 0;
                    }else{
                        return parseFloat(val).toLocaleString();
                    }
                }
            },
        },
        created(){
            Bus.$on('showDrawer',obj=>{
                this.drawer=obj.show;
                this.spanIndex=obj.roomStr?obj.roomStr.split(','):[];
            })
        }
    }

</script>


<style scoped lang="scss">
    .btn{
        position: absolute;
        width: 100px;
        right: 20px;

    }
    .ybtn{
        margin-bottom: 40px;
    }
    .room{
        text-align: center;
        color:#3085FC;
        padding-top: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-left: 27px;
        cursor: pointer;
        height: 30px;
    }
    .room:first-child{
        margin: 0;
    }
    .change{
        color: #3085FC;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
    }
    .roomBox{
        overflow:hidden;
        padding-bottom:90px;
    }
    .roomBox .el-col{
        padding:20px 10px 10px 10px;
        text-align: center;
        float: left;
    }
    .el-col:nth-child(12n){
        padding:20px 0px 10px 20px;
        float: right;

    }
    .el-col:first-child{
        padding:20px 20px 10px 0px;
    }
    .el-col:nth-child(12n+1){
        padding:20px 20px 10px 0;
        float: left;
    }
    .mainBox{
        padding: 0 20px;
    }
    .drawerFooter{
        display: flex;
    }
    .btnBox{
        margin-left:auto;
    }
</style>

