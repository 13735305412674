<template>
  <div class="root">
    <div>
      <coop-tab-executing v-bind="$attrs" :contentData="contentData" :sys="sys" :dataReset="dataReset" v-if="dataReset==true" :id="id" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure">
      </coop-tab-executing>
    </div>
    <!--    账户信息——目标及分成比例    -->
    <el-tabs v-model="activeName" v-if="sys==true" type="border-card" @tab-click=getyear>
      <el-tab-pane label="执行中" name="2022">
        <coop-tab-executing v-bind="$attrs" :contentData="contentData" :sys="sys" v-if="sys==true" :id="id" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure">
        </coop-tab-executing>
      </el-tab-pane>
      <el-tab-pane label="2021" name="2021">
        <coop-tab-executing v-bind="$attrs" :contentData="contentData" :sys="sys" v-if="sys==true" :id="id" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure">
        </coop-tab-executing>
      </el-tab-pane>
      <el-tab-pane label="2020" name="2020">
        <coop-tab-executing v-bind="$attrs" :contentData="contentData" :sys="sys" v-if="sys==true" :id="id" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure">
        </coop-tab-executing>
      </el-tab-pane>
       <el-tab-pane label="2019" name="2019">
        <coop-tab-executing v-bind="$attrs" :contentData="contentData" :sys="sys" v-if="sys==true" :id="id" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure">
        </coop-tab-executing>
      </el-tab-pane>
    </el-tabs>
    <!--    合作伙伴列表详情——计划及分成比例    -->
    <el-tabs v-model="activeName" v-if="tabExecutingShow&&sys!==true&&dataReset!==true" type="border-card" @tab-click=getyear>
      <el-tab-pane :label=labelName name="2022" v-if="NextMouthStatus==1">
        <coop-tab-executing v-bind="$attrs" :id="id" :optYear="2022" :contentData="contentData" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure" :checkReason="checkReason">
        </coop-tab-executing>
      </el-tab-pane>
      <el-tab-pane :label="nextMonth" name="unExecuted" v-if="(!noDataExecuting && !noDataUnExecutedDefault) || (!noDataExecuting && editFlag)">
        <coop-tab-executing :id="id" tabType="unExecuted" :contentData="contentData" v-bind="$attrs" :optYear="2022" :allData="unExecutedData" :noDataUnExecuted="noDataUnExecuted" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure" :checkReason="preCheckReason">
        </coop-tab-executing>
      </el-tab-pane>
      <el-tab-pane v-for="(item) in yearsData" :key="item.name" :label="item.label" :name="item.name">
        <coop-tab-executing :id="id" tabType="unExecuting" @getyear='getyear' :contentData="contentData" v-bind="$attrs" :optYear="item.name" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @copyDataToUnExected="copyDataToUnExected" @groupPushData="groupPushData" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure"
          :checkReason="checkReason">
        </coop-tab-executing>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "price",
  props: ["id", "hotelOrPartner", "sys", "click", "dataReset"],
  data: () => ({
    checkReason: "",
    contentStatus: 1,
    NextMouthStatus: 1,
    labelName: "执行中",
    contentData: {},
    executingData: {
      content: {},
      flashCost: {
        group: [],
      },
      marketCost: {
        group: [],
      },
      vodonlineCost: {
        group: [],
      },
      vodofflineCost: {
        group: [],
      },
      adCost: {
        group: [],
      },
      shopCost: {
        group: [],
      },
      defineValidCost: {
        group: [],
      },
      independentOneCost: {
        group: [],
      },
      independentCardCost: {
        group: [],
      },
      cinemaOne: {
        group: [],
      },
      cinemaCard: {
        group: [],
      },
      cardCost: {
        group: [],
      },
    },
    unExecutedData: {
      content: {},
      flashCost: {
        group: [],
      },
      marketCost: {
        group: [],
      },
      vodonlineCost: {
        group: [],
      },
      vodofflineCost: {
        group: [],
      },
      adCost: {
        group: [],
      },
      shopCost: {
        group: [],
      },
      defineValidCost: {
        group: [],
      },
      independentOneCost: {
        group: [],
      },
      independentCardCost: {
        group: [],
      },
      cinemaOne: {
        group: [],
      },
      cinemaCard: {
        group: [],
      },
      cardCost: {
        group: [],
      },
    },
    token: "",
    activeName: "2022",
    nextMonth: "待执行",
    noDataExecuting: false,
    noDataUnExecuted: false,
    noDataUnExecutedDefault: false,
    executingBtn: "save",
    tabExecutingShow: false,
    allotPartnerDefaultConfigure: "",
    editFlag: false,
    yearsData: [],
    preCheckReason: "",
  }),
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getAllotPartnerDefaultConfigure();
    this.yearTabs();
    if (!this.dataReset) {
      this.getDetail(this.id)
        .then(() => {
          return this.getPreDetail(this.id);
        })
        .then(() => {
          this.tabExecutingShow = true;
        });
    }
  },
  methods: {
    yearTabs() {
      var smallYears = 2019;
      //获取当前时间
      var date = new Date();
      var nowYears = date.getFullYear() - 1;
      var Years = nowYears - smallYears;
      var arrYear = [];
      for (var i = 0; i <= Years; i++) {
        arrYear.push(nowYears--);
      }
      arrYear.forEach((ele) => {
        this.yearsData.push({
          name: String(ele),
          label: ele + "年",
        });
      });
    },
    getyear() {
      if (this.activeName == "unExecuted") {
        if (!this.editFlag) {
          this.getPreDetail(this.id);
        }
      } else {
        return new Promise((resolve, reject) => {
          var param = "";
          var global = this.global;
          var reqUrl = "";
          reqUrl = global.getPartnerConfigure;
          param = qs.stringify({
            token: this.token,
            partnerId: this.id,
            settlement_year:
              this.activeName == "unExecuted" ? "2022" : this.activeName,
          });
          this.$http.post(reqUrl, param).then((res) => {
            if (res.data.code === 100000) {
              var resData = res.data.data;
              var executingData = this.executingData;
              this.contentData = resData.partnerContract;
              this.checkReason = "";
              if (this.activeName == 2022) {
                if (this.contentData == null) {
                  this.NextMouthStatus = 1;
                  this.nextMonth = "待执行";
                  this.labelName = "待执行";
                } else if (this.contentData.status == 1) {
                  this.labelName = "执行中";
                } else if (this.contentData.status == 2) {
                  this.labelName = "待审核";
                } else if (this.contentData.status == 0) {
                  this.labelName = "驳回";
                  this.contentStatus = 0;
                  executingData = {};
                }
              }

              executingData.flashCost.group = resData.flash
                ? resData.flash
                : [];
              executingData.marketCost.group = resData.market
                ? resData.market
                : [];
              executingData.vodonlineCost.group = resData.vodonline
                ? resData.vodonline
                : [];
              executingData.vodofflineCost.group = resData.vodoffline
                ? resData.vodoffline
                : [];
              executingData.adCost.group = resData.ad ? resData.ad : [];
              executingData.shopCost.group = resData.shop ? resData.shop : [];
              executingData.independentOneCost.group = resData.independentOne
                ? resData.independentOne
                : [];
              executingData.independentCardCost.group = resData.independentCard
                ? resData.independentCard
                : [];
              executingData.cinemaOne.group = resData.cinemaOne
                ? resData.cinemaOne
                : [];
              executingData.cinemaCard.group = resData.cinemaCard
                ? resData.cinemaCard
                : [];
              executingData.cardCost.group = resData.card ? resData.card : [];
              // this.getPreDetail(this.id);
              // if(this.activeName == "unExecuted"&& this.editFlag == true){
              //    this.unExecutedData = JSON.parse(JSON.stringify(this.executingData));
              // }else{
              // }
            }
            resolve();
          });
        });
      }
    },
    getDataResetShow(partnerId, year) {
      var param = "";
      var global = this.global;
      var reqUrl = "";
      reqUrl = global.getPartnerConfigure;
      param = qs.stringify({
        token: this.token,
        partnerId: partnerId,
        settlement_year: year,
      });
      this.$http.post(reqUrl, param).then((res) => {
        if (res.data.code === 100000) {
          var resData = res.data.data;
          if (!resData.partnerContract) {
            this.$emit("noContract", false);
          } else {
            this.$emit("noContract", true);
          }
          var executingData = this.executingData;
          this.contentData = resData.partnerContract;
          this.checkReason = "";
          if (this.contentData == null) {
            this.NextMouthStatus = 1;
            this.nextMonth = "待执行";
            this.labelName = "待执行";
          } else if (this.contentData.status == 1) {
            this.labelName = "执行中";
          } else if (this.contentData.status == 2) {
            this.labelName = "待审核";
          } else if (this.contentData.status == 0) {
            this.labelName = "驳回";
            this.checkReason =
              "驳回原因：" + resData.partnerContract.check_reason;
            this.NextMouthStatus = 1;
            this.contentStatus = 0;
          }
          executingData.flashCost.group = resData.flash ? resData.flash : [];
          executingData.marketCost.group = resData.market ? resData.market : [];
          executingData.vodonlineCost.group = resData.vodonline
            ? resData.vodonline
            : [];
          executingData.vodofflineCost.group = resData.vodoffline
            ? resData.vodoffline
            : [];
          executingData.adCost.group = resData.ad ? resData.ad : [];
          executingData.shopCost.group = resData.shop ? resData.shop : [];
          executingData.independentOneCost.group = resData.independentOne
            ? resData.independentOne
            : [];
          executingData.independentCardCost.group = resData.independentCard
            ? resData.independentCard
            : [];
          executingData.cinemaOne.group = resData.cinemaOne
            ? resData.cinemaOne
            : [];
          executingData.cinemaCard.group = resData.cinemaCard
            ? resData.cinemaCard
            : [];
          executingData.cardCost.group = resData.card ? resData.card : [];
        }
      });
      this.getPreDetail(partnerId).then((res) => {
        console.log(res,"res");
        if (Array.isArray(res)) {
          if (res.length == 0) {
            this.$emit("nonextmonth", true);
          } else {
            this.$emit("nonextmonth", false);
          }
        } else {
          this.$emit("nonextmonth", true);
        }
      });
    },
    getDetail(id) {
      return new Promise((resolve, reject) => {
        var param = "";
        var global = this.global;
        var reqUrl = "";
        if (this.hotelOrPartner === "hotel") {
          reqUrl = global.getHotelConfigure;
          param = qs.stringify({
            token: this.token,
            hotelContract: this.$attrs.hotel_contract,
          });
        } else {
          reqUrl = global.getPartnerConfigure;
          param = qs.stringify({
            token: this.token,
            partnerId: this.id,
          });
        }
        this.$http.post(reqUrl, param).then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            var executingData = this.executingData;
            this.contentData = resData.partnerContract;
            this.checkReason = "";
            if (this.contentData == null) {
              this.NextMouthStatus = 1;
              this.nextMonth = "待执行";
              this.labelName = "待执行";
            } else if (this.contentData.status == 1) {
              this.labelName = "执行中";
            } else if (this.contentData.status == 2) {
              this.labelName = "待审核";
            } else if (this.contentData.status == 0) {
              this.labelName = "驳回";
              this.checkReason =
                "驳回原因：" + resData.partnerContract.check_reason;
              this.NextMouthStatus = 1;
              this.contentStatus = 0;
            }
            executingData.flashCost.group = resData.flash ? resData.flash : [];
            executingData.marketCost.group = resData.market
              ? resData.market
              : [];
            executingData.vodonlineCost.group = resData.vodonline
              ? resData.vodonline
              : [];
            executingData.vodofflineCost.group = resData.vodoffline
              ? resData.vodoffline
              : [];
            executingData.adCost.group = resData.ad ? resData.ad : [];
            executingData.shopCost.group = resData.shop ? resData.shop : [];
            executingData.independentOneCost.group = resData.independentOne
              ? resData.independentOne
              : [];
            executingData.independentCardCost.group = resData.independentCard
              ? resData.independentCard
              : [];
            executingData.cinemaOne.group = resData.cinemaOne
              ? resData.cinemaOne
              : [];
            executingData.cinemaCard.group = resData.cinemaCard
              ? resData.cinemaCard
              : [];
            executingData.cardCost.group = resData.card ? resData.card : [];
            if (this.isNoData(executingData, "执行中")) {
              this.noDataExecuting = true;
            } else {
              this.noDataExecuting = false;
            }
          }
          resolve();
        });
      });
    },
    getPreDetail(id) {
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
          partnerId: this.id,
        });
        var global = this.global;
        var reqUrl = "";
        if (this.hotelOrPartner === "hotel") {
          reqUrl = global.getHotelPreConfigure;
          param = qs.stringify({
            token: this.token,
            hotelContract: this.$attrs.hotel_contract,
          });
        } else {
          reqUrl = global.getPartnerPreConfigure;
          param = qs.stringify({
            token: this.token,
            partnerId: this.id,
          });
        }
        this.$http.post(reqUrl, param).then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            this.preCheckReason = "";
            if (resData.length == 0 || !resData.partnerContract) {
              // this.NextMouthStatus = 0;
              // this.activeName=this.nextMonth
            } else if (resData.partnerContract.status == 1) {
              // 如果有驳回条件就是驳回，没有就是执行中
              if (resData.partnerContract.check_reason != "") {
                this.nextMonth = "驳回";
                this.preCheckReason =
                  "驳回原因：" + resData.partnerContract.check_reason;
                this.NextMouthStatus = 1;
              } else {
                this.getCurMonth();
                this.NextMouthStatus = 1;
              }
            } else if (resData.partnerContract.status == 2) {
              this.nextMonth = "待审核";
              this.NextMouthStatus = 1;
            }
            var unExecutedData = this.unExecutedData;
            if (this.activeName == "unExecuted") {
              this.contentData = resData.partnerContract;
            }

            unExecutedData.flashCost.group = resData.flash ? resData.flash : [];
            unExecutedData.marketCost.group = resData.market
              ? resData.market
              : [];
            unExecutedData.vodonlineCost.group = resData.vodonline
              ? resData.vodonline
              : [];
            unExecutedData.vodofflineCost.group = resData.vodoffline
              ? resData.vodoffline
              : [];
            unExecutedData.adCost.group = resData.ad ? resData.ad : [];
            unExecutedData.shopCost.group = resData.shop ? resData.shop : [];
            unExecutedData.independentOneCost.group = resData.independentOne
              ? resData.independentOne
              : [];
            unExecutedData.independentCardCost.group = resData.independentCard
              ? resData.independentCard
              : [];
            unExecutedData.cinemaOne.group = resData.cinemaOne
              ? resData.cinemaOne
              : [];
            unExecutedData.cinemaCard.group = resData.cinemaCard
              ? resData.cinemaCard
              : [];
            unExecutedData.cardCost.group = resData.card ? resData.card : [];
            if (this.nextMonth != "驳回") {
              if (this.isNoData(unExecutedData, "预配置")) {
                this.noDataUnExecuted = true;
                this.noDataUnExecutedDefault = true;
              } else {
                this.noDataUnExecuted = false;
                this.noDataUnExecutedDefault = false;
              }
            }
            resolve(resData);
          }
        });
      });
    },
    copyDataToUnExected() {
      this.unExecutedData = JSON.parse(JSON.stringify(this.executingData));
      this.activeName = "unExecuted";
      this.editFlag = true;
    },
    getCurMonth() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      if (month === 12) {
        this.nextMonth = 1 + "月1日生效";
      } else {
        this.nextMonth = month + 1 + "月1日生效";
      }
    },
    isNoData(obj, type) {
      var flag = true; //没有数据
      if (Array.isArray(obj)) {
        if (obj.length === 0) {
          flag = false;
        }
      } else {
        Object.keys(obj).forEach(function (key) {
          if (key !== "content") {
            if (obj[key].group) {
              if (obj[key].group.length > 0) {
                flag = false; //有数据
              }
            }
          }
        });
      }
      return flag;
    },
    groupPushData(tabType, configType) {
      var obj = {};
      var unitType = "P";
      var data =
        tabType === "executing" || tabType === "unExecuting"
          ? this.executingData
          : this.unExecutedData;
      switch (configType) {
        case "flash":
          obj = data.flashCost.group;
          unitType = "F";
          break;
        case "market":
          obj = data.marketCost.group;
          unitType = "F";
          break;
        case "vodonline":
          obj = data.vodonlineCost.group;
          break;
        case "vodoffline":
          obj = data.vodofflineCost.group;
          break;
        case "ad":
          obj = data.adCost.group;
          break;
        case "shop":
          obj = data.shopCost.group;
          break;
        case "defineValid":
          obj = data.defineValidCost.group;
          unitType = "F";
          break;
        case "independentOne":
          obj = data.independentOneCost.group;
          break;
        case "independentCard":
          obj = data.independentCardCost.group;
          break;
        case "cinemaOne":
          obj = data.cinemaOne.group;
          break;
        case "cinemaCard":
          obj = data.cinemaCard.group;
          break;
        case "card":
          obj = data.cardCost.group;
          break;
      }
      obj.push({
        num: null,
        base_on: "active_terminal_num",
        base_on_text: "",
        price: null,
        allot_price: this.allotPartnerDefaultConfigure,
        type: unitType,
        key: Date.now(),
      });
    },
    getNewVal(tabType, configType, val) {
      if (tabType === "executing") {
        switch (configType) {
          case "flash":
            this.executingData.flashCost = val;
            break;
          case "market":
            this.executingData.marketCost = val;
            break;
          case "vodonline":
            this.executingData.vodonlineCost = val;
            break;
          case "vodoffline":
            this.executingData.vodofflineCost = val;
            break;
          case "ad":
            this.executingData.adCost = val;
            break;
          case "shop":
            this.executingData.shopCost = val;
            break;
          case "defineValid":
            this.executingData.defineValidCost = val;
            break;
          case "independentOne":
            this.executingData.independentOneCost = val;
            break;
          case "independentCard":
            this.executingData.independentCardCost = val;
          case "cinemaOne":
            this.executingData.cinemaOne = val;
            break;
          case "cinemaCard":
            this.executingData.cinemaCard = val;
            break;
          case "card":
            this.executingData.cardCost = val;
            break;
        }
      } else if (tabType === "unExecuted") {
        switch (configType) {
          case "flash":
            this.unExecutedData.flashCost = val;
            break;
          case "market":
            this.unExecutedData.marketCost = val;
            break;
          case "vodonline":
            this.unExecutedData.vodonlineCost = val;
            break;
          case "vodoffline":
            this.unExecutedData.vodofflineCost = val;
            break;
          case "ad":
            this.unExecutedData.adCost = val;
            break;
          case "shop":
            this.unExecutedData.shopCost = val;
            break;
          case "defineValid":
            this.unExecutedData.defineValidCost = val;
            break;
          case "independentOne":
            this.unExecutedData.independentOneCost = val;
            break;
          case "independentCard":
            this.unExecutedData.independentCard = val;
            break;
          case "cinemaOne":
            this.unExecutedData.cinemaOne = val;
            break;
          case "cinemaCard":
            this.unExecutedData.cinemaCard = val;
            break;
          case "card":
            this.unExecutedData.cardCost = val;
            break;
        }
      }
    },
    getAllotPartnerDefaultConfigure() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http
        .post(this.global.getAllotPartnerDefaultConfigure, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.allotPartnerDefaultConfigure =
              res.data.data.allotPartnerDefaultConfigure;
          }
        });
    },
    // showsys(){
    //     if (this.sys=true){
    //         this.getDetail(this.id).then(()=>{
    //             return this.getPreDetail(this.id);
    //         }).then(()=>{
    //             this.tabExecutingShow=true;
    //         });
    //     }
    // }
  },

  computed: {
    executingReadOnly() {
      if (
        this.type === "executing" &&
        !this.noDataExecuting &&
        !this.noDataUnExecuted
      ) {
        return true; //只读
      } else {
        return false;
      }
    },
  },
  watch: {
    unExecutedData: {
      handler: function (newVal) {
        if (this.isNoData(newVal)) {
          this.noDataUnExecuted = true;
        } else {
          this.noDataUnExecuted = false;
        }
      },
      deep: true,
      immediate: true,
    },
    id: {
      handler: function (newVal) {
        this.id = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.outItem {
  width: 90%;
  border: 1px solid #8aa4c2;
  margin-bottom: 30px;
}
.top {
  display: flex;
  background: #f8fbff;
  padding: 5px 10px;
  align-items: center;
  border-bottom: 1px solid #e2e6eb;

  .right {
    margin-left: auto;
    .el-button {
      margin-right: 20px;
    }
  }
  span {
    color: #2b466b;
    font-size: 14px;
  }
}
.bottom {
  padding: 20px;
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .innerInput {
    width: 190px;
    margin: 0 10px;
  }
  .hideInput {
    visibility: hidden;
  }
  .el-col {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
.saveBtn {
  margin-left: 20px;
}
.dialogOpeBtn {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
</style>
