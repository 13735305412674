	<template>
    <div>
         <div class="boxBlack" v-if="dialog.dialogFormVisible"></div>
        <el-dialog title="发布" :visible.sync="dialog.dialogFormVisible"
                   width='550px' :close-on-click-modal="false" :modal="false">
            <el-form :model="form" ref="dialogForm" :rules="rules" class="noFixedWidthForm">
                <el-form-item prop="publishNotes" label="发布说明" label-width="130px" v-if="parentUrl!=='materialPublishList'">
                    <el-input v-model="form.publishNotes" placeholder="请输入发布说明"></el-input>
                </el-form-item>
                <el-form-item prop="publishModule" label="发布模式" label-width="130px" v-if="parentUrl!=='materialPublishList'">
                    <el-select v-model="form.publishModule" class="selectItem fullSelect" filterable @change="modelChange">
                        <el-option
                                v-for="item in selectData.mode"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="publishRoomStr" label="发布房间" label-width="130px" v-if="publishRoomStrCom">
                    <el-input class="publishRoomStrInput" v-model="form.publishRoomStr" :disabled="true" placeholder="请选择发布房间"></el-input>
                    <el-button @click="choiceRoomMeth" class="rightBtn">选择</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Bus from '../../../api/Bus'
    export default {
        name: "ChoiceRoom",
        props: {
            hotelContract: {},
            userNameProp: {},
            id:'',
            parentUrl: {
                default:''
            },
            database:"",
            curInfoProp:{},
           
        },
        data:()=>({
            form:{
                publishNotes:'',
                publishModule:'all',
                publishRoomStr:'',
            },
            dialog:{
                dialogFormVisible:false,
            },
            publishRoomStrShow:false,
            selectData:{
                mode:[
                    {name:'部分房间',value:'part'},
                    {name:'全部房间',value:'all'},
                ]
            },
            rules:{
                publishNotes: [
                    { required: true, message: '请输入发布说明', trigger: 'blur' },
                ],
                publishModule: [
                    { required: true, message: '请选择发布模式', trigger: 'blur' },
                ],
                publishRoomStr: [
                    { required: true, message: '请选择发布房间', trigger: 'blur' },
                ],
            },
            query:{},
            publishListCurInfo:{},
            text:'',
        }),
        methods:{
            submitDialog() {
                this.$refs['dialogForm'].validate((valid) => {
                    if (valid) {
                        var formData=this.form;
                        var info={};
                         var auth = JSON.parse(sessionStorage.getItem("auth"));
                        if(this.parentUrl!=='materialPublishList'){
                            info={
                                intro:formData.publishNotes,
                                module:formData.publishModule,
                                roomStr:formData.publishRoomStr,
                                uname:auth.user_name,
                                text:this.text
                            };
                        }else{
                            var publishListCurInfo=this.publishListCurInfo;
                            info={
                                intro:publishListCurInfo.intro,
                                module:publishListCurInfo.module,
                                roomStr:formData.publishRoomStr,
                                uname:auth.user_name,
                                id:publishListCurInfo.id,
                                 text:this.text
                            };
                        }
                        this.$emit('confirmMeth',info);
                        this.cancelDialog();
                    } else {
                        return false;
                    }
                });
            },
            choiceRoomMeth(){
            const link = this.$router.resolve({path: '/chooseRoom/', query: {id: this.id,database:this.database,roomStr:this.form.publishRoomStr}});
            window.open(link.href,'_blank')
                // Bus.$emit('showDrawer',{show:true,roomStr:this.form.publishRoomStr});
            },
            modelChange(val){
                val==='part'
                ?this.publishRoomStrShow=true
                    :this.publishRoomStrShow=false
            },
            dialogShowMeth(val,text){
                this.dialog.dialogFormVisible=val;
                this.text = text
            },
            cancelDialog(){
                this.form={
                    publishNotes:'',
                    publishModule:'all',
                    publishRoomStr:'',
                };
                this.dialogShowMeth(false);
                this.publishRoomStrShow=false;
            },
            asd(val){
               this.form.publishRoomStr = val;
            },
        },
       
        computed:{
            
            publishRoomStrCom(){
                var flag=false;
                if(this.parentUrl!=='materialPublishList'){
                    if(this.publishRoomStrShow){
                        flag=true;
                    }else{
                        flag=false;
                    }
                }else{
                    flag=true;
                }
                return flag;
            }
        },
        mounted(){
              
            var that = this;
            that.query=that.$route.query;
            window["getSonData"]=(val)=>{
                that.asd(val);
            }
        },
        created(){
            Bus.$on('setPublishRoom',val=>{
                console.log('接收值chooseRoo1111m',val);
                this.form.publishRoomStr=val;

            })
        }
    }
</script>

<style scoped>
    .fullSelect{
        width:100%;
    }
    .rightBtn{
        margin-left:auto;
    }
      .boxBlack{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background: #000;
            z-index: 2000;
    }
</style>
