<template>
  <div>
    <div class="homeContentBox">
      <div class="model1" v-if="servicemodelShow" @click="closeModel"></div>
      <img class="bgPic" :src="btnList.bgUrl" alt="">
      <div class="logoBox">
        <!-- @mouseenter="borderShow('logo')" @mouseleave="borderHidden('logo')" -->
        <div class="logoBoxLeft">
          <img :src="logoUrl" alt="">
        </div>
        <div class="logoBoxRight">
          <div class="info_one">
            <div class="top">{{curTime.time}}</div>
            <div class="bottom">{{curTime.date}}</div>
          </div>
          <div class="info_two">
            <div class="top">广州 晴</div>
            <div class="bottom">25~30℃</div>
          </div>
        </div>
      </div>
      <div v-if="ListLength>4"  class="leftBtn" @click="moveBox('left')"> <i class="el-icon-arrow-left"></i></div>
      <div v-if="ListLength>4" class="rightBtn" @click="moveBox('right')"><i class="el-icon-arrow-right"></i></div>
      <div class="contentShowBox"> 
        <div :class="[ListLength >3? 'picCardShowBox':'picCardShowBox1']">
           <div class="model1" v-if="servicemodelShow" @click="closeModel"></div>
          <div  :class="[ListLength >3? 'picCard':'picCard1',activeName == key?'contentLight':'',activeIndex == index?'borderActive':'']" v-for="(item,key,index) in pageData.pic" :key="item.id">
            <edit-btn @ishidden="isHidden" :msg="key"  :itemName="key"  @recoverydata='recoveryData' :class="['editBtnStyle','editBtnPic1',item.switch?'btnLight':'',]" :hidden="item.switch" @mouseenter.native="borderShow(index)" @mouseleave.native="borderHidden(index)"></edit-btn>
            <img   :src="item.picVertical?item.picVertical.url:''" alt="">
            <div class="plateName"><span>{{item.mname}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <drawer-box :hotelContract="hotelContract" :allPicAata="pageData.pic" @trueedit='trueEdit' @canceledit="cancelEdit"></drawer-box>
  </div>
</template>
<script>
import editBtn from "../common/editBtn";
import commonMeth from "@/api/method";
import drawerBox from "../common/drawerBox"
export default {
  props: ["attrName","data", "optBtnList", "logoUrl","hotelContract","modelShow"],
  components: {
    editBtn,
    drawerBox
  },
  data() {
    return {
      servicemodelShow: false,
      activeName:'',
      activeIndex: null,
      pageData: {},
       historyData:{},
          saveData:{},
      ListLength: null,
      clickSum: null,
      stopNum: 0,
      btnList: {},
      curTime: {
        date: "",
        week: "",
        time: "",
      },
    };
  },
  methods: {
     // 保存数据至vuex
      sendDataForVuex(){
        this.$store.commit("saveVisualContent",this.saveData)
      },
      // 保存初始历史数据
      saveHistoryData(){
         this.historyData=JSON.parse(JSON.stringify(this.pageData));
      },
      // 恢复历史文件
      recoveryData(val){
         this.pageData.pic[val]=JSON.parse(JSON.stringify(this.historyData.pic[val]));
         this.sendDataForVuex();  
      },
     cancelEdit(data,itemName){
       Object.assign(this.pageData.pic[itemName], JSON.parse(JSON.stringify(data)));
    },
    trueEdit(data){
       Object.assign(this.pageData.pic, JSON.parse(JSON.stringify(data)));
       Object.assign(this.saveData[this.attrName].pic, JSON.parse(JSON.stringify(data)));
      this.addSwitch();
       this.$forceUpdate();
       this.sendDataForVuex();  
    },
    moveBox(val) {
      var listBox = document.getElementsByClassName("contentShowBox")[2];
      var list = document.getElementsByClassName("picCardShowBox")[0];
      var nowPlace = parseFloat(listBox.clientWidth / 4);
      var oalce = parseFloat(list.style.marginLeft.slice(0, -2));
      this.clickSum = this.ListLength - 4;
      var flag = this.stopNum < this.clickSum;
      if (val === "left" && flag) {
        if (oalce) {
          var sum = oalce - nowPlace;
          list.style.marginLeft = sum + "px";
          this.stopNum++;
        } else {
          list.style.marginLeft = -nowPlace + "px";
          this.stopNum++;
        }
      } else if (val === "right" && this.stopNum > 0) {
        if (oalce) {
          var sum = oalce + nowPlace;
          list.style.marginLeft = sum + "px";
          this.stopNum--;
        } else if (oalce === 0) {
          list.style.marginLeft = 0 + "px";
          this.stopNum--;
        }
      }
    },
    // 按钮
    isHidden(val) {
      this.activeName=val;
      if(!this.servicemodelShow){
         this.pageData.pic[val].switch=true;
          this.servicemodelShow =true;
      }else{
         this.pageData.pic[val].switch=false;
          this.servicemodelShow =false;
    }
    this.$forceUpdate();
    },
    // 边框显示
    borderShow(val) {
      this.activeIndex = val;
    },
    // 边框隐藏
    borderHidden(val) {
      this.activeIndex = null
    },
    // 关闭遮罩
    closeModel() {
      this.servicemodelShow = false;
      var obj = this.pageData.pic;
      for (const key in obj) {
        obj[key].switch = false;
      }
    },
    // 给每一项绑定 开关
    addSwitch(){
      let that = this
        for (const key in that.pageData.pic) {
          that.$set(that.pageData.pic[key], "switch", false ) 
        }
      var arr = [];
      var obj = that.pageData.pic;
      for (var k in obj) {
        arr.push(k);
      }
      that.ListLength = arr.length;
    },
  },
  created() {
    this.pageData = this.data;
    this.addSwitch(); 
  },
  computed: {
    getImgUrl() {
      return function (val) {
        var obj = this.pageData.pic;
        for (const key in obj) {
          if (key == val && key == "1") {
            return obj[key].picHorizontal.url;
          } else if (key == val && key !== "1") {
            if (key == "4") {
              return obj[key].picIn[0].url;
            } else {
              return obj[key].picVertical.url;
            }
          }
        }
      };
    }, 
  },
  mounted() {
        this.saveData[this.attrName]=this.data;
    setInterval(() => {
      this.curTime = commonMeth.getCurrentDate();
    }, 1000);
        this.saveHistoryData();
    this.sendDataForVuex();
  },
  watch: {
     modelShow:{
        handler: function (newVal) {
            this.closeModel();
        },
      deep: true,
      immediate: true,
    },
    data: {
      handler: function (newVal) {
        this.pageData = newVal;      
      },
      deep: true,
      immediate: true,
    },
    optBtnList: {
      handler: function (newVal) {
        this.btnList = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style  scoped>
.editBtnStyle {
  position: absolute;
  width: 90%;
  height: 8%;
  z-index: 999;
}
.editBtnPic1 {
  top: 1%;
  left: 1%;
}
.homeContentBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.bgPic {
  width: 100%;
  height: 100%;
}
.logoBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 17.5%;
  width: 88%;
  margin: 0 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borderActive{
     border: 2px dashed #4093ff;
     transition: .2s;
}
.contentLight {
  position: relative;
  z-index: 2000;
}
.btnLight {
  z-index: 2001;
}
.logoBoxLeft {
  height: 52%;
  /* box-sizing: border-box; */
}
.logoBoxLeft img {
  height: 100%;
}
.logoBoxRight {
  height: 50%;
  display: flex;
  font-size: 1.8vw;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}
.info_two,
.info_one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_one {
  padding-right: 15px;
}
.info_two {
  padding-left: 15px;
  border-left: 1px solid #fff;
}
.info_two .top {
  margin-top: 2%;
}
.logoBox .bottom,
.info_two {
  font-size: 1vw;
}
.contentShowBox {
  position: absolute;
  top: 53%;
  left: 50%;
  height: 61.5%;
  width: 76%;
  transform: translate(-50%, -50%);
z-index: 1500;
  box-sizing: border-box;
  overflow: hidden;
}
.leftBtn {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  font-size: 5.6vw;
  color: #fff;
}
.rightBtn {
  position: absolute;
  top: 50%;
  right: 3%;
  font-size: 5.6vw;
  color: #fff;
  transform: translateY(-50%);
}
.picCardShowBox {
  width: 1000%;
  height: 100%;
}
.picCardShowBox1 {
    display: flex;
 justify-content: center;
 align-items: center;
  width: 100%;
  height: 100%;
}
.picCard {
 float: left;
  position: relative;
  height: 100%;
  width: 2.4%;
  margin:0 0.05%;
  box-sizing: border-box;
  padding-bottom:1px ;
}
.picCard1 {
     position: relative;
  display: inline-block;
  height: 100%;
  width: 24%;
  margin:0 0.5%;
   padding-bottom:1px ;
  box-sizing: border-box;
}
.picCard:nth-child(1) {
}
.picCard img ,.picCard1 img{
  width: 100%;
  height: 100%;
}
.plateName {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1vw;
}
.btnList {
  position: absolute;
  top: 82%;
  left: 0;
  height: 11%;
  width: 88%;
  margin: 0 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnCard {
  display: flex;
  width: 10.5%;
  height: 100%;
  margin-right: 1.6%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  color: #a4a4a4;
}
.btnCard.active {
  background: rgba(7, 0, 2, 0.3);
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 162, 255, 75%);
  color: #fff;
}
.btnCard:last-child {
  margin-right: 0%;
}
.btnCard img {
  height: 42%;
}
.btnCard span {
  margin-top: 8%;
}
.model1 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1500;
}
</style>