<template >
  <div>
    <div class="model" v-show="uploadVisible"></div>
    <div class="content" v-show="uploadVisible" v-loading="loading">
      <el-tabs type="card" v-model="activeItem" class="tabCard" @tab-click="tabForSource">
        <el-tab-pane label="文件上传" name="1">
          <div class="uploadBox" >
            <el-upload class="upload-demo" 
            drag 
            :action="global.materialUpload" 
            :on-success="handleSuccess" 
            :on-change="handleChange" 
            :on-progress="handleprogress"
            :before-upload="uploadBefore" 
            :headers="headers" 
            :file-list="fileList" 
            :show-file-list="false" 
            :auto-upload="true">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip"></div>
            </el-upload>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="false" label="素材管理" name="2">
          <div class="picBox" v-if="upType=='pic'">
            <div class="picBoxCard" v-for="(item,index) in picList" :key='index'>
              <img :src="item" alt="">
            </div>
          </div>
          <div class="picBox" v-if="upType=='video'">
            <div class="picBoxCard" v-for="(item,index) in picList" :key='index'>
              <video :src="item" alt=""></video>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-button class="outBtn" type="primary" @click="closeUp">取 消</el-button>
    </div>
  </div>
</template>
<script>
import {uploadHotelPicture,uploadHotelVideo} from "@/api/visual.js"
import bus from "../bus";
 import  qs from 'querystring'
import { log } from 'util';
export default {
  props:["hotelContract"],
  data() {
    return {
      upType:'',
      msg:"",
      activeItem:"1",
      loading:false,
      uploadVisible: false,
      headers: {
        token: "",
        Authorization: "",
      },
      fileList: [],
      picList:[],
    };
  },
  created() {
    bus.$on("open", (type,msg) => {
      this.uploadVisible = true;
      this.upType=type;
      this.msg=msg;
      this.activeItem="1";
      console.log( this.upType,this.msg);
    });
     bus.$on("openInDrawer", (type,msg) => {
      this.uploadVisible = true;
      this.upType=type;
      this.msg=msg;
      console.log( this.upType,this.msg);
    });
      bus.$on("close", (data) => {
      this.uploadVisible = false;
    });
    
  },
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    // 关闭上传
    closeUp(){
      this.uploadVisible=false;
      bus.$emit("close",false)
    },
    tabForSource(){
      if(this.activeItem=='2'){
        const json = {
          token:this.headers.token,
          hotelContract:this.hotelContract,
          type:this.upType
        }
        var  param = qs.stringify(json);
        this.$http.post(this.global.getHotelMaterialByType,param).then(res=>{
          console.log(res);
          this.picList = res.data.data
        }).catch(err=>{
          console.log(err);
        })
      }
    },
    // 上传成功回调
    handleSuccess(response, file, fileList) {
      this.loading=true;
     
      if(this.upType=="pic"||this.upType=="img"){
        const json ={
          hotelContract:this.hotelContract,
          fileUrl:response.data.url,
          fileName:response.data.fileName
        }
        var param = qs.stringify(json);
        uploadHotelPicture(param).then(res=>{
            // this.$emit('upsuccessdrawer',response.data,this.msg);
            this.$emit('upsuccess',response.data,this.msg);
            if(this.msg == 'drawer'){
              bus.$emit("updrawer",response.data,this.msg)
            }
           this.loading=false;
           this.closeUp();
          console.log(res);
        }).catch(err=>{
           console.log(err);
        })
      }else{
        const json ={
          hotelContract:this.hotelContract,
          fileUrl:response.data.url,
          fileName:response.data.fileName
        }
        var param = qs.stringify(json);
        uploadHotelVideo(param).then(res=>{
           this.$emit('upsuccess',response.data,this.msg);
             bus.$emit("updrawer",response.data,this.msg)
          //  this.$emit('upsuccessdrawer',response.data,this.msg);
            this.loading=false;
             this.closeUp();
          console.log(res);
        }).catch(err=>{
           console.log(err);
        })
      }
    },
    handleprogress(event, file, fileList){
      // console.log(event,file,fileList);
    },
    handlePreview(file) {},
    handleChange(file, fileList) {},
    // 上传文件之前校检文件格式
    uploadBefore(file) {
      if(this.upType=="pic"||this.upType=="img"){
          const isJPG = file.type === 'image/jpeg';
          if (!isJPG) {
            this.$message.error('请上传jpg格式图片!');
          }
          return isJPG ;
      }else{
         const isVideo= file.type === 'video/mp4';
        if (!isVideo) {
          this.$message.error('请上传mp4格式视频!');
        }
         return isVideo ;
      }   
    }, 
  },
};
</script>
<style scoped>
.tabShowBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.tabShowBox.localUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  padding-bottom:30px ;
  background: #fff;
  width: 50%;
  height: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5001;
}
.tabCard, >>>.el-tabs__content{
  width: 100%;
  height: 100%;
}
>>>.el-tab-pane{
  width: 100%;
  height:calc(100% - 100px) ;
}
 .uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.dialog-footer {
  position: relative;
  z-index: 9999;
}
.model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.23);
  z-index: 5000;
}
.outBtn {
  width: 100px;
  height: 40px;
  position: absolute;
  bottom: 18px;
  right: 20px;
}
.picBox{
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content:flex-start;
}
.picBoxCard{
width: 22%;
  height: 100px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin-top: 2% ;
   margin-left: 2% ;
  position: relative;
}
.picBoxCard:nth-child(4n){
   /* margin-left: 0 ; */
}
.picBoxCard img{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.picBoxCard video{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>