import Vue from 'vue'
import {
    Button,
    Form,
    FormItem,
    Input,
    Message,
    Container,
    Tooltip,
    Header,
    Aside,
    Main,
    Submenu,
    Menu,
    MenuItem,
    MenuItemGroup,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Table,
    TableColumn,
    Pagination,
    Tabs,
    TabPane,
    Dialog,
    Progress

} from 'element-ui'



Vue.use(Tooltip);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
// Vue.use(Message);
Vue.component(Message.name, Message);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Submenu);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Breadcrumb); //面包屑
Vue.use(BreadcrumbItem); //面包屑
Vue.use(Card);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Dialog);
Vue.use(Progress);
// Vue.prototype.$message=Message;