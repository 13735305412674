<template>

  <div :class="['box',showData?'':'box1']" :style="style">
    <div class="borderBox"></div>
    <visiualButton class="hide" :msg="showData.name[0].content" btnShow='picmore' @openmore='openMore' @recoverydata="recoverydata"></visiualButton>
    <uploadSource ref="uploadSource" sourceType="pic" :size="picSize" @upsuccess='upSuccess'></uploadSource>
    <uploadSourceMore ref="uploadSourceMore" sourceType="pic" @upsuccessmore="upSuccessMore"></uploadSourceMore>
    <div class="bottomBox" v-if="!boxStyle.notShowTitle" :style="{background:'url('+showData.title_pic[0].source_url+')'}">{{showData.name[0].content}}</div>
    <img v-if="showData&&showIndex==0" :src="showData.horizontal_pic[0].source_url" alt="" :style="{width: '100%', height: '100%'}">
    <img v-if="showData&&showIndex!=0" :src="showData.vertical_pic[0].source_url" alt="" :style="{width: '100%', height: '100%'}">
    <el-dialog title="服务详情" :visible.sync="dialogVisible" :append-to-body='true' width="1200px" :before-close="handleClose">
      <el-form :model="form" ref="form" :rules="rules" label-width="160px">
        <el-form-item label="模块名称" prop="mname">
          <el-input v-model="showData.name[0].content" :readonly="isEdit" @focus="showInput" @blur="textChange"></el-input>
        </el-form-item>
        <el-form-item label="横版封面图片" prop="picHorizontalHas">
          <div class="showPicBox" @click="changePic('H')">
            <i v-if="!showData.horizontal_pic[0].source_url" class="el-icon-plus" style="font-size:30px"></i>
            <img v-if="showData.horizontal_pic[0].source_url" :src="showData.horizontal_pic[0].source_url" alt="">
            <div class="btn" v-if="showData.horizontal_pic[0].source_url">
              <i class="el-icon-zoom-in" @click.stop="showPic('H')"></i>
              <i class="el-icon-delete" v-if="!isEdit" @click.stop="delPicHorizontal"></i>
              <!-- <i class="el-icon-refresh" @click.stop="refresh('H')"></i> -->

            </div>
          </div>
        </el-form-item>
        <el-form-item label="竖版封面图片" prop="picVerticalHas">
          <div class="showPicBox" @click="changePic('V')">
            <i v-if="!showData.vertical_pic[0].source_url" class="el-icon-plus" style="font-size:30px"></i>
            <img v-if="showData.vertical_pic[0].source_url" :src="showData.vertical_pic[0].source_url" alt="">
            <div class="btn" v-if="showData.vertical_pic[0].source_url">
              <i class="el-icon-zoom-in" @click.stop="showPic('V')"></i>
              <i class="el-icon-delete" v-if="!isEdit" @click.stop="delpicVertical"></i>
              <!-- <i class="el-icon-refresh" @click.stop="refresh('V')"></i> -->
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div class="root" style="margin-bottom:50px;">
        <div class="btnMore" @click="showUploadMore" v-if="!isEdit">配置</div>
        <img :src="getCurImgUrl" alt="" v-if="showData.pic_list.length>0">
        <div class="noDataOut" v-if="showData.pic_list.length===0">
          <no-data v-if="showData.pic_list.length===0"><span slot="tips">暂无数据</span></no-data>
        </div>
        <div class="rightBox">
          <div class="title">{{showData.name[0].content}}</div>
          <div class="topArrow arrow">
            <div class="el-icon-caret-top"></div>
          </div>
          <div class="imgBox">
            <draggable class="draggableBox" group="article" :value="showData.pic_list" animation="300" @input="handleListChange($event)">
              <div class="rightImgItem" :class="{active:curImgIndex===index}" :style="{'backgroundImage':'url('+item.source_url+')'}" :key="index" v-for="(item,index) in showData.pic_list" @click.stop="showCurImg(index)">
                <div class="delIcon">
                  <i class="el-icon-close" v-if="!isEdit" @click.stop="delMeth(item.source_url)"></i>
                </div>
              </div>
            </draggable>
          </div>
          <div class="topArrow arrow">
            <div class="el-icon-caret-bottom"></div>
          </div>
        </div>

      </div>

      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveTrue">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="picVisible" :append-to-body='true'>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>

</template>

<script>
import Draggable from "vuedraggable";
import visiualButton from "../common/editBtn.vue";
import uploadSource from "../common/uploadSource.vue";
import uploadSourceMore from "../common/uploadSourceMore.vue";
import { verificationResult, auditing } from "@/api/visual.js";
import md5 from "./md5";
import qs from "qs";
export default {
  name: "picMore",
  components: {
    visiualButton,
    uploadSource,
    uploadSourceMore,
    Draggable,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
    showIndex: {
      type: Number,
      default: 999,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        let obj = JSON.parse(JSON.stringify(newVal.position));
        for (var i in obj) {
          this.style[i] = obj[i] + "px";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    getCurImgUrl() {
      var str = "";
      if (this.showData.pic_list) {
        if (this.showData.pic_list.length > 0) {
          if (this.curImgIndex >= this.showData.pic_list.length) {
            this.curImgIndex = this.showData.pic_list.length - 1;
          }
          str = this.showData.pic_list[this.curImgIndex].source_url;
        }
      }
      return str;
    },
  },
  data() {
    return {
      style: {},
      picSize: "",
      rules: {
        mname: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
          { max: 20, message: "模块名称不能超过20个字符", trigger: "blur" },
        ],
        picHorizontalHas: [
          { required: true, message: "请选择横板图片", trigger: "blur" },
        ],
        picVerticalHas: [
          { required: true, message: "请选择竖板图片", trigger: "blur" },
        ],
      },
      form: {
        mname: "悦梦间",
        picHorizontal: "https://source.ctlife.tv/20220607115941988_0.png",
        picVertical: "https://source.ctlife.tv/20220607115950233_0.jpg",
      },
      showData: this.value,
      dialogVisible: false,
      picVisible: false,
      dialogImageUrl: "",
      curImgIndex: 0,
      showIn: {},
    };
  },
  mounted() {
    this.form = {
      mname: this.showData.name[0].content,
      picHorizontal: this.showData.horizontal_pic[0].source_url,
      picVertical: this.showData.vertical_pic[0].source_url,
    };
    this.showIn = JSON.parse(JSON.stringify(this.showData));
  },
  methods: {
    showInput() {
      if (this.isEdit) return;
      this.inputShow = true;
      this.oldStr = this.showData.name[0].content;
    },
    //  获取字符串MD5并验证
    verificationString(oldStr, newStr, v) {
      return new Promise((response, ret) => {
        var newMd5 = md5(newStr);
        verificationResult(qs.stringify({ md5: newMd5 })).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data == 3) {
              response(newStr);
            } else {
              var auth = JSON.parse(sessionStorage.getItem("auth"));
              var channelName = sessionStorage.getItem("curCompanyName");
              let json = {
                taskName: "可视化文字审核",
                resourcesType: 1,
                resourcesForm: 1,
                product: 5,
                userName: auth.user_name,
                channelName: channelName,
                userPhone: "-",
                txtContent: newStr,
              };
              auditing(qs.stringify(json)).then((res) => {
                if (res.data.code == 200) {
                  if (res.data.data[0].auditingResult == 3) {
                    v.$message.success("审核成功");
                    return response(newStr);
                  } else {
                    v.$message.warning("审核未通过");
                    return response(oldStr);
                  }
                } else {
                  v.$message.warning("审核失败");
                  return response(oldStr);
                }
              });
            }
          } else {
            v.$message.warning("获取审核信息失败");
            return response(oldStr);
          }
        });
      });
    },
    async textChange() {
      let res = await this.verificationString(
        this.oldStr,
        this.showData.name[0].content,
        this
      );
      this.showData.name[0].content = res;
      this.showData.name[0].update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    changePic(type) {
      if (type == "H" && !this.form.picHorizontal) {
        this.$refs.uploadSource.dialogVisible = true;
        this.$refs.uploadSource.HV = "H";
        this.picSize = this.boxStyle.sourceDesc;
      } else if (type == "V" && !this.form.picVertical) {
        this.$refs.uploadSource.dialogVisible = true;
        this.$refs.uploadSource.HV = "V";
        this.picSize = "390*658";
      }
    },
    refresh(type) {
      if (type == "H") {
        this.showData.horizontal_pic[0].source_md5 =
          this.historyData.horizontal_pic[0].source_md5;
        this.showData.horizontal_pic[0].source_url =
          this.historyData.horizontal_pic[0].source_url;
        this.showData.horizontal_pic[0].update = false;

        this.$emit("change", this.showData);
      } else if (type == "V") {
        this.showData.vertical_pic[0].source_md5 =
          this.historyData.vertical_pic[0].source_md5;
        this.showData.vertical_pic[0].source_url =
          this.historyData.vertical_pic[0].source_url;
        this.showData.vertical_pic[0].update = false;
        this.$emit("change", this.showData);
      }
      this.form = {
        mname: this.showData.name[0].content,
        picHorizontal: this.showData.horizontal_pic[0].source_url,
        picVertical: this.showData.vertical_pic[0].source_url,
      };
    },
    delPicHorizontal() {
      this.showData.horizontal_pic[0].source_url = "";
      this.form.picHorizontal = "";
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    delpicVertical() {
      this.showData.vertical_pic[0].source_url = "";
      this.form.picVertical = "";
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    showPic(type) {
      if (type == "H") {
        this.picVisible = true;
        this.dialogImageUrl = this.showData.horizontal_pic[0].source_url;
      } else if (type == "V") {
        this.picVisible = true;
        this.dialogImageUrl = this.showData.vertical_pic[0].source_url;
      }
    },
    upSuccess(data, hv) {
      if (hv == "H") {
        this.showData.horizontal_pic[0].source_md5 = data.md5;
        this.showData.horizontal_pic[0].source_url = data.url;
        this.showData.horizontal_pic[0].update = true;
        this.form.picHorizontal = data.url;
        this.$emit("change", this.showData);
        this.$emit("canrelease");
      } else {
        this.showData.vertical_pic[0].source_md5 = data.md5;
        this.showData.vertical_pic[0].source_url = data.url;
        this.showData.vertical_pic[0].update = true;
        this.form.picVertical = data.url;
        this.$emit("change", this.showData);
        this.$emit("canrelease");
      }
    },
    canculLog() {
      this.showData = JSON.parse(JSON.stringify(this.showIn));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    upSuccessMore(data) {
      let obj = {
        update: true,
        source_md5: data.md5,
        source_sort: 999,
        source_type: "1",
        source_url: data.url,
      };
      this.showData.pic_list.unshift(obj);
      this.sortMeth();
    },
    handleListChange(event) {
      this.showData.pic_list = event;
      this.sortMeth();
    },
    sortMeth() {
      var order = 0;
      this.showData.pic_list.forEach((item, index) => {
        order++;
        item.source_sort = order;
         item.update =true;
      });
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    delMeth(val) {
      this.showData.pic_list.filter((item, index) => {
        if (item.source_url === val) {
          var appointIndex = this.showData.pic_list.indexOf(item);
          this.showData.pic_list.splice(appointIndex, 1);
        }
      });
      this.sortMeth();
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    showCurImg(val) {
      this.curImgIndex = val;
    },
    showUploadMore() {
      this.$refs.uploadSourceMore.dialogVisible = true;
    },
    saveTrue() {
      if (this.form.mname == "") {
        this.$message.warning("模块名称不能为空");
        this.dialogVisible = true;
        return;
      } else if (this.form.picHorizontal == "") {
        this.$message.warning("模块横向封面不能为空");
        this.dialogVisible = true;
        return;
      } else if (this.form.picVertical == "") {
        this.$message.warning("模块竖向封面不能为空");
        this.dialogVisible = true;
        return;
      }
      this.showIn = JSON.parse(JSON.stringify(this.showData));
      this.dialogVisible = false;
    },
    handleClose() {
      this.canculLog();
      this.dialogVisible = false;
    },
    openMore() {
      this.dialogVisible = true;
    },
    openUppage() {
      this.$refs.uploadSource.dialogVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.btnMore {
  height: 30px;
  width: 80px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  cursor: pointer;
  transition: 0.3s;
  position: absolute;
  top: -40px;
  left: 0;
}
.btnMore:hover {
  /* background: #4093ff; */
  background: rgba(60, 138, 255, 0.8);
}
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 3;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
.showPicBox {
  height: 108px;
  width: 108px;
  position: relative;
  border: 1px dashed #999;
  background: rgba($color: #eee, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-height: 100%;
    max-width: 100%;
  }
  .btn {
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.3);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    transition: 0.3s;
    i {
      margin: 4px;
      color: #fff;
      font-size: 24px;
      transition: 0.3s;
    }
    i:hover {
      color: #409eff;
    }
  }
}
.showPicBox:hover .btn {
  display: flex;
}
.root {
  margin-top: 60px;
  width: 1152px;
  height: 648px;
  position: relative;
  border: 1px solid #ddd;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }
}
.sortPicBox {
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
}
.rightBox {
  width: 200px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  .title {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  img {
    width: auto;
    height: 100%;
    /*width:170px;*/
    /*height:94px;*/
    object-fit: cover;
    :last-child {
      margin-bottom: 0;
    }
  }
}
ul {
  padding: 0;
  width: 400px;
}
li {
  width: 100px;
  float: left;
}
.triangleUp {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid red;
}
.arrow {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow > div {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9d99b0;
}
.centerEl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploadBox {
  width: 952px;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
/*swiper开始*/
.swiperBox {
  height: 520px;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}
.swiper-slide {
  background-size: cover;
}
.delIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}
.relativeEl {
  position: relative;
  height: 100%;
}
/*swiper结束*/
.form {
  width: 1152px;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.btnBox {
  display: flex;
  margin-top: 20px;
  margin-left: auto;
}
.submitBtn {
  margin-left: auto;
}
.gallery-top {
  height: 100%;
  width: 100%;
  .swiper-slide {
    width: 100% !important;
  }
}
.scrollBox {
  width: 100%;
}
.gallery-thumbs {
  width: 100%;
  height: 518px;
  box-sizing: border-box;
  /*padding: 10px 0;*/
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
}
.gallery-thumbs .swiper-slide {
  width: 90% !important;
  height: 100%;
  opacity: 1;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-slide {
  background-size: cover !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.gallery-thumbs .swiper_slide_thumb_active {
  opacity: 1;
}
.rightImgItem {
  width: 90%;
  height: 19.6%;
  margin: 0 auto 2% auto;
  position: relative;
  box-sizing: content-box;
  border: 3px solid transparent;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat !important;
}
.rightImgItem.active {
  border: 3px solid #ffe699;
}
.imgBox {
  flex: 1;
  overflow: auto;
}
/*配置按钮样式开始*/
.configBox {
  display: flex;
  position: absolute;
  top: -40px;
  left: 0;
}
.configBox .btnItem {
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 20px;
  display: inline-block;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3) !important;
  cursor: pointer;
}
.configBtn:hover {
  background: #1c90ee !important;
  transition: 0.5s;
}
.btnGroup {
  display: inline-block;
  margin-right: 10px;
}
.btnGroupItem {
  display: inline-block;
  position: relative;
}
/*配置按钮样式结束*/
.draggableBox {
  height: 100%;
}
.noDataOut {
  width: calc(100% - 200px);
  height: 100%;
  /* background: red; */
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawerFooter {
  display: flex;
  margin-bottom: 20px;
}
.submitBtn {
  margin-left: 10px;
}
.boxBlack {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.bottomBox {
  position: absolute;
  height: 30px;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: #fff;
}
</style>