<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">流程列表</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <!--<div class="title chartTitle blueTitle">流程设置</div>-->
            <back @backMeth="backMeth" class="backBtn"></back>
            <el-row  v-for="(item,index) in processArr" :key="index" class="rowFlex">
                <el-col :span="5">
                    <div class="processItem">
                        <div class="connectBox" v-if="index!==0">
                            <div class="line"></div>
                            <div class="addIcon el-icon-circle-plus" @click="addMeth(index)">
                            </div>
                        </div>
                        <el-card class="box-card noMargin dashedHeader " :class="index===0?'bgProcessItem':''" shadow="never">
                            <div slot="header" class="header">
                                <span v-if="index===0">申请人</span>
                                <span v-else>审批人</span>
                                <el-button style="float: right; padding: 3px 0" type="text" @click="delProcessMeth(item.id,index)" v-if="index!==0">
                                    <i class="el-icon-close gray"></i>
                                </el-button>
                            </div>
                            <div class="contentBox">
                                <div class="nameText item">{{nameShow(item.adminIdsText,index)}}</div>
                                <div class="extendIcon" @click="showDrawer(true,index)" v-if="index!==0">
                                    <i class="el-icon-arrow-right extendIcon"></i>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <el-row class="rowFlex">
                <el-col :span="5">
                    <div class="processItem">
                        <div class="connectBox">
                            <div class="line"></div>
                            <div class="addIcon el-icon-circle-plus" @click="addMeth(processArr.length)">
                            </div>
                        </div>
                        <el-card class="box-card noMargin bgProcessItem" shadow="never">
                            <div class="endText item">结束</div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <el-row class="btnRow rowFlex">
                <el-button type="primary" @click="allSave('allBtn')">保 存</el-button>
            </el-row>
        </el-card>
        <el-drawer ref="drawer" :modal="false"
                title="审批人设置"
                size="60%"
                :visible.sync="drawer"
                :direction="direction">
            <div class="drawerContent">
                <el-form action="" ref="form" :model='form' :rules="rules" class="form">
                    <div>
                        <h4>修改审批人</h4>
                        <div class="selectedBox">
                            <div class="selectedItem" :key="index" v-for="(item,index) in form.approvalPerson" :data-value="item.id">
                                <span>{{item.name}}</span>
                                <i class="el-icon-close delPersonIcon" @click="delPersonMeth(item.value,'drawer')"></i>
                            </div>
                        </div>
                        <el-row class="flexBox">
                            <el-form-item prop="approvalPerson" class="noMargin approvalPerson">
                                <el-select v-model="form.approvalPerson" multiple  placeholder="审批人" @change="personChange($event)"
                                           class="fullSelect selectIconMargin"
                                           filterable
                                           collapse-tags
                                           clearable >
                                    <el-option
                                            v-for="(item,index) in selectData.personList"
                                            :key="item.id"
                                            :label="item.username"
                                            :value="{value:item.id,name:item.username,test:item.username}">
                                    </el-option>
                                </el-select>
                                <el-button type="text" class="selectMoreBtn" @click="showDialog(true)">更多</el-button>
                            </el-form-item>
                        </el-row>
                    </div>
                    <div>
                        <h4>多人审批方式</h4>
                        <el-form-item prop="approvalMethod">
                            <el-radio-group v-model="form.approvalMethod" :disabled="true">
                                <el-radio class="radioItem" :label="item.id" :key="index" v-for="(item,index) in selectData.radioList">{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                </el-form>
                <div class="drawerFooter">
                    <el-button @click="showDrawer(false)">取 消</el-button>
                    <!--<el-button type="primary" @click="$refs.drawer.showDrawer(false)" :loading="drawerBtnLoading">{{ drawerBtnLoading ? '保存中 ...' : '确 定' }}</el-button>-->
                    <el-button type="primary" @click="savePersonMeth" :loading="drawerBtnLoading" class="largeBtn">保 存</el-button>
                </div>
            </div>
        </el-drawer>
        <el-dialog title="人员选择" :visible.sync="dialog.visible" >
            <div class="selectedBox" v-if="dialog.tableSelectedData.length>0">
                <div class="selectedText">已选择：</div>
                <div class="selectedItem" :key="index" v-for="(item,index) in dialog.tableSelectedData">
                    <span>{{item.username}}</span>
                    <i class="el-icon-close delPersonIcon" @click="delPersonMeth(item.id,'dialog')"></i>
                </div>
            </div>
            <div>
                <el-table :data="selectData.personList" ref="dialogTable"
                          @selection-change="handleSelectionChange"
                          height="500" >
                    <el-table-column type="selection" ></el-table-column>
                    <el-table-column property="username" label="姓名"></el-table-column>
                    <el-table-column property="account_alias" label="账号名称" ></el-table-column>
                </el-table>
            </div>
            <div slot="footer" >
                <el-button @click="cancelDialog">取 消</el-button>
                <el-button type="primary" @click="submitDialog">确 定</el-button>
                <!--<el-button type="primary" @click="toggleSelection([selectData.personList[6],selectData.personList[7]])">赋值</el-button>-->
                <!--<el-button type="primary" @click="toggleSelection()">清除select</el-button>-->
                <!--<el-button type="primary" @click="testMeth()">测试</el-button>-->
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "SysProcess",
        data:()=>({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/sysProcess'},
            },
            processArr:[
                // {name:'测试1，测试2，测试3，测试4，测试5，测试6'},
                // {name:'张三，李四，王五，赵六'},
                // {name:'张三1，李四1，王五1，赵六1'},
            ],
            drawer: false,
            direction: 'rtl',
            form:{
                approvalPerson:'',
                approvalMethod:1
            },
            rules:{
                approvalPerson:{ required: true, message: '审批人不能为空', trigger: 'blur'},
                approvalMethod:{ required: true, message: '多人审批方式不能为空', trigger: 'blur'},
            },
            selectData:{
                personList:[
                    {id:1,name:'张三1'},
                    {id:2,name:'张三2'},
                    {id:3,name:'张三3'},
                    {id:4,name:'张三4'},
                    {id:5,name:'张三5'},
                    {id:6,name:'张三6'},
                    {id:7,name:'张三6'},
                    {id:8,name:'张三6'},
                    {id:9,name:'张三6'},
                    {id:10,name:'张三6'},
                    {id:11,name:'张三6'},
                    {id:12,name:'张三6'},
                    {id:13,name:'张三6'},
                ],
                radioList:[
                    {id:1,name:'成签（一名成员同意即可）'},
                    {id:2,name:'会签（须所有成员同意）'},
                    {id:3,name:'依次审批（按顺序依次审批）'},
                ]
            },
            drawerBtnLoading:false,
            query:{},
            dialog:{
                listData:[

                ],
                visible:false,
                tableSelectedData:[]
            },
            curProcessIndex:'',
            testData:[
                {
                    account_alias: "集团KA BD",
                    id: 244,
                    status: "normal",
                    status_text: "启用",
                    username: "谭洁"
                }],
            testDataTwo:[0]
        }),
        methods:{
            delProcessMeth(id,index){
                var param=qs.stringify({
                    token:this.token,
                    flowId:this.query.id,
                    id:id,
                });
                this.$http.post(this.global.destroyFlowProcess,param).then(res=>{
                    if(res.data.code===100000){
                        this.getDetail();
                    }
                });
                if( this.processArr[index+1]){
                      this.processArr[index-1].process_to=this.processArr[index+1].id
                       this.processArr[index+1].process_from=this.processArr[index-1].id
                }else{
                    this.processArr[index-1].process_to=null;
                }
              
                this.processArr.splice(index,1);
                this.allSave();
            },
            delPersonMeth(id,type){
                if(type==='drawer'){
                    var outIndex='';
                    this.form.approvalPerson.forEach((item,index)=>{
                        if(item.value===id){
                            outIndex=index
                        }
                    });
                    this.form.approvalPerson.splice(outIndex,1);
                }else if(type==='dialog'){
                    var indexArr=[];
                    var delIndex='';
                    this.dialog.tableSelectedData.forEach((itemOut,indexOut)=>{
                        this.selectData.personList.forEach((itemInner,indexInner)=>{
                            if(itemOut.id===itemInner.id){
                                indexArr.push(indexInner);
                            }
                        });
                    });
                    this.selectData.personList.forEach((item,index)=>{
                        if(item.id===id){
                            delIndex=index;
                        }
                    });
                    indexArr.splice(indexArr.indexOf(delIndex),1);
                    this.toggleSelection();
                    indexArr.forEach((item,index)=>{
                        this.toggleSelection([this.selectData.personList[item]]);
                    });
                }
            },
            addMeth(index){
                console.log(this.processArr);
                var prev=this.processArr[index-1];
                var prevId=prev?prev.id:null;
                var next=this.processArr[index];
                var nextId=next?next.id:null;
                var param=qs.stringify({
                    token:this.token,
                    flowId:this.query.id,
                    process_from:prevId,
                    process_to:nextId
                });
                console.log('上一个',prevId);
                console.log('下一个',nextId);
                console.log('添加',{id:'',process_from:prev?prev.id:null,process_to:next?next.id:null,fallbackType:'1',adminIds:'',hasPermissionIds:'',adminText:''});
                this.$http.post(this.global.addFlowProcess,param).then(res=>{
                    if(res.data.code===100000){
                        var curId=res.data.data.processId;
                        console.log('当前id',curId);
                        this.processArr.splice(index,0,{id:curId,process_from:prev?prev.id:null,process_to:next?next.id:null,fallbackType:'1',adminIds:'',hasPermissionIds:'',adminText:''});
                        this.processArr[index-1]?this.processArr[index-1].process_to=curId:'';
                        this.processArr[index+1]?this.processArr[index+1].process_from=curId:'';
                    }
                })
                console.log( this.processArr);
            },
            showDrawer(val,index){
                this.drawer=val;
                if(val){
                    this.curProcessIndex=index;
                    var curIdsArr=this.processArr[index].adminIds.split(',');
                    console.log('当前',curIdsArr);
                    var curDrawerArr=[];
                    curIdsArr.forEach((itemOut,index)=>{
                        this.selectData.personList.forEach((itemInner,index)=>{
                            if(itemOut==itemInner.id){
                                curDrawerArr.push({name:itemInner.username,value:itemInner.id})
                            }
                        })
                    });
                    this.form.approvalPerson=curDrawerArr;
                }

            },
            showDialog(val){
                this.dialog.visible=val;
                var idArr=[];
                var indexArr=[];
                this.form.approvalPerson.forEach((item,index)=>{
                    idArr.push(item.value);
                });
                this.$nextTick(() => {
                    this.toggleSelection();
                    this.form.approvalPerson.forEach((itemOut,indexOut)=>{
                        this.selectData.personList.forEach((itemInner,indexInner)=>{
                            if(itemInner.id===itemOut.value){
                                indexArr.push(indexInner);
                                this.toggleSelection([this.selectData.personList[indexInner]]);
                            }
                        });
                    });
                });
            },
            personChange(val){
                val.forEach((item,index)=>{

                })
            },
            savePersonMeth(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        var id=[];
                        var text=[];
                        var approvalPerson=this.form.approvalPerson;
                        approvalPerson.forEach((item,index)=>{
                            id.push(item.value);
                            text.push(item.name);
                        });
                        console.log('curIndex',this.curProcessIndex);
                        console.log('curIndex',this.processArr);
                        this.processArr[this.curProcessIndex].adminIds=id.join(',');
                        this.processArr[this.curProcessIndex].adminIdsText=text.join(',');
                        this.showDrawer(false);
                    } else {
                        this.$message.warning('请完善信息');
                    }
                });
            },
            getDetail(){
                var param=qs.stringify({
                    token:this.token,
                    flowId:this.query.id
                });
                this.$http.post(this.global.getFlowProcessByFlowId,param).then(res=>{
                    if(res.data.code===100000){
                        console.log('工作流11',res);
                        this.processArr=res.data.data;
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                })
            },
            getPerson(){
                console.log(1519889918);
                var param=qs.stringify({
                    token:this.token,
                    id:this.query.id,
                });
                this.$http.post(this.global.getHtripAccount,param).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.personList=res.data.data;
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                })
            },
            handleSelectionChange(val) {
                console.log('选择',val);
                this.dialog.tableSelectedData=val;
                // this.$emit('tableSelect',val);
            },
            cancelDialog(){
                this.dialog.visible=false;
            },
            submitDialog(){
                console.log('转换前',this.dialog.tableSelectedData);
                var test=JSON.parse(JSON.stringify(this.dialog.tableSelectedData));
                this.showDialog(false);
                this.form.approvalPerson=this.transObj(test);
            },
            transObj(arr){
                var keyMap = {
                    "username" : "name",
                    "id" : "value"
                };
                for(var i = 0;i < arr.length;i++){
                    var obj = arr[i];
                    for(var key in obj){
                        var newKey = keyMap[key];
                        if(newKey){
                            obj[newKey] = obj[key];
                            delete obj[key];
                        }
                    }
                }
                return arr;
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.dialogTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.dialogTable.clearSelection();
                }
            },
            testMeth(){
                this.toggleSelection();
                var test=[this.dialog.listData[6]];
                this.testData=[this.dialog.listData[6]];
                this.toggleSelection(this.testData);
            },
            allSave(type){
                if(this.processArr[1]){
                    this.processArr[0].process_to=this.processArr[1].id;
                }
                var param=qs.stringify({
                    token:this.token,
                    flowId:this.query.id,
                    processInfo:JSON.stringify(this.processArr),
                });
                this.$http.post(this.global.editProcessLinkByFlowId,param).then(res=>{
                    if(res.data.code===100000){
                        type==='allBtn'?this.$message.success('保存成功'):'';
                        this.getDetail();
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                });
            },
            backMeth(){
                this.$router.push({path:`./${this.query.fromUrl}`});
            },
        },
        computed:{
            nameShow(){
                return function(name,index){
                    var str='';
                    name && name!==''?str=name:(index===0?str='合作伙伴':str='请设置审批人');
                    return str;
                }
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.query=this.$route.query;
            console.log(this.query);
            this.getDetail();
            this.getPerson();
        }
    }
</script>

<style scoped lang="scss">
    .processItem{
        display:flex;
        flex-direction: column;
        .header{
            text-align: center;
        }
    }
    .line{
        width:2px;
        height:100%;
        background:#eef0f0;
        margin:auto;
    }
    .connectBox{
        margin:auto;
        height:80px;
        position: relative;
    }
    .noMargin{
        margin-top:0!important;
    }
    .addIcon{
        position: absolute;
        top: 28px;
        font-size: 25px;
        left: -12px;
        color:#409eff;
    }
    .nameText{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        flex: 1;
        text-align: center;
    }
    .endText{
        text-align: center;
    }
    .extendIcon{
        font-size: 20px;
        cursor: pointer;
        color:#43a0ff;
    }
    .contentBox{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: auto;
    }
    .noMargin{
        margin-bottom:0!important;
    }
    .addBtn{
        margin-left:20px;
    }
    .radioItem{
        width:100%;
        margin-bottom:20px;
    }
    .flexBox{
        display:flex;
    }
    .selectedItem{
        padding:10px;
        border-radius:8px;
        background:#eee;
        margin-right:10px;
        color:#909399;
        font-size:12px;
        margin-bottom: 10px;
    }
    .delPersonIcon{
        margin-left:10px;
    }
    .selectedBox{
        display:flex;
        margin-top:20px;
        flex-wrap:wrap;
    }
    .drawerContent{
        padding:0 20px;
    }
    .drawerFooter{
        position: absolute;
        bottom: 20px;
        display: flex;
        width: calc(100% - 40px);
        box-sizing: border-box;
        justify-content:space-around;
        align-items: center;
        .el-button{
            width: 100px;
            height: 40px;   
        }
    }
    .approvalPerson{
        margin-bottom:0!important;
        width:100%;
        position: relative;
    }
    .dialogContent{
        height:300px;
        overflow:auto;
    }
    .dialogFooter{
        position: absolute;
        bottom:20px;
    }
    .selectedText{
        margin-top:10px;
        font-size:14px;
    }
    .btnRow{
        margin-top:20px;
    }
    .backBtn{
        margin-bottom:20px;
    }
    .selectMoreBtn{
        position: absolute;
        right:10px;
        top: 4px;
    }
    .rowFlex{
        display: flex;
        justify-content: center;
    }
    .gray{
        color:#d5d9dd;
    }
</style>
