<template>
  <div style="padding:20px" class="page">

    <!-- <el-card class="box-card "> -->
    <div class="title blueTitle">机器人对接列表</div>
    <div class="showBox">
      <div class="item" v-for="(item,index) in list" :key="index">
        <div>{{item.name}}</div>
        <div class="jump" @click="jump(item.url)">跳转</div>
      </div>
    </div>

    <!-- </el-card> -->

  </div>
</template>
<script>
export default {
  name: "iotRobot",
  data: () => ({
    list: [
      {
        name: "九号机器人",
        url: "http://iotit.ctlife.tv:8000",
      },
      {
        name: "云迹机器人",
        url: "https://sso.user.yunjichina.com.cn/login?redirect=http://crowdsourcing.yunjichina.com.cn",
      },
    ],
  }),
  methods: {
    jump(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 20px;
  background: #fff;
}
.showBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .item {
    margin-top: 20px;
    margin-right: 20px;
    height: 120px;
    width: 300px;
    line-height: 120px;
    border-radius: 8px;
    box-shadow: 1px 1px 6px 2px rgba($color: #000000, $alpha: 0.1);
    padding: 0 10px;
    font-size: 20px;
    position: relative;
    text-align: center;
    .jump {
      position: absolute;
      right: 10px;
      bottom: 15px;
      color: #5a9dfc;
      font-size: 14px;
      height: 14px;
      line-height: 14px;
      cursor: pointer;
    }
  }
}
</style>
