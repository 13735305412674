<template>
    <div class="noDataBox">
        <div class="noData">
            <img src="../../assets/img/noData.png" alt="">
            <div class="text flexBox">
                <slot name="tips"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NoData"
    }
</script>

<style scoped lang="scss">
    .noDataBox{
        display:flex;
        justify-content: center;
        padding:90px 0;
        .noData{
            width:300px;
            position:relative;
            img{
                width:100%;
                height:auto;
            }
        }
        .text{
            width:100%;
            color:#a3a7af;
            text-align: center;
            position:absolute;
            bottom:10px;
        }
    }
</style>