<template>
    <div >
         <div class="boxBlack" v-if="dialogParData.visible"></div>
        <el-dialog  class="dialogUpload" :close-on-click-modal="false" title=""
                   :visible.sync="dialogParData.visible"
                   :show-close="false" :modal="false">
            <el-tabs type="border-card" class="uploadTab">
                <el-tab-pane label="本地上传" v-loading='upLoading'>
                    <div class="tabShowBox localUpload">
                        <el-upload
                                class="upload-demo"
                                drag
                                :action="global.materialUpload"
                                :on-success="handleSuccess"
                                :on-preview="handlePreview"
                                :on-change="handleChange"
                                :before-upload="uploadBefore"
                                :on-progress="uploadProgress"
                                :headers="headers"
                                :file-list="fileList"
                                :show-file-list="false"
                                :accept="uploadAccept.type"
                                :auto-upload="true" :multiple="multipleProp">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">{{uploadAccept.text}}</div>
                        </el-upload>
                        <!--<div class="agreeBox">-->
                            <!--<div class="radioBtn" :class="dialogUpload.agreenActive?'active':''" @click="agreenMeth">-->
                                <!--<i class="el-icon-check"></i>-->
                            <!--</div>-->
                            <!--<div class="textBox">-->
                                <!--<span>我已阅读并同意遵守</span>-->
                                <!--<span class="blueText">《携旅视频上传服务规则》</span>-->
                            <!--</div>-->
                        <!--</div>-->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="素材库上传" v-if="hideEl">
                    <div class="tabShowBox" v-if="!noData">
                        <div class="topBtn">
                            <el-button type="text" class="redText delTextBtn" @click="delConfirm">删除</el-button>
                            <el-button type="text" class="blueText confirmBtn" @click="confirmMeth">确定</el-button>
                        </div>
                        <el-row :gutter="20" class="conBox" v-if="(dialogParData.uploadMaterialType==='img' || dialogParData.uploadMaterialType==='pic' )">
                            <el-col :span="8" class="materialItemCol"  :key="index" v-for="(item,index) in dialogUpload.imgMaterialList" >
                                <div class="materialItem" @click="chooseMeth(index,item)"  :class="dialogUpload.chooseItemIndex===index?'active':''">
                                    <div class="itemRadioBtn flexBox">
                                        <i class="el-icon-check"></i>
                                    </div>
                                    <div class="imgBox">
                                        <img :src="item" alt="" >
                                    </div>
                                    <!--<div class="itemText">测试name</div>-->
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="conBox" v-if="dialogParData.uploadMaterialType==='video'">
                            <el-col :span="8"  :key="index" v-for="(item,index) in dialogUpload.videoMaterialList" >
                                <div class="materialItem" @click="chooseMeth(index,item)"  :class="dialogUpload.chooseItemIndex===index?'active':''">
                                    <div class="itemRadioBtn flexBox">
                                        <i class="el-icon-check"></i>
                                    </div>
                                    <div class="imgBox">
                                        <video :src="item" controls ></video>
                                    </div>
                                    <div class="itemText">{{item.name}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="tabShowBox noDataBox flexBox" v-if="noData">
                        <div class="noData">
                            <img src="../../../assets/img/noData.png" alt="">
                            <div class="text flexBox">暂无数据</div>
                        </div>
                    </div>
                </el-tab-pane>
                <div class="cancelBox">
                    <el-button @click="cancelMeth" type="primary">取消</el-button>
                </div>
            </el-tabs>
        </el-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "UploadDialog",
        props:{
            dialogParData:{},
            hotelContract:'',
            multipleProp:{
                type:Boolean,
                default:false
            },
        },
        data:()=>({
            fileList: [],
            dialogUpload:{
                agreenActive:'',
                imgMaterialList:[],
                videoMaterialList:[],
                chooseItemIndex:'',
                uploadModel:'',
                uploadMaterialType:'',
                uploadMaterialUrl:''
            },
            headers: {
                token: '',
                Authorization: ''
            },
            uploadAccept:{
                type:'',
                text:''
            },
            uploadAcceptImg:'.jpg',
            uploadAcceptVideo:'.mp4',
            noData:false,
            hideEl:false,
            upLoading:false,
            // hotelContract:'0cc82c745972d89646558071c6a154d9'
        }),
        methods:{
            uploadProgress(event, file, fileList){
                this.upLoading=true;
                if (event.percent==100) {
                     this.upLoading=false;
                     this.handleSuccess();
                }  
            },
            agreenMeth(){
                this.dialogUpload.agreenActive=!this.dialogUpload.agreenActive;
            },
            chooseMeth(index,url){
                var dialogUpload=this.dialogUpload;
                dialogUpload.chooseItemIndex=index;
                dialogUpload.uploadMaterialUrl=url;
            },
            delConfirm(){
                if(this.judgeIsChoose()){
                    this.$confirm('确定删除该素材?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.delMeth();
                    })
                }else{
                    this.$message.warning('请选择要删除的素材');
                }
            },
            delMeth(){
              
                var param=qs.stringify({
                    token:this.headers.token,
                    hotelContract:this.hotelContract,
                    data:this.dialogUpload.uploadMaterialUrl
                });
                // this.$http.post(this.global.destroyHotelMaterial,param).then(res=>{
                this.$http.post(this.global.destroyHotelMaterial,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('删除成功');
                        var dialogUpload=this.dialogUpload;
                        dialogUpload.chooseItemIndex='';
                        dialogUpload.uploadMaterialUrl='';
                        if(this.dialogParData.uploadMaterialType==='img'){
                            this.getMaterial('pic');
                        }else{
                            this.getMaterial('video');
                        }
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{
                 
                })
            },
            judgeIsChoose(){
                var flag=false;
                if(this.dialogUpload.uploadMaterialUrl!==''){
                    flag=true;
                }
                return flag;
            },
            confirmMeth(){
                var dialogUpload=this.dialogUpload;
                if(this.judgeIsChoose()){
                    this.$emit('uploadMaterialUrl',dialogUpload.uploadMaterialUrl,'');
                    dialogUpload.visible=false;
                }else{
                    this.$message.warning('请选择素材');
                }
            },
            handleSuccess(res, file) {
                this.$emit('editTabLoadingMeth', false);
                var url=res.data.url;
                var fileName=res.data.fileName;
                var md5=res.data.md5;
                this.$emit('uploadMaterialUrl',url,fileName,md5,file);
            },
            uploadBefore(file){
                // this.$emit('changeDialogVisible', false);
                this.$emit('editTabLoadingMeth', true,'upload');
                var type=this.dialogParData.uploadMaterialType;
                var sizeLimit;
                var str='';
                if(type==='video'){
                    sizeLimit = file.size / 1024 / 1024 < 50; // 限制小于50M
                    str='视频大小不可超过50MB';
                    this.$emit('editTabLoadingMeth', false,'upload');
                }else{
                    sizeLimit = file.size / 1024 < 500; // 限制小于500k
                    str='图片大小不可超过500K';
                    this.$emit('editTabLoadingMeth', false,'upload');
                }
                if (!sizeLimit) {
                    this.$message.error(str);
                }
                return sizeLimit
            },
            handlePreview(file){

            },
            handleChange(file, fileList) { },
            getMaterial(type){
                var param=qs.stringify({
                    token:this.headers.token,
                    hotelContract:this.hotelContract,
                    type:type
                });
                this.$http.post(this.global.getHotelMaterialByType,param).then(res=>{
                    console.log(res);
                    if(res.data.code===100000){
                        if(type==='pic'){
                            this.dialogUpload.imgMaterialList=res.data.data;
                        }else{
                            this.dialogUpload.videoMaterialList=res.data.data;
                        }
                        this.noData=false;
                    }else if(res.data.code==='没有找到相关数据'){
                        this.noData=true;
                    }else{
                        this.noData=false;
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{

                })
            },
            cancelMeth(){
                this.$parent.cancelMeth();
            }

        },
        mounted(){
            this.headers.token=sessionStorage.getItem('token');
            var dialogUpload=this.dialogUpload;
            var dialogParData=this.dialogParData;
            dialogUpload.chooseItemIndex='';
            dialogUpload.uploadMaterialType=dialogParData.uploadMaterialType;
        },
        watch:{
            'dialogParData.visible'(newVal){
                this.dialogUpload.chooseItemIndex='';
                this.dialogUpload.uploadMaterialType='';
                this.dialogUpload.uploadMaterialUrl='';
                if(newVal){
                    if(this.dialogParData.uploadMaterialType==='video'){
                
                        this.getMaterial(this.dialogParData.uploadMaterialType);
                        this.uploadAccept.type=this.uploadAcceptVideo;
                        this.uploadAccept.text='只限上传MP4格式视频，且单个视频最大不能超过50M。';
                    }else{
                        this.getMaterial('pic');
                        this.uploadAccept.type=this.uploadAcceptImg;
                        this.uploadAccept.text='只限上传JPG格式图片，且单张图片最大不能超过500K。';
                    }
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    /*上传弹窗开始*/
    .tabShowBox{
        width:100%;
        height:470px;
        display:flex;
        flex-direction: column;
    }
    .tabShowBox.localUpload{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .agreeBox{
        margin-top:40px;
        display:flex;
        align-items: center;
    }
    .radioBtn{
        border:1px solid #ddd;
        border-radius:50%;
        margin-right:8px;
        transition:0.5s;
        cursor:pointer;
    }
    .radioBtn i{
        font-size:18px;
        color:#fff;
        transition:0.5s;
    }
    .radioBtn.active{
        background:#409eff;
        transition:0.5s;
    }
    .radioBtn.active i{
        color:#fff;
        transition:0.5s;
    }

    .transText{
        color:transparent;
    }
    .blueText{
        color:#409eff;
    }
    .topBtn{
        display:flex;
        margin-bottom:5px;
    }
    .redText{
        color:#ea1f1f;
    }
    .confirmBtn{
        margin-left:auto;
    }
    .conBox{
        flex:1;
        overflow:auto;
        box-sizing: border-box;
        padding-top:10px;
    }
    .materialItemCol{
        margin-bottom:20px;
    }

    .conBox .materialItem{
        box-sizing:border-box;
        padding:10px;
        border:1px solid rgba(206,208,214,0.5);
        border-radius:6px;
        position:relative;
        cursor:pointer;
        transition:0.5s;

    }
    .conBox .materialItem.active{
        border:1px solid #409eff;
        transition:0.5s;
    }
    .conBox .materialItem.active .itemRadioBtn{
        background:#409eff;
        transition:0.5s;
        border:none;
    }
    .conBox .materialItem.active .itemRadioBtn i{
        color:#fff;
        transition:0.5s;
    }
    .itemRadioBtn{
        border:1px solid #ddd;
        border-radius:50%;
        width:20px;
        height:20px;
        position:absolute;
        top:-10px;
        left:-10px;
        background:#fff;
        transition:0.5s;
    }
    .itemRadioBtn i{
        color:transparent;
        transition:0.5s;
    }

    .itemText{
        margin-top:15px;
        text-align: center;
    }
    .flexBox{
        display:flex;
        justify-content: center!important;
        align-items: center!important;
    }
    .imgBox{
        height:auto;
    }
    .dialogUpload{
        /*z-index:9999999!important;*/
    }
    img,video{
        width:100%;
        height:auto;
        outline:none!important;
    }
    .cancelBox{
        height: 60px;
        padding: 0 20px;
        box-sizing: border-box;
        display:flex;
    }
    .cancelBox .el-button{
        margin-left:auto;
        height: 36px;
        width: 100px;
    line-height: 36px;
    box-sizing: border-box;
    padding: 0;
    }

    .noDataBox{
        .noData{
            width:300px;
            position:relative;
        }
        .text{
            width:100%;
            color:#a3a7af;
            text-align: center;
            position:absolute;
            bottom:10px;
        }
    }
   ::v-deep .el-tabs--border-card>.el-tabs__content{
       box-sizing: border-box;;
    }
    /*上传弹窗结束*/
     .boxBlack{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background: #000;
            z-index: 2000;
    }
    .uploadTab ::v-deep .el-tabs__header {
  padding-left: 0px !important;
}

.uploadTab .el-tabs--top ::v-deep.el-tabs__item.is-top:last-child {
  padding-right: 20px !important;
}
</style>
