<template>
  <div v-loading='mainLoading'>
    <!-- 面包屑 -->
    <div class="outTopBox">
      <top-bread :bread="bread" v-if="curPage === '/indexShow'">
        <span slot="second">首页</span>
        <span slot="third">更多</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskToDo" v-if="curPage === '/taskToDo'">
        <span slot="second">任务管理</span>
        <span slot="third">待办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskDone" v-if="curPage === '/taskDone'">
        <span slot="second">任务管理</span>
        <span slot="third">已办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <!-- 面包屑 -->
    <el-card class="box-card">
      <!-- 按钮及标题 -->
      <el-col class="btnRow" :span="24">
        <div>
          <back @backMeth="backMeth"></back>
        </div>
      </el-col>
      <div class="pageTitle">
        <p class="titleWithdraw">提现申请</p>
        <p class="numWithdraw">流水号：{{ tableData[0].withdraw_no }}</p>
      </div>
      <!-- 按钮及标题 -->
      <!-- 数据列表 -->
      <el-table ref="singleTable" :data="tableData" style="width: 100%" :header-cell-style="headerStyle">
        <el-table-column label="合作伙伴名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.partner.company_name }}</span>
          </template>
        </el-table-column>
        <el-table-column property="withdraw_amount_text" label="申请提现金额">
        </el-table-column>
        <el-table-column property="partner_code" label="合作伙伴编码" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.partner.code }}</span>
          </template>
        </el-table-column>
        <el-table-column property="timeshow" label="合同有效期" show-overflow-tooltip>
        </el-table-column>
        <el-table-column property="apply_admin_name_text" label="申请人">
        </el-table-column>
      </el-table>
      <div v-if="btnShow" class="rightBtn">
        <el-button type="primary" @click="pass" class="largeBtn">通过</el-button>
        <el-button type="danger" @click="reject" class="largeBtn redBtn">驳回</el-button>
      </div>
    </el-card>
    <!-- 数据列表 -->
    <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
  </div>
</template>

<script>
import { iddWithdrawPassOrNo, ukWithdrawPassOrNo } from "@/api/iddfinan";
import { withdrawPassOrNo } from "@/api/financialManagement";
import qs from "querystring";
export default {
  name: "IndexDoDetail",
  data: () => ({
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/indexDoMore" },
    },
    breadTaskToDo: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/taskToDo" },
    },
    breadTaskDone: {
      level: 4,
      thirdPath: { path: "/taskDone" },
    },
    id: "", //接收参数
    readOnly: true,
    labelPosition: "top",
    second: "60",
    showCountDown: "",
    form: {
      partner_name: "",
      partner_type: "",
      contractTime: "",
      withdraw_amount: "",
    },
    query: {},
    token: "",
    btnShow: "",
    mainLoading: true,
    curPage: "",

    tableData: [{ withdraw_no: "", partner: { company_name: "" } }],
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
  }),
  methods: {
    // 驳回填写
    reject() {
      this.$refs.rejectDialog.dialogShow(true);
    },
    // 按钮类型
    pass() {
      this.$confirm("确定通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.examine(1);
        })
        .catch(() => {});
    },
    // 提示信息框
    showMessage(str, type) {
      this.$message({
        message: str,
        type: type,
      });
    },
    // 审核
    examine(status) {
      var json = {
        id: this.query.id,
        status: status,
        message: this.$refs.rejectDialog.dialog.reason,
      };
      var param = qs.stringify(json);
      if (this.query.type == "partnerWithdraw") {
        withdrawPassOrNo(param).then((res) => {
          var str = "";
          status === 1 ? (str = "审核") : (str = "驳回");
          if (res.data.code === 100000) {
            this.showMessage(str + "成功", "success");
          } else {
            this.showMessage(str + "失败", "error");
          }
          this.$router.push("/indexDoMore");
        });
      } else if (this.query.type == "independentWithdraw") {
        iddWithdrawPassOrNo(param).then((res) => {
          var str = "";
          status === 1 ? (str = "审核") : (str = "驳回");
          if (res.data.code === 100000) {
            this.showMessage(str + "成功", "success");
          } else {
            this.showMessage(str + "失败", "error");
          }
          this.$router.push("/indexDoMore");
        });
      } else {
        ukWithdrawPassOrNo(param).then((res) => {
          var str = "";
          status === 1 ? (str = "审核") : (str = "驳回");
          if (res.data.code === 100000) {
            this.showMessage(str + "成功", "success");
          } else {
            this.showMessage(str + "失败", "error");
          }
          this.$router.push("/indexDoMore");
        });
      }
    },
    //  获取页面数据
    getDetail() {
      this.mainLoading = true;
      var url = "";
      if (this.query.type === "independentWithdraw") {
        url = this.global.iddGetWithdrawContent;
      } else if (this.query.type === "partnerWithdraw") {
        url = this.global.getWithdrawContent;
      } else {
        url = this.global.ukGetWithdrawContent;
      }
      this.$http
        .get(url, {
          params: {
            token: this.token,
            id: this.query.id,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.tableData = res.data.data;
            var timer = "";
            if(this.tableData[0].partner_contract){
               timer =
              this.tableData[0].partner_contract.contract_date_begin.substr(
                0,
                10
              ) +
              "~" +
              this.tableData[0].partner_contract.contract_date_end.substr(
                0,
                10
              );
            this.tableData[0].timeshow = timer;
            }else{
                  this.tableData[0].timeshow = "";
            }
           
          }
          this.mainLoading = false;
        }).catch(err=>{
           this.mainLoading = false;
        });
    },
    //  返回功能
    backMeth() {
      this.$router.push({ path: `./${this.query.fromUrl}` });
    },
  },
  // 数据挂载
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.curPage = sessionStorage.getItem("defaultActive");
    var query = this.$route.query;
    this.query = query;
    if (query.reviewStatus === "待审核") {
      this.btnShow = true;
    } else {
      this.btnShow = false;
    }
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.btnRow {
  display: flex;
  .el-button:first-child {
    margin-left: 10px;
  }
}
.formBox {
  width: 100%;
}
.el-input > input {
  border: none !important;
}
.selectItem {
  width: 100%;
}
.halfWidth {
  width: 50%;
}
.radioBox {
  margin-top: 12px;
}
.fullEl {
  width: 100%;
}
.rightBtn {
  margin-top: 20px;
  text-align: right;
}
.pageTitle {
  margin-bottom: 20px;
}
.pageTitle p {
  text-align: center;
  margin-bottom: 10px;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.titleWithdraw {
  font-size: 14px;
}
.numWithdraw {
  font-size: 12px;
  color: #adb6c1;
}
</style>
