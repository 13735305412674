<template>
  <div>
    <div class="btnRow btnRowbox" style="padding: 0 15px">
      <span>模板:</span>
      <el-select style="width:300px" v-model="query.templateId" placeholder="选择模板" class="hotelBrand fullSelect marginLeft" filterable>
        <el-option v-for="item in templateList" :key="item.id" :label="item.t_name" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" class="largeBtn marginLeft" @click="changeTemplate">搜 索</el-button>
      <el-button type="primary" class="largeBtn marginLeft" @click="openRealeaseView">提 交</el-button>
    </div>
    <launcherTemplate v-loading="launcherLoading" v-if="launcherShow" :query="query" @updata="updataLauncher" :content="launcherData" />
    <el-dialog title="选择房间" :visible.sync="releaseVisible" :before-close="releaseClose" width="60%" :append-to-body="true">
      <el-form ref="versionForm" :rules="rules" :model="versionForm" label-width="100px">
        <el-form-item label="版本说明" prop='versionDesc'>
          <el-input v-model="versionForm.versionDesc"></el-input>
        </el-form-item>
        <el-form-item label="酒店选择" prop='hotelStr'>
          <hotelSelect v-model="versionForm.hotelStr" :noInsert="true" /> <span style="margin-left:10px">已选酒店 {{versionForm.hotelStr.length}} 家</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" @click="releaseVisibleTrue('versionForm')">发 布</el-button>
        <el-button class="cancelBtn" @click="releaseClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import qs from "querystring";
import launcherTemplate from "./launcherTemplate.vue";
import {
  getTemplateList,
  getLauncher,
  releaseVersion,
} from "../api/launcherTemplate";
export default {
  name: "launcherMore",
  components: {
    launcherTemplate,
  },
  data() {
    return {
      launcherLoading: false,
      launcherShow: false,
      launcherData: [
        {
          mname: "功能模块",
          morder: "0",
          attr: "1",
          attrName: "目录",
          id: "2",
          child: [
            {
              mname: "主页",
              morder: "1",
              attr: "62",
              attrName: "IU主页",
              id: "3",
              child: [
                {
                  mname: "主页视频组",
                  morder: "1",
                  attr: "48",
                  attrName: "视频组",
                  id: "533",
                  child: [
                    {
                      mname: "主页视频1",
                      morder: "0",
                      attr: "1",
                      attrName: "目录",
                      id: "534",
                      child: [
                        {
                          mname: "主页视频1-1",
                          morder: "0",
                          attr: "37",
                          attrName: "视频",
                          id: "535",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "主页右图",
                  morder: "2",
                  attr: "39",
                  attrName: "广告",
                  id: "18",
                },
              ],
            },
            {
              mname: "手机投屏",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "457",
            },
            {
              mname: "电视直播",
              morder: "4",
              attr: "72",
              attrName: "瀑布流电视",
              id: "5",
              child: [
                {
                  mname: "电视广告",
                  morder: "1",
                  attr: "39",
                  attrName: "广告",
                  id: "461",
                  child: [
                    {
                      mname: "酒店概况",
                      morder: "1",
                      attr: "3",
                      attrName: "图文",
                      id: "499",
                      child: [
                        {
                          mname: "电视广告刊位1内容",
                          morder: "0",
                          attr: "34",
                          attrName: "视图",
                          id: "500",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "电视内页广告",
                  morder: "2",
                  attr: "39",
                  attrName: "广告",
                  id: "160",
                },
              ],
            },
            {
              mname: "酒店服务",
              morder: "5",
              attr: "45",
              attrName: "六联版",
              id: "4",
              child: [
                {
                  mname: "酒店服务视频组",
                  morder: "1",
                  attr: "48",
                  attrName: "视频组",
                  id: "536",
                  child: [
                    {
                      mname: "酒店服务视频1",
                      morder: "1",
                      attr: "1",
                      attrName: "目录",
                      id: "537",
                      child: [
                        {
                          mname: "酒店服务视频1-1",
                          morder: "0",
                          attr: "37",
                          attrName: "视频",
                          id: "538",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "服务文字",
                  morder: "2",
                  attr: "33",
                  attrName: "文字",
                  id: "15",
                },
                {
                  mname: "服务内容",
                  morder: "3",
                  attr: "46",
                  attrName: "双图目录",
                  id: "16",
                  child: [
                    {
                      mname: "互动活动",
                      morder: "1",
                      attr: "46",
                      attrName: "双图目录",
                      id: "19",
                      child: [
                        {
                          mname: "互动活动大图",
                          morder: "0",
                          attr: "34",
                          attrName: "视图",
                          id: "169",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "服务广告",
                  morder: "4",
                  attr: "39",
                  attrName: "广告",
                  id: "462",
                  child: [
                    {
                      mname: "酒店介绍",
                      morder: "1",
                      attr: "3",
                      attrName: "图文",
                      id: "502",
                      child: [
                        {
                          mname: "酒店服务广告刊位1内容",
                          morder: "0",
                          attr: "34",
                          attrName: "视图",
                          id: "503",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              mname: "携旅极光TV",
              morder: "6",
              attr: "73",
              attrName: "瀑布流电影",
              id: "515",
            },
          ],
        },
        {
          mname: "欢迎页模块",
          morder: "2",
          attr: "1",
          attrName: "目录",
          id: "1",
          child: [
            {
              mname: "焦点图标",
              morder: "0",
              attr: "3",
              attrName: "图文",
              id: "26",
            },
            {
              mname: "默认图标",
              morder: "0",
              attr: "3",
              attrName: "图文",
              id: "27",
            },
            {
              mname: "欢迎语",
              morder: "0",
              attr: "33",
              attrName: "文字",
              id: "28",
            },
            {
              mname: "欢迎视频",
              morder: "4",
              attr: "48",
              attrName: "视频组",
              id: "539",
              child: [
                {
                  mname: "欢迎视频1",
                  morder: "1",
                  attr: "1",
                  attrName: "目录",
                  id: "540",
                  child: [
                    {
                      mname: "欢迎视频1-1",
                      morder: "0",
                      attr: "37",
                      attrName: "视频",
                      id: "541",
                    },
                  ],
                },
              ],
            },
            {
              mname: "欢迎页广告",
              morder: "5",
              attr: "39",
              attrName: "广告",
              id: "158",
            },
          ],
        },
        {
          mname: "导航栏图标",
          morder: "3",
          attr: "1",
          attrName: "目录",
          id: "42",
          child: [
            {
              mname: "选中图标零",
              morder: "1",
              attr: "3",
              attrName: "图文",
              id: "508",
            },
            {
              mname: "焦点图标零",
              morder: "1",
              attr: "3",
              attrName: "图文",
              id: "509",
            },
            {
              mname: "默认图标零",
              morder: "1",
              attr: "3",
              attrName: "图文",
              id: "510",
            },
            {
              mname: "焦点图标一",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "43",
            },
            {
              mname: "默认图标一",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "44",
            },
            {
              mname: "选中图标一",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "504",
            },
            {
              mname: "焦点图标二",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "45",
            },
            {
              mname: "默认图标二",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "51",
            },
            {
              mname: "选中图标二",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "505",
            },
            {
              mname: "焦点图标三",
              morder: "4",
              attr: "3",
              attrName: "图文",
              id: "46",
            },
            {
              mname: "默认图标三",
              morder: "4",
              attr: "3",
              attrName: "图文",
              id: "52",
            },
            {
              mname: "选中图标三",
              morder: "4",
              attr: "3",
              attrName: "图文",
              id: "506",
            },
            {
              mname: "焦点图标四",
              morder: "5",
              attr: "3",
              attrName: "图文",
              id: "47",
            },
            {
              mname: "默认图标四",
              morder: "5",
              attr: "3",
              attrName: "图文",
              id: "53",
            },
            {
              mname: "选中图标四",
              morder: "5",
              attr: "3",
              attrName: "图文",
              id: "507",
            },
            {
              mname: "选中图标5",
              morder: "6",
              attr: "3",
              attrName: "图文",
              id: "516",
            },
            {
              mname: "默认图标5",
              morder: "6",
              attr: "3",
              attrName: "图文",
              id: "517",
            },
            {
              mname: "焦点图标5",
              morder: "6",
              attr: "3",
              attrName: "图文",
              id: "518",
            },
            {
              mname: "选中图标6",
              morder: "7",
              attr: "3",
              attrName: "图文",
              id: "519",
            },
            {
              mname: "默认图标6",
              morder: "7",
              attr: "3",
              attrName: "图文",
              id: "520",
            },
            {
              mname: "焦点图标6",
              morder: "7",
              attr: "3",
              attrName: "图文",
              id: "521",
            },
          ],
        },
        {
          mname: "默认素材",
          morder: "4",
          attr: "1",
          attrName: "目录",
          id: "463",
          child: [
            {
              mname: "默认视频组",
              morder: "1",
              attr: "48",
              attrName: "视频组",
              id: "464",
              child: [
                {
                  mname: "默认视频目录",
                  morder: "0",
                  attr: "1",
                  attrName: "目录",
                  id: "465",
                  child: [
                    {
                      mname: "默认视频",
                      morder: "0",
                      attr: "37",
                      attrName: "视频",
                      id: "466",
                    },
                  ],
                },
              ],
            },
            {
              mname: " 默认广告",
              morder: "2",
              attr: "39",
              attrName: "广告",
              id: "468",
              child: [
                {
                  mname: "酒店图片",
                  morder: "0",
                  attr: "3",
                  attrName: "图文",
                  id: "469",
                  child: [
                    {
                      mname: "酒店图片详情",
                      morder: "0",
                      attr: "34",
                      attrName: "视图",
                      id: "470",
                    },
                  ],
                },
              ],
            },
            {
              mname: "默认跳转大图广告",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "532",
            },
          ],
        },
        {
          mname: "隐藏",
          morder: "5",
          attr: "1",
          attrName: "目录",
          id: "498",
          child: [
            {
              mname: "约吧",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "456",
            },
            {
              mname: "商城",
              morder: "7",
              attr: "40",
              attrName: "商城",
              id: "7",
            },
          ],
        },
      ],
      query: {
        templateId: "",
      },
      templateList: [
        {
          name: "name",
          id: 2,
        },
      ],
      releaseVisible: false,
      versionForm: {
        versionDesc: "",
        hotelStr: [],
      },
      rules: {
        versionDesc: {
          required: true,
          message: "请输入版本说明",
          trigger: "blur",
        },
        hotelStr: {
          required: true,
          message: "请选择发布酒店",
          trigger: "blur",
        },
      },
    };
  },

  watch: {},
  mounted() {
    this.getTemplateList();
  },
  methods: {
    // 模板变化
    changeTemplate() {
      this.updataLauncher();
    },
    // 获取模板对应信息
    updataLauncher() {
      this.launcherLoading = true;
      let json = {
        templateId: this.query.templateId,
      };
      getLauncher(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.launcherData = res.data.data;
            this.launcherShow = true;
          } else {
            this.$message.warning(res.data.msg);
            this.launcherShow = false;
          }
          this.launcherLoading = false;
        })
        .catch((err) => {
          this.$message.warning("launcher数据加载失败");
          this.launcherShow = false;
          this.launcherLoading = false;
        });
    },
    // 获取筛选模板
    getTemplateList() {
      getTemplateList()
        .then((res) => {
          if (res.data.code == 100000) {
            this.templateList = res.data.data;
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.warning("模板列表获取失败");
        });
    },
    // 展开提交界面
    openRealeaseView() {
      this.releaseVisible = true;
    },
    // 字段拼接
    getString(list) {
      let str = "";
      if (list.length == 0) {
        this.$message.warning("请选择发布酒店");
      } else {
        let arr = list.map((i) => {
          return (
            i.id +
            "@#" +
            i.hotel_contract +
            "@#" +
            i.ipaddr +
            "/" +
            i.hotelName +
            ""
          );
        });
        str = arr.join(",");
      }
      return str;
    },
    // 发布确认
    releaseVisibleTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 确认
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let str = this.getString(this.versionForm.hotelStr);
              const json = {
                versionIntro: this.versionForm.versionDesc,
                selectedIDs: str,
                templateId: this.query.templateId,
              };
              releaseVersion(json).then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("操作成功");
                  this.releaseClose();
                } else {
                  this.$message.warning(res.data.msg);
                }
              });
            })
            .catch(() => {
              this.$message.warning("操作失败");
            });
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
    // 取消发布
    releaseClose() {
      this.versionForm = {
        versionDesc: "",
        hotelStr: [],
      };
      this.releaseVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btnRowbox {
  display: flex;
  align-items: center;
}
.marginLeft {
  margin-left: 20px;
}
</style>
