<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">系统配置</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <div class="title chartTitle blueTitle">系统配置列表</div>
            <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
            <!--<el-form  :model="form" class="demo-form-inline">-->
                <!--<div class="lineContent" >-->
                    <!--<line-title style="padding-top:0">有效激活终端标准设置</line-title>-->
                    <!--<el-row :gutter="20">-->
                        <!--<el-col :span="4">-->
                            <!--<el-form-item>-->
                                <!--<el-input v-model="form.dayOne" >-->
                                    <!--<template slot="append">天内</template>-->
                                <!--</el-input>-->
                            <!--</el-form-item>-->
                        <!--</el-col>-->
                        <!--<el-col :span="4">-->
                            <!--<el-form-item label="任意">-->
                                <!--<el-input v-model="form.dayTwo" >-->
                                    <!--<template slot="append">天有心跳终端</template>-->
                                <!--</el-input>-->
                            <!--</el-form-item>-->
                        <!--</el-col>-->
                    <!--</el-row>-->
                <!--</div>-->
                <!--<div class="lineContent">-->
                    <!--<line-title style="padding-top:0">审批默认同意时间标准设置</line-title>-->
                    <!--<el-row :gutter="20">-->
                        <!--<el-col :span="4">-->
                            <!--<el-form-item>-->
                                <!--<el-input v-model="form.dayThree" >-->
                                    <!--<template slot="append">天</template>-->
                                <!--</el-input>-->
                            <!--</el-form-item>-->
                        <!--</el-col>-->
                    <!--</el-row>-->
                <!--</div>-->
            <!--</el-form>-->
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import commonMeth from '../../api/method'
    export default {
        name: "sysConfig",
        data:()=>({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
            },
            form:{
                dayOne:'',
                dayTwo:'',
                dayThree:'',
            },
            token:'',
            table:{
                tableHeader:[
                    {prop:'name',label:'名称',width:''},
                    {prop:'calculate_min_value',label:'配置项最小值',width:''},
                    {prop:'calculate_max_value',label:'配置项最大值',width:''},
                    {prop:'settlement_value',label:'结算配置项值',width:''},
                ],
                tableList:[ ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'sysConfig',
                option:true,
                loading:true
            },
            funArr:[],
            allData:{}
        }),
        methods:{
            getConfigList(){
                var param=qs.stringify({
                    token:this.token,
                    page:1,
                    limit:5
                });
                this.$http.post(this.global.getActiveConfigList,param).then(res=>{
                    if(res.data.code===100000){
                        // this.selectData.confinumype=res.data.data;
                    }
                })
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getTableSelect(val){
                var operateIds='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+','
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            tableMeth(orderVal){
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.$http.get(this.global.getActiveConfigList,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        limit:this.table.limit,
                        per_page:this.table.limit,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            testReq(){
                this.$http.get('http://www.ssp_backstage.com/api/v1/Activeconfig/getActiveConfigListTest?token=a4c632e4-56c7-45a0-adcc-947330694702&page=1&limit=5',{
                    params:{
                        template_id:'45'
                    }
                }).then(res=>{


                }).catch((res)=>{
                    console.log("catch-res",res);
                })
            },
            testList(){
                this.$http.get('http://www.ssp_backstage.com/api/v1/Visual/getActiveConfigListTest?token=a4c632e4-56c7-45a0-adcc-947330694702&page=1&limit=5').then(res=>{
                    console.log("获取列表",res);

                }).catch((res)=>{
                    console.log("catch-res",res);
                })
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getConfigList();
            this.tableMeth();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
                this.table.option=res.flag;
            });
        }
    }
</script>

<style scoped>
    .lineContent{
        margin-top:20px;
    }


</style>
