<template>
    <div>
        <el-row class="btnRow">
            <el-button class="largeBtn" @click="invertMeth" size="medium" v-if="table.invertSelect">反选</el-button>
            <el-button class="largeBtn" type="primary" @click="confirmMeth('add','添加')" size="medium" v-if="query.type!=='edit'">保存选中数据</el-button>
            <el-button class="largeBtn" type="danger" @click="confirmMeth('del','删除')" size="medium" v-if="query.type==='edit'">删除</el-button>
        </el-row>
        <table-list :table="table" ref="tablePar"
                    @tableMeth="tableMeth"
                    v-loading="table.loading"
                    @tableSelect="getTableSelect">
        </table-list>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "HotelList",
        props:['condition'],
        data:()=>({
            table:{
                tableHeader:[
                    {prop:'hotel',label:'酒店名称',width:'350'},
                    {prop:'hotel_brand',label:'集团品牌',width:''},
                    {prop:'hotel_use_state',label:'客户终端状态',width:''},
                    {prop:'h_province',label:'省份',width:'110'},
                    {prop:'h_city',label:'城市',width:'110'},
                    {prop:'h_county',label:'区县',width:'110'},
                    {prop:'hotel_room',label:'客房总量（间）',width:''},
                    {prop:'n_connected',label:'激活终端数量（台）',width:''},
                    {prop:'addtime',label:'上线时间',width:'180'},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'noCalcIncomeHotelAll',
                name:'hotelManAll',
                option:false,
                loading:true,
                pageShow:false,
                select:true,
                invertSelect:true,
                specialCell:{
                    BD:false,
                    build_person:false,
                    jumpBtn:false,
                }
            },
            operateIds:'',
            operateIdsArr:[],
            query:{},
            token:''

        }),
        methods:{
            tableMeth(orderVal,type) {
                this.table.loading=true;
                if(this.query.type!=='edit'){
                   this.getSearchNoPage();
                }else{
                    this.getUnsettlehotellist();
                }

            },
            getSearchNoPage(){
                var params= {
                    token: this.token,
                    partnerCode: this.query.partnerCode,
                    hotel_brand: this.condition.hotel_brand,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword: this.condition.keyword,
                    sort:'id',
                    order:'DESC',
                };
                this.$http.get(this.global.searchNoPage, {
                    params
                }).then(res => {
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData;
                    }
                    this.table.loading = false;
                }).catch((res) => {
                    this.table.loading = false;
                })
            },
            getUnsettlehotellist(){
                var params= qs.stringify({
                    token: this.token,
                    partnerCode:this.query.partnerCode
                });
                this.$http.post(this.global.getUnsettlehotellist, params).then(res => {
                    if (res.data.code === 100000) {
                        this.table.tableList = res.data.data;
                    } else {
                        this.table.loading = false;
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(() => {
                    this.$message.error(res.data.msg);
                    this.table.loading=false;
                });

            },
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm(`确定${str}？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if(type==='add'){
                            this.operateMeth('add','保存');
                        }else if(type==='del'){
                            this.operateMeth('del','删除');
                        }
                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '请选择酒店',
                        type: 'warning'
                    });
                }
            },
            operateMeth(type,str){
                var url='';
                var param=qs.stringify({
                    token:this.token,
                    hotelIds:this.operateIds,
                });
                if(type==='add'){
                    url=this.global.addHotelUnsettleMovieIncome
                }else{
                    url=this.global.destroyHotelUnsettleMovieIncome
                }
                this.$http.post(url,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success(`${str}成功`);
                        this.query.type==='edit'
                        ?this.getUnsettlehotellist()
                            :this.getSearchNoPage()
                    }else{
                        this.$message.success(res.data.msg);
                    }
                })
            },
            getTableSelect(val){
                var operateIds='';
                this.operateIdsArr=[];
                if(this.query.type==='edit'){
                    val.forEach((item,index)=>{
                        this.operateIdsArr.push(item.manage_hotel_id);
                        operateIds+=item.manage_hotel_id+','
                    });
                }else{
                    val.forEach((item,index)=>{
                        this.operateIdsArr.push(item.id);
                        operateIds+=item.id+','
                    });
                }
                this.operateIds=operateIds.slice(0,-1);
            },
            invertMeth(){
                var arr=[];
                this.table.tableList.filter((item,index)=>{
                    if(this.query.type==='edit'){
                        if(this.operateIdsArr.indexOf(item.manage_hotel_id)===-1){
                            arr.push(item);
                        }
                    }else{
                        if(this.operateIdsArr.indexOf(item.id)===-1){
                            arr.push(item);
                        }
                    }
                });
                this.toggleSelection(arr);
            },
            toggleSelection(rows) {
                if (rows) {
                    this.$refs.tablePar.$refs.table.clearSelection();
                    rows.forEach(row => {
                        this.$refs.tablePar.$refs.table.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.tablePar.$refs.table.clearSelection();
                }
            },
            getEditTableData(){
                if(this.query.type==='edit'){
                    this.table.tableHeader=[
                        {prop:'hotel_name',label:'酒店名称',width:'350'},
                        {prop:'province',label:'省份',width:'110'},
                        {prop:'city',label:'城市',width:'110'},
                        {prop:'county',label:'区县',width:'110'},
                        {prop:'effective_time',label:'生效时间',width:''},
                    ];
                    this.table.fromUrl='';
                    this.table.name='noCalcIncomeHotel';
                }
            }
        },
        mounted(){
            this.query=this.$route.query;
            this.token=sessionStorage.getItem('token');
            this.getEditTableData();
            this.tableMeth();
        }
    }
</script>

<style scoped>
    .btnRow{
        display:flex;
        /*justify-content: space-between;*/
    }
</style>
