import request from "@/api/request";
import qs from "querystring";

// 属性配置列表
export function getList(param) {
  return request({
    url: `v2/Manageattribute/getList`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 属性配置新增
export function add(param) {
  return request({
    url: `v2/Manageattribute/addAttribute`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 属性配置编辑
export function edit(param) {
  return request({
    url: `v2/Manageattribute/editAttribute`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 属性配置删除
export function del(param) {
  return request({
    url: `v2/Manageattribute/delAttribute`,
    method: "post",
    data: qs.stringify(param),
  });
}