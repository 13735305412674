<template>
  <div style="padding:0 15px">

    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">内容监管</span>
        <span slot="third">文化巡检</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">文化巡检列表</div>
      <!-- 操作栏 -->
      <div class="btnRow">
        <el-input style="width:15%;margin-right:10px" v-model="form.name" placeholder="请输入酒店关键字"></el-input>

        <el-date-picker style="width:15%;margin-right:10px" v-model="form.time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>

        <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
        <el-button type="primary" class="largeBtn" @click="reset">重 置</el-button>
        <!-- <el-button type="danger" class="largeBtn" @click="del">删 除</el-button> -->
      </div>
      <!-- 操作栏 -->
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />

    </el-card>

    <!-- 表格 -->

  </div>
</template>
<script>
export default {
  name: "hotel",

  data() {
    return {
      bread: {
        level: 3,
      },
      picName: "",

      // 表格数据信息
      tableData: {
        tableList: [
          {
            account_manager: "唐萍",
            account_manager_id: "243",
            address: "沈阳市皇姑区北陵大街45-9号",
            addtime: "2023-07-03 09:31:58",
            allot_channel_code: "HCC001673",
            allot_channel_code_text: "沈阳广电",
            baidu_address: "沈阳市皇姑区北陵大街45-9号",
            build_person: "倪德前",
            building_state: "3",
            channel_code: "HCC001673",
            channel_code_text: "沈阳广电",
            check_state_text: "未验收",
            citycode: "210105",
            cus_service_personnel: "涂莉珍",
            cus_service_personnel_id: "242",
            dar: "0.00",
            dau: 0,
            day_active_num: null,
            demand: null,
            device_info: "智能电视::海信电视:1",
            groupId: null,
            group_code: null,
            h_factory_all: "",
            h_platform_all: "智能电视::海信电视:1",
            hotel: "乐游辽宁门店",
            hotelName: "hra06578",
            hotel_brand: "单体",
            hotel_city: "沈阳市",
            hotel_contract: "38bdc6676c87417f837cb45538251e3b",
            hotel_county: "皇姑区",
            hotel_province: "辽宁省",
            hotel_room: "9",
            hotel_store_id: null,
            hotel_template_id: "2",
            hotel_use_state: "12",
            hotel_use_state_text: "测试演示",
            id: "277526",
            ipaddr: "jd.ctlife.tv:8000",
            isSkip: 1,
            n_daily_active: "0",
            n_rooms_connected: "1",
            original_name: null,
            skipAddress: "jd.ctlife.tv:8000/hra06578/admin",
            tech_service_personnel: "张广彬",
            tech_service_personnel_id: "324",
             contract_end: "2024-07-03 09:30:15",
                  contract_start: "2023-07-03 00:00:00",
            total_num: "9",
            res: {
              data: {
                code: 100000,
                msg: "成功",
                time: "1688353061",
                data: {
                  account_manager: null,
                  account_manager_id: "243",
                  addadminId: "0",
                  address: "沈阳市皇姑区北陵大街45-9号",
                  addtime: "2023-07-03 09:31:58",
                  allot_channel_code: "HCC001673",
                  baidu_address: "沈阳市皇姑区北陵大街45-9号",
                  baidu_hotel_name: "辽宁省政府",
                  baidu_uid: "a02b9e23b6c80e4f4207c1db",
                  bu_status: "2023-07-01",
                  build_person: "倪德前",
                  build_person_id: "24",
                  building_md5: "",
                  building_project_name: "hra01471",
                  building_state: "3",
                  business_district: "",
                  channel_code: "HCC001673",
                  channel_link_person: null,
                  channel_link_phone: null,
                  channel_name: "沈阳广电",
                  check_date: null,
                  check_state: "0",
                  city: "沈阳市",
                  citycode: "210105",
                  clientNo: "0",
                  clientNoPc: "0",
                  clientNoTv: "0",
                  contact_info: "",
                  contract_365: null,
                  contract_end: "2024-07-03 09:30:15",
                  contract_start: "2023-07-03 00:00:00",
                  county: "皇姑区",
                  cpu: null,
                  create_time: "2023-07-03 09:31:58",
                  cus_service_personnel: null,
                  cus_service_personnel_id: "242",
                  deleted_time: null,
                  demand: null,
                  device_num: null,
                  english_name: null,
                  finance_code: "HCC000042",
                  groupId: "1",
                  group_code: null,
                  group_hotel_name: "",
                  h_ota_price: "555",
                  hard_factory: null,
                  hostname: null,
                  hotel: "乐游辽宁门店",
                  hotelName: "hra06578",
                  hotel_address: "沈阳市皇姑区北陵大街45-9号",
                  hotel_alias: "默认",
                  hotel_brand: "1",
                  hotel_charger: "辽宁",
                  hotel_charger_phone: "1",
                  hotel_city: "沈阳市",
                  hotel_contract: "38bdc6676c87417f837cb45538251e3b",
                  hotel_county: "皇姑区",
                  hotel_info_hotel_sell_boss: null,
                  hotel_key: "hra06578",
                  hotel_level: "2",
                  hotel_platform: null,
                  hotel_project_num: null,
                  hotel_province: "辽宁省",
                  hotel_quality: "1",
                  hotel_room: "9",
                  hotel_server_ip: "user.ctlife.tv",
                  hotel_server_key: "hra06578",
                  hotel_store_id: null,
                  hotel_template_id: "2",
                  hotel_try_end: "2023-12-31 00:00:00",
                  hotel_try_start: "2023-07-03 00:00:00",
                  hotel_use_state: "12",
                  id: "277526",
                  ipaddr: "jd.ctlife.tv:8000",
                  is_check_coordinate: "0",
                  is_coop_offline: "0",
                  is_ctrip: "0",
                  is_moive_settlement: "1",
                  is_os_sell: "0",
                  is_settle_online: "1",
                  is_settle_service: "1",
                  is_signed: "1",
                  juping_type: "0",
                  ka_type: "6",
                  keyStates: "0",
                  lat: 41.84188611,
                  lng: 123.43804781,
                  maintain_name: "辽宁",
                  maintain_phone: "1",
                  mode_state: "1,",
                  network_condition: null,
                  network_mode: null,
                  network_remarks: null,
                  online_channel_code: null,
                  operationStatus: "test",
                  original_name: null,
                  ota_max_price: "0.00",
                  ota_min_price: "0.00",
                  pms_brand: null,
                  power_state: "1",
                  program_name: "hra06578",
                  project_act_num: null,
                  provider: "1",
                  province: "辽宁省",
                  rstate: null,
                  sell_goods: "",
                  server_ip: "jd.ctlife.tv ",
                  settle_type: null,
                  signal_type: null,
                  spare_url: null,
                  starlevel: "11",
                  state: "1",
                  stime: "0000-00-00",
                  supplier_id: "7",
                  synUpdateState: "11",
                  tech_service_personnel: null,
                  tech_service_personnel_id: "324",
                  template_name: "临时",
                  template_program_name: "hra01471",
                  ter_store_type: "2",
                  ter_type: null,
                  terminal_customer_from: "6",
                  third_store_id: null,
                  total_num: "9",
                  update_time: "2023-07-03 10:08:10",
                  vpnstate: "0",
                  hotel_use_state_text: "测试演示",
                  is_visual: 1,
                  template_type: "ehotel",
                  uk_number: "",
                  uk_code: "",
                  project_code: "7400",
                  upgrade_password: "7177",
                  hotel_template_name: "标准模板",
                  supplier_name: "极光TV",
                },
                auth: {
                  type: "htrip",
                  register_review_status: 1,
                  ca_review_status: 1,
                  user_name: "Lucien",
                  register_message: "",
                },
              },
              status: 200,
              statusText: "OK",
              headers: {
                "content-type": "application/json; charset=utf-8",
              },
              config: {
                url: "v1/hotel/view",
                method: "get",
                headers: {
                  Accept: "application/json, text/plain, */*",
                },
                params: {
                  token: "42a1f73d-ddb5-4fa2-a80b-142a7c1d318a",
                  id: "277526",
                  hotel_project: "hra06578",
                },
                baseURL: "http://sspadmin-f.ctlife.tv:8000//api",
                transformRequest: [null],
                transformResponse: [null],
                timeout: 0,
                xsrfCookieName: "XSRF-TOKEN",
                xsrfHeaderName: "X-XSRF-TOKEN",
                maxContentLength: -1,
                maxBodyLength: -1,
                transitional: {
                  silentJSONParsing: true,
                  forcedJSONParsing: true,
                  clarifyTimeoutError: false,
                },
              },
              request: {},
            },
            type: "edit",
          },
        ],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "酒店名称",
            prop: "hotel",
            minWidth: "300",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "60",
            type: "button",
            prop: "num",
            // 详情
            info: (item) => {
              this.$router.push({
                path: "./hotelinfo",
                query: item,
              });
            },
          },

          {
            label: "品牌",
            prop: "hotel_brand",
            minWidth: "80",
            type: "text",
          },
          {
            label: "省份",
            prop: "hotel_province",
            minWidth: "120",
            type: "text",
          },
          {
            label: "城市",
            prop: "hotel_city",
            minWidth: "120",
            type: "text",
          },
          {
            label: "县/区",
            prop: "hotel_county",
            minWidth: "120",
            type: "text",
          },
          {
            label: "客房数量",
            prop: "hotel_room",
            minWidth: "100",
            type: "text",
          },
          {
            label: "激活终端数量",
            prop: "n_rooms_connected",
            minWidth: "100",
            type: "text",
          },
          {
            label: "合约开始时间",
            prop: "contract_start",
            minWidth: "300",
            type: "text",
          },
          {
            label: "合约结束时间",
            prop: "contract_end",
            minWidth: "300",
            type: "text",
          },
        ],
      },

      // 表单字段
      form: {
        name: "",
        time: "",
      },
      checkList: [],
      n: 0,
    };
  },

  methods: {
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      //   this.getTableData(
      //     this.query.hotelName,
      //     this.tableData.limit,
      //     this.tableData.page
      //   );
    },
    reset() {
      this.form = {
        name: "",
        time: "",
      };
    },

    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 选中项处理
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return i.id;
      });
    },
    // 获取表格数据
    getTableData(database, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        name: this.picName,
        page: p ? p : 1,
        limit: s ? s : 10,
      };
      getBasicPicture(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data.items;
            // 字段显示处理

            // 组件数据总数
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
</style>
