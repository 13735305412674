<template>
  <div v-loading='loading' class="scalBox">
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">批量更新</span>
        <span slot="third">{{isEdit?'批量更新详情':'新建批量更新'}}</span>
      </top-bread>
    </div>
    <el-card>
      <div class=" btnRowbox" style="padding: 0 15px">
        <span>模板:</span>
        <el-select :disabled="isEdit" style="width:300px" clearable v-model="template" value-key="id" placeholder="选择模板" class="hotelBrand fullSelect marginLeft" filterable @change="changeTemplate">
          <el-option v-for="item in templateList" :key="item.id" :label="item.t_name" :value="item">
          </el-option>
        </el-select>
        <!-- <el-button type="primary" class="largeBtn marginLeft" v-if="!isEdit" @click="changeTemplate">搜 索</el-button> -->
      </div>
      <visiual-setting @defaultdata='getDefaultdata' ref="visiualSetting" :notPage='true' :isEdit="isEdit"> </visiual-setting>

      <div class="hotelSearchBox">
        <div class="checkCondition" v-loading="hotelLoading">
          <!-- 酒店选择界面 -->
          <div class="checkConditionBox address">
            <p class="title"><span>酒店</span><span style="margin-right:10px">{{checkedHotel.length+'/'+hotels.length}}</span></p>
            <div class="checkList">
              <div class="searchBox">
                <el-select :disabled="isEdit" style="width: 25%;margin-right:10px " v-model="province" placeholder="省份" @change="provinceChange" collapse-tags clearable class="fullSelect" filterable>
                  <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
                  </el-option>
                </el-select>
                <el-select :disabled="isEdit" style="width: 25%;margin-right:10px " v-model="city" placeholder="城市" clearable class=" fullSelect" filterable collapse-tags>
                  <el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
                  </el-option>
                </el-select>
                <el-input class="searchInput" :disabled="isEdit" v-model="hotel_name" style="width: 25%;margin-right:10px " placeholder="酒店名称关键字"></el-input>
                <el-button class="trueBtn" type="primary" v-if="!isEdit" @click="getHotelList">搜索</el-button>
                <el-button class="cancelBtn" @click="recetHotelList" v-if="!isEdit">重置</el-button>
              </div>
              <div style="margin: 10px 0"></div>
              <el-checkbox :disabled="isEdit" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <el-checkbox-group v-model="checkedHotel" @change="checkedHotelChange">
                <div class="checkBoxShow">
                  <template v-for="hotels in hotels">
                    <el-checkbox :disabled="isEdit" :label="hotels.hotelName" :key="hotels.hotelName">
                      <span> {{ hotels.hotel }}</span>
                      <span v-if="hotels.hotel_alias!='默认'"> {{"("+hotels.hotel_alias+")"}}</span>
                    </el-checkbox>
                  </template>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <!-- 酒店选择界面 -->
        </div>
        <!-- 已选酒店 -->
        <div class="showCondition" v-loading="hotelLoading">
          <div class="checkConditionBox">
            <p class="title"><span>已选定向</span>
              <span>
                <span style="margin-right:10px;font-size:12px">{{showHotelList.hotels.length+'家'}}</span>
                <span class="clearAll" v-if="!isEdit" @click="clearAll">清空</span>
              </span>
            </p>
            <div class="searchBox" style="padding :10px">
              <el-input class="searchInput" v-model="check_hotel_name" style="width: 25%;margin-right:10px " placeholder="酒店名称关键字"></el-input>
              <!-- v-if="!isEdit"7 -->
              <el-button class="trueBtn" type="primary" @click="checkSearch">搜索</el-button>
              <el-button class="cancelBtn" @click="recetcheckList">重置</el-button>
            </div>
            <div class="contentShow">
              <div>
                <span style=" position: relative" class="checkCard" v-for="item in showHotelList.showhotels" :key="item.hotelName">
                  <span> {{ item.hotel }}</span>
                  <span v-if="item.hotel_alias!='默认'"> {{"("+item.hotel_alias+")"}}</span>
                  <i class="el-icon-close" v-if="!isEdit" @click="delCheckHotel(item)"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- 已选酒店 -->
      </div>
      <div class="btnRow">
        <el-button class="trueBtn largeBtn" type="primary" v-if="!isEdit" @click="toRelease">发 布</el-button>
        <!-- <el-button class="largeBtn marginLeft back" @click="backLast">返 回</el-button> -->
      </div>
    </el-card>
    <el-dialog title="发布说明" :visible.sync="releaseVisible" :before-close="releaseClose" width="500px" :append-to-body="true">
      <el-form ref="versionForm" :rules="rules" :model="versionForm" label-width="100px">
        <el-form-item label="版本说明" prop='versionDesc'>
          <el-input v-model="versionForm.versionDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" @click="releaseVisibleTrue('versionForm')">发 布</el-button>
        <el-button class="cancelBtn" @click="releaseClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTemplateList, getTemplateVersion } from "./api";
import { setLauncher } from "@/api/visual.js";
export default {
  name: "addBatchUpdata",
  data() {
    return {
      bread: {
        level: 3,
      },
      loading: false,
      isEdit: true,
      template: "",
      templateList: [
        { t_name: "亚朵批量更新测试酒店", hotelName: "hra05937", brand_id: 2 },
      ],
      releaseVisible: false,
      versionForm: {
        versionDesc: "",
        hotelStr: [],
      },
      rules: {
        versionDesc: {
          required: true,
          message: "请输入版本说明",
          trigger: "blur",
        },
        hotelStr: {
          required: true,
          message: "请选择发布酒店",
          trigger: "blur",
        },
      },

      checkAll: false,
      isIndeterminate: true,
      provinceList: [],
      cityList: [],
      province: null,
      city: null,
      hotel_id: "",
      hotel_name: "",
      checkedHotel: [],
      checkedHotelAll: [],
      hotels: [],
      checkHotelList: [],
      showHotelList: {
        hotels: [],
        hotelsId: [],
        showhotels: [],
      },
      hotelLoading: false,
      token: "",
      check_hotel_name: "",
      brandList: [],
      query: {},
      json: {},
    };
  },
  mounted() {
   
    this.token = sessionStorage.getItem("token");
    this.query = this.$route.query;
    if (this.query.isEdit == 1) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    if (this.query.item) {
      this.init(this.query.item.id);
      this.template = {
        t_name: this.query.item.t_name,
        id: this.query.item.template_id,
      };
      //  this.$refs.visiualSetting.init(this.query.item.id);
    }
    this.getProvince();
    this.getTemplateAllList();
  },
  methods: {
    toRelease() {
      if (this.showHotelList.hotels.length == 0) {
        this.$message.warning("请选择发布酒店列表");
        return;
      }
      if (!this.template.id) {
        this.$message.warning("请选择品牌");
        return;
      }
      this.$refs.visiualSetting.defaultData();
    },
    getDefaultdata(data, flag) {
      if (flag) {
        this.versionForm.versionDesc = "";
        this.releaseVisible = true;
        this.json = data;
      } else {
        this.$message.warning("素材内容未更改，无法进行发布操作");
        return;
      }
    },
    init(id) {
      getTemplateVersion({ version_id: id })
        .then((res) => {
          if (res.data.code == 100000) {
            this.intData(res.data.data.hotel_data);
            this.$refs.visiualSetting.init(res.data.data.data_arr);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    getTemplateAllList() {
      getTemplateList().then((res) => {
        let currentUrl = this.$route.path;
        if (currentUrl == "/addBatchUpdata") {
          this.templateList = res.data.data;
        } else {
          this.templateList = res.data.data.filter((i) => {
            if (i.id == 145) return i;
          });
          this.template = this.templateList[0];
          this.changeTemplate();
        }
      });
    },
    backLast() {
      let currentUrl = this.$route.path;
      if (currentUrl == "/addBatchUpdata") {
        this.$router.push("/batchUpdata");
      } else {
        this.$router.push("/onePage/batchUpdata");
      }
      // this.$router.go(-1);
    },
    checkSearch() {
      this.showHotelList.showhotels = this.showHotelList.hotels.filter((i) => {
        if (i.hotel.indexOf(this.check_hotel_name) != -1) {
          return i;
        }
      });
    },
    recetcheckList() {
      this.check_hotel_name = "";
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
    },
    provinceChange() {
      this.city = null;
      this.getCity(this.province.value);
    },
    getProvince() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getProvince, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.provinceList = res.data.data;
            }
            resolve();
          });
      });
    },
    getCity(prov_code) {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.cityList = res.data.data;
            }
            resolve();
          });
      });
    },
    // 清空
    clearAll() {
      this.checkedHotel = [];
      this.checkAll = false;
      this.showHotelList = {
        hotels: [],
        hotelsId: [],
        showhotels: [],
      };
    },
    // 数据回显写入
    intData(data) {
      this.showHotelList.hotels = data;
      this.showHotelList.hotelsId = data.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = data;
      this.checkHotelList = this.showHotelList.hotels;
      this.checkedHotel = this.showHotelList.hotelsId;
      this.hotelLoading = false;
    },

    // 已选酒店删除
    delCheckHotel(item) {
      this.showHotelList.hotels = this.showHotelList.hotels.filter((i) => {
        if (i.hotelName != item.hotelName) {
          return i;
        } else {
          this.checkHotelList = this.checkHotelList.filter((i) => {
            if (i.hotelName != item.hotelName) {
              return i;
            }
          });
        }
      });
      this.showHotelList.hotelsId = this.showHotelList.hotels.map((i) => {
        return i.hotelName;
      });
      this.checkedHotel = this.checkHotelList.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
    },
    handleCheckAllChange(val) {
      if (val) {
        this.checkedHotelAll.forEach((i) => {
          if (this.checkedHotel.indexOf(i) == -1) {
            this.checkedHotel.push(i);
          }
        });
      } else {
        this.checkedHotel = this.checkedHotel.filter((i) => {
          if (this.checkedHotelAll.indexOf(i) == -1) {
            return i;
          }
        });
      }
      this.checkHotelList = this.hotels.filter((i) => {
        if (this.checkedHotel.includes(i.hotelName)) {
          return i;
        }
      });
      let list = this.hotels.map((i) => {
        return i.hotelName;
      });
      var arr1 = this.showHotelList.hotels.filter((i) => {
        if (list.indexOf(i.hotelName) == -1) {
          return i;
        }
      });
      let arr = this.checkHotelList;
      this.showHotelList.hotels = [...arr1, ...arr];
      this.showHotelList.hotelsId = this.showHotelList.hotels.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
      this.isIndeterminate = false;
    },
    //  酒店选择
    checkedHotelChange(value) {
      let checklist = this.checkedHotel.filter((i) => {
        if (this.checkedHotelAll.indexOf(i) != -1) {
          return i;
        }
      });
      if (checklist.length == this.checkedHotelAll.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.checkHotelList = [];
      this.checkHotelList = this.hotels.filter((i) => {
        if (this.checkedHotel.includes(i.hotelName)) {
          return i;
        }
      });
      let list = this.hotels.map((i) => {
        return i.hotelName;
      });
      var arr1 = this.showHotelList.hotels.filter((i) => {
        if (list.indexOf(i.hotelName) == -1) {
          return i;
        }
      });
      let arr = this.checkHotelList;
      this.showHotelList.hotels = [...arr1, ...arr];
      this.showHotelList.hotelsId = this.showHotelList.hotels.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
      //   this.showHotelList.hotels.
    },
    // 重置酒店列表
    recetHotelList() {
      this.province = null;
      this.city = null;
      this.hotel_name = "";
      this.getHotelList();
    },
    // 获取酒店列表
    getHotelList() {
      this.hotelLoading = true;
      var params = {
        token: this.token,
        keyword: this.hotel_name,
        hotelName: this.template.hotelName,
        citycode: this.city ? this.city.value : "",
        province: this.province ? this.province.label : "",
        city: this.city ? this.city.label : "",
        // sort: "id",
        // order: "DESC",
        // screenType: "self",
        // refresh: false,
      };
      this.$http
        .get(this.global.getHotelSearch, {
          params,
        })
        .then((res) => {
          if (res.data.code === 100000 && res.data.data) {
            var tableData = res.data.data;
            let arr = tableData.filter((i) => {
              if (i.hotel_use_state != 2) {
                return i;
              }
            });
            this.hotels = arr;
            this.checkedHotelAll = this.hotels.map((i) => {
              return i.hotelName;
            });
            let checklist = this.checkedHotel.filter((i) => {
              if (this.checkedHotelAll.indexOf(i) != -1) {
                return i;
              }
            });
            if (checklist.length == this.checkedHotelAll.length) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
            this.hotelLoading = false;
          } else {
            this.$message.warning(res.data.msg);
            this.hotelLoading = false;
          }
        });
    },

    changeTemplate() {
      this.$refs.visiualSetting.getPageData(this.template.id);
      this.showHotelList.hotels = [];
      this.showHotelList.hotelsId = [];
      this.showHotelList.showhotels = [];
      this.checkHotelList = [];
      this.checkedHotel = [];
      this.checkAll = false;
      this.getHotelList();
    },
    // 展开提交界面
    openRealeaseView() {
      this.releaseVisible = true;
    },
    // 取消发布
    releaseClose() {
      this.versionForm = {
        versionDesc: "",
        hotelStr: [],
      };
      this.releaseVisible = false;
    },
    // 发布确认
    releaseVisibleTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认发布更新？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.loading = true;
            this.releaseVisible = false;
            let releaseData = JSON.parse(JSON.stringify(this.json));
            let auth = JSON.parse(sessionStorage.getItem("auth"));
            let arr = this.showHotelList.hotels.map((i) => {
              let obj = {
                hotel: i.hotel,
                hotel_contract: i.hotel_contract,
                ipaddr: i.ipaddr,
                hotelName: i.hotelName,
                hotel_alias: i.hotel_alias,
                id: i.id,
              };
              return obj;
            });
            let obj = {
              data: JSON.stringify(releaseData),
              database: "",
              intro: this.versionForm.versionDesc,
              template_id: this.template.id,
              module: "",
              roomStr: "",
              use_name: auth.use_name,
              hotelStr: JSON.stringify(arr),
            };
            setLauncher(obj)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("发布成功");
                  // this.$router.push({ path: `/batchUpdata` });
                } else {
                  this.$message.warning(res.data.msg);
                  this.loading = false;
                }
              })
              .catch((err) => {
                this.$message.warning("发布失败");
                this.loading = false;
              });
          });
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.btnRowbox {
  display: flex;
  align-items: center;
  position: relative;
  .back {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
.marginLeft {
  margin-left: 20px;
}

.hotelSearchBox {
  width: 100%;
  height: 400px;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.checkCondition {
  width: 50%;
  height: 100%;
  border: 1px solid #ebeef5;
  display: flex;
  justify-content: flex-start;
  margin-right: 16px;
}
.showCondition {
  width: calc(50% - 16px);
  height: 100%;
  border: 1px solid #ebeef5;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-top: 1px solid #e4e7ed !important;
  border-bottom: 1px solid #e4e7ed !important;
  border-radius: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: 1px solid #e4e7ed !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  border-right: 1px solid #e4e7ed !important;
}
.checkCondition .checkConditionBox {
  flex: 1;
}

.checkCondition .address:last-child {
  border-right: none;
}
.checkCondition .title,
.showCondition .title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ebeef5;
  background: #f7f8fa;
  color: #222;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title span:first-child {
  color: #596080;
  font-size: 16px;
}
.title span:last-child {
  color: #8a8c99;
  font-size: 12px;
}
.checkList {
  padding: 10px;
  height: 340px;
}
.checkBoxShow {
  height: 270px;
  overflow: hidden;
  overflow-y: visible;
}
.el-checkbox {
  display: block;
}
.showCondition .checkConditionBox {
  height: calc(100%);
}
.showCondition .contentShow {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: calc(100% - 130px);
  overflow: hidden;
  overflow-y: visible;
}
.showCondition .contentShow > div {
  width: 100%;
}
.checkType {
  margin-right: 5px;
  font-size: 14px;
}
.checkCard {
  display: inline-block;

  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ebebed;
  background: #f8f9fa;
  color: #596080;
  padding: 0 20px 0 10px;
  border-radius: 3px;
}
::v-deep .el-checkbox__label {
  width: 100%;
}
::v-deep .el-checkbox:last-of-type {
  margin-right: 30px !important;
}
.openPlace {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.openPlace i {
  font-size: 14px;
  cursor: pointer;
}
.openPlace i:hover {
  color: #658bff;
}
.el-radio {
  display: block;
}
.greenBtn {
  width: 15%;
  max-width: 100px;
  background: #658bff;
  border: 1px solid #658bff;
  color: #fff;
}
.whiteBtn {
  width: 15%;
  max-width: 100px;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.el-icon-close {
  position: absolute;
  top: 50%;
  right: -2px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.el-checkbox {
  margin-bottom: 10px;
}
.clearAll {
  border: 1px solid #ebebed;
  margin-right: 10px;
  height: 28px;
  padding: 0 10px;
  line-height: 28px;
  color: #596080 !important;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
.btnRow {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>