<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span v-if='!query' slot="second">系统设置</span>
        <span v-if='!query' slot="third">标签管理</span>
        <span v-if='query' slot="second">标签管理</span>
      </top-bread>
      <fold-top-search v-if="activeName=='list'" class="searchBtn" @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-tabs style="margin-left:20px" v-model="activeName">
      <el-tab-pane label="标签列表" name="list">
        <label-list-page :funArr="funArr" ref="labellistpage"> </label-list-page>
      </el-tab-pane>
      <el-tab-pane label="分类列表" name="type">
        <label-type-page :funArr="funArr" @loadclassdata="loadClassData"> </label-type-page>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import labelTypePage from "./components/labelTypePage.vue";
import labelListPage from "./components/labelListPage.vue";
import commonMeth from "@/api/method";
export default {
  name: "labelList",
  components: {
    labelTypePage,
    labelListPage,
  },
  data() {
    return {
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },

      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      activeName: "list",
      funArr: [],
      query: null,
    };
  },
  mounted() {
    this.query = sessionStorage.getItem("apaasData");
    if (this.query) {
      this.bread.level = 3;
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      commonMeth.getTreeData().then((res) => {
        this.funArr = res.funArr;
      });
    }
  },
  methods: {
    // 筛选
    getFoldMeth(val) {
      this.fold = val;
      this.$refs.labellistpage.fold = val;
    },
    // 搜索
    loadClassData() {
      this.$refs.labellistpage.loadData();
    },
  },
};
</script>
<style scoped>
.el-tabs ::v-deep .el-tabs__item {
  list-style: none;
  font-size: 16px;
  color: #303030;
}
.el-tabs /deep/ .el-tabs__active-bar {
  width: 64px !important;
}
.outTopBox {
  height: 58px;
}
</style>