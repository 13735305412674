<template>
    <div>
        <el-col :span="span">
            <div class="grid-content bg-purple outBox">
                <div class="onePart">
                    <div class="leftBox">
                        <div class="numTitle noWrap">
                            <slot name="numTitle" class="noWrap"></slot>
                            <slot name="time" class="numTitle"  v-if="type!=='timeCenter'"></slot>
                        </div>
                    </div>
                    <div v-if="type==='timeCenter'" class="timeText timePart" >
                        <slot name="time"></slot>
                    </div>
                </div>
                <div  :class="idd=='idd'?'numOutBox_text':'numOutBox'">
                    <span class="numBox">
                             <span class="indexNum marginRight">
                            <slot name="num"></slot>
                        </span>
                        <span class="unit">
                            <slot name="unit"></slot>
                        </span>
                    </span>
                    <span class="numBox numBox1">
                            <span class=" marginRight">
                            <slot name="refund"></slot>
                        </span>
                        <span >
                            <slot name="many"></slot>
                        </span>
                    </span>
                </div>
                <div class="twoPart" v-if="idd!=='idd'">
                    <div class="item noWrap" >
                        <span class="littleTitle itemTitle">极光TV</span>
                        <span class="minFontNum">
                            <slot name="first"></slot>
                        </span>
                    </div>
                    <div class="item noWrap">
                        <span class="littleTitle itemTitle">风霆迅</span>
                        <span class="minFontNum">
                            <slot name="two"></slot>
                        </span>
                    </div>
                    <div class="item noWrap">
                        <span class="littleTitle itemTitle">爱奇艺</span>
                        <span class="minFontNum">
                            <slot name="three"></slot>
                        </span>
                    </div>
                </div>
                <div class="twoPart" v-if="threePartType==='setAccount'">
                    <div class="item">
                        <span class="timeText itemTitle">已结算</span>
                        <span class="minFontNum">
                            <slot name="first"></slot>
                        </span>
                    </div>
                    <div class="item">
                        <span class="timeText itemTitle">未结算</span>
                        <span class="minFontNum">
                            <slot name="two"></slot>
                        </span>
                    </div>
                    <div class="item">
                        <span class="timeText itemTitle">可结算</span>
                        <span class="minFontNum">
                            <slot name="three"></slot>
                        </span>
                    </div>
                </div>
            </div>
        </el-col>
    </div>
</template>

<script>
    // greenColor orangeColor blueColor   redColor
    export default {
        name: "QuarterRow",
        props:{
            span:{
                default:6
            },
            type:{
                default:'timeRight'
            },
            threePartType:{
                default:'platform'
            },
            color:{},
            iconShow:{
                default:false
            },
            idd:{
                idd:'idd'
            }
        },
        data: () => ({
        }),
    }
</script>

<style scoped lang="scss">
    .el-col-5{
        width:20%!important;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .borderBox.rowBox .grid-content{
        /*border:1px solid #ddd;*/
    }

    .vertical{
        .grid-content{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            .numTitle{
                margin-bottom:20px;
                display:flex;
                justify-content: space-between;
                color:#a3a7af;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
        .bg-purple {
            /*background: #d3dce6;*/
            background: #fff;
        }
        .grid-content {
            border-radius: 4px;
            padding:20px 20px;
        }
    }
    .indexIcon{
        font-size:40px;
    }
    .indexIcon.green{
        color:#40c2ad;
    }
    .indexIcon.orange{
        color:#ff9846;
    }
    .indexIcon.blue{
        color:#445eee;
    }
    .indexIcon.red{
        color:#f12525;
    }
    .numBox{
        display:flex;
        align-items: flex-end;
        float: left;
    }
    .numBox1{
        margin-top: 8px;
           float: right;
             display:flex;
        align-items: flex-end;
        color: #aaa;
    }
    .indexNum.marginRight{
        margin-right:10px;
        font-size: 28px;
    }
    .unit{
        margin-bottom:5px;
    }
    .bottomBox{
        display:flex;
        justify-content: space-between;
    }
    .outBox{
        box-shadow:0 0 11px 2px rgba(94,151,255,0.15);
    }
    .timeText{
        margin-left:-5px;
        font-size:12px;
        color:#a3a7af;
    }
    .onePart{
        display:flex;
        justify-content: space-between;
        /*margin-bottom:10px!important;*/
        /*margin-bottom:35px!important;*/
    }
    .twoPart{
        display:flex;
        justify-content: space-between;
    }
    .itemTitle{
        margin-right:6px;
    }
    .vertical .grid-content .numTitle{
        margin-bottom:0!important;
    }
    .leftBox{
        align-items: center;
        margin-bottom: 20px;
    }
    .littleTitle{
        margin-bottom:20px;
        font-size:12px;
        color:#a3a7af;
    }
    .noWrap{
        white-space: nowrap!important;
    }
    .numOutBox_text{
        padding-top: 10px;
    }
    .numOutBox{
        padding:20px 0;
    }
</style>
