import request from './request';

//  上传汇款单
export function uploadWithdrawPic(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/updateInfo`,
        method: 'post',
        data: param,
    });
}
//  修改汇款状态
export function updatePayAll(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/updateAll`,
        method: 'post',
        data: param,
    });
}

//  查询扣款列表详情
export function getDeductedList(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/deductedList?` + param,
        method: 'get',

    });
}
//  提现统计数据汇总
export function getStatisticalAll(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/statisticalAll`,
        method: 'post',
        data: param,
    });
}
//  提现统计  管理合伙人提现列表获取
export function getpartnerListAll(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/partnerList`,
        method: 'post',
        data: param,
    });
}
//  提现统计  扣款操作
export function createDeducted(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/createDeducted`,
        method: 'post',
        data: param,
    });
}
// 获取扣款详情通过data
export function getDeductedEventList(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/dateToDeductedList?` + param,
        method: 'GET',
        // data: param,
    });
}
// 获取提现申请通过data
export function getPartnerWithdrawEventList(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/dateToPartnerWithdraw?` + param,
        method: 'GET',
        // data: param,
    });
}

//  字典表列表  tab类别  
export function getTabList(param) {
    return request({
        url: `v1/sysdic/list?parent_id=001`,
        method: 'GET',
    });
}