<template>
    <div class="chartBox">
        <div class="rankTips">
            <div>时间：<slot name="time"></slot></div>
            <div class="unit">单位：<slot name="unit"></slot></div>
        </div>
        <div class="rankChart" :class="getChartHeight(rankChartData.seriesData.length)" id="rankChart" v-if="rankChartData.seriesData.length!==0"></div>
        <span v-if="rankChartData.seriesData.length===0" class="noData">暂无数据</span>
    </div>
</template>

<script>
    import echarts from 'echarts'
    export default {
        name: "RankChart",
        props:{
            id:{},
            rankChartData:{
                type:Object,
                default:()=>{

                }
            },
        },
        data:()=>({

        }),
        methods:{
            getRankChart(yData,seriesData){
                var chart = echarts.init(document.getElementById('rankChart'));
                var copyData=[...seriesData];
                var maxData=[];
                var maxDataSignal=this.getMaxData(copyData);
                seriesData.forEach((item,indx)=>{
                    maxData.push(maxDataSignal)
                });
                var option = {
                    // tooltip: {
                        // trigger: "item",
                        // formatter: (p) => {
                        //     if (p.seriesName === "total") {
                        //         return "";
                        //     }
                        //     return `${p.name}<br/>: ${p.value}`;
                        // },
                    // },
                    legend: {
                        show: false,
                    },
                    grid: {
                        left: 50,
                        top: 10,
                        right: 50,
                    },
                    xAxis: {
                        type: "value",
                        show:false,
                        name: "",
                        nameLocation: "end",
                        nameTextStyle: {
                            fontSize: 14,
                            color: "#666666",
                            padding: [60, 0, 0, -65],
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            color: "#555555",
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#e2e4e8",
                            },
                        },
                    },
                    yAxis: [
                        {
                            type: "category",
                            inverse: true,
                            axisLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisPointer: {
                                label: {
                                    show: false,
                                    margin: 30,
                                },
                            },
                            data: yData,
                            show:true,
                            axisLabel: {
                                margin: 40,
                                fontSize: 14,
                                align: "left",
                                color: "#333",
                                // 配置序号背景
                                rich: {
                                    a1: {
                                        color: "#fff",
                                        // backgroundColor: {
                                        //     // image: "../../assets/img/first.png",
                                        //     image: "C:/Users/Administrator/Desktop/first.png",
                                        // },
                                        backgroundColor: "#ff0000",
                                        width: 25,
                                        height: 25,
                                        align: "center",
                                        borderRadius: 15,
                                    },
                                    a2: {
                                        color: "#fff",
                                        backgroundColor: "#f6b836",
                                        width: 25,
                                        height: 25,
                                        align: "center",
                                        borderRadius: 15,
                                    },
                                    a3: {
                                        color: "#fff",
                                        backgroundColor: "#00cc66",
                                        width: 25,
                                        height: 25,
                                        align: "center",
                                        borderRadius: 15,
                                    },
                                    b: {
                                        color: "#858585",
                                        backgroundColor: "#e1e1e1",
                                        width: 25,
                                        height: 25,
                                        lineHeight: 30,
                                        align: "center",
                                        verticalAlign: "middle",
                                        borderRadius: 15,
                                    },
                                },
                                formatter: function (params, index) {
                                    var leftIndex = index + 1;
                                    if (leftIndex < 4) {
                                        return ["{a" + leftIndex + "|" + leftIndex + "}" + "  "].join(
                                            "\n"
                                        );
                                    } else {
                                        return ["{b|" + leftIndex + "}" + "  "].join("\n");
                                    }
                                },
                            },
                        },
                        {
                            type: 'category',
                            inverse: true,
                            axisTick: 'none',
                            axisLine: 'none',
                            show: true,
                            axisLabel: {
                                textStyle: {
                                    color: '#333',
                                    fontSize: '12'
                                },
                                // formatter: function(value) {
                                //     if (value >= 10000) {
                                //         return (value / 10000).toLocaleString() + '万';
                                //     } else {
                                //         return value.toLocaleString();
                                //     }
                                // },
                            },
                            data:seriesData
                        }
                    ],
                    series: [
                        {
                            name: "value",
                            type: "bar",
                            barWidth: 7,
                            barGap: '-50',
                            legendHoverLink: false,
                            zlevel: 1,
                            data: seriesData,
                            barCategoryGap: '100%',
                            itemStyle: {
                                color:function(params){
                                    // var clolrList=['#f0454b','#f7be32','#5ec49c','#5ec49c'];
                                    var clolrList=['#f0454b','#fbc941','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c','#5ec49c'];
                                    return clolrList[params.dataIndex]
                                },
                                // color: {
                                //     type: "linear",
                                //     x: 0,
                                //     y: 0,
                                //     x2: 1,
                                //     y2: 0,
                                //     colorStops: [
                                //         {
                                //             offset: 0,
                                //             color: "#1595f8", // 0% 处的颜色
                                //         },
                                //         {
                                //             offset: 1,
                                //             color: "#51eff9", // 100% 处的颜色
                                //         },
                                //     ],
                                // },
                                // barBorderRadius: [10, 5, 5, 5],
                                barBorderRadius: [10],
                            },
                            // 配置柱子上方类目轴名字
                            label: {
                                show: true,
                                position: [0, "-25px"],
                                color: "#555",
                                fontSize: 14,
                                offset: [0, 0],
                                formatter: function (a) {
                                    return `${a.name}`;
                                },
                            },
                        },
                        {
                            name: '背景',
                            type: 'bar',
                            barWidth: 7,
                            barGap: '-100%',
                            data:maxData,
                            itemStyle: {
                                normal: {
                                    // color: '#cccccc',
                                    // color: '#e8e7ea',
                                    color: '#e1e1e1',
                                    // color: 'yellow',
                                    barBorderRadius: 30,
                                }
                            },
                        },
                    ],
                };
                chart.setOption(option,true);
            },
            getRankData(id){
                var url='';
                switch (id) {
                    case 'brand':
                        url='1';
                        break;
                    case 'roomNum':
                        url='1';
                        break;
                    case 'terminal':
                        url='1';
                        break;
                    case 'rate':
                        url='1';
                        break;
                }
                this.$http.get(this.global.getBelongPartnerOptions,{
                    params:{
                        token:this.token
                    }
                }).then(res=>{
                    if(res.data.code===100000){

                    }
                });
            },
            getMaxData(arr){
                arr.sort(function (a, b) {
                    return a-b;
                }); // [5,12,22,25,51,56]
                var min = arr[0];  // 5
                var max = arr[arr.length - 1];  // 56
                return max;
            },
        },
        mounted(){
            if(this.rankChartData.seriesData.length!==0){
                this.getRankChart(this.rankChartData.yData,this.rankChartData.seriesData);
            }
        },
        watch:{
            rankChartData:{
                handler:function(newVal){
                    this.getRankChart();
                },
                deep:true
            }
        },
        computed:{
            getChartHeight(){
                return function(val){
                    var str='';
                    if(val>0 && val<2){
                        str='elHeightOne'
                    }else if(val===2){
                        str='elHeightTwo'
                    }else if(val===3){
                        str='elHeightThree'
                    }else if(val>3 && val<=6){
                        str='elHeightMiddle'
                    }else if(val>6){
                        str='elHeightMax'
                    }
                    return str;
                }
            }
        }
    }
</script>

<style scoped>
    .rankChart,.chartBox{
        width:100%;
    }
    .rankChart{
        margin-top:15px;
    }
    .elHeightMax{
        height:610px;
        /*height:260px;*/
    }
    .elHeightMiddle{
        height:330px;
        /*height:260px;*/
    }
    .elHeightMin{
        height:260px;
    }
    .elHeightOne{
        height:125px;
    }
    .elHeightTwo{
        height:180px;
    }
    .elHeightThree{
        height:210px;
    }
    .noData{
        margin-top:10px;
        color:#909399;
        font-size:14px;
    }
    .rankTips{
        display:flex;
        justify-content: space-between;
    }
    .rankTips div{
        font-size:14px;
        color: #a3a7af;
    }

</style>
