<!-- 云apk管理 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">内容管理</span>
        <span slot="third">云apk管理</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <el-tabs v-model="activeTag" @tab-click="handleClick">
        <el-tab-pane label="apk配置" name="setting">
          <ApkSet ref="setting"></ApkSet>
        </el-tab-pane>
        <el-tab-pane label="apk已入库名单" name="in">
          <ApkIn ref="in"></ApkIn>
        </el-tab-pane>
        <el-tab-pane label="apk任务下发" name="issue">
          <ApkSend ref="issue"></ApkSend>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import ApkSet from "@/components/operation/ApkManage/apkSet";
import ApkIn from "@/components/operation/ApkManage/apkIn";
import ApkSend from "@/components/operation/ApkManage/apkSend";
export default {
  components: { ApkSet, ApkIn, ApkSend },
  data() {
    return {
      bread: {
        level: 3,
      },
      activeTag: "setting",
    };
  },
  methods: {
    handleClick() {
      this.$refs[this.activeTag].getList(1);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
