<template>

  <div :class="['box',showData?'':'box1']" :style="{background:showData.pic?'url('+showData.pic[0].source_url+')':style.background,backgroundSize: showData.pic?'100% 100%':'',width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',lineHeight:style.lineHeight+'px'}">
    <div :class="[inputShow?'show':'',inputShow?'':'borderBox']"></div>
    <div class="text" v-if="!inputShow" :style="{color:style.color,width:style.inputwidth+'px',height:style.inputheight?style.inputheight+'px':'',fontSize:style.fontSize+'px !important',lineHeight:style.inputlineHeight+'px',textAlign:style.textAlign,position: inputPosition?'absolute':'',top:inputPosition?style.position.top+'px':'',left:inputPosition?style.position.left+'px':''}" @click="showInput">
      <div class="textBorder"></div>
      {{showData.intro}}
    </div>
    <div class="text" v-if="inputShow" :style="{color:style.color+'!important',width:style.inputwidth+'px',height:style.inputheight?style.inputheight+'px':'',fontSize:style.fontSize+'px !important',lineHeight:style.inputlineHeight+'px',textAlign:style.textAlign,position: inputPosition?'absolute':'',top:inputPosition?style.position.top+'px':'',left:inputPosition?style.position.left+'px':''}">
      <div class="textBorder"></div>
      <el-input :class="inputShow?'active':''" :style="{width:style.inputwidth+'px',height:style.inputheight?style.inputheight+'px':'',fontSize:style.fontSize+'px !important',lineHeight:style.inputlineHeight+'px !important',textAlign:style.textAlign}" type='textarea' resize='none' v-model="showData.intro" @blur="textChange" placeholder="请输入内容" rows="1" :maxlength="style.maxTextLength?style.maxTextLength:''" show-word-limit></el-input>
    </div>
  </div>
</template>

<script>
import { verificationResult, auditing } from "@/api/visual.js";
import md5 from "./md5";
import qs from "qs";
export default {
  name: "textBox",
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        let obj = JSON.parse(JSON.stringify(newVal));
        for (var i in obj.style) {
          this.style[i] = obj.style[i];
        }
        if (this.style.position) {
          this.inputPosition = true;
        }
        this.inputlineHeight = this.style.inputlineHeight;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      inputPosition: false,
      text: "99999999999",
      inputShow: false,
      oldStr: "",
      showData: this.value,
      inputlineHeight: "",
    };
  },
  methods: {
    //  获取字符串MD5并验证
    verificationString(oldStr, newStr, v) {
      return new Promise((response, ret) => {
        var newMd5 = md5(newStr);
        verificationResult(qs.stringify({ md5: newMd5 })).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data == 3) {
              return response(newStr);
            } else {
              var auth = JSON.parse(sessionStorage.getItem("auth"));
              var channelName = sessionStorage.getItem("curCompanyName");
              let json = {
                taskName: "可视化文字审核",
                resourcesType: 1,
                resourcesForm: 1,
                product: 5,
                userName: auth.user_name,
                channelName: channelName,
                userPhone: "-",
                txtContent: newStr,
              };
              auditing(qs.stringify(json)).then((res) => {
                if (res.data.code == 200) {
                  if (res.data.data[0].auditingResult == 3) {
                    v.$message.success("审核成功");
                    return response(newStr);
                  } else {
                    v.$message.warning("审核未通过");
                    return response(oldStr);
                  }
                } else {
                  v.$message.warning("审核失败");
                  return response(oldStr);
                }
              });
            }
          } else {
            v.$message.warning("获取审核信息失败");
            return response(oldStr);
          }
        });
      });
    },
    async textChange() {
      let res = await this.verificationString(
        this.oldStr,
        this.showData.intro,
        this
      );
      this.inputShow = false;
      this.showData.intro = res;
      this.showData.update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    showInput() {
      if (this.isEdit) {
        return;
      }
      this.inputShow = true;
      this.oldStr = this.showData.intro;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.show {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}

.text {
  font-size: 16px !important;
  font-family: "微软雅黑" !important;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
  .textBorder {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.text:hover .textBorder {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.el-textarea {
  width: 100%;
  font-size: 16px !important;
  font-family: "微软雅黑" !important;
  box-sizing: border-box;
  border: none;
  position: relative;

  .textBorder {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 2px dashed #2e63a6;
    box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
  }
}

.active ::v-deep .el-textarea__inner {
  // border: 1px dashed #2e63a6;
  // box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
  // box-sizing: border-box;
}
::v-deep .el-textarea .el-input__count {
  background: transparent;
  color: #ddd;
  bottom: 0;
}
::v-deep .el-textarea__inner {
  width: 100%;
  height: 100%;
  color: inherit !important;
  background: transparent !important;
  border: none;
  line-height: var(--inputlineHeight);
  text-align: inherit;
  padding: 0;
  border: none;
}
</style>