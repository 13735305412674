import request from '../../api/request';
import qs from "qs"

//   获取酒店分组列表
export function getRoomGroup(param) {
    return request({
        url: `v2/Roomgroup/getRoomGroup`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   添加分组
export function saveRoomGroup(param) {
    return request({
        url: `v2/Roomgroup/saveRoomGroup`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   修改分组
export function editData(param) {
    return request({
        url: `v2/Roomgroup/editData`,
        method: 'post',
        data: qs.stringify(param),
    });
}

//   删除分组
export function delRoomGroup(param) {
    return request({
        url: `v2/Roomgroup/delRoomGroup`,
        method: 'post',
        data: qs.stringify(param),
    });
}

//  根据合同号获取房间信息
export function getRoomByHotelContract(param) {
    return request({
        url: `v2/hotel/getRoomByHotelContract`,
        method: 'post',
        data: qs.stringify(param),
    });
}