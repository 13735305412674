<template>
  <div>
    <div class="btnRow">
      <el-button type="primary" class="largeBtn" @click="$refs.AddDownloadConfig.open('新增',query.hotelName)">添 加</el-button>
      <el-button type="danger" class="largeBtn" @click="deleteData()">批量删除</el-button>
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <AddDownloadConfig ref="AddDownloadConfig"></AddDownloadConfig>
    <textDownloadConfig ref="textDownloadConfig" @update="getList"></textDownloadConfig>
  </div>
</template>
<script>
import { getList,allDelete} from "../api/application/downloadConfig";
import AddDownloadConfig from "./components/AddDownloadConfig.vue";
import textDownloadConfig from "./components/textDownloadConfig.vue";
export default {
  props: {
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  name: "ServiceConfig",
  components: {
    AddDownloadConfig,
    textDownloadConfig,
  },
  data() {
    return {
      tableData: {
        tableList: [ ],
        checked: true,
        tableLoading: false,
        page: 1,
        size: 2,
        props: [
          {
            label: "版本号",
            prop: "music_code",
            minWidth: "50",
            type:"text",
          },
          {
            label: "类型",
            prop: "state_text",
            minWidth: "50",
            type:"text",
          },
          {
            label: "名字",
            prop: "music_name",
            minWidth: "80",
            type:"text",
          },
          {
            label: "路径",
            prop: "music_addr",
            minWidth: "150",
            type:"text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            edit: (row) => {
              if(row.music_name == "AS自动化配置"||row.music_name == "应用安装白名单"||row.music_name == "先卸载后安装应用名单"){
                if(row.music_name == "AS自动化配置"){
                   this.$refs.textDownloadConfig.open('As',row,this.query)
                }else if(row.music_name == "应用安装白名单"){
                    this.$refs.textDownloadConfig.open('App',row,this.query)
                }else if(row.music_name == "先卸载后安装应用名单"){
                    this.$refs.textDownloadConfig.open('App',row,this.query,'out')
                }
                   
              }else{
                  this.$refs.AddDownloadConfig.open('修改',this.query.hotelName,row)
              }
            },
          },
        ],
      },
      listQuery: {
        database: this.query.hotelName,
        page: 1,
        size: 10
      },
      checkList: [],
    };
  },
  methods: {
    changeSize(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
      
    },
    changeCurrent(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
    },
    changeSelection(val) {
      this.checkList = val.map(v=>{
        return v.id
      })
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
        this.$refs.operationTable.resetTable();
      }
      getList(this.listQuery).then(res => {
        if (res.data.code == 100000) {
          let typeList = {
            0: '音乐',
            1: '字体',
            2: '视频',
            3: '其他'
          }
          if (res.data.data.totalNum > 0) {
            res.data.data.row.forEach(v => {
              v.state_text = typeList[v.state]
            });
            this.tableData.tableList = res.data.data.row;
            

          } else {
            this.tableData.tableList = [];
          }
          this.$refs.operationTable.changeTotal(Number(res.data.data.totalNum));
        }
        this.tableData.tableLoading = false;
      })
    },
    deleteData() {
      if (this.checkList.length <= 0 ) {
        return this.$message.error('请选择下载配置');
      }
      this.$confirm('此操作将永久删除下载配置？, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          allDelete(
            {
              ids:`${this.checkList.join()}`,
              database:this.listQuery.database
            }).then(res => {
              if (res.data.code === 100000) {
                this.getList(1);
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
              } else {
                this.$message.error(es.data.msg)
              }
          })
        }).catch(() => {
        });
    },
  },
  mounted(){
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map(i=>{
      if(i.ltype==1){
        i.language ="中文"
      }else if(i.ltype==2){
         i.language ="英文"
      }else if(i.ltype==3){
         i.language ="日文"
      }else if(i.ltype==4){
         i.language ="韩文"
      }
      return i
    })
  },
  watch: {
    query: {
      handler: function (newVal) {
       this.query=newVal;
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
