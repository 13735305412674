<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">运维管理</span>
        <span slot="third">批量更新</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <!-- 操作栏 -->
      <div class="btnRow">

        <el-form ref="form" :inline="true" :model="form" label-position="left">
          <el-form-item >
            <el-select v-model="form.id" placeholder="选择模板" clearable class="fullSelect" filterable>
              <el-option v-for="item in brandList" :key="item.id" :label="item.t_name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.result_name" placeholder="发布名称关键词"></el-input>
          </el-form-item>

          <el-form-item>

            <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
            <!-- <el-button type="primary" class="largeBtn marginLeft" @click=" getTableData(tableData.limit, tableData.page);">刷 新</el-button> -->
            <el-button type="primary" class="largeBtn" @click="reset">重置</el-button>
            <!-- <el-button type="primary" class="largeBtn" :loading='btnLoading' @click="importTable">导出</el-button> -->

          </el-form-item>
        </el-form>
        <el-button v-if="$route.path=='/batchUpdata'" type="primary" class="largeBtn" style="height:40px" @click="toCreat">新建</el-button>

      </div>
      <!-- 操作栏 -->
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent">
      </operationTable>
      <!-- 表格 -->
    </el-card>

    <el-dialog title="发布追踪" :visible.sync="releaseVisible" width="1000px" :modal-append-to-body="true">

      <el-form ref="releaseForm" :inline="true" :model="releaseForm" label-position="left">
        <el-form-item>
          <el-select v-model="releaseForm.state" placeholder="选择发布状态" clearable class="fullSelect" filterable>
            <el-option label="未获取" value="0"></el-option>
            <el-option label="发布执行" value="4"></el-option>
            <el-option label="资源下载完成" value="2"></el-option>
            <el-option label="发布成功" value="3"></el-option>
            <el-option label="发布失败" value="99"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="releaseForm.name" placeholder="酒店名称关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="largeBtn marginLeft" @click="searchRealeaseInfo">搜 索</el-button>
          <!-- <el-button type="primary" class="largeBtn marginLeft" @click=" getTableData(tableData.limit, tableData.page);">刷 新</el-button> -->
          <el-button type="primary" class="largeBtn" @click="resetRealeaseInfo">重置</el-button>
          <!-- <el-button type="primary" class="largeBtn" :loading='btnLoading' @click="importTable">导出</el-button> -->
        </el-form-item>
      </el-form>
      <el-table class="tableBox" ref="singleTable" :data="releaseList" style="width: 100%" v-loading="releaseLoading">
        <el-table-column prop="hotel_name" label="酒店名称" min-width="260" show-overflow-tooltip></el-table-column>
        <el-table-column prop="room_number" label="发布终端数" min-width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="renew_number" label="已更新终端数" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="no_renew_number" label="未更新终端数" min-width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" class="largeBtn" :disabled="scope.row.no_renew_number==0" @click="openRoom(scope.row)">{{scope.row.no_renew_number}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="renew_rate" label="更新率" min-width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="state_name" label="发布状态" min-width="140" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination @size-change="releaseSizeChange" @current-change="releaseCurrentChange" :current-page.sync="releasePage" :page-size="5" layout="total, prev, pager, next" :total="releaseTotal"></el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="releaseVisible=false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="未更新终端详情" :visible.sync="roomVisible" width="600px" :modal-append-to-body="false">
      <div v-loading="roomLoading">
        <el-tag style="margin: 0 10px 10px 0 " v-for="room in roomList" :key="room">
          {{room}}
        </el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="roomVisible=false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="新增酒店" :visible.sync="addHotelVisible" :before-close="closeAddhotel" width="1080px" :modal-append-to-body="false">

      <checkHotel ref="checkHotel"></checkHotel>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button class="cancelBtn" @click="closeAddhotel">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import {
  getTemplateList,
  getHotelByVersion,
  staVersion,
  getHotelNoUpdate,
  refreshNumber,
  repeatLauncher,
  importVersion,
  addHotel,
} from "./api";
import checkHotel from "./checkHotel.vue";
export default {
  name: "batchUpdata",
  components: {
    checkHotel,
  },
  data() {
    return {
      form: {
        id: "",
        result_name: "",
      },
      releaseForm: {
        name: "",
        state: "",
      },
      releasePage: 1,
      releaseTotal: 0,
      bread: {
        level: 3,
      },
      brandList: [],
      btnLoading: false,
      item: {},
      // 表格数据信息
      tableData: {
        tableList: [],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "名称",
            prop: "intro",
            minWidth: "250",
            type: "text",
          },
          {
            label: "品牌",
            prop: "groupname",
            minWidth: "120",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "280",
            type: "button",
            prop: "num",
            // 详情
            info: (item) => {
              let currentUrl = this.$route.path;
              if (currentUrl == "/batchUpdata") {
                this.$router.push({
                  path: `/addBatchUpdata`,
                  query: {
                    item: item,
                  },
                });
              } else {
                this.$router.push({
                  path: `/onePage/addBatchUpdata`,
                  query: {
                    item: item,
                  },
                });
              }
            },
            addHotel: (item) => {
              console.log(item);

              this.addHotelVisible = true;
              this.$nextTick(() => {
                this.item = item;
                this.$refs.checkHotel.template_id = item.template_id;
              });
            },
            // 发布追踪
            result: (item) => {
              this.roomItem = item;
              this.releaseVisible = true;
              this.releaseLoading = true;
              this.releaseForm.name = "";
              this.releaseForm.state = "";
              this.releasePage = 1;
              getHotelByVersion({
                version_id: item.id,
                name: "",
                state: "",
                page: this.releasePage,
              }).then((res) => {
                if (res.data.code == 100000) {
                  this.releaseList = res.data.data.data;
                  this.releaseTotal = res.data.data.totalNum;
                  this.releaseLoading = false;
                } else {
                  this.releaseList = [];
                  this.releaseLoading = false;
                }
              });
            },
            reset: (item) => {
              this.$confirm("是否确认重发？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                repeatLauncher({ version_id: item.id }).then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("重发成功");
                    this.getTableData(
                      this.tableData.limit,
                      this.tableData.page
                    );
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                });
              });
            },
            load: (item) => {
              this.tableData.tableLoading = true;
              refreshNumber({ version_id: item.id }).then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("刷新成功");
                  this.getTableData(this.tableData.limit, this.tableData.page);
                } else {
                  this.$message.warning(res.data.msg);
                }
              });
            },
            import: (item) => {
              this.tableData.tableLoading = true;
              importVersion({ version_id: item.id })
                .then((res) => {
                  if (!res.data.code) {
                    console.log(res);
                    const blobUrl = window.URL.createObjectURL(res.data);
                    const title = `导出列表.xlsx`;
                    // const title = `${this.tableName}.csv`;
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.download = title;
                    a.href = blobUrl;
                    a.click();
                    this.tableData.tableLoading = false;
                    document.body.removeChild(a);
                  } else {
                    this.$message.warning(res.data.msg);
                    this.tableData.tableLoading = false;
                  }
                })
                .catch((err) => {});
            },
          },
          {
            label: "发布时间",
            prop: "stime",
            minWidth: "160",
            type: "text",
          },
          {
            label: "发布人",
            prop: "uname",
            minWidth: "120",
            type: "text",
          },
          {
            label: "发布门店数/发布房间数",
            prop: "SroomStr",
            minWidth: "200",
            type: "text",
          },
          {
            label: "更新门店数/更新房间数",
            prop: "UroomStr",
            minWidth: "200",
            type: "text",
          },
          {
            label: "更新率",
            prop: "upNumber",
            minWidth: "100",
            type: "text",
          },
        ],
      },
      // 已选项
      checkList: [],
      releaseVisible: false,
      releaseList: [],
      releaseLoading: false,
      roomVisible: false,
      roomLoading: false,
      roomList: [],
      roomItem: {},
      addHotelVisible: false,
    };
  },

  methods: {
    submit() {
      let list = this.$refs.checkHotel.showHotelList.hotels;
      if (list.length == 0) {
        this.$message.warning("请选择酒店信息");
        return;
      }
      this.$confirm("是否确认添加酒店操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          update_type: "SqlUpdate",
          version_id: this.item.id,
          hotel_str: JSON.stringify(list),
        };
        addHotel(param).then((res) => {
          if (res.data.code == 100000) {
            this.$message.success("酒店添加成功");
            this.$refs.checkHotel.clearAll();
            this.addHotelVisible = false;
            this.search();
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      });
    },
    closeAddhotel() {
      this.$refs.checkHotel.clearAll();
      this.addHotelVisible = false;
    },
    getReleaseInfo() {
      this.releaseLoading = true;
      getHotelByVersion({
        version_id: this.roomItem.id,
        name: this.releaseForm.name,
        state: this.releaseForm.state,
        page: this.releasePage,
      }).then((res) => {
        if (res.data.code == 100000) {
          this.releaseList = res.data.data.data;
          this.releaseTotal = res.data.data.totalNum;
          this.releaseLoading = false;
        } else {
          this.releaseList = [];
          this.releaseLoading = false;
        }
      });
    },
    resetRealeaseInfo() {
      this.releasePage = 1;
      this.releaseForm.name = "";
      this.releaseForm.state = "";
      this.getReleaseInfo();
    },
    releaseSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    releaseCurrentChange(val) {
      this.releasePage = val;
      this.getReleaseInfo();
    },
    searchRealeaseInfo() {
      this.releasePage = 1;
      this.getReleaseInfo();
    },
    openRoom(row) {
      this.roomVisible = true;
      this.roomLoading = true;
      let json = {
        ipaddr: row.ipaddr,
        project_name: row.project_name,
        version_id: row.version_id,
        data_version: row.data_version,
      };
      getHotelNoUpdate(json).then((res) => {
        if (res.data.code == 100000) {
          this.roomList = res.data.data;
          this.roomLoading = false;
        } else {
          this.roomLoading = false;
          this.$message.waring(res.data.msg);
        }
      });
    },
    toCreat() {
      let currentUrl = this.$route.path;
      if (currentUrl == "/batchUpdata") {
        this.$router.push({
          path: `/addBatchUpdata`,
          query: {
            isEdit: 0,
          },
        });
      } else {
        this.$router.push({
          path: `/onePage/addBatchUpdata`,
          query: {
            isEdit: 0,
          },
        });
      }
    },
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    reset() {
      this.tableData.page = 1;
      this.form.id = "";
      this.form.result_name = "";
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },
    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: this.tableData.page,
        limit: this.tableData.limit,
        templateId: this.form.id,
        number: this.form.result_name,
      };
      staVersion(json)
        .then((res) => {
          if (res.data.code == 100000) {
            let arr = res.data.data.items.map((i) => {
              i.SroomStr = i.hotelcount + "/" + i.roomCount;
              i.UroomStr = i.updatehotelcount + "/" + i.updateroomcount;
              if (i.roomCount == 0) {
                i.upNumber = "0.00%";
              } else {
                i.upNumber = ((i.updateroomcount / i.roomCount) * 100).toFixed(
                  2
                );
                if (i.upNumber == 0) {
                  i.upNumber = 0 + "%";
                } else if (i.upNumber == 100) {
                  i.upNumber = 100 + "%";
                } else {
                  i.upNumber = i.upNumber + "%";
                }
              }
              return i;
            });
            this.tableData.tableList = arr;
            this.$refs.operationTable.changeTotal(Number(res.data.data.total));
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
    getTemplateAllList() {
      getTemplateList().then((res) => {
        let currentUrl = this.$route.path;
        if (currentUrl == "/batchUpdata") {
          this.brandList = res.data.data;
        } else {
          this.brandList = res.data.data.filter((i) => {
            if (i.id == 145) return i;
          });
          this.form.id ='145'
        }
         this.getTableData();
      });
    },
  },
  mounted() {
   
    this.getTemplateAllList();
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
.btnRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
