<template>
  <div style="padding:0 15px">

    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">内容监管</span>
        <span slot="third">业务开通</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">业务开通列表</div>
      <!-- 操作栏 -->
      <div class="btnRow">
        <el-input style="width:15%;margin-right:10px" v-model="form.name" placeholder="请输入酒店关键字"></el-input>

        <el-date-picker style="width:15%;margin-right:10px" v-model="form.time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>

        <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
        <el-button type="primary" class="largeBtn" @click="reset">重 置</el-button>
        <RoomExport></RoomExport>
      </div>
      <!-- 操作栏 -->
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />

    </el-card>

    <!-- 表格 -->

  </div>
</template>
<script>
import  RoomExport from './conmonents/RoomExport.vue'
export default {
  name: "importList",
    components:{
        RoomExport,
    },
  data() {
    return {
      bread: {
        level: 3,
      },
      picName: "",

      // 表格数据信息
      tableData: {
        tableList: [
          {
            hotel_name: "沈阳大东区嘉隆会连锁酒店",
            brand_name: "单体",
            province: "辽宁省",
            city: "沈阳市",
            country: "大东区",
            room_num: "100",
            terminal_num: "100",
            star_time_text: "2023-01-01",
            end_time_text: "2025-01-01",
          },
        ],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "酒店名称",
            prop: "hotel_name",
            minWidth: "300",
            type: "text",
          },
          {
            label: "品牌",
            prop: "brand_name",
            minWidth: "80",
            type: "text",
          },
          {
            label: "省份",
            prop: "province",
            minWidth: "120",
            type: "text",
          },
          {
            label: "城市",
            prop: "city",
            minWidth: "120",
            type: "text",
          },
          {
            label: "县/区",
            prop: "country",
            minWidth: "120",
            type: "text",
          },
          {
            label: "客房数量",
            prop: "room_num",
            minWidth: "100",
            type: "text",
          },
          {
            label: "激活终端数量",
            prop: "terminal_num",
            minWidth: "120",
            type: "text",
          },
          {
            label: "合约开始时间",
            prop: "star_time_text",
            minWidth: "300",
            type: "text",
          },
          {
            label: "合约结束时间",
            prop: "end_time_text",
            minWidth: "300",
            type: "text",
          },
        ],
      },

      // 表单字段
      form: {
        name: "",
        time: "",
      },
      checkList: [],
      n: 0,
    };
  },

  methods: {
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      //   this.getTableData(
      //     this.query.hotelName,
      //     this.tableData.limit,
      //     this.tableData.page
      //   );
    },
    reset() {
      this.form = {
        name: "",
        time: "",
      };
    },

    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 选中项处理
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return i.id;
      });
    },
    // 获取表格数据
    getTableData(database, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        name: this.picName,
        page: p ? p : 1,
        limit: s ? s : 10,
      };
      getBasicPicture(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data.items;
            // 字段显示处理

            // 组件数据总数
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
</style>
