<template>
<div>   
      <el-dialog class="dialog" title="驳回原因" :visible.sync="dialog.dialogFormVisible" width='550px' :modal="false">
        <el-form :model="dialog" ref="dialogForm" :rules="backRules">
            <el-form-item prop="reason" label="驳回原因" label-width="130px">
                <el-input v-model="dialog.reason" placeholder="请输入驳回原因"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialog.dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitDialogForm('form')">确 定</el-button>
        </div>
    </el-dialog>
     <div class="modal" v-if="dialog.dialogFormVisible"></div>
</div>
  
    
</template>

<script>
    export default {
        name: "RejectDialog",
        data:()=>({
            dialog:{
                dialogFormVisible:false,
                reason:''
            },
            backRules:{
                reason: [
                    {required: true, message: '请输入驳回原因', trigger: 'blur' },
                ],
            },
        }),
        methods:{
            submitDialogForm() {
                this.$refs['dialogForm'].validate((valid) => {
                    if (valid) {
                        this.$emit('examine',-1,this.dialog.reason);
                        this.dialog.dialogFormVisible=false;
                    } else {
                        return false;
                    }
                });
            },
            dialogShow(val){
                this.dialog.dialogFormVisible=val;
            }
        },
        watch:{
            'dialog.dialogFormVisible'(newVal){
                if(!newVal){
                    this.dialog.reason='';
                }
            }
        }
    }
</script>

<style scoped>
.dialog{
    z-index: 9999 !important;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9998;
}
</style>
