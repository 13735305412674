<template>
  <div class="task_manage_box" v-loading="fullLoading">
    <!-- 表单 -->
    <div class="form_box">
      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <!-- <span>管理操作：</span> -->
          <el-input v-model="form.name" placeholder="apk模糊名" clearable></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="form.sign" placeholder="请选择签名" clearable>
            <el-option v-for="item in signList" :key="item.id + 'sign'" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜 索</el-button>
          <el-button type="primary" @click="reset">重 置</el-button>
          <!-- <el-button type="primary" @click="exportData">导 出</el-button> -->
          <el-button type="warning" @click="
              current = {};
              taskDia = true;
            ">+任务</el-button>
        </el-form-item>
        <!-- <span class="export_row">无签名归属酒店机型：<el-button type="primary" @click="exportNoSign">导 出</el-button></span> -->
      </el-form>
    </div>
    <!-- table -->
    <!-- 表格 -->
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent">
    </operationTable>
    <!-- 表格 -->
    <div>

      <el-dialog title="发布追踪" :visible.sync="releaseVisible" width="1000px" :modal-append-to-body="true">

        <el-form ref="releaseForm" :inline="true" :model="releaseForm" label-position="left">
          <el-form-item>
            <el-select v-model="releaseForm.state" placeholder="选择发布状态" clearable class="fullSelect" filterable>
              <el-option label="未获取" value="0"></el-option>
              <el-option label="发布执行" value="4"></el-option>
              <el-option label="资源下载完成" value="2"></el-option>
              <el-option label="发布成功" value="3"></el-option>
              <el-option label="发布失败" value="99"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="releaseForm.name" placeholder="酒店名称关键词"></el-input>
          </el-form-item>

          <el-form-item>

            <el-button type="primary" class="largeBtn marginLeft" @click="searchRealeaseInfo">搜 索</el-button>
            <!-- <el-button type="primary" class="largeBtn marginLeft" @click=" getTableData(tableData.limit, tableData.page);">刷 新</el-button> -->
            <el-button type="primary" class="largeBtn" @click="resetRealeaseInfo">重置</el-button>
            <!-- <el-button type="primary" class="largeBtn" :loading='btnLoading' @click="importTable">导出</el-button> -->

          </el-form-item>
        </el-form>
        <el-table class="tableBox" ref="singleTable" :data="releaseList" style="width: 100%" v-loading="releaseLoading">
          <el-table-column prop="hotel_name" label="酒店名称" min-width="260" show-overflow-tooltip></el-table-column>
          <el-table-column prop="room_number" label="发布终端数" min-width="130" show-overflow-tooltip></el-table-column>
          <el-table-column prop="renew_number" label="已更新终端数" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="no_renew_number" label="未更新终端数" min-width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button type="text" class="largeBtn" :disabled="scope.row.no_renew_number==0" @click="openRoom(scope.row)">{{scope.row.no_renew_number}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="renew_rate" label="更新率" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="state_name" label="发布状态" min-width="140" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-pagination @size-change="releaseSizeChange" @current-change="releaseCurrentChange" :current-page.sync="releasePage" :page-size="5" layout="total, prev, pager, next" :total="releaseTotal">
        </el-pagination>

        <span slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="releaseVisible=false">取 消</el-button>
        </span>
      </el-dialog>
      <el-dialog title="未更新终端详情" :visible.sync="roomVisible" width="600px" :modal-append-to-body="false">
        <div v-loading="roomLoading">
          <el-tag style="margin: 0 10px 10px 0 " v-for="room in roomList" :key="room">
            {{room}}
          </el-tag>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="roomVisible=false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog title="未更新终端详情" :visible.sync="addHotelVisible" width="1080px" :modal-append-to-body="false">

      <HotelSelect v-model="hotels" ref="apkHotel" type="apk" :noInsert="true" :signList="signList" :signId="item.signed_id" id="" :apk="item.currentApkName" :showDiv='true' @platfrom="(val) => (platfrom = val)" @close='closeSelect'></HotelSelect>

    </el-dialog>
    <TaskCreate :visible.sync="taskDia" :apkList="apkList" @loadpage='reset' :signList="signList" :current="current"></TaskCreate>
  </div>
</template>
<script>
// import { stringToExcel } from "./utils/stringToExcel";

import TaskCreate from "@/components/operation/ApkManage/components/taskCreate";
import HotelSelect from "../../../components/common/operation/components/hotelSelect";
import {
  getList,
  exportNoSign,
  exportData,
  rowExport,
  reSend,
  getTemplateList,
  setBatchApk,
  getHotelByVersion,
  staVersion,
  getHotelNoUpdate,
  refreshNumber,
  repeatLauncher,
  importVersion,
  getApkList,
  getSignList,
  addHotel,
} from "./api/apkSend";
export default {
  name: "taskManage",
  components: { TaskCreate, HotelSelect },
  data() {
    return {
      hotels: [],
      item: {
        signed_id: "0",
        currentApkName: "849849844984",
      },
      addHotelVisible: false,
      platfrom: [],
      form: {
        name: "",
        sign: "",
      },
      tableData1: [],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      current: {},
      signList: [],
      apkList: [],
      taskDia: false,
      loading: false,
      fullLoading: false,

      releaseForm: {
        name: "",
        state: "",
      },
      releaseList: [],
      releasePage: 1,
      releaseTotal: 0,
      releaseLoading: false,
      releaseVisible: false,
      roomVisible: false,
      roomLoading: false,
      roomList: [],
      btnLoading: false,

      // 表格数据信息
      tableData: {
        tableList: [],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "说明",
            prop: "intro",
            minWidth: "250",
            type: "text",
          },
          {
            label: "apk名称",
            prop: "apk_name",
            minWidth: "220",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "250",
            type: "button",
            prop: "num",

            // 发布追踪
            result: (item) => {
              this.roomItem = item;
              this.releaseVisible = true;
              this.releaseLoading = true;
              this.releaseForm.name = "";
              this.releaseForm.state = "";
              this.releasePage = 1;
              getHotelByVersion({
                version_id: item.id,
                name: "",
                state: "",
                page: this.releasePage,
              }).then((res) => {
                if (res.data.code == 100000) {
                  this.releaseList = res.data.data.data;
                  this.releaseTotal = res.data.data.totalNum;
                  this.releaseLoading = false;
                } else {
                  this.releaseList = [];
                  this.releaseLoading = false;
                }
              });
            },
            addHotel: (item) => {
              console.log(item);
              this.addHotelVisible = true;
              this.$nextTick(() => {
                this.item.currentApkName = item.apk_name;
                this.item.signed_id = item.signed_id;
                this.item.id = item.id;
                this.hotels = [];
                this.$refs.apkHotel.showData(item);
              });
            },
            reset: (item) => {
              this.$confirm("是否确认重发？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                repeatLauncher({ version_id: item.id }).then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("重发成功");
                    this.getTableData(
                      this.tableData.limit,
                      this.tableData.page
                    );
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                });
              });
            },
            load: (item) => {
              this.tableData.tableLoading = true;
              refreshNumber({ version_id: item.id }).then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("刷新成功");
                  this.getTableData(this.tableData.limit, this.tableData.page);
                } else {
                  this.$message.warning(res.data.msg);
                }
              });
            },
            import: (item) => {
              this.tableData.tableLoading = true;
              importVersion({ version_id: item.id })
                .then((res) => {
                  if (!res.data.code) {
                    const blobUrl = window.URL.createObjectURL(res.data);
                    const title = `导出列表.xlsx`;
                    // const title = `${this.tableName}.csv`;
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.download = title;
                    a.href = blobUrl;
                    a.click();
                    this.tableData.tableLoading = false;
                    document.body.removeChild(a);
                     
                  } else {
                    this.$message.warning(res.data.msg);
                    this.tableData.tableLoading = false;
                  }
               
                })
                .catch((err) => {});
            },
          },
          {
            label: "发布时间",
            prop: "stime",
            minWidth: "160",
            type: "text",
          },
          {
            label: "发布人",
            prop: "uname",
            minWidth: "120",
            type: "text",
          },
          {
            label: "发布门店数/发布房间数",
            prop: "SroomStr",
            minWidth: "200",
            type: "text",
          },
          {
            label: "更新门店数/更新房间数",
            prop: "UroomStr",
            minWidth: "200",
            type: "text",
          },
          {
            label: "更新率",
            prop: "upNumber",
            minWidth: "100",
            type: "text",
          },
        ],

        // 已选项
        checkList: [],
      },
    };
  },
  methods: {
    closeSelect(type) {
   

      if (type == 1) {
        this.addHotelVisible = false;
      } else {
        if (this.hotels.length == 0) {
          this.$message.warning("请选择酒店信息");
          return;
        }
        this.$confirm("是否确认添加酒店操作", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let param = {
            update_type: "SqlApk ",
            version_id: this.item.id,
            hotel_str: JSON.stringify(this.hotels),
            device_name: this.platfrom.join(","),
          };
          addHotel(param).then((res) => {
            if (res.data.code == 100000) {
              this.$message.success("酒店添加成功");
              this.addHotelVisible = false;
              this.search();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        });
      }
    },
    
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.page = 1;
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    reset() {
      this.tableData.page = 1;
      this.form.name = "";
      this.form.sign = "";
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    openRoom(row) {
      this.roomVisible = true;
      this.roomLoading = true;
      let json = {
        ipaddr: row.ipaddr,
        project_name: row.project_name,
        data_version: row.data_version,
        version_id: row.version_id,
      };
      getHotelNoUpdate(json).then((res) => {
        if (res.data.code == 100000) {
          this.roomList = res.data.data;
          this.roomLoading = false;
        } else {
          this.roomLoading = false;
          this.$message.waring(res.data.msg);
        }
      });
    },
    getReleaseInfo() {
      this.releaseLoading = true;
      getHotelByVersion({
        version_id: this.roomItem.id,
        name: this.releaseForm.name,
        state: this.releaseForm.state,
        page: this.releasePage,
      }).then((res) => {
        if (res.data.code == 100000) {
          this.releaseList = res.data.data.data;
          this.releaseTotal = res.data.data.totalNum;
          this.releaseLoading = false;
        } else {
          this.releaseList = [];
          this.releaseLoading = false;
        }
      });
    },
    resetRealeaseInfo() {
      this.releasePage = 1;
      this.releaseForm.name = "";
      this.releaseForm.state = "";
      this.getReleaseInfo();
    },
      releaseSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      releaseCurrentChange(val) {
       this.releasePage = val;
        this.getReleaseInfo();
      },
    searchRealeaseInfo() {
      this.releasePage = 1;
      this.getReleaseInfo();
    },

    // exportDataRow(row) {
    //   this.fullLoading = true;
    //   rowExport({
    //     flag: "apk",
    //     number: row.id,
    //   }).then((data) => {
    //     this.fullLoading = false;
    //     const tmpDown = new Blob([data.data]);
    //     const a = document.createElement("a");
    //     a.href = URL.createObjectURL(tmpDown);
    //     a.download = row.name + ".xlsx";
    //     a.click();
    //   });
    // },
    // exportNoSign() {
    //   this.fullLoading = true;
    //   exportNoSign().then((data) => {
    //     this.fullLoading = false;
    //     const tmpDown = new Blob([data.data]);
    //     const a = document.createElement("a");
    //     a.href = URL.createObjectURL(tmpDown);
    //     a.download = "无签名归属酒店机型导出.xlsx";
    //     a.click();
    //   });
    // },
    // // 导出
    // exportData() {
    //   this.fullLoading = true;
    //   exportData({
    //     number: this.form.name,
    //   }).then((data) => {
    //     this.fullLoading = false;
    //     const tmpDown = new Blob([data.data]);
    //     const a = document.createElement("a");
    //     a.href = URL.createObjectURL(tmpDown);
    //     a.download = "apk机型导出.xlsx";
    //     a.click();
    //   });
    // },
    // send(row) {
    //   reSend({
    //     id: row.id,
    //   }).then((data) => {
    //     if (data.data.code == 100000) {
    //       this.$message.success(data.data.msg);
    //     } else {
    //       this.$message.error(data.data.msg);
    //     }
    //   });
    // },
    // 页面数据数
    changeSize(s, p) {
      console.log(s, p, "sezi");
      this.tableData.limit = s;
      this.tableData.page = p;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      console.log(s, p, "page");
      this.tableData.limit = s;
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },
    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: this.tableData.page,
        limit: this.tableData.limit,
        apk_name: this.form.name,
        sign_id: this.form.sign,
        updateType: "SqlApk",
      };
      staVersion(json)
        .then((res) => {
          if (res.data.code == 100000) {
            let arr = res.data.data.items.map((i) => {
              i.SroomStr = i.hotelcount + "/" + i.roomCount;
              i.UroomStr = i.updatehotelcount + "/" + i.updateroomcount;
              if (i.roomCount == 0) {
                i.upNumber = "0.00%";
              } else {
                i.upNumber = ((i.updateroomcount / i.roomCount) * 100).toFixed(
                  2
                );
                if (i.upNumber == 0) {
                  i.upNumber = 0 + "%";
                } else if (i.upNumber == 100) {
                  i.upNumber = 100 + "%";
                } else {
                  i.upNumber = i.upNumber + "%";
                }
              }
              return i;
            });
            this.tableData.tableList = arr;
            this.$refs.operationTable.changeTotal(Number(res.data.data.total));
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },

    // 获取列表
    getList(page) {
      getApkList().then((data) => {
        if (data.data.code == 100000) {
          this.apkList = data.data.data;
        } else {
          this.$message.warning(data.data.msg);
        }
      });
      getSignList().then((data) => {
        if (data.data.code == 100000) {
          this.signList = data.data.data;
        } else {
          this.$message.warning(data.data.msg);
        }
      });
    },
  },
  mounted() {
    this.getList();
    this.getTableData();
  },
};
</script>
<style lang="scss" scoped>
.task_manage_box {
  .el-pagination {
    margin-top: 15px;
  }
}
.export_row {
  display: inline-block;
  float: right;
  font-size: 16px;
}
</style>
