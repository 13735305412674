import request from "@/api/request";
import qs from "querystring";

//  eteams资产类型列表
export function getList(param) {
  return request({
    url: `v2/devicemanage/eteamstypelist`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  添加资产类型
export function add(param) {
  return request({
    url: `v2/devicemanage/eteamstypecreate`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  编辑资产类型
export function edit(param) {
  return request({
    url: `v2/devicemanage/eteamstypeupdate`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  删除资产类型
export function del(param) {
  return request({
    url: `v2/devicemanage/eteamstypedelete`,
    method: "post",
    data: qs.stringify(param),
  });
}
