<template>
  <div>
    <el-button
      type="primary"
      class="table_btn"
      @click="
        current = {};
        groupOperShow = true;
      "
      >添加集团</el-button
    >
    <!-- 集团table -->
    <el-table
      :data="tableDataGroup"
      border
      style="width: 100%;"
      :header-cell-style="{
        background: '#f8fbff',
        color: '#606266',
      }"
    >
      <!-- <el-table-column prop="index" label="编号" width="200">
                  <template slot-scope="scope">
                    <div>
                      {{ (scope.$index + 1) }}
                    </div>
                  </template>
                </el-table-column> -->
      <el-table-column prop="id" label="编号" width="200"> </el-table-column>
      <el-table-column prop="name" label="酒店集团"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="
              current = scope.row;
              groupOperShow = true;
            "
            type="text"
            size="small"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            slot="reference"
            @click="delGroup(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      layout="total,prev, pager, next"
      :total="pagination.total"
      v-show="pagination.total != 0"
      :current-page="pagination.currentPage"
      @current-change="
        (val) => {
          pagination.currentPage = val;
          getList();
        }
      "
    >
    </el-pagination>
    <GroupOper
      :visible.sync="groupOperShow"
      :current="current"
      @refresh="
        pagination.currentPage = 1;
        getList();
      "
    ></GroupOper>
  </div>
</template>
<script>
import { getGroupList, delGroup } from "./api/group";
import GroupOper from "@/components/operation/HotelClassify/components/groupOper";
export default {
  name: "hotelGroup",
  components: { GroupOper },
  data() {
    return {
      tableDataGroup: [],
      groupOperShow: false,
      current: {},
      pagination: {
        total: 0,
        currentPage: 1,
      },
    };
  },
  methods: {
    init() {
      this.getList();
    },
    delGroup(row) {
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delGroup({
            id: row.id,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getList(page, size) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getGroupList({
        page: page ? page : this.pagination.currentPage,
        size: size ? size : 10,
      }).then((data) => {
        this.tableDataGroup = data.data.data.row;
        this.pagination.total = parseInt(data.data.data.totalNum);
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.table_btn {
  margin: 10px 0px;
}
.pagination {
  margin-top: 15px;
}
</style>
