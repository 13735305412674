<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">合作伙伴</span>
                <span slot="third">合作伙伴列表</span>
            </top-bread>
        </div>

        <el-card class="box-card">
            <div class="title chartTitle blueTitle">待配置酒店列表</div>

            <table-list :table="table" ref="table"
                        @page="getNewPage"
                        @limit="getNewLimit"
                        @tableSelect="getTableSelect"
                        @tableMeth="tableMeth" v-loading="table.loading"></table-list>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import FileSaver from "file-saver";
    import XLSX from "xlsx";
    import commonMeth from '../../api/method'
    export default {
        name: "CoopList",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
            },
            table:{
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                name:'toBeConfiguredHotel',
                fromUrl:'toBeConfiguredHotel',
                otherFromUrl:'toBeConfiguredHotel',
                option:true,
                select:true,
                loading:true
            },

        }),
        methods:{
            tableMeth(type){
                this.table.loading=true;
                var sortStr='id';
                var orderStr='DESC';
                this.$http.get(this.global.getConfigureHotelList,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        per_page:this.table.limit,
                        limit:this.table.limit,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res=>{
                    if(type==='mounted'){
                        this.table.page=sessionStorage.getItem('filterPage')*1;
                    }
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch((res)=>{
                    this.$message.error(res.data.msg);
                    this.table.loading=false;
                })
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getTableSelect(val){
                var delIds='';
                var partnerCode='';
                val.forEach((item,index)=>{
                    delIds+=item.id+',';
                    partnerCode+=item.code+',';
                });
                this.delIds=delIds.slice(0,-1);
                this.partnerCode=partnerCode.slice(0,-1);
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.tableMeth();
        },
        computed:{

        }
    }
</script>

<style scoped lang="scss">

    .el-col .el-select{
        width:100%;
    }
    .rightBtn{
        margin-left:auto;
    }
</style>
