<template>
  <div>
    <div class="btnRow">
      <!-- <el-button type="primary" class="largeBtn" @click="$refs.APILogDetail.open('新增',query.hotelName)">添 加</el-button> -->
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <APILogDetail ref="APILogDetail"></APILogDetail>
  </div>
</template>
<script>
import { getList,allDelete} from "../api/operationLog/APILog";
// import APILogDetail from "./components/APILogDetail.vue";
export default {
  props: {
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  name: "TvList",
  components: {
    // APILogDetail,
    // TvExport
  },
  data() {
    return {
      tableData: {
        tableList: [ ],
        checked: false,
        tableLoading: false,
        page: 1,
        size: 2,
        props: [
          {
            label: "编号",
            prop: "other",
            minWidth: "50",
            type:"text",
          },
          {
            label: "房间号",
            prop: "roomNo",
            minWidth: "80",
            type:"text",
          },
          {
            label: "时间",
            prop: "stime",
            minWidth: "120",
            type:"text",
          },
          {
            label: "状态",
            prop: "stateText",
            minWidth: "80",
            type:"text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            info: (row) => {
              // this.$refs.APILogDetail.open('查看日志',this.query.hotelName,row)；
              window.open(row.url,'_blank')
            }
          },
        ],
      },
      listQuery: {
        database: this.query.hotelName,
        hotel_contract: this.query.hotel_contract,
        ipaddr: this.query.ipaddr,
        page: 1,
        size: 10
      },
      checkList: [],
    };
  },
  methods: {
    changeSize(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
      
    },
    changeCurrent(s, p) {
      this.listQuery.page = p ? p : 1,
      this.listQuery.size = s ? s : 10,
      this.getList();
    },
    changeSelection(val) {
        this.checkList = val.map(v=>{
          return v.id
        })
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
      }
      getList(this.listQuery).then(res => {
        if (res.data.code == 100000) {

          let typeList = {
            98: '终端已获取',
            1: '终端已获取',
            2: '已执行',
            3: '已完成',
            99: '已执行',
          }
          this.$refs.operationTable.changeTotal(Number(res.data.data.totalNum));
          if (res.data.data.totalNum > 0) {
            res.data.data.row.forEach(v => {
              if (typeList[v.state]) {
                 v.stateText = typeList[v.state] 
              } else {
                v.stateText = '指令已发'
              }
           
            });
            this.tableData.tableList = res.data.data.row;
          } else {
            this.tableData.tableList = []
          }
        }
        this.tableData.tableLoading = false;
      })
    },
    deleteData() {
      if (this.checkList.length <= 0 ) {
        return this.$message.error('请选择服务配置');
      }
      this.$confirm('此操作将永久删除服务配置？, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          allDelete(
            {
              ids:`${this.checkList.join()}`,
              database:this.listQuery.database
            }).then(res => {
              if (res.data.code === 100000) {
                this.getList();
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
              } else {
                this.$message.error(es.data.msg)
              }
          })
        }).catch(() => {
        });
    },
  },
  mounted(){
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map(i=>{
      if(i.ltype==1){
        i.language ="中文"
      }else if(i.ltype==2){
         i.language ="英文"
      }else if(i.ltype==3){
         i.language ="日文"
      }else if(i.ltype==4){
         i.language ="韩文"
      }
      return i
    })
  },
  watch: {
    query: {
      handler: function (newVal) {
       this.query=newVal;
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
