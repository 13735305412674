import request from '@/api/request';
import qs from "querystring";

//   获取版本列表
export function getVersionControl(param) {
    return request({
        url: `v2/Content/versionControl`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   版本控制
export function addVersion(param) {
    return request({
        url: `v2/Content/addVersion`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   版本修改
export function editVersion(param) {
    return request({
        url: `v2/Content/editVersion`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取完成详情
export function getFinishUpdateRooms(param) {
    return request({
        url: `v2/Content/finishUpdateRooms`,
        method: 'post',
        data: qs.stringify(param),
    });
}