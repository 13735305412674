<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">角色管理</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card">
            <div class="title chartTitle blueTitle">角色管理列表</div>
            <el-row  class="btnRow">
                <div>
                    <el-button class="largeBtn" type="danger" @click="confirmMeth('del','删除')" size="medium" v-if="showFunModel('删除')">删除</el-button>
                </div>
                <el-button type="primary" @click="newBuild" size="medium" class="rightBtn largeBtn" v-if="showFunModel('新建')">新建</el-button>
            </el-row>
            <table-list
                    :table="table"
                    :roleDialog="roleDialog"
                    @tableMeth="tableMeth"
                    @page="getNewPage"
                    @limit="getNewLimit"
                    @tableSelect="getTableSelect"
                    @rowId="getRowId"
                    @dialogState="getDialogState"
                    v-loading="table.loading"></table-list>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import commonMeth from '../../api/method'
    export default {
        name: "SysRoleMan",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
            },
            tab:'basic',
            showData:false,
            allData:{},
            dateRange:'',
            token:'',
            auth:'',
            treeList:[
                {
                    id:101,
                    authName:'酒店管理',
                    path:null,
                    pid:0,
                    children:[
                        {
                            id:104,
                            authName:'所有酒店列表',
                            path:null,
                            pid:101,
                            children:[
                                {
                                    id:105,
                                    authName:'新建按钮',
                                    path:null,
                                    pid:'101,104'
                                }
                            ]
                        }
                    ]

                }
            ],
            table:{
                tableHeader:[
                    {prop:'name',label:'角色名称',width:'350'},
                    {prop:'num',label:'用户数',width:''},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'sysRoleMan',
                name:'sysRoleMan',
                option:true,
                select:true,
                loading:false,
                pageShow:true,
                dialogShow:true
            },
            topSearchShow:{},
            operateIds:'',
            roleDialog:{
                dialogVisible: false,
            },
            condition:{},
            allFunData:{},
            funArr:[],
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            topSearchSave:{},
            allLoadingState:false,
        }),
        methods:{
            getDetail(){
                this.$http.get(this.global.getAccountDetailByCurrentAdmin,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.allData=res.data.data;
                        this.showData=true;
                        // this.topAttr.city=res.data.data;
                    }
                });
            },
            tableMeth(orderVal) {
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }
                this.$http.get(this.global.indexWithPage, {
                    params: {
                        token: this.token,
                        page: this.table.page,
                        limit: this.table.limit,
                        code:this.condition.partner_id,
                        group_name:this.condition.keyword,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        this.table.total = parseInt(tableData.total);
                        console.log(this.table.tableList);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading = false;
                }).catch((res) => {
                    this.$message.error(res.data.msg);
                    this.table.loading = false;
                })
            },
            getTableSelect(val){
                var operateIds='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+','
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            getNewPage(newPage) {
                this.table.page = newPage;
                this.table.loading = true;
                this.tableMeth();
            },
            getNewLimit(newLimit) {
                this.table.limit = newLimit;
                this.table.loading = true;
                this.tableMeth();
            },
            getTopSearchShow(type) {
                var auth = JSON.parse(sessionStorage.getItem('auth'));
                var auth_type = auth.type;
                this.topSearchShow = {
                    coopType: true,
                    // role:true,
                    keyword:true,
                };
                this.topSearchShow.keywordPlaceholder='请输入角色名称关键词';
            },
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.delMeth();
                    })
                }else{
                    this.$message({
                        message: '请选择角色',
                        type: 'warning'
                    });
                }
            },
            delMeth(){
                this.$http.get(this.global.groupDel,{
                    params:{
                        token:this.token,
                        ids:this.operateIds
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('删除成功');
                        this.tableMeth();
                    }else {
                        this.$message.error(res.data.code)
                    }
                });
            },
            newBuild(){
                this.$router.push({path:'/SysRoleManNew'})
            },
            getCondition(val,filterPage,filterLimit,type) {
                this.condition.partner_id=Array.isArray(val.noTypePartnerCode)?val.noTypePartnerCode.join(','):'';
                this.condition.keyword = val.keyword;
                this.tableMeth();
            },
            getRowId(val){

            },
            getDialogState(newVal){
                this.roleDialog.dialogVisible=newVal;
            },
            getFoldMeth(val){
                this.fold=val;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
            },
            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                this.getCondition(filterCon,filterPage*1,filterLimit*1,'mounted');
            }

        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            var query=this.$route.query;
            var auth=JSON.parse(sessionStorage.getItem('auth'));
            this.auth=auth;
            if(query.fromType==='complete'){
                this.tab='identify'
            }
            this.getFilterCon();
            this.getDetail();
            this.getTopSearchShow();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
                this.table.option=res.flag;
            });
        },
        computed:{
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading);
            },
        }
    }
</script>

<style scoped lang="scss">
    .rightBtn{
        margin-left:auto;
    }
    .roleAccount .el-col{
        /*margin-bottom:15px;*/
        padding-bottom:5px!important;
    }
    .largeBtn{
        width: 100px;height: 40px;
    }

</style>
