<!-- 包名类型添加/修改弹窗 -->
<template>
  <el-dialog
    title="包名管理"
    :visible.sync="dialogVisible"
    width="550px"
    @open="open"
    @closed="handleClose"
  >
    <el-form :model="form" ref="form" label-width="100px">
      <el-form-item label="包名类型：" prop="type_name">
        <el-input v-model="form.type_name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="排序：" prop="attrMorder">
        <el-input v-model="form.attrMorder" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { addEdit } from "../api/packageType";
export default {
  name: "packageTypeOper",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      auth:null,
      dialogVisible: this.visible,
      form: {
        type_name: "",
        attrMorder: "",
      },
    };
  },
  methods: {
    open() {
      let obj = { ...this.current };
      if (JSON.stringify(this.current) != "{}") {
        this.form.type_name = obj.type_name;
        this.form.attrMorder = obj.morder;
      } else {
        this.form = {
          type_name: "",
          attrMorder: "",
        };
      }
    },
    submit() {
      let params = { ...this.form };
         params.user_name = this.auth.user_name;
      if (JSON.stringify(this.current) != "{}") {
        params.id = this.current.id;
      }
      addEdit(params).then((data) => {
        if (data.data.code == 100000) {
          this.$message.success(data.data.msg);
          this.dialogVisible = false;
          this.$emit("refresh");
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {this.auth = JSON.parse(sessionStorage.getItem("auth"));},
};
</script>
<style lang="scss" scoped></style>
