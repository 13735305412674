<template>
    <div class="root" :class="[$attrs.onlyShowModule?'onlyShowModule':'']">
       
        <div class='imgBox borderBox' @mouseenter.stop="modelShowBtnMeth('modelImg')"
             :class="[modelShowBtnName==='modelImg'?'active':'',modelHighIndexClass('modelImg')]"
             @click="fullClick" >
            <img :src="welPage.url"  v-if="welPage.type==='pic' || welPage.type==='img'" alt="">
              <div class="player" v-if="flag"><i class="el-icon-video-play"></i></div>
            <div class="player paused"  v-if="!flag"><i class="el-icon-video-pause"></i></div>
            <video ref="videoPlayer" title="标题" :src="welPage.url"
                   v-if="welPage.type==='video'" @click="videoSwitch"  controls width="100%"></video>
                
            <div class='textBox borderBox'
                 @mouseenter.stop="modelShowBtnMeth('modelText')"
                 :class="[welPage.coBack,welPage.align,welPage.po,!welPage.disabled?'coTrans':'',modelShowBtnName==='modelText'?'active':'',modelHighIndexClass('modelText'),modelHighIndexClass('firstLine'),modelHighIndexClass('secondLine')]">
                <el-form :model="welPage" class="noBgInput" v-if="welPage.disabled">
                    <div @click.stop="showShadeMeth('firstLine')" @mouseover="modelShowBtnMeth('firstLine')" :class="[modelHighIndexClass('firstLine')]">
                        <el-input  class="textBorderBox noDisabledColor"  v-model="welPage.firstLine" @input="inputMeth('firstLine')"
                                   placeholder="请输入第一行文字，不能超过30个字符" :disabled="$attrs.onlyShowModule"
                                   @focus="focusTextBoxMeth('firstLine')"
                                   @blur="blurTextBoxMeth"
                                   :class="{focusTextBox:curFocusTextBox==='firstLine'}"></el-input>
                    </div>
                    <div @click.stop="showShadeMeth('secondLine')" @mouseover="modelShowBtnMeth('secondLine')" :class="[modelHighIndexClass('secondLine')]">
                        <el-input class="textBorderBox noDisabledColor"  v-model="welPage.secondLine" @input="inputMeth('secondLine')"
                                  placeholder="请输入第二行文字，不能超过30个字符" :disabled="$attrs.onlyShowModule"
                                  v-if="hotelTemplateTypeCom"
                                  @focus="focusTextBoxMeth('secondLine')"
                                  @blur="blurTextBoxMeth"
                                  :class="{focusTextBox:curFocusTextBox==='secondLine'}"></el-input>
                    </div>
                </el-form>
                <div class="configBox modelText highIndex">
                    <div class="slideBox animate__animated" v-if="modelTextData.configShow">
                        <div class="btnGroup">
                            <div class="btnGroupItem" v-if="welPage.disabled">
                                <div class="btnItem" @click.stop="showDetailMeth('colorDetailShow')">配色
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up color_match animate__animated" v-if="modelTextData.colorDetailShow">
                                    <div class="item co_transparent" :class="coActiveClass('coTrans')" id='coTrans' @click.stop="clickCo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/color_match_trans.png" alt=""></span>
                                        <span  class="noWarp">全透</span>
                                    </div>
                                    <div class="item  co_black"  :class="coActiveClass('coBlack')" id='coBlack' @click.stop="clickCo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/color_match_black.png" alt=""></span>
                                        <span class="noWarp">黑色</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem" v-if="welPage.disabled">
                                <div class="btnItem" @click.stop="showDetailMeth('alignDetailShow')">
                                    对齐
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up align_mode animate__animated" v-if="modelTextData.alignDetailShow">
                                    <div class="item po_left" :class="alignActiveClass('alignLeft')" id="alignLeft" @click.stop="clickAlign">
                                        <i class="iconfont icon-zuoduiqi"></i>
                                    </div>
                                    <div class="item po_center" :class="alignActiveClass('alignCenter')" id="alignCenter" @click.stop="clickAlign">
                                        <i class="iconfont icon-733bianjiqi_juzhongduiqi"></i>
                                    </div>
                                    <div class="item po_right" :class="alignActiveClass('alignRight')" id="alignRight" @click.stop="clickAlign">
                                        <i class="iconfont icon-youduiqi"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem" v-if="welPage.disabled">
                                <div class="btnItem" @click.stop="showDetailMeth('poDetailShow')">
                                    位置
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up position_mode animate__animated" v-if="modelTextData.poDetailShow">
                                    <div class="item po_top" :class="poActiveClass('poTop')" id="poTop" @click.stop="clickPo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/po_top.png" alt=""></span>
                                        <span class="itemText">居上</span>
                                    </div>
                                    <div class="item po_middle"  :class="poActiveClass('poCenter')" id="poCenter" @click.stop="clickPo">
                                        <span class="check_icon"> <i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/po_center.png" alt=""></span>
                                        <span class="itemText">居中</span>
                                    </div>
                                    <div class="item  po_bottom"  :class="poActiveClass('poBottom')" id="poBottom" @click.stop="clickPo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/po_bottom.png" alt=""></span>
                                        <span class="itemText">居下</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem">
                                <div class="btnItem" @click.stop="showDetailMeth('stateDetailShow')">
                                    显示状态
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up position_mode animate__animated" v-if="modelTextData.stateDetailShow">
                                    <div class="item po_top" :class="stateActiveClass(true)" id="stateShow" @click.stop="clickState">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="itemText">显示</span>
                                    </div>
                                    <div class="item po_middle"  :class="stateActiveClass(false)" id="stateHide" @click.stop="clickState">
                                        <span class="check_icon"> <i class="el-icon-check"></i></span>
                                        <span class="itemText">隐藏</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem">
                                <div class="btnItem" @click="resetItemMeth('modelText')">恢复</div>
                            </div>
                        </div>
                    </div>
                    <div class="btnItem highIndex configBtn" @mouseenter.stop="modelShowBtnMeth('modelText')" @click.stop="slideConfigBox('modelText')" v-if="hotelTemplateTypeCom">配置</div>
                </div>
            </div>
            <div class="tips">
                <p class="tipsFirst">按“OK”键进入>></p>
                <p class="tipsSecond">
                    即为同意 <span class="redText">账号使用规则和隐私政策 </span> &  <span class="redText">©</span>
                </p>
                 
            </div>
            <div class="configBox modelText modelImg highIndex animate__animated" style="z-index:102!important;">
                <div class="btnItem highIndex configBtn" @click.stop="slideConfigBox('modelImg')" >配置</div>
                <div class="slideBox animate__animated" v-if="modelImgData.configShow">
                    <div class="btnGroup">
                        <div class="btnGroupItem" v-if="hotelTemplateTypeCom">
                            <div class="btnItem" @click.stop="showDetailMeth('modelImg','moreDetailShow')">更多素材
                                <i class="el-icon-caret-top arrowIcon" ></i>
                            </div>
                            <div class="detail_box up more_file animate__animated highIndex" v-if="modelImgData.moreDetailShow">
                                <div class="item upload" @click.stop="uploadMaterial('modelImg','img')" :class="modelMoreActiveClass('modelImg','img')" >
                                    <span>上传图片</span>
                                </div>
                                <div class="item upload" @click.stop="uploadMaterial('modelImg','video')" :class="modelMoreActiveClass('modelImg','video')">
                                    <span>上传视频</span>
                                </div>
                            </div>
                        </div>
                        <div class="btnGroupItem" v-if="!hotelTemplateTypeCom">
                            <div class="btnItem" @click.stop="uploadMaterial('modelImg','video')">上传视频
                                <i class="el-icon-caret-top arrowIcon" ></i>
                            </div>
                        </div>
                        <div class="btnGroupItem">
                            <div class="btnItem" @click="resetItemMeth('modelImg')">恢复</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="shade animate__animated" @click="hideShade" v-if="shadeShow"></div>
        </div>
        <upload-dialog
                :dialogParData="dialogUpload"
                @uploadMaterialUrl="getUploadMaterialUrl" @changeDialogVisible="changeDialogVisible" @editTabLoadingMeth="editTabLoadingMeth"
                :hotelContract="hotelContract"></upload-dialog>
    </div>
</template>

<script>
    import commonMeth from '../../../../api/method'
    export default {
        name: "EditEelDetail",
        props:['hotelContract','welPageProp','onlyShow','hotelTemplateType','modelReadOnly'],
        data:()=>({
            modelImgData:{
                configShow:false,
                moreType:'',
                moreDetailShow:false,
                arrowDirection:'up'
            },
            modelTextData:{
                configShow:false,
                colorDetailShow:false,
                alignDetailShow:false,
                poDetailShow:false,
                stateDetailShow:false,
            },
            welPage:{
                url:'',
                fileName:'',
                firstLine:'',
                secondLine:'',
                coBack:'',
                align:'',
                po:'',
                disabled:false,
                type:'',
            },
            defaultData:{
                url:'',
                firstLine:'',
                secondLine:'',
                coBack:'',
                align:'',
                po:'',
                disabled:'',
                type:'',
                title:'',
            },
            modelShowBtnName:'',
            dialogUpload:{
                visible:false,
                agreenActive:'',
                chooseItemIndex:'',
                uploadModel:'',
                uploadMaterialType:'',
                uploadMaterialUrl:''
            },
            shadeShow:false,
            uploadAccept:'',
            uploadAcceptImg:'.jpg,.jpeg,.png,JPG,.JPEG',
            uploadAcceptVideo:'.mp4',
            textAlign:'center',
            curFocusTextBox:'',
            query:{},
             flag:true
        }),
        methods:{
               videoSwitch(){
                var flag = this.$refs.videoPlayer.paused;
                this.flag = flag;
            },
            showDetailMeth(val){
                var modelTextData=this.modelTextData;
                if(val==='colorDetailShow'){
                    modelTextData.colorDetailShow=!modelTextData.colorDetailShow;
                    modelTextData.alignDetailShow=false;
                    modelTextData.poDetailShow=false;
                    modelTextData.stateDetailShow=false;
                }else if(val==='alignDetailShow'){
                    modelTextData.alignDetailShow=!modelTextData.alignDetailShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.poDetailShow=false;
                    modelTextData.stateDetailShow=false;
                }else if(val==='poDetailShow'){
                    modelTextData.poDetailShow=!modelTextData.poDetailShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.alignDetailShow=false;
                    modelTextData.stateDetailShow=false;
                }else if(val==='stateDetailShow'){
                    modelTextData.stateDetailShow=!modelTextData.stateDetailShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.alignDetailShow=false;
                    modelTextData.poDetailShow=false;
                }else if(val==='modelImg'){
                    this.modelImgData.moreDetailShow=!this.modelImgData.moreDetailShow;
                }
            },
            clickCo(e) {
                this.welPage.disabled ? this.welPage.coBack = e.currentTarget.id : "";
            },
            clickAlign(e) {
                this.welPage.disabled ? this.welPage.align = e.currentTarget.id : "";
            },
            clickPo(e) {
                this.welPage.disabled ? this.welPage.po = e.currentTarget.id : "";
            },
            clickState(e) {
                e.currentTarget.id==='stateShow'
                    ?this.welPage.disabled=true
                    :this.welPage.disabled=false;
            },
            showMeth(val){
                val==='hide'?this.welPage.disabled=false:this.welPage.disabled=true;
                this.resetDetailShow();
            },
            fullClick(){
                var modelTextData=this.modelTextData;
                modelTextData.colorDetailShow=false;
                modelTextData.alignDetailShow=false;
                modelTextData.poDetailShow=false;
            },
            slideConfigBox(type){
                var modelImgData=this.modelImgData;
                var modelTextData=this.modelTextData;
                if(type==='modelImg'){
                    modelImgData.moreType='';
                    modelImgData.configShow=!modelImgData.configShow;
                    modelImgData.moreDetailShow=false;
                    modelTextData.configShow=false;
                    this.shadeShow=false;
                }else if(type==='modelText'){
                    modelTextData.configShow=!modelTextData.configShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.alignDetailShow=false;
                    modelTextData.poDetailShow=false;
                    modelTextData.stateDetailShow=false;
                    modelImgData.configShow=false;
                    if(modelTextData.configShow){
                        this.shadeShow=true;
                    }else{
                        this.shadeShow=false;
                    }
                }
            },
            resetDetailShow(){
                var modelTextData=this.modelTextData;
                modelTextData.colorDetailShow=false;
                modelTextData.alignDetailShow=false;
                modelTextData.poDetailShow=false;
            },
            modelShowBtnMeth(type){
                if(type==='modelImg'){
                    if(!this.shadeShow){
                        this.modelShowBtnName = type;
                    }
                }else{
                    this.modelShowBtnName = type;
                }
            },
            clearShowBtnName(){
                this.modelShowBtnName='';
                this.shadeShow=false;
                this.modelImgData.configShow=false;
                this.modelTextData.configShow=false;
            },
            uploadMaterial(modelType,materialType){
                this.modelImgData.moreType=materialType;
                var dialogUpload=this.dialogUpload;
                dialogUpload.uploadModel=modelType;
                dialogUpload.uploadMaterialType=materialType;
                dialogUpload.visible=true;
            },
            resetDialog(){
                var dialogUpload=this.dialogUpload;
                dialogUpload.chooseItemIndex='';
                dialogUpload.uploadModel='';
                dialogUpload.uploadMaterialType='';
                dialogUpload.uploadMaterialUrl='';
            },
            getUploadMaterialUrl(url,fileName){
                this.welPage.type=this.modelImgData.moreType;
                this.welPage.url=url;
                this.welPage.fileName=fileName;
                this.dialogUpload.visible=false;
            },
            defaultDataMeth(){
                this.shadeShow=false;
                this.welPage=commonMeth.objDataAssign(this.defaultData);
                this.welPage.fileName='';
                if(this.hotelTemplateType==='ehotel'){
                    this.welPage.align='alignCenter';
                    this.welPage.coBack='coBlack';
                    this.welPage.po='poBottom';
                }
            },
            divEditChange(newVal){
                this.welPage.firstLine=document.getElementById('firstLine').innerHTML;
                this.welPage.secondLine=document.getElementById('secondLine').innerHTML;
            },
            hideShade(){
                this.shadeShow=false;
                this.modelImgData.configShow=false;
                this.modelTextData.configShow=false;
            },
            getData(){
                this.defaultData=commonMeth.objDataAssign(this.welPageProp);
            },
            cancelMeth(){
                this.dialogUpload.visible=false;
            },
            resetItemMeth(type){
                var welPage=this.welPage;
                var defaultData=this.defaultData;
                if(type==='modelImg'){
                    welPage.url=defaultData.url;
                    welPage.fileName='';
                    welPage.type=defaultData.type;
                }else if(type==='modelText'){
                    welPage.disabled=defaultData.disabled;
                    welPage.firstLine=defaultData.firstLine;
                    welPage.secondLine=defaultData.secondLine;
                    welPage.coBack=defaultData.coBack;
                    welPage.align=defaultData.align;
                    welPage.po=defaultData.po;
                }
            },
            clearFileName(){
                this.welPage.fileName='';
            },
            editTabLoadingMeth(val,type){
                this.$emit('editTabLoadingMeth', val,type);
            },
            changeDialogVisible(val){
                this.dialogUpload.visible=val;
            },
            showShadeMeth(){
                if(!this.$attrs.onlyShowModule){
                    this.shadeShow=true;
                }
            },
            focusTextBoxMeth(val){
                this.curFocusTextBox=val;
            },
            blurTextBoxMeth(){
                this.curFocusTextBox='';
            },
            mergeData(){
                var welcomePageMerge={};
                var welPage=this.welPage;
                var defaultData=this.defaultData;
                welcomePageMerge.url=welPage.url;
                welcomePageMerge.fileName=welPage.fileName;
                welcomePageMerge.firstLine=welPage.firstLine;
                welcomePageMerge.secondLine=welPage.secondLine;
                welcomePageMerge.coBack=welPage.coBack;
                welcomePageMerge.align=welPage.align;
                welcomePageMerge.po=welPage.po;
                welcomePageMerge.disabled=welPage.disabled;
                welcomePageMerge.type='最后title';
                return welcomePageMerge;
            },
            inputMeth(val){
                if(val==='firstLine'){
                    var firstLine=this.welPage.firstLine;
                    if(firstLine.length>30){
                        this.$message.warning('输入内容不能超过30个字符');
                        this.welPage.firstLine=firstLine.slice(0,30);
                    }
                }else{
                    var secondLine=this.welPage.secondLine;
                    if(secondLine.length>30){
                        this.$message.warning('输入内容不能超过30个字符');
                        this.welPage.secondLine=secondLine.slice(0,30);
                    }
                }

            }
        },
        mounted(){
            this.getData();
            this.defaultDataMeth();
        },
        computed:{
            coActiveClass() {
                return function(msg) {
                    // return this.welPage.coBack.trim() === msg.trim() ? 'active' : ''
                    return this.welPage.coBack === msg? 'active' : ''
                }
            },
            alignActiveClass() {
                return function(msg) {
                    return this.welPage.align === msg ? 'active' : ''
                }
            },
            poActiveClass() {
                return function(msg) {
                    return this.welPage.po === msg ? 'active' : ''
                }
            },
            stateActiveClass() {
                return function(msg) {
                    return this.welPage.disabled === msg ? 'active' : ''
                }
            },
            configBtnClass(){
                return function(msg) {
                    return this.modelShowBtnName === msg ? 'animate__fadeOut' : 'animate__fadeIn'
                }
            },
            animateClass() {
                return function(val,direction) {
                    var className='';
                    if(direction==='down'){
                        if(val){
                            className='animate__fadeInDown';
                        }else{
                            className='animate__fadeOutRight noneEl';
                        }
                    }else if(direction==='up'){
                        if(val){
                            className='animate__fadeInUp';
                        }else{
                            className='animate__fadeOutRight noneEl';
                        }
                    }else if(direction==='right'){
                        if(val){
                            className='animate__fadeInRight';
                        }else{
                            className='animate__fadeOutRight noneEl';
                        }
                    }
                    return className;
                }
            },
            modelMoreActiveClass() {
                return function(modelType,materialType) {
                    return this.modelImgData.moreType === materialType ? 'active' : ''
                }
            },
            shadeShowClass(){
                return this.shadeShow  ? 'animate__fadeIn highIndex' : 'animate__fadeOut noneEl'
            },
            hotelTemplateTypeCom(){
                var flag=false;
                this.hotelTemplateType==='ehotel'
                ?flag=false
                    :flag=true;
                return flag;
            },
            modelHighIndexClass() {
                return function (msg) {
                    return this.modelShowBtnName === msg ? ' highIndex' : ' '
                }
            },
        },
        watch:{
             hotelContract:{
                  handler: function (newVal) {
                          this.hotelContract=newVal
                        this.getData();
                        this.defaultDataMeth();
                },
                deep: true,
                immediate: true, 
            },
             welPageProp:{
                 handler: function (newVal) {
                          this.welPageProp=newVal
                        this.getData();
                         if(this.$refs.videoPlayer){
                               var flag = this.$refs.videoPlayer.paused;
                        this.flag = flag;
                        }
                        this.defaultDataMeth();
                },
                deep: true,
                immediate: true, 
            },
             onlyShow:{
                 handler: function (newVal) {
                          this.onlyShow=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelTemplateType:{
                 handler: function (newVal) {
                          this.hotelTemplateType=newVal
                },
                deep: true,
                immediate: true, 
            },
             modelReadOnly:{
                 handler: function (newVal) {
                          this.modelReadOnly=newVal
                },
                deep: true,
                immediate: true, 
            },
            activeName(newVal){
                this.$nextTick(()=>{
                    this.$refs[`${newVal}Chart`].resize();
                })
            },
            modelShowBtnName(newVal){
                if(newVal!=='modelImg'){
                    // this.modelImgData.configShow=false;
                }
                if(newVal!=='modelText'){
                    // this.modelTextData.configShow=false;
                }
            },

        }
    }
</script>

<style scoped>
    @import "../../../../../node_modules/animate.css/animate.css";
    .root{

    }
    .imgBox {
        /*width: 80%;*/
        /*height: 596px;*/
        /*overflow: hidden;*/
        width:1152px;
        height:648px;
        position: relative;
     
    }
    .imgBox img,.imgBox video {
        width: 100%;
        height: 100%;
    }
    .textBox {
        width: 100%;
        height: 140px;
        padding: 0 30px;
        display: flex;
        /*align-items: space-around;*/
        position: absolute;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        transition: 0.5s;
        z-index:5;
    }
    .textBox.test{
        width:100px;
        height:100px;
        background:red!important;
        position:absolute;
        top:0!important;
        right:0!important;
    }
    .textBox.whiteBorder{
        border: 2px dashed #fff;
    }
    .textBox.noBorder {
        border: none;
    }
    .textBox .firstLine {
        margin-bottom: 20px;
    }

    .textBox .textLine {
        width: 100%;
        color: white;
        font-size: 18px;
        letter-spacing: 2px;
        transition: 0.5s;
        outline:none;
    }

    /* 背景色 */
    .textBox.coTrans {
        background: none!important;
        transition: 0.5s;
    }

    .textBox.coBlack {
        background: rgba(0, 0, 0, 0.6);
        transition: 0.5s;
    }

    .textBox.coWhite {
        background: rgba(0, 0, 0, 0.4);
        transition: 0.5s;
    }

    /* 文字对齐 */
    .textBox.alignLeft input {
        text-align: left;
        transition: 0.5s;
    }

    .textBox.alignCenter input {
        text-align: center;
        transition: 0.5s;
    }

    .textBox.alignRight input {
        text-align: right;
        transition: 0.5s;
    }

    /* 文字位置 */
    .textBox.poTop {
        top: 0;
        transition: 0.5s;
    }

    .textBox.poCenter {
        top: 248px;
        transition: 0.5s;
    }
    .textBox{
        transition: 0.5s;
    }

    .textBox.poBottom {
        top:433px;
        transition: 0.5s;
    }
    .configBox{
        display:flex;
        position:absolute;
    }
    .configBox.modelText{
        top:-50px;
        right:50px;
    }
    .configBox.modelImg{
      top:0;
        left:10px;
    }
    .configBox .btnItem{
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.3)!important;
        cursor:pointer;
    }
    .slideBox{
        transition:2s;
    }
    .btnGroup{
        display:inline-block;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
    }

    .detail_box{
        /*background:rgba(0,0,0,0.6);*/
        background:#252527;
        padding:5px 0;
    }
    .detail_box.up{
        position:absolute;
        left:0;
        bottom:40px;
    }
    .detail_box .item{
        color:#fff;
        cursor:pointer;
    }

    /*配色详情*/
    /*.color_match,.position_mode{*/
        /*overflow:hidden;*/
        /*padding:5px 0;*/
        /*background:rgba(0,0,0,0.4);*/
        /*position:absolute;*/
        /*!*top:-130px;*!*/
        /*display:none;*/
        /*border-radius:2px;*/

    /*}*/
    /*.more_file{*/
        /*overflow:hidden;*/
        /*padding:5px 0;*/
        /*background:rgba(0,0,0,0.4);*/
        /*position:absolute;*/
        /*!*top:-130px;*!*/
        /*display:none;*/
        /*border-radius:2px;*/
    /*}*/
    /*.color_match.active,.more_file.active{*/
        /*!*display:block;*!*/
    /*}*/
    .color_match>.item,.more_file>.item,.position_mode>.item{
        width:100%;
        height:36px;
        padding:12px 20px;
        display:flex;
        align-items: center;
        font-size:14px;
        box-sizing:border-box;
    }
    .color_match>.item.active,.more_file>.item.active,.position_mode>.item.active{
        background:#1b90ed;
    }
    .item.active .check_icon{
        visibility: visible;
    }

    .item .check_icon{
        visibility: hidden;
    }

    .color_match>.item>.check_icon,.position_mode>.item>.check_icon{
        width:18px;
        height:20px;
        margin-right:10px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .color_match>.item>.show_icon,.position_mode>.item>.show_icon{
        width:44px;
        height:24px;
        margin-right:10px;
        display:inline-block;
    }
    .none{
        display:none!important;
    }
    /*对齐方式*/
    .align_mode{
        width:82px;
        /*height:86px;*/
        padding:5px 5px;
        box-sizing:border-box;
        background:rgba(0,0,0,0.4);
        overflow:hidden;
        /*display:none;*/
        border-radius:2px;
    }
    .align_mode>.item{
        width:36px;
        height:36px;
        border-radius:5px;
        float:left;
        box-sizing:border-box;
        padding:5px;
    }
    .align_mode>.item.active{
        background:#fff;
        color:#333;
    }
    /*位置按钮*/
    .position_mode{
        /*display:none;*/
    }
    /*显示按钮详情*/
    .detail_box.active{
        /*display:block;*/
    }
    .detail_box{
        background:rgba(0,0,0,0.6);
    }
    div.upload_tab_box{
        width:100%;
        /*height:400px;*/
        /*background:#b39ddb;*/
        display:flex!important;
        flex-direction:column!important;
        padding:0!important;
        margin:0!important;

    }
    .itemText{
        white-space: nowrap;
    }
    .cloud_icon img{
        width:100%;
        height:100%;
    }
    .gray_tips img{
        width:100%;
        height:100%;
    }
    .highIndex{
        z-index:100;
    }
    .noWarp{
        white-space:nowrap;
    }
    .borderBox{
        border:2px dashed transparent;
        transition: 0.5s;
        cursor:pointer;
    }
    /*.borderBox:hover{*/
        /*border:4px dashed #1b90ed;*/
        /*transition: 0.5s*/
    /*}*/
    .borderBox.active{
        border:2px dashed #1b90ed;
        transition: 0.5s
    }
    .configBtn{
        transition:0.5s;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .shade{
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.6);
        position:absolute;
        top:0;
        left:0;
        z-index:99;
        cursor:pointer;
        transition:2s;
    }
    .noneEl{
        visibility:hidden;
    }
    .editWelBox{
        width:80%;
    }
    .po_center i{
        font-size:24px;
    }
    .po_left i,.po_right i{
        font-size:20px;
    }
    .align_mode>.item{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .btnGroupItem>.btnItem{
        z-index:9999;
    }
    .tips{
        position:absolute;
        bottom:15px;
        right:0;
        height:50px;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right:15px;
        color:#fff;
    }
    .redText{
        color:red;
    }
    .tipsFirst{
        font-size:14px;
        text-align: center;
    }
    .tipsSecond{
        font-size:12px;
        text-align: center;
    }

      .player{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 80px;
        height: 80px;
        border-radius: 40px;
        font-size: 80px;
        line-height: 75px;
        text-align: center;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;

    }
    .paused{
        display: none;
    }
    .imgBox:hover .paused{
        display: block;
    }
</style>
