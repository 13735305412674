<template>
    <div>
        <div class="boxBlack" v-if="drawer"></div>
        <el-dialog class="publishListDrawer"
                title="发布列表"
                :visible.sync="drawer"
                width="70%"
                :modal="false"
               >
            <div class="mainBox" style="height:100%;position:relative">
                <div class="tableBox">
                    <el-table :data="tableData" v-loading="loading">
                        <el-table-column prop="dataVersion" label="编号" width="60px" ></el-table-column>
                        <el-table-column prop="intro" label="发布说明" width="230">
                            <template slot-scope="scope">
                                <span>{{scope.row.intro}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="module" label="发布模式" width="200px"></el-table-column>
                        <el-table-column prop="roomStr" label="发布房间" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="stime" label="发布时间" width="200"></el-table-column>
                        <!-- <el-table-column prop="state3" label="已更新终端数" width="150px">
                            <template slot-scope="scope">
                                <span>{{scope.row.state3}}</span>
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="uname" label="发布人" width="180"></el-table-column>
                        <el-table-column prop="operate" label="操作" width="150">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="detail(scope.row),dialogdetail=true">详情</el-button>
                                <!--<el-button type="text" size="small" @click="partMeth(scope.row)">部分</el-button>-->
                                <el-button type="text" size="small" @click="allMeth(scope.row)">全部</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        
                        <el-dialog title="更新状态" :visible.sync="dialogdetail" width="70%" :modal="false">
                            <div class="det_table">
                                <template>
                                    <el-table
                                            :data="details"
                                            border width="900"
                                            class="detail">
                                        <el-table-column prop="detail" label="状态"  width="200" height="200"></el-table-column>
                                        <el-table-column prop="room_num" label="房间号"></el-table-column>
                                    </el-table>
                                </template>
                            </div>
                        </el-dialog>
                    </div>
                </div>
                <div class="drawerFooter">

                    <!--<div class="btnBox">-->
                        <!--<el-button @click="showDrawer(false)" class="largeBtn">取 消</el-button>-->
                        <!--<el-button type="primary" @click="submitMeth" class="largeBtn">保 存</el-button>-->
                    <!--</div>-->
                </div>
            </div>
            <div class="dialogdetailModal" v-if="dialogdetail"></div>
        </el-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import Bus from '../../../../api/Bus'
    export default {
        isActive:false,
        name: "Online",
        props:['showData'],
        data: () => ({
              direction: 'rtl',
            tableData: [],
            details:[],
            dialogdetail:false,
            part: false,
            token: '',
            query:{},
            configData:{},
            loading:false,
            userName:'',
            curInfo:{
                module:'',
                id:''
            },
            drawer: false,
          
        }),
        methods:{
            showDrawer(val){
                this.drawer=val;
            },
            detail(data){
                console.log(data);
                var {state0,state1,state2,state3}=data;
                this.details=[
                    {
                        detail:`未执行（${state0.length}间）`,
                        room_num:state0.join(',')
                    },
                    {
                        detail:`已执行（${state1.length}间）`,
                        room_num:state1.join(',')
                    },
                    {
                        detail:`已获取（${state2.length}间）`,
                        room_num:state2.join(',')
                    },
                    {
                        detail:`已完成（${state3.length}间）`,
                        room_num:state3.join(',')
                    },
                ]
            },
            getHotelConfigInfo(hotelContract) {
                this.loading=true;
                var param = qs.stringify({
                    token: this.token,
                    hotelContract: hotelContract,
                });
                this.$http.post(this.global.getHotelCurrentVisualInfo, param).then(res => {
                    if (res.data.code === 100000) {
                        this.tableData = res.data.data.roomList;
                        this.userName=res.data.auth.user_name;
                    }
                    this.loading=false;
                }).catch(() => {
                    // debugger;
                })
            },
            backMeth(){
                this.$router.push({path:`./hotelManAllDetail`,
                    query:{
                        fromUrl:'hotelManAll',
                        id:this.query.hotelId,
                        hotelContract:this.query.hotelContract,
                        type:'edit',
                        fromEditType:'publishList',
                    }})
            },
            confirmMeth(info) {
                this.$confirm(`确认发布?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.publishPostMeth(info);
                });
            },
            publishPostMeth(info) {
                this.loading=true;
                var str=JSON.stringify({info:info});
                this.$refs.choiceRoomDialog.cancelDialog(false);
                this.$refs.choiceRoomDialog.removeSessionData();
                var param = qs.stringify({
                    token: this.token,
                    hotelContract: this.query.hotelContract,
                    data: str
                });
                this.$http.post(this.global.uploadHotelVisualData, param).then(res => {
                    if (res.data.code === 100000) {
                        this.$message.success('发布成功');
                    } else {
                        this.$message.error('发布失败');
                    }
                    this.loading=false;
                }).catch(() => {
                })
            },
            partMeth(data){
                this.curInfo={
                    intro:data.intro,
                    module:'part',
                    id:data.id,
                };
                this.$refs.choiceRoomDialog.dialogShowMeth(true);
            },
            allMeth(data){
                this.$confirm('确定更新所有房间吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var info={
                        intro:data.intro,
                        module:'all',
                        roomStr:'',
                        uname:this.userNameProp,
                        id:data.id
                    };
                    this.publishPostMeth(info);
                }).catch(() => {
                });
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.query=this.$route.query;
        },
        computed:{
            showDataCom(){
                return function(val){
                    if(val===null){
                        return 0;
                    }else{
                        return parseFloat(val).toLocaleString();
                    }
                }
            },
        },

    }

</script>


<style scoped >
	::v-deep :focus {
		outline: 0;
	}

    .btn{
        position: absolute;
        width: 100px;
        right: 20px;

    }
    .ybtn{
        margin-bottom: 40px;
    }
    .room{
        text-align: center;
        color:#3085FC;
        padding-top: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-left: 27px;
        cursor: pointer;
        height: 30px;
    }
    .room:first-child{
        margin: 0;
    }
    .change{
        color: #3085FC;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
    }
    .roomBox{
        overflow:hidden;
        padding-bottom:90px;
    }
    .roomBox .el-col{
        padding:20px 10px 10px 10px;
        text-align: center;
        float: left;
    }
    .el-col:nth-child(12n){
        padding:20px 0px 10px 20px;
        float: right;

    }
    .el-col:first-child{
        padding:20px 20px 10px 0px;
    }
    .el-col:nth-child(12n+1){
        padding:20px 20px 10px 0;
        float: left;
    }
    .mainBox{
        padding: 0 20px;
    }
    .drawerFooter{
        display: flex;
    }
    .btnBox{
        margin-left:auto;
    }
    .dialogdetailModal{
          position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background: #000;
            z-index: 2001;
    }
    .boxBlack{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background: #000;
            z-index: 2000;
    }
    ::v-deep .el-dialog{   
     height: 100%;
     max-height: calc(100%);
    }
    
 </style>

