<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">酒店管理</span>
        <span slot="third">可提交验收酒店管理</span>
        <span slot="fourth">可验收酒店</span>
        <span slot="fiveth">验收预览</span>
      </top-bread>
    </div>
    <el-card>
      <div class="title blueTitle">验收酒店列表预览</div>
      <div class="btnRow" style="margin-top: 15px">
        <el-button class="largeBtn" type="primary" @click="backMeth">返回</el-button>
        <el-button class="largeBtn" type="primary" @click="readycheck">确认验收</el-button>
      </div>
      <el-table :data="tableData" ref="accountTable" style="width: 100%; margin-top: 10px" :header-cell-style="headerStyle">
        <el-table-column prop="hotel" label="酒店名称" width="300">
        </el-table-column>
        <el-table-column width="220" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
            <el-button type="text" size="mini" @click="skipToManage(scope.row)">跳转</el-button>
            <el-button type="text" size="mini" @click="returnShow(scope.row)" v-if="scope.row.refuse_reason">驳回原因</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="hotelName" width="100" label="程序名称">
        </el-table-column>
        <el-table-column label="验收状态">
          <template slot-scope="scope">
            <span :style="{ background: statusCircleColor(scope.row.check_state) }" v-if="scope.row.check_state" class="statusCircle"></span>
            <span>{{ scope.row.check_state_text }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150" prop="total_num" label="应上线终端数量">
        </el-table-column>
        <el-table-column width="150" prop="n_rooms_connected" label="激活终端数量">
        </el-table-column>
        <el-table-column width="130" prop="dar" label="平均日活率">
        </el-table-column>
        <el-table-column width="130" prop="update_version_num" label="更新版本数量">
        </el-table-column>
        <el-table-column width="150" prop="max_update_nums" label="最大更新终端数量">
        </el-table-column>
        <el-table-column prop="channel_name" width="300" label="所属合作伙伴" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </el-card>
    <el-drawer title="驳回原因" :visible.sync="result_drawer" :direction="direction" :before-close="handleClose" size="45%" :modal="false" :wrapperClosable="false">
      {{result}}
    </el-drawer>
    <el-drawer title="详情" :visible.sync="detail_drawer" :direction="direction" :modal-append-to-body="true" :before-close="handleClose" size="120%" :destroy-on-close="true" :modal="false" :wrapperClosable="false">
      <div class="changeType">
        <div :class="{ active: type == index ? true : false }" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="contentShow" v-show="type == 0">
        <detail-no :id="query.id" v-if="auth.type !== 'htrip' && auth.type !== 'screen'" @topInfo="getTopInfo"></detail-no>
        <detail-juping :id="query.id" v-if="auth.type === 'screen'" @topInfo="getTopInfo"></detail-juping>

        <detail-has :id="query.id" v-if="auth.type === 'htrip'" @topInfo="getTopInfo">
        </detail-has>
      </div>
      <div class="contentShow" v-show="type == 1">
        <all-tab-person :id="query.id" v-if="type == 1"></all-tab-person>
      </div>
      <div class="contentShow" v-show="type == 2">
        <all-tab-online :id="query.id" :showData="true" v-if="type == 2"></all-tab-online>
      </div>
      <div class="contentShow" v-show="type == 3">
        <showfile ref="fileshow" :hotelContract="hotelContract" :showFileData="showFileData"></showfile>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getEnclosureUrls,
  getHotelListByCheckListId,
  sponsorCheckFlow,
} from "@/api/hotel.js";
import uploadfile from "./CPTForUpload/uploadFile";
import showfile from "./CPTForUpload/fileShow";
import qs from "querystring";
export default {
  components: {
    uploadfile,
    showfile,
  },
  data() {
    return {
      bread: {
        level: 5,
      },
      query: {
        id: "",
      },
      typeList: [
        {
          name: "酒店信息",
        },
        {
          name: "实施人员",
        },
        {
          name: "上线情况",
        },
        {
          name: "附件验收",
        },
      ],
      table: {
        limit: 5,
        page: 1,
        total: 0,
      },
      showFileData: {},
      hotelContract: "",
      tableData: [],
      topInfo: {
        hotel: "",
        hotel_use_state_text: "",
        contract_end: "",
        hotel_server_key: "",
      },
      type: 0,
      auth: {},
      result_drawer: false,
      detail_drawer: false,
      direction: "rtl",
      result: "",
      show_detail: true,
      show_result: true,
      show_upload: true,
      selectIdArr: [],
      previewId: null,
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
    };
  },
  methods: {
    // 审核状态标记颜色 抛出
    statusCircleColor(txt) {
      if (txt == 3) {
        return "#FE8637";
      } else if (txt == 7) {
        return "#3774FE";
      } else if (txt == 6) {
        return "#53D439";
      }
    },
    // 详情列表点击
    changeType(index) {
      if (index == 3) {
        this.type = index;
        var json = {
          hotel_contract: this.hotelContract,
        };
        var param = qs.stringify(json);
        getEnclosureUrls(param)
          .then((res) => {
            if ((res.data.code = 100000)) {
              this.showFileData = res.data.data;
              this.$emit("showFileData", this.showFileData);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.type = index;
        return;
      }
    },
    // 获取页面列表
    hoteltable() {
      var json = {
        id: this.previewId,
      };
      var param = qs.stringify(json);
      getHotelListByCheckListId(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData = res.data.data;
          }
        })
        .catch((err) => {});
    },
    getTopInfo() {},
    // 查看详情
    detail(val) {
      this.hotelContract = val.hotel_contract;
      this.query.id = 97;
      this.type = 0;
      this.detail_drawer = true;
      this.result_drawer = false;
    },
    // 查看驳回原因
    returnShow(row) {
      this.result = row.refuse_reason;
      this.result_drawer = true;
      this.detail_drawer = false;
    },
    //  跳转功能 按钮
    skipToManage(data) {
      if (data.skipAddress !== "127.0.0.1//admin") {
        window.open("http://" + data.skipAddress);
      } else {
      }
    },
    // 关闭弹框通用
    handleClose(done) {
      done();
    },
    // table 选取功能
    selectionChange(val) {
      this.selectIdArr = val;
    },
    // 返回上一页
    backMeth() {
      this.$router.go(-1);
    },
    //  验收按钮
    readycheck() {
      const json = {
        id: this.previewId,
      };
      var param = qs.stringify(json);
      sponsorCheckFlow(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.$message.success("发起成功");
            setTimeout(this.backMeth(), 500);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("发起失败");
        });
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    var query = this.$route.query;
    this.previewId = query.id;
    this.hoteltable();
  },
  
};
</script>
<style scoped lang="scss">
::v-deep :focus {
  outline: 0;
}
.bread {
  flex: 1;
}
.result_btn {
  margin-left: 38vw;
  width: 100px;
}
::v-deep .el-drawer__body {
  overflow: scroll;
  padding: 0 20px;
}
.changeType {
  position: absolute;
  left: -85px;
  top: 0px;
  width: 85px;
  height: 220px;
  border: 1px solid #e2e2e2;
  z-index: 999;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}
.changeType div {
  width: 85px;
  height: 55px;
  text-align: center;
  font-size: 14px;
  line-height: 55px;
  cursor: pointer;
}
::v-deep .el-drawer {
  overflow: visible !important;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible !important;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  overflow: scroll;
  padding: 15px 20px 0;
}
.active {
  background-color: #f1f9ff;
  color: #0078ff;
}
::v-deep .v-modal {
  display: none;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.testContentRow {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
}
.statusCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}
.btnRow {
  display: flex;
  justify-content: space-between;
}
</style>