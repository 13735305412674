<template>
  <div>
    <div class="btnRow">
      <el-button class="largeBtn" type="primary">导出</el-button>
      <el-button class="largeBtn1" type="primary" @click="openSearch">{{btnText}}</el-button>
    </div>
    <el-card v-if="serachFalg">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>

    <el-table v-if="num==1" class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" :height="table.limit>=20 &&table.total>10?'550px':'330px'">
      <el-table-column prop="name" label="酒店名称" min-width="300px" fixed></el-table-column>
      <el-table-column min-width="100px" label="品牌" show-overflow-tooltip></el-table-column>
      <el-table-column label="省份" min-width="100px"></el-table-column>
      <el-table-column label="城市" min-width="100px" show-overflow-tooltip></el-table-column>
      <el-table-column label="客房数"  min-width="100px"></el-table-column>
      <el-table-column label="激活终端数"  min-width="150px"></el-table-column>
      <el-table-column label="日活率"  min-width="100px"></el-table-column>
      <el-table-column label="单间ARPU(元/间）" min-width="150px"></el-table-column>
    </el-table>
     <el-table v-if="num==2" class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" :height="table.limit>=20 &&table.total>10?'550px':'330px'">
      <el-table-column prop="name" label="酒店名称" min-width="300px" fixed></el-table-column>
      <el-table-column min-width="100px" label="品牌" show-overflow-tooltip></el-table-column>
      <el-table-column label="省份" min-width="100px"></el-table-column>
      <el-table-column label="城市" min-width="100px" show-overflow-tooltip></el-table-column>
      <el-table-column label="客房数"  min-width="100px"></el-table-column>
      <el-table-column label="激活终端数"  min-width="150px"></el-table-column>
      <el-table-column label="日活率"  min-width="100px"></el-table-column>
      <el-table-column label="单间ARPU(元/间" min-width="150px"></el-table-column>
       <el-table-column label="最新版本房间号" min-width="150px"></el-table-column>
        <el-table-column label="版本房间号" min-width="150px"></el-table-column>
    </el-table>
     <el-table v-if="num==3" class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" :height="table.limit>=20 &&table.total>10?'550px':'330px'">
      <el-table-column prop="name" label="酒店名称" min-width="300px" fixed></el-table-column>
      <el-table-column min-width="100px" label="品牌" show-overflow-tooltip></el-table-column>
      <el-table-column label="省份" min-width="100px"></el-table-column>
      <el-table-column label="城市" min-width="100px" show-overflow-tooltip></el-table-column>
      <el-table-column label="客房数"  min-width="100px"></el-table-column>
      <el-table-column label="激活终端数"  min-width="150px"></el-table-column>
      <el-table-column label="日活率"  min-width="100px"></el-table-column>
     
    </el-table>
    <div class="block pageBox" >
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>


export default {
    props:["rowData"],
  data() {
    return {
      topSearchShow: {},
      conditionData: {
        hotel_brand: null,
        province: null,
        city: null,
        keyword: null,
      },
      serachFalg:false,
      btnText:"显示筛选",
      table:{
          page:1,
          limit:5,
          total:0,
      },
      tableList: [],
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      num:3,
      chartLine:{},
    };
  },
  watch:{
      rowData:{
           handler: function (newVal) {
                          this.rowData=newVal;
                },
                deep: true,
                immediate: true, 
      }
  },
  mounted() {
    this.getTopSearchShow(); 
  },
  methods: {
      openSearch(){
          this.serachFalg =  !this.serachFalg;
          if(this.serachFalg){
              this.btnText="收起筛选"
          }else{
               this.btnText="显示筛选"
          }
              
      },
      handleSizeChange(val){
           this.table.limit = val;
      },
      handleCurrentChange(val){
           this.table.page = val;
      },
    getCondition(val) {
      this.conditionData.hotel_brand = val.hotel_brand;
      this.conditionData.province = val.province;
      this.conditionData.city = val.city;
      this.conditionData.keyword = val.keyword;
    },
    //  获取搜索框权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          keyword: true,
          keywordPlaceholder: "酒店名称关键词",
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          keyword: true,
          keywordPlaceholder: "酒店名称关键词",
        };
      }
    },
  },
};
</script>
<style scoped>
.btnRow {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.largeBtn1{
     width: 100px;
  height: 40px;
  border: 1px solid #66b1ff;
  color: #333;
  background: #fff;
}
.largeBtn1:hover{
    color: #66b1ff;
}
.tableBox{
    margin-top: 20px;
}
  ::v-deep .el-table th.is-leaf {
    border-top:  1px solid #EBEEF5  !important; 
    box-sizing: border-box;
      border-bottom: none !important;
}
::v-deep .el-table__footer-wrapper, ::v-deep .el-table__header-wrapper {
    border-radius: 4px !important;
    
   border:  1px solid #EBEEF5 !important; 
    border-top: none !important;
}
</style>