<template>
  <div>
    <el-table :data="tableList" style="width: 100%; margin-top: 10px" :header-cell-style="headerStyle" :row-class-name="tableRowClassName">
      <el-table-column v-for="(item,index) in dataTableTitle" :key="index" :prop="item.prop" :fixed="item.fixed" :width="item.width">
        <template slot="header" slot-scope="scope">
          <el-tooltip v-if="item.tooltip" effect="dark" :content="item.desc" placement="top">
            <span>{{item.name+'('+item.unit+')'}}</span>
          </el-tooltip>
          <span v-if="!item.tooltip">{{item.name+(item.unit?'('+item.unit+')':'')}}</span>
        </template>
        <template slot-scope="scope">
          <span class="number">{{item.prop=="contractInfo"?scope.row[item.prop]:showDataCom(Number(scope.row[item.prop]),item.unit==='元'?'money':'')}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="msgText">备注：状态为实施中、使用中或已离线且影视合作方式为极光TV。</div>
      <div class="msgText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收益不包含退款，实际收益以每月对账金额为准。</div>
      <div class="msgText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;合同分成阶梯执行标准分别为2019年”激活终端数量“，2020年”有效激活终端数量“，2021年”影视有效激活终端数量“。如没有历史年份代表该年未完成业绩要求。</div>
  </div>
</template>
<script>
export default {
  props: {
    tableList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // 统计样式
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "warning-row";
      }
      return "";
    },
  },
  data() {
    return {
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      isShowUnit: false,
      // 表头
      dataTableTitle: [
        {
          id: 1,  //id
          name: "",  // 名称
          desc: "",  // 描述
          prop: "contractInfo",  // 字段
          width: 230,   //  宽度
          fixed: true,  // 固定
          unit: "",  // 单位
        },
       
        {
          id: 2,
          name: "激活终端数量",
          desc: "",
          tooltip: false,
          prop: "current_room_number",
          width: 140,
          unit: "间",
        },
        {
          id: 4,
          name: "有效激活终端数量 ",
          desc: "当前有效上线终端数量（有效终端为任意连续30天内有任意10天上线的终端）",
          tooltip: false,
          prop: "current_valid_number",
          width: 170,
          unit: "间",
        },
        {
          id: 5,
          name: "影视有效激活终端数量",
          desc: "当前成功安装了携旅极光影视应用的有效终端数",
          tooltip: false,
          prop: "current_valid_movie_number",
          unit: "间",
          width: 190,
        },
        {
          id: 6,
          name: "本年预估总收益",
          desc: "单点本年收益加天卡本年收益且不包含相应退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "incomeTotalYear",
          unit: "元",
        },
        {
          id: 7,
          name: "单点昨日收益",
          desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieYesterdayIncomeOne",
          unit: "元",
        },
        {
          id: 8,
          name: "单点本周收益",
          desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieLastWeekIncomeOne",
          unit: "元",
        },
        {
          id: 9,
          name: "单点本月收益",
         desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieCurrentMonthIncomeOne",
          unit: "元",
        },
        {
          id: 10,
          name: "单点本年收益",
          desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieTotalIncomeOne",
          unit: "元",
        },
        {
          id: 11,
          name: "天卡昨日收益",
          desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieYesterdayIncomeDay",
          unit: "元",
        },
        {
          id: 12,
          name: "天卡本周收益",
           desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieLastWeekIncomeDay",
          unit: "元",
        },
        {
          id: 13,
          name: "天卡本月收益",
          desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieCurrentMonthIncomeDay",
          unit: "元",
        },
        {
          id: 14,
          name: "天卡本年收益",
          desc: "不包含退款收益，实际收益以对账金额为准",
          tooltip: true,
          width: 160,
          prop: "movieTotalIncomeDay",
          unit: "元",
        },
      ],
    };
  },
  mounted() {},
  computed: {
    // 数据格式化
    showDataCom() {
      return function (s, type = "", n = 2) {
        var stringNum = String(s);
        var pointIndex = stringNum.indexOf(".");
        var strLength = 0;
        var result;
        if (pointIndex !== -1) {
          //有小数
          var noPointStr = String(s).substring(0, pointIndex);
          strLength = noPointStr.length;
        } else {
          strLength = stringNum.length;
        }
        if (s === null) {
          return 0;
        } else {
          if (strLength > 5) {
            this.isShowUnit = true;
            if (type === "money") {
              n = n > 0 && n <= 20 ? n : 2;
              s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
              var l = s.split(".")[0].split("").reverse(),
                r = s.split(".")[1];
              var t = "";
              for (var i = 0; i < l.length; i++) {
                t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
              }
              result = t.split("").reverse().join("") + "." + r;

            } else {
              result = parseInt(((s / 10000) * 100) / 100); //取整
              result = parseFloat(String(result)).toLocaleString(); //取整
            }
          } else {
            //小于5
            this.isShowUnit = false;
            if (type === "money") {
              n = n > 0 && n <= 20 ? n : 2;
              s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
              var l = s.split(".")[0].split("").reverse(),
                r = s.split(".")[1];
              var t = "";
              for (var i = 0; i < l.length; i++) {
                t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
              }
              result = t.split("").reverse().join("") + "." + r;
            } else {
              result = parseFloat(s).toLocaleString();
            }
          }
          return result;
        }
      };
    },
  },
};
</script>
<style scoped>
.el-table ::v-deep .warning-row {
  background: #f0f6fd;
  font-size: 18px;
  font-family: DIN !important;
}
.el-table ::v-deep .warning-row td:first-child {
  font-size: 12px;
  font-family: "Microsoft YaHei";
}
.el-table ::v-deep td:first-child {
  border-right: 1px solid #ddd;
  font-size: 12px;
}
.el-table ::v-deep th.is-leaf:first-child {
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}
.el-table ::v-deep th.is-leaf.is-hidden:first-child {
  border: none;
}
.el-table ::v-deep .warning-row .unit {
  font-size: 14px;
}
::v-deep .el-table__body-wrapper {
  font-size: 12px;
}
.msgText{
  margin-top: 10px;
  color: #a3a7af;
  font-size: 12px;
}
</style>