<template>
<div  >       
  <div class="jumpContentBox">
        <edit-btn @ishidden="isHidden" :upType="'pic'" :msg="'jumpPic'" class="editBtnStyle" :hidden="btnhidden" @recoverydata="recoveryData"></edit-btn>
         <img :src="pageData.picurl" alt="">
  </div>
   <to-upload :hotelContract="hotelContract" @upsuccess="upSuccess"></to-upload>
  </div>
</template>
<script>
import editBtn from "../common/editBtn";
import toUpload from "../common/toUpload"
export default {
  props: ["attrName","data","hotelContract"],
  components: {
    editBtn,
    toUpload,
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.pageData = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    this.saveData[this.attrName]=this.data;
    this.saveHistory();
    this.sendDataForVuex();
  },
  data() {
    return {
        btnhidden:false,
        pageData: {},
        historyData:{},
          saveData:{},
    };
  },
  methods:{
    // 保存数据至vuex
      sendDataForVuex(){
        this.$store.commit("saveVisualContent",this.saveData)
      },
      // 按钮控制
      isHidden(val){
          if(val == "jumpPic"){
              this.btnhidden = !this.btnhidden;
          }
      },
      // 保存初始历史数据
      saveHistory(){
          this.historyData.picurl=this.data.picurl;
          this.historyData.md5=this.data.md5;
      },
      // 上传成功
      upSuccess(data,msg){
        this.saveData[this.attrName].picurl =data.url;
        this.saveData[this.attrName].md5 =data.md5;
        this.pageData.picurl =data.url;
        this.pageData.md5 =data.md5;
        this.sendDataForVuex();
        console.log(this.$store.state);
      },
      // 恢复历史文件
      recoveryData(val){
        if (val == "jumpPic") {
        this.saveData[this.attrName].picurl = this.historyData.picurl;
        this.saveData[this.attrName].md5 = this.historyData.md5;
        this.pageData.picurl = this.historyData.picurl;
        this.pageData.md5 = this.historyData.md5;
         this.sendDataForVuex();
           console.log(this.$store.state);
        }
      },
  }
};
</script>
<style scoped>
.jumpContentBox {
  width: 100%;
  height: 100%;
}
.jumpContentBox img{
    width: 100%;
     height: 100%;
}
.editBtnStyle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20%;
  height: 5%;
  z-index: 10;
}
</style>