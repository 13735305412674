<template>
  <div id="app">
    <!--路由占位符-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  mounted() {},
  computed: {
    test() {
      return "dddd";
    },
  },
};
</script>

<style lang="scss">
.el-table__cell{
  position: relative;
}
.el-table [class*=el-table__row--level] .el-table__expand-icon {
  
    position: absolute;
    left: -10px;
    top: 16px;
}
.tooltipItem{
max-width: 400px;
}
.el-textarea__inner {
  font-family: "Microsoft YaHei";
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
.el-drawer {
  overflow: visible !important;
}

:focus {
  outline: 0;
}

.el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible !important;
  margin: 0;
  left: unset;
  bottom: unset;
}

.el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

.el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
.el-table__fixed-right::before,
.el-table__fixed::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  // background-color: #EBEEF5;
  z-index: 4;
}
.el-table__fixed,
.el-table__fixed-right {
  height: auto !important;
  bottom: 10px !important;
  top: 0px;
}
.el-table .el-table__fixed-body-wrapper {
  top: 50px !important;
  box-sizing: border-box;
  height: calc(100% - 55px) !important;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 5px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写*/
}
::v-deep .el-table th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  box-sizing: border-box;
  border-bottom: none !important;
}
::v-deep .el-table__footer-wrapper,
::v-deep .el-table__header-wrapper {
  border-radius: 4px !important;

  border: 1px solid #ebeef5 !important;
  border-top: none !important;
}
// .el-table__body-wrapper::-webkit-scrollbar {
//     width: 6px; // 横向滚动条
//     height: 6px; // 纵向滚动条 必写
//   }
// 滚
.minFont {
  font-size: 14px;
}
.middleFont {
  font-size: 16px;
}
#app {
}
input:-webkit-autofill {
  　　-webkit-box-shadow: 0 0 0px 1000px white inset;
  　　-webkit-text-fill-color: #333;
}

/*input:webkit-box-shadow: 0 0 0 1000px #fff inset;*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  /*背景颜色*/
  box-shadow: 0 0 0 60px #fff inset;
  /*字的颜色*/
  border: none;
  /*-webkit-text-fill-color: #878787;*/
}
.el-aside {
  background: #f8f9fb !important;
}
.borderBox.rowBox .grid-content,
.borderBox.vertical .grid-content {
  /*border:1px solid #ddd;*/
}
.lineContent.chart > div {
  height: 440px;
  /*height:540px;*/
}
.el-form-item__content {
  display: flex !important;
  align-items: center;
}
/*上传文件开始*/
.avatar-uploader > .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.disabled .el-upload--picture-card {
  display: none !important;
}
.noBorder > input {
  border: none;
}
/*上传文件结束*/
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 250px !important;
}
.ql-editor {
  min-height: 250px !important;
}

.el-button--info:focus,
.el-button--info:hover {
}
.el-button--info {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-color: rgba(0, 0, 0, 0.3) !important;
}
.el-tabs__content {
  overflow: visible !important;
}
@font-face {
  font-family: "DIN";
  src: url("./assets/fontCus/DIN Alternate Bold.ttf");
}
/*首页数字展示*/
.indexNum {
  font-size: 36px;
  font-family: DIN !important;
  font-weight: 600;
}
.minFontNum {
  font-size: 14px;
  font-family: DIN !important;
  font-weight: 600;
}
.numTitle {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: #a3a7af;
}
.numTime {
  font-size: 14px !important;
  color: #a3a7af;
}

/*全部头部筛选样式开始*/
.inputColorChange input,
.inputColorChange .el-range-editor.el-input__inner {
  border: 1px solid #2e63a6 !important;
  color: #2e63a6 !important;
}
.inputColorNone .el-select__tags input,
.inputColorNone .el-range-editor.el-input__inner {
  border: none !important;
  color: #2e63a6 !important;
}
.inputColorChange input::-webkit-input-placeholder {
  color: #2e63a6 !important;
}
/*日期范围input边框*/
.inputColorChange .el-range-editor .el-range-input {
  border: none !important;
}
/*日期范围边框*/
.el-date-editor.el-range-editor.el-input__inner.fullSelect.el-date-editor--daterange,
.el-date-editor.el-range-editor.el-input__inner.fullSelect.el-date-editor--monthrange {
  border: 1px solid #2e63a6 !important;
}
.el-date-editor.el-range-editor.el-input__inner.fullSelect.el-date-editor--datetimerange,
.el-date-editor.el-range-editor.el-input__inner.fullSelect.el-date-editor--monthrange {
  border: 1px solid #2e63a6 !important;
}
fullSelect.el-date-editor--monthrange {
}
/*input::-moz-input-placeholder {*/
/*color: #c0c4cc;*/
/*}*/
.inputColorChange input::-ms-input-placeholder {
  color: #2e63a6 !important;
}
.fullSelect {
  width: 100%;
}
.inputColorChange .el-select .el-input .el-select__caret {
  color: #2e63a6 !important;
}
.el-form--label-top .el-form-item__label {
  color: #2e63a6 !important;
}
/*全部头部筛选样式结束*/
/*table表头颜色开始*/
.el-table thead {
  background: #e2e6eb !important;
  border: 1px solid rgba(226, 230, 235, 1) !important;
  border-radius: 9px !important;
}
/*表头圆角*/
.el-table__footer-wrapper,
.el-table__header-wrapper {
  border-radius: 4px !important;
  border: 1px solid #ebeef5 !important;
  border-bottom: none !important;
}
/*table表头颜色结束*/

/*筛选上面的按钮组开始*/
.topBtnGroup {
  margin-top: 20px;
}
.searchCard {
  position: relative;
}
.searchCard .el-card__body {
  padding-top: 0 !important;
}
.el-input__suffix-inner .el-input__icon {
  color: #2e63a6 !important;
}
/*筛选上面的按钮组结束*/

/*登陆页面input样式开始*/
.loginForm .el-input__inner {
  border-radius: 0 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 14px !important;
}
.loginForm .el-input--prefix .el-input__inner {
  padding-left: 45px !important;
}
/*登陆页面input样式结束*/

.noMarginTop {
  margin-top: 0 !important;
}
/*echarts标题，table标题*/
.chartTitle {
  width: 100%;
  margin-bottom: 20px;
}
.chartTitle > div:first-child,
.blueTitle {
  border-left: 3px solid #909ca7;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 14px;
}

.box-card {
  margin-top: 10px;
}
/*弹窗上下居中*/

.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
/*弹窗上下居中*/
.noRightPadding .el-card__body {
  padding-right: 0 !important;
}
.el-main {
  padding: 10px !important;
}
.el-row {
  /*margin-top:20px!important;*/
}
.rightBtnGroup .el-form-item__content {
  float: right;
}
.largeBtn {
  min-width: 100px;
}
.tenMarginTop {
  margin-top: 10px !important;
}
/*收益统计-tab中字体大小*/
.incomeTab .el-tabs__item {
  font-size: 16px !important;
}

.loginTab .el-tabs__nav-scroll {
  display: flex !important;
  justify-content: center !important;
}
.loginTab .el-tabs__item {
  width: 50% !important;
  font-size: 18px !important;
  text-align: center !important;
}
.loginTab .el-collapse-item__arrow,
.el-tabs__nav {
  width: 100%;
}
.loginTab .loginTab .el-collapse-item__arrow,
.el-tabs__nav {
  padding-bottom: 10px !important;
}
/*大图片查看开始*/
/*.bigImgShow .el-upload-list--picture-card .el-upload-list__item{*/
/*width:548px!important;*/
/*height:548px!important;*/
/*}*/
/*大图片查看结束*/

/*el-form-item 宽度自适应开始*/
.widthAutoInput .el-form-item__label {
  width: auto !important;
}
.widthAutoInput .el-form-item__content {
  margin-left: 0 !important;
}
/*el-form-item 宽度自适应结束*/

/*按钮组合开始*/
.btnRow {
  display: flex;
  margin-bottom: 20px;
}
/*按钮组合结束*/

/*筛选框控制按钮开始*/
.outTopBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 20px;
  align-items: center;
}
/*筛选框控制按钮结束*/

.titleBox {
  display: flex;
  align-items: center;
}

/*收益统计tab开始*/
.incomeTab .el-tabs__header {
  padding-left: 20px !important;
}
/*收益统计tab结束*/

/*状态显示开始*/
.stateBox {
  margin-left: 20px;
}
/*状态显示结束*/

/*可视化编辑弹窗开始*/
.dialogUpload .el-dialog__header {
  display: none;
}
.dialogUpload .el-dialog__body {
  padding: 0;
}
.dialogUpload .loginTab .loginTab .el-collapse-item__arrow,
.el-tabs__nav {
  padding-bottom: 0 !important;
}
/*可视化编辑弹窗结束*/

/*表头不换行开始*/
.el-table th > .cell {
  white-space: nowrap !important;
}
/*表头不换行结束*/

/*右上角通知隐藏chackBox-label开始*/
.labelNone {
  display: flex;
  align-items: center;
}
.labelNone > span.el-checkbox__label {
  display: none !important;
}
/*右上角通知隐藏chackBox-label结束*/

/*渠道商政策列表-富文本内容展示开始*/
.newsDetail img {
  max-width: 100% !important;
}
/*渠道商政策列表-富文本内容展示结束*/

/*运营酒店列表中地图显示*/
.noPaddingLeft > .el-card__body {
  padding-left: 0 !important;
}

.BMap_bubble_content p {
  font-size: 14px !important;
}

.elFormItemNoMarginLeft .el-form-item__content {
  margin-left: 0 !important;
}
.elFormItemNoMarginBottom {
  margin-bottom: 0 !important;
}

.el-main {
  background: #f9f9f9 !important;
}
p {
  white-space: pre-wrap !important;
}
.noPaddingTop > .el-card__body {
  padding-top: 0 !important;
}

/*可视化文字对齐开始*/
.textBox.alignLeft input {
  text-align: left;
  transition: 0.5s;
}
.textBox.alignCenter input {
  text-align: center;
  transition: 0.5s;
}
.textBox.alignRight input {
  text-align: right;
  transition: 0.5s;
}
.noBgInput input {
  background: none;
  border: none;
  color: #fff;
}
/*可视化文字对齐结束*/

/*通知drawer开始*/
.noticeDrawer .el-drawer {
  width: 715px;
}

/*通知drawer结束*/
.tabCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabCenter > .el-tabs__header {
  width: 1152px;
}
.tabCenter
  > .el-tabs__header
  > .el-tabs__nav-wrap
  > .el-tabs__nav-scroll
  > .el-tabs__nav {
  display: flex !important;
  /*justify-content: center!important;*/
}
.tabCenter > .el-tabs__header {
  margin-bottom: 0;
}
/*素材编辑tab样式开始*/
.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-radius: 5px 0 0 0;
  background: #f9f9f9;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background: #fff;
}
/*素材编辑tab样式结束*/

/*表格上时间范围显示*/
.titleTime {
  margin-left: 10px;
}
/*蓝色步骤条开始*/
/*.blueStep .el-step__line{*/
/*background:#1875f0!important;*/
/*}*/
/*.blueStep .el-step__title{*/
/*!*color:#7b7b7b!important;*!*/
/*}*/
/*.blueStep .el-step__head.is-finish{*/
/*color:#1875f0;*/
/*}*/
/*.noHideEl.el-card{*/
/*overflow:visible!important;*/
/*}*/
/*蓝色步骤条结束*/
.noBorderTab.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}
.noBorderTab.el-tabs--card > .el-tabs__header {
  border: none !important;
}
.noBorderTab .el-tabs__nav > div {
  border: 1px solid #e4e7ed !important;
}
.noBorderTab .el-tabs__nav > div:nth-child(2) {
  border-left: none !important;
}

.rightBtn {
  margin-left: auto !important;
}
.quickDateSearchBox {
  display: flex;
  align-items: center;
}
.absoluteFoldBox {
  padding: 10px 0;
  overflow: hidden;
  position: absolute;
  top: -73px;
  right: 0;
  z-index: 999;
}
/*可视化文字边框开始*/
.textBorderBox {
  border: 1px dashed #fff;
  box-sizing: border-box;
}
.textBorderBox textarea {
  border: none;
  background: none;
  height: 100%;
}
.textBorderBox:hover {
  border: 1px dashed #1b90ed;
  box-sizing: border-box;
}
.focusTextBox {
  border: 1px dashed #1b90ed;
  background: linear-gradient(to left, #fff, #fff) no-repeat left top,
    linear-gradient(to left, #fff, #fff) no-repeat right top,
    linear-gradient(to left, #fff, #fff) no-repeat left bottom,
    linear-gradient(to left, #fff, #fff) no-repeat right bottom, none;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px;
}
/*可视化文字边框结束*/
video {
  object-fit: cover;
}
.swiper-wrapper {
  display: flex;
}
.cardCenter .el-card__body {
  /*display:flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
}
.sortUpload .el-upload__tip,
.sortUpload .el-upload-dragger .el-upload__text {
  color: #fff;
}
.sortUpload .el-upload-dragger {
  background-color: rgba(255, 255, 255, 0.2);
}
.noFixedWidthForm {
  .el-form-item__label {
    width: auto !important;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
}

/*素材编辑AllModel 箭头位置开始*/
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 35px !important;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 35px !important;
}
/*素材编辑AllModel 箭头位置结束*/

.publishRoomStrInput {
  width: 80% !important;
}

.el-loading-mask {
  /*z-index:30000!important;*/
}
.topSearchRow .el-col {
  margin-top: 0 !important;
  padding-top: 20px !important;
}
.elFormItemNoMarginBottom {
  margin-bottom: 0 !important;
}
.centerTab .el-tabs__nav {
  display: flex;
  justify-content: center;
}

.addToType .el-form-item__content {
  margin-left: 0 !important;
}
.selectIconMargin .el-input__suffix {
  margin-right: 40px !important;
}
.dashedHeader .el-card__header {
  border-bottom: 1px dashed #e9e9e9 !important;
}
.bgProcessItem .el-card__header,
.bgProcessItem .el-card__body {
  background: #fcfdff;
}

/*可视化只读状态开始*/
.onlyShowModule .borderBox {
  border: 2px dashed transparent !important;
  transition: 0.5s;
}
.onlyShowModule .configBox {
  display: none !important;
}
/*.noDisabledColor .el-input.is-disabled .el-input__inner{*/
.noDisabledColor.el-input.is-disabled .el-input__inner {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
}
.noDisabledColor.el-input.is-disabled .el-input__inner {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
}
.noDisabledColor.el-textarea.is-disabled .el-textarea__inner {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
}
/*可视化只读状态结束*/

.hotelServiceDrawer .el-drawer {
  width: 1300px !important;
}
.hotelServiceDrawer .el-drawer__body {
  overflow: auto;
}
.scrollDrawer .el-drawer__body {
  overflow: auto;
}
/*文件上传关闭动画开始*/
.noTransition .el-upload-list__item {
  transition: none !important;
}
/*文件上传关闭动画结束*/
.publishListDrawer .el-drawer {
  width: 1500px !important;
}

.el-tabs .el-tabs__item {
  list-style: none;
  font-size: 14px;
  color: #303030;
}
.oper-dia {
  // top: 35%;
}
</style>
