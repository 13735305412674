import request from "@/api/request";
import qs from "querystring";

//   获取发布统计列表
export function getList(param) {
    return request({
        url: `v2/Contentbatch/staVersion`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   重新发布
export function reSend(param) {
    return request({
        url: `v2/Contentbatch/repeatVersion`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   +酒店发布
export function hotelSend(param) {
    return request({
        url: `v2/Contentbatch/addHotelVersion`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   过期
export function overTime(param) {
    return request({
        url: `v2/Contentbatch/offShelf`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   导出
export function exportData(param) {
    return request({
        url: `v2/Contentbatch/exportContentVersion`,
        method: "post",
        data: qs.stringify(param),
        responseType: "blob",
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   指令列表
export function instructionList(param) {
    return request({
        url: `v2/cmdtask/getCmdList`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   指令执行
export function doInstruction(param) {
    return request({
        url: `v2/Cmdtask/addCmdTask`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   获取房间在线状态
export function getRoomActive(param) {
    return request({
        url: `v2/Cmdtask/doSearchActiveRoom`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   获取自定义列表
export function getCustomList(param) {
    return request({
        url: `v2/Cmdtask/getCustomList`,
        method: "post",
        data: qs.stringify(param),

    });
}
//   添加自定义指令
export function addCustom(param) {
    return request({
        url: `v2/Cmdtask/addCustom`,
        method: "post",
        data: qs.stringify(param),

    });
}
//   删除自定义指令
export function delCustom(param) {
    return request({
        url: `v2/Cmdtask/delCustom`,
        method: "post",
        data: qs.stringify(param),

    });
}