<template>
  <div style="padding:0 15px">

    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">内容监管</span>
        <span slot="third">信息发布</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">信息发布列表</div>
      <!-- 操作栏 -->
      <div class="btnRow">
        <!-- <el-input style="width:300px" placeholder="图片名称" v-model="picName" :clearable="true" class="input-with-select fullSelect "></el-input>
      <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button> -->
        <el-button type="primary" class="largeBtn" @click="addBasicBox">添 加</el-button>
        <!-- <el-button type="danger" class="largeBtn" @click="del">删 除</el-button> -->
      </div>
      <!-- 操作栏 -->
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />

    </el-card>

    <!-- 表格 -->
    <!-- 添加表单 -->
    <el-dialog title="执行信息" :visible.sync="dialogVisible" width="500px" :before-close="handleClose" :append-to-body="true">
      <el-form ref="basicForm" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="提示名称" prop='name'>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="提示内容" prop='desc'>
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="选择酒店" prop='hotel'>
          <el-select style="width:100%" v-model="form.hotel" placeholder="请选择">
            <el-option v-for="item in hotelList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addBasic('basicForm')">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加表单 -->

  </div>
</template>
<script>
export default {
  name: "execute",

  data() {
    return {
      bread: {
        level: 3,
      },
      picName: "",
      hotelList: [
        { name: "考拉都市民宿沈阳北站店", id: 1 },
        { name: "希岸Deluxe酒店沈阳站店", id: 2 },
        { name: "麗枫酒店沈阳黎明广场地铁站店", id: 3 },
        { name: "沈阳于洪希尔顿惠庭酒店", id: 4 },
      ],
      areaList: [
        { name: "区域A", id: 1 },
        { name: "区域B", id: 2 },
        { name: "区域C", id: 3 },
        { name: "区域D", id: 4 },
      ],
      LevelList: [
        { name: "信息", id: "信息" },
        { name: "警告", id: "警告" },
        { name: "危险", id: "危险" },
      ],
      // 表格数据信息
      tableData: {
        tableList: [
          {
            name: "提示信息：",
            desc: "直播内容不得违反法律法规",
            user_name: "admin",
            create_time: "2023-05-11",
          },
           {
            name: "提示信息：",
            desc: "直播内容不得出现侵权情况",
            user_name: "admin",
            create_time: "2023-05-10",
          },
           {
            name: "提示信息：",
            desc: "劳动节到了，劳动光荣，勤劳致富",
            user_name: "admin",
            create_time: "2023-05-01",
          },
        ],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "提示名称",
            prop: "name",
            minWidth: "80",
            type: "text",
          },

          {
            label: "提示描述",
            prop: "desc",
            minWidth: "120",
            type: "text",
          },
          {
            label: "创建人",
            prop: "user_name",
            minWidth: "120",
            type: "text",
          },
          {
            label: "创建时间",
            prop: "create_time",
            minWidth: "120",
            type: "text",
          },
        ],
      },
      // 表单字段验证
      rules: {
        name: [{ required: true, message: "请填写信息名称", trigger: "blur" }],
        desc: [{ required: true, message: "请填写信息描述", trigger: "blur" }],

        // morder: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      // 表单字段
      form: {
        name: "",
        desc: "",
        hotel: "",
      },
      checkList: [],
      dialogVisible: false,
      n: 0,
    };
  },

  methods: {
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      //   this.getTableData(
      //     this.query.hotelName,
      //     this.tableData.limit,
      //     this.tableData.page
      //   );
    },

    // 删除项
    del() {
      if (this.checkList.length == 0) {
        this.$message.warning("请至少选择一项在进行删除操作");
        return false;
      }
      // 确认
      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    // 添加项
    addBasic(formName) {
      // 表单字段验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 上传数据验证
          this.tableData.tableList.push({
            id: this.n,
            name: this.form.name,
            desc: this.form.desc,
            hotel: this.form.desc,
            user_name: "admin",
            create_time: "2023-01-01",
          });
          this.n++;
          console.log(this.form, this.tableData.tableList);
          this.handleClose();
          this.$message.success("添加成功");
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },

    // 打开添加界面
    addBasicBox() {
      this.dialogVisible = true;
      this.form = {
        hotel: "",
        name: "",
        desc: "",
      };
    },
    // 关闭界面
    handleClose() {
      this.dialogVisible = false;
      this.form = {
        name: "",
        desc: "",
        hotel: "",
      };
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 选中项处理
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return i.id;
      });
    },
    // 获取表格数据
    getTableData(database, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        name: this.picName,
        page: p ? p : 1,
        limit: s ? s : 10,
      };
      getBasicPicture(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data.items;
            // 字段显示处理

            // 组件数据总数
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
</style>
