<template >
  <div>
    <el-card class="box-card" style="height: 440px;">
      <div class="title blueTitle">{{type}}</div>
      <div class="timeShow">{{searchTime}}</div>
      <!-- <div class="radioBox" v-if="type=='收益订单'">
        <el-radio-group v-model="radioType" text-color='#3688F8' fill="#fff" size='mini' @change="changeRadio">
          <el-radio-button label="dmp,dmp_youzan">全部</el-radio-button>
          <el-radio-button label="dmp">影视</el-radio-button>
          <el-radio-button label="dmp_youzan">商城</el-radio-button>
        </el-radio-group>
      </div> -->
      <el-table ref="table" :header-cell-style="headerStyle" :data="tableData" v-loading="table.loading" style="width: 100%" class="sixtable" height="315px">
        <el-table-column v-if="type=='版本信息'" prop="appName" label="应用名称" min-width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column v-if="type=='版本信息'" prop="versionname" label="版本号" min-width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column v-if="type=='版本信息'" prop="publisher" label="发行商" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column v-if="type=='版本信息'" prop="rtime" label="更新时间" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.rtime?scope.row.rtime.substring(0,10):''}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="type=='收益订单'" prop="no" label="订单号" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="textBtn" @click="openIncomeShow(scope.row)">{{scope.row.no}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="type=='收益订单'" prop="orderType" label="订单类型" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.orderType=='h5_package'">H5权益销售</span>
            <span v-if="scope.row.orderType=='package'">权益销售</span>
            <span v-if="scope.row.orderType=='package_tv'">大屏收银</span>
          </template>
        </el-table-column>
        <el-table-column v-if="type=='收益订单'" prop="totalAmount" label="订单金额（元）" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.totalAmount/100}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="type=='广告订单'" prop="order_no" label="订单号" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="textBtn" @click="openAdsShow(scope.row)">{{scope.row.order_no}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="type=='广告订单'" prop="brand_name" label="投放品牌" width="80" show-overflow-tooltip>
        </el-table-column>
        <el-table-column v-if="type=='广告订单'" label="投放周期" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{(scope.row.ad_start_time?scope.row.ad_start_time.substring(0,10):'')+'~'+(scope.row.ad_end_time?scope.row.ad_end_time.substring(0,10):'')}}</span>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination class="changePage" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </el-card>

    <!-- 订单信息 -->
    <el-dialog title="订单凭证" :visible.sync="orderVisible" width="30%">
      <div>{{orderMsg}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="orderVisible = false,orderMsg=''">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 订单信息 -->
  </div>
</template>
<script>
import {
  getAdvOrderList,
  getProfitList,
  getOrderCert,
  getVerList,
} from "@/api/dataCenter.js";
export default {
  name: "tableCard",
  props: {
    searchTime: {
      type: String,
      default: "",
    },
    screenName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    searchForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    searchForm: {
      handler: function (newVal) {
        let json = JSON.parse(JSON.stringify(newVal));
        if (this.type == "收益订单") {
          this.radioType = "dmp,dmp_youzan";
          json.source = this.radioType;
        }
        this.table = {
          limit: 5,
          page: 1,
          total: 0,
          loading: false,
        };
        json.page = this.table.page;
        json.size = this.table.limit;
        this.searchData = json;
        if (json.hotelContract && json.roomNo) {
          this.getTableList(json);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      radioType: "dmp,dmp_youzan",
      table: {
        limit: 5,
        page: 1,
        total: 0,
        loading: false,
      },
      searchData: {},
      tableData: [],
      orderVisible: false,
      orderMsg: "",
    };
  },
  mounted() {},
  methods: {
    // 获取表格数据
    getTableList(form) {
      this.table.loading = true;
      if (this.type == "广告订单") {
        // 广告订单列表数据
        getAdvOrderList(form)
          .then((res) => {
            if (res.data.code == 100000) {
              this.tableData = res.data.data.data;
              this.table.total = res.data.data.total;
              this.table.loading = false;
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("广告订单数据加载失败");
          });
      } else if (this.type == "版本信息") {
        // 版本信息列表数据
        getVerList(form)
          .then((res) => {
            if (res.data.code == 100000) {
              this.tableData = res.data.data.data;
              this.table.total = res.data.data.totalCount;
              this.table.loading = false;
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("版本信息数据加载失败");
          });
      } else if (this.type == "收益订单") {
        // 收益订单列表数据
        getProfitList(form)
          .then((res) => {
            if (res.data.code == 100000) {
              this.tableData = res.data.data.rows;
              this.table.total = res.data.data.totalRows;
              this.table.loading = false;
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("收益订单数据加载失败");
          });
      }
    },
    // 获取收益订单凭证
    openIncomeShow(row) {
      let json = {
        orderNo: row.no,
      };
      getOrderCert(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.orderMsg = res.data.data.orderCert;
            this.orderVisible = true;
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("凭证信息获取失败");
        });
    },
    // 数据范围变化
    changeRadio() {
      this.searchData.source = this.radioType;
      this.getTableList(this.searchData);
    },
    // 展开详情界面
    openAdsShow(row) {
      this.orderMsg = row.credentials;
      this.orderVisible = true;
    },
    // 分页功能
    handleSizeChange(limit) {
      this.table.limit = limit;
      this.searchData.size = limit;
      this.getTableList(this.searchData);
    },
    handleCurrentChange(page) {
      this.table.page = page;
      this.searchData.page = page;
      this.getTableList(this.searchData);
    },
  },
};
</script>
<style scoped lang="scss">
.textBtn {
  font-size: 14px !important;
  color: #409eff;
  cursor: pointer;
}
.box-card {
  position: relative;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border-color: rgb(54, 136, 248) !important;
  z-index: 99;
  box-shadow: rgb(54, 136, 248) -1px 0px 0px 0px !important;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  box-shadow: none !important;
}
.radioBox {
  position: absolute;
  top: 35px;
  right: 20px;
}
::v-deep .el-table__fixed,
::v-deep .el-table__fixed-right {
  height: auto !important;
  bottom: 10px !important;
  top: 0px;
}
.sixtable {
  margin-top: 10px;
  border-right: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
}
.sixtable ::v-deep.el-table__fixed-body-wrapper {
  top: 60px !important;
  box-sizing: border-box;
  height: calc(100% - 65px) !important;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 5px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写*/
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*background-color: rgba(0,0,0,0.19);*/
  /*border-radius: 3px;*/
}
::v-deep .el-table th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  box-sizing: border-box;
  border-bottom: none !important;
}
::v-deep .el-table__footer-wrapper,
::v-deep .el-table__header-wrapper {
  border-radius: 4px !important;

  border: 1px solid #ebeef5 !important;
  border-top: none !important;
}
.el-pagination {
  margin-top: 10px;
}
.timeShow {
  margin-top: 10px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #a3a7af;
}
::v-deep .el-table__empty-text {
  color: #a3a7af !important;
}
</style>