<template>
  <div>
    <!-- 筛选界面处理 -->
    <!-- 筛选界面处理 -->
    <el-card class="box-card searchCard">
      <el-input style="width:300px" placeholder="请输入品牌名称" v-model="groupName" :clearable="true" class="input-with-select fullSelect">
      </el-input>
      <el-button style="margin-left:20px" type="primary" @click="search">搜 索</el-button>
      <el-button @click="reset">重 置</el-button>
    </el-card>
    <!-- 表格操作区 -->
    <el-card class="box-card">
      <div class="title blueTitle">NO广告品牌列表</div>
      <div class="btnRow">
        <!-- v-if="showFunModel('标签列表新建')" -->
        <el-button type="primary" @click="openCreatLabel">新 建</el-button>
        <el-button type="primary" class="largeBtn" @click="importList">导 出</el-button>
      </div>
      <el-table class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="loading">
        <el-table-column prop="id" label="ID" width="55"></el-table-column>
        <el-table-column prop="name" label="品牌名称" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip min-width="140">
          <template slot-scope="scope">
            <el-button type='text' @click="openInfo(scope.row)">标签详情</el-button>
            <el-button type='text' style="color:red" @click="delLabel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="所属酒店数量" min-width="140" show-overflow-tooltip></el-table-column>

        <el-table-column prop="remark" label="备注" min-width="200" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="status_text" label="状态"></el-table-column> -->
        <el-table-column prop="nickname" label="创建人"></el-table-column>
        <el-table-column prop="createtimeText" label="创建时间" min-width="160px"></el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="新建NO广告品牌" :visible.sync="dialogCreateVisible" width="600px" :modal-append-to-body="false">
      <el-form ref="createForm" :rules="rules" :model="createForm" label-width="100px">
        <el-form-item label="选择品牌" prop='groupId'>
          <el-select v-model="createForm.groupId" placeholder="请选择品牌" class="hotelBrand fullSelect " filterable collapse-tags clearable @change="changeMeth">

            <template v-for="(item,index) in hotelGroupList">
              <el-option v-if="item.id!=1" :key="index" :label="item.groupname" :value="item.id">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="备注说明" prop='remark'>
          <el-input v-model="createForm.remark" placeholder="请输入备注说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" :loading='subLoading' @click="checkTrue('createForm')">确 定</el-button>
        <el-button class="cancelBtn" @click="checkCancel">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="标签详情" :visible.sync="labelVisible" width="980px" :modal-append-to-body="false">
      <div style="margin-bottom:20px">
        <el-input style="width:300px" placeholder="请输入酒店名称" v-model="hotelName" :clearable="true" class="input-with-select fullSelect">
        </el-input>
        <el-input style="width:300px;margin-left:20px" placeholder="请输入酒店合同号" v-model="hotelContract" :clearable="true" class="input-with-select fullSelect">
        </el-input>
        <el-button style="margin-left:20px" type="primary" @click="searchLabel">搜 索</el-button>
        <el-button @click="resetLabel">重 置</el-button>
      </div>

      <el-table class="tableBox" ref="singleTable" :data="tableLabelList" style="width: 100%" :header-cell-style="headerStyle" v-loading="labelLoading">
        <el-table-column prop="hotel" label="酒店名称" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="标签" prop="label" min-width="140">
          <template slot-scope="scope">
            <el-tag style="margin: 5px " v-for="tag in scope.row.label" :key="tag">
              {{tag}}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeInfoChange" @current-change="handleCurrentInfoChange" :current-page.sync="tableInfo.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="tableInfo.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableInfo.total">
        </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="labelVisible=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addNoAdv,
  getNoAdvList,
  delNoAdv,
  getLabelByBrand,
  exportBrand,
} from "@/api/label.js";
import qs from "querystring";
export default {
  name: "groupNoAdv",
  props: ["funArr"],

  data() {
    return {
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      tableInfo: {
        page: 1,
        limit: 5,
        total: 0,
      },
      createForm: {
        groupId: "",
        groupName: "",
        type: 1,
        remark: "",
      },
      rules: {
        groupId: [{ required: true, message: "请选择品牌", trigger: "blur" }],
      },
      subLoading: false,
      dialogCreateVisible: false,
      tableList: [{}],
      loading: false,
      selectIdArr: [],
      hotelGroupList: [],
      token: "",
      groupName: "",
      labelVisible: false,
      tableLabelList: [],
      hotelName: "",
      hotelContract: "",
      labelLoading: false,
      checkBrand: { brand_id: "" },
      auth: {},
    };
  },
  watch: {
    funArr: {
      handler: function (newVal) {
        this.funArr = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getOption();
    this.getNoAdvList();
  },
  computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    importList() {
      exportBrand()
        .then((res) => {
          if (!res.data.code) {
            console.log(res);
            const blobUrl = window.URL.createObjectURL(res.data);
            const title = `NO广告品牌列表.xlsx`;
            // const title = `${this.tableName}.csv`;
            const a = document.createElement("a");
            a.style.display = "none";
            a.download = title;
            a.href = blobUrl;
            a.click();
            document.body.removeChild(a);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    openInfo(item) {
      this.checkBrand = item;
      this.labelVisible = true;

      this.getLabelList();
    },
    changeMeth() {
      if (this.createForm.groupId == "") {
        this.createForm.groupName = "";
        return;
      }
      this.hotelGroupList.forEach((i, n) => {
        if (this.createForm.groupId == i.id) {
          this.createForm.groupName = i.groupname;
        }
      });
    },
    checkTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 确认
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.subLoading = true;
              console.log(this.createForm);
              const json = {
                brand_id: this.createForm.groupId,
                remark: this.createForm.remark,
                type: 1,
                name: this.createForm.groupName,
                user_name: this.auth.user_name,
              };
              addNoAdv(json)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("添加成功");
                    this.$emit("updata");
                    this.getNoAdvList();
                    this.dialogCreateVisible = false;
                  } else {
                    this.$message.warning(res.data.msg);
                  }

                  this.subLoading = false;
                })
                .catch((err) => {});
            })
            .catch(() => {});
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    getLabelList() {
      this.labelLoading = true;
      let param = {
        brand_id: this.checkBrand.brand_id,
        page: this.tableInfo.page,
        size: this.tableInfo.limit,
        hotel_contract: this.hotelContract,
        hotel_name: this.hotelName,
      };
      getLabelByBrand(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableLabelList = res.data.data.row;
            this.tableInfo.total = Number(res.data.data.totalNum);
            this.labelLoading = false;
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("品牌标签数据获取失败");
        });
    },
    checkCancel() {
      this.dialogCreateVisible = false;
    },
    searchLabel() {
      this.tableInfo.page = 1;
      this.getLabelList();
    },
    resetLabel() {
      this.hotelName = "";
      this.hotelContract = "";
      this.tableInfo.page = 1;
      this.getLabelList();
    },
    search() {
      this.table.page = 1;
      this.getNoAdvList();
    },
    reset() {
      this.groupName = "";
      this.table.page = 1;
      this.getNoAdvList();
    },
    openCreatLabel() {
      this.dialogCreateVisible = true;
      this.createForm = {
        groupId: "",
        groupName: "",
        type: 1,
        remark: "",
      };
    },
    getOption() {
      this.$http
        .get(this.global.getOptions, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var optionData = res.data.data;
            this.hotelGroupList = optionData.hotelGroupList;
          }
        });
    },

    // 删除标签
    delLabel(item) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let json = {
            id: item.id,
            user_name: this.auth.user_name,
          };
          var param = qs.stringify(json);
          delNoAdv(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("删除成功");
                this.$emit("updata");
                this.getNoAdvList();
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },

    // 分页
    handleSizeChange(val) {
      this.table.limit = val;
      this.getNoAdvList();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getNoAdvList();
    },
    handleSizeInfoChange(val) {
      this.tableInfo.limit = val;
      this.getLabelList();
    },
    handleCurrentInfoChange(val) {
      this.tableInfo.page = val;
      this.getLabelList();
    },
    // 获取列表
    getNoAdvList() {
      this.loading = true;
      const json = {
        page: this.table.page,
        size: this.table.limit,
        type: 1,
        name: this.groupName,
      };
      getNoAdvList(json)
        .then((res) => {
          this.tableList = res.data.data.data;
          this.table.total = res.data.data.total;
          this.loading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.searchCard {
  padding-top: 20px;
}
.searchBtn {
  position: fixed;
  top: 110px;
  right: 10px;
}
.btnRow {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-top: 20px;
}
.btnRow .el-button {
  width: 100px;
  height: 40px;
}
</style>