<template>
    <div class="root">
        <div class="item" :key="index" v-for="(item,index) in adList">
            <div class="left">{{item.name}}</div>
            <div class="center">{{item.state}}</div>
            <div class="right">
                <el-switch @change="switchChange($event,item.id)"
                        v-model="item.enable"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                </el-switch>
            </div>
        </div>
    </div>
</template>
<script>
    import  qs from 'querystring'
    export default {
        name: "Ad",
        props:['id'],
        data: () => ({
            adList:[
                // {name:'xxxxx广告位1',state:'空闲',switch:'',id:1,enable:false},
            ],
            token:'',
            query:{
                id:''
            }
        }),
        methods:{
            getHotelDetail(){
                this.$http.get(this.global.view,{
                    params:{
                        token:this.token,
                        // id:this.query.id
                        id:this.query.id
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var formData=res.data.data;
                        var topInfo={
                            hotel:formData.hotel,
                            hotel_use_state:formData.hotel_use_state,
                            contract_end:formData.contract_end
                        };
                        this.$emit('topInfo',topInfo)
                    }
                });
            },
            tableMeth(id){
                this.$http.get(this.global.getHotelCaseStatusById,{
                    params:{
                        token:this.token,
                        id:id,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.adList=tableData;
                        // this.table.total=parseInt(tableData.total);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch((res)=>{
                    this.$message.error(res.data.msg);
                    this.table.loading=false;
                })
            },
            editMeth(caseId,status){
                var param=qs.stringify({
                    token:this.token,
                    caseId:caseId,
                    hotelId:this.query.id,
                    status:status,
                });
                 this.$http.post(this.global.editHotelCaseStatus,param).then(res=>{
                     if(res.data.code===100000){
                         this.$message.success('修改成功');
                     }else{
                         this.$message.error(res.data.msg);
                     }
                 }).catch((res)=>{
                     this.$message.error(res.data.msg);
                 })
             },
            switchChange(val,caseId){
                var status='';
                val?status=1:status=0;
                this.editMeth(caseId,status);
            }
        },
        watch:{
            id(val){
                // alert('s');
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.tableMeth(this.$route.query.id);
            var query=this.$route.query;
            this.query.id=this.$route.query.id;
        },

    }
</script>

<style scoped lang="scss">
    .root{
        padding:30px 0 0 30px;
    }
    .item{
        width:auto;
        display:flex;
        align-items:center;
        margin-bottom:50px;
        div{
            font-size:14px;
        }
        .left{
            width:200px;
            overflow-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            margin-right:100px;
        }
        .center{
            margin-right:100px;
        }
    }
</style>
