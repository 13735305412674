import axios from 'axios'
import global_ from '@/api/config'
axios.defaults.baseURL = global_.BASE_URL + '/api';
// axios.defaults.baseURL='https://sspadmin.ctlife.tv:8899/ssp/api';
import global from './globalVar.js'
var token = sessionStorage.getItem('token');
export default {
    setTableDarText(startTime, endTime) {
        if (startTime !== endTime) {
            return `日活率（%）（${startTime}~${endTime}）`
        } else {
            return `日活率（%）`
        }
    },
    getTreeData(tableOption) {
        return new Promise((resolve, reject) => {
            axios.get(global.getUserRuleLists, {
                params: {
                    token: sessionStorage.getItem('token')
                }
            }).then(res => {
                if (res.data.code === 100000) {
                    var funArr = [];
                    var flag = false;
                    res.data.data.forEach((item, index) => {
                        var menuId = sessionStorage.getItem('defaultActiveId');
                        if (item.parent == menuId) {
                            funArr.push(item.text);
                        }
                    });
                    if (tableOption) {
                        funArr.indexOf('详情') !== -1 ?
                            flag = true :
                            flag = false
                    }
                    resolve({ funArr, flag });
                }
            });
        })
    },
    dataTrans(chartData) {
        for (let key in chartData.series) {
            for (let a in chartData.series[key]) {
                if (!chartData.series[key][a]) {
                    chartData.series[key][a] = 0;
                }
            }
        }
        return chartData;
    },
    getTableSelect(val) {
        var operateIds = '';
        var operateIdsArr = [];
        val.forEach((item, index) => {
            operateIds += item.id + ','
        });
        operateIds = operateIds.slice(0, -1);
        operateIdsArr = operateIds.split(',');
        return ({ operateIds, operateIdsArr });
    },
    btnLoading(val, btnText) {
        var text = '';
        val ? text = `${btnText}中…` : text = btnText;
        return text;
    },
    getAllLoading(tableLoadnig, lineLoading, showLoading, barLoading) {
        var flag = false;
        if (barLoading) {
            !tableLoadnig && !lineLoading && !showLoading && !barLoading ?
                flag = true : flag = false;
        } else {
            !tableLoadnig && !lineLoading && !showLoading ?
                flag = true : flag = false;
        }
        return flag;
    },
    objDataAssign(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    getCurrentDate() {
        var timeStr = '-';
        var curDate = new Date();
        var yesDate = new Date(curDate.getTime() - 24 * 3600 * 1000);
        var curYear = curDate.getFullYear(); //获取完整的年份(4位,1970-????)
        var curMonth = curDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
        var curDay = curDate.getDate(); //获取当前日(1-31)
        var yesYear = yesDate.getFullYear(); //获取完整的年份(4位,1970-????)
        var yesMonth = yesDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
        var yesDay = yesDate.getDate(); //获取当前日(1-31)
        yesMonth = yesMonth < 10 ? '0' + yesMonth : yesMonth;
        yesDay = yesDay < 10 ? '0' + yesDay : yesDay;
        curMonth = curMonth < 10 ? '0' + curMonth : curMonth;
        curDay = curDay < 10 ? '0' + curDay : curDay;
        var curWeekDay = curDate.getDay(); //获取当前星期X(0-6,0代表星期天)
        var curHour = curDate.getHours(); //获取当前小时数(0-23)
        curHour = curHour < 10 ? '0' + curHour : curHour;
        var curMinute = curDate.getMinutes(); // 获取当前分钟数(0-59)
        curMinute = curMinute < 10 ? '0' + curMinute : curMinute;
        var curSec = curDate.getSeconds(); //获取当前秒数(0-59)
        var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
        var Current = curYear + timeStr + curMonth + timeStr + curDay + ' ' + curHour + ':' + curMinute + ':' + curSec;
        var date = curYear + timeStr + curMonth + timeStr + curDay;
        var yesDate = yesYear + timeStr + yesMonth + timeStr + yesDay;
        var week = weeks[curWeekDay];
        var time = curHour + ' : ' + curMinute;
        var msTime = curDay + '/' + curMonth
        return {
            yesDate,
            date,
            week,
            time,
            msTime
        }
    },
    showMessage(str, type) {
        this.$message({
            message: str,
            type: type
        });
    },
}