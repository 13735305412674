<!-- 批量设置 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">内容管理</span>
        <span slot="third">launcher配置</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <el-tabs
        v-model="activeTag"
        @tab-click="handleClick"
        class="incomeTab"
        style="margin-bottom:20px;"
      >
        <el-tab-pane label="launcher" name="launcher">
          <launcherMore></launcherMore>
        </el-tab-pane>
        <el-tab-pane label="分发统计" name="list">
          <launcherRealeaseList ref="lanRealease"></launcherRealeaseList>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import launcherMore from "./components/launcherMore";
import launcherRealeaseList from "./components/launcherRealeaseList";
export default {
  name: "launcherSetting",
  components: { launcherMore, launcherRealeaseList },
  data() {
    return {
      bread: {
        level: 3,
      },
      activeTag: "launcher",
    };
  },
  methods: {
    handleClick() {
      if (this.activeTag == "list") {
        this.$refs.lanRealease.getList(1);
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
