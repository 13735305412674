<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span v-if='!query' slot="second">系统设置</span>
        <span v-if='!query' slot="third">标签管理</span>
        <span v-if='!query' slot="fourth">标签记录</span>
        <span v-if='query' slot="second">标签记录</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <el-card>
      <div class="title blueTitle">标签记录列表</div>
      <div class="btnDiv">
        <el-button class="btn" @click="labelAdd()" type="primary" v-if="showFunModel('打标签')">打标签</el-button>
        <el-button class="btn" @click="labelSub()" type="danger" v-if="showFunModel('下架标签')">下架标签</el-button>
        <el-button class="btn" type="warning" @click="exportLabelHistory" :loading="importLoding" v-if="showFunModel('导出')" style="float: right">导出
        </el-button>
      </div>
      <el-table :data="tableList" :header-cell-style="headerStyle" style="width: 100%" v-loading="tableLoading">
        <el-table-column label="操作人" min-width="200px" prop="operator_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作时间" min-width="300px" prop="create_time_text" show-overflow-tooltip></el-table-column>
        <el-table-column label="事件" min-width="800px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.type=='1'">
              单店打标签：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
            </span>
            <span v-if="scope.row.type=='2'">
              单店打标签组：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
            </span>
            <span v-if="scope.row.type=='3'">
              批量打标签：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
              <el-button size="mini" type="text" @click="showdetailDraw(scope.row.id)">详情</el-button>
            </span>
            <span v-if="scope.row.type=='4'">
              批量打标签组：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
              <el-button size="mini" type="text" @click="showdetailDraw(scope.row.id)">详情</el-button>
            </span>
            <span v-if="scope.row.type=='5'">
              单店取消标签：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
            </span>
            <span v-if="scope.row.type=='6'">
              单店取消标签组：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
            </span>
            <span v-if="scope.row.type=='7'">
              批量取消标签：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
              <el-button size="mini" type="text" @click="showdetailDraw(scope.row.id)">详情</el-button>
            </span>
            <span v-if="scope.row.type=='8'">
              批量取消标签组：
              {{ scope.row.label_history_labels[0].label_name }}
              <el-button size="mini" type="text" v-if="scope.row.label_history_labels.length>1" @click="showLabelDraw(scope.row)">详情</el-button>
              至
              {{ scope.row.label_history_hotels[0].hotel_name }}
              <el-button size="mini" type="text" @click="showdetailDraw(scope.row.id)">详情</el-button>
            </span>
            <!--                        <span v-if="scope.row.type=='9'">-->
            <!--                             单店操作标签：-->
            <!--                             {{ scope.row.label_history_labels[0].label_name }}-->
            <!--                               <el-button size="mini" type="text"-->
            <!--                                          v-if="scope.row.label_history_labels.length>1"-->
            <!--                                          @click="showLabelDraw(scope.row)">详情</el-button>-->
            <!--                             至-->
            <!--                             {{ scope.row.label_history_hotels[0].hotel_name }}-->
            <!--                         </span>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <el-drawer title="更多" size="800px" :visible.sync="labelDraw">
      <div style="width: 100%;margin: 20px 0 0 20px;overflow: hidden;" class="label_list">
        <div style="height: 800px;    overflow-y: scroll;">
          <div class="checkedLabelBox">
            <template v-for="(item, index) in labels">
              <el-tooltip class="item" :key="index" effect="dark" placement="top" :content="item">
                <div class="checkedLabel checkedLabel1">{{ item }}</div>
              </el-tooltip>
            </template>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="更多" size="800px" v-loading="drawLoading" :visible.sync="detailDraw">
      <div style="width: 100%;margin-left: 20px" class="draw_box">
        <div style="width: 100%;">
          <el-input placeholder="酒店名称关键词搜索" class="hotelSearch" v-model="searchHotel">
            <el-button slot="append" icon="el-icon-search" @click="searchHotelText"></el-button>
          </el-input>
          <el-link type="primary" @click="resetNewInfo" :underline="false" style="margin-left: 20px">重置
          </el-link>
        </div>
        <div style="height: 800px;    overflow-y: scroll;">
          <div class="checkedLabelBox">
            <template v-for="(item, index) in drawHotelgroup">
              <el-tooltip class="item" :key="index" effect="dark" placement="top" :content="item">
                <div class="checkedLabel checkedLabel1">{{ item }}</div>
              </el-tooltip>
            </template>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { exportHistory, findHotelName, historyList } from "@/api/label.js";
import qs from "querystring";
import commonMeth from "@/api/method";
export default {
  name: "",
  props: [""],
  data: () => ({
    importLoding: false,
    drawLoading: "",
    token: "",
    tableLoading: false,
    bread: {
      level: 4,
    },
    activeName: "",
    tableList: [],
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    table: {
      page: 1,
      limit: 5,
      total: 0,
    },
    condition: {
      startTime: "",
      endTime: "",
      operator: "",
      operation: "",
    },
    detailDraw: false,
    searchHotel: "",
    drawHotelgroup: [],
    topSearchShow: {},
    fromUrl: "labelHistory",
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    history_id: "",
    labels: [],
    labelDraw: false,
    funArr: "",
    query: null,
  }),
  computed: {
    // 权限
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    //标签更多
    showLabelDraw(label) {
      this.labels = [];
      for (var i = 0; i < label.label_history_labels.length; i++) {
        this.labels.push(label.label_history_labels[i].label_name);
      }
      this.labelDraw = true;
    },
    //弹出框内更多酒店
    showdetailDraw(id) {
      this.drawHotelgroup = [];
      this.history_id = id;
      var hotel = {};
      var hotel_name = "";
      hotel_name = this.searchHotel;
      this.detailDraw = true;
      this.drawLoading = true;
      const json = {
        history_id: id,
        hotel_name: "",
      };
      var param = qs.stringify(json);
      findHotelName(param)
        .then((res) => {
          if (res.data.code == 100000) {
            hotel = res.data.data;
            for (var i = 0; i < hotel.length; i++) {
              this.drawHotelgroup.push(hotel[i].hotel_name);
            }
            hotel = {};
            hotel_name = "";
            this.searchHotel = "";
            this.drawLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //上方筛选相关
    getTopSearchShow(type) {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      this.topSearchShow = {
        operator: true,
        operation: true,
        date: true,
      };
      this.topSearchShow.operatorPlaceholder = "请输入操作人名称关键词";
    },
    //两个跳转
    labelAdd() {
      this.$router.push({ path: `./labelAdd` });
    },
    labelSub() {
      this.$router.push({ path: `./labelSub` });
    },
    handleSizeChange(val) {
      this.table.limit = val;
      this.getLabelHistory();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getLabelHistory();
    },
    getCondition(val) {
      this.condition.startTime = val.startTime;
      this.condition.endTime = val.endTime;
      (this.condition.operator = val.operator),
        (this.condition.operation = val.operation),
        (this.table.page = 1);
      this.getLabelHistory();
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    //表格数据
    getLabelHistory() {
      this.tableLoading = true;
      const json = {
        page: this.table.page,
        limit: this.table.limit,
        event: this.condition.operation,
        operate_time_start: this.condition.startTime,
        operate_time_end: this.condition.endTime,
        operator_name: this.condition.operator,
      };
      if (
        json.operate_time_start == json.operate_time_end &&
        json.operate_time_start != ""
      ) {
        json.operate_time_start = json.operate_time_start + " 00:00:00";
        json.operate_time_end = json.operate_time_end + " 24:00:00";
      }
      var param = qs.stringify(json);
      historyList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableList = res.data.data.items;
            this.table.total = res.data.data.total;
            this.tableLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //导出
    exportLabelHistory() {
      this.importLoding = true;
      this.$message.success(
        "列表导出操作正在处理,文件处理完毕将自动下载,请稍等"
      );
      var token = sessionStorage.getItem("token");
      let json = {
        page: this.table.page,
        limit: this.table.limit,
        event: this.condition.operation,
        operate_time_start: this.condition.startTime,
        operate_time_end: this.condition.endTime,
        operator_name: this.condition.operator,
        token: this.token,
      };
      var param = qs.stringify(json);
      exportHistory(param)
        .then((res) => {
          const content = res.data;
          const blob = new Blob([content]);
          const fileName = "历史标签记录列表.xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
            this.importLoding = false;
            this.$message.success("导出成功");
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
            this.importLoding = false;
            this.$message.success("导出成功");
          }
        })
        .catch((err) => {});
    },
    //酒店名称搜索
    searchHotelText() {
      this.drawHotelgroup = [];
      var hotel = {};
      var hotel_name = "";
      hotel_name = this.searchHotel;
      this.detailDraw = true;
      const json = {
        history_id: this.history_id,
        hotel_name: hotel_name,
      };
      var param = qs.stringify(json);
      findHotelName(param)
        .then((res) => {
          if (res.data.code == 100000) {
            hotel = res.data.data;
            for (var i = 0; i < hotel.length; i++) {
              this.drawHotelgroup.push(hotel[i].hotel_name);
            }
            hotel = {};
            hotel_name = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //    重置
    resetNewInfo() {
      this.showdetailDraw(this.history_id);
    },
  },
  created() {},
  watch: {
    funArr: {
      handler: function (newVal) {
        this.funArr = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.query = sessionStorage.getItem("apaasData");
    if (this.query) {
      this.bread.level = 3;
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      commonMeth.getTreeData().then((res) => {
        this.funArr = res.funArr;
      });
    }

    this.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.getLabelHistory();
  },
};
</script>

<style lang="scss" scoped>
.hotelBrand {
  width: 100%;
}

.rowBox {
  margin-top: 20px;
}

.vertical {
  box-sizing: border-box;

  .grid-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;

    .numTitle {
      margin-bottom: 20px;
    }

    .num {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.el-table {
  margin-top: 15px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.rowBox.horizontal {
  .grid-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border: 1px solid #ddd;

    .leftBox {
      display: flex;
      align-items: center;
    }
    .draw_box {
      overflow: hidden;
    }
    .numTitle {
      width: 150px;
    }

    .num {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.btnDiv {
  margin-top: 20px;
}

.btn {
  width: 100px;
  height: 40px;
}

.draw_span {
  color: #409eff !important;
  margin-top: 5px;
  border-width: 0px;
  background-color: #f1f8ff;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #f1f8ff;
  border-radius: 10px;
}

.bread {
  flex: 1;
}

.checkedLabel {
  width: calc(100% / 2 - 4%);
  text-align: center;
  height: 32px;
  line-height: 32px;
  padding: 0 15px 0 15px;
  margin: 0 1% 1%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  border: 1px solid #3688f8;
  color: #3688f8;
}

.checkedLabel1 {
  background: #f1f8ff;
}

.checkedLabelBox {
  margin: 0;
  width: 100%;
  display: flex;
  float: left;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.import_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.delBtn span {
  width: 1px;
  height: 18px;
  background: #3688f8;
}

.hotelSearch {
  width: 25vw;
  margin: 10px 0;
}
.label_list {
  list-style: none;
  overflow: hidden;
  overflow-y: visible;
}
</style>
