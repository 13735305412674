<!-- mac解绑弹窗 -->
<template>
  <el-dialog title="mac解绑" :visible.sync="dialogVisible" custom-class="oper-dia" :close-on-click-modal="false" @closed="close">
    <!-- 表单 -->
    <div>
      <span>MAC：</span>
      <el-input v-model="keyWord" placeholder="" class="dia_input"></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
    <!-- 搜索数据 -->
    <div class="table_row">
      <el-table :data="tableData" style="width: 100%" border height="362" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="room_number" label="客房名称"> </el-table-column>
        <el-table-column prop="room_date" label="首次上线时间"> </el-table-column>
        <el-table-column prop="hotelName" label="酒店程序名"> </el-table-column>
        <el-table-column prop="hotel" label="酒店名称"> </el-table-column>
      </el-table>
    </div>
    <!-- 按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="unbind" :disabled="selectData.length == 0">解 绑</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getList, unbind } from "./api/macBund";
export default {
  name: "macUnbundingDia",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      keyWord: "",
      tableData: [],
      selectData: [],
    };
  },
  methods: {
    unbind() {
      unbind({
        roomId: this.selectData
          .map((item) => {
            return item.id;
          })
          .join(","),
      }).then((data) => {
        if (data.data.code == -1) {
          this.$message.error(data.data.msg);
        } else {
          this.$message.success(data.data.msg);
          this.dialogVisible = false;
        }
      });
    },
    close() {
      this.selectData = [];
      this.tableData = [];
      this.keyWord = "";
    },
    search() {
      getList({
        mac: this.keyWord,
      }).then((data) => {
        if (data.data.code == -1) {
          this.$message.error(data.data.msg);
        } else {
          if (data.data.data && data.data.data.length != 0) {
            this.tableData = data.data.data;
          } else {
            this.$message.error("暂无数据");
          }
        }
      });
    },
    handleSelectionChange(val) {
      this.selectData = val;
    },
  },
  mounted() {},
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.oper-dia {
  .dia_input {
    width: 50%;
    margin-right: 10px;
  }
  .table_row {
    margin-top: 20px;
  }
}
</style>
