import { render, staticRenderFns } from "./onlyHomeIndex.vue?vue&type=template&id=da680242&scoped=true"
import script from "./onlyHomeIndex.vue?vue&type=script&lang=js"
export * from "./onlyHomeIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da680242",
  null
  
)

export default component.exports