<template>
  <div class="hotelSearchBox">
    <div class="checkCondition" v-loading="hotelLoading">
      <!-- 酒店选择界面 -->
      <div class="checkConditionBox address">
        <p class="title"><span>酒店</span><span style="margin-right:10px">{{checkedHotel.length+'/'+hotels.length}}</span></p>
        <div class="checkList">
          <div class="searchBox">
            <el-select :disabled="isEdit" style="width: 20%;margin-right:10px " v-model="province" placeholder="省份" @change="provinceChange" collapse-tags clearable class="fullSelect" filterable>
              <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
            <el-select :disabled="isEdit" style="width: 20%;margin-right:10px " v-model="city" placeholder="城市" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
            <el-input class="searchInput" :disabled="isEdit" v-model="hotel_name" style="width: 20%;margin-right:10px " placeholder="酒店名称关键字"></el-input>
            <el-button class="trueBtn" type="primary" v-if="!isEdit" @click="getHotelList">搜索</el-button>
            <el-button class="cancelBtn" @click="recetHotelList" v-if="!isEdit">重置</el-button>
          </div>
          <div style="margin: 10px 0"></div>
          <el-checkbox :disabled="isEdit" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="checkedHotel" @change="checkedHotelChange">
            <div class="checkBoxShow">
              <template v-for="hotels in hotels">
                <el-checkbox :disabled="isEdit" :label="hotels.hotelName" :key="hotels.hotelName">
                  <span> {{ hotels.hotel }}</span>
                  <span v-if="hotels.hotel_alias!='默认'"> {{"("+hotels.hotel_alias+")"}}</span>
                </el-checkbox>
              </template>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 酒店选择界面 -->
    </div>
    <!-- 已选酒店 -->
    <div class="showCondition" v-loading="hotelLoading">
      <div class="checkConditionBox">
        <p class="title"><span>已选定向</span>
          <span>
            <span style="margin-right:10px;font-size:12px">{{showHotelList.hotels.length+'家'}}</span>
            <span class="clearAll" v-if="!isEdit" @click="clearAll">清空</span>
          </span>
        </p>
        <div class="searchBox" style="padding :10px">

          <el-input class="searchInput" v-model="check_hotel_name" style="width: 25%;margin-right:10px " placeholder="酒店名称关键字"></el-input>
          <!-- v-if="!isEdit"7 -->
          <el-button class="trueBtn" type="primary" @click="checkSearch">搜索</el-button>
          <el-button class="cancelBtn" @click="recetcheckList">重置</el-button>
        </div>
        <div class="contentShow">
          <div>
            <span style=" position: relative" class="checkCard" v-for="item in showHotelList.showhotels" :key="item.hotelName">
              <span> {{ item.hotel }}</span>
              <span v-if="item.hotel_alias!='默认'"> {{"("+item.hotel_alias+")"}}</span>
              <i class="el-icon-close" v-if="!isEdit" @click="delCheckHotel(item)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 已选酒店 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEdit: false,
      checkAll: false,
      isIndeterminate: true,
      provinceList: [],
      cityList: [],
      province: null,
      city: null,
      hotel_id: "",
      hotel_name: "",
      checkedHotel: [],
      checkedHotelAll: [],
      hotels: [],
      checkHotelList: [],
      showHotelList: {
        hotels: [],
        hotelsId: [],
        showhotels: [],
      },
      hotelLoading: false,
      token: "",
      check_hotel_name: "",
      template_id:'',
    };
  },
  methods: {
    checkSearch() {
      this.showHotelList.showhotels = this.showHotelList.hotels.filter((i) => {
        if (i.hotel.indexOf(this.check_hotel_name) != -1) {
          return i;
        }
      });
    },
    recetcheckList() {
      this.check_hotel_name = "";
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
    },
    provinceChange() {
      this.city = null;
      this.getCity(this.province.value);
    },
    getProvince() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getProvince, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.provinceList = res.data.data;
            }
            resolve();
          });
      });
    },
    getCity(prov_code) {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.cityList = res.data.data;
            }
            resolve();
          });
      });
    },
    // 清空
    clearAll() {
      this.checkedHotel = [];
      this.hotels = [];
      this.checkAll = false;
      this.showHotelList = {
        hotels: [],
        hotelsId: [],
        showhotels: [],
      };
    },
    // 数据回显写入
    intData(data) {
      this.showHotelList.hotels = data;
      this.showHotelList.hotelsId = data.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = data;
      this.checkHotelList = this.showHotelList.hotels;
      this.checkedHotel = this.showHotelList.hotelsId;
      this.hotelLoading = false;
    },

    // 已选酒店删除
    delCheckHotel(item) {
      this.showHotelList.hotels = this.showHotelList.hotels.filter((i) => {
        if (i.hotelName != item.hotelName) {
          return i;
        } else {
          this.checkHotelList = this.checkHotelList.filter((i) => {
            if (i.hotelName != item.hotelName) {
              return i;
            }
          });
        }
      });
      this.showHotelList.hotelsId = this.showHotelList.hotels.map((i) => {
        return i.hotelName;
      });
      this.checkedHotel = this.checkHotelList.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
    },
    handleCheckAllChange(val) {
      if (val) {
        this.checkedHotelAll.forEach((i) => {
          if (this.checkedHotel.indexOf(i) == -1) {
            this.checkedHotel.push(i);
          }
        });
      } else {
        this.checkedHotel = this.checkedHotel.filter((i) => {
          if (this.checkedHotelAll.indexOf(i) == -1) {
            return i;
          }
        });
      }
      this.checkHotelList = this.hotels.filter((i) => {
        if (this.checkedHotel.includes(i.hotelName)) {
          return i;
        }
      });
      let list = this.hotels.map((i) => {
        return i.hotelName;
      });
      var arr1 = this.showHotelList.hotels.filter((i) => {
        if (list.indexOf(i.hotelName) == -1) {
          return i;
        }
      });
      let arr = this.checkHotelList;
      this.showHotelList.hotels = [...arr1, ...arr];
      this.showHotelList.hotelsId = this.showHotelList.hotels.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
      this.isIndeterminate = false;
    },
    //  酒店选择
    checkedHotelChange(value) {
      let checklist = this.checkedHotel.filter((i) => {
        if (this.checkedHotelAll.indexOf(i) != -1) {
          return i;
        }
      });
      if (checklist.length == this.checkedHotelAll.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.checkHotelList = [];
      this.checkHotelList = this.hotels.filter((i) => {
        if (this.checkedHotel.includes(i.hotelName)) {
          return i;
        }
      });
      let list = this.hotels.map((i) => {
        return i.hotelName;
      });
      var arr1 = this.showHotelList.hotels.filter((i) => {
        if (list.indexOf(i.hotelName) == -1) {
          return i;
        }
      });
      let arr = this.checkHotelList;
      this.showHotelList.hotels = [...arr1, ...arr];
      this.showHotelList.hotelsId = this.showHotelList.hotels.map((i) => {
        return i.hotelName;
      });
      this.showHotelList.showhotels = [...this.showHotelList.hotels];
      //   this.showHotelList.hotels.
    },
    // 重置酒店列表
    recetHotelList() {
      this.province = null;
      this.city = null;
      this.hotel_name = "";
      this.getHotelList();
    },
  
    // 获取酒店列表
    getHotelList() {
      this.hotelLoading = true;
      var params = {
        token: this.token,
        keyword: this.hotel_name,
        hotelName:'',
        citycode: this.city ? this.city.value : "",
        province: this.province ? this.province.label : "",
        city: this.city ? this.city.label : "",
        template_id:this.template_id,
        // sort: "id",
        // order: "DESC",
        // screenType: "self",
        // refresh: false,
      };
      this.$http
        .get(this.global.getHotelSearch, {
          params,
        })
        .then((res) => {
          if (res.data.code === 100000 && res.data.data) {
            var tableData = res.data.data;
            let arr = tableData.filter((i) => {
              if (i.hotel_use_state != 2) {
                return i;
              }
            });
            this.hotels = arr;
            this.checkedHotelAll = this.hotels.map((i) => {
              return i.hotelName;
            });
            let checklist = this.checkedHotel.filter((i) => {
              if (this.checkedHotelAll.indexOf(i) != -1) {
                return i;
              }
            });
            if (checklist.length == this.checkedHotelAll.length) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
            this.hotelLoading = false;
          } else {
            this.$message.warning(res.data.msg);
            this.hotelLoading = false;
          }
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.hotelSearchBox {
  width: 100%;
  height: 400px;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.checkCondition {
  width: 50%;
  height: 100%;
  border: 1px solid #ebeef5;
  display: flex;
  justify-content: flex-start;
  margin-right: 16px;
}
.showCondition {
  width: calc(50% - 16px);
  height: 100%;
  border: 1px solid #ebeef5;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-top: 1px solid #e4e7ed !important;
  border-bottom: 1px solid #e4e7ed !important;
  border-radius: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: 1px solid #e4e7ed !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  border-right: 1px solid #e4e7ed !important;
}
.checkCondition .checkConditionBox {
  flex: 1;
}

.checkCondition .address:last-child {
  border-right: none;
}
.checkCondition .title,
.showCondition .title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ebeef5;
  background: #f7f8fa;
  color: #222;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title span:first-child {
  color: #596080;
  font-size: 16px;
}
.title span:last-child {
  color: #8a8c99;
  font-size: 12px;
}
.checkList {
  padding: 10px;
  height: 340px;
}
.checkBoxShow {
  height: 270px;
  overflow: hidden;
  overflow-y: visible;
}
.el-checkbox {
  display: block;
}
.showCondition .checkConditionBox {
  height: calc(100%);
}
.showCondition .contentShow {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: calc(100% - 130px);
  overflow: hidden;
  overflow-y: visible;
}
.showCondition .contentShow > div {
  width: 100%;
}
.checkType {
  margin-right: 5px;
  font-size: 14px;
}
.checkCard {
  display: inline-block;

  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ebebed;
  background: #f8f9fa;
  color: #596080;
  padding: 0 20px 0 10px;
  border-radius: 3px;
}
::v-deep .el-checkbox__label {
  width: 100%;
}
::v-deep .el-checkbox:last-of-type {
  margin-right: 30px !important;
}
.openPlace {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.openPlace i {
  font-size: 14px;
  cursor: pointer;
}
.openPlace i:hover {
  color: #658bff;
}
.el-radio {
  display: block;
}
.greenBtn {
  width: 15%;
  max-width: 100px;
  background: #658bff;
  border: 1px solid #658bff;
  color: #fff;
}
.whiteBtn {
  width: 15%;
  max-width: 100px;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.el-icon-close {
  position: absolute;
  top: 50%;
  right: -2px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.el-checkbox {
  margin-bottom: 10px;
}
.clearAll {
  border: 1px solid #ebebed;
  margin-right: 10px;
  height: 28px;
  padding: 0 10px;
  line-height: 28px;
  color: #596080 !important;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
</style>