<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">酒店管理</span>
                <span slot="third">新建酒店</span>
                <span slot="fourth">新建</span>
            </top-bread>
        </div>
        <el-card class="box-card noHideEl">
            <back @backMeth="backMeth"></back>
            <detail-no :edit="false" :id="false" v-if="auth.type!=='htrip' && auth.type!=='screen'"
                       @getHotelTemplateId="getHotelTemplateId"></detail-no>
            <detail-juping v-if="auth.type==='screen'" :id="false" :edit="false"></detail-juping>
            <detail-has v-if="auth.type==='htrip'" :id="false" :edit="false"></detail-has>
        </el-card>
    </div>
</template>

<script>
import qs from 'querystring'

export default {
    name: "hotelManNew",
    data() {
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请填写手机号码'))
            } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
                return callback(new Error('请填写正确的手机号码'))
            } else {
                callback()
            }
        };
        return {
            auth: {},
            bread: {
                level: 4,
                firstPath: {path: '/indexShow'},
                thirdPath: {path: '/hotelManNewList'},
            },
            selectData: {
                hotelGroupList: [],
                hotelTemplateList: [],
                province: [],
                city: [],
                country: [],
                hotel_level_select: [],
                hotel_quality_select: [],
            },
            form: {
                hotel_brand: '',
                hotel_store_id: '',
                hotel: '',
                contract_365: '',
                hotel_template_id: '',
                templateType: 'hihotel',
                templateId: '',
                hotel_level: '',
                hotel_quality: '',
                hotel_room: '',
                total_num: '',
                h_ota_price: '',
                pms_brand: '',
                hotel_charger: '',
                hotel_charger_phone: '',
                maintain_name: '',
                maintain_phone: '',
                address: '',
                citycode: '',
                province: '',
                city: '',
                country: '',
            },
            region: {
                prov_code: '',
                city_code: '',
                country: '',
            },
            rules: {
                hotel_brand: [
                    {required: true, message: '请输入集团品牌', trigger: 'blur'}
                ],
                hotel_store_id: [
                    {required: true, message: '请输入集团门店ID', trigger: 'blur'}
                ],
                hotel: [
                    {required: true, message: '请输入终端门店名称', trigger: 'blur'}
                ],
                contract_365: [
                    {required: true, message: '请输入终端门店编码', trigger: 'blur'}
                ],
                templateType: [
                    {required: true, message: '请选择终端门店系统版式', trigger: 'blur'}
                ],
                hotel_level: [
                    {required: true, message: '请选择终端门店级别', trigger: 'blur'}
                ],
                hotel_quality: [
                    {required: true, message: '请选择终端门店性质', trigger: 'blur'}
                ],
                hotel_room: [
                    {required: true, message: '请输入客房总量', trigger: 'blur'}
                ],
                total_num: [
                    {required: true, message: '终端数量', trigger: 'blur'}
                ],
                h_ota_price: [
                    {required: true, message: '请输入OTA房价', trigger: 'blur'}
                ],
                hotel_charger: [
                    {required: true, message: '请输入终端门店负责人', trigger: 'blur'}
                ],
                hotel_charger_phone: [
                    {required: true, message: '请输入终端门店负责人电话', trigger: 'blur'}
                ],
                maintain_name: [
                    {required: true, message: '请输入终端门店系统维护人', trigger: 'blur'}
                ],
                maintain_phone: [
                    {required: true, validator: checkPhone, trigger: 'blur'}
                ],
                address: [
                    {required: true, message: '请输入详细地址', trigger: 'blur'}
                ],
                province: [
                    {required: true, message: '请选择', trigger: 'blur'}
                ],
                city: [
                    {required: true, message: '请选择', trigger: 'blur'}
                ],
                country: [
                    {required: true, message: '请选择', trigger: 'blur'}
                ],
            },
            token: '',
            lng: '',
            lat: '',
            map: {
                center: {lng: 116.404, lat: 39.915},
                zoom: 3
            },
        }
    },
    methods: {
        provinceChange(val) {
            this.region.prov_code = val;
            this.region.city = '';
            this.region.country = '';
            this.form.city = '';
            this.form.country = '';
            this.getCity();
            this.selectData.country = [];
        },
        cityChange(val) {
            this.region.city_code = val;
            this.region.country = '';
            this.form.country = '';
            this.getCountry();
        },
        countryChange(val) {
            this.form.citycode = val;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('确定提交?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.create();
                    })
                } else {
                    this.$message.warning('请完善信息');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        getProvince() {
            this.$http.get(this.global.getProvince, {
                params: {
                    token: this.token
                }
            }).then(res => {
                if (res.data.code === 100000) {
                    this.selectData.province = res.data.data;
                }
            });
        },
        getCity() {
            var param = qs.stringify({token: this.token, prov_code: this.region.prov_code});
            this.$http.get(this.global.getCitys, {
                params: {
                    token: this.token,
                    prov_code: this.region.prov_code
                }
            }).then(res => {
                if (res.data.code === 100000) {
                    this.selectData.city = res.data.data;
                }
            });
        },
        getCountry() {
            this.$http.get(this.global.getDistricts, {
                params: {
                    token: this.token,
                    prov_code: this.region.prov_code,
                    city_code: this.region.city_code
                }
            }).then(res => {
                if (res.data.code === 100000) {
                    this.selectData.country = res.data.data;
                }
            });
        },
        getOption() {
            var param = qs.stringify({token: this.token});
            this.$http.post(this.global.getOptions, param).then(res => {
                if (res.data.code === 100000) {
                    this.selectData.hotelGroupList = res.data.data.hotelGroupList;
                    this.selectData.hotelTemplateList = res.data.data.hotelTemplateList;
                    this.getHotelTemplateId('hihotel');
                }
            });
        },
        getHotelTemplateId(val) {
            this.selectData.hotelTemplateList.filter((item, index) => {
                if (item.name.indexOf(val) !== -1) {
                    this.form.hotel_template_id = item.id;
                }
            });
        },
        getLevelQuality() {
            var param = qs.stringify({token: this.token});
            this.$http.post(this.global.getHotelLevelAndQuality, param).then(res => {
                if (res.data.code === 100000) {
                    this.selectData.hotel_level_select = res.data.data.level;
                    this.selectData.hotel_quality_select = res.data.data.quality;
                }
            });
        },
        create() {
            var param = '';
            if (this.auth.type !== 'htrip') {
                param = qs.stringify({
                    token: this.token,
                    hotel_brand: this.form.hotel_brand,
                    hotel_store_id: this.form.hotel_store_id,
                    hotel: this.form.hotel,
                    contract_365: this.form.contract_365,
                    template: {type: this.form.templateType, id: this.form.templateId},
                    // hotel_template_id:this.templateDialog.templateFormat,
                    hotel_template_id: '1',
                    hotel_room: this.form.hotel_room,
                    total_num: this.form.total_num,
                    h_ota_price: this.form.h_ota_price,
                    pms_brand: this.form.pms_brand,
                    hotel_charger: this.form.hotel_charger,
                    hotel_charger_phone: this.form.hotel_charger_phone,
                    maintain_name: this.form.maintain_name,
                    maintain_phone: this.form.maintain_phone,
                    citycode: this.form.citycode,
                    address: this.form.address,
                    lat: this.map.center.lat,
                    lng: this.map.center.lng,
                    brand_name: this.form.brand_name
                });
            }
            this.$http.post(this.global.create, param).then(res => {
                if (res.data.code === 100000) {
                    this.$message({
                        message: '创建成功',
                        type: 'success'
                    });
                    this.$router.push({path: '/hotelManAll'});
                } else if (res.data.code === 100001) {
                    this.$message.error(res.data.msg);
                } else if (res.data.code === 100002) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        templateTypeChange(val) {
            if (val === 'ehotel') {
                this.templateDialog.visible = true;
                this.templateDialog.templateFormat = '';
                this.form.templateId = '';
                this.getHotelTemplate();
            } else {
                this.form.templateLabel = '';
                this.getHotelTemplateId('hihotel');
            }
        },
        dialogConfirm() {
            if (this.form.templateId === '' || this.templateDialog.templateFormatRadioList === '') {
                this.$message.warning('请完善信息');
            } else {
                this.templateDialog.visible = false;
                if (this.templateDialog.templateFormatLabel.indexOf('标准') !== -1) {
                    this.getHotelTemplateId('标准');
                } else if (this.templateDialog.templateFormatLabel.indexOf('欢朋单体') !== -1) {
                    this.getHotelTemplateId('欢朋单体');
                }
            }
        },
        dialogCancel() {
            this.templateDialog.visible = false;
            if (this.form.templateId === '') {
                this.form.templateType = 'hihotel';
                this.getHotelTemplateId('hihotel')
            }
        },
        getInfoClick(e) {
            this.map.center.lat = e.Ag.lat;
            this.map.center.lng = e.Ag.lng;
        },
        syncCenterAndZoom(e) {
            const {lng, lat} = e.target.getCenter();
            this.map.center.lng = lng;
            this.map.center.lat = lat;
            this.map.zoom = e.target.getZoom()
        },
        handler({BMap, map1}) {
            // this.map.center.lng = 116.404;
            // this.map.center.lat = 39.915;
            // var map=new BMap.map('map');
            // var ac = new BMap.Autocomplete( //建立一个自动完成的对象
            //     {
            //         'input': 'suggestId',
            //         'location': map
            //     });
            this.map.center.lng = this.form.lng;
            this.map.center.lat = this.form.lat;
            this.map.zoom = 15
        },
        confirm(e, target, item) {
            this.map.center.lat = e.currentTarget.map.re.lat;
            this.map.center.lng = e.currentTarget.map.re.lng;
        },
        getHotelTemplate() {
            this.$http.get(this.global.getHotelTemplate, {
                params: {
                    token: this.token
                }
            }).then(res => {
                if (res.data.code === 100000) {
                    var arr = [];
                    arr.push(res.data.data[1]);
                    arr.push(res.data.data[2]);
                    this.templateDialog.templateFormatRadioList = arr;
                }
            });
        },
        searchMeth() {
            this.form.templateId = '';
            var keyword = this.templateDialog.keyword;
            this.templateDialog.searchTemplateRadioList = [];
            this.templateDialog.templateRadioList.filter((item, index) => {
                var a = item.label.indexOf(keyword);
                if (item.label.indexOf(keyword) !== -1) {
                    this.templateDialog.searchTemplateRadioList.push(item);
                }
            });
        },
        backMeth() {
            sessionStorage.removeItem('home');
            sessionStorage.removeItem('welcomePage');
            sessionStorage.removeItem('hotelService');
            sessionStorage.removeItem('hotelServiceTwo');
            sessionStorage.removeItem('jumpUrl');
            this.$router.go(-1)
        },
    },
    mounted() {
        this.token = sessionStorage.getItem('token');
        this.auth = JSON.parse(sessionStorage.getItem('auth'));
        this.getProvince();
        this.getOption();
        this.getLevelQuality();

    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.el-table {
    margin-top: 15px;
}

.pageBox {
    margin-top: 15px;
}

.saveBtn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .el-button {
        width: 120px;
        margin-left: auto;
    }
}

.selectItem {
    width: 100%;
}

.btnFormItem {
    display: flex;
    margin-bottom: 20px;
}

.submit {
    margin-left: auto;
}

.radioBox {
    width: 100%;
}

.radioBox {
    margin-top: 12px;
}

img, video {
    width: 100%;
    height: 100%;
}

.templateFormatBox {
    display: flex;
    justify-content: space-around;
}

.templateTitle {
    margin-top: 70px;
}

.marginBottom {
    margin-bottom: 20px;
}

.imgItem {
    width: 48%;
}

.imgBox {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.templateFormatRadio {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0 !important;
}

.twoRow {
    display: flex;
    flex-direction: column;
}

.map {
    width: 100%;
    height: 0;
}

#map {
    width: 100%;
}

.searchInput {
    position: absolute;
    top: -64px !important;
    background: red;
    /*z-index:999;*/
}

.coordinateBox {
    display: flex;
}

.coordinate {
    width: 50%;
}

.searchBox {
    display: none !important;
}

.pointer {
    cursor: pointer;
}
</style>
