<template>
  <div class="visualBox">
    <op-tab-edit1 :hotelContract="hotelContract" v-if="materialEdit" :configData="configData" :noData="noData" :hotelTemplateType="hotelTemplateType" element-loading-spinner="el-icon-loading" @getHotelConfigInfo="getHotelConfigInfo(this.hotelContract)"></op-tab-edit1>
  </div>
</template>
<script>
import qs from "querystring";
export default {
   props:{
    query:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      token: "",
      hotelId:"",
      hotelContract: "",
      materialEdit: false,
      configData: {},
      noData: false,
      hotelTemplateType: "",
      notSupportTemplate: [],
    };
  },
   watch:{
     query: {
                handler: function (newVal) {
                   this.token =  sessionStorage.getItem("token");
                  this.hotelId =newVal.id;
                  this.hotelContract =newVal.hotel_contract;
                  this.getHotelConfigInfo();
                    this.getHotelDetail();
                },
                deep: true,
                immediate: true,
            },
  },
  mounted() {
     
  },
  methods: {
    getHotelDetail() {
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
             id:this.hotelId,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.hotelTemplateType = res.data.data.template_type;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getHotelConfigInfo(hotelContract) {
      var param = qs.stringify({
        token: this.token,
          hotelContract: this.hotelContract,
      });
      this.$http
        .post(this.global.getHotelCurrentVisualInfo, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$store.commit("getConfigData", res.data.data);
            this.configData = this.$store.state.configData;
            if (
              this.notSupportTemplate.indexOf(res.data.data.template_id) !== -1
            ) {
            }
            this.noData = false;
          } else if (res.data.msg === "暂不支持") {
            this.noData = true;
          } else {
            this.$message.error(res.data.msg);
          }
          this.materialEdit = true;
        })
        .catch(() => {
          // debugger;
        });
    },
  },
};
</script>
<style scoped>
.visualBox {
  width: 1152px;
  height: auto;
  margin: 20px auto;
}
</style>