<!-- 批量配置 -->
<template>
  <div class="mul_set_oper_box">
    <el-table
      :data="data"
      style="width: 100%"
      border=""
      :header-cell-style="headerStyle"
    >
      <el-table-column prop="name" label="模块"> </el-table-column>
      <el-table-column prop="add" label="添加" min-width="120px">
        <template slot-scope="scope">
          <div>
            <el-button
              type="success"
              class="oper_btn"
              @click="handleOpen(scope.row.name, 'add')"
              >批量增加</el-button
            >
            <div>
              注：只在酒店后台新增此参数，酒店已有此参数的不做修改
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mod" label="修改" min-width="100px">
        <template slot-scope="scope">
          <div>
            <el-button
              type="primary"
              class="oper_btn"
              @click="handleOpen(scope.row.name, 'edit')"
              >批量修改</el-button
            >
            <div>
              注：修改酒店后台此参数值，没有则新增
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="del" label="删除" min-width="100px">
        <div slot-scope="scope">
          <el-button
            type="warning"
            class="oper_btn"
            @click="handleOpen(scope.row.name, 'del')"
            >批量删除</el-button
          >
          <div>
            注：删除酒店后台此下载项，没有则不做修改
          </div>
        </div>
      </el-table-column>
    </el-table>
    <!-- 参数配置弹窗 -->
    <ParamsDialog :type="type" :visible.sync="paramsDia"></ParamsDialog>
    <ServiceDialog :type="type" :visible.sync="serviceDia"></ServiceDialog>
    <DownloadDialog :type="type" :visible.sync="downloadDia"></DownloadDialog>
  </div>
</template>
<script>
import ParamsDialog from "@/components/operation/MulSetting/components/paramsDialog";
import ServiceDialog from "@/components/operation/MulSetting/components/serviceDialog";
import DownloadDialog from "@/components/operation/MulSetting/components/downloadDialog";

export default {
  name: "mulSetOper",
  components: { ParamsDialog, ServiceDialog, DownloadDialog },
  data() {
    return {
      data: [
        {
          name: "参数配置",
          add: "",
          mod: "",
          del: "",
        },
        {
          name: "服务配置",
          add: "",
          mod: "",
          del: "",
        },
        {
          name: "下载配置",
          add: "",
          mod: "",
          del: "",
        },
      ],
      paramsDia: false,
      serviceDia: false,
      downloadDia: false,
      type: "add",
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
    };
  },
  methods: {
    getList(){},
    handleOpen(tag, type) {
      this.type = type;
      if (tag == "参数配置") {
        this.paramsDia = true;
      }
      if (tag == "服务配置") {
        this.serviceDia = true;
      }
      if (tag == "下载配置") {
        this.downloadDia = true;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.mul_set_oper_box {
  .oper_btn {
    margin: 10px 0px;
  }
}
</style>
