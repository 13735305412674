import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
//  
export function TVPreview(data) {
  return request({
    url: `${vi}/Hoteltelevision/TVPreview`,
    method: 'post',
    data,
  });
}
export function importTv(data) {
  return request({
    url: `${vi}/Hoteltelevision/importTv`,
    method: 'post',
    data,
  });
}


