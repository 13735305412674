<template>
  <div>
    <div>
      <div class="mainBox" style="height:100%;position:relative">
        <el-row :gutter="20" style="margin-top:20px">
          <el-col :span="24">
            <el-input placeholder="请输入房间号" class="input-with-select fullSelect" v-model="roomid" :clearable="true">
              <el-button slot="append" icon="el-icon-search" @click="clickFilter"></el-button>
            </el-input>
          </el-col>
        </el-row>
        <div class="roomBox">
          <div ref="roomList">
            <el-col :span="3" v-for="(item,index) in roomList" :key="index">
              <div class="room" @click="changeSpan(item.rname)" :class="{'change':spanIndex.indexOf(item.rname)>-1}">{{item.rname}}</div>
            </el-col>
          </div>
          <el-col :span="3" v-for="(item,index) in searchList" :key="index" class="allroom">
            <div class="room" @click="changeSpan(item.rname)" :class="{'change':spanIndex.indexOf(item.rname)>-1}">{{item.rname}}</div>
          </el-col>
        </div>
        <div class="drawerFooter">
          <div class="btnBox">
            <el-button @click="showDrawer(false)" class="largeBtn">取 消</el-button>
            <el-button type="primary" @click="submitMeth" class="largeBtn">保 存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "querystring";
import Bus from "@/api/Bus";
import {
  getList,

} from "@/api/guestRoom";
export default {
  isActive: false,
  name: "Online",
  props: ["showData"],
  data: () => ({
    spanIndex: [],
    isActive: false,
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/hotelManAll" },
    },
    type: "timeCenter",
    roomid: "",
    roomList: [],
    searchList: [],
    token: "",
    query: {},
    loading: false,
    drawer: false,
    direction: "rtl",
  }),
  methods: {
    clickFilter() {
      this.$refs.roomList.style.display = "none";
      var roomid = this.roomid;
         if (roomid) {
        this.roomList.forEach((item, index) => {
          if (item.rname.indexOf(roomid) !== -1) {
            this.searchList.push(item);
          }
        });
      
      } else {
        this.searchList = this.roomList;
      }
      // if (roomid) {
      //   this.searchList = this.roomList.filter(function (roomList) {
      //     return Object.keys(roomList).some(function (key) {
      //       return String(roomList[key]).toLowerCase().indexOf(roomid) > -1;
      //     });
      //   });
      // } else {
      //   this.searchList = this.roomList;
      // }
    },
    changeSpan(room) {
      let arrIndex = this.spanIndex.indexOf(room);
      if (arrIndex > -1) {
        this.spanIndex.splice(arrIndex, 1);
      } else {
        this.spanIndex.push(room);
      }
    },


    
    getRoom() {
      this.loading = true;
      let json = {
        alive: "1",
        database: this.query.database,
        hotel_contract: this.query.hotel_contract,
        ip: "",
        name: "",
        page: 1,
        size: 9999,
      };
      getList(json).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data) {
            if (!res.data.data.row) {
              this.roomList = [];
               this.loading = false;
              return;
            }
            this.roomList = res.data.data.row;
               this.loading = false;
          } else {
            this.roomList = [];
               this.loading = false;
          }
        }else{
             this.loading = false;
        }
      });
    },
    // getRoom() {
    //   this.loading = true;

    //   this.$http
    //     .get(this.global.getRoomsNew, {
    //       params: {
    //         token: this.token,
    //         id: this.query.id,
    //         hotel_project: this.query.database,
    //         // id:5268
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data.code === 100000) {
    //         var roomData = res.data.data;
    //         this.roomList = roomData.details;
    //       }
    //       this.loading = false;
    //     });
    // },
    submitMeth() {
      if (this.spanIndex.length === 0) {
        this.$message.warning("所选房间不能为空");
      } else {
        sessionStorage.setItem(
          "roomList",
          JSON.stringify(this.spanIndex.join(","))
        );
        //  this.$store.commit('publishRoomStr',this.spanIndex.join(','))
        window.opener.getSonData(this.spanIndex.join(","));
        window.close();
      }
    },
    showDrawer(val) {
      window.close();
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.query = this.$route.query;
    console.log(this.query);
    this.spanIndex = this.query.roomStr ? this.query.roomStr.split(",") : [];
    this.getRoom();
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
  },
  created() {
    Bus.$on("showDrawer", (obj) => {
      this.drawer = obj.show;
      this.spanIndex = obj.roomStr ? obj.roomStr.split(",") : [];
    });
  },
};
</script>


<style scoped lang="scss">
.btn {
  position: absolute;
  width: 100px;
  right: 20px;
}
.ybtn {
  margin-bottom: 40px;
}
.room {
  text-align: center;
  color: #3085fc;
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 27px;
  cursor: pointer;
  height: 30px;
}
.room:first-child {
  margin: 0;
}
.change {
  color: #3085fc;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.roomBox {
  overflow: hidden;
  padding-bottom: 90px;
}
.roomBox .el-col {
  padding: 20px 10px 10px 10px;
  text-align: center;
  float: left;
}
.el-col:nth-child(12n) {
  padding: 20px 0px 10px 20px;
  float: right;
}
.el-col:first-child {
  padding: 20px 20px 10px 0px;
}
.el-col:nth-child(12n + 1) {
  padding: 20px 20px 10px 0;
  float: left;
}
.mainBox {
  width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  // border: 1px solid cornflowerblue;
}
.drawerFooter {
  display: flex;
}
.btnBox {
  margin-left: auto;
}
</style>

