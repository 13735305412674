<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second" v-if="!apaasData">酒店管理</span>
        <span slot="third" v-if="!apaasData">{{pageInfo.text}}酒店</span>
        <span slot="second">{{pageInfo.text}}酒店</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" @setDefaultQuickDateCur="setDefaultQuickDateCur" :topSearchSave="topSearchSave" :allLoadingState="allLoadingState" ref="topSearch" :isQuickDateCur="true"></top-search>
    </el-card>
    <screen-type @screenTypeSearch="screenTypeSearch" v-if="auth.type!=='group'" :showPartProp="showPart" :pageInfo="pageInfo" :allLoadingState="allLoadingState"></screen-type>
    <el-card class="box-card" v-loading="showLoading" v-if="showFunModel('数据概览')">
      <div class=" rowBox vertical">
        <div class="titleBox">
          <div class="title blueTitle">数据概览</div>
        </div>
        <el-row :gutter="10" style="margin-top:20px;">
          <index-four-row-num-unit :type="type" :span="spanCom" :parShowDate="showData.hotelNum">
            <span slot="numTitle">酒店数量</span>
            <span slot="unit">家</span>
            <span slot="time">{{showDataTime}}</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :type="type" :span="spanCom" :parShowDate="showData.roomNum">
            <span slot="numTitle">客房数量</span>
            <span slot="unit">间</span>
            <span slot="time">{{showDataTime}}</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :type="type" :span="spanCom" :parShowDate="showData.activeTerminalNum">
            <span slot="numTitle">激活终端数量</span>
            <span slot="unit">台</span>
            <span slot="time">{{showDataTime}}</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :type="type" v-if="pageInfo.hotelState!=='del'" :span="spanCom" :parShowDate="showData.dayActiveRate" parShowUnit="money">
            <span slot="numTitle">{{showRateText}}</span>
            <span slot="unit">%</span>
            <span slot="time">{{showDataTime}}</span>
          </index-four-row-num-unit>
        </el-row>
      </div>
    </el-card>
    <div v-if="showline">
      <el-card class="box-card" v-if="showFunModel('数据走势') && pageInfo.hotelState!=='newList' && pageInfo.hotelState!=='del'">
        <div class="lineContent chart">
          <div class="outChartBox" v-loading="lineLoading">
            <one-yaxis-line-chart v-if="lineChart.flag && !lineChart.noData" :chart="lineChart"></one-yaxis-line-chart>
          </div>
          <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">暂无数据</div>
        </div>
      </el-card>
    </div>
    <el-card class="box-card" v-if="showFunModel('列表')">
      <div class="quickDateBox">
        <div class="title blueTitle">酒店列表</div>
      </div>
      <el-row class="btnRow">
        <el-button type="danger" @click="operateConfirmMeth('del','删除')" size="medium" class="largeBtn" v-if="showFunModel('删除')&&!apaasData">删除</el-button>
        <el-button type="danger" @click="operateConfirmMeth('recover','恢复')" size="medium" class="largeBtn" v-if="showFunModel('申请恢复')">恢复</el-button>
        <!--聚屏导出-->
        <!--所有酒店列表导出-->
        <export-btn :exportUrlProp="global.exportAllHotel" :conditionProp="condition" parentProp="allHotel" v-if="showFunModel('导出') && pageInfo.hotelState==='all'"></export-btn>
        <el-button @click="operateConfirmMeth('adv','广告下架')" size="medium" class="largeBtn" v-if="showFunModel('广告下架')&& pageInfo.hotelState==='all'">广告下架</el-button>
        <el-button :disabled="operateIds.split(',').length>1" @click="operateConfirmMeth('editMac','修改')" size="medium" class="largeBtn" v-if="showFunModel('修改MAC地址') && pageInfo.hotelState==='all'">修改MAC地址</el-button>
        <div class="rightBtn">
          <el-button type="primary" @click="newBuild" size="medium" class="largeBtn" v-if="showFunModel('新建')&&!apaasData">新建</el-button>
          <export-btn :exportUrlProp="global.exportCreateHotel" :conditionProp="condition" parentProp="hotelManNewList" v-if="showFunModel('导出') &&  pageInfo.hotelState==='newList'"></export-btn>
          <el-button type="success" @click="dialogExport.dialog = true" size="medium" class="largeBtn" v-if="showFunModel('聚屏酒店导入')&&!apaasData">聚屏导入</el-button>
          <export-btn :exportUrlProp="global.exportScreenHotel" :conditionProp="condition" parentProp="screenHotel" textProp="聚屏导出" v-if="showFunModel('聚屏商导出') && pageInfo.hotelState==='newList'"></export-btn>
          <el-button type="success" @click="virtualExport.dialog = true" size="medium" class="largeBtn" v-if="showFunModel('虚拟运营导入')">虚拟酒店导入</el-button>

          <el-button type="success" @click="virtualRoomExport.dialog = true" size="medium" class="largeBtn" v-if="showFunModel('虚拟运营导入')">虚拟房间导入</el-button>

          <exportBootTime :exportUrlProp="global.exportBootTime" :conditionProp="condition" parentProp="BootTime" v-if="pageInfo.hotelState==='all' && behaviorExportShow" class="boot_time" id="boot_time"></exportBootTime>
        </div>
      </el-row>
      <table-list :table="table" ref="tablePar" @tableMeth="tableMeth" @showTableLoading="showTableLoading" @page="getNewPage" @showUploadDialog="showUploadDialog" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading" :topSearchSave="topSearchSave"></table-list>
    </el-card>

    <el-card class="box-card" v-if="false">
      <el-form :model="editCodeForm" :rules="editCodeRules" ref="editCodeForm" style="width:400px">
        <el-form-item prop="event">
          <el-select class="fullSelect" v-model="editCodeForm.event" clearable collapse-tags placeholder="事件类型">
            <el-option v-for="item in selectData.editCodeEvent" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="editCodeForm.mobile"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="editCodeForm.code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="editCodeConfirmMeth">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog title="导入聚屏商酒店" :visible.sync="dialogExport.dialog" :close-on-click-modal="false" width="60%">
      <el-steps :active="importData.active" align-center class="blueStep">
        <el-step title="上传Excel"></el-step>
        <el-step title="数据预览"></el-step>
        <el-step title="导入数据"></el-step>
      </el-steps>
      <div class="container">
        <div class="first stepItem" v-if="importData.active===0">
          <div class="uploadBox " v-if="importData.active===0 ">
            <el-upload class="upload-demo" drag accept=".xlsx" :action="global.upload" :headers="headers" :on-success="uploadSuccess" :before-upload="beforeUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </div>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning" v-if="importData.active===0 && importData.oneProgress===1"></el-progress>
          <div class="notice" v-if="importData.active===0 && importData.oneProgress===0">
            <div class="title">*Excel文件请符合以下标准</div>
            <div class="item">请按照模板格式导入 <span class="downLoad"><a href="./static/template.xlsx" download="">下载导入模板</a></span></div>
            <div class="item">后缀名需为xls或者xlsx</div>
            <div class="item">数据请勿放在合并的单元格中</div>
            <div class="item">单次导入条数最大不可超过100条</div>
          </div>
        </div>
        <div class="two stepItem" v-if="importData.active===1 ">
          <div class="top paddingBox">预览数据：{{importData.fileName}}</div>
          <div class="showCon">
            <table-list :table="importTable" v-loading="importData.loading"></table-list>
          </div>
          <div class="bottom">
            <div class="show paddingBox">
              <span>信息补全：{{importData.infoComplete}}</span>
              <span class="infoIncomplete">信息不完整：{{importData.infoIncomplete}}</span>
            </div>
            <div>酒店信息填写不完整会导致部分功能无法正常使用。可选择重新编辑文件后再次导入或导入后及时在系统中补全酒店信息。</div>
          </div>
        </div>
        <div class="three stepItem" v-if="importData.active===2" v-loading="importData.loading">
          <!--<el-progress :text-inside="true" :stroke-width="20" :percentage="importData.percentage" status="exception"></el-progress>-->
          <!--<div class="dataShow">-->
          <!--<span>准备导入数据：{{importData.readyCount}}</span>-->
          <!--<span>成功：{{importData.successCount}}</span>-->
          <!--<span class="error">失败：{{importData.errorCount}}</span>-->
          <!--</div>-->
        </div>
        <div class="four stepItem" v-if="importData.active===3">
          <div class="iconBox">
            <i class="el-icon-success"></i>
          </div>
          <div class="notice">
            数据导入完成，已导入{{importData.successCount}}条数据，失败{{importData.errorCount}}条数据
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelMeth" :class="importData.active===3 ? 'none':''">取 消</el-button>
        <el-button type="primary" @click="next" v-if="importData.active>0 && importData.active<3">下一步</el-button>
        <el-button type="primary" @click="next" v-if="importData.active===3">完成</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入虚拟运营酒店" :visible.sync="virtualExport.dialog" :close-on-click-modal="false" width="60%">
      <el-steps :active="virtualData.active" align-center class="blueStep">
        <el-step title="上传Excel"></el-step>
        <el-step title="数据预览"></el-step>
        <el-step title="导入数据"></el-step>
      </el-steps>
      <div class="container">
        <div class="first stepItem" v-if="virtualData.active===0">
          <div class="uploadBox " v-if="virtualData.active===0 ">
            <!--	接口是:action="global.upload"，是一个完整的地址	  -->
            <el-upload class="upload-demo" drag accept=".xlsx" :action="global.upload" :headers="headers" :on-success="virtualSuccess" :before-upload="virtualbeforeUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </div>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning" v-if="virtualData.active===0 &&virtualData.oneProgress===1"></el-progress>
          <div class="notice" v-if="virtualData.active===0 && virtualData.oneProgress===0">
            <div class="title">*Excel文件请符合以下标准</div>
            <div class="item">请按照模板格式导入 <span class="downLoad"><a href="./static/virtualtemplate.xlsx" download="">下载导入模板</a></span></div>
            <div class="item">后缀名需为xls或者xlsx</div>
            <div class="item">数据请勿放在合并的单元格中</div>
            <div class="item">单次导入条数最大不可超过100条</div>
          </div>
        </div>
        <div class="two stepItem" v-if="virtualData.active===1 ">
          <div class="top paddingBox">预览数据：{{virtualData.fileName}}</div>
          <div class="showCon">
            <table-list :table="virtualTable" v-loading="virtualData.loading"></table-list>
          </div>
          <div class="bottom">
            <div class="show paddingBox">
              <span>信息补全：{{virtualData.infoComplete}}</span>
              <span class="infoIncomplete">信息不完整：{{virtualData.infoIncomplete}}</span>
            </div>
            <div>酒店信息填写不完整会导致部分功能无法正常使用。可选择重新编辑文件后再次导入或导入后及时在系统中补全酒店信息。</div>
          </div>
        </div>
        <div class="three stepItem" v-if="virtualData.active===2" v-loading="virtualData.loading">
          <!--<el-progress :text-inside="true" :stroke-width="20" :percentage="importData.percentage" status="exception"></el-progress>-->
          <!--<div class="dataShow">-->
          <!--<span>准备导入数据：{{importData.readyCount}}</span>-->
          <!--<span>成功：{{importData.successCount}}</span>-->
          <!--<span class="error">失败：{{importData.errorCount}}</span>-->
          <!--</div>-->
        </div>
        <div class="four stepItem" v-if="virtualData.active===3">
          <div class="iconBox">
            <i class="el-icon-success"></i>
          </div>
          <div class="notice">
            数据导入完成，已导入{{virtualData.successCount}}条数据，失败{{virtualData.errorCount}}条数据。
            <div style="margin-top: 15px" v-if="virtualData.repeat_hotel">
              重复酒店名称：{{virtualData.repeat_hotel}}
            </div>
            <div style="margin-top: 20px">
              <span class="downLoad">
                <a v-if="virtualData.repeat_hotel" :href=virtualData.file_path download="">下载重复酒店</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="virtualcancelMeth" :class="virtualData.active===3 ? 'none':''">取 消</el-button>
        <el-button type="primary" @click="virtualnext" v-if="virtualData.active>0 && virtualData.active<3">下一步</el-button>
        <el-button type="primary" @click="virtualnext" v-if="virtualData.active===3">完成</el-button>
      </div>
    </el-dialog>
    <!-- 虚拟房间 -->
    <el-dialog title="导入虚拟运营房间" :visible.sync="virtualRoomExport.dialog" :close-on-click-modal="false" width="60%">
      <el-steps :active="virtualRoomData.active" align-center class="blueStep">
        <el-step title="上传Excel"></el-step>
        <el-step title="数据预览"></el-step>
        <el-step title="导入数据"></el-step>
      </el-steps>
      <div class="container">
        <div class="first stepItem" v-if="virtualRoomData.active===0">
          <div class="uploadBox " v-if="virtualRoomData.active===0 ">
            <!--	接口是:action="global.upload"，是一个完整的地址	  -->
            <el-upload class="upload-demo" drag accept=".xlsx" :action="global.upload" :headers="headers" :on-success="virtualRoomSuccess" :before-upload="virtualRoombeforeUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </div>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning" v-if="virtualRoomData.active===0 &&virtualRoomData.oneProgress===1"></el-progress>
          <div class="notice" v-if="virtualRoomData.active===0 && virtualRoomData.oneProgress===0">
            <div class="title">*Excel文件请符合以下标准</div>
            <div class="item">请按照模板格式导入 <span class="downLoad"><a href="./static/virtualroom.xlsx" download="">下载导入模板</a></span></div>
            <div class="item">后缀名需为xls或者xlsx</div>
            <div class="item">数据请勿放在合并的单元格中</div>
            <div class="item">单次导入条数最大不可超过100条</div>
          </div>
        </div>
        <div class="two stepItem" v-if="virtualRoomData.active===1 ">
          <div class="top paddingBox">预览数据：{{virtualRoomData.fileName}}</div>
          <div class="showCon">
            <table-list :table="virtualRoomTable" v-loading="virtualRoomData.loading"></table-list>
          </div>
          <div class="bottom">
            <div class="show paddingBox">
              <span>信息补全：{{virtualRoomData.infoComplete}}</span>
              <span class="infoIncomplete">信息不完整：{{virtualRoomData.infoIncomplete}}</span>
            </div>
            <div>房间信息填写不完整会导致部分功能无法正常使用。可选择重新编辑文件后再次导入或导入后及时在系统中补全房间信息。</div>
          </div>
        </div>
        <div class="three stepItem" v-if="virtualRoomData.active===2" v-loading="virtualRoomData.loading">
          <!--<el-progress :text-inside="true" :stroke-width="20" :percentage="importData.percentage" status="exception"></el-progress>-->
          <!--<div class="dataShow">-->
          <!--<span>准备导入数据：{{importData.readyCount}}</span>-->
          <!--<span>成功：{{importData.successCount}}</span>-->
          <!--<span class="error">失败：{{importData.errorCount}}</span>-->
          <!--</div>-->
        </div>
        <div class="four stepItem" v-if="virtualRoomData.active===3">
          <div class="iconBox">
            <i class="el-icon-success"></i>
          </div>
          <div class="notice">
            数据导入完成，已导入{{virtualRoomData.successCount}}条数据，失败{{virtualRoomData.errorCount}}条数据。
            <div style="margin-top: 15px" v-if="virtualRoomData.repeat_hotel">
              重复房间名称：{{virtualRoomData.repeat_hotel}}
            </div>
            <div style="margin-top: 20px">
              <span class="downLoad">
                <a v-if="virtualRoomData.repeat_hotel" :href=virtualRoomData.file_path download="">下载重复房间</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="virtualRoomcancelMeth" :class="virtualRoomData.active===3 ? 'none':''">取 消</el-button>
        <el-button type="primary" @click="virtualRoomNext" v-if="virtualRoomData.active>0 && virtualRoomData.active<3">下一步</el-button>
        <el-button type="primary" @click="virtualRoomNext" v-if="virtualRoomData.active===3">完成</el-button>
      </div>
    </el-dialog>
    <!-- 虚拟房间 -->
    <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    <el-dialog title="修改MAC地址" :close-on-click-modal="false" class="coopDialog" :visible.sync="editMacDialog.visible" width='550px'>
      <el-form :model="editMacDialog" ref="editMacForm" :rules="editMacRules">
        <el-form-item prop="mac" label="原MAC地址">
          <el-input v-model="editMacDialog.mac"></el-input>
        </el-form-item>
        <el-form-item prop="newMac" class="noMarginBottom" label="新MAC地址">
          <el-input v-model="editMacDialog.newMac"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogCancel">取 消</el-button>
        <el-button type="primary" @click="editMacMath">确 定</el-button>
      </div>
    </el-dialog>
    <hotelAllContent ref="hotelAllContent" :query="query"></hotelAllContent>

  </div>
</template>
<script>
import hotelAllContent from "./hotelAllContent";
import obj from "@/api/public.js";
import qs from "querystring";
import GLOBAL from "../../../api/globalVar.js";
import commonMeth from "../../../api/method";
import exportBootTime from "../exportBootTime";
export default {
  name: "hotelManAll",
  props: ["pageInfo"],
  components: {
    exportBootTime,
    hotelAllContent,
  },
  data: () => ({
    apaasData: null,
    showline: false,
    accountType: "",
    TypeSearch: true,
    getIndexHeader: {
      partnerType: "",
      accountId: "",
    },
    type: "timeCenter",
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/hotelManAll" },
      fourthPath: { path: "/hotelManAll" },
    },
    topAttr: {
      terminal: "",
      terminalOption: [
        { label: "country1", value: "1" },
        { label: "country2", value: "2" },
        { label: "country3", value: "3" },
      ],
    },
    table: {
      tableHeader: [
        { prop: "hotel", label: "酒店名称", width: "350" },
        { prop: "hotel_brand", label: "集团品牌", width: "" },
        { prop: "hotel_use_state", label: "客户终端状态", width: "" },
        { prop: "h_province", label: "省份", width: "110" },
        { prop: "h_city", label: "城市", width: "110" },
        { prop: "h_county", label: "区县", width: "110" },
        { prop: "hotel_room", label: "客房总量（间）", width: "" },
        { prop: "n_connected", label: "激活终端数量（台）", width: "" },
        { prop: "addtime", label: "上线时间", width: "180" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "hotelManAll",
      name: "hotelManAll",
      otherFromUrl: "hotelManAll",
      option: true,
      select: true,
      loading: true,
      specialCell: {
        BD: true,
        build_person: true,
        jumpBtn: true,
        typeshow: true,
        dar: false,
        darText: "日活率（%）",
      },
    },
    form: {
      name: "",
      region: "",
      date1: "",
      date2: "",
      delivery: false,
      type: [],
      resource: "",
      desc: "",
    },
    formLabelWidth: "120px",
    dialogExport: {
      dialog: false,
    },
    virtualExport: {
      dialog: false,
    },
    virtualRoomExport: {
      dialog: false,
    },
    importData: {
      active: 0,
      oneProgress: 0,
      percentage: 40,
      fileName: "",
      infoComplete: "",
      infoIncomplete: "",
      readyCount: "",
      successCount: "",
      errorCount: "",
      file: "",
      filePath: "",
      loading: true,
    },
    virtualData: {
      active: 0,
      oneProgress: 0,
      percentage: 40,
      fileName: "",
      infoComplete: "",
      infoIncomplete: "",
      readyCount: "",
      successCount: "",
      errorCount: "",
      file: "",
      filePath: "",
      loading: true,
      repeat_hotel: "",
      file_path: "",
    },
    virtualRoomData: {
      active: 0,
      oneProgress: 0,
      percentage: 40,
      fileName: "",
      infoComplete: "",
      infoIncomplete: "",
      readyCount: "",
      successCount: "",
      errorCount: "",
      file: "",
      filePath: "",
      loading: true,
      repeat_hotel: "",
      file_path: "",
    },
    topSearchShow: {},
    condition: {
      startTime: "",
      endTime: "",
      hotelCreateTimeStart: "",
      hotelCreateTimeEnd: "",
      screenType: "self",
      screenTypeUndefined: "",
      acceptanceType: "",
    },
    token: "",
    auth_type: "",
    today: "",
    importTable: {
      //没有区
      tableHeader: [
        { prop: "province", label: "省份", width: "80" },
        { prop: "city", label: "城市", width: "80" },
        { prop: "county", label: "区县", width: "80" },
        { prop: "lng", label: "经度", width: "" },
        { prop: "lat", label: "纬度", width: "" },
        { prop: "original_name", label: "酒店名称", width: "" },
        { prop: "original_address", label: "酒店地址", width: "" },
        // {prop:'contact_name',label:'酒店联系人',width:''},
        // {prop:'contact_phone',label:'联系人电话',width:''},
        { prop: "room_num", label: "房间数量（间）", width: "" },
        { prop: "box_num", label: "终端数量（台）", width: "" },
        { prop: "ota_price", label: "ota价格（元）", width: "" },
        { prop: "ota_hotel_level", label: "酒店标签", width: "" },
      ],
      tableList: [
        {
          hotel: 11111,
          hotel_brand: "11111",
          hotel_use_state: "11111",
          h_province: "11111",
        },
        {
          hotel: 22222,
          hotel_brand: "22222",
          hotel_use_state: "22222",
          h_province: "22222",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
      ],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "hotelManAssign",
      pageShow: false,
    },
    virtualTable: {
      //没有区
      tableHeader: [
        { prop: "groupId", label: "品牌ID", width: "" },
        { prop: "hotel", label: "酒店名称", width: "" },
        { prop: "hotel_store_id", label: "集团门店ID", width: "" },
        { prop: "demand", label: "业务类型", width: "" },
      ],
      tableList: [
        {
          hotel: 11111,
          hotel_brand: "11111",
          hotel_use_state: "11111",
          h_province: "11111",
        },
        {
          hotel: 22222,
          hotel_brand: "22222",
          hotel_use_state: "22222",
          h_province: "22222",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
      ],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "hotelManAssign",
      pageShow: false,
    },
    virtualRoomTable: {
      //没有区
      tableHeader: [
        { prop: "hotel_contract", label: "酒店合同号", width: "" },
        { prop: "room_number", label: "房间号", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "hotelManAssign",
      pageShow: false,
    },
    headers: {
      token: "",
      Authorization: "",
    },
    cache_key: "",
    auth: {},
    showData: {
      hotelNum: 0,
      roomNum: 0,
      activeTerminalNum: 0,
      dayActiveRate: 0,
    },
    showLoading: true,
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    belongPartnerCodeAllStr: "",
    allotPartnerCodeAllStr: "",
    funArr: [],
    allData: {},
    topSearchSave: {
      // hotel_brand:['2','3','1'],
      // BD:[189,187],
      // province:[
      //     // {label:'内蒙古自治区',value:'15'},
      //     {label:'河北省',value:'13'},
      // ],
      // city: {label: "保定市",value: "1306"},
      // country: {label: "满城区",value: "130607"},
      // keyword:'测试09-15',
      // hotel_use_state_tem:[
      //     {name:'待上线',value:6}
      // ],
      // coopType:'screen',
      // partner_id:["f6c142ebfe9b438fa442d44f25238b93", "HCC000106"],
      // coopPartnerType:'belongPartnerCode',
    },
    quickDateCur: "",
    curDate: {
      yesDay: "",
      curDay: "",
      startWeek: "",
      endWeek: "",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    },
    operateIds: "",
    hotelContract: "",
    lineLoading: false,
    lineChart: {
      id: "lineChart",
      showTitle: true,
      flag: false,
      noData: false,
      fromUrl: "hotelMan",
      lineLoading: false,
      option: {
        legend: [],
        xData: [],
        series: [],
      },
    },
    showPart: {},
    allLoadingState: false,
    editCodeForm: {
      event: "",
      mobile: "",
      code: "",
    },
    editCodeRules: {
      event: [
        { required: true, message: "请选择修改事件类型", trigger: "blur" },
      ],
      mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
    },
    selectData: {
      editCodeEvent: [
        { value: "login", name: "登录" },
        { value: "register", name: "注册" },
      ],
    },
    editMacDialog: {
      visible: false,
      mac: "",
      newMac: "",
    },
    editMacRules: {
      mac: [{ required: true, message: "请输入原mac地址", trigger: "blur" }],
      newMac: [{ required: true, message: "请输入新mac地址", trigger: "blur" }],
    },
    behaviorExportShow: false,
    query: {},
  }),
  methods: {
    showUploadDialog(val) {
      this.query = val;
      this.$refs.hotelAllContent.contentShow = true;
      this.$refs.hotelAllContent.type = "detail";
      this.query.hotelState = this.pageInfo.hotelState;
      this.$refs.hotelAllContent.getHotalContentList(this.query.hotel_contract);

      if (this.query.id !== "") {
        this.$http
          .get(this.global.view, {
            params: {
              token: this.token,
              id: this.query.id,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.query.hotel_use_state = res.data.data.hotel_use_state;
              console.log(this.query, " this.query this.query this.query");
              this.$refs.hotelAllContent.getHotelDetail(res, this.query);
            }
          });
      } else {
        this.$refs.hotelAllContent.getHotelDetail(this.query);
      }
    },
    SetConditionScreentype() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
        switch (this.accountType) {
          case 1:
            this.condition.screenType = "self";
            break;
          case 2:
            this.condition.screenType = "self";
            break;
          case 3:
            this.condition.screenType = "";
            break;
          case 4:
            this.condition.screenType = "";
            break;
          case 5:
            this.condition.screenType = "belong";
            break;
          case 6:
            this.condition.screenType = "belong";
        }
      });
    },
    showTableLoading() {
      this.table.loading = true;
    },
    tableMeth(orderVal, type) {
      this.table.loading = true;
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      var params = {
        token: this.token,
        page: this.table.page,
        per_page: this.table.limit,
        hotel_brand: this.condition.hotel_brand,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        check_state: this.condition.acceptanceType,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        keyword: this.condition.keyword,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        isDeleted: this.condition.isDeleted,
        sort: sortStr,
        order: orderStr,
        screenType: this.condition.screenType,
        refresh: false,
      };
      //partner_id传进对应PartnerCode里，两者不会同时存在
      if (params.belongPartnerCode == "" && params.allotPartnerCode == "") {
      } else if (params.belongPartnerCode !== "") {
        params.partner_id = params.belongPartnerCode;
      } else if (params.allotPartnerCode !== "") {
        params.partner_id = params.allotPartnerCode;
      }
      //新建酒店传CreateTime,其他模块不传
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              this.condition.hotelCreateTimeStart = params.startTime;
              this.condition.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
          this.condition.hotelCreateTimeStart = this.today;
          this.condition.hotelCreateTimeEnd = this.today;
        }
      } else {
        params.hotelCreateTimeStart = "";
        params.hotelCreateTimeEnd = "";
      }
      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          if (type === "mounted") {
            this.table.page = sessionStorage.getItem("filterPage") * 1;
          }
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = parseInt(tableData.total);
          } else {
            // this.$message.error(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch((res) => {
          this.table.loading = false;
        });
    },
    tableMethfrist(orderVal, type) {
      this.table.loading = true;
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      var params = {
        token: this.token,
        page: this.table.page,
        per_page: this.table.limit,
        hotel_brand: this.condition.hotel_brand,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        check_state: this.condition.acceptanceType,
        keyword: this.condition.keyword,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        isDeleted: this.condition.isDeleted,
        sort: sortStr,
        order: orderStr,
        screenType: "",
        refresh: true,
      };
      //新建酒店传CreateTime,其他模块不传
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              this.condition.hotelCreateTimeStart = params.startTime;
              this.condition.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
          this.condition.hotelCreateTimeStart = this.today;
          this.condition.hotelCreateTimeEnd = this.today;
        }
      } else {
        params.hotelCreateTimeStart = "";
        params.hotelCreateTimeEnd = "";
      }
      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          if (type === "mounted") {
            this.table.page = sessionStorage.getItem("filterPage") * 1;
          }
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = parseInt(tableData.total);
          } else {
            // this.$message.error(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch((res) => {
          this.table.loading = false;
        });
    },
    getNewPage(newPage) {
      this.table.page = newPage;
      this.table.loading = true;
      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
      this.table.loading = true;
      this.tableMeth();
    },
    newBuild() {
      this.$router.push("/hotelManNew");
    },
    next() {
      if (this.importData.active++ > 2) {
        this.dialogExport.dialog = false;
        this.importData.active = 0;
      }
      if (this.importData.active === 1) {
        this.previewMeth();
      } else if (this.importData.active === 2) {
        this.confirmMeth();
      } else if (this.importData.active === 3) {
        this.dialogExport.dialog = false;
      }
    },

    virtualnext() {
      if (this.virtualData.active++ > 2) {
        this.virtualExport.dialog = false;
        this.virtualData.active = 0;
      }
      if (this.virtualData.active === 1) {
        this.virtualpreviewMeth();
      } else if (this.virtualData.active === 2) {
        this.virtualconfirmMeth();
      } else if (this.virtualData.active === 3) {
        this.virtualExport.dialog = false;
      }
    },
    // 虚拟房间流程1
    virtualRoomNext() {
      if (this.virtualRoomData.active++ > 2) {
        this.virtualRoomExport.dialog = false;
        this.virtualRoomData.active = 0;
      }
      if (this.virtualRoomData.active === 1) {
        this.virtualRoompreviewMeth();
      } else if (this.virtualRoomData.active === 2) {
        this.virtualRoomconfirmMeth();
      } else if (this.virtualRoomData.active === 3) {
        this.virtualRoomExport.dialog = false;
      }
    },
    previewMeth() {
      var param = qs.stringify({
        token: this.token,
        file_path: this.importData.file,
      });
      this.$http
        .post(this.global.preview, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.importTable.tableList = tableData.items;
            this.importData.infoComplete = tableData.success_count;
            this.importData.infoIncomplete = tableData.error_count;
            this.importData.loading = false;
            this.cache_key = tableData.cache_key;
          } else {
            this.importData.loading = false;
            this.$message.error("预览失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    //返回第二步数据的接口
    virtualpreviewMeth() {
      var param = qs.stringify({
        token: this.token,
        file_path: this.virtualData.file,
      });
      this.$http
        .post(this.global.inventedPreview, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.virtualTable.tableList = tableData.items;
            this.virtualData.infoComplete = tableData.success_count;
            this.virtualData.infoIncomplete = tableData.error_count;
            this.virtualData.loading = false;
            this.cache_key = tableData.cache_key;
          } else {
            this.virtualData.loading = false;
            this.$message.error("预览失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    //虚拟房间返回第二步数据的接口
    virtualRoompreviewMeth() {
      var param = qs.stringify({
        token: this.token,
        file_path: this.virtualRoomData.file,
      });
      this.$http
        .post(this.global.inventedRoomPreview, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.virtualRoomTable.tableList = tableData.items;
            this.virtualRoomData.infoComplete = tableData.success_count;
            this.virtualRoomData.infoIncomplete = tableData.error_count;
            this.virtualRoomData.loading = false;
            this.cache_key = tableData.cache_room_key;
          } else {
            this.virtualRoomData.loading = false;
            this.$message.error("预览失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    confirmMeth() {
      this.importData.loading = true;
      var param = qs.stringify({
        token: this.token,
        file_path: this.importData.file,
        cache_key: this.cache_key,
      });
      this.$http
        .post(this.global.importAction, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.importData.successCount = tableData.hotel_success_count;
            this.importData.errorCount = tableData.hotel_error_count;
            this.importData.loading = false;
            this.importData.active = 3;
            if (tableData.error_hotel_reason.length == 0) {
              if (tableData.error_room_reason.length == 0) {
                return;
              } else {
                obj.screenTableRoomToExcel(
                  tableData.error_room_reason,
                  "导入酒店房间失败及原因"
                );
              }
            } else {
              obj.screenTableToExcel(
                tableData.error_hotel_reason,
                "导入酒店失败及原因"
              );
            }

            // setTimeout(()=>{
            //     this.importData.active=3;
            // },300)
          } else {
            this.$message.error("导入失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    virtualconfirmMeth() {
      this.virtualData.loading = true;
      var param = qs.stringify({
        token: this.token,
        // file_path: this.virtualData.file,
        cache_key: this.cache_key,
      });
      this.$http
        .post(this.global.importVirtualHotel, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.virtualData.successCount = tableData.success_count;
            this.virtualData.errorCount = tableData.error_count;
            this.virtualData.repeat_hotel = tableData.repeat_hotel.join();
            this.virtualData.loading = false;
            this.virtualData.active = 3;
            this.virtualData.file_path =
              this.GLOBAL.BASE_URL + tableData.file_path;
            if (this.virtualData.errorCount != 0) {
              var a = document.createElement("a");
              a.download = "导入失败酒店列表";
              a.href = this.virtualData.file_path;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            //   window.open(this.virtualData.file_path);
          } else {
            this.$message.error("导入失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    // 虚拟房间流程3
    virtualRoomconfirmMeth() {
      this.virtualRoomData.loading = true;
      var param = qs.stringify({
        token: this.token,
        // file_path: this.virtualRoomData.file,
        cache_room_key: this.cache_key,
      });
      this.$http
        .post(this.global.importVirtualRoom, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.virtualRoomData.successCount = tableData.success_count;
            this.virtualRoomData.errorCount = tableData.error_count;
            this.virtualRoomData.loading = false;
            this.virtualRoomData.active = 3;
            console.log(tableData.file_path);
            if (this.virtualRoomData.errorCount != 0) {
              this.virtualRoomData.file_path =
                this.GLOBAL.BASE_URL + tableData.file_path;
              var a = document.createElement("a");
              a.download = "导入失败酒店列表";
              a.href = this.virtualRoomData.file_path;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          } else {
            this.$message.error("导入失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    getToday() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.today = year + "-" + month + "-" + day;
    },
    getTopSearchShow(type) {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      this.auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          country: true,
          keyword: true,
          coopType: true,
          coopPartnerType: true,
          acceptanceType: true,
        };
      } else if (auth_type === "group") {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          country: true,
          keyword: true,
          // coopPartnerType:true,
          acceptanceType: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          country: true,
          // coopType: true,
          coopPartner: true,
          BD: true,
          keyword: true,
          // coopPartnerType: true,
          acceptanceType: true,
        };
      }
      this.pageInfo.hotelState === "all"
        ? (this.topSearchShow.hotel_use_state = true)
        : "";
      this.pageInfo.hotelState !== "newList"
        ? (this.topSearchShow.date = true)
        : "";
      this.pageInfo.hotelState == "newList"
        ? (this.topSearchShow.date = true)
        : "";
      this.pageInfo.hotelState == "newList"
        ? (this.topSearchShow.acceptanceType = false)
        : "";
      this.pageInfo.hotelState == "implementation"
        ? (this.topSearchShow.acceptanceType = false)
        : "";
      this.topSearchShow.keywordPlaceholder = "请输入酒店名称关键词";
    },
    boottime() {
      var params = {
        token: this.token,
        hotel_brand: this.condition.hotel_brand,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        keyword: this.condition.keyword,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        check_state: this.condition.acceptanceType,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        isDeleted: this.condition.isDeleted,
      };
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
        });
        this.$http.post(this.global.getIndexHeader, param).then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.getIndexHeader.partnerType = res.data.data.partnerType;
            this.getIndexHeader.accountId = res.data.data.accountId;
          } else {
          }
          if (
            this.getIndexHeader.partnerType !== "携旅" &&
            this.getIndexHeader.accountId == "HCC000262"
          ) {
            this.behaviorExportShow = true;
          } else {
            this.behaviorExportShow = false;
          }
        });
      });
    },
    getScreenTypeSearchShow() {
      switch (this.pageInfo.hotelState) {
        case "newList":
        case "del":
          this.showPart = {
            date: true,
            self: true,
            other: true,
            videoRoom: true,
          };
          break;
        case "all":
          this.showPart = {
            self: true,
            other: true,
            videoRoom: true,
          };
          break;
        case "using":
        case "operate":
          this.showPart = {
            date: false,
            self: true,
            other: true,
            videoRoom: true,
          };
          break;
      }
    },
    getCondition(val, filterPage, filterLimit, type) {
      switch (this.pageInfo.hotelState) {
        case "implementation":
          this.condition.hotel_use_state = "5";
          break;
        case "using":
          this.condition.hotel_use_state = "7"; //hotelManAllOperate
          break;
        case "offline":
          this.condition.hotel_use_state = "11";
          break;
        case "all":
          var hotel_use_state = val.hotel_use_state;
          if (Array.isArray(hotel_use_state)) {
            this.condition.hotel_use_state = val.hotel_use_state.join(",");
          } else if (hotel_use_state === "10") {
            this.condition.hotel_use_state = val.hotel_use_state;
          } else {
            this.condition.hotel_use_state = "";
          }
          break;
        case "del":
          this.condition.isDeleted = 1;
          break;
      }
      var hotel_use_state_label = this.condition.hotel_use_state_label;
      this.condition.hotel_brand = Array.isArray(val.hotel_brand)
        ? val.hotel_brand.join(",")
        : ""; //ka
      this.condition.hotel_use_state_label = Array.isArray(
        val.hotel_use_state_label
      )
        ? val.hotel_use_state_label.join(",")
        : ""; //ka
      this.condition.hotel_use_state_label =
        "（" + this.condition.hotel_use_state_label.replace(/,/g, "，") + "）";
      if (this.condition.hotel_use_state_label === "（）") {
        this.condition.hotel_use_state_label = "（全部状态）";
      }
      this.condition.BD = Array.isArray(val.BD) ? val.BD.join(",") : "";
      this.condition.partner_id = Array.isArray(val.partner_id)
        ? val.partner_id.join(",")
        : ""; //ka
      this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode)
        ? val.belongPartnerCode.join(",")
        : ""; //ka
      this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode)
        ? val.allotPartnerCode.join(",")
        : ""; //ka
      if (typeof val.citycode === "string") {
        this.condition.citycode = val.citycode;
      } else {
        this.condition.citycode = Array.isArray(val.citycode)
          ? val.citycode.join(",")
          : ""; //citycode
      }
      this.condition.provinceLabel = Array.isArray(val.provinceLabel)
        ? val.provinceLabel.join(",")
        : ""; //provinceLabel
      this.condition.cityLabel = val.cityLabel;
      (this.condition.acceptanceType = val.acceptanceType),
        (this.condition.countyLabel = val.countyLabel);
      this.condition.startTime = val.startTime;
      this.condition.endTime = val.endTime;
      if (this.pageInfo.hotelState === "newList") {
        this.condition.hotelCreateTimeStart =
          !val.hotelCreateTimeStart || val.hotelCreateTimeStart === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeStart;
        this.condition.hotelCreateTimeEnd =
          !val.hotelCreateTimeEnd || val.hotelCreateTimeEnd === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeEnd;
      }
      if (this.pageInfo.hotelState === "newList") {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.hotelCreateTimeStart,
          this.condition.hotelCreateTimeEnd
        );
      } else {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.startTime,
          this.condition.endTime
        );
      }
      this.condition.keyword = val.keyword;
      this.getFilterTableCon(filterPage, filterLimit, type);
      this.tableMeth("", type);
      this.getLineChart();
      this.showDataMeth();
    },
    getConditionfrist(val, filterPage, filterLimit, type) {
      switch (this.pageInfo.hotelState) {
        case "implementation":
          this.condition.hotel_use_state = "5";
          break;
        case "using":
          this.condition.hotel_use_state = "7"; //hotelManAllOperate
          break;
        case "offline":
          this.condition.hotel_use_state = "11";
          break;
        case "all":
          var hotel_use_state = val.hotel_use_state;
          if (Array.isArray(hotel_use_state)) {
            this.condition.hotel_use_state = val.hotel_use_state.join(",");
          } else if (hotel_use_state === "10") {
            this.condition.hotel_use_state = val.hotel_use_state;
          } else {
            this.condition.hotel_use_state = "";
          }
          break;
        case "del":
          this.condition.isDeleted = 1;
          break;
      }
      var hotel_use_state_label = this.condition.hotel_use_state_label;
      this.condition.hotel_brand = Array.isArray(val.hotel_brand)
        ? val.hotel_brand.join(",")
        : ""; //ka
      this.condition.hotel_use_state_label = Array.isArray(
        val.hotel_use_state_label
      )
        ? val.hotel_use_state_label.join(",")
        : ""; //ka
      this.condition.hotel_use_state_label =
        "（" + this.condition.hotel_use_state_label.replace(/,/g, "，") + "）";
      if (this.condition.hotel_use_state_label === "（）") {
        this.condition.hotel_use_state_label = "（全部状态）";
      }
      this.condition.BD = Array.isArray(val.BD) ? val.BD.join(",") : "";
      this.condition.partner_id = Array.isArray(val.partner_id)
        ? val.partner_id.join(",")
        : ""; //ka
      this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode)
        ? val.belongPartnerCode.join(",")
        : ""; //ka
      this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode)
        ? val.allotPartnerCode.join(",")
        : ""; //ka
      if (typeof val.citycode === "string") {
        this.condition.citycode = val.citycode;
      } else {
        this.condition.citycode = Array.isArray(val.citycode)
          ? val.citycode.join(",")
          : ""; //citycode
      }
      this.condition.provinceLabel = Array.isArray(val.provinceLabel)
        ? val.provinceLabel.join(",")
        : ""; //provinceLabel
      this.condition.cityLabel = val.cityLabel;
      this.condition.countyLabel = val.countyLabel;
      this.condition.startTime = val.startTime;
      (this.condition.acceptanceType = val.acceptanceType),
        (this.condition.endTime = val.endTime);
      if (this.pageInfo.hotelState === "newList") {
        this.condition.hotelCreateTimeStart =
          !val.hotelCreateTimeStart || val.hotelCreateTimeStart === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeStart;
        this.condition.hotelCreateTimeEnd =
          !val.hotelCreateTimeEnd || val.hotelCreateTimeEnd === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeEnd;
      }
      if (this.pageInfo.hotelState === "newList") {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.hotelCreateTimeStart,
          this.condition.hotelCreateTimeEnd
        );
      } else {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.startTime,
          this.condition.endTime
        );
      }
      this.condition.keyword = val.keyword;
      this.getFilterTableCon(filterPage, filterLimit, type);
      this.tableMethfrist("", type);
      this.getLineChartfrist();
      this.showDataMethfrist();
    },
    screenTypeSearch(screenType, startTime, endTime) {
      this.condition.screenType = screenType;
      if (this.pageInfo.hotelState === "newList") {
        if (this.showPart.date) {
          if (startTime !== "") {
            this.condition.startTime = startTime;
            this.condition.endTime = endTime;
          }
        }
      } else {
        if (this.showPart.date) {
          this.condition.startTime = startTime;
          this.condition.endTime = endTime;
          this.condition.hotelCreateTimeStart = startTime;
          this.condition.hotelCreateTimeEnd = endTime;
        }
      }
      this.table.page = 1;
      this.table.per_page = 5;
      this.tableMeth();
      this.getLineChart();
      this.showDataMeth();
    },
    beforeUpload(val) {
      this.importData.file = val;
      this.importData.fileName = val.name;
    },
    virtualbeforeUpload(val) {
      this.virtualData.file = val;
      this.virtualData.fileName = val.name;
    },
    // 虚拟房间 上传
    virtualRoombeforeUpload(val) {
      this.virtualRoomData.file = val;
      this.virtualRoomData.fileName = val.name;
    },
    uploadSuccess(response, file, fileList) {
      this.importData.file = response.data.url;
      this.importData.file = response.data.url;
      this.importData.active = 1;
      this.previewMeth();
    },
    virtualSuccess(response, file, fileList) {
      this.virtualData.file = response.data.url;
      this.virtualData.file = response.data.url;
      this.virtualData.active = 1;
      this.virtualpreviewMeth();
    },
    // 虚拟房间上传 成功
    virtualRoomSuccess(response, file, fileList) {
      this.virtualRoomData.file = response.data.url;
      this.virtualRoomData.file = response.data.url;
      this.virtualRoomData.active = 1;
      this.virtualRoompreviewMeth();
    },
    cancelMeth() {
      this.importData.active = 0;
      this.importData.fileName = "";
      this.importData.infoComplete = "";
      this.importData.infoIncomplete = "";
      this.importData.readyCount = "";
      this.importData.successCount = "";
      this.importData.errorCount = "";
      this.importData.file = "";
      this.importData.filePath = "";
      this.dialogExport.dialog = false;
    },
    virtualcancelMeth() {
      this.virtualData.active = 0;
      this.virtualData.fileName = "";
      this.virtualData.infoComplete = "";
      this.virtualData.infoIncomplete = "";
      this.virtualData.readyCount = "";
      this.virtualData.successCount = "";
      this.virtualData.errorCount = "";
      this.virtualData.file = "";
      this.virtualData.filePath = "";
      this.virtualExport.dialog = false;
    },
    // 虚拟房间 清空
    virtualRoomcancelMeth() {
      this.virtualRoomData.active = 0;
      this.virtualRoomData.fileName = "";
      this.virtualRoomData.infoComplete = "";
      this.virtualRoomData.infoIncomplete = "";
      this.virtualRoomData.readyCount = "";
      this.virtualRoomData.successCount = "";
      this.virtualRoomData.errorCount = "";
      this.virtualRoomData.file = "";
      this.virtualRoomData.filePath = "";
      this.virtualRoomExport.dialog = false;
    },
    //数据统计
    showDataMeth() {
      return new Promise((resolve, reject) => {
        this.showLoading = true;
        var params = {
          token: this.token,
          hotel_brand: this.condition.hotel_brand,
          hotelBrand: this.condition.hotel_brand,
          citycode: this.condition.citycode,
          province: this.condition.provinceLabel,
          city: this.condition.cityLabel,
          county: this.condition.countyLabel,
          hotel_use_state: this.condition.hotel_use_state,
          accountManagerId: this.condition.BD,
          partner_id: this.condition.partner_id,
          keyword: this.condition.keyword,
          check_state: this.condition.acceptanceType,
          startTime: this.condition.startTime,
          endTime: this.condition.endTime,
          hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
          belongPartnerCode: this.condition.belongPartnerCode,
          allotPartnerCode: this.condition.allotPartnerCode,
          isDeleted: this.condition.isDeleted,
          screenType: this.condition.screenType,
          refresh: false,
        };
        //partner_id传进对应PartnerCode里，两者不会同时存在
        if (params.belongPartnerCode == "" && params.allotPartnerCode == "") {
        } else if (params.belongPartnerCode !== "") {
          params.partner_id = params.belongPartnerCode;
        } else if (params.allotPartnerCode !== "") {
          params.partner_id = params.allotPartnerCode;
        }
        //新建酒店传CreateTime,其他模块不传
        if (this.pageInfo.hotelState === "newList") {
          if (params.hotelCreateTimeStart != "") {
            //两个时间都有
            if (params.startTime !== "") {
              if (typeof params.startTime == "undefined") {
              } else {
                params.hotelCreateTimeStart = params.startTime;
                params.hotelCreateTimeEnd = params.endTime;
                params.startTime = "";
                params.endTime = "";
              }
            }
          } else {
            params.hotelCreateTimeStart = this.today;
            params.hotelCreateTimeEnd = this.today;
          }
        } else {
          params.hotelCreateTimeStart = "";
          params.hotelCreateTimeEnd = "";
        }
        this.$http
          .post(this.global.getAllHotelDataStatistics, qs.stringify(params))
          .then((res) => {
            var code = res.data.code;
            if (code === 100000) {
              this.showData = res.data.data;
              this.showLoading = true;
            }
            this.showLoading = false;
            resolve();
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    showDataMethfrist() {
      return new Promise((resolve, reject) => {
        this.showLoading = true;
        var params = {
          token: this.token,
          hotel_brand: this.condition.hotel_brand,
          hotelBrand: this.condition.hotel_brand,
          citycode: this.condition.citycode,
          province: this.condition.provinceLabel,
          city: this.condition.cityLabel,
          county: this.condition.countyLabel,
          hotel_use_state: this.condition.hotel_use_state,
          accountManagerId: this.condition.BD,
          check_state: this.condition.acceptanceType,
          partner_id: this.condition.partner_id,
          keyword: this.condition.keyword,
          startTime: this.condition.startTime,
          endTime: this.condition.endTime,
          hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
          belongPartnerCode: this.condition.belongPartnerCode,
          allotPartnerCode: this.condition.allotPartnerCode,
          isDeleted: this.condition.isDeleted,
          screenType: "",
          refresh: true,
          accountType: this.accountType,
        };
        //新建酒店传CreateTime,其他模块不传
        if (this.pageInfo.hotelState === "newList") {
          if (params.hotelCreateTimeStart != "") {
            //两个时间都有
            if (params.startTime !== "") {
              if (typeof params.startTime == "undefined") {
              } else {
                params.hotelCreateTimeStart = params.startTime;
                params.hotelCreateTimeEnd = params.endTime;
                params.startTime = "";
                params.endTime = "";
              }
            }
          } else {
            params.hotelCreateTimeStart = this.today;
            params.hotelCreateTimeEnd = this.today;
          }
        } else {
          params.hotelCreateTimeStart = "";
          params.hotelCreateTimeEnd = "";
        }
        this.$http
          .post(this.global.getAllHotelDataStatistics, qs.stringify(params))
          .then((res) => {
            var code = res.data.code;
            if (code === 100000) {
              this.showData = res.data.data;
              this.showLoading = true;
            }
            this.showLoading = false;
            resolve();
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    getFilterTableCon(filterPage, filterLimit, type) {
      if (type === "mounted") {
        !filterPage || filterPage === ""
          ? (this.table.page = 1)
          : (this.table.page = filterPage);

        !filterLimit || filterLimit === ""
          ? (this.table.limit = 5)
          : (this.table.limit = filterLimit);
      } else {
        this.table.page = 1;
        this.table.limit = 5;
        sessionStorage.setItem("filterPage", "1");
        sessionStorage.setItem("filterLimit", "5");
      }
    },
    getFilterCon() {
      var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
      this.topSearchSave = filterCon;
      if (filterCon.screenType) {
        this.condition.screenType = filterCon.screenType.join(",");
      }
      var filterPage = sessionStorage.getItem("filterPage");
      var filterLimit = sessionStorage.getItem("filterLimit");
      this.getCondition(filterCon, filterPage * 1, filterLimit * 1, "mounted");
    },
    getFilterConfrist() {
      var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
      this.topSearchSave = filterCon;
      if (filterCon.screenType) {
        this.condition.screenType = filterCon.screenType.join(",");
      }
      var filterPage = sessionStorage.getItem("filterPage");
      var filterLimit = sessionStorage.getItem("filterLimit");
      this.getConditionfrist(
        filterCon,
        filterPage * 1,
        filterLimit * 1,
        "mounted"
      );
    },
    getTableSelect(val) {
      var operateIds = "";
      var partnerCode = "";
      var hotelContract = "";
      val.forEach((item, index) => {
        operateIds += item.id + ",";
        partnerCode += item.code + ",";
        hotelContract += item.hotel_contract + ",";
      });
      this.operateIds = operateIds.slice(0, -1);
      this.hotelContract = hotelContract.slice(0, -1);
    },
    operateConfirmMeth(type, str) {
      if (this.operateIds !== "") {
        if (type === "editMac") {
          this.editMacDialog.visible = true;
        } else {
          this.$confirm("确定" + str + "？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.operateMeth(type, str);
            })
            .catch(() => {});
        }
      } else {
        this.$message({
          message: "请选择酒店",
          type: "warning",
        });
      }
    },
    delMeth() {
      var param = qs.stringify({
        token: this.token,
        id: this.delIds,
      });
      this.$http.post(this.global.hotelDestroy, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message.success("删除成功");
          this.tableMeth();
        } else {
          this.$message.success("删除失败");
        }
      });
    },
    operateMeth(type, str) {
      var param = "";
      var url = "";
      param = qs.stringify({
        token: this.token,
        id: this.operateIds,
        hotelContract: this.hotelContract,
      });
      if (type === "del") {
        url = this.global.hotelDestroy;
      } else if (type === "recover") {
        url = this.global.hotelRecover;
      } else if (type === "adv") {
        url = this.global.putOffLineAdvByHotel;
      }
      this.$http.post(url, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message.success(`${str}成功`);
          this.tableMeth();
          this.getLineChart();
          this.showDataMeth();
        } else {
          this.$message.error(`${str}失败`);
        }
      });
    },
    editMacMath() {
      this.$refs["editMacForm"].validate((valid) => {
        if (valid) {
          var param = qs.stringify({
            token: this.token,
            hotelId: this.operateIds,
            mac: this.editMacDialog.mac,
            newMac: this.editMacDialog.newMac,
          });
          this.$http
            .post(this.global.editMacByHotelIdAndMac, param)
            .then((res) => {
              if (res.data.code === 100000) {
                this.$message.success(`修改成功`);
                this.editDialogCancel();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
    editDialogCancel() {
      this.editMacDialog.visible = false;
      this.editMacDialog.mac = "";
      this.editMacDialog.newMac = "";
    },
    setDefaultQuickDateCur() {
      this.quickDateCur = "";
    },
    getLineChart() {
      this.lineChart.flag = false;
      this.lineLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        check_state: this.condition.acceptanceType,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        keyword: this.condition.keyword,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        refresh: false,
      };
      //partner_id传进对应PartnerCode里，两者不会同时存在
      if (params.belongPartnerCode == "" && params.allotPartnerCode == "") {
      } else if (params.belongPartnerCode !== "") {
        params.partner_id = params.belongPartnerCode;
      } else if (params.allotPartnerCode !== "") {
        params.partner_id = params.allotPartnerCode;
      }
      params.screenType = this.condition.screenType;
      //新建酒店传CreateTime,其他模块不传
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
        }
      } else {
        params.hotelCreateTimeStart = "";
        params.hotelCreateTimeEnd = "";
      }
      this.$http
        .post(this.global.getHotelLineLegend, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var chartData = res.data.data;
            if (chartData.series) {
              this.lineChart.option.legend = chartData.legend;
              this.lineChart.option.xData = chartData.xAxis;
              var dataTransEd = commonMeth.dataTrans(chartData);
              var arr = [];
              var index = -1;
              for (let key in dataTransEd.series) {
                index++;
                var obj = {
                  name: `${key}`,
                  type: "line",
                  smooth: true,
                  symbolSize: this.global.symbolSize, //拐点圆的大小
                  itemStyle: {
                    normal: {
                      color: GLOBAL.chartColor[index], //改变折线点的颜色
                      lineStyle: {
                        color: GLOBAL.chartColor[index], //改变折线颜色
                      },
                    },
                  },
                  data: dataTransEd.series[key],
                };
                arr.push(obj);
              }
              this.lineChart.option.series = arr;
            } else {
              this.lineChart.noData = true;
            }
            this.lineChart.flag = true;
            this.lineLoading = false;
          } else if (code === 100001) {
            this.$message.error("折线图获取失败");
          }
          this.lineLoading = false;
        });
    },

    getLineChartfrist() {
      this.lineChart.flag = false;
      this.lineLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        check_state: this.condition.acceptanceType,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        keyword: this.condition.keyword,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        refresh: true,
        accountType: this.accountType,
      };
      //新建酒店传CreateTime,其他模块不传
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
        }
      } else {
        params.hotelCreateTimeStart = "";
        params.hotelCreateTimeEnd = "";
      }
      params.screenType = "";
      this.$http
        .post(this.global.getHotelLineLegend, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var chartData = res.data.data;
            if (chartData.series) {
              this.lineChart.option.legend = chartData.legend;
              this.lineChart.option.xData = chartData.xAxis;
              var dataTransEd = commonMeth.dataTrans(chartData);
              var arr = [];
              var index = -1;
              for (let key in dataTransEd.series) {
                index++;
                var obj = {
                  name: `${key}`,
                  type: "line",
                  smooth: true,
                  symbolSize: this.global.symbolSize, //拐点圆的大小
                  itemStyle: {
                    normal: {
                      color: GLOBAL.chartColor[index], //改变折线点的颜色
                      lineStyle: {
                        color: GLOBAL.chartColor[index], //改变折线颜色
                      },
                    },
                  },
                  data: dataTransEd.series[key],
                };
                arr.push(obj);
              }
              this.lineChart.option.series = arr;
            } else {
              this.lineChart.noData = true;
            }
            this.lineChart.flag = true;
            this.lineLoading = false;
          } else if (code === 100001) {
            this.$message.error("折线图获取失败");
          }
          this.lineLoading = false;
        });
    },
    getConditionScreenType() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
      });
    },
    getTableSpecialCell() {
      switch (this.pageInfo.hotelState) {
        case "newList":
        case "implementation":
        case "using":
        case "offline":
        case "all":
          this.table.specialCell = {
            dar: true,
          };
          break;
      }
    },
    editCodeConfirmMeth() {
      this.$refs["editCodeForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确定修改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.editCodePost();
          });
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
    editCodePost() {
      var param = qs.stringify({
        token: this.token,
        event: this.editCodeForm.event,
        mobile: this.editCodeForm.mobile,
        code: this.editCodeForm.code,
      });
      this.$http.post(this.global.resetSmsCode, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  mounted() {
    this.apaasData = sessionStorage.getItem("apaasData");
    if (this.apaasData) {
      this.bread.level = 3;
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
      this.table.option = false;
      this.condition.screenType = "self";
      this.accountType = 1;
    } else {
      commonMeth.getTreeData(this.table.option).then((res) => {
        this.funArr = res.funArr;
        this.table.option = res.flag;
      });
      this.SetConditionScreentype();
      this.getConditionScreenType();
      this.boottime();
    }

    this.$store.commit("getCurDay");
    this.curDate = this.$store.state.curDate;
    this.getTableSpecialCell();
    this.token = sessionStorage.getItem("token");

    this.getToday();
    this.headers.token = this.token;
    this.headers.Authorization = this.token;
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.condition.hotel_use_state_label = "（全部状态）";
    this.getTopSearchShow();
    this.getScreenTypeSearchShow();
    this.getFilterConfrist();
    this.table.otherFromUrl = this.pageInfo.fromUrl;

    if (
      this.pageInfo.hotelState === "newList" ||
      this.pageInfo.hotelState === "implementation"
    ) {
      this.table.specialCell.typeshow = false;
    } else {
      this.table.specialCell.typeshow = true;
    }
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null || val === "") {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    twoPointCom() {
      return function (x) {
        !x ? (x = 0) : "";
        var f = parseFloat(x);
        if (isNaN(f)) {
          return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf(".");
        if (rs < 0) {
          rs = s.length;
          s += ".";
        }
        while (s.length <= rs + 2) {
          s += "0";
        }
        return s;
      };
    },
    spanCom() {
      var str = 1;
      this.pageInfo.hotelState !== "del" ? (str = 6) : (str = 8);
      return str;
    },
    showDataTime() {
      var startTime = "";
      var endTime = "";
      if (this.pageInfo.hotelState === "newList") {
        startTime = this.condition.startTime;
        endTime = this.condition.endTime;
      } else {
        startTime = this.condition.startTime;
        endTime = this.condition.endTime;
      }
      if (
        !startTime ||
        startTime === "" ||
        (startTime === endTime &&
          startTime === this.$store.state.curDate.curDay)
      ) {
        return "【即时】";
      } else {
        var time = "【" + startTime + "~" + endTime + "】";
        return time;
      }
    },
    showRateText() {
      if (this.pageInfo.hotelState === "newList") {
        if (
          this.condition.hotelCreateTimeStart === "" ||
          this.condition.hotelCreateTimeStart ===
            this.condition.hotelCreateTimeEnd
        ) {
          return "日活率";
        } else {
          return "平均日活率";
        }
      } else {
        if (
          this.condition.startTime === "" ||
          this.condition.startTime === this.condition.endTime
        ) {
          return "日活率";
        } else {
          return "平均日活率";
        }
      }
    },
    allLoadingStateCom() {
      var hotelState = this.pageInfo.hotelState;
      if (hotelState === "newList" || hotelState === "del") {
        this.allLoadingState = commonMeth.getAllLoading(
          this.table.loading,
          false,
          this.showLoading
        );
      } else {
        this.allLoadingState = commonMeth.getAllLoading(
          this.table.loading,
          this.lineLoading,
          this.showLoading
        );
      }
    },
  },
  watch: {
    pageInfo: {
      handler: function (newVal) {
        this.table.otherFromUrl = newVal.fromUrl;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.bread {
  flex: 1;
}
.hotelBrand {
  width: 100%;
}
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.container {
  /*height:400px;*/
  /*background:red;*/
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
}
.stepItem {
  width: 100%;
}
.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}
.imgBox {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  img {
    height: 100%;
    width: 100%;
  }
}
.none {
  display: none;
}
.notice {
  width: 100%;
  background: #eef2f7;
  padding: 20px 20px;
  margin-top: 30px;
  box-sizing: border-box;
  div {
    color: #909ca7;
  }
  .title {
    font-size: 16px;
    padding-bottom: 20px;
  }
  .item {
    margin-bottom: 20px;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .downLoad > a {
    color: #418efa;
    margin-left: 20px;
    cursor: pointer;
  }
}
.two {
  .showCon {
    width: 100%;
    height: 300px;
    border-radius: 4px;
    border: 1px dashed #ddd;
    overflow: auto;
  }
  .bottom {
    .show {
      display: flex;
    }
    .infoIncomplete {
      margin-left: 20px;
    }
    div {
      margin-bottom: 15px;
    }
  }
}
.three {
  width: 100%;
  height: 300px;
  .dataShow {
    display: flex;
    justify-content: center;
  }
  .error {
    color: #dd2139;
  }
}
.four {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .notice {
    width: 100%;
    text-align: center;
    background: none;
  }
  .iconBox {
    text-align: center;
    font-size: 80px;
    color: #20b759;
    margin-top: 30px;
  }
}
.paddingBox {
  padding: 15px 0;
}
.foldBtn {
  cursor: pointer;
}
.btnRow {
  display: flex;
  margin-top: 20px;
}
.rightBtn {
  margin-left: auto;
}
.quickDateBox {
  display: flex;
  align-items: center;
}
.quickDateSearch {
  display: flex;
}
.quickDateSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}
.quickDateSearch .item:last-child {
  margin-right: 0;
}
.quickDateSearch .item.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.largeBtn {
  min-width: 100px;
  height: 40px;
}
</style>
