import request from "@/api/request";
import qs from "querystring";

//  参数配置批量操作
export function paramsMul(param) {
    return request({
        url: `v2/batch_set/addConfigTask`,
        method: "post",
        data: qs.stringify(param),
    });
}
// 上传接口
export function upload(param) {
    return request({
        url: `v2/batch_set/uploadVideo`,
        method: "post",
        data: param,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
// 获取报障列表
export function staVersion(param) {
    return request({
        url: `v2/Hotelvisualization/staVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  导出接口
export function importVersion(param) {
    return request({
        url: `v2/Hotelvisualization/importVersion`,
        method: "post",
        data: qs.stringify(param),
        responseType: 'blob'
    });
}
//  获取模板列表
export function getTemplateList(param) {
    return request({
        url: `v2/Hotelvisualization/getTemplate`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取追踪详情
export function getHotelByVersion(param) {
    return request({
        url: `v2/Hotelvisualization/getHotelByVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取详情
export function getTemplateVersion(param) {
    return request({
        url: `v2/Hotelvisualization/getTemplateVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取房间数据
export function getHotelNoUpdate(param) {
    return request({
        url: `v2/Hotelvisualization/getHotelNoUpdate`,
        method: "post",
        data: qs.stringify(param),
    });
}

//  操作栏刷新
export function refreshNumber(param) {
    return request({
        url: `v2/Hotelvisualization/refreshNumber`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  操作栏重发接口
export function repeatLauncher(param) {
    return request({
        url: `v2/Hotelvisualization/repeatLauncher`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取apk列表
export function getApkList(param) {
    return request({
        url: `v2/Hotelvisualization/getApkList`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取签名列表
export function getSignList(param) {
    return request({
        url: `v2/Hotelvisualization/getSignList`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  apk发布
export function setBatchApk(param) {
    return request({
        url: `v2/Hotelvisualization/setBatchApk`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  批量更新加酒店
export function addHotel(param) {
    return request({
        url: `v2/Hotelvisualization/addHotel`,
        method: "post",
        data: qs.stringify(param),
    });
}
// 批量操作参数
export function paramBatch(param) {
    return request({
        url: `v2/Hotelvisualization/staVersionParam`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  批量操作下载
export function downloadBatch(param) {
    return request({
        url: `v2/Hotelvisualization/staVersionDownload`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  批量操作服务
export function serviceBatch(param) {
    return request({
        url: `v2/Hotelvisualization/staVersionService`,
        method: "post",
        data: qs.stringify(param),
    });
}