<template>
  <div>
    <!-- 面包屑及筛选 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">独立影视财务</span>
        <span slot="third">待对账管理</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getList"></top-search>
    </el-card>
    <!-- 面包屑及筛选 -->
    <!-- 对账酒店列表 -->
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">待对账列表</div>
      <div class="tab-left">
        <el-tabs :class="tabDisabled?'disable':''" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="影视单点" name="001001" :disabled="tabDisabled">
            <table-list :table="table" :checkedData="checkedData" @tableMeth="tableMeth" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" @showUploadDialog="showUploadDialog" v-loading="table.loading"></table-list>
          </el-tab-pane>
          <el-tab-pane label="影视天卡" name="001002" :disabled="tabDisabled">
            <table-list :table="tableTwo" :checkedData="checkedData" @tableMeth="tableMeth" @page="getNewPage2" @limit="getNewLimit2" @tableSelect="getTableSelect" @showUploadDialog="showUploadDialog" v-loading="tableTwo.loading"></table-list>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 对账酒店列表 -->
      <!-- 对账按钮 -->
      <div class="btn-right">
        <el-tooltip :content="btnTooltipContent" placement="top">
          <div>
            <!-- :disabled="dateBtn" -->
            <el-button class="largeBtn"  @click="toAccountStatemen" v-if="showFunModel('生成对账单') && auth.type != 'htrip'" type="primary">生成对账单</el-button>
            <el-button class="largeBtn" @click="toAccountAllStatemen" v-if="showFunModel('生成对账单') && auth.type != 'htrip'" type="primary">全部对账</el-button>
          </div>
        </el-tooltip>
      </div>
      <!-- 对账按钮 -->
    </el-card>
    <!-- 详情 -->
    <el-drawer :title="contentTtile" :visible.sync="contentShowVisible" :size="drawerWidth" :modal="false" :wrapperClosable="false">
      <div class="picShow" v-if="contentType == 1">
        <el-table ref="singleTable" :data="contentTableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="Loading">
          <el-table-column label="时间" min-width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.order_date}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_room" label="房间号" min-width="150px"></el-table-column>
          <el-table-column prop="order_paysn" min-width="180px" label="订单号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="order_phone" label="手机号" min-width="150px">
          </el-table-column>
          <el-table-column prop="order_goods_name" label="电影名称" min-width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="order_amount" label="单价">
          </el-table-column>
          <el-table-column prop="calculate" label="分成比例">
          </el-table-column>
          <el-table-column prop="income" label="收益"> </el-table-column>
        </el-table>
      </div>

      <div class="picShow picShow1" v-if="contentType == 2">
        {{ returnContent }}
      </div>
    </el-drawer>
    <!-- 详情 -->
    <div class="allLoading" v-show="false">{{ allLoadingStateCom }}</div>
  </div>
</template>

<script>
import {
  getNoPassMessage,
  getIddReconciliationDetailByContractAndDate,
  checkReconciliationExistMonth,
  getAllIds,
} from "@/api/iddfinan.js";
import qs from "querystring";
import commonMeth from "../../api/method";
export default {
  name: "adManCustomHotal",
  data: () => ({
    btnTooltipContent: "对账时间为每月15-20号",
    Loading: false,
    drawerWidth: "95%",
    contentTtile: "",
    contentType: null,
    returnContent: "",
    contentShowVisible: false,
    activeName: "001001",
    type: "timeCenter",
    contentTableList: [],
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    dateBtn: false,
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    table: {
      tableHeader: [
        { prop: "hotel_name", label: "酒店名称", width: "300" },
        { prop: "room_num", label: "客房数", width: "" },
        { prop: "active_terminal_num", label: "激活终端数", width: "" },
        {
          prop: "valid_active_terminal_num",
          label: "有效激活终端数",
          width: "",
        },
        { prop: "day_active_rate", label: "日活率", width: "" },
        { prop: "date", label: "收益月份", width: "" },
        { prop: "order_num", label: "订单数", width: "" },
        { prop: "amount", label: "收益金额", width: "" },
        { prop: "income_source_text", label: "收益来源", width: "" },

        { prop: "partner_name", label: "所属合作伙伴名称", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "iddFinManReconHotal",
      name: "iddFinManReconHotal",
      option: false,
      loading: true,
      select: true,
    },
    tableTwo: {
      tableHeader: [
        { prop: "hotel_name", label: "酒店名称", width: "300" },
        { prop: "room_num", label: "客房数", width: "" },
        { prop: "active_terminal_num", label: "激活终端数", width: "" },
        {
          prop: "valid_active_terminal_num",
          label: "有效激活终端数",
          width: "",
        },
        { prop: "day_active_rate", label: "日活率", width: "" },
        { prop: "date", label: "收益月份", width: "" },
        { prop: "order_num", label: "订单数", width: "" },
        { prop: "amount", label: "收益金额", width: "" },
        { prop: "income_source_text", label: "收益来源", width: "" },
        { prop: "partner_name", label: "所属合作伙伴名称", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "iddFinManReconHotal",
      name: "iddFinManReconHotal",
      option: false,
      loading: true,
      select: true,
    },
    topSearchShow: {},
    condition: {},
    operateIds: "",
    operateIdsArr: [],
    alloperateIdsArr: [],
    dayAlloperateIdsArr: [],
    checkedData: [],
    showData: {
      amount: 0,
      alreadyReconciliation: 0,
      notReconciliation: 0,
    },
    token: "",
    showLoading: true,
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    auth: {},
    funArr: [],
    allData: {},
    tableOperateDisabled: false,
    topSearchSave: {},
    allLoadingState: false,
    incomeSource: "",
    reconciliationStatus: "",
    keyword: "",
    tabDisabled: false,
  }),
  methods: {
    // 点击表格按钮 查看相关信息
    showUploadDialog(val) {
      this.Loading = true;
      this.contentType = val.num;
      if (this.contentType == 1) {
        this.contentTtile = "详细";
        this.drawerWidth = "95%";
        const json = {
          date: val.val.date,
          hotelContract: val.val.hotel_contract,
          partnerId: val.val.partner_id,
          reconciliationType: val.val.type,
        };
        var param = qs.stringify(json);
        getIddReconciliationDetailByContractAndDate(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.contentTableList = res.data.data;
              this.Loading = false;
            }
          })
          .catch((err) => {});
      } else {
        this.contentTtile = "驳回原因";
        this.drawerWidth = "45%";
        const json = {
          id: val.val.id,
        };
        var param = qs.stringify(json);
        getNoPassMessage(param)
          .then((res) => {
            if (res.data.code == 100000)
              this.returnContent = res.data.data.message;
          })
          .catch((err) => {});
      }
      this.contentShowVisible = true;
    },
    // 关闭
    closeDialog() {
      this.contentShowVisible = false;
    },
    // 二次确认选择的酒店数据
    toAccountStatemen() {
      if (
        this.alloperateIdsArr.length == 0 &&
        this.dayAlloperateIdsArr.length == 0
      ) {
        this.$message.warning("请先选择需对账酒店，再进行对账单操作");
      } else {
        if (this.dayAlloperateIdsArr.length == 0) {
          let msgOnlyOne = [
            "您未选择“影视天卡”对账，是否继续发起对账申请？",
            "每月仅限一次对账申请！",
          ];
          this.goAccountStatemen(msgOnlyOne);
        } else if (this.alloperateIdsArr.length == 0) {
          let msgOnlyDay = [
            "您未选择“影视单点”对账，是否继续发起对账申请？",
            "每月仅限一次对账申请！",
          ];
          this.goAccountStatemen(msgOnlyDay);
        } else {
          let msgAll = ["每月仅限一次对账申请！"];
          this.goAccountStatemen(msgAll);
        }
      }
    },
    // 二次确认全部对账
    toAccountAllStatemen() {
      this.$confirm("提示", {
        title: "提示",
        message: "每月仅限一次对账申请！",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var param = "";
          param = qs.stringify({
            token: this.token,
          });
          getAllIds(param).then((res) => {
            if (res.data.code === 100000) {
              var one = res.data.data.one;
              var day = res.data.data.day;
              if (!one && !day) {
                this.$message.warning("无可对账酒店");
              } else {
                this.$router.push({
                  path: "./iddStatementAccount",
                  query: {
                    ids: one,
                    dayids: day,
                  },
                });
              }
            }
          });
        })
        .catch(() => {});
    },
    // 确认酒店选择结果并跳转
    goAccountStatemen(data) {
      let msg = data;
      const newDatas = [];
      const creat = this.$createElement;
      for (const i in msg) {
        newDatas.push(creat("p", null, msg[i]));
      }
      this.$confirm("提示", {
        title: "提示",
        message: creat("div", null, newDatas),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = [];
          this.alloperateIdsArr.forEach((item) => {
            ids.push(item.id);
          });
          var dayids = [];
          this.dayAlloperateIdsArr.forEach((item) => {
            dayids.push(item.id);
          });
          this.$router.push({
            path: "./iddStatementAccount",
            query: {
              ids: ids,
              dayids: dayids,
            },
          });
        })
        .catch(() => {});
    },
    // tab切换
    handleClick() {
      this.tableMeth();
    },
    // 表格数据加载
    tableMeth(orderVal) {
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      }
      var startDate = "";
      var endDate = "";
      if (this.condition.month && this.condition.month !== "") {
        startDate = this.condition.month[0];
        endDate = this.condition.month[1];
      }
      this.table.loading = true;
      this.tableTwo.loading = true;
      this.tabDisabled = true;
      var param = "";
      param = qs.stringify({
        token: this.token,
        reconciliationType: this.activeName,
        page:
          this.activeName == "001001" ? this.table.page : this.tableTwo.page,
        limit:
          this.activeName == "001001" ? this.table.limit : this.tableTwo.limit,
        incomeSource: this.incomeSource,
        reconciliationStatus: this.reconciliationStatus,
        keyword: this.keyword,
        // revenueType: this.activeName,
      });
      //
      this.$http
        .post(this.global.getCardPreReconciliationList, param)
        .then((res) => {
          if (res.data.code === 100000) {
            if (this.activeName == "001001") {
              var tableData = res.data.data;
              this.table.tableList = tableData.items;
              this.table.total = tableData.total;
              this.table.tableList.filter((item) => {
                this.alloperateIdsArr.forEach((item1) => {
                  if (item1.id === item.courseId) {
                    this.checkedData.push(item);
                  }
                });
              });
            } else {
              var tableData = res.data.data;
              this.tableTwo.tableList = tableData.items;
              this.tableTwo.total = tableData.total;
              this.tableTwo.tableList.filter((item) => {
                this.dayAlloperateIdsArr.forEach((item1) => {
                  if (item1.id === item.courseId) {
                    this.checkedData.push(item);
                  }
                });
              });
            }
          } else {
            this.$message.warning(res.data.msg);
          }
          this.table.loading = false;
          this.tableTwo.loading = false;
          this.tabDisabled = false;
        })
        .catch(() => {
          this.table.loading = false;
          this.tableTwo.loading = false;
          this.tabDisabled = false;
        });
    },
    // 分页
    getNewPage(newPage) {
      this.table.page = newPage;
      this.table.loading = true;

      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
      this.table.loading = true;
      this.tableMeth();
    },
    // 分页
    getNewPage2(newPage) {
      this.tableTwo.page = newPage;
      this.tableTwo.loading = true;
      this.tableMeth();
    },
    getNewLimit2(newLimit) {
      this.tableTwo.limit = newLimit;
      this.tableTwo.loading = true;
      this.tableMeth();
    },
    // 获取筛选权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          keyword: true,
          keywordPlaceholder: "请输入酒店关键字",
          watingHotalStatus: true,
          SourceOfIncome: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          keyword: true,
          keywordPlaceholder: "请输入酒店关键字",
          watingHotalStatus: true,
          SourceOfIncome: true,
        };
      }
    },
    // 筛选
    getList(e) {
      this.incomeSource = e.SourceOfIncome;
      this.reconciliationStatus = e.watingHotalStatus;
      this.keyword = e.keyword;
      this.table.page = 1;
      this.tableMeth();
    },
    // 表格选择
    getTableSelect(val) {
      var operateIds = "";
      this.operateIdsArr = val;
      if (this.activeName == "001001") {
        this.alloperateIdsArr = this.operateIdsArr;
      } else {
        this.dayAlloperateIdsArr = this.operateIdsArr;
      }
      val.forEach((item, index) => {
        operateIds += item.id + ",";
      });
      this.operateIds = operateIds.slice(0, -1);
      this.operateIdsArr = this.operateIds.split(",");
    },
    //数据统计
    showDataMeth() {
      this.$http
        .get(this.global.getReconciliationStatistics, {
          params: {
            token: this.token,
            status: this.condition.finStatus,
            reconciliationType: this.condition.finType,
            month: this.condition.month,
            keyword: this.condition.keyword,
          },
        })
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showData = res.data.data;
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    // 按钮时间控制
    dateTrueBtn() {
      checkReconciliationExistMonth()
        .then((res) => {
          if (res.data.code == 100000) {
            var d = new Date();
            var today = d.getDate();
            if (15 <= today && today <= 20) {
              this.dateBtn = false;
            } else {
              this.dateBtn = true;
            }
          } else {
            this.dateBtn = true;
            this.btnTooltipContent = "当前账号本月已生成过对账单";
          }
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.showDataMeth();
    this.tableMeth();
    this.dateTrueBtn();
    commonMeth.getTreeData().then((res) => {
      this.funArr = res.funArr;
    });
  },
  computed: {
    showDataCom() {
      return function (s, type = "", n = 2) {
        if (type === "money") {
          if (s === null) {
            return 0;
          } else {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
            var l = s.split(".")[0].split("").reverse(),
              r = s.split(".")[1];
            var t = "";
            for (var i = 0; i < l.length; i++) {
              t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
            }
            return t.split("").reverse().join("") + "." + r;
          }
        } else {
          if (s === null) {
            return 0;
          } else {
            return parseFloat(s).toLocaleString();
          }
        }
      };
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(
        this.table.loading,
        false,
        this.showLoading
      );
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep :focus {
  outline: 0;
}
.rightBtn {
  margin-left: auto;
}
.rowBox {
  margin-top: 20px;
  box-sizing: border-box;
}
.box-card {
  position: relative;
}
.btn-right {
  position: absolute;
  top: 30px;
  right: 21px;
  z-index: 10;
}
.placeHolderBox {
  height: 40px;
  text-align: center;
  font-size: 20px;
}
.largeBtn.el-button {
  width: 100px;
  height: 40px;
  padding: 0;
}
.chartTitle {
  margin-bottom: 0px !important;
}
.el-tabs ::v-deep .el-tabs__nav {
  padding: 10px 0 0px !important;
}
.el-tabs ::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  color: #303030;
  position: relative;
}
.el-tabs ::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #e4e7ed;
  z-index: 1;
}
.el-tabs ::v-deep .el-tabs__active-bar {
  width: 56px !important;
}
.el-tabs.disable ::v-deep.el-tabs__item {
  color: #ccc;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;

  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  background-color: #f9f9f9;
}

.tableBoxContent {
  background-color: #fff;
  padding: 20px;
}
.picShow1 {
  box-sizing: border-box;
  margin-top: 10px;
  overflow: hidden;
}
.picShow {
  position: relative;
  z-index: 20;
  width: 100%;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background: #b6babf;
}
</style>
