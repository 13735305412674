import request from '@/api/request';
import qs from "querystring";
let vi = '/v2'
//  a列表
export function getList(data) {
  return request({
    url: `${vi}/Hotellog/dailyLog`,
    method: 'post',
    data,
  });
}
// 新增
export function create(data) {
  return request({
    url: `${vi}/`,
    method: 'post',
    data,
  });
}
// 修改
export function updateData(data) {
  return request({
    url: `${vi}/`,
    method: 'post',
    data,
  });
}
// 删除
export function allDelete(data) {
  return request({
    url: `${vi}/`,
    method: 'post',
    data,
  });
}

