<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="incomeTab" style="margin-bottom:20px;">
      <el-tab-pane label="launcher" name="launcher">
        <launcher v-if="activeName==='launcher'" :query="query"  @updata="updataLauncher" :content="launcherData"></launcher>
      </el-tab-pane>
      <el-tab-pane label="基础图片" name="basic">
        <basicList v-if="activeName==='basic'" :query="query"></basicList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getMenuAttrList, getLauncherData } from "../../api/launcher";
import basicList from "./basicList";
import launcher from "./launcher";
export default {
  name: "launcherSet",
  components: {
    basicList,
    launcher,
  },
  props: ["query"],
  data() {
    return {
      activeName: "launcher",
      launcherData: [
        {
          mname: "功能模块",
          morder: "0",
          attr: "1",
          attrName: "目录",
          id: "2",
          child: [
            {
              mname: "主页",
              morder: "1",
              attr: "62",
              attrName: "IU主页",
              id: "3",
              child: [
                {
                  mname: "主页视频组",
                  morder: "1",
                  attr: "48",
                  attrName: "视频组",
                  id: "533",
                  child: [
                    {
                      mname: "主页视频1",
                      morder: "0",
                      attr: "1",
                      attrName: "目录",
                      id: "534",
                      child: [
                        {
                          mname: "主页视频1-1",
                          morder: "0",
                          attr: "37",
                          attrName: "视频",
                          id: "535",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "主页右图",
                  morder: "2",
                  attr: "39",
                  attrName: "广告",
                  id: "18",
                },
              ],
            },
            {
              mname: "手机投屏",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "457",
            },
            {
              mname: "电视直播",
              morder: "4",
              attr: "72",
              attrName: "瀑布流电视",
              id: "5",
              child: [
                {
                  mname: "电视广告",
                  morder: "1",
                  attr: "39",
                  attrName: "广告",
                  id: "461",
                  child: [
                    {
                      mname: "酒店概况",
                      morder: "1",
                      attr: "3",
                      attrName: "图文",
                      id: "499",
                      child: [
                        {
                          mname: "电视广告刊位1内容",
                          morder: "0",
                          attr: "34",
                          attrName: "视图",
                          id: "500",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "电视内页广告",
                  morder: "2",
                  attr: "39",
                  attrName: "广告",
                  id: "160",
                },
              ],
            },
            {
              mname: "酒店服务",
              morder: "5",
              attr: "45",
              attrName: "六联版",
              id: "4",
              child: [
                {
                  mname: "酒店服务视频组",
                  morder: "1",
                  attr: "48",
                  attrName: "视频组",
                  id: "536",
                  child: [
                    {
                      mname: "酒店服务视频1",
                      morder: "1",
                      attr: "1",
                      attrName: "目录",
                      id: "537",
                      child: [
                        {
                          mname: "酒店服务视频1-1",
                          morder: "0",
                          attr: "37",
                          attrName: "视频",
                          id: "538",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "服务文字",
                  morder: "2",
                  attr: "33",
                  attrName: "文字",
                  id: "15",
                },
                {
                  mname: "服务内容",
                  morder: "3",
                  attr: "46",
                  attrName: "双图目录",
                  id: "16",
                  child: [
                    {
                      mname: "互动活动",
                      morder: "1",
                      attr: "46",
                      attrName: "双图目录",
                      id: "19",
                      child: [
                        {
                          mname: "互动活动大图",
                          morder: "0",
                          attr: "34",
                          attrName: "视图",
                          id: "169",
                        },
                      ],
                    },
                  ],
                },
                {
                  mname: "服务广告",
                  morder: "4",
                  attr: "39",
                  attrName: "广告",
                  id: "462",
                  child: [
                    {
                      mname: "酒店介绍",
                      morder: "1",
                      attr: "3",
                      attrName: "图文",
                      id: "502",
                      child: [
                        {
                          mname: "酒店服务广告刊位1内容",
                          morder: "0",
                          attr: "34",
                          attrName: "视图",
                          id: "503",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              mname: "携旅极光TV",
              morder: "6",
              attr: "73",
              attrName: "瀑布流电影",
              id: "515",
            },
          ],
        },
        {
          mname: "欢迎页模块",
          morder: "2",
          attr: "1",
          attrName: "目录",
          id: "1",
          child: [
            {
              mname: "焦点图标",
              morder: "0",
              attr: "3",
              attrName: "图文",
              id: "26",
            },
            {
              mname: "默认图标",
              morder: "0",
              attr: "3",
              attrName: "图文",
              id: "27",
            },
            {
              mname: "欢迎语",
              morder: "0",
              attr: "33",
              attrName: "文字",
              id: "28",
            },
            {
              mname: "欢迎视频",
              morder: "4",
              attr: "48",
              attrName: "视频组",
              id: "539",
              child: [
                {
                  mname: "欢迎视频1",
                  morder: "1",
                  attr: "1",
                  attrName: "目录",
                  id: "540",
                  child: [
                    {
                      mname: "欢迎视频1-1",
                      morder: "0",
                      attr: "37",
                      attrName: "视频",
                      id: "541",
                    },
                  ],
                },
              ],
            },
            {
              mname: "欢迎页广告",
              morder: "5",
              attr: "39",
              attrName: "广告",
              id: "158",
            },
          ],
        },
        {
          mname: "导航栏图标",
          morder: "3",
          attr: "1",
          attrName: "目录",
          id: "42",
          child: [
            {
              mname: "选中图标零",
              morder: "1",
              attr: "3",
              attrName: "图文",
              id: "508",
            },
            {
              mname: "焦点图标零",
              morder: "1",
              attr: "3",
              attrName: "图文",
              id: "509",
            },
            {
              mname: "默认图标零",
              morder: "1",
              attr: "3",
              attrName: "图文",
              id: "510",
            },
            {
              mname: "焦点图标一",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "43",
            },
            {
              mname: "默认图标一",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "44",
            },
            {
              mname: "选中图标一",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "504",
            },
            {
              mname: "焦点图标二",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "45",
            },
            {
              mname: "默认图标二",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "51",
            },
            {
              mname: "选中图标二",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "505",
            },
            {
              mname: "焦点图标三",
              morder: "4",
              attr: "3",
              attrName: "图文",
              id: "46",
            },
            {
              mname: "默认图标三",
              morder: "4",
              attr: "3",
              attrName: "图文",
              id: "52",
            },
            {
              mname: "选中图标三",
              morder: "4",
              attr: "3",
              attrName: "图文",
              id: "506",
            },
            {
              mname: "焦点图标四",
              morder: "5",
              attr: "3",
              attrName: "图文",
              id: "47",
            },
            {
              mname: "默认图标四",
              morder: "5",
              attr: "3",
              attrName: "图文",
              id: "53",
            },
            {
              mname: "选中图标四",
              morder: "5",
              attr: "3",
              attrName: "图文",
              id: "507",
            },
            {
              mname: "选中图标5",
              morder: "6",
              attr: "3",
              attrName: "图文",
              id: "516",
            },
            {
              mname: "默认图标5",
              morder: "6",
              attr: "3",
              attrName: "图文",
              id: "517",
            },
            {
              mname: "焦点图标5",
              morder: "6",
              attr: "3",
              attrName: "图文",
              id: "518",
            },
            {
              mname: "选中图标6",
              morder: "7",
              attr: "3",
              attrName: "图文",
              id: "519",
            },
            {
              mname: "默认图标6",
              morder: "7",
              attr: "3",
              attrName: "图文",
              id: "520",
            },
            {
              mname: "焦点图标6",
              morder: "7",
              attr: "3",
              attrName: "图文",
              id: "521",
            },
          ],
        },
        {
          mname: "默认素材",
          morder: "4",
          attr: "1",
          attrName: "目录",
          id: "463",
          child: [
            {
              mname: "默认视频组",
              morder: "1",
              attr: "48",
              attrName: "视频组",
              id: "464",
              child: [
                {
                  mname: "默认视频目录",
                  morder: "0",
                  attr: "1",
                  attrName: "目录",
                  id: "465",
                  child: [
                    {
                      mname: "默认视频",
                      morder: "0",
                      attr: "37",
                      attrName: "视频",
                      id: "466",
                    },
                  ],
                },
              ],
            },
            {
              mname: " 默认广告",
              morder: "2",
              attr: "39",
              attrName: "广告",
              id: "468",
              child: [
                {
                  mname: "酒店图片",
                  morder: "0",
                  attr: "3",
                  attrName: "图文",
                  id: "469",
                  child: [
                    {
                      mname: "酒店图片详情",
                      morder: "0",
                      attr: "34",
                      attrName: "视图",
                      id: "470",
                    },
                  ],
                },
              ],
            },
            {
              mname: "默认跳转大图广告",
              morder: "3",
              attr: "3",
              attrName: "图文",
              id: "532",
            },
          ],
        },
        {
          mname: "隐藏",
          morder: "5",
          attr: "1",
          attrName: "目录",
          id: "498",
          child: [
            {
              mname: "约吧",
              morder: "2",
              attr: "3",
              attrName: "图文",
              id: "456",
            },
            {
              mname: "商城",
              morder: "7",
              attr: "40",
              attrName: "商城",
              id: "7",
            },
          ],
        },
      ],
    };
  },
  watch: {
    // 酒店信息监听
    query: {
      handler: function (newVal) {
        this.query = newVal;
        this.getLauncherData(this.query.hotelName);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleClick() {},
    // 获取launcher数据
    updataLauncher(){
        this.getLauncherData(this.query.hotelName);
    },
    // 获取launcher数据
    getLauncherData(dataName) {
      const json ={
        database:dataName
      }
      getLauncherData(json)
        .then((res) => {
          if(res.data.code == 100000){
            this.launcherData = res.data.data
          }else{
            this.$message.warning(res.data.msg)
          }
        })
        .catch((err) => {
           this.$message.warning("数据查询失败")
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
