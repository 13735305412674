import request from './request';
import qs from "querystring";
//  获取独立影视汇总统计
export function getAllPartnerIndependentStatistics(param) {
    return request({
        url: `v1/Movieincomeindependent/getAllPartnerIndependentStatistics`,
        method: 'post',
        data: param,
    });
}
//  获取独立影视汇总合作伙伴列表
export function getAllPartnerIndependentList(param) {
    return request({
        url: `v1/Movieincomeindependent/getAllPartnerIndependentList`,
        method: 'post',
        data: param,
    });
}
//  独立影视汇总表格导出
export function ExportIddTableList(param) {
    return request({
        url: `v1/Movieincomeindependent/exportAllPartnerIndependentList`,
        method: 'post',
        data: param,
        responseType: 'blob'
    });
}
//  获取影视汇总统计
export function getAllPartnerStatistics(param) {
    return request({
        url: `v1/Movieincome/getAllPartnerStatistics`,
        method: 'post',
        data: param,
    });
}
//  获取影视汇总合作伙伴列表
export function getAllPartnerList(param) {
    return request({
        url: `v1/movieincome/getAllPartnerList`,
        method: 'post',
        data: param,
    });
}
//  影视汇总表格导出
export function exportAllPartnerList(param) {
    return request({
        url: `v1/Movieincome/exportAllPartnerList`,
        method: 'post',
        data: param,
        responseType: 'blob'
    });
}
//  获取渠道商合同列表
export function getTrackingList(param) {
    return request({
        url: `v1/Partnercontract/trackingList`,
        method: 'post',
        data: param,
    });
}
//  获取渠道商合同列表
export function getTrackingInfo(param) {
    return request({
        url: `v1/Partnercontract/trackingInfo`,
        method: 'post',
        data: param,
    });
}

//  获取收益数据修正列表
export function DataCorrectionSearch(param) {
    return request({
        url: `v1/datacorrectiontask/search`,
        method: 'post',
        data: param,
    });
}

//  获取所有渠道商
export function getAllPartner(param) {
    return request({
        url: `v1/datacorrectiontask/getAllPartner`,
        method: 'post',
        data: param,
    });
}

//  数据修正任务新增
export function addDataCorrectionTask(param) {
    return request({
        url: `v1/datacorrectiontask/addDataCorrectionTask`,
        method: 'post',
        data: param,
    });
}
//  数据资产列表-表头映射关系
export function getTableHeader(param) {
    return request({
        url: `v1/Digitalassets/getSearchTab`,
        method: 'post',
        data: param,
    });
}
//  数据资产列表-列表查询
export function getDataList(param) {
    return request({
        url: `v1/Digitalassets/search`,
        method: 'post',
        data: param,
    });
}
//    历史导出
export function exportFile(param) {
    return request({
        url: `v1/Digitalassets/export?${param}`,
        method: 'get',
        responseType: 'blob',
        data: param,
    });
}
//  获取酒店详情 
export function getHotelInfo(param) {
    return request({
        url: `v2/hotel/detail`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取酒店房间列表 
export function getHotelRoom(param) {
    return request({
        url: `v2/hotel/roomlist`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取广告订单列表 
export function getAdvOrderList(param) {
    return request({
        url: `v2/single_screen/getAdvOrderList`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取收益订单列表 
export function getProfitList(param) {
    return request({
        url: `v2/single_screen/getProfitList`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取收益订单凭证 
export function getOrderCert(param) {
    return request({
        url: `v2/single_screen/getOrderCert`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取版本信息 
export function getVerList(param) {
    return request({
        url: `v2/single_screen/getVerList`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取房间设备信息
export function getEquipmentInfo(param) {
    return request({
        url: `v2/single_screen/getEquipmentInfo`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取收益趋势信息
export function getProfitTrend(param) {
    return request({
        url: `v2/single_screen/getProfitTrend`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取系统交互趋势信息
export function getSysTrend(param) {
    return request({
        url: `v2/single_screen/getSysTrend`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取在线时长信息
export function getOnLineTrend(param) {
    return request({
        url: `v2/single_screen/getOnLineTrend`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取公区屏幕列表
export function getPublicDeviceList(param) {
    return request({
        url: `v2/Device/publicDeviceList`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   房间截屏
export function doInstruction(param) {
    return request({
        url: `v2/Cmdtask/addCmdTask`,
        method: "post",
        data: qs.stringify(param),
    });
}
//   公区截屏
export function publicScreenShot(param) {
    return request({
        url: `v2/Device/publicScreenShot`,
        method: "post",
        data: qs.stringify(param),
    });
}
//   公区刷新截屏
export function getPublicScreenPic(param) {
    return request({
        url: `v2/Device/getPublicScreenPic`,
        method: "post",
        data: qs.stringify(param),
    });
}
//   房间刷新截屏
export function getRoomScreenPic(param) {
    return request({
        url: `v2/Device/getRoomScreenPic`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取房间开机次数
export function getStartTimes(param) {
    return request({
        url: `v2/single_screen/getStartTimes`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取最近上线时长
export function getDeviceOnlineTime(param) {
    return request({
        url: `v2/Device/getDeviceOnlineTime`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取广告曝光趋势
export function getAdvTrend(param) {
    return request({
        url: `v2/single_screen/getAdvTrend`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取酒店代理商信息
export function getBusinessesagents(param) {
    return request({
        url: `v2/hotel/businessesagents`,
        method: "post",
        data: qs.stringify(param),
    });
}