<template>

  <div :class="['box',showData[0]?'':'box1']" :style="{width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px',zIndex:style.zIndex}">
    <div class="borderBox"></div>
    <visiualButton class="hide" @openmore='openUppage' @recoverydata="recoverydata" btnShow='picmore'></visiualButton>
    <uploadSource ref="uploadSource" sourceType="video" @upsuccess='upSuccess'></uploadSource>

    <img v-if="showData[0]" :src="showData[0].fileList[0].source_url" alt="" :style="{width: '100%', height: '100%'}">
    <div class="title" v-if="content.showTextType != 2">{{showData[0].title}}</div>
    <div class="title2" v-if="content.showTextType == 2">
      <div>{{showData[0].title}}</div>
      <div style="font-size:15px;margin-top:12px">{{showData[0].desc}}</div>
    </div>
    <div class="label" v-if="content.showTextType == 3">{{showData[0].labelValue}}</div>
    <el-dialog title="编辑层级视频信息" class="info" :visible.sync="infoVisible" width="40%" :append-to-body="true" :before-close='cannel'>
      <hierarchy1 ref="hierarchy1" :notOpt='content.notOpt' :noChangeTitle='content.noChangeTitle' :isEdit='isEdit' :titleDataList="titleDataList" sourceType="video" @defaulttitledata="gettitleData" :is_first='true' style="margin-bottom:20px"></hierarchy1>
      <span slot="footer" class="dialog-footer">
        <el-button class="largeBtn" type="primary" @click="save">保 存</el-button>
        <el-button class="cancelBtn" @click="cannel">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";
import uploadSource from "../common/uploadSource.vue";
import hierarchy1 from "../common/hierarchy1.vue";
export default {
  name: "hierarchyVideoBox",
  components: {
    visiualButton,
    uploadSource,
    hierarchy1,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => {},
    },
    historyData: {
      type: Array,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        this.content = JSON.parse(JSON.stringify(newVal));
        let obj = JSON.parse(JSON.stringify(this.content.position));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      content: {},
      showData: this.value,
      flag: true,
      video: null,
      infoVisible: false,
      titleDataList: [],
      noPicList: [],
      noTitleList: [],
      noSortList: [],
      noPicList2: [],
    };
  },
  mounted() {
    this.titleDataList = JSON.parse(JSON.stringify(this.showData));
  },
  methods: {
    cannel() {
      this.titleDataList = JSON.parse(JSON.stringify(this.showData));
      this.infoVisible = false;
    },
    save() {
      if (this.noPicList.length != 0) {
        this.$message.warning("请将素材内容补充完整");
        return false;
      }
      if (this.noPicList2.length != 0) {
        this.$message.warning("请将素材内容补充完整");
        return false;
      }
      if (this.noTitleList.length != 0) {
        this.$message.warning("请将标题补充完整");
        return false;
      }
      if (this.noSortList.length != 0) {
        this.$message.warning("请将排序补充完整");
        return false;
      }
      this.showData = JSON.parse(JSON.stringify(this.titleDataList));
      let str1 = JSON.stringify(this.showData);
      let str2 = JSON.stringify(this.historyData);
      if (str1 != str2) {
        this.showData[0].update = true;
      }
      this.infoVisible = false;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    // 描述图文数据获取与验证列表
    gettitleData(data) {
      this.titleDataList = data;
      let childList = [];
      this.titleDataList.forEach((j) => {
        childList = [...j.child, ...childList];
      });
      this.noPicList = this.titleDataList.filter((i) => {
        if (i.fileList.length == 0 && i.isOnline == "0") {
          return i;
        }
        if (i.isOnline == "1") {
          let arr = [];
          arr = i.fileList.filter((t) => {
            if (t.source_url == "") {
              return t;
            }
          });
          if (arr.length != 0 && i.isOnline == "1") {
            return i;
          }
        }
      });
      let childFileList = childList.filter((i) => {
        if (i.fileList.length == 0 && i.isOnline == "0") {
          return i;
        }
        if (i.isOnline == "1") {
          let arr = [];
          arr = i.fileList.filter((t) => {
            if (t.source_url == "") {
              return t;
            }
          });
          if (arr.length != 0 && i.isOnline == "1") {
            return i;
          }
        }
      });
      this.noPicList = [...this.noPicList, ...childFileList];
      this.noTitleList = this.titleDataList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      let childTitleList = childList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      this.noTitleList = [...this.noTitleList, ...childTitleList];
      this.noSortList = this.titleDataList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      let childSortList = childList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      this.noSortList = [...this.noSortList, ...childSortList];
      this.noPicList2 = this.titleDataList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }
        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
      let childoutList = childList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }

        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
      this.noPicList2 = [...this.noPicList2, ...childoutList];
    },

    upSuccess(data) {
      this.showData.source_md5 = data.md5;
      this.showData.source_url = data.url;
      this.showData.update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    openUppage() {
      this.infoVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 2;
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: none;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
.title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 9;
  color: #fff;
  line-height: 57px;
  padding: 0 20px;
  box-sizing: border-box;
}
.title2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 76.6px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 9;
  color: #fff;
  font-size: 24px;
  padding: 5px 20px;
  box-sizing: border-box;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  border-radius: 0 0 20px 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
}
.player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 40px;
  font-size: 80px;
  line-height: 75px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  z-index: 9;
}
.paused {
  display: none;
  z-index: 9;
}
.box:hover .paused {
  display: block;
  z-index: 9;
}
</style>