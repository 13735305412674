<template>
    <div>
        <!--<top-bread :bread="bread">-->
            <!--<span slot="second">审核中</span>-->
        <!--</top-bread>-->
        <el-card class="box-card noRightPadding" >
            <div class="outBox" v-if="auth.register_review_status===0">
                <div class="left" style="flex-direction: column;justify-content: space-between">
                    <div style="margin-bottom:30px;">
                        <div class="firstTitle item">账号信息审核中……</div>
                        <div class="smallTitle item">请等待</div>
                    </div>
                    <div class="help item">如有疑问，请拨打40000-77988咨询</div>
                </div>
                <div class="right">
                    <div class="imgBox">
                        <img v-if="auth.register_review_status===0" src="../../assets/img/checking.png" alt="" >
                        <img v-if="auth.register_review_status===-1" src="../../assets/img/checkFailed.png" alt="">
                    </div>
                </div>
            </div>

            <div class="outBox" v-if="auth.register_review_status===-1">
                <div class="left">
                    <div class="backBox">
                       <div>
                           <div class="firstTitle item">账号信息审核未通过</div>
                           <div class="smallTitle item">请修改后重新提交</div>
                       </div>
                        <div class="help item">如有疑问，请拨打40000-77988咨询</div>
                    </div>
                    <div class="rejectReason">驳回原因：{{auth.register_message}}</div>

                </div>
                <div class="right">
                    <div class="imgBox">
                        <img v-if="auth.register_review_status===0" src="../../assets/img/checking.png" alt="" >
                        <img v-if="auth.register_review_status===-1" src="../../assets/img/checkFailed.png" alt="">
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card" v-if="auth.register_review_status===-1">
            <el-form label-position="right" label-width="120px" :model="form" ref="form" :rules="rules" >
                <el-form-item label="所属公司名称" prop="company_name" style="width:50%">
                    <el-input v-model="form.company_name"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contact_name" style="width:50%">
                    <el-input v-model="form.contact_name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="mobile" style="width:50%">
                    <span class="getCodeSpan getCodeBtn"  @click="sendCode" v-if="!showCountDown">获取验证码</span>
                    <span class="getCodeSpan countDown" v-if="showCountDown">{{second}}s后重新获取</span>
                    <el-input v-model="form.mobile"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="验证码" prop="code">
                            <el-input v-model="form.code"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item size="large" class="rightBtnGroup">
                            <el-button type="primary" @click="submitForm('form')" class="editBtn largeBtn">修改</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "CoopList",
        data(){
            var mobileRule = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入手机号码'));
                } else if (!(/^1[3456789]\d{9}$/.test(value))) {
                    callback(new Error('手机号码格式不正确'));
                }
            };
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请填写手机号码'))
                } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
                    return callback(new Error('请填写正确的手机号码'))
                }else{
                    callback()
                }
            };
            return {
                showCountDown:'',
                bread:{
                    level:1,
                    firstPath:{path: '/indexShow'},
                    thirdPath:{path: '/adManCus'},
                },
                auth:{},
                token:'',
                second:'60',
                rejectReason:'信息不合格',
                checkImgUrl:'',
                form:{
                    company_name:'',
                    contact_name:'',
                    mobile:'',
                    code:'',
                    token:''
                },
                rules:{
                    company_name: [
                        { required: true, message: '请输入公司名称', trigger: 'blur' }
                    ],
                    contact_name: [
                        { required: true, message: '请输入联系人', trigger: 'blur' }
                    ],
                    mobile: [
                        {validator: checkPhone,required:true, trigger: 'blur' }
                    ],
                    code: [
                        { required: true, message: '请输入验证码', trigger: 'blur' }
                    ],
                },
            }
        },
        methods:{
            configMeth(){

            },
            edit(){
                this.$router.push({path:'/register',query:{id:'id',type:'edit'}})
            },
            getMenuList(){
                this.$http.get(this.global.getmenulist,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var authData=res.data.auth;
                        this.auth=authData;
                        console.log(this.auth);
                        if(authData.register_review_status===1){  //资质审核通过
                            this.$router.push({path:'/indexShow'});
                            sessionStorage.setItem('auth',JSON.stringify(authData));
                        }else if(authData.register_review_status===-1){
                            this.getDetail();
                        }
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                }).catch(()=>{
                })
            },
            sendCode(){      //部分表单验证
                var flag=true;
                this.$refs['form'].validateField('mobile',err => {
                    if(err){
                        flag=false;
                    }
                });
                flag ? this.sendCodeMeth() : '';
            },
            sendCodeMeth(){
                var param=qs.stringify({mobile:this.form.mobile,event:'register'});
                this.$http.post(this.global.sendNoValidate,param).then(res=>{
                    if(res.data.code===1){
                        this.showCountDown=true;
                        if(res.data.code===0){
                            this.$message(res.data.msg);
                        }
                        var interval = setInterval(() => {
                            --this.second
                        }, 1000);
                        setTimeout(() => {
                            clearInterval(interval);
                            this.showCountDown=false;
                            this.second = 60;
                        }, 60000);
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                })
            },
            submitFormTest(formName) {
                var flag=true;
                this.$refs[formName].validate((valid) => {
                    flag=false;
                });
                if(flag){
                    this.$confirm('确认修改?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.form.token=this.token;
                        var param=qs.stringify(this.form);
                        this.editMeth(param);
                    }).catch(() => {

                    });
                }
            },
            submitForm(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('确认修改?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.form.token=this.token;
                            var param=qs.stringify(this.form);
                            this.editMeth(param);
                        }).catch(() => {

                        });

                    } else {
                        return false;
                    }
                });
            },
            editMeth(param){
                this.$http.post(this.global.editRegisterData,param).then(res=>{
                    if(res.data.code==='Captcha is incorrect'){
                        this.$message.error('验证码错误');
                    }else if(res.data.code===100002){
                        this.$message.error(res.data.msg);
                    }else if(res.data.code===100000){
                        this.$message.success('修改成功');
                        this.auth.register_review_status=0;
                    }
                });
            },
            getDetail(){
                this.showLoading=true;
                this.$http.get(this.global.getregisterdata,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.form=res.data.data;
                        this.showLoading=false;
                    }
                });
            },
        },

        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getMenuList();
            this.auth=JSON.parse(sessionStorage.getItem('auth'));
        }
    }
</script>

<style scoped lang="scss">

    .el-col .el-select{
        width:100%;
    }

    .rightBtn{
        /*margin-left:auto;*/
    }
    .firstTitle{
        font-size:20px;
        font-weight:600;
        letter-spacing:1px;
        margin-bottom:15px;
    }
    .smallTitle{
        font-size:14px;
    }

    .help{
        color:#b6b6b6;
        font-size:14px;
    }
    .editText{
        color:#3d98f2;
        font-size:16px;
        cursor:pointer;
        margin-left:30px;
    }
    .rejectReason{
        margin-bottom:30px;
        font-size:14px;
        color:#ca1700;
    }
    .outBox{
        display:flex;
        justify-content: space-between;
    }
    .left{
        display:flex;
        /*flex-direction: column;*/
        /*justify-content: space-between;*/
    }
    .right{
        display:flex;
        align-items: center;
    }
    .imgBox{
        /*width:auto;*/
    }
    .backBox{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .getCodeSpan{
        cursor:pointer;
    }
    .getCodeBtn{
        position:absolute;
        right:0;
        bottom:0;
        z-index:99;
        padding:0 10px;
        color:#559bfc
    }
    .countDown{
        position:absolute;
        right:0;
        bottom:0;
        z-index:99;
        padding:0 10px;
        color:#999;
    }
    .editBtn{
        margin-left:auto!important;
    }
</style>
