<template>
  <div class="container">
    <div class="fullBg">
      <div class="left">
        <div class="title">
          <div class="titleImg">
            <img src="../assets/img/login/logo.png" alt="">
            <!-- <img  v-if='!isSsp' src="../assets/img/login/wanyuelogo.png" alt=""> -->
          </div>
          <span class="titleText">合作伙伴管理平台</span>
        </div>
        <div style="" class="imgOutBox">
          <div class="imgBox">
            <img src="../assets/img/illustration.png" alt="">
          </div>
        </div>
      </div>
      <!--<form :model="loginForm" class="loginForm" :rules="rules">-->
      <div class="right">
        <div style="font-size: 30px;font-weight: 600;text-align: center;margin-bottom: 30px;">公告</div>
        <div style="font-size: 18px;font-weight: 600;text-align: left;margin-bottom: 30px;line-height:22px">&emsp;&emsp;尊敬的用户，合作伙伴管理平台全面升级为SaaS云平台，为您提供更丰富的产品功能，欢迎使用体验~</div>
        <div style="font-size: 14px;color:#666;text-align: left;line-height:18px">新平台登录账号与原登录手机号一致，初始密码为 a+手机号</div>
        <div style="font-size: 14px;color:#666;text-align: left;line-height:18px">示例：</div>
        <div style="font-size: 14px;color:#666;text-align: left;line-height:18px">原平台登录手机号为13900000000</div>
        <div style="font-size: 14px;color:#666;text-align: left;line-height:18px">SaaS云平台登录账号为13900000000</div>
        <div style="font-size: 14px;color:#666;text-align: left;line-height:18px">初始密码a13900000000</div>
        <div style="font-size: 14px;color:#666;text-align: left;line-height:18px;margin-top:30px">若账号及数据有任何问题，请及时与商务傅经理联系，联系电话15333424166，我们将第一时间为您处理，给您带来的不便敬请谅解。</div>
        <div class="formBtn">
          <el-button class="btnItem blueBtn" type="primary" @click="jumpTo">跳转云平台</el-button>
        </div>
      </div>
      <!--</form>-->
    </div>

  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "loginTWo",
  data: () => ({
    jumpurl: "",
  }),
  mounted() {
    console.log(window.location.hostname);
    if (window.location.hostname == "ssp.ctlife.tv") {
      this.jumpurl = "https://cloud.ctlife.tv/login";
    } else {
      this.jumpurl = "https://apaas-test.ctlife.tv/login";
    }
  },
  methods: {
    //    注册方法结束
    jumpTo() {
      window.open(this.jumpurl,'_blank')
      // window.location.href = this.jumpurl;
    },
  },
};
</script>

<style scoped>
/*@import "../style/basic.css";*/
@import "../assets/css/basic.css";
body,
html {
  width: 100%;
  height: 100%;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

h3 {
  font-size: 26px;
}
/*右边登录框开始*/
.input_item {
  height: 75px;
  margin-top: 15px;
  position: relative;
  box-sizing: border-box;
}
.el-input {
  font-size: 18px;
}
.input_item > input {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  letter-spacing: 1px;
  padding: 0 30px;
  box-sizing: border-box;
}
.del_icon {
  float: right;
  position: relative;

  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
}
.del_icon.active {
  opacity: 1;
  transition: 0.5s;
}
.send_code {
  /*float:right;*/
  height: 38px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 58px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 20px;
}
.send_code.countDown {
  color: #999;
}
.send_code.gray {
  color: #808080;
}

.btnItem {
  width: 45%;
  height: 60px;
  /*background:#169688;*/
  /*color:#fff;*/
  font-size: 18px;
}
.whiteBtn {
  color: #0066ff;
  border: 1px solid #0066ff;
}
.blueBtn {
  box-shadow: 0 5px 15px 0 rgba(0, 102, 255, 0.5);
  width: 100%;
}

/*错误提示*/
.error {
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: 999;
  color: red;
  font-size: 16px;
  transition: 0.5s;
  /*opacity:0;*/
}
.error.active {
  /*opacity:1;*/
  transition: 0.5s;
}

.inputBox {
  height: 70px !important;
}
input.el-input__inner {
  height: 70px !important;
}
/deep/ .el-input__inner {
  height: 70px !important;
}
.el-button--primary {
  background: #0066ff !important;
}
.el-button--primary:hover {
  background: #0066ff !important;
  /*background:rgba(0, 102, 255);*/
}

/*全屏登录页面开始*/
.fullBg {
  background-image: url("../assets/img/login/bg.png");
  /*background-size:cover;*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-size:10% auto;   //调整大小*/
  /*background-position:bottom center;  //调整背景图片的位置*/
}
.title {
  display: flex;
  align-items: center;
  margin-top: 5%;
}
.titleImg {
  min-width: 100px;
  max-width: 150px;
  /* max-height: 65px; */
  margin-right: 20px;
}
.left {
  width: 660px;
  height: 100%;
  /*background:red;*/
  display: flex;
  flex-direction: column;
  /*margin-right:15%;*/
  margin-right: 200px;
  justify-content: center;
}
.imgOutBox {
  flex: 1;
  display: flex;
  align-items: center;
}
.imgBox {
  width: 100%;
  height: 450px;
}
img {
  margin-top: 5px;
  width: 100%;
  height: auto;
}

.titleText {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 1px;
  height: 30px;
  line-height: 30px;
  font-family: Microsoft YaHei;
}
.right {
  width: 400px;
  /*width:25%;*/
  box-shadow: 0 5px 24px 0 rgba(71, 137, 198, 0.29);
  border-radius: 20px;
  padding: 20px 50px 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}
/*.icon*/
.icon {
  font-size: 22px;
}
.icon.blue {
  color: #0066ff;
}
.icon.gray {
  color: #d7dee3;
}

.formTitle {
  font-size: 20px;
  color: #2d81ff;
  text-align: center;
}
.formBtn {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.phone_input {
  position: relative;
}
.loginTab {
  height: 100%;
}
.hideEl {
  visibility: hidden;
}
/*全屏登录页面结束*/

/*注册成功弹窗开始*/
.successTitle {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 30px 0;
}
.successText {
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
.toIndex {
  color: #66b1ff;
  margin-left: 5px;
  cursor: pointer;
}
.successImgBox {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  /*width:auto;*/
  position: absolute;
  top: -65px;
}
.successImgBox img {
  width: auto;
  height: 100%;
}
/*注册成功弹窗结束*/
</style>
