<template>
  <div v-loading="loading" class="allShowBox">
    <uploadSource ref="uploadSource" sourceType="pic" @upsuccess='upSuccess'></uploadSource>
    <no-data v-if="noData"><span slot="tips">暂不支持</span></no-data>
    <div class="btnBox" v-if="!noData">
      <el-button class="largeBtn" type="primary" v-if="showFunModel('发布审核')&&!notPage" @click="publishDialogMeth('publish','审核')">发布审核</el-button>
      <el-button class="largeBtn" type="primary" v-if="showFunModel('发布')&&!notPage" @click="publishDialogMeth('publish','发布')">发布</el-button>
      <el-button class="largeBtn" type="primary" v-if="!notPage" @click="publishListMeth('publish','发布')">发布列表</el-button>
    </div>
    <div style="margin: 0 auto" class="viiual" v-if="!noData">
      <el-tabs v-model="activeName" @tab-click="clickTab">
        <el-tab-pane label="开机视频" name="openvideo" v-if="json.open.position">
          <div class="visiualBox">
            <openVideoBox :isEdit='isEdit' v-if="json.open.positionJson.openvideo" :boxStyle='json.open.positionJson.openvideo' :value="json.open.openvideo" :historyData='history.open.openvideo' @canrelease='canRelease'></openVideoBox>
          </div>
        </el-tab-pane>
        <el-tab-pane label="欢迎页" name="welcome" v-if="json.welcome.position">
          <div class="visiualBox">
            <videoBox :isEdit='isEdit' v-if="json.welcome.positionJson.video" v-for="(item,v) in json.welcome.positionJson.video" :key="v+'wvideo'" :boxStyle='item' v-model="json.welcome.source.video[v]" @canrelease='canRelease' :historyData='history.welcome.source.video[v]' :zIndex="2"></videoBox>
            <picBox :isEdit='isEdit' v-if="json.welcome.positionJson.logo" :boxStyle='json.welcome.positionJson.logo' :value="json.welcome.wel_logo" :historyData='history.welcome.wel_logo' @canrelease='canRelease' :is_logo="true"></picBox>
            <picBox :isEdit='isEdit' v-if="json.welcome.positionJson.pic" v-for="(item,p) in json.welcome.positionJson.pic" :key="p+'wpic'" :boxStyle='item' v-model="json.welcome.source.pic[p]" @canrelease='canRelease' :historyData='history.welcome.source.pic[p]'></picBox>
            <textBox :isEdit='isEdit' v-if="json.welcome.positionJson.text" v-for="(item,t) in json.welcome.positionJson.text" :key="t+'wtext'" :boxStyle='item' v-model="json.welcome.source.text[t]" @canrelease='canRelease' :historyData='history.welcome.source.text[t]'></textBox>
            <weatherBox :isEdit='isEdit' v-if="json.welcome.positionJson.weather" v-for="(item,w) in json.welcome.positionJson.weather" :key="w+'wweather'" :boxStyle='item'></weatherBox>
            <textShow :isEdit='isEdit' v-if="json.welcome.positionJson.weltext" v-for="(item,wt) in json.welcome.positionJson.weltext" :key="wt+'wweltext'" :boxStyle='item'></textShow>
          </div>
        </el-tab-pane>

        <el-tab-pane label="功能模块" name="moulde">
          <div class="visiualBox" v-if="activeName=='moulde'" >
            <picBox v-if="json.menu.public.positionJson.main_bg" :isEdit='isEdit' :boxStyle='json.menu.public.positionJson.main_bg' v-model="json.menu.public.main_background" @canrelease='canRelease' :historyData='history.menu.public.main_background' :zIndex="0"></picBox>
            <videoBox v-if="json.menu.public.positionJson.menu_video" :isEdit='isEdit' :boxStyle='json.menu.public.positionJson.menu_video' v-model="json.menu.public.menu_video[0]" @canrelease='canRelease' :historyData='history.menu.public.menu_video[0]' :zIndex="0" :showMore='true' @opentabinfo='openTabInfo'></videoBox>
            <picBox :isEdit='isEdit' v-if="json.menu.public.positionJson.pic" v-for="(item,pp) in json.menu.public.positionJson.pic" :key="pp+'pic'" :boxStyle='item' v-model="json.menu.public.logo" @canrelease='canRelease' :historyData='history.menu.public.logo' :is_logo="true"></picBox>
            <menuBox :isEdit='isEdit' :ref="'menubox'+m" :activeChange="activeChange" v-if="json.menu.public.positionJson.menu" v-for="(item,m) in json.menu.public.positionJson.menu" :key="m+'menuBox'" :boxStyle='item' v-model="json.menu.menu_list" :publicSource="json.menu.public" @clickitem='clickitem' @changehistory="changehistory" @canrelease='canRelease' :notPage='notPage' :activeId='mouldeShow' @changemenupic='openChangeMenuPic'>
            </menuBox>
            <weatherBox :isEdit='isEdit' v-if="json.menu.public.positionJson.weather" v-for="(item,w) in json.menu.public.positionJson.weather" :key="w+'weather'" :boxStyle='item'></weatherBox>
            <wifiBox :isEdit='isEdit' v-if="json.menu.public.positionJson.wifiBox" v-for="(item,wf) in json.menu.public.positionJson.wifiBox" :key="wf+'wifiBox'" @canrelease='canRelease' :boxStyle='item' v-model="json.menu.public.wifi" :historyData='history.menu.public.wifi'></wifiBox>

            <template v-for="(menu,mi) in json.menu.menu_list">
              <div :key="mi+'menu'" v-if="mouldeShow == menu.menu_id">
                <videoBox :isEdit='isEdit' v-if="menu.positionJson.video&&menu.source.video[v]" v-for="(item,v) in menu.positionJson.video" :key="v+'video'" :boxStyle='item' v-model="menu.source.video[v]" :historyData='history.menu.menu_list[mi].source.video[v]' @canrelease='canRelease'></videoBox>
                <picListBox :isEdit='isEdit' @canrelease='canRelease' v-if="menu.positionJson.picList" v-for="(item,p) in menu.positionJson.picList" :key="p+'picList'" :boxStyle='item' v-model="menu.source.picList[p]" :historyData='history.menu.menu_list[mi].source.picList[p]'></picListBox>
                <hierarchyPicBox :isEdit='isEdit' v-if="menu.positionJson.hierarchyPic&&menu.source.hierarchyPic[hp]" v-for="(item,hp) in menu.positionJson.hierarchyPic" :key="hp+'hierarchyPic'" :boxStyle='item' v-model="menu.source.hierarchyPic[hp]" @canrelease='canRelease' :historyData='history.menu.menu_list[mi].source.hierarchyPic[hp]'></hierarchyPicBox>
                <hierarchyVideoBox :isEdit='isEdit' v-if="menu.positionJson.hierarchyVideo&&menu.source.hierarchyVideo[hp]" v-for="(item,hp) in menu.positionJson.hierarchyVideo" :key="hp+'hierarchyVideo'" :boxStyle='item' v-model="menu.source.hierarchyVideo[hp]" @canrelease='canRelease' :historyData='history.menu.menu_list[mi].source.hierarchyVideo[hp]'></hierarchyVideoBox>

                <picBox :isEdit='isEdit' v-if="menu.positionJson.pic" v-for="(item,p) in menu.positionJson.pic" :key="p+'pic'" :boxStyle='item' v-model="menu.source.pic[p]" @canrelease='canRelease' :historyData='history.menu.menu_list[mi].source.pic[p]'></picBox>
                <textBox :isEdit='isEdit' v-if="menu.positionJson.text&&menu.source.text[t]" v-for="(item,t) in menu.positionJson.text" :key="t+'text'" @canrelease='canRelease' :boxStyle='item' v-model="menu.source.text[t]"></textBox>
                <picMore :isEdit='isEdit' @canrelease='canRelease' v-if="menu.positionJson.picMore" v-for="(item,c) in menu.positionJson.picMore" :key="c+'picMore'" :boxStyle='item' v-model="menu.source.double_pic[c]" :historyData='history.menu.menu_list[mi].source.double_pic[c]' :showIndex='0'></picMore>
                <textShow :isEdit='isEdit' v-if="menu.positionJson.weltext" v-for="(item,wt) in menu.positionJson.weltext" :key="wt+'weltext'" :boxStyle='item'></textShow>
              </div>
            </template>

          </div>
          <div style="margin-top:15px;color:#999">注：点击图标切换模块<span v-if="!notPage">，拖动图标排序模块</span></div>
        </el-tab-pane>
        <el-tab-pane label="跳转大图" name="jump_pic" v-if="json.jump_pic.position">
          <div class="visiualBox">
            <picBox :isEdit='isEdit' @canrelease='canRelease' v-if="json.jump_pic.positionJson.pic" v-for="(item,p) in json.jump_pic.positionJson.pic" :key="p+'jpic'" :boxStyle='item' v-model="json.jump_pic.source.pic[p]" :historyData='history.jump_pic.source.pic[p]'></picBox>

          </div>
        </el-tab-pane>
        <el-tab-pane label="屏保" name="screen_pic" v-if="json.screen_pic&&json.screen_pic.position">
          <div class="visiualBox">
            <picBox :isEdit='isEdit' @canrelease='canRelease' v-if="json.screen_pic.positionJson.pic" v-for="(item,p) in json.screen_pic.positionJson.pic" :key="p+'jpic'" :boxStyle='item' v-model="json.screen_pic.source.pic[p]" :historyData='history.screen_pic.source.pic[p]'></picBox>

          </div>
        </el-tab-pane>
      </el-tabs>

      <template v-for="(item,menuIndex) in json.menu.menu_list">
        <div :key="menuIndex" v-if="mouldeShow == item.menu_id" v-show="activeName=='moulde'">
          <menuItemForm :isEdit='isEdit' :formShow='formShow' v-show="!(item.menu_attr==73&&auth.type=='channel')" :notPage='notPage' v-model="json.menu.menu_list[menuIndex]" :historyData='history.menu.menu_list[menuIndex]' @savedata="menuChange" @canrelease='canRelease'></menuItemForm>
          <div v-show="item.menu_attr==73&&auth.type=='channel'" style=" width: 300px;margin-left: 15px; padding:15px;border: 1px solid #ddd;margin-top: 40px;height: 628px;">
            <no-data><span slot="tips">暂无配置内容</span></no-data>
          </div>
        </div>
      </template>
      <div v-show="activeName!='moulde'" style=" width: 300px;margin-left: 15px; padding:15px;border: 1px solid #ddd;margin-top: 40px;height: 628px;">
        <no-data><span slot="tips">暂无配置内容</span></no-data>
      </div>
    </div>

    <choice-room-dialog v-if="!notPage" ref="choiceRoomDialog" :hotelContract="itemData.hotel_contract" :id='itemData.id' :database='itemData.hotelName' @confirmMeth="confirmMeth">
    </choice-room-dialog>
    <choose-room v-if="!notPage"></choose-room>
    <releaseList ref="publishList" v-if="!notPage" :database="itemData.hotelName" :ipaddr="itemData.ipaddr" :hotelContract="itemData.hotel_contract"></releaseList>

    <el-dialog title="导航栏编辑" class="info" :visible.sync="menuVisible" width="40%" :append-to-body="true" :before-close='cannel'>
      <el-form ref="form" :model="menuForm" :rules="rules" class="formBox">
        <el-form-item label="导航背景" prop='bgPic'>
          <div class="showPicBox" @click="changePic('H')">
            <i v-if="!menuForm.bgPic" class="el-icon-plus" style="font-size:30px"></i>
            <img v-if="menuForm.bgPic" :src="menuForm.bgPic" alt="">
            <div class="btn" v-if="menuForm.bgPic">
              <i class="el-icon-delete" v-if="!isEdit" @click.stop="menuForm.bgPic=''"></i>
              <!-- <i class="el-icon-refresh" @click.stop="refresh('H')"></i> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item label="导航焦点" prop='pointPic'>
          <div class="showPicBox" @click="changePic('V')">
            <i v-if="!menuForm.pointPic" class="el-icon-plus" style="font-size:30px"></i>
            <img v-if="menuForm.pointPic" :src="menuForm.pointPic" alt="">
            <div class="btn" v-if="menuForm.pointPic">
              <i class="el-icon-delete" v-if="!isEdit" @click.stop="menuForm.pointPic=''"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="焦点文字颜色" prop='fColor'>
          <el-color-picker :disabled="isEdit" v-model="menuForm.fColor" @change="fColorChange"></el-color-picker>

        </el-form-item>
        <el-form-item label="默认文字颜色" prop='dColor'>
          <el-color-picker :disabled="isEdit" v-model="menuForm.dColor" @change="dColorChange"></el-color-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button class="largeBtn" type="primary" @click="save('form')">保 存</el-button>
        <el-button class="cancelBtn" @click="cannel">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑子级素材内容" class="info" :visible.sync="moreVisible" width="1150px" :append-to-body="true">
      <div class='showMoreBox'>
        <template v-if="moreSourceType==2">
          <hierarchyPicBox style="margin:5px" v-for="(item,hp) in moreSource" :key="hp+'hierarchyVideo'" :boxStyle='{  name: "hierarchyVideo3",
          notOpt: false,canUploadMore:true,positioning:"relative",position: { width: 210, height: 180}}' v-model="moreSource[hp]" :isEdit='isEdit' @canrelease='canRelease'></hierarchyPicBox>
        </template>
        <template v-if="moreSourceType==1">
          <videoBox style="margin:5px" :isEdit='isEdit' v-for="(item,v) in moreSource" :key="v+'video'" :boxStyle='{ name: v+"video",
positioning:"relative",position: { width: 210, height: 180}}' v-model="moreSource[v]" @canrelease='canRelease'></videoBox>
        </template>

      </div>
      <span slot="footer" class="dialog-footer">

        <el-button @click="moreVisible=false;moreSource=[]; moreSourceType=''">取 消</el-button>
        <el-button type="primary" @click="saveTabTrue">确 认</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import videoBox from "./commonents/videoBox.vue";
import wifiBox from "./commonents/wifiBox.vue";
import picBox from "./commonents/picBox.vue";
import hierarchyPicBox from "./commonents/hierarchyPicBox.vue";
import hierarchyVideoBox from "./commonents/hierarchyVideoBox.vue";
import textBox from "./commonents/textBox.vue";
import weatherBox from "./commonents/weatherBox.vue";
import menuBox from "./commonents/menuBox.vue";
import picMore from "./commonents/picMore.vue";
import textShow from "./commonents/textShow.vue";
import menuItemForm from "./commonents/menuItemForm.vue";
import releaseList from "./common/releaseList.vue";
import openVideoBox from "./commonents/openVideoBox.vue";
import picListBox from "./commonents/picListBox.vue";
import { getLauncher, setLauncher, setLauncherPro } from "@/api/visual.js";
import uploadSource from "./common/uploadSource.vue";
import {
  getTemplateList,
  getTemplateVersion,
} from "../../../operation/batchUpdata/api";
export default {
  components: {
    videoBox,
    picBox,
    textBox,
    weatherBox,
    menuBox,
    picMore,
    textShow,
    menuItemForm,
    releaseList,
    wifiBox,
    hierarchyPicBox,
    openVideoBox,
    picListBox,
    hierarchyVideoBox,
    uploadSource,
  },
  props: {
    notPage: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    formShow: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.itemData = JSON.parse(sessionStorage.getItem("hoteliitem"));
    this.funArr = sessionStorage.getItem("funArr");

    if (this.funArr) {
      this.funArr.split(",");
    } else {
      this.funArr = [];
    }
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    console.log(445);
    window.addEventListener(
      "resize",
      this.debounce(this.watchClientWidth, 500)
    );
    if (!this.notPage) {
      this.getPageData();
    }
    let s2 = document.body.clientWidth;

    if (Number(s2) < 1700 && Number(s2) > 1400) {
      let box = document.getElementsByClassName("allShowBox");
      box[0].style.transformOrigin = "left top";
      box[0].style.transform = "scale(0.8) ";
    }
    if (Number(s2) < 1400) {
      let box = document.getElementsByClassName("allShowBox");
      box[0].style.transformOrigin = "left top";
      box[0].style.transform = "scale(0.6) translate(0px,-200px)";
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debounce);
  },
  watch: {
    json: {
      handler: function (newVal) {
        console.log(newVal, "数据变动监听");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openTabInfo() {
      let list = this.json.menu.menu_list.filter((i) => {
        if (i.menu_id == this.mouldeShow) return i;
      });

      if (list[0].menu_attr == 35) {
        this.$message.warning("无更多编辑内容");
      } else {
        if (list[0].source.video) {
          this.moreSource = JSON.parse(JSON.stringify(list[0].source.video));
          this.moreSourceType = 1;
        } else {
          this.moreSource = JSON.parse(
            JSON.stringify(list[0].source.hierarchyPic)
          );
          this.moreSourceType = 2;
        }
        this.moreVisible = true;
      }
      console.log(this.moreSource);
    },
    saveTabTrue() {
      this.json.menu.menu_list.forEach((i) => {
        if (i.menu_id == this.mouldeShow) {
          if (this.moreSourceType == 2) {
            i.source.hierarchyPic = JSON.parse(JSON.stringify(this.moreSource));
          } else {
            i.source.video = JSON.parse(JSON.stringify(this.moreSource));
          }
          this.moreVisible = false;
          this.canRelease();
        }
      });
    },
    save() {
      this.$refs["form"].validate((valid, obj) => {
        if (valid) {
          this.json.menu.public.menu_background.source_url =
            this.menuForm.bgPic;
          this.json.menu.public.menu_background.update = this.menuForm.bgupdate;
          this.json.menu.public.menu_background.source_md5 =
            this.menuForm.bgMd5;
          this.json.menu.public.menu_point.source_url = this.menuForm.pointPic;
          this.json.menu.public.menu_point.source_md5 = this.menuForm.pointMd5;
          this.json.menu.public.menu_point.update = this.menuForm.pointupdate;
          this.json.menu.public.menu_focal_color.x = this.menuForm.fColor;
          this.json.menu.public.menu_focal_color.update =
            this.menuForm.fColorUpdate;
          this.json.menu.public.menu_text_color.x = this.menuForm.dColor;
          this.json.menu.public.menu_text_color.update =
            this.menuForm.dColorUpdate;
          this.canReleaseFlag = true;
          this.menuVisible = false;
        } else {
          this.$message.warning("请将数据补充完整");
        }
      });
    },
    fColorChange() {
      this.menuForm.fColorUpdate = true;
    },
    dColorChange() {
      this.menuForm.dColorUpdate = true;
    },
    upSuccess(data, hv) {
      if (hv == "H") {
        this.menuForm.bgMd5 = data.md5;
        this.menuForm.bgPic = data.url;
        this.menuForm.bgupdate = true;
      } else {
        this.menuForm.pointMd5 = data.md5;
        this.menuForm.pointPic = data.url;
        this.menuForm.pointupdate = true;
      }
    },
    changePic(type) {
      if (type == "H" && !this.menuForm.bgPic) {
        this.$refs.uploadSource.dialogVisible = true;
        this.$refs.uploadSource.HV = "H";
      } else if (type == "V" && !this.menuForm.pointPic) {
        this.$refs.uploadSource.dialogVisible = true;
        this.$refs.uploadSource.HV = "V";
      }
    },
    cannel() {
      this.menuForm.bgPic = this.json.menu.public.menu_background.source_url;
      this.menuForm.pointPic = this.json.menu.public.menu_point.source_url;
      this.menuForm.fColor = this.json.menu.public.menu_focal_color.x;
      this.menuForm.dColor = this.json.menu.public.menu_text_color.x;
      this.menuForm.pointupdate = false;
      this.menuForm.bgupdate = false;
      this.menuForm.dColorUpdate = false;
      this.menuForm.FColorUpdate = false;
      this.menuVisible = false;
    },
    openChangeMenuPic() {
      this.menuVisible = true;
    },
    init(data) {
      this.json = JSON.parse(JSON.stringify(data));
      this.history = JSON.parse(JSON.stringify(data));
      // this.json = JSON.parse(JSON.stringify(data));
      // this.history = JSON.parse(JSON.stringify(data));
      this.$set(
        this.json.menu.public,
        "positionJson",
        JSON.parse(this.json.menu.public.position)
      );
      this.menuForm.bgPic = this.json.menu.public.menu_background.source_url;
      this.menuForm.pointPic = this.json.menu.public.menu_point.source_url;
      this.menuForm.fColor = this.json.menu.public.menu_focal_color.x;
      this.menuForm.dColor = this.json.menu.public.menu_text_color.x;

      console.log(1);
      if (this.json.welcome.position) {
        this.$set(
          this.json.welcome,
          "positionJson",
          JSON.parse(this.json.welcome.position)
        );
      } else {
        this.activeName = "moulde";
      }
      console.log(2);
      if (this.json.open.position) {
        this.$set(
          this.json.open,
          "positionJson",
          JSON.parse(this.json.open.position)
        );
      }
      console.log(3);
      if (this.json.jump_pic.position) {
        this.$set(
          this.json.jump_pic,
          "positionJson",
          JSON.parse(this.json.jump_pic.position)
        );
      }
      if (this.json.screen_pic.position) {
        this.$set(
          this.json.screen_pic,
          "positionJson",
          JSON.parse(this.json.screen_pic.position)
        );
      }

      console.log(4);
      this.mouldeShow = this.json.menu.menu_list[0].menu_id;
      // this.$refs.menubox[0].active = this.mouldeShow;
      console.log(1111, 222);
      this.json.menu.menu_list = this.json.menu.menu_list.map(
        (element, index) => {
          element.positionJson = JSON.parse(element.position);
          console.log(this.json.menu.menu_list, index);
          return element;
        }
      );

      this.activeChange = !this.activeChange;
      this.noData = false;
      this.loading = false;
      this.showPage = true;
    },
    canRelease() {
      this.canReleaseFlag = true;
    },
    debounce(fn, delay = 500) {
      // timer 是在闭包中的
      let timer = null;
      return function () {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn.apply(this, arguments);
          timer = null;
        }, delay);
      };
    },
    watchClientWidth() {
      console.log(111);
      let width = document.body.clientWidth;
      if (Number(width) > 1700) {
        let box = document.getElementsByClassName("allShowBox");
        box[0].style.transformOrigin = "left top";
        box[0].style.transform = "scale(1)";
      }
      if (Number(width) < 1700 && Number(width) > 1400) {
        let box = document.getElementsByClassName("allShowBox");
        box[0].style.transformOrigin = "left top";
        box[0].style.transform = "scale(0.8) ";
      }
      if (Number(width) < 1400) {
        let box = document.getElementsByClassName("allShowBox");
        box[0].style.transformOrigin = "left top";
        box[0].style.transform = "scale(0.7)";
      }
    },
    getPageData(template_id) {
      this.loading = true;
      this.noData = true;

      getLauncher({
        database:
          this.itemData && this.itemData.hotelName
            ? this.itemData.hotelName
            : "",
        template_id: template_id ? template_id : "",
      })
        .then((res) => {
          console.log(999);
          if (res.data.code == 100000 && res.data.data) {
            let data = {
              menu: {
                public: {
                  position:
                    '{"pic":[{"name":"logo","position":{"width":300,"height":50,"top":30,"left":42}}],"main_bg":{"name":"main_bg","position":{"width":1152,"height":648,"top":0,"left":0}},"weather":[{"width":360,"height":40,"top":31.8,"right":7,"color":"#FFF","type":"5"}],"wifiBox":[{"width":400,"height":40,"top":31,"left":380,"color":"#fff","type":1}],"menu":[{"width":1152,"height":102,"bottom":0,"left":0,"itemWidth":212,"itemHeight":102,"background":null,"justifyContent": "space-between","nameShowType": 1}]}',
                  main_background: {
                    content: "主背景",
                    lid: "468",
                    ltype: "1",
                    mid: "126",
                    mname: "主背景",
                    source_id: "184",
                    source_md5: "711b012718f7c23047e13b7e1316cb31",
                    source_sort: "0",
                    source_type: "1",
                    source_url:
                      "https://source.ctlife.tv/711b012718f7c23047e13b7e1316cb31.png",
                    type: "1",
                  },
                  logo: {
                    content: "logo",
                    lid: "467",
                    ltype: "1",
                    mid: "125",
                    mname: "logo",
                    source_id: "183",
                    source_md5: "09d1f0a940e68d5ab81eb34ecc79fc03",
                    source_sort: "0",
                    source_type: "1",
                    source_url:
                      "https://source.ctlife.tv/09d1f0a940e68d5ab81eb34ecc79fc03.png",
                    type: "1",
                  },
                  menu_background: {
                    content: "导航栏底部背景",
                    lid: "2290",
                    ltype: "1",
                    mid: "594",
                    mname: "导航栏底部背景",
                    source_id: "1713",
                    source_md5: "874d760e392998647b1b3ff4ee6bc686",
                    source_sort: "20201117",
                    source_type: "1",
                    source_url:
                      "https://source.ctlife.tv/874d760e392998647b1b3ff4ee6bc686.png",
                    type: "1",
                  },
                  wifi: {
                    id: "156",
                    open: "1",
                    addRoom: "0",
                    SSID: "Atour",
                    password: "4000606606",
                    SSIDIcon: {
                      content: "wifi标志",
                      lid: "3339",
                      ltype: "1",
                      mid: "729",
                      mname: "wifi标志",
                      source_id: "2409",
                      source_md5: "f6add3e54cb3a660fbf02092f65d547a",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/f6add3e54cb3a660fbf02092f65d547a.png",
                      type: "1",
                    },
                    passwordIcon: {
                      content: "密码标志",
                      lid: "3343",
                      ltype: "1",
                      mid: "730",
                      mname: "密码标志",
                      source_id: "2410",
                      source_md5: "4148006814dd6aca9d3b2ce84ee20806",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/4148006814dd6aca9d3b2ce84ee20806.png",
                      type: "1",
                    },
                  },
                  menu_point: {
                    content: "导航栏焦点",
                    lid: "2238",
                    ltype: "1",
                    mid: "581",
                    mname: "导航栏焦点",
                    source_id: "1630",
                    source_md5: "03f23b4b7108fed5d92cab559d56d061",
                    source_sort: "0",
                    source_type: "1",
                    source_url:
                      "https://source.ctlife.tv/20220729110622180_0.png",
                    type: "1",
                  },
                  menu_text_color: {
                    id: "114",
                    name: "导航栏文字颜色",
                    x: "#d3cfce",
                    y: "",
                  },
                  menu_focal_color: {
                    id: "115",
                    name: "导航栏文字选中颜色",
                    x: "#ffffff",
                    y: "",
                  },
                  menu_video: null,
                },
                menu_list: [
                  {
                    menu_id: "3",
                    menu_attr: "82",
                    menu_hide: 1,
                    menuName: "主页",
                    menu_bljlm: "0",
                    menu_morder: "1",
                    menu_name: {
                      cid: "0",
                      content: "主页",
                      id: "9",
                      intro: "0",
                      mid: "3",
                      mname: "精彩推荐",
                      morder: "1",
                      type: "1",
                    },
                    menu_enname: {
                      cid: "0",
                      content: "Home",
                      id: "10",
                      intro: "0",
                      mid: "3",
                      mname: "精彩推荐",
                      morder: "1",
                      type: "2",
                    },
                    focal_point: {
                      content: "焦点图标一",
                      lid: "137",
                      ltype: "1",
                      mid: "43",
                      mname: "焦点图标一",
                      source_id: "1723",
                      source_md5: "a381459e33ba6803c13ed50c4ad26d4b",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150210766_0.png",
                      type: "1",
                    },
                    default_point: {
                      content: "默认图标一",
                      lid: "141",
                      ltype: "1",
                      mid: "44",
                      mname: "默认图标一",
                      source_id: "1724",
                      source_md5: "80b1ea0138fc75cf28cabff414ab65d7",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150231301_0.png",
                      type: "1",
                    },
                    position:
                      '{"title":"丽呈主页","pic":[{"name":"homepic0","sourceDesc":"600*450","position":{"width":360,"height":270,"top":90,"left":42}},{"name":"homepic1","sourceDesc":"390*250","position":{"width":234,"height":150,"top":378,"left":42}},{"name":"homepic2","sourceDesc":"390*250","position":{"width":234,"height":150,"top":378,"left":294}},{"name":"homepic3","sourceDesc":"390*250","position":{"width":234,"height":150,"top":378,"left":546}},{"name":"homepic4","sourceDesc":"520*730","position":{"width":312,"height":438,"top":90,"right":42}}],"video":[{"name":"homevideo1","position":{"width":360,"height":270,"top":90,"left":420}}]}',
                    source: {
                      pic: [
                        {
                          bljlm: "丽呈会",
                          content: "主页营销活动",
                          lid: "69",
                          ltype: "1",
                          mid: "18",
                          mname: "主页营销活动",
                          source_id: "1790",
                          source_md5: "0465613cd5f3f6902cc99a3f8558218d",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/0465613cd5f3f6902cc99a3f8558218d.png",
                          type: "1",
                        },
                        {
                          bljlm: "店内服务",
                          content: "跳转店内服务",
                          lid: "41",
                          ltype: "1",
                          mid: "11",
                          mname: "跳转店内服务",
                          source_id: "1778",
                          source_md5: "b94dfd8bb5fa54e0d78feb9ffafc71ac",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/b94dfd8bb5fa54e0d78feb9ffafc71ac.png",
                          type: "1",
                        },
                        {
                          bljlm: "一键投屏",
                          content: "跳转一键投屏",
                          lid: "49",
                          ltype: "1",
                          mid: "13",
                          mname: "跳转一键投屏",
                          source_id: "1779",
                          source_md5: "7c2c6fa3a9cb76ef0c4082ab3a370d91",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/7c2c6fa3a9cb76ef0c4082ab3a370d91.png",
                          type: "1",
                        },
                        {
                          bljlm: "丽呈会",
                          content: "跳转丽呈会",
                          lid: "2328",
                          ltype: "1",
                          mid: "605",
                          mname: "跳转丽呈会",
                          source_id: "1780",
                          source_md5: "f05ffb5117c6f5db614d7f077976e198",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/f05ffb5117c6f5db614d7f077976e198.png",
                          type: "1",
                        },
                        {
                          bljlm: "",
                          content: "pms丽呈会",
                          lid: "2332",
                          ltype: "1",
                          mid: "606",
                          mname: "pms丽呈会",
                          source_id: "1821",
                          source_md5: "5ae4d596a9102bbc51d60a0c2f051a3c",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/5ae4d596a9102bbc51d60a0c2f051a3c1694485912.png",
                          type: "1",
                        },
                      ],
                      video: [
                        {
                          mid: "279",
                          mname: "主页视频1-1",
                          source_id: "56",
                          source_md5: "5f3409dc957f3c2d0e191571df73d658",
                          source_name: "主页视频1-1_497",
                          source_sort: "0",
                          source_type: "2",
                          source_url:
                            "https://source.ctlife.tv/5f3409dc957f3c2d0e191571df73d658.mp4",
                        },
                      ],
                    },
                  },
                  {
                    menu_id: "661",
                    menu_attr: "83",
                    menu_hide: 1,
                    menuName: "亚朵商城",
                    menu_bljlm: "",
                    menu_morder: "2",
                    menu_name: {
                      cid: "0",
                      content: "亚朵商城",
                      id: "2604",
                      intro: "",
                      mid: "661",
                      mname: "亚朵商城",
                      morder: "2",
                      type: "1",
                    },
                    menu_enname: {
                      cid: "0",
                      content: "",
                      id: "2605",
                      intro: "",
                      mid: "661",
                      mname: "亚朵商城",
                      morder: "2",
                      type: "2",
                    },
                    focal_point: {
                      content: "焦点图标二",
                      lid: "145",
                      ltype: "1",
                      mid: "45",
                      mname: "焦点图标二",
                      source_id: "1725",
                      source_md5: "b57c6ce7b6ee105d354d6a5014bb10ef",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150258317_0.png",
                      type: "1",
                    },
                    default_point: {
                      content: "默认图标二",
                      lid: "169",
                      ltype: "1",
                      mid: "51",
                      mname: "默认图标二",
                      source_id: "1726",
                      source_md5: "24c0e77a39358ba04042cacd49cbce2b",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150317208_0.png",
                      type: "1",
                    },
                    position:
                      '{"title":"丽呈电视直播","pic":[{"name":"TV1","sourceDesc":"400*160","position":{"width":240,"height":96,"top":90,"left":42}},{"name":"TV2","sourceDesc":"400*160","position":{"width":240,"height":96,"top":204,"left":42}},{"name":"TV3","sourceDesc":"400*160","position":{"width":240,"height":96,"top":318,"left":42}},{"name":"TV4","sourceDesc":"400*160","position":{"width":240,"height":96,"top":432,"left":42}},{"name":"TV5","sourceDesc":"890*450","position":{"width":534,"height":270,"top":90,"left":300}},{"name":"TV6","sourceDesc":"430*450","position":{"width":258,"height":270,"top":90,"right":42}},{"name":"TV7","sourceDesc":"430*250","position":{"width":258,"height":150,"top":378,"left":300}},{"name":"TV8","sourceDesc":"430*250","position":{"width":258,"height":150,"top":378,"right":318}},{"name":"TV9","sourceDesc":"430*250","position":{"width":258,"height":150,"top":378,"right":42}}]}',
                    source: {
                      pic: [
                        {
                          bljlm: "com.htrip.movieplayer",
                          content: "电视剧",
                          lid: "2348",
                          ltype: "1",
                          mid: "610",
                          mname: "电视剧",
                          source_id: "1793",
                          source_md5: "7b1a54398c5b1c1a07dc75f914d7aa08",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/7b1a54398c5b1c1a07dc75f914d7aa08.png",
                          type: "1",
                        },
                        {
                          bljlm: "com.htrip.movieplayer",
                          content: "综艺",
                          lid: "2352",
                          ltype: "1",
                          mid: "611",
                          mname: "综艺",
                          source_id: "1794",
                          source_md5: "bb875184005146224e209897883cfa91",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/bb875184005146224e209897883cfa91.png",
                          type: "1",
                        },
                        {
                          bljlm: "com.htrip.movieplayer",
                          content: "纪录片",
                          lid: "2356",
                          ltype: "1",
                          mid: "612",
                          mname: "纪录片",
                          source_id: "1795",
                          source_md5: "be446dd9123ac10264b7082b4a26302a",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/be446dd9123ac10264b7082b4a26302a.png",
                          type: "1",
                        },
                        {
                          bljlm: "com.htrip.movieplayer",
                          content: "少儿",
                          lid: "2360",
                          ltype: "1",
                          mid: "613",
                          mname: "少儿",
                          source_id: "1796",
                          source_md5: "44ab3910414723b8f2bbefe63a85194a",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/44ab3910414723b8f2bbefe63a85194a.png",
                          type: "1",
                        },
                        {
                          bljlm: "0",
                          content: "电视营销图",
                          lid: "2266",
                          ltype: "1",
                          mid: "588",
                          mname: "电视营销图",
                          source_id: "1809",
                          source_md5: "f637acc1288957c1739264a8d334a94f",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/f637acc1288957c1739264a8d334a94f.png",
                          type: "1",
                        },
                        {
                          bljlm: "com.qy.kktv",
                          content: "OK键观看",
                          lid: "2336",
                          ltype: "1",
                          mid: "607",
                          mname: "OK键观看",
                          source_id: "1783",
                          source_md5: "fbbc0dd66762968c85ffc69a8a14021b",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/fbbc0dd66762968c85ffc69a8a14021b.png",
                          type: "1",
                        },
                        {
                          bljlm: "mzc002008v8rka3",
                          content: "宣传一部",
                          lid: "2368",
                          ltype: "1",
                          mid: "615",
                          mname: "宣传一部",
                          source_id: "1805",
                          source_md5: "00ac7bf282097c165d484e40ea7bce57",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/00ac7bf282097c165d484e40ea7bce57.png",
                          type: "1",
                        },
                        {
                          bljlm: "mzc002009ktefn0",
                          content: "宣传二部",
                          lid: "2372",
                          ltype: "1",
                          mid: "616",
                          mname: "宣传二部",
                          source_id: "1806",
                          source_md5: "1a9f9d359e98ab18b52f43e860916345",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/1a9f9d359e98ab18b52f43e860916345.png",
                          type: "1",
                        },
                        {
                          bljlm: "mzc002005sbknaw",
                          content: "宣传三部",
                          lid: "2376",
                          ltype: "1",
                          mid: "617",
                          mname: "宣传三部",
                          source_id: "1822",
                          source_md5: "2ce229e633565254a9e898dcefad36b0",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/2ce229e633565254a9e898dcefad36b01694506079.png",
                          type: "1",
                        },
                      ],
                    },
                  },
                  {
                    menu_id: "5",
                    menu_attr: "2",
                    menu_hide: 1,
                    menuName: "电视直播",
                    menu_bljlm: "com.travelink.remote",
                    menu_morder: "3",
                    menu_name: {
                      cid: "0",
                      content: "电视直播",
                      id: "17",
                      intro: "",
                      mid: "5",
                      mname: "电视直播",
                      morder: "3",
                      type: "1",
                    },
                    menu_enname: {
                      cid: "0",
                      content: "TV",
                      id: "18",
                      intro: "",
                      mid: "5",
                      mname: "电视直播",
                      morder: "3",
                      type: "2",
                    },
                    focal_point: {
                      content: "焦点图标三",
                      lid: "149",
                      ltype: "1",
                      mid: "46",
                      mname: "焦点图标3",
                      source_id: "1727",
                      source_md5: "934083410707f7f8f4fd1e864b76e6a7",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150337705_0.png",
                      type: "1",
                    },
                    default_point: {
                      content: "默认图标三",
                      lid: "173",
                      ltype: "1",
                      mid: "52",
                      mname: "默认图标3",
                      source_id: "1728",
                      source_md5: "33cd14dd3d34706319f001bc8a6e094e",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150403624_0.png",
                      type: "1",
                    },
                    source: {
                      pic: [
                        {
                          content: "电视左侧配置",
                          lid: "2557",
                          ltype: "1",
                          mid: "655",
                          mname: "电视左侧配置",
                          source_id: "2289",
                          source_md5: "343ba6dd75f2e12f0182bd479e258631",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/343ba6dd75f2e12f0182bd479e258631.jpg",
                          type: "1",
                        },
                      ],
                    },
                    apk: [],
                    position:
                      '{"title":"亚朵应用","pic":[{"name":"apk2","sourceDesc":"1780*730","nochange":false,"position":{"width":1068,"height":438,"top":90,"left":42}}]}',
                  },
                  {
                    menu_id: "597",
                    menu_attr: "2",
                    menu_hide: 1,
                    menuName: "手机投屏",
                    menu_bljlm: "com.htrip.lebo",
                    menu_morder: "4",
                    menu_name: {
                      cid: "0",
                      content: "手机投屏",
                      id: "2302",
                      intro: "",
                      mid: "597",
                      mname: "手机投屏",
                      morder: "4",
                      type: "1",
                    },
                    menu_enname: {
                      cid: "0",
                      content: "Screen",
                      id: "2303",
                      intro: "",
                      mid: "597",
                      mname: "手机投屏",
                      morder: "4",
                      type: "2",
                    },
                    focal_point: {
                      content: "焦点图标四",
                      lid: "153",
                      ltype: "1",
                      mid: "47",
                      mname: "焦点图标4",
                      source_id: "1729",
                      source_md5: "083fa5734c77fe224fd653300f7e2eeb",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150424473_0.png",
                      type: "1",
                    },
                    default_point: {
                      content: "默认图标四",
                      lid: "177",
                      ltype: "1",
                      mid: "53",
                      mname: "默认图标4",
                      source_id: "1730",
                      source_md5: "efc53cdd1ffa56871f6129f60e309365",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150443721_0.png",
                      type: "1",
                    },
                    source: {
                      pic: [
                        {
                          content: "投屏图片",
                          lid: "2446",
                          ltype: "1",
                          mid: "633",
                          mname: "投屏图片",
                          source_id: "2565",
                          source_md5: "c6158f50d0cc303f10ff6716c0cc6bbd",
                          source_sort: "0",
                          source_type: "1",
                          source_url:
                            "https://source.ctlife.tv/c6158f50d0cc303f10ff6716c0cc6bbd.png",
                          type: "1",
                        },
                      ],
                    },
                    apk: [],
                    position:
                      '{"title":"亚朵应用","pic":[{"name":"apk2","sourceDesc":"1780*730","nochange":true,"position":{"width":1068,"height":438,"top":90,"left":42}}]}',
                  },
                  {
                    menu_id: "598",
                    menu_attr: "2",
                    menu_hide: 1,
                    menuName: "手机投屏",
                    menu_bljlm: "com.htrip.lebo",
                    menu_morder: "4",
                    menu_name: {
                      cid: "0",
                      content: "手机投屏",
                      id: "2302",
                      intro: "",
                      mid: "597",
                      mname: "手机投屏",
                      morder: "4",
                      type: "1",
                    },
                    menu_enname: {
                      cid: "0",
                      content: "Screen",
                      id: "2303",
                      intro: "",
                      mid: "597",
                      mname: "手机投屏",
                      morder: "4",
                      type: "2",
                    },
                    focal_point: {
                      content: "焦点图标四",
                      lid: "153",
                      ltype: "1",
                      mid: "47",
                      mname: "焦点图标4",
                      source_id: "1729",
                      source_md5: "083fa5734c77fe224fd653300f7e2eeb",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150424473_0.png",
                      type: "1",
                    },
                    default_point: {
                      content: "默认图标四",
                      lid: "177",
                      ltype: "1",
                      mid: "53",
                      mname: "默认图标4",
                      source_id: "1730",
                      source_md5: "efc53cdd1ffa56871f6129f60e309365",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20220727150443721_0.png",
                      type: "1",
                    },
                    source: {
                      double_pic: [
                        {
                          name: [
                            {
                              cid: "0",
                              content: "酒店客房",
                              id: "81",
                              intro: "0",
                              mid: "21",
                              mname: "酒店客房",
                              morder: "1",
                              type: "1",
                            },
                          ],
                          horizontal_pic: [
                            {
                              bljlm: "0",
                              lid: "21",
                              ltype: "5",
                              mname: "酒店客房",
                              source_id: "1642",
                              source_md5: "1da0c8fd81e3cac2875279a68134d7e6",
                              source_sort: "0",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155658794_01.jpg",
                            },
                          ],
                          vertical_pic: [
                            {
                              bljlm: "0",
                              lid: "21",
                              ltype: "6",
                              mname: "酒店客房",
                              source_id: "1643",
                              source_md5: "0e12280445dd634a953a8fdab84d5f44",
                              source_sort: "0",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155702770_01.jpg",
                            },
                          ],
                          title_pic: [
                            {
                              content: "二级模块标题背景",
                              lid: "2254",
                              ltype: "1",
                              mid: "585",
                              mname: "二级模块标题背景",
                              source_id: "1634",
                              source_md5: "103d2235fb928b0970fb1de993984dae",
                              source_sort: "0",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403154223571_0.png",
                              type: "1",
                            },
                          ],
                          pic_list: [
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1866",
                              source_md5: "8b9bec1553371136337ffe1b7fbde05a",
                              source_sort: "111",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155743253_111.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1867",
                              source_md5: "1a32ca804d2a9c0f56d92f70a460a56f",
                              source_sort: "112",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155745570_112.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1868",
                              source_md5: "4767876f68c408abcb5fbd176d683fe8",
                              source_sort: "113",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155744198_113.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1869",
                              source_md5: "86501c9329ad172b3aec876c22864a7d",
                              source_sort: "114",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155746738_114.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1870",
                              source_md5: "82d966fbc73ead02e3034018aba19b2b",
                              source_sort: "115",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155744821_115.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1871",
                              source_md5: "f3e24a7055c2f5310f5f19d290690a41",
                              source_sort: "116",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155743946_116.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1872",
                              source_md5: "b2c9b7a0c73e448266f6b010dda5462b",
                              source_sort: "117",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155746199_117.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1873",
                              source_md5: "e3871d87b2168b8a746ec7c21c9ffcc3",
                              source_sort: "118",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155747609_118.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店客房大图",
                              lid: "633",
                              ltype: "1",
                              mid: "171",
                              mname: "酒店客房大图",
                              source_id: "1874",
                              source_md5: "786cc85456a2ab947f734403b40c86c7",
                              source_sort: "119",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155747781_119.jpg",
                              type: "1",
                            },
                          ],
                        },
                        {
                          name: [
                            {
                              cid: "0",
                              content: "酒店餐饮",
                              id: "77",
                              intro: "0",
                              mid: "20",
                              mname: "酒店餐饮",
                              morder: "2",
                              type: "1",
                            },
                          ],
                          horizontal_pic: [
                            {
                              bljlm: "0",
                              lid: "20",
                              ltype: "5",
                              mname: "酒店餐饮",
                              source_id: "1653",
                              source_md5: "f3fb044b1d5f0047418d232a2eb7dfd3",
                              source_sort: "0",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155931546_02.jpg",
                            },
                          ],
                          vertical_pic: [
                            {
                              bljlm: "0",
                              lid: "20",
                              ltype: "6",
                              mname: "酒店餐饮",
                              source_id: "1654",
                              source_md5: "508fedb707e652b4c76eff677b959dc2",
                              source_sort: "0",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403155934445_02.jpg",
                            },
                          ],
                          title_pic: [
                            {
                              content: "二级模块标题背景",
                              lid: "2254",
                              ltype: "1",
                              mid: "585",
                              mname: "二级模块标题背景",
                              source_id: "1634",
                              source_md5: "103d2235fb928b0970fb1de993984dae",
                              source_sort: "0",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403154223571_0.png",
                              type: "1",
                            },
                          ],
                          pic_list: [
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1875",
                              source_md5: "25ff1e9d0d852ac1e93e58f4ded6b42c",
                              source_sort: "121",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160005403_121.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1876",
                              source_md5: "d809b6c895a75a871e7f77a651a1f282",
                              source_sort: "122",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160010114_122.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1877",
                              source_md5: "a304d793ef297b872627be1962efc959",
                              source_sort: "123",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160001768_123.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1878",
                              source_md5: "e2c9e710d093d1566cbc3880874987f1",
                              source_sort: "124",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160002318_124.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1879",
                              source_md5: "3a097fa268327cfe72ba7910a5892d9e",
                              source_sort: "125",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160005595_125.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1880",
                              source_md5: "e1e906a81d4bc860df6b72eb0339562e",
                              source_sort: "126",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160006807_126.jpg",
                              type: "1",
                            },
                            {
                              content: "酒店餐饮大图",
                              lid: "629",
                              ltype: "1",
                              mid: "170",
                              mname: "酒店餐饮大图",
                              source_id: "1881",
                              source_md5: "2b0d86fcd63b1537897863fef2eb570d",
                              source_sort: "127",
                              source_type: "1",
                              source_url:
                                "http://source.ctlife.tv/20200403160005764_127.jpg",
                              type: "1",
                            },
                          ],
                        },
                      ],
                    },
                    apk: [],
                    position:
                      '{"title":"丽呈会版式","picMore":[{"name":"server1picMore1","sourceDesc":"570*730","notShowTitle":true,"position":{"width":342,"height":438,"top":90,"left":42}},{"name":"server1picMore2","sourceDesc":"1175*730","notShowTitle":true,"position":{"width":705,"height":438,"top":90,"right":42}}]}',
                  },
                ],
              },
              welcome: {
                name: "欢迎页",
                wel_logo: {
                  source_url: "",
                },
                source: {
                  pic: [
                    {
                      source_url:
                        "http://source.ctlife.tv/20200403161100890_0.png",
                    },
                    {
                      source_url:
                        "http://source.ctlife.tv/20200403161137861_0.jpg",
                    },
                    {
                      source_url: "",
                    },
                  ],
                },
                position: null,
              },
              jump_pic: {
                position:
                  '{"title":"跳转大图","pic":[{"name":"jump1","sourceDesc":"1920*1080","position":{"width":1152,"height":648,"top":0,"left":0}}]}',
                source: {
                  pic: [
                    {
                      content: "默认跳转大图广告",
                      lid: "1642",
                      ltype: "1",
                      mid: "432",
                      mname: "默认跳转大图广告",
                      source_id: "2413",
                      source_md5: "c1a85e1723ace09871eca88dbe60a312",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/20230309150651982_0.jpg",
                      type: "1",
                    },
                  ],
                },
              },
              open: {
                position:
                  '{"openvideo":{"name":"openvideo1","position":{"width":1152,"height":648,"top":0,"left":0}}}',
                openvideo: {
                  play: "1",
                  source_id: "124",
                  source_md5: "f03bbacb7c1c782b35fc4684d353bc33",
                  source_sort: "0",
                  source_type: 2,
                  source_url:
                    "https://source.ctlife.tv/f03bbacb7c1c782b35fc4684d353bc33.mp4",
                },
              },
              screen_pic: {
                position:
                  '{"title":"跳转大图","pic":[{"name":"jump1","sourceDesc":"1920*1080","position":{"width":1152,"height":648,"top":0,"left":0}}]}',
                source: {
                  pic: [
                    {
                      content: "屏保",
                      lid: "2591",
                      ltype: "1",
                      mid: "660",
                      mname: "屏保",
                      source_id: "2093",
                      source_md5: "3b7f2c28a43b586c73beb736c6ce4c01",
                      source_sort: "0",
                      source_type: "1",
                      source_url:
                        "https://source.ctlife.tv/3b7f2c28a43b586c73beb736c6ce4c01.jpg",
                      type: "1",
                    },
                  ],
                },
              },
            };

            this.json = JSON.parse(JSON.stringify(res.data.data));
            this.history = JSON.parse(JSON.stringify(res.data.data));
            // this.json = JSON.parse(JSON.stringify(data));
            // this.history = JSON.parse(JSON.stringify(data));

            this.$set(
              this.json.menu.public,
              "positionJson",
              JSON.parse(this.json.menu.public.position)
            );
            this.menuForm.bgPic =
              this.json.menu.public.menu_background.source_url;
            this.menuForm.pointPic =
              this.json.menu.public.menu_point.source_url;
            this.menuForm.fColor = this.json.menu.public.menu_focal_color.x;
            this.menuForm.dColor = this.json.menu.public.menu_text_color.x;

            console.log(this.menuForm);
            console.log(1);
            if (this.json.welcome.position) {
              this.$set(
                this.json.welcome,
                "positionJson",
                JSON.parse(this.json.welcome.position)
              );
            } else {
              this.activeName = "moulde";
            }
            console.log(2);
            if (this.json.open.position) {
              this.$set(
                this.json.open,
                "positionJson",
                JSON.parse(this.json.open.position)
              );
            }
            console.log(3);
            if (this.json.jump_pic.position) {
              this.$set(
                this.json.jump_pic,
                "positionJson",
                JSON.parse(this.json.jump_pic.position)
              );
            }
            if (this.json.screen_pic && this.json.screen_pic.position) {
              this.$set(
                this.json.screen_pic,
                "positionJson",
                JSON.parse(this.json.screen_pic.position)
              );
            }
            console.log(4);
            this.mouldeShow = this.json.menu.menu_list[0].menu_id;
            // this.$refs.menubox[0].active = this.mouldeShow;
            console.log(1111, 222);
            this.json.menu.menu_list = this.json.menu.menu_list.map(
              (element, index) => {
                element.positionJson = JSON.parse(element.position);
                console.log(this.json.menu.menu_list, index);
                return element;
              }
            );

            this.activeChange = !this.activeChange;
            this.noData = false;
            this.loading = false;
            this.showPage = true;
          } else {
            this.$message.warning(res.data.msg);
            this.loading = false;
            this.noData = true;
            this.showPage = true;
          }
        })
        .catch((err) => {
          // this.$message.warning("可视化·数据加载失败");
          this.loading = false;
          this.noData = true;
        });
    },
    clickTab(tab) {
      if (tab.name == "moulde") {
        this.mouldeShow = this.json.menu.menu_list[0].menu_id;
        this.activeChange = !this.activeChange;
      }
    },
    defaultData() {
      this.$emit("defaultdata", this.json, this.canReleaseFlag);
    },
    confirmMeth(data) {
      this.$confirm(
        data.text == "发布" ? "是否确认发布更新？" : "是否确认发布审核？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        let releaseData = JSON.parse(JSON.stringify(this.json));
        let obj = {
          data: JSON.stringify(releaseData),
          database: this.itemData.hotelName,
          intro: data.intro,
          template_id: "",
          module: data.module,
          roomStr: data.roomStr,
          use_name:this.auth.user_name,
        };
        if (data.text == "发布") {
          setLauncher(obj)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("发布成功");
                this.getPageData();
              } else {
                this.$message.warning(res.data.msg);
              }
              this.loading = false;
              this.noData = false;
            })
            .catch((err) => {
              this.$message.warning("发布失败");
              this.loading = false;
              this.noData = false;
            });
        } else {
          setLauncherPro(obj)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("提交审核成功");
                // this.getPageData();
              } else {
                this.$message.warning(res.data.msg);
              }
              this.loading = false;
              this.noData = false;
            })
            .catch((err) => {
              this.$message.warning("提交审核成功");
              this.loading = false;
              this.noData = false;
            });
        }
      });
    },
    publishListMeth() {
      this.$refs.publishList.showDrawer(true);
      this.$refs.publishList.getHotelConfigInfo();
    },
    publishDialogMeth(type, textStr) {
      if (this.canReleaseFlag) {
        this.$refs.choiceRoomDialog.dialogShowMeth(true, textStr);
      } else {
        this.$message.warning("素材信息未做更改，无需进行发布操作");
      }
    },
    menuChange(id) {
      this.mouldeShow = id;
      // this.$refs.menubox.active = id;
    },
    clickitem(item) {
      this.mouldeShow = item.menu_id;
    },
    changehistory(data) {
      let sortList = data.map((i) => {
        return i.menu_id;
      });
      this.history.menu.menu_list = sortList.map((i) => {
        let item = this.history.menu.menu_list.filter((t) => {
          if (t.menu_id == i) {
            return t;
          }
        });
        return item[0];
      });
    },
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  data() {
    return {
      moreVisible: false,
      moreSource: [],
      moreSourceType: "",
      menuForm: {
        bgPic: "",
        bgMd5: "",
        pointPic: "",
        pointMd5: "",
        fColor: "",
        dColor: "",
      },
      menuVisible: false,
      rules: {
        bgPic: [
          { required: true, message: "请上传导航背景图", trigger: "blur" },
        ],
        pointPic: [
          { required: true, message: "请上传焦点背景图", trigger: "blur" },
        ],
        fColor: [
          { required: true, message: "请输入焦点颜色", trigger: "blur" },
        ],
        dColor: [
          { required: true, message: "请输入默认颜色", trigger: "blur" },
        ],
      },
      showPage: false,
      canReleaseFlag: false,
      mouldeShow: "",
      loading: false,
      noData: true,
      activeChange: true,
      itemData: { hotel_contract: "" },
      json: {},
      activeName: "welcome",
      history: {},
      funArr: [],
      auth: {},
    };
  },
};
</script>

<style scoped lang="scss">
.visiualBox {
  height: 648px;
  width: 1152px;
  background: rgba($color: #999, $alpha: 1);
  position: relative;
}
.btnBox {
  margin: 15px 0;
}
.viiual {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .formBox {
    margin-top: 40px;
    transform: scale(0.5);
  }
}
.showPicBox {
  height: 108px;
  width: 108px;
  position: relative;
  border: 1px dashed #999;
  background: rgba($color: #eee, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-height: 100%;
    max-width: 100%;
  }
  .btn {
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.3);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    transition: 0.3s;
    i {
      margin: 4px;
      color: #fff;
      font-size: 24px;
      transition: 0.3s;
    }
    i:hover {
      color: #409eff;
    }
  }
}
.showPicBox:hover .btn {
  display: flex;
}
.showMoreBox {
  position: relative;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
}
.allShowBox {
  width: 100%;
}
</style>
