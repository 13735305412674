<template>
  <div>
    <div class="searchBox">
      <el-date-picker @change="changeData" :picker-options="pickerOptions" class="dataSelect"  v-model="searchTime" value-format='yyyy-MM-dd' format='yyyy-MM-dd' type="date" placeholder="选择日期">
      </el-date-picker>
    
      <el-button type="primary">搜索</el-button>
      <div class="reset" @click="dataReset">重置</div>
    </div>

    <div class="contentBox">
      <el-row class="rowDouble" :gutter='24'>
        <el-col class="colBox" :span='4'></el-col>
        <el-col class="colBox" :span='6'>
          <el-row class="rowIncol">当前周期</el-row>
          <el-row class="rowIncol" :gutter="24">
            <el-col class="colInrow" :span='12'>酒店数</el-col>
            <el-col class="colInrow" :span='12'>终端数</el-col>
          </el-row>
        </el-col>
        <el-col class="colBox" :span='6'>
          <el-row class="rowIncol">上一周期</el-row>
          <el-row class="rowIncol" :gutter="24">
            <el-col class="colInrow" :span='12'>酒店数</el-col>
            <el-col class="colInrow" :span='12'>终端数</el-col>
          </el-row>
        </el-col>
        <el-col class="colBox onlyOneCol" :span='4'>
          变动
        </el-col>
        <el-col class="colBox onlyOneCol" :span='4'>
          变动幅度
        </el-col>
      </el-row>
      <el-row class="rowOnlyOne" :gutter='24'>
        不可控阵地
      </el-row>
      <el-row class="rowDouble" :gutter='24'>
        <el-row class="rowOnlyOne" :gutter='24'>
          <el-col class="colBox onlyOneCol titleName" :span='4'>
            无高版本阵地
            <div class="topTipBox">
              <div class="text">简单介绍</div>
              <div class="down"></div>
            </div>
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
        </el-row>
        <el-row class="rowOnlyOne" :gutter='24'>
          <el-col class="colBox onlyOneCol titleName" :span='4'>
            低更新率阵地
            <div class="topTipBox">
              <div class="text">简单介绍</div>
              <div class="down"></div>
            </div>
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
        </el-row>
      </el-row>
      <el-row class="rowOnlyOne" :gutter='24'>
        可控阵地
      </el-row>
      <el-row class="rowDouble" :gutter='24'>
        <el-row class="rowOnlyOne" :gutter='24'>
          <el-col class="colBox onlyOneCol titleName" :span='4'>
            有效高版本阵地
            <div class="topTipBox">
              <div class="text">简单介绍</div>
              <div class="down"></div>
            </div>
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
        </el-row>
        <el-row class="rowOnlyOne" :gutter='24'>
          <el-col class="colBox onlyOneCol titleName" :span='4'>
            可运营阵地
            <div class="topTipBox">
              <div class="text">简单介绍</div>
              <div class="down"></div>
            </div>
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
        </el-row>
      </el-row>
      <el-row class="rowOnlyOne" :gutter='24'>
        亚健康阵地
      </el-row>
      <el-row class="rowDouble" :gutter='24'>
        <el-row class="rowOnlyOne" :gutter='24'>
          <el-col class="colBox onlyOneCol titleName" :span='4'>
            伪活跃阵地
            <div class="topTipBox">
              <div class="text">简单介绍</div>
              <div class="down"></div>
            </div>
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
        </el-row>
        <el-row class="rowOnlyOne" :gutter='24'>
          <el-col class="colBox onlyOneCol titleName" :span='4'>
            伪交互阵地
            <div class="topTipBox">
              <div class="text">简单介绍</div>
              <div class="down"></div>
            </div>
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='3'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
          <el-col class="colBox onlyOneCol" :span='4'>
            测试数据
          </el-col>
        </el-row>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        pickerOptions:{
         disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
      searchTime: "",
    };
  },
  mounted() {},
  methods: {
    dataReset(){
      this.searchTime='';
    },
    changeData(val){
      console.log(this.searchTime);
    },
  },
};
</script>
<style scoped>
.contentBox {
  margin-top: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.el-row {
  margin: 0 !important;
  font-size: 14px;
  color: #333;
}
.rowDouble {
  text-align: center;

  height: 90px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.rowDouble:last-child {
  border-bottom: none;
}
.rowDouble:first-child {
  background: #f8fbff;
}
.rowDouble .rowOnlyOne {
  background: #fff;
}
.rowOnlyOne {
  background: #eee;
  text-align: center;
  line-height: 45px;
  height: 45px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.rowOnlyOne:last-child {
  border-bottom: none;
}
.colBox {
  padding: 0 !important;
  height: 100%;
  position: relative;
  border-left: 1px solid #ccc;
}
.colBox:first-child {
  border-left: 0;
}
.rowIncol {
  height: 45px;
  line-height: 45px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}
.rowIncol:last-child {
  border-bottom: none;
}
.colInrow {
  padding: 0 !important;
  height: 100%;
  border-left: 1px solid #ccc;
}
.colInrow:first-child {
  border-left: 0;
}
.onlyOneCol {
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleName:hover .topTipBox {
  display: block;
}
.topTipBox {
  display: none;
  position: absolute;
  background: #333;
  top: -80px;
  left: 20px;
  padding: 5px 20px;
  width: 200px;
  height: 80px;
  border-radius: 10px;
  z-index: 999;
  color: #fff;
  transition: 0.5s;
}
.text {
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  height: 60px;
  overflow: auto;
}
.down {
  background: #333;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0) rotate(-45deg);
}
.dataSelect{
  margin-right: 10px;
}
.el-button{
  width: 100px;
  height: 40px;
  margin-left: 20px;
}
::v-deep .el-input__inner {

  border: 1px solid #2e63a6 !important;
    color: #2e63a6 !important;
}
.reset{
  display: inline-block;
  border: 1px solid #2e63a6;
   width: 100px;
  height: 40px;
  margin-left: 20px;
   background: #fff;
   border-radius: 5px;
   text-align: center;
   color: #2e63a6 ;
   line-height: 40px;
   cursor: pointer;
  box-sizing: border-box;
}
.searchBox{
  display: flex;
  justify-content: flex-start;

}

</style>