<template>
  <div>
    <!-- 面包屑 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">财务管理</span>
        <span slot="third">申请提现</span>
      </top-bread>
    </div>
    <!-- 面包屑 -->
    <!-- 数据概览 -->
    <el-card class="box-card" v-if="pageData.total">
      <div class="title blueTitle">数据概览</div>
      <div class="lineContent">
        <el-row :gutter="20" class="rowBox vertical borderBox" v-loading="showLoading">
          <index-four-row :span="8" :type="type">
            <span slot="numTitle">可提现总金额</span>
            <span slot="num">{{
              pageData.total.sum_can_withdraw_amount
                ? pageData.total.sum_can_withdraw_amount
                : "0"
            }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="8" :type="type">
            <span slot="numTitle">扣除金额</span>
            <span slot="num">{{ pageData.total.sum_deducted_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="8" :type="type">
            <span slot="numTitle">对账确认金额</span>
            <span slot="num">{{ pageData.total.sum_check_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
        </el-row>
        <div class="contentBtn" @click="openTheContent">详情</div>
      </div>
    </el-card>
    <!-- 数据概览 -->
    <!-- 申请提现 -->
    <el-card class="WithdrawalCard" v-if="showFunModel('申请提现')">
      <div class="title blueTitle">申请提现</div>
      <div class="lineContent lineContent2">
        <div class="WithdrawalLeft">
          <el-row :gutter="20" class="rowContent">
            <el-col class="labelmsg" :span="4">
              <span>可提现金额:</span>
            </el-col>
            <el-col :span="12">
              <span>
                {{
              pageData.total.sum_can_withdraw_amount
                ? pageData.total.sum_can_withdraw_amount
                : "0"
            }} 元
              </span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowContent">
            <el-col class="labelmsg" :span="4">
              <span>申请提现金额:</span>
            </el-col>
            <el-col :span="12">
              <el-input placeholder="请输入内容" v-model="withdrawalMoney">
                <template slot="append" class="yuan">元</template>
              </el-input>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" @click="moneyTrue">申请提现</el-button>
            </el-col>
          </el-row>

          <div class="messageContent">
            <p>* 可提现金额大于{{pageData.minMoney}}元方可申请提现。</p>
            <p>* 每次申请提现最小金额为100元。</p>
            <p>* 提现审核通过后，需寄送相应名称和税率的发票至携旅。携旅在收到发票后安排线下汇款。</p>
          </div>
        </div>

      </div>
    </el-card>
    <!-- 申请提现 -->
    <!-- 提现记录列表 -->
    <el-card class="box-card" v-if="showFunModel('提现记录列表')">
      <div class="rowBox vertical noMarginTop">
        <div class="title blueTitle">提现记录列表</div>
        <div class="type_box">
          <el-select v-model="pay_status" placeholder="汇款状态" clearable class=" search" filterable collapse-tags>
            <el-option v-for="item in payStatus" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="statusType" placeholder="审核状态" clearable class=" search" filterable collapse-tags>
            <el-option v-for="item in examineStatus" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <div class="btn_box">
            <el-button class="searchBtn " type="primary" @click="searchMeth" :loading="table.loading">搜索</el-button>
            <el-button class="searchBtn " type="text" @click="resetMeth" :loading="table.loading">重置</el-button>
          </div>
        </div>
        <table-list :table="table" @tableMeth="tableMeth" v-loading="table.loading" class="tableBox" @showUploadDialog="showUploadDialog"></table-list>
      </div>
    </el-card>
    <!-- 提现记录列表 -->
    <!-- 详情界面 -->
    <el-drawer :title="dialogTitle" :visible.sync="contentShowVisible" :size="drawerWidth" :modal="false" :destroy-on-close="true" :wrapperClosable="false">
      <div class="picShow picShow2" v-if="dialogType === 1">
        <div @click="picShow">
          <el-upload :disabled="true" :action="global.materialUpload" list-type="picture-card" :on-preview="handlePictureCardPreview" :headers="headers" :file-list="fileList" ref="uploadPic" :class="{ hide: true }">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>

        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div class="picShow picShow1" v-if="dialogType === 2">
        <p>{{ returnContent }}</p>
      </div>
      <div class="picShow" v-if="dialogType === 3">
        <div class="tableBoxContent">
          <el-table ref="singleTable" v-loading="contentTable.loading" :data="contentTableList" style="width: 100%" :header-cell-style="headerStyle">
            <el-table-column property="amount" label="扣款金额(元)">
              <template slot-scope="scope">
                <span>{{ scope.row.amount / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column property="reason" label="扣款原因">
            </el-table-column>
            <el-table-column label="附件">
              <template slot-scope="scope">
                <el-button v-if="scope.row.attachment_name" type="text" size="mini" @click="donowdFile(scope.row)">{{ scope.row.attachment_name }}
                </el-button>
                <el-button v-else type="text" size="mini">暂无数据</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="changePage" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="contentTable.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="contentTable.limit" layout="total, sizes, prev, pager, next, jumper" :total="contentTable.total">
          </el-pagination>
        </div>
      </div>
    </el-drawer>
    <!-- 详情界面 -->
  </div>
</template>

<script>
import qs from "querystring";
import commonMeth from "../../api/method";
import { log } from "util";
import AssignVue from "../hotelMan/hotelManAssignMenu/Assign.vue";
import { getDeductedList } from "@/api/financialManagement.js";
export default {
  name: "FinManApply",
  data: () => ({
    Btn: false,
    examineStatus: [
      { id: 0, name: "待审核" },
      { id: 1, name: "已通过" },
      { id: 2, name: "已驳回" },
    ],
    payStatus: [
      { id: 0, name: "不可付款" },
      { id: 1, name: "待付款" },
      { id: 2, name: "已付款" },
    ],
    SourceOfType: [
      { id: "001001", name: "影视单点" },
      { id: "001002", name: "影视天卡" },
    ],
    drawal: false,
    drawerWidth: "95%",
    applicationWithdrawalForm: [
      { withdraw_amount_text: 0, withdraw_type: "001001" },
      { withdraw_amount_text: 0, withdraw_type: "001002" },
    ],
    pageData: {
      items: [],
      total: { sum_check_amount: 0 },
    },
    ImageUrl: require("../../assets/logo.png"),
    returnContent: "",
    fileList: [],
    dialogType: 1,
    dialogTitle: "汇款单",
    contentShowVisible: false,
    withdrawalMoney: "",
    WithdrawalMoneyDay: "",
    activeName: "001001",
    type: "timeCenter",
    startDatePlaceholder: "申请开始日期",
    endDatePlaceholder: "申请结束日期",
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    headers: {
      token: "",
    },
    table: {
      tableHeader: [
        { prop: "create_time_text", label: "申请时间", width: "" },
        { prop: "withdraw_type_name_text", label: "收益类型", width: "" },
        { prop: "withdraw_amount_text", label: "申请提现金额", width: "" },
        { prop: "status_text", label: "审核状态", width: "" },
        { prop: "confirm_admin_name_text", label: "审核人", width: "" },
        { prop: "confirm_time_text", label: "审核时间", width: "" },
        { prop: "pay_status_text", label: "付款状态", width: "" },
        // { prop: "pay_admin_name_text", label: "付款人", width: "" },
        { prop: "pay_time_text", label: "付款时间", width: "" },
      ],
      fold: null,
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "finManApply",
      name: "finManApplyChange",
      option: true,
      loading: false,
    },
    contentTable: {
      page: 1,
      limit: 5,
      total: 20,
      loading: false,
    },
    statusType: null,
    pay_status: "",
    contentTableList: [],
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    auth: {},
    topSearchShow: {},
    showLoading: true,
    condition: {},
    token: "",
    sourceType: null,
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    funArr: [],
    allData: {},
    dialogVisible: false,
    allLoadingState: false,
    dialogImageUrl: "",
  }),
  methods: {
    // 文件下载
    donowdFile(row) {
      this.$confirm("是否确认下载或查看当前文件？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.open(row.attachment);
        })
        .catch((err) => {});
    },
    handlePictureCardPreview() {},
    // 分页功能
    handleSizeChange(limit) {
      this.contentTable.limit = limit;
      this.getContentTableList();
    },

    handleCurrentChange(page) {
      this.contentTable.page = page;
      this.getContentTableList();
    },
    // 打开详情
    openTheContent() {
      this.drawerWidth = "95%";
      this.getContentTableList();
      this.contentShowVisible = true;
      this.dialogTitle = "扣款详情";
      this.dialogType = 3;
    },
    // 获取扣款详情列表
    getContentTableList() {
      this.contentTable.loading = true;
      const json = {
        limit: this.contentTable.limit,
        page: this.contentTable.page,
      };
      var params = qs.stringify(json);
      getDeductedList(params)
        .then((res) => {
          if (res.data.code == 100000) {
            this.contentTableList = res.data.data.items;
            this.contentTable.total = res.data.data.total;
            this.contentTable.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭详情界面
    closeDialog() {
      this.fileList = [];
      this.contentShowVisible = false;
    },
    // 申请提现
    toWithdrawalMoney() {
      this.drawal = true;
      var str = String(this.withdrawalMoney * 100);
      var param = qs.stringify({
        withdraw_amount: str,
      });

      this.$http
        .post(this.global.partnerwithdrawTrue, param, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("申请成功");
            this.withdrawalMoney = null;
            this.tableMeth();
            this.getData();
          } else if (res.data.msg === "amount必须大于等于 100") {
            // 两项特定返回提示
            this.$message.warning("申请提现金额最小额度为100元");
            this.withdrawalMoney = null;
          } else if (res.data.msg === "输入异常") {
            this.$message.warning("请输入提现金额");
            this.withdrawalMoney = null;
          } else {
            this.$message.warning(res.data.msg);
            this.withdrawalMoney = null;
          }
          this.drawal = false;
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },

    //   输入金额确认
    moneyTrue() {
      this.$confirm("是否确认当前提现金额？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var rex = /^([1-9]\d{0,9}|0)(\.\d{1,2})?$/;
          // 输入金额大小判定
          var flag =
            parseFloat(this.withdrawalMoney) >
            parseFloat(this.pageData.total.sum_can_withdraw_amount);
          if (flag) {
            this.$message.warning("申请提现金额超过可提现金额");
            this.withdrawalMoney = "";
          } else if (this.withdrawalMoney.trim() === "") {
            // 输入金额为空判定
            this.$message.warning("请输入提现金额");
          } else {
            // 判断已输入金额是否包含小数点
            if (String(this.withdrawalMoney).indexOf(".") == -1) {
              if (this.withdrawalMoney.match(rex)) {
                this.toWithdrawalMoney();
              } else {
                this.$message.warning("输入格式错误");
                this.withdrawalMoney = "";
              }
            } else {
              var index = String(this.withdrawalMoney).indexOf(".");
              var num1 = this.withdrawalMoney.substr(0, index + 1).length;
              var num2 = this.withdrawalMoney.length;
              var num = num2 - num1;
              if (num > 2) {
                this.$message.warning("申请提现金额最多保留两位小数");
                this.withdrawalMoney = "0";
              } else {
                if (this.withdrawalMoney.match(rex)) {
                  this.toWithdrawalMoney();
                } else {
                  this.$message.warning("输入格式错误");
                }
              }
            }
          }
        })
        .catch((err) => {});
    },
    //   关闭对话框
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //  切换状态
    typeChange(val) {
      if (val == 12) {
        this.statusType = 2;
        this.pay_status = null;
      } else {
        this.pay_status = val;
        this.statusType = null;
      }

      this.tableMeth();
    },
    //  获取表格数据
    tableMeth(orderVal) {
      this.table.loading = true;
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      this.$http
        .get(this.global.partnerwithdraw, {
          params: {
            token: this.token,
            page: this.table.page,
            limit: this.table.limit,
            status: this.statusType,
            pay_status: this.pay_status,
            type: this.sourceType,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = parseInt(tableData.total);
          } else {
            this.$message.error(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch(() => {
          this.table.loading = false;
        });
    },
    //  获取搜索框权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          examineStatus: true,
          SourceOfType: true,
          payStatus: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {};
      }
    },
    // 筛选
    getCondition(val) {
      this.table.page = 1;
      this.statusType = val.examineStatus;
      this.pay_status = val.payStatus;
      this.sourceType = val.SourceOfType;
      this.tableMeth();
    },
    // 搜索
    searchMeth() {
      this.tableMeth();
    },
    // 重置
    resetMeth() {
      this.statusType = "";
      this.pay_status = "";
      this.sourceType = "";
      this.tableMeth();
    },
    // 打开筛选
    getFoldMeth(val) {
      this.fold = val;
    },
    // 展示图片
    picShow() {
      this.dialogVisible = true;
      this.dialogImageUrl = this.fileList[0].url;
    },
    // 打开弹框
    showUploadDialog(val) {
      this.fileList = [];
      this.drawerWidth = "45%";
      if (val.pay_voucher != "") {
        this.dialogTitle = "汇款单";
        this.dialogType = 1;

        const josn = {
          url: val.pay_voucher,
        };
        this.fileList.push(josn);
      } else if (val.check_reason != "") {
        this.dialogTitle = "驳回原因";
        this.dialogType = 2;
        this.returnContent = val.check_reason;
      } else {
        this.dialogTitle = "数据错误";
        this.dialogType = 1;
        const josn = {
          url: val.pay_voucher,
        };
        this.fileList.push(josn);
        this.returnContent = val.check_reason;
      }
      this.contentShowVisible = true;
    },
    //  关闭弹框 并清空数据
    cancelUploadDialog() {
      this.fileList = [];
      this.uploadPicDialog.visible = false;
      this.uploadPicDialog.defaultList = [];
    },
    //  获取页面数据
    getData() {
      this.showLoading = true;
      this.$http
        .get(this.global.applyWithdrawShow, {
          params: { token: this.token },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.pageData = res.data.data;
            var obj = res.data.data.total;
            for (const key in obj) {
              this.pageData.total[key] = Number(obj[key]) / 100;
            }
            this.showLoading = false;
          } else {
            this.$message.error("返回错误");
            this.showLoading = false;
          }
        })
        .catch((err) => {
          this.showLoading = false;
          console.log(err);
        });
    },
  },
  // 数据加载
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.token = sessionStorage.getItem("token");
    this.headers.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.getData();
    this.tableMeth();
    commonMeth.getTreeData(this.table.option).then((res) => {
      this.funArr = res.funArr;
      this.table.option = res.flag;
    });
  },
  computed: {
    // 权限
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    // loading
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(
        this.table.loading,
        false,
        this.showLoading
      );
    },
  },
};
</script>

<style scoped>
>>> .el-upload-list__item {
  transition: none !important;
}
.changePage {
  margin-top: 20px;
}
.lineContent {
  position: relative;
  margin-top: 0px;
}
.lineContent:first-child {
  margin-top: 0;
}
.el-card {
  margin-top: 20px;
}
.noBorder > input {
  border: none !important;
}
.tips {
  color: #bebebe;
  margin-bottom: 0 !important;
}
.confirmBtn {
  margin-left: 50px;
  margin-right: 50px;
}

[v-cloak] {
  display: none;
}
.tableBox {
  margin-top: 20px;
}
.rowBox {
  margin-top: 20px;
}

.lineContent2 {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.contentBtn {
  position: absolute;
  right: 37%;
  bottom: 20%;
  color: #409eff;
  cursor: pointer;
  font-size: 12px;
}
.WithdrawalLeft {
  width: 57%;
  position: relative;
}
.WithdrawalLeft::after {
  content: "";
  display: block;
  position: absolute;
  top: 30%;
  right: 0;
  width: 1px;
  height: 60%;
}
.Withdrawalright {
  width: 40%;
}
.rowContent {
  margin-top: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
}
.WithdrawalMoneyNumber {
  margin-top: 48px;
  line-height: 20px;
  font-size: 14px;
}
.WithdrawalMoneyNumber2 {
  line-height: 20px;

  margin-bottom: 16px;
  font-size: 14px;

  display: flex;
  align-items: center;
}
.number_show {
  font-family: "DINAlternate";
}
.moneyLog {
  margin-bottom: 62px;
  max-height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
}
.WithdrawalMoneyNumberRight {
  line-height: 20px;
  font-size: 14px;
}
.WithdrawalMoneyNumberRight:nth-child(n + 2) {
  margin-top: 25px;
}
.WithdrawalMoneyNumber2 .labelmsg span,
.WithdrawalMoneyNumber2 .number_show {
  font-weight: 600 !important;
}
.labelmsg {
  text-align: right;
  font-size: 14px;
}
.lineBox {
  width: 100%;
  height: 49px;
  border-bottom: 1px solid #e4e7ed;
  margin-bottom: 30px;
  position: relative;
}
.lineBox:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #e4e7ed;
  position: absolute;
  top: 0;
  left: -100px;
}
.messageContent {
  color: #aaa;
  font-size: 12px;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 0;
}
.messageContent p {
  padding: 5px;
}
.btnBoxRow {
  overflow: hidden;
}
.btnBox {
  float: right;
}
.el-input {
  height: 42px;
}
.el-input /deep/ .el-input__inner {
  height: 42px;
}
.el-input /deep/ .el-input-group__append {
  line-height: 40px;
  box-sizing: border-box;
  text-align: center;
}
.el-button {
  width: 100px;
  height: 40px;
}

.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  width: 50%;
  padding: 10px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background: #b6babf;
}
.dialogTitle {
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-align: left;
  padding: 0 10px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-upload--picture-card {
  width: 300px;
  height: 200px;
}
::v-deep .el-upload--picture-card i {
  line-height: 200px;
}
::v-deep .el-upload-list__item {
  width: 300px;
  height: 200px;
}

::v-deep .el-upload-list__item.is-uploading {
  width: 300px;
  height: 200px;
}
::v-deep .el-upload-list__item.is-success {
  width: 300px;
  height: 200px;
}
.picShow1 {
  box-sizing: border-box;
  margin-top: 10px;
}
.picShow {
  position: relative;
  z-index: 20;
  width: 100%;
  height: calc(100% - 100px);
}
.picShow img {
  width: 100%;
  max-height: calc(100% - 40px);
}
.picShow2 {
  width: 300px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.btnBox .el-button {
  background: #e6ebf2;
  border: 1px solid #bbb;
  color: #aaa;
  width: 100px;
  height: 40px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
}
.btnBox .el-button:first-child {
  border-radius: 3px 0 0 3px;
}
.btnBox .el-button:first-child.isActive {
  border-radius: 3px 0 0 3px;
}
.btnBox .el-button:last-child {
  border-radius: 0 3px 3px 0;
}
.btnBox .el-button:last-child.isActive {
  border-radius: 0 3px 3px 0;
}
.btnBox .el-button.isActive {
  color: #fff;
  border: 1px solid #3688f8;
  background: #3688f8;
  z-index: 2;
  border-radius: 0;
}
.el-tabs /deep/ .el-tabs__nav {
  padding: 10px 0 0px !important;
}

.el-tabs /deep/ .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  color: #303030;
  position: relative;
}
.el-tabs /deep/ .el-tabs__item:last-child {
  /* color: #ccc; */
}
.el-tabs /deep/ .el-tabs__active-bar {
  width: 56px !important;
}

.el-tabs /deep/ .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #e4e7ed;
  z-index: 1;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;

  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  background-color: #f9f9f9;
}

.tableBoxContent {
  background-color: #fff;
  padding: 20px;
}

::v-deep :focus {
  outline: 0;
}

.hide ::v-deep .el-upload--picture-card {
  display: none;
}
.delNum {
  color: #aaa;
}
.type_box {
  margin-top: 20px;
  width: 100%;
}
.search_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.search {
  width: 15%;
  margin-right: 20px;
}
.btn_box {
  float: right;
}
</style>
