<template>

  <div :class="['box',showData?'':'box1']" :style="{position:content.positioning?content.positioning:'absolute',zIndex:style.zIndex?style.zIndex:zIndex,width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px',}">
    <div class="borderBox" v-if="!content.positioning"></div>
    <visiualButton class="hide" :msg="content.name" @showmore='openMoreInfo' :showMore='showMore' v-if="!isEdit" @openuppage='openUppage' @recoverydata="recoverydata"></visiualButton>
     <visiualButton class="hide" :msg="content.name" @showmore='openMoreInfo' :showMore='showMore' :isEdit='isEdit' @openuppage='openUppage' @recoverydata="recoverydata"></visiualButton>
    <uploadSource ref="uploadSource" sourceType="video" @upsuccess='upSuccess'></uploadSource>
    <div class="player" v-if="flag"><i class="el-icon-video-play" @click="playMedia"></i></div>
    <div class="player paused" v-if="!flag"><i class="el-icon-video-pause" @click="playMedia"></i></div>
    <video :id="content.name" @click="playMedia" :controls="false" v-if="showData" :src="showData.source_url" alt="" :style="{width: '100%', height: '100%'}"></video>
  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";
import uploadSource from "../common/uploadSource.vue";
export default {
  name: "videoBox",
  components: {
    visiualButton,
    uploadSource,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
          isEdit: {
      type: Boolean,
      default: false,
    },
       zIndex: {
      type: Number,
      default: 4,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        this.content = JSON.parse(JSON.stringify(newVal));
        let obj = JSON.parse(JSON.stringify(this.content.position));
        for (var i in obj) {
          this.style[i] = obj[i] ;
        }
        console.log( this.style);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      content: {},
      showData: this.value,
      flag: true,
      video:null,
    };
  },
  mounted() {
    this.video = document.getElementById(this.content.name);
    this.video.addEventListener("ended", this.flagReset);
  },
  
  beforeDestroy() {
    this.video.removeEventListener("ended", this.flagReset);
  },
  methods: {
    openMoreInfo(){
      console.log(4144);
        this.$emit("opentabinfo");
    },
    flagReset() {
      this.flag = true;
    },
    playMedia() {
      let video = document.getElementById(this.content.name);
      if (video.paused) {
        video.play();
        this.flag = false;
      } else {
        video.pause();
        this.flag = true;
      }
    },
    upSuccess(data) {
      this.showData.source_md5 = data.md5;
      this.showData.source_url = data.url;
      this.showData.update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    openUppage() {
      this.$refs.uploadSource.dialogVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 2;
  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: none;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
.player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 40px;
  font-size: 80px;
  line-height: 75px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  z-index: 9;
}
.paused {
  display: none;
  z-index: 9;
}
.box:hover .paused {
  display: block;
  z-index: 9;
}
</style>