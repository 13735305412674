<template>
  <div>
    <el-drawer title="详情" :visible.sync="drawerShowVisible" size="50%" :modal="false" @close="closeDrawer" :wrapperClosable="false" v-loading="pageLoading">
      <div class="allcontentShow">
        <!-- 顶部信息 -->
        <div class="StatementAccountTitle">
          <p class="StatementAccountTitlename">{{StatementAccountMonth}}月份对账单</p>
          <p class="StatementAccountTitlenum">
            流水号：{{ pageData.reconciliation_id }}
          </p>
        </div>
          <!-- 顶部信息 -->
        <!-- tab切换页 -->
        <div class="contentshowTab">
          <el-tabs v-model="pageData.type" :before-leave="handleClick">
            <el-tab-pane label="影视单点" name="001001" v-if="reconciliationType!='2'">
              <div class="showNewBox">
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p><span>对账公司名称：</span>{{ pageData.partner_name }}</p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>合作伙伴终端编码：</span>{{ pageData.partner_code }}
                    </p>
                  </el-col>
                </el-row>
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p>
                      <span>合同有效期：</span>{{
                      pageData.contract_date_begin && pageData.contract_date_end
                        ? pageData.contract_date_begin.substr(0, 10) +
                          " ~ " +
                          pageData.contract_date_end.substr(0, 10)
                        : ""
                    }}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>对账周期：</span>{{
                      pageData.reconciliation_cycle_begin &&
                      pageData.reconciliation_cycle_end
                        ? pageData.reconciliation_cycle_begin.substr(0, 10) +
                           " ~ " +
                          pageData.reconciliation_cycle_end.substr(0, 10)
                        : ""
                    }}
                    </p>
                  </el-col>
                </el-row>
              </div>
               <div class="showNewBox">
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p><span>订单数量（个）：</span>{{ pageData.total_order_num }}</p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>订单金额（元）：</span>{{ pageData.total_order_income }}
                    </p>
                  </el-col>
                </el-row>
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p>
                      <span>退款订单数量（个）：</span>{{pageData.refund_order_num}}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>退款金额（元）：</span>{{pageData.refund_order_income}}
                    </p>
                  </el-col>
                </el-row>
                 <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p>
                      <span>对账订单数量（个）：</span>{{pageData.order_num}}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>对账金额（元）：</span>{{pageData.amount}}
                    </p>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <el-tab-pane label="影视天卡" name="001002" v-if="reconciliationType!='1'">
              <div class="showNewBox">
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p><span>对账公司名称：</span>{{ pageData.partner_name }}</p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>合作伙伴终端编码：</span>{{ pageData.partner_code }}
                    </p>
                  </el-col>
                </el-row>
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p>
                      <span>合同有效期：</span>{{
                      pageData.contract_date_begin && pageData.contract_date_end
                        ? pageData.contract_date_begin.substr(0, 10) +
                          " ~ " +
                          pageData.contract_date_end.substr(0, 10)
                        : ""
                    }}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>对账周期：</span>{{
                      pageData.reconciliation_cycle_begin &&
                      pageData.reconciliation_cycle_end
                        ? pageData.reconciliation_cycle_begin.substr(0, 10) +
                           " ~ " +
                          pageData.reconciliation_cycle_end.substr(0, 10)
                        : ""
                    }}
                    </p>
                  </el-col>
                </el-row>
              </div>
                <div class="showNewBox">
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p><span>订单数量（个）：</span>{{ pageData.total_order_num }}</p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>订单金额（元）：</span>{{ pageData.total_order_income }}
                    </p>
                  </el-col>
                </el-row>
                <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p>
                      <span>退款订单数量（个）：</span>{{pageData.refund_order_num}}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>退款金额（元）：</span>{{pageData.refund_order_income}}
                    </p>
                  </el-col>
                </el-row>
                 <el-row :gutter="24" class="rowContent">
                  <el-col :span="12">
                    <p>
                      <span>对账订单数量（个）：</span>{{pageData.order_num}}
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p>
                      <span>对账金额（元）：</span>{{pageData.amount}}
                    </p>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
           <!-- tab切换页 -->
        <!-- 列表-->
        <div class="contentshowTab contentshowTab1">
          <el-table ref="singleTable" :data="tableData" style="width: 100%" class="duizhangList" :header-cell-style="headerStyle">
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="hotel_name" label="酒店名称" min-width="300" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" min-width="120">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="openContent(scope.row, 1)">详细</el-button>
                <el-button type="text" v-show="scope.row.message" size="mini" @click="openContent(scope.row, 2)">驳回原因</el-button>
              </template>
            </el-table-column>
              <el-table-column prop="date" label="日期"> </el-table-column>
            <el-table-column  label="客房数" show-overflow-tooltip>
           <template slot-scope="scope">
              <span>{{scope.row.room_num?scope.row.room_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column  width="130" label="激活终端数量" show-overflow-tooltip>
         <template slot-scope="scope">
              <span>{{scope.row.active_terminal_num?scope.row.active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="valid_active_terminal_num" width="180" label="影视有效激活终端数量" show-overflow-tooltip>
          <template slot-scope="scope">
              <span>{{scope.row.valid_active_terminal_num?scope.row.valid_active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
            <el-table-column prop="order_num" label="订单数">
            </el-table-column>
            <el-table-column prop="amount" label="收益金额（元）" min-width="120">
            </el-table-column>
            <el-table-column prop="refund_order_num" label="退款订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="refund_order_income" label="退款收益" show-overflow-tooltip></el-table-column>
            <el-table-column prop="reconciliation_order_num" label="对账订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="reconciliation_order_income" label="对账收益" show-overflow-tooltip></el-table-column>

            <el-table-column prop="day_active_rate" label="日活率">
            </el-table-column>
            <el-table-column prop="income_source_text" label="收益来源">
            </el-table-column>
            <el-table-column prop="partner_name" label="所属合作伙伴" min-width="300" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
         <!-- 列表-->
      </div>
    </el-drawer>
    <!-- 详情 -->
    <div class="contentDialogBlack" v-show="contentClickShow">
      <div class="contentDialog">
        <p class="dialogTitle">{{ contentTitle }}</p>
        <div class="close" @click="closeDialog2"><i class="el-icon-close"></i></div>
        <div class="picShow" v-if="contentType == 1">
          <el-table ref="singleTable" :data="contentTableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="Loading">
            <el-table-column label="时间" min-width="200">
              <template slot-scope="scope" v-if="scope.row.order_date">
                <span>{{ scope.row.order_date }}</span>
              </template>
            </el-table-column>
              <el-table-column prop="room_addtime" label="上线时间" min-width="200px"></el-table-column>
            <el-table-column prop="order_room" label="房间号" min-width="150px"></el-table-column>
            <el-table-column prop="order_paysn" width="180px" label="订单号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="order_phone" label="手机号" width="120px">
            </el-table-column>
            <el-table-column prop="order_goods_name" label="电影名称" min-width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="order_amount" label="单价">
            </el-table-column>
            <el-table-column prop="calculate" label="分成比例">
            </el-table-column>
            <el-table-column prop="income" label="收益"> </el-table-column>
          </el-table>
        </div>
        <div class="picShow picShow1" v-if="contentType == 2">
          <span>{{returnMessageShow}}</span>
        </div>
      </div>
    </div>
      <!-- 详情 -->
  </div>
</template>

<script>
import { getReconciliationDetailByContractAndDate } from "@/api/financialManagement.js";
import qs from "querystring";
export default {
  props: [
    "contentShowVisible",
    "allDialogData",
    "contentLoading",
    "StatementAccountMonth",
  ],
  data() {
    return {
      Loading: false,
      contentTableList: [],
      contentType: 1,
      contentTitle: "明细",
      contentClickShow: false,
      oneStatus: false,
      oneText: "更多",
      twoStatus: false,
      twoText: "更多",
      threeStatus: false,
      threeText: "更多",
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      activeName: "001001",
      returnMessageShow: "",
      reconciliationType: "",
      pageData: {},
      tableData: [],
      pageData1: {},
      tableData1: [],
      changeBln: true,
      drawerShowVisible: false,
      pageLoading: false,
    };
  },
  watch: {
    allDialogData: {
      handler: function (newVal) {
        if (newVal) {
          this.allDialogData = newVal;
          this.loadingData(newVal).then((res) => {
            if (res) {
              this.changeBln = false;
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
    contentLoading: {
      handler: function (newVal) {
        this.pageLoading = newVal;
      },
      deep: true,
      immediate: true,
    },
    contentShowVisible: {
      handler: function (newVal) {
        this.drawerShowVisible = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    loadingData(newVal) {
      return new Promise((res, ret) => {
        this.reconciliationType = newVal.reconciliationType;
        if (newVal.reconciliationType == 1) {
          //只有单点
          this.activeName = "001001";
          this.pageData = newVal.reconciliationRow.mcu;
          this.tableData = newVal.items.mcu;
        } else if (newVal.reconciliationType == 2) {
          // 只有天卡
          this.pageData = newVal.reconciliationRow.day_card;
          this.tableData = newVal.items.day_card;
          this.activeName = "001002";
        } else {
          // 天卡对账共存
          this.activeName = "001001";
          this.pageData = newVal.reconciliationRow.mcu;
          this.tableData = newVal.items.mcu;
          this.pageData1 = newVal.reconciliationRow.day_card;
          this.tableData1 = newVal.items.day_card;
        }
      
        res(true);
      });
    },
    closeDrawer() {
      this.$emit("closedialog");
      this.changeBln = true;
    },
    // 详情
    openContent(row, num) {
      this.Loading = true;
      if (num == 1) {
        this.contentTitle = "明细";
        this.contentType = 1;
        this.contentClickShow = true;

        const json = {
          date: row.date,
          hotelContract: row.hotel_contract,
          partnerId: row.partner_id,
          reconciliationType: this.activeName,
        };
        var param = qs.stringify(json);
        getReconciliationDetailByContractAndDate(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.contentTableList = res.data.data;
              this.Loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.contentTitle = "驳回原因";
        this.returnMessageShow = row.message;
        this.contentType = 2;
        this.contentClickShow = true;
      }
    },
    // 更多
    showMore(val) {
      if (val == 1) {
        if (this.oneStatus) {
          this.oneStatus = false;
          this.oneText = "更多";
        } else {
          this.oneStatus = true;
          this.oneText = "收起";
        }
      } else if (val == 2) {
        if (this.twoStatus) {
          this.twoStatus = false;
          this.twoText = "更多";
        } else {
          this.twoStatus = true;
          this.twoText = "收起";
        }
      } else {
        if (this.threeStatus) {
          this.threeStatus = false;
          this.threeText = "更多";
        } else {
          this.threeStatus = true;
          this.threeText = "收起";
        }
      }
    },
    handleClick(activeName, oldActiveName) {
      if (this.changeBln) {
        return;
      } else {
        if (activeName != oldActiveName) {
          if (this.reconciliationType == "3") {
            var page = JSON.parse(JSON.stringify(this.pageData));
            var table = JSON.parse(JSON.stringify(this.tableData));
            this.pageData = JSON.parse(JSON.stringify(this.pageData1));
            this.tableData = JSON.parse(JSON.stringify(this.tableData1));
            this.pageData1 = JSON.parse(JSON.stringify(page));
            this.tableData1 = JSON.parse(JSON.stringify(table));
          }
        }
      }
    },
    // 关闭
    closeDialog() {
      this.$emit("closeDialog");
    },
    closeDialog2() {
      this.contentClickShow = false;
    },
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}
.StatementAccountTitle {
  padding-top: 20px;
  background-color: #fff;
}
.StatementAccountTitle p {
  text-align: center;
}
.showNewBox {
  padding: 20px 0;
  margin-bottom: 10px;
}
.showNewBox:first-child {
  border-bottom: 1px solid #dee4eb;
}
.rowContent {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #70747a;
  font-size: 14px;
}
.firstinlineBlock {
  display: inline-block;
  width: 150px;
  font-size: 14px;
  text-align: right;
}
.hotalContentType {
  display: flex;
  align-items: center;
}
.hotalContentType span {
  flex: 1;
}
.inlineBlock {
  display: inline-block;
  font-size: 12px;
  width: 45px;
}
.el-tabs__header {
  margin: 0 !important;
}

.rowContent p span:first-child {
  display: inline-block;
  width: 170px;
  font-size: 14px;
  text-align: right;
}
.el-table {
  margin-top: 20px;
}
.btn {
  margin-top: 15px;
  text-align: right;
}
.box-card {
  position: relative;
}
.backHistroy {
  position: absolute;
  top: 20px;
  left: 20px;
}
.contentDialogBlack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.21);
}
.contentDialogBlack .contentDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 50%;
  padding: 0px 15px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
  overflow: auto;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;

  height: 100%;
  width: 50%;
  padding: 0 15px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 9;
  font-size: 12px;
  box-sizing: border-box;
}
.allcontentShow {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
::v-deep .el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.duizhangList {
  flex: 1;
}
.contentDialogBlack .close {
  right: 15px;
  top: 15px;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;

  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.dialogTitle {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  text-align: left;
  padding: 0;
  border-bottom: 1px solid #ecf3fd;
}
.dialog_title {
  height: 30px;
  line-height: 30px;
  background: #ccc;
  text-align: center;
  margin-bottom: 15px;
}
.dateSelect {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: right;
}
.changeType {
  position: absolute;
  left: -40px;
  top: 0;
  width: 40px;
  height: 120px;
  border-right: 1px solid #ccc;
  z-index: 10;
  background-color: #fff;
}
.changeType div {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.active {
  background-color: #ccc;
}
.lineContentDialog {
  padding-top: 20px;
  box-sizing: border-box;
}
.sumRowBox {
  display: flex;
  justify-content: space-around;
}
.cardShowSum {
  color: #70747a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px;
  padding: 0px 30px;
  box-shadow: 0 0 11px 2px rgb(94 151 255 / 15%);
}
.cardShowfirstP {
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}
.cardShowP {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
}
.cardShowP .cardShowSumnum {
  margin-right: 10px;
  font-family: "DINAlternate";
}
.cardShowfirstPnum {
  display: flex;
  align-items: center;
}
.cardShowSumnum1 {
  color: #00183b;
  font-size: 30px;
  margin-right: 10px;
  font-family: "DINAlternate";
}
.cardShowSumnum {
  font-family: "DINAlternate";
}
.numberShow {
  font-family: "DINAlternate";
}
.numberShow1 {
  color: #333;
  font-size: 16px;
  font-family: "DINAlternate";
}
.el-card {
  margin-bottom: 20px;
}
.StatementAccountTitlename {
  color: #333333;
  font-size: 16px;
  margin-bottom: 17px;
}
.StatementAccountTitlenum {
  color: #adb6c1;
  font-size: 14px;
  margin-bottom: 17px;
}
.el-tabs /deep/ .el-tabs__nav {
  padding: 10px 0 0px !important;
}

.el-tabs /deep/ .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  color: #303030;
  position: relative;
}
.el-tabs /deep/ .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  z-index: 1;
}
.el-tabs /deep/ .el-tabs__item:last-child {
  /* color: #ccc; */
}
.el-tabs /deep/ .el-tabs__active-bar {
  width: 56px !important;
}
.more {
  color: skyblue;
  cursor: pointer;
  font-size: 12px;
}
.picShow1 {
  padding-top: 20px;
}
.grayBox {
  height: 20px;
  width: 100%;
  background-color: #f9f9f9;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  overflow: auto;
  background-color: #f9f9f9;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}
.contentshowTab {
  background-color: #fff;
  padding: 0 20px;
}
.contentshowTab1 {
  margin-top: 20px;
}
</style>