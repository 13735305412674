<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second" v-if="!apaasData">数据中心</span>
        <span slot="third" v-if="!apaasData">商务上线统计</span>
        <span slot="second">商务上线统计</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShowRoom" @condition="getConditionRoom" :pickerIsToday="true" :allLoadingState="allLoadingState"></top-search>
    </el-card>
    <!-- <screen-type @screenTypeSearch="screenTypeSearch" v-if="auth.type!=='group'" :showPartProp="showPart" :allLoadingState="allLoadingState"></screen-type> -->
    <el-card class="box-card searchCard">
      <div class=" rowBox vertical" v-loading="showDataRoomLoading">
        <div class="titleBox">
          <div class="title blueTitle">新增激活终端统计</div>
          <div v-if="conditionRoom.dateType!=2">
            <export-btn :exportUrlProp="global.exportBusinessPerformanceHotelList" :conditionProp="conditionRoom" parentProp="performanceSta"></export-btn>
            <export-btn :exportUrlProp="global.exportBusinessMovie" :conditionProp="conditionRoom" parentProp="performanceSta1" :textProp="'财务导出'"></export-btn>
          </div>
        </div>
        <el-row :gutter="10" style="margin-top:20px;">
          <IndexThreeRow :span="8" :type="type" :parShowDate="(showDataRoomMovie.movieOsNumYesterday-showDataRoomMovie.yearMovieOsNumYesterday)">
            <div slot="numTitle">
              <span class="iconCai">财</span>新增影视点播终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统可以影视点播的有效终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>

            </div>
            <span slot="unit">台</span>
            <span slot="time">
              {{showDataYesRoomTime}}
            </span>
            <div slot="otherShow">
              <div style="text-align:right">
                <div style="margin-bottom:5px"> <span class="iconCai">财</span>新增影视终端
                  <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统具有影视功能的终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.movieOsNumYesterday)}} <span style="font-size:12px">台</span>
                </div>
                <div>转包年终端
                  <el-tooltip class="item" effect="dark" content="自去年7月建站的通包年的影视终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.yearMovieOsNumYesterday)}} <span style="font-size:12px">台</span>
                </div>
              </div>
            </div>
          </IndexThreeRow>

          <IndexThreeRow :span="8" :type="type" :parShowDate="(showDataRoomMovie.movieOsNumMonth-showDataRoomMovie.yearMovieOsNumMonth)">
            <div slot="numTitle">
              <span class="iconCai">财</span>
              新增影视点播终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统可以影视点播的有效终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>

            </div>
            <span slot="unit">台</span>
            <span slot="time">
              【本月】
            </span>
            <div slot="otherShow">
              <div style="text-align:right">
                <div style="margin-bottom:5px"> <span class="iconCai">财</span>新增影视终端
                  <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统具有影视功能的终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.movieOsNumMonth)}} <span style="font-size:12px">台</span>
                </div>
                <div>转包年终端
                  <el-tooltip class="item" effect="dark" content="自去年7月建站的开通包年的影视终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.yearMovieOsNumMonth)}} <span style="font-size:12px">台</span>
                </div>
              </div>
            </div>
          </IndexThreeRow>

          <IndexThreeRow :span="8" :type="type" :parShowDate="(showDataRoomMovie.movieOsNumYear-showDataRoomMovie.yearMovieOsNumYear)">
            <div slot="numTitle">
              <span class="iconCai">财</span> 新增影视点播终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统可以影视点播的有效终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>

            </div>
            <span slot="unit">台</span>
            <span slot="time">
              【本年】
            </span>
            <div slot="otherShow">
              <div style="text-align:right">
                <div style="margin-bottom:5px"> <span class="iconCai">财</span>新增影视终端
                  <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统具有影视功能的终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.movieOsNumYear)}} <span style="font-size:12px">台</span>
                </div>
                <div>转包年终端
                  <el-tooltip class="item" effect="dark" content="自去年7月建站的开通包年的影视终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.yearMovieOsNumYear)}} <span style="font-size:12px">台</span>
                </div>
              </div>
            </div>
          </IndexThreeRow>
        </el-row>

        <el-row :gutter="10" style="margin-top:20px;">
          <IndexThreeRow :span="8" :type="type" :parShowDate="(showDataRoomMovie.movieJspNumYesterday-showDataRoomMovie.yearMovieJspNumYesterday)">
            <div slot="numTitle">
              <span class="iconCai">财</span> 新增独立影视点播终端
              <el-tooltip class="item" effect="dark" content="聚屏系统可见点播有效终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>

            </div>
            <span slot="unit">台</span>
            <span slot="time">
              {{showDataYesRoomTime}}
            </span>
            <div slot="otherShow">
              <div style="text-align:right">
                <div style="margin-bottom:5px"> <span class="iconCai">财</span>新增独立影视终端
                  <el-tooltip class="item" effect="dark" content="聚屏系统具有携旅影视功能的终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.movieJspNumYesterday)}} <span style="font-size:12px">台</span>
                </div>
                <div>转包年终端
                  <el-tooltip class="item" effect="dark" content="开通包年的独立影视终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.yearMovieJspNumYesterday)}} <span style="font-size:12px">台</span>
                </div>
              </div>
            </div>
          </IndexThreeRow>

          <IndexThreeRow :span="8" :type="type" :parShowDate="(showDataRoomMovie.movieJspNumMonth-showDataRoomMovie.yearMovieJspNumMonth)">
            <div slot="numTitle">
              <span class="iconCai">财</span>新增独立影视点播终端
              <el-tooltip class="item" effect="dark" content="聚屏系统可见点播有效终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>

            </div>
            <span slot="unit">台</span>
            <span slot="time">
              【本月】
            </span>
            <div slot="otherShow">
              <div style="text-align:right">
                <div style="margin-bottom:5px"> <span class="iconCai">财</span>新增独立影视终端
                  <el-tooltip class="item" effect="dark" content="聚屏系统具有携旅影视功能的终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.movieJspNumMonth)}} <span style="font-size:12px">台</span>
                </div>
                <div>转包年终端
                  <el-tooltip class="item" effect="dark" content="开通包年的独立影视终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.yearMovieJspNumMonth)}} <span style="font-size:12px">台</span>
                </div>
              </div>
            </div>
          </IndexThreeRow>

          <IndexThreeRow :span="8" :type="type" :parShowDate="(showDataRoomMovie.movieJspNumYear-showDataRoomMovie.yearMovieJspNumYear)">
            <div slot="numTitle">
              <span class="iconCai">财</span>
              新增独立影视点播终端
              <el-tooltip class="item" effect="dark" content="聚屏系统可见点播有效终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>

            </div>
            <span slot="unit">台</span>
            <span slot="time">
              【本年】
            </span>
            <div slot="otherShow">
              <div style="text-align:right">
                <div style="margin-bottom:5px"> <span class="iconCai">财</span>新增独立影视终端
                  <el-tooltip class="item" effect="dark" content="聚屏系统具有携旅影视功能的终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.movieJspNumYear)}} <span style="font-size:12px">台</span>
                </div>
                <div>转包年终端
                  <el-tooltip class="item" effect="dark" content="开通包年的独立影视终端" placement="top">
                    <i class=" el-icon-warning-outline"></i>
                  </el-tooltip>：{{showDataCom(showDataRoomMovie.yearMovieJspNumYear)}} <span style="font-size:12px">台</span>
                </div>
              </div>
            </div>
          </IndexThreeRow>
        </el-row>

        <el-row :gutter="10" style="margin-top:20px;">
          <index-four-row-num-unit :span="6" :type="type" :parShowDate="showDataRoom.currentRoomNum">
            <div slot="numTitle">
              新增激活终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>
            </div>

            <span slot="unit">台</span>

            <span slot="time">
              {{showDataRoomTime}}
            </span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :span="6" :type="type" :parShowDate="showDataRoom.currentYesterdayRoomNum">
            <div slot="numTitle">
              新增激活终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <span slot="unit">台</span>

            <span slot="time">
              【昨日】
            </span>
          </index-four-row-num-unit>
          <!-- <index-four-row-num-unit :span="5" :type="type" :parShowDate="showDataRoom.currentWeekRoomNum">
            <span slot="numTitle">新增激活终端</span>
            <span slot="unit">台</span>

            <span slot="time">
              【本周】
            </span>
          </index-four-row-num-unit> -->
          <index-four-row-num-unit :span="6" :type="type" :parShowDate="showDataRoom.currentMonthRoomNum">
            <div slot="numTitle">
              新增激活终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <span slot="unit">台</span>

            <span slot="time">
              【本月】
            </span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :span="6" :type="type" :parShowDate="showDataRoom.currentYearRoomNum">
            <div slot="numTitle">
              新增激活终端
              <el-tooltip class="item" effect="dark" content="自去年7月建站的自有系统终端" placement="top">
                <i class=" el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <span slot="unit">台 </span>

            <span slot="time">
              【本年】
            </span>
          </index-four-row-num-unit>
        </el-row>
      </div>
      <div class="lineContent chart" v-loading="lineLoading" style="margin-top:20px!important;">
        <div class="outChartBox">
          <line-chart :chart="lineChart" v-if="lineChart.flag && !lineChart.noData"></line-chart>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" v-if="false">
      <div class=" rowBox vertical noMarginTop">
        <div class="title blueTitle">计划完成统计</div>
        <top-search :topSearchShow="topSearchShowPlan" @condition="getConditionPlan"></top-search>
        <el-row :gutter="20" class="rowBox vertical borderBox" v-loading="showDataPlanLoading">
          <index-four-row-num-unit :span="5" :type="type" :parShowDate="showDataPlan.annualPlan">
            <span slot="numTitle">年度计划</span>
            <span slot="time">【本年】</span>
            <span slot="unit">间</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :span="5" :type="type" :parShowDate="showDataPlan.monthlyPlan">
            <span slot="numTitle">月度计划</span>
            <span slot="time">{{showDataPlanTime}}</span>
            <span slot="num">{{showDataCom(showDataPlan.monthlyPlan)}}</span>
            <span slot="unit">间</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :span="5" :type="type" :parShowDate="showDataPlan.active_room_num">
            <span slot="numTitle">新增激活终端</span>
            <span slot="time">{{showDataPlanTime}}</span>
            <span slot="unit">台</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :span="5" :type="type" :parShowDate="showDataPlan.monthPercentageComplete" parShowUnit="money">
            <span slot="numTitle">月度计划完成率</span>
            <span slot="time">{{showDataPlanTime}}</span>
            <span slot="unit">%</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :span="5" :type="type" :parShowDate="showDataPlan.yearPercentageComplete">
            <span slot="numTitle">年度计划完成率</span>
            <span slot="time">【本年】</span>
            <span slot="unit">%</span>
          </index-four-row-num-unit>
        </el-row>
      </div>
      <div class="lineContent chart chartBox">
        <div class="chartBox" style="height:100%!important;" v-loading="barChart.barLoading">
          <bar-chart :chart="barChart" v-if="barChart.flag"></bar-chart>
        </div>
      </div>
    </el-card>
    <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
  </div>
</template>

<script>
import echarts from "echarts";
import qs from "querystring";
import GLOBAL from "../../api/globalVar.js";
import commonMeth from "../../api/method";
export default {
  name: "hotelSta",
  data: () => ({
    type: "timeCenter",
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/performanceSta" },
    },
    lineChart: {
      id: "lineChart",
      showTitle: true,
      flag: false,
      noData: false,
      averageTerminalNum: 0,
      sumActiveTerminalNum: 0,
      yesterdayActiveNum: 0,
      option: {
        legend: ["新增激活终端"],
        xData: [],
        series: [
          {
            name: "新增激活终端",
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                label: { show: true },
                color: GLOBAL.chartColor[0], //改变折线点的颜色
                lineStyle: {
                  color: GLOBAL.chartColor[0], //改变折线颜色
                },
              },
            },
            data: [0],
          },
        ],
      },
    },
    barChart: {
      flag: false,
      barLoading: false,
      option: {
        legend: [],
        xAxis: [],
        series: {},
      },
    },
    showDate: true,
    conditionRoom: {
      startTime: "",
      endTime: "",
      province: "",
      city: "",
      country: "",
      hotelBrand: "",
      accountManagerId: "",
      partner_id: "",
      keyword: "",
      screenType: "",
      dateType: 0,
      onlyToday: "",
    },
    accountType: "",
    conditionPlan: {
      startTime: "",
      endTime: "",
      province: "",
      dateType: 0,
      city: "",
      country: "",
      hotelBrand: "",
      accountManagerId: "",
      partnerCode: "",
    },
    topSearchShowRoom: {},
    topSearchShowPlan: {},
    barData: {},
    showData: {
      annualPlan: 0,
      hotel_num: 0,
      active_terminal_num: 0,
      percentageComplete: 0,
    },
    showDataRoom: {
      currentMonthRoomNum: 0,
      currentRoomNum: 0,
      currentYesterdayRoomNum: 0,
      currentWeekRoomNum: 0,
      currentYearRoomNum: 0,
    },
    showDataRoomMovie: {
      movieJspNumMonth: 0,
      movieJspNumYear: 0,
      movieJspNumYesterday: 0,
      movieOsNumMonth: 0,
      movieOsNumYear: 0,
      movieOsNumYesterday: 0,
      yearMovieJspNumMonth: 0,
      yearMovieJspNumYear: 0,
      yearMovieJspNumYesterday: 0,
      yearMovieOsNumMonth: 0,
      yearMovieOsNumYear: 0,
      yearMovieOsNumYesterday: 0,
    },
    showDataPlan: {
      active_room_num: 0,
      annualPlan: 0,
      monthPercentageComplete: 0,
      monthlyPlan: 0,
      yearPercentageComplete: 0,
    },
    token: "",
    auth: {},
    lineLoading: true,
    barLoading: true,
    barFlag: false,
    showDataRoomLoading: true,
    showDataRoomMovierLoading: true,
    showDataPlanLoading: true,
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    funArr: [],
    allData: {},
    quickNatureCur: [""],
    screenType: "",
    allLoadingState: false,
    showPart: {},
    apaasData: null,
  }),
  computed: {
    showDataCom() {
      return function (s, type = "", n = 2) {
        var stringNum = String(s);
        var pointIndex = stringNum.indexOf(".");
        var strLength = 0;
        var result = 0;

        if (pointIndex !== -1) {
          //有小数点
          var noPointStr = String(s).substring(0, pointIndex);
          strLength = noPointStr.length;
        } else {
          strLength = stringNum.length;
        }
        if (s === null) {
          return 0;
        } else {
          if (strLength > 5) {
            this.isShowUnit = true;
            result = Math.floor((s / 10000) * 100) / 100; //保留小数点两位
            return result.toFixed(2);
          } else {
            //小于5
            this.isShowUnit = false;
            if (type === "money") {
              n = n > 0 && n <= 20 ? n : 2;
              s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
              var l = s.split(".")[0].split("").reverse(),
                r = s.split(".")[1];
              var t = "";
              for (var i = 0; i < l.length; i++) {
                t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
              }
              return t.split("").reverse().join("") + "." + r;
            } else {
              return parseFloat(s).toLocaleString();
            }
          }
        }
      };
    },
  },
  methods: {
    SetConditionScreentype() {
      var param = qs.stringify({
        token: this.token,
      });
      if (this.apaasData) {
        this.accountType = 1;
        return;
      }
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else if (res.data.code === 100003) {
          Message.error("登录状态以超时，请重新登录");
          sessionStorage.clear();
          localStorage.clear();
          return next("/login");
        } else {
        }
      });
    },
    //监听pageSize改变
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
    },
    //监听页码值改变
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
    },
    //得到柱状数据
    getBarChart() {
      return new Promise((resolve, reject) => {
        this.barChart.flag = false;
        this.barChart.barLoading = true;
        var params = {
          token: this.token,
          partnerCode: this.conditionPlan.partner_id,
          startTime: this.conditionPlan.startTime,
          endTime: this.conditionPlan.endTime,
          onlyToday: this.conditionRoom.onlyToday,
        };
        if (this.auth.type === "htrip") {
          params.screenType = this.screenType;
        }
        this.$http
          .post(this.global.getPerformanceBar, qs.stringify(params))
          .then((res) => {
            if (res.data.code === 100000) {
              this.barData = res.data.data;
              this.barChart = res.data.data;
            } else if (res.data.code === 100003) {
              Message.error("登录状态以超时，请重新登录");
              sessionStorage.clear();
              localStorage.clear();
              return next("/login");
            } else {
              this.$message.error("失败");
            }
            this.barChart.flag = true;
            this.barChart.barLoading = false;
          });
      });
    },
    //数据统计-房间
    showDataMethRoom() {
      this.showDataRoomLoading = true;
      this.showDataRoomMovieLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.conditionRoom.hotel_brand,
        citycode: this.conditionRoom.citycode,
        hotel_use_state: this.conditionRoom.hotel_use_state,
        province: this.conditionRoom.provinceLabel,
        city: this.conditionRoom.cityLabel,
        county: this.conditionRoom.countyLabel,
        accountManagerId: this.conditionRoom.BD,
        partner_id: this.conditionRoom.partner_id,
        partnerCode: this.conditionRoom.partner_id,
        startTime: this.conditionRoom.startTime,
        endTime: this.conditionRoom.endTime,
        onlyToday: this.conditionRoom.onlyToday,
        keyword: this.conditionRoom.keyword,
        dateType: this.conditionRoom.dateType,
        refresh: false,
        // screenType:this.conditionRoom.screenType,
      };
      params.screenType = this.screenType;
      this.$http
        .post(
          this.global.getBusinessActiveRoomNumRealTime,
          qs.stringify(params)
        )
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            // 本次修改 从此接口获取全新数据进行重新梳理渲染逻辑
            this.showDataRoom = res.data.data;
          } else if (res.data.code === 100003) {
            Message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            return next("/login");
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDataRoomLoading = false;
        });
      var params = {
        token: this.token,
        accountManagerId: this.conditionRoom.BD,
        startTime: this.conditionRoom.startTime,
        endTime: this.conditionRoom.endTime,
        dateType: this.conditionRoom.dateType,
      };
      this.$http
        .post(this.global.getAccountMovies, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            console.log(res.data.data, "showDataRoomMovie");
            // 本次修改 从此接口获取全新数据进行重新梳理渲染逻辑
            this.showDataRoomMovie = res.data.data;
          } else if (res.data.code === 100003) {
            Message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            return next("/login");
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDataRoomMovieLoading = false;
        });
    },
    showDataMethRoomfrist() {
      this.showDataRoomLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.conditionRoom.hotel_brand,
        citycode: this.conditionRoom.citycode,
        hotel_use_state: this.conditionRoom.hotel_use_state,
        province: this.conditionRoom.provinceLabel,
        city: this.conditionRoom.cityLabel,
        county: this.conditionRoom.countyLabel,
        accountManagerId: this.conditionRoom.BD,
        partner_id: this.conditionRoom.partner_id,
        partnerCode: this.conditionRoom.partner_id,
        startTime: this.conditionRoom.startTime,
        endTime: this.conditionRoom.endTime,
        onlyToday: this.conditionRoom.onlyToday,
        keyword: this.conditionRoom.keyword,
        dateType: this.conditionRoom.dateType,
        refresh: true,
        // screenType:this.conditionRoom.screenType,
      };
      params.screenType = this.screenType;
      this.$http
        .post(
          this.global.getBusinessActiveRoomNumRealTime,
          qs.stringify(params)
        )
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showDataRoom = res.data.data;
          } else if (res.data.code === 100003) {
            Message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            return next("/login");
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDataRoomLoading = false;
        });
      var params = {
        token: this.token,
        accountManagerId: this.conditionRoom.BD,
        startTime: this.conditionRoom.startTime,
        endTime: this.conditionRoom.endTime,
        dateType: this.conditionRoom.dateType,
      };
      this.$http
        .post(this.global.getAccountMovies, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            console.log(res.data.data, "showDataRoomMovie");
            // 本次修改 从此接口获取全新数据进行重新梳理渲染逻辑
            this.showDataRoomMovie = res.data.data;
          } else if (res.data.code === 100003) {
            Message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            return next("/login");
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDataRoomMovieLoading = false;
        });
    },
    //数据统计-计划
    showDataMethPlan() {
      this.showDataPlanLoading = true;
      var params = {
        token: this.token,
        partnerCodeList: this.conditionPlan.partner_id,
        startTime: this.conditionPlan.startTime,
        endTime: this.conditionPlan.endTime,
        onlyToday: this.conditionRoom.onlyToday,
        refresh: false,
      };
      params.screenType = this.screenType;
      this.$http
        .post(this.global.getperformancestatistics, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showDataPlan = res.data.data;
          } else if (res.data.code === 100003) {
            Message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            return next("/login");
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDataPlanLoading = false;
        });
    },
    showDataMethPlanfrist() {
      this.showDataPlanLoading = true;
      var params = {
        token: this.token,
        partnerCodeList: this.conditionPlan.partner_id,
        startTime: this.conditionPlan.startTime,
        endTime: this.conditionPlan.endTime,
        onlyToday: this.conditionRoom.onlyToday,
        refresh: true,
      };
      params.screenType = this.screenType;
      this.$http
        .post(this.global.getperformancestatistics, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showDataPlan = res.data.data;
          } else if (res.data.code === 100003) {
            Message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            return next("/login");
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDataPlanLoading = false;
        });
    },
    //折线图
    getLineChart() {
      this.lineChart.flag = false;
      this.lineChart.option.xData = [];
      this.lineLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.conditionRoom.hotel_brand,
        citycode: this.conditionRoom.citycode,
        hotel_use_state: this.conditionRoom.hotel_use_state,
        province: this.conditionRoom.provinceLabel,
        city: this.conditionRoom.cityLabel,
        county: this.conditionRoom.countyLabel,
        accountManagerId: this.conditionRoom.BD,
        partner_id: this.conditionRoom.partner_id,
        partnerCode: this.conditionRoom.partner_id,
        startTime: this.conditionRoom.startTime,
        endTime: this.conditionRoom.endTime,
        onlyToday: this.conditionRoom.onlyToday,
        keyword: this.conditionRoom.keyword,
        dateType: this.conditionRoom.dateType,
        // screenType:this.conditionRoom.screenType,'
        refresh: false,
      };
      params.screenType = this.screenType;
      this.$http
        .post(this.global.getBusinessPerformanceLine, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var chartData = res.data.data;
            if (chartData.xAxis) {
              this.lineChart.option.xData = chartData.xAxis;
            } else if (res.data.code === 100003) {
              Message.error("登录状态以超时，请重新登录");
              sessionStorage.clear();
              localStorage.clear();
              return next("/login");
            } else {
              if (this.conditionRoom.startTime && this.conditionRoom.endTime) {
                this.lineChart.option.xData = [
                  this.conditionRoom.startTime,
                  this.conditionRoom.endTime,
                ]; //图例
              }
            }
            this.lineChart.yesterdayActiveNum = chartData.yesterdayActiveNum;
            this.lineChart.sumActiveTerminalNum =
              chartData.sumActiveTerminalNum;
            this.lineChart.averageTerminalNum = chartData.averageTerminalNum;
            var dataTransEd = commonMeth.dataTrans(chartData);
            this.lineChart.option.series[0].data =
              dataTransEd.series["新增激活终端"];
            this.lineChart.flag = true;
          }
          this.lineLoading = false;
        })
        .catch(() => {
          this.lineLoading = false;
          this.$message.error("失败");
        });
    },
    getLineChartfrist() {
      this.lineChart.flag = false;
      this.lineChart.option.xData = [];
      this.lineLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.conditionRoom.hotel_brand,
        citycode: this.conditionRoom.citycode,
        hotel_use_state: this.conditionRoom.hotel_use_state,
        province: this.conditionRoom.provinceLabel,
        city: this.conditionRoom.cityLabel,
        county: this.conditionRoom.countyLabel,
        accountManagerId: this.conditionRoom.BD,
        partner_id: this.conditionRoom.partner_id,
        partnerCode: this.conditionRoom.partner_id,
        startTime: this.conditionRoom.startTime,
        endTime: this.conditionRoom.endTime,
        onlyToday: this.conditionRoom.onlyToday,
        keyword: this.conditionRoom.keyword,
        dateType: this.conditionRoom.dateType,
        refresh: true,
      };
      params.screenType = this.screenType;
      this.$http
        .post(this.global.getBusinessPerformanceLine, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var chartData = res.data.data;
            if (chartData.xAxis) {
              this.lineChart.option.xData = chartData.xAxis;
            } else if (res.data.code === 100003) {
              Message.error("登录状态以超时，请重新登录");
              sessionStorage.clear();
              localStorage.clear();
              return next("/login");
            } else {
              if (this.conditionRoom.startTime && this.conditionRoom.endTime) {
                this.lineChart.option.xData = [
                  this.conditionRoom.startTime,
                  this.conditionRoom.endTime,
                ]; //图例
              }
            }
            this.lineChart.yesterdayActiveNum = chartData.yesterdayActiveNum;
            this.lineChart.sumActiveTerminalNum =
              chartData.sumActiveTerminalNum;
            this.lineChart.averageTerminalNum = chartData.averageTerminalNum;
            var dataTransEd = commonMeth.dataTrans(chartData);
            this.lineChart.option.series[0].data =
              dataTransEd.series["新增激活终端"];
            this.lineChart.flag = true;
          }
          this.lineLoading = false;
        })
        .catch(() => {
          this.lineLoading = false;
          this.$message.error("失败");
        });
    },
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "factory"
      ) {
        this.topSearchShowRoom = {
          date: true,

          businessMonth: true,
        };
        this.topSearchShowPlan = {
          month: true,
        };
      }
      if (auth_type === "group") {
        this.topSearchShowRoom = {
          date: true,
          businessMonth: true,
        };
        this.topSearchShowPlan = {
          month: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShowRoom = {
          // BD: true,
          date: true,
          businessMonth: true,
        };
        this.topSearchShowPlan = {
          coopType: true,
          month: true,
        };
      }
      this.topSearchShowRoom.keywordPlaceholder = "请输入酒店名称关键词";
    },
    getScreenTypeSearchShow() {
      this.showPart = {
        self: true,
        other: false,
        videoRoom: true,
      };
    },
    getConditionRoom(val) {
      console.log(val);
      this.conditionRoom.hotel_brand = Array.isArray(val.hotel_brand)
        ? val.hotel_brand.join(",")
        : ""; //ka
      if (typeof val.citycode === "string") {
        this.conditionRoom.citycode = val.citycode;
      } else {
        this.conditionRoom.citycode = Array.isArray(val.citycode)
          ? val.citycode.join(",")
          : ""; //citycode
      }
      this.conditionRoom.hotel_use_state = Array.isArray(val.hotel_use_state)
        ? val.hotel_use_state.join(",")
        : ""; //ka
      this.conditionRoom.provinceLabel = Array.isArray(val.provinceLabel)
        ? val.provinceLabel.join(",")
        : ""; //provinceLabel
      this.conditionRoom.cityLabel = val.cityLabel;
      this.conditionRoom.countyLabel = val.countyLabel;
      this.conditionRoom.BD = Array.isArray(val.BD) ? val.BD.join(",") : "";
      this.conditionRoom.dateType = 0;
      this.conditionRoom.endTime = val.endTime;
      this.conditionRoom.startTime = val.startTime;
      if (this.conditionRoom.startTime && this.conditionRoom.startTime != "") {
        this.conditionRoom.dateType = 1;
      }
      if (val.onlyToday && val.onlyToday !== "") {
        this.conditionRoom.endTime = val.onlyToday;
        this.conditionRoom.startTime =
          val.onlyToday.substring(0, 10) + " 00:00:00";
        this.conditionRoom.dateType = 2;
      }
      if (val.month && val.month !== "") {
        this.conditionRoom.startTime = val.month;
        this.conditionRoom.endTime = val.month;
        this.conditionRoom.dateType = 3;
      }
      console.log(this.conditionRoom);
      this.conditionRoom.partner_id = Array.isArray(val.noTypePartnerCode)
        ? val.noTypePartnerCode.join(",")
        : "";
      this.conditionRoom.keyword = val.keyword;
      // this.conditionRoom.screenType=val.screenType
      this.getLineChart();
      this.showDataMethRoom();
    },
    getConditionPlan(val) {
      this.conditionPlan.partner_id = Array.isArray(val.noTypePartnerCode)
        ? val.noTypePartnerCode.join(",")
        : "";
      this.conditionPlan.startTime = val.startTime;
      this.conditionPlan.endTime = val.endTime;
      this.showDataMethPlan();
      this.getBarChart();
    },
    getCurrentDate() {
      var obj = new Date();
      // var date=obj.getDate();
      var year = obj.getFullYear();
      var month = obj.getMonth() + 1;
      var day = obj.getDate();
      return year + "-" + month + "-" + "day";
      // return curDate;
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    screenTypeSearch(screenType) {
      this.screenType = screenType;
      this.getLineChart();
      this.showDataMethRoom();
      this.showDataMethPlan();
      this.getBarChart();
      this.conditionRoom.screenType = screenType;
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.apaasData = sessionStorage.getItem("apaasData");
    if (this.apaasData) {
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      commonMeth.getTreeData().then((res) => {
        this.funArr = res.funArr;
      });
    }
    this.conditionRoom.screenType = "self";
    this.screenType = "self";
    this.getTopSearchShow();
    this.getScreenTypeSearchShow();
    this.showDataMethRoomfrist();
    this.showDataMethPlanfrist();
    this.getBarChart();
    this.lineChart.option.series.forEach((item, index) => {
      item.symbolSize = this.global.symbolSize;
    });
    this.getLineChartfrist();
    this.SetConditionScreentype();
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
    showDataRoomTime() {
      if (this.conditionRoom.startTime === "") {
        return "【今日】";
      } else if (this.conditionRoom.dateType == 3) {
        return "【" + this.conditionRoom.startTime + "】";
      } else {
        var time =
          "【" +
          this.conditionRoom.startTime +
          "~" +
          this.conditionRoom.endTime +
          "】";
        return time;
      }
    },
    showDataYesRoomTime() {
      if (this.conditionRoom.startTime === "") {
        return "【昨日】";
      } else if (this.conditionRoom.dateType == 3) {
        return "【" + this.conditionRoom.startTime + "】";
      } else {
        var time =
          "【" +
          this.conditionRoom.startTime +
          "~" +
          this.conditionRoom.endTime +
          "】";
        return time;
      }
    },
    showDataPlanTime() {
      if (this.conditionPlan.startTime === "") {
        return "【本月】";
      } else {
        var time =
          "【" +
          this.conditionPlan.startTime +
          "~" +
          this.conditionPlan.endTime +
          "】";
        return time;
      }
    },
    showRateText() {
      if (
        this.conditionPlan.startTime === "" ||
        this.conditionPlan.startTime === this.conditionPlan.endTime
      ) {
        return "日活率";
      } else {
        // var time=this.condition.startTime+'~'+this.condition.endTime;
        return "平均日活率";
      }
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    twoPointCom() {
      return function (x) {
        !x ? (x = 0) : "";
        var f = parseFloat(x);
        if (isNaN(f)) {
          return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf(".");
        if (rs < 0) {
          rs = s.length;
          s += ".";
        }
        while (s.length <= rs + 2) {
          s += "0";
        }
        return s;
      };
    },
    quickNatureCurActive() {
      return function (val) {
        var str = "";
        if (this.quickNatureCur.indexOf(val) !== -1) {
          str = "active";
        }
        return str;
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(
        this.barChart.barLoading,
        this.lineLoading,
        this.showDataRoomLoading,
        this.showDataPlanLoading
      );
    },
  },
};
</script>

<style scoped lang="scss">
.hotelBrand {
  width: 100%;
}
.rowBox {
  margin-top: 20px;
}
.vertical {
  box-sizing: border-box;
  .grid-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;

    .numTitle {
      margin-bottom: 20px;
    }
    .num {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.bg-purple {
  /*background: #d3dce6;*/
  background: #fff;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  /*min-height: 36px;*/
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.lineContent {
  margin-top: 20px;
  .lineTitle {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
    .line {
      width: 3px;
      /*height:100%;*/
      height: 25px;
      background: #797979;
      margin-right: 10px;
    }
  }
}
.chartBox {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  #chart,
  #barChart {
    flex: 1;
  }
}
.numTitle {
  /*margin-bottom:20px;*/
  /*display:flex;*/
  /*justify-content: space-between;*/
}
.num {
  font-size: 24px;
  margin-right: 10px;
}
.lineNum {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(209, 217, 227, 1);
  border-radius: 6px;
  .top {
    margin-bottom: 15px;
  }
  .left {
    display: flex;
    align-items: flex-end;
  }
}
.itemMarginBottom {
  margin-bottom: 20px;
}
.itemMarginLeft {
  margin-left: 10px;
}
.indexNum.rightNumBox {
  font-size: 24px !important;
}
.rightItem {
  display: flex;
  align-items: center;
}
.indexNum {
  /*font-size:30px!important;*/
}
.outChartBox {
  height: 540px;
}
.titleBox {
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.numBox {
  margin-left: 10px;
}
.quickSearch {
  display: flex;
}
.quickSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}
.quickSearch .item.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.quickDateSearch {
  margin-right: 30px;
}
.quickDateSearch:last-child {
  margin-right: 0 !important;
}
.quickDateBox {
  display: flex;
  align-items: center;
}
.quickDateSearch {
  display: flex;
}
.quickDateSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}
.quickDateSearch .item:last-child {
  margin-right: 0;
}
.quickDateSearch .item.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.quickSearchBox {
  display: flex;
}
.impcity {
  position: absolute;
  right: 150px;
}
.channsta {
  display: flex;
}
.timecontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeshow {
  flex: 1;
}
.timeshownum {
  font-family: "DINAlternate";
}
.timeshow1 {
  display: flex;
  justify-content: space-between;
}
.iconCai {
  font-size: 12px;
  height: 14px;
  width: 14px;
  border-radius: 7px;
  display: inline-block;
  border: 1px solid #e6a23c;
  color: #e6a23c;
  margin-right: 5px;
}
.el-icon-warning-outline {
  color: #409eff;
}
</style>
