import TopBreadIm from './TopBread'
import TopSearchIm from './search/TopSearch'
import ScreenTypeSearchIm from './search/ScreenTypeSearch'
import ScreenType from './search/ScreenType'
import DataAssetSearch from './search/DataAssetSearch'
import FourRowIm from './FourRow'
import TwoRowIm from './TwoRow'
import RejectDialogIm from './action/RejectDialog' //审核驳回框
import LineChartIm from './LineChart'
import BarChartIm from './BarChart'
import LineTitleIm from './LineTitle'
import InTabMovieIm from './incomeTab/Movie'
import incomeHistorylm from "./incomeTab/incomeHistory";
import iddTabMovieIm from './incomeTab/iddMovie'
import InTabAdIm from './incomeTab/Ad'
import InTabBrushIm from './incomeTab/Brush'
import InTabMarketIm from './incomeTab/Market'
import InTabViewMovieIm from './incomeTab/ViewMovie'
import QuickSearchIm from './incomeTab/QuickSearch'
import TwoLineIm from './TwoLine'
import TableListIm from './TableList'
import MapChartIm from './MapChart' //地图
import SubAlertIm from './SubAlert' //
import DetailHasIm from './detailPer/DetailHas' //运营|所有 酒店列表 tab1 详情  有权限
import DetailNoIm from './detailPer/DetailNo' //运营|所有 酒店列表 tab1 详情  没有权限
import DetailJupingIm from './detailPer/DetailJuping' //运营|所有 酒店列表 tab1 详情  聚屏商
import ConfirmDialogIm from './detailPer/ConfirmDialog'
import EhotelDialogIm from './detailPer/EhotelDialog' //运营|所有 酒店列表 tab1 详情  没有权限
import hotelItemIm from './detailPer/hotelItem.vue' //运营|所有 酒店列表 tab1 详情  没有权限
import OpTabEditIm from './operteTab/Edit' //运营酒店列表 tab2  素材编辑
import OpTabEditIm1 from './operteTab/Edit1' //运营酒店列表 tab2  素材编辑
import OpTabEditIm2 from './operteTab/Edit2' //运营酒店列表 tab2  素材编辑
import EditWelDetailIm from './operteTab/EditWelDetail' //运营酒店列表 tab2  素材编辑-hihotel
import EditWelHihotelIm from './operteTab/editWel/EditWelHihotel'
import EditWelEhotelBtnIm from './operteTab/editWel/EditWelEhotelBtn'
import EditWelEhotelLogoIm from './operteTab/editWel/EditWelEhotelLogo'
import editWelEhotelNoneIm from './operteTab/editWel/EditWelEhotelNone'
import EditHotelServiceThreeIm from './operteTab/editHotelService/EditHotelServiceThree'
import EditHotelServiceOneIm from './operteTab/editHotelService/EditHotelServiceOne'
import EditJumpToBigPicIm from './operteTab/editJumpToBigPic/EditJumpToBigPic'
import uploadPicSortIm from './operteTab/editHotelService/UploadPicSort'
import AllModuleIm from './operteTab/editHotelService/AllModule'
import ConfigBtnIm from './operteTab/editHotelService/ConfigBtn'
import EditHomeDetailIm from './operteTab/EditHomeDetail' //运营酒店列表 tab2  素材编辑
import EditHomeHihotelIm from './operteTab/editHome/EditHomeHihotel' //运营酒店列表 tab2  素材编辑
import EditHomeEhotelStandardIm from './operteTab/editHome/EditHomeEhotelStandard' //运营酒店列表 tab2  素材编辑-ehotel标准版
import EditHomeEhotelRightTextIm from './operteTab/editHome/EditHomeEhotelRightText' //运营酒店列表 tab2  素材编辑-ehotel文字版
import EditHomeEhotelRightImgIm from './operteTab/editHome/EditHomeEhotelRightImg' //运营酒店列表 tab2  素材编辑-ehotel图片版
import ChooseRoomIm from './operteTab/drawer/ChooseRoom'
import HotelServiceDetailIm from './operteTab/drawer/HotelServiceDetail'
import PublishListIm from './operteTab/drawer/PublishList'
import OpTabAdIm from './operteTab/Ad' //运营酒店列表 tab3  广告位配置
import UploadDialogIm from './operteTab/UploadDialog' //运营酒店列表 编辑详情上传图片弹窗
import MarqueeIm from './operteTab/Marquee' //运营酒店列表 编辑详情文字横向滚动
import RankChartIm from './RankChart' //运营酒店列表 tab2  素材编辑
import AllTabPersonIm from './allTab/Person' //所有酒店列表 tab2  实施人员
import AllTabOnlineIm from './allTab/Online' //所有酒店列表 tab3  上线情况
import AllTabOnlineRoomIm from './allTab/OnlineRoom' //所有酒店列表 tab3  上线房间情况
import AllTabGuestRoomIm from './allTab/GuestRoom' //所有酒店列表 tab3  客房管理
import AllTabAuthorizationIm from './allTab/Authorization' //所有酒店列表 授权管理
import HotelManIndexIm from './hotelMan/HotelManIndex' //酒店列表
import HotelAllUoko from './hotelMan/HotelAllUoko' //酒店列表 uoko
import HotelAll from './hotelMan/HotelAll' //新所有酒店模板
import ChoiceRoomDialogIm from './material/ChoiceRoomDialog' //酒店列表


import AdCusTabCusIm from './adCusTab/Custom' //自定义广告管理  tab1 关联携旅刊位
import AdCusTabRelaIm from './adCusTab/Relation' //自定义广告管理  tab1 关联携旅刊位
import CoopTabBasic from './coopTab/Basic' //合作伙伴  tab1 基础信息
import CoopTabFinance from './coopTab/Finance' //合作伙伴  tab2 财务信息
import CoopTabType from './coopTab/CoopType' //合作伙伴  tab3 合作类型
import CoopTabRate from './coopTab/Rate' //合作伙伴 计划及分成比例
import CoopTabRateItem from './coopTab/RateItem' //合作伙伴 计划及分成比例每个item
import CoopTabExecuting from './coopTab/Executing' //合作伙伴 计划及分成比例 tab 执行中
import CoopTabHotel from './coopTab/Hotel' //合作伙伴  tab5 酒店列表
import SysTabRate from './sysTab/Rate' //系统设置  tab1 目标及分成比例

//首页显示组件
import IndexTwoRowIm from './index/IndexTwoRow'

import IndexFourRowIm from './index/IndexFourRow'
import IndexFourRowNumUnitIm from './index/IndexFourRowNumUnit'
import IndexThreeRow from './index/IndexThreeRow'

import DataShowRightIm from './index/DataShowRight'
import DataShowBottomIm from './index/DataShowBottom'
import IndexLineChartIm from './index/IndexLineChart' //系统设置  tab1 目标及分成比例
import indexShowEcharts from './index/indexShowEcharts.vue' //系统设置  针对首页图标增加 独立影视收益
import oneYaxisLineChartIm from './index/oneYaxisLineChart'
import foldTopSearchIm from './search/FoldTopSearch' //折叠筛选项
import BackIm from './Back'
import ExportBtnIm from './ExportBtn'
import ExcelExport from "./ExcelExport"; // 表格导出

import ThreePartShowIm from './ThreePartShow' //收益统计-数据展示
import MoviePartIm from './MoviePart' //收益统计-数据展示
import SumBox from './dataAsset/SumBox' //收益统计-数据展示
import SoftNum from './dataAsset/SoftNum' //收益统计-数据展示
import SoftHeader from './dataAsset/SoftHeader' //收益统计-数据展示
import NoDataIm from './NoData' //暂无数据
import TestOneIm from './TestOne' //暂无数据

import OpTabEditChange from './operteTab/visualEdit/visualEdit' // 可视化编辑重构 
import labelShow from './labelManagement/labelShow.vue' // 可视化编辑重构 
import contractListShow from "./index/contractListShow.vue" // 合同列表
import dataShowBox from "./index/dataShowBox.vue" //数据概览

// 运维集成组件
import systemDrawer from "./operation/systemDrawer.vue"
import remoteMaintenance from "./operation/remoteMaintenance.vue"
import operationTable from "./operation/components/common/operationTable.vue"
import launcher from "./operation/components/system/launcher.vue"
import hotelSelect from "./operation/components/hotelSelect/index.vue"

// 新版素材编辑
import visiualSetting from './operteTab/visualSetVersion/index.vue' //运营酒店列表 tab2  素材编辑

import apkDown from "./allTab/apkDown.vue"
// import TestIm from './Table'
const common = {
    install: function(Vue) {
        // 新版素材编辑
        Vue.component('visiualSetting', visiualSetting);

        // 运维集成组件
        Vue.component('systemDrawer', systemDrawer);
        Vue.component('remoteMaintenance', remoteMaintenance);
        Vue.component('operationTable', operationTable);
        Vue.component('launcher', launcher);
        Vue.component('hotelSelect', hotelSelect);


        // 运维集成组件
        Vue.component('apkDown', apkDown);
        Vue.component('TopBread', TopBreadIm);
        Vue.component('TopSearch', TopSearchIm);
        Vue.component('ScreenTypeSearch', ScreenTypeSearchIm);
        Vue.component('ScreenType', ScreenType);
        Vue.component('TopData', DataAssetSearch);
        Vue.component('FourRow', FourRowIm);
        Vue.component('TwoRow', TwoRowIm);
        Vue.component('RejectDialog', RejectDialogIm);
        Vue.component('LineChart', LineChartIm);
        Vue.component('BarChart', BarChartIm);
        Vue.component('LineTitle', LineTitleIm);
        Vue.component('inTabMovie', InTabMovieIm); //
        Vue.component('historySta', incomeHistorylm); //
        Vue.component('iddTabMovie', iddTabMovieIm); //收益统计iddTabMovieIm
        Vue.component('inTabAd', InTabAdIm);
        Vue.component('inTabBrush', InTabBrushIm);
        Vue.component('inTabMarket', InTabMarketIm);
        Vue.component('InTabViewMovie', InTabViewMovieIm);
        Vue.component('QuickSearch', QuickSearchIm);
        Vue.component('TwoLine', TwoLineIm);
        Vue.component('TableList', TableListIm);
        Vue.component('MapChart', MapChartIm);
        Vue.component('SubAlert', SubAlertIm);
        Vue.component('DetailHas', DetailHasIm);
        Vue.component('DetailNo', DetailNoIm);
        Vue.component('DetailJuping', DetailJupingIm);
        Vue.component('ConfirmDialog', ConfirmDialogIm);
        Vue.component('EhotelDialog', EhotelDialogIm);
        Vue.component('hotelItem', hotelItemIm);
        Vue.component('OpTabEdit', OpTabEditIm);
        Vue.component('OpTabEdit1', OpTabEditIm1);
        Vue.component('OpTabEdit2', OpTabEditIm2);
        Vue.component('EditWelDetail', EditWelDetailIm);
        Vue.component('EditHomeDetail', EditHomeDetailIm);
        Vue.component('EditHomeHihotel', EditHomeHihotelIm);
        Vue.component('EditHomeEhotelStandard', EditHomeEhotelStandardIm);
        Vue.component('EditHomeEhotelRightText', EditHomeEhotelRightTextIm);
        Vue.component('EditHomeEhotelRightImg', EditHomeEhotelRightImgIm);
        Vue.component('ChooseRoom', ChooseRoomIm);
        Vue.component('HotelServiceDetail', HotelServiceDetailIm);
        Vue.component('PublishList', PublishListIm);
        Vue.component('EditWelHihotel', EditWelHihotelIm);
        Vue.component('EditWelEhotelBtn', EditWelEhotelBtnIm);
        Vue.component('EditWelEhotelLogo', EditWelEhotelLogoIm);
        Vue.component('editWelEhotelNone', editWelEhotelNoneIm);
        Vue.component('EditHotelServiceThree', EditHotelServiceThreeIm);
        Vue.component('EditHotelServiceOne', EditHotelServiceOneIm);
        Vue.component('editJumpToBigPic', EditJumpToBigPicIm);
        Vue.component('uploadPicSort', uploadPicSortIm);
        Vue.component('AllModule', AllModuleIm);
        Vue.component('ConfigBtn', ConfigBtnIm);
        Vue.component('UploadDialog', UploadDialogIm);
        Vue.component('Marquee', MarqueeIm);
        Vue.component('RankChart', RankChartIm);
        Vue.component('OpTabAd', OpTabAdIm);
        Vue.component('AllTabPerson', AllTabPersonIm);
        Vue.component('AllTabOnline', AllTabOnlineIm);
        Vue.component('AllTabOnlineRoom', AllTabOnlineRoomIm);
        Vue.component('AllTabGuestRoom', AllTabGuestRoomIm);
        Vue.component('AllTabAuthorizationIm', AllTabAuthorizationIm);
        Vue.component('HotelManIndex', HotelManIndexIm);
        Vue.component('HotelAllUoko', HotelAllUoko);
        Vue.component('HotelAll', HotelAll)
        Vue.component('ChoiceRoomDialog', ChoiceRoomDialogIm);
        Vue.component('AdCusTabCus', AdCusTabCusIm);
        Vue.component('AdCusTabRela', AdCusTabRelaIm);
        Vue.component('CoopTabBasic', CoopTabBasic);
        Vue.component('CoopTabFinance', CoopTabFinance);
        Vue.component('CoopTabType', CoopTabType);
        Vue.component('CoopTabRate', CoopTabRate);
        Vue.component('CoopTabRateItem', CoopTabRateItem);
        Vue.component('CoopTabExecuting', CoopTabExecuting);
        Vue.component('CoopTabHotel', CoopTabHotel);
        Vue.component('SysTabRate', SysTabRate);
        Vue.component('IndexTwoRow', IndexTwoRowIm);
        Vue.component('IndexFourRow', IndexFourRowIm);
        Vue.component('IndexFourRowNumUnit', IndexFourRowNumUnitIm);
        Vue.component('IndexThreeRow', IndexThreeRow);
        Vue.component('DataShowRight', DataShowRightIm);
        Vue.component('DataShowBottom', DataShowBottomIm);
        Vue.component('IndexLineChart', IndexLineChartIm);
        Vue.component('indexShowEcharts', indexShowEcharts);
        Vue.component('oneYaxisLineChart', oneYaxisLineChartIm);
        Vue.component('foldTopSearch', foldTopSearchIm);
        Vue.component('Back', BackIm);
        Vue.component('ExportBtn', ExportBtnIm);
        Vue.component('ExcelExport', ExcelExport);
        Vue.component('ThreePartShow', ThreePartShowIm);
        Vue.component('MoviePart', MoviePartIm);
        Vue.component('SumBox', SumBox);
        Vue.component('SoftNum', SoftNum);
        Vue.component('SoftHeader', SoftHeader);
        Vue.component('NoData', NoDataIm);
        Vue.component('TestOne', TestOneIm);
        Vue.component('OpTabEditChange', OpTabEditChange);
        Vue.component('labelShow', labelShow);
        Vue.component('contractListShow', contractListShow);
        Vue.component('dataShowBox', dataShowBox);
    }
};
export default common;