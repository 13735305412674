<!-- 签名添加/修改弹窗 -->
<template>
  <el-dialog
    title="签名信息"
    :visible.sync="dialogVisible"
    width="550px"
    @open="open"
    @closed="handleClose"
  >
    <el-form :model="form" ref="form" label-width="120px">
      <el-form-item label="签名名称：" prop="name">
        <el-input v-model="form.name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="签名简写：" prop="sign_short">
        <el-input v-model="form.sign_short" placeholder=""></el-input>
      </el-form-item>
        <el-form-item label="签名MD5：" prop="sign_md5">
        <el-input v-model="form.sign_md5" placeholder=""></el-input>
      </el-form-item>
      <!-- <el-form-item label="上传签名秘钥：" prop="sort">
        <UploadFile text="上传签名秘钥" v-model="form.signKey"></UploadFile>
      </el-form-item>
      <el-form-item label="上传签名pk：" prop="sort">
        <UploadFile text="上传签名pk" v-model="form.signPk"></UploadFile>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import UploadFile from "../../../../components/common/operation/components/uploadFile";
import { addSign, editSign } from "../api/sign";
export default {
  name: "signOper",
  // components: { UploadFile },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      form: {
        name: "",
        sign_short: "",
        sign_md5:"",
      },
    };
  },
  methods: {
    open() {
      if (JSON.stringify(this.current) != "{}") {
        this.form = {...this.current};
      } else {
        this.form = {
          name: "",
          sign_short: "",
          sign_md5:"",
        };
      }
    },
    submit() {
      let params = {...this.form}
      // 新增
      if (JSON.stringify(this.current) == "{}") {
        addSign(params).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.dialogVisible = false;
            this.$emit("refresh");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
      // 编辑
      else {
        params.id = this.current.id;
        editSign(params).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.dialogVisible = false;
            this.$emit("refresh");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
