<template>
    <div class="numShowItem">
        <div class="polygonItems flexFullBox"
             v-for="(item,index) in data"
             :key="index">
            <div class="centerBox">
                <div class="fontBold">
                    <span class="num">{{showDataCom(item.number).result}}</span>
                    <span class="unit" v-if="showDataCom(item.number).showUnit">万</span>
                    <span  class="unit">{{unitProp}}</span>
                </div>
                <div class="widthCompare">
                    <div class="leftBox rate" v-if="rateFilter.isYear==1" :class="[rateTextStyleCom(item.yearLevel,item.yearPercent)]">
                        同比：{{item.yearPercent}}%
                        <i class="iconfont" v-if="rateTextStyleCom(item.yearLevel,item.yearPercent)==='redFonts'">&#xe633;</i>
                        <i class="iconfont" v-if="rateTextStyleCom(item.yearLevel,item.yearPercent)==='greenFonts'">&#xe631;</i>
                        <span v-if="rateTextStyleCom(item.yearLevel,item.yearPercent)==='grayFonts'">-</span>
                    </div>
                    <div class="rightBox rate" v-if="rateFilter.isChain==1" :class="[rateTextStyleCom(item.chainLevel,item.chainPercent)]">
                        环比：{{item.chainPercent}}%
                        <i class="iconfont" v-if="rateTextStyleCom(item.chainLevel,item.chainPercent)==='redFonts'">&#xe633;</i>
                        <i class="iconfont" v-if="rateTextStyleCom(item.chainLevel,item.chainPercent)==='greenFonts'">&#xe631;</i>
                        <i class="iconfont" v-if="rateTextStyleCom(item.chainLevel,item.chainPercent)==='grayFonts'">-</i>
                        <!--<i v-if="rateTextStyleCom(item.chainLevel,item.chainLevel)==='grayFonts'">-</i>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SoftNum",
        props:{
            data:{},
            rateFilter:{},
            unitProp:{
                type:String,
                default:'台'
            },
        },
        data:()=>({

        }),
        computed:{
            showDataComTest(){
                return function(val){
                    if(val===null){
                        return 0;
                    }else{
                        return parseFloat(val).toLocaleString();
                    }
                }
            },
            showDataCom(){
                return function(s,type='',n=2,unitType){
                    if(s=='-'){
                        return {
                            result:s,
                            showUnit:false
                        };
                    }  else if(s=='0'){
                        return {
                            result:'-',
                            showUnit:false
                        };
                    }else{
                        var stringNum=String(s);
                        var pointIndex=stringNum.indexOf('.');
                        var strLength=0;
                        var result=0;
                        if(pointIndex!==-1){  //有小数点
                            var noPointStr=String(s).substring(0,pointIndex);
                            strLength=noPointStr.length;
                        }else{
                            strLength=stringNum.length;
                        }
                        if(s===null){
                            return {
                                result:'-',
                                showUnit:false
                            };
                        }else{
                            if(strLength>5){
                                result = Math.floor((s /10000) * 100) / 100; //保留小数点两位
                                return {
                                    result:result.toFixed(2),
                                    showUnit:true
                                };
                            }else{   //小于5
                                // this.modelTypeUnit(unitType,false);
                                if(type==='money'){
                                    n = n > 0 && n <= 20 ? n : 2;
                                    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                                    var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                                    var t = "";
                                    for (var i = 0; i < l.length; i++) {
                                        t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                                    }
                                    return {
                                        result:t.split("").reverse().join("") + "." + r,
                                        showUnit:false
                                    };
                                }else{
                                    return {
                                        result: parseFloat(s).toLocaleString(),
                                        showUnit:false
                                    };
                                }
                            }
                        }
                    }
                }
            },
            rateTextStyleCom(){
                return function(level,percent){
                    var color='';
                    var icon='';
                    var upIcon='<i class="iconfont">&#xe633;</i>';
                    var downIcon='<i class="iconfont">&#xe631;</i>';
                    if(level == 1 && percent!=0.00){
                        icon=upIcon;
                        color='redFonts'
                    }else if(level == 1 && percent==0.00){
                        icon='';
                        color='grayFonts'
                    }else if(level != 1){
                        icon='downIcon';
                        color='greenFonts'
                    }
                    return color;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
::-webkit-scrollbar{
    height: 3px;
}
    .numShowBox{
        flex:1;
        height:456px;
        /*background:red;*/
    }
    .numShowItem{
        height:111px;
        display:flex;
        padding:15px 0;
        box-sizing:border-box;
        /*overflow-y: auto;*/
    }
    .centerBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding:0  20px;
        box-sizing: border-box;
        border-right: 1px solid #e7eef3;
    }
    .polygonItems {
        font-size: 16px;
        /*padding-right: 22px;*/
        padding:22px 0;
        text-align: left;
        color: #676e74;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .fontBold {
            font-weight: bold;
            font-size: 28px;
            color: rgba(1, 1, 1, 1);
            padding-bottom: 7px;
            text-align: center;
            font-family: DIN !important;
            .num{
                font-weight: bold;
                font-size: 28px;
                color: rgba(1, 1, 1, 1);
                padding-bottom: 7px;
                font-family: DIN !important;
                margin-right:10px;
            }
            span {
                font-weight: normal;
            }
        }
        .widthCompare {
            padding-top: 5px;
            width: 200px;
            display:flex;
            justify-content: space-between;
            .rate{
                white-space: nowrap;
            }
        }
        .redFonts {
            font-family: DIN !important;
            color: rgba(255, 8, 8, 1);
        }
        .greenFonts {
            font-family: DIN !important;
            color: rgba(57, 187, 40, 1);
        }
        .grayFonts {
            font-family: DIN !important;
            color: #939495;
        }
    }
    .unit{
        color: #a3a7af;
        font-size: 14px;
    }
</style>