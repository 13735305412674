<template>
  <div>
    <el-drawer title="扣款申请" :visible.sync="DeductionShowVisible" size="50%" :modal="false" :wrapperClosable="false" :before-close="handleClose">

      <el-form :rules="rules" ref="DeductionForm" :model="DeductionForm" label-width="80px">
        <el-form-item prop="type" label="收益类型">
          <el-select v-model="DeductionForm.type" placeholder="请选择收益类型" @change="log" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in incomeTypeList" :key="index" :label="item.kind_name" :value="item.child_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="扣款金额" prop="amount">
          <el-input v-model="DeductionForm.amount">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="扣款原因" prop="reason">
          <el-input type="textarea" resize="none" :rows="15" v-model="DeductionForm.reason"></el-input>
        </el-form-item>
        <el-form-item label="附件上传" prop="attachment">
          <el-upload class="upload-demo" :action="global.upload" :on-remove="uploadRemove" :on-success="uploadSuccess" :limit="1" ref="pic" :on-exceed="uploadExceed" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>

      <div class="btnSumnitRow">
        <el-button class="btnSumnit" type="primary" @click="submitForm()">提交</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { createDeducted } from "@/api/uokofinan.js";
import qs from "querystring";
export default {
  props: ["ids", "incomeTypeList"],

  data() {
    return {
      DeductionShowVisible: false,
      uploadsuccesshide: false,
      uploadVideo: false,
      DeductionForm: {
        type: "",
        amount: "",
        reason: "",
        attachment: "",
        attachment_name: "",
      },
      rules: {
        type: [
          { required: true, message: "请选择收益类型", trigger: "change" },
        ],
        reason: [
          { required: true, message: "请填写扣款原因", trigger: "blur" },
        ],
        amount: [
          {
            required: true,
            message: "请填写扣款金额",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              let reg = /^([1-9]\d{0,9}|0)(\.\d{1,2})?$/;
              if (!value) {
                callback(new Error("单价不能为空"));
              } else if (!reg.test(value)) {
                callback(new Error("请输入正确格式的单价"));
              } else if (value.length > 10) {
                callback(new Error("最多可输入10个字符"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        attachment: [
          { required: true, message: "请选择附件", trigger: "blur" },
        ],
      },
      fileList: [],
      VideoList: [],
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
    };
  },

  methods: {
    //文件删除
    uploadRemove(file) {
      this.DeductionForm.attachment_name ="";
      this.DeductionForm.attachment = "";
      this.$message.success("文件删除成功，请重新添加附件");
    },
    // 文件上传成功
    uploadSuccess(response, file, fileList) {
      this.$message.success("文件上传成功");

      this.DeductionForm.attachment_name = file.name;
      this.DeductionForm.attachment = file.response.data.url;
      this.fileList = fileList;
    },
    // 文件上传数量
    uploadExceed(files) {
      this.$message.warning("附件最大数量为1");
    },
    // 选择器变化
    log() {
      console.log(this.DeductionForm.type);
    },
    // 扣款确认操作
    submitForm() {
      this.$refs.DeductionForm.validate((valid) => {
        if (valid) {
          var json = {};
          Object.assign(json, this.DeductionForm);
          json.partner_ids = this.ids;
          json.amount = json.amount * 100;
          var param = qs.stringify(json);
          createDeducted(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("操作成功");
                this.$emit("closeDialog2");
                this.DeductionShowVisible = false;
                this.DeductionForm.type = "";
                this.DeductionForm.amount = "";
                this.DeductionForm.reason = "";
                this.DeductionForm.attachment = "";
                this.DeductionForm.attachment_name = "";
                this.fileList = [];
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error("操作失败");
              console.log(err);
            });
        } else {
          console.log("请将表单内容补充完整");
          // return false;
        }
      });
    },
    // 关闭弹框
    handleClose() {
      this.DeductionShowVisible = false;
      this.$refs.DeductionForm.resetFields();
      this.fileList = [];
    },
  },
};
</script>
<style scoped>
::v-deep :focus {
  outline: 0;
}
.upload-demo {
  height: 50px;
}
.btnSumnitRow {
  text-align: right;
  margin-top: 40px;
  height: 52px;
}
.btnSumnit {
  width: 100px;
  height: 40px;
  float: right;
}
.StatementAccountTitle p {
  text-align: center;
  margin-bottom: 10px;
}
.showNewBox {
  background: #ccc;
  padding: 0 15px;
}
.rowContent {
  padding: 10px 0;
}
.inlineBlock {
  display: inline-block;
  width: 45px;
}
.el-tabs__header {
  margin: 0 !important;
}
.firstinlineBlock {
  margin-left: 50px;
}
.rowContent p span:first-child {
  display: inline-block;
  width: 120px;
  text-align: right;
}
.el-table {
  margin-top: 20px;
}
.btn {
  margin-top: 15px;
  text-align: right;
}
.box-card {
  position: relative;
}
.backHistroy {
  position: absolute;
  top: 20px;
  left: 20px;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  width: 50%;
  padding: 15px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background: #b6babf;
}
.dialogTitle {
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-align: left;
  padding: 0 10px;
  border-bottom: 1px solid #ecf3fd;
}
.dialog_title {
  height: 30px;
  line-height: 30px;
  background: #ccc;
  text-align: center;
  margin-bottom: 15px;
}
.el-form {
  margin-top: 20px;
}

/* 验收测试 */
.uploadBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.picUpload {
  width: 300px;
  height: 200px;
}
.picUpload /deep/ .el-upload--picture-card {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.picUpload /deep/ .el-upload--picture-card span {
  height: 20px;
  line-height: 20px;
}
.picUpload /deep/ .el-upload-list__item.is-success {
  width: 300px;
  height: 200px;
}
.hide /deep/ .el-upload--picture-card {
  display: none;
}
::v-deep .el-drawer {
  overflow: visible !important;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;

  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}
</style>