<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread" class="bread">
                <span slot="second">合作伙伴</span>
                <span slot="third">合作伙伴列表</span>
                <span slot="fourth">不计算收益酒店</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth" foldState="展开"></fold-top-search>
        </div>
        <div>
            <back @backMeth="backMeth"></back>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow"
                        @condition="getCondition"
                        :topSearchSave="topSearchSave"></top-search>
        </el-card>
        <el-card class="box-card">
            <coop-tab-hotel ref="coopTabHotel" :condition="condition">
            </coop-tab-hotel>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "CoopNew",
        data:()=>({

            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/coopList'},
            },
            fold:{
                showSearch:true,
                foldText:'展开筛选项'
            },
            topSearchShow:{ },
            topSearchSave:{ },
            condition:{ },
            token:'',
        }),
        methods:{
            getFoldMeth(val){
                this.fold=val;
            },
            getTopSearchShow(type) {
                this.topSearchShow = {
                    hotel_brand: true,
                    province: true,
                    city: true,
                    country: true,
                    date: true,
                    keyword: true,
                };
                this.topSearchShow.keywordPlaceholder='请输入酒店名称关键词';
            },
            getCondition(val,filterPage,filterLimit,type) {
                this.topSearchSave=val;
                this.condition.hotel_brand = Array.isArray(val.hotel_brand) ? val.hotel_brand.join(',') : ''; //ka
                this.condition.hotel_use_state = Array.isArray(val.hotel_use_state) ? val.hotel_use_state.join(',') : ''; //ka

                if (typeof val.citycode === 'string') {
                    this.condition.citycode = val.citycode;
                } else {
                    this.condition.citycode = Array.isArray(val.citycode) ? val.citycode.join(',') : ''; //citycode
                }
                this.condition.provinceLabel = Array.isArray(val.provinceLabel) ? val.provinceLabel.join(',') : ''; //provinceLabel
                this.condition.cityLabel = val.cityLabel;
                this.condition.countyLabel = val.countyLabel;
                this.condition.startTime = val.startTime; //ka
                this.condition.endTime = val.endTime; //ka
                this.condition.keyword = val.keyword; //ka
                this.$refs.coopTabHotel.tableMeth();
            },
            backMeth(){
                this.$router.push({path:`./coopList`})
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getTopSearchShow();
        }
    }
</script>

<style scoped>

</style>