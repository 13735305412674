<template>
    <div class="grid-content bg-purple chartCon">
        <div class="chartTitle blueTitle" v-if="chart.showTitle">数据走势</div>
        <div :id="chart.id" class="chart chartBox"></div>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import GLOBAL from '../../../api/globalVar.js'

    export default {
        name: "LineChart",
        props:['chart'],
        data: () => ({
            yAxis:[],
            allData:{
            },
        }),
        methods:{
            chartRender(fromUrl){
                var chart = echarts.init(document.getElementById(this.chart.id));
                var that=this;
                var option={};
                if(fromUrl==='indexShow'){
                    option = {
                        title: {
                            text: ''
                        },
                        tooltip: {
                            trigger: 'axis',
                        },
                        legend: {
                            x:'center',
                            y:'bottom',
                            padding:[0,0,0,0],
                            icon:'circle',
                            data:this.chart.option.legend
                        },
                        grid: {
                            top:'3%',
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            axisLine: {
                                lineStyle: {
                                    color: '#ced7ec',
                                }
                            },
                            axisLabel:{
                                textStyle:{
                                    color:'#333'
                                }
                            },
                            data:this.chart.option.xData
                        },
                        // yAxis: {
                        //     type: 'value',
                        //     axisLine: {
                        //         show: false
                        //     },
                        //     axisTick: {
                        //         show: false
                        //     },
                        //     data:[
                        //         {name:'激活终端数量',type:'value'},
                        //         {name:'日活率',type:'value',show:false},
                        //         {name:'点播总收益',type:'value'},
                        //     ]
                        // },
                        yAxis:this.yAxis,
                        series: this.chart.option.series
                    };
                }else{
                    option = {
                        title: {
                            text: ''
                        },
                        tooltip: {
                            trigger: 'axis',
                        },
                        legend: {
                            x:'center',
                            y:'bottom',
                            icon:'circle',
                            padding:[0,0,0,0],
                            data:this.chart.option.legend
                        },
                        grid: {
                            top:'3%',
                            left: '5%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            axisLine: {
                                // color:'#ced7ec', //折点颜色
                                lineStyle: {
                                    color: '#ced7ec',
                                },
                                symbolSize:10,
                            },
                            axisLabel:{
                                textStyle:{
                                    color:'#333'
                                }
                            },
                            data:this.chart.option.xData
                            // data:[1,2,4,5,6]
                        },
                        // yAxis: {
                        //     type: 'value',
                        //     axisLine: {
                        //         show: false
                        //     },
                        //     axisTick: {
                        //         show: false
                        //     },
                        //     data:[
                        //         {name:'激活终端数量',type:'value'},
                        //         {name:'日活率',type:'value',show:false},
                        //         {name:'点播总收益',type:'value'},
                        //     ]
                        // },
                        yAxis:this.yAxis,
                        series: this.chart.option.series
                    };
                }
                chart.setOption(option);
            },
            getYaxis(nameOne,unitOne,nameTwo,unitTwo,nameThree,unitThree,nameFour,unitFour,fromUrl){
                if(fromUrl==='indexShow' || fromUrl==='hotelSta'){
                    this.yAxis=[
                        {
                            type:'value',
                            axisLabel:{
                                // formatter:`{value}(${unitOne})`,
                                formatter:`{value}${unitOne}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[0]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"left",     //多个Y轴使用
                            offset:55,            //偏移px
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            type:'value',
                            axisLabel:{
                                // formatter:`{value}(${unitTwo})`,
                                formatter:`{value}${unitTwo}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[1]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"left",     //多个Y轴使用
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            type:'value',
                            axisLabel:{
                                // formatter:`{value}(${unitThree})`,
                                formatter:`{value}${unitThree}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[2]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitFour}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[3]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"right ",     //多个Y轴使用
                            offset:55,            //偏移px
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                    ]
                }
                else if(fromUrl==='inTabBrush' || fromUrl==='inTabMarket'){
                    this.yAxis=[
                        {
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitOne}`,
                                // formatter:`{value}(1)`,
                                textStyle:{
                                    color:GLOBAL.chartColor[0]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },

                        },
                        {
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitTwo}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[1]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"right",     //多个Y轴使用
                            // offset:80,            //偏移px
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitThree}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[2]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitFour}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[3]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"right",     //多个Y轴使用
                            offset:70,            //偏移px
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                    ]
                }else if(fromUrl==='inTabMovie' || fromUrl==='inTabViewMovie' ){
                    this.yAxis=[
                        {
                            nameTextStyle:{
                                color:GLOBAL.chartColor[0],
                            },
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitOne}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[0]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"left",     //多个Y轴使用
                            offset:70,            //偏移px
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            nameTextStyle:{
                                color:GLOBAL.chartColor[1],
                            },
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitTwo}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[1]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"left",     //多个Y轴使用
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            nameTextStyle:{
                                color:GLOBAL.chartColor[2],
                            },
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitThree}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[2]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                        {
                            nameTextStyle:{
                                color:GLOBAL.chartColor[3],
                            },
                            type:'value',
                            axisLabel:{
                                formatter:`{value}${unitFour}`,
                                textStyle:{
                                    color:GLOBAL.chartColor[3]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"right",     //多个Y轴使用
                            offset:70,            //偏移px
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                    ]
                }
            },
            calMin(arr){
                var min = 0;
                arr.forEach((el) => {
                    el.forEach((el1) => {
                        if (!(el1 === undefined || el1 === '')) {
                            if (min > el1) {
                                min = el1;
                            }
                        }
                    })
                });
                var minint = Math.floor(min / 10);
                var minval = minint * 10;//让显示的刻度是整数
                return minval;
            },
            calMax(arr){
                var max = 0;
                arr.forEach((el) => {
                    el.forEach((el1) => {
                        if (!(el1 === undefined || el1 === '')) {
                            if (parseInt(max) < parseInt(el1)) {
                                max = el1;
                            }
                        }
                    })
                });
                var maxint = Math.ceil(max / 9.5);//不让最高的值超过最上面的刻度
                var maxval = maxint * 10;//让显示的刻度是整数
                return maxval;
            },
            transData(nameOne,nameTwo,nameThree,nameFour){
                var series=this.chart.option.series;
                series.filter((item,index)=>{
                    console.log('filter遍历',item);
                    if(item.name===nameOne){
                        this.minMaxMeth(item.data,0);
                    }else if(item.name===nameTwo){
                        this.minMaxMeth(item.data,1);
                    }else if(item.name===nameThree){
                        this.minMaxMeth(item.data,2);
                    }else if(item.name===nameFour){
                        this.minMaxMeth(item.data,3);
                    }
                });

                this.chartRender(this.chart.fromUrl);
            },
            minMaxMeth(data,index){
                var Min=this.calMin([data]);
                var Max=this.calMax([data]);
                if(Max){
                        Max =Max
                }else{
                     Max =10 
                }   
                this.yAxis[index].min=Min;
                this.yAxis[index].max=Max;
                this.yAxis[index].interval= (Max - Min) / 5;
            }
        },
        mounted(){
            var id=this.id;
            var fromUrl=this.chart.fromUrl;
            if(fromUrl==='indexShow'){
                this.getYaxis('激活终端数量','元','日活率','%','','','','','indexShow');
                this.transData('激活终端数量','日活率','');
            }else if(fromUrl==='hotelSta'){
                this.getYaxis('运营酒店数量','家','日活率','%','点播总收益','台','','','hotelSta');
                this.transData('运营酒店数量','日活率','点播总收益');
            }else if(fromUrl==='inTabMovie'){
                this.getYaxis('观影订单数量','个','分成收益','元','观影订单收益','元','','','inTabMovie');
                this.transData('观影订单数量','分成收益','观影订单收益');
            }else if(fromUrl==='inTabBrush'){ //inTabViewMovie
                this.getYaxis('上线费用','元','','','','','','','inTabBrush');
                // this.transData('上线费用','市场费用');
                this.transData('上线费用');
            }else if(fromUrl==='inTabMarket'){ //inTabViewMovie
                this.getYaxis('市场费用','元','','','','','','','inTabMarket');
                // this.transData('上线费用','市场费用');
                this.transData('市场费用');
            }else if(fromUrl==='inTabViewMovie'){
                this.getYaxis('观影订单收益','元','麗管家售卡收益','元','酒店前台售卡收益','元','电视平台售卡收益','元','inTabViewMovie');
                this.transData('观影订单收益','麗管家售卡收益','酒店前台售卡收益','电视平台售卡收益');
            }else if(fromUrl==='hotelMan'){
                this.getYaxis('激活终端数量','家','酒店数量','台','','','','','hotelSta');
                this.transData('激活终端数量','酒店数量');
            }
        },
        watch:{
            chart(val){
            }
        }
    }
</script>

<style scoped lang="scss">

    .grid-content {
        height:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        /*padding:15px 20px;*/
        .leftBox{
            display:flex;
            align-items: center;
        }
        .numTitle{
            width:150px;
        }
        .num{
            font-size:24px;
            margin-right:10px;
        }
    }
    .bg-purple {
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        /*padding:20px 20px;*/
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .chartBox{
        height:400px;
        overflow:hidden;
    }
    .grid-content{
        box-sizing:border-box;
    }
    .el-col{
        height:100%;
        .grid-content{
            height:100%;
        }
    }
    .chartTitle{
        width:100%;
        margin-bottom:15px;
    }
    .chartCon{
        height:100%;
        display:flex;
        flex-direction:column;
    }
    #chart,.chart{
        width:100%;
        /*height:300px;*/
        flex:1;
    }

</style>
