<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">设备管理</span>
        <span slot="third">终端上报日志</span>
      </top-bread>
    </div>
     <el-card class="box-card">
         <div class="btnRow">
      <el-form ref="ruleForm" :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="" prop="hotel_name">
          <el-input v-model="listQuery.hotel_name" placeholder="酒店名称"></el-input>
        </el-form-item>
        <el-form-item label="" prop="project_name">
          <el-input v-model="listQuery.project_name" placeholder="程序名称"></el-input>
        </el-form-item>
        <el-form-item label="" prop="room_no">
          <el-input v-model="listQuery.room_no" placeholder="房间号"></el-input>
        </el-form-item>
        <el-form-item label="" prop="update_time">
          <el-date-picker v-model="listQuery.update_time" type="date" value-format="yyyy-MM-dd" placeholder="选择更新日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="state">
          <span>状态筛选：</span>
          <el-switch v-model="listQuery.state" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
          <ExcelExport :listQuery="listQuery" :apiExpor="getList" listName=""></ExcelExport>
        </el-form-item>
      </el-form>
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changelimit" @defaultpage="changeCurrent" @defaultcheck="changeSelection">
      <template slot="operation" slot-scope="{row}">
        <a :href="row.logUrl" target="_blank">{{row.logUrl}}</a>
      </template>
    </operationTable>
     </el-card>
  
  </div>
</template>
<script>
import { getList, dealLog } from "./api/appLog";
export default {
  name: "appLog",
  data() {
    return {
      bread: {
        level: 3,
      },
      tableData: {
        tableList: [],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 2,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "80",
            type: "text",
          },
          {
            label: "酒店名称",
            prop: "hotelName",
            minWidth: "200",
            type: "text",
          },
          {
            label: "房间号",
            prop: "roomNo",
            minWidth: "100",
            type: "text",
          },
          {
            label: "合同号",
            prop: "hotel_contract",
            minWidth: "150",
            type: "text",
          },
          {
            label: "程序名称",
            prop: "project",
            minWidth: "120",
            type: "text",
          },
          {
            label: "log链接",
            prop: "logUrl",
            minWidth: "300",
            type: "text",
            isSlot: true,
            // showTooltip: true,
          },
          {
            label: "log生成时间",
            prop: "updateTime",
            minWidth: "160",
            type: "text",
          },
          {
            label: "状态",
            prop: "stateText",
            minWidth: "100",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            deal: (row) => {
              this.$confirm("你确定要处理吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  dealLog({ id: row.id }).then((res) => {
                    if (res.data.code === 100000) {
                      this.$message.success("处理成功");
                      this.$refs.operationTable.resetTable();
                      this.getList(1);
                    } else {
                      this.$message.error(data.data.msg);
                    }
                  });
                })
                .catch(() => {});
            },
          },
        ],
      },
      cmdList: [],
      listQuery: {
        // database: this.query.hotelName,
        // hotel_contract: this.query.hotel_contract,
        project_name: "",
        hotel_name: "",
        room_no: "",
        update_time: "",
        state: "",
        page: 1,
        limit: 10,
      },
      checkList: [],
    };
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.listQuery.state = "";
      this.$refs.operationTable.resetTable();
      this.getList(1);
    },
    changelimit(s, p) {
      this.listQuery.page = p;
      this.listQuery.limit = s;
      this.getList();
    },
    changeCurrent(s, p) {
      this.listQuery.page = p;
      this.listQuery.limit = s;
      this.getList();
    },
    changeSelection(val) {
      this.checkList = val.map((v) => {
        return v.id;
      });
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
      }
      getList(this.listQuery).then((res) => {
        if (res.data.code == 100000) {
          this.$refs.operationTable.changeTotal(Number(res.data.data.total));
          let stateOjb = {
            0: "未处理",
            1: "已处理",
          };
          if (res.data.data.total > 0) {
            res.data.data.list.forEach((item) => {
              item.stateText = stateOjb[item.state];
            });
            this.tableData.tableList = res.data.data.list;
          } else {
            this.tableData.tableList = [];
          }
        }
        this.tableData.tableLoading = false;
      });
    },
    search() {
      // this.listQuery.state = '';
      this.$refs.operationTable.resetTable();
      this.getList(1);
    },
  },
  mounted() {
    this.listQuery.state = "";
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map((i) => {
      if (i.ltype == 1) {
        i.language = "中文";
      } else if (i.ltype == 2) {
        i.language = "英文";
      } else if (i.ltype == 3) {
        i.language = "日文";
      } else if (i.ltype == 4) {
        i.language = "韩文";
      }
      return i;
    });
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
