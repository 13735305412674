<template>
  <div>
    <div class="boxBlack" v-if="drawer"></div>
    <el-dialog class="hotelServiceDrawer" title="素材配置" :visible.sync="drawer" width="70%" :modal="false">
      <div>
        <upload-pic-sort :hotelContract="hotelContract" ref="uploadPicSort" v-if="flagShow" style="margin-top:30px;" :filterDataProp="filterData" :tabActiveName="tabActiveName" :curId="curId" @loadingMeth="loadingMeth"  v-bind="$attrs">
        </upload-pic-sort>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Swiper from "swiper";
import qs from "querystring";
import Bus from "../../../../api/Bus";
export default {
  name: "hotelManEditHotelSerivice",
  props: ["hotelContract"],
  data: () => ({
    configData: {},
    noData: false,
    token: "",
    filterData: {},
    flagShow: false,
    loading: false,
    drawer: false,
    direction: "rtl",
    tabActiveName: "",
    curId: "",
  }),
  methods: {
    initSwiper() {
      var swiper = new Swiper(".swiper-container", {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    getHotelConfigInfo(hotelContract) {
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
          hotelContract: hotelContract,
        });
        this.$http
          .post(this.global.getHotelCurrentVisualInfo, param)
          .then((res) => {
            if (res.data.code === 100000) {
              this.configData = res.data.data;
            } else if (res.data.msg === "暂不支持") {
              this.noData = true;
            } else {
              this.$message.error(res.data.msg);
            }
            return resolve();
          })
          .catch(() => {
            // debugger;
          });
      });
    },
    filterDataMeth(operateId, tabActiveName, configData) {
      var pic = JSON.parse(JSON.stringify(configData.pic));
      var that = this;
      Object.keys(pic).forEach(function (key) {
        if (pic[key].id == operateId) {
          that.filterData = pic[key];
          that.flagShow = true;
        }
      });
      this.$nextTick(() => {
        this.$refs.uploadPicSort.assignData();
      });
    },
    loadingMeth(val) {
      this.loading = val;
    },
    showDrawer(val) {
      this.drawer = val;
    },
    submitMeth() {
      this.$refs.uploadPicDialog.submitForm();
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.query = this.$route.query;
    this.initSwiper();
    console.log(this.hotelContract);
  },
  created() {
    Bus.$on("showConfigDetailDrawer", (obj) => {
      var { id, tabActiveName, configData } = obj;
      this.tabActiveName = tabActiveName;
      if (this.$attrs.showType !== "action") {
        this.$store.commit("editConfigData", {
          tabActiveName: tabActiveName,
          pic: configData.pic,
        });
      }
      this.curId = id;
      this.drawer = true;
      this.filterDataMeth(id, tabActiveName, configData);
    });
    Bus.$on("closeDrawer", (obj) => {
      this.drawer = false;
    });
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../node_modules/swiper/swiper-bundle.css";
@import "../../../../../node_modules/swiper/swiper-bundle.min.css";
.drawerFooter {
  position: absolute;
  bottom: 20px;
  display: flex;
  width: 100%;
  .el-button {
    flex: 1;
  }
}
.boxBlack{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background: #000;
            z-index: 2000;
    }
</style>
