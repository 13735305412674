<template>
    <div>我是testOne</div>
</template>

<script>
    export default {
        name: "TestOne",
        data:()=>({
            test:'测试'
        })
    }
</script>

<style scoped>

</style>