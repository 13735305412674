<template>
  <div class="inputColorChange inputColorNone">
    <el-row :gutter="20" style="margin-top:0;!important;" class="topSearchRow">
      <el-col :span="22" class="searchCol">
        <el-row :gutter="20" class="" style="display: flex;
    flex-wrap: wrap;">
          <el-col :span="6" v-if="topSearchShow.hotel_group" class="inputColorNone">
            <!--  @change="mulSearchMeth($event,'hotel_brand')"-->
            <el-cascader placeholder="请选择集团品牌" style="width:100%" filterable v-model="form.hotel_group" :options="topAttr.hotelGroupOptionList" :props="hotel_group_props" collapse-tags clearable></el-cascader>
          </el-col>
          <el-col :span="6" class="relativeEl" v-if="topSearchShow.coopType || topSearchShow.coopPartnerType">
            <el-input placeholder="请选择合作伙伴" :readonly="true" class="input-with-select fullSelect" @focus="showCoopDialog" v-model="form.coopShowText"></el-input>
            <div class="inputSpanBox">
              <span @click="showCoopDialog" class="inputSpan">选择</span>
              <i class="el-icon-circle-close closeIcon" @click="clearCoopFilter" v-if="form.coopShowText!==''"></i>
            </div>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.hotel_brand" class="inputColorNone">
            <!--  @change="mulSearchMeth($event,'hotel_brand')"-->
            <el-select v-model="form.hotel_brand" multiple placeholder="集团品牌" class="hotelBrand fullSelect" filterable collapse-tags clearable>
              <el-option v-for="item in topAttr.hotelGroupList" :key="item.id" :label="item.groupname" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.province">
            <el-select v-model="form.province" multiple placeholder="省份" @change="provinceChange" collapse-tags clearable class="fullSelect" filterable>
              <el-option v-for="item in topAttr.province" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.city">
            <el-select v-model="form.city" placeholder="城市" @change="cityChange($event,'city')" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.city" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.country">
            <el-select v-model="form.country" placeholder="区县" @change="countyChange($event,'county')" clearable class=" fullSelect" filterable>
              <el-option v-for="item in topAttr.country" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.hotel_use_state">
            <el-select multiple v-model="form.hotel_use_state_tem" placeholder="酒店状态" @change="mulSearchMeth($event,'hotel_use_state')" clearable class=" fullSelect" filterable collapse-tags>
              <template v-if="parentUrl!=='dataAssetPosition'">
                <el-option v-for="item in topAttr.hotelUseState" :key="item.id" :label="item.name" :value="{value:item.id,name:item.name}">
                </el-option>
              </template>
              <template v-if="parentUrl==='dataAssetPosition'">
                <template v-for="item in topAttr.hotelUseState">
                  <el-option v-if="item.id==5 || item.id==6 || item.id==7 || item.id==11" :key="item.id" :label="item.name" :value="{value:item.id,name:item.name}">
                  </el-option>
                </template>
              </template>
            </el-select>
          </el-col>
          <!--<el-col :span="4"  v-if="topSearchShow.hotelUseStatePosition" >-->
          <!--<el-select multiple v-model="form.hotelUseStatePosition" placeholder="终端客户状态" @change="mulSearchMeth" clearable class=" fullSelect" filterable  collapse-tags>-->
          <!--<el-option-->
          <!--v-for="item in topAttr.hotelUseStatePosition"-->
          <!--:key="item.id"-->
          <!--:label="item.name"-->
          <!--:value="{value:item.id,name:item.name}">-->
          <!--</el-option>-->
          <!--</el-select>-->
          <!--</el-col>-->
          <el-col :span="4" v-if="topSearchShow.BD">
            <el-select multiple v-model="form.BD" placeholder="客户经理" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="(item,index) in topAttr.BD" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- 是否分成收益 -->
          <el-col :span="4" v-if="topSearchShow.is_profit">
            <el-select v-model="form.is_profit" placeholder="收益类型" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="(item,index) in topAttr.is_profit" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- 是否分成收益 -->

          <el-col :span="4" v-if="topSearchShow.onlyCoopType">
            <el-select v-model="form.onlyCoopType" multiple placeholder="合作类型" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.coopType" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-col>
          <!--审核状态-->
          <el-col :span="4" v-if="topSearchShow.coopExamineStatus">
            <el-select v-model="form.coopExamineStatus" placeholder="审核状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.coopExamineStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>

          <!--补全状态-->
          <el-col :span="4" v-if="topSearchShow.coopCompleStatus">
            <el-select multiple v-model="form.coopCompleStatus" placeholder="状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.coopCompleStatus" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.coopName">
            <el-input :placeholder="topSearchShow.coopNamePlaceholder" v-model="form.coopName" :clearable="true" class="input-with-select fullSelect">
            </el-input>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.coopPhone">
            <el-input :placeholder="topSearchShow.coopPhonePlaceholder" v-model="form.coopPhone" :clearable="true" class="input-with-select fullSelect">
            </el-input>
          </el-col>
          <!--合作伙伴结束-->
          <el-col :span="4" v-if="topSearchShow.adStatus">
            <el-select multiple v-model="form.adStatus" placeholder="状态" clearable class=" fullSelect" @blur="blurMeth" filterable collapse-tags>
              <el-option v-for="item in topAttr.adStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.adFormat">
            <el-select multiple v-model="form.adFormat" placeholder="格式" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.adFormat" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.orderStatus">
            <el-select multiple v-model="form.orderStatus" placeholder="状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.adFormat" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--财务开始-->
          <el-col :span="4" v-if="topSearchShow.finType">
            <el-select multiple v-model="form.finType" placeholder="类型" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.finType" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.finStatus">
            <el-select multiple v-model="form.finStatus" placeholder="状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.finStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--财务结束-->

          <!--通知开始-->
          <el-col :span="4" v-if="topSearchShow.noticeStatus">
            <el-select multiple v-model="form.noticeStatus" placeholder="状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.noticeStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--通知结束-->

          <!--首页待办开始-->
          <!--任务类型-->
          <el-col :span="4" v-if="topSearchShow.taskType">
            <el-select v-model="form.taskType" multiple placeholder="任务类型" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.taskType" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-col>
          <!--任务状态-->
          <el-col :span="4" v-if="topSearchShow.taskStatus">
            <el-select v-model="form.taskStatus" multiple placeholder="任务状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.taskStatus" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <!-- 付款状态 -->
          <el-col :span="4" v-if="topSearchShow.payStatus">
            <el-select v-model="form.payStatus" placeholder="汇款状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.payStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--提现审核状态-->
          <el-col :span="4" v-if="topSearchShow.examineStatus">
            <el-select v-model="form.examineStatus" placeholder="审核状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.examineStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--酒店验收状态-->
          <el-col :span="4" v-if="topSearchShow.acceptanceStatus">
            <el-select v-model="form.acceptanceStatus" placeholder="验收状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.acceptanceStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.acceptanceType">
            <el-select v-model="form.acceptanceType" placeholder="验收类型" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.acceptanceType" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.hasChildren">
            <el-select v-model="form.hasChildren" placeholder="是否分组" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.hasChildren" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.taskDoneStatus">
            <el-select v-model="form.taskDoneStatus" multiple placeholder="任务状态" clearable class=" fullSelect" filterable collapse-tags>
              <el-option v-for="item in topAttr.taskDoneStatus" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <!--首页待办结束-->

          <el-col :span="8" v-if="topSearchShow.date">
            <el-date-picker class="fullSelect" v-model="form.date" type="daterange" align="right" unlink-panels :clearable='true' :disabled="(form.month!==null&&form.month!==''&&topSearchShow.businessMonth)||(form.onlyToday!==null&&form.onlyToday!==''&&topSearchShow.onlyToday)" range-separator="至" :start-placeholder="startDatePlaceholder" :end-placeholder="endDatePlaceholder" value-format="yyyy-MM-dd" :picker-options="pickerIsToday?pickerOptionsToday:pickerOptionsYesterday" @change="dateChange">
            </el-date-picker>
          </el-col>
          <el-col :span="8" v-if="topSearchShow.dateTime">
            <el-date-picker class="fullSelect" v-model="form.date" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerDateTimeOptions" range-separator="至" :start-placeholder="startDatePlaceholder" :end-placeholder="endDatePlaceholder" align="right" @change="dateChange">
            </el-date-picker>
          </el-col>
          <el-col :span="8" v-if="topSearchShow.hotelCreateTime">
            <el-date-picker class="fullSelect" v-model="form.hotelCreateTime" type="datetimerange" align="right" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerIsToday?pickerOptionsToday:pickerOptionsYesterday" @change="hotelCreateTimeChange">
            </el-date-picker>
          </el-col>
          <el-col :span="8" v-if="topSearchShow.confirmDate">
            <el-date-picker class="fullSelect" v-model="form.confirmDate" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="审核开始日期" end-placeholder="审核结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="confirmDateChange">
            </el-date-picker>
          </el-col>

          <el-col :span="8" v-if="topSearchShow.month">
            <el-date-picker class="fullSelect" :disabled="(form.date!==null&&form.date!==''&&topSearchShow.date)||(form.onlyToday!==null&&form.onlyToday!==''&&topSearchShow.onlyToday)" v-model="form.month" type="monthrange" placeholder="选择月" @change="monthChange" value-format="yyyy-MM" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
            </el-date-picker>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.businessMonth">
            <el-date-picker class="fullSelect" :disabled="(form.date!==null&&form.date!==''&&topSearchShow.date)||(form.onlyToday!==null&&form.onlyToday!==''&&topSearchShow.onlyToday)" v-model="form.month" type="month" placeholder="选择月" @change="monthChange" value-format="yyyy-MM" >
              <!-- :picker-options="pickerOptionMonths" -->
            </el-date-picker>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.onlymonth">
            <el-date-picker class="fullSelect" v-model="form.onlymonth" type="month" placeholder="选择月" value-format="yyyy-MM">
            </el-date-picker>
          </el-col>

          <el-col :span="4" v-if="topSearchShow.onlyYear">
            <el-date-picker class="fullSelect" v-model="form.onlyYear" type="year" placeholder="选择合同签订年份" value-format="yyyy">
            </el-date-picker>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.onlyDay">
            <el-date-picker class="fullSelect" v-model="form.onlyDay" type="date" :picker-options="pickerOptionsDay" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.onlyToday">
            <el-date-picker class="fullSelect thisDel" v-model="form.onlyToday" type="datetime" :disabled="(form.date!==null&&form.date!==''&&topSearchShow.date)||(form.month!==null&&form.month!==''&&topSearchShow.businessMonth)" :picker-options="pickerOptionsOneDay" placeholder="选择今日时间" :clearable='true' :default-value="nowTime" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-col>
          <!--账号信息-角色筛选-->
          <el-col :span="4" v-if="topSearchShow.role">
            <el-select multiple v-model="form.role" collapse-tags placeholder="角色" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.roleSelect" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--账号信息-角色筛选-->

          <!--账号信息-状态筛选-->
          <el-col :span="4" v-if="topSearchShow.sysAccStatus">
            <el-select v-model="form.sysAccStatus" placeholder="状态" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.sysAccStatusSelect" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--账号信息-状态筛选-->
          <!--带对账酒店状态筛选-->
          <el-col :span="4" v-if="topSearchShow.watingHotalStatus">
            <el-select v-model="form.watingHotalStatus" placeholder="状态筛选" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.watingHotalStatus" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--带对账酒店状态筛选-->
          <!--收益来源-->
          <el-col :span="4" v-if="topSearchShow.SourceOfIncome">
            <el-select v-model="form.SourceOfIncome" placeholder="收益来源" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.SourceOfIncome" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--收益类型-->
          <el-col :span="4" v-if="topSearchShow.SourceOfType">
            <el-select v-model="form.SourceOfType" placeholder="收益类型" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.SourceOfType" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--标签性质-->
          <el-col :span="4" v-if="topSearchShow.labelNature">
            <el-select v-model="form.labelNature" placeholder="标签性质" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.labelNatureList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--标签性质-->
          <el-col :span="4" v-if="topSearchShow.labelType">
            <el-select v-model="form.labelType" placeholder="标签分类" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.labelType" :key="item.id" :label="item.classify_name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--标签类型-->
          <el-col :span="4" v-if="topSearchShow.labelMold">
            <el-select v-model="form.labelMold" placeholder="标签类型" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.labelMold" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--标签类型-->
          <!--授权操作类型-->
          <el-col :span="4" v-if="topSearchShow.authorizedOperation">
            <el-select v-model="form.authorizedOperation" placeholder="操作类型" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.authorizedOperation" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--授权操作类型-->
          <!--授权操作项目-->
          <el-col :span="4" v-if="topSearchShow.authorizedObject">
            <el-select v-model="form.authorizedObject" placeholder="操作项目" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.authorizedObject" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--授权操作项目-->
          <!--授权操作动作-->
          <el-col :span="4" v-if="topSearchShow.authorizedAction">
            <el-select v-model="form.authorizedAction" placeholder="操作动作" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.authorizedAction" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--授权操作动作-->
          <!--收益来源-->
          <el-col :span="4" v-if="topSearchShow.hotelContract">
            <el-input :placeholder="topSearchShow.hotelContractPlaceholder" v-model="form.hotelContract" :clearable="true" class="input-with-select fullSelect">
            </el-input>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.hotelName">
            <el-input :placeholder="topSearchShow.hotelNamePlaceholder" v-model="form.hotelName" :clearable="true" class="input-with-select fullSelect" @keyup.enter.native="searchMeth">
            </el-input>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.MAC_number">
            <el-input :placeholder="topSearchShow.MAC_numberPlaceholder" v-model="form.MAC_number" :clearable="true" class="input-with-select fullSelect">
            </el-input>
          </el-col>
          <el-col :span="8" v-if="topSearchShow.hotelStoreID">
            <!-- @click="searchMeth"-->
            <el-input :placeholder="topSearchShow.hotelStoreIDPlaceholder" class="input-with-select fullSelect" v-model="form.hotelStoreID" :clearable="true" @keyup.enter.native="searchMeth">
              <!--<i slot="suffix" class="el-input__icon el-icon-search pointer" :class="colorOne"></i>-->
            </el-input>
          </el-col>
          <el-col :span="4" v-if="topSearchShow.keyword">
            <!-- @click="searchMeth"-->
            <el-input :placeholder="topSearchShow.keywordPlaceholder" class="input-with-select fullSelect" v-model="form.keyword" :clearable="true" @keyup.enter.native="searchMeth">
              <!--<i slot="suffix" class="el-input__icon el-icon-search pointer" :class="colorOne"></i>-->
            </el-input>
          </el-col>
          <el-col :span="8" v-if="topSearchShow.codekeyword">
            <!-- @click="searchMeth"-->
            <el-input :placeholder="topSearchShow.codekeywordPlaceholder" class="input-with-select fullSelect" v-model="form.codekeyword" :clearable="true" @keyup.enter.native="searchMeth">
              <!--<i slot="suffix" class="el-input__icon el-icon-search pointer" :class="colorOne"></i>-->
            </el-input>
          </el-col>
          <el-col :span="8" v-if="topSearchShow.namekeyword">
            <!-- @click="searchMeth"-->
            <el-input :placeholder="topSearchShow.namekeywordPlaceholder" class="input-with-select fullSelect" v-model="form.namekeyword" :clearable="true" @keyup.enter.native="searchMeth">
              <!--<i slot="suffix" class="el-input__icon el-icon-search pointer" :class="colorOne"></i>-->
            </el-input>
          </el-col>

          <el-col :span="6" v-if="topSearchShow.operator">
            <el-input :placeholder="topSearchShow.operatorPlaceholder" class="input-with-select fullSelect" v-model="form.operator" :clearable="true" @keyup.enter.native="searchMeth">
              <!--<i slot="suffix" class="el-input__icon el-icon-search pointer" :class="colorOne"></i>-->
            </el-input>
          </el-col>
          <!-- 媒体类型 -->
          <el-col :span="4" v-if="topSearchShow.mediaType">
            <el-select v-model="form.mediaType" placeholder="媒体类型" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.mediaType" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- 媒体类型 -->
          <el-col :span="4" v-if="topSearchShow.operation">
            <el-select v-model="form.operation" placeholder="操作类型" clearable filterable class="fullSelect">
              <el-option v-for="item in topAttr.operation" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!--<el-col :span="24" class="flexBox">-->
          <!--<el-button class="rightBtn largeBtn" type="primary" @click="searchMeth" :loading="searchBtn.loading">{{searchBtn.text}}</el-button>-->
          <!--</el-col>-->
          <el-dialog title="合作伙伴筛选" :close-on-click-modal="false" class="coopDialog" :visible.sync="coopDialog.visible" width='550px'>
            <el-form :model="form" ref="form" :rules="coopDialogRule">
              <el-form-item v-if="topSearchShow.coopType">
                <el-select class="fullSelect" v-model="form.coopType" clearable collapse-tags @change="getCoop($event,'getType')" placeholder="合作类型">
                  <el-option v-for="item in topAttr.coopType" :key="item.key" :label="item.value" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="partner_id" v-if="topSearchShow.coopType">
                <el-select class="fullSelect" @change="changePartnerId" v-model="form.partner_id" clearable multiple collapse-tags filterable placeholder="合作伙伴">
                  <el-option value="all" label="全选" v-if="isShowPartnerAll"></el-option>
                  <el-option v-for="item in topAttr.partner_id" :key="item.id" :label="item.company_name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="coopPartnerType" v-if="topSearchShow.coopPartnerType" class="noMarginBottom">
                <el-select v-model="form.coopPartnerType" clearable collapse-tags placeholder="合作伙伴类型" class="fullSelect" filterable>
                  <el-option value="belongPartnerCode" label="所属合作伙伴" v-if="showbelong"></el-option>
                  <el-option value="allotPartnerCode" label="归属合作伙伴" v-if="showallot"></el-option>
                  <!--                                    <el-option-->
                  <!--                                            v-for="item in topAttr.coopPartnerType"-->
                  <!--                                            :key="item.id"-->
                  <!--                                            :label="item.name"-->
                  <!--                                            :value="item.id">-->
                  <!--                                    </el-option>-->
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="coopDialogCancel">取 消</el-button>
              <el-button type="primary" @click="coopDialogConfirm">确 定</el-button>
            </div>
          </el-dialog>
        </el-row>
      </el-col>
      <div class="line"></div>
      <el-col :span="2" class="btnCol">
        <el-button class="searchBtn " type="primary" @click="searchMeth" :loading="searchBtn.loading">{{searchBtn.text}}</el-button>
        <el-button class="searchBtn resetBtn noMargin" type="text" @click="resetMeth" :loading="resetBtn.loading">{{resetBtn.text}}</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SearchBus from "./searchBus";
import { getClassifyList } from "@/api/label.js";
import commonMeth from "../../../api/method";
import qs from "querystring";
export default {
  name: "TopSearch",
  props: {
    topSearchShow: {
      codekeywordPlaceholder: "",
      hotelStoreIDPlaceholder: "",
      namekeywordPlaceholder: "",
      operatorPlaceholder: "",
      coopNamePlaceholder: "请输入联系人关键词",
      coopPhonePlaceholder: "请输入电话号码",
      keywordPlaceholder: "请输入名称",
      hotelNamePlaceholder: "请输入名称",
      hotelContractPlaceholder: "请输入名称",
      MAC_numberPlaceholder: "请输入名称",
    },
    keywordPlaceholder: {
      default: "false",
    },
    pickerIsToday: {
      default: false,
    },
    fromUrl: {
      default: "",
    },
    startDatePlaceholder: {
      default: "开始日期",
    },
    endDatePlaceholder: {
      default: "结束日期",
    },
    searchList: {
      type: Object,
      default: () => {},
    },
    topSearchSave: {
      type: Object,
      default: () => {
        return {
          province: "",
          city: "",
          country: "",
          hotel_group: null,
          hotel_brand: "",
          hotel_use_state_tem: "",
          BD: "",
          is_profit: "",
          coopCompleStatus: "",
          coopType: "",
          onlyCoopType: "",
          partner_id: "",
          taskType: "",
          role: "",
          keyword: "",
          codekeyword: "",
          hotelStoreID: "",
          namekeyword: "",
          coopName: "",
          coopPhone: "",
          operator: "",
          coopPartnerType: "",
          date: "",
          adStatus: "",
          adFormat: "",
          finType: "",
          finStatus: "",
          month: "",
          onlymonth: "",
          onlyYear: "",
          onlyDay: "",
          onlyToday: "",
          noticeStatus: "",
          sysAccStatus: "",
          SourceOfIncome: "",
          SourceOfType: "",
          labelNature: "",
          authorizedOperation: "",
          authorizedObject: "",
          authorizedAction: "",
          labelType: "",
          labelMold: "",
          mediaType: "",
          operation: "",
          hotelName: "",
          hotelContract: "",
          MAC_number: "",
        };
      },
    },
    isQuickDateCur: {},
    parentUrl: {
      default: "other",
    },
    allLoadingState: {},
  },
  data: () => ({
    accountType: "",
    showbelong: true,
    showallot: true,
    topAttr: {
      hotelContract: "",
      MAC_number: "",
      hotelGroupOptionList: [],
      hotelGroupList: "", //集团品牌
      hotelUseState: "", //酒店状态
      hotelUseStatePosition: [
        { id: 5, name: "实施中" },
        { id: 6, name: "待上线" },
        { id: 7, name: "使用中" },
        { id: 11, name: "已离线" },
      ], //阵地状态
      province: "",
      city: "",
      country: "",
      hotelTemplateList: "", //终端门店模板
      networkCondition: "", //网络状况
      networkMode: "", //网络接入方式
      operatingState: "", //终端门店营业状态
      starLevel: "", //酒店标签
      supplierId: "", //高清影视合作方式
      terminalCustomerFrom: "", //终端门店客户来源
      terminalStoreType: "", //终端门店类型
      terminalType: "", //终端类型
      coopType: [
        { id: "channel", name: "渠道商" },
        { id: "screen", name: "聚屏商" },
        { id: "group", name: "酒店集团" },
        { id: "factory", name: "电视厂商" },
      ],
      partner_id: [],
      BD: [],
      is_profit: [
        //审核状态
        { id: 1, name: "分成收益" },
        { id: 0, name: "全部收益  " },
      ],
      //合作伙伴
      coopExamineStatus: [
        //审核状态
        { id: 1, name: "待审核" },
        { id: 2, name: "已审核" },
      ],
      coopCompleStatus: [
        //补全状态
        { id: 1, name: "待补全" },
        { id: 2, name: "已补全" },
      ],
      adStatus: [
        //广告状态
        { id: 1, name: "启用" },
        { id: 0, name: "暂停" },
      ],
      adFormat: [
        //广告格式
        { id: 1, name: "图片" },
        { id: 2, name: "视频" },
      ],
      orderStatus: [
        //广告格式
        { id: 1, name: "未完成" },
        { id: 2, name: "已完成" },
      ],
      finType: [
        //对账类型
        { id: 1, name: "上线费用" },
        { id: 2, name: "市场费用" },
        { id: 3, name: "电影收益" },
        { id: 4, name: "广告收益" },
      ],
      finStatus: [
        //对账状态
        { id: 0, name: "待审核" },
        { id: 1, name: "待对账" },
        { id: 2, name: "已对账" },
        { id: 3, name: "已驳回" },
      ],
      taskType: [
        //任务类型
        // {id:0,name:'渠道商申请'},
        // {id:1,name:'内容分发审批'},
        // {id:2,name:'提现申请'},
        // {id:3,name:'广告订单取人'},
        // {id:4,name:'刷机验收'},
      ],
      taskStatus: [],
      payStatus: [
        { id: 0, name: "不可付款" },
        { id: 1, name: "待付款" },
        { id: 2, name: "已付款" },
      ],
      examineStatus: [
        { id: 0, name: "待审核" },
        { id: 1, name: "已通过" },
        { id: 2, name: "已驳回" },
      ],
      acceptanceStatus: [
        { id: 3, name: "待验收" },
        { id: 6, name: "审核中" },
        { id: 7, name: "已驳回" },
      ],
      acceptanceType: [
        { id: "10", name: "系统验收" },
        { id: "9", name: "在线验收" },
      ],
      hasChildren: [
        { id: "1", name: "非分组酒店" },
        { id: "2", name: "分组酒店" },
      ],
      noticeStatus: [
        //通知状态
        { id: 1, name: "草稿" },
        { id: 2, name: "发布" },
      ],
      coopPartnerType: [
        //归属，所属
        { id: "belongPartnerCode", name: "所属合作伙伴" },
        { id: "allotPartnerCode", name: "归属合作伙伴" },
      ],
      belongPartnerCode: [],
      allotPartnerCode: [],
      roleSelect: [
        { id: 1, name: "渠道商" },
        { id: 2, name: "酒店集团" },
        { id: 3, name: "携旅" },
      ],
      sysAccStatusSelect: [
        { id: 0, name: "开" },
        { id: 1, name: "关" },
      ],
      //待对账状态
      watingHotalStatus: [
        { id: 1, name: "待对账" },
        { id: 3, name: "已驳回" },
        { id: 2, name: "审核中" },
      ],
      SourceOfIncome: [
        { id: "belong", name: "所属酒店" },
        { id: "allot", name: "归属酒店" },
        { id: "subordinate", name: "下级酒店" },
      ],
      SourceOfType: [
        { id: "001001", name: "影视单点" },
        { id: "001002", name: "影视天卡" },
      ],
      labelNatureList: [
        { id: "1", name: "业务标签" },
        { id: "2", name: "阵地标签" },
      ],
      labelType: [],
      labelMold: [
        { id: "0", name: "标签" },
        { id: "1", name: "标签组" },
      ],
      authorizedObject: [
        { id: "1", name: "携旅授权书" },
        { id: "2", name: "腾讯极光授权书" },
      ],
      authorizedOperation: [
        { id: "1", name: "自动" },
        { id: "2", name: "手动" },
      ],
      authorizedAction: [
        { id: "1", name: "生成" },
        { id: "2", name: "上传" },
        { id: "3", name: "关闭" },
        { id: "4", name: "打开" },
      ],
      mediaType: [
        { id: "携旅酒店电视媒体A网", name: "携旅酒店电视媒体A网" },
        { id: "携旅酒店电视媒体B网", name: "携旅酒店电视媒体B网" },
        { id: "携旅酒店电视媒体C网", name: "携旅酒店电视媒体C网" },
        { id: "携旅酒店电视媒体D网", name: "携旅酒店电视媒体D网" },
        { id: "携旅酒店电视媒体E网", name: "携旅酒店电视媒体E网" },
        { id: "携旅酒店电视媒体F网", name: "携旅酒店电视媒体F网" },
      ],
      operation: [
        { id: "1", name: "单店打标签" },
        { id: "2", name: "单店打标签组" },
        { id: "3", name: "批量打标签" },
        { id: "4", name: "批量打标签组" },
        { id: "5", name: "单店取消标签" },
        { id: "6", name: "单店取消标签组" },
        { id: "7", name: "批量取消标签" },
        { id: "8", name: "批量取消标签组" },
      ],
    },
    pickerOptionsDay: {
      disabledDate(time) {
        return time.getTime() > Date.now(); //如果没有后面的-8.64e6就是不可以选择今天的
        // return time.getTime() > Date.now() ;如果没有后面的-8.64e6就是不可以选择今天的
      },
    },
    pickerOptionMonths: {
      disabledDate(time) {
        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let flag = true;
        if (
          time.getTime() > new Date(year - 1 + "-12-31").getTime() &&
          time.getTime() < new Date(year + "-" + month + "-01").getTime()
        ) {
          flag = false;
        }
        return flag;
      },
    },

    pickerOptionsOneDay: {
      disabledDate(time) {
        var date = new Date(Date.now());
        var dateNext = new Date(Date.now() + 8.64e6);
        var today = date.toLocaleDateString();
        var tomorrow = date.toLocaleDateString();
        var todayTime = new Date(today).getTime();
        var tomorrowTime = new Date(tomorrow).getTime();
        if (time.getTime() > tomorrowTime) {
          return true;
        } else if (time.getTime() < todayTime) {
          return true;
        } else {
          return false;
        }
      },
    },
    pickerOptionsToday: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
      disabledDate(time) {
        return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
        // return time.getTime() > Date.now() ;如果没有后面的-8.64e6就是不可以选择今天的
      },
    },
    pickerOptionsYesterday: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            end.setTime(end.getTime() - 3600 * 1000 * 24); //不能选到今天
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
      disabledDate(time) {
        // return time.getTime() > Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
        // return time.getTime() > Date.now() ;//如果没有后面的-8.64e6就是不可以选择今天的
        var times = Date.now() - 24 * 60 * 60 * 1000;
        return time.getTime() > times;
      },
    },
    pickerOptions: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    showallotPartner() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
        switch (this.accountType) {
          case 5:
            this.showallot = false;
            break;
        }
      });
    },
    pickerDateTimeOptions: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    region: {
      prov_code: "",
      city_code: "",
      country: "",
    },
    hotel_group_props: { multiple: true },
    form: {
      hotelContract: "", //合同号
      MAC_number: "",
      hotelName: "", //程序名
      hotel_group: "", //集团
      hotel_brand: "", //集团品牌
      citycode: [], //省市区
      hotel_use_state_tem: "", //酒店状态
      hotel_use_state: [], //
      hotel_use_state_label: [],
      keyword: "", //关键字
      codekeyword: "",
      hotelStoreID: "",
      namekeyword: "", //关键字
      coopName: "",
      coopPhone: "",
      operator: "",
      coopShowText: "", //关键字
      province: "",
      city: "",
      country: "",
      provinceLabel: [],
      countyLabel: [],
      cityLabel: [],
      date: "",
      hotelCreateTime: "",
      startTime: "",
      endTime: "",
      confirmStartTime: "",
      confirmEndTime: "",
      dateRange: "",
      coopType: "",
      onlyCoopType: "",
      BD: "",
      is_profit: "",
      partner_id: "",
      partner_id_arr: "",
      adStatus: "",
      adFormat: "",
      //合作伙伴-审核状态，补全状态
      coopExamineStatus: "",
      coopCompleStatus: "",
      orderStatus: "",
      finType: "",
      finStatus: "",
      taskType: "",
      taskStatus: "",
      payStatus: "",
      examineStatus: "",
      acceptanceStatus: "",
      acceptanceType: "",
      hasChildren: "",
      taskDoneStatus: "",
      month: "",
      onlymonth: "",
      onlyYear: "",
      onlyDay: "",
      onlyToday: "",
      coopPartnerType: "",
      belongPartnerCode: [],
      allotPartnerCode: [],
      noTypePartnerCode: [],
      sysAccStatus: "",
      SourceOfIncome: "",
      SourceOfType: "",
      labelNature: "",
      labelType: "",
      labelMold: "",
      authorizedOperation: "",
      authorizedObject: "",
      authorizedAction: "",
      mediaType: "",
      operation: "",
      role: "",
      coopDialog: "",
      watingHotalStatus: "",
    },
    coopDialogRule: {
      partner_id: [
        { required: true, message: "请选择合作伙伴", trigger: "blur" },
      ],
      coopPartnerType: [
        { required: true, message: "请选择合作伙伴类型", trigger: "blur" },
      ],
    },
    token: "",
    colorOne: "",
    coopDialog: {
      coopDialogText: "已选择6项",
      visible: false,
    },
    isShowPartnerAll: false,
    searchBtn: {
      text: "搜索",
      loading: false,
    },
    resetBtn: {
      text: "重置",
      loading: false,
    },
    nowTime: "",
  }),
  methods: {
    getNowTime() {
      var date = new Date();
      let localDay = date.toLocaleString("chinese", { hour12: false });
      // 日期截取
      var dayEndIndex = localDay.indexOf(":") - 2;
      let time = localDay.substring(9);
      let day = localDay.substring(0, 9);
      this.nowTime = day + " " + time;
    },
    allchoose() {
      this.form.partner_id = this.topAttr.partner_id;
    },
    dateChange(val) {
      console.log(val);
      if (val) {
        this.form.startTime = val[0];
        this.form.endTime = val[1];
        this.form.dateRange = val;
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
        this.form.data = "";
      }
      if (this.isQuickDateCur) {
        this.$emit("setDefaultQuickDateCur", this.form.startTime);
      }
      SearchBus.$emit("setScreenTypeSearchDefault", "date");
    },
    hotelCreateTimeChange(val) {
      this.$emit("setDefaultQuickDateCur");
      SearchBus.$emit("setScreenTypeSearchDefault", "date");
      if (val) {
        this.form.hotelCreateTimeStart = "";
        this.form.hotelCreateTimeEnd = "";
        this.form.dateRange = val;
      } else {
        this.form.hotelCreateTimeStart = "";
        this.form.hotelCreateTimeEnd = "";
      }
    },
    confirmDateChange(val) {
      if (val) {
        this.form.confirmStartTime = val[0];
        this.form.confirmEndTime = val[1];
      } else {
        this.form.confirmStartTime = "";
        this.form.confirmEndTime = "";
      }
    },
    monthChange(val) {
      if (val) {
        this.form.startTime = val[0];
        this.form.endTime = val[1];
        this.form.dateRange = val;
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },

    provinceChange(val) {
      if (val.length === 1) {
        this.region.prov_code = val.value;
      } else {
        this.region.prov_code = "";
      }
      this.form.citycode = [];
      this.form.provinceLabel = [];
      this.form.city = "";
      this.form.country = "";
      this.form.cityLabel = "";
      this.form.countyLabel = "";
      val.forEach((item, index) => {
        this.form.citycode.push(item.value);
        this.form.provinceLabel.push(item.label);
      });
      if (val.length === 1) {
        this.getCity(val[0].value);
        this.region.prov_code = val[0].value;
      } else {
        this.topAttr.city = [];
        this.topAttr.country = [];
      }
    },
    cityChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.prov_code;
        this.form.cityLabel = "";
        this.form.countyLabel = "";
        this.form.country = "";
      } else {
        this.region.city_code = value;
        this.form.citycode = value;
        this.form.country = "";
        this.form.cityLabel = label;
      }
      this.getCountry();
    },
    countyChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.city_code;
        this.form.countyLabel = "";
      } else {
        this.region.citycode = value;
        this.form.citycode = value;
        this.form.countyLabel = label;
      }
    },
    getProvince() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getProvince, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.province = res.data.data;
            }
            resolve();
          });
      });
    },
    getCity(prov_code) {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.city = res.data.data;
            }
            resolve();
          });
      });
    },
    getCountry() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getDistricts, {
            params: {
              token: this.token,
              prov_code: this.region.prov_code,
              city_code: this.region.city_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.country = res.data.data;
            }
            resolve();
          });
      });
    },

    getHotelGroupOptionList() {
      this.$http
        .get(this.global.getHotelGroup, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var optionData = res.data.data;
            console.log(res, "goupdata");
            this.topAttr.hotelGroupOptionList = optionData;
          }
        });
    },
    getOption() {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getOptions, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var optionData = res.data.data;
              this.topAttr.hotelGroupList = optionData.hotelGroupList;
              this.topAttr.hotelTemplateList = optionData.hotelTemplateList;
              this.topAttr.hotelUseState = optionData.hotelUseState;
              this.topAttr.networkCondition = optionData.networkCondition;
              this.topAttr.networkMode = optionData.networkMode;
              this.topAttr.operatingState = optionData.operatingState;
              this.topAttr.starLevel = optionData.starLevel;
              this.topAttr.supplierId = optionData.supplierId;
              this.topAttr.terminalCustomerFrom =
                optionData.terminalCustomerFrom;
              this.topAttr.terminalStoreType = optionData.terminalStoreType;
              this.topAttr.terminalType = optionData.terminalType;
            }
            resolve();
          });
      });
    },
    getPartnerReviewStatus() {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getPartnerReviewStatus, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var data = res.data.data;
              var arr = [];
              var doneArr = [];
              for (var key in res.data.data) {
                arr.push({ name: data[key], value: key });
                if (data[key] !== "待审核") {
                  doneArr.push({ name: data[key], value: key });
                }
              }
              this.topAttr.coopCompleStatus = arr;
              this.topAttr.taskStatus = arr;
              this.topAttr.taskDoneStatus = doneArr;
            }
            resolve();
          });
      });
    },
    getBD() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getAccountManagerList, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.BD = res.data.data;
            }
            resolve();
          });
      });
    },
    getCoopType() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getPartnerTypes, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.coopType = res.data.data;
            }
            resolve();
          });
      });
    },
    getCoop(type) {
      return new Promise((resolve, reject) => {
        this.form.partner_id = "";
        this.$http
          .get(this.global.getPartnersByType, {
            params: {
              token: this.token,
              type: this.form.coopType,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var resData = res.data.data;
              this.topAttr.partner_id = resData;
              resData.length === 0
                ? (this.isShowPartnerAll = false)
                : (this.isShowPartnerAll = true);
            }
          });
        resolve();
      });
    },
    searchMeth() {
      this.btnStateChange(true, "search");
      sessionStorage.setItem("filterCon", JSON.stringify(this.form));
      SearchBus.$emit("setScreenTypeSearchDefault");
      this.$emit("condition", this.form);
      SearchBus.$emit("setScreenTypeSearchDefault", "date");
      // this.$emit("HotalWaitingForm",this.form);
      if (this.isQuickDateCur) {
        this.$emit("setDefaultQuickDateCur", this.form.startTime);
      }
      this.btnStateChange(false, "search");
    },
    resetMeth() {
      // this.btnStateChange(true,'reset');
      this.form = {
        hotelName: "",
        hotel_brand: "", //集团品牌
        hotel_group: null,
        citycode: [], //省市区
        hotel_use_state_tem: "", //酒店状态
        hotel_use_state: [], //
        hotel_use_state_label: [],
        keyword: "",
        codekeyword: "",
        hotelStoreID: "",
        namekeyword: "", //关键字
        coopName: "",
        coopPhone: "",
        operator: "",
        coopShowText: "", //关键字
        province: "",
        city: "",
        country: "",
        provinceLabel: [],
        countyLabel: [],
        cityLabel: [],
        date: "",
        hotelCreateTime: "",
        startTime: "",
        endTime: "",
        confirmStartTime: "",
        confirmEndTime: "",
        dateRange: "",
        coopType: "",
        onlyCoopType: "",
        BD: "",
        is_profit: "",
        partner_id: "",
        partner_id_arr: "",
        adStatus: "",
        adFormat: "",
        //合作伙伴-审核状态，补全状态
        coopExamineStatus: "",
        coopCompleStatus: "",
        orderStatus: "",
        finType: "",
        finStatus: "",
        taskType: "",
        taskStatus: "",
        payStatus: "",
        examineStatus: "",
        acceptanceStatus: "",
        acceptanceType: "",
        hasChildren: "",
        taskDoneStatus: "",
        month: "",
        onlymonth: "",
        onlyYear: "",
        onlyDay: "",
        onlyToday: "",
        coopPartnerType: "",
        belongPartnerCode: [],
        allotPartnerCode: [],
        noTypePartnerCode: [],
        sysAccStatus: "",
        SourceOfIncome: "",
        labelNature: "",
        labelType: "",
        labelMold: "",
        authorizedOperation: "",
        authorizedAction: "",
        authorizedObject: "",
        mediaType: "",
        SourceOfType: "",
        operation: "",
        role: "",
        coopDialog: "",
      };
      this.searchMeth();
    },
    btnStateChange(flag, type) {
      if (type === "search") {
        this.searchBtn.text = commonMeth.btnLoading(flag, "搜索");
        this.searchBtn.loading = flag;
      } else if (type === "reset") {
        this.resetBtn.text = commonMeth.btnLoading(flag, "重置");
        this.resetBtn.loading = flag;
      }
    },
    provinceDataInit(val) {
      if (val) {
        if (val.length === 1) {
          this.region.prov_code = val.value;
        } else {
          this.region.prov_code = "";
        }
        val.forEach((item, index) => {
          this.form.citycode.push(item.value);
          this.form.provinceLabel.push(item.label);
        });
        if (val.length === 1) {
          this.region.prov_code = val[0].value;
          this.getCity(val[0].value)
            .then(() => {
              this.form.city = this.topSearchSave.city;
              this.region.city_code = this.form.city.value;
              this.getCountry();
            })
            .then(() => {
              this.form.country = this.topSearchSave.country;
            });
        } else {
          this.topAttr.city = [];
          this.topAttr.country = [];
        }
      }
    },
    mulSearchMeth(val, type) {
      if (type === "province") {
        if (val.length === 1) {
          this.region.prov_code = val.value;
        } else {
          this.region.prov_code = "";
        }
        this.form.citycode = [];
        this.form.provinceLabel = [];
        this.form.city = "";
        this.form.country = "";
        this.form.cityLabel = "";
        this.form.countyLabel = "";
        val.forEach((item, index) => {
          this.form.citycode.push(item.value);
          this.form.provinceLabel.push(item.label);
        });
        if (val.length === 1) {
          this.getCity(val[0].value);
          this.region.prov_code = val[0].value;
        } else {
          this.topAttr.city = [];
          this.topAttr.country = [];
        }
      }
      if (type === "hotel_use_state") {
        this.form.hotel_use_state = [];
        this.form.hotel_use_state_label = [];
        SearchBus.$emit("setScreenTypeSearchDefault", "hotel_use_state");
        val.forEach((item, index) => {
          this.form.hotel_use_state.push(item.value);
          this.form.hotel_use_state_label.push(item.name);
        });
      }
      if (this.form.coopPartnerType === "belongPartnerCode") {
        this.getPartnerCode("belong");
      } else if (this.form.coopPartnerType === "allotPartnerCode") {
        this.getPartnerCode("allot");
      } else {
        this.getPartnerCode();
      }
    },
    getPartnerCode(type) {
      var str = "";
      if (Array.isArray(this.form.partner_id)) {
        str = this.form.partner_id.join(",");
      }
      var finalArr = [];
      if (str === "all") {
        this.topAttr.partner_id.forEach((item, index) => {
          finalArr.push(item.id);
        });
      } else {
        finalArr = this.form.partner_id;
      }
      if (type === "belong") {
        this.form.belongPartnerCode = finalArr;
        this.form.allotPartnerCode = [];
      } else if (type === "allot") {
        this.form.allotPartnerCode = finalArr;
        this.form.belongPartnerCode = [];
      } else {
        this.form.allotPartnerCode = [];
        this.form.belongPartnerCode = [];
        this.form.noTypePartnerCode = finalArr;
      }
    },
    selectAll(val, type) {
      var flag = false;
      var finalArr = [];
      for (var key of val) {
        if (key === "all") {
          flag = true;
        }
      }
      if (flag) {
        if (type === "belongPartnerCode") {
          this.topAttr.belongPartnerCode.forEach((item, index) => {
            finalArr.push(item.code);
          });
          this.form.belongPartnerCode = finalArr;
        } else if (type === "allotPartnerCode") {
          // this.form.allotPartnerCode=[];
          this.topAttr.allotPartnerCode.forEach((item, index) => {
            finalArr.push(item.code);
          });
          this.form.allotPartnerCode = finalArr;
        }
      }
    },
    getTaskType() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getReviewTypePullDownList, {
            params: {
              token: this.token,
              isApaas: 1,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.taskType = res.data.data;
            }
            resolve();
          });
      });
    },
    blurMeth(val) {},
    getRoleOption() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getGroup, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var data = res.data.data;
              this.topAttr.roleSelect = this.transObj(data);
            } else {
              // this.$message.error(res.data.msg);
            }
            resolve();
          })
          .catch((res) => {
            // this.$message.error(res.data.msg);
          });
      });
    },
    transObj(data) {
      var arr = [];
      for (var i in data) {
        arr.push({ id: i, name: data[i] });
      }
      return arr;
    },
    showCoopDialog() {
      this.coopDialog.visible = true;
    },
    coopDialogConfirm() {
      if (this.coopDialogValidate()) {
        this.coopDialog.visible = false;
        this.mulSearchMeth();
        this.getCoopSelectedLength();
      }
    },
    getCoopSelectedLength() {
      if (this.form.partner_id && this.form.partner_id !== "") {
        if (this.form.coopPartnerType === "belongPartnerCode") {
          this.form.coopShowText = `已选择${this.form.belongPartnerCode.length}项`;
        } else if (this.form.coopPartnerType === "allotPartnerCode") {
          this.form.coopShowText = `已选择${this.form.allotPartnerCode.length}项`;
        } else {
          this.form.coopShowText = `已选择${this.form.noTypePartnerCode.length}项`;
        }
      } else {
        this.form.coopShowText = "";
      }
    },
    coopDialogValidate() {
      var flag = false;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          flag = true;
        } else {
          this.$message.warning("请选择合作伙伴");
          return false;
        }
      });
      return flag;
    },
    coopDialogCancel() {
      var form = this.form;
      if (form.coopShowText === "") {
        form.coopType = "";
        form.coopPartnerType = "";
        form.partner_id = "";
      }
      this.coopDialog.visible = false;
    },
    coopShowChange(val) {
      if (val === "") {
        this.form.coopType = "";
        this.form.partner_id = [];
      }
    },
    clearCoopFilter() {
      this.form.coopType = "";
      this.form.partner_id = "";
      this.form.coopPartnerType = "";
      this.form.coopShowText = "";
      this.mulSearchMeth();
    },
    setDefault(type) {
      if (type === "date") {
        this.form.date = "";
      } else if (type === "hotel_use_state") {
        this.form.hotel_use_state_tem = [];
      }
    },
    setHotelCreateTimeDefault() {
      this.form.hotelCreateTime = "";
    },
    getDefaultData(topSearchSave) {
      var form = this.form;
      this.getOption().then(() => {
        form.hotel_brand = topSearchSave.hotel_brand;
        topSearchSave.hotel_use_state_tem === "10"
          ? (form.hotel_use_state_tem = "")
          : (form.hotel_use_state_tem = topSearchSave.hotel_use_state_tem);
      });
      this.getProvince().then(() => {
        form.province = topSearchSave.province;
        this.provinceDataInit(form.province);
      });
      //   this.getCity().then(()=>{
      //     form.city=topSearchSave.city;
      // });
      this.getBD().then(() => {
        form.BD = topSearchSave.BD;
      });
      this.getPartnerReviewStatus().then(() => {
        form.coopCompleStatus = topSearchSave.coopCompleStatus;
        form.taskStatus = topSearchSave.taskStatus;
        form.payStatus = topSearchSave.payStatus;
        form.examineStatus = topSearchSave.examineStatus;
        form.acceptanceStatus = topSearchSave.acceptanceStatus;
        form.acceptanceType = topSearchSave.acceptanceType;
        form.hasChildren = topSearchSave.hasChildren;

        form.taskDoneStatus = topSearchSave.taskDoneStatus;
      });
      this.getCoopType()
        .then(() => {
          form.coopType = topSearchSave.coopType;
          form.onlyCoopType = topSearchSave.onlyCoopType;
          this.getCoop();
        })
        .then(() => {
          form.partner_id = topSearchSave.partner_id;
          form.belongPartnerCode = topSearchSave.belongPartnerCode;
          form.allotPartnerCode = topSearchSave.allotPartnerCode;
          form.noTypePartnerCode = topSearchSave.noTypePartnerCode;
          this.getCoopSelectedLength();
        });
      this.getTaskType().then(() => {
        form.taskType = topSearchSave.taskType;
      });
      //首页待办下拉
      this.getRoleOption().then(() => {
        form.role = topSearchSave.role;
      });

      form.hotelName = topSearchSave.hotelName;
      form.hotelContract = topSearchSave.hotelContract;
      form.MAC_number = topSearchSave.MAC_number;
      form.keyword = topSearchSave.keyword;
      form.codekeyword = topSearchSave.codekeyword;
      form.hotelStoreID = topSearchSave.hotelStoreID;
      form.namekeyword = topSearchSave.namekeyword;
      form.coopName = topSearchSave.coopName;
      form.coopPhone = topSearchSave.coopPhone;
      form.operator = topSearchSave.operator;
      form.coopPartnerType = topSearchSave.coopPartnerType;
      form.date = topSearchSave.date;
      form.hotelCreateTime = topSearchSave.hotelCreateTime;
      form.adStatus = topSearchSave.adStatus;
      form.adFormat = topSearchSave.adFormat;
      form.finType = topSearchSave.finType;
      form.finStatus = topSearchSave.finStatus;
      form.month = topSearchSave.month;
      form.onlymonth = topSearchSave.onlymonth;
      form.onlyYear = topSearchSave.onlyYear;
      form.onlyDay = topSearchSave.onlyDay;
      form.onlyToday = topSearchSave.onlyToday;
      form.noticeStatus = topSearchSave.noticeStatus;
      form.sysAccStatus = topSearchSave.sysAccStatus;
      form.SourceOfIncome = topSearchSave.SourceOfIncome;
      form.SourceOfType = topSearchSave.SourceOfType;
      form.labelNature = topSearchSave.labelNature;
      form.labelType = topSearchSave.labelType;
      form.is_profit = topSearchSave.is_profit;
      form.labelMold = topSearchSave.labelMold;
      form.authorizedOperation = topSearchSave.authorizedOperation;
      form.authorizedObject = topSearchSave.authorizedObject;
      form.authorizedAction = topSearchSave.authorizedAction;
      form.mediaType = topSearchSave.mediaType;
      form.SourceOfType = topSearchSave.SourceOfType;
      form.operation = topSearchSave.operation;
    },
    changePartnerId(val) {
      var arrBack = [...val];
      var popEl = arrBack.pop();
      if (popEl == "all") {
        if (this.form.partner_id[0] == "all") {
          this.form.partner_id = [];
          this.topAttr.partner_id.forEach((item) => {
            this.form.partner_id.push(item.id);
          });
        } else {
          this.form.partner_id = [];
        }
      } else {
        var allStrIndex = val.indexOf("all");
        if (allStrIndex !== -1) {
          val.splice(allStrIndex, 1);
        }
        this.form.partner_id = val;
      }
    },
    // 获取页面信息
    getLabelData() {
      const json = {
        page: 1,
        limit: 99999,
      };
      var param = qs.stringify(json);
      getClassifyList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.topAttr.labelType = res.data.data.data;
          }
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getDefaultData(this.topSearchSave);
    let apaasData = sessionStorage.getItem("apaasData");
    if (!apaasData) {
      this.showallotPartner();
    }
    this.getNowTime();
    this.getLabelData();
    this.getHotelGroupOptionList();
  },
  created() {
    SearchBus.$on("setTopSearchDefault", (type) => {
      if (type === "hotel_use_state") {
        this.form.hotel_use_state_tem = [];
        sessionStorage.setItem("filterCon", JSON.stringify(this.form));
      } else if (type === "date") {
        this.form.hotel_use_state_tem = [];
        this.form.hotelCreateTime = "";
        this.form.hotelCreateTimeStart = "";
        this.form.hotelCreateTimeEnd = "";
        this.form.date = "";
        this.form.startTime = "";
        this.form.endTime = "";
        sessionStorage.setItem("filterCon", JSON.stringify(this.form));
      }
    });
  },
  watch: {
    "form.coopShowText"(newVal) {
      if (newVal === "已选择0项") {
        this.form.coopShowText = "";
      }
    },
    topSearchSave(newVal) {
      this.getDefaultData(newVal);
    },
    allLoadingState(newVal) {
      if (newVal) {
        this.btnStateChange(false, "search");
        this.btnStateChange(false, "reset");
      } else {
        this.btnStateChange(true, "search");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-col {
  margin-top: 20px;
}
.fullSelect {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.coopShowCol {
  position: relative;
}
.inputSpanBox {
  position: absolute;
  right: 25px;
  bottom: 13px;
  z-index: 99;
  color: #2e63a6;
  font-size: 14px;
  cursor: pointer;
}
.closeIcon {
  margin-left: 10px;
}
.relativeEl {
  position: relative;
}
.coopDialog {
  padding-bottom: 0;
}
.noMarginBottom {
  margin-bottom: 0;
}
.flexBox {
  display: flex;
}
.rightBorder {
  border-right: 1px solid #dbe0f1;
}
.btnCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.searchCol {
  padding-top: 0 !important;
}
.resetBtn {
  margin-top: 20px;
}
.line {
  width: 1px;
  background: #dbe0f1;
  margin: 20px 10px 0 10px;
}
.topSearchRow {
  display: flex;
}
.noMargin {
  margin-left: 0 !important;
}
.searchBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-cascader ::v-deep .el-cascader__search-input {
  border: none !important;
}
</style>
