<!-- 属性配置 -->
<template>
  <div>
    <div class="form_box">
      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <el-input v-model="form.attr" placeholder="属性名模糊搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              pagination.currentPage = 1;
              getList();
            "
            >搜 索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- table -->
    <div>
      <el-button
        type="primary"
        class="btn"
        @click="
          current = {};
          attrDia = true;
        "
        >添 加</el-button
      >
      <el-button type="danger" @click="del" class="btn">批量删除</el-button>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-cell-style="{
          background: '#f8fbff',
          color: '#606266',
        }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="属性名"></el-table-column>
        <el-table-column prop="attr" label="属性号"></el-table-column>
        <el-table-column prop="morder" label="排序"></el-table-column>
        <el-table-column prop="operator" label="操作者"></el-table-column>
        <el-table-column fixed="right" label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              @click="
                current = scope.row;
                attrDia = true;
              "
              type="text"
              size="small"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        layout="total,prev, pager, next"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        v-show="pagination.total != 0"
        @current-change="
          (val) => {
            pagination.currentPage = val;
            getList();
          }
        "
      >
      </el-pagination>
    </div>
    <AttrOper
      :visible.sync="attrDia"
      :current="current"
      @refresh="
        pagination.currentPage = 1;
        getList();
      "
    ></AttrOper>
  </div>
</template>
<script>
import { getList, del } from "./api/attr";
import AttrOper from "@/components/operation/SettingManage/components/attrOper";
export default {
  name: "attr",
  components: { AttrOper },
  data() {
    return {
      auth:null,
      form: {
        attr: "",
      },
      tableData: [{ id: 1 }],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      selected: [],
      attrDia: false,
      current: {},
    };
  },
  methods: {
    del() {
      if (this.selected.length == 0) {
        this.$message.error("尚未选择属性");
        return;
      }
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({
            ids: this.selected
              .map((item) => {
                return item.id;
              })
              .join(","),
               user_name: this.auth.user_name,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.selected = val;
    },
    getList(page) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        page: page ? page : this.pagination.currentPage,
        size: 10,
        search_name: this.form.attr,
      }).then((data) => {
        this.tableData = data.data.data.row;
        this.pagination.total = parseInt(data.data.data.totalNum);
      });
    },
  },
  mounted() {
    this.getList();
     this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
</style>
