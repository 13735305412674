<template>
  <div v-loading="loading">
    <div v-if="checkReason!=''" style="margin-bottom:10px">
    <span >{{checkReason}}</span>
    </div>
    <el-form ref="rateForm" label-width="140px" :model="rateForm" class="noFixedWidthForm" :rules="rateRules">
      <div>
        <el-form-item label="合同号" prop="contractNum">
          <el-input :readonly="sys==true||executingReadOnly||tabType==='unExecuted'" style="width:280px" placeholder="请输入内容" v-model="rateForm.contractNum" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="合同起止日期" prop="contractTime">
          <el-date-picker :disabled="sys==true||executingReadOnly||tabType==='unExecuted'" style="width:280px" value-format="yyyy-MM-dd" v-model="rateForm.contractTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="目标量" prop="targetNum">
          <el-input :readonly="sys==true||executingReadOnly||tabType==='unExecuted'" style="width:280px" placeholder="请输入内容" v-model="rateForm.targetNum" clearable>
          </el-input>
        </el-form-item>
        <el-form-item class="" label="阶梯开始计算时间" prop="computerStartTime">
          <el-date-picker :disabled="sys==true||executingReadOnly||tabType==='unExecuted'" @change="startTimeChange" style="width:280px" v-model="rateForm.computerStartTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="阶梯结束计算时间" prop="computerEndTime">
          <el-date-picker :disabled="sys==true||executingReadOnly||tabType==='unExecuted'" @change="endTimeChange" style="width:280px" v-model="rateForm.computerEndTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="附件上传" prop="attachment">
          <el-upload :before-upload="uploadBefore" accept=".PDF" v-if="!(sys==true||executingReadOnly||tabType==='unExecuted')" class="upload-demo" :action="global.materialUpload" :headers="headers" :on-remove="uploadRemove" :on-success="uploadSuccess" :limit="1" ref="pic" :on-exceed="uploadExceed" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div v-else class="downloadBox">
            <el-button type="text" size="mini" @click="donowdFile(rateForm.attachment)">{{ rateForm.attachment_name }}
            </el-button>
          </div>

        </el-form-item>
        <!-- <el-form-item label="合同号">
          <el-button icon="el-icon-plus" size="mini" circle @click.prevent="log"></el-button>
        </el-form-item> -->
      </div>
      <el-row class="oprateRow" v-if="sys!=true">
        <div v-if="!executingReadOnly">
          <el-form-item label="配置类型" prop="configType">
            <el-select style="width:280px" v-if="hotelOrPartner==='partner'" v-model="rateForm.configType" class="fullSelect">
              <!--<el-option v-if="item.key!=='defineValid'"-->
              <el-option v-if="(authType==='htrip' && item.key!=='defineValid') ||(authType!=='htrip' && item.key!=='defineValid' && item.key!=='flash' && item.key!=='market')" v-for="item in selectData.configTypeSelect" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="!executingReadOnly">
          <el-form-item class="dialogOpeBtn addToType">
            <el-button icon="el-icon-plus" size="mini" circle @click.prevent="addToType"></el-button>
          </el-form-item>
        </div>

        <div>
          <el-form-item>
            <el-button type="primary" class="largeBtn" @click.prevent="allSave" v-if="(tabType==='executing'&& noDataExecuting) || tabType!=='executing'">提交</el-button>
            <el-button type="primary" class="largeBtn editBtn" @click.prevent="copyDataToUnExected" v-if="tabType==='executing'&& !noDataExecuting && noDataUnExecuted&&sys!=true&&dataReset!=true">修改配置</el-button>
          </el-form-item>
        </div>

      </el-row>
    </el-form>
    <div v-if="rateItemShow">
      <div v-if="tabType==='unExecuted' && preEditInfo.current_name" class="preEditInfo">最后一次由{{preEditInfo.current_name}}于{{preEditInfo.update_time_text}}保存</div>
      <coop-tab-rate-item :sys="sys" :form="allData.flashCost" ref="flash" type="flash" :id="id" title="上线结算单价" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.marketCost" ref="market" type="market" :id="id" title="市场结算单价" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :year="year" :form="allData.vodonlineCost" ref="vodonline" type="vodonline" :id="id" title="影视单点分成比例" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.vodofflineCost" ref="vodoffline" type="vodoffline" :id="id" title="影视分成比例（本地服务器）" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.adCost" ref="ad" type="ad" :id="id" title="广告分成比例" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.shopCost" ref="shop" type="shop" :id="id" title="商城分成比例" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.defineValidCost" ref="defineValid" type="defineValid" :id="id" title="有效激活终端数" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.cinemaOne" ref="cinemaOne" type="cinemaOne" :id="id" title="优客单片" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.cinemaCard" ref="cinemaCard" type="cinemaCard" :id="id" title="优客天卡" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.independentOneCost" ref="independentOne" type="independentOne" :id="id" title="独立影视单点" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :form="allData.independentCardCost" ref="independentCard" type="independentCard" :id="id" title="独立影视天卡" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>
      <coop-tab-rate-item :sys="sys" :year="year" :form="allData.cardCost" ref="card" type="card" :id="id" title="影视天卡分成比例" @getNewVal="getNewVal" :executingReadOnly="executingReadOnly" :hotelOrPartner="hotelOrPartner" v-bind="$attrs"></coop-tab-rate-item>

    </div>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "Executing",
  inheritAttrs: false,
  props: [
    "id",
    "tabType",
    "noDataExecuting",
    "noDataUnExecuted",
    "allData",
    "hotelOrPartner",
    "sys",
    "dataReset",
    "optYear",
    "contentData",
    "checkReason"
  ],
  data: () => ({
    year: "",
    rateForm: {
      configType: "",
      computerStartTime: "",
      computerEndTime: "",
      contractTime: [],
      contractNum: "",
      targetNum: "",
      attachment_name: "",
      attachment: "",
    },
    rateRules: {
      configType: [{ required: true, message: "配置类型不能为空" }],
      computerStartTime: [
        {
          required: true,
          message: "阶梯开始计算时间不能为空",
          trigger: "blur",
        },
      ],
      // computerEndTime: [
      //   { required: true, message: "阶梯结束计算时间不能为空", trigger: "blur" },
      // ],
      contractTime: [
        { required: true, message: "合同起止日期不能为空", trigger: "blur" },
      ],
      contractNum: [
        { required: true, message: "合同号不能为空", trigger: "blur" },
      ],
      targetNum: [
        { required: true, message: "目标量不能为空", trigger: "blur" },
      ],
      year: [{ required: true, message: "年份不能为空" }],
      attachment: [{ required: true, message: "请选择附件", trigger: "blur" }],
    },
    selectData: {
      configTypeSelect: [],
    },
    rateItemTypeArr: [
      "flash",
      "market",
      "vodonline",
      "vodoffline",
      "ad",
      "shop",
      "defineValid",
      "independentOne",
      "independentCard",
      "cinemaOne",
      "cinemaCard",
      "card",
    ],
    rateItemShow: false,
    preEditInfo: {
      current_name: "",
      update_time_text: "",
    },
    loading: false,
    token: "",
    status:"",
    authType: "",
    yearsData: [],
    headers: {
      token: "",
    },
    fileList: [],
  }),
  methods: {
    uploadBefore() {
      if (this.fileList.length >= 1) {
        this.$message.warning("最多只可上传一个附件");
        return false;
      }
    },
    // 文件下载
    donowdFile(row) {
      this.$confirm("是否确认下载或查看当前文件？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.open(row);
        })
        .catch((err) => {});
    },
    //文件删除
    uploadRemove(file) {
      this.fileList = [];
      this.rateForm.attachment_name = "";
      this.rateForm.attachment = "";
      this.$message.success("文件删除成功，请重新添加附件");
    },
    // 文件上传成功
    uploadSuccess(response, file, fileList) {
      this.$message.success("文件上传成功");
      this.rateForm.attachment_name = file.name;
      this.rateForm.attachment = file.response.data.url;
      this.fileList = fileList;
    },
    // 文件上传数量
    uploadExceed(files) {
      this.$message.warning("附件最大数量为1");
    },
    startTimeChange() {
      if (this.rateForm.computerEndTime) {
        var start = new Date(this.rateForm.computerStartTime).getTime();
        var end = new Date(this.rateForm.computerEndTime).getTime();
        if (start > end) {
          this.rateForm.computerStartTime = "";
          this.$message.warning("阶梯计算开始时间不得超过阶梯计算结束时间");
        }
      }
    },
    endTimeChange() {
      if (this.rateForm.computerStartTime) {
        var start = new Date(this.rateForm.computerStartTime).getTime();
        var end = new Date(this.rateForm.computerEndTime).getTime();
        if (start > end) {
          this.rateForm.computerEndTime = "";
          this.$message.warning("阶梯计算开始时间不得少于阶梯计算结束时间");
        }
      }
    },
    getPreDetail() {
      var param = qs.stringify({
        token: this.token,
        partnerId: this.id,
      });
      this.$http.post(this.global.getPartnerPreConfigure, param).then((res) => {
        if (res.data.code === 100000) {
          var resData = res.data.data;
        }
      });
    },
    isEmptyObj(obj) {
      for (let i in Object.keys(obj)) {
        return false; // 进入循环即不为空
      }
      return true;
    },
    getNewVal(configType, val) {
      this.$emit("getNewVal", this.tabType, configType, val);
    },
    getConfigType() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getPartnerConfigType, param).then((res) => {
        if (res.data.code === 100000) {
          this.selectData.configTypeSelect = res.data.data;
        }
      });
    },
    addToType() {
      this.$refs["rateForm"].validate((valid, obj) => {
        if (valid) {
          if (
            typeof this.$refs[this.rateForm.configType].transForm == "undefined"
          ) {
            this.$message.warning("请完善信息");
            return false;
          }
          if (this.$refs[this.rateForm.configType].transForm.group.length > 0) {
            this.$message.warning("该配置类型已存在");
          } else {
            this.groupPushData(this.rateForm.configType);
          }
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    allSave() {
      if (this.formValidate()) {
        var rateItemTypeArr = this.rateItemTypeArr;
        var flash = this.transArrToNull(
          this.$refs[rateItemTypeArr[0]].transForm
        );
        var market = this.transArrToNull(
          this.$refs[rateItemTypeArr[1]].transForm
        );
        var vodonline = this.transArrToNull(
          this.$refs[rateItemTypeArr[2]].transForm
        );
        var vodoffline = this.transArrToNull(
          this.$refs[rateItemTypeArr[3]].transForm
        );
        var ad = this.transArrToNull(this.$refs[rateItemTypeArr[4]].transForm);
        var shop = this.transArrToNull(
          this.$refs[rateItemTypeArr[5]].transForm
        );
        var defineValid = this.transArrToNull(
          this.$refs[rateItemTypeArr[6]].transForm
        );
        var independentOne = this.transArrToNull(
          this.$refs[rateItemTypeArr[7]].transForm
        );
        var independentCard = this.transArrToNull(
          this.$refs[rateItemTypeArr[8]].transForm
        );
        var cinemaOne = this.transArrToNull(
          this.$refs[rateItemTypeArr[9]].transForm
        );
        var cinemaCard = this.transArrToNull(
          this.$refs[rateItemTypeArr[10]].transForm
        );
        var card = this.transArrToNull(
          this.$refs[rateItemTypeArr[11]].transForm
        );
        var reqUrl = "";
        var obj = {};
        var global = this.global;
        if (
          flash === "" &&
          market === "" &&
          vodonline === "" &&
          vodoffline === "" &&
          ad === "" &&
          shop === "" &&
          defineValid === "" &&
          independentOne === "" &&
          cinemaOne === "" &&
          cinemaCard === "" &&
          independentCard === "" &&
          card === ""
        ) {
          this.$message.warning("请完善信息");
          return;
        }
        this.loading = true;
        if (this.hotelOrPartner === "hotel") {
          reqUrl = global.updatePartnerHotelConfig;

          obj = {
            contract_number: this.rateForm.contractNum,
            contract_date_begin: this.rateForm.contractTime[0],
            contract_date_end: this.rateForm.contractTime[1],
            contract_num: this.rateForm.targetNum,
            calculate_start_time: this.rateForm.computerStartTime,
            calculate_end_time: this.rateForm.computerEndTime,
            attachment_name: this.rateForm.attachment_name, // 文件名称
            attachment: this.rateForm.attachment, //  文件地址
            settlement_year: this.optYear,
            hotelContract: this.$attrs.hotel_contract,
            token: this.token,
            flash,
            market,
            vodonline,
            vodoffline,
            ad,
            shop,
            defineValid,
            independentOne,
            independentCard,
            cinemaOne,
            cinemaCard,
            card,
          };
        } else {
          reqUrl = global.updatePartnerConfig;
          obj = {
            id: this.id,
            contract_number: this.rateForm.contractNum,
            contract_date_begin: this.rateForm.contractTime[0],
            contract_date_end: this.rateForm.contractTime[1],
            contract_num: this.rateForm.targetNum,
            attachment: this.rateForm.attachment,
            calculate_start_time: this.rateForm.computerStartTime,
            calculate_end_time: this.rateForm.computerEndTime,
            settlement_year: this.optYear,
            attachment_name: this.rateForm.attachment_name, // 文件名称
            attachment: this.rateForm.attachment, //  文件地址
            token: this.token,
            flash,
            market,
            vodonline,
            vodoffline,
            ad,
            shop,
            defineValid,
            independentOne,
            independentCard,
            cinemaOne,
            cinemaCard,
            card,
          };
        }
        this.$http.post(reqUrl, qs.stringify(obj)).then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("已提交审核");
            this.resetForm();
            if (this.optYear != "2021") {
              this.$emit("getyear");
              this.$emit("getPreDetail");
            } else {
              this.$emit("getDetail");
              this.$emit("getPreDetail");
            }
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false;
        });
      } else {
        this.$message.warning("请完善信息");
      }
    },
    transArrToNull(val, num) {
      var str = "";
      if (val.group.length !== 0) {
        str = JSON.stringify(val.group);
      }
      return str;
    },
    copyDataToUnExected() {
      this.$emit("copyDataToUnExected");
    },
    formValidate() {
      var validateArr = [];
      this.rateItemTypeArr.forEach((item, index) => {
        validateArr.push(this.$refs[item].getFormValid());
      });
      if (validateArr.indexOf(false) !== -1) {
        return false;
      } else {
        return true;
      }
    },
    groupPushData() {
      this.$emit("groupPushData", this.tabType, this.rateForm.configType);
    },
    showDataInfo() {
      let data = this.contentData;
      if (data) {
        this.rateForm.contractNum = data.contract_number;
        if(data.contract_date_begin&&data.contract_date_end){
          this.rateForm.contractTime = [data.contract_date_begin, data.contract_date_end];
        }else{
          this.rateForm.contractTime = [];
        }
      
        this.rateForm.targetNum = data.contract_num;
        this.rateForm.computerStartTime = data.calculate_start_time;
        this.rateForm.computerEndTime = data.calculate_end_time;
        if (data.attachment_name) {
          this.rateForm.attachment_name = data.attachment_name;
          this.rateForm.attachment = data.attachment;
        }
      } else {
        this.rateForm.contractNum = "";
        this.rateForm.contractTime = [];
        this.rateForm.targetNum = "";
        this.rateForm.computerStartTime = "";
        this.rateForm.computerEndTime = "";
        this.rateForm.attachment_name = "";
        this.rateForm.attachment = "";
      }
    },
    // 重置表单
    resetForm() {
      this.rateForm.contractNum = "";
      this.rateForm.contractTime = [];
      this.rateForm.targetNum = "";
      this.rateForm.computerStartTime = "";
      this.rateForm.computerEndTime = "";
      this.rateForm.attachment_name = "";
      this.rateForm.attachment = "";
      this.fileList = [];
    },
    getPreEditInfo() {
      if (this.tabType === "unExecuted") {
        var allData = this.allData;
        var that = this;
        Object.keys(allData).forEach(function (key) {
          if (!allData[key].group) return;
          if (!allData[key].group.length > 0) return;
          if (allData[key].group[0].current_name) {
            that.preEditInfo = {
              current_name: allData[key].group[0].current_name,
              update_time_text: allData[key].group[0].update_time_text,
            };
          }
        });
      }
    },
  },

  mounted() {
    this.token = sessionStorage.getItem("token");
    this.headers.token = sessionStorage.getItem("token");
    this.getPreDetail();
    this.getConfigType();
    this.getPreEditInfo();
    this.authType = JSON.parse(sessionStorage.getItem("auth")).type;
  },
  watch: {
    allData: {
      handler: function (newVal) {
        this.getPreEditInfo();
        this.rateItemShow = true;
      },
      deep: true,
      immediate: true,
    },
    contentData: {
      handler: function (newVal) {
        this.showDataInfo();
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    executingReadOnly() {
      if (this.tabType === "executing" && !this.noDataExecuting) {
        return true; //只读
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.dialogOpeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addToType {
  padding: 0 20px;
}
.preEditInfo {
  margin-bottom: 20px;
  font-size: 14px;
}
.editBtn {
}
.oprateRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.noFixedWidthForm ::v-deep .el-form-item__label {
  width: 140px !important;
}
::v-deep .el-upload-list {
  position: absolute;
  top: 0;
  left: 100px;
}
.downloadBox {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
