<template>
  <!--运营酒店列表 tab1-->
  <div>
    <el-form :model="form" :rules="rules" class="tabContent" ref="form" style="margin-top: 15px" v-loading="formLoading">
      <el-col :span="12">
        <el-form-item prop="groupId" label="门店品牌" label-width="200px">
          <el-select :disabled="groupDisabled || hotel_is_children" ref="groupName" v-model="form.groupId" class="selectItem" filterable @change="groupChange">
            <el-option v-for="item in selectData.hotelGroupList" :key="item.id" :label="item.groupname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="集团门店ID" label-width="200px" prop="hotel_store_id">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_store_id"></el-input>
        </el-form-item>
      </el-col>
      <!--<el-col :span="12" style="visibility:hidden;">-->
      <!--<el-form-item label="" label-width="200px">-->
      <!--<el-select v-model="form.hotel_template_id" class="selectItem" filterable>-->
      <!--<el-option-->
      <!--v-for="item in selectData.hotelTemplateList"-->
      <!--:key="item.id"-->
      <!--:label="item.name"-->
      <!--:value="item.id">-->
      <!--</el-option>-->
      <!--</el-select>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <el-col :span="12">
        <el-form-item prop="hotel" label="门店名称" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="集团门店名称" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.group_hotel_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="!id">
        <el-form-item label="终端门店编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.contract_365"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="id">
        <el-form-item label="终端门店编码" prop="contract_365" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.contract_365"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item prop="hotel_use_state" label="酒店状态" label-width="200px">
          <!--<el-input v-model="form.hotel_use_state" ></el-input>-->
          <el-select v-if="form.hotel_use_state=='10'" v-model="form.hotel_use_state" :disabled="form.hotel_use_state=='10'||hotel_is_children" class="selectItem" filterable>
            <template v-for="item in selectData.hotelUseState">
              <el-option :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </template>

          </el-select>
          <el-select :disabled="hotel_is_children" v-if="form.hotel_use_state!='10'" v-model="form.hotel_use_state" class="selectItem" filterable>
            <template v-for="item in selectData.hotelUseState">
              <el-option v-if="item.id!='10'" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </template>

          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否美团" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.is_ctrip" placeholder="请选择" @change="ctripChange" class="selectItem" filterable>
            <template v-for="(item,index) in selectData.is_ctrip_select">
              <el-option :key="index" :label="item.name" :value="item.id">
              </el-option>
            </template>
          </el-select>
          <!--<el-input v-model="form.network_remarks" ></el-input>-->
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="聚屏合作类型" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.juping_type" class="selectItem" filterable>
            <el-option v-for="item in selectData.juping_type_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="is_moive_settlement" label="合作伙伴影视结算" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.is_moive_settlement" placeholder="请选择" class="selectItem" filterable>
            <el-option v-for="item in selectData.is_moive_settlement" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="network_remarks" label="SAAS产品" label-width="200px">
          <el-select :disabled="osDisabled||hotel_is_children" v-model="form.is_os_sell" placeholder="请选择" class="selectItem" @change="OSChange" filterable>
            <el-option v-for="item in selectData.is_os_sell_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <!--<el-input v-model="form.network_remarks" ></el-input>-->
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="false">
        <el-form-item label="售卖产品" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.sell_goods" class="selectItem" filterable multiple>
            <el-option v-for="item in selectData.sell_goods" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <!--<el-col :span="12" :class="query.id?'occupyEl':''">-->
      <!--<template :class="query.id?'hideEl':''">-->
      <template v-if="!edit||form.hotel_use_state=='10'">
        <el-col :span="12" style="display:flex;">
          <!--<el-form-item :prop="id?'':'selectedHotelTemLabel'" label="终端门店系统版式" label-width="200px"> </el-form-item>-->
          <el-form-item prop="selectedHotelTemLabel" label="门店系统版式" label-width="200px">
            <el-button type="primary" v-if="!edit||form.hotel_use_state=='10'" @click="showTemplateDialog">选择</el-button>
          </el-form-item>
          <el-form-item class="fullFormItem">
            <el-input :readonly="true" v-model="selectedHotel.label" class="selectItem tenMarginLeft" placeholder="该内容在选择系统版式后自动读取，无需填写"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <el-col :span="12" v-if="edit&&form.hotel_use_state!='10'">
        <el-form-item label="所选版式" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="selectedHotel.label" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="bu_status" label="门店开业时间" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" v-model="form.bu_status" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <!--<el-col :span="12" class="occupyEl">-->
      <!--<el-form-item  label-width="200px">-->
      <!--<el-input v-model="selectedHotel.label" :readonly="true"-->
      <!--placeholder="该内容在选择系统版式后自动读取，无需填写"></el-input>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <el-col :span="12" class="">
        <div>
          <el-form-item prop="hotel_template_id" label="门店模板" label-width="200px">
            <el-select :disabled="hotel_is_children" v-model="form.hotel_template_id" class="selectItem" filterable>
              <el-option v-for="item in selectData.hotelTemplateList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="group_code" label="集团项目编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.group_code"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_level" label="门店级别" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.hotel_level" class="selectItem" filterable>
            <el-option v-for="item in selectData.hotel_level_select" :key="item.code_value" :label="item.code_label" :value="item.code_value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_quality" label="门店性质" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.hotel_quality" class="selectItem" filterable>
            <el-option v-for="item in selectData.hotel_quality_select" :key="item.code_value" :label="item.code_label" :value="item.code_value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="account_manager_id" label="客户经理" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.account_manager_id" class="selectItem" filterable @change="accountManagerChange">
            <el-option v-for="item in selectData.account_manager_id_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <!--<el-col :span="12">-->
        <el-form-item prop="cus_service_personnel_id" label="客户服务" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.cus_service_personnel_id" class="selectItem" filterable>
            <el-option v-for="item in selectData.cus_service_personnel_id_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="tech_service_personnel_id" label="技术服务" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.tech_service_personnel_id" class="selectItem" filterable>
            <el-option v-for="item in selectData.tech_service_personnel_id_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item prop="ka_type" label="所属部门" label-width="200px">
          <!--<el-select v-model="form.ka_type" class="selectItem" @change="departChange" filterable>-->
          <el-select :disabled="hotel_is_children" v-model="form.ka_type" class="selectItem" filterable>
            <el-option v-for="(item,index) in selectData.kaTypeSelect" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="is_signed" label="是否售卖" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.is_signed" class="selectItem" filterable>
            <el-option v-for="item in selectData.is_signed_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <!--<el-radio-group  v-model="form.is_signed" class="radioBox" >-->
          <!--&lt;!&ndash;is_signed_select&ndash;&gt;-->
          <!--<el-radio :label="1">是</el-radio>-->
          <!--<el-radio :label="0">否</el-radio>-->
          <!--</el-radio-group>-->
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item prop="terminal_customer_from" label="客户来源渠道" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.terminal_customer_from" class="selectItem" filterable>
            <el-option v-for="(item,index) in selectData.terminalCustomerFrom" :key="item.value" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="is_coop_offline" label="线下场景合作" label-width="200px">
          <el-radio-group :disabled="hotel_is_children" v-model="form.is_coop_offline" class="radioBox">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="power_state" label="是否上电开机" label-width="200px">
          <el-radio-group :disabled="hotel_is_children" v-model="form.power_state" class="radioBox">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_room" label="客房总量" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_room" name="hotel_room" type="number" min="0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="total_num" label="系统上线数量" label-width="200px">
          <el-input :disabled="hotel_is_children" placeholder="需上线终端数量，最大不超过500" v-model="form.total_num" name="total_num" type="number" min="0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="h_ota_price" label="最低房价" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.h_ota_price" name="h_ota_price" type="number" min="0"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="pms_brand" label="PMS品牌" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.pms_brand" name="address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" style="display:flex;">
        <!--<el-form-item prop="province" label="* 所属地区" label-width="200px"></el-form-item>-->
        <el-form-item prop="provinceLabel" label="所属地区" label-width="200px"></el-form-item>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="provinceLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect" placeholder="省" v-model="form.provinceShow" @change="provinceChange" v-if="aysnOption.provFlag" filterable>
              <el-option v-for="item in selectData.province" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="cityLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect tenMarginLeft" placeholder="市" v-model="form.cityShow" @change="cityChange">
              <el-option v-for="item in selectData.city" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="countryLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect tenMarginLeft" placeholder="区" v-model="form.countryShow" @change="countryChange">
              <el-option v-for="item in selectData.country" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_address" label="门店地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" class="relativeEl">
        <!--<el-form-item prop="occupyRules" label="百度地图终端门店地址" label-width="200px"></el-form-item>-->
        <el-form-item prop="baidu_address" label="百度地图终端门店地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.baidu_address">
            <i v-show="!hotel_is_children" slot="suffix" class="el-input__icon el-icon-search pointer " @click="searchMeth"></i>
          </el-input>
        </el-form-item>
        <div class="searchList">
          <div class="searchListBox" v-if="searchListBoxShow">
            <div class="searchListItem" :key="index" v-for="(item,index) in selectData.addressSearchList" @click="getCoordinate(item.uid,item.adcode,item.province,item.city,item.district)">{{item.name}}
            </div>
          </div>
        </div>
        <!--<el-col :span="8" class="relativeEl">-->
        <!--<el-form-item prop="baidu_address">-->
        <!--<el-input v-model="form.baidu_address" >-->
        <!--<i slot="suffix" class="el-input__icon el-icon-search pointer " @click="searchMeth" ></i>-->
        <!--</el-input>-->
        <!--</el-form-item>-->
        <!--<div class="searchList">-->
        <!--<div class="searchListBox" v-if="searchListBoxShow">-->
        <!--<div class="searchListItem" :key="index"-->
        <!--v-for="(item,index) in selectData.addressSearchList"-->
        <!--@click="getCoordinate(item.uid)">{{item.name}}</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</el-col>-->

        <!--<div class="relativeEl">-->
        <!--<el-form-item prop="baidu_address" label="百度地图终端门店地址" label-width="200px">-->
        <!--<el-input v-model="form.baidu_address" >-->
        <!--<i slot="suffix" class="el-input__icon el-icon-search pointer " @click="searchMeth" ></i>-->
        <!--</el-input>-->
        <!--</el-form-item>-->
        <!--<div class="searchList">-->
        <!--<div class="searchListBox" v-if="searchListBoxShow">-->
        <!--<div class="searchListItem" :key="index"-->
        <!--v-for="(item,index) in selectData.addressSearchList"-->
        <!--@click="getCoordinate(item.uid)">{{item.name}}</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="coordinateBox">-->
        <!--<el-form-item prop="province" label="" label-width="200px"></el-form-item>-->
        <!--<el-form-item class="coordinate">-->
        <!--<el-col :span="24">-->
        <!--<el-input v-model="map.center.lat" class="selectItem"></el-input>-->
        <!--</el-col>-->
        <!--</el-form-item>-->
        <!--<el-form-item class="coordinate">-->
        <!--<el-col :span="24">-->
        <!--<el-input  v-model="map.center.lng" class="selectItem"></el-input>-->
        <!--</el-col>-->
        <!--</el-form-item>-->
        <!--</div>-->
      </el-col>
      <el-col :span="12" style="display:flex;">
        <el-form-item label="百度地图终端门店名称" label-width="200px"></el-form-item>
        <el-col :span="8" class="relativeEl">
          <el-form-item prop="baidu_hotel_name" style="position:relative">
            <el-input :disabled="hotel_is_children" v-model="form.baidu_hotel_name" :readonly="true" placeholder="该内容自动读取"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input :disabled="hotel_is_children" v-model="map.center.lat" class="selectItem tenMarginLeft"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input :disabled="hotel_is_children" v-model="map.center.lng" class="selectItem tenMarginLeft"></el-input>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24"></el-col>
      <el-col :span="24" v-if="edit">
        <el-form-item label="终端信息" label-width="200px">
          <el-button type="primary" @click="showTerInfo">查看</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-if="terInfoShow" :key="index" v-for="(item,index) in terInfo">
        <el-form-item :label="index===0?'终端信息':''" label-width="200px">
          <el-row :gutter="20" class="terInfoRow">
            <el-col :span="6">
              <div class="terInfoItem">
                终端品牌：{{item.terBrandLabel}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端类型：{{item.terTypeVal}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端型号：{{item.terModelLabel}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端数量：{{item.terNumVal}}
              </div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <!--<el-col :span="24">-->
      <!--<el-form-item  label="终端信息" label-width="200px">-->
      <!--<el-button type="primary" @click="showTerInfo">查看</el-button>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <el-col :span="12">
        <el-form-item prop="hotel_charger" label="门店负责人" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_charger"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_charger_phone" label="门店电话" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_charger_phone"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="maintain_name" label="门店系统维护人" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.maintain_name" name="hotel"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="maintain_phone" label="门店系统维护人电话" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.maintain_phone" name="hotel"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="ter_store_type" label="门店类型" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.ter_store_type" class="selectItem" filterable>
            <el-option v-for="item in selectData.terminalStoreType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="验收时间" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" v-model="form.check_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="试用期（开始时间）" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" :readonly="true" v-model="form.hotel_try_start" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="合同期（开始时间）" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" :readonly="true" v-model="form.contract_start " type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="试用期（结束时间）" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" :readonly="true" v-model="form.hotel_try_end" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="合同期（结束时间）" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" :readonly="true" v-model="form.contract_end" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="channel_name" label="所属合作伙伴" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.channel_name" class="selectItem" @change="channelChange" filterable>
            <el-option v-for="(item,index) in selectData.channelName" :key="index" :label="item.company_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="channel_code" label="所属合作伙伴编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.channel_code" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="edit">
        <el-form-item prop="uk_number" label="优客账号" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.uk_number" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="edit">
        <el-form-item prop="uk_number" label="优客账号编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.uk_number" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="allot_channel_name" label="归属合作伙伴" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.allot_channel_name" class="selectItem" @change="allotChannelChange" filterable>
            <el-option v-for="item in selectData.threechannelName" :key="item.id" :label="item.company_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="allot_channel_code" label="归属合作伙伴编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.allot_channel_code" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="上线合作伙伴" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.online_channel_name" class="selectItem" @change="onlineChannelChange" filterable>
            <el-option v-for="(item,index) in selectData.channelName" :key="index" :label="item.company_name" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="上线合作伙伴编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.online_channel_code" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="硬件厂家" label-width="200px">
          <!--<el-input v-model="form.hard_factory" ></el-input>-->
          <el-select :disabled="hotel_is_children" v-model="form.hard_factory" class="selectItem" filterable>
            <el-option v-for="item in selectData.hard_factory_select" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="项目激活号" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.project_act_num"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" class="terInfoCol" :key="index" v-for="(item,index) in form.terInfo" v-if="terInfoShow">
        <el-form-item class="terShowItem" label="终端类型" label-width="200px">{{item.terTypeVal}}</el-form-item>
        <el-form-item class="terShowItem widthAutoInput" label="终端品牌">{{item.terBrandLabel}}</el-form-item>
        <el-form-item class="terShowItem widthAutoInput" label="终端型号">{{item.terModelLabel}}</el-form-item>
        <el-form-item class="terShowItem widthAutoInput" label="应上线终端数量">{{item.terNumVal}}</el-form-item>
        <el-form-item class="terShowItemBtn" prop="" label="" label-width="">
          <!--<el-input v-model="terDialog.terNumVal" placeholder="应上线终端数量"></el-input>-->
          <el-button type="text" icon="el-icon-edit" v-show="!hotel_is_children" @click="editTerInfo(item.id)"></el-button>
          <el-button type="text" icon="el-icon-delete" v-show="!hotel_is_children" @click="delTerInfo(item.id)"></el-button>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="ipaddr" label="终端门店服务器地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.ipaddr" :readonly="true" placeholder="该地址在酒店建成之后自动生成，无需填写"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotelName" label="终端门店程序名称" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotelName" :readonly="true" placeholder="该名称在酒店建成之后自动生成，无需填写"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建站时间" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" :readonly="true" v-model="form.addtime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="build_person" label="建站人" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.build_person" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="supplier_id" label="高清影视合作方式" label-width="200px">
          <!--<el-input v-model="form.supplier_id" ></el-input>-->
          <el-select :disabled="hotel_is_children" v-model="form.supplier_id" class="selectItem" filterable>
            <el-option v-for="item in selectData.supplierId" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_key" label="影视服务器KEY" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_key"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_server_ip" label="影视服务器本地IP地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_server_ip"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_server_key" label="CMS服务器KEY" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_server_key"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="demand" label="备注" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.demand"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="spare_url" label="备用地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.spare_url"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-show="!hotel_is_children">
        <div style="float: right;margin-bottom: 10px">
          <el-button v-if="showFormal" class="rightBtn" style="margin-right: 25px" @click="formal('form')" type="warning">
            转为正式
          </el-button>
          <el-button class="largeBtn rightBtn" type="primary" @click="confirmMeth('form')" :disabled="false">
            提交
          </el-button>
        </div>

      </el-col>
      <!--<el-button class="largeBtn" type="primary" @click="submitForm('form')"  style="position:fixed;bottom:200px;right:200px;" :disabled="false">提交</el-button>-->
      <div style='clear:both'></div>
      <!--<el-button type="primary" @click="toTop"  style="position:fixed;bottom:200px;right:100px;" >回到顶部</el-button>-->
    </el-form>
    <ehotel-dialog ref="ehotelDialog" @getSelectedHotel="getSelectedHotel" :selectedHotelCheck="selectedHotel">
    </ehotel-dialog>
    <confirm-dialog @createMeth="createMeth" ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import qs from "querystring";

export default {
  name: "OperateTableTab",
  props: ["id", "edit", "hotelName", "is_children"],
  data() {
    var checkPhone = (rule, value, callback) => {
      let str1 = /[\u4e00-\u9fa5_a-zA-Z]/;
      console.log(value);
      if (!value) {
        return callback(new Error("请填写号码"));
        //    不能是英文或汉字
      } else if (str1.test(value)) {
        return callback(new Error("号码中不能包含汉字或字母"));
      } else {
        callback();
      }
    };
    var peopleName = (rule, value, callback) => {
      let str1 = /[\u4e00-\u9fa5]/;
      console.log(value);
      if (!value) {
        return callback(new Error("请填写名称"));
        //
      } else if (!str1.test(value)) {
        return callback(new Error("名称中只能输入汉字"));
      } else {
        callback();
      }
    };
    var noSpaceStr = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写手机号码"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
        return callback(new Error("请填写正确的手机号码"));
      } else {
        callback();
      }
    };
    var checkNumber = (rule, value, callback) => {
      var a = "";
      if (rule.field == "hotel_room") {
        a = "客房总量";
      } else if (rule.field == "total_num") {
        a = "系统上线数量";
      }
      if (value < 0) {
        return callback(new Error(a + "不能小于0"));
      } else if (value == 0) {
        callback();
      } else if (!Number.isInteger(Number(value))) {
        return callback(new Error(a + "为整数"));
      } else {
        callback();
      }
    };
    return {
      showFormal: false,
      location: "北京",
      keyword_test: "百度",
      selectData: {
        province: [],
        city: [],
        country: [],

        //网络接入方式
        netAccess: [
          { label: "有线", value: "1" },
          { label: "无线", value: "2" },
        ],
        //网络状况
        netStatus: [
          { label: "50M以下", value: "1" },
          { label: "50M-100M", value: "2" },
          { label: "100M以上", value: "3" },
        ],
        //高清影视合作方式
        filmCoop: [
          { label: "奇异果", value: "1" },
          { label: "风霆迅包年", value: "2" },
          { label: "风霆迅单点", value: "3" },
        ],
        //集团品牌
        hotelGroupList: [
          {
            id: "1",
            groupname: "携旅",
          },
          {
            id: "2",
            groupname: "麗枫",
          },
          {
            id: "3",
            groupname: "维也纳",
          },
        ],
        //终端门店模板
        hotelTemplateList: [],
        //酒店状态
        hotelUseState: [
          {
            id: 4,
            name: "已工勘",
          },
          {
            id: 5,
            name: "实施中",
          },
          {
            id: 6,
            name: "待上线",
          },
          {
            id: 7,
            name: "使用中",
          },
          {
            id: 9,
            name: "已暂停",
          },
          {
            id: 10,
            name: "虚拟运营",
          },
          {
            id: 11,
            name: "已离线",
          },
          {
            id: 12,
            name: "测试演示",
          },
        ],
        //终端门店营业状态
        operatingState: [
          {
            id: 2,
            name: "未开业",
          },
          {
            id: 3,
            name: "试营业",
          },
          {
            id: 4,
            name: "已营业",
          },
          {
            id: 6,
            name: "停业",
          },
          {
            id: 7,
            name: "其他状态",
          },
        ],

        //高清影视合作方式
        supplierId: [
          {
            id: 1,
            name: "点播-电影运营管理平台",
          },
          {
            id: 2,
            name: "包年-电影运营管理平台",
          },
          {
            id: 3,
            name: "奇异果",
          },
          {
            id: 4,
            name: "风霆迅包年",
          },
          {
            id: 5,
            name: "风霆迅单点",
          },
          {
            id: 6,
            name: "demo爱奇艺",
          },
          {
            id: 7,
            name: "极光TV",
          },
        ],
        //客户来源渠道
        terminalCustomerFrom: [
          {
            id: 1,
            name: "精英合作伙伴",
          },
          {
            id: 2,
            name: "集团客户",
          },
          {
            id: 3,
            name: "终端KA客户",
          },
          {
            id: 3,
            name: "直销BD",
          },
          {
            id: 3,
            name: "经销商",
          },
          {
            id: 3,
            name: "渠道商",
          },

          // {
          //     "id": 4,
          //     "name": "直销BD"
          // },
          // {
          //     "id": 5,
          //     "name": "集团客户"
          // },
          // {
          //     "id": 6,
          //     "name": "网络客户"
          // },
          // {
          //     "id": 7,
          //     "name": "友商客户"
          // },
          // {
          //     "id": 8,
          //     "name": "其他来源"
          // }
        ],
        //终端门店类型
        terminalStoreType: [
          {
            id: 2,
            name: "携旅TV网络片源",
          },
          {
            id: 3,
            name: "携旅TV系统合作",
          },
          {
            id: 4,
            name: "联盟TV本地片源",
          },
          {
            id: 5,
            name: "联盟TV网络片源",
          },
          {
            id: 6,
            name: "影音房本地片源",
          },
          {
            id: 7,
            name: "影音房网络片源",
          },
          {
            id: 8,
            name: "影音房网联盟本地片源",
          },
          {
            id: 9,
            name: "影音房联盟网络片源",
          },
          {
            id: 10,
            name: "影音房联盟系统合作",
          },
          {
            id: 11,
            name: "联盟TVOS系统合作",
          },
          {
            id: 12,
            name: "联盟TVSDK合作",
          },
          {
            id: 13,
            name: "联盟BOX网络片源",
          },
          {
            id: 14,
            name: "联盟BOXOS系统合作",
          },
          {
            id: 15,
            name: "联盟BOXSDK合作",
          },
        ],
        //终端类型
        terminalType: [
          {
            id: 1,
            name: "电视",
          },
          {
            id: 2,
            name: "盒子",
          },
          {
            id: 3,
            name: "投影",
          },
        ],
        threechannelName: [
          { id: "HCC000042", company_name: "广州携旅信息科技有限公司" },
          { id: "HCC000129", company_name: "成都城住通新零售科技有限公司" },
          { id: "HCC000017", company_name: "四川携旅信息科技有限公司" },
        ],
        channelName: [],
        pmsSelect: [
          { value: 0, name: "绿云" },
          { value: 1, name: "Whotel" },
          { value: 2, name: "尚美" },
          { value: 3, name: "西软" },
          { value: 4, name: "中软" },
          { value: 5, name: "住哲" },
          { value: 6, name: "千里马" },
          { value: 7, name: "迪联" },
          { value: 8, name: "金钥匙" },
          { value: 9, name: "罗盘" },
          { value: 10, name: "掌控" },
          { value: 11, name: "华盛" },
          { value: 12, name: "OPERA" },
          { value: 13, name: "威达" },
        ],
        otaOption: [
          { id: 1, name: "150一下" },
          { id: 2, name: "150-300" },
          { id: 3, name: "301-450" },
          { id: 4, name: "451-500" },
          { id: 5, name: "600以上" },
        ],
        hard_factory_option: [
          { id: 1, name: "厂家1" },
          { id: 2, name: "厂家2" },
          { id: 3, name: "厂家3" },
        ],
        //终端型号
        terTypeSelect: ["电视", "投影", "盒子"],
        terBrandSelect: [
          { value: 1, label: "品牌1" },
          { value: 2, label: "品牌2" },
          { value: 3, label: "品牌3" },
          { value: 4, label: "品牌4" },
          { value: "other", label: "其他" },
        ],
        terModelSelect: [],
        kaTypeSelect: [
          { value: 4, label: "终端KA" },
          { value: 5, label: "渠道KA" },
          { value: 6, label: "集团KA" },
        ],
        account_manager_id_select: [],
        tech_service_personnel_id_select: [
          // '于金龙','张广彬','谌龙','匡森','罗磊','秦蕾','谭培强','倪德前','张明敏','熊维维','罗世平'
        ],
        cus_service_personnel_id_select: [
          "卢瑞英",
          "任燕琳",
          "李萍",
          "刘黎婷",
          "鲁静",
          "杨子建",
          "罗世平",
        ],
        settle_type_select: [
          { name: "ehotel", value: "ehotel" },
          { name: "hihotel", value: "hihotel" },
        ],
        hotel_level_select: [],
        hotel_quality_select: [],
        hard_factory_select: [
          { name: "TCL", value: "TCL" },
          { name: "创维", value: "创维" },
        ],
        juping_type_select: [
          { id: 1, name: "广告" },
          { id: 2, name: "影视" },
          { id: 3, name: "广告和影视" },
          { id: 4, name: "独立影视 " },
        ],
        addressSearchList: [],
        is_signed_select: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        is_os_sell_select: [
          { id: 1, name: "优客" },
          { id: 0, name: "无" },
        ],
        network_remarks_select: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        is_ctrip_select: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        is_moive_settlement: [
          { id: 1, name: "是" },
          { id: 0, name: "否" },
        ],
        sell_goods: [],
      },
      form: {
        hotel_brand: "",
        brand_name: "",
        groupId: "",
        hotel_store_id: "",
        hotel_store_id: "",
        is_signed: "", //签约情况
        hotel_template_id: "2", //终端门店模板，少
        hotel: "",
        contract_365: "",
        juping_type: "",
        hotel_use_state: "",
        bu_status: "",
        templateId: "",
        templateLabel: "",
        templateType: "hihotel",
        hotel_level: "",
        hotel_quality: "",
        ka_type: "", //所属部门
        terminal_customer_from: "",
        account_manager_id: "",
        tech_service_personnel_id: "",
        cus_service_personnel_id: "",
        hotel_room: "",
        total_num: "",
        h_ota_price: "",
        pms_brand: "",
        citycode: "",
        provinceShow: "",
        provinceLabel: "",
        cityShow: "",
        cityLabel: "",
        countryShow: "",
        countryLabel: "",
        address: "",
        lat: "39.915",
        lng: "116.40",
        hotel_address: "",
        baidu_address: "",
        occupyRules: "",
        baidu_hotel_name: "",
        baidu_uid: "",
        is_coop_offline: 0,
        power_state: 1,
        hotel_charger: "",
        hotel_charger_phone: "",
        maintain_name: "",
        maintain_phone: "",
        ter_store_type: 2,
        check_date: "",
        hotel_try_start: "",
        hotel_try_end: "",
        contract_start: "",
        contract_end: "",
        channel_name: "",
        channel_code: "",
        channel_link_person: "",
        channel_link_phone: "",
        allot_channel_name: "广州携旅信息科技有限公司",
        allot_channel_code: "HCC000042",
        online_channel_name: "",
        online_channel_code: "",
        settle_type: "",
        is_settle_service: 1,
        is_settle_online: 1,
        hard_factory: "",
        project_act_num: "", //项目激活号
        is_os_sell: 0,
        group_hotel_name: "",
        is_ctrip: "",
        group_code: "",
        ipaddr: "",
        hotelName: "",
        addtime: "",
        build_person: "",
        supplier_id: 7,
        hotel_key: "",
        hotel_server_ip: process.env.VUE_APP_HOTEL_SERVER,
        hotel_server_key: "",
        demand: "",
        spare_url: "",
        ter_type: "",
        uid: "",
        uk_number: "",
        selectedHotelTemLabel: "",
        is_moive_settlement: 1,
        sell_goods: "",
        selectedHotelTemplate: {
          type: "",
          id: "",
          label: "",
        },
      },
      templateLabel: "",
      templateDialog: {
        visible: false,
        brand: "",
        templateFormat: "",
        templateFormatLabel: "",
        brandSelect: [
          { id: 1, name: "品牌1" },
          { id: 2, name: "品牌2" },
        ],
        templateFormatRadioList: [
          {
            id: 1,
            name: "标准版",
            val: 1,
          },
          {
            id: 2,
            name: "欢朋版",
            val: 2,
          },
        ],
        keyword: "",
      },
      terBrandInputShow: false,
      terModelInputShow: false,
      operateTerType: "",
      operateTerId: "",
      rules: {
        groupId: [
          {
            required: true,
            message: "请输入门店品牌",
            trigger: ["blur", "change"],
          },
        ],
        hotel_store_id: [
          {
            pattern: /^[^ ]+$/,
            message: "集团门店ID中不能有空格",
            trigger: "blur",
          },
        ],
        is_signed: [
          {
            required: true,
            message: "请选择是否售卖",
            trigger: ["blur", "change"],
          },
        ],
        contract_365: [
          { required: true, message: "请输入终端门店编码", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "终端门店编码中不能有空格",
            trigger: "blur",
          },
        ],
        hotel: [
          { required: true, message: "请输入门店名称", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "门店名称中不能有空格",
            trigger: "blur",
          },
        ],
        hotel_template_id: [
          { required: true, message: "请输入门店模板", trigger: "blur" },
        ],
        bu_status: [
          {
            required: true,
            message: "请选择终端门店开业时间",
            trigger: "blur",
          },
        ],
        templateType: [
          {
            required: true,
            message: "请选择门店系统版式",
            trigger: "blur",
          },
        ],
        hotel_level: [
          {
            required: true,
            message: "请选择门店级别",
            trigger: ["blur", "change"],
          },
        ],
        hotel_quality: [
          {
            required: true,
            message: "请选择门店性质",
            trigger: ["blur", "change"],
          },
        ],
        ka_type: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: ["blur", "change"],
          },
        ],
        hotel_use_state: [
          {
            required: true,
            message: "请输入终端客户状态",
            trigger: ["blur", "change"],
          },
        ],
        ter_store_type: [
          {
            required: true,
            message: "请输入门店类型",
            trigger: ["blur", "change"],
          },
        ],
        account_manager_id: [
          {
            required: true,
            message: "请输入客户经理",
            trigger: ["blur", "change"],
          },
        ],
        provinceLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        cityLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        countryLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        hotel_address: [
          { required: true, message: "请输入地址", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "地址中不能有空格",
            trigger: "blur",
          },
        ],
        occupyRules: [{ required: true, message: "", trigger: "blur" }],
        baidu_address: [
          {
            required: true,
            message: "请输入百度地图终端门店地址",
            trigger: "blur",
          },
          {
            pattern: /^[^ ]+$/,
            message: "百度地图终端门店地址中不能有空格",
            trigger: "blur",
          },
        ],
        baidu_hotel_name: [
          {
            required: true,
            message: "请输入百度地图终端门店名称",
            trigger: "blur",
          },
        ],
        maintain_name: [
          {
            required: true,
            message: "请输入门店系统维护人",
            trigger: "blur",
          },
          {
            pattern: /^[^ ]+$/,
            message: "门店系统维护人中不能有空格",
            trigger: "blur",
          },
        ],
        maintain_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        hotel_room: [
          { required: true, message: "请输入客房总量", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        total_num: [
          { required: true, message: "系统上线数量", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        h_ota_price: [
          { required: true, message: "请输入房价", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "房价中不能有空格",
            trigger: "blur",
          },
          {
            pattern: /^((?!0)\d+(\.\d{1,2})?)$/g,
            message: "房价不能小于0",
            trigger: "blur",
          },
        ],
        pms_brand: [
          {
            pattern: /^[^ ]+$/,
            message: "PMS品牌中不能有空格",
            trigger: "blur",
          },
        ],
        is_coop_offline: [
          { required: true, message: "请选择线下场景合作", trigger: "blur" },
        ],
        power_state: [
          { required: true, message: "请选择是否上电开机", trigger: "blur" },
        ],
        hotel_charger: [
          { required: true, message: "请输入门店负责人", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "门店负责人中不能有空格",
            trigger: "blur",
          },
          { required: true, validator: peopleName, trigger: "blur" },
        ],
        hotel_charger_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        allot_channel_name: [
          { required: true, message: "请输入归属合作伙伴", trigger: "blur" },
        ],
        online_channel_name: [
          { required: true, message: "请输入上线合作伙伴", trigger: "blur" },
        ],
        online_channel_code: [
          { required: true, message: "请输入上线合作伙伴", trigger: "blur" },
        ],
        allot_channel_code: [
          {
            required: true,
            message: "请输入归属合作伙伴编码",
            trigger: "blur",
          },
        ],
        terminal_customer_from: [
          {
            required: true,
            message: "请输入客户来源渠道",
            trigger: ["blur", "change"],
          },
        ],
        check_date: [
          { required: true, message: "请选择验收时间", trigger: "blur" },
        ],
        channel_name: [
          { required: true, message: "请输入所属合作伙伴", trigger: "blur" },
        ],
        channel_code: [
          {
            required: true,
            message: "请输入所属合作伙伴编码",
            trigger: "blur",
          },
        ],
        settle_type: [
          { required: true, message: "请选择上线结算类型", trigger: "blur" },
        ],
        is_settle_service: [
          {
            required: true,
            message: "请选择是否结算服务费用",
            trigger: "blur",
          },
        ],
        is_settle_online: [
          {
            required: true,
            message: "请选择是否结算上线费用",
            trigger: "blur",
          },
        ],
        channel_link_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        hard_factory: [
          { required: true, message: "请输入硬件厂家", trigger: "blur" },
        ],
        project_act_num: [
          {
            pattern: /^[^ ]+$/,
            message: "项目激活号中不能有空格",
            trigger: "blur",
          },
        ],
        hotel_try_start: [
          {
            required: true,
            message: "请选择试用期（开始时间）",
            trigger: "blur",
          },
        ],
        hotel_try_end: [
          {
            required: true,
            message: "请选择试用期（结束时间）",
            trigger: "blur",
          },
        ],
        contract_start: [
          {
            required: true,
            message: "请选择合同期（开始时间）",
            trigger: "blur",
          },
        ],
        contract_end: [
          {
            required: true,
            message: "请选择合同期（结束时间）",
            trigger: "blur",
          },
        ],
        tech_service_personnel_id: [
          { required: true, message: "请输入技术服务", trigger: "blur" },
        ],
        cus_service_personnel_id: [
          { required: true, message: "请输入客户服务", trigger: "blur" },
        ],

        is_os_sell: [
          { required: true, message: "请选择OS是否售卖", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "OS是否售卖中不能有空格",
            trigger: "blur",
          },
        ],
        group_code: [
          {
            pattern: /^[^ ]+$/,
            message: "集团项目编码中不能有空格",
            trigger: "blur",
          },
        ],
        ipaddr: [
          {
            pattern: /^[^ ]+$/,
            message: "终端门店服务器地址中不能有空格",
            trigger: "blur",
          },
        ],
        hotelName: [
          {
            pattern: /^[^ ]+$/,
            message: "终端门店程序名称中不能有空格",
            trigger: "blur",
          },
        ],
        addtime: [
          { required: true, message: "请选择建站时间", trigger: "blur" },
        ],
        build_person: [
          {
            pattern: /^[^ ]+$/,
            message: "建站人中不能有空格",
            trigger: "blur",
          },
        ],
        supplier_id: [
          {
            required: true,
            message: "请选择高清影视合作方式",
            trigger: "blur",
          },
        ],
        hotel_key: [
          {
            pattern: /^[^ ]+$/,
            message: "影视服务器KEY中不能有空格",
            trigger: "blur",
          },
        ],
        hotel_server_ip: [
          {
            required: true,
            message: "请输入影视服务器本地IP地址",
            trigger: "blur",
          },
          {
            pattern: /^[^ ]+$/,
            message: "影视服务器本地IP地址中不能有空格",
            trigger: "blur",
          },
        ],
        hotel_server_key: [
          {
            pattern: /^[^ ]+$/,
            message: "CMS服务器KEY中不能有空格",
            trigger: "blur",
          },
        ],
        spare_url: [
          {
            pattern: /^[^ ]+$/,
            message: "备用地址中不能有空格",
            trigger: "blur",
          },
        ],
        demand: [
          { pattern: /^[^ ]+$/, message: "备注中不能有空格", trigger: "blur" },
        ],
        // selectedHotelTemLabel: [
        //     {required: true, message: '请选择', trigger: 'blur'}
        // ],
        is_moive_settlement: [
          {
            required: true,
            message: "请选择合作伙伴影视结算",
            trigger: "blur",
          },
        ],
      },
      map: {
        center: { lng: 116.404, lat: 39.915 },
        zoom: 3,
      },
      region: {
        prov_code: "",
        city_code: "",
        country: "",
      },
      hotelId: null,
      hotelProgram: "",
      aysnOption: {
        provFlag: null,
        cityFlag: null,
        countryFlag: null,
      },
      ter_flag: false,
      token: "",
      keyword: "",
      auth_type: "",
      authUserName: "",
      query: {},
      terInfo: [],
      terInfoBtn: false,
      terInfoShow: false,
      hotel_contract: "",
      baiduMapShow: false,
      selectedHotel: {
        type: "",
        id: "",
        label: "",
      },
      searchListBoxShow: false,
      confirmDialog: {
        dialogVisible: false,
      },
      // uoko 配置
      bloneDisabled: false,
      osDisabled: false,
      groupDisabled: false,
      formLoading: false,

      templateList: [],
      templateList1: [],
      hotel_is_children: false,
    };
  },
  watch: {
    id(newVal) {
      this.hotelId = newVal;
      if (this.hotelId) {
        // this.getDetail(1);
      }
    },
    is_children(newVal) {
      this.is_children = newVal;
      this.hotel_is_children = newVal;
    },
    hotelName(newVal) {
      this.hotelProgram = newVal;
      if (this.hotelProgram) {
        this.getDetail(1);
      }
    },
    terDialog: {
      handler: function (newVal) {
        var flag = true;
        if (newVal.terBrandVal === "other") {
          this.terBrandInputShow = true;
          this.terModelInputShow = true;
        } else {
          if (newVal.terModelVal === "other") {
            this.terModelInputShow = true;
          } else {
            this.terModelInputShow = false;
          }
          this.terBrandInputShow = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    getAllTemplateList() {
      this.$http
        .get(this.global.getHotelTemplate, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            this.assignTemplateData(resData);
          }
        });
    },
    assignTemplateData(obj) {
      var that = this;
      Object.keys(obj).forEach(function (key) {
        switch (obj[key].label) {
          case "标准版":
            that.templateList = [...obj[key].list];
            break;
          case "hihotel":
            that.templateList1 = [...obj[key].list];
            break;
        }
      });
    },
    ctripChange() {
      this.$forceUpdate();
    },
    OSChange() {},
    defaultCheckTemplate(group_id) {
      let arr = this.templateList.map((i) => {
        return i.group_id;
      });
      let arr1 = this.templateList1.map((i) => {
        return i.group_id;
      });
      let n = 0;
      if (arr.includes(group_id)) {
        let list = this.templateList.filter((i) => {
          if (i.group_id == group_id) {
            return i;
          }
        });
        let obj = {
          id: list[0].id,
          label: list[0].label,
          type: "标准版",
        };
        this.selectedHotel = obj;
        this.form.selectedHotelTemLabel = obj.label;
        let type = obj.type === "标准版" ? "标准" : "hihotel";
        this.selectData.hotelTemplateList.filter((item, index) => {
          if (item.name.indexOf(type) !== -1) {
            this.form.hotel_template_id = item.id;
          }
        });
      }
      if (arr1.includes(group_id)) {
        let list = this.templateList1.filter((i) => {
          if (i.group_id == group_id) {
            return i;
          }
        });
        let obj = {
          id: list[0].id,
          label: list[0].label,
          type: "hihotel",
        };
        this.selectedHotel = obj;
        this.form.selectedHotelTemLabel = obj.label;
        let type = obj.type === "标准版" ? "标准" : "hihotel";
        this.selectData.hotelTemplateList.filter((item, index) => {
          if (item.name.indexOf(type) !== -1) {
            this.form.hotel_template_id = item.id;
          }
        });
      }
      if (!arr.includes(group_id) && !arr1.includes(group_id)) {
        this.selectedHotel = {
          id: "",
          label: "",
          type: "",
        };
        this.form.selectedHotelTemLabel = "";
        this.form.hotel_template_id = "";
      }
    },
    groupChange(val) {
      if (this.edit) return;
      this.form.brand_name = val
        ? this.selectData.hotelGroupList.find((ele) => ele.id === val).groupname
        : "";
      this.defaultCheckTemplate(this.form.groupId);
    },
    handler({ BMap, map1 }) {
      // this.map.center.lng = 116.404;
      // this.map.center.lat = 39.915;
      // var map=new BMap.map('map');
      // var ac = new BMap.Autocomplete( //建立一个自动完成的对象
      //     {
      //         'input': 'suggestId',
      //         'location': map
      //     });
      this.map.center.lng = this.form.lng;
      this.map.center.lat = this.form.lat;
      this.map.zoom = 15;
    },
    getProvince() {
      this.$http
        .get(this.global.getProvince, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.province = res.data.data;
            this.aysnOption.provFlag = true;
          } else {
            this.$message.error("获取省份失败");
          }
        });
    },
    getCity(prov_code) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              // prov_code:this.region.prov_code
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.selectData.city = res.data.data;
              this.aysnOption.cityFlag = true;
              return resolve();
            }
          });
      });
    },
    getCountry() {
      var param = qs.stringify({
        token: this.token,
        prov_code: this.region.prov_code,
        city_code: this.region.city_code,
      });
      this.$http
        .get(this.global.getDistricts, {
          params: {
            token: this.token,
            prov_code: this.region.prov_code,
            city_code: this.region.city_code,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.country = res.data.data;
            // return resolve();
          }
        });
    },
    getOption() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getOptions, param).then((res) => {
        if (res.data.code === 100000) {
          this.selectData.hotelGroupList = res.data.data.hotelGroupList;
          this.selectData.hotelTemplateList = res.data.data.hotelTemplateList;
          this.selectData.hotelUseState = res.data.data.hotelUseState;
          this.selectData.operatingState = res.data.data.operatingState;
          this.selectData.supplierId = res.data.data.supplierId;
          this.selectData.terminalCustomerFrom =
            res.data.data.terminalCustomerFrom;
          this.selectData.terminalStoreType = res.data.data.terminalStoreType;
          this.selectData.terminalType = res.data.data.terminalType;
          this.selectData.sell_goods = res.data.data.sellGoodsType;
          this.getProvince();
        } else {
          this.$message.error("获取option失败");
        }
      });
    },
    getPartner() {
      this.$http
        .get(this.global.getPartnersByType, {
          params: {
            token: this.token,
            type: "channel,screen",
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.channelName = res.data.data;
          }
        });
    },
    provinceChange(val) {
      this.region.prov_code = val.value;
      this.region.city_code = "";
      this.region.country = "";
      this.form.provinceLabel = val.label;
      this.form.cityShow = "";
      this.form.cityLabel = "";
      this.form.countryShow = "";
      this.form.countryLabel = "";
      this.selectData.country = [];
      this.getCity(val.value);
    },
    cityChange(val) {
      this.region.city_code = val.value;
      this.region.country = "";
      this.form.cityLabel = val.label;
      this.form.countryShow = "";
      this.form.countryLabel = "";
      this.getCountry(val.value);
    },
    countryChange(val) {
      this.form.countryLabel = val.label;
      this.form.citycode = val.value;
      this.$forceUpdate();
    },
    getDetail(n) {
      this.formLoading = true;
      this.terInfoShow = false;
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.hotelId,
            hotel_project: this.hotelName,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var terFormData = res.data.data;
            this.form = JSON.parse(JSON.stringify(terFormData));
            this.form.addtime = terFormData.addtime;
            this.form.provinceShow = terFormData.hotel_province;
            this.form.provinceLabel = terFormData.hotel_province;
            this.form.cityShow = terFormData.hotel_city;
            this.form.cityLabel = terFormData.hotel_city;
            this.form.countryShow = terFormData.hotel_county;
            this.form.uk_number = terFormData.uk_number;
            this.form.countryLabel = terFormData.hotel_county;
            this.form.group_hotel_name = terFormData.group_hotel_name;
            this.form.is_signed = terFormData.is_signed
              ? parseInt(terFormData.is_signed)
              : "";
            this.form.ka_type = this.selectZeroData(
              terFormData.ka_type,
              "parseInt"
            );
            this.form.account_manager_id = this.selectZeroData(
              terFormData.account_manager_id,
              "parseInt"
            );
            this.form.tech_service_personnel_id = this.selectZeroData(
              terFormData.tech_service_personnel_id,
              "parseInt"
            );
            this.form.cus_service_personnel_id = this.selectZeroData(
              terFormData.cus_service_personnel_id,
              "parseInt"
            );
            this.form.hotel_brand = terFormData.hotel_brand;
            this.form.groupId = this.selectZeroData(terFormData.groupId);
            this.form.juping_type = this.selectZeroData(
              terFormData.juping_type,
              "parseInt"
            ); //聚屏合作类型
            this.form.is_os_sell = this.selectRemarkData(
              terFormData.is_os_sell,
              "parseInt"
            ); //OS是否售卖
            this.form.is_ctrip = this.selectRemarkData(
              terFormData.is_ctrip,
              "parseInt"
            ); //OS是否售卖
            this.form.is_moive_settlement = this.selectRemarkData(
              terFormData.is_moive_settlement,
              "parseInt"
            ); //合作伙伴影视结算
            if (terFormData.bu_status) {
              this.form.bu_status =
                terFormData.bu_status.length !== 10
                  ? ""
                  : terFormData.bu_status; //开业时间
            }
            this.form.hotel_use_state = this.selectZeroData(
              terFormData.hotel_use_state,
              "parseInt"
            );
            if (this.form.hotel_use_state == 10) {
              this.showFormal = true;
            } else {
              this.showFormal = false;
            }
            this.form.ter_store_type = this.selectZeroData(
              terFormData.ter_store_type,
              "parseInt"
            ); //终端门店类型
            this.form.is_coop_offline = parseInt(terFormData.is_coop_offline); //线下场景合作
            this.form.power_state = parseInt(terFormData.power_state); //上电开机
            this.form.is_settle_service = terFormData.is_settle_service
              ? parseInt(terFormData.is_settle_service)
              : 1; //是否结算服务费用
            this.form.is_settle_online = terFormData.is_settle_online
              ? parseInt(terFormData.is_settle_online)
              : 1; //是否结算上线费用
            this.form.channel_name = terFormData.channel_code; //所属
            this.form.allot_channel_name = terFormData.allot_channel_code; //归属
            this.form.online_channel_name = terFormData.online_channel_code; //上线
            this.form.terminal_customer_from = this.selectZeroData(
              terFormData.terminal_customer_from,
              "parseInt"
            ); //客户来源渠道
            this.form.supplier_id = this.selectZeroData(
              terFormData.supplier_id,
              "parseInt"
            ); //高清影视合作方式
            this.form.hotel_level = this.selectZeroData(
              terFormData.hotel_level
            );
            this.form.hotel_quality = this.selectZeroData(
              terFormData.hotel_quality
            );
            this.form.sell_goods =
              terFormData.sell_goods !== ""
                ? terFormData.sell_goods.split(",")
                : "";
            this.form.brand_name = terFormData.brand_name;
            if (this.edit && this.form.is_os_sell && this.form.uk_number) {
              this.osDisabled = true;
              this.form.channel_code = terFormData.channel_code;
              this.form.channel_name = terFormData.channel_name;
            } else if (
              this.edit &&
              this.form.is_os_sell &&
              !this.form.uk_number
            ) {
              this.osDisabled = false;
            } else {
              this.osDisabled = false;
              this.bloneDisabled = false;
            }
            this.region.prov_code = terFormData.citycode.substr(0, 2);
            this.form.lng = terFormData.lng === "0" ? 116.404 : terFormData.lng;
            this.map.center.lng =
              terFormData.lng === "0" ? 116.404 : terFormData.lng;
            this.form.lat = terFormData.lat === "0" ? 39.915 : terFormData.lat;
            this.map.center.lat =
              terFormData.lat === "0" ? 116.404 : terFormData.lat;
            this.ter_flag = true;
            this.hotel_contract = terFormData.hotel_contract;
            // if(!terFormData.hotel_try_start){
            //     this.getCurrentDate();
            //     this.getOneYearLater();
            //     this.getYearEnd();
            // }
            this.getCity().then((res) => {
              this.region.city_code = terFormData.citycode.substr(0, 4);
              this.aysnOption.cityFlag = true;
              this.getCountry().then((res) => {
                this.region.country_code = terFormData.citycode.substr(0, 6);
                this.aysnOption.countryFlag = true;
              });
            });
            var topInfo = {
              hotel: terFormData.hotel,
              hotel_use_state_text: terFormData.hotel_use_state_text,
              contract_end: terFormData.contract_end,
            };
            this.$emit("topInfo", topInfo);
            this.selectData.city = [];
            this.selectData.country = [];
            this.selectedHotel = {
              id: 11,
              label: terFormData.template_name,
              type: "tabStandard",
            };
            this.form.selectedHotelTemLabel = this.selectedHotel.label;
            this.formLoading = false;
          } else {
            this.formLoading = false;
          }
        });
    },
    selectZeroData(val, type) {
      var valData;
      if (type === "parseInt") {
        valData = parseInt(val);
      } else {
        valData = val;
      }
      var data = val && val !== "0" ? valData : "";
      return data;
    },
    selectRemarkData(val, type) {
      var valData;
      if (type === "parseInt") {
        valData = parseInt(val);
      } else {
        valData = val;
      }
      var data = val ? valData : "";
      return data;
    },
    formal(formName) {
      if (this.form.selectedHotelTemLabel == "") {
        this.$message.warning("请选择终端门店系统版式");
        return;
      } else {
        this.form.occupyRules = this.form.hotel_address;
        this.$refs[formName].validate((valid, obj) => {
          if (valid) {
            if (this.id) {
              this.$confirm("确定转为正式？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.formalMeth();
                })
                .catch(() => {});
            } else {
              this.$refs.confirmDialog.showDialog(true);
            }
          } else {
            this.$message.warning("请完善信息");
            return false;
          }
        });
      }
    },
    confirmMeth(formName) {
      if (!this.edit && this.form.selectedHotelTemLabel == "") {
        this.$message.warning("请选择终端门店系统版式");
        return;
      } else {
        this.form.occupyRules = this.form.hotel_address;
        this.$refs[formName].validate((valid, obj) => {
          if (valid) {
            if (this.id) {
              this.$confirm("确定保存？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.editMeth();
                })
                .catch(() => {});
            } else {
              this.$refs.confirmDialog.showDialog(true);
            }
          } else {
            this.$message.warning("请完善信息");
            return false;
          }
        });
      }
    },
    formalMeth() {
      var param = this.getParam();
      param.id = this.id;
      var template = JSON.stringify(this.selectedHotel);
      param.template = template;
      param = qs.stringify(param);
      this.$http.post(this.global.formal, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "转正成功",
            type: "success",
          });
          // var fromUrl = this.query.fromUrl;
          // this.$router.push({path: `/${fromUrl}`});
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    toTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getParam() {
      var sell_goods_data = this.form.sell_goods
        ? this.form.sell_goods.join()
        : "";
      var paramData = {
        // hotel:form.
        // token:'1d528162-d6f4-4c18-87fb-7ff7e9e552f8',
        token: this.token,
        hotel_brand: this.form.groupId,
        groupId: this.form.groupId,
        hotel_store_id: this.form.hotel_store_id,
        is_signed: this.form.is_signed,
        hotel_template_id: this.form.hotel_template_id,
        hotel: this.form.hotel,
        contract_365: this.form.contract_365,
        juping_type: this.form.juping_type,
        hotel_use_state: this.form.hotel_use_state,
        bu_status: this.form.bu_status,
        hotel_level: this.form.hotel_level,
        hotel_quality: this.form.hotel_quality,
        ka_type: this.form.ka_type,
        terminal_customer_from: this.form.terminal_customer_from,
        account_manager_id: this.form.account_manager_id,
        tech_service_personnel_id: this.form.tech_service_personnel_id,
        cus_service_personnel_id: this.form.cus_service_personnel_id,
        is_coop_offline: this.form.is_coop_offline,
        power_state: this.form.power_state,
        hotel_room: this.form.hotel_room,
        total_num: this.form.total_num,
        h_ota_price: this.form.h_ota_price,
        pms_brand: this.form.pms_brand,
        citycode: this.form.citycode,
        baidu_address: this.form.baidu_address,
        hotel_address: this.form.hotel_address,
        address: this.form.baidu_address,
        baidu_hotel_name: this.form.baidu_hotel_name,
        baidu_uid: this.form.baidu_uid,
        lat: this.map.center.lat,
        lng: this.map.center.lng,
        hotel_charger: this.form.hotel_charger,
        hotel_charger_phone: this.form.hotel_charger_phone,
        maintain_name: this.form.maintain_name,
        maintain_phone: this.form.maintain_phone,
        ter_store_type: this.form.ter_store_type,
        check_date: this.form.check_date,
        hotel_try_start: this.form.hotel_try_start,
        hotel_try_end: this.form.hotel_try_end,
        contract_start: this.form.contract_start,
        contract_end: this.form.contract_end,
        channel_name: this.form.channel_name,
        channel_code: this.form.channel_code,
        channel_link_person: this.form.channel_link_person,
        channel_link_phone: this.form.channel_link_phone,
        allot_channel_code: this.form.allot_channel_code,
        online_channel_code: this.form.online_channel_code,
        settle_type: this.form.settle_type,
        is_settle_service: this.form.is_settle_service,
        is_settle_online: this.form.is_settle_online,
        hard_factory: this.form.hard_factory,
        project_act_num: this.form.project_act_num,
        is_os_sell: this.form.is_os_sell,
        is_ctrip: this.form.is_ctrip,
        group_hotel_name: this.form.group_hotel_name,
        group_code: this.form.group_code,
        ipaddr: this.form.ipaddr,
        hotelName: this.form.hotelName,
        addtime: this.form.addtime,
        supplier_id: this.form.supplier_id,
        hotel_key: this.form.hotel_key,
        hotel_server_ip: this.form.hotel_server_ip,
        hotel_server_key: this.form.hotel_server_key,
        spare_url: this.form.spare_url,
        demand: this.form.demand,
        hotel_province: this.form.provinceLabel,
        hotel_city: this.form.cityLabel,
        hotel_county: this.form.countryLabel,
        is_moive_settlement: this.form.is_moive_settlement,
        sell_goods: sell_goods_data,
        brand_name: this.form.brand_name,
      };
      for (var i = 0; i < this.selectData.hotelGroupList.length; i++) {
        if (this.selectData.hotelGroupList[i].id == paramData.groupId) {
          paramData.brand_name = this.selectData.hotelGroupList[i].groupname;
        }
      }
      return paramData;
    },
    createMeth() {
      var param = this.getParam();
      var ehotelDialog = this.$refs.ehotelDialog;
      var template = JSON.stringify(this.selectedHotel);
      param.template = template;
      param = qs.stringify(param);
      this.$http.post(this.global.create, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
          sessionStorage.setItem("defaultActiveId", 247);
          this.$router.push({ path: "/hotelManAll" });
        } else if (res.data.code === 100001) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100002) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100003) {
          this.$message.error(res.data.msg);
        }
      });
    },
    editMeth() {
      var param = this.getParam();
      param.id = this.id;
      param = qs.stringify(param);
      this.$http.post(this.global.update, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          // var fromUrl = this.query.fromUrl;
          // this.$router.push({path: `/${fromUrl}`});
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getTerType(val) {
      this.form.ter_type = val;
    },
    getClickInfo(e) {
      this.map.center.lng = e.point.lng;
      this.map.center.lat = e.point.lat;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.map.center.lng = lng;
      this.map.center.lat = lat;
      this.map.zoom = e.target.getZoom();
    },
    channelChange(val) {
      this.form.channel_code = val;
    },
    allotChannelChange(val) {
      console.log(this.form.allot_channel_code, this.allot_channel_name);
      this.form.allot_channel_code = val;
    },
    onlineChannelChange(val) {
      this.form.online_channel_code = val;
    },
    getCurrentDate() {
      var obj = new Date();
      var year = obj.getFullYear();
      var month = obj.getMonth() + 1;
      var day = obj.getDate();
      this.form.hotel_try_start = year + "-" + month + "-" + day;
      this.form.addtime = year + "-" + month + "-" + day;
      this.form.contract_start = year + "-" + month + "-" + day;
    },
    getOneYearLater() {
      var date = new Date();
      var y = date.getFullYear() + 1;
      var m = date.getMonth() + 1;
      // m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      // d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      // h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "1" + minute : minute;
      second = second < 10 ? "0" + second : second;
      this.form.contract_end =
        y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    test(val) {
      // return 'test44433';
    },
    getYearEnd() {
      var obj = new Date();
      var year = obj.getFullYear();
      this.form.hotel_try_end = year + "-12-31";
    },
    confirm(e, target, item) {
      this.map.center.lat = e.currentTarget.map.re.lat;
      this.map.center.lng = e.currentTarget.map.re.lng;
    },
    getInfoClick(e) {
      this.map.center.lat = e.Ag.lat;
      this.map.center.lng = e.Ag.lng;
    },
    mapRender() {
      // var map = new BMap.Map('allmap');
      // var ac = new BMap.Autocomplete( //建立一个自动完成的对象
      //     {
      //         'input': 'searchInput',
      //         'location': map
      //     });
      // ac.addEventListener('onhighlight', function (e) { //鼠标放在下拉列表上的事件
      //     alert('hige');
      // });
      // ac.addEventListener('onconfirm', function (e) { //鼠标放在下拉列表上的事件
      //     alert('onconfirm');
      // });
    },
    //终端信息
    getTerBrand(terminalBrand) {
      var param = qs.stringify({
        token: this.token,
        terminalBrand: terminalBrand,
      });
      this.$http
        .post(this.global.getTerminalBrandPullDownList, param)
        .then((res) => {
          if (res.data.code === 100000) {
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    showTerInfo() {
      this.terInfoShow = true;
      var param = qs.stringify({
        token: this.token,
        hotelContract: this.hotel_contract,
      });
      this.$http.post(this.global.getHotelTerminalInfo, param).then((res) => {
        if (res.data.code === 100000) {
          if (res.data.data.length === 0) {
            this.$message.warning("暂无终端信息");
          } else {
            this.terInfo = res.data.data;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    editTerInfo(id) {
      this.operateTerType = "edit";
      this.operateTerId = id;
      var terDialog = this.terDialog;
      this.form.terInfo.forEach((item, index) => {
        if (item.id === id) {
          terDialog.terTypeVal = item.terTypeVal;
          terDialog.terBrandVal = item.terBrandVal;
          terDialog.terBrandLabel = item.terBrandLabel;
          terDialog.terModelVal = item.terModelVal;
          terDialog.terModelLabel = item.terModelLabel;
          terDialog.terNumVal = item.terNumVal;
        }
      });
      terDialog.dialogFormVisible = true;
    },
    delTerInfo(id) {
      this.resetTerDialog();
      // this.terDialog.dialogFormVisible=true;
      var terInfo = this.form.terInfo;
      var delIndex;
      terInfo.forEach((item, index) => {
        if (item.id == id) {
          delIndex = index;
        }
      });
      terInfo.splice(delIndex, 1);
    },
    resetTerDialog() {
      var terDialog = this.terDialog;
      terDialog.terTypeVal = "";
      terDialog.terBrandVal = "";
      terDialog.terBrandLabel = "";
      terDialog.terModelVal = "";
      terDialog.terModelLabel = "";
      terDialog.terNumVal = "";
    },
    submitDialogForm() {
      var terFormData = this.terDialog;
      var terInfo = this.form.terInfo;
      this.$refs["terDialog"].validate((valid) => {
        if (valid) {
          if (this.operateTerType === "new") {
            var id = 0;
            terInfo.push({
              terTypeVal: terFormData.terTypeVal,
              terBrandVal: terFormData.terBrandVal,
              terBrandLabel: terFormData.terBrandLabel,
              terModelVal: terFormData.terModelVal,
              terModelLabel: terFormData.terModelLabel,
              terNumVal: terFormData.terNumVal,
            });
            terInfo.forEach((item, index) => {
              item.id = id++;
            });
          } else if (this.operateTerType === "edit") {
            terInfo.forEach((item, index) => {
              if (item.id == this.operateTerId) {
                item.terTypeVal = terFormData.terTypeVal;
                item.terBrandVal = terFormData.terBrandVal;
                item.terBrandLabel = terFormData.terBrandLabel;
                item.terModelVal = terFormData.terModelVal;
                item.terModelLabel = terFormData.terModelLabel;
                item.terNumVal = terFormData.terNumVal;
              }
            });
          }
          this.terDialog.dialogFormVisible = false;
        } else {
          this.$message("验证失败");
          return false;
        }
      });
    },
    // signUpStateChange(val){
    //     this.getKaType(val);
    // },
    showTemplateDialog(val) {
      this.$refs.ehotelDialog.showDialog();
    },
    getKaType(val) {
      var arr = [
        { value: 4, label: "终端KA" },
        { value: 5, label: "渠道KA" },
        { value: 6, label: "集团KA" },
      ];
      this.form.ka_type = "";
      this.form.account_manager_id = "";
      this.selectData.kaTypeSelect = arr;
    },
    dialogConfirm() {
      if (
        this.form.templateId === "" ||
        this.templateDialog.templateFormatRadioList === ""
      ) {
        this.$message.warning("请完善信息");
      } else {
        this.templateDialog.visible = false;
      }
    },
    getHotelTemplateId(val) {
      this.selectData.hotelTemplateList.filter((item, index) => {
        if (item.name.indexOf(val) !== -1) {
          this.form.hotel_template_id = item.id;
        }
      });
    },
    setTemplateType(val) {
      this.form.templateType = val;
    },
    getTemplateLabel(val) {
      this.templateLabel = val;
    },
    getLevelQuality() {
      var param = qs.stringify({ token: this.token });
      this.$http
        .post(this.global.getHotelLevelAndQuality, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.hotel_level_select = res.data.data.level;
            this.selectData.hotel_quality_select = res.data.data.quality;
          }
        });
    },
    isEmptyObj(obj) {
      for (let i in Object.keys(obj)) {
        return false; // 进入循环即不为空
      }
      return true;
    },
    getSelectedHotel(val, type) {
      this.selectedHotel = val;
      this.form.selectedHotelTemLabel = val.label;
      this.selectData.hotelTemplateList.filter((item, index) => {
        if (item.name.indexOf(type) !== -1) {
          this.form.hotel_template_id = item.id;
        }
      });
    },
    searchMeth() {
      this.selectData.addressSearchList = [];
      this.searchListBoxShow = true;
      var param = qs.stringify({
        token: this.token,
        keyword: this.form.baidu_address,
      });
      this.$http
        .post(this.global.getBaiduSearch, param)
        .then((res) => {
          if (res.data.code === 100000) {
            if (res.data.data.length === 0) {
              this.selectData.addressSearchList = [{ name: "暂无数据" }];
            } else {
              this.selectData.addressSearchList = res.data.data;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    getCoordinate(uid, adcode, province, city, district) {
      if (uid) {
        var param = qs.stringify({
          token: this.token,
          uid: uid,
        });
        this.$http
          .post(this.global.getBaiduInfo, param)
          .then((res) => {
            if (res.data.code === 100000) {
              var resData = res.data.data;
              this.map.center.lat = resData.location.lat;
              this.map.center.lng = resData.location.lng;
              this.form.baidu_address = resData.address;
              this.form.baidu_hotel_name = resData.name;
              this.form.baidu_uid = resData.uid;
            } else {
              this.$message.error(res.msg);
            }
            this.searchListBoxShow = false;
          })
          .catch(() => {
            this.$message.error("返回错误");
            this.searchListBoxShow = false;
          });
        this.form.provinceShow = province;
        this.form.provinceLabel = province;
        this.form.cityShow = city;
        this.form.cityLabel = city;
        this.form.countryShow = district;
        this.form.countryLabel = district;
        this.form.citycode = adcode;
      }
    },
    getAccountManagerList(val) {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getAccountManagerList, {
            params: {
              token: this.token,
              accountManagerTypeId: val,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              if (val === "2") {
                //客服
                this.selectData.cus_service_personnel_id_select = res.data.data;
              } else if (val === "3") {
                //技服
                this.selectData.tech_service_personnel_id_select =
                  res.data.data;
              } else {
                this.selectData.account_manager_id_select = res.data.data;
              }
            }
            resolve();
          });
      });
    },
    accountManagerChange(val) {
      switch (val) {
        case 246:
        case 247:
        case 248:
        case 249:
        case 273:
        case 274:
        case 275:
        case 276:
        case 277:
        case 278:
        case 270:
        case 271:
        case 272:
        case 337:
          this.form.ka_type = 5; //部门  渠道
          // this.form.is_signed = 0; //签约  否
          this.form.terminal_customer_from = 1; //来源  精英
          break;
        case 243:
        case 420:
          this.form.ka_type = 6; //部门  集团
          // this.form.is_signed = 1; //签约  是
          this.form.terminal_customer_from = 6; //来源  集团
          break;
        case 242:
          this.form.ka_type = 4; //部门  终端
          // this.form.is_signed = 0; //签约  否
          this.form.terminal_customer_from = 1; //来源  精英
          break;
      }
    },
  },
  mounted() {
    if (this.$route.query) {
      var query = this.$route.query;
      if (!this.isEmptyObj(query)) {
        this.query = query;
      }
    } else {
      var query = {
        id: this.id,
        fromUrl: "",
      };
    }
    this.hotelId = this.id;
    this.hotelProgram = this.hotelName;
    this.hotel_is_children = this.is_children;
    this.token = sessionStorage.getItem("token");
    var auth = JSON.parse(sessionStorage.getItem("auth"));
    this.auth_type = auth.type;
    this.authUserName = auth.user_name;
    this.form.build_person = auth.user_name;
    this.getOption();
    this.getPartner();
    if (!this.id) {
      this.ter_flag = true;
      this.getCurrentDate();
    } else {
      this.getDetail(2);
    }
    this.getAllTemplateList();
    this.getOneYearLater();
    this.getYearEnd();
    this.mapRender();
    this.getLevelQuality();
    // this.getKaType(this.form.is_signed);
    this.getAccountManagerList("2"); //客服
    this.getAccountManagerList("3"); //技服
    this.getAccountManagerList(); //技服
  },
};
</script>

<style scoped lang="scss">
/*地图容器*/
/*地图容器*/
.bm-view {
  width: 100%;
  height: 420px;
}

.selectItem {
  width: 100%;
}

.radioBox {
  margin-top: 12px;
}

.map {
  width: 100%;
  height: 0;
}

#map {
  width: 100%;
}

.searchBox {
  display: none !important;
}

.terInfoCol {
  display: flex;
}

.terShowItem {
  width: 20%;
}

.terShowItemBtn {
  width: 10%;
  margin-left: 10px;
}

.occupyEl {
  visibility: hidden;
}
.hideEl {
  visibility: hidden;
}

.twoRow {
  display: flex;
  flex-direction: column;
  /*justify-content: space-around;*/
}

.searchInput {
  position: absolute;
  top: -64px !important;
  background: red;
  /*z-index:999;*/
}

.coordinateBox {
  display: flex;
}

.coordinate {
  width: 50%;
}

img,
video {
  width: 100%;
  height: 100%;
}

.templateBox {
}

.templateFormatBox {
  display: flex;
  justify-content: space-around;
}

.templateTitle {
  margin-top: 70px;
}

.marginBottom {
  margin-bottom: 20px;
}

.imgItem {
  width: 48%;
}

.imgBox {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.templateFormatRadio {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0 !important;
}

.noneEl {
  visibility: hidden;
}

.terInfoRow {
  width: 100%;
}

.alignCenter {
  margin-top: 25px;
}

.searchList {
  width: 100%;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  z-index: 3;
  box-sizing: border-box;
  top: 40px;
  padding-left: 200px;
}

.searchListBox {
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  box-sizing: border-box;
}

.searchListItem {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 14px;
}

.noneEl {
  /*display:none;*/
}

.relativeEl {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.firstTips {
  font-size: 16px;
  margin-bottom: 15px;
}

.flexBox {
  display: flex;
  align-items: center;
}

.warningIcon {
  .tenMarginLeft {
    margin-left: 10px;
  }
  color: #e6a23c;
  font-size: 24px;
  margin-right: 20px;
}

.tenMarginLeft {
  margin-left: 10px;
}
.fullFormItem {
  width: 100%;
}
</style>
