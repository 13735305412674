<template>
    <el-card class="box-card">
        <div class="quickSearchBox">
            <div class="quickDateSearch">
                <div class="item" @click="quickDateSearchMeth('yesDay')" :class="quickDateCur==='yesDay'?'active':''">昨日</div>
                <div class="item" @click="quickDateSearchMeth('week')" :class="quickDateCur==='week'?'active':''">本周</div>
                <div class="item" @click="quickDateSearchMeth('month')" :class="quickDateCur==='month'?'active':''">本月</div>
                <div class="item" @click="quickDateSearchMeth('year')" :class="quickDateCur==='year'?'active':''">本年</div>
            </div>
            <div class="rightBtn">
                <div class="quickDateSearch " >
                    <div class="item" @click="switchReqUrl('belong')" :class="switchReqUrlCur==='belong'?'active':''">所属</div>
                    <div class="item" @click="switchReqUrl('allot')" :class="switchReqUrlCur==='allot'?'active':''">归属</div>
                </div>
                <div class="quickDateSearch ">
                    <div class="item" @click="quickNatureSearchMeth('self')" :class="quickNatureCur==='self'?'active':''">自有</div>
                    <div class="item" @click="quickNatureSearchMeth('screen')" :class="quickNatureCur==='screen'?'active':''">聚屏</div>
                </div>
            </div>
        </div>
    </el-card>
</template>

<script>
    export default {
        name: "QuickSearch",
        data:()=>({
            quickDateCur:'yesDay',
            quickNatureCur:'self',
            switchReqUrlCur:'belong',
            curDate:{
                yesDay:'',
                curDay:'',
                startWeek:'',
                endWeek:'',
                startMonth:'',
                endMonth:'',
                startYear:'',
                endYear:'',
            },
        }),
        methods:{
            quickDateSearchMeth(type){
                this.quickDateCur=type;
                var curDate=this.curDate;
                var condition=this.condition;
                if(type==='week'){
                    condition.startTime=curDate.startWeek;
                    condition.endTime=curDate.endWeek;
                }else if(type==='month'){
                    condition.startTime=curDate.startMonth;
                    condition.endTime=curDate.endMonth;
                }else if(type==='year'){
                    condition.startTime=curDate.startYear;
                    condition.endTime=curDate.endYear;
                }else if(type==='yesDay'){
                    condition.startTime='';
                    condition.endTime='';
                }
                this.getLineChart(this.switchReqUrlCur);
                this.showDataMeth(this.switchReqUrlCur);
                this.tableMeth('',this.switchReqUrlCur);
            },
            getYesDay(){
                var time=(new Date).getTime()-24*60*60*1000;
                var yesterday=new Date(time);
                var month=yesterday.getMonth()+1;
                var day=yesterday.getDate();
                this.curDate.yesDay=yesterday.getFullYear() + "-" + (month>9 ? (month) : "0" + (month)) + "-" +(yesterday.getDate()> 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
            },
            getCurDay(){
                var time=(new Date).getTime();
                var today=new Date(time);
                var month=today.getMonth()+1;
                var day=today.getDate();
                this.curDate.curDay=today.getFullYear() + "-" + (month> 9 ? (month) : "0" + (month)) + "-" +(today.getDate()> 9 ? (today.getDate()) : "0" + (today.getDate()));
            },
            add0(m){
                return m<10?'0'+m:m
            },
            getCurWeek(){
                var startStop = new Array();
                //获取当前时间
                var currentDate = new Date();
                //返回date是一周中的某一天
                var week = currentDate.getDay();
                //返回date是一个月中的某一天
                var month = currentDate.getDate();
                //一天的毫秒数
                var millisecond = 1000 * 60 * 60 * 24;
                //减去的天数
                var minusDay = week != 0 ? week - 1 : 6;
                //alert(minusDay);
                //本周 周一
                var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
                //本周 周日
                var sunday = new Date(monday.getTime() + (6 * millisecond));
                var sy = monday.getFullYear();
                var sm = monday.getMonth() + 1;
                var sd = monday.getDate();
                var ey = sunday.getFullYear();
                var em = sunday.getMonth() + 1;
                var ed = sunday.getDate();
                // var s = sy+'-'+add0(sm)+'-'+add0(sd)+' 00:00:00';//开始
                // var e = ey+'-'+add0(em)+'-'+add0(ed)+' 23:59:59';//结束
                var s = sy+'-'+this.add0(sm)+'-'+this.add0(sd);//开始
                var e = ey+'-'+this.add0(em)+'-'+this.add0(ed);//结束
                this.curDate.startWeek=s;
                this.curDate.endWeek=this.curDate.yesDay;
            },
            getCurMonth(type,months){
                var d = new Date();
                var year = d.getFullYear();
                var month = d.getMonth() + 1;
                if (Math.abs(months) > 12) {
                    months = months % 12;
                };
                if (months != 0) {
                    if (month + months > 12) {
                        year++;
                        month = (month + months) % 12;
                    } else if (month + months < 1) {
                        year--;
                        month = 12 + month + months;
                    } else {
                        month = month + months;
                    };
                };
                month = month < 10 ? "0" + month: month;
                var date = d.getDate();
                var firstday = year + "-" + month + "-" + "01";
                var lastday = "";
                if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
                    lastday = year + "-" + month + "-" + 31;
                } else if (month == "02") {
                    if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                        lastday = year + "-" + month + "-" + 29;
                    } else {
                        lastday = year + "-" + month + "-" + 28;
                    };
                } else {
                    lastday = year + "-" + month + "-" + 30;
                };
                var day = "";
                if (type == "s") {
                    day = firstday;
                } else {
                    // day = lastday;
                    day=this.curDate.curDay;
                };
                return day;
            },
            getCurYear(type,dates){
                var dd = new Date();
                var n = dates || 0;
                var year = dd.getFullYear() + Number(n);
                if (type == "s") {
                    var day = year + "-01-01";
                };
                if (type == "e") {
                    // var day = year + "-12-31";
                    var day=this.curDate.curDay;
                };
                if (!type) {
                    var day = year + "-01-01/" + year + "-12-31";
                };
                return day;
            },
            getCurDate(){},
        },
        mounted(){
            this.getCurDate();
            this.getYesDay();
            this.getCurDay();
            this.getCurWeek();
            this.curDate.startMonth=this.getCurMonth("s",0);
            this.curDate.endMonth=this.curDate.yesDay;
            this.curDate.startYear=this.getCurYear("s",0);
            this.curDate.endYear=this.curDate.yesDay;
        }
    }
</script>

<style scoped>
    .quickDateSearch{
        margin-left:15px;
        display:flex;
    }
    .quickDateSearch .item{
        border:1px solid #dcdfe6;
        color:#606266;
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
        padding: 12px 20px;
        cursor:pointer;
        margin-left:15px;
    }
    .quickDateSearch .item.active{
        color: #409EFF;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
    }
</style>
