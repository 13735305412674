<template>
    <div :class="[$attrs.onlyShowModule?'onlyShowModule':'']">
        <div class="home_container" :style="{'backgroundImage':'url('+bgUrlProp+')'}">
            <div class="home_bottom">
                <div class="bottom_left  borderBox" :class="[modelHighIndexClass('modelOne')]"
                     style="position:relative;" @mouseover="modelShowBtnMeth('modelOne')"
                     @mousemove.stop="modelShowBtnMeth('modelOne')">
                    <img :src="homePage.modelOne" class="" alt="" v-if="homePage.modelOneType==='img'">
                    <video :src="homePage.modelOne" controls v-if="homePage.modelOneType==='video'"></video>
                    <div class="configBox highIndex modelOne " :class="configBtnClass('modelOne')">
                        <div class="btnItem highIndex configBtn" @click.stop="slideConfigBox('modelOne')"
                             :class="configBtnClass('modelOne')">配置
                        </div>
                        <div class="slideBox " v-if="modelOneData.configShow">
                            <div class="btnGroup configMarginLeft">
                                <div class="btnGroupItem">
                                    <div class="btnItem" @click.stop="uploadMaterial('modelOne','video')">上传视频</div>
                                </div>
                                <div class="btnGroupItem">
                                    <div class="btnItem" @click="resetItemMeth('modelOne')">恢复</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="bottom_right">
                    <img class="" :src="homePageProp.homeRtBg" alt="">
                    <div class="rightTextBox">
                        <div class="date-week textCenter">
                            {{curTime.date}}&nbsp;&nbsp; &nbsp;&nbsp; {{curTime.week}}
                        </div>
                        <div class="time textCenter">
                            {{curTime.time}}
                        </div>
                        <div class="home_logo borderBox text"
                             :class="[modelHighIndexClass('modelLogo')]"
                             style="margin-top: 60px;">
                            <img   :src="homePage.modelLogo" alt="" @mouseover="modelShowBtnMeth('modelLogo')" >
                            <div @mouseover="modelShowBtnMeth('modelLogo')" class="configBox logo  highIndex logo " :class="configBtnClass('modelLogo')">
                                <div class="configBox">
                                    <div class="slideBox " v-if="modelLogoData.configShow">
                                        <div class="btnGroup configMarginLeft">
                                            <div class="btnGroupItem">
                                                <div class="btnItem"
                                                     @click.stop="uploadMaterial('modelLogo','img')">上传图片 </div>
                                            </div>
                                            <div class="btnGroupItem">
                                                <div class="btnItem" @click="resetItemMeth('modelLogo')">恢复</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btnItem highIndex configBtn " @click.stop="slideConfigBox('modelLogo')">
                                        配置
                                    </div>
                                </div>
                            </div>
                        </div>
                        <el-form :model="homePage" class="formBox noBgInput textBox alignCenter">
                            <div @click.stop="showShadeMeth('homeWelWord')" @mouseover="modelShowBtnMeth('homeWelWord')" :class="[modelHighIndexClass('homeWelWord')]">
                                <el-input class="homeWelWord textBorderBox noDisabledColor" @input="inputMeth('homeWelWord')" v-model="homePage.homeWelWord" placeholder="请输入欢迎语"
                                          @focus="focusTextBoxMeth('homeWelWord')" :disabled="$attrs.onlyShowModule"
                                          @blur="blurTextBoxMeth"
                                          :class="{focusTextBox:curFocusTextBox==='homeWelWord'}"></el-input>
                            </div>
                            <div @click.stop="showShadeMeth('homeHotelAddr')" @mouseover="modelShowBtnMeth('homeHotelAddr')" :class="[modelHighIndexClass('homeHotelAddr')]">
                                <el-input  class="homeHotelAddr textBorderBox noDisabledColor" @input="inputMeth('homeHotelAddr')"
                                           v-model="homePage.homeHotelAddr" :disabled="$attrs.onlyShowModule"
                                           placeholder="请输入酒店地址"
                                           @focus="focusTextBoxMeth('homeHotelAddr')"
                                           @blur="blurTextBoxMeth"
                                           :class="{focusTextBox:curFocusTextBox==='homeHotelAddr'}"></el-input>
                            </div>
                            <div @click.stop="showShadeMeth('homeHotelName')" @mouseover="modelShowBtnMeth('homeHotelName')"
                                 :class="[modelHighIndexClass('homeHotelName')]">
                                <el-input  class="homeHotelName textBorderBox noDisabledColor" @input="inputMeth('homeHotelName')"
                                           v-model="homePage.homeHotelName" :disabled="$attrs.onlyShowModule"
                                           placeholder="请输入酒店名称"
                                           @focus="focusTextBoxMeth('homeHotelName')"
                                           @blur="blurTextBoxMeth"
                                           :class="{focusTextBox:curFocusTextBox==='homeHotelName'}"></el-input>
                            </div>
                            <div @click.stop="showShadeMeth('homeTel')" @mouseover="modelShowBtnMeth('homeTel')" :class="[modelHighIndexClass('homeTel')]">
                                <el-input  class="homeTel textBorderBox noDisabledColor" @input="inputMeth('homeTel')"
                                           v-model="homePage.homeTel" :disabled="$attrs.onlyShowModule"
                                           placeholder="请输入酒店电话"
                                           @focus="focusTextBoxMeth('homeTel')"
                                           @blur="blurTextBoxMeth"
                                           :class="{focusTextBox:curFocusTextBox==='homeTel'}"></el-input>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
            <div class="home_middle" :class="{'blueBg': templateMenuCurStr =='TSbg'?true:false}" style="margin-bottom: 10px">
                <div class="menuItemBox" :class="{blurBorderBg:blurBorderBg}">
                    <div class="menuItem"
                         :class="{active:index===0,borderBlueShadow:borderBlueShadow,smallMenuItem:smallMenuItem}"
                         v-for="(item,index) in menuProp"
                         :style="templateMenuBoxStyleCom(item.color,index)">
                        <div class="icon" :class="{iconBorder:iconBorder}"><img :src="item.focuspicaddr" alt="" v-if="activeNameProp===item.mname"></div>
                        <div class="icon" :class="{iconBorder:iconBorder}"><img :src="item.defaultpicaddr" alt="" v-if="activeNameProp!==item.mname"></div>
                        <!--<div class="text" :style="{'color':item.color}">{{item.mname}}</div>-->
                        <div class="text" :style="templateMenuTextStyleCom(item.color,index)">{{ item.mname }}</div>
                    </div>
                </div>
            </div>
            <div class="shade" @click="hideShade" v-if="shadeShow"></div>
        </div>
        <upload-dialog :dialogParData="dialogUpload"
                       @uploadMaterialUrl="getUploadMaterialUrl" @changeDialogVisible="changeDialogVisible" @editTabLoadingMeth="editTabLoadingMeth"
                       :hotelContract="hotelContract"></upload-dialog>
    </div>
</template>
<script>
    import commonMeth from '../../../../api/method'
    import qs from 'querystring'

export default {
    name: "EditEelDetail",
    props: ['hotelContract', 'homePageProp', 'menuProp',
        'templateIdProp', 'bgUrlProp', 'onlyShow',
        'hotelTemplateType','modelReadOnly','activeNameProp'],
    data: () => ({
        test: '',
        modelShowBtnName: '',
        modelLogoData: {
            configShow: false,
            moreType: '',
            moreDetailShow: false,
            arrowDirection: 'down',
            fileName: ''
        },
        modelScrollTextData: {
            configShow: false,
            moreType: '',
            colorDetailShow: false,
            arrowDirection: 'down'
        },
        modelOneData: {
            configShow: false,
            moreType: '',
            moreDetailShow: false,
            arrowDirection: 'top',
            fileName: ''
        },
        modelTwoData: {
            configShow: false,
            moreType: '',
            moreDetailShow: false,
            arrowDirection: 'top',
            fileName: ''
        },
        modelThreeData: {
            configShow: false,
            moreType: '',
            moreDetailShow: false,
            arrowDirection: 'top',
            fileName: ''
        },
        homePage: {
            scrollText: '',
            coBack: '',
            moreDetailShow: false,
            colorDetailShow: false,
            modelLogo: '',
            modelOne: '',
            modelOneType: '',
            modelTwo: '',
            modelThree: '',
            homeRtBg: '',
            homeWelWord: '',
            homeHotelAddr: '',
            homeHotelName: '',
            homeTel: '',
            title: '',
        },
        defaultData: {
            scrollText: '',
            coBack: '',
            moreDetailShow: false,
            colorDetailShow: false,
            modelLogo: '',
            modelOne: '',
            modelOneType: 'img',
            modelTwo: '',
            modelThree: '',
        },
        visible: true,
        dialogUpload: {
            visible: false,
            agreenActive: '',
            imgMaterialList: [],
            videoMaterialList: [
                {id: 1, name: 1, url: require('../../../../assets/img/video/1.mp4')},
                {id: 1, name: 2, url: require('../../../../assets/img/video/2.mp4')},
            ],
            chooseItemIndex: '',
            uploadModel: '',
            uploadMaterialType: '',
            uploadMaterialUrl: ''
        },
        headers: {
            token: '',
            Authorization: ''
        },
        initConfigBtnShow: false,
        shadeShow: false,

        templateRightModel: {
            threeModel: [],
            twoEditTextImg: ['71', '73', '74'],
            twoEditImg: ['75'],
        },
        templateMenuCurStr: '',
        blurBorderBg: false,
        borderBlueShadow: false,
        iconBorder: false,
        smallMenuItem: false,
        curTime: {
            date: '',
            week: '',
            time: '',
        },
        curFocusTextBox:'',
        query:{}
    }),
    methods: {
        slideConfigBox(type) {
            this.shadeShow = true;
            if (type === 'modelLogo') {
                var modelLogoData = this.modelLogoData;
                modelLogoData.configShow = !this.modelLogoData.configShow;
                if (modelLogoData.configShow) {
                    this.shadeShow = true;
                    if (!this.initConfigBtnShow) {
                        this.initConfigBtnShow = true;
                    }
                } else {
                    this.shadeShow = false;
                }
                modelLogoData.moreType = '';
                modelLogoData.moreDetailShow = false;
            } else if (type === 'scrollText') {
                var modelScrollTextData = this.modelScrollTextData;
                modelScrollTextData.configShow = !modelScrollTextData.configShow;
                if (modelScrollTextData.configShow) {
                    this.shadeShow = true;
                } else {
                    this.shadeShow = false;
                }
                modelScrollTextData.moreDetailShow = false;
                modelScrollTextData.colorDetailShow = false;
            } else if (type === 'modelOne') {
                var modelOneData = this.modelOneData;
                modelOneData.configShow = !this.modelOneData.configShow;
                if (modelOneData.configShow) {
                    this.shadeShow = true;
                } else {
                    this.shadeShow = false;
                }
                modelOneData.moreType = '';
                modelOneData.moreDetailShow = false;
            } else if (type === 'modelTwo') {
                var modelTwoData = this.modelTwoData;
                modelTwoData.configShow = !this.modelTwoData.configShow;
                if (modelTwoData.configShow) {
                    this.shadeShow = true;
                } else {
                    this.shadeShow = false;
                }
                modelTwoData.moreType = '';
                modelTwoData.moreDetailShow = false;
            } else if (type === 'modelThree') {
                var modelThreeData = this.modelThreeData;
                modelThreeData.configShow = !this.modelThreeData.configShow;
                if (modelThreeData.configShow) {
                    this.shadeShow = true;
                } else {
                    this.shadeShow = false;
                }
                modelThreeData.moreType = '';
                modelThreeData.moreDetailShow = false;
            }
        },
        uploadMaterial(modelType, materialType) {
            if (modelType === 'modelLogo') {
                this.modelLogoData.moreType = materialType;
            } else if (modelType === 'modelOne') {
                this.modelOneData.moreType = materialType;
            } else if (modelType === 'modelTwo') {
                this.modelTwoData.moreType = materialType;
            } else if (modelType === 'modelThree') {
                this.modelThreeData.moreType = materialType;
            }
            var dialogUpload = this.dialogUpload;
            dialogUpload.uploadModel = modelType;
            dialogUpload.uploadMaterialType = materialType;
            dialogUpload.visible = true;
        },
        resetDialog() {
            var dialogUpload = this.dialogUpload;
            dialogUpload.chooseItemIndex = '';
            dialogUpload.uploadModel = '';
            dialogUpload.uploadMaterialType = '';
            dialogUpload.uploadMaterialUrl = '';
        },
        showDetailMeth(model, val) {
            this.modelLogoData.moreType = '';
            if (model === 'logo') {
                var modelLogoData = this.modelLogoData;
                modelLogoData.moreDetailShow = !modelLogoData.moreDetailShow;
                modelLogoData.moreDetailShow ? modelLogoData.arrowDirection = 'top' : modelLogoData.arrowDirection = 'down'
            } else if (model === 'scrollText') {
                var modelScrollTextData = this.modelScrollTextData;
                modelScrollTextData.colorDetailShow = !modelScrollTextData.colorDetailShow;
                modelScrollTextData.moreDetailShow ? modelScrollTextData.arrowDirection = 'top' : modelScrollTextData.arrowDirection = 'down'

            } else if (model === 'modelOne') {
                this.modelOneData.moreDetailShow = !this.modelOneData.moreDetailShow;
            } else if (model === 'modelTwo') {
                var modelTwoData = this.modelTwoData;
                modelTwoData.moreDetailShow = !modelTwoData.moreDetailShow;
                modelTwoData.moreDetailShow ? modelTwoData.arrowDirection = 'down' : modelTwoData.arrowDirection = 'top'
            } else if (model === 'modelThree') {
                var modelThreeData = this.modelThreeData;
                modelThreeData.moreDetailShow = !modelThreeData.moreDetailShow;
            }
        },
        clickCo(e) {
            this.homePage.coBack = e.currentTarget.id;
        },
        modelShowBtnMeth(type) {
            if(!this.shadeShow){
                this.modelShowBtnName=type;
            }
        },
        clearShowBtnName() {
            this.modelShowBtnName = '';
            this.shadeShow = false;
        },
        getUploadMaterialUrl(url, fileName) {
            var homePage = this.homePage;
            var dialogUpload = this.dialogUpload;
            if (dialogUpload.uploadModel === 'modelLogo') {
                homePage.modelLogo = url;
                this.modelLogoData.fileName = fileName;
            } else if (dialogUpload.uploadModel === 'modelOne') {
                homePage.modelOneType = dialogUpload.uploadMaterialType;
                homePage.modelOne = url;
                this.modelOneData.fileName = fileName;
            } else if (dialogUpload.uploadModel === 'modelTwo') {
                homePage.modelTwo = url;
                this.modelTwoData.fileName = fileName;
            } else if (dialogUpload.uploadModel === 'modelThree') {
                homePage.modelThree = url;
                this.modelThreeData.fileName = fileName;
            }
            dialogUpload.visible = false;
        },
        getFormDataFile(val) {
            var homePage = this.homePage;
            var modelOneData = this.modelOneData;
            var dialogUpload = this.dialogUpload;
            if (dialogUpload.uploadModel === 'modelLogo') {
                homePage.modelLogoFile = val;
            } else if (dialogUpload.uploadModel === 'modelOne') {
                homePage.modelOneFile = val;
            } else if (dialogUpload.uploadModel === 'modelTwo') {
                homePage.modelTwoFile = val;
            } else if (dialogUpload.uploadModel === 'modelThree') {
                homePage.modelThreeFile = val;
            }
        },
        defaultDataMeth() {
            this.shadeShow = false;
            this.homePage=commonMeth.objDataAssign(this.defaultData);
            if (this.hotelTemplateType === 'ehotel') {
                this.homePage.coBack = 'coBlack'
            }
            console.log('主页右侧文字homeAPgae',this.homePage);
        },
        resetItemMeth(type) {
            var homePage = this.homePage;
            var defaultData = this.defaultData;
            if (type === 'modelLogo') {
                homePage.modelLogo = defaultData.modelLogo;
            } else if (type === 'scrollText') {
                homePage.scrollText = defaultData.scrollText;
            } else if (type === 'modelOne') {
                homePage.modelOne = defaultData.modelOne;
                homePage.modelOneType = defaultData.modelOneType;
            } else if (type === 'modelTwo') {
                homePage.modelTwo = defaultData.modelTwo;
            } else if (type === 'modelThree') {
                homePage.modelThree = defaultData.modelThree;
            }
        },
        hideShade() {
            this.shadeShow = false;
            this.modelLogoData.configShow = false;
            this.modelScrollTextData.configShow = false;
            this.modelOneData.configShow = false;
            this.modelTwoData.configShow = false;
            this.modelThreeData.configShow = false;
        },
        divEditChange(newVal) {
            // this.homePage.scrollText = document.getElementById('scrollText').innerHTML;
        },
        getData() {
            this.defaultData=commonMeth.objDataAssign(this.homePageProp);
            this.defaultData.moreDetailShow = false;
            this.defaultData.colorDetailShow = false;
            var index = this.homePageProp.modelOne.lastIndexOf('.');
            var type = this.homePageProp.modelOne.substring(index + 1);
            type === 'mp4' ? this.defaultData.modelOneType = 'video' : this.defaultData.modelOneType = 'img';
        },
        cancelMeth() {
            this.dialogUpload.visible = false;
        },
        clearFileName() {
            this.modelLogoData.fileName = '';
            this.modelOneData.fileName = '';
            this.modelTwoData.fileName = '';
            this.modelThreeData.fileName = '';
        },
        templateMenuStyleStr() {
            var templateMenuStyle = this.global.materialStyle.templateMenuStyle;
            var val = this.templateIdProp;
            var styleStr = '';
            if (templateMenuStyle.focusBorder.indexOf(val) !== -1) {
                styleStr = 'focusBorder';
            }
            if (templateMenuStyle.focusBg.indexOf(val) !== -1) {
                styleStr = 'focusBg';
            }
            if (templateMenuStyle.focusBorderBg.indexOf(val) !== -1) {
                styleStr = 'focusBorderBg';
            }
            if (templateMenuStyle.focusBottomBorder.indexOf(val) !== -1) {
                styleStr = 'focusBottomBorder';
            }
            if (templateMenuStyle.focusNone.indexOf(val) !== -1) {
                styleStr = 'focusNone';
            }
             if(val == 87){
                    styleStr='TSbg';
                }
            this.templateMenuCurStr = styleStr;
        },
        objForEach() {
            var obj = this.global.materialStyle.templateMenuColor;
            var filterObj = {};
            Object.keys(obj).forEach((key) => {
                if (obj[key].id.indexOf(this.templateIdProp) !== -1) {
                    filterObj = obj[key];
                }
            });
            return filterObj;
        },
        editTabLoadingMeth(val,type){
            this.$emit('editTabLoadingMeth', val,type);
        },
        changeDialogVisible(val){
            this.dialogUpload.visible=val;
        },
        showShadeMeth(){
            if(!this.$attrs.onlyShowModule){
                this.shadeShow=true;
            }
        },
        focusTextBoxMeth(val){
            this.curFocusTextBox=val;
        },
        blurTextBoxMeth(){
            this.curFocusTextBox='';
        },
        mergeData(){
            var homeMerge={};
            var homePage=this.homePage;
            var defaultData=this.defaultData;
            homeMerge.scrollText=homePage.scrollText;
            homeMerge.coBack=homePage.coBack;
            homeMerge.moreDetailShow=homePage.moreDetailShow;
            homeMerge.colorDetailShow=homePage.colorDetailShow;
            homeMerge.modelLogo=homePage.modelLogo;
            homeMerge.modelOne=homePage.modelOne;
            homeMerge.modelOneType=homePage.modelOneType;
            homeMerge.homeWelWord=homePage.homeWelWord;
            homeMerge.homeHotelAddr=homePage.homeHotelAddr;
            homeMerge.homeHotelName=homePage.homeHotelName;
            homeMerge.homeTel=homePage.homeTel;
            return homeMerge;
        },
        inputMeth(type){
            var homeWelWord=this.homePage.homeWelWord;
            var homeHotelAddr=this.homePage.homeHotelAddr;
            var homeHotelName=this.homePage.homeHotelName;
            var homeTel=this.homePage.homeTel;
            if(type==='homeWelWord'){
                if(homeWelWord.length>8){
                    this.$message.warning('输入内容不能超过8个字符');
                    this.homePage.homeWelWord=homeWelWord.slice(0,8);
                }
            }else  if(type==='homeHotelAddr'){
                if(homeHotelAddr.length>8){
                    this.$message.warning('输入内容不能超过8个字符');
                    this.homePage.homeHotelAddr=homeHotelAddr.slice(0,8);
                }
            }else  if(type==='homeHotelName'){
                if(homeHotelName.length>8){
                    this.$message.warning('输入内容不能超过8个字符');
                    this.homePage.homeHotelName=homeHotelName.slice(0,8);
                }
            }else  if(type==='homeTel'){
                if(homeTel.length>12){
                    this.$message.warning('输入内容不能超过12个字符');
                    this.homePage.homeTel=homeTel.slice(0,12);
                }
            }

        }
    },
    mounted() {
        this.getData();
        this.headers.token = sessionStorage.getItem('token');
        this.defaultDataMeth();
        this.templateMenuStyleStr();
        setInterval(()=>{
            this.curTime=commonMeth.getCurrentDate();
        },1000);
        this.query=this.$route.query;
        this.defaultDataMeth();
    },
    computed: {
        coActiveClass() {
            return function (msg) {
                return this.homePage.coBack.trim() === msg.trim() ? 'active' : ''
            }
        },
        modelMoreActiveClass() {
            return function (modelType, materialType) {
                if (modelType === 'modelLogo') {
                    return this.modelLogoData.moreType === materialType ? 'active' : ''
                } else if (modelType === 'modelOne') {
                    return this.modelOneData.moreType === materialType ? 'active' : ''
                } else if (modelType === 'modelTwo') {
                    return this.modelTwoData.moreType === materialType ? 'active' : ''
                } else if (modelType === 'modelThree') {
                    return this.modelThreeData.moreType === materialType ? 'active' : ''
                }
            }
        },
        arrowDirectionClass() {
            return function (msg) {
                return this.homePage.coBack.trim() === msg.trim() ? 'active' : ''
            }
        },
        configBtnClass() {
            return function (msg) {
                // return this.modelShowBtnName === msg ? 'animate__fadeIn highIndex' : 'animate__fadeOut noneEl'
                return this.modelShowBtnName === msg ? 'animate__fadeIn highIndex' : 'animate__fadeOut'
            }
        },
        modelHighIndexClass() {
            return function (msg) {
                return this.modelShowBtnName === msg ? ' highIndex' : ' '
            }
        },
        shadeShowClass() {
            return this.shadeShow ? 'animate__fadeIn highIndex' : 'animate__fadeOut noneEl'
        },
        hotelTemplateTypeCom() {
            var flag = false;
            this.hotelTemplateType === 'ehotel'
                ? flag = false
                : flag = true;
            return flag;
        },
        templateMenuBoxStyleCom() {
            return function (color, index) {
                var str = {};
                var filterObj = this.objForEach();
                if (this.templateMenuCurStr === 'focusDoubleBorder' && index === 0) {
                    str = {'background': '#00173a', 'border': `2px solid ${color}`}
                }
                if (this.templateMenuCurStr === 'focusBorder' && index === 0) {
                    filterObj.borderBlueShadow
                        ? this.borderBlueShadow = true
                        : this.borderBlueShadow = false;
                    filterObj.borderDiff
                        ? str = {'border': `2px solid ${filterObj.borderColor}`}
                        : str = {'border': `2px solid ${filterObj.focusColor}`};
                }
                if (this.templateMenuCurStr === 'focusBg' && index === 0) {
                    str = {'background': `${filterObj.bgColor}`}
                }
                if (this.templateMenuCurStr === 'focusBorderBg' && index === 0) {
                    filterObj.borderBlueShadow
                        ? this.borderBlueShadow = true
                        : this.borderBlueShadow = false;
                    if (filterObj.blurBorderBg) {
                        this.blurBorderBg = true;
                        str = {'background': `${filterObj.bgColor}`, 'border': `2px solid ${filterObj.focusColor}`}
                    } else {
                        this.blurBorderBg = false;
                        str = {
                            'background': `${filterObj.bgColor}`,
                            'border': `2px solid ${filterObj.focusColor}`,
                            'border-radius': '5px'
                        }
                    }
                }
                if (this.templateMenuCurStr === 'focusBottomBorder' && index === 0) {
                    // str={'background':'#114aa1','border-bottom':`2px solid ${filterObj.focusColor}`}
                    str = {'background': `${filterObj.bgColor}`, 'border-bottom': `2px solid ${filterObj.focusColor}`}
                }
                if (this.templateMenuCurStr === 'focusNone' && index === 0) {
                    str = {}
                }
                 if(this.templateMenuCurStr==='TSbg' && activeNameProp===name){
                        str={'background':`#786379`}
                    }
                filterObj.iconBorder
                    ? this.iconBorder = true
                    : this.iconBorder = false;
                filterObj.smallMenuItem
                    ? this.smallMenuItem = true
                    : this.smallMenuItem = false;
                return str;
            }
        },
        templateMenuTextStyleCom() {
            return function (color, index) {
                var str = {};
                var filterObj = this.objForEach();
                index === 0
                    ? str = {'color': `${filterObj.focusColor}`}
                    : str = {'color': `${filterObj.blurColor}`};
                return str;
            }
        },
        templateMenuBoxClassCom() {
            return function (val) {
                var className = '';
                console.log(val);
                var templateIdProp = this.templateIdProp;
                if (templateIdProp === '47') {
                    className = 'blurBorderBg'
                } else if (
                    templateIdProp === '43' ||
                    templateIdProp === '45' ||
                    templateIdProp === '46' ||
                    templateIdProp === '48' ||
                    templateIdProp === '49' ||
                    templateIdProp === '56' ||
                    templateIdProp === '57' ||
                    templateIdProp === '63'
                ) {
                    className = 'defaultGrayGeneral'
                }
                return className;
            }

        },


    },
    watch: {
          hotelContract:{
                  handler: function (newVal) {
                          this.hotelContract=newVal
                       
                },
                deep: true,
                immediate: true, 
            },
           
             homePageProp:{
                 handler: function (newVal) {
                          this.homePageProp=newVal;
                            this.getData();
                            this.defaultDataMeth();
                            this.templateMenuStyleStr();
                },
                deep: true,
                immediate: true, 
            },
             menuProp:{
                 handler: function (newVal) {
                          this.menuProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             templateIdProp:{
                 handler: function (newVal) {
                          this.templateIdProp=newVal
                },
                deep: true,
                immediate: true, 
            },
          
             bgUrlProp:{
                 handler: function (newVal) {
                          this.bgUrlProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             onlyShow:{
                 handler: function (newVal) {
                          this.onlyShow=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelTemplateType:{
                 handler: function (newVal) {
                          this.hotelTemplateType=newVal
                },
                deep: true,
                immediate: true, 
            },
             modelReadOnly:{
                 handler: function (newVal) {
                          this.modelReadOnly=newVal
                          
                },
                deep: true,
                immediate: true, 
            },
             activeNameProp:{
                 handler: function (newVal) {
                          this.activeNameProp=newVal
                },
                deep: true,
                immediate: true, 
            },
           
        modelShowBtnName(newVal) {
            if (newVal !== 'modelLogo') {
                this.modelLogoData.configShow = false;
            }
            if (newVal !== 'modelOne') {
                this.modelOneData.configShow = false;
            }
            if (newVal !== 'scrollText') {
                this.modelScrollTextData.configShow = false;
            }
            if (newVal !== 'modelTwo') {
                this.modelTwoData.configShow = false;
            }
            if (newVal !== 'modelThree') {
                this.modelThreeData.configShow = false;
            }
        },
        'dialogUpload.visible'(newVal) {
            // newVal ? '' : this.resetDialog();
        },
        homePageProp: {
            handler: function (newVal) {
                // this.tabShow = true;
                // this.getTemplateWelTextModelCur();
                // this.getTemplateHomeTextModelCur();
                // this.modelIsReadOnly();
            },
            deep: true
        }
    }
}
</script>
<style scoped lang="scss">
    .home_container {
        width: 1152px;
        height: 648px;
        padding: 55px 100px;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        box-sizing: border-box;
       
    }
    .home_top {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .home_logo {
        height:95px;
        width: 100%;
        /*background:#ffcdd2;*/
        position: relative;
        /*box-sizing: content-box;*/
        /*border:2px dashed transparent;*/
        /*transition:0.5s;*/
        border: 2px dashed rgba(28, 144, 238, 0);
        transition: 0.5s;
        display:flex;
        justify-content: center;
        box-sizing: border-box;
    }
    .home_logo.active {
        /*border:2px dashed #1c90ee;*/
        border: 2px dashed rgba(28, 144, 238, 1);
        transition: 0.5s;
    }
    .home_logo > img {
        width: auto;
        height: 100%;
    }
    .home_logo_group > div {
        float: left;
    }
    .home_scroll_box {
        /*width:36.6%!important;*/
        width: 380px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed rgba(28, 144, 238, 0);
        transition: 0.5s;
        padding: 0 10px;
    }
    .home_scroll_box.active {
        border: 2px dashed rgba(28, 144, 238, 1);
        transition: 0.5s;
    }
    .scroll_box {
        /*width:*/
    }
    .home_scroll_text {
        width: 100%;
        border-radius: 30px;
        color: #fff;
        padding: 0 20px;
        display: flex;
        align-items: center;
        position: relative;
    }
    .home_scroll_text.coBlack {
        background: rgba(250, 250, 250, 0.05);
    }
    .home_scroll_text.coTrans {
        background: none;
    }
    .txt {
        color: #fafafd !important;
        font-weight: 400 !important;
        outline: none;
        font-size: 14px;
        overflow: auto;
        padding: 5px 0;
    }
    .txt-clone {
        font-weight: 400 !important;
    }
    .home_scroll_text > div {
        /*width:100%;*/
        white-space: nowrap;
        /*overflow:hidden;*/
        /*text-overflow: ellipsis;*/
    }
    .txt-scroll {
        width: 100%;
        background: none !important;
    }
    .city_info {
        display: flex;
        height: 45px;
        /*background:#80cbc4;*/
    }
    .city_info div {
        color: #fff;
        letter-spacing: 1px;
    }
    .info_one {
        height: 100%;
        border-right: 2px solid #fff;
        /*background:#81d4fa;*/
        padding-right: 7px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_one .top {
        /*font-weight:600;*/
        font-size: 22px;
    }
    .info_one .bottom {
        font-size: 14px;
    }
    .info_two {
        padding-left: 7px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_two div {
        font-size: 14px;
    }
    /*中间主页导航*/
    .home_middle {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    .home_middle .menuItem.smallMenuItem {
        width: 105px !important;
    }
    .home_middle .menuItem {
        width: 130px;
        box-sizing: border-box;
        color: #fff;
        text-align: center;
        padding: 10px 0;
        /*padding:10px 20px;*/
        font-size: 16px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        :last-child {
            margin-right: 0;
        }

        .icon {
            width: 40px;
            height: auto;
        }

        .icon.iconBorder {
            width: 55px !important;
            height: auto;
        }

        .text {
            margin-top: 8px;
        }
    }
    /*失去焦点，有边框有背景色*/
    .menuItemBox.blurBorderBg {
        .menuItem {
            background: #fff;
            border: 2px solid #91afb2 !important;
        }

        .menuItem.active {
            background: #2aafc2;
        }
    }
    /*获得焦点，outLightBorder 外发光border*/
    .menuItemBox.defaultGrayGeneral {
        .menuItem.active {
            border: 1px solid #fff;
            box-shadow: 0 0 15px #a2794e;
        }
    }
    .menuItem.active.borderBlueShadow {
        box-shadow: 0 0 15px #042fb8;
    }
    /*动态样式开始*/
    .home_middle.focusBorder .menuItem.active {
        border-radius: 5px;
        color: #fff;
        transition: 0.5s;
        border: 2px solid #a2794e;
    }
    .focusBottomBorder.focusBottomBorder .menuItem.active {
        /*background:#114aa1;*/
        /*border-bottom:2px solid #fcac45;*/
    }
    .menuItemBox {
        display: flex;
    }
    /*动态样式结束*/
    .home_bottom {
        width: 100%;
        height: 430px;
        display: flex;
        justify-content: space-between;

    }
    .bottom_left {
        width:70%;
        position:relative;
    }
    .bottom_left > video {

        object-fit: cover;
    }
    .bottom_right {
        width: 26%;
        position:relative;
    }
    .bottom_right > .top {
        height: 140px;

    }
    .rightTextBox{
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        display:flex;
        flex-direction: column;
        .time{
            color:#fff!important;
            font-size:28px;
            margin-top: 25px;
        }

        .homeWelWord {
            font-weight: 100;
            font-size: 22px;
            margin-bottom:10px;
        }
        .homeHotelAddr {
            font-size: 18px;
        }
        .homeHotelName {
            font-size: 20px;
        }
        .homeTel {
            margin-top:10px;
            font-size: 20px;
        }
    }
    .formBox{
        flex:1;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
      
    }
    /*logo配置工具栏*/
    .logo_config_box {
        position: absolute;
        bottom: -35px;
    }
    /*主页右上配置*/
    .home_top_config_box {
        position: absolute;
        top: 0;
        right: -46px;
        /*right:-46px;*/
    }
    .home_bottom_config_box {
        position: absolute;
        bottom: 0;
        right: -46px;
    }
    /*滚动字幕的confi_box*/
    .home_scroll_config_box {
        position: absolute;
        bottom: 0;
        right: -46px;
    }
    .video_config_box {
        position: absolute;
        bottom: -33px;
        left: 0;
    }
    /*配置开始*/
    .configBox {
        display: flex;
    }
    .configBox.modelOne {
        position: absolute;
        left: 0;
        bottom: -45px;
    }
    .configBox.logo{
        position: absolute;
       left: 0px;
        top: -50px;
    }
    .configBox.scrollText {
        position: absolute;
        /*right:-400px;*/
        left: 403px;
        bottom: 0;
    }
    .configBox.modelTwo {
        position: absolute;
        right: -90px;
        top: 0;
    }
    .configBox.modelThree {
        position: absolute;
        right: -90px;
        top: 0;
    }
    .configBox .btnItem {
        white-space: nowrap;
        font-weight: 500;
        font-size: 14px;
        padding: 12px 20px;
        display: inline-block;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8) !important;
        cursor: pointer;
    }
    .configBtn {
        display: none;
    }
    .configBtn:hover {
        background: #1c90ee !important;
        transition: 0.5s;
    }
    .detail_box {
        /*background:#252527;*/
        background-color: rgba(0, 0, 0, 0.8) !important;
        padding: 5px 0;
    }
    .detail_box.up {
        position: absolute;
        /*left:10px;*/
        bottom: 40px;
    }
    .detail_box.down {
        position: absolute;
        top: 40px;
    }
    .detail_box .item {
        height: 36px;
        color: #fff;
        padding: 0 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        transition: 0.5s;
    }
    .slideBox {
        /*display:none;*/
        /*overflow:hidden;*/
        transition: 2s;
    }
    .btnGroup {
        display: flex;
        margin-right: 10px;
    }
    .btnGroupItem {
        display: inline-block;
        position: relative;
        z-index: 9999;
    }
    .configMarginRight {
        margin-right: 10px;
    }
    .configMarginLeft {
        margin-left: 10px;
    }
    .highIndex {
        z-index: 100;
    }
    .color_match > .item, .more_file > .item {
        width: 100%;
        height: 36px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        box-sizing: border-box;
    }
    .color_match > .item.active {
        background: #1b90ed;
    }
    .item.active .check_icon {
        visibility: visible;
    }
    .item .check_icon {
        visibility: hidden;
    }
    .color_match > .item > .check_icon {
        width: 18px;
        height: 20px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .color_match > .item > .show_icon {
        width: 44px;
        height: 24px;
        margin-right: 10px;
        display: inline-block;
    }
    .noWarp {
        white-space: nowrap;
    }
    img, video {
        width: 100%;
        height: 100%;
        outline: none !important;
    }
    .arrowIcon.down {
        transform: rotate(180deg);
    }
    .noneOutLine {

    }
    /*配置结束*/
    .borderBox {
        border: 2px dashed transparent;
        transition: 0.5s;
        cursor: pointer;
    }
    .borderBox:hover {
        border: 2px dashed #1b90ed;
        transition: 0.5s
    }
    .shade {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        cursor: pointer;
        transition: 0.5s;
    }
    .noneEl {
        visibility: hidden;
    }
    .textCenter{
        text-align: center;
    }
    .date-week {
        margin-top: 15px;
        color:#fff;
        font-size:16px;
    }
    #date, #week {
        color: white;
        font-size: 25px;

    }
  .home_middle.blueBg{
        background: #0C5DC6;
    }
</style>
