<template>
  <div class="test" style="height:100%;position:relative;margin-top: 15px">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入房间号" class="input-with-select fullSelect" v-model="topAttr.query" :clearable="true">
          <el-button slot="append" icon="el-icon-search" @click="clickFilter"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="vertical rowBox showDataBox" style="margin-top:20px;">
      <index-four-row :type="type">
        <span slot="numTitle">酒店房间数</span>
        <span slot="num">{{showDataCom(bottomNum.input)}}</span>
        <span slot="unit">间</span>
      </index-four-row>
    </el-row>

    <div class="roomBox">
      <el-col :span="4" v-for="(item,index) in searchList" :key="index" class="roomItemBox">
        <div class="roomItem" :class="['online','noPic']">{{item}}
          <div class='brushBox'>
            <img src="../../../assets/img/selfBrush.png" alt="">
          </div>
        </div>
      </el-col>
      <el-col :span="2" class="roomItemBox">
        <!--<div class="roomItem" :class="[item.state==1?'online':'offline',item.pic_state==0?'noPic':'']">{{item.room}}-->
        <!--<div class="roomItem online" >ww-->
        <!--<div class='brushBox'>-->
        <!--<img src="../../../assets/img/selfBrush.png" alt="" >-->
        <!--</div>-->
        <!--</div>-->
      </el-col>
    </div>
    <el-dialog title="查看凭证" :visible.sync="dialog.dialogTableVisible" width="600px" :modal-append-to-body="false">
      <div class="showPicBox">
        <div class="title">刷机人：{{dialog.brushName}}</div>
        <div class="middle">
          <div class="picBox" v-if="dialog.pic_state===0">
            <div class="inner">
              <i class="el-icon-picture"></i>
              <div class="text">暂无凭证</div>
            </div>
          </div>
          <img :src="dialog.pic_url" alt="" v-if="dialog.pic_state===1">
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import qs from "querystring";

export default {
  name: "Online",
  props: ["id", "showData", "hotel_contract"],
  data: () => ({
    type: "timeCenter",
    topAttr: {
      terminal: "",
      picState: [
        { label: "已上传", value: "1" },
        { label: "未上传", value: "0" },
      ],
      onlineState: [
        { label: "在线", value: "1" },
        { label: "离线", value: "0" },
      ],
      selfBrush: [
        { label: "自刷", value: "1" },
        { label: "他刷", value: "0" },
      ],
      query: "",
      state: "",
      pic: "",
      brush: "",
    },
    roomList: [
      {
        room: "1001",
        state: "1001",
        flag: "",
        pic_state: "",
        pic_url: "",
        name: "",
      },
      // {label:'1001',value:'1001'},
    ],
    searchList: [],
    bottomNum: {
      input: null, //酒店房间数
      connected: null, //激活房间数
      online: null, //在线房间数
      picNum: null, //上传凭证房间数
    },
    query: {
      fromUrl: "",
      id: "", //酒店id
      type: "check",
    },
    clickActive: "", //选中搞得
    dialog: {
      dialogTableVisible: false,
      pic_state: 0,
      pic_url: "",
      brushName: "brushName",
      room: "",
    },
    token: "",
  }),
  methods: {
    getRoom() {
      this.$http
        .get(this.global.getVirtualRooms, {
          params: {
            token: this.token,
            hotel_contract: this.hotel_contract,
            // id:5268
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var roomData = res.data.data;
            this.roomList = roomData.list;
            this.searchList = roomData.list;
            this.bottomNum.input = roomData.count; //酒店房间数
          }
        });
    },

    clickFilter(val, type) {
      this.$http
        .get(this.global.getVirtualRooms, {
          params: {
            token: this.token,
            hotel_contract: this.hotel_contract,
            room_number: this.topAttr.query,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.searchList = res.data.data.list;
          }
        });
    },
    showPic(room, pic_state, pic_url, brushName, flag) {
      this.dialog.room = room;
      this.dialog.pic_state = pic_state;
      this.dialog.pic_url = pic_url;
      this.dialog.brushName = brushName;
      this.dialog.dialogTableVisible = true;
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    var query = this.$route.query;
    this.query.id = this.id; //酒店id
    this.query.type = query.type; //酒店id
    this.getRoom();
    if (this.query.type === "edit") {
    }
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
  },
  watch: {
    "dialog.dialogTableVisible"(newVal) {
      if (!newVal) {
        this.dialog.room = "";
        this.dialog.pic_state = "";
        this.dialog.pic_url = "";
        this.dialog.brushName = "";
      }
    },
    hotel_contract(val) {
      this.hotel_contract = val;
      this.getRoom();
    },
  },
};
</script>

<style scoped lang="scss">
.roomBox {
  overflow: hidden;
  padding-bottom: 90px;
}
.roomBox .el-col {
  padding: 10px 20px 10px 0;
}
.roomItem {
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

.numBox {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 20px;
  background: #fff;
  .el-col {
    padding: 30px 0;
  }
}

/*图例*/
.legendTop {
  align-items: center;
}
.legendTop,
.legendTop > .left,
.legendTop > .left > .item {
  display: flex;
}
.legendTop > .right {
  display: flex;
  flex: 1;
  justify-content: space-around;
}
.legendTop {
  padding: 20px 0;
  display: flex;
}

.legendTop .item {
  margin-right: 26px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 8px;
  padding: 10px 6px;
}
.legendTop .item.active {
  background: #1199ff;
  color: white;
}

.colorShow {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 50%;
}

.colorShow.online {
  background: #78bf2f;
}

.colorShow.offline {
  border: 1px solid #eae8e9;
  background: #f6f4f5;
  box-sizing: border-box;
}
.colorShow.noPic {
  border: 1px solid #eae8e9;
  background: #ffcb4f;
  box-sizing: border-box;
}

.colorShow.selfBrush {
  background: #f6f4f5;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.roomItemBox {
  font-size: 14px;
  position: relative;
}
.brushBox {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: -14px;
  right: -14px;
}
.brushBox img {
  width: 100%;
  height: 100%;
}
.roomBox .roomItem.online {
  background: #78bf31;
  color: #fff;
}
.roomBox .roomItem.offline {
  background: #f6f4f5;
  color: #333;
}
.roomBox .roomItem.noPic {
  border-left: 4px solid #ffb400;
}

/* 凭证弹窗开始 */
.showPicBox {
  width: 100%;
  background: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  transition: 0.3s;
}
.showPicBox .title {
  width: 100%;
  text-align: left;
  transition: 0.3s;
  padding-bottom: 30px;
  box-sizing: border-box;
  font-size: 16px;
}

.middle {
  width: 100%;
  height: 300px;
  /*padding:0 40px;*/
  box-sizing: border-box;
}
.middle img {
  width: 100%;
  height: 100%;
}
.picBox {
  width: 100%;
  height: 100%;
  margin: 0 auto 30px auto;
  position: relative;
  transition: 0.3s;
  border-radius: 7px;
  box-sizing: border-box;
  background: #e7f5ff;
}

.picBox .inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inner i {
  font-size: 116px;
  margin-bottom: 30px;
  color: #afcade;
}
.inner .text {
  font-size: 18px;
  color: #afcade;
}
.showDataBox {
  margin: 20px 0 10px 0;
}
.fullSelect {
  width: 100%;
}

/* 凭证弹窗结束 */
</style>
