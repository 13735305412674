<template>
  <div class="box" :style="{width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',right:style.right+'px',fontSize:style.fontSize+'px',color:style.color?style.color:'#fff',}">
    <span>{{style.text}}</span><span class="red" v-if="style.redtext">{{style.redtext}}</span>
  </div>
</template>
<script>
export default {
  name: "textShow",
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        let obj = JSON.parse(JSON.stringify(newVal));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      text: "99999999999",
    };
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  z-index: 4;
  // background: black;
}
.red {
  color: red;
}
</style>