<template>
    <div>
        <el-row :gutter="20" class="rowBox">
            <el-col :span="15">
                <div  class="bg-purple grid-content">
                    <el-form  label-width="auto" class="form" :model="form" ref="form" :rules="rules">
                        <el-row>
                            <el-col :span="4" v-for="(item,index) in selectData.materialType" :key="index">
                                <el-radio v-model="radio" :label="item.id" @change="radioChange">{{item.name}}</el-radio>
                            </el-col>
                            <!--<el-col :span="4">-->
                                <!--<el-radio v-model="radio" label="main">主界面</el-radio>-->
                            <!--</el-col>-->
                          <!---->

                        </el-row>
                        <!--<div class="tableOutBox">-->
                            <table-list :table="table" @tableMeth="tableMeth"  @tableSelect="getTableSelect" v-loading="table.loading"></table-list>
                        <!--</div>-->
                        <el-form-item label="新建刊位名称" prop="name">
                            <el-input v-model="form.name"></el-input>
                            <el-button type="primary" @click="submitForm('form')" class="submitBtn">保存</el-button>
                        </el-form-item>

                    </el-form>

                </div>
            </el-col>
            <el-col :span="9">
                <div class="bg-purple grid-content">
                    <div class="imgTitle">刊位样式示意</div>
                    <div class="imgBox">
                        <img src="../../../assets/img/109.jpg" alt="">
                    </div>
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "Relation",
        data: () => ({
            table:{
                tableHeader:[
                    {prop:'name',label:'刊位名称',width:'230'},
                    {prop:'playing_time',label:'播放时长（s）',width:''},
                    {prop:'size',label:'规格尺寸',width:''},
                    {prop:'resolution',label:'稿件分辨率',width:''},
                    {prop:'media_format',label:'格式',width:''},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'adManCus',
                option:false,
                pageShow:false,
                select:true,
                radio:true,
            },
            radio:1,
            form:{
                name:''
            },
            rules:{
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],

            },
            operateIds:'',
            test:'',
            selectData:{
                materialType:[]
            },
            token:''
        }),
        methods:{
            tableMeth(val){
                this.$http.get(this.global.getAllHtripCase,{
                    params:{
                        token:this.token,
                        material_type:val
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData;
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getTableSelect(val){
                if(this.table.radio){
                    this.operateIds=val.id;
                }else{
                    var operateIds='';
                    val.forEach((item,index)=>{
                        operateIds+=item.id+','
                    });
                    this.operateIds=operateIds.slice(0,-1);
                }

            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.operateIds===''){
                            this.$message.warning('请选择刊位');
                            return false;
                        }else{
                            this.$confirm('确定提交?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.submitMeth();
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitMeth(){
                var param=qs.stringify({
                    token:this.token,
                    type:'hl',
                    name:this.form.name,
                    hl_id:this.operateIds,
                });
                this.$http.post(this.global.caseCreate,param).then(res=>{
                    console.log('创建',res);
                    var str='';
                    if(res.data.code===100000){
                        this.$message.success('保存成功');
                        this.tableMeth();
                        this.$refs['form'].resetFields();
                    }else{
                        this.$message.error('保存失败');
                    }
                }).catch(()=>{
                    this.$message('保存失败','error');
                })
            },
            getMaterialType(){
                this.$http.get(this.global.getCaseMaterialType,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.materialType=res.data.data;
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                }).catch(()=>{

                })
            },
            radioChange(val){
                this.tableMeth(val);
            }

        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.tableMeth(this.radio);
            this.getMaterialType();
        }
    }
</script>

<style scoped lang="scss">
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        /*background: #99a9bf;*/
    }
    .bg-purple {
        /*background: #d3dce6;*/
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .form{
        margin-top:20px;
    }
    .imgTitle{
        width:100%;
        text-align: center;
        padding:0 0 30px 0;
    }
    .imgBox{
        width:100%;
        height:400px;
        img{
            width:100%;
            height:100%;
        }
    }
    .tableOutBox{
        height:500px;
        overflow:auto;
        padding-bottom:15px;
    }
    .submitBtn{
        margin-left:20px;
    }
</style>
