import request from './request';
import qs from "querystring";
//  获取酒店刊位列表
export function getJournalPositionByHotelContract(param) {
    return request({
        url: `v1/hotel/getJournalPositionByHotelContract`,
        method: 'post',
        data: param,
    });
}
//  
//  酒店刊位页面 根据合同号关闭刊位
export function updateJournalPositionByHotelContract(param) {
    return request({
        url: `v1/hotel/updateJournalPositionByHotelContract`,
        method: 'post',
        data: param,
    });
}
// 获取渠道商自建广告位列表
export function getAdvertisingListByChannelCode(param) {
    return request({
        url: `v1/hotel/advertisingListByChannelCode`,
        method: 'post',
        data: param,
    });
}
// 删除渠道商自建广告位
export function delAdvPosition(param) {
    return request({
        url: `v1/hotel/stateUpdateAdvPosition`,
        method: 'post',
        data: param,
    });
}
// 广告位开关
export function operateAdvPosition(param) {
    return request({
        url: `v1/hotel/operateAdvPosition`,
        method: 'post',
        data: param,
    });
}
// 新建或者编辑渠道商自建广告位
export function createOrEditAdvertising(param) {
    return request({
        url: `v1/hotel/createAdvertisingPositionSelf`,
        method: 'post',
        data: param,
    });
}

// 获取携旅标准广告刊位
export function getStandardAdvPosList(param) {
    return request({
        url: `v1/hotel/standardAdvPosList`,
        method: 'post',
        data: param,
    });
}
// 获取携旅标准分辨率列表
export function getStandardPosResolutionList(param) {
    return request({
        url: `v1/hotel/standardPosResolutionList`,
        method: 'post',
        data: param,
    });
}
// 获取携旅标准投放方式
export function getstandardTypeList(param) {
    return request({
        url: `v1/hotel/standardTypeList`,
        method: 'post',
        data: param,
    });
}
// 获取携旅标准投放方式
export function passCheckAdAdFrequency(param) {
    return request({
        url: `v1/order/checkAdAdFrequency`,
        method: 'post',
        data: param,
    });
}
//  查询广告刊期列表
export function getreviewList(param) {
    return request({
        url: `v1/order/reviewList?` + param,
        method: 'get',

    });
}
//  获取广告刊位下拉列表
export function getReviewAdvList(param) {
    return request({
        url: `v1/order/getReviewAdvList?` + param,
        method: 'get',

    });
}
//  获取广告分组下拉列表
export function getLabelAdv(param) {
    return request({
        url: `v2/Labeladv/getLabelAdv`,
        method: 'post',
        data: qs.stringify(param)
    });
}
// 广告标签审核审核
export function advAction(param) {
    return request({
        url: `v2/Labeladv/advAction`,
        method: 'post',
        data: qs.stringify(param)
    });
}