<template>
  <div>
    <el-form :model="form" :rules="rules" class="tabContent" ref="form" style="margin-top: 10px" v-loading="formLoading">
      <el-col :span="12">
        <el-form-item prop="groupId" label="门店品牌" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.groupId" placeholder="请选择" class="selectItem" filterable @change="groupChange">
            <el-option v-for="item in selectData.hotelGroupList" :key="item.id" :label="item.groupname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
           <el-col :span="12" style="display:flex;">
        <!--<el-form-item prop="province" label="* 所属地区" label-width="200px"></el-form-item>-->
        <el-form-item prop="provinceLabel" label="所属地区" label-width="200px"></el-form-item>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="provinceLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect" placeholder="省" v-model="form.provinceShow" @change="provinceChange" v-if="aysnOption.provFlag" filterable>
              <el-option v-for="item in selectData.province" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="cityLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect tenMarginLeft" placeholder="市" v-model="form.cityShow" @change="cityChange">
              <el-option v-for="item in selectData.city" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="countryLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect tenMarginLeft" placeholder="区" v-model="form.countryShow" @change="countryChange">
              <el-option v-for="item in selectData.country" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-col>
      <!-- <el-col :span="12">
        <el-form-item label="集团门店ID" label-width="200px" prop="hotel_store_id">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_store_id"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="12">
        <el-form-item prop="hotel" label="门店名称" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel"></el-input>
        </el-form-item>
      </el-col>
        <el-col :span="12">
        <el-form-item prop="address" label="门店地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.address"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12">
        <el-form-item label="终端门店编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.contract_365"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="12">
        <el-form-item prop="hotel_level" label="门店级别" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.hotel_level" placeholder="请选择" class="selectItem" filterable>
            <el-option v-for="item in selectData.hotel_level_select" :key="item.code_value" :label="item.code_label" :value="item.code_value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_quality" label="门店性质" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.hotel_quality" placeholder="请选择" class="selectItem" filterable>
            <el-option v-for="item in selectData.hotel_quality_select" :key="item.code_value" :label="item.code_label" :value="item.code_value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_room" label="房间数量" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_room" name="hotel_room" type="number"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12">
        <el-form-item prop="total_num" label="应上线终端数量" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.total_num" name="total_num" type="number"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="12">
        <el-form-item prop="h_ota_price" label="最低房价" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.h_ota_price" name="h_ota_price" type="number"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="juping_type" label="合作类型" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.juping_type" placeholder="请选择" class="selectItem" filterable>
            <el-option v-for="item in selectData.juping_type_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
 
      <!-- <el-col :span="12" class="twoRow">
        <div class="relativeEl">
          <el-form-item prop="baidu_address" label="百度地图终端门店地址" label-width="200px">
            <el-input :disabled="hotel_is_children" v-model="form.baidu_address">
              <i slot="suffix" class="el-input__icon el-icon-search pointer " @click="searchMeth"></i>
            </el-input>
          </el-form-item>
          <div class="searchList">
            <div class="searchListBox" v-if="searchListBoxShow">
              <div class="searchListItem" :key="index" v-for="(item,index) in selectData.addressSearchList" @click="getCoordinate(item.uid,item.adcode,item.province,item.city,item.district)">{{item.name}}</div>
            </div>
          </div>
        </div>
        <!--<div class="coordinateBox">-->
        <!--<el-form-item prop="province" label="" label-width="200px"></el-form-item>-->
        <!--<el-form-item class="coordinate">-->
        <!--<el-col :span="24">-->
        <!--<el-input v-model="map.center.lat" class="selectItem"></el-input>-->
        <!--</el-col>-->
        <!--</el-form-item>-->
        <!--<el-form-item class="coordinate">-->
        <!--<el-col :span="24">-->
        <!--<el-input  v-model="map.center.lng" class="selectItem"></el-input>-->
        <!--</el-col>-->
        <!--</el-form-item>-->
        <!--</div>-->
      <!-- </el-col> --> 
    
      <!-- <el-col :span="12" style="display:flex;">
        <el-form-item prop="occupyRules" label="百度地图终端门店名称" label-width="200px"></el-form-item>
        <el-col :span="8" class="relativeEl">
          <el-form-item prop="baidu_hotel_name" style="position:relative">
            <el-input :disabled="hotel_is_children" v-model="form.baidu_hotel_name" :readonly="true" placeholder="该内容自动读取"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input :disabled="hotel_is_children" v-model="map.center.lat" class="selectItem tenMarginLeft"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input :disabled="hotel_is_children" v-model="map.center.lng" class="selectItem tenMarginLeft"></el-input>
          </el-form-item>
        </el-col>
     
      </el-col> -->
      <!-- <el-col :span="24" v-if="edit">
        <el-form-item prop="maintain_name" label="终端信息" label-width="200px">
          <el-button type="primary" @click="showTerInfo">查看</el-button>
        </el-form-item>
      </el-col> -->
      <el-col :span="24" v-if="terInfoShow" :key="index" v-for="(item,index) in terInfo">
        <el-form-item prop="maintain_name" :label="index===0?'终端信息':''" label-width="200px">
          <el-row :gutter="20" class="terInfoRow">
            <el-col :span="6">
              <div class="terInfoItem">
                终端品牌：{{item.terBrandLabel}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端类型：{{item.terTypeVal}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端型号：{{item.terModelLabel}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端数量：{{item.terNumVal}}
              </div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <div style='clear:both'></div>
      <el-col :span="24" v-show="!hotel_is_children">
        <el-form-item label="" label-width="200px">
          <el-button class="largeBtn rightBtn" type="primary" @click="submitForm('form')">
            提交
          </el-button>
        </el-form-item>
      </el-col>
      <!--<el-button class="largeBtn" type="primary" @click="submitForm('form')" :disabled="false">提交</el-button>-->
      <!--<el-button type="primary" @click="toTop"  style="position:fixed;bottom:200px;right:100px;" >回到顶部</el-button>-->
    </el-form>
  </div>

</template>

<script>
import qs from "querystring";
export default {
  name: "OperateTableTab",
  props: ["id", "edit", "hotelName", "is_children"],
  data() {
    var checkNumber = (rule, value, callback) => {
      var a = "";
      if (rule.field == "hotel_room") {
        a = "客房总量";
      } else if (rule.field == "total_num") {
        a = "应上线终端数量";
      }
      if (value < 0) {
        return callback(new Error(a + "不能小于0"));
      } else if (value == 0) {
        callback();
      } else if (!Number.isInteger(Number(value))) {
        return callback(new Error(a + "为整数"));
      } else {
        callback();
      }
    };
    return {
      hotelId: "",
      selectData: {
        province: [],
        city: [],
        //签约情况

        otaOption: [
          { id: 1, name: "150一下" },
          { id: 2, name: "150-300" },
          { id: 3, name: "301-450" },
          { id: 4, name: "451-500" },
          { id: 5, name: "600以上" },
        ],

        //终端型号
        hotel_level_select: [],
        hotel_quality_select: [],
        juping_type_select: [
          { id: 1, name: "广告" },
          { id: 2, name: "影视" },
          { id: 3, name: "广告和影视" },
          { id: 4, name: "独立影视" },
        ],
        addressSearchList: [],
      },
      form: {
        hotel_brand: "",
        
        groupId: "1056",
        hotel_store_id: "",
        hotel: "",
        contract_365: "",
        hotel_level: "",
        hotel_quality: "",
        hotel_room: "",
        total_num: "",
        h_ota_price: "",
        juping_type: 2,
        citycode: "",
        provinceShow: "",
        provinceLabel: "",
        cityShow: "",
        cityLabel: "",
        countryShow: "",
        countryLabel: "",
        address: "",
        lat: "39.915",
        lng: "116.40",
        baidu_address: "",
        occupyRules: "",
        baidu_hotel_name: "",
        baidu_uid: "",
      },
      rules: {
        groupId: [
          { required: true, message: "请输入集团品牌", trigger: "blur" },
        ],
        hotel: [
          { required: true, message: "请输入终端门店名称", trigger: "blur" },
        ],
        contract_365: [
          { required: true, message: "请输入终端门店编码", trigger: "blur" },
        ],

        hotel_level: [
          { required: true, message: "请选择终端门店级别", trigger: "blur" },
        ],
        hotel_quality: [
          { required: true, message: "请选择终端门店性质", trigger: "blur" },
        ],
        hotel_room: [
          { required: true, message: "请输入客房总量", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        total_num: [
          { required: true, message: "应上线终端数量", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        h_ota_price: [
          { required: true, message: "请输入OTA房价", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "OTA房价中不能有空格",
            trigger: "blur",
          },
          {
            pattern: /^((?!0)\d+(\.\d{1,2})?)$/g,
            message: "OTA房价不能小于0",
            trigger: "blur",
          },
        ],
        juping_type: [
          { required: true, message: "请选择聚屏合作类型", trigger: "blur" },
        ],
        hotel_store_id: [
          {
            pattern: /^[^ ]+$/,
            message: "集团门店ID中不能有空格",
            trigger: "blur",
          },
        ],
        provinceLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        cityLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        countryLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        baidu_address: [
          {
            required: true,
            message: "请输入百度地图终端门店地址",
            trigger: "blur",
          },
        ],
        occupyRules: [{ required: true, message: "", trigger: "blur" }],
        baidu_hotel_name: [
          {
            required: true,
            message: "请输入百度地图终端门店名称",
            trigger: "blur",
          },
        ],
      },
      map: {
        center: { lng: 116.404, lat: 39.915 },
        zoom: 3,
      },
      region: {
        prov_code: "",
        city_code: "",
        country: "",
      },
      aysnOption: {
        provFlag: null,
        cityFlag: null,
        countryFlag: null,
      },
      token: "",
      auth_type: "",
      query: {},
      terInfo: [],
      terInfoBtn: false,
      terInfoShow: false,
      selectedHotel: {
        type: "",
        id: "",
        label: "",
      },
      searchListBoxShow: false,
      formLoading: false,
      hotel_is_children: false,
    };
  },
  watch: {
    id(newVal) {
      this.hotelId = newVal;
      //   this.getDetail();
    },
    is_children(newVal) {
      this.is_children = newVal;
      this.hotel_is_children = newVal;
    },
    hotelName(newVal) {
     
      if (this.hotelProgram) {
        this.getDetail(1);
      }
       this.hotelProgram = newVal;
    },
  },
  methods: {
    groupChange(val) {
      console.log(val);
      this.form.brand_name = val
        ? this.selectData.hotelGroupList.find((ele) => ele.id === val).groupname
        : "";
             console.log( this.form.brand_name);
    },
    getProvince() {
      this.$http
        .get(this.global.getProvince, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.province = res.data.data;
            this.aysnOption.provFlag = true;
          } else {
            this.$message.error("获取省份失败");
          }
        });
    },
    getCity(prov_code) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              // prov_code:this.region.prov_code
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.selectData.city = res.data.data;
              this.aysnOption.cityFlag = true;
              return resolve();
            }
          });
      });
    },
    getCountry() {
      var param = qs.stringify({
        token: this.token,
        prov_code: this.region.prov_code,
        city_code: this.region.city_code,
      });
      this.$http
        .get(this.global.getDistricts, {
          params: {
            token: this.token,
            prov_code: this.region.prov_code,
            city_code: this.region.city_code,
          },
        })
        .then((res) => {
          console.log("区", res);
          if (res.data.code === 100000) {
            this.selectData.country = res.data.data;
            this.$forceUpdate();
            console.log("获取到区县", res.data.data);
            console.log("selectData-country", this.selectData.country);
          }
        });
    },
    getOption() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getOptions, param).then((res) => {
        if (res.data.code === 100000) {
          this.selectData.hotelGroupList = res.data.data.hotelGroupList;
          this.selectData.hotelTemplateList = res.data.data.hotelTemplateList;
          this.selectData.hotelUseState = res.data.data.hotelUseState;
          this.selectData.networkCondition = res.data.data.networkCondition;
          this.selectData.networkMode = res.data.data.networkMode;
          this.selectData.operatingState = res.data.data.operatingState;
          this.selectData.starLevel = res.data.data.starLevel;
          this.selectData.supplierId = res.data.data.supplierId;
          this.selectData.terminalCustomerFrom =
            res.data.data.terminalCustomerFrom;
          this.selectData.terminalStoreType = res.data.data.terminalStoreType;
          this.selectData.terminalType = res.data.data.terminalType;
          this.getProvince();
          this.getHotelTemplateId("hihotel");
        } else {
          this.$message.error("获取option失败");
        }
      });
    },
    provinceChange(val) {
      this.region.prov_code = val.value;
      this.region.city_code = "";
      this.region.country = "";
      this.form.provinceLabel = val.label;
      this.form.cityShow = "";
      this.form.cityLabel = "";
      this.form.countryShow = "";
      this.form.countryLabel = "";
      this.selectData.country = [];
      this.getCity(val.value);
    },
    cityChange(val) {
      this.region.city_code = val.value;
      this.region.country = "";
      this.form.cityLabel = val.label;
      this.form.countryShow = "";
      this.form.countryLabel = "";
      this.getCountry(val.value);
    },
    countryChange(val) {
      this.form.countryLabel = val.label;
      this.form.citycode = val.value;
      this.$forceUpdate();
    },
    getDetail() {
      this.formLoading = true;
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.hotelId,
            hotel_project: this.hotelProgram,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var terFormData = res.data.data;
            this.form = terFormData;
            this.form.provinceShow = terFormData.province;
            this.form.provinceLabel = terFormData.province;
            this.form.cityShow = terFormData.city;
            this.form.cityLabel = terFormData.city;
            this.form.countryShow = terFormData.county;
            this.form.countryLabel = terFormData.county;
            this.form.hotel_brand = terFormData.groupId;
            this.form.groupId = terFormData.groupId;
            this.form.hotel_use_state = parseInt(terFormData.hotel_use_state); //终端客户状态
            this.form.juping_type = terFormData.juping_type
              ? parseInt(terFormData.juping_type)
              : ""; //聚屏合作类型
            this.form.hotel_level =
              terFormData.hotel_level == 0 ? "" : terFormData.hotel_level;
            this.form.hotel_quality =
              terFormData.hotel_quality == 0 ? "" : terFormData.hotel_quality;
            this.region.prov_code = terFormData.citycode.substr(0, 2);
            this.form.lng = terFormData.lng === "0" ? 116.404 : terFormData.lng;
            this.map.center.lng =
              terFormData.lng === "0" ? 116.404 : terFormData.lng;
            this.form.lat = terFormData.lat === "0" ? 39.915 : terFormData.lat;
            this.map.center.lat =
              terFormData.lat === "0" ? 116.404 : terFormData.lat;
            this.form.brand_name = terFormData.brand_name;
            this.getCity().then((res) => {
              this.region.city_code = terFormData.citycode.substr(0, 4);
              this.aysnOption.cityFlag = true;
              this.getCountry().then((res) => {
                this.region.country_code = terFormData.citycode.substr(0, 6);
                this.aysnOption.countryFlag = true;
              });
            });

            var topInfo = {
              hotel: terFormData.hotel,
              hotel_use_state_text: terFormData.hotel_use_state_text,
              contract_end: terFormData.contract_end,
            };
            this.$emit("topInfo", topInfo);
            this.selectData.city = [];
            this.selectData.country = [];
            this.formLoading = false;
            console.log(this.form, "4984984984984");
          } else {
            this.formLoading = false;
            // this.$message.error('获取酒店详情失败');
          }
        });
    },
    submitForm(formName) {
      this.form.occupyRules = this.form.baidu_address;
      this.$refs[formName].validate((valid, test) => {
        if (valid) {
          //通过验证，执行子组件方法
          // var terValidate=this.$refs.ter_dom.submit();
          // return false;
          if (this.id) {
            this.$confirm("确定提交?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.update();
              })
              .catch(() => {});
          } else {
            this.$confirm("确定提交?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.create();
              })
              .catch(() => {});
          }
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    getParam() {
      var paramData = {
        token: this.token,
        hotel_brand: this.form.groupId,
        groupId: this.form.groupId,
        hotel_store_id: this.form.hotel_store_id,
        hotel: this.form.hotel,
        contract_365: this.form.contract_365,
        hotel_level: this.form.hotel_level,
        hotel_quality: this.form.hotel_quality, //终端门店性质
        hotel_room: this.form.hotel_room,
        total_num: this.form.total_num,
        h_ota_price: this.form.h_ota_price,
        hotel_use_state: this.form.hotel_use_state, //终端客户状态
        juping_type: this.form.juping_type,
        citycode: this.form.citycode,
        hotel_province: this.form.provinceLabel,
        hotel_city: this.form.cityLabel,
        hotel_county: this.form.countryLabel,
        baidu_address: this.form.baidu_address,
        address: this.form.address,
        lat: this.map.center.lat,
        lng: this.map.center.lng,
        baidu_hotel_name: this.form.baidu_hotel_name,
        baidu_uid: this.form.baidu_uid,
        brand_name: this.form.brand_name,
      };
      for (var i = 0; i < this.selectData.hotelGroupList.length; i++) {
        if (this.selectData.hotelGroupList[i].id == paramData.groupId) {
          paramData.brand_name = this.selectData.hotelGroupList[i].groupname;
        }
      }
      return paramData;
    },
    create() {
      var param = this.getParam();
      param = qs.stringify(param);
      this.$http.post(this.global.create, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
              sessionStorage.setItem("defaultActiveId", 247);
      this.$router.push({ path: "/HotelManAll" });
        } else if (res.data.code === 100001) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100002) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100003) {
          this.$message.error(res.data.msg);
        }
      });
    },
    update() {
      var param = this.getParam();
      param.id = this.id;
      param = qs.stringify(param);
      this.$http.post(this.global.update, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.$router.push({ path: "/hotelManAll" });
        } else if (res.data.code === 100001) {
          this.$message.error("失败");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getInfoClick(e) {
      this.map.center.lat = e.Ag.lat;
      this.map.center.lng = e.Ag.lng;
    },
    showTerInfo() {
      this.terInfoShow = true;
      var param = qs.stringify({
        token: this.token,
        hotelContract: this.hotel_contract,
      });
      this.$http.post(this.global.getHotelTerminalInfo, param).then((res) => {
        if (res.data.code === 100000) {
          if (res.data.data.length === 0) {
            this.$message.warning("暂无终端信息");
          } else {
            this.terInfo = res.data.data;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    showTemplateDialog(val) {
      this.$refs.ehotelDialog.showDialog();
    },
    getHotelTemplateId(val) {
      this.selectData.hotelTemplateList.filter((item, index) => {
        if (item.name.indexOf(val) !== -1) {
          this.form.hotel_template_id = item.id;
        }
      });
    },
    getLevelQuality() {
      var param = qs.stringify({ token: this.token });
      this.$http
        .post(this.global.getHotelLevelAndQuality, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.hotel_level_select = res.data.data.level;
            this.selectData.hotel_quality_select = res.data.data.quality;
          }
        });
    },
    isEmptyObj(obj) {
      for (let i in Object.keys(obj)) {
        return false; // 进入循环即不为空
      }
      return true;
    },
    searchMeth() {
      this.selectData.addressSearchList = [];
      this.searchListBoxShow = true;
      var param = qs.stringify({
        token: this.token,
        keyword: this.form.baidu_address,
      });
      this.$http
        .post(this.global.getBaiduSearch, param)
        .then((res) => {
          if (res.data.code === 100000) {
            if (res.data.data.length === 0) {
              this.selectData.addressSearchList = [{ name: "暂无数据" }];
            } else {
              this.selectData.addressSearchList = res.data.data;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    getCoordinate(uid, adcode, province, city, district) {
      if (uid) {
        var param = qs.stringify({
          token: this.token,
          uid: uid,
        });
        this.$http
          .post(this.global.getBaiduInfo, param)
          .then((res) => {
            if (res.data.code === 100000) {
              var resData = res.data.data;
              this.map.center.lat = resData.location.lat;
              this.map.center.lng = resData.location.lng;
              this.form.baidu_address = resData.address;
              this.form.baidu_hotel_name = resData.name;
              this.form.baidu_uid = resData.uid;
            } else {
              this.$message.error(res.msg);
            }
            this.searchListBoxShow = false;
          })
          .catch(() => {
            this.$message.error("返回错误");
            this.searchListBoxShow = false;
          });
        this.form.provinceShow = province;
        this.form.provinceLabel = province;
        this.form.cityShow = city;
        this.form.cityLabel = city;
        this.form.countryShow = district;
        this.form.countryLabel = district;
        this.form.citycode = adcode;
      }
    },
  },
  mounted() {
    var query = {
      id: this.id,
      fromUrl: "",
    };
    this.query = query;
    this.hotelId = this.id;
    this.hotelProgram = this.hotelName;
    this.hotel_is_children = this.is_children;
    // if(!this.isEmptyObj(query)){
    //     this.query=query;
    // }
    // console.log('参数',this.query);
    // console.log('参数-id',this.query.id);
    this.token = sessionStorage.getItem("token");
    var auth = JSON.parse(sessionStorage.getItem("auth"));
    this.auth_type = auth.type;
    this.getOption();
    this.getLevelQuality();
    if (this.hotelProgram) {
      this.getDetail(1);
    }
  },
};
</script>

<style scoped lang="scss">
/*地图容器*/
/*地图容器*/
.bm-view {
  width: 100%;
  height: 420px;
}
.selectItem {
  width: 100%;
}
.radioBox {
  margin-top: 12px;
}
.map {
  width: 100%;
  height: 0;
}
#map {
  width: 100%;
}
.searchBox {
  display: none !important;
}
.terInfoCol {
  display: flex;
}
.terShowItem {
  width: 20%;
}
.terShowItemBtn {
  width: 10%;
  margin-left: 10px;
}
.hideEl {
  visibility: hidden;
}
.twoRow {
  display: flex;
  flex-direction: column;
  /*justify-content: space-around;*/
}
.searchInput {
  position: absolute;
  top: -64px !important;
  background: red;
  /*z-index:999;*/
}
.coordinateBox {
  display: flex;
}
.coordinate {
  width: 50%;
}
img,
video {
  width: 100%;
  height: 100%;
}
.templateBox {
}
.templateFormatBox {
  display: flex;
  justify-content: space-around;
}
.templateTitle {
  margin-top: 70px;
}
.marginBottom {
  margin-bottom: 20px;
}
.imgItem {
  width: 48%;
}
.imgBox {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.templateFormatRadio {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0 !important;
}
.noneEl {
  visibility: hidden;
}
.terInfoRow {
  width: 100%;
}
.alignCenter {
  margin-top: 25px;
}
.searchList {
  width: 100%;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  z-index: 3;
  box-sizing: border-box;
  padding-left: 200px;
  top: 40px;
}
.searchListBox {
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  box-sizing: border-box;
}
.searchListItem {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 14px;
}
.noneEl {
  /*display:none;*/
}
.relativeEl {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.tenMarginLeft {
  margin-left: 10px;
}
</style>
