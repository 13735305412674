<template>
  <div>
    <div class="form_box">
      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <el-input v-model="form.keyWord" placeholder="apk模糊搜索"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.sign" placeholder="签名模糊搜索"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.package" placeholder="包名模糊搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="
              pagination.currentPage = 1;
              getList();
            ">搜 索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- table -->
    <div>
      <!-- <el-button type="primary" class="btn" @click="apkDia = true"
        >新 增</el-button
      > -->
      <el-button type="danger" @click="del" class="btn">批量删除</el-button>
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{
          background: '#f8fbff',
          color: '#606266',
        }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="versionApk" label="版本号"></el-table-column>
        <el-table-column prop="apk_url" label="apk" min-width="650" show-overflow-tooltip></el-table-column>
        <el-table-column prop="brand_name" label="品牌" min-width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="signedApk" label="签名"></el-table-column>
        <el-table-column prop="apkPackage" label="包名" min-width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="apk_md5" label="md5" min-width="280" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="total,prev, pager, next" :total="pagination.total" :current-page="pagination.currentPage" v-show="pagination.total != 0" @current-change="
          (val) => {
            pagination.currentPage = val;
            getList();
          }
        ">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getList, delApk } from "./api/apkIn";
export default {
  name: "apkIn",
  data() {
    return {
      form: {
        keyWord: "",
        sign: "",
        package: "",
      },
      tableData: [],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      selected: [],
      apkDia: false,
      currentId: "",
    };
  },
  methods: {
    mod() {},
    handleSelectionChange(val) {
      this.selected = val;
    },
    del() {
      if (this.selected.length == 0) {
        this.$message.error("尚未选择数据");
        return;
      }
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let str = this.selected
            .map((item) => {
              return item.id;
            })
            .join(",");
          delApk({
            val: str + ",",
            status: 0,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.selected = [];
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getList(page) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        url_input: this.form.keyWord,
        signed_input: this.form.sign,
        package_input: this.form.package,
        page: page ? page : this.pagination.currentPage,
        per_page: 10,
      }).then((data) => {
        this.tableData = data.data.data.row || [];
        this.pagination.total = parseInt(data.data.data.count);
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
</style>
