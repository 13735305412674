<template>
  <div>
    <!-- 面包屑 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">财务管理</span>
        <span slot="third">提现管理</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth" v-if="auth.type == 'htrip'"></fold-top-search>
    </div>
    <!-- 面包屑 -->
    <!-- 筛选 -->
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <!-- 筛选 -->
    <!-- 列表 -->
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">提现管理列表</div>
      <div class="btnRow">
        <div>
          <el-button class="largeBtn" @click="confirmRemittance" type="primary" size="medium" v-if="pay_status == '1' && showFunModel('已付款')" :disabled="tableOperateDisabled">已付款</el-button>
        </div>
        <div class="rightBtn">
          <el-button :class="pay_status == 1 ? 'isActive' : ''" type="primary" @click="changeStatus(1)">待汇款</el-button>
          <el-button :class="pay_status == 2 ? 'isActive' : ''" type="primary" @click="changeStatus(2)">已汇款</el-button>
          <el-button :class="pay_status == 0 ? 'isActive' : ''" type="primary" @click="changeStatus(0)">不可付款</el-button>
        </div>
      </div>
      <table-list :table="table" @tableMeth="tableMeth" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading" @showUploadDialog="showUploadDialog"></table-list>
    </el-card>
    <!-- 列表 -->
    <!-- 详情 -->
    <el-drawer :title="dialogTitle" :visible.sync="contentShowVisible" size="50%" :modal="false" :before-close="handleClose" :wrapperClosable="false" :destroy-on-close="true">
      <div class="picShow" v-if="dialogType === 1"></div>
      <div class="picShow picShow1" v-if="dialogType === 2">
        <p>{{ returnContent }}</p>
      </div>
      <div class="picShow3" v-if="dialogType === 3">
        <el-upload :class="{ hide: uploadPicTrue }" :action="global.materialUpload" :headers="headers" list-type="picture-card" :on-remove="handleRemove" :on-success="handleSuccess" :on-change="handleChange" :on-preview="handlePictureCardPreview" :on-exceed="handleExceed" :before-remove='beforeRemove' :before-upload="beforeAvatarUpload" :limit="1" :file-list="fileList" ref="uploadPic">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-button class="largeBtn" type="primary" v-show="uploadBtnShow" @click="toUploadWithdrawPic" :disabled="fileList.length == 0">确认上传</el-button>
      </div>
    </el-drawer>
    <!-- 详情 -->
    <div class="allLoading" v-show="false">{{ allLoadingStateCom }}</div>
  </div>
</template>

<script>
import { uploadWithdrawPic, updatePayAll } from "@/api/financialManagement.js";
import qs from "querystring";
import commonMeth from "../../api/method";
export default {
  name: "adManCustom",
  data: () => ({
    dialogVisible: false,
    dialogImageUrl: "",
    uploadPicId: null,
    returnContent: "",
    fileList: [],
    dialogType: 3,
    uploadBtnShow: true,
    uploadPicTrue: false,
    dialogTitle: "汇款单",
    contentShowVisible: false,
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    table: {
      tableHeader: [
        { prop: "partner_name", label: "合作伙伴", width: "300" },
        { prop: "withdraw_type_name_text", label: "收益类型", width: "" },
        {
          prop: "withdraw_amount_text",
          label: "申请提现金额（元）",
          width: "",
        },
        { prop: "status_text", label: "审核状态", width: "" },
        { prop: "confirm_admin_name_text", label: "审核人", width: "" },
        { prop: "confirm_time_text", label: "审核时间", width: "" },
        { prop: "pay_status_text", label: "汇款状态", width: "" },
        { prop: "pay_admin_name_text", label: "汇款人", width: "" },
        { prop: "pay_time_text", label: "汇款时间", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "finManCashOut",
      name: "finManCashOut",
      option: false,
      select: true,
      loading: true,
      radio: false,
      upload: null,
    },
    code_string: null,
    sourceType: null,
    company_name: null,
    pay_status: "1",
    topSearchShow: {},
    condition: {
      coopCompleStatus: null,
    },
    operateIds: "",
    operateIdsArr: [],
    token: "",
    auth: {},
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    editId: "",
    funArr: [],
    allData: {},
    tableOperateDisabled: false,
    allLoadingState: false,
    headers: {
      token: "",
    },
    upNoPic: false,
  }),
  methods: {
    // 关闭对话框
    handleClose(done) {
      done();
      this.uploadPicTrue = false;
      this.uploadBtnShow = true;
    },
    //上传凭证
    toUploadWithdrawPic() {
      this.$confirm("是否确认上传汇款凭证?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const json = {
            id: String(this.uploadPicId),
            pay_voucher: this.fileList[0].response.data.url,
          };
          var param = qs.stringify(json);
          uploadWithdrawPic(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("您已成功上传汇款凭证");
                this.contentShowVisible = false;
                this.tableMeth();
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.success("上传汇款凭证失败");
            });
        })
        .catch((err) => {});
    },
    // 更新汇款单按钮
    uploadPic() {
      this.dialogTitle = "更新汇款单";
      this.dialogType = 3;
    },
    // 文件上传数量提示
    handleExceed(file, fileList) {
      this.$message.warning("您已选择汇款凭证，如需更改，请删除已上传凭证");
    },
    // 关闭弹框
    closeDialog() {
      (this.fileList = []), (this.contentShowVisible = false);
      this.uploadPicTrue = false;
    },
    // 选择付款状态
    changeStatus(val) {
      this.pay_status = String(val);
      this.tableMeth();
    },
    //  已付款按钮
    confirmRemittance() {
      const that = this;
      if (that.operateIdsArr.length === 0) {
        that.$message.warning("请至少选择一条申请进行操作");
      } else {
        this.$confirm("确认已付款吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var JSONStr = that.operateIdsArr.join(",");
            var params = qs.stringify({ ids: JSONStr, pay_status: "2" });
            updatePayAll(params)
              .then((res) => {
                if (res.data.code == 100000) {
                  that.$message({
                    message: "已付款状态设成功",
                    type: "success",
                  });
                  that.tableMeth();
                } else {
                  that.$message.warning(res.data.msg);
                }
              })
              .catch((err) => {
                that.$message.error("已付款状态设置失败");
              });
          })
          .catch(() => {});
      }
    },
    // 表格数据获取
    tableMeth(orderVal) {
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      this.table.loading = true;
      this.$http
        .get(this.global.approvalWithdrawListAdmin, {
          params: {
            token: this.token,
            page: this.table.page,
            status: this.statusType,
            limit: this.table.limit,
            pay_status: this.pay_status,
            code_string: this.code_string,
            type: this.sourceType,
            company_name: this.company_name,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = tableData.total;
          } else {
            this.$message.warning(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch(() => {
          this.table.loading = false;
        });
    },
    // 分页
    getNewPage(newPage) {
      this.table.page = newPage;
      this.table.loading = true;
      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
      this.table.loading = true;
      this.tableMeth();
    },
    // 筛选权限配置
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {};
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          examineStatus: true,
          payStatus: true,
          coopType: true,
          keyword: true,
          keywordPlaceholder: "请输入合作伙伴名称关键字",
        };
      }
    },
    // 筛选表格数据
    getCondition(val) {
      this.statusType = val.examineStatus;
      if (val.payStatus === "") {
        this.pay_status = null;
      } else if (val.payStatus === 0) {
        this.pay_status = val.payStatus;
      } else {
        this.pay_status = val.payStatus;
      }
      this.sourceType = val.SourceOfType;
      if (val.partner_id == "") {
        this.code_string = val.partner_id;
      } else {
        this.code_string = val.partner_id.join(",");
      }
      this.table.page = 1;
      this.company_name = val.keyword;
      this.tableMeth();
    },
    // 选择表格数据
    getTableSelect(val) {
      this.operateIdsArr = [];
      if (this.table.radio) {
        this.operateIds = val.id;
        this.operateIdsArr.push(val.id);
      } else {
        var operateIds = "";
        val.forEach((item, index) => {
          operateIds += item.id + ",";
        });
        this.operateIds = operateIds.slice(0, -1);
        this.operateIdsArr = this.operateIds.split(",");
        if (this.operateIdsArr[0] == "") {
          this.operateIdsArr = [];
        }
      }
    },
    // 详情
    showUploadDialog(val) {
      const that = this;
      this.fileList = [];
      if (val.pay_voucher != "") {
        this.dialogTitle = "汇款单";
        this.dialogType = 3;
        this.uploadPicId = val.id;
        const json = {
          url: val.pay_voucher,
        };
        this.uploadBtnShow = false;
        this.fileList.push(json);
        this.uploadPicTrue = true;
      } else if (val.check_reason != "") {
        this.dialogTitle = "驳回原因";
        this.dialogType = 2;
        this.returnContent = val.check_reason;
      } else if (val.pay_status == 2) {
        this.dialogTitle = "上传汇款单";
        this.uploadPicTrue = false;
        this.uploadBtnShow = true;
        this.dialogType = 3;
        this.uploadPicId = val.id;
      }
      this.contentShowVisible = true;
    },
    // 筛选开关
    getFoldMeth(val) {
      this.fold = val;
    },
    // 删除上传文件确认
    beforeRemove(file, fileList) {
      if (!this.upNoPic) {
        return this.$confirm("是否确认删除已上传文件？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.uploadPicTrue = false;
            this.uploadBtnShow = true;
          })
          .catch((err) => {
            reject(false);
          });
      } else {
        this.uploadPicTrue = false;
        this.uploadBtnShow = true;
        this.upNoPic=false;
      }
    },
    // 上传文件时钩子函数
      beforeAvatarUpload(file) {
      var jpeg = file.type === "image/jpeg";
      var png = file.type === "image/png";
      if (!jpeg) {
        if (!png) {
          this.$message.warning("请上传图片文件");
          this.upNoPic = true;
          return false;
        } else {
          this.uploadPicTrue = true;
          return true;
        }
      } else {
        this.uploadPicTrue = true;
        return true;
      }
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    // 文件预览
    handlePictureCardPreview(file, fileList) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    // 文件列表变化
    handleChange() {
      if (this.fileList.length >= 1) {
        this.uploadPicTrue = true;
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      this.fileList = fileList;
    },
  },
  // 数据挂载处理
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.headers.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.tableMeth();
    commonMeth.getTreeData(this.table.option).then((res) => {
      this.funArr = res.funArr;
      if (this.funArr.indexOf("上传汇款单") != -1) {
        this.table.upload = true;
      } else {
        this.table.upload = false;
      }
      this.table.option = res.flag;
    });
  },
  // 监听
  watch: {
    "uploadPicDialog.visible"(newVal) {
      if (!newVal) {
        this.uploadPicDialog.url = "";
        this.uploadPicDialog.defaultList = [];
        this.uploadPicDialog.uploadDisabled = false;
      }
    },
  },
  computed: {
    // 权限挂载
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    // loading
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(this.table.loading);
    },
  },
};
</script>

<style scoped lang="scss">
.rightBtn {
  margin-left: auto;
}
::v-deep .el-upload-list__item {
  transition: none !important;
}
::v-deep .el-upload--picture-card {
  width: 300px;
  height: 200px;
}
::v-deep .el-upload--picture-card i {
  line-height: 200px;
}
::v-deep .el-upload-list__item {
  width: 300px;
  height: 200px;
}

::v-deep .el-upload-list__item.is-uploading {
  width: 300px;
  height: 200px;
}
::v-deep .el-upload-list__item.is-success {
  width: 300px;
  height: 200px;
}
.btnRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  width: 50%;
  padding: 10px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background: #b6babf;
}
.dialogTitle {
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-align: left;
  padding: 0 10px;
  border-bottom: 1px solid #ecf3fd;
}
.picShow1 {
  box-sizing: border-box;
  margin-top: 10px;
}
.picShow {
  position: relative;
  z-index: 20;
  width: 100%;
  height: calc(100% - 100px);
}
.picShow3 {
  width: 300px;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 40px);
}
.picShow3 .el-button {
  margin-top: 20px;
}
.picShow img {
  width: 100%;
  max-height: calc(100% - 40px);
}
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
.rightBtn .el-button {
  background: #e6ebf2;
  border: 1px solid #bbb;
  color: #aaa;
  width: 100px;
  height: 40px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
}
.rightBtn .el-button:first-child {
  border-radius: 3px 0 0 3px;
}
.rightBtn .el-button:first-child.isActive {
  border-radius: 3px 0 0 3px;
}
.rightBtn .el-button:last-child {
  border-radius: 0 3px 3px 0;
}
.rightBtn .el-button:last-child.isActive {
  border-radius: 0 3px 3px 0;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.rightBtn .el-button.isActive {
  color: #fff;
  border: 1px solid #3688f8;
  background: #3688f8;
  z-index: 2;
  border-radius: 0;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;

  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  // padding: 0 20px;
  background-color: #f9f9f9;
}
// ::v-deep .el-drawer.rtl {
//   width: 95% !important;
// }
::v-deep :focus {
  outline: 0;
}
</style>
