import request from "@/api/request";
import qs from "querystring";

//  apk已入库列表
export function getList(param) {
  return request({
    url: `v2/batch_set/apkPullList`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  apk已入库删除
export function delApk(param) {
  return request({
    url: `v2/batch_set/insertTable`,
    method: "post",
    data: qs.stringify(param),
  });
}
