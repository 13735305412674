<template>
  <div>
    <!-- 面包屑和筛选控制 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span  v-if='!query' slot="second">酒店管理</span>
        <span  v-if='!query' slot="third">酒店授权管理</span>
        <span v-if='query' slot="second">酒店授权管理</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <!-- 面包屑和筛选控制 -->
    <!-- 筛选界面 -->
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <!-- 筛选界面 -->
    <!-- 授权书列表 -->
    <el-card class="box-card">
      <div class="title blueTitle">授权书管理列表</div>
      <div class="btnRow">
        <el-button class="largeBtn" @click="openBatchPage" type="primary">批量上传</el-button>
        <el-button class="largeBtn" type="primary" :loading="btnLoading" @click="exportTable">导出</el-button>
      </div>
      <el-table :data="tableData" ref="accountTable" style="width: 100%; margin-top: 10px" :header-cell-style="headerStyle" v-loading="tableLoading">
        <el-table-column prop="hotel_name" label="酒店名称" min-width="260" show-overflow-tooltip fixed></el-table-column>
        <el-table-column prop="hotel_contract" label="合同号" min-width="280" show-overflow-tooltip fixed></el-table-column>
        <el-table-column prop="operation_type_text" label="操作类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="certificate_text" label="操作项目" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operation_action_text" label="操作动作" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operation_status_text" label="上传状态" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operator" label="操作人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operate_time" label="操作时间" min-width="160" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination class="pageChange" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </el-card>
    <!-- 授权书列表 -->
    <!-- 批量组件 -->
    <authorizationMoreOpearate ref="batchPage" @opearetesuccess="opeareteSuccess"></authorizationMoreOpearate>
    <!-- 批量组件 -->
  </div>
</template>
<script>
// api引入
import { getCertificateList, exportCertificate } from "@/api/hotel.js";
// qs引入
import qs from "querystring";
// ’组件引入
import authorizationMoreOpearate from "./components/authorizationMoreOpearate.vue";
export default {
  // 组件名称
  name: "authorizationRecordList",
  // 组件挂载
  components: {
    authorizationMoreOpearate,
  },
  // 数据
  data() {
    return {
      // 面包屑
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },
      // 筛选控制
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      // 筛选字段
      topSearchShow: {
        keyword: true,
        codekeyword: true,
        keywordPlaceholder: "请输入酒店名称",
        codekeywordPlaceholder: "请输入合同号",
        authorizedOperation: true,
        authorizedObject: true,
        authorizedAction: true,
        hotelCreateTime: true,
      },
      // 筛选表单
      searchForm: {
        authorizedAction: "",
        authorizedObject: "",
        authorizedOperation: "",
        hotelName: "",
        hotelContract: "",
        searchTime: [],
      },
      // 表格样式
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      tableData: [],
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      tableLoading: false,
      btnLoading: false,
      query:null,
    };
  },
  mounted() {
    // 获取数据
    this.getTableList();
        this.query = sessionStorage.getItem("apaasData");
  },
  methods: {
    // 批量成功 刷新数据
    opeareteSuccess() {
      this.searchForm.authorizedAction = "";
      this.searchForm.authorizedObject = "";
      this.searchForm.authorizedOperation = "";
      this.searchForm.hotelName = "";
      this.searchForm.hotelContract = "";
      this.searchForm.searchTime = [];
      this.table.page = 1;
      this.getTableList();
    },
    // 导出列表
    exportTable() {
      // 二次确认
      this.$confirm("确定导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 导出接口调用
          exportCertificate()
            .then((res) => {
              this.btnLoading = true; // 按钮loading
              const link = document.createElement("a"); //创建a标签
              let blob = new Blob([res.data]); //  文件转换
              let objectUrl = window.URL.createObjectURL(blob); // 创建URL
              link.href = objectUrl; //  a 属性
              link.download = "授权列表.xlsx"; // 自定义文件名
              link.click(); // 下载文件
              URL.revokeObjectURL(objectUrl); // 释放内存
              this.$message.success("导出成功"); //提示
              this.btnLoading = false; // 按钮loading取消
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    // 获取表格数据
    getTableList() {
      // 表格loading
      this.tableLoading = true;
      // json参数字段
      let json = {
        page: this.table.page,
        limit: this.table.limit,
        hotel_contract: this.searchForm.hotelContract,
        hotel_name: this.searchForm.hotelName,
        ce_type: this.searchForm.authorizedObject,
        start_time: this.searchForm.searchTime[0]
          ? this.searchForm.searchTime[0]
          : "",
        end_time: this.searchForm.searchTime[1]
          ? this.searchForm.searchTime[1]
          : "",
        operation_type: this.searchForm.authorizedOperation,
        operation_action: this.searchForm.authorizedAction,
      };
      // json格式转换
      let param = qs.stringify(json);
      // 获取列表接口调用
      getCertificateList(param)
        .then((res) => {
          if (res.data.code === 100000) {
            // 返回成功
            this.tableData = res.data.data.data; //  表格赋值
            this.table.total = res.data.data.total; //  数据总数赋值
          }
          this.tableLoading = false; //laoding 效果重置
        })
        .catch((err) => {
          this.tableLoading = false; //laoding 效果重置
        });
    },
    // 打开批量界面
    openBatchPage() {
      this.$refs.batchPage.open();
    },
    // 筛选界面控制
    getFoldMeth(val) {
      this.fold = val;
    },
    // 筛选
    getCondition(val) {
      // 筛选表单分别赋值
      this.searchForm.authorizedAction = val.authorizedAction;
      this.searchForm.authorizedObject = val.authorizedObject;
      this.searchForm.authorizedOperation = val.authorizedOperation;
      this.searchForm.hotelName = val.keyword;
      this.searchForm.hotelContract = val.codekeyword;
      this.searchForm.searchTime = val.hotelCreateTime;
      this.table.page = 1;
      // 获取列表
      this.getTableList();
    },
    // 表格分页
    handleSizeChange(limit) {
      this.table.limit = limit;
      this.getTableList();
    },
    // 表格分页
    handleCurrentChange(page) {
      this.table.page = page;
      this.getTableList();
    },
  },
};
</script>
<style scoped>
.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
</style>