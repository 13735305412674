<template>
    <div>
        <!--<el-row class="btnRow">-->
            <el-button type="primary" @click="backMeth" class="largeBtn ">返回</el-button>
        <!--</el-row>-->
        <!--<el-page-header @back="backMeth" content="详情页面">-->
        <!--</el-page-header>-->
    </div>
</template>

<script>
    export default {
        name: "Back",
        data:()=>({
        
        }),
        methods:{
            backMeth(){
                this.$emit('backMeth');
            }
        }
    }
</script>

<style scoped>

</style>