import request from "@/api/request";
import qs from "querystring";

//  获取设备列表
export function getList(param) {
    return request({
        url: `v2/Devicepath/getList`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  增加设备
export function addDevice(param) {
    return request({
        url: `v2/Devicepath/addDevice`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  删除设备
export function delDevice(param) {
    return request({
        url: `v2/Devicepath/delDevice`,
        method: "post",
        data: qs.stringify(param),
    });
}