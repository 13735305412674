/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    //注意，下面的内容要改成自己的
    echarts.registerMap('沈阳市', { "type": "FeatureCollection", "features": [{ "type": "Feature", "properties": { "adcode": 210102, "name": "和平区", "center": [123.406664, 41.788074], "centroid": [123.389006, 41.746003], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 0, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.429266, 41.81739],
                            [123.412213, 41.812649],
                            [123.403568, 41.806907],
                            [123.396664, 41.79835],
                            [123.383119, 41.778859],
                            [123.374989, 41.759955],
                            [123.375991, 41.749371],
                            [123.357992, 41.746961],
                            [123.35163, 41.743864],
                            [123.337737, 41.726249],
                            [123.328466, 41.721093],
                            [123.330095, 41.715269],
                            [123.326142, 41.711318],
                            [123.327742, 41.709704],
                            [123.319752, 41.701189],
                            [123.321673, 41.700299],
                            [123.319362, 41.695308],
                            [123.325501, 41.694213],
                            [123.327784, 41.696588],
                            [123.332601, 41.693786],
                            [123.335106, 41.698221],
                            [123.342429, 41.694046],
                            [123.34815, 41.694825],
                            [123.348623, 41.698647],
                            [123.354247, 41.701746],
                            [123.35195, 41.70336],
                            [123.354373, 41.708109],
                            [123.35021, 41.711819],
                            [123.352466, 41.713711],
                            [123.358785, 41.71078],
                            [123.360929, 41.70425],
                            [123.35763, 41.697571],
                            [123.374251, 41.691634],
                            [123.378525, 41.693638],
                            [123.386348, 41.690706],
                            [123.391861, 41.698184],
                            [123.396817, 41.700243],
                            [123.40599, 41.698147],
                            [123.41142, 41.701189],
                            [123.416584, 41.701894],
                            [123.391109, 41.707664],
                            [123.375936, 41.713025],
                            [123.381755, 41.723949],
                            [123.380015, 41.734872],
                            [123.387852, 41.732739],
                            [123.392668, 41.729809],
                            [123.393629, 41.726193],
                            [123.399211, 41.722836],
                            [123.403819, 41.727899],
                            [123.405322, 41.726564],
                            [123.411726, 41.729179],
                            [123.411921, 41.724524],
                            [123.416487, 41.723281],
                            [123.419605, 41.733629],
                            [123.423739, 41.734519],
                            [123.432509, 41.743049],
                            [123.440556, 41.74481],
                            [123.445748, 41.748351],
                            [123.438969, 41.75382],
                            [123.435238, 41.759121],
                            [123.435725, 41.778229],
                            [123.428152, 41.778173],
                            [123.425159, 41.789346],
                            [123.422598, 41.789883],
                            [123.430379, 41.810871],
                            [123.429266, 41.81739]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210103, "name": "沈河区", "center": [123.445696, 41.795591], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 1, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.456007, 41.826352],
                            [123.429266, 41.81739],
                            [123.430379, 41.810871],
                            [123.422598, 41.789883],
                            [123.425159, 41.789346],
                            [123.428152, 41.778173],
                            [123.435725, 41.778229],
                            [123.435238, 41.759121],
                            [123.438969, 41.75382],
                            [123.445748, 41.748351],
                            [123.4563, 41.75293],
                            [123.485756, 41.753208],
                            [123.504298, 41.75673],
                            [123.513291, 41.761401],
                            [123.519486, 41.771595],
                            [123.519875, 41.776376],
                            [123.52607, 41.782064],
                            [123.541828, 41.788475],
                            [123.55973, 41.794441],
                            [123.569711, 41.802906],
                            [123.581502, 41.811501],
                            [123.576407, 41.816242],
                            [123.578885, 41.82726],
                            [123.578342, 41.831055],
                            [123.571159, 41.847661],
                            [123.564352, 41.842238],
                            [123.541285, 41.837665],
                            [123.527017, 41.831944],
                            [123.530748, 41.827575],
                            [123.525472, 41.823705],
                            [123.512163, 41.820631],
                            [123.518539, 41.813501],
                            [123.516033, 41.807426],
                            [123.516159, 41.80324],
                            [123.519653, 41.801202],
                            [123.513653, 41.790587],
                            [123.512832, 41.783806],
                            [123.504173, 41.782398],
                            [123.493997, 41.777117],
                            [123.488206, 41.776895],
                            [123.48861, 41.784325],
                            [123.482512, 41.784566],
                            [123.482707, 41.789124],
                            [123.476081, 41.784047],
                            [123.469065, 41.792588],
                            [123.464416, 41.792977],
                            [123.46436, 41.804796],
                            [123.44856, 41.80487],
                            [123.452151, 41.810575],
                            [123.451595, 41.818205],
                            [123.453223, 41.823853],
                            [123.456007, 41.826352]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210104, "name": "大东区", "center": [123.469956, 41.808503], "centroid": [123.512129, 41.861227], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 2, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.571159, 41.847661],
                            [123.567665, 41.856083],
                            [123.568236, 41.866687],
                            [123.566064, 41.87122],
                            [123.556153, 41.881304],
                            [123.553313, 41.887409],
                            [123.55671, 41.888981],
                            [123.549359, 41.891959],
                            [123.546687, 41.895585],
                            [123.553299, 41.898748],
                            [123.559146, 41.898008],
                            [123.555234, 41.902502],
                            [123.559062, 41.906608],
                            [123.553021, 41.911693],
                            [123.547438, 41.914208],
                            [123.552116, 41.919773],
                            [123.546325, 41.921142],
                            [123.544961, 41.924599],
                            [123.546715, 41.930496],
                            [123.553591, 41.932456],
                            [123.558199, 41.936024],
                            [123.566315, 41.947261],
                            [123.560468, 41.946688],
                            [123.524581, 41.946633],
                            [123.517941, 41.928056],
                            [123.513611, 41.921641],
                            [123.51098, 41.922251],
                            [123.507263, 41.917481],
                            [123.503853, 41.920938],
                            [123.500929, 41.917018],
                            [123.495027, 41.913727],
                            [123.493176, 41.916205],
                            [123.486118, 41.904259],
                            [123.480355, 41.893458],
                            [123.477821, 41.886132],
                            [123.474731, 41.860858],
                            [123.468188, 41.838979],
                            [123.464889, 41.833203],
                            [123.456007, 41.826352],
                            [123.453223, 41.823853],
                            [123.451595, 41.818205],
                            [123.452151, 41.810575],
                            [123.44856, 41.80487],
                            [123.46436, 41.804796],
                            [123.464416, 41.792977],
                            [123.469065, 41.792588],
                            [123.476081, 41.784047],
                            [123.482707, 41.789124],
                            [123.482512, 41.784566],
                            [123.48861, 41.784325],
                            [123.488206, 41.776895],
                            [123.493997, 41.777117],
                            [123.504173, 41.782398],
                            [123.512832, 41.783806],
                            [123.513653, 41.790587],
                            [123.519653, 41.801202],
                            [123.516159, 41.80324],
                            [123.516033, 41.807426],
                            [123.518539, 41.813501],
                            [123.512163, 41.820631],
                            [123.525472, 41.823705],
                            [123.530748, 41.827575],
                            [123.527017, 41.831944],
                            [123.541285, 41.837665],
                            [123.564352, 41.842238],
                            [123.571159, 41.847661]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210105, "name": "皇姑区", "center": [123.405677, 41.822336], "centroid": [123.425618, 41.85951], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 3, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.353997, 41.825501],
                            [123.35756, 41.823464],
                            [123.39839, 41.813316],
                            [123.402065, 41.808074],
                            [123.396664, 41.79835],
                            [123.403568, 41.806907],
                            [123.412213, 41.812649],
                            [123.429266, 41.81739],
                            [123.456007, 41.826352],
                            [123.464889, 41.833203],
                            [123.468188, 41.838979],
                            [123.474731, 41.860858],
                            [123.477821, 41.886132],
                            [123.480355, 41.893458],
                            [123.486118, 41.904259],
                            [123.47899, 41.900116],
                            [123.472977, 41.902132],
                            [123.473297, 41.905239],
                            [123.467854, 41.906867],
                            [123.465543, 41.904259],
                            [123.459098, 41.902373],
                            [123.455311, 41.903131],
                            [123.453335, 41.900394],
                            [123.445971, 41.902169],
                            [123.435669, 41.907403],
                            [123.421916, 41.907921],
                            [123.418255, 41.90646],
                            [123.408983, 41.909881],
                            [123.410737, 41.907458],
                            [123.393476, 41.909326],
                            [123.388075, 41.904999],
                            [123.375685, 41.909419],
                            [123.372678, 41.89945],
                            [123.378316, 41.901855],
                            [123.377592, 41.893809],
                            [123.393991, 41.893643],
                            [123.392696, 41.895881],
                            [123.401745, 41.895955],
                            [123.404668, 41.891848],
                            [123.414357, 41.895381],
                            [123.418784, 41.888204],
                            [123.415484, 41.887409],
                            [123.418032, 41.857767],
                            [123.409164, 41.855213],
                            [123.410375, 41.845921],
                            [123.418978, 41.846976],
                            [123.418018, 41.844626],
                            [123.406213, 41.837091],
                            [123.397457, 41.83909],
                            [123.389856, 41.83872],
                            [123.388979, 41.845199],
                            [123.381741, 41.856175],
                            [123.374989, 41.853677],
                            [123.371885, 41.855713],
                            [123.356475, 41.84979],
                            [123.352855, 41.846976],
                            [123.358674, 41.846588],
                            [123.359871, 41.842201],
                            [123.365815, 41.837239],
                            [123.363574, 41.834407],
                            [123.356656, 41.838183],
                            [123.353384, 41.831389],
                            [123.353997, 41.825501]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210106, "name": "铁西区", "center": [123.350664, 41.787808], "centroid": [123.119361, 41.673501], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 4, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.374989, 41.759955],
                            [123.383119, 41.778859],
                            [123.396664, 41.79835],
                            [123.402065, 41.808074],
                            [123.39839, 41.813316],
                            [123.35756, 41.823464],
                            [123.353997, 41.825501],
                            [123.336234, 41.836647],
                            [123.330457, 41.836795],
                            [123.317274, 41.834333],
                            [123.316787, 41.783769],
                            [123.319738, 41.778785],
                            [123.30501, 41.781027],
                            [123.27586, 41.782083],
                            [123.272324, 41.788846],
                            [123.273118, 41.796868],
                            [123.269888, 41.798016],
                            [123.253197, 41.797757],
                            [123.2422, 41.795663],
                            [123.225829, 41.788068],
                            [123.221945, 41.78212],
                            [123.21454, 41.782657],
                            [123.206229, 41.777302],
                            [123.190262, 41.773911],
                            [123.173474, 41.768388],
                            [123.163757, 41.763403],
                            [123.169952, 41.760845],
                            [123.169576, 41.757323],
                            [123.162922, 41.745737],
                            [123.154068, 41.746998],
                            [123.15088, 41.740286],
                            [123.155446, 41.736281],
                            [123.154681, 41.733833],
                            [123.147331, 41.727343],
                            [123.142653, 41.725618],
                            [123.136458, 41.728678],
                            [123.127577, 41.725136],
                            [123.116468, 41.725488],
                            [123.113322, 41.729568],
                            [123.1158, 41.734019],
                            [123.107364, 41.737653],
                            [123.105582, 41.735261],
                            [123.09613, 41.736133],
                            [123.094794, 41.734204],
                            [123.079175, 41.734204],
                            [123.073175, 41.73795],
                            [123.071491, 41.7363],
                            [123.05789, 41.736541],
                            [123.05061, 41.735391],
                            [123.032262, 41.736096],
                            [123.027209, 41.73413],
                            [123.026416, 41.729735],
                            [123.032095, 41.726453],
                            [123.037315, 41.721278],
                            [123.03332, 41.717643],
                            [123.038151, 41.716289],
                            [123.035297, 41.709296],
                            [123.039445, 41.70963],
                            [123.034545, 41.702339],
                            [123.034114, 41.704733],
                            [123.030647, 41.697441],
                            [123.024188, 41.693842],
                            [123.022741, 41.688646],
                            [123.019775, 41.687162],
                            [122.946163, 41.643149],
                            [122.925212, 41.647253],
                            [122.901881, 41.639825],
                            [122.905682, 41.636055],
                            [122.923848, 41.642889],
                            [122.947875, 41.63366],
                            [122.917319, 41.622367],
                            [122.914257, 41.59054],
                            [122.901199, 41.568699],
                            [122.911152, 41.571878],
                            [122.93653, 41.566319],
                            [122.957216, 41.555796],
                            [122.977011, 41.556],
                            [122.976343, 41.515173],
                            [122.971318, 41.513349],
                            [122.969229, 41.509796],
                            [122.986449, 41.511117],
                            [122.997377, 41.514596],
                            [123.002208, 41.513908],
                            [123.009669, 41.505664],
                            [123.014527, 41.505106],
                            [123.02068, 41.507488],
                            [123.030912, 41.518299],
                            [123.030592, 41.520978],
                            [123.041185, 41.540212],
                            [123.0417, 41.545754],
                            [123.050178, 41.551036],
                            [123.060229, 41.551612],
                            [123.071171, 41.554457],
                            [123.082251, 41.56076],
                            [123.078507, 41.563791],
                            [123.080511, 41.569275],
                            [123.084799, 41.572677],
                            [123.082363, 41.576488],
                            [123.087207, 41.579369],
                            [123.088975, 41.584722],
                            [123.087569, 41.588978],
                            [123.092887, 41.592417],
                            [123.099694, 41.600221],
                            [123.10909, 41.606297],
                            [123.109619, 41.616237],
                            [123.105026, 41.624224],
                            [123.110705, 41.626453],
                            [123.110817, 41.623258],
                            [123.114728, 41.619673],
                            [123.120032, 41.620565],
                            [123.121368, 41.625896],
                            [123.12535, 41.631078],
                            [123.129053, 41.632452],
                            [123.124682, 41.637838],
                            [123.140092, 41.640698],
                            [123.148361, 41.643966],
                            [123.152565, 41.647327],
                            [123.150073, 41.65195],
                            [123.150797, 41.655794],
                            [123.159344, 41.658913],
                            [123.162407, 41.656388],
                            [123.170021, 41.645415],
                            [123.17719, 41.644338],
                            [123.178861, 41.647439],
                            [123.176842, 41.652545],
                            [123.172026, 41.65403],
                            [123.173098, 41.657836],
                            [123.183482, 41.659804],
                            [123.187687, 41.662514],
                            [123.198712, 41.659228],
                            [123.195649, 41.663628],
                            [123.199449, 41.663665],
                            [123.206034, 41.682003],
                            [123.213621, 41.683432],
                            [123.223087, 41.681205],
                            [123.232274, 41.688721],
                            [123.235643, 41.694436],
                            [123.243954, 41.695178],
                            [123.250413, 41.692228],
                            [123.263318, 41.688665],
                            [123.286537, 41.693267],
                            [123.290017, 41.696273],
                            [123.287818, 41.702989],
                            [123.280175, 41.71117],
                            [123.277377, 41.716048],
                            [123.280704, 41.718441],
                            [123.301154, 41.714583],
                            [123.309478, 41.715084],
                            [123.302421, 41.729976],
                            [123.289001, 41.751651],
                            [123.286245, 41.758287],
                            [123.296588, 41.761401],
                            [123.310063, 41.763458],
                            [123.32461, 41.770946],
                            [123.328452, 41.775245],
                            [123.352911, 41.766368],
                            [123.372191, 41.763069],
                            [123.374989, 41.759955]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210111, "name": "苏家屯区", "center": [123.341604, 41.665904], "centroid": [123.418969, 41.583169], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 5, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.109619, 41.616237],
                            [123.114004, 41.61711],
                            [123.121424, 41.611778],
                            [123.125753, 41.614026],
                            [123.134315, 41.608452],
                            [123.127577, 41.599181],
                            [123.123526, 41.589647],
                            [123.114102, 41.58528],
                            [123.115717, 41.578291],
                            [123.114213, 41.568774],
                            [123.115383, 41.564999],
                            [123.111638, 41.558566],
                            [123.121187, 41.557172],
                            [123.130654, 41.554438],
                            [123.130626, 41.54899],
                            [123.127772, 41.546163],
                            [123.134398, 41.539803],
                            [123.134426, 41.536139],
                            [123.14282, 41.535488],
                            [123.14321, 41.538743],
                            [123.156755, 41.536808],
                            [123.161822, 41.541551],
                            [123.158077, 41.540863],
                            [123.169464, 41.548953],
                            [123.177831, 41.546963],
                            [123.182063, 41.549362],
                            [123.188786, 41.545085],
                            [123.193422, 41.549343],
                            [123.187603, 41.555666],
                            [123.195552, 41.559292],
                            [123.2019, 41.564572],
                            [123.203208, 41.56301],
                            [123.209375, 41.565371],
                            [123.219481, 41.565297],
                            [123.219815, 41.559143],
                            [123.227472, 41.558697],
                            [123.228697, 41.554922],
                            [123.235532, 41.551686],
                            [123.236005, 41.540286],
                            [123.245694, 41.540528],
                            [123.252961, 41.538129],
                            [123.254993, 41.54103],
                            [123.263471, 41.538761],
                            [123.279674, 41.538073],
                            [123.283586, 41.530503],
                            [123.280217, 41.528624],
                            [123.284282, 41.524438],
                            [123.287191, 41.525852],
                            [123.295613, 41.525071],
                            [123.300416, 41.52814],
                            [123.299804, 41.522634],
                            [123.305762, 41.522354],
                            [123.313223, 41.520085],
                            [123.313599, 41.517331],
                            [123.322174, 41.511433],
                            [123.324095, 41.508325],
                            [123.329441, 41.508474],
                            [123.336025, 41.511042],
                            [123.335677, 41.514652],
                            [123.353704, 41.510782],
                            [123.359384, 41.513108],
                            [123.364869, 41.510112],
                            [123.360651, 41.508642],
                            [123.363268, 41.504753],
                            [123.370716, 41.508381],
                            [123.377008, 41.506427],
                            [123.376493, 41.509126],
                            [123.381824, 41.509442],
                            [123.383731, 41.500435],
                            [123.389439, 41.495578],
                            [123.392891, 41.498742],
                            [123.39761, 41.495131],
                            [123.402023, 41.500286],
                            [123.402844, 41.496843],
                            [123.407619, 41.500621],
                            [123.412993, 41.498202],
                            [123.410821, 41.493847],
                            [123.415429, 41.492321],
                            [123.417085, 41.487481],
                            [123.424602, 41.487928],
                            [123.420732, 41.483814],
                            [123.427108, 41.483665],
                            [123.430073, 41.480854],
                            [123.427609, 41.478713],
                            [123.427999, 41.472905],
                            [123.433609, 41.472923],
                            [123.439553, 41.469386],
                            [123.440333, 41.472458],
                            [123.445971, 41.471397],
                            [123.449395, 41.475008],
                            [123.453738, 41.469944],
                            [123.455395, 41.472569],
                            [123.460518, 41.468436],
                            [123.458096, 41.466518],
                            [123.468856, 41.463055],
                            [123.477752, 41.463818],
                            [123.489626, 41.478992],
                            [123.496043, 41.479104],
                            [123.498159, 41.483684],
                            [123.503212, 41.481841],
                            [123.512929, 41.48292],
                            [123.516465, 41.480072],
                            [123.522562, 41.484987],
                            [123.527504, 41.492004],
                            [123.535884, 41.489063],
                            [123.540158, 41.483386],
                            [123.546589, 41.481096],
                            [123.549137, 41.484242],
                            [123.553021, 41.483404],
                            [123.560802, 41.486811],
                            [123.580611, 41.4985],
                            [123.590913, 41.493921],
                            [123.599905, 41.497104],
                            [123.605557, 41.502445],
                            [123.603191, 41.505199],
                            [123.609956, 41.505888],
                            [123.616248, 41.508623],
                            [123.615803, 41.51348],
                            [123.620522, 41.517257],
                            [123.625798, 41.516903],
                            [123.629194, 41.523638],
                            [123.646595, 41.518634],
                            [123.657342, 41.52468],
                            [123.658428, 41.529591],
                            [123.664706, 41.527061],
                            [123.67768, 41.528717],
                            [123.677346, 41.523973],
                            [123.683791, 41.519713],
                            [123.688497, 41.520829],
                            [123.688413, 41.523489],
                            [123.693383, 41.526577],
                            [123.703002, 41.521164],
                            [123.703392, 41.515675],
                            [123.7096, 41.51415],
                            [123.712969, 41.510558],
                            [123.719206, 41.510354],
                            [123.72643, 41.514522],
                            [123.727461, 41.517145],
                            [123.735827, 41.516568],
                            [123.742008, 41.518299],
                            [123.747409, 41.526931],
                            [123.740087, 41.540156],
                            [123.735841, 41.543727],
                            [123.730133, 41.542871],
                            [123.728839, 41.547298],
                            [123.735562, 41.55204],
                            [123.729089, 41.556837],
                            [123.725526, 41.556558],
                            [123.721753, 41.568309],
                            [123.728477, 41.571971],
                            [123.73289, 41.57132],
                            [123.739474, 41.576265],
                            [123.749942, 41.580968],
                            [123.757947, 41.580299],
                            [123.760202, 41.58489],
                            [123.77312, 41.588811],
                            [123.776322, 41.58738],
                            [123.780888, 41.589183],
                            [123.781139, 41.591785],
                            [123.791816, 41.598512],
                            [123.786442, 41.601448],
                            [123.782085, 41.606613],
                            [123.77074, 41.608267],
                            [123.767942, 41.61345],
                            [123.767413, 41.618745],
                            [123.759589, 41.619413],
                            [123.757585, 41.610719],
                            [123.742161, 41.616367],
                            [123.734908, 41.612967],
                            [123.724273, 41.613004],
                            [123.719679, 41.619933],
                            [123.709294, 41.617036],
                            [123.699271, 41.613079],
                            [123.697837, 41.61423],
                            [123.689137, 41.611332],
                            [123.686534, 41.608211],
                            [123.678, 41.604736],
                            [123.674604, 41.607468],
                            [123.673922, 41.612948],
                            [123.667128, 41.612837],
                            [123.660265, 41.610515],
                            [123.653681, 41.612948],
                            [123.65066, 41.617128],
                            [123.643588, 41.612837],
                            [123.635306, 41.614398],
                            [123.63273, 41.611054],
                            [123.625283, 41.610868],
                            [123.621218, 41.606873],
                            [123.614856, 41.611184],
                            [123.604778, 41.612763],
                            [123.595326, 41.608973],
                            [123.5903, 41.613562],
                            [123.583465, 41.616051],
                            [123.584022, 41.620751],
                            [123.580973, 41.623518],
                            [123.581363, 41.629945],
                            [123.577758, 41.629388],
                            [123.577256, 41.633827],
                            [123.570421, 41.634012],
                            [123.56999, 41.630799],
                            [123.56477, 41.631412],
                            [123.559661, 41.628664],
                            [123.560232, 41.631542],
                            [123.553229, 41.631672],
                            [123.546186, 41.634217],
                            [123.543374, 41.637244],
                            [123.534256, 41.632787],
                            [123.533671, 41.627475],
                            [123.526947, 41.618447],
                            [123.523551, 41.617221],
                            [123.514961, 41.620639],
                            [123.511217, 41.61711],
                            [123.504048, 41.615568],
                            [123.500804, 41.612837],
                            [123.49692, 41.613822],
                            [123.493913, 41.611537],
                            [123.488986, 41.612911],
                            [123.485129, 41.609734],
                            [123.478155, 41.613376],
                            [123.467534, 41.61607],
                            [123.461854, 41.619302],
                            [123.461715, 41.628013],
                            [123.458638, 41.631654],
                            [123.454434, 41.631022],
                            [123.45101, 41.635313],
                            [123.44217, 41.635015],
                            [123.430282, 41.636018],
                            [123.425535, 41.631747],
                            [123.419702, 41.636223],
                            [123.412422, 41.63509],
                            [123.406102, 41.636984],
                            [123.400422, 41.632248],
                            [123.394478, 41.634421],
                            [123.39665, 41.63925],
                            [123.371829, 41.64677],
                            [123.375017, 41.656128],
                            [123.378748, 41.656109],
                            [123.377384, 41.659414],
                            [123.379096, 41.663795],
                            [123.385082, 41.664259],
                            [123.390455, 41.670607],
                            [123.396065, 41.671907],
                            [123.402246, 41.686197],
                            [123.400186, 41.687032],
                            [123.402148, 41.691875],
                            [123.405308, 41.692914],
                            [123.40599, 41.698147],
                            [123.396817, 41.700243],
                            [123.391861, 41.698184],
                            [123.386348, 41.690706],
                            [123.378525, 41.693638],
                            [123.374251, 41.691634],
                            [123.35763, 41.697571],
                            [123.360929, 41.70425],
                            [123.358785, 41.71078],
                            [123.352466, 41.713711],
                            [123.35021, 41.711819],
                            [123.354373, 41.708109],
                            [123.35195, 41.70336],
                            [123.354247, 41.701746],
                            [123.348623, 41.698647],
                            [123.34815, 41.694825],
                            [123.342429, 41.694046],
                            [123.335106, 41.698221],
                            [123.332601, 41.693786],
                            [123.327784, 41.696588],
                            [123.325501, 41.694213],
                            [123.319362, 41.695308],
                            [123.321673, 41.700299],
                            [123.319752, 41.701189],
                            [123.327742, 41.709704],
                            [123.326142, 41.711318],
                            [123.330095, 41.715269],
                            [123.328466, 41.721093],
                            [123.309478, 41.715084],
                            [123.301154, 41.714583],
                            [123.280704, 41.718441],
                            [123.277377, 41.716048],
                            [123.280175, 41.71117],
                            [123.287818, 41.702989],
                            [123.290017, 41.696273],
                            [123.286537, 41.693267],
                            [123.263318, 41.688665],
                            [123.250413, 41.692228],
                            [123.243954, 41.695178],
                            [123.235643, 41.694436],
                            [123.232274, 41.688721],
                            [123.223087, 41.681205],
                            [123.213621, 41.683432],
                            [123.206034, 41.682003],
                            [123.199449, 41.663665],
                            [123.195649, 41.663628],
                            [123.198712, 41.659228],
                            [123.187687, 41.662514],
                            [123.183482, 41.659804],
                            [123.173098, 41.657836],
                            [123.172026, 41.65403],
                            [123.176842, 41.652545],
                            [123.178861, 41.647439],
                            [123.17719, 41.644338],
                            [123.170021, 41.645415],
                            [123.162407, 41.656388],
                            [123.159344, 41.658913],
                            [123.150797, 41.655794],
                            [123.150073, 41.65195],
                            [123.152565, 41.647327],
                            [123.148361, 41.643966],
                            [123.140092, 41.640698],
                            [123.124682, 41.637838],
                            [123.129053, 41.632452],
                            [123.12535, 41.631078],
                            [123.121368, 41.625896],
                            [123.120032, 41.620565],
                            [123.114728, 41.619673],
                            [123.110817, 41.623258],
                            [123.110705, 41.626453],
                            [123.105026, 41.624224],
                            [123.109619, 41.616237]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210112, "name": "浑南区", "center": [123.458981, 41.741946], "centroid": [123.627317, 41.764818], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 6, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.725136, 42.07626],
                            [123.713415, 42.071575],
                            [123.695986, 42.070413],
                            [123.724718, 42.060212],
                            [123.722226, 42.050452],
                            [123.718301, 42.04951],
                            [123.717493, 42.035559],
                            [123.717841, 42.017193],
                            [123.72128, 42.008424],
                            [123.717117, 42.001278],
                            [123.713248, 41.999247],
                            [123.704283, 41.990641],
                            [123.697573, 41.989385],
                            [123.693383, 41.9822],
                            [123.699438, 41.978506],
                            [123.693494, 41.975421],
                            [123.696042, 41.971818],
                            [123.68297, 41.966996],
                            [123.672042, 41.960289],
                            [123.668228, 41.956224],
                            [123.653653, 41.961786],
                            [123.643742, 41.959753],
                            [123.636043, 41.954709],
                            [123.626452, 41.953933],
                            [123.626257, 41.949054],
                            [123.623807, 41.945653],
                            [123.614118, 41.9424],
                            [123.610346, 41.94253],
                            [123.615608, 41.934637],
                            [123.62048, 41.930977],
                            [123.628652, 41.930903],
                            [123.629473, 41.927224],
                            [123.637909, 41.921548],
                            [123.637519, 41.91382],
                            [123.633747, 41.91443],
                            [123.632313, 41.910713],
                            [123.628067, 41.908383],
                            [123.630239, 41.902021],
                            [123.635278, 41.896806],
                            [123.637937, 41.88506],
                            [123.640971, 41.883728],
                            [123.63763, 41.88025],
                            [123.632313, 41.878529],
                            [123.630169, 41.881915],
                            [123.622137, 41.882155],
                            [123.621984, 41.887964],
                            [123.607061, 41.883413],
                            [123.601506, 41.879213],
                            [123.596606, 41.879195],
                            [123.594894, 41.887353],
                            [123.589702, 41.893717],
                            [123.58288, 41.891238],
                            [123.576212, 41.88123],
                            [123.560969, 41.879713],
                            [123.558728, 41.883136],
                            [123.556153, 41.881304],
                            [123.566064, 41.87122],
                            [123.568236, 41.866687],
                            [123.567665, 41.856083],
                            [123.571159, 41.847661],
                            [123.578342, 41.831055],
                            [123.578885, 41.82726],
                            [123.576407, 41.816242],
                            [123.581502, 41.811501],
                            [123.569711, 41.802906],
                            [123.55973, 41.794441],
                            [123.541828, 41.788475],
                            [123.52607, 41.782064],
                            [123.519875, 41.776376],
                            [123.519486, 41.771595],
                            [123.513291, 41.761401],
                            [123.504298, 41.75673],
                            [123.485756, 41.753208],
                            [123.4563, 41.75293],
                            [123.445748, 41.748351],
                            [123.440556, 41.74481],
                            [123.432509, 41.743049],
                            [123.423739, 41.734519],
                            [123.419605, 41.733629],
                            [123.416487, 41.723281],
                            [123.411921, 41.724524],
                            [123.411726, 41.729179],
                            [123.405322, 41.726564],
                            [123.403819, 41.727899],
                            [123.399211, 41.722836],
                            [123.393629, 41.726193],
                            [123.392668, 41.729809],
                            [123.387852, 41.732739],
                            [123.380015, 41.734872],
                            [123.381755, 41.723949],
                            [123.375936, 41.713025],
                            [123.391109, 41.707664],
                            [123.416584, 41.701894],
                            [123.41142, 41.701189],
                            [123.40599, 41.698147],
                            [123.405308, 41.692914],
                            [123.402148, 41.691875],
                            [123.400186, 41.687032],
                            [123.402246, 41.686197],
                            [123.396065, 41.671907],
                            [123.390455, 41.670607],
                            [123.385082, 41.664259],
                            [123.379096, 41.663795],
                            [123.377384, 41.659414],
                            [123.378748, 41.656109],
                            [123.375017, 41.656128],
                            [123.371829, 41.64677],
                            [123.39665, 41.63925],
                            [123.394478, 41.634421],
                            [123.400422, 41.632248],
                            [123.406102, 41.636984],
                            [123.412422, 41.63509],
                            [123.419702, 41.636223],
                            [123.425535, 41.631747],
                            [123.430282, 41.636018],
                            [123.44217, 41.635015],
                            [123.45101, 41.635313],
                            [123.454434, 41.631022],
                            [123.458638, 41.631654],
                            [123.461715, 41.628013],
                            [123.461854, 41.619302],
                            [123.467534, 41.61607],
                            [123.478155, 41.613376],
                            [123.485129, 41.609734],
                            [123.488986, 41.612911],
                            [123.493913, 41.611537],
                            [123.49692, 41.613822],
                            [123.500804, 41.612837],
                            [123.504048, 41.615568],
                            [123.511217, 41.61711],
                            [123.514961, 41.620639],
                            [123.523551, 41.617221],
                            [123.526947, 41.618447],
                            [123.533671, 41.627475],
                            [123.534256, 41.632787],
                            [123.543374, 41.637244],
                            [123.546186, 41.634217],
                            [123.553229, 41.631672],
                            [123.560232, 41.631542],
                            [123.559661, 41.628664],
                            [123.56477, 41.631412],
                            [123.56999, 41.630799],
                            [123.570421, 41.634012],
                            [123.577256, 41.633827],
                            [123.577758, 41.629388],
                            [123.581363, 41.629945],
                            [123.580973, 41.623518],
                            [123.584022, 41.620751],
                            [123.583465, 41.616051],
                            [123.5903, 41.613562],
                            [123.595326, 41.608973],
                            [123.604778, 41.612763],
                            [123.614856, 41.611184],
                            [123.621218, 41.606873],
                            [123.625283, 41.610868],
                            [123.63273, 41.611054],
                            [123.635306, 41.614398],
                            [123.643588, 41.612837],
                            [123.65066, 41.617128],
                            [123.653681, 41.612948],
                            [123.660265, 41.610515],
                            [123.667128, 41.612837],
                            [123.673922, 41.612948],
                            [123.674604, 41.607468],
                            [123.678, 41.604736],
                            [123.686534, 41.608211],
                            [123.689137, 41.611332],
                            [123.697837, 41.61423],
                            [123.699271, 41.613079],
                            [123.709294, 41.617036],
                            [123.719679, 41.619933],
                            [123.724273, 41.613004],
                            [123.734908, 41.612967],
                            [123.742161, 41.616367],
                            [123.757585, 41.610719],
                            [123.759589, 41.619413],
                            [123.756917, 41.625655],
                            [123.754202, 41.626732],
                            [123.753924, 41.63288],
                            [123.749901, 41.636817],
                            [123.752977, 41.642945],
                            [123.765311, 41.646919],
                            [123.76538, 41.653269],
                            [123.769529, 41.657316],
                            [123.773942, 41.654921],
                            [123.778661, 41.656685],
                            [123.784744, 41.662941],
                            [123.786484, 41.669122],
                            [123.791161, 41.671907],
                            [123.796229, 41.680834],
                            [123.79151, 41.679887],
                            [123.790646, 41.686197],
                            [123.79776, 41.692654],
                            [123.801254, 41.69169],
                            [123.808562, 41.697293],
                            [123.807212, 41.703434],
                            [123.811862, 41.710131],
                            [123.808855, 41.715195],
                            [123.801226, 41.718515],
                            [123.801087, 41.728901],
                            [123.796744, 41.728678],
                            [123.796994, 41.732331],
                            [123.79002, 41.738803],
                            [123.785398, 41.746868],
                            [123.786039, 41.749945],
                            [123.781849, 41.752151],
                            [123.78654, 41.758102],
                            [123.789046, 41.766795],
                            [123.784869, 41.770186],
                            [123.778883, 41.76926],
                            [123.772633, 41.774096],
                            [123.780985, 41.778321],
                            [123.779886, 41.782379],
                            [123.786651, 41.785455],
                            [123.780081, 41.797201],
                            [123.771157, 41.797275],
                            [123.766452, 41.799331],
                            [123.76222, 41.796256],
                            [123.756485, 41.797275],
                            [123.755622, 41.794144],
                            [123.761858, 41.788642],
                            [123.760995, 41.779192],
                            [123.756875, 41.782713],
                            [123.754049, 41.779711],
                            [123.743957, 41.785511],
                            [123.73211, 41.778711],
                            [123.717076, 41.774949],
                            [123.710658, 41.779859],
                            [123.701095, 41.779693],
                            [123.696515, 41.778396],
                            [123.690974, 41.779285],
                            [123.692213, 41.783584],
                            [123.683958, 41.784899],
                            [123.679657, 41.783991],
                            [123.669397, 41.785307],
                            [123.666432, 41.789698],
                            [123.663815, 41.798627],
                            [123.667045, 41.79859],
                            [123.669523, 41.803666],
                            [123.676511, 41.80387],
                            [123.679476, 41.808333],
                            [123.685434, 41.811389],
                            [123.683722, 41.817408],
                            [123.679615, 41.821242],
                            [123.681174, 41.825353],
                            [123.675703, 41.827389],
                            [123.67381, 41.83224],
                            [123.676748, 41.837258],
                            [123.688817, 41.840497],
                            [123.699285, 41.840738],
                            [123.706691, 41.842552],
                            [123.720709, 41.849642],
                            [123.728045, 41.855435],
                            [123.733126, 41.861098],
                            [123.739808, 41.863763],
                            [123.737706, 41.866817],
                            [123.742857, 41.868408],
                            [123.740323, 41.871739],
                            [123.739098, 41.880398],
                            [123.732569, 41.881008],
                            [123.727641, 41.884616],
                            [123.719902, 41.887187],
                            [123.7156, 41.89183],
                            [123.719219, 41.895511],
                            [123.728825, 41.897397],
                            [123.729841, 41.908346],
                            [123.734226, 41.912211],
                            [123.733279, 41.91578],
                            [123.742537, 41.920439],
                            [123.740936, 41.924137],
                            [123.736453, 41.921604],
                            [123.730969, 41.923194],
                            [123.732792, 41.931809],
                            [123.723243, 41.946115],
                            [123.732472, 41.954783],
                            [123.727057, 41.957961],
                            [123.728129, 41.961952],
                            [123.732862, 41.96659],
                            [123.739711, 41.966183],
                            [123.750165, 41.971837],
                            [123.752003, 41.96986],
                            [123.763543, 41.972613],
                            [123.774665, 41.969657],
                            [123.776002, 41.973666],
                            [123.784521, 41.979983],
                            [123.785454, 41.98643],
                            [123.789477, 41.989791],
                            [123.777631, 41.993485],
                            [123.776712, 41.996403],
                            [123.772007, 41.99511],
                            [123.769376, 42.002681],
                            [123.762777, 41.999099],
                            [123.761051, 42.005266],
                            [123.765436, 42.009476],
                            [123.763668, 42.017839],
                            [123.773218, 42.023562],
                            [123.780401, 42.023285],
                            [123.78551, 42.019446],
                            [123.78835, 42.019575],
                            [123.792414, 42.013206],
                            [123.803203, 42.016566],
                            [123.807644, 42.022178],
                            [123.810637, 42.022344],
                            [123.813448, 42.030853],
                            [123.808214, 42.035578],
                            [123.808757, 42.040838],
                            [123.813838, 42.044565],
                            [123.813114, 42.050119],
                            [123.806043, 42.0513],
                            [123.803634, 42.054695],
                            [123.803788, 42.059289],
                            [123.795212, 42.060285],
                            [123.789658, 42.052407],
                            [123.79169, 42.050322],
                            [123.785635, 42.038549],
                            [123.773204, 42.044639],
                            [123.768401, 42.044565],
                            [123.76726, 42.047739],
                            [123.760049, 42.046097],
                            [123.759812, 42.049547],
                            [123.752017, 42.052961],
                            [123.755385, 42.058016],
                            [123.746142, 42.06534],
                            [123.740894, 42.066594],
                            [123.735395, 42.065192],
                            [123.72973, 42.074711],
                            [123.725136, 42.07626]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210113, "name": "沈北新区", "center": [123.521471, 42.052312], "centroid": [123.496836, 42.035269], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 7, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.486118, 41.904259],
                            [123.493176, 41.916205],
                            [123.495027, 41.913727],
                            [123.500929, 41.917018],
                            [123.503853, 41.920938],
                            [123.507263, 41.917481],
                            [123.51098, 41.922251],
                            [123.513611, 41.921641],
                            [123.517941, 41.928056],
                            [123.524581, 41.946633],
                            [123.560468, 41.946688],
                            [123.566315, 41.947261],
                            [123.558199, 41.936024],
                            [123.553591, 41.932456],
                            [123.546715, 41.930496],
                            [123.544961, 41.924599],
                            [123.546325, 41.921142],
                            [123.552116, 41.919773],
                            [123.547438, 41.914208],
                            [123.553021, 41.911693],
                            [123.559062, 41.906608],
                            [123.555234, 41.902502],
                            [123.559146, 41.898008],
                            [123.553299, 41.898748],
                            [123.546687, 41.895585],
                            [123.549359, 41.891959],
                            [123.55671, 41.888981],
                            [123.553313, 41.887409],
                            [123.556153, 41.881304],
                            [123.558728, 41.883136],
                            [123.560969, 41.879713],
                            [123.576212, 41.88123],
                            [123.58288, 41.891238],
                            [123.589702, 41.893717],
                            [123.594894, 41.887353],
                            [123.596606, 41.879195],
                            [123.601506, 41.879213],
                            [123.607061, 41.883413],
                            [123.621984, 41.887964],
                            [123.622137, 41.882155],
                            [123.630169, 41.881915],
                            [123.632313, 41.878529],
                            [123.63763, 41.88025],
                            [123.640971, 41.883728],
                            [123.637937, 41.88506],
                            [123.635278, 41.896806],
                            [123.630239, 41.902021],
                            [123.628067, 41.908383],
                            [123.632313, 41.910713],
                            [123.633747, 41.91443],
                            [123.637519, 41.91382],
                            [123.637909, 41.921548],
                            [123.629473, 41.927224],
                            [123.628652, 41.930903],
                            [123.62048, 41.930977],
                            [123.615608, 41.934637],
                            [123.610346, 41.94253],
                            [123.614118, 41.9424],
                            [123.623807, 41.945653],
                            [123.626257, 41.949054],
                            [123.626452, 41.953933],
                            [123.636043, 41.954709],
                            [123.643742, 41.959753],
                            [123.653653, 41.961786],
                            [123.668228, 41.956224],
                            [123.672042, 41.960289],
                            [123.68297, 41.966996],
                            [123.696042, 41.971818],
                            [123.693494, 41.975421],
                            [123.699438, 41.978506],
                            [123.693383, 41.9822],
                            [123.697573, 41.989385],
                            [123.704283, 41.990641],
                            [123.713248, 41.999247],
                            [123.717117, 42.001278],
                            [123.72128, 42.008424],
                            [123.717841, 42.017193],
                            [123.717493, 42.035559],
                            [123.718301, 42.04951],
                            [123.722226, 42.050452],
                            [123.724718, 42.060212],
                            [123.695986, 42.070413],
                            [123.713415, 42.071575],
                            [123.725136, 42.07626],
                            [123.716394, 42.08076],
                            [123.713373, 42.083858],
                            [123.707345, 42.084651],
                            [123.704881, 42.091271],
                            [123.698659, 42.091566],
                            [123.692742, 42.096158],
                            [123.680534, 42.097153],
                            [123.678223, 42.098389],
                            [123.662242, 42.093927],
                            [123.657662, 42.096545],
                            [123.654781, 42.094738],
                            [123.647765, 42.100693],
                            [123.641166, 42.100546],
                            [123.637992, 42.102666],
                            [123.632187, 42.10097],
                            [123.633037, 42.104196],
                            [123.628763, 42.11039],
                            [123.624921, 42.111294],
                            [123.620647, 42.11592],
                            [123.609121, 42.119183],
                            [123.590036, 42.129393],
                            [123.575489, 42.132231],
                            [123.573498, 42.134719],
                            [123.564477, 42.13997],
                            [123.560468, 42.140486],
                            [123.556153, 42.144595],
                            [123.551963, 42.1429],
                            [123.541731, 42.147008],
                            [123.538752, 42.155574],
                            [123.540506, 42.155685],
                            [123.542023, 42.182261],
                            [123.53516, 42.181598],
                            [123.521393, 42.191006],
                            [123.516451, 42.192498],
                            [123.504785, 42.192277],
                            [123.496586, 42.187471],
                            [123.491658, 42.182574],
                            [123.487245, 42.180493],
                            [123.469594, 42.181543],
                            [123.460949, 42.17495],
                            [123.460142, 42.170494],
                            [123.455075, 42.168431],
                            [123.443688, 42.172298],
                            [123.441126, 42.171875],
                            [123.43173, 42.163753],
                            [123.424032, 42.163053],
                            [123.419438, 42.165687],
                            [123.419187, 42.170899],
                            [123.415053, 42.170751],
                            [123.409485, 42.162482],
                            [123.409554, 42.151227],
                            [123.400144, 42.148371],
                            [123.388798, 42.149145],
                            [123.385513, 42.147929],
                            [123.377537, 42.139896],
                            [123.373514, 42.138717],
                            [123.368363, 42.140504],
                            [123.361653, 42.148777],
                            [123.357908, 42.15014],
                            [123.350753, 42.149551],
                            [123.344113, 42.151909],
                            [123.34396, 42.149532],
                            [123.337849, 42.150195],
                            [123.334605, 42.138588],
                            [123.335594, 42.134571],
                            [123.331584, 42.136027],
                            [123.325348, 42.135032],
                            [123.320128, 42.128766],
                            [123.324012, 42.12344],
                            [123.309896, 42.109487],
                            [123.303646, 42.100177],
                            [123.302991, 42.096305],
                            [123.296059, 42.091198],
                            [123.291089, 42.083895],
                            [123.285326, 42.087583],
                            [123.272951, 42.072755],
                            [123.277934, 42.069823],
                            [123.279897, 42.071556],
                            [123.28747, 42.066041],
                            [123.285076, 42.062425],
                            [123.288166, 42.059289],
                            [123.285076, 42.052334],
                            [123.285883, 42.049824],
                            [123.278853, 42.047462],
                            [123.272742, 42.032496],
                            [123.279994, 42.031684],
                            [123.279772, 42.028029],
                            [123.287038, 42.021882],
                            [123.288695, 42.014978],
                            [123.294917, 42.012079],
                            [123.295474, 42.006134],
                            [123.301084, 42.007814],
                            [123.30533, 41.999357],
                            [123.303047, 41.994574],
                            [123.308755, 41.99306],
                            [123.312012, 41.984102],
                            [123.319682, 41.986928],
                            [123.334285, 41.980482],
                            [123.342805, 41.974793],
                            [123.339951, 41.967403],
                            [123.339575, 41.962174],
                            [123.341872, 41.959033],
                            [123.347663, 41.95676],
                            [123.363699, 41.95506],
                            [123.363435, 41.950828],
                            [123.373472, 41.950643],
                            [123.377161, 41.95397],
                            [123.379472, 41.952768],
                            [123.374502, 41.946707],
                            [123.378817, 41.946097],
                            [123.378302, 41.942955],
                            [123.382618, 41.943398],
                            [123.383912, 41.93778],
                            [123.379834, 41.937391],
                            [123.380878, 41.934674],
                            [123.376604, 41.931975],
                            [123.372038, 41.926318],
                            [123.376534, 41.921364],
                            [123.372637, 41.91798],
                            [123.374015, 41.909862],
                            [123.375685, 41.909419],
                            [123.388075, 41.904999],
                            [123.393476, 41.909326],
                            [123.410737, 41.907458],
                            [123.408983, 41.909881],
                            [123.418255, 41.90646],
                            [123.421916, 41.907921],
                            [123.435669, 41.907403],
                            [123.445971, 41.902169],
                            [123.453335, 41.900394],
                            [123.455311, 41.903131],
                            [123.459098, 41.902373],
                            [123.465543, 41.904259],
                            [123.467854, 41.906867],
                            [123.473297, 41.905239],
                            [123.472977, 41.902132],
                            [123.47899, 41.900116],
                            [123.486118, 41.904259]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210114, "name": "于洪区", "center": [123.310829, 41.795833], "centroid": [123.253673, 41.906113], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 8, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.375685, 41.909419],
                            [123.374015, 41.909862],
                            [123.372637, 41.91798],
                            [123.376534, 41.921364],
                            [123.372038, 41.926318],
                            [123.376604, 41.931975],
                            [123.380878, 41.934674],
                            [123.379834, 41.937391],
                            [123.383912, 41.93778],
                            [123.382618, 41.943398],
                            [123.378302, 41.942955],
                            [123.378817, 41.946097],
                            [123.374502, 41.946707],
                            [123.379472, 41.952768],
                            [123.377161, 41.95397],
                            [123.373472, 41.950643],
                            [123.363435, 41.950828],
                            [123.363699, 41.95506],
                            [123.347663, 41.95676],
                            [123.341872, 41.959033],
                            [123.339575, 41.962174],
                            [123.339951, 41.967403],
                            [123.342805, 41.974793],
                            [123.334285, 41.980482],
                            [123.319682, 41.986928],
                            [123.312012, 41.984102],
                            [123.308755, 41.99306],
                            [123.303047, 41.994574],
                            [123.30533, 41.999357],
                            [123.301084, 42.007814],
                            [123.295474, 42.006134],
                            [123.294917, 42.012079],
                            [123.288695, 42.014978],
                            [123.287038, 42.021882],
                            [123.279772, 42.028029],
                            [123.279994, 42.031684],
                            [123.272742, 42.032496],
                            [123.278853, 42.047462],
                            [123.285883, 42.049824],
                            [123.285076, 42.052334],
                            [123.288166, 42.059289],
                            [123.285076, 42.062425],
                            [123.28747, 42.066041],
                            [123.279897, 42.071556],
                            [123.277934, 42.069823],
                            [123.272951, 42.072755],
                            [123.268357, 42.067332],
                            [123.262733, 42.069952],
                            [123.257666, 42.066484],
                            [123.249313, 42.056743],
                            [123.250872, 42.05285],
                            [123.245332, 42.052776],
                            [123.240321, 42.048182],
                            [123.234404, 42.04951],
                            [123.228864, 42.036888],
                            [123.220484, 42.037054],
                            [123.216572, 42.030742],
                            [123.214233, 42.023839],
                            [123.207496, 42.019353],
                            [123.204461, 42.021679],
                            [123.186935, 42.015199],
                            [123.187812, 42.011082],
                            [123.182397, 42.008608],
                            [123.176494, 42.001536],
                            [123.180155, 41.999376],
                            [123.174183, 41.990401],
                            [123.168894, 41.991952],
                            [123.15663, 41.993282],
                            [123.15553, 41.986485],
                            [123.151604, 41.98667],
                            [123.152077, 41.977674],
                            [123.1463, 41.980187],
                            [123.142625, 41.97422],
                            [123.132909, 41.975809],
                            [123.130793, 41.971079],
                            [123.135136, 41.968142],
                            [123.129248, 41.95894],
                            [123.121675, 41.956557],
                            [123.116928, 41.962174],
                            [123.113392, 41.960881],
                            [123.112264, 41.956594],
                            [123.114339, 41.95214],
                            [123.119113, 41.948315],
                            [123.114102, 41.947058],
                            [123.11729, 41.942844],
                            [123.114366, 41.936744],
                            [123.133633, 41.928666],
                            [123.133229, 41.922658],
                            [123.129317, 41.921585],
                            [123.128454, 41.917259],
                            [123.125475, 41.917092],
                            [123.123206, 41.906201],
                            [123.127605, 41.900967],
                            [123.13508, 41.898267],
                            [123.141567, 41.903112],
                            [123.142055, 41.901559],
                            [123.158049, 41.889518],
                            [123.147818, 41.881711],
                            [123.148597, 41.878695],
                            [123.143196, 41.878843],
                            [123.138547, 41.882322],
                            [123.135498, 41.878159],
                            [123.140899, 41.876753],
                            [123.139257, 41.870795],
                            [123.130612, 41.857489],
                            [123.141011, 41.848735],
                            [123.15663, 41.847032],
                            [123.162337, 41.832555],
                            [123.161056, 41.828149],
                            [123.161015, 41.817371],
                            [123.16455, 41.819075],
                            [123.16739, 41.814075],
                            [123.171539, 41.813223],
                            [123.173933, 41.808982],
                            [123.171998, 41.805592],
                            [123.164592, 41.801054],
                            [123.164481, 41.798461],
                            [123.158578, 41.790995],
                            [123.161376, 41.790309],
                            [123.159678, 41.782139],
                            [123.16313, 41.781008],
                            [123.15837, 41.774059],
                            [123.161056, 41.769278],
                            [123.159859, 41.765738],
                            [123.163757, 41.763403],
                            [123.173474, 41.768388],
                            [123.190262, 41.773911],
                            [123.206229, 41.777302],
                            [123.21454, 41.782657],
                            [123.221945, 41.78212],
                            [123.225829, 41.788068],
                            [123.2422, 41.795663],
                            [123.253197, 41.797757],
                            [123.269888, 41.798016],
                            [123.273118, 41.796868],
                            [123.272324, 41.788846],
                            [123.27586, 41.782083],
                            [123.30501, 41.781027],
                            [123.319738, 41.778785],
                            [123.316787, 41.783769],
                            [123.317274, 41.834333],
                            [123.330457, 41.836795],
                            [123.336234, 41.836647],
                            [123.353997, 41.825501],
                            [123.353384, 41.831389],
                            [123.356656, 41.838183],
                            [123.363574, 41.834407],
                            [123.365815, 41.837239],
                            [123.359871, 41.842201],
                            [123.358674, 41.846588],
                            [123.352855, 41.846976],
                            [123.356475, 41.84979],
                            [123.371885, 41.855713],
                            [123.374989, 41.853677],
                            [123.381741, 41.856175],
                            [123.388979, 41.845199],
                            [123.389856, 41.83872],
                            [123.397457, 41.83909],
                            [123.406213, 41.837091],
                            [123.418018, 41.844626],
                            [123.418978, 41.846976],
                            [123.410375, 41.845921],
                            [123.409164, 41.855213],
                            [123.418032, 41.857767],
                            [123.415484, 41.887409],
                            [123.418784, 41.888204],
                            [123.414357, 41.895381],
                            [123.404668, 41.891848],
                            [123.401745, 41.895955],
                            [123.392696, 41.895881],
                            [123.393991, 41.893643],
                            [123.377592, 41.893809],
                            [123.378316, 41.901855],
                            [123.372678, 41.89945],
                            [123.375685, 41.909419]
                        ]
                    ],
                    [
                        [
                            [123.309478, 41.715084],
                            [123.328466, 41.721093],
                            [123.337737, 41.726249],
                            [123.35163, 41.743864],
                            [123.357992, 41.746961],
                            [123.375991, 41.749371],
                            [123.374989, 41.759955],
                            [123.372191, 41.763069],
                            [123.352911, 41.766368],
                            [123.328452, 41.775245],
                            [123.32461, 41.770946],
                            [123.310063, 41.763458],
                            [123.296588, 41.761401],
                            [123.286245, 41.758287],
                            [123.289001, 41.751651],
                            [123.302421, 41.729976],
                            [123.309478, 41.715084]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210115, "name": "辽中区", "center": [122.731269, 41.512725], "centroid": [122.744639, 41.553183], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 9, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [122.969229, 41.509796],
                            [122.971318, 41.513349],
                            [122.976343, 41.515173],
                            [122.977011, 41.556],
                            [122.957216, 41.555796],
                            [122.93653, 41.566319],
                            [122.911152, 41.571878],
                            [122.901199, 41.568699],
                            [122.914257, 41.59054],
                            [122.917319, 41.622367],
                            [122.947875, 41.63366],
                            [122.923848, 41.642889],
                            [122.905682, 41.636055],
                            [122.901881, 41.639825],
                            [122.925212, 41.647253],
                            [122.946163, 41.643149],
                            [123.019775, 41.687162],
                            [123.022741, 41.688646],
                            [123.024188, 41.693842],
                            [123.030647, 41.697441],
                            [123.034114, 41.704733],
                            [123.034545, 41.702339],
                            [123.039445, 41.70963],
                            [123.035297, 41.709296],
                            [123.038151, 41.716289],
                            [123.03332, 41.717643],
                            [123.037315, 41.721278],
                            [123.032095, 41.726453],
                            [123.026416, 41.729735],
                            [123.027209, 41.73413],
                            [123.017701, 41.732795],
                            [123.015697, 41.734093],
                            [123.004268, 41.731757],
                            [122.995846, 41.724394],
                            [122.997962, 41.72202],
                            [122.991391, 41.715863],
                            [122.985294, 41.712858],
                            [122.983707, 41.709778],
                            [122.977401, 41.711262],
                            [122.97374, 41.71896],
                            [122.970761, 41.711856],
                            [122.965749, 41.713655],
                            [122.957898, 41.720722],
                            [122.952928, 41.731886],
                            [122.946316, 41.73782],
                            [122.938326, 41.739452],
                            [122.936432, 41.741399],
                            [122.927217, 41.742808],
                            [122.925045, 41.741324],
                            [122.917375, 41.743976],
                            [122.915746, 41.748499],
                            [122.906921, 41.745756],
                            [122.894851, 41.748889],
                            [122.894517, 41.74544],
                            [122.887348, 41.74112],
                            [122.868541, 41.744606],
                            [122.851085, 41.750835],
                            [122.826473, 41.754969],
                            [122.811021, 41.75838],
                            [122.800358, 41.759807],
                            [122.785992, 41.764422],
                            [122.784836, 41.769278],
                            [122.782052, 41.765794],
                            [122.773588, 41.763922],
                            [122.774702, 41.755377],
                            [122.769899, 41.749204],
                            [122.774312, 41.74709],
                            [122.773936, 41.743049],
                            [122.762869, 41.740268],
                            [122.761227, 41.743234],
                            [122.756229, 41.739359],
                            [122.747111, 41.728011],
                            [122.742086, 41.7284],
                            [122.736768, 41.722818],
                            [122.733761, 41.714397],
                            [122.728402, 41.711281],
                            [122.723474, 41.725155],
                            [122.718351, 41.727362],
                            [122.712491, 41.734297],
                            [122.714105, 41.737449],
                            [122.709414, 41.737987],
                            [122.711544, 41.744013],
                            [122.705586, 41.744736],
                            [122.699127, 41.739266],
                            [122.692793, 41.747517],
                            [122.68735, 41.746404],
                            [122.686264, 41.755581],
                            [122.680515, 41.75508],
                            [122.676868, 41.75026],
                            [122.672413, 41.75267],
                            [122.671564, 41.756471],
                            [122.662585, 41.754821],
                            [122.652701, 41.763588],
                            [122.641272, 41.76811],
                            [122.640716, 41.773652],
                            [122.638043, 41.772669],
                            [122.62738, 41.777821],
                            [122.627992, 41.786548],
                            [122.622159, 41.783621],
                            [122.616549, 41.786196],
                            [122.612401, 41.775672],
                            [122.601097, 41.765608],
                            [122.60036, 41.766572],
                            [122.588666, 41.758584],
                            [122.57991, 41.754969],
                            [122.574537, 41.749408],
                            [122.570458, 41.740638],
                            [122.571224, 41.735539],
                            [122.575442, 41.731497],
                            [122.572184, 41.728437],
                            [122.574718, 41.716753],
                            [122.572532, 41.712505],
                            [122.555967, 41.70809],
                            [122.549493, 41.699074],
                            [122.547962, 41.688294],
                            [122.550649, 41.678922],
                            [122.53773, 41.671127],
                            [122.53297, 41.669939],
                            [122.526636, 41.676268],
                            [122.51749, 41.676974],
                            [122.515847, 41.658133],
                            [122.526552, 41.655051],
                            [122.525828, 41.651468],
                            [122.51852, 41.648144],
                            [122.519884, 41.642462],
                            [122.515611, 41.63535],
                            [122.516362, 41.630279],
                            [122.510126, 41.627753],
                            [122.500437, 41.629741],
                            [122.501648, 41.627029],
                            [122.496122, 41.615902],
                            [122.493532, 41.61607],
                            [122.487101, 41.605312],
                            [122.488855, 41.599199],
                            [122.484442, 41.597583],
                            [122.488382, 41.591989],
                            [122.492627, 41.590911],
                            [122.491667, 41.587176],
                            [122.486405, 41.584295],
                            [122.485834, 41.5816],
                            [122.479667, 41.583235],
                            [122.476981, 41.580373],
                            [122.480322, 41.579685],
                            [122.481324, 41.573793],
                            [122.488744, 41.56855],
                            [122.487352, 41.566301],
                            [122.497736, 41.570447],
                            [122.494688, 41.564107],
                            [122.500381, 41.562266],
                            [122.500214, 41.558288],
                            [122.492948, 41.5515],
                            [122.495885, 41.543448],
                            [122.506576, 41.537441],
                            [122.520789, 41.542555],
                            [122.553489, 41.550645],
                            [122.566101, 41.5478],
                            [122.572476, 41.540063],
                            [122.582945, 41.535209],
                            [122.595125, 41.534446],
                            [122.598912, 41.530223],
                            [122.599135, 41.514615],
                            [122.604536, 41.513015],
                            [122.624999, 41.520959],
                            [122.634618, 41.51694],
                            [122.631946, 41.50505],
                            [122.636901, 41.501663],
                            [122.632321, 41.496862],
                            [122.624693, 41.492414],
                            [122.623899, 41.488914],
                            [122.636386, 41.48171],
                            [122.637054, 41.479216],
                            [122.632614, 41.474115],
                            [122.62518, 41.471415],
                            [122.62582, 41.466015],
                            [122.634549, 41.448212],
                            [122.635941, 41.440072],
                            [122.634758, 41.435341],
                            [122.62589, 41.433366],
                            [122.629746, 41.429714],
                            [122.64304, 41.432546],
                            [122.64659, 41.429659],
                            [122.647105, 41.424498],
                            [122.640298, 41.418423],
                            [122.631194, 41.416784],
                            [122.634048, 41.405229],
                            [122.649138, 41.395872],
                            [122.648776, 41.392256],
                            [122.644293, 41.385936],
                            [122.645031, 41.38217],
                            [122.640813, 41.363617],
                            [122.642623, 41.357611],
                            [122.649068, 41.356958],
                            [122.65639, 41.35873],
                            [122.658979, 41.354832],
                            [122.641579, 41.339722],
                            [122.629551, 41.330188],
                            [122.620322, 41.317517],
                            [122.604355, 41.318096],
                            [122.601988, 41.315128],
                            [122.607682, 41.307905],
                            [122.606721, 41.303462],
                            [122.59468, 41.303462],
                            [122.589515, 41.301409],
                            [122.588471, 41.296835],
                            [122.595195, 41.293512],
                            [122.576416, 41.279975],
                            [122.569539, 41.268453],
                            [122.563177, 41.261505],
                            [122.554073, 41.254855],
                            [122.552347, 41.249624],
                            [122.55999, 41.24415],
                            [122.578532, 41.244579],
                            [122.589265, 41.247064],
                            [122.596169, 41.245065],
                            [122.601083, 41.238974],
                            [122.606206, 41.229388],
                            [122.60984, 41.229014],
                            [122.608517, 41.225856],
                            [122.614823, 41.225127],
                            [122.612471, 41.220548],
                            [122.615129, 41.218959],
                            [122.613041, 41.2158],
                            [122.616814, 41.20535],
                            [122.622535, 41.199854],
                            [122.633031, 41.203088],
                            [122.637041, 41.210174],
                            [122.643681, 41.212454],
                            [122.651602, 41.209893],
                            [122.659898, 41.212828],
                            [122.665383, 41.217651],
                            [122.669559, 41.224136],
                            [122.666664, 41.229406],
                            [122.667736, 41.234359],
                            [122.666956, 41.245607],
                            [122.675448, 41.253547],
                            [122.676742, 41.260888],
                            [122.680793, 41.263783],
                            [122.692514, 41.26821],
                            [122.69509, 41.275139],
                            [122.698208, 41.278986],
                            [122.704946, 41.281507],
                            [122.705182, 41.287015],
                            [122.698305, 41.297078],
                            [122.698431, 41.299262],
                            [122.704973, 41.302212],
                            [122.709637, 41.308502],
                            [122.718991, 41.309566],
                            [122.724128, 41.314606],
                            [122.717029, 41.320914],
                            [122.717808, 41.322761],
                            [122.731172, 41.32347],
                            [122.737784, 41.33002],
                            [122.747807, 41.329441],
                            [122.747626, 41.346028],
                            [122.749394, 41.351848],
                            [122.755937, 41.355224],
                            [122.7568, 41.358861],
                            [122.76564, 41.368335],
                            [122.772085, 41.366712],
                            [122.770595, 41.360875],
                            [122.773769, 41.360334],
                            [122.780994, 41.366339],
                            [122.798019, 41.366862],
                            [122.803657, 41.373407],
                            [122.815128, 41.37268],
                            [122.81968, 41.374917],
                            [122.831721, 41.377751],
                            [122.84038, 41.378012],
                            [122.843136, 41.38506],
                            [122.850862, 41.39302],
                            [122.852184, 41.410559],
                            [122.849651, 41.415889],
                            [122.854022, 41.419821],
                            [122.861776, 41.424125],
                            [122.865089, 41.423454],
                            [122.865479, 41.418032],
                            [122.871423, 41.414361],
                            [122.876225, 41.41587],
                            [122.881738, 41.422299],
                            [122.886847, 41.423995],
                            [122.884035, 41.419784],
                            [122.886722, 41.417902],
                            [122.892708, 41.420678],
                            [122.898429, 41.417734],
                            [122.90919, 41.419635],
                            [122.914591, 41.417808],
                            [122.922512, 41.422784],
                            [122.925407, 41.422374],
                            [122.929667, 41.428094],
                            [122.936934, 41.427218],
                            [122.912628, 41.432938],
                            [122.924266, 41.442494],
                            [122.928553, 41.448529],
                            [122.922512, 41.447653],
                            [122.916804, 41.452086],
                            [122.919574, 41.45488],
                            [122.933634, 41.447933],
                            [122.940316, 41.456053],
                            [122.935277, 41.458362],
                            [122.938409, 41.462831],
                            [122.937978, 41.466946],
                            [122.933314, 41.469199],
                            [122.934414, 41.478695],
                            [122.939077, 41.483535],
                            [122.94665, 41.48467],
                            [122.939982, 41.478136],
                            [122.944534, 41.474711],
                            [122.959708, 41.468101],
                            [122.960223, 41.476386],
                            [122.965429, 41.474282],
                            [122.975772, 41.478676],
                            [122.978306, 41.482809],
                            [122.97644, 41.488859],
                            [122.98205, 41.496173],
                            [122.971318, 41.4993],
                            [122.969216, 41.4926],
                            [122.957828, 41.496397],
                            [122.961698, 41.503952],
                            [122.969229, 41.509796]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210123, "name": "康平县", "center": [123.352703, 42.741533], "centroid": [123.267489, 42.759025], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 10, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [122.968339, 42.572364],
                            [122.971666, 42.573609],
                            [122.97438, 42.570571],
                            [122.977916, 42.572712],
                            [122.98379, 42.571614],
                            [122.992714, 42.566673],
                            [122.996291, 42.57101],
                            [123.002611, 42.568576],
                            [123.008639, 42.564056],
                            [123.007414, 42.567442],
                            [123.014012, 42.568375],
                            [123.023743, 42.564184],
                            [123.03023, 42.570589],
                            [123.031719, 42.573828],
                            [123.041868, 42.57187],
                            [123.048466, 42.569345],
                            [123.055621, 42.564752],
                            [123.058002, 42.558126],
                            [123.061649, 42.554594],
                            [123.057876, 42.545807],
                            [123.061412, 42.541541],
                            [123.064892, 42.54372],
                            [123.068595, 42.54145],
                            [123.077323, 42.540205],
                            [123.088028, 42.534547],
                            [123.091355, 42.530281],
                            [123.097453, 42.528559],
                            [123.09414, 42.52552],
                            [123.104775, 42.525483],
                            [123.112752, 42.521784],
                            [123.115104, 42.518231],
                            [123.121577, 42.52041],
                            [123.130208, 42.525446],
                            [123.133452, 42.525465],
                            [123.132589, 42.530079],
                            [123.135387, 42.532515],
                            [123.14385, 42.534419],
                            [123.160305, 42.535829],
                            [123.159943, 42.532112],
                            [123.1654, 42.533577],
                            [123.169924, 42.532368],
                            [123.173014, 42.534144],
                            [123.178165, 42.532478],
                            [123.195245, 42.535389],
                            [123.203375, 42.530775],
                            [123.205547, 42.525208],
                            [123.213718, 42.515776],
                            [123.22072, 42.519],
                            [123.233834, 42.531929],
                            [123.245541, 42.54718],
                            [123.266157, 42.566069],
                            [123.289948, 42.568595],
                            [123.304801, 42.578037],
                            [123.312986, 42.584935],
                            [123.327561, 42.59485],
                            [123.335956, 42.599661],
                            [123.338837, 42.606684],
                            [123.349946, 42.621058],
                            [123.359774, 42.624001],
                            [123.370326, 42.617492],
                            [123.386655, 42.611128],
                            [123.40897, 42.617656],
                            [123.412199, 42.612262],
                            [123.422264, 42.612079],
                            [123.426287, 42.616486],
                            [123.443715, 42.616852],
                            [123.459362, 42.613798],
                            [123.465627, 42.614968],
                            [123.494707, 42.629578],
                            [123.505746, 42.632868],
                            [123.517537, 42.638426],
                            [123.529954, 42.638462],
                            [123.539615, 42.639504],
                            [123.548594, 42.639193],
                            [123.570658, 42.644878],
                            [123.574361, 42.644275],
                            [123.586597, 42.634002],
                            [123.592221, 42.635117],
                            [123.597539, 42.640162],
                            [123.593655, 42.64316],
                            [123.597497, 42.643343],
                            [123.598305, 42.650471],
                            [123.604847, 42.655752],
                            [123.620842, 42.657068],
                            [123.625575, 42.664067],
                            [123.62577, 42.675376],
                            [123.619854, 42.6785],
                            [123.611265, 42.68029],
                            [123.607047, 42.678792],
                            [123.603831, 42.674956],
                            [123.603358, 42.670553],
                            [123.597803, 42.665382],
                            [123.592639, 42.663683],
                            [123.589841, 42.665656],
                            [123.586291, 42.672709],
                            [123.579303, 42.676271],
                            [123.582365, 42.680162],
                            [123.588727, 42.677495],
                            [123.589075, 42.682555],
                            [123.58327, 42.688437],
                            [123.569336, 42.692875],
                            [123.568403, 42.696729],
                            [123.574152, 42.696893],
                            [123.580751, 42.701075],
                            [123.582602, 42.706279],
                            [123.575739, 42.70869],
                            [123.571229, 42.711994],
                            [123.569169, 42.717344],
                            [123.559494, 42.724755],
                            [123.559313, 42.729483],
                            [123.564477, 42.732823],
                            [123.572454, 42.741438],
                            [123.575419, 42.751164],
                            [123.585623, 42.752477],
                            [123.58682, 42.760159],
                            [123.578481, 42.765978],
                            [123.578301, 42.771323],
                            [123.586082, 42.775135],
                            [123.589548, 42.77508],
                            [123.598889, 42.771633],
                            [123.604346, 42.774825],
                            [123.603539, 42.779111],
                            [123.593572, 42.790144],
                            [123.600629, 42.796617],
                            [123.597163, 42.801267],
                            [123.598332, 42.804603],
                            [123.594727, 42.807957],
                            [123.592625, 42.815832],
                            [123.592012, 42.823433],
                            [123.605293, 42.829301],
                            [123.603302, 42.833328],
                            [123.598402, 42.835059],
                            [123.592388, 42.841983],
                            [123.593906, 42.846793],
                            [123.58611, 42.848742],
                            [123.585692, 42.852604],
                            [123.593878, 42.859581],
                            [123.603497, 42.866374],
                            [123.600936, 42.870472],
                            [123.589688, 42.872384],
                            [123.583966, 42.875334],
                            [123.582393, 42.878175],
                            [123.585776, 42.886259],
                            [123.599557, 42.893523],
                            [123.599363, 42.898529],
                            [123.601799, 42.900368],
                            [123.598569, 42.905137],
                            [123.591316, 42.906356],
                            [123.585999, 42.909614],
                            [123.584634, 42.914619],
                            [123.588755, 42.921825],
                            [123.594379, 42.921334],
                            [123.601075, 42.923372],
                            [123.603539, 42.930267],
                            [123.59314, 42.938872],
                            [123.584356, 42.943929],
                            [123.582157, 42.950077],
                            [123.574695, 42.949368],
                            [123.566524, 42.951641],
                            [123.55831, 42.957297],
                            [123.554914, 42.961225],
                            [123.546812, 42.977353],
                            [123.554106, 42.984388],
                            [123.563238, 42.98517],
                            [123.566482, 42.988114],
                            [123.561262, 42.998983],
                            [123.561944, 43.005398],
                            [123.558185, 43.006397],
                            [123.557517, 43.010177],
                            [123.54961, 43.007887],
                            [123.537624, 43.007179],
                            [123.52859, 43.009777],
                            [123.533462, 43.01154],
                            [123.528103, 43.014211],
                            [123.522367, 43.020333],
                            [123.513458, 43.023458],
                            [123.512873, 43.025674],
                            [123.506915, 43.023549],
                            [123.50221, 43.025874],
                            [123.500345, 43.024021],
                            [123.493788, 43.025765],
                            [123.489988, 43.032159],
                            [123.477153, 43.041258],
                            [123.471863, 43.042711],
                            [123.462411, 43.038988],
                            [123.448964, 43.038334],
                            [123.441906, 43.031414],
                            [123.435363, 43.027908],
                            [123.423753, 43.029652],
                            [123.411726, 43.028635],
                            [123.407536, 43.023222],
                            [123.4029, 43.020587],
                            [123.383021, 43.016609],
                            [123.383787, 43.014828],
                            [123.367987, 43.010558],
                            [123.351101, 43.007324],
                            [123.332949, 43.005307],
                            [123.321937, 43.000782],
                            [123.311594, 42.99971],
                            [123.284978, 42.998293],
                            [123.27231, 42.99673],
                            [123.258974, 42.993022],
                            [123.248798, 42.984624],
                            [123.240432, 42.979462],
                            [123.232539, 42.968008],
                            [123.231356, 42.962953],
                            [123.219328, 42.953533],
                            [123.211867, 42.953606],
                            [123.206229, 42.951678],
                            [123.198197, 42.940546],
                            [123.19164, 42.936235],
                            [123.184123, 42.924445],
                            [123.186211, 42.914601],
                            [123.188745, 42.895835],
                            [123.187297, 42.887079],
                            [123.182146, 42.883146],
                            [123.170035, 42.859727],
                            [123.170703, 42.852131],
                            [123.182327, 42.846028],
                            [123.189413, 42.848177],
                            [123.21227, 42.838102],
                            [123.21898, 42.836353],
                            [123.227444, 42.832326],
                            [123.221611, 42.825328],
                            [123.218284, 42.82715],
                            [123.206827, 42.824763],
                            [123.187255, 42.825401],
                            [123.178805, 42.822594],
                            [123.180684, 42.819241],
                            [123.16778, 42.811585],
                            [123.150351, 42.808286],
                            [123.132338, 42.802725],
                            [123.117499, 42.801103],
                            [123.107907, 42.794101],
                            [123.104176, 42.794922],
                            [123.096186, 42.789816],
                            [123.091175, 42.790618],
                            [123.087193, 42.786406],
                            [123.083449, 42.788704],
                            [123.075959, 42.780917],
                            [123.071184, 42.779148],
                            [123.065797, 42.773712],
                            [123.059143, 42.773202],
                            [123.058475, 42.769079],
                            [123.049733, 42.769554],
                            [123.047965, 42.771524],
                            [123.031107, 42.767784],
                            [123.021752, 42.772198],
                            [123.015544, 42.772107],
                            [123.013107, 42.774953],
                            [123.00772, 42.773293],
                            [122.995665, 42.775208],
                            [122.988496, 42.77902],
                            [122.979002, 42.776777],
                            [122.969118, 42.766781],
                            [122.973698, 42.763625],
                            [122.971596, 42.761271],
                            [122.961879, 42.756546],
                            [122.952887, 42.754758],
                            [122.948502, 42.759082],
                            [122.945954, 42.753682],
                            [122.938952, 42.760961],
                            [122.932256, 42.770429],
                            [122.92886, 42.772144],
                            [122.915426, 42.770958],
                            [122.90344, 42.77125],
                            [122.887306, 42.770119],
                            [122.878731, 42.759429],
                            [122.877868, 42.751127],
                            [122.883562, 42.751967],
                            [122.883102, 42.745635],
                            [122.879107, 42.747606],
                            [122.8734, 42.740616],
                            [122.875432, 42.739905],
                            [122.866592, 42.731235],
                            [122.858518, 42.726143],
                            [122.856876, 42.721104],
                            [122.850876, 42.714441],
                            [122.856361, 42.711775],
                            [122.8554, 42.707101],
                            [122.846533, 42.708178],
                            [122.849052, 42.711775],
                            [122.846547, 42.720119],
                            [122.839753, 42.718275],
                            [122.838654, 42.71351],
                            [122.836106, 42.716504],
                            [122.814459, 42.719206],
                            [122.805091, 42.724755],
                            [122.800274, 42.724901],
                            [122.79291, 42.721944],
                            [122.789096, 42.718494],
                            [122.783834, 42.718859],
                            [122.781036, 42.713784],
                            [122.774939, 42.709749],
                            [122.770317, 42.710187],
                            [122.761589, 42.705932],
                            [122.750939, 42.697934],
                            [122.751148, 42.691816],
                            [122.747334, 42.689204],
                            [122.756243, 42.679742],
                            [122.769524, 42.67797],
                            [122.779505, 42.673074],
                            [122.780758, 42.669292],
                            [122.791824, 42.662788],
                            [122.792743, 42.651001],
                            [122.794455, 42.650233],
                            [122.811035, 42.652152],
                            [122.818817, 42.650361],
                            [122.824914, 42.652664],
                            [122.829355, 42.650983],
                            [122.842816, 42.648844],
                            [122.838695, 42.645225],
                            [122.842551, 42.63338],
                            [122.862917, 42.621039],
                            [122.864476, 42.617967],
                            [122.873497, 42.608586],
                            [122.877102, 42.610982],
                            [122.879427, 42.608019],
                            [122.881014, 42.610927],
                            [122.886513, 42.61122],
                            [122.892053, 42.608897],
                            [122.903078, 42.61367],
                            [122.911152, 42.608988],
                            [122.92673, 42.604398],
                            [122.928832, 42.601984],
                            [122.92286, 42.596807],
                            [122.923249, 42.593789],
                            [122.927398, 42.593057],
                            [122.930238, 42.587715],
                            [122.950687, 42.580269],
                            [122.954112, 42.57725],
                            [122.968339, 42.572364]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210124, "name": "法库县", "center": [123.416722, 42.507045], "centroid": [123.239321, 42.408583], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 11, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [123.469594, 42.181543],
                            [123.470067, 42.187214],
                            [123.462968, 42.190233],
                            [123.464777, 42.193326],
                            [123.457775, 42.196143],
                            [123.458123, 42.200174],
                            [123.454295, 42.200487],
                            [123.454782, 42.203616],
                            [123.44959, 42.204721],
                            [123.451316, 42.211089],
                            [123.45662, 42.215469],
                            [123.459334, 42.220696],
                            [123.463177, 42.220346],
                            [123.465251, 42.230264],
                            [123.476791, 42.23306],
                            [123.468745, 42.249451],
                            [123.473617, 42.261939],
                            [123.482359, 42.271446],
                            [123.490197, 42.275877],
                            [123.493259, 42.273634],
                            [123.497937, 42.275987],
                            [123.515783, 42.298249],
                            [123.524233, 42.316313],
                            [123.528075, 42.321623],
                            [123.528659, 42.33968],
                            [123.522882, 42.339809],
                            [123.521449, 42.345411],
                            [123.516994, 42.349451],
                            [123.511843, 42.350443],
                            [123.509017, 42.354446],
                            [123.505788, 42.35373],
                            [123.499649, 42.363425],
                            [123.496892, 42.362341],
                            [123.487914, 42.363039],
                            [123.483014, 42.368327],
                            [123.482485, 42.371448],
                            [123.471042, 42.376771],
                            [123.465877, 42.38136],
                            [123.470555, 42.388114],
                            [123.469497, 42.390757],
                            [123.473603, 42.392409],
                            [123.476165, 42.398042],
                            [123.472225, 42.397951],
                            [123.475705, 42.407088],
                            [123.481385, 42.408134],
                            [123.479102, 42.41439],
                            [123.464429, 42.424736],
                            [123.462717, 42.430807],
                            [123.455158, 42.432696],
                            [123.454059, 42.439408],
                            [123.458861, 42.445441],
                            [123.467325, 42.450135],
                            [123.469343, 42.454352],
                            [123.47345, 42.456112],
                            [123.471919, 42.460035],
                            [123.479074, 42.46493],
                            [123.488693, 42.464985],
                            [123.49635, 42.463298],
                            [123.497728, 42.46559],
                            [123.506442, 42.469494],
                            [123.509379, 42.468981],
                            [123.513194, 42.477961],
                            [123.519068, 42.479226],
                            [123.518985, 42.483313],
                            [123.522437, 42.487417],
                            [123.524205, 42.485255],
                            [123.529843, 42.486703],
                            [123.5342, 42.48203],
                            [123.542162, 42.490606],
                            [123.550028, 42.490954],
                            [123.554371, 42.493244],
                            [123.558645, 42.499968],
                            [123.563628, 42.501543],
                            [123.566412, 42.498942],
                            [123.571145, 42.506361],
                            [123.583047, 42.502771],
                            [123.58579, 42.510648],
                            [123.584022, 42.512626],
                            [123.608898, 42.509329],
                            [123.616791, 42.525208],
                            [123.612156, 42.526435],
                            [123.613297, 42.535481],
                            [123.621761, 42.534163],
                            [123.625575, 42.53755],
                            [123.624169, 42.543738],
                            [123.62861, 42.541193],
                            [123.626995, 42.545496],
                            [123.643742, 42.553074],
                            [123.649449, 42.552562],
                            [123.653959, 42.5502],
                            [123.656242, 42.552232],
                            [123.663801, 42.551811],
                            [123.666947, 42.547326],
                            [123.663077, 42.540241],
                            [123.664776, 42.531489],
                            [123.668562, 42.530116],
                            [123.662785, 42.522882],
                            [123.659152, 42.524109],
                            [123.657662, 42.521143],
                            [123.670553, 42.519018],
                            [123.672752, 42.51552],
                            [123.679462, 42.516344],
                            [123.683875, 42.510043],
                            [123.703893, 42.529988],
                            [123.714445, 42.536872],
                            [123.717772, 42.534767],
                            [123.728296, 42.538209],
                            [123.736829, 42.542676],
                            [123.752838, 42.543024],
                            [123.758197, 42.54912],
                            [123.758559, 42.553971],
                            [123.746462, 42.55862],
                            [123.742105, 42.559188],
                            [123.737108, 42.553111],
                            [123.729535, 42.551793],
                            [123.725762, 42.555399],
                            [123.73076, 42.562574],
                            [123.723925, 42.56746],
                            [123.712426, 42.566271],
                            [123.713596, 42.576555],
                            [123.710338, 42.579592],
                            [123.699396, 42.577342],
                            [123.694733, 42.578494],
                            [123.690125, 42.589691],
                            [123.691128, 42.592838],
                            [123.701554, 42.593734],
                            [123.702612, 42.596405],
                            [123.696376, 42.602605],
                            [123.68542, 42.601819],
                            [123.673824, 42.602038],
                            [123.668326, 42.604672],
                            [123.662465, 42.614914],
                            [123.659555, 42.616194],
                            [123.651036, 42.615188],
                            [123.651579, 42.618406],
                            [123.664692, 42.623672],
                            [123.665569, 42.626451],
                            [123.657398, 42.631826],
                            [123.645593, 42.636616],
                            [123.638452, 42.637932],
                            [123.637074, 42.63987],
                            [123.624113, 42.647455],
                            [123.618851, 42.655094],
                            [123.620842, 42.657068],
                            [123.604847, 42.655752],
                            [123.598305, 42.650471],
                            [123.597497, 42.643343],
                            [123.593655, 42.64316],
                            [123.597539, 42.640162],
                            [123.592221, 42.635117],
                            [123.586597, 42.634002],
                            [123.574361, 42.644275],
                            [123.570658, 42.644878],
                            [123.548594, 42.639193],
                            [123.539615, 42.639504],
                            [123.529954, 42.638462],
                            [123.517537, 42.638426],
                            [123.505746, 42.632868],
                            [123.494707, 42.629578],
                            [123.465627, 42.614968],
                            [123.459362, 42.613798],
                            [123.443715, 42.616852],
                            [123.426287, 42.616486],
                            [123.422264, 42.612079],
                            [123.412199, 42.612262],
                            [123.40897, 42.617656],
                            [123.386655, 42.611128],
                            [123.370326, 42.617492],
                            [123.359774, 42.624001],
                            [123.349946, 42.621058],
                            [123.338837, 42.606684],
                            [123.335956, 42.599661],
                            [123.327561, 42.59485],
                            [123.312986, 42.584935],
                            [123.304801, 42.578037],
                            [123.289948, 42.568595],
                            [123.266157, 42.566069],
                            [123.245541, 42.54718],
                            [123.233834, 42.531929],
                            [123.22072, 42.519],
                            [123.213718, 42.515776],
                            [123.205547, 42.525208],
                            [123.203375, 42.530775],
                            [123.195245, 42.535389],
                            [123.178165, 42.532478],
                            [123.173014, 42.534144],
                            [123.169924, 42.532368],
                            [123.1654, 42.533577],
                            [123.159943, 42.532112],
                            [123.160305, 42.535829],
                            [123.14385, 42.534419],
                            [123.135387, 42.532515],
                            [123.132589, 42.530079],
                            [123.133452, 42.525465],
                            [123.130208, 42.525446],
                            [123.121577, 42.52041],
                            [123.115104, 42.518231],
                            [123.112752, 42.521784],
                            [123.104775, 42.525483],
                            [123.09414, 42.52552],
                            [123.097453, 42.528559],
                            [123.091355, 42.530281],
                            [123.088028, 42.534547],
                            [123.077323, 42.540205],
                            [123.068595, 42.54145],
                            [123.064892, 42.54372],
                            [123.061412, 42.541541],
                            [123.057876, 42.545807],
                            [123.061649, 42.554594],
                            [123.058002, 42.558126],
                            [123.055621, 42.564752],
                            [123.048466, 42.569345],
                            [123.041868, 42.57187],
                            [123.031719, 42.573828],
                            [123.03023, 42.570589],
                            [123.023743, 42.564184],
                            [123.014012, 42.568375],
                            [123.007414, 42.567442],
                            [123.008639, 42.564056],
                            [123.002611, 42.568576],
                            [122.996291, 42.57101],
                            [122.992714, 42.566673],
                            [122.98379, 42.571614],
                            [122.977916, 42.572712],
                            [122.97438, 42.570571],
                            [122.971666, 42.573609],
                            [122.968339, 42.572364],
                            [122.969647, 42.568082],
                            [122.974436, 42.564331],
                            [122.966905, 42.561732],
                            [122.966153, 42.557577],
                            [122.963522, 42.558071],
                            [122.957759, 42.550328],
                            [122.949393, 42.545075],
                            [122.943365, 42.547912],
                            [122.921802, 42.535536],
                            [122.916846, 42.529695],
                            [122.91782, 42.521564],
                            [122.911542, 42.519494],
                            [122.911793, 42.516198],
                            [122.908521, 42.510043],
                            [122.910025, 42.504438],
                            [122.908368, 42.500701],
                            [122.904136, 42.502001],
                            [122.899724, 42.499437],
                            [122.888573, 42.501434],
                            [122.882114, 42.503925],
                            [122.87152, 42.504072],
                            [122.843414, 42.500023],
                            [122.834352, 42.497989],
                            [122.819429, 42.490147],
                            [122.826459, 42.490147],
                            [122.823216, 42.483844],
                            [122.818566, 42.481425],
                            [122.818775, 42.475799],
                            [122.823229, 42.470245],
                            [122.821225, 42.466964],
                            [122.825011, 42.461154],
                            [122.822004, 42.458019],
                            [122.825554, 42.452904],
                            [122.827266, 42.44579],
                            [122.826612, 42.438822],
                            [122.823285, 42.439115],
                            [122.822798, 42.435227],
                            [122.828951, 42.437373],
                            [122.832835, 42.431614],
                            [122.824872, 42.429358],
                            [122.825819, 42.423948],
                            [122.820543, 42.424461],
                            [122.817786, 42.416775],
                            [122.821754, 42.414647],
                            [122.833962, 42.417986],
                            [122.83335, 42.4134],
                            [122.828269, 42.410281],
                            [122.83218, 42.403364],
                            [122.846185, 42.399602],
                            [122.850973, 42.405235],
                            [122.858852, 42.404024],
                            [122.862054, 42.406795],
                            [122.866036, 42.398978],
                            [122.865618, 42.395694],
                            [122.873622, 42.389381],
                            [122.881919, 42.390335],
                            [122.882351, 42.38839],
                            [122.875279, 42.374825],
                            [122.879956, 42.374054],
                            [122.878689, 42.370768],
                            [122.883395, 42.368327],
                            [122.895269, 42.368859],
                            [122.900058, 42.357035],
                            [122.903218, 42.353252],
                            [122.900962, 42.349194],
                            [122.884745, 42.348459],
                            [122.871089, 42.345649],
                            [122.858337, 42.34609],
                            [122.850138, 42.338395],
                            [122.838862, 42.331911],
                            [122.826348, 42.330441],
                            [122.812009, 42.320576],
                            [122.805174, 42.312013],
                            [122.803072, 42.311425],
                            [122.78059, 42.298543],
                            [122.769482, 42.297036],
                            [122.762494, 42.293304],
                            [122.748726, 42.288415],
                            [122.746387, 42.286007],
                            [122.75499, 42.281595],
                            [122.751538, 42.279407],
                            [122.752582, 42.274278],
                            [122.748656, 42.276521],
                            [122.744981, 42.275693],
                            [122.755659, 42.270674],
                            [122.753014, 42.265599],
                            [122.76454, 42.264458],
                            [122.766879, 42.259383],
                            [122.771542, 42.255668],
                            [122.780938, 42.253001],
                            [122.785143, 42.259328],
                            [122.791671, 42.260523],
                            [122.795486, 42.264569],
                            [122.803393, 42.266022],
                            [122.805564, 42.270655],
                            [122.815865, 42.269534],
                            [122.814696, 42.264808],
                            [122.832612, 42.261148],
                            [122.843206, 42.260394],
                            [122.85224, 42.257286],
                            [122.858992, 42.26067],
                            [122.871117, 42.263208],
                            [122.884383, 42.25793],
                            [122.898833, 42.272549],
                            [122.913241, 42.268982],
                            [122.92105, 42.268706],
                            [122.920424, 42.263705],
                            [122.92659, 42.27049],
                            [122.936293, 42.267015],
                            [122.936975, 42.262932],
                            [122.942725, 42.260394],
                            [122.941388, 42.256974],
                            [122.956659, 42.257507],
                            [122.95787, 42.254123],
                            [122.971471, 42.251897],
                            [122.972041, 42.255006],
                            [122.980074, 42.261663],
                            [122.992212, 42.26262],
                            [122.999952, 42.25942],
                            [123.000676, 42.260928],
                            [123.01333, 42.258482],
                            [123.013121, 42.256698],
                            [123.020096, 42.256735],
                            [123.029993, 42.251897],
                            [123.039849, 42.248715],
                            [123.046489, 42.249046],
                            [123.050081, 42.251897],
                            [123.055886, 42.251769],
                            [123.061273, 42.253921],
                            [123.077254, 42.263374],
                            [123.088209, 42.263337],
                            [123.09201, 42.264532],
                            [123.099221, 42.27106],
                            [123.10536, 42.269148],
                            [123.108603, 42.27185],
                            [123.118863, 42.271372],
                            [123.124765, 42.269019],
                            [123.135052, 42.270214],
                            [123.138588, 42.267824],
                            [123.141205, 42.262417],
                            [123.148792, 42.259291],
                            [123.147567, 42.257599],
                            [123.154374, 42.25484],
                            [123.153623, 42.250684],
                            [123.160444, 42.251015],
                            [123.159984, 42.247943],
                            [123.170035, 42.240254],
                            [123.185654, 42.226455],
                            [123.196164, 42.226823],
                            [123.204405, 42.230926],
                            [123.21621, 42.232177],
                            [123.233096, 42.21834],
                            [123.251443, 42.205052],
                            [123.256009, 42.195646],
                            [123.256928, 42.183016],
                            [123.260046, 42.181285],
                            [123.260742, 42.176847],
                            [123.258306, 42.174711],
                            [123.257819, 42.167897],
                            [123.262942, 42.165226],
                            [123.271934, 42.165374],
                            [123.282514, 42.161671],
                            [123.284936, 42.162095],
                            [123.290727, 42.170383],
                            [123.29901, 42.172593],
                            [123.306026, 42.171046],
                            [123.308657, 42.165484],
                            [123.306165, 42.159203],
                            [123.308017, 42.153364],
                            [123.310592, 42.151374],
                            [123.318248, 42.149882],
                            [123.326016, 42.152535],
                            [123.344113, 42.151909],
                            [123.350753, 42.149551],
                            [123.357908, 42.15014],
                            [123.361653, 42.148777],
                            [123.368363, 42.140504],
                            [123.373514, 42.138717],
                            [123.377537, 42.139896],
                            [123.385513, 42.147929],
                            [123.388798, 42.149145],
                            [123.400144, 42.148371],
                            [123.409554, 42.151227],
                            [123.409485, 42.162482],
                            [123.415053, 42.170751],
                            [123.419187, 42.170899],
                            [123.419438, 42.165687],
                            [123.424032, 42.163053],
                            [123.43173, 42.163753],
                            [123.441126, 42.171875],
                            [123.443688, 42.172298],
                            [123.455075, 42.168431],
                            [123.460142, 42.170494],
                            [123.460949, 42.17495],
                            [123.469594, 42.181543]
                        ]
                    ]
                ] } }, { "type": "Feature", "properties": { "adcode": 210181, "name": "新民市", "center": [122.828868, 41.996508], "centroid": [122.857957, 42.009602], "childrenNum": 0, "level": "district", "parent": { "adcode": 210100 }, "subFeatureIndex": 12, "acroutes": [100000, 210000, 210100] }, "geometry": { "type": "MultiPolygon", "coordinates": [
                    [
                        [
                            [122.60036, 41.766572],
                            [122.601097, 41.765608],
                            [122.612401, 41.775672],
                            [122.616549, 41.786196],
                            [122.622159, 41.783621],
                            [122.627992, 41.786548],
                            [122.62738, 41.777821],
                            [122.638043, 41.772669],
                            [122.640716, 41.773652],
                            [122.641272, 41.76811],
                            [122.652701, 41.763588],
                            [122.662585, 41.754821],
                            [122.671564, 41.756471],
                            [122.672413, 41.75267],
                            [122.676868, 41.75026],
                            [122.680515, 41.75508],
                            [122.686264, 41.755581],
                            [122.68735, 41.746404],
                            [122.692793, 41.747517],
                            [122.699127, 41.739266],
                            [122.705586, 41.744736],
                            [122.711544, 41.744013],
                            [122.709414, 41.737987],
                            [122.714105, 41.737449],
                            [122.712491, 41.734297],
                            [122.718351, 41.727362],
                            [122.723474, 41.725155],
                            [122.728402, 41.711281],
                            [122.733761, 41.714397],
                            [122.736768, 41.722818],
                            [122.742086, 41.7284],
                            [122.747111, 41.728011],
                            [122.756229, 41.739359],
                            [122.761227, 41.743234],
                            [122.762869, 41.740268],
                            [122.773936, 41.743049],
                            [122.774312, 41.74709],
                            [122.769899, 41.749204],
                            [122.774702, 41.755377],
                            [122.773588, 41.763922],
                            [122.782052, 41.765794],
                            [122.784836, 41.769278],
                            [122.785992, 41.764422],
                            [122.800358, 41.759807],
                            [122.811021, 41.75838],
                            [122.826473, 41.754969],
                            [122.851085, 41.750835],
                            [122.868541, 41.744606],
                            [122.887348, 41.74112],
                            [122.894517, 41.74544],
                            [122.894851, 41.748889],
                            [122.906921, 41.745756],
                            [122.915746, 41.748499],
                            [122.917375, 41.743976],
                            [122.925045, 41.741324],
                            [122.927217, 41.742808],
                            [122.936432, 41.741399],
                            [122.938326, 41.739452],
                            [122.946316, 41.73782],
                            [122.952928, 41.731886],
                            [122.957898, 41.720722],
                            [122.965749, 41.713655],
                            [122.970761, 41.711856],
                            [122.97374, 41.71896],
                            [122.977401, 41.711262],
                            [122.983707, 41.709778],
                            [122.985294, 41.712858],
                            [122.991391, 41.715863],
                            [122.997962, 41.72202],
                            [122.995846, 41.724394],
                            [123.004268, 41.731757],
                            [123.015697, 41.734093],
                            [123.017701, 41.732795],
                            [123.027209, 41.73413],
                            [123.032262, 41.736096],
                            [123.05061, 41.735391],
                            [123.05789, 41.736541],
                            [123.071491, 41.7363],
                            [123.073175, 41.73795],
                            [123.079175, 41.734204],
                            [123.094794, 41.734204],
                            [123.09613, 41.736133],
                            [123.105582, 41.735261],
                            [123.107364, 41.737653],
                            [123.1158, 41.734019],
                            [123.113322, 41.729568],
                            [123.116468, 41.725488],
                            [123.127577, 41.725136],
                            [123.136458, 41.728678],
                            [123.142653, 41.725618],
                            [123.147331, 41.727343],
                            [123.154681, 41.733833],
                            [123.155446, 41.736281],
                            [123.15088, 41.740286],
                            [123.154068, 41.746998],
                            [123.162922, 41.745737],
                            [123.169576, 41.757323],
                            [123.169952, 41.760845],
                            [123.163757, 41.763403],
                            [123.159859, 41.765738],
                            [123.161056, 41.769278],
                            [123.15837, 41.774059],
                            [123.16313, 41.781008],
                            [123.159678, 41.782139],
                            [123.161376, 41.790309],
                            [123.158578, 41.790995],
                            [123.164481, 41.798461],
                            [123.164592, 41.801054],
                            [123.171998, 41.805592],
                            [123.173933, 41.808982],
                            [123.171539, 41.813223],
                            [123.16739, 41.814075],
                            [123.16455, 41.819075],
                            [123.161015, 41.817371],
                            [123.161056, 41.828149],
                            [123.162337, 41.832555],
                            [123.15663, 41.847032],
                            [123.141011, 41.848735],
                            [123.130612, 41.857489],
                            [123.139257, 41.870795],
                            [123.140899, 41.876753],
                            [123.135498, 41.878159],
                            [123.138547, 41.882322],
                            [123.143196, 41.878843],
                            [123.148597, 41.878695],
                            [123.147818, 41.881711],
                            [123.158049, 41.889518],
                            [123.142055, 41.901559],
                            [123.141567, 41.903112],
                            [123.13508, 41.898267],
                            [123.127605, 41.900967],
                            [123.123206, 41.906201],
                            [123.125475, 41.917092],
                            [123.128454, 41.917259],
                            [123.129317, 41.921585],
                            [123.133229, 41.922658],
                            [123.133633, 41.928666],
                            [123.114366, 41.936744],
                            [123.11729, 41.942844],
                            [123.114102, 41.947058],
                            [123.119113, 41.948315],
                            [123.114339, 41.95214],
                            [123.112264, 41.956594],
                            [123.113392, 41.960881],
                            [123.116928, 41.962174],
                            [123.121675, 41.956557],
                            [123.129248, 41.95894],
                            [123.135136, 41.968142],
                            [123.130793, 41.971079],
                            [123.132909, 41.975809],
                            [123.142625, 41.97422],
                            [123.1463, 41.980187],
                            [123.152077, 41.977674],
                            [123.151604, 41.98667],
                            [123.15553, 41.986485],
                            [123.15663, 41.993282],
                            [123.168894, 41.991952],
                            [123.174183, 41.990401],
                            [123.180155, 41.999376],
                            [123.176494, 42.001536],
                            [123.182397, 42.008608],
                            [123.187812, 42.011082],
                            [123.186935, 42.015199],
                            [123.204461, 42.021679],
                            [123.207496, 42.019353],
                            [123.214233, 42.023839],
                            [123.216572, 42.030742],
                            [123.220484, 42.037054],
                            [123.228864, 42.036888],
                            [123.234404, 42.04951],
                            [123.240321, 42.048182],
                            [123.245332, 42.052776],
                            [123.250872, 42.05285],
                            [123.249313, 42.056743],
                            [123.257666, 42.066484],
                            [123.262733, 42.069952],
                            [123.268357, 42.067332],
                            [123.272951, 42.072755],
                            [123.285326, 42.087583],
                            [123.291089, 42.083895],
                            [123.296059, 42.091198],
                            [123.302991, 42.096305],
                            [123.303646, 42.100177],
                            [123.309896, 42.109487],
                            [123.324012, 42.12344],
                            [123.320128, 42.128766],
                            [123.325348, 42.135032],
                            [123.331584, 42.136027],
                            [123.335594, 42.134571],
                            [123.334605, 42.138588],
                            [123.337849, 42.150195],
                            [123.34396, 42.149532],
                            [123.344113, 42.151909],
                            [123.326016, 42.152535],
                            [123.318248, 42.149882],
                            [123.310592, 42.151374],
                            [123.308017, 42.153364],
                            [123.306165, 42.159203],
                            [123.308657, 42.165484],
                            [123.306026, 42.171046],
                            [123.29901, 42.172593],
                            [123.290727, 42.170383],
                            [123.284936, 42.162095],
                            [123.282514, 42.161671],
                            [123.271934, 42.165374],
                            [123.262942, 42.165226],
                            [123.257819, 42.167897],
                            [123.258306, 42.174711],
                            [123.260742, 42.176847],
                            [123.260046, 42.181285],
                            [123.256928, 42.183016],
                            [123.256009, 42.195646],
                            [123.251443, 42.205052],
                            [123.233096, 42.21834],
                            [123.21621, 42.232177],
                            [123.204405, 42.230926],
                            [123.196164, 42.226823],
                            [123.185654, 42.226455],
                            [123.170035, 42.240254],
                            [123.159984, 42.247943],
                            [123.160444, 42.251015],
                            [123.153623, 42.250684],
                            [123.154374, 42.25484],
                            [123.147567, 42.257599],
                            [123.148792, 42.259291],
                            [123.141205, 42.262417],
                            [123.138588, 42.267824],
                            [123.135052, 42.270214],
                            [123.124765, 42.269019],
                            [123.118863, 42.271372],
                            [123.108603, 42.27185],
                            [123.10536, 42.269148],
                            [123.099221, 42.27106],
                            [123.09201, 42.264532],
                            [123.088209, 42.263337],
                            [123.077254, 42.263374],
                            [123.061273, 42.253921],
                            [123.055886, 42.251769],
                            [123.050081, 42.251897],
                            [123.046489, 42.249046],
                            [123.039849, 42.248715],
                            [123.029993, 42.251897],
                            [123.020096, 42.256735],
                            [123.013121, 42.256698],
                            [123.01333, 42.258482],
                            [123.000676, 42.260928],
                            [122.999952, 42.25942],
                            [122.992212, 42.26262],
                            [122.980074, 42.261663],
                            [122.972041, 42.255006],
                            [122.971471, 42.251897],
                            [122.95787, 42.254123],
                            [122.956659, 42.257507],
                            [122.941388, 42.256974],
                            [122.942725, 42.260394],
                            [122.936975, 42.262932],
                            [122.936293, 42.267015],
                            [122.92659, 42.27049],
                            [122.920424, 42.263705],
                            [122.92105, 42.268706],
                            [122.913241, 42.268982],
                            [122.898833, 42.272549],
                            [122.884383, 42.25793],
                            [122.871117, 42.263208],
                            [122.858992, 42.26067],
                            [122.85224, 42.257286],
                            [122.843206, 42.260394],
                            [122.832612, 42.261148],
                            [122.814696, 42.264808],
                            [122.815865, 42.269534],
                            [122.805564, 42.270655],
                            [122.803393, 42.266022],
                            [122.795486, 42.264569],
                            [122.791671, 42.260523],
                            [122.785143, 42.259328],
                            [122.780938, 42.253001],
                            [122.771542, 42.255668],
                            [122.766879, 42.259383],
                            [122.76454, 42.264458],
                            [122.753014, 42.265599],
                            [122.755659, 42.270674],
                            [122.744981, 42.275693],
                            [122.748656, 42.276521],
                            [122.752582, 42.274278],
                            [122.751538, 42.279407],
                            [122.75499, 42.281595],
                            [122.746387, 42.286007],
                            [122.748726, 42.288415],
                            [122.742336, 42.288599],
                            [122.734304, 42.284279],
                            [122.735028, 42.280988],
                            [122.719367, 42.270619],
                            [122.703734, 42.261112],
                            [122.700115, 42.256606],
                            [122.691763, 42.249929],
                            [122.680974, 42.244669],
                            [122.655973, 42.233907],
                            [122.651267, 42.232637],
                            [122.645128, 42.23766],
                            [122.636428, 42.23709],
                            [122.636567, 42.231589],
                            [122.632628, 42.231],
                            [122.626196, 42.234679],
                            [122.619083, 42.231478],
                            [122.620071, 42.230098],
                            [122.609297, 42.23019],
                            [122.611552, 42.217346],
                            [122.614489, 42.212764],
                            [122.573326, 42.196032],
                            [122.5645, 42.191651],
                            [122.552027, 42.188079],
                            [122.520065, 42.174692],
                            [122.500869, 42.164416],
                            [122.493407, 42.162537],
                            [122.480837, 42.15598],
                            [122.455835, 42.144595],
                            [122.447817, 42.146806],
                            [122.445228, 42.150361],
                            [122.441121, 42.14874],
                            [122.439451, 42.150951],
                            [122.432657, 42.144208],
                            [122.436611, 42.140136],
                            [122.432504, 42.138975],
                            [122.429205, 42.141039],
                            [122.422105, 42.139196],
                            [122.423985, 42.132028],
                            [122.429664, 42.125136],
                            [122.446592, 42.124178],
                            [122.449265, 42.121413],
                            [122.44062, 42.121395],
                            [122.438476, 42.117321],
                            [122.443126, 42.114648],
                            [122.447232, 42.115036],
                            [122.44765, 42.110501],
                            [122.435581, 42.104823],
                            [122.428286, 42.092322],
                            [122.445214, 42.094646],
                            [122.450991, 42.090442],
                            [122.449891, 42.087583],
                            [122.436165, 42.084153],
                            [122.435817, 42.076961],
                            [122.439367, 42.074471],
                            [122.450726, 42.073696],
                            [122.453148, 42.072479],
                            [122.451311, 42.067295],
                            [122.447371, 42.063477],
                            [122.44868, 42.060968],
                            [122.459009, 42.055544],
                            [122.458745, 42.045838],
                            [122.462795, 42.041668],
                            [122.470577, 42.041151],
                            [122.477482, 42.042757],
                            [122.477621, 42.038586],
                            [122.47105, 42.033511],
                            [122.469366, 42.029173],
                            [122.47964, 42.02491],
                            [122.483746, 42.021458],
                            [122.494799, 42.004269],
                            [122.497305, 41.999247],
                            [122.494813, 41.988757],
                            [122.49782, 41.977508],
                            [122.501495, 41.970895],
                            [122.500869, 41.957591],
                            [122.507857, 41.93924],
                            [122.506604, 41.936061],
                            [122.517949, 41.936319],
                            [122.515778, 41.92654],
                            [122.518158, 41.919644],
                            [122.521067, 41.91663],
                            [122.517866, 41.912322],
                            [122.52335, 41.905702],
                            [122.523128, 41.900135],
                            [122.519522, 41.900042],
                            [122.50904, 41.904222],
                            [122.493727, 41.904074],
                            [122.473918, 41.906182],
                            [122.456392, 41.904795],
                            [122.443376, 41.901226],
                            [122.441942, 41.896436],
                            [122.443863, 41.890665],
                            [122.453343, 41.882858],
                            [122.459566, 41.870554],
                            [122.457645, 41.861191],
                            [122.459204, 41.84805],
                            [122.461612, 41.846939],
                            [122.459246, 41.843015],
                            [122.471844, 41.839165],
                            [122.473292, 41.836147],
                            [122.487115, 41.832185],
                            [122.494674, 41.828685],
                            [122.497611, 41.824593],
                            [122.505017, 41.82776],
                            [122.505713, 41.822575],
                            [122.510808, 41.821686],
                            [122.50826, 41.818001],
                            [122.513592, 41.820594],
                            [122.511448, 41.81676],
                            [122.517991, 41.816723],
                            [122.51749, 41.812797],
                            [122.529531, 41.808371],
                            [122.52974, 41.804777],
                            [122.540069, 41.800665],
                            [122.544858, 41.792032],
                            [122.542672, 41.789791],
                            [122.552932, 41.786882],
                            [122.554407, 41.792496],
                            [122.56095, 41.789161],
                            [122.562133, 41.781064],
                            [122.560184, 41.781045],
                            [122.557707, 41.77493],
                            [122.56173, 41.771113],
                            [122.569247, 41.772855],
                            [122.596504, 41.772262],
                            [122.60036, 41.766572]
                        ]
                    ]
                ] } }] });
}));