<template>
    <div>
        <div class="configBox" >
            <div class="btnItem configBtn" @click.stop="slideConfigBox('modelThree')">配置</div>
            <div class="slideBox " v-if="configShow">
                <div class="btnGroup configMarginLeft">
                    <div class="btnGroupItem">
                        <div class="btnItem" @click.stop="toSortPic(idProp)">编辑</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Bus from '../../../../api/Bus'
    export default {
        name: "ConfigBtn",
        props:['idProp','serviceTypeProp','hotelContractProp','keyProp','hotelServiceProp'],
        data:()=>({
            shadeShow:false,
            configShow:false,
            query:{},
            test:'configBtn测试'
        }),
        methods:{
            slideConfigBox(type){
                this.configShow=!this.configShow;
                if(this.configShow){
                    this.$emit('shadeMeth',true);
                    this.$emit('modelShowBtnMeth',this.keyProp);
                }else{
                    this.$emit('shadeMeth',false);
                }
                this.shadeShow=true;
            },
            toSortPic(id){
                var obj={
                    id:this.idProp,
                    tabActiveName:this.$attrs.activeName,
                    configData:this.hotelServiceProp,
                    showType:this.$attrs.showType,
                };
                Bus.$emit('showConfigDetailDrawer',obj);
            },
            resetItemMeth(){

            },
            hideConfigBox(){
                this.configShow=false;
            }
        },
        watch:{
               idProp:{
                  handler: function (newVal) {
                          this.idProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             serviceTypeProp:{
                 handler: function (newVal) {
                          this.serviceTypeProp=newVal    
                },
                deep: true,
                immediate: true, 
            },
             hotelContractProp:{
                 handler: function (newVal) {
                          this.hotelContractProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             keyProp:{
                 handler: function (newVal) {
                          this.keyProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelServiceProp:{
                 handler: function (newVal) {
                          this.hotelServiceProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             
        },
        mounted(){
            this.query=this.$route.query;
        }
    }
</script>

<style scoped>
    /*配置开始*/
    .configBox{
        display:flex;
        position: absolute;
        top:0;
    }

    .configBox .btnItem{
        white-space:nowrap;
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.8)!important;
        cursor:pointer;
    }
    .configBtn{
        display:none;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .detail_box .item{
        height:36px;
        color:#fff;
        padding:0 20px;
        cursor:pointer;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        transition:0.5s;
    }
    .slideBox{
        /*display:none;*/
        /*overflow:hidden;*/
        transition:2s;
    }
    .btnGroup{
        display:flex;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
        z-index:9999;
    }
    .configMarginLeft{
        margin-left:10px;
    }
    .color_match>.item,.more_file>.item{
        width:100%;
        height:36px;
        padding:0 20px;
        display:flex;
        align-items: center;
        font-size:14px;
        box-sizing:border-box;
    }
    .color_match>.item.active{
        background:#1b90ed;
    }
    .item.active .check_icon{
        visibility: visible;
    }
    .item .check_icon{
        visibility: hidden;
    }
    .color_match>.item>.check_icon{
        width:18px;
        height:20px;
        margin-right:10px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .color_match>.item>.show_icon{
        width:44px;
        height:24px;
        margin-right:10px;
        display:inline-block;
    }
    .noWarp{
        white-space:nowrap;
    }
    img,video{
        width:100%;
        height:100%;
        outline:none!important;
    }
    .arrowIcon.down{
        transform:rotate(180deg);
    }
    .noneOutLine{

    }

    /*配置结束*/
</style>
