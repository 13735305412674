<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">数字资产</span>
        <span slot="third">软件资产</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <div class="SearchPosition">
      <top-data ref="dataAssetSearch"  :showRateBtn="true" :hotelNatureBtn="hotelNatureBtn"  :asOfYesterday="true"
                @conditionDataAsset="conditionDataAsset"
                :topDataAssetSearchShow="topDataAssetSearchShow"
                :showSearchProp="fold.showSearch" v-loading="loading"
                @getRateFilter="getRateFilter" @getFilterStr="getFilterStr" :conditionProp="condition"
      @filterMeth="filterMeth" @exportMeth="exportMeth" :exportBtn="exportBtn"></top-data>
      <!--<section class="rightBtn">-->
        <!--<div class="occupy"></div>-->
        <!--<el-button type="primary"-->
                   <!--@click="exportMeth('export','导出')"-->
                   <!--size="medium"-->
                   <!--class="largeBtn exportBtn" :loading="exportBtn.loading">{{exportBtn.text}}</el-button>-->
      <!--</section>-->
    </div>
    <section class="distanceTop" >
      <el-card v-loading="loading">
        <section class="criteria" v-if="adActiveLi!=='' || shoppingctiveLi!=='' ||filmActiveLi!=='' ">
          <section
                  class="criteriaFlesBox criteriaFlesBoxAddrss"
                  v-if="provinceLength"
                  @click="showDetailedAdd">
            <div>阵地：</div>
            <div class="ellipsis">{{ province }}</div>
            <section class="detailedAdd" v-if="showCityDetails">
              <section v-if="!countyLabel">
                <div class="flexCity" v-for="(item,index) in districtUrban" :key="index">
                  <div class="weightFont">{{ item.label }}：</div>
                  <ul>
                    <li v-for="(item1,index1) in item.children" :key="index1">{{ item1.label }}</li>
                  </ul>
                </div>
              </section>
              <div class="flexCity" v-if="countyLabel">
                <div class="weightFont">{{ districtCounty }}：</div>
                <ul>
                  <li>{{ countyLabel}}</li>
                </ul>
              </div>
            </section>
          </section>
          <section class="criteriaFlesBox" v-if="adActiveLi!==''">
            <div>AD版本：</div>
            <div>{{ adActiveLi }}</div>
          </section>
          <section class="criteriaFlesBox" v-if="shoppingctiveLi!==''">
            <div>商城版本：</div>
            <div>{{ shoppingctiveLi }}</div>
          </section>
          <section class="criteriaFlesBox" v-if="filmActiveLi!==''">
            <div>电影媒资：</div>
            <div>{{ filmActiveLi }}</div>
          </section>
        </section>
        <div class="flexBox">
          <div class="imgBox">
            <section class="headerTitle" style="padding-top:0;">
              数字资产漏斗
            </section>
            <section class="flexBoxBig" style="padding-top:0;">
              <section class="trapezoid">
                <div class="location textCenter">
                  <el-tooltip class="item tooltip payment"  effect="dark" content="可安装应用激活终端数" placement="right">
                    <p class="tooltipBox">阵地终端数</p>
                  </el-tooltip>
                  <p class="noWrap">（应安装）</p>
                </div>
              </section>
            </section>
            <section class="flexBoxBig">
              <section class="trapezoid trapezoidOne">
                <div class="location textCenter">
                  <el-tooltip class="item tooltip payment"  effect="dark" content="有行为的激活终端数" placement="right">
                    <p class="tooltipBox">可运营终端数</p>
                  </el-tooltip>
                  <p class="noWrap">（有心跳）</p>
                </div>
              </section>
            </section>
            <section class="flexBoxBig">
              <section class="trapezoid trapezoidTwo">
                <div class="location textCenter">
                  <el-tooltip class="item tooltip payment"  effect="dark" content="应用有交互的激活终端数" placement="right">
                    <p class="tooltipBox">交互终端数</p>
                  </el-tooltip>
                  <p class="noWrap">（有交互）</p>
                </div>
              </section>
            </section>
            <section class="flexBoxBig">
              <section class="trapezoid trapezoidThree">
                <div class="location">
                  <el-tooltip class="item tooltip payment"  effect="dark" content="支付行为次数" placement="right">
                    <p class="tooltipBox">收益终端数</p>
                  </el-tooltip>
                  <p class="noWrap">（有支付行为）</p>
                </div>
              </section>
            </section>
          </div>
          <section class="contentSoftware numShowBox">
            <soft-header :headerTitleArrProp="headerTitleArr" :filterConArr="filterConArr"
                         class="softHeader" :showAllAppDataProp="showAllAppData"></soft-header>
            <soft-num :data="showAllAppData.positionApp" :rateFilter="rateFilter"></soft-num>
            <soft-num :data="showAllAppData.onlineApp" :rateFilter="rateFilter"></soft-num>
            <soft-num :data="showAllAppData.activeApp" :rateFilter="rateFilter"></soft-num>
            <soft-num :data="showAllAppData.payment" :rateFilter="rateFilter" unitProp="次"></soft-num>
          </section>
        </div>
      </el-card>
    </section>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "DataAssetSoft",
  data: () => ({
    type:'timeCenter',
    topDataAssetSearchShow:{
      positionSelect:true,
      dateSelect:true,
      adSelect:true,
      shoppingSelect:true,
      movieSelect:true
    },
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/dataAssetSoft" },
    },
    showDataFilter: false,
    dataFilter: "",
    provinceLength: false,
    province: "",
    adListsLength: false,
    adActiveLi: "",
    shoppingctiveLiLength: false,
    shoppingctiveLi: "",
    ilmListsLength: false,
    filmActiveLi: "",
    defaultAllAppData: [], // 所有APP数据
    showAllAppData: [], // 所有APP数据
    citycodeMu: [],
    regioncode: "",
    showCityDetails: false,
    districtUrban: [], // 市区
    districtCounty: "",
    countyLabel: "",
    showData:{
      test:1000,
    },
    loading:true,
    token:'',
    auth:{},
    exportBtn:{
      loading:false,
      text:'导出'
    },
    fold:{
      showSearch:false,
      foldText:'展开筛选项'
    },
    headerTitleArr:[],
    filterConArr:[],
    hideEl:false,
    condition:{
      screenType:'self'
    },
    rateFilter:{
      isYear:0,
      isChain:0,
    },
    hotelNatureBtn:false

  }),
  created() {

  },
  methods: {
    // 点击出现地区详情
    showDetailedAdd() {
      console.log(
        this.regioncode,
        this.citycodeMu,
        "this.regioncode,this.citycodeMu"
      );
      if (this.citycodeMu instanceof Array) {
        this.citysMut(this.citycodeMu.join());
      } else {
        this.citysAndProName(this.regioncode, this.citycodeMu);
      }
      this.showCityDetails = !this.showCityDetails;
    },
    // 条件搜素
    conditionDataAsset(e,type) {
       this.condition.screenType=e.screenType;
      this.provinceLength = e.province.length != 0 ? true : false;
      this.adListsLength = e.activeLiAD.length != 0 ? true : false;
      // this.shoppingctiveLiLength = e.shoppingctiveLi.length != 0 ? true : false;
      this.ilmListsLength = e.filmActiveLi.length != 0 ? true : false;
      if (
        e.city ||
        e.country ||
        e.startTime ||
        e.endTime ||
        e.shoppingctiveLi.length != 0 ||
        e.activeLiAD.length != 0 ||
        e.filmActiveLi.length != 0 ||
        e.province.length != 0 ||
              e.isYear ||
              e.isChain ||
              e.isAccumulative
      ) {
        // console.log(222);
        if ( e.city || e.country || e.shoppingctiveLi.length != 0 || e.activeLiAD.length != 0 ||e.filmActiveLi.length != 0 ||  e.province.length != 0) {
          this.showDataFilter = true;
        } else {
          this.showDataFilter = false;
        }

        let cityCode =
          e.provinceLabel && e.city
            ? `${e.provinceLabel[0]}${e.city ? "-" : ""}${
                e.city ? e.cityLabel : ""
              }${e.country ? "-" : ""}${e.country ? e.countyLabel : ""}`
            : "";
        let allCity = e.provinceLabel ? e.provinceLabel.join() : "";
        // console.log(e, cityCode, 888);
        // console.log(e.provinceLabel.length )
        this.province = cityCode ? cityCode : allCity;
        this.districtCounty = e.city ? e.cityLabel : "";
        this.countyLabel = e.country ? e.countyLabel : "";

        this.citycodeMu = e.citycode; // 省份代码
        this.regioncode = e.provinceLabel ? e.province[0].value : ""; // 城市代码

        // AD版本
        let activeLiAD = [];
        let adArr_ids = [];
        e.activeLiAD != 0
          ? e.activeLiAD.map((item) => {
              adArr_ids.push(item.id);
              item = item.name.slice(2);
              activeLiAD.push(item);
            })
          : "";
        // 商场版本
        let shoppingctiveLi = [];
        let shoppArr = [];
        e.shoppingctiveLi != 0
          ? e.shoppingctiveLi.map((item) => {
              shoppArr.push(item.id);
              item = item.name.slice(2);
              shoppingctiveLi.push(item);
            })
          : "";
        // this.shoppingctiveLi = shoppingctiveLi.join();
        // 电影媒资
        let filmActiveLi = [];
        let filmArr = [];
        e.filmActiveLi != 0
          ? e.filmActiveLi.map((item) => {
              filmArr.push(item.id);
              item = item.name;
              filmActiveLi.push(item);
            })
          : "";

        // this.filmActiveLi = filmActiveLi.join();

        let codeCity = e.provinceLabel ? e.provinceLabel.toString() : "";
        let ad_ids = adArr_ids ? adArr_ids.toString() : "";
        let shop_ids = shoppArr ? shoppArr.toString() : "";
        let media_ids = filmArr ? filmArr.toString() : "";
        let isYear=e.isYear;
        let isChain=e.isChain;
        let isAccumulative=e.isAccumulative;
        this.condition.screenType=e.screenType;

        this.initData(type,
          codeCity,
          e.city.label,
          e.country.label,
          e.startTime,
          e.endTime,
          ad_ids,
          shop_ids,
          media_ids,
                isYear,
                isChain,
                isAccumulative,

        ); // 实现搜索功能
        // this.dataFilter = e
      } else {
        this.initData();
        this.showDataFilter = false;
        this.showCityDetails = false;
      }
      // this.Filter  = e
    },
    // 多省份获取城市列表
    citysMut(prov_code) {
      this.$http
        .get(this.global.getCitysMut, {
          params: {
            token: sessionStorage.getItem("token"),
            prov_code: prov_code,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            const { data } = res.data;
            this.districtUrban = data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.table.loading = false;
        });
    },
    // 城市获取区县列表
    citysAndProName(prov_code, city_code) {
      this.$http
        .get(this.global.getCitysAndProName, {
          params: {
            token: sessionStorage.getItem("token"),
            prov_code: prov_code,
            city_code: city_code,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            const { data } = res.data;
            this.districtUrban = data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.table.loading = false;
        });
    },
    exportMeth(){
      var date=this.$refs.dataAssetSearch.form.date;
      var startTime='';
      var endTime='';
      var flag=false;
      if(date && date!==''){
        startTime=date[0];
        endTime=date[1];
      }
      if(date!=='' && startTime!==endTime){
        this.$message.warning('时间跨度请选择单天');
      }else{
        flag=true;
      }
      if(flag){
        this.exportStateChange(true);
        var param=qs.stringify({
          token:this.token,
          startTime:startTime,
          endTime:endTime,
        });
        this.$message({
          message:'导出请求已提交，导出所需时间较长，请耐心等待……',
          type:'success',
          duration:6000
        });
        this.$http.post(this.global.DataassetsExport,param,{responseType:'blob'}).then(res=>{
          const blobUrl = window.URL.createObjectURL(res.data);
          const title = `数字资产导出.xlsx`;
          const a = document.createElement('a');
          a.style.display = 'none';
          a.download = title;
          a.href = blobUrl;
          a.click();
          document.body.removeChild(a);
          this.exportStateChange(false);
        }).catch((res)=>{
          this.exportStateChange(false);
        })
      }
    },
    exportStateChange(flag){
      if(flag){
        this.exportBtn.text='导出中……';
      }else{
        this.exportBtn.text='导出';
      }
      this.exportBtn.loading=flag;
    },
    getFoldMeth(val){
      this.fold=val;
    },
    getHeaderTitle(){
      this.headerTitleArr=[];
      this.showAllAppData.positionApp.forEach((item,index)=>{
        this.headerTitleArr.push(item.name);
      });
    },
    getRateFilter(isYear,isChain){
      this.rateFilter.isYear=isYear;
      this.rateFilter.isChain=isChain;
    },
    getFilterStr(ad,shop,film){
      var adArr=[];
      var shopArr=[];
      var filmArr=[];
      if(ad.length>0){
        ad.forEach((item,index)=>{
          adArr.push(item.name);
        })
      }
      if(shop.length>0){
        shop.forEach((item,index)=>{
          shopArr.push(item.name);
        })
      }
      if(film.length>0){
        film.forEach((item,index)=>{
          filmArr.push(item.name);
        })
      }
      this.adActiveLi=adArr.join(',');
      this.shoppingctiveLi=shopArr.join(',');
      this.filmActiveLi=filmArr.join(',');
    },
    // 数据初始化
    initData(type,
      province,
      city,
      county,
      startTime,
      endTime,
      ad_ids,
      shop_ids,
      media_ids,
      isYear,
      isChain,
      isAccumulative,
    ) {
      this.loading=true;
      let params = {
        token: sessionStorage.getItem("token"),
        province: province,
        city: city,
        county: county,
        start_time: startTime,
        end_time: endTime,
        isYear: isYear,
        isChain: isChain,
        isAccumulative: isAccumulative,
      };
      if(this.auth.type==='htrip'){
        params.screenType=this.condition.screenType
      }
      this.$http
        .post(this.global.getSoftwareAssets, qs.stringify(params))
        .then((res) => {
          this.loading=false;
          if (res.data.code === 100000) {
            let { data } = res.data;
            this.defaultAllAppData = data;
            if(type==='filter'){
              this.filterMeth(this.filterConArr)
            }else{
              this.showAllAppData = data;
              this.getHeaderTitle();
            }
          } else {
            this.importData.loading = false;
            this.$message.error("预览失败");
          }
        })
        .catch(() => {
          this.loading=false;
          // this.$message.error("返回错误");
        });
    },
    filterMeth(arr){
      this.filterConArr=arr;
      var obj=this.defaultAllAppData;
      var filterObj={};
      Object.keys(obj).forEach(function(key){
        filterObj[key]=[];
        if(Array.isArray(obj[key])){
          obj[key].forEach((itemOut,index)=>{
            arr.forEach((itemInner,index)=>{
              if(itemOut.name===itemInner){
                filterObj[key].push(itemOut);
              }
            })
          })
        }
      });
      if(filterObj.activeApp.length===0){
        this.showAllAppData=this.defaultAllAppData;
      }else{
        this.showAllAppData=filterObj;
      }
      this.getHeaderTitle();
    }

  },
  mounted(){
    this.token=sessionStorage.getItem('token');
    this.auth=JSON.parse(sessionStorage.getItem('auth'));
    if(this.auth.type==='htrip'){
      this.hotelNatureBtn=true;
    }else{
      this.hotelNatureBtn=false;
    }
    this.initData();
  },
  computed:{
    showDataCom(){
      return function(val){
        if(val===null){
          return 0;
        }else{
          return parseFloat(val).toLocaleString();
        }
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.SearchPosition{
  position: relative;
}
.rightBtn{
  position: absolute;
  right: 16px;
  bottom: 22px;
  // display: flex;
  // justify-content: flex-end;
}
  .textCenter{
    display:flex;align-items: center;justify-content: center
  }
  .textCenter p{
    /*padding-left:0!important;*/
  }
  .location{
    display:flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: center!important;
  }
.dinNumber {
  font-family: DIN !important;
}
.distanceTop {
  padding-top: 20px;
}
.criteria {
  width: 98.6%;
  height: 37px;
  background: #f0f7ff;
  border: 1px solid rgba(226, 230, 235, 1);
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 20px;
  color: rgba(43, 70, 107, 1);
  margin-bottom:10px;
  // justify-content: space-between;
  .criteriaFlesBox {
    // width: 25%;
    display: flex;
    padding-right: 98px;
    .ellipsis {
      width: 196px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
  .contentSoftware {
    width: calc(100% - 520px);
    overflow-y: hidden;
    overflow-x: auto;
    /*background: rgba(248, 251, 255, 1);*/
  }

  /*漏斗图头开始*/
.headerBox{
  width:100%;
  display:flex;
  .left{
    width: 470px;
    height: 0;
    border-top: 111px solid #abd1f8;
    border-right: 32px solid #fff;
    border-left: 32px solid transparent;
    position: relative;
    z-index: 1;
    color: yellow;
  }
  .leftTitle{
    position: absolute;
    top: -77px;
    left: 38%;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(20, 35, 112, 0.43);
  }
  .headerItem{
    width:140px;
    padding-right:22px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .widthCompare{
      font-weight: 600!important;
      font-size: 18px;
      color: #3d587e;
    }
    }
  }
  .right{
    display:flex;
  }

  /*漏斗图头结束*/


.flexBoxBig {
  display: flex;
  padding-top: 3px;

  .trapezoid {
    /*width: 430px;*/
    width: 280px;
    height: 0;
    border-top: 111px solid #abd1f8;
    border-right: 32px solid #fff;
    border-left: 32px solid transparent;
    position: relative;
    z-index: 1;
    .location {
      position: absolute;
      top: -77px;
      left: 34%;
      color: #fff;
      text-shadow: 1px 1px 2px rgba(20, 35, 112, 0.43);
      p:first-child {
        font-size: 18px;
      }
      p:last-child {
        padding-top: 8px;
        font-size: 14px;
        opacity: 0.6;
      }
    }
  }
  .trapezoidOne {
    width: 215px;
    margin-left: 33px;
    border-top: 111px solid #90bcfc;
    .location {
      left: 26%;
    }
  }
  .contentSoftwareOne {
    padding: 22px 0 0 32px;
  }

  .trapezoidTwo {
    width: 150px;
    margin-left: 65px;
    border-top: 111px solid #9daaf3;
    .location {
      left: 24%;
      p:last-child {

        /*padding-left: 34px;*/
        // padding-left: 152%;
        // width: 100px;
      }
    }
  }
  .contentSoftwareTwo {
    padding: 22px 0 0 60px;
  }

  .trapezoidThree {
    width: 86px;
    margin-left: 97px;
    border-top: 111px solid #748af0;
    .location {
      left: 0;
      p:last-child {
        /*padding-left: 34px;*/
        // padding-left: 122%;
        // width: 100px;
      }
    }
    // p {
    //   padding-top: 5px;
    //   // padding-left: 5px;
    // }
  }
  .contentSoftwareThree {
    padding: 22px 0 0 90px;
  }
  .trapezoidFour {
    width: 172px;
    margin-left: 128px;
    border-top: 111px solid #5a76fd;
    .location {
      left: 34%;
      p:first-child {
        /*padding-left: 12px;*/
      }
      p:last-child {
        padding-left: 0;
        // padding-left: 90%;
        // width: 100px;
      }
    }
    // .locationOne {
    //   left: 43%;
    // }
  }
  .contentSoftwareFour {
    padding: 22px 0 0 120px;
  }
  .trapezoidSeven {
    width: 196px;
    margin-left: 160px;
    border-top: 111px solid #4967f3;
    .location {
      left: 19%;
      p:last-child {
        /*padding-left: 22px;*/
        // padding-left: 56%;
        // width: 100px;
      }
    }
  }
  .contentSoftwareSeven {
    padding: 22px 0 0 156px;
  }
  .trapezoidFive {
    width: 130px;
    margin-left: 192px;
    // width: 197px;
    // margin-left: 160px;
    border-top: 111px solid #3d5ae5;
    .location {
      left: 16%;
      p:last-child {
        padding-left: 2px;
        // padding-left: 26%;
        // width: 100px;
      }
    }
  }
  .contentSoftwareFive {
    // padding: 22px 0 0 152px;
    padding: 22px 0 0 185px;
  }
  .trapezoidSix {
    // width: 132px;
    // margin-left: 192px;
    width: 66px;
    margin-left: 222px;
    border-top: 111px solid #2c49d5;
    .location {
      left: -16px;
      top: -85px;
      p:last-child {
        /*padding-left: 34px;*/
        // padding-left: 0;
        // width: 100px;
      }
    }
  }
  .contentSoftwareSix {
    padding: 22px 0 0 218px;
  }
}

// 地区详情
.criteriaFlesBoxAddrss {
  cursor: pointer;
  position: relative;
  .detailedAdd {
    position: absolute;
    top: 24px;
    left: 0;
    width: 428px;
    height: 276px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    padding: 20px;
    z-index: 22;
    .flexCity {
      display: flex;
      font-size: 16px;
      padding-bottom: 6px;
      color: #5674b2;
      .weightFont {
        font-weight: 600;
        padding-right: 20px;
        flex: 1;
      }
      ul {
        font-weight: normal;
        display: flex;
        flex-wrap: wrap;
        flex: 5;
        li {
          padding: 0 10px 6px 0;
        }
      }
    }
  }
}
.marginTop{
  margin-top:30px!important;
}
.pointer{
  cursor:pointer;
}
  .tooltipBox{
    position:relative;
    cursor:pointer;
    .payment.tooltip{
      position:absolute;
      right:-36px!important;
      cursor:pointer;
    }
    .tooltip{
      position:absolute;
      right:-30px;
    }
  }
  .flexBox{
    display:flex;
  }
  .flexFullBox{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .numShowBox{
    flex:1;
    height:567px;
    /*background:red;*/
  }
  .numShowItem{
    height:111px;
    display:flex;
    padding:15px 0;
    box-sizing:border-box;
  }
  .imgBox{
    display:flex;
    flex-direction: column;
    margin-right: 10px;
    /*background:#f8fbff;*/
  }
  .exportBtn{
    margin-left:100px;
  }
  .headerTitle{
    height:60px;
    border-radius: 5px;
    background:#f7faff;
    margin-bottom:10px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    color: #3d587e;
  }
  .softHeader{
    margin-bottom:10px;
  }
.noWrap{
  white-space: nowrap!important;
}
</style>
