<template>
    <div class="inputColorChange">
        <!--<coop-tab-finance v-if="showData" :bankData="allData.bank" :contractData="allData.contract" :info="allData.info"></coop-tab-finance>-->
        <div class="outTopBox">
            <top-bread :bread="bread" v-if="curPage==='/indexShow'">
                <span slot="second">首页</span>
                <span slot="third">更多</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskToDo" v-if="curPage==='/taskToDo'">
                <span slot="second">任务管理</span>
                <span slot="third">待办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskDone" v-if="curPage==='/taskDone'">
                <span slot="second">任务管理</span>
                <span slot="third">已办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>

        </div>
        <el-card class="box-card">
            <el-form :model="form" ref="form" label-width="160px" class="demo-form">
                <el-col class="btnRow" :span="24" >
                    <div>
                        <back @backMeth="backMeth"></back>
                    </div>
                   <div class="rightBtn" v-if="btnShow">
                       <el-button type="danger" @click="reject" class="largeBtn">驳回</el-button>
                       <el-button type="primary" @click="pass" class="largeBtn ">通过</el-button>
                   </div>
                </el-col>
                <line-title>酒店基本信息</line-title>
                <el-col :span="12" >
                    <el-form-item label="酒店名称" label-width="200px" >
                        <el-input v-model="form.hotel_name" :readonly="onlyShow"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="终端门店编码" label-width="200px">
                        <el-input v-model="form.terminal_store_code" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="刷机管理员" label-width="200px">
                        <el-input v-model="form.root_admin" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="刷机管理员联系方式" label-width="200px" >
                        <el-input v-model="form.root_contact" :readonly="onlyShow"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="客服人员" label-width="200px">
                        <el-input v-model="form.customer_service_agent" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="技术服务" label-width="200px" >
                        <el-input v-model="form.technical_service_agent" :readonly="onlyShow"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="客房数量" label-width="200px">
                        <el-input v-model="form.room_num" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="终端数量" label-width="200px" >
                        <el-input v-model="form.terminal_num" :readonly="onlyShow"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="激活终端数量" label-width="200px">
                        <el-input v-model="form.active_terminal_num" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="已验收终端数量" label-width="200px" >
                        <el-input v-model="form.already_check_terminal_num" :readonly="onlyShow"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="此次验收终端数量" label-width="200px">
                        <el-input v-model="form.check_terminal_num" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="此次验收房间列表 " label-width="200px" >
                        <span @click="showRoomList" class="showRoom">{{showRoomData.showText}}</span>
                    </el-form-item>
                </el-col>

            </el-form>
        </el-card>
        <el-card class="box-card noPaddingTop" v-if="showRoomData.show">
            <div class="checkPic" >
                <line-title class="noTopPadding">此次验收房间列表</line-title>
                <el-row :gutter="20"  >
                    <el-col :span="2" :key="index"  v-for="(item,index) in roomList" >
                        <div class="roomItem online" @click="showPic(item.room_number,item.code_url,item.pname)">{{item.room_number}}</div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card noPaddingTop">
            <el-form :model="form" ref="form" label-width="160px" class="demo-form">
                <line-title>酒店结算信息</line-title>
                <el-col :span="12" >
                    <el-form-item label="所属合作伙伴" label-width="200px" >
                        <el-input v-model="form.partner" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="上线合作伙伴" label-width="200px">
                        <el-input v-model="form.onlinePartner" :readonly="onlyShow" ></el-input>
                        {{form.onlinePartner}}
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="系统版式" label-width="200px">
                        <el-input v-model="form.settle_type" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="默认结算单价" label-width="200px" >
                        <el-input v-model="form.price" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="是否结算" label-width="200px">
                        <el-input v-model="form.is_settle_online_text" :readonly="onlyShow" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="实际结算单价" label-width="200px" v-if="auth.type==='htrip'" >
                        <el-input v-model="realPrice"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-card>

        <el-card class="box-card noPaddingTop">
            <div class="checkPic">
                <line-title>酒店验收单</line-title>
                <el-form ref="form" label-width="200px">
                    <el-col :span="24">
                        <el-form-item label="" prop="imgDialog" class="bigImgShow">
                            <el-upload :disabled="true"
                                       action="global.upload"
                                       list-type="picture-card"
                                       :on-preview="imgPreview"
                                       :file-list="imgDialog.defaultList"
                                       :headers="headers"
                                       :limit="1" class="disabled">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="imgDialog.dialogVisible">
                                <img width="100%" :src="imgDialog.img_url" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-form>
            </div>
        </el-card>
        <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
        <el-dialog title="查看凭证" :visible.sync="picDialog.dialogTableVisible" width="600px">
            <div class="showPicBox">
                <div class="title">刷机人：{{picDialog.pname}}</div>
                <div class="middle">
                    <!--<div class="picBox" v-if="picDialog.pic_state===0">-->
                        <!--<div class="inner">-->
                            <!--<i class="el-icon-picture"></i>-->
                            <!--<div class="text">暂无凭证</div>-->
                        <!--</div>-->
                    <!--</div >-->
                    <img :src="picDialog.code_url" alt=""  @load="imgLoad" v-loading="showRoomData.showLoading">
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "IndexDoQualify",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/indexDoMore'},
            },
            breadTaskToDo:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/taskToDo'},
            },
            breadTaskDone:{
                level:4,
                thirdPath:{path: '/taskDone'},
            },
            imgDialog:{
                img_url:[],
                dialogVisible:false,
                uploadDisabled:true,
                defaultList:[
                    // {name:'front',url:"http://test7-1104358706.cn-northwest-1.elb.amazonaws.com.cn:8000/ssp/public/uploads/20200529/460df5abfc94fdb77028fa163f5c40bd.jpg"}
                ]
            },

            bankData:{},
            form:{
                test:'',

            },
            rules:{
                test: [
                    { required: true, message: '请输入集团品牌', trigger: 'blur' }
                ],
            },
            contractData:{},
            infoData:{},
            query:{
                id:''
            },
            token:'',
            auth:{},
            showData:false,
            onlyShow:true,
            headers:{
                token:'',
                Authorization:''
            },
            roomList:[
                // {id:1,room_number:'0001',brushName:'张三',pic_state:1,pic_url:'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1592529891870&di=973d09af0f76246df4b3688bb0fa4e44&imgtype=0&src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20180731%2Fd8b0fe4f732144c4b9b10f9eee446df6.jpeg'},
                // {id:1,room_number:'0002',brushName:'李四',pic_state:1,pic_url:'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3480175068,2262619765&fm=26&gp=0.jpg'},
                // {id:1,room_number:'0003',brushName:'王五',pic_state:1,pic_url:'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1692360401,675901463&fm=26&gp=0.jpg'},
            ],
            picDialog:{
                dialogTableVisible:false,
                code_url:'',
                pname:'brushName',
                room_number:'',
            },
            showRoomData:{
                showLoading:false,
                showText:'展开',
                show:false
            },
            realPrice:'',
            curPage:'',
            btnShow:false,
        }),
        methods:{
            reject() {
                this.$refs.rejectDialog.dialogShow(true);
            },
            pass(){
                this.$confirm('确定通过?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.examine(1);
                })
            },
            examine(status){
                var param=qs.stringify({
                    token:this.token,
                    id:this.query.id,
                    status:status,
                    message:this.dialog.reason,
                    price:this.realPrice
                });
                this.$http.post(this.global.rootAction,param).then(res=>{
                    var str='';
                    status===1?str='审核':str='驳回';
                    if(res.data.code===100000){
                        this.showMessage(str+'成功','success');
                    }else{
                        this.showMessage(res.data.msg);
                    }
                    this.$router.push('/indexDoMore')
                });
            },
            showMessage(str,type){
                this.$message({
                    message: str,
                    type: type
                });
            },
            getDetail(){
                this.$http.get(this.global.registerReviewDetail,{
                    params:{
                        token:this.token,
                        id:this.query.id,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var formData=res.data.data;
                        this.form=formData;
                        this.realPrice=formData.price;
                        formData.is_settle_online*1===1?this.form.is_settle_online_text='是':this.form.is_settle_online_text='否';
                        var checkPicList = JSON.parse(this.form.acceptance_form_url.replaceAll("&quot;", "\""));
                        // this.imgDialog.defaultList.push({name:'img',url:this.form.acceptance_form_url});
                        this.imgDialog.defaultList.push(
                            {name:'img',url:checkPicList.checkPic},
                            {name:'img',url:checkPicList.headPic},
                            {name:'img',url:checkPicList.receptionPic},
                            {name:'img',url:checkPicList.roomPic},
                            {name:'img',url:checkPicList.welPic},
                            {name:'img',url:checkPicList.goodsPic},
                            );
                        this.form.realPrice='';
                    }
                })
            },
            showRoomList(){
                if(this.showRoomData.show){
                    this.showRoomData.show=false;
                    this.showRoomData.showText='展开';
                }else{
                    this.showRoomData.show=true;
                    this.showRoomData.showText='收起';
                    this.getRoomReceive();
                }
            },
            getRoomReceive(){
                var param=qs.stringify({token:this.token,receiving_number:this.form.receiving_number});
                this.$http.post(this.global.getRootDetailByReceiving,param).then(res=>{
                    if(res.data.code===100000){
                        var roomData=res.data.data;
                        this.roomList=roomData;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(res=>{
                    this.$message.error(res.data.msg);
                })
            },
            //身份证正面
            imgPreview(file) {
                this.imgDialog.img_url = file.url;
                this.imgDialog.dialogVisible = true;
            },
            imgRemove(file, fileList) {

            },
            imgSuccess(res,file,fileList){

            },
            imgBefore(){
            },
            imgChange(){
            },
            showPic(room_number,code_url,pname){
                this.showRoomData.showLoading=true;
                this.picDialog.room_number=room_number;
                this.picDialog.code_url=code_url;
                this.picDialog.pname=pname;
                this.picDialog.dialogTableVisible=true;
            },
            imgLoad(){
                this.showRoomData.showLoading=false;
            },
            backMeth(){
                this.$router.push({path:`./${this.query.fromUrl}`})
            },
        },
        mounted(){
            var query=this.$route.query;
            // query.reviewStatus==='待审核'?this.onlyShow=false:this.onlyShow=true;
            this.curPage=sessionStorage.getItem('defaultActive');
            this.token=sessionStorage.getItem('token');
            var auth=JSON.parse(sessionStorage.getItem('auth'));
            this.auth=auth;
            this.query=query;
            if(query.reviewStatus==='待审核'){
                this.btnShow=true;
            }else{
                this.btnShow=false;
            }
            this.getDetail();
        }
    }
</script>

<style scoped lang="scss">

    .redBtn{
        background:#de4313!important;
    }
    .redBtn:hover{
        background:#de6414!important;
    }
    .btnRow{
        .el-button:first-child{
            margin-left:10px;
        }
    }
    .imgBox{
        width:400px;
        height:500px;
        background:#ddd;
        /*margin:0 auto;*/
        img{
            width:100%;
            height:100%;
        }
    }
    .showRoom{
        color:#3085fc;
        cursor:pointer;
    }
    .roomBox{
        overflow:hidden;
        padding-bottom:90px;
    }
    .roomItem{
        padding:10px;
        border-radius:4px;
        text-align: center;
        border:1px solid #ddd;
        cursor:pointer;
        position:relative;
        color:#3085fc;
    }
    .roomBox .roomItem.online {
        background: #78bf31;
        color: #fff;
    }
    .roomBox .roomItem.offline {
        background: #f6f4f5;
        color: #333;
    }
    .test{
        width:100%;
    }
    /* 凭证弹窗开始 */
    .showPicBox{
        width: 100%;
        background: #fff;
        border-radius: 7px;
        box-sizing: border-box;
        transition: 0.3s;
    }
    .showPicBox .title {
        width: 100%;
        text-align: left;
        transition: 0.3s;
        padding-bottom:30px;
        box-sizing: border-box;
        font-size:16px;
    }

    .middle{
        width:100%;
        height:300px;
        /*padding:0 40px;*/
        box-sizing: border-box;
    }
    .middle img{
        width:100%;
        height:100%;
    }
    .picBox {
        width: 100%;
        height:100%;
        margin: 0 auto 30px auto;
        position: relative;
        transition: 0.3s;
        border-radius:7px;
        box-sizing: border-box;
        background:#e7f5ff;
    }

    .picBox .inner{
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .inner i{
        font-size:116px;
        margin-bottom:30px;
        color:#afcade;
    }
    .inner .text{
        font-size:18px;
        color:#afcade;
    }



    /* 凭证弹窗结束 */
</style>
