<template>
    <div class="headerOutBox">
        <div class="headerItem flexFullBox" v-for="(item,index) in headerTitleArrProp" :key="index">
            <div class="widthCompare">
                {{item}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SoftNum",
        props:['headerTitleArrProp','filterConArr',
            'showAllAppDataProp'],
        data:()=>({
            showTitleArr:[],
        }),
        mounted(){
            this.showTitleArr=this.headerTitleArrProp;
        },
        computed:{
            showDataCom(){
                return function(val){
                    if(val===null){
                        return 0;
                    }else{
                        return parseFloat(val).toLocaleString();
                    }
                }
            },
            rateTextStyleCom(){
                return function(level,percent){
                    var color='';
                    var icon='';
                    var upIcon='<i class="iconfont">&#xe633;</i>';
                    var downIcon='<i class="iconfont">&#xe631;</i>';
                    if(level == 1 && percent!=0.00){
                        icon=upIcon;
                        color='redFonts'
                    }else if(level == 1 && percent==0.00){
                        icon='';
                        color='grayFonts'
                    }else if(item.level != 1){
                        icon='downIcon';
                        color='greenFonts'
                    }
                    return color;
                }
            }
        },

        watch:{
            filterConArr(newVal){
                // if(newVal.length===0){
                //     this.showTitleArr=this.headerTitleArrProp;
                // }else{
                //     this.showTitleArr=newVal;
                // }
                // console.log('监听条件',newVal);
            },
        },
    }
</script>

<style scoped lang="scss">
    ::-webkit-scrollbar{
        height: 3px;
    }
    .numShowBox{
        flex:1;
        height:456px;
        /*background:red;*/
    }
    .headerOutBox{
        height:60px;
        display:flex;
        box-sizing:border-box;
        background:#f7faff;
        /*overflow-y: auto;*/
    }
    .headerItem {
        box-sizing: border-box;
        font-size: 16px;
        text-align: left;
        color: #676e74;
        /*border-right: 1px solid #e7eef3!important;*/
        display: flex;
        align-items: center;
        background:#f7faff;
        div{
            color: #3d587e;
        }
        .fontBold {
            font-weight: bold;
            font-size: 28px;
            color: rgba(1, 1, 1, 1);
            padding-bottom: 7px;
            font-family: DIN !important;
            .num{
                font-weight: bold;
                font-size: 28px;
                color: rgba(1, 1, 1, 1);
                padding-bottom: 7px;
                font-family: DIN !important;
                margin-right:10px;
            }
            span {
                font-weight: normal;
                font-size: 12px;
            }
        }
        .widthCompare {
            color: #3d587e;
            font-size: 18px;
            width: 200px;
            height:40px;
            padding: 0 20px;
            display:flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #e7eef3;
            font-weight:600;
        }
        .redFonts {
            font-family: DIN !important;
            color: rgba(255, 8, 8, 1);
        }
        .greenFonts {
            font-family: DIN !important;
            color: rgba(57, 187, 40, 1);
        }
        .grayFonts {
            font-family: DIN !important;
            color: #939495;
        }
    }
</style>