<template>
    <div class="grid-content bg-purple chartCon">
        <div class="chartTitle" v-if="chart.showTitle">数据走势</div>
        <div :id="chart.id" class="chart"></div>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import GLOBAL from '../../api/globalVar.js'
    export default {
        name: "LineChart",
        props:['chart'],
        data: () => ({

        }),
        mounted(){
            var id=this.id;
            var chart = echarts.init(document.getElementById(this.chart.id));
            // var option=this.chart.option;
            var option = {
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer:{
                        type:'none'
                    }
                    // formatter: "{a} <br/>{b}: {c} ({d/}%)" // 这里是鼠标移上去的显示数据
                },
                legend: {
                    x:'center',
                    y:'bottom',
                    icon:'circle',
                    padding:[0,0,0,50],
                    data:this.chart.option.legend
                },
                grid: {
                    top:'5%',
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        // color:'#ced7ec', //折点颜色
                        lineStyle: {
                            color: '#ced7ec',
                        }
                    },
                    axisLabel:{
                        textStyle:{
                            color:'#333'
                        },
                    },
                    data:this.chart.option.xData
                    // data:[1,2,4,5,6]
                },
                yAxis: {
                    type: 'value',
                    axisLabel:{
                        formatter:`{value}台`,
                        textStyle:{
                            color:GLOBAL.chartColor[0]
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            type:'dashed',
                            color:'#ebeef5'
                        }
                    },
                },
                series: this.chart.option.series
            };
            chart.setOption(option,true);

        },
        watch:{
            chart(val){

            }
        }
    }
</script>

<style scoped lang="scss">

    .grid-content {
        height:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        /*padding:15px 20px;*/
        .leftBox{
            display:flex;
            align-items: center;
        }
        .numTitle{
            width:150px;
        }
        .num{
            font-size:24px;
            margin-right:10px;
        }
    }
    .bg-purple {
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        /*padding:20px 20px;*/
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .chartBox{
        height:400px;
        margin-bottom:20px!important;
        overflow:hidden;
    }
    .grid-content{
        box-sizing:border-box;
    }
    .el-col{
        height:100%;
        .grid-content{
            height:100%;
        }
    }

    .chartCon{
        height:100%;
        display:flex;
        flex-direction:column;
    }
    #chart,.chart{
        width:100%;
        /*height:300px;*/
        flex:1;
    }

</style>
