<template>
  <div>
    <div class="homeContentBox">
      <div class="model" v-if="pic1Hidden||videoHidden||pic2Hidden||pic3Hidden||inputText" @click="closeModel"></div>
      <edit-btn @ishidden="isHidden" :msg="'servicePic1'" :itemName="'1'" :class="['editBtnStyle','editBtnCenter',pic1Hidden?'btnLight':'',]" :hidden="pic1Hidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('servicePic1')" @mouseleave.native="borderHidden('servicePic1')"></edit-btn>
      <edit-btn @ishidden="isHidden" :msg="'serviceVideo'" :upType="'video'" :class="['editBtnStyle','editBtnVideoStyle',videoHidden?'btnLight':'',]" :hidden="videoHidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('serviceVideo')" @mouseleave.native="borderHidden('serviceVideo')"></edit-btn>
      <edit-btn @ishidden="isHidden" :msg="'servicePic2'" :itemName="'2'" :class="['editBtnStyle','editBtnPic1Style',pic2Hidden?'btnLight':'',]" :hidden="pic2Hidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('servicePic2')" @mouseleave.native="borderHidden('servicePic2')"></edit-btn>
      <edit-btn @ishidden="isHidden" :msg="'servicePic3'" :itemName="'3'" :class="['editBtnStyle','editBtnPic2Style',pic3Hidden?'btnLight':'',]" :hidden="pic3Hidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('servicePic3')" @mouseleave.native="borderHidden('servicePic3')"></edit-btn>
      <img class="bgPic" :src="btnList.bgUrl" alt="">
      <div class="logoBox">
        <!-- @mouseenter="borderShow('logo')" @mouseleave="borderHidden('logo')" -->
        <div class="logoBoxLeft">
          <img :src="logoUrl" alt="">
        </div>
        <div class="logoBoxRight">
          <div class="info_one">
            <div class="top">{{curTime.time}}</div>
            <div class="bottom">{{curTime.date}}</div>
          </div>
          <div class="info_two">
            <div class="top">广州 晴</div>
            <div class="bottom">25~30℃</div>
          </div>
        </div>
      </div>
      <div class="contentShowBox">
        <!-- @mouseenter="borderShow('video')" @mouseleave="borderHidden('video')" -->
        <!-- <div  :class="['videoBox',videoHidden?'contentLight':'',videoBorderShow?'borderActive':'']" >
                     <video :src="pageData.modelOne" controls></video>
                 </div> -->
        <div class="contentShowBoxLeft">
          <div :class="['videoBox',videoHidden?'contentLight':'',videoBorderShow?'borderActive':'']">
            <video v-if="pageData.video" style="width:100%;height:100%" :src="pageData.video.url" controls></video>
          </div>
          <div :class="['textShow',inputText?'contentLight':'',inputBorderShow?'borderActive':'']" class="textShow">
            <p v-if="!inputText" @click="inputTextContent">{{pageData.word}}</p>
            <el-input v-if="inputText" v-model="pageData.word" type="textarea" resize="none" @blur="endTextContent">
            </el-input>
          </div>
        </div>
        <div class="contentShowBoxCenter">
          <div class="picShowTop" :class="['picShowTop',pic1Hidden?'contentLight':'',pic1BorderShow?'borderActive':'']">
            <img :src="getImgUrl('1')" alt="">
            <div class="plateName"><span>{{getPicName('1')}}</span></div>
          </div>
          <div class="picShowBottom">
            <div class="picShowBottomLeft" :class="['picShowBottomLeft',pic2Hidden?'contentLight':'',pic2BorderShow?'borderActive':'']">
              <img :src="getImgUrl('2')" alt="">
              <div class="plateName"><span>{{getPicName('2')}}</span></div>
            </div>
            <div class="picShowBottomRight" :class="['picShowBottomRight',pic3Hidden?'contentLight':'',pic3BorderShow?'borderActive':'']">
              <img :src="getImgUrl('3')" alt="">
              <div class="plateName"><span>{{getPicName('3')}}</span></div>
            </div>
          </div>
        </div>
        <div class="contentShowBoxRight">
          <img src="@/assets/img/109.jpg" alt="">
          <!-- <img :src="getImgUrl('4')" alt="">
              <div class="plateName"><span>{{getPicName('4')}}</span></div> -->
        </div>
      </div>
      <div class="btnList">
        <div :class="['btnCard',pageData.title == item.mname?'active':'']" v-for="(item,index) in btnList.menu" :key="index">
          <img v-if="pageData.title == item.mname?'active':''" :src="item.focuspicaddr" alt="">
          <img v-if="pageData.title !== item.mname?'active':''" :src="item.defaultpicaddr" alt="">
          <span>{{item.mname}}</span>
        </div>
      </div>
    </div>
    <to-upload :hotelContract="hotelContract" @upsuccess="upSuccess" key="ser1"></to-upload>
    <drawer-box :hotelContract="hotelContract" :allPicAata="pageData.pic" @trueedit='trueEdit' @canceledit="cancelEdit"></drawer-box>
  </div>
</template>
<script>
import editBtn from "../common/editBtn";
import toUpload from "../common/toUpload"
import drawerBox from "../common/drawerBox"
import commonMeth from "@/api/method";
export default {
  props: ["attrName","data", "optBtnList", "logoUrl","hotelContract","modelShow"],
  components: {
    editBtn,
    toUpload,
    drawerBox
  },
  data() {
    return {
      pic1Hidden: false,
      pic1BorderShow: false,
      videoHidden: false,
      videoBorderShow: false,
      pic2Hidden: false,
      pic2BorderShow: false,
      pic3Hidden: false,
      pic3BorderShow: false,
      inputText: false,
      inputBorderShow: false,
      pageData: {},
        historyData:{
          video:{},
          pic:{}
        },
          saveData:{},
      btnList: {},
      curTime: {
        date: "",
        week: "",
        time: "",
      },
    };
  },
  methods: { 
    cancelEdit(data,itemName){
      this.saveData[this.attrName].pic[itemName] = JSON.parse(JSON.stringify(data));
      this.pageData.pic[itemName] = JSON.parse(JSON.stringify(data));
      this.sendDataForVuex();  
    },
    trueEdit(data){
         this.saveData[this.attrName].pic = JSON.parse(JSON.stringify(data));
        this.pageData.pic = JSON.parse(JSON.stringify(data));
      this.sendDataForVuex();  
      console.log(this.$store.state.visualContent);
    },
     // 保存数据至vuex
      sendDataForVuex(){
        this.$store.commit("saveVisualContent",this.saveData)

      },
      // 保存初始历史数据
      saveHistory(){
        Object.assign(this.historyData, JSON.parse(JSON.stringify(this.pageData)));
      },
      // 上传成功
      upSuccess(data,msg){ 
        if(msg=='serviceVideo'){
             this.saveData[this.attrName].video.url =data.url;
             this.pageData.video.url =data.url;
             this.saveData[this.attrName].video.md5 =data.md5;
             this.pageData.video.md5 =data.md5;
             this.sendDataForVuex();  
        }
      },
      // 恢复历史文件
      recoveryData(val){
        console.log(val);
        if(val=='serviceVideo'){
             this.saveData[this.attrName].video.url =this.historyData.video.url;
             this.pageData.video.url =this.historyData.video.url;
             this.saveData[this.attrName].video.md5 =this.historyData.video.md5;
             this.pageData.video.md5 =this.historyData.video.md5;
             this.sendDataForVuex();
           
        }else if (val == "servicePic1") {
          this.saveData[this.attrName].pic['1'] = JSON.parse(JSON.stringify(this.historyData.pic['1']));
          this.pageData.pic['1'] = JSON.parse(JSON.stringify(this.historyData.pic['1']));
          this.sendDataForVuex();
      } else if (val == "servicePic2") {
          this.saveData[this.attrName].pic['2'] = JSON.parse(JSON.stringify(this.historyData.pic['2']));
          this.pageData.pic['2'] = JSON.parse(JSON.stringify(this.historyData.pic['2']));
          this.sendDataForVuex();
       
      } else if (val == "servicePic3") {
            this.saveData[this.attrName].pic['3'] = JSON.parse(JSON.stringify(this.historyData.pic['3']));
          this.pageData.pic['3'] = JSON.parse(JSON.stringify(this.historyData.pic['3']));
          this.sendDataForVuex();
      }
      },
    // 按钮
    isHidden(val) {
      if (val == "servicePic1") {
        this.pic1Hidden = !this.pic1Hidden;
      } else if (val == "serviceVideo") {
        this.videoHidden = !this.videoHidden;
      } else if (val == "servicePic2") {
        this.pic2Hidden = !this.pic2Hidden;
      } else if (val == "servicePic3") {
        this.pic3Hidden = !this.pic3Hidden;
      }
    },
    // 边框显示
    borderShow(val) {
      if (val == "servicePic1") {
        this.pic1BorderShow = true;
      } else if (val == "serviceVideo") {
        this.videoBorderShow = true;
      } else if (val == "servicePic2") {
        this.pic2BorderShow = true;
      } else if (val == "servicePic3") {
        this.pic3BorderShow = true;
      }
    },
    // 边框隐藏
    borderHidden(val) {
      if (val == "servicePic1") {
        this.pic1BorderShow = false;
      } else if (val == "serviceVideo") {
        this.videoBorderShow = false;
      } else if (val == "servicePic2") {
        this.pic2BorderShow = false;
      } else if (val == "servicePic3") {
        this.pic3BorderShow = false;
      }
    },
    // 关闭遮罩
    closeModel() {
      this.pic1Hidden = false;
      this.videoHidden = false;
      this.pic3Hidden = false;
      this.pic2Hidden = false;
      this.inputText = false;
    },
    // 打开文字输入区域
    inputTextContent() {
      this.inputText = true;
    },
    endTextContent() {
      this.inputText = false;
    },
  },
  created() {},
  computed: {
    getImgUrl() {
      return function (val) {
        var obj = this.pageData.pic;
        for (const key in obj) {
          if (key == val ) {
              return obj[key].picHorizontal.url;
          } 
          
        }
      };
    },
    getPicName() {
      return function (val) {
        var obj = this.pageData.pic;
        for (const key in obj) {
          if (key == val && key == "1") {
            return obj[key].mname;
          } else if (key == val && key !== "1") {
            return obj[key].mname;
          }
        }
      };
    },
  },
  mounted() {
     this.saveData[this.attrName]=this.data;  
    this.saveHistory();
    this.sendDataForVuex();
    setInterval(() => {
      this.curTime = commonMeth.getCurrentDate();
    }, 1000);
  },
  watch: {
      modelShow:{
        handler: function (newVal) {
            this.closeModel();
        },
      deep: true,
      immediate: true,
    },
    data: {
      handler: function (newVal) {
        this.pageData = newVal;
      },
      deep: true,
      immediate: true,
    },
    optBtnList: {
      handler: function (newVal) {
        this.btnList = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style  scoped>
.editBtnStyle {
  position: absolute;
  width: 16%;
  height: 5%;
  z-index: 999;
}
.editBtnCenter {
  top: 17.6%;
  left: 43.6%;
}
.editBtnVideoStyle {
  top: 17%;
  left: 0%;
}
.editBtnPic1Style {
  top: 49.5%;
  left: 43.6%;
}
.editBtnPic2Style {
  top: 49.5%;
  right: 25.5%;
}
.homeContentBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.bgPic {
  width: 100%;
  height: 100%;
}
.logoBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 17.5%;
  width: 88%;
  margin: 0 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borderActive{
     border: 2px dashed #4093ff;
     transition: .2s;
}
.contentLight {
  position: relative;
  z-index: 1998;
}
.btnLight {
  z-index: 1999;
}
.logoBoxLeft {
  height: 52%;
  /* box-sizing: border-box; */
}
.logoBoxLeft img {
  height: 100%;
}
.logoBoxRight {
  height: 50%;
  display: flex;
  font-size: 1.8vw;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}
.info_two,
.info_one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_one {
  padding-right: 15px;
}
.info_two {
  padding-left: 15px;
  border-left: 1px solid #fff;
}
.info_two .top {
  margin-top: 2%;
}
.logoBox .bottom,
.info_two {
  font-size: 1vw;
}
.contentShowBox {
  position: absolute;
  top: 17.5%;
  left: 0;
  height: 61.5%;
  width: 88%;
  margin: 0 6%;
}
.contentShowBoxLeft {
  float: left;
  width: 41.5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.videoBox {
  height: 63.1%;
  width: 100%;
}
.textShow {
  border: 1px solid #000;
  height: 34.6%;
  width: 100%;
  color: #fff;
  padding: 3%;
  font-size: 0.8vw;
  line-height: 130%;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
}
.textShow p {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.textShow:hover p {
  border: 1px dashed #4093ff;
}
/* ::v-deep.textShow \ .el-textarea__inner:hover */
.el-textarea {
  width: 100%;
  height: 100%;
}
>>> .el-textarea__inner {
  background: transparent;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0.8vw;
  font-family: "Microsoft YaHei";
   line-height: 130%;
  border: none;
  padding: 0;
  color: #fff;
}
>>> .el-textarea__inner:hover {
  border: 1px dashed #4093ff;
}
.contentShowBoxCenter {
  float: left;
  width: 33.3%;
  height: 100%;
  margin-left: 0.9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.picShowTop,
.picShowBottom {
  height: 48.7%;
  width: 100%;
  position: relative;
}
.plateName {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 17%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.8vw;
}
.picShowBottom {
  display: flex;
  justify-content: space-between;
}
.picShowTop img,
.picShowBottomLeft img,
.picShowBottomRight img,
.contentShowBoxRight img {
  width: 100%;
  height: 100%;
}
.picShowBottomLeft {
  width: 48.7%;
  height: 100%;
  position: relative;
}
.picShowBottomRight {
  width: 48.7%;
  height: 100%;
  position: relative;
}
.contentShowBoxRight {
  float: right;
  width: 23.5%;
  height: 100%;
  position: relative;
}
.btnList {
  position: absolute;
  top: 82%;
  left: 0;
  height: 11%;
  width: 88%;
  margin: 0 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnCard {
  display: flex;
  width: 10.5%;
  height: 100%;
  margin-right: 1.6%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  color: #a4a4a4;
}
.btnCard.active {
  background: rgba(7, 0, 2, 0.3);
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 162, 255, 75%);
  color: #fff;
}
.btnCard:last-child {
  margin-right: 0%;
}
.btnCard img {
  height: 42%;
}
.btnCard span {
  margin-top: 8%;
}
.model {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
</style>