<template>
    <div>
        <!--<coop-tab-finance v-if="showData" :bankData="allData.bank" :contractData="allData.contract" :info="allData.info"></coop-tab-finance>-->

        <div class="outTopBox">
            <top-bread :bread="bread" v-if="curPage==='/indexShow'">
                <span slot="second">首页</span>
                <span slot="third">更多</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskToDo" v-if="curPage==='/taskToDo'">
                <span slot="second">任务管理</span>
                <span slot="third">待办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskDone" v-if="curPage==='/taskDone'">
                <span slot="second">任务管理</span>
                <span slot="third">已办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <el-row class="btnRow">
                <div>
                    <back @backMeth="backMeth"></back>
                </div>
                <div  v-if="!btnShow" class="rightBtn">
                    <el-button type="danger" class="largeBtn" @click="reject">驳回</el-button>
                    <el-button type="primary" class="largeBtn" @click="pass">通过</el-button>
                </div>
            </el-row>
            <coop-tab-finance v-if="showData" :bankData="bankData" :contractData="contractData" :info="infoData" :readonly="true" :onlyShow="btnShow" :saveBtnShow="saveBtnShow" :allData="allData" type="examine"></coop-tab-finance>
            <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
        </el-card>
    </div>
</template>

<script>
    import commonMeth from '../../api/method'
    import  qs from 'querystring'
    export default {
        name: "IndexDoQualify",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/indexDoMore'},
            },
            breadTaskToDo:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/taskToDo'},
            },
            breadTaskDone:{
                level:4,
                thirdPath:{path: '/taskDone'},
            },
            bankData:{},
            contractData:{},
            infoData:{},
            query:{
                id:''
            },
            token:'',
            showData:false,
            btnShow:true,
            saveBtnShow:false,
            allData:{
                info:{
                    company_name:''
                },
                bank:{
                    uscc:'',
                    company_address:'',
                    corporation:'',
                    corporation_id:'',
                    corporation_phone:'',
                    corporation_id_front:'',
                    corporation_id_back:'',
                    license_img:'',
                    bank_name:'',
                    bank_address:'',
                    branch_name:'',
                    bank_account:'',
                },
                contract:{
                    company_name:''
                },
            },
            curPage:''
        }),
        methods:{
            reject() {
                this.$refs.rejectDialog.dialogShow(true);
            },
            pass(){
                this.$confirm('确定通过?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.examine(1);
                }).catch(() => {

                });
            },
            examine(status){
                var param=qs.stringify({
                    token:this.token,
                    id:this.query.id,
                    status:status,
                    message:this.$refs.rejectDialog.dialog.reason,
                });
                this.$http.post(this.global.partnerAction,param).then(res=>{
                    var str='';
                    status===1?str='审核':str='驳回';
                    if(res.data.code===100000){
                        this.showMessage('审核成功','success');
                    }else{
                        this.showMessage(res.data.msg,'error');
                    }
                    this.$router.push('/indexDoMore')
                });
            },
            showMessage(str,type){
                this.$message({
                    message: str,
                    type: type
                });
            },
            getDetail(){
                this.$http.get(this.global.registerReviewDetail,{
                    params:{
                        token:this.token,
                        id:this.query.id,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var formData=res.data.data;
                        console.log('name',formData.company_name);
                        this.allData.info.company_name=formData.company_name;
                        this.allData.bank.uscc=formData.uscc;
                        this.allData.bank.company_address=formData.company_address;
                        this.allData.contract.contract_date_begin=formData.contract_date_begin;
                        this.allData.contract.contract_date_end=formData.contract_date_end;
                        this.allData.bank.corporation=formData.corporation;
                        this.allData.bank.corporation_id=formData.corporation_id;
                        this.allData.bank.corporation_phone=formData.corporation_phone;
                        this.allData.bank.corporation_id_front=formData.corporation_id_front;
                        this.allData.bank.corporation_id_back=formData.corporation_id_back;
                        this.allData.bank.license_img=formData.license_img;

                        this.allData.bank.bank_name=formData.bank_name;
                        this.allData.bank.bank_address=formData.bank_address;
                        this.allData.bank.branch_name=formData.branch_name;
                        this.allData.bank.bank_account=formData.bank_account;
                        // this.contractData.contract_date_begin=formData.contract_date_begin;
                        // this.contractData.contract_date_end=formData.contract_date_end;
                        // this.bankData.company_name=formData.company_name;
                        // this.bankData.uscc=formData.uscc;   //社会信用代码
                        // this.bankData.company_address=formData.company_address;
                        // this.bankData.corporation=formData.corporation;
                        // this.bankData.corporation_id=formData.corporation_id;
                        // this.bankData.corporation_phone=formData.corporation_phone;
                        // this.bankData.corporation_id_front=formData.corporation_id_front;
                        // this.bankData.corporation_id_back=formData.corporation_id_back;
                        // this.bankData.license_img=formData.license_img;
                        // this.bankData.bank_name=formData.bank_name;
                        // this.bankData.bank_address=formData.bank_address;
                        // this.bankData.branch_name=formData.branch_name;
                        // this.bankData.bank_account=formData.bank_account;
                        // this.infoData.company_name=formData.company_name;
                        console.log('最后allData',this.allData);
                        // console.log('父组件',this.infoData);
                        this.showData=true;
                    }
                })
            },
            backMeth(){
                this.$router.push({path:`./${this.query.fromUrl}`})
            },
        },
        mounted(){
            var query=this.$route.query;
            this.curPage=sessionStorage.getItem('defaultActive');
            this.query.id=query.id;
            this.query.reviewStatus=query.reviewStatus;
            this.query.fromUrl=query.fromUrl;
            query.reviewStatus==='待审核'?this.btnShow=false:this.btnShow=true;
            this.token=sessionStorage.getItem('token');
            this.getDetail();
        }
    }
</script>

<style scoped lang="scss">
    .btnRow{
        display:flex;
        .el-button:first-child{
            margin-left:10px;
        }
    }

</style>
