<template>
    
    <div class="main" @click="hideShade">
       
        <el-form :model="formData"  ref="form" :rules="rules" label-width="160px" class="form noFixedWidthForm" @click="hideShade">
            <el-form-item label="模块名称" prop="mname">
                <el-input v-model="formData.mname" @input="inputMeth" :disabled="judgeDisabled"></el-input>
            </el-form-item>
            <el-form-item label="横版封面图片" prop="picHorizontalHas">
                <el-upload class="noTransition"
                           :action="global.materialUpload"
                           list-type="picture-card"
                           :on-preview="picHorizontalPreview"
                           :on-remove="picHorizontalRemove"
                           :on-success="picHorizontalSuccess"
                           :on-before="picHorizontalBefore"
                           :on-change="picHorizontalChange"
                           :before-upload="uploadBefore"
                           accept=".jpg,.jpeg,.png,JPG,.JPEG"
                           :file-list="picHorizontal.defaultList"
                           :headers="headers"
                           :limit="1" :disabled="judgeDisabled"
                           :class="{disabled:picHorizontal.uploadDisabled}">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="picHorizontal.dialogVisible" :modal="false">
                    <img width="100%" :src="picHorizontal.url" alt="">
                </el-dialog>
            </el-form-item>
            <el-form-item label="竖版封面图片" prop="picVerticalHas">
                <el-upload class="noTransition"
                        :action="global.materialUpload"
                        list-type="picture-card"
                        :on-preview="picVerticalPreview"
                        :on-remove="picVerticalRemove"
                        :on-success="picVerticalSuccess"
                        :on-before="picVerticalBefore"
                        :on-change="picVerticalChange"
                        :before-upload="uploadBefore"
                        accept=".jpg,.jpeg,.JPG,.JPEG"
                        :file-list="picVertical.defaultList"
                        :headers="headers"
                        :limit="1" :disabled="judgeDisabled"
                        :class="{disabled:picVertical.uploadDisabled}">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="picVertical.dialogVisible"  :modal="false">
                    <img width="100%" :src="picVertical.url" alt="">
                </el-dialog>
            </el-form-item>
             
            <div class="root" style="margin-bottom:50px;">
                <img :src="getCurImgUrl" alt="" v-if="formData.picIn.length>0">
                <div class="noDataOut" v-if="formData.picIn.length===0">
                    <no-data v-if="formData.picIn.length===0"><span slot="tips">暂无数据</span></no-data>
                </div>
                <div class="rightBox">
                    <div class="title">{{formData.mname}}</div>
                    <div class="topArrow arrow">
                        <div class="el-icon-caret-top"></div>
                    </div>
                    <div class="imgBox">
                        <draggable class="draggableBox"  group="article" :value="formData.picIn" animation="300" @input="handleListChange($event)" :disabled="judgeDisabled">
                            <div class="rightImgItem" :class="{active:curImgIndex===index}"
                                 :style="{'backgroundImage':'url('+item.url+')'}"
                                 :key="index" v-for="(item,index) in formData.picIn" @click.stop="showCurImg(index)">
                                <div class="delIcon" v-if="!judgeDisabled">
                                    <i class="el-icon-close" @click.stop="delMeth(item.url)"></i>
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <div class="topArrow arrow">
                        <div class="el-icon-caret-bottom"></div>
                    </div>
                </div>
                <div class="configBox modelImg highIndex animate__animated 1" style="z-index:102!important;" v-if="!judgeDisabled">
                    <div class="btnItem highIndex configBtn" @click.stop="slideConfigBox('modelImg')">配置</div>
                    <div class="slideBox animate__animated" v-if="modelImgData.configShow">
                        <div class="btnGroup">
                            <div class="btnGroupItem">
                                <div class="btnItem" @click.stop="uploadMaterial('modelImg','img')">上传图片</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="drawerFooter" v-if="!judgeDisabled">
                <div class="btnBox">
                    <el-button class="largeBtn drawerBtn" @click="closeDrawer(false)">取 消</el-button>
                    <el-button class="largeBtn submitBtn drawerBtn" type="primary" @click="submitForm('form')">保存</el-button>
                </div>
            </div>
        </el-form>
         <div class="boxBlack" v-if="picVertical.dialogVisible||picHorizontal.dialogVisible"></div>
        <upload-dialog
                :dialogParData="dialogUpload" @changeDialogVisible="changeDialogVisible" @editTabLoadingMeth="editTabLoadingMeth"
                @uploadMaterialUrl="getUploadMaterialUrl" :multipleProp="true"
                :hotelContract="hotelContract"></upload-dialog>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable'
    import Swiper from 'swiper';
    import Bus from '../../../../api/Bus'
    export default {
        name: "UploadPicSort",
        components: {
            Draggable
        },
        props:['filterDataProp','hotelContract','tabActiveName','curId'],
        data:()=>({
            formData:{
                id:0,
                mname:'酒店餐厅',
                picHorizontal:{},
                picHorizontalHas:'',
                picVerticalHas:'',
                picVertical:{},
                picIn:[],
            },
            picHorizontal:{
                url:'',
                md5:'',
                dialogVisible:false,
                uploadDisabled:false,
                defaultList:[
                    // {name:'front',url:"http://test7-1104358706.cn-northwest-1.elb.amazonaws.com.cn:8000/ssp/public/uploads/20200529/460df5abfc94fdb77028fa163f5c40bd.jpg"}
                ]
            },
            picVertical:{
                url:'',
                dialogVisible:false,
                uploadDisabled:false,
                defaultList:[
                    // {name:'front',url:"http://test7-1104358706.cn-northwest-1.elb.amazonaws.com.cn:8000/ssp/public/uploads/20200529/460df5abfc94fdb77028fa163f5c40bd.jpg"}
                ]
            },
            headers:{
                token:'',
                Authorization:''
            },
            token:'',
            url:"",
            rules: {
                mname: [
                    { required: true, message: '请输入模块名称', trigger: 'blur' },
                    { max: 20, message: '模块名称不能超过20个字符', trigger: 'blur' }
                ],
                picHorizontalHas: [
                    { required: true, message: '请选择横板图片', trigger: 'blur' },
                ],
                picVerticalHas: [
                    { required: true, message: '请选择竖板图片', trigger: 'blur' },
                ],
            },
            thumbIndex:0,
            query:{},
            curImgIndex:0,
            modelImgData:{
                configShow:false,
            },
            dialogUpload:{
                visible:false,
                agreenActive:'',
                chooseItemIndex:'',
                uploadModel:'',
                uploadMaterialType:'',
                uploadMaterialUrl:''
            },
        }),
        methods:{
            handleListChange(event){
                this.formData.picIn = event;
                this.sortMeth();
            },
            sortMeth(){
                var order=0;
                this.formData.picIn.forEach((item,index)=>{
                    order++;
                    item.picorder=order;
                })

            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024  < 500;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过500K!');
                }
                return isJPG && isLt2M;
            },
            delMeth(val){
                this.formData.picIn.filter((item,index)=>{
                    if(item.url===val){
                        var appointIndex=this.formData.picIn.indexOf(item);
                        this.formData.picIn.splice(appointIndex,1);
                    }
                });
            },
            showCurImg(val){
                this.curImgIndex=val;
            },
            picHorizontalPreview(file) {
                this.picHorizontal.url = file.url;
                this.picHorizontal.dialogVisible = true;
            },
            picHorizontalSuccess(res,file,fileList){
                this.formData.picHorizontal.url=res.data.url;
                this.formData.picHorizontal.md5=res.data.md5;
                 this.formData.picHorizontalHas="only";
                this.picHorizontal.uploadDisabled=true;
            },
            picHorizontalRemove(file, fileList) {
                this.picHorizontal.uploadDisabled=false;
                this.formData.picHorizontal={};
                  this.formData.picHorizontalHas=""
                return false;
                setTimeout(()=>{
                    this.picHorizontal.uploadDisabled=false;
                    this.formData.picHorizontal={};
                },10)
            },
            picHorizontalBefore(){
                this.picHorizontal.uploadDisabled=true;
            },
            picHorizontalChange(){
                this.picHorizontal.uploadDisabled=true;
            },
            picVerticalPreview(file) {
                this.picVertical.url = file.url;
                this.picVertical.dialogVisible = true;
            },
            picVerticalSuccess(res,file,fileList){
                this.formData.picVertical.url=res.data.url;
                this.formData.picVertical.md5=res.data.md5;
                this.formData.picVerticalHas="only"
                this.picVertical.uploadDisabled=true;
            },
            picVerticalRemove(file, fileList) {
                setTimeout(()=>{
                    this.picVertical.uploadDisabled=false;
                    this.formData.picVertical={};
                     this.formData.picVerticalHas=""
                },10)
            },
            picVerticalBefore(){
                this.picVertical.uploadDisabled=true;
            },
            picVerticalChange(){
                this.picVertical.uploadDisabled=true;
            },
            uploadBefore(file){
                const sizeLimit = file.size / 1024  < 500;
                if (!sizeLimit) {
                    this.$message.error('图片大小不可超过500K')
                }
                return sizeLimit
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.formData.picIn.length===0){
                            this.$message.warning('内置图片不能为空');
                        }else{
                            this.$confirm('确定保存', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.submitMeth();
                            }).catch(() => {

                            });
                        }
                    } else {
                        this.$message.warning('请完善内容');
                        return false;
                    }
                });
            },
            submitMeth(){
                var hotelServiceData={};
                var storeConfigData=this.$store.state.configData;
                hotelServiceData=JSON.parse(JSON.stringify(storeConfigData.tab[this.tabActiveName]));
                var pic=JSON.parse(JSON.stringify(hotelServiceData.pic));
                var that=this;
                Object.keys(pic).forEach(function(key){
                    if(pic[key].id==that.curId){
                        pic[key]=that.formData;
                    }
                });
                hotelServiceData.pic=pic;
                this.$store.commit('editConfigData',{tabActiveName:this.tabActiveName,pic:pic});
                Bus.$emit('getHotelDataThree');
            },
            assignData(){
                var filterDataProp=this.filterDataProp;
                this.formData.id=filterDataProp.id;
                this.formData.mname=filterDataProp.mname;
                this.formData.picHorizontal=filterDataProp.picHorizontal;
                if(this.formData.picHorizontal.url){
                   this.formData.picHorizontalHas="only"
                }
                // this.picHorizontal.defaultList.length=0;
                this.picHorizontal.defaultList=[{name:'picHorizontal',url:filterDataProp.picHorizontal.url}];
                this.formData.picVertical=filterDataProp.picVertical;
                 if(this.formData.picVertical.url){
                   this.formData.picVerticalHas="only"
                }
                // this.picVertical.defaultList.length=0;
                this.picVertical.defaultList=[{name:'picVertical',url:filterDataProp.picVertical.url}];
                this.picHorizontal.uploadDisabled=true;
                this.picVertical.uploadDisabled=true;
                this.formData.picIn=filterDataProp.picIn?JSON.parse(JSON.stringify(filterDataProp.picIn)):[];
                this.curImgIndex=0;
                this.$emit('loadingMeth',false);
            },
            inputMeth(){
                var mname=this.formData.mname;
                if(mname.length>20){
                    this.$message.warning('输入内容不能超过20个字符');
                    this.formData.mname=mname.slice(0,20);
                }
            },
            slideConfigBox(type){
                var modelImgData=this.modelImgData;
                modelImgData.configShow=!modelImgData.configShow;
            },
            uploadMaterial(modelType,materialType){
                var dialogUpload=this.dialogUpload;
                dialogUpload.uploadModel=modelType;
                dialogUpload.uploadMaterialType=materialType;
                dialogUpload.visible=true;
            },
            editTabLoadingMeth(val,type){
                // this.$emit('editTabLoadingMeth', val,type);
            },
            changeDialogVisible(val){
                this.dialogUpload.visible=val;
            },
            getUploadMaterialUrl(url,fileName,md5,file){
                var obj={
                    url,
                    md5,
                    picorder:file.name.replace(/[^0-9]/ig,"")
                };
                this.formData.picIn.unshift(obj);
                this.sortMeth();
                this.dialogUpload.visible=false;
            },
            cancelMeth(){
                this.dialogUpload.visible=false;
            },
            hideShade() {
                this.modelImgData.configShow = false;
            },
            closeDrawer(){
                Bus.$emit('closeDrawer');
            }
        },
        mounted(){
            this.$emit('loadingMeth',true);
            this.token=sessionStorage.getItem('token');
            this.headers.token=this.token;
            this.headers.Authorization=this.token;
            this.query=this.$route.query;
            this.assignData();
        },
        watch:{
             
             hotelContract:{
                 handler: function (newVal) {
                          this.hotelContract=newVal
                },
                deep: true,
                immediate: true, 
            },
              tabActiveName:{
                 handler: function (newVal) {
                          this.tabActiveName=newVal
                },
                deep: true,
                immediate: true, 
            },
               curId:{
                 handler: function (newVal) {
                          this.curId=newVal
                },
                deep: true,
                immediate: true, 
            },
             filterDataProp:{
                 handler: function (newVal) {
                          this.filterDataProp=newVal
                           this.assignData();
                },
                deep: true,
                immediate: true, 
            },
          
        },
        computed:{
            getCurImgUrl(){
                var str='';
                if(this.formData.picIn){
                    if(this.formData.picIn.length>0){
                        str=this.formData.picIn[this.curImgIndex].url;
                    }
                }
                return str;
            },
            judgeDisabled(){
                var flag=false;
                this.$attrs.showType==='action'?flag=true:flag=false;
                return flag;
            }
        },
    }
</script>

<style scoped lang="scss">
    /*import 'swiper/dist/css/swiper.min.css';*/
    @import '../../../../../node_modules/swiper/swiper-bundle.css';
    @import '../../../../../node_modules/swiper/swiper-bundle.min.css';
    img{
        width:100%;
        height:100%;
        object-fit: cover;
    }
    .root{
        width: 1152px;
        height:648px;
        position:relative;
        border: 1px solid #ddd;
        box-sizing: border-box;
    }
    .sortPicBox{
        position:absolute;
        width:200px;
        height:100%;
        top:0;
        right:0;
        background:rgba(0,0,0,0.5);
        color:#fff;
        display:flex;
        flex-direction: column;

    }
    .rightBox{
        width:200px;
        height: 100%;
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: space-around;
        position:absolute;
        top:0;
        right:0;
        background:rgba(0,0,0,0.5);
        z-index:99;
        .title{
            width:100%;
            height:70px;
            display:flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color:#fff;
        }
        img{
            width:auto;
            height:100%;
            /*width:170px;*/
            /*height:94px;*/
            object-fit: cover;
            :last-child{
                margin-bottom:0;
            }
        }
    }
    ul{
        padding: 0;
        width: 400px;
    }
    li{
        width: 100px;
        float:left;
    }
    .triangleUp {
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 100px solid red;
    }
    .arrow{
        height:30px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .arrow>div{
        font-size:26px;
        display:flex;
        align-items: center;
        justify-content: center;
        color:#9d99b0;
    }
    .centerEl{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .uploadBox{
        width:952px;
        height:100%;
        position: absolute;
        top:0;
        background: rgba(0,0,0,0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index:999;
    }
    /*swiper开始*/
    .swiperBox{
        height:520px;
    }
    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: #000;
        opacity: 1;
        background: rgba(0, 0, 0, 0.2);
    }
    .swiper-pagination-bullet-active {
        color: #fff;
        background: #007aff;
    }
    .swiper-slide{
        background-size:cover;
    }
    .delIcon{
        width: 20px;
        height: 20px;
        position: absolute;
        top:5px;
        right:0;
        cursor:pointer;
        color:#fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.3);
    }
    .relativeEl{
        position: relative;
        height:100%;
    }
    /*swiper结束*/
    .form{
        width:1152px;
    }
    .main{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .btnBox{
        display:flex;
        margin-top:20px;
        margin-left:auto;
    }
    .submitBtn{
        margin-left:auto;
    }
    .gallery-top {
        height: 100%;
        width: 100%;
        .swiper-slide{
            width:100%!important;
        }
    }
    .scrollBox{
        width:100%;

    }
    .gallery-thumbs {
        width:100%;
        height: 518px;
        box-sizing: border-box;
        /*padding: 10px 0;*/
        .swiper-wrapper{
            display:flex;
            align-items: center;
        }
    }
    .gallery-thumbs .swiper-slide {
        width: 90%!important;
        height: 100%;
        opacity: 1;
    }
    .gallery-thumbs .swiper-slide-thumb-active {
        opacity: 1;
    }
    .swiper-slide{
        background-size: cover!important;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .gallery-thumbs .swiper_slide_thumb_active {
        opacity: 1;
    }
    .rightImgItem{
        width: 90%;
        height: 19.6%;
        margin: 0 auto 2% auto;
        position: relative;
        box-sizing: content-box;
        border: 3px solid transparent;
        cursor:pointer;
        background-size: contain;
        background-repeat: no-repeat!important;
    }
    .rightImgItem.active{
        border: 3px solid #ffe699;
    }
    .imgBox{
        flex:1;
        overflow: auto;
    }
    /*配置按钮样式开始*/
    .configBox{
        display:flex;
        position:absolute;
        top: -40px;
        left: 0;
    }
    .configBox .btnItem{
        margin-right:10px;
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.3)!important;
        cursor:pointer;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .btnGroup{
        display:inline-block;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
    }
    /*配置按钮样式结束*/
    .draggableBox{
        height:100%;
    }
    .noDataOut{
        width: calc(100% - 200px);
        height: 100%;
        /* background: red; */
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .drawerFooter{
        display:flex;
        margin-bottom: 20px;
    }
    .submitBtn{
        margin-left:10px;
    }
    .boxBlack{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background: #000;
            z-index: 2000;
    }
</style>
