<template>
    <div>
        <!-- <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">信息中心</span>
                <span slot="third">{{breadText}}</span>
                <span slot="fourth">{{bread.fourthName}}</span>
            </top-bread>
        </div> -->
        <el-card class="box-card">
            <!-- <el-row class="btnRow">
                <el-button type="primary" @click="backMeth" size="medium" class="largeBtn ">返回</el-button>
            </el-row> -->
            <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-form">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" :readonly="readOnlyData"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="text">
                    <!--<el-input v-model="form.text"></el-input>-->
                    <el-row v-loading="quillUpdateImg" class="full">
                        <quill-editor  style="width:100%;" :disabled="readOnlyData"
                                       ref="myQuillEditor"
                                       v-model="form.text"
                                       class="myQuillEditor ql-editor"
                                       :options="editorOption"
                                       @blur="editorBlur"/>
                    </el-row>
                </el-form-item>
                <el-form-item label="发送至" prop="partner_ids_arr" >
                    <el-row :gutter="20" style="width:100%">
                        <el-col :span="6">
                            <el-select v-model="form.coopType"
                                       clearable multiple
                                       placeholder="请选择合作类型"
                                       @change="getCoopPartner($event,'getType')"
                                       :disabled="readOnlyData">
                                <el-option value="all" label="全选"></el-option>
                                <el-option
                                        v-for="item in selectData.coopType"
                                        :key="item.key"
                                        :label="item.value"
                                        :value="item.key">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="10">
                            <el-select v-model="form.partner_ids_arr"
                                       clearable
                                       placeholder="请选择合作伙伴"
                                       :multiple="true" filterable=""
                                       @change="partnerChange"
                                       :disabled="readOnlyData">
                                <el-option value="all" label="全选" v-if="isShowAllSelect"></el-option>
                                <!--<el-option value="all" label="全选" v-if="isShowAllSelect"></el-option>-->
                                <el-option
                                        v-for="item in selectData.coopPartner"
                                        :key="item.id"
                                        :label="item.company_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item v-if="!readOnlyData">
                    <el-button type="primary" class="largeBtn" @click="submitForm('form')">保存</el-button>
                    <el-button @click="resetForm('form')" class="largeBtn">重置</el-button>
                </el-form-item>
            </el-form>
            <el-upload id="quill-upload"
                    class="upload-demo"
                       :action="global.upload"
                       :headers="headers"
                    multiple
                    :on-success="handleSuccess"
                       :error="uploadError"
                       :before-upload="beforeUpload"
                    :before-remove="handleRemove"
                    :file-list="fileList">
                <el-button size="small" type="primary" class="none">点击上传</el-button>
                <div slot="tip" class="el-upload__tip none">只限上传JPG格式图片，且单张图片最大不能超过500K。</div>
            </el-upload>
        </el-card>
    </div>
</template>

<script>
    // 工具栏配置
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],
        ['link', 'image', 'video'],
        ['clean']                                         // remove formatting button
    ];

    import '../../api/quill-edit'
    import  qs from 'querystring'
    // import { quillEditor } from 'vue-quill-editor'
    import { quillEditor } from 'vue-quill-editor'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import '../../../node_modules/quill/dist/quill.snow.css'
    export default {
        name: "InfoPubNoNew",
          props:{
            query:{
                type:Object,
                default:{}
            }
        },
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/infoPubNotice'},
                fourthName:'',
            },
            breadText:'',
            quillUpdateImg:'',
            form: {
                title: '',
                text:'',
                file:'',
                type:'info',
                partner_ids_arr:'',
                partner_ids_str:'',
                imgUrl:'',
                fileUrl:'',
                coopType:'',
            },
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
                text: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],
                partner_ids_arr: [
                    { required: true, message: '请选择合作伙伴', trigger: 'blur' },
                ],
            },
            getMsg:{},
            selectData:{
                flag:false,
                coopType:[],
                coopPartner:[],
            },
            // query:{
            //     id:'',
            //     showType:'',
            //     type:'',
            //     status:0
            // },
            fileList:[],
            headers:{
                token:'',
                Authorization:''
            },
            editorOption: {  // 富文本编辑器配置
                placeholder: '',
                theme: 'snow',  // or 'bubble'
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    document.querySelector('#quill-upload input').click()
                                } else {
                                    this.quill.format('image', false);
                                }
                            }
                        }
                    }
                }
            },
            readOnlyData:false,
            coopTypeAllStr:'',
            coopPartnerCodeAllStr:'',
            isShowAllSelect:false,
        }),
        components: {
            quillEditor
        },
          created: function () {
            this.getMsg = this.query
        },
        watch:{
           query (newValue, oldValue) {
            this.getMsg = newValue
            }
        },
        methods:{
            getCoopPartner(val){
                return new Promise((resolve,reject)=>{
                    if(val){
                        if(val.length===0){
                            this.form.partner_ids_arr=[];
                            this.form.partner_ids_str=[];
                        }
                        var arrBack=[...val];
                        var popEl=arrBack.pop();
                        var coopTypeStr='';
                        if(popEl=='all'){   //选择了全部
                            this.form.coopType=['all'];
                            coopTypeStr=this.getAllCoopTypeData();
                        }else{
                            var allStrIndex=val.indexOf('all');
                            if(allStrIndex!==-1){
                                val.splice(allStrIndex,1);
                            }
                            this.form.coopType=val;
                            coopTypeStr=this.form.coopType.join(',');
                        }
                        this.$http.get(this.global.getPartnersByTypes,{
                            params:{
                                token:this.token,
                                types:coopTypeStr
                            }
                        }).then(res=>{
                            if(res.data.code===100000){
                                var resData=res.data.data;
                                this.selectData.flag=true;
                                this.selectData.coopPartner=resData;
                                resData.length===0?this.isShowAllSelect=false:this.isShowAllSelect=true;
                                resolve(res);
                            }
                        })
                    }else{

                    }
                })
            },
            resetForm(formName){
                this.$refs[formName].resetFields();
                this.form.title='';
                this.form.type='';
                this.form.text='';
                this.form.partner_ids_arr='';
                this.form.partner_ids_str='';
            },
            submitForm(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定提交?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            if(this.query.type==='edit'){
                                this.editMeth();
                            }else{
                                this.createMeth();
                            }
                        });
                    }else{
                        this.$message.warning('请完善内容');
                    }
                })
            },
            createMeth(){
                if(this.form.partner_ids_arr.join(',')==='all'){
                    this.form.partner_ids_str=this.getAllPartnerCodeData();
                }else{
                    this.form.partner_ids_str=this.form.partner_ids_arr.join(',');
                }
                var param=qs.stringify({
                    token:this.token,
                    title:this.form.title,
                    text:this.form.text,
                    type:'notice',
                    partner_ids:this.form.partner_ids_str,
                });
                this.$http.post(this.global.infoCreate,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('创建成功');
                          this.$emit("createSuccess");
                        // this.$router.push('./infoPubNotice')
                    }else if(res.data.code===100004){
                        this.$message.error(res.msg);
                    }else{
                        this.$message.error('失败');
                    }
                    // this.table.loading=false;
                }).catch(()=>{
                })
            },
            editMeth(){
                this.form.partner_ids_str=this.form.partner_ids_arr.join(',');
                var param=qs.stringify({
                    token:this.token,
                    id:this.getMsg.id,
                    title:this.form.title,
                    text:this.form.text,
                    type:this.form.type,
                    partner_ids:this.form.partner_ids_str,
                });
                this.$http.post(this.global.infoUpdate,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('修改成功');
                          this.$emit("editSuccess");
                        // this.$router.push('./infoPubNotice')
                    }else {
                        this.$message.error(res.data.data);
                    }
                    // this.table.loading=false;
                }).catch(()=>{
                })
            },
            getInfoDetail(id){
                this.$http.get(this.global.infoView,{
                    params:{
                        token:this.token,
                        id:id
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        console.log("res",res.data.data);
                        var infoData=res.data.data;
                        this.form.title=infoData.title;
                        this.form.type=infoData.type;
                        this.form.text=infoData.text;
                        this.form.coopType=infoData.partners.type;
                        if(infoData.partners.type){
                            this.getCoopPartner([infoData.partners.type]).then(()=>{
                            this.form.partner_ids_arr=infoData.partners.code;
                            this.form.partner_ids_str=infoData.partners.code.join(',');
                        })
                        }
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            editorBlur(){

            },
            handleSuccess(res, file) {

                // res为图片服务器返回的数据
                // 获取富文本组件实例
                let quill = this.$refs.myQuillEditor.quill;
                // 如果上传成功
                if (res.code === 100000) {
                    // 获取光标所在位置
                    let length = quill.getSelection().index;
                    // 插入图片  res.info为服务器返回的图片地址
                    quill.insertEmbed(length, 'image',this.global.baseUrl+ res.data.url);
                    this.form.file=res.data.url;
                    // quill.insertEmbed(length, 'image', '<img src="re">');
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                } else {
                    this.$message.error('图片插入失败')
                }
                // loading动画消失
                this.quillUpdateImg = false
                // this.imageUrl = URL.createObjectURL(file.raw);
            },
            handleRemove(file, fileList) {

            },
            handlePreview(file) {

            },
            beforeUpload() {
                // 显示loading动画
                this.quillUpdateImg = true
            },
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false;
                this.$message.error('图片插入失败')
            },
            partnerChange(val,a,b){
                var arrBack=[...val];
                var popEl=arrBack.pop();
                if(popEl=='all'){
                    this.form.partner_ids_arr=['all'];
                }else{
                    var allStrIndex=val.indexOf('all');
                    if(allStrIndex!==-1){
                        val.splice(allStrIndex,1);
                    }
                    this.form.partner_ids_arr=val;
                }
            },
            getCoopType(){
                this.$http.get(this.global.getPartnerTypes,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.coopType=res.data.data;
                        this.getAllCoopTypeData();
                    }
                })
            },
            isEmptyObj(obj){
                for (let i in Object.keys(obj)) {
                    return false // 进入循环即不为空
                }
                return true
            },
            isReadOnly(){
                this.query.status!=2
                    ?this.readOnlyData=false
                    :this.readOnlyData=true;
            },
            getAllCoopTypeData(){
                var arr=[];
                this.selectData.coopType.forEach((item,index)=>{
                    arr.push(item.key);
                });
                return arr.join(',');
            },
            getAllPartnerCodeData(){
                var arr=[];
                this.selectData.coopPartner.forEach((item,index)=>{
                    arr.push(item.id);
                });
                return arr.join(',');
            },
            backMeth(){
                this.$router.push({path:'./infopubnotice'})
            },
        },
        mounted(){
            // var query=this.$route.query;
            if(!this.isEmptyObj(this.query)){
                this.getMsg.showType=this.query.showType;
                this.getMsg.type=this.query.type;
                this.getMsg.status=this.query.status;
            }

            if(this.query.showType==='noticeMan'){
                this.breadText='通知管理';
                this.bread.thirdPath.path='/infoPubNotice'
            }else if(this.query.showType==='coopPolicy'){
                this.breadText='合作政策';
                this.bread.thirdPath.path='/infoPubCoop';
            }
            this.token=sessionStorage.getItem('token');
            this.headers.token=this.token;
            this.headers.Authorization=this.token;

            this.getCoopType();
            // return false;
            this.getCoopPartner();

            if(this.query.fromType==='new'){
                this.bread.fourthName='新建';
            }else if(this.query.fromType==='detail'){
                this.getMsg.id=this.query.id;
                this.getInfoDetail(this.getMsg.id);
                this.bread.fourthName='详情';
            }

            let quill = this.$refs.myQuillEditor.quill;
            this.isReadOnly();
            // this.getAllCoopTypeData();   //全选合作伙伴类型
        }
    }
</script>


<style scoped lang="scss">
    .box-card{
        margin-top: -0px;
    }
    .el-select{
        width:100%!important;
    }
    .full{
        width:100%!important;
        margin-top:-10px;
    }
    .none{
        display:none;
    }
    .myQuillEditor{
        padding:0!important;
    }

</style>
