<template>
  <div>
    <el-upload :class="[uploadPicTrue?'hide':'show']" :disabled="readonly" :action="global.materialUpload" :headers="headers" list-type="picture-card" :on-remove="handleRemove" :on-success="handleSuccess" :on-change="handleChange" :on-preview="handlePictureCardPreview" :before-remove='beforeRemove' :before-upload="beforeAvatarUpload" :limit="1" :file-list="fileList" ref="uploadPic">
      <i class="el-icon-plus"></i>
      <span>上传logo</span>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "uploadLogo",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    readonly:{
          type: Boolean,
        default: () => false
    }
  },
  data() {
    return {
      fileList: [],
      uploadPicTrue: false,
      dialogVisible: false,
      dialogImageUrl: "",
      headers: {
        token: "e3498af9-6a77-43ce-95aa-21f785647571",
      },
      fileSrc:this.value,
    };
  },
  watch:{
     value: {
      handler: function (newVal) {
       this.fileSrc = newVal;
        if( this.fileSrc){
          let obj = {
            url:this.fileSrc,
          }
          this.fileList=[];
           this.fileList.push(obj);
           this.uploadPicTrue = true;
        }else{
           this.fileList=[];
            this.uploadPicTrue = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
      this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    // 删除上传文件确认
    beforeRemove(file, fileList) {
      if (!this.upNoPic) {
        return this.$confirm("是否确认删除logo？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.uploadPicTrue = false;
            console.log(55555);
          })
          .catch((err) => {
            reject(false);
          });
      } else {
        this.uploadPicTrue = false;
      }
    },
    // 上传文件时钩子函数
    beforeAvatarUpload(file) {
      var jpeg = file.type === "image/jpeg";
      var png = file.type === "image/png";
      if (!jpeg) {
        if (!png) {
          this.$message.warning("请上传图片文件");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.uploadPicTrue = false;
      this.fileList = [];
      this.fileSrc = "";
       this.$emit("change", this.fileSrc);
    },
    // 文件预览
    handlePictureCardPreview(file, fileList) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    // 文件列表变化
    handleChange() {
      if (this.fileList.length >= 1) {
      
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
        this.uploadPicTrue = true;
      this.fileList = fileList;
      this.fileSrc = file.response.data.url;
        this.$emit("change", this.fileSrc);
    },
  },
};
</script>
<style lang="scss" scoped>
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
.hide ::v-deep .el-upload-list__item.is-uploading {
  display: none;
}
.hide ::v-deep  .el-upload-list--picture-card{
  line-height: 0;
}

.hide ::v-deep .el-upload-list--picture-card .el-upload-list__item{
  margin: 0;
}
.show ::v-deep .el-upload-list--picture-card {
  display: none;
}
::v-deep .el-upload--picture-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
::v-deep .el-upload--picture-card span{
    margin-top: 10px;
    height: 16px;
    font-size: 12px;
    color: #aaa;
    line-height: 16px;
}
</style>