<template>
  <div style="width:100%">
    <el-upload class="upload-demo" ref="upload" :action="global.materialUpload" :http-request="uploadMehod" :before-remove="beforeRemove" :limit="1" :headers="headers" :file-list="fileList" :before-upload="beforeUpload" accept=".apk">
      <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传apk文件
      </div>

    </el-upload>
    <el-progress style="width:200px" v-if="percentage" show-text :percentage="percentage"></el-progress>
  </div>
</template>
<script>
import SparkMD5 from "spark-md5";
import ApkParser from "app-info-parser";
import { checkApk, uploadFile } from "../../api/application/apkList";
export default {
  props: {
    apkAddr: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      fileList: [],
      md5: "",
      filename: "",
      apk_package: "",
      eapk_code: "",
      headers: {
        token: "",
      },
      percentage: 0,
    };
  },
  watch: {
    apkAddr(newVal) {
      if (newVal) {
        this.apkAddr = newVal;
        this.fileList = [{ name: newVal }];
        
      } else {
        this.fileList = [];
         this.percentage = 0
      }
     
    },
  },
  mounted() {
    console.log(this.apkAddr, 11111);
    if (this.apkAddr) {
      this.fileList = [{ name: this.apkAddr }];
    } else {
      this.fileList = [];
    }
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    reset() {
      this.fileList = [];
      this.percentage = 0;
    },
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    // },
    async beforeUpload(file) {},
    async uploadMehod(file, fileList) {
      // console.log('uploadMehod',file.file,fileList);
      const parser = new ApkParser(file.file);
      let result = await parser.parse();
      this.apk_package = result.package;
      this.eapk_code = result.versionCode;
      this.filename = file.file.name;
      this.getFileMD5(file.file);
    },
    //生成MD5
    getFileMD5(file) {
      let fileReader = new FileReader(),
        blobSlice =
          File.prototype.mozSlice ||
          File.prototype.webkitSlice ||
          File.prototype.slice,
        chunkSize = 2097152,
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5(),
        that = this;
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result);
        currentChunk++;
        if (currentChunk < chunks) {
          loadNext();
        } else {
          let md = spark.end();
          that.md5 = md;
          //  return md
          that.getCheckApk(file);
        }
      };
      function loadNext() {
        let start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsBinaryString(blobSlice.call(file, start, end));
      }
      loadNext();
    },
    async getCheckApk(file) {
      let params = {
        filename: this.filename,
        md5: this.md5,
        apk_package: this.apk_package,
        database: "hr800042",
      };
      let res = await checkApk(params);
      if (res.data.code == 100000) {
        let {
          data: { data },
        } = res;
        let apkInfo = {
          apkAddr: data.addr, //  apk地址
          eapk_package: this.apk_package, // 包名
          apkMd5: this.md5,
          eapk_code: this.eapk_code,
        };
        this.percentage = 100;
        this.$emit("uploadApkInfo", apkInfo);
      } else if (res.data.code == 2) {
        this.percentage = 50;
        const formdata = new FormData();
        formdata.append("file", file);
        uploadFile(formdata).then((res) => {
          if (res.data.code == 100000) {
            let {
              data: { data },
            } = res;
            let apkInfo = {
              apkAddr: data.url, //  apk地址
              eapk_package: this.apk_package, // 包名
              apkMd5: data.md5,
              eapk_code: this.eapk_code,
            };
            this.percentage = 100;
            this.$emit("uploadApkInfo", apkInfo);
          }
        });
      } else {
        this.$message.warning(res.data.msg);
        this.fileList = [];
        this.$refs.upload.uploadFiles = [];
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let apkInfo = {
            apkAddr: "", //  apk地址
            eapk_package: "", // 包名
            apkMd5: "",
            eapk_code: "",
          };
          this.percentage = 0;
          this.$emit("uploadApkInfo", apkInfo);
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>