<template>
  <div>
    <el-button
      type="primary"
      class="table_btn"
      @click="
        current = {};
        brandOperShow = true;
      "
      >添加品牌</el-button
    >
    <!-- 品牌table -->
    <el-table
      :data="tableDataBrand"
      border
      style="width: 100%;"
      :header-cell-style="{
        background: '#f8fbff',
        color: '#606266',
      }"
    >
      <el-table-column prop="id" label="编号" width="100">
        <!-- <template slot-scope="scope">
          <div>
            {{ scope.$index + 1 }}
          </div>
        </template> -->
      </el-table-column>
      <el-table-column prop="name" label="酒店品牌"></el-table-column>
      <el-table-column prop="name" label="所属集团">
        <template slot-scope="scope">
          <div v-if="groupList.length != 0">
            {{
              groupList.find((item) => item.id == scope.row.groupId)
                ? groupList.find((item) => item.id == scope.row.groupId).name
                : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="
              current = scope.row;
              brandOperShow = true;
            "
            type="text"
            size="small"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="del(scope.row)"
            >删除</el-button
          >
          <!-- @click="delGroup(scope.row)" -->
          <!-- <el-button type="text" size="small" @click="del(scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      layout="total,prev, pager, next"
      v-show="pagination.total != 0"
      :total="pagination.total"
      :current-page="pagination.currentPage"
      @current-change="
        (val) => {
          pagination.currentPage = val;
          getList();
        }
      "
    >
    </el-pagination>
    <BrandOper
      :visible.sync="brandOperShow"
      :current="current"
      @refresh="
        pagination.currentPage = 1;
        getList();
      "
    ></BrandOper>
  </div>
</template>
<script>
import { getGroupList } from "./api/group";
import { getList, delBrand } from "./api/brand";
import BrandOper from "@/components/operation/HotelClassify/components/brandOper";
export default {
  name: "hotelBrand",
  components: { BrandOper },
  data() {
    return {
      groupList: [],
      tableDataBrand: [],
      brandOperShow: false,
      current: {},
      pagination: {
        total: 0,
        currentPage: 1,
      },
    };
  },
  methods: {
    init() {
      this.getGroupList();
      this.getList();
    },
    del(row) {
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delBrand({
            id: row.id,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getGroupList() {
      getGroupList({
        page: 1,
        size: 9999,
      }).then((data) => {
        console.log(data,123123);
        this.groupList = data.data.data.row;
      });
    },
    getList(page, size) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        page: page ? page : this.pagination.currentPage,
        size: size ? size : 10,
      }).then((data) => {
        this.tableDataBrand = data.data.data.row;
        this.pagination.total = parseInt(data.data.data.totalNum);
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.table_btn {
  margin: 10px 0px;
}
.pagination {
  margin-top: 15px;
}
</style>
