<template>
  <div class="container" v-if="pageLoading">
    <div class="fullBg">
      <div class="left">
        <div class="title">
          <div class="titleImg">
            <img v-if='isSsp' src="../assets/img/login/logo.png" alt="">
            <img  v-if='!isSsp' src="../assets/img/login/wanyuelogo.png" alt="">
          </div>
          <span class="titleText">合作伙伴管理平台</span>
        </div>
        <div style="" class="imgOutBox">
          <div class="imgBox">
            <img src="../assets/img/illustration.png" alt="">
          </div>
        </div>
      </div>
      <!--<form :model="loginForm" class="loginForm" :rules="rules">-->
      <div class="right">
        <el-tabs v-model="activeName" class="loginTab">
          <!--<el-tab-pane label="登录" name="login" @keyup.enter.native="login" >-->
          <el-tab-pane label="登录" name="login" @keyup.enter.native="login">
            <el-form :model="loginForm" class="loginForm">
              <div class="formCon">
                <div class="input_item hideEl">
                  <el-input max="11" class="inputBox phone_input" style="" v-model="loginForm.mobile" placeholder="请输入注册手机号" @focus="inputFocus('phone')" @blur="inputBlur('phone')">
                    <i slot="prefix" class="el-input__icon el-icon-s-custom icon " :class="iconColor.phone"></i>
                  </el-input>
                </div>
                <div class="input_item">
                  <el-input max="11" class="inputBox phone_input" style="" v-model="loginForm.mobile" placeholder="请输入注册手机号" @focus="inputFocus('phone')" @blur="inputBlur('phone')">
                    <i slot="prefix" class="el-input__icon el-icon-s-custom icon " :class="iconColor.phone"></i>
                  </el-input>
                  <div class="code_error error" v-if="loginError.mobileErrorText!==''">{{loginError.mobileErrorText}}</div>
                </div>
                <div class="input_item">
                  <!--<input type="text" class="input_attr code_input" placeholder="请输入验证码" @focus="inputFocus" @input="codeVerify" v-model="loginForm.code">-->
                  <div>
                    <el-input max="11" class="inputBox" v-model="loginForm.code" placeholder="请输入验证码" @focus="inputFocus('loginCode')" @blur="inputBlur('loginCode')">
                      <i slot="prefix" class="el-input__icon el-icon-s-claim icon" :class="iconColor.loginCode"></i>
                    </el-input>
                    <div class="code_error error" v-if="loginError.codeErrorText!==''">{{loginError.codeErrorText}}</div>
                  </div>
                  <el-button class="send_code" v-if="!showCountDown&&loginForm.mobile!='18653253523'" type="primary" @click="sendCode('login')" size="mini">点击获取验证码</el-button>
                  <span class="send_code countDown" v-if="showCountDown">{{loginForm.second}}s后重新获取</span>
                  <i class="del_icon iconfont icon-shanchu"></i>
                </div>
                <div class="input_item hideEl">
                  <el-input max="11" class="inputBox phone_input" style="" v-model="loginForm.mobile" placeholder="请输入注册手机号" @focus="inputFocus('phone')" @blur="inputBlur('phone')">
                    <i slot="prefix" class="el-input__icon el-icon-s-custom icon " :class="iconColor.mobile"></i>
                  </el-input>
                </div>
              </div>
              <div class="formBtn">
                <el-button class="btnItem blueBtn" type="primary" @click="login">登录</el-button>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="注册" name="register">
            <el-form :model="registerForm" class="loginForm" @keyup.enter.native="submitForm">
              <div class="formCon">
                <div class="input_item">
                  <el-input max="11" class="inputBox phone_input" style="" v-model="registerForm.company_name" placeholder="请输入所属公司名称" @focus="inputFocus('company_name')" @blur="inputBlur('company_name')">
                    <i slot="prefix" class="el-input__icon el-icon-s-shop icon" :class="iconColor.company_name"></i>
                  </el-input>
                  <div class="code_error error" v-if="registerError.companyErrorText!==''">{{registerError.companyErrorText}}</div>
                </div>
                <div class="input_item">
                  <el-input max="11" class="inputBox phone_input" style="" v-model="registerForm.contact_name" placeholder="请输入联系人" @focus="inputFocus('contact_name')" @blur="inputBlur('contact_name')">
                    <i slot="prefix" class="el-input__icon el-icon-s-custom icon" :class="iconColor.contact_name"></i>
                  </el-input>
                  <div class="code_error error" v-if="registerError.contactNameErrorText!==''">{{registerError.contactNameErrorText}}</div>
                </div>
                <div class="input_item">
                  <el-input max="11" class="inputBox phone_input" style="" v-model="registerForm.mobile" placeholder="请输入手机号" @focus="inputFocus('mobile')" @blur="inputBlur('mobile')">
                    <i slot="prefix" class="el-input__icon el-icon-phone icon" :class="iconColor.mobile"></i>
                  </el-input>
                  <div class="code_error error" v-if="registerError.mobileErrorText!==''">{{registerError.mobileErrorText}}</div>
                </div>
                <div class="input_item">
                  <div>
                    <el-input max="11" class="inputBox" style="" v-model="registerForm.code" placeholder="请输入验证码" @focus="inputFocus('registerCode')" @blur="inputBlur('registerCode')">
                      <i slot="prefix" class="el-input__icon el-icon-s-claim icon" :class="iconColor.registerCode"></i>
                    </el-input>
                    <div class="code_error error" v-if="registerError.codeErrorText!==''">{{registerError.codeErrorText}}</div>
                  </div>

                  <el-button class="send_code" v-if="!showCountDown" type="primary" @click="sendCode('register')" size="mini">点击获取验证码</el-button>
                  <span class="send_code countDown" v-if="showCountDown">{{registerForm.second}}s后重新获取</span>
                  <i class="del_icon iconfont icon-shanchu"></i>
                  <!--<div class="code_error error" v-if="errorText!==''">{{errorText}}</div>-->
                  <!--<div class="code_tips">验证码有效期5分钟</div>-->
                </div>
              </div>
              <div class="formBtn">
                <el-button class="btnItem blueBtn" type="primary" @click="submitForm">注册</el-button>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!--</form>-->
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <div class="successImgBox">
        <img src="../assets/img/login/successIcon.png" alt="">
      </div>
      <div class="successTitle">信息提交成功</div>
      <div class="successText">系统将在{{successSecond}}秒之后跳转到首页，如果没有请 <span @click="toIndex" class="toIndex">点击手动跳转</span></div>
    </el-dialog>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "login",
  data: () => ({
    loginForm: {
      mobile: "",
      code: "",
      second: "60",
    },
    registerForm: {
      username: "",
      company_name: "",
      contact_name: "",
      mobile: "",
      code: "",
      token: "",
      second: "60",
    },
    loginError: {
      mobileErrorText: "",
      codeErrorText: "",
    },
    registerError: {
      companyErrorText: "",
      contactNameErrorText: "",
      mobileErrorText: "",
      codeErrorText: "",
    },
    // errorText:'',
    // loginError.mobileErrorText:'',
    // codeErrorText:'',
    successSecond: "5",
    showCountDown: "",
    show: false,
    iconColor: {
      phone: "",
      loginCode: "",
      company_name: "",
      contact_name: "",
      mobile: "",
      registerCode: "",
    },
    rules: {
      mobile: [{ required: true, message: "111", trigger: "change" }],
    },
    activeName: "login",
    dialogVisible: false,
    pageLoading: false,
    isSsp:true,
  }),
  mounted() {
    if(window.location.hostname=='ssp.wanyuejiayuan.com'){
       this.isSsp=false
    }else{
       this.isSsp=true
    }
    let url = window.location.href;
    let query = url.split("?")[1];
    if (query) {
      let queryCode = query.split("#")[0].split("=")[1];
      let locationCode = localStorage.getItem("queryCode");
      if (queryCode == locationCode) {
        this.pageLoading = true;
        return;
      } else {
        localStorage.setItem("queryCode", queryCode);
        this.$http
          .get(this.global.codeLogin + "?code=" + queryCode)
          .then((res) => {
            var code = res.data.code;
            if (code === 100000) {
              let data = res.data;
              var token = data.data.userinfo.token;
              var auth = data.auth;
              sessionStorage.setItem("auth", JSON.stringify(auth));
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("defaultActive", "/indexShow");
              sessionStorage.setItem("defaultActiveId", "1");
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.$router.push({ path: "/home", params: { token: token } });
            } else {
              // window.history.back();
              this.pageLoading = true;
              this.$message.warning("验证失败");
            }
          });
      }
    } else {
      this.pageLoading = true;
    }
  },
  methods: {
    loginSendCodeMeth() {
      var param = qs.stringify({
        mobile: this.loginForm.mobile,
        event: "login",
      });
      this.$http
        .post(this.global.send, param)
        .then((res) => {
          if (res.data.code === 1) {
            this.showCountDown = true;
            if (res.data.code === 0) {
              this.loginError.codeErrorText = res.data.msg;
            }
            var interval = setInterval(() => {
              --this.loginForm.second;
            }, 1000);
            setTimeout(() => {
              clearInterval(interval);
              this.showCountDown = false;
              this.loginForm.second = 60;
            }, 60000);
          } else {
            this.loginError.codeErrorText = res.data.msg;
          }
        })
        .catch(() => {
          this.$message.error("失败");
        });
    },
    registerSendCodeMeth() {
      var param = qs.stringify({
        mobile: this.registerForm.mobile,
        event: "register",
      });
      this.$http.post(this.global.send, param).then((res) => {
        if (res.data.code === 1) {
          this.showCountDown = true;
          if (res.data.code === 0) {
            // this.$message(res.data.msg);
            this.registerError.mobileErrorText = res.data.msg;
          }
          var interval = setInterval(() => {
            --this.registerForm.second;
          }, 1000);
          setTimeout(() => {
            clearInterval(interval);
            this.showCountDown = false;
            this.registerForm.second = 60;
          }, 60000);
        } else {
          this.registerError.mobileErrorText = res.data.msg;
        }
      });
    },
    //登录方法
    loginMeth(param) {
      return new Promise((resolve, reject) => {
        this.$http.post(this.global.mobileLogin, param).then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            return resolve(res.data);
          } else if (code === 100001) {
            this.loginError.codeErrorText = "验证码错误";
          }
        });
      });
    },
    mobileRule(val) {
      if (val === "login") {
        if (this.loginForm.mobile === "") {
          this.loginError.mobileErrorText = "请输入手机号";
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(this.loginForm.mobile.trim())) {
          this.loginError.mobileErrorText = "手机号码格式不正确";
          return false;
        } else {
          return true;
        }
      } else if (val === "register") {
        if (this.registerForm.mobile === "") {
          this.registerError.mobileErrorText = "请输入手机号";
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(this.registerForm.mobile.trim())) {
          this.registerError.mobileErrorText = "手机号码格式不正确";
          return false;
        } else {
          return true;
        }
      }
    },
    loginAllRule() {
      if (this.mobileRule("login")) {
        //手机号码通过验证
        if (this.loginForm.code === "") {
          this.loginError.codeErrorText = "请输入验证码";
          return false;
        } else {
          return true;
        }
      }
    },

    sendCode(val) {
      if (val === "login") {
        if (this.mobileRule("login")) {
          this.loginSendCodeMeth();
        }
      } else if (val === "register") {
        if (this.mobileRule("register")) {
          this.registerSendCodeMeth();
        }
      }
    },

    inputFocus(val) {
      val === "phone"
        ? (this.iconColor.phone = "blue")
        : (this.iconColor.code = "blue");
      var color = "blue";
      switch (val) {
        case "phone":
          this.iconColor.phone = color;
          break;
        case "loginCode":
          this.iconColor.loginCode = color;
          break;
        case "company_name":
          this.iconColor.company_name = color;
          break;
        case "contact_name":
          this.iconColor.contact_name = color;
          break;
        case "mobile": //注册
          this.iconColor.mobile = color;
          break;
        case "registerCode":
          this.iconColor.registerCode = color;
          break;
      }
      this.clearError();
    },
    inputBlur(val) {
      // val==='phone'?this.iconColor.phone='gray':this.iconColor.code='gray';
      // this.clearError();
      var color = "gray";
      switch (val) {
        case "phone":
          this.iconColor.phone = color;
          break;
        case "loginCode":
          this.iconColor.loginCode = color;
          break;
        case "company_name":
          this.iconColor.company_name = color;
          break;
        case "contact_name":
          this.iconColor.contact_name = color;
          break;
        case "mobile": //注册
          this.iconColor.mobile = color;
          break;
        case "registerCode":
          this.iconColor.registerCode = color;
          break;
      }
      this.clearError();
    },
    clearError() {
      this.loginError.mobileErrorText = "";
      this.loginError.codeErrorText = "";
      this.registerError.companyErrorText = "";
      this.registerError.contactNameErrorText = "";
      this.registerError.mobileErrorText = "";
      this.registerError.codeErrorText = "";
    },
    login() {
      if (this.loginAllRule()) {
        //手机号码通过验证
        var param = {
          mobile: this.loginForm.mobile.trim(),
          code: this.loginForm.code,
        };
        param = qs.stringify(param);
        this.loginMeth(param).then((res) => {
          var token = res.data.userinfo.token;
          var auth = res.auth;
          sessionStorage.setItem("auth", JSON.stringify(auth));
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("defaultActive", "/indexShow");
          sessionStorage.setItem("defaultActiveId", "1");

          this.$message({
            message: "登录成功",
            type: "success",
          });

          this.$router.push({ path: "/home", params: { token: token } });
        });
      }
    },
    // return false;
    toRegiter() {
      this.$router.push({ path: "/register" });
    },

    //    注册方法开始
    submitForm(formName) {
      if (this.registerAllRule()) {
        this.registerForm.token = this.token;
        var param = qs.stringify(this.registerForm);
        this.registerMeth(param);
      }
    },
    registerMeth(param) {
      return new Promise((resolve, reject) => {
        this.$http.post("v1/admin/register", param).then((res) => {
          if (res.data.code === "Captcha is incorrect") {
            this.registerError.codeErrorText = "验证码错误";
          } else if (res.data.code === 100002) {
            this.registerError.codeErrorText = res.data.msg;
          } else if (res.data.code === 100000) {
            var token = res.data.data.userinfo.token;
            var auth = res.data.auth;
            this.dialogVisible = true;
            this.successCountDown();
            // this.$router.push({path:'/registerSuccess'});
            sessionStorage.setItem("auth", JSON.stringify(auth));
            sessionStorage.setItem("token", token);
          }
        });
      });
    },
    registerAllRule() {
      var b = this.registerForm.mobile.trim();
      this.clearError();
      if (this.registerForm.company_name === "") {
        this.registerError.companyErrorText = "请输入所属公司名称";
        return false;
      } else if (this.registerForm.contact_name === "") {
        this.registerError.contactNameErrorText = "请输入联系人";
        return false;
      } else if (this.registerForm.mobile === "") {
        this.registerError.mobileErrorText = "请输入手机号";
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.registerForm.mobile.trim())) {
        var a = this.registerForm.mobile.trim();
        this.registerError.mobileErrorText = "手机号码格式不正确！";
        return false;
      } else if (this.registerForm.code === "") {
        this.registerError.codeErrorText = "请输入验证码";
        return false;
      } else {
        return true;
      }
    },
    toIndex() {
      this.$router.push({ path: "/indexShow" });
      var obj = { path: "/index", id: 1 };
      this.$emit("toAccInfo", obj);
      sessionStorage.setItem("defaultActiveId", "1");
    },
    successCountDown() {
      var interval = setInterval(() => {
        --this.successSecond;
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        this.successSecond = 5;
        this.toIndex();
      }, 5000);
    },
    //    注册方法结束
  },
};
</script>

<style scoped>
/*@import "../style/basic.css";*/
@import "../assets/css/basic.css";
body,
html {
  width: 100%;
  height: 100%;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

h3 {
  font-size: 26px;
}
/*右边登录框开始*/
.input_item {
  height: 75px;
  margin-top: 15px;
  position: relative;
  box-sizing: border-box;
}
.el-input {
  font-size: 18px;
}
.input_item > input {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  letter-spacing: 1px;
  padding: 0 30px;
  box-sizing: border-box;
}
.del_icon {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
}
.del_icon.active {
  opacity: 1;
  transition: 0.5s;
}
.send_code {
  /*float:right;*/
  height: 38px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 58px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 20px;
}
.send_code.countDown {
  color: #999;
}
.send_code.gray {
  color: #808080;
}

.btnItem {
  width: 45%;
  height: 60px;
  /*background:#169688;*/
  /*color:#fff;*/
  font-size: 18px;
}
.whiteBtn {
  color: #0066ff;
  border: 1px solid #0066ff;
}
.blueBtn {
  box-shadow: 0 5px 15px 0 rgba(0, 102, 255, 0.5);
  width: 100%;
}

/*错误提示*/
.error {
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: 999;
  color: red;
  font-size: 16px;
  transition: 0.5s;
  /*opacity:0;*/
}
.error.active {
  /*opacity:1;*/
  transition: 0.5s;
}

.inputBox {
  height: 70px !important;
}
input.el-input__inner {
  height: 70px !important;
}
/deep/ .el-input__inner {
  height: 70px !important;
}
.el-button--primary {
  background: #0066ff !important;
}
.el-button--primary:hover {
  background: #0066ff !important;
  /*background:rgba(0, 102, 255);*/
}

/*全屏登录页面开始*/
.fullBg {
  background-image: url("../assets/img/login/bg.png");
  /*background-size:cover;*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-size:10% auto;   //调整大小*/
  /*background-position:bottom center;  //调整背景图片的位置*/
}
.title {
  display: flex;
  align-items: center;
  margin-top: 5%;
}
.titleImg {
  min-width: 100px;
    max-width: 150px;
  /* max-height: 65px; */
  margin-right: 20px;
}
.left {
  width: 660px;
  height: 100%;
  /*background:red;*/
  display: flex;
  flex-direction: column;
  /*margin-right:15%;*/
  margin-right: 200px;
  justify-content: center;
}
.imgOutBox {
  flex: 1;
  display: flex;
  align-items: center;
}
.imgBox {
  width: 100%;
  height: 450px;
}
img {
  margin-top:5px;
  width: 100%;
  height: auto;
}

.titleText {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 1px;
  height: 30px;
  line-height: 30px;
  font-family: Microsoft YaHei;
}
.right {
  min-width: 400px;
  /*width:25%;*/
  box-shadow: 0 5px 24px 0 rgba(71, 137, 198, 0.29);
  border-radius: 20px;
  padding: 20px 50px 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}
/*.icon*/
.icon {
  font-size: 22px;
}
.icon.blue {
  color: #0066ff;
}
.icon.gray {
  color: #d7dee3;
}

.formTitle {
  font-size: 20px;
  color: #2d81ff;
  text-align: center;
}
.formBtn {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.phone_input {
  position: relative;
}
.loginTab {
  height: 100%;
}
.hideEl {
  visibility: hidden;
}
/*全屏登录页面结束*/

/*注册成功弹窗开始*/
.successTitle {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 30px 0;
}
.successText {
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
.toIndex {
  color: #66b1ff;
  margin-left: 5px;
  cursor: pointer;
}
.successImgBox {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  /*width:auto;*/
  position: absolute;
  top: -65px;
}
.successImgBox img {
  width: auto;
  height: 100%;
}
/*注册成功弹窗结束*/
</style>
