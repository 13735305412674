import request from "@/api/request";
import qs from "querystring";

//  模板列表
export function getList(param) {
  return request({
    url: `v2/devicemanage/templatelist`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  新增模板
export function addModule(param) {
  return request({
    url: `v2/devicemanage/templatecreate`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  修改模板
export function editModule(param) {
  return request({
    url: `v2/devicemanage/templateupdate`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  删除模板
export function delModule(param) {
  return request({
    url: `v2/devicemanage/templatedelete`,
    method: "post",
    data: qs.stringify(param),
  });
}