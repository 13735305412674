import request from '@/api/request';
import qs from "querystring";
let vi = '/v2'
//  列表
export function getList(data) {
  return request({
    url: `${vi}/cmdtask/getMessageTask`,
    method: 'post',
    data,
  });
}
//获取指令列表
export function getCmdList(data) {
  return request({
    url: `${vi}/cmdtask/getCmdList`,
    method: 'post',
    data,
  });
}

