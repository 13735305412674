<template>
  <div>
    <div>
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <!-- <el-form-item label="">
          <el-input v-model="listQuery.ip" placeholder="请输入客房ip"></el-input>
        </el-form-item> -->
        <el-form-item label="">
          <el-input v-model="listQuery.name" placeholder="请输入客房名称"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="listQuery.alive" placeholder="请选择绑定状态" class="hotelBrand fullSelect " filterable clearable>
            <el-option label="未绑定" value="0">
            </el-option>
            <el-option label="已绑定" value="1">
            </el-option>
          </el-select>
        </el-form-item>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查询</el-button>
          <el-button type="primary" @click="resetDeviceFn()">批量复位</el-button>
          <RoomExport :hotelInFo="hotelInFo" @UpdateList="getList()"></RoomExport>
        </el-form-item>
      </el-form>
    </div>
    <div class="btnRow">
      <el-button type="primary" class="largeBtn" @click="dialogVisible = true">添 加</el-button>
      <el-button type="primary" class="largeBtn" @click="importList" v-if="showImport">导 出</el-button>
      <el-button type="danger" class="largeBtn" @click="del()">删 除</el-button>
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <div class="models flexBox">
      <div class="models-item" v-for="(item,index) in roomManagementList" :key="index">
        <el-button class="btn" type="primary" size="small" @click="openRoomIofo(item.platform)">{{item.platform}}</el-button>
        <span>={{item.num}}间;</span>
      </div>
    </div>
    <el-dialog :title="ruleForm.id?'编辑客房': '新增客房'" :visible.sync="dialogVisible" width="800px" :before-close="handleClose" append-to-body>
      <div class="container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <!-- <el-form-item label="客房ip" prop="roomip">
            <el-input v-model="ruleForm.roomip" placeholder="请输入客房ip"></el-input>
          </el-form-item> -->
          <el-form-item label="客房名称" prop="rname">
            <el-input v-model="ruleForm.rname" placeholder="请输入客房名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getList,
  editUpdate,
  delall,
  resetDevice,
  exportDeviceInfo,
} from "@/api/guestRoom";
import { roomManagement, getRoomByPlatform } from "@/api/allTab/Online";
import RoomExport from "../RoomExport.vue";
export default {
  props: {
    database: {
      type: String,
      default: "",
      // default: 'hr800042'
    },
    hotelInFo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "GuestRoom",
  components: {
    RoomExport,
  },
  data() {
    return {
      showImport: true,
      roomManagementList: [],
      // database: '',
      hotel_contract: "",
      tableData: {
        tableList: [],
        checked: true,
        tableLoading: false,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "50",
            type: "text",
          },
          {
            label: "客房ip",
            prop: "ipaddr",
            minWidth: "80",
            type: "text",
          },
          {
            label: "客房名称",
            prop: "rname",
            minWidth: "120",
            type: "text",
          },
          {
            label: "首次上线时间",
            prop: "room_date",
            minWidth: "250",
            type: "text",
          },
          {
            label: "客房状态",
            prop: "aliveText",
            minWidth: "80",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            // edit: (item) => {
            //   this.ruleForm = {
            //     database: this.database,
            //     id: item.id,

            //     rname: item.rname,
            //   };
            //   this.dialogVisible = true;
            // },
            resetDevice: (item) => {
              this.resetDeviceFn(item.id);
            },
          },
        ],
      },
      listQuery: {
        database: "",
        alive: "",
        name: "",
        ip: "",
        page: 1,
        size: 10,
      },
      checkList: [],

      apkPlatform: [],
      dialogVisible: false,
      ruleForm: {
        database: "",
        hotel_contract: "",
        id: "",
        // roomip: "", //版本号
        rname: "", // apk地址
      },
      rules: {
        // roomip: [{ required: true, message: "请输入客房ip", trigger: "blur" }],
        rname: [{ required: true, message: "请输入客房名称", trigger: "blur" }],
      },
      hotel_name: "",
    };
  },
  methods: {
    getRoomManagement() {
      let params = {
        database: this.databaset,
        alive: this.listQuery.alive,
      };
      roomManagement(params).then((res) => {
        if (res.data.code == 100000) {
          this.roomManagementList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    openRoomIofo(platform) {
      let params = {
        database: this.databaset,
        platform: platform,
      };
      getRoomByPlatform(params).then((res) => {
        if (res.data.code == 100000) {
          console.log(res);
          this.$alert(res.data.data.room_str, "房间信息", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
        } else {
          this.$message.warning("房间信息获取失败");
        }
      });
    },
    importList() {
      let json = {
        hotelContract: this.hotel_contract,
        database: this.databaset,
      };
      exportDeviceInfo(json)
        .then((res) => {
          if (!res.data.code) {
            console.log(res);
            const blobUrl = window.URL.createObjectURL(res.data);
            const title = `${this.hotel_name}客房列表.xlsx`;
            // const title = `${this.tableName}.csv`;
            const a = document.createElement("a");
            a.style.display = "none";
            a.download = title;
            a.href = blobUrl;
            a.click();
            document.body.removeChild(a);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    changeSize(s, p) {
      this.listQuery.page = p;
      this.listQuery.size = s;
      this.getList();
    },
    changeCurrent(s, p) {
      this.listQuery.page = p;
      this.listQuery.size = s;
      this.getList();
    },
    changeSelection(val) {
      this.checkList = val.map((v) => {
        return v.id;
      });
    },
    getList() {
      this.tableData.tableLoading = true;
      this.listQuery.database = this.database;
      this.listQuery.hotel_contract = this.hotel_contract;
      console.log(
        this.listQuery.database,
        "this.listQuerythis.listQuerythis.listQuery"
      );
      getList(this.listQuery).then((res) => {
        this.tableData.tableLoading = false;
        if (res.data.code == 100000) {
          if (res.data.data) {
            this.$refs.operationTable.changeTotal(
              Number(res.data.data.totalNum)
            );
            if (!res.data.data.row) {
              this.tableData.tableList = [];
              return;
            }
            res.data.data.row.forEach((item) => {
              item.aliveText = item.alive == "0" ? "未绑定" : "已绑定";
              item.isEditDisabled = item.alive == "0" ? false : true;
            });
            this.tableData.tableList = res.data.data.row;
          } else {
            this.tableData.tableList = [];
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    del() {
      if (this.checkList.length <= 0) {
        return this.$message.error("请选择客房");
      }
      this.$confirm("此操作将永久删除客房, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delall({
            ids: `${this.checkList.join()}`,
            database: this.listQuery.database,
          }).then((res) => {
            if (res.data.code === 100000) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    resetDeviceFn(id) {
      let ids;
      if (id) {
        ids = id;
      } else {
        if (this.checkList.length <= 0) {
          return this.$message.error("请选择客房");
        }
        ids = this.checkList.join();
      }
      this.$confirm("确定复位客房？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          resetDevice({
            ids: ids,
            database: this.listQuery.database,
            hotel_contract: this.hotel_contract,
            ipaddr: this.hotelInFo.ipaddr,
          }).then((res) => {
            if (res.data.code === 100000) {
              this.getList();
              this.$message({
                type: "success",
                message: "复位成功!",
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleClose() {
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = "";
        }
      }
      this.dialogVisible = false;
    },
    uploadApkInfo(apk) {
      this.ruleForm = {
        ...this.ruleForm,
        ...apk,
      };
    },
    submitForm(formName) {
      this.ruleForm.database = this.database;
      if (this.ruleForm.rname.indexOf("，") != -1) {
        this.$message({
          showClose: true,
          message: `房间输入是不能包含中文逗号`,
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let json = JSON.parse(JSON.stringify(this.ruleForm));
          json.hotel_contract = this.hotel_contract;
          editUpdate(json).then((res) => {
            if (res.data.code === 100000) {
              let tips = this.ruleForm.id ? "编辑" : "新增";
              this.$message({
                showClose: true,
                message: `${tips}成功`,
                type: "success",
              });
              this.dialogVisible = false;
              this.listQuery.page = 1;
              this.getList();
              for (const key in this.ruleForm) {
                if (Object.hasOwnProperty.call(this.ruleForm, key)) {
                  this.ruleForm[key] = "";
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    let data = JSON.parse(sessionStorage.getItem("hoteliitem"));
    console.log(data);
    if (data && data.isApaas) {
      this.showImport = data.hotelRoomExport;
    }
    let { id, hotel_contract, hotelName, hotel } = this.hotelInFo;
    if (!hotel_contract) return;
    this.databaset = hotelName;
    this.hotel_contract = hotel_contract;
    this.hotel_name = hotel;

    // this.getList();
    this.getRoomManagement();
    this.tableData.tableList = this.tableData.tableList.map((i) => {
      if (i.ltype == 1) {
        i.language = "中文";
      } else if (i.ltype == 2) {
        i.language = "英文";
      } else if (i.ltype == 3) {
        i.language = "日文";
      } else if (i.ltype == 4) {
        i.language = "韩文";
      }
      return i;
    });
  },
  watch: {
    hotelInFo: {
      handler: function (newVal) {
        console.log(
          this.hotelInFo.hotelName,
          "this.hotelInFothis.hotelInFothis.hotelInFo"
        );
        this.hotelInFo = newVal;
        let { id, hotel_contract, hotelName, hotel } = newVal;
        if (!hotel_contract) return;
        this.databaset = hotelName;
        this.hotel_contract = hotel_contract;
        this.hotel_name = hotel;
        console.log(this.hotel_name);
        if (this.databaset) {
          this.getList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.models {
  .models-item {
    padding: 0 20px 20px 0;
    .btn {
      margin-right: 10px;
    }
  }
  padding: 10px;
}
.flexBox {
  display: flex;
  flex-wrap: wrap;
}
</style>
