<template>
  <div>
    <el-dialog title="apk批量管理" :visible.sync="dialogVisible" width="800px" :before-close="handleClose" append-to-body>
      <div class="container">
        <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="" prop="eapk_platform">
            <span>DeviceName：</span>
            <el-select v-model="ruleForm.eapk_platform" placeholder="请选择" filterable @change="getApkData">
              <el-option label="通用" value="0">
              </el-option>
              <el-option v-for="(item,index) in apkPlatform" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getApkData">拉取</el-button>
          </el-form-item>
        </el-form>
        <div style="padding-bottom: 20px">共({{tableData.length}})个应用</div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="apkPackage" label="包名" min-width="180">
          </el-table-column>
          <el-table-column prop="nameApk" label="apk" min-width="180">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pullApkList, batchEditAPK } from "../../api/application/apkList";
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        eapk_platform: "",
      },
      apkPlatform: [],
      tableData: [],
      rules: {},
      multipleSelection: [],
      database: "",
      btnLoading: false,
      query: {},
    };
  },
  methods: {
    open(database, apkPlatform, query) {
      this.query = query;
      this.btnLoading = false;
      this.database = database;
      this.ruleForm.eapk_platform = "";
      this.tableData = [];
      this.multipleSelection = [];
      let list = [...apkPlatform];
      list.forEach((item, index) => {
        if (item.value == "0") {
          list.splice(index, 1);
        }
      });

      this.apkPlatform = [...list];
      this.dialogVisible = true;
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getApkData() {
 
      if (this.ruleForm.eapk_platform) {
        let parans = {
          platform: this.ruleForm.eapk_platform,
          database: this.database,
          hotel_contract: this.query.hotel_contract,
        };
        pullApkList(parans).then((res) => {
          if (res.data.code === 100000) {
            console.log(res.data.data);
            this.tableData = res.data.data.data;
            this.multipleSelection = res.data.data.data;
            this.tableData.forEach((row) => {
              this.$refs.multipleTable.toggleRowSelection(row);
            });
          }
        });
      }
    },
    submitForm(flag) {
      let ids = this.multipleSelection.map((v) => v.nameApk);
      let akpStr = "";
      this.multipleSelection.forEach((item, index) => {
        if (index == this.multipleSelection.length - 1) {
          akpStr += `${item.versionApk}@#${item.apkPackage}@#${item.apk_md5}@#${item.apk_url}@#${item.signed_id}`;
        } else {
          akpStr += `${item.versionApk}@#${item.apkPackage}@#${item.apk_md5}@#${item.apk_url}@#${item.signed_id},`;
        }
      });
      if (ids.length <= 0) return this.$message.warning("请选择apk");
      let params = {
        apklist: akpStr,
        database: this.database,
        platform: this.ruleForm.eapk_platform,
        flag: flag,
      };
      this.btnLoading = true;
      batchEditAPK(params).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            showClose: true,
            message: `添加成功`,
            type: "success",
          });
          this.dialogVisible = false;
          this.$parent.getApkList();
          this.btnLoading = false;
        } else if (res.data.code == -2) {
          this.confirmFn(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.btnLoading = false;
      });
    },
    confirmFn(msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.submitForm(1);
      });
    },
  },
};
</script>

<style>
</style>