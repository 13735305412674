<template>
  <el-container style="height: 100vh">
    <div class="asideBox">
      <div class="logo">
        <img :src="logoUrl" alt />
      </div>
      <div class="asideTitle">
        <!--<div>携旅合作伙伴</div>-->
        <div>{{sysName}}</div>

      </div>
      <el-aside width="200px" class="aside">
        <el-col>
          <!-- -->
          <el-menu :default-openeds="['1','2','3']" :default-active="menuiIndex" :unique-opened='false' class="el-menu-vertical-demo" @select="handleSelect" @open="handleOpen" @close="handleClose" background-color="#1a3563" text-color="rgba(255,255,255,0.6)" active-text-color="#fff">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-chat-line-round"></i>
                <span>酒店详情</span>
              </template>
              <el-menu-item v-if="item.show" :index="item.msg" v-for="(item,i) in contentList" :key="i">
                <i class="el-icon-menu"></i>
                <span>{{item.name}}</span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="2" v-if="showFunModel('系统管理')">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span>系统管理</span>
              </template>
              <el-menu-item v-if="item.show" :index="item.msg" v-for="(item,i) in sysList" :key="i">
                <i class="el-icon-menu"></i>
                <span>{{item.name}}</span>
              </el-menu-item>

            </el-submenu>
            <el-submenu index="3" v-if="showFunModel('远程维护')&&showInstroction">
              <template slot="title">
                <i class="el-icon-position"></i>
                <span>远程维护</span>
              </template>
              <el-menu-item v-if="item.show" :index="item.msg" v-for="(item,i) in intList" :key="i">
                <i class="el-icon-menu"></i>
                <span>{{item.name}}</span>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-aside>
    </div>
    <div class="contentBox">
      <div class="topInfo" v-loading='baseloading'>
        <div class="nameBox">
          <div class="name" v-if="topInfo.hotel_alias=='默认'">{{ topInfo.hotel }}</div>
          <div class="name" v-if="topInfo.hotel_alias!='默认'">{{ topInfo.hotel +"（"+topInfo.hotel_alias+"）"  }}</div>
          <el-select style="width:300px;margin-left:50px" @change="groupChange" v-model="hotelGroupProject" placeholder="请选择">
            <el-option v-for="item in groupList" :key="item.hotelName" :label="item.hotel_alias" :value="item.hotelName">
            </el-option>
          </el-select>
          <el-button style="margin-left:20px" type="primary" class="largeBtn" @click="loadPage">数据刷新</el-button>
        </div>

        <div class="bottom">
          <div class="state">
            <div style="width:23%"> 合同号：{{ topInfo.hotel_contract }}</div>
            <div style="width:12%"> 终端门店程序名：{{ topInfo.program_name }}</div>
            <div style="width:13%"> 模板类型：{{ topInfo.hotel_template_name }}</div>
            <div style="width:13%"> 酒店状态：{{ topInfo.hotel_use_state_text  }}</div>
            <div style="width:26%"> 直播方式：{{ topInfo.iptv_name }}</div>
            <div style="width:13%"> 投屏热点：{{ topInfo.lebo_str }}</div>
          </div>
          <div class="state">
            <div style="width:23%">上线登入密码：{{ topInfo.project_code }}</div>
            <div style="width:12%" v-if="showFunModel('终端管理密码（详情）')"> 终端管理密码：{{ topInfo.upgrade_password }}</div>
            <div style="width:13%"> 影视点播类型：{{ topInfo.supplier_name }}</div>
            <div style="width:13%"> 门店ID：{{ topInfo.id }}</div>
            <div style="width:26%"> 有无投屏：{{ topInfo.projection_str }}</div>
            <div style="width:13%"> </div>
          </div>
          <!--<div class="time">合同到期时间：{{showContractText(topInfo.contract_end )}}</div>-->
        </div>
      </div>

      <div class="tabShow">

        <infoPage v-show="optName ==1" ref="hotelAllContent" :topInfoLoading="false" @contentlist="getContentList" :query="itemData"></infoPage>
        <sysPage v-show="optName ==2" @syslist='getSysList' ref="systemDrawer" :query="itemData"></sysPage>
        <drPage v-show="optName ==3" v-if="showInstroction" @intlist="getIntlist" ref="remoteMaintenance" :query="itemData"></drPage>
      </div>

    </div>

    <!-- <el-tabs v-model="optName" @tab-click="changePage">
      <el-tab-pane label="酒店详情" name="info">
        <infoPage ref="hotelAllContent" :topInfoLoading="false" :query="itemData"></infoPage>
      </el-tab-pane>
      <el-tab-pane label="系统管理" name="sys" v-if="showFunModel('系统管理')">
      
      </el-tab-pane>
      <el-tab-pane label="远程维护" name="other" v-if="showFunModel('远程维护')">
      
      </el-tab-pane>
    </el-tabs> -->

  </el-container>
</template>
<script>
import qs from "querystring";
import infoPage from "@/components/common/hotelMan/hotelAllContent.vue";
import sysPage from "@/components/common/operation/systemDrawer.vue";
import drPage from "@/components/common/operation/remoteMaintenance.vue";
import { getHotelGroup, getHotelAllInfo } from "@/api/hotelGroup.js";
export default {
  components: {
    infoPage,
    sysPage,
    drPage,
  },
  data: () => ({
    topLoading: false,
    itemData: {},
    optName: "1",
    menuiIndex: "",
    funArr: [],
    topInfo: "",
    token: "",
    sysName: "",
    logoUrl: "",
    contentList: [],
    sysList: [],
    intList: [],
    itemData: "",
    groupList: [],
    hotelGroupInfo: "",
    hotelGroupProject: "",
    showInstroction: true,
    baseloading: false,
  }),
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  mounted() {
    this.itemData = JSON.parse(sessionStorage.getItem("hoteliitem"));
    console.log(this.itemData,'this.itemDatathis.itemData');
    
    this.topInfo = this.itemData.res.data.data;
    if (this.topInfo.hotel_template_id == 24) {
      this.showInstroction = false;
    }

    document.title = this.topInfo.hotel;
    this.hotelGroupProject = this.itemData.hotelName;
    this.loadGroupChild();
    let str = sessionStorage.getItem("funArr");
    if (str.indexOf("[") != -1) {
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      this.funArr = str.split(",");
    }

    this.$refs.hotelAllContent.type = "detail";
    this.$refs.hotelAllContent.getHotalContentList(
      this.itemData.hotel_contract
    );
    this.$refs.hotelAllContent.getHotelDetail(this.itemData.res, this.itemData);
    if (this.showInstroction) {
      this.$refs.remoteMaintenance.open(this.itemData, this.funArr);
    }


    this.token = sessionStorage.getItem("token");
    this.getLogoData();
  },
  methods: {
    loadPage() {
      this.baseloading = true;
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.topInfo.id,
            hotel_project: this.topInfo.hotelName,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.topInfo = res.data.data;
            if (this.topInfo.hotel_template_id == 24) {
              this.showInstroction = false;
            }
          }
          this.baseloading = false;
        })
        .catch((err) => {
          this.baseloading = false;
        });
    },
    // 获取分组列表
    loadGroupChild() {
      let json = {
        hotel_contract: this.itemData.hotel_contract,
      };
      let firstItemData = JSON.parse(sessionStorage.getItem("firsthoteliitem"));
      getHotelAllInfo(json).then((res) => {
        if (res.data.code == 100000 || res.data.code == 100001) {
          let list = res.data.data.map((i) => {
            i.type = firstItemData.type;
            i.fromUrl = firstItemData.fromUrl;
            i.hotel = firstItemData.hotel;
            i.hotelName = i.program_name;
            return i;
          });
          this.groupList = [...list];
        } else {
        }
            this.$refs.systemDrawer.open(this.itemData, this.funArr,this.groupList.length>1);
      });
    },
    groupChange() {
      let arr = this.groupList.filter((i) => {
        if (i.hotelName == this.hotelGroupProject) {
          return i;
        }
      });
      this.hotelGroupInfo = arr[0];
      this.hotelGroupInfo.id = this.hotelGroupInfo.hotel_id;
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.hotelGroupInfo.id,
            hotel_project: this.hotelGroupInfo.hotelName,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.hotelGroupInfo.hotel_use_state = res.data.data.hotel_use_state;
            this.hotelGroupInfo.res = res;
            let dataItem = Object.assign(this.hotelGroupInfo);
            dataItem.is_newPage = true;
            sessionStorage.setItem("hoteliitem", JSON.stringify(dataItem));
            location.reload();
          }
        });
    },
    getIntlist(data) {
      this.intList = data;
    },
    getSysList(data) {
      this.sysList = data;
    },
    getContentList(list) {
      this.contentList = list;
      this.menuiIndex = "detail";
    },
    handleOpen(key, keyPath) {
      // this.optName = key;
      // if (this.optName == 1) {
      //   this.menuiIndex = "detail";
      //   this.$refs.hotelAllContent.changeTab({ name: this.menuiIndex });
      // } else if (this.optName == 2) {
      //   this.menuiIndex = this.sysList[0].msg;
      //   console.log(this.menuiIndex);
      //   this.$refs.systemDrawer.changeType(this.menuiIndex);
      // } else if (this.optName == 3) {
      //   this.menuiIndex = this.intList[0].msg;
      //   this.$refs.remoteMaintenance.changeType(this.menuiIndex);
      // }
    },
    handleSelect(key, keyPath) {
      if (this.optName == keyPath[0]) {
        this.menuiIndex = keyPath[1];
      } else {
        this.optName = keyPath[0];
        this.menuiIndex = keyPath[1];
      }
      if (this.optName == 1) {
        this.$refs.hotelAllContent.changeTab({ name: this.menuiIndex });
      } else if (this.optName == 2) {
        this.$refs.systemDrawer.changeType(this.menuiIndex);
      } else if (this.optName == 3) {
        this.$refs.remoteMaintenance.changeType(this.menuiIndex);
      }
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 动态获取 账号logo
    getLogoData() {
      this.$http
        .get(this.global.getLogo, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          this.logoUrl = res.data.data.logo_url;
          this.sysName = res.data.data.system_name;
        })
        .catch(() => {});
    },

    changePage(tab, event) {
      this.optName = tab.name;

      if (tab.name == "info") {
        this.$refs.hotelAllContent.type = "detail";
        this.$refs.hotelAllContent.getHotalContentList(
          this.itemData.hotel_contract
        );
        this.$refs.hotelAllContent.getHotelDetail(
          this.itemData.res,
          this.itemData
        );
        this.topInfo = this.itemData.res.data.data;
      } else if (tab.name == "other") {
        this.$refs.remoteMaintenance.open(this.itemData, this.funArr);
      } else {
        this.$refs.systemDrawer.open(this.itemData, this.funArr);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contentBox {
  height: 100%;
  width: calc(100% - 200px);
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
.aside {
  /*height:100vh;*/
  overflow: auto;
  background: #193461 !important;
  flex: 1;
}

.asideBox {
  width: 200px;
  background: #1a3563;
  display: flex;
  flex-direction: column;
}
.asideTitle {
  // height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*padding-bottom:20px;*/
}
.asideTitle div:first-child {
  margin-bottom: 10px;
}
.asideTitle,
.asideTitle div {
  color: #fff;
  /*font-weight:600;*/
  font-size: 16px;
  text-align: center;
}
.logo {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  img {
    /*width:60%;*/
    /*height:auto;*/
    height: 100%;
    // margin-right: 20px;
  }
}
.tabShow {
  margin-top: 140px;
  height: calc(100% - 146px);
  padding-top: 10px;
  box-sizing: border-box;
  overflow-y: visible;
}
.topInfo {
  width: calc(100% - 200px);
  height: 120px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 200px;
  background: #fff;
  z-index: 10;
  border-bottom: 2px solid #ddd;
  box-shadow: 0 4px 10px 2px rgba($color: #000000, $alpha: 0.1);
  .name {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .bottom {
    background: #fff;
    margin-top: 30px;
    .state {
      display: flex;
      justify-content: flex-start;
      color: #999;
      font-size: 14px;
      margin-bottom: 10px;
      div {
        width: 20%;
      }
      .point {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #78affd;
        margin-right: 10px;
      }
    }
  }
}
.state div {
  width: 50%;
  text-align: left;
}
.nameBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
