<template>
	<div>
		<fold-top-search @fold="getFoldMeth" class="absoluteFoldBox"></fold-top-search>
		<el-card class="box-card searchCard" v-if="fold.showSearch">
			<top-search :topSearchShow="topSearchShow" @condition="getCondition"
			            :fromUrl="fromUrl" :allLoadingState="allLoadingState"></top-search>
		</el-card>
		<screen-type @screenTypeSearch="screenTypeSearch" dateCurProp="yesDay" :pageInfo="pageInfo"
                 :showPartProp="showPart" :allLoadingState="allLoadingState" :setSessionDataProp="false">
		</screen-type>
		<div class="lineContent" v-loading="showLoading">
			<el-card class="box-card">
				<div class=" rowBox vertical">
					<div class="quickDateBox">
						<div class="title blueTitle">影视收益预计</div>
					</div>
					<el-row :gutter="20" class="rowBox horizontal borderBox">
						<three-part-show :span="6" :type="type" class="noWarpBox"
										 :idd="idd">
							<span slot="numTitle">{{tabType}}订单数量</span>
							<span slot="time">{{showDataTime('manyDay')}}</span>
							<span slot="num">{{showDataCom(showData.watch_film_num_total)}}</span>
							<span slot="unit">个</span>
                          	<span slot="refund">退款订单数量：</span>
                            <span slot="many">{{showDataCom(showData.refund_film_num_total)}} 个</span>
						</three-part-show>
						<three-part-show :span="6" :type="type"
										 :idd="idd">
							<span slot="numTitle">{{tabType}}订单金额</span>
							<span slot="time">{{showDataTime('manyDay')}}</span>
							<span slot="num">{{showDataCom(showData.movie_income_total_total,'money')}}</span>
							<span slot="unit">元</span>
                            	<span slot="refund">退款订单金额：</span>
                            <span slot="many">{{showDataCom(showData.refund_income_total_total)}} 元</span>
						</three-part-show>
						<three-part-show :span="6" :type="type" :idd="idd">
							<span slot="numTitle">
								{{tabType}}分成收益
						<el-popover
								placement="top-start"
								width="400"
								trigger="hover">
							<i>2019、2020年建站酒店产生订单按照当年合同中达到的分成比例进行分成计算。2021年6月份以后建站酒店产生订单根据2021年签订的合同规定按阶梯进行相应分成计算。</i>
								<i class="el-icon-question" slot="reference"></i>
									</el-popover>
							</span>
							<span slot="time">{{showDataTime('manyDay')}}</span>
							<span slot="num">{{showDataCom(showData.movie_income_total,'money')}}</span>
							<span slot="unit">元</span>
                            	<span slot="refund">退款订单收益：</span>
                            <span slot="many">{{showDataCom(showData.refund_income_total)}} 元</span>
						</three-part-show>
						<three-part-show :span="6" :type="type"  :idd="idd">
						<span slot="numTitle">影视有效激活终端数量</span>
						<span slot="time">{{showDataTime('activeTerminal')}}</span>
						<span slot="num" >{{showDataCom(showData.valid_active_terminal_num_total)}}</span>
						<span slot="unit">台</span>
						</three-part-show>
					</el-row>
				</div>
			</el-card>
		</div>
		<el-card class="box-card" v-loading="lineLoading">
			<div class="lineContent chartBox">
				<index-line-chart :chart="lineChart" v-if="lineChart.flag && !lineChart.noData"></index-line-chart>
				<div v-if="lineChart.flag && lineChart.	noData" style="line-height:500px;text-align:center">暂无数据</div>
			</div>
		</el-card>
		<el-card class="box-card">
			<div class="lineContent">
				<div class="title chartTitle flexBox">
					<div>订单列表</div>
					<div class="titleTime">{{showDataTime('manyDay')}}</div>
				</div>
				<table-list
						:table="table"
						@tableMeth="tableMeth"
						v-loading="table.loading"
						@showUploadDialog="showUploadDialog">
				</table-list>
			</div>
		</el-card>
		<div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
		<el-drawer
				size="1000px"
				title="详情"
				:visible.sync="showUpload">
			<div style="padding-left:20px;padding-right: 20px">
			<el-table :data="movieIncome"  :header-cell-style="headerStyle">
				<el-table-column prop="orderDate" label="时间"  width="160"></el-table-column>
				<el-table-column prop="orderRoom" label="房间号" show-overflow-tooltip  width="100"></el-table-column>
                <el-table-column prop="room_addtime" label="房间上线时间" show-overflow-tooltip  width="160"></el-table-column>
				<el-table-column prop="videoName" label="影片名称" show-overflow-tooltip></el-table-column>
				<el-table-column prop="orderAmount" label="影片价格（元）" show-overflow-tooltip></el-table-column>
				<el-table-column prop="calculateProportion" label="分成比例" show-overflow-tooltip></el-table-column>
				<el-table-column prop="calculateAmount" label="收益金额（元）" show-overflow-tooltip></el-table-column>
			</el-table>
			</div>
		</el-drawer>
	</div>
</template>
<script>
    import qs from 'querystring'
    import GLOBAL from '../../../api/globalVar.js'
    import commonMeth from '../../../api/method'
	import Bus from "../../../api/Bus";

    export default {
        name: "movie",
		props:["tabType"],
        data: () => ({
			showStatus:false,
			idd:'idd',
			headerStyle: GLOBAL.tableHeaderStyle,
          pageInfo:{
            hotelState:'incomesta',
          },
            getIndexHeader: {
                accountId: "",
                accountType:'',
            },
            type: 'timeCenter',
            partType: 'setAccount',
            lineChart: {
                id: 'movie',
                showTitle: true,
                flag: false,
                noData: false,
                fromUrl: 'inTabMovie',
                option: {
                    legend: [],
                    xData: [],
                    series: [
						{
						name: '单点订单数量',
						type: 'line',
						smooth: true,
						itemStyle: {
							normal: {
								color: GLOBAL.chartColor[0], //改变折线点的颜色
								lineStyle: {
									color: GLOBAL.chartColor[0] //改变折线颜色
								}
							}
						},
						data: []
					},
						{
							name: '单点订单金额',
							type: 'line',
							smooth: true,
							yAxisIndex: 2,
							itemStyle: {
								normal: {
									color: GLOBAL.chartColor[2], //改变折线点的颜色
									lineStyle: {
										color: GLOBAL.chartColor[2] //改变折线颜色
									}
								}
							},
							data: []
						},
						{
							name: '单点分成收益',
							type: 'line',
							smooth: true,
							yAxisIndex: 1,
							itemStyle: {
								normal: {
									color: GLOBAL.chartColor[1], //改变折线点的颜色
									lineStyle: {
										color: GLOBAL.chartColor[1]//改变折线颜色
									}
								}
							},
							data: []
						},
                        {
                            name: '天卡订单数量',
                            type: 'line',
                            smooth: true,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[0], //改变折线点的颜色
                                    lineStyle: {
                                        color: GLOBAL.chartColor[0] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
						{
							name: '天卡订单金额',
							type: 'line',
							smooth: true,
							yAxisIndex: 2,
							itemStyle: {
								normal: {
									color: GLOBAL.chartColor[2], //改变折线点的颜色
									lineStyle: {
										color: GLOBAL.chartColor[2] //改变折线颜色
									}
								}
							},
							data: []

						},
						{
							name: '天卡分成收益',
							type: 'line',
							smooth: true,
							yAxisIndex: 1,
							itemStyle: {
								normal: {
									color: GLOBAL.chartColor[1], //改变折线点的颜色
									lineStyle: {
										color: GLOBAL.chartColor[1]//改变折线颜色
									}
								}
							},
							data: []
						},
                    ]
                }
            },
            table: {
				tabType:'',
                name: 'incomeStaMovie',
                tableHeader: [
                ],
                tableList: [],
                page: 1,
                limit: 5,   //当前每页显示多少条
                total: 0,
                fromUrl: 'incomeStaMovie',
                option: true,
                loading: true,
                darText: '日活率（%）',
            },
            showData: {
				//订单数量
                watch_film_num_total: 0,
				//订单金额
                movie_income_total: 0,
				//分成收益
				movie_income_total_total:0,
				//影视有效激活终端数量
				valid_active_terminal_num_total:0,
				//观影运营酒店数量
                watch_film_hotel_num_total: 0,
				//观影激活终端数量
                active_terminal_num_total: 0,
				// 已安装影视终端数量
				setup_terminal_num_total:0,
            },
            condition: {
                startTime: '',
                endTime: '',
                province: '',
                city: '',
                country: '',
                hotelBrand: '',
                accountManagerId: '',
                incomeSource: '',
                is_profit:"",
            },
            topSearchShow: {},
            token: '',
            auth: {},
            auth_type: '',
            showLoading: true,
            
            lineLoading: false,
            fold: {
                showSearch: false,
                foldText: '展开筛选项'
            },
            quickNatureCur: ['self'],
            screenType: '',
            curDate: {
                yesDay: '',
                curDay: '',
                startWeek: '',
                endWeek: '',
                startMonth: '',
                endMonth: '',
                startYear: '',
                endYear: '',
            },
            fromUrl: 'inTabMovie',
            belongPartnerCodeAllStr: '',
            allotPartnerCodeAllStr: '',
            allLoadingState: false,
            showPart: {},
            reqUrl: {
                table: '',
                data: '',
                line: '',
            },
			movieIncome:{
			},
			showUpload:false,
        }),
        methods: {
			showUploadDialog(val){
				this.query = val;
				console.log(val)
				this.$http.post(this.global.getMovieIncomeInfoById, 
					qs.stringify({
						token: this.token,
						hotelContract:this.query.hotel_contract,
						endTime:this.query.endTime,
                        id:this.query.id,
                        is_special:this.query.is_special,
                        is_profit:this.condition.is_profit,
						order_goods_type:this.query.order_goods_type,
						incomeSource:this.screenType,}
					)
				).then((res) => {
					if (res.data.code === 100000) {
					this.movieIncome=res.data.data
						this.showUpload=true
					}
				});
			},
            handleSizeChange(newSize) {
                this.queryInfo.pageSize = newSize;
            },
            handleCurrentChange(newPage) {
                this.queryInfo.currentPage = newPage;
            },
            getLineChart() {
                this.lineChart.flag = false;
                this.lineLoading=true;
                var params = {
                    token: this.token,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                    incomeSource: this.condition.incomeSource,
	                screenType:this.screenType,
                    is_profit:this.condition.is_profit,
	                refresh:false
                };
                if (params.belongPartnerCode==''&&params.allotPartnerCode==''){
                }else if (params.belongPartnerCode!==''){
                    params.partner_id=params.belongPartnerCode
                }else if (params.allotPartnerCode!==''){
                    params.partner_id=params.allotPartnerCode
                }
				var a=''
				if (this.tabType=='单点'){
					a=this.global.getMovieIncomeLine
				}else if (this.tabType=='天卡'){
					a=this.global.getMovieIncomeDayLine
				}
                this.$http.post(a, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var chartData = res.data.data;
                        if (chartData.series) {
                            this.lineChart.option.legend = chartData.legend;  //图例
                            this.lineChart.option.xData = chartData.xAxis;  //x轴
                            var dataTransEd = commonMeth.dataTrans(chartData);
							if (this.tabType=='单点'){
								this.lineChart.option.series[0].data = dataTransEd.series['单点订单数量'];  //x轴
								this.lineChart.option.series[1].data = dataTransEd.series['单点订单金额'];  //x轴
								this.lineChart.option.series[2].data = dataTransEd.series['单点分成收益'];  //x轴
							}else if (this.tabType=='天卡'){
								this.lineChart.option.series[3].data = dataTransEd.series['天卡订单数量'];  //x轴
								this.lineChart.option.series[4].data = dataTransEd.series['天卡订单金额'];  //x轴
								this.lineChart.option.series[5].data = dataTransEd.series['天卡分成收益'];  //x轴
							}
                            this.lineChart.flag = true;
                            this.lineChart.noData = false;
                        } else {
                            this.lineChart.flag = true;
                            this.lineChart.noData = true;
                        }
                    }
                    this.lineLoading = false;
                });
            },
            getLineChartfrist() {
                this.lineChart.flag = false;
                this.lineLoading=true;
                var params = {
                    token: this.token,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                    incomeSource: this.condition.incomeSource,
                    screenType:this.screenType,
	                refresh: true,
                };
                if (params.belongPartnerCode==''&&params.allotPartnerCode==''){
                }else if (params.belongPartnerCode!==''){
                    params.partner_id=params.belongPartnerCode
                }else if (params.allotPartnerCode!==''){
                    params.partner_id=params.allotPartnerCode
                }
				var a=''
				if (this.tabType=='单点'){
					a=this.global.getMovieIncomeLine
				}else if (this.tabType=='天卡'){
					a=this.global.getMovieIncomeDayLine
				}
                this.$http.post(a, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var chartData = res.data.data;
                        if (chartData.series) {
                            this.lineChart.option.legend = chartData.legend;  //图例
                            this.lineChart.option.xData = chartData.xAxis;  //x轴
                            var dataTransEd = commonMeth.dataTrans(chartData);
							if (this.tabType=='单点'){
								this.lineChart.option.series[0].data = dataTransEd.series['单点订单数量'];  //x轴
								this.lineChart.option.series[1].data = dataTransEd.series['单点订单金额'];  //x轴
								this.lineChart.option.series[2].data = dataTransEd.series['单点分成收益'];  //x轴
							}else if (this.tabType=='天卡'){
								this.lineChart.option.series[3].data = dataTransEd.series['天卡订单数量'];  //x轴
								this.lineChart.option.series[4].data = dataTransEd.series['天卡订单金额'];  //x轴
								this.lineChart.option.series[5].data = dataTransEd.series['天卡分成收益'];  //x轴
							}
                            this.lineChart.flag = true;
                            this.lineChart.noData = false;
                        } else {
                            this.lineChart.flag = true;
                            this.lineChart.noData = true;
                        }
                    }
                    this.lineLoading = false;
                });
            },
            tableMeth(orderVal) {
            	var {startTime,endTime,incomeSource}=this.condition;
            	if(startTime===''&& endTime===''){
					this.table.startTime=this.curDate.yesDay;
					this.table.endTime=this.curDate.yesDay;
				}else{
					this.table.startTime=startTime;
					this.table.endTime=endTime;
				}
            	this.table.incomeSource=this.screenType;
                this.table.loading = true;
                var sortStr = '';
                var orderStr = '';
                var url = '';
                if (orderVal) {
                    if (orderVal.prop.indexOf('_text') !== -1) {
                        var index = orderVal.prop.indexOf('_text');
                        sortStr = orderVal.prop.substring(0, index);
                    } else {
                        sortStr = orderVal.prop;
                    }
                    orderVal.order === 'ascending' ? orderStr = 'ASC' : orderStr = 'DESC';
                } else {
                    sortStr = 'id';
                    orderStr = 'DESC';
                }
                var params = {
                    token: this.token,
                    page: this.table.page,
                    limit: this.table.limit,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    keyword: this.condition.keyword,
                    is_profit:this.condition.is_profit,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    sort: sortStr,
                    order: orderStr,
	                screenType:this.screenType,
	                refresh:false
                };
                    params.screenType = this.screenType
                if (params.belongPartnerCode==''&&params.allotPartnerCode==''){
                }else if (params.belongPartnerCode!==''){
                    params.partner_id=params.belongPartnerCode
                }else if (params.allotPartnerCode!==''){
                    params.partner_id=params.allotPartnerCode
                }
				var a=''
				if (this.tabType=='单点'){
					a=this.global.getMovieIncomeList
				}else if (this.tabType=='天卡'){
					a=this.global.getMovieDayIncomeList
				}
                this.$http.post(a, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        this.table.total = tableData.total;
						this.table.tabType=this.tabType;
                    }
                    this.table.loading = false;
                }).catch(() => {
                    this.table.loading = false;
                })
            },
            tableMethfrist(orderVal) {
                var {startTime,endTime,incomeSource}=this.condition;
                if(startTime===''&& endTime===''){
                    this.table.startTime=this.curDate.yesDay;
                    this.table.endTime=this.curDate.yesDay;
                }else{
                    this.table.startTime=startTime;
                    this.table.endTime=endTime;
                }
                this.table.incomeSource=this.screenType;
                this.table.loading = true;
                var sortStr = '';
                var orderStr = '';
                var url = '';
                if (orderVal) {
                    if (orderVal.prop.indexOf('_text') !== -1) {
                        var index = orderVal.prop.indexOf('_text');
                        sortStr = orderVal.prop.substring(0, index);
                    } else {
                        sortStr = orderVal.prop;
                    }
                    orderVal.order === 'ascending' ? orderStr = 'ASC' : orderStr = 'DESC';
                } else {
                    sortStr = 'id';
                    orderStr = 'DESC';
                }
                var params = {
                    token: this.token,
                    page: this.table.page,
                    limit: this.table.limit,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    keyword: this.condition.keyword,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    sort: sortStr,
                    order: orderStr,
                    screenType:this.screenType,
	                refresh:true
                };
                params.screenType = this.screenType
                if (params.belongPartnerCode==''&&params.allotPartnerCode==''){
                }else if (params.belongPartnerCode!==''){
                    params.partner_id=params.belongPartnerCode
                }else if (params.allotPartnerCode!==''){
                    params.partner_id=params.allotPartnerCode
                }
				var a=''
				if (this.tabType=='单点'){
					a=this.global.getMovieIncomeList
				}else if (this.tabType=='天卡'){
					a=this.global.getMovieDayIncomeList
				}
                this.$http.post(a, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        this.table.total = tableData.total;
                        this.table.tabType=this.tabType;
                    }
                    this.table.loading = false;
                }).catch(() => {
                    this.table.loading = false;
                })
            },
            showDataMeth() {
                this.showLoading = true;
                var params = {
                    token: this.token,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                  screenType:this.screenType,
                   is_profit:this.condition.is_profit,
	                refresh:false
                };
                if (params.belongPartnerCode==''&&params.allotPartnerCode==''){
                }else if (params.belongPartnerCode!==''){
                    params.partner_id=params.belongPartnerCode
                }else if (params.allotPartnerCode!==''){
                    params.partner_id=params.allotPartnerCode
                }
				var a=''
				if (this.tabType=='单点'){
					a=this.global.getMovieIncomeStatistics
				}else if (this.tabType=='天卡'){
					a=this.global.getMovieIncomeDayStatistics
				}
                this.$http.post(a, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.showData = res.data.data;
                    }
                    this.showLoading = false;
                }).catch(() => {
                    this.showLoading = false;
                })
            },
            showDataMethfrist() {
                this.showLoading = true;
                var params = {
                    token: this.token,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    startTime: this.condition.startTime,
                    endTime: this.condition.endTime,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                  screenType:this.screenType,
	                refresh:true
                };
                if (params.belongPartnerCode==''&&params.allotPartnerCode==''){
                }else if (params.belongPartnerCode!==''){
                    params.partner_id=params.belongPartnerCode
                }else if (params.allotPartnerCode!==''){
                    params.partner_id=params.allotPartnerCode
                }
				var a=''
				if (this.tabType=='单点'){
					a=this.global.getMovieIncomeStatistics
				}else if (this.tabType=='天卡'){
					a=this.global.getMovieIncomeDayStatistics
				}
                this.$http.post(a, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.showData = res.data.data;
                    }
                    this.showLoading = false;
                }).catch(() => {
                    this.showLoading = false;
                })
            },
            getTopSearchShow(type) {
                var auth = JSON.parse(sessionStorage.getItem('auth'));
                var auth_type = auth.type;
                if (auth_type === 'channel' || auth_type === 'screen' || auth_type === 'factory') {
                    this.topSearchShow = {
                      hotel_brand: true,
                      province: true,
                      city: true,
                      country: true,
                      coopType: true,
                      coopPartner: true,
                      BD: true,
                      date: true,
                      keyword: true,
                      coopPartnerType: true,
                      is_profit:true,
                    }
                } else if (auth_type === 'group') {
                    this.topSearchShow = {
                        province: true,
                        city: true,
                        country: true,
                        coopType: true,
                        coopPartner: true,
                        date: true,
	                    keyword:true,
                         is_profit:true,
                    }
                } else if (auth_type === 'htrip') {
                    this.topSearchShow = {
                        hotel_brand: true,
                        province: true,
                        city: true,
                        country: true,
                        coopType: true,
                        coopPartner: true,
                        BD: true,
                        date: true,
                        keyword: true,
                        coopPartnerType: true,
                        is_profit:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder = '请输入酒店名称关键词';
            },
            getScreenTypeSearchShow() {
                var param = qs.stringify({
                    token: this.token,
                });
                this.$http.post(this.global.getIndexHeader, param).then((res) => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.getIndexHeader.accountId = res.data.data.accountId;
                        this.getIndexHeader.accountType=res.data.data.accountType
                    } else {
                    }
                    switch (this.getIndexHeader.accountType) {
                        case 1:
                            this.showPart = {
                                reqUrl:true,
                                self:true,
                                other:true,
                                videoRoom:true,
                            }
							this.showStatus=false
                            this.screenType='self'
                            break
	                    case 2:
                            this.showPart = {
                                reqUrl:true,
                                self:true,
                                other:true,
                                videoRoom:true,
                            }
							this.showStatus=false
                            this.screenType='self'
                            break
	                    case 3:
                            this.screenType=''
		                    break
	                    case 4:
                            this.screenType=''
                            break
	                    case 5:
	                        this.showPart={
                                reqUrl:true,
		                        belong:true,
                                subordinate:true,
	                        }
							this.showStatus=true
	                        this.screenType='belong'
		                    break
	                    case 6:
	                        this.showPart={
                            	reqUrl:true,
		                        allot:true,
		                        belong:true,
		                        subordinate:true,
	                    }
							this.showStatus=true
							this.screenType='belong'
	                    break
                    }
                });
            },
            getCondition(val) {
                this.condition.is_profit = val.is_profit;
                this.condition.hotel_brand = Array.isArray(val.hotel_brand) ? val.hotel_brand.join(',') : '';
                this.condition.hotel_use_state = Array.isArray(val.hotel_use_state) ? val.hotel_use_state.join(',') : '';
                this.condition.BD = Array.isArray(val.BD) ? val.BD.join(',') : '';
                this.condition.partner_id = Array.isArray(val.partner_id) ? val.partner_id.join(',') : '';
                this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode) ? val.belongPartnerCode.join(',') : '';
                this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode) ? val.allotPartnerCode.join(',') : '';
                if (typeof val.citycode === 'string') {
                    this.condition.citycode = val.citycode;
                } else {
                    this.condition.citycode = Array.isArray(val.citycode) ? val.citycode.join(',') : ''; //citycode
                }
                this.condition.provinceLabel = Array.isArray(val.provinceLabel) ? val.provinceLabel.join(',') : ''; //provinceLabel
                this.condition.cityLabel = val.cityLabel;
                this.condition.countyLabel = val.countyLabel;
                this.condition.startTime = val.startTime;
                this.condition.endTime = val.endTime;
                this.table.darText = commonMeth.setTableDarText(this.condition.startTime, this.condition.endTime);
                this.condition.keyword = val.keyword;
                this.table.page = 1;
                this.tableMeth('');
                this.getLineChart();
                this.showDataMeth();
            },
            getFoldMeth(val) {
                this.fold = val;
            },
            getYesDay() {
                var time = (new Date).getTime() - 24 * 60 * 60 * 1000;
                var yesterday = new Date(time);
                var month = yesterday.getMonth() + 1;
                var day = yesterday.getDate();
                this.curDate.yesDay = yesterday.getFullYear() + "-" + (month > 9 ? (month) : "0" + (month)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
            },
            getCurDay() {
                var time = (new Date).getTime();
                var today = new Date(time);
                var month = today.getMonth() + 1;
                var day = today.getDate();
                this.curDate.curDay = today.getFullYear() + "-" + (month > 9 ? (month) : "0" + (month)) + "-" + (today.getDate() > 9 ? (today.getDate()) : "0" + (today.getDate()));
            },
            add0(m) {
                return m < 10 ? '0' + m : m
            },
            getCurWeek() {
                var startStop = new Array();
                //获取当前时间
                var currentDate = new Date();
                //返回date是一周中的某一天
                var week = currentDate.getDay();
                //返回date是一个月中的某一天
                var month = currentDate.getDate();
                //一天的毫秒数
                var millisecond = 1000 * 60 * 60 * 24;
                //减去的天数
                var minusDay = week != 0 ? week - 1 : 6;
                //alert(minusDay);
                //本周 周一
                var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
                //本周 周日
                var sunday = new Date(monday.getTime() + (6 * millisecond));
                var sy = monday.getFullYear();
                var sm = monday.getMonth() + 1;
                var sd = monday.getDate();
                var ey = sunday.getFullYear();
                var em = sunday.getMonth() + 1;
                var ed = sunday.getDate();
                // var s = sy+'-'+add0(sm)+'-'+add0(sd)+' 00:00:00';//开始
                // var e = ey+'-'+add0(em)+'-'+add0(ed)+' 23:59:59';//结束
                var s = sy + '-' + this.add0(sm) + '-' + this.add0(sd);//开始
                var e = ey + '-' + this.add0(em) + '-' + this.add0(ed);//结束
                this.curDate.startWeek = s;
                this.curDate.endWeek = this.curDate.yesDay;
            },
            getCurMonth(type, months) {
                var d = new Date();
                var year = d.getFullYear();
                var month = d.getMonth() + 1;
                if (Math.abs(months) > 12) {
                    months = months % 12;
                };

                if (months != 0) {
                    if (month + months > 12) {
                        year++;
                        month = (month + months) % 12;
                    } else if (month + months < 1) {
                        year--;
                        month = 12 + month + months;
                    } else {
                        month = month + months;
                    };
                };
                month = month < 10 ? "0" + month : month;
                var date = d.getDate();
                var firstday = year + "-" + month + "-" + "01";
                var lastday = "";
                if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
                    lastday = year + "-" + month + "-" + 31;
                } else if (month == "02") {
                    if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                        lastday = year + "-" + month + "-" + 29;
                    } else {
                        lastday = year + "-" + month + "-" + 28;
                    };
                } else {
                    lastday = year + "-" + month + "-" + 30;
                };
                var day = "";
                if (type == "s") {
                    day = firstday;
                } else {
                    // day = lastday;
                    day = this.curDate.curDay;
                }
                ;
                return day;
            },
            getCurYear(type, dates) {
                var dd = new Date();
                var n = dates || 0;
                var year = dd.getFullYear() + Number(n);
                if (type == "s") {
                    var day = year + "-01-01";
                }
                ;
                if (type == "e") {
                    // var day = year + "-12-31";
                    var day = this.curDate.curDay;
                }
                ;
                if (!type) {
                    var day = year + "-01-01/" + year + "-12-31";
                }
                ;
                return day;
            },
            getCurDate() {},
            getBelongPartnerOptions() {   //所属
                this.$http.get(this.global.getBelongPartnerOptions, {
                    params: {
                        token: this.token
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var finalArr = [];
                        res.data.data.forEach((item, index) => {
                            finalArr.push(item.code);
                        });
                        this.belongPartnerCodeAllStr = finalArr.join(',');
                    }
                });
            },
            getAllotPartnerOptions() {   //归属
                this.$http.get(this.global.getAllotPartnerOptions, {
                    params: {
                        token: this.token
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var finalArr = [];
                        res.data.data.forEach((item, index) => {
                            finalArr.push(item.code);
                        });
                        this.allotPartnerCodeAllStr = finalArr.join(',');

                    }
                });
            },
            screenTypeSearch(screenType, startTime, endTime, belongCur) {
                this.screenType = screenType;
                this.condition.startTime = startTime;
                this.condition.endTime = endTime;
                this.table.page = 1;
                this.table.limit = 5;
                this.tableMeth();
                this.getLineChart();
                this.showDataMeth();
            },
        },
        computed: {
            showDataCom() {
                return function (s, type = '', n = 2) {
                    if (type === 'money') {
                        if (s === null) {
                            return 0;
                        } else {
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    } else {
                        if (s === null) {
                            return 0;
                        } else {
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            showDataTime() {
                return function (val) {
                    if (val === 'manyDay') {   //日活率
                        if (this.condition.startTime === '') {
                            return '【昨日】'
                        } else {
                            var time = '【' + this.condition.startTime + '~' + this.condition.endTime + '】';
                            return time;
                        }
                    } else if (val==='activeTerminal'){
                        if (this.condition.startTime === '') {
                            return '【2021-01-01 – 昨日】'
                        } else {
                            var time = '【' + this.condition.startTime + '~' + this.condition.endTime + '】';
                            return time;
                        }
                    }else {   //其他数据
                        if (this.condition.startTime === '') {
                            return '【昨日】'
                        } else if (this.condition.startTime !== this.condition.endTime) {
                            var time = '【' + this.condition.endTime + '】';
                            return time;
                        } else {
                            var time = '【' + this.condition.startTime + '~' + this.condition.endTime + '】';
                            return time;
                        }
                    }
                }
            },
            twoPointCom() {
                return function (x) {
                    !x ? x = 0 : '';
                    var f = parseFloat(x);
                    if (isNaN(f)) {
                        return false;
                    }
                    var f = Math.round(x * 100) / 100;
                    var s = f.toString();
                    var rs = s.indexOf('.');
                    if (rs < 0) {
                        rs = s.length;
                        s += '.';
                    }
                    while (s.length <= rs + 2) {
                        s += '0';
                    }
                    return s;
                }
            },
            allLoadingStateCom() {
                this.allLoadingState = commonMeth.getAllLoading(this.table.loading, this.lineLoading, this.showLoading);
            }
        },
        mounted() {
            this.token = sessionStorage.getItem('token');
            this.auth = JSON.parse(sessionStorage.getItem('auth'));
            this.auth_type = this.auth.type;
            this.showDataMethfrist();
            this.lineChart.option.series.forEach((item, index) => {
                item.symbolSize = this.global.symbolSize;
            });
            this.lineChart.id=this.tabType;
            this.getLineChartfrist();
			this.getYesDay();
            this.tableMethfrist();
            this.getTopSearchShow();
            this.getScreenTypeSearchShow();
            this.getCurDate();
            this.getYesDay();
            this.getCurDay();
            this.getCurWeek();
            this.curDate.startMonth = this.getCurMonth("s", 0);
            this.curDate.endMonth = this.curDate.yesDay;
            this.curDate.startYear = this.getCurYear("s", 0);
            this.curDate.endYear = this.curDate.yesDay;
            this.getBelongPartnerOptions();
            this.getAllotPartnerOptions();

        }
    }
</script>

<style scoped lang="scss">
	.chartBox {
		height: 400px;
		overflow: hidden;
	}

	.el-row {
		margin-top: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.quickDateBox {
		display: flex;
		align-items: center;
	}

	.borderBox > .noWarpBox > span {
		white-space: nowrap !important;
	}

	.flexBox {
		display: flex;
	}
    .titleTime{
        font-size: 14px;
    }
</style>
