<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">CIBN播控配置</span>
        <span slot="third">发布列表</span>
      </top-bread>
      <fold-top-search  @fold="getFoldMeth"></fold-top-search>
    </div>
    <!-- 筛选界面处理 -->
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" :topSearchSave="topSearchSave"></top-search>
    </el-card>
    <!-- 表格操作区 -->
    <el-card class="box-card">
      <div class="title blueTitle">发布列表</div>
      <div class="btnRow">
        <el-button type="primary" @click="openCreate">新 建</el-button>
      </div>
      <el-table class="tableBox" ref="singleTable" :row-key="getRowKey" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="loading" @selection-change="selectionChange">
        <el-table-column type="selection" width="55" :reserve-selection="true" :selectable="selectable"></el-table-column>
        <el-table-column prop="id" label="ID" width="55"></el-table-column>
        <el-table-column prop="label_name" label="名称" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type='text' @click="openEditContent(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="classify_text" label="分类"></el-table-column>
        <el-table-column prop="label_type_text" label="性质" min-width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="num" label="所属酒店数量" min-width="120" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="status_text" label="状态"></el-table-column> -->
        <el-table-column prop="admin_id_text" label="创建人"></el-table-column>
        <el-table-column prop="create_time_text" label="创建时间" min-width="160px"></el-table-column>
        <el-table-column prop="update_admin_id_text" label="最新修改人" min-width="120"></el-table-column>
        <el-table-column prop="update_time_text" label="最新修改时间" min-width="160px"></el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <cibnform ref="cibnform"></cibnform>
  </div>
</template>
<script>
import cibnform from "./components/CIBNform.vue";
import commonMeth from "@/api/method";
export default {
  name: "CIBNList",
components:{
  cibnform
},
  data() {
    return {
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },

      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      funArr: [],
        headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      topSearchSave: {},
      topSearchShow: {
        labelType: true,
        labelNature: true,
        labelMold: true,
        mediaType: true,
        keyword: true,
        hotel_use_state: true,
        keywordPlaceholder: "请输入标签名称关键字",
      },
      tableList: [{}],
      loading: false,

      selectIdArr: [],
    };
  },
    computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  mounted() {
    // 获取权限列表
    commonMeth.getTreeData().then((res) => {
      this.funArr = res.funArr;
    });
  },
  methods: {
    openCreate(){
        this.$refs.cibnform.open();
    },
     // 广告禁用
    selectable(row, index) {
      if (row.classify_id == 1) {
        return false;
      } else {
        return true;
      }
    },
    // 获取每一行的key值
    getRowKey(row) {
      return row.id;
    },
    // 表格选中
    selectionChange(val) {
      this.selectIdArr = val;
    },
    // 分页
    handleSizeChange(val) {
      this.table.limit = val;
      this.getLabelList();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getLabelList();
    },
    // 搜索
    getCondition(val) {
      console.log(val,"new");
      this.table.page = 1;
      this.getLabelList();
    },
    // 获取列表
    getLabelList() {
      //   this.loading = true;
    },
    // 筛选
    getFoldMeth(val) {
      this.fold = val;
    },
    
  },
};
</script>
<style scoped>

.outTopBox {
  height: 38px;
}
.searchBtn {
  position: fixed;
  top: 110px;
  right: 10px;
}
.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.btnRow .el-button {
  width: 100px;
  height: 40px;
}
</style>