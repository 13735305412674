<template>
  <el-container style="height: 100vh">
    <div class="asideBox">
      <div class="logo">
        <img src="../../assets/apaaslog.png" alt />
      </div>
      <div class="asideTitle">
        <!--<div>携旅合作伙伴</div>-->
        <div>{{sysName}}</div>

      </div>
      <el-aside width="200px" class="aside">
        <el-col>
          <!-- -->

          <!-- { path: '/apaas/sysLabelHistory', component: sysLabelHistory }, //系统设置-标签记录
            { path: '/apaas/labelAdd', component: labelAdd }, //系统设置-打标签
            { path: '/apaas/labelSub', component: labelSub }, //系统设置-下架标签
            { path: '/apaas/sysLabel', component: labelList }, //系统设置-下架标签
       
            { path: '/apaas/HotelManNewList', component: HotelManNewList }, //新建酒店 -->
          <el-menu :default-active="menuiIndex" :unique-opened='false' class="el-menu-vertical-demo" background-color="#fff" text-color="rgba(0,0,0,0.6)" active-text-color="#78affd" :router='true'>
            <el-menu-item index="/apaas/HotelClassify" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">酒店分类</span>
            </el-menu-item>
            <el-menu-item index="/apaas/noDdvertisement" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">NO广告管理</span>
            </el-menu-item>
            <el-menu-item index="/apaas/SignManage" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">签名管理</span>
            </el-menu-item>

            <el-menu-item index="/apaas/spaceNotList" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">空间不足列表</span>
            </el-menu-item>
            <el-menu-item index="/apaas/savePlaceList" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">存储位置管理</span>
            </el-menu-item>
            <el-menu-item index="/apaas/MulSetting" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">批量设置</span>
            </el-menu-item>
            <el-menu-item index="/apaas/SettingManage" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">配置项管理</span>
            </el-menu-item>
            <el-menu-item index="/apaas/dataAssetList" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">数据资产列表 </span>
            </el-menu-item>
            <el-menu-item index="/apaas/authorizationRecordList" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">授权记录</span>
            </el-menu-item>
            <el-menu-item index="/apaas/HotelManNewList" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">新建酒店列表</span>
            </el-menu-item>
            <el-menu-item index="/apaas/sysLabelHistory" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">标签记录</span>
            </el-menu-item>
            <el-menu-item index="/apaas/sysLabel" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">标签管理</span>
            </el-menu-item>
            <el-menu-item index="/apaas/businessPerformanceSta" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">商务上线统计</span>
            </el-menu-item>
            <el-menu-item index="/apaas/PerformanceSta" @click="saveNavState('/indexShow', 1)">
              <i class="el-icon-menu" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">上线终端统计</span>
            </el-menu-item>

          </el-menu>
        </el-col>
      </el-aside>
    </div>
    <div class="contentBox">
      <router-view></router-view>
    </div>

  </el-container>
</template>
<script>
import qs from "querystring";

export default {
  data: () => ({
    optName: "1",
    menuiIndex: "/apaas/HotelClassify",
    funArr: [],

    sysName: "",
    logoUrl: "",
  }),

  mounted() {
    let str = sessionStorage.getItem("funArr");
    if (str.indexOf("[") != -1) {
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      this.funArr = str.split(",");
    }
  },
  methods: {
    saveNavState() {},
    // 动态获取 账号logo
  },
};
</script>

<style lang="scss" scoped>
.contentBox {
  height: 100%;
  width: calc(100% - 200px);
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
.aside {
  /*height:100vh;*/
  overflow: auto;
  background: #fff !important;
  flex: 1;
  border-right: 1px solid #eee;
}

.asideBox {
  width: 200px;
  background: #1a3563;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.asideTitle {
  // height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*padding-bottom:20px;*/
}
.asideTitle div:first-child {
  margin-bottom: 10px;
}
.asideTitle,
.asideTitle div {
  color: #fff;
  /*font-weight:600;*/
  font-size: 16px;
  text-align: center;
}
.logo {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  padding: 10px 0;

  img {
    /*width:60%;*/
    /*height:auto;*/
    height: 100%;
    // margin-right: 20px;
  }
}
.tabShow {
  margin-top: 140px;
  height: calc(100% - 146px);
  padding-top: 10px;
  box-sizing: border-box;
  overflow-y: visible;
}
.topInfo {
  width: calc(100% - 200px);
  height: 120px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 200px;
  background: #fff;
  z-index: 10;
  border-bottom: 2px solid #ddd;
  box-shadow: 0 4px 10px 2px rgba($color: #000000, $alpha: 0.1);
  .name {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .bottom {
    background: #fff;
    margin-top: 30px;
    .state {
      display: flex;
      justify-content: flex-start;
      color: #999;
      font-size: 14px;
      margin-bottom: 10px;
      div {
        width: 20%;
      }
      .point {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #78affd;
        margin-right: 10px;
      }
    }
  }
}
.state div {
  width: 50%;
  text-align: left;
}
.nameBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
