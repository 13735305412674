import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
    // 获取房间
export function roomManagement(data) {
    return request({
        url: `${vi}/Hotelrooms/roomManagement`,
        method: 'post',
        data,
    });
}
export function getRoomsDetail(data) {
    return request({
        url: `${vi}/Hotelrooms/getRoomDetail`,
        method: 'post',
        data,
    });
}
export function getRoomByPlatform(data) {
    return request({
        url: `${vi}/Hotelrooms/getRoomByPlatform`,
        method: 'post',
        data,
    });
}
export function exportRoomsNew(data) {
    return request({
        url: `v1/hotel/exportRoomsNew`,
        method: 'get',
        params: data,
        responseType: 'blob'
    });
}