<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">流程列表</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <div class="title chartTitle blueTitle">流程列表</div>
            <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "SysProcess",
        data:()=>({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAssignTest'},
            },
            table:{
                tableHeader:[
                    {prop:'hotel',label:'酒店名称',width:'280'},
                    {prop:'hotel_brand',label:'集团品牌',width:''},
                    {prop:'hotel_use_state',label:'客户终端状态',width:''},
                    {prop:'h_province',label:'省份',width:'110'},
                    {prop:'h_city',label:'城市',width:'110'},
                    {prop:'h_county',label:'区县',width:'110'},
                    {prop:'hotel_room',label:'客房总量（间）',width:''},
                    {prop:'n_connected',label:'激活终端数量（台）',width:''},
                    {prop:'addtime',label:'上线时间',width:'180'},
                ],
                tableList:[
                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'sysProcess',
                name:'sysProcess',
                option:true,
                select:true,
                loading:false,
            },
            token:''
        }),
        methods:{
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            tableMeth(orderVal){
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.table.loading=true;
                var param=qs.stringify({token:this.token,no_partner:1});
                this.$http.get(this.global.getFlowList,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        per_page:this.table.limit,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData;
                        // this.table.total=parseInt(tableData.total);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.tableMeth();
        }
    }
</script>

<style scoped>

</style>
