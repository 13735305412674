<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">下载中心</span>
        <span slot="third">应用下载</span>
      </top-bread>
    </div>
   
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">插播列表</div>
      <div class="btnRow">
        <el-button type="primary" class="largeBtn" @click="toCreate">获取最新应用</el-button>
      </div>

      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent">\
      
      </operationTable>
      <!-- 表格 -->

    </el-card>
   
  </div>

</template>
<script>


export default {
  name: "DownIndex",

  data() {
    return {
     
      bread: {
        level: 3,
      },
      signList: [],
      btnLoading: false,
    
      // 表格数据信息
      tableData: {
        tableList: [{}],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "版本号",
            prop: "name",
            minWidth: "280",
            type: "text",
          },
          {
            label: "生成日期",
            prop: "publish_status",
            minWidth: "100",
            type: "text",
            isSlot: true,
          },
          {
            label: "大小",
            prop: "nickname",
            minWidth: "120",
            type: "text",
          },
         
          {
            label: "操作",
            minWidth: "280",
            type: "button",
            prop: "num",
            // 详情
            log: (item) => {
            
            },
            down: (item) => {
           
            },
           
          },
        ],
      },
      // 已选项
      checkList: [],
    };
  },

  methods: {
  
   
    toCreate() {
      this.$router.push("/hotelMsgReleaseInfo");
    },
   
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },

    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p ? p : 1,
        size: s ? s : 10,
      };
      getSendMessage(json)
        .then((res) => {
          if (res.data.code == 100000) {
            console.log(res, "列表数据");
            this.tableData.tableList = res.data.data.data;
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {
    // this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
.el-form-item {
  margin-bottom: 0px;
}
</style>
