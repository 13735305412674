import request from "@/api/request";
import qs from "querystring";

// 包名类型列表
export function getList(param) {
  return request({
    url: `v2/batch_set/apkTypeManage`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 包名类型添加/修改
export function addEdit(param) {
  return request({
    url: `v2/batch_set/addApkType`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 包名类型删除
export function del(param) {
  return request({
    url: `v2/batch_set/delAllApkType`,
    method: "post",
    data: qs.stringify(param),
  });
}
