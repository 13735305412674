<template>
    <div class="main">
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">酒店管理</span>
                <span slot="third">酒店服务编辑</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card cardCenter" v-loading="loading">
            <back @backMeth="backMeth"></back>
            <upload-pic-sort v-if="flagShow" style="margin-top:30px;"
                             :filterDataProp="filterData" @loadingMeth="loadingMeth"
            :hotelContract="query.hotelContract"></upload-pic-sort>
        </el-card>
    </div>
</template>

<script>
    import Swiper from "swiper"
    import  qs from 'querystring'
    
    export default {
      
        name: "hotelManEditHotelSerivice",
        data:()=>({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAll'},
                thirdName:''
            },
            configData:{},
            noData:false,
            token:'',
            filterData:{},
            flagShow:false,
            loading:false,
        }),
        methods:{
            initSwiper(){
                var swiper = new Swiper('.swiper-container', {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            },
            getHotelConfigInfo(hotelContract) {
                return new Promise((resolve ,reject)=>{
                    var param = qs.stringify({
                        token: this.token,
                        hotelContract:hotelContract,
                    });
                    this.$http.post(this.global.getHotelCurrentVisualInfo, param).then(res => {
                        if (res.data.code === 100000) {
                            this.configData = res.data.data;
                        } else if (res.data.msg === '暂不支持') {
                            this.noData = true;
                        } else {
                            this.$message.error(res.data.msg);
                        }
                        return resolve();
                    }).catch(() => {
                        // debugger;
                    })
                })
            },
            filterDataMeth(){
                var pic={};
                if(this.query.serviceType==='serviceTwo'){
                    pic=JSON.parse(sessionStorage.getItem('hotelServiceTwo')).pic;
                }else if (this.query.serviceType==='serviceThree'){
                    pic=JSON.parse(sessionStorage.getItem('hotelServiceThree')).pic;
                }else{
                    pic=JSON.parse(sessionStorage.getItem('hotelService')).pic;
                }
                var that=this;
                Object.keys(pic).forEach(function(key){
                    if(pic[key].id==that.query.id){
                        that.filterData=pic[key];
                        that.flagShow=true;
                    }
                });
            },
            backMeth(){
                this.$router.push({path:`./${this.query.fromUrl}`,
                    query:{
                    fromUrl:'hotelManAll',
                        id:this.query.hotelId,
                        hotelContract:this.query.hotelContract,
                        type:'edit',
                        fromEditType:'hotelService',
                        serviceType:this.query.serviceType
                }})
            },
            loadingMeth(val){
                this.loading=val;
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.query=this.$route.query;
            this.initSwiper();
            this.getHotelConfigInfo(this.query.hotelContract).then(()=>{
                this.filterDataMeth();
            })
        }
    }
</script>

<style scoped>
    @import '../../../node_modules/swiper/swiper-bundle.css';
    @import '../../../node_modules/swiper/swiper-bundle.min.css';
</style>
