<template>
    <div>
        <el-form label-width="160px" :model="form" :rules="rules" ref="form">
            <el-col :span="24">
                <el-form-item prop="partnerType" label="合作类型">
                    <!--<el-input v-model="form.partnerType"></el-input>-->
                    <el-select  v-model="form.partnerType" placeholder="请选择" @change="typeChange" class="selectFull" clearable>
                        <el-option
                                v-for="item in selectData.partnerType"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  prop="partnerId" label="上级合作伙伴">
                    <!--<el-input v-model="form.partnerId"></el-input>-->
                    <el-select  v-model="form.partnerId" placeholder="请选择" clearable  class="selectFull">
                        <el-option
                                v-for="item in selectData.partnerId"
                                :key="item.id"
                                :label="item.company_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">保存</el-button>
                </el-form-item>
            </el-col>
        </el-form>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "Rate",
        props:['id'],
        data: () => ({
            form:{
                partnerType:'',
                partnerId:'',
            },
            selectData:{
                partnerType:[],
                partnerId:[],
            },
            rules:{
                partnerType: [
                    { required: true, message: '请选择合作伙伴性质', trigger: 'trigger' }
                ],
                partnerId: [
                    { required: true, message: '请输入上级合作伙伴', trigger: 'trigger' }
                ],
            }
        }),
        methods:{
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        this.submitMeth();
                    }else{
                        this.$message.warning('请选择合作伙伴');
                        return false;
                    }

                });
                // if(flag){
                // var param=qs.stringify(this.registerForm);
                // console.log('参数',param);
                // return false;
                // this.registerMeth(param);
                // }
            },
            getPartnerType(){
                this.$http.get(this.global.getPartnerTypes,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.partnerType=res.data.data;
                    }
                })
            },
            getPartner(type){
                this.form.partner_id='';
                // if(1){
                this.$http.get(this.global.getPartnersByType,{
                    params:{
                        token:this.token,
                        type:type
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.partnerId=res.data.data;
                        // this.topAttr.partner_id=res.data.data;
                    }
                })
                // }

            },
            typeChange(val){
                this.getPartner(val);
            },
            submitMeth(){
                var param=qs.stringify({
                    token:this.token,
                    id:this.id,
                    type:this.form.partnerType,
                    partner_id:this.form.partnerId,
                });
                this.$http.post(this.global.setPartnerTypeById,param).then(res=>{
                    var str='';
                    if(res.data.code===100000){
                        this.$message.success('保存成功');
                    }else{
                        this.$message.error('保存失败');
                    }
                }).catch(()=>{
                    this.$message('保存失败','error');
                })
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getPartnerType();
        },
        watch:{
            id(val){

            }
        }
    }
</script>

<style scoped lang="scss">
    .selectFull{
        width:100%;
    }

</style>
