<template>

  <div :class="['box',showData?'':'box1']" :style="{width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px'}">
    <visiualButton class="hide" @openmore='openUppage' @recoverydata="recoverydata" btnShow='picmore'></visiualButton>
    <div class="borderBox"></div>
    <div class="city_info" :style="{color:style.color?style.color:'#fff'}" v-if="!style.type">
      <div class="info_one info_one1">
        <div class="top"><img :src="showData.SSIDIcon.source_url" alt=""></div>
        <div class="bottom">{{showData.SSID}}</div>
      </div>
      <div class="info_one">
        <div class="top"><img :src="showData.passwordIcon.source_url" alt=""></div>
        <div class="bottom">{{showData.password}}</div>
      </div>
    </div>
    <div class="licheng" v-if="style.type==1 " :style="{color:style.color?style.color:'#fff'}">
        <div class="info_one info_one1">
        <div class="top"><img :src="showData.SSIDIcon.source_url" alt=""></div>
        <div class="bottom">{{showData.SSID}}</div>
      </div>
      <div class="info_one">
        <div class="top"><img :src="showData.passwordIcon.source_url" alt=""></div>
        <div class="bottom">{{showData.password}}</div>
      </div>
    </div>
    <el-dialog title="编辑WiFi信息" class="info" :visible.sync="infoVisible" width="40%" :append-to-body="true" :before-close='cannel'>
      <el-form ref="form" :model="form" :rules="rules" class="formBox">
        <el-form-item label="SSID" prop='SSID'>
          <el-input :readonly="isEdit" v-model="form.SSID"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop='password'>
          <el-input :readonly="isEdit" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="是否打开" prop='open'>
          <el-switch :disabled="isEdit" style="height:40px" v-model="form.open" active-color="#658BFF" inactive-color="rgba(191, 191, 191, 1)" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="是否拼接房间" prop='addRoom'>
          <el-switch :disabled="isEdit" style="height:40px" v-model="form.addRoom" active-color="#658BFF" inactive-color="rgba(191, 191, 191, 1)" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button class="largeBtn" type="primary" @click="save('form')">保 存</el-button>
        <el-button class="cancelBtn" @click="cannel">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";

export default {
  components: {
    visiualButton,
  },
  name: "wifiBox",
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
          isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        let obj = JSON.parse(JSON.stringify(newVal));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      showData: this.value,
      infoVisible: false,
      form: {
        open: true,
        addRoom: true,
        SSID: "183",
        password: "0",
      },
      rules: {
        SSID: [{ required: true, message: "请输入SSID", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.form = {
      open: String(this.showData.open),
      addRoom: String(this.showData.addRoom),
      SSID: this.showData.SSID,
      password: this.showData.password,
    };
  },
  methods: {
    cannel() {
      this.form.open = this.showData.open;
      this.form.addRoom = this.showData.addRoom;
      this.form.SSID = this.showData.SSID;
      this.form.password = this.showData.password;
      this.showData.update = false;
      this.infoVisible = false;
    },
    save() {
      this.$refs["form"].validate((valid, obj) => {
        if (valid) {
          this.showData.open = this.form.open;
          this.showData.addRoom = this.form.addRoom;
          this.showData.SSID = this.form.SSID;
          this.showData.password = this.form.password;
          this.showData.update = true;
          this.infoVisible = false;
          this.$emit("change", this.showData);
          this.$emit("canrelease");
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    openUppage() {
      this.infoVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 3;

  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.city_info {
  display: flex;

  flex-direction: column;
  /*background:#80cbc4;*/
}
.city_info div {
  color: inherit;
  letter-spacing: 1px;
}
.info_one {
  height: 100%;

  /*background:#81d4fa;*/
  padding-right: 7px;
  display: flex;
  flex-direction: row;
}
.info_one .top {
  /*font-weight:600;*/
  font-size: 18px;
  letter-spacing: 2px;
  margin-right: 4px;
}
.info_one .top img {
  max-width: 28px;
  max-height: 28px;
}
.info_one1 {
  margin-bottom: 14px;
}
.info_one .bottom {
  font-size: 20px;
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}
.hide {
  display: none;
}
.licheng{
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 18px;
  justify-content: flex-end;
  align-items: center;
  .info_one{
    display: flex;
    height: 100%;
    justify-content: flex-end;
      align-items: center;
  }
  .info_one1{
    margin-bottom: 0;
    margin-right: 21px;
  }

}
</style>