import request from './request';

//  上传汇款单
export function uploadWithdrawPic(param) {
    return request({
        url: `v1/partnerwithdraw/updateInfo`,
        method: 'post',
        data: param,
    });
}
//  修改汇款状态
export function updatePayAll(param) {
    return request({
        url: `v1/partnerwithdraw/updateAll`,
        method: 'post',
        data: param,
    });
}
//  提现审核
export function withdrawPassOrNo(param) {
    return request({
        url: `v1/partnerwithdraw/checkStatus`,
        method: 'post',
        data: param,
    });
}
//  查询扣款列表详情
export function getDeductedList(param) {
    return request({
        url: `v1/partnerwithdraw/deductedList?` + param,
        method: 'get',

    });
}
//  查询对账单账单驳回信息  v1/reconciliation/getReconciliationDetailByContractAndDate
export function getNoPassMessage(param) {
    return request({
        url: `v1/reconciliation/getPreReconciliationMessage`,
        method: 'post',
        data: param,
    });
}
//  查询对账单账单详细信息  
export function getReconciliationDetailByContractAndDate(param) {
    return request({
        url: `v1/reconciliation/getReconciliationDetailByContractAndDate`,
        method: 'post',
        data: param,
    });
}
//  选择预对账酒店记录流水号

export function InitiateReconciliation(param) {
    return request({
        url: `v1/reconciliation/generateReconciliation`,
        method: 'post',
        data: param,
    });
}
// 获取对账记录列表

export function getReconciliationList(param) {
    return request({
        url: `v1/reconciliation/getReconciliationList`,
        method: 'post',
        data: param,
    });
}

// 获取对账预览列表    
export function getReconciliationPreview(param) {
    return request({
        url: `v1/reconciliation/getPreviewReconciliation`,
        method: 'post',
        data: param,
    });
}
// 获取合作伙伴各收益方式汇总数据   

export function getCheckAmountByPartner(param) {
    return request({
        url: `v1/reconciliation/getCheckAmountByPartner`,
        method: 'post',
        data: param,
    });
}
// 获取合作伙伴对账单列表 

export function getPartnerReconciliationList(param) {
    return request({
        url: `v1/reconciliation/getPartnerReconciliationList`,
        method: 'post',
        data: param,
    });
}
// 根据对账单流水号获取对账酒店列表

export function getReconciliationHotelList(param) {
    return request({
        url: `v1/reconciliation/getReconciliationHotelList`,
        method: 'post',
        data: param,
    });
}

// 判断是否当前账号本月是否发起对账单
export function hasReconciliation(param) {
    return request({
        url: `v1/reconciliation/checkReconciliationExistMonth`,
        method: 'post',
        data: param,
    });
}
// 根据流水发起审核工作
export function sponsorReconciliationFlow(param) {
    return request({
        url: `v1/reconciliation/sponsorReconciliationFlow`,
        method: 'post',
        data: param,
    });
}
// 根据待办id获取对账单详情

export function getReconciliationContengByID(param) {
    return request({
        url: `v1/review/view?` + param,
        method: 'GET',
        // data: param,
    });
}
// 对账单审核接口

export function reconciliationAction(param) {
    return request({
        url: `v1/review/reconciliationAction`,
        method: 'POST',
        data: param,
    });
}
//  字典表列表  tab类别  
export function getTabList(param) {
    return request({
        url: `v1/sysdic/list?parent_id=001`,
        method: 'GET',
    });
}



//  提现统计数据汇总
export function getStatisticalAll(param) {
    return request({
        url: `v1/partnerwithdraw/statisticalAll`,
        method: 'post',
        data: param,
    });
}
//  提现统计  管理合伙人提现列表获取
export function getpartnerListAll(param) {
    return request({
        url: `v1/partnerwithdraw/partnerList`,
        method: 'post',
        data: param,
    });
}
//  提现统计  扣款操作
export function createDeducted(param) {
    return request({
        url: `v1/partnerwithdraw/createDeducted`,
        method: 'post',
        data: param,
    });
}
// 获取扣款详情通过data
export function getDeductedEventList(param) {
    return request({
        url: `v1/partnerwithdraw/dateToDeductedList?` + param,
        method: 'GET',
        // data: param,
    });
}
// 获取提现申请通过data
export function getPartnerWithdrawEventList(param) {
    return request({
        url: `v1/partnerwithdraw/dateToPartnerWithdraw?` + param,
        method: 'GET',
        // data: param,
    });
}

// 获取渠道商下全部酒店id
export function getAllIds(param) {
    return request({
        url: `v1/reconciliation/getAllIds`,
        method: 'post',
        data: param,
    });
}