<template>
    <div class="all">
        <div class="btnStyle" v-if="this.msg!=='drawer'" @click="showBtn">配置</div>
        <div class="btnStyle  hideBtn" v-if="hidden || this.msg=='drawer'" @click="openEditPage">{{msg=='drawer'?'上传':'编辑'}}</div>
        <div class="btnStyle hideBtn" v-if="hidden && this.msg!=='drawer'"  @click="recoveryData">恢复</div>
    </div>
</template>
<script>
import bus from "../bus";
export default {
    props:{
        hidden:{
            type:Boolean,
            default:()=>false
        },
        msg:{
            type:String,
            default:()=>''
        },
        upType:{
             type:String,
            default:()=>''
        },
        itemName:{
             type:String,
            default:()=>''
        }
    },
    data(){
        return{
        }
    },   
    methods:{
        showBtn(){ 
            this.$emit('ishidden',this.msg)  
        },
        openEditPage(){
            if(this.itemName==""){
                if (this.msg=='drawer') {
                    console.log(111);
                     bus.$emit("openInDrawer",this.upType,this.msg,this.itemName)
                }else{
                     bus.$emit("open",this.upType,this.msg)
                }
               
            }else{
                console.log(this.msg);
                 bus.$emit("opendrawer",this.itemName,this.msg)
            }
        },
        recoveryData(){
            this.$emit("recoverydata",this.msg)
        }
    } 
}
</script>
<style scoped>
.all{
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.btnStyle{
    height: 100%;
    width: 33%;
    background: rgba(0, 0, 0, .4);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  font-size: 0.8vw;
    cursor: pointer;
    transition: .3s;
}
.btnStyle:hover{
    /* background: #4093ff; */
     background: rgba(60, 138, 255, .8);
}
.hideBtn{
    border-left: 1px solid #fff;
}
</style>
    
