<template>
  <div>
    <!-- 展示面板 -->
    <el-card class="box-card">
      <div class="title blueTitle">分类列表</div>
      <div class="typeCardBox">
        <div v-if="showFunModel('标签分类新建')" class="typeCard createBtn" @click="createType"> <i class="el-icon-plus" style="margin-right:5px"></i>新建</div>
        <div :class="[showFunModel('标签分类删除')?'typeCard':'typeCard1' ,'showCard']" @click="editType(item)" v-for="(item,index) in list" :key="index">
          {{item.classify_name}}
          <span v-if="showFunModel('标签分类删除')" class="delBtn"><span></span><i class="el-icon-close" @click.stop="delLabelType(item)"></i></span>
          <!-- <i class="el-icon-close" ></i> -->
        </div>
      </div>
    </el-card>
    <!-- 展示面板 -->
    <!-- 编辑and新建 -->
    <el-dialog title="分类名称" :visible.sync="typeVisible" width="30%" :before-close="handleClose">
      <el-form ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="分类名称" style="margin:0">
          <el-input v-model="typeName" placeholder="请填写分类名称（限6个字）" maxlength="6"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="trueCreate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑and新建 -->
  </div>
</template>
<script>
import qs from "querystring";
import { setLabelClassify, getClassifyList, delClassify } from "@/api/label.js";
export default {
  name: "labelList",
  props: ["funArr"],
  data() {
    return {
      auth: null,
      list: [],
      table: {
        page: 1,
        limit: 9999,
      },
      typeName: "",
      typeId: "",
      typeVisible: false,
      delId: "",
    };
  },
  watch: {
    funArr: {
      handler: function (newVal) {
        this.funArr = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // 获取界面数据
    this.getList();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
  computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    // 操作函数封装
    setLbelType(msg) {
      this.$confirm("是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (msg == "分类删除") {
            let json = {
              id: this.delId,
              user_name: this.auth.user_name,
            };
            var param = qs.stringify(json);
            delClassify(param)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success(msg + "成功");
                  this.delId = "";
                  this.getList();
                  this.$emit("loadclassdata");
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch((err) => {});
          } else {
            let json = {
              classify_name: this.typeName,
              id: this.typeId,
              user_name: this.auth.user_name,
            };
            var param = qs.stringify(json);
            setLabelClassify(param)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success(msg + "成功");
                  this.typeVisible = false;
                  this.getList();
                  this.$emit("loadclassdata");
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch((err) => {});
          }
        })
        .catch(() => {});
    },
    // 确认编辑或者新建
    trueCreate() {
      if (this.typeName == "") {
        this.$message.warning("请填写分类名称");
      } else if (this.typeName.length > 6) {
        this.$message.warning("分类名称不得大于六个字");
      } else {
        this.setLbelType("分类操作");
      }
    },
    // 删除
    delLabelType(item) {
      this.delId = item.id;
      this.setLbelType("分类删除");
    },
    // 打开编辑框
    editType(item) {
      if (this.showFunModel("标签分类编辑")) {
        this.typeName = item.classify_name;
        this.typeId = item.id;
        this.typeVisible = true;
      } else {
        return;
      }
    },
    // 打开新建框
    createType() {
      this.typeVisible = true;
      this.typeId = "";
      this.typeName = "";
    },
    // 关闭弹窗
    handleClose() {
      this.typeVisible = false;
      this.typeName = "";
      this.typeId = "";
    },
    // 获取初始数据
    getList() {
      let json = {
        page: this.table.page,
        limit: this.table.limit,
      };
      let param = qs.stringify(json);
      getClassifyList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.list = res.data.data.data;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.typeCardBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 0;
}
.typeCard {
  width: calc(100% / 6 - 1%);
  text-align: center;
  padding: 8px 50px 8px 10px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #409eff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  background: #f1f8ff;
  color: #409eff;
  font-size: 14px;
}
.typeCard1 {
  width: calc(100% / 6 - 1%);
  text-align: center;
  padding: 8px 10px 8px 10px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #409eff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  background: #f1f8ff;
  color: #409eff;
  font-size: 14px;
}
.createBtn {
  padding: 8px 10px 8px 10px;
  border: 1px solid #409eff;
  background: #fff;
  color: #409eff;
}
.delBtn {
  position: absolute;
  top: 0;
  right: 10px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delBtn span {
  width: 1px;
  height: 18px;
  background: #3688f8;
}
.el-icon-close {
  margin-left: 3px;
  font-size: 18px;
}
</style>