<template>
    <div>
        <fold-top-search @fold="getFoldMeth" class="absoluteFoldBox"></fold-top-search>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :allLoadingState="allLoadingState" :fromUrl="fromUrl"
                        :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
        </el-card>
        <screen-type :allLoadingState="allLoadingState" :pageInfo="pageInfo" :setSessionDataProp="false"
                     :showPartProp="showPart" @screenTypeSearch="screenTypeSearch" dateCurProp="yesDay">
        </screen-type>
        <el-card class="box-card searchCard" v-loading="showLoading">
            <div class="title blueTitle">数据概览</div>
            <el-row class="rowContent" v-loading="pageDataLoading">
                <div :span='6' class="showCard">
                    <p style="font-size:14px;margin-bottom:20px;">新建酒店</p>
                    <div class="NumberShow">
                        <div class="Number">{{(parseFloat(pageData.hotel_num_total))}}<span
                                style="font-size:14px;color:#a3a7af"> 家</span></div>
                    </div>
                </div>
                <div :span='6' class="showCard">
                    <p style="font-size:14px;margin-bottom:20px;">客房数量</p>
                    <div class="NumberShow">
                        <div class="Number">{{(parseFloat(pageData.room_num_total))}}<span
                                style="font-size:14px;color:#a3a7af"> 间</span></div>
                    </div>
                </div>
                <div :span='6' class="showCard">
                    <p style="font-size:14px;margin-bottom:20px;">激活终端数量</p>
                    <div class="NumberShow">
                        <div class="Number">{{(parseFloat(pageData.active_terminal_num_total))}}<span
                                style="font-size:14px;color:#a3a7af"> 台</span></div>
                    </div>
                </div>
            </el-row>
            <el-row class="rowContent" v-loading="pageDataLoading">
                <div :span='6' class="showCard">
                    <p style="font-size:14px;margin-bottom:20px;">订单数量</p>
                    <div class="NumberShow">
                        <div class="Number">{{(parseFloat(pageData.watch_film_num_total))}}<span
                                style="font-size:14px;color:#a3a7af"> 单</span></div>
                        <div class="typeOther" style="font-size:12px">
                            <p>【单点】<span>{{pageData.watch_film_num_mcu}}单</span></p>
                            <p>【天卡】<span>{{pageData.watch_film_num_day_card}}单</span></p>
                        </div>
                    </div>
                </div>
                <div :span='6' class="showCard">
                    <p style="font-size:14px;margin-bottom:20px;">订单金额</p>
                    <div class="NumberShow">
                        <div class="Number">{{showDataCom(parseFloat(pageData.movie_income_total_total),'money')}}<span
                                style="font-size:14px;color:#a3a7af"> 元</span></div>
                        <div class="typeOther" style="font-size:12px">
                            <p>【单点】<span>{{showDataCom(pageData.movie_income_mcu_total,'money')}} 元</span></p>
                            <p>【天卡】<span>{{showDataCom(pageData.movie_income_day_card_total,'money')}} 元</span></p>
                        </div>
                    </div>
                </div>
                <div :span='6' class="showCard">
                    <p style="font-size:14px;margin-bottom:20px;">分成收益</p>
                    <div class="NumberShow">
                        <div class="Number">{{showDataCom(parseFloat(pageData.movie_income_total),'money')}}<span
                                style="font-size:14px;color:#a3a7af"> 元</span></div>
                        <div class="typeOther" style="font-size:12px">
                            <p>【单点】<span>{{showDataCom(pageData.movie_income_mcu,'money')}} 元</span></p>
                            <p>【天卡】<span>{{showDataCom(pageData.movie_income_day_card,'money')}} 元</span></p>
                        </div>
                    </div>
                </div>
            </el-row>
        </el-card>
        <el-card class="box-card searchCard">
            <div class="title blueTitle">
                <span>订单详情</span>
            </div>
            <table-list
                    :table="table"
                    @showUploadDialog="showUploadDialog"
                    @tableMeth="tableMeth"
                    v-loading="table.loading">
            </table-list>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
        <el-drawer
                :visible.sync="showUpload"
                size="1000px"
                title="详情">
            <div style="padding-left:20px;padding-right: 20px">
                <el-table :data="movieIncome" :header-cell-style="headerStyle">
                    <el-table-column label="时间" prop="orderDate" show-overflow-tooltip></el-table-column>
                    <el-table-column label="房间号" prop="orderRoom" show-overflow-tooltip width="100"></el-table-column>
                    <el-table-column label="影片名称" prop="videoName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="影片价格（元）" prop="orderAmount" show-overflow-tooltip></el-table-column>
                    <el-table-column label="分成比例" prop="calculateProportion" show-overflow-tooltip></el-table-column>
                    <el-table-column label="收益金额（元）" prop="calculateAmount" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import qs from 'querystring'
    import GLOBAL from '../../../api/globalVar.js'
    import commonMeth from '../../../api/method'

    export default {
        name: "movie",
        props: ["tabType"],
        data: () => ({
            nowYears: '',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            bread: {
                level: 3,
            },
            searchTime: [],
            keyWord: "",
            tableList: [],
            table: {
                name: 'historyIncome',
                tableHeader: [],
                tableList:[],
                page: 1,
                limit: 5,   //当前每页显示多少条
                total: 0,
                fromUrl: 'historyIncome',
                option: true,
                loading: true,
                darText: '日活率（%）',
            },
            pageDataLoading: false,
            tableDataLoading: false,
            pageData: {
                //酒店数
                hotel_num_total: 0,
                //客房数
                room_num_total: 0,
                //激活终端数
                active_terminal_num_total: 0,
                //订单数
                watch_film_num_total: 0,
                watch_film_num_mcu: 0,
                watch_film_num_day_card: 0,
                //订单金额
                movie_income_total_total: 0,
                movie_income_mcu_total: 0,
                movie_income_day_card_total: 0,
                //分成收益
                movie_income_total: 0,
                movie_income_mcu: 0,
                movie_income_day_card: 0,
            },
            tableName: "订单详情",
            downloadFile: false,
            headerStyle: GLOBAL.tableHeaderStyle,
            pageInfo: {
                hotelState: 'incomesta',
            },
            getIndexHeader: {
                accountId: "",
                accountType: '',
                partnerType: '',
            },
            type: 'timeCenter',
            partType: 'setAccount',
            showData: {},
            condition: {
                startTime: '',
                endTime: '',
                province: '',
                city: '',
                country: '',
                hotelBrand: '',
                accountManagerId: '',
                incomeSource: '',
            },
            topSearchShow: {},
            token: '',
            auth: {},
            auth_type: '',
            showLoading: true,
            lineLoading: false,
            fold: {
                showSearch: false,
                foldText: '展开筛选项'
            },
            screenType: '',
            curDate: {
                yesDay: '',
                curDay: '',
                startWeek: '',
                endWeek: '',
                startMonth: '',
                endMonth: '',
                startYear: '',
                endYear: '',
            },
            fromUrl: 'inTabMovie',
            belongPartnerCodeAllStr: '',
            allotPartnerCodeAllStr: '',
            allLoadingState: false,
            showPart: {},
            reqUrl: {
                table: '',
                data: '',
                line: '',
            },
            movieIncome: {},
            showUpload: false,
            year: '',
            showHistory: false,
            showNow: true,
            statusLoading:false,
        }),
        methods: {
            showUploadDialog(val) {
                this.query = val;
                this.$http.get(this.global.getMovieIncomeHistoryInfoByContract, {
                    params: {
                        token: this.token,
                        hotelContract: this.query.hotel_contract,
                        incomeSource: this.screenType,
                        year:this.year,
                    },
                }).then((res) => {
                    if (res.data.code === 100000) {
                        this.movieIncome = res.data.data
                        this.showUpload = true
                    }
                });
            },
            handleSizeChange(newSize) {
                this.queryInfo.pageSize = newSize;
            },
            handleCurrentChange(newPage) {
                this.queryInfo.currentPage = newPage;
            },
            tableMeth(orderVal) {
                var {startTime, endTime, incomeSource} = this.condition;
                this.table.incomeSource = this.screenType;
                this.table.loading = true;
                var sortStr = '';
                var orderStr = '';
                var url = '';
                if (orderVal) {
                    if (orderVal.prop.indexOf('_text') !== -1) {
                        var index = orderVal.prop.indexOf('_text');
                        sortStr = orderVal.prop.substring(0, index);
                    } else {
                        sortStr = orderVal.prop;
                    }
                    orderVal.order === 'ascending' ? orderStr = 'ASC' : orderStr = 'DESC';
                } else {
                    sortStr = 'id';
                    orderStr = 'DESC';
                }
                var params = {
                    token: this.token,
                    page: this.table.page,
                    limit: this.table.limit,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                    sort: sortStr,
                    order: orderStr,
                    screenType: this.screenType,
                    refresh: false,
                     year: this.year,
                };
                params.screenType = this.screenType
                if (params.belongPartnerCode == '' && params.allotPartnerCode == '') {
                } else if (params.belongPartnerCode !== '') {
                    params.partner_id = params.belongPartnerCode
                } else if (params.allotPartnerCode !== '') {
                    params.partner_id = params.allotPartnerCode
                }
                this.$http.post(this.global.getMovieIncomeListHistory, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        this.table.total = tableData.total;
                    }
                    this.table.loading = false;
                }).catch(() => {
                    this.table.loading = false;
                })
            },
            tableMethfrist(orderVal) {
                var {startTime, endTime, incomeSource} = this.condition;
                if (startTime === '' && endTime === '') {
                    this.table.startTime = this.curDate.yesDay;
                    this.table.endTime = this.curDate.yesDay;
                } else {
                    this.table.startTime = startTime;
                    this.table.endTime = endTime;
                }
                this.table.incomeSource = this.screenType;
                this.table.loading = true;
                var sortStr = '';
                var orderStr = '';
                var url = '';
                if (orderVal) {
                    if (orderVal.prop.indexOf('_text') !== -1) {
                        var index = orderVal.prop.indexOf('_text');
                        sortStr = orderVal.prop.substring(0, index);
                    } else {
                        sortStr = orderVal.prop;
                    }
                    orderVal.order === 'ascending' ? orderStr = 'ASC' : orderStr = 'DESC';
                } else {
                    sortStr = 'id';
                    orderStr = 'DESC';
                }
                var params = {
                    token: this.token,
                    page: this.table.page,
                    limit: this.table.limit,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                    sort: sortStr,
                    order: orderStr,
                    screenType: this.screenType,
                    refresh: true,
                    year: this.year,
                };
                params.screenType = this.screenType
                if (params.belongPartnerCode == '' && params.allotPartnerCode == '') {
                } else if (params.belongPartnerCode !== '') {
                    params.partner_id = params.belongPartnerCode
                } else if (params.allotPartnerCode !== '') {
                    params.partner_id = params.allotPartnerCode
                }
                this.$http.post(this.global.getMovieIncomeListHistory, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.items;
                        console.log()
                        this.table.total = tableData.total;
                    }
                    this.table.loading = false;
                }).catch(() => {
                    this.table.loading = false;
                })
            },
            showDataMeth() {
                this.showLoading = true;
                var params = {
                    token: this.token,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                    screenType: this.screenType,
                    refresh: false,
                    year: this.year,
                };
                if (params.belongPartnerCode == '' && params.allotPartnerCode == '') {
                } else if (params.belongPartnerCode !== '') {
                    params.partner_id = params.belongPartnerCode;
                } else if (params.allotPartnerCode !== '') {
                    params.partner_id = params.allotPartnerCode;
                }
                this.$http.post(this.global.getMovieIncomeStatisticsHistory, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.pageData = res.data.data;
                    }
                    this.showLoading = false;
                }).catch(() => {
                    this.showLoading = false;
                })
            },
            showDataMethfrist() {
                this.showLoading = true;
                var params = {
                    token: this.token,
                    hotelBrand: this.condition.hotel_brand,
                    citycode: this.condition.citycode,
                    province: this.condition.provinceLabel,
                    city: this.condition.cityLabel,
                    county: this.condition.countyLabel,
                    accountManagerId: this.condition.BD,
                    partner_id: this.condition.partner_id,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    keyword: this.condition.keyword,
                    screenType: this.screenType,
                    refresh: true,
                    year: this.year,
                };
                if (params.belongPartnerCode == '' && params.allotPartnerCode == '') {
                } else if (params.belongPartnerCode !== '') {
                    params.partner_id = params.belongPartnerCode;
                } else if (params.allotPartnerCode !== '') {
                    params.partner_id = params.allotPartnerCode;
                }
                this.$http.post(this.global.getMovieIncomeStatisticsHistory, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.pageData = res.data.data;
                    }
                    this.showLoading = false;
                }).catch(() => {
                    this.showLoading = false;
                })
            },
            getTopSearchShow(type) {
                var auth = JSON.parse(sessionStorage.getItem('auth'));
                var auth_type = auth.type;
                if (auth_type === 'channel' || auth_type === 'screen' || auth_type === 'factory') {
                    this.topSearchShow = {
                        hotel_brand: true,
                        province: true,
                        city: true,
                        country: true,
                        coopType: true,
                        coopPartner: true,
                        BD: true,
                        keyword: true,
                        coopPartnerType: true,
                    }
                } else if (auth_type === 'group') {
                    this.topSearchShow = {
                        province: true,
                        city: true,
                        country: true,
                        coopType: true,
                        coopPartner: true,
                        keyword: true,
                    }
                } else if (auth_type === 'htrip') {
                    this.topSearchShow = {
                        hotel_brand: true,
                        province: true,
                        city: true,
                        country: true,
                        coopType: true,
                        coopPartner: true,
                        BD: true,
                        coopPartnerType: true,
                    }
                }
                this.topSearchShow.keywordPlaceholder = '请输入酒店名称关键词';
            },
            getScreenTypeSearchShow() {
                var param = qs.stringify({
                    token: this.token,
                });
                this.$http.post(this.global.getIndexHeader, param).then((res) => {
                    var code = res.data.code;
                    if (code === 100000) {
                        this.getIndexHeader.accountType = res.data.data.accountType
                    } else {
                    }
                    var date = new Date;
                    var nowYears = date.getFullYear()
                    switch (this.getIndexHeader.accountType) {
                        case 1:
                            this.showPart = {
                                historybtn: true,
                                historyselect: true,
                            }
                            this.screenType = 'self'
                            break
                        case 2:
                            this.showPart = {
                                reqUrl: true,
                                historybtn: true,
                                historyselect: true,
                            }
                            this.screenType = 'self'
                            break
                        case 3:
                            this.screenType = ''
                            break
                        case 4:
                            this.screenType = ''
                            break
                        case 5:
                            this.showPart = {
                                reqUrl: true,
                                belong: true,
                                subordinate: true,
                                historybtn: true,
                                historyselect: true,
                            }
                            this.screenType = 'belong'
                            break
                        case 6:
                            this.showPart = {
                                reqUrl: true,
                                allot: true,
                                belong: true,
                                subordinate: true,
                                historybtn: true,
                                historyselect: true,
                            }
                            this.screenType = 'belong'
                            break
                    }
                });
            },
            getCondition(val) {
                this.condition.hotel_brand = Array.isArray(val.hotel_brand) ? val.hotel_brand.join(',') : '';
                this.condition.hotel_use_state = Array.isArray(val.hotel_use_state) ? val.hotel_use_state.join(',') : '';
                this.condition.BD = Array.isArray(val.BD) ? val.BD.join(',') : '';
                this.condition.partner_id = Array.isArray(val.partner_id) ? val.partner_id.join(',') : '';
                this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode) ? val.belongPartnerCode.join(',') : '';
                this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode) ? val.allotPartnerCode.join(',') : '';
                if (typeof val.citycode === 'string') {
                    this.condition.citycode = val.citycode;
                } else {
                    this.condition.citycode = Array.isArray(val.citycode) ? val.citycode.join(',') : ''; //citycode
                }
                this.condition.provinceLabel = Array.isArray(val.provinceLabel) ? val.provinceLabel.join(',') : ''; //provinceLabel
                this.condition.cityLabel = val.cityLabel;
                this.condition.countyLabel = val.countyLabel;
                this.condition.keyword = val.keyword;
                this.table.darText = commonMeth.setTableDarText(this.condition.startTime, this.condition.endTime);
                this.table.page = 1;
                this.tableMeth('');
                this.showDataMeth();
            },
            getFoldMeth(val) {
                this.fold = val;
            },
            getBelongPartnerOptions() {   //所属
                this.$http.get(this.global.getBelongPartnerOptions, {
                    params: {
                        token: this.token
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var finalArr = [];
                        res.data.data.forEach((item, index) => {
                            finalArr.push(item.code);
                        });
                        this.belongPartnerCodeAllStr = finalArr.join(',');
                    }
                });
            },
            getAllotPartnerOptions() {   //归属
                this.$http.get(this.global.getAllotPartnerOptions, {
                    params: {
                        token: this.token
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var finalArr = [];
                        res.data.data.forEach((item, index) => {
                            finalArr.push(item.code);
                        });
                        this.allotPartnerCodeAllStr = finalArr.join(',');
                    }
                });
            },
            screenTypeSearch(screenType, startTime, endTime, belongCur, year) {
                this.screenType = screenType;
                var date = new Date;
                this.nowYears = date.getFullYear()
                this.year = year;
                //选择框和按钮进来的数据类型不一样
                this.condition.endTime = endTime;
                this.table.page = 1;
                this.table.limit = 5;
                this.getTopSearchShow()
                this.tableMeth();
                this.showDataMeth();
                if (typeof (this.year) == "object") {
                    //按钮
                    if (this.year[0] !== this.nowYears) {
                        this.year = year[0];
                    } else {
                    }
                } else if (typeof (this.year) == "number") {
                    this.year = year;
                    //选择框
                    if (this.year == this.nowYears) {
                    } else {
                    }
                }
                console.log(this.year)
            },
            getYear() {
                var date = new Date;
                this.year = date.getFullYear() - 1
            },
        },
        computed: {
            showDataCom() {
                return function (s, type = '', n = 2) {
                    if (type === 'money') {
                        if (s === null) {
                            return 0;
                        } else {
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    } else {
                        if (s === null) {
                            return 0;
                        } else {
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            twoPointCom() {
                return function (x) {
                    !x ? x = 0 : '';
                    var f = parseFloat(x);
                    if (isNaN(f)) {
                        return false;
                    }
                    var f = Math.round(x * 100) / 100;
                    var s = f.toString();
                    var rs = s.indexOf('.');
                    if (rs < 0) {
                        rs = s.length;
                        s += '.';
                    }
                    while (s.length <= rs + 2) {
                        s += '0';
                    }
                    return s;
                }
            },
            allLoadingStateCom() {
                this.allLoadingState = commonMeth.getAllLoading(this.table.loading, this.lineLoading, this.showLoading);
                this.allLoadingState = commonMeth.getAllLoading(this.table.loading, this.lineLoading, this.showLoading);
            },
        },
        mounted() {
            this.token = sessionStorage.getItem('token');
            this.auth = JSON.parse(sessionStorage.getItem('auth'));
            this.auth_type = this.auth.type;
            this.getYear();
            this.showDataMethfrist();
            this.tableMethfrist();
            this.getTopSearchShow();
            this.getScreenTypeSearchShow();
            this.getBelongPartnerOptions();
            this.getAllotPartnerOptions();

        }
    }
</script>
<style lang="scss" scoped>
    .el-button {
        width: 100px;
        height: 40px;
        margin-left: 20px;
    }

    .searchBox ::v-deep .el-input__inner {
        border: 1px solid #2e63a6 !important;
        color: #2e63a6 !important;
    }

    .reset {
        display: inline-block;
        border: 1px solid #2e63a6;
        width: 100px;
        height: 40px;
        margin-left: 20px;
        background: #fff;
        border-radius: 5px;
        text-align: center;
        color: #2e63a6;
        line-height: 40px;
        cursor: pointer;
        box-sizing: border-box;
    }

    .outTopBox {
        margin-top: 10px;
    }

    .searchBox {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
    }

    .rowContent {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        padding: 0 0px;
    }

    .showCard {
        flex: 1;
        margin-right: 10px;
        color: #a3a7af;
        margin-top: 20px;
        border-radius: 4px;
        padding: 20px !important;
        box-shadow: 0 0 11px 2px rgb(94, 151, 255, 0.15);
        box-sizing: border-box !important;
    }

    .showCard:last-child {
        margin-right: 0;
    }

    .NumberShow {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .NumberShow div {
        flex: 1;
    }

    .typeOther {
        display: flex;
        flex-direction: column;
    }

    .typeOther p {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0 0 0;
        font-family: DIN !important;
    }

    .Number {
        font-family: DIN !important;
        color: #333;
        font-size: 28px;
    }

    .blueTitle {
        margin-top: 20px;
    }

    .btnForList {
        position: absolute;
        right: 20px;
        top: 10px;
    }

    .tableBox {
        margin-top: 30px;
    }

    ::v-deep .el-table th.is-leaf {
        border-top: 1px solid #ebeef5 !important;
        box-sizing: border-box;
        border-bottom: none !important;
    }

    ::v-deep .el-table__footer-wrapper,
    ::v-deep .el-table__header-wrapper {
        border-radius: 4px !important;

        border: 1px solid #ebeef5 !important;
        border-top: none !important;
    }
    ::v-deep .el-drawer__body {
        overflow: scroll;
        padding: 15px 20px 0;
    }

    ::v-deep .el-drawer__body::-webkit-scrollbar {
        height: 10px;
    }

</style>
