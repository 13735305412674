<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <!--<span slot="second">首页</span>-->
                <span slot="second">待办事项</span>
                <span slot="third">详情</span>
                <span slot="fourth">房间列表</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <all-tab-online :id="query.id" v-if="childShow.flag" :showData="false"></all-tab-online>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "indexDoRoom",
        data:()=>({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                secondPath:{path: '/indexDoMore'},
                thirdPath:{path: '/indexDoBrush'},
                fourthPath:{path: '/hotelManAll'},
            },
            query:{
                id:''
            },
            childShow:{
                flag:true
            },
            token:''
        }),
        methods:{
            getRoomReceive(){
                // var param
                var param=qs.stringify({token:this.token,receiving_number:this.query.receiving_number});
                this.$http.post(this.global.getRootDetailByReceiving,param).then(res=>{

                    // if(res.data.code===100000){
                    //     var roomData=res.data.data;
                    //
                    // }
                })
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            var query=this.$route.query;
            this.query.id=query.id;
            this.query.receiving_number=query.receiving_number;
            this.getRoomReceive();
        }
    }
</script>

<style scoped>

</style>
