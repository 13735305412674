import request from './request';
import qs from "querystring";
//   设置标签分类  
export function setLabelClassify(param) {
    return request({
        url: `v1/label/classifySetting`,
        method: 'post',
        data: param,
    });
}
//   获取标签分类列表 
export function getClassifyList(param) {
    return request({
        url: `v1/label/classifyList`,
        method: 'post',
        data: param,
    });
}
//   标签分类删除
export function delClassify(param) {
    return request({
        url: `v1/label/classifyDel`,
        method: 'post',
        data: param,
    });
}
//   获取标签列表
export function getLabelList(param) {
    return request({
        url: `v1/label/labelList`,
        method: 'post',
        data: param,
    });
}
//   标签新建and编辑
export function setAndAddLabel(param) {
    return request({
        url: `v1/label/labelSetting`,
        method: 'post',
        data: param,
    });
}
//   标签新建and编辑
export function delLabel(param) {
    return request({
        url: `v1/label/labelDel`,
        method: 'post',
        data: param,
    });
}
//   导出
export function importLabel(param) {
    return request({
        url: `/v1/label/labelImport?${param}`,
        method: 'get',
        responseType: 'blob'
    });
}
// 按标签导出酒店
export function labelImportWithEs(param) {
    return request({
        url: `/v1/label/labelImportWithEs?${param}`,
        method: 'get',
        responseType: 'blob'
    });
}

//   单店打标签
export function setLabelOneHotel(param) {
    return request({
        url: `v1/labelaction/labelToHotels`,
        method: 'post',
        data: param,
    });
}
//   单店标签信息查询
export function getLabelOneHotel(param) {
    return request({
        url: `v1/labelaction/labelView`,
        method: 'post',
        data: param,
    });
}

//  历史查询列表
export function historyList(param) {
    return request({
        url: `v1/labelhistory/historyList`,
        method: 'post',
        data: param,
    });
}
//批量操作标签,添加和删除都是这个接口
export function labelToManyHotels(param) {
    return request({
        url: `v1/labelaction/labelToManyHotels`,
        method: 'post',
        data: param,
    });
}
//    查询酒店
export function searchHotelsFromEs(param) {
    return request({
        url: `v1/labelaction/searchHotelsFromEs`,
        method: 'post',
        data: param,
    });
}
//    历史导出
export function exportHistory(param) {
    return request({
        url: `v1/labelhistory/exportHistory?${param}`,
        method: 'get',
        responseType: 'blob'
    });
}
//    标签记录-更多-酒店搜索
export function findHotelName(param) {
    return request({
        url: `v1/labelhistory/findHotelName`,
        method: 'post',
        data: param,
    });
}

//    添加no广告项
export function addNoAdv(param) {
    return request({
        url: `v2/Noadv/add`,
        method: 'post',
        data: param,
    });
}
//    获取no广告项列表
export function getNoAdvList(param) {
    return request({
        url: `v2/Noadv/getList`,
        method: 'post',
        data: param,
    });
}
//   删除no广告项
export function delNoAdv(param) {
    return request({
        url: `v2/Noadv/del`,
        method: 'post',
        data: param,
    });
}
//    获取no广告统计数据
export function getTotal(param) {
    return request({
        url: `v2/Noadv/getTotal`,
        method: 'post',
        data: param,
    });
}
//    酒店合同号获取酒店标签
export function getLabelByHotel(param) {
    return request({
        url: `v2/Noadv/getLabelByHotel`,
        method: 'post',
        data: param,
    });
}
//    获取no广告统计数据
export function getLabelByBrand(param) {
    return request({
        url: `v2/Noadv/getLabelByBrand`,
        method: 'post',
        data: param,
    });
}
//    集团导出
export function exportBrand(param) {
    return request({
        url: `v2/Noadv/exportBrand`,
        method: 'post',
        data: param,
        responseType: 'blob'
    });
}
//    酒店导出
export function exportHotel(param) {
    return request({
        url: `v2/Noadv/exportHotel`,
        method: 'post',
        data: param,
        responseType: 'blob'
    });
}
//    酒店A网导出
export function exportAhotel(param) {
    return request({
        url: `v2/Noadv/exportAhotel`,
        method: 'post',
        data: param,
        responseType: 'blob'
    });
}