import request from '@/api/request';
import qs from "querystring";

//   获取launcher数据
export function getLauncherData(param) {
    return request({
        url: `v2/Content/launcher`,
        method: 'post',
        data: qs.stringify(param),
    });
}

//   获取属性列表
export function getMenuAttrList(param) {
    return request({
        url: `v2/Contentbatch/menuAttrList`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取基础图片数据
export function getBasicPicture(param) {
    return request({
        url: `v2/Content/basicPicture`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   添加基础图片数据和launcher数据
export function addMenu(param) {
    return request({
        url: `v2/Content/addMenu`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   删除launcher目录树
export function delAllMenu(param) {
    return request({
        url: `v2/Content/delAllMenu`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取节点资源详情及删除
export function showMenuContentDetail(param) {
    return request({
        url: `v2/Content/showMenuContentDetail`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取launcher目录树节点数据
export function getEditMenuJson(param) {
    return request({
        url: `v2/Content/getEditMenuJson`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   删除基础图片数据
export function delallpic(param) {
    return request({
        url: `v2/Content/delallpic`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取广告列表
export function getAdsModule(param) {
    return request({
        url: `v2/Content/getAdsModule`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取跳转列表
export function getMenuModule(param) {
    return request({
        url: `v2/Content/getMenuModule`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   基础图片编辑
export function editPicture(param) {
    return request({
        url: `v2/Content/editPicture`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取launcher 上级目录
export function getLauncherName(param) {
    return request({
        url: `v2/Hotelvisualization/getLauncherName`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取launcher 跳转模块目录                                                                                                            
export function getFunctionName(param) {
    return request({
        url: `v2/Content/getFunctionName `,
        method: 'post',
        data: qs.stringify(param),
    });
}