<template>
  <el-button type="warning" @click="exportMeth" class="largeBtn " :loading="exportBtn.loading">{{exportBtn.text}}</el-button>
</template>

<script>
import qs from "querystring";
import commonMeth from "../../api/method";
import globalVar from "../../api/globalVar";
import ScreenType from "./search/ScreenType";
export default {
  name: "ExportBtn",
  // props:['conditionProp','exportUrlProp','parentProp','textProp'],
  props: {
    conditionProp: {},
    exportUrlProp: {},
    parentProp: {},
    textProp: {
      default: "导出",
    },
  },
  data: () => ({
    apaasData:null,
    fileType: "xlsx",
    exportBtn: {
      text: "导 出",
      loading: false,
      token: "",
      screenType: "",
      screenTypeUndefined: false,
    },
    tableName: "",
  }),
  methods: {
    exportMeth() {
      console.log(this.conditionProp);
      this.exportStateChange(true);
      var param = "";
      if (this.parentProp === "hotelManNewList") {
        this.tableName = "酒店列表导出";
        param = qs.stringify({
          token: this.token,
          hotel_brand: this.conditionProp.hotel_brand,
          hotelBrand: this.conditionProp.hotel_brand,
          citycode: this.conditionProp.citycode,
          province: this.conditionProp.provinceLabel,
          city: this.conditionProp.cityLabel,
          county: this.conditionProp.countyLabel,
          hotel_use_state: this.conditionProp.hotel_use_state,
          accountManagerId: this.conditionProp.BD,
          screenType: this.conditionProp.screenType,
          partner_id: this.conditionProp.partner_id,
          keyword: this.conditionProp.keyword,
          hotelCreateTimeStart: this.conditionProp.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.conditionProp.hotelCreateTimeEnd,
          belongPartnerCode: this.conditionProp.belongPartnerCode,
          allotPartnerCode: this.conditionProp.allotPartnerCode,
          isDeleted: this.conditionProp.isDeleted,
        });
      } else if (this.parentProp === "exportChannelStatistics") {
        this.tableName = "渠道导出";
        param = qs.stringify({
          token: this.token,
        });
      } else if (this.parentProp === "importantcity") {
        this.tableName = "酒店列表导出";
        param = qs.stringify({
          token: this.token,
        });
      } else if (this.parentProp === "performanceSta") {
        this.tableName = "商务统计导出";
        param = qs.stringify({
          token: this.token,
          hotelBrand: this.conditionProp.hotel_brand,
          citycode: this.conditionProp.citycode,
          hotel_use_state: this.conditionProp.hotel_use_state,
          province: this.conditionProp.provinceLabel,
          city: this.conditionProp.cityLabel,
          county: this.conditionProp.countyLabel,
          accountManagerId: this.conditionProp.BD,
          partner_id: this.conditionProp.partner_id,
          partnerCode: this.conditionProp.partner_id,
          startTime: this.conditionProp.startTime,
          endTime: this.conditionProp.endTime,
          keyword: this.conditionProp.keyword,
          dateType: this.conditionProp.dateType,
          screenType: this.conditionProp.screenType,
        });
      } else if (this.parentProp === "performanceSta1") {
        this.tableName = "商务统计财务导出";
        param = qs.stringify({
          token: this.token,
          accountManagerId: this.conditionProp.BD,
          startTime: this.conditionProp.startTime,
          endTime: this.conditionProp.endTime,
          dateType: this.conditionProp.dateType,
        });
      } else if (this.parentProp === "exportInactiveList") {
        this.tableName = "未活跃导出";
        param = qs.stringify({
          token: this.token,
          hotelBrand: this.conditionProp.hotel_brand,
          citycode: this.conditionProp.citycode,
          hotel_use_state: this.conditionProp.hotel_use_state,
          province: this.conditionProp.provinceLabel,
          city: this.conditionProp.cityLabel,
          county: this.conditionProp.countyLabel,
          accountManagerId: this.conditionProp.BD,
          partner_id: this.conditionProp.partner_id,
          partnerCode: this.conditionProp.partner_id,
          startTime: this.conditionProp.startTime,
          endTime: this.conditionProp.endTime,
          keyword: this.conditionProp.keyword,
          screenType: this.conditionProp.screenType,
        });
      } else if (this.parentProp === "importantcity") {
        this.tableName = "酒店列表导出";
        param = qs.stringify({
          token: this.token,
          screenType: this.conditionProp.screenType,
        });
      } else if (this.parentProp === "coopList") {
        this.tableName = "合作伙伴列表导出";
        param = qs.stringify({
          token: this.token,
          type: this.conditionProp.onlyCoopType,
          review_status: this.conditionProp.coopCompleStatus,
          name: this.conditionProp.keyword,
          contact_name: this.conditionProp.coopName,
          contact_phone: this.conditionProp.coopPhone,
        });
      } else if (this.parentProp === "sysAccMan") {
        this.tableName = "账号列表导出";
        param = qs.stringify({
          token: this.token,
          code: this.conditionProp.partner_id,
          group_id: this.conditionProp.role,
          status: this.conditionProp.sysAccStatus,
          account_name: this.conditionProp.keyword,
        });
      } else if (this.parentProp === "screenHotel") {
        this.tableName = "聚屏酒店列表导出";
        param = qs.stringify({
          token: this.token,
          hotel_brand: this.conditionProp.hotel_brand,
          hotelBrand: this.conditionProp.hotel_brand,
          citycode: this.conditionProp.citycode,
          province: this.conditionProp.provinceLabel,
          city: this.conditionProp.cityLabel,
          county: this.conditionProp.countyLabel,
          hotel_use_state: this.conditionProp.hotel_use_state,
          accountManagerId: this.conditionProp.BD,
          partner_id: this.conditionProp.partner_id,
          keyword: this.conditionProp.keyword,
          startTime: this.conditionProp.startTime,
          endTime: this.conditionProp.endTime,
          hotelCreateTimeStart: this.conditionProp.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.conditionProp.hotelCreateTimeEnd,
          belongPartnerCode: this.conditionProp.belongPartnerCode,
          allotPartnerCode: this.conditionProp.allotPartnerCode,
          isDeleted: this.conditionProp.isDeleted,
          isApaas:this.apaasData?1:null
        });
        
      } else if (this.parentProp === "allHotel") {
        this.tableName = "所有酒店列表导出";
        param = qs.stringify({
          token: this.token,
          hotel_brand: this.conditionProp.hotel_brand,
          hotel_store_id: this.conditionProp.hotelStoreID,
          hotelBrand: this.conditionProp.hotel_brand,
          citycode: this.conditionProp.citycode,
          province: this.conditionProp.provinceLabel,
          city: this.conditionProp.cityLabel,
          county: this.conditionProp.countyLabel,
          hotel_use_state: this.conditionProp.hotel_use_state,
          accountManagerId: this.conditionProp.BD,
          partner_id: this.conditionProp.partner_id,
          keyword: this.conditionProp.keyword,
          startTime: this.conditionProp.startTime,
          endTime: this.conditionProp.endTime,
          hotelCreateTimeStart: this.conditionProp.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.conditionProp.hotelCreateTimeEnd,
          belongPartnerCode: this.conditionProp.belongPartnerCode,
          allotPartnerCode: this.conditionProp.allotPartnerCode,
          isDeleted: this.conditionProp.isDeleted,
          screenType: this.conditionProp.screenType,
          hotelContract: this.conditionProp.hotelContract,
          hotelName: this.conditionProp.hotelName,
        });
      } else if (this.parentProp === "BootTime") {
        this.tableName = "开机时常导出列表";
        param = qs.stringify({
          token: this.token,
          hotelBrand: this.conditionProp.hotel_brand,
          citycode: this.conditionProp.citycode,
          hotel_use_state: this.conditionProp.hotel_use_state,
          province: this.conditionProp.provinceLabel,
          city: this.conditionProp.cityLabel,
          county: this.conditionProp.countyLabel,
          accountManagerId: this.conditionProp.BD,
          partner_id: this.conditionProp.partner_id,
          partnerCode: this.conditionProp.partner_id,
          startTime: this.conditionProp.startTime,
          endTime: this.conditionProp.endTime,
          keyword: this.conditionProp.keyword,
          screenType: this.conditionProp.screenType,
        });
      }
      if (this.conditionProp.screenType == "") {
        this.$message({
          message: "请选择屏幕类型",
          type: "warning",
          duration: 3000,
        });
      } else {
        this.$message({
          message: "导出请求已提交，导出所需时间较长，请耐心等待……",
          type: "success",
          duration: 6000,
        });
        if (
          this.tableName == "所有酒店列表导出" ||
          this.tableName == "合作伙伴列表导出" ||
          this.tableName == "账号列表导出"
        ) {
          this.$http
            .post(this.exportUrlProp, param, { responseType: "blob" })
            .then((res) => {
              const blobUrl = window.URL.createObjectURL(res.data);
              const title = `${this.tableName}.csv`;
              // const title = `${this.tableName}.csv`;
              const a = document.createElement("a");
              a.style.display = "none";
              a.download = title;
              a.href = blobUrl;
              a.click();
              document.body.removeChild(a);
              this.exportStateChange(false);
            })
            .catch((res) => {
              this.exportStateChange(false);
            });
        } else {
          this.$http
            .post(this.exportUrlProp, param, { responseType: "blob" })
            .then((res) => {
              const blobUrl = window.URL.createObjectURL(res.data);
              const title = `${this.tableName}.xlsx`;
              // const title = `${this.tableName}.csv`;
              const a = document.createElement("a");
              a.style.display = "none";
              a.download = title;
              a.href = blobUrl;
              a.click();
              document.body.removeChild(a);
              this.exportStateChange(false);
            })
            .catch((res) => {
              this.exportStateChange(false);
            });
        }
        this.$http
          .post(this.exportUrlProp, param, { responseType: "blob" })
          .then((res) => {
            const blobUrl = window.URL.createObjectURL(res.data);
            if (this.tableName == "所有酒店列表导出") {
              const title = `${this.tableName}.csv`;
            } else {
              const title = `${this.tableName}.xlsx`;
            }
            // const title = `${this.tableName}.csv`;
            const a = document.createElement("a");
            a.style.display = "none";
            a.download = title;
            a.href = blobUrl;
            a.click();
            document.body.removeChild(a);
            this.exportStateChange(false);
          })
          .catch((res) => {
            this.exportStateChange(false);
          });
      }
    },
    exportStateChange(flag) {
      if (this.conditionProp.screenType == "") {
      } else {
        this.exportBtn.text = commonMeth.btnLoading(flag, this.textProp);
        this.exportBtn.loading = flag;
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.exportBtn.text = this.textProp;
     this.apaasData = sessionStorage.getItem("apaasData");
  },
};
</script>
<style scoped>
</style>
