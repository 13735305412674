<template>
    <div v-if="$route.path.indexOf('onePage')==-1">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
            <!--<el-breadcrumb-item :to="bread.firstPath">首页</el-breadcrumb-item>-->
            <el-breadcrumb-item v-if="bread.level>1" :to="bread.secondPath"><slot name="second"></slot></el-breadcrumb-item>
            <el-breadcrumb-item v-if="bread.level>2" :to="bread.thirdPath"><slot name="third"></slot></el-breadcrumb-item>
            <el-breadcrumb-item v-if="bread.level>3" :to="bread.fourthPath"><slot name="fourth"></slot></el-breadcrumb-item>
            <el-breadcrumb-item v-if="bread.level>4" :to="bread.fivethPath"><slot name="fiveth"></slot></el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
    export default {
        name: "TopBread",
        props:{
            bread:{
                type:Object,
            },
        },
        data: () => ({})
    }
</script>

<style scoped lang="scss">
    .bread{
        /*width:50%;*/
    }

</style>
