<template>
    <div>
        <el-col :span="span">
            <div class="grid-content bg-purple">
                <div class="numTitle">
                    <slot name="numTitle" class="indexNum"></slot>
                    <slot name="time" v-if="type!=='timeCenter'"></slot>
                </div>
                <div v-if="type==='timeCenter'" style="margin-bottom:20px;"><slot name="time"></slot></div>
                <div>
                    <span class="num"><slot name="num"></slot></span>
                    <slot name="unit"></slot>
                </div>
            </div>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: "QuarterRow",
        props:{
            span:{
                default:6
            },
            type:{

            }
        },
        data: () => ({

        })
    }
</script>

<style scoped lang="scss">
    .el-col-5{
        width:20%!important;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .borderBox.rowBox .grid-content{
        /*border:1px solid #ddd;*/
    }

    .vertical{
        .grid-content{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            .numTitle{
                margin-bottom:20px;
                display:flex;
                justify-content: space-between;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
        .bg-purple {
            /*background: #d3dce6;*/
            background: #fff;
        }
        .grid-content {
            border-radius: 4px;
            padding:20px 20px;
        }
    }
</style>
