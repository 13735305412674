<template>
  <div>
    <div>
      <div class="mainBox" style="height:100%;position:relative">
        <el-row :gutter="20" style="padding:0 ;margin:0;">
          <el-col :span="12" class="searchBox">
            <el-input cla placeholder="请输入房间号" class="input-with-select searchInput fullSelect" v-model="roomid" :clearable="true">
              <el-button slot="append" icon="el-icon-search" @click="clickFilter"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" class="searchBox">
            <el-button type="primary" class="trueBtn" @click="checkAll">全选</el-button>
          </el-col>
        </el-row>
        <div class="roomBox">
          <div ref="roomList">
            <el-col :span="3" v-for="(item,index) in roomList" :key="index">
              <div class="room" @click="changeSpan(item.rname)" :class="[spanIndex.indexOf(item.rname)>-1?'change':'',editId&&checkRoom.includes(item.rname)?'disable':'']">{{item.rname}}</div>
            </el-col>
          </div>
          <el-col :span="3" v-for="(item,index) in searchList" :key="index" class="allroom">
            <div class="room" @click="changeSpan(item.rname)" :class="[spanIndex.indexOf(item.rname)>-1?'change':'',editId&&checkRoom.includes(item.rname)?'disable':'']">{{item.rname}}</div>
          </el-col>
        </div>
        <!-- <div class="drawerFooter">
          <div class="btnBox">
            <el-button @click="showDrawer(false)" class="largeBtn whiteBtn">取 消</el-button>
            <el-button type="primary" @click="submitMeth" class="largeBtn">保 存</el-button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import qs from "querystring";
import Bus from "@/api/Bus";
import {
  getList,

} from "@/api/guestRoom";
export default {
  isActive: false,
  name: "Online",
  props: ["showData", "queryRoom", "editId", "editRoom"],
  data: () => ({
    checkRoom: [],
    spanIndex: [],
    isActive: false,
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/hotelManAll" },
    },
    type: "timeCenter",
    roomid: "",
    roomList: [],
    searchList: [],
    token: "",
    loading: false,
    drawer: false,
    direction: "rtl",
    query: {},
    flag: true,
  }),
  methods: {
    clickFilter() {
      this.$refs.roomList.style.display = "none";
      this.searchList = [];
      var roomid = this.roomid;
      if (roomid) {
        console.log(this.roomList,roomid,'roomidroomid');
        this.roomList.forEach((item, index) => {
          if (item.rname.indexOf(roomid) !== -1) {
            this.searchList.push(item);
          }
        });
      } else {
        this.searchList = this.roomList;
      }
    },
    checkAll() {
      if (this.flag) {
        this.$refs.roomList.style.display = "none";
        this.searchList = [];
        this.spanIndex = [];
        this.roomList.forEach((item, index) => {
          this.searchList.push(item);
          this.spanIndex.push(item.rname);
        });
      } else {
        this.$refs.roomList.style.display = "block";
        this.searchList = [];
        this.spanIndex = [];
      }
      this.flag = !this.flag;
    },
    changeSpan(room) {
      if (this.editId && this.checkRoom.includes(room)) {
        return;
      } else {
        let arrIndex = this.spanIndex.indexOf(room);
        if (arrIndex > -1) {
          this.spanIndex.splice(arrIndex, 1);
        } else {
          this.spanIndex.push(room);
        }
      }
    },

    getRoom() {
      this.loading = true;
      let json = {
        alive: "1",
        database: this.query.database,
        hotel_contract: this.query.hotel_contract,
        ip: "",
        name: "",
        page: 1,
        size: 9999,
      };
      getList(json).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data) {
            if (!res.data.data.row) {
              this.roomList = [];
               this.loading = false;
              return;
            }
            this.roomList = res.data.data.row;
               this.loading = false;
          } else {
            this.roomList = [];
               this.loading = false;
          }
        }else{
             this.loading = false;
        }
      });
    },
    submitMeth() {
      if (this.spanIndex.length === 0) {
        this.$message.warning("所选房间不能为空");
      } else {
        this.$emit("sendroom", this.spanIndex.join(","));
      }
    },
    showDrawer(val) {
      this.$emit("close");
    },
  },
  created() {
    this.queryRoom = this.query;
  },
  watch: {
    queryRoom: {
      handler: function (newVal) {
        this.query = newVal;
        if (this.editId) {
          this.checkRoom = this.editRoom ? this.editRoom.split(",") : [];
          this.spanIndex = [];
        } else {
          this.spanIndex = this.query.roomStr
            ? this.query.roomStr.split(",")
            : [];
        }

        this.token = sessionStorage.getItem("token");
        if (this.query.id) {
          this.getRoom();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
  },
  created() {
    Bus.$on("showDrawer", (obj) => {
      this.drawer = obj.show;
      this.spanIndex = obj.roomStr ? obj.roomStr.split(",") : [];
    });
  },
};
</script>


<style scoped lang="scss">
.btn {
  position: absolute;
  width: 100px;
  right: 20px;
}
.ybtn {
  margin-bottom: 40px;
}
.room {
  text-align: center;
  color: #658bff;
  line-height: 30px;
  border: 1px solid #658bff;
  border-radius: 4px;
  margin-left: 27px;
  cursor: pointer;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.room:first-child {
  margin: 0;
}
.change {
  color: #fff;
  border-color: #658bff;
  background-color: #658bff;
}
.disable {
  color: #fff;
  border-color: #ddd;
  background-color: #ddd;
}
.roomBox {
  overflow: hidden;
  padding-bottom: 90px;
}
.roomBox .el-col {
  padding: 10px 10px 10px 10px;
  text-align: center;
  float: left;
}

// .el-col:nth-child(12n) {
//   padding: 20px 0px 10px 20px;
//   float: right;
// }
// .el-col:first-child {
//   padding: 20px 20px 10px 0px;
// }
// .el-col:nth-child(12n + 1) {
//   padding: 20px 20px 10px 0;
//   float: left;
// }
.searchBox {
  padding: 0px !important;
  margin-right: 20px;
}
.mainBox {
  width: 100%;
  margin: 0 auto;
  // padding: 0 20px;
  box-sizing: border-box;
  // border: 1px solid cornflowerblue;
}
.drawerFooter {
  display: flex;
}
.btnBox {
  margin-left: auto;
}
.largeBtn {
  background: #658bff;
  border: 1px solid #658bff;
  width: 100px;
}
.whiteBtn {
  border: 1px solid #658bff;
  background: #fff;
  color: #658bff;
  width: 100px;
}
</style>

