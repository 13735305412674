import request from "@/api/request";
import qs from "querystring";


// 获取报障列表
export function getNetwork(param) {
    return request({
        url: `v2/Networkreporting/getNetwork`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  导出接口
export function exportNetwork(param) {
    return request({
        url: `v2/Networkreporting/exportNetwork`,
        method: "post",
        data: qs.stringify(param),
        responseType: 'blob'
    });
}