<template>
    <!--<div>-->
        <el-col :span="12">
            <div class="colBox">
                <div class="textBox">
                    <div class="top">
                        <div class="left">
                            <!--影片单点数据-->
                            <!---->
                            <div><slot name="title"></slot></div>
                            <div class="date"><slot name="date"></slot></div>
                        </div>
                        <div class="right">
                            <!--<span class="check">查看</span>-->
                            <!--<span class="export">导出</span>-->
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="left">
                            <span class="num">
                                <slot name="amount"></slot>
                            </span>
                            <span class="date">元</span>
                        </div>
                        <div class="right">
                            <div ><slot name="top"></slot></div>
                            <div><slot name="bottom"></slot></div>
                        </div>
                    </div>
                </div>
                <div class="chartBoxLittle">
                    <line-chart :chart="chart"  v-if="chart.flag && !chart.noData"></line-chart>
                    <div v-if="chart.flag && chart.noData" style="line-height:350px;text-align:center">暂无数据</div>
                </div>
            </div>
        </el-col>
    <!--</div>-->
</template>

<script>
    export default {
        name: "TowRowLine",
        props:['chart'],
        data: () => ({

        })
    }
</script>

<style scoped lang="scss">
    /*小折线图模块开始*/
    .colBox{
        border:1px solid #ddd;
        border-radius:4px;
        padding:20px;
        .date{
            color:#bababa;
            margin-top:15px;
            font-size:14px;
        }
        .top{
            display:flex;
            justify-content: space-between;
            margin-bottom:20px;

            .right{
                span{
                    color:#559afc;
                }
                .check{
                    margin-right:5px;
                }
            }
        }
        .bottom{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .num{
                font-size:28px;
                margin-right:10px;
            }
            .right{
                border-left:1px solid #ddd;
                padding-left:15px;
                div:first-child{
                    margin-bottom:15px;
                }
            }
        }

    }
    .chartBoxLittle{
        height:350px;
        .grid-content{
            padding:20px 0 0 0;
        }
    }

    /*小折线图模块结束*/
</style>
