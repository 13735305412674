<template >

    <div>
        <div class="outTopBox">
        <top-bread :bread="bread">
        <span slot="second">数字资产</span>
        <span slot="third">资产报表</span>
      </top-bread>
         </div>
        <el-tabs class="tabStyle" type="border-card">
        <el-tab-pane label="影视健康状态">
              <dataAssetMovie></dataAssetMovie>
        </el-tab-pane>
        <el-tab-pane label="广告健康状态">
            <dataAssetAdvertisement></dataAssetAdvertisement>
        </el-tab-pane>
        <el-tab-pane label="终端健康状态">
            <dataAssetTerminal></dataAssetTerminal>
        </el-tab-pane>
         <el-tab-pane label="影视阵地评级">
            <dataAssetGrade></dataAssetGrade>
        </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import  dataAssetAdvertisement from "./components/DataAssetAdvertisement.vue"
import  dataAssetTerminal from "./components/DataAssetTerminal.vue"
import  dataAssetMovie from "./components/DataAssetMovie.vue"
import  dataAssetGrade from "./components/DataAssetGrade.vue"

export default {
    components:{
        dataAssetAdvertisement,
        dataAssetTerminal,
        dataAssetMovie,
        dataAssetGrade
    },
    data(){
        return{
              bread: {
                level: 3,
                
                },
        }
    }
}
</script>
<style scoped>
.tabStyle{
    margin-top: 15px;
}
.el-tabs--border-card {
    border: none;
    box-shadow:none;
    }
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
    border-top: 1px solid  #E4E7ED !important;
    margin-top: 0;
    box-sizing: border-box;
}
</style>