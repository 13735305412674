<template>
    <div :class="[$attrs.onlyShowModule?'onlyShowModule':'']">
        <div class="home_container" :style="{'backgroundImage':'url('+configStyleProp.bgUrl+')'}">
            <div class="home_top">
                <div class="home_logo">
                    <img :src="hotelService.modelLogo" alt="" @mouseover.stop="modelShowBtnMeth('modelLogo')" >
                </div>
                <div class="city_info">
                    <div class="info_one">
                        <div class="top">{{curTime.time}}</div>
                        <div class="bottom">{{curTime.date}}</div>
                    </div>
                    <div class="info_two">
                        <div class="top">广州 晴</div>
                        <div class="bottom">25~30℃</div>
                    </div>
                </div>
            </div>
            <div class="home_bottom">
                <div class="bottom_left">
                    <div class="videoBox borderBox" :class="[modelHighIndexClass('modelOne')]" @mouseover.stop="modelShowBtnMeth('modelOne')"  @mousemove.stop="modelShowBtnMeth('modelOne')">
                        <video :src="hotelService.modelOne.url" controls></video>
                        <div class="configBox modelOne" :class="configBtnClass('modelOne')">
                            <div class="btnItem configBtn" @click.stop="slideConfigBox('modelOne')" :class="configBtnClass('modelOne')">配置</div>
                            <div class="slideBox " v-if="modelOneData.configShow">
                                <div class="btnGroup configMarginLeft" >
                                    <div class="btnGroupItem">
                                        <div class="btnItem" @click.stop="uploadMaterial('modelOne','video')">上传视频</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="descBox borderBox"  :style="getTextareaBgCom" :class="[modelHighIndexClass('word')]" @mouseover.stop="modelShowBtnMeth('word')" >
                        <el-form class="descForm" @click="focusTextBoxMeth('word')">
                            <div @click.stop="showShadeMeth" class="fullHeight">
                                <el-input type="textarea" class="textBorderBox noBgInput noDisabledColor" v-model="hotelService.word" :style="getTextareaColorCom(configStyleProp.serviceColor)"
                                          placeholder="请输入简介，不能超过138个字符" @input="inputMeth"
                                          @focus="focusTextBoxMeth('word')"
                                          @blur="blurTextBoxMeth" :disabled="$attrs.onlyShowModule"
                                          :class="{focusTextBox:curFocusTextBox==='word',highIndex:this.modelShowBtnName==='word'}"></el-input>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div class="bottom_center">
                    <div class="top z_index_show borderBox" :class="[modelHighIndexClass('modelTwo')]" style="position:relative;" @mouseenter="modelShowBtnMeth('modelTwo')"  @mousemove.stop="modelShowBtnMeth('modelTwo')">
                        <img :src="hotelService.modelTwo.url" alt="">
                        <div class="modelTitle">{{getModelTitleCom(0)}}</div>
                        <div class="configBox modelTwo " :class="configBtnClass('modelTwo')" >
                            <div class="slideBox" v-if="modelTwoData.configShow">
                                <div class="btnGroup configMarginLeft">
                                    <div class="btnGroupItem">
                                        <div class="btnItem" @click.stop="toSortPic(hotelService.pic[Object.keys(hotelService.pic)[0]].id)">编辑</div>
                                    </div>
                                </div>
                            </div>
                            <div class="btnItem configBtn" @click.stop="slideConfigBox('modelTwo')" :class="configBtnClass('modelTwo')">配置</div>
                        </div>
                    </div>
                </div>
                <div class="bottom_right">
                    <img src="../../../../assets/img/109.jpg" alt="">
                </div>
            </div>
            <div class="home_middle" :class="{'blueBg': templateMenuCurStr =='TSbg'?true:false}">
                <div class="menuItemBox" :class="{blurBorderBg:blurBorderBg}">
                    <div class="menuItem" :class="{active:activeNameProp===item.mname,borderBlueShadow:borderBlueShadow,smallMenuItem:smallMenuItem}" v-for="(item,index) in configStyleProp.menu"
                         :style="templateMenuBoxStyleCom(item.color,item.mname)">
                        <div class="icon" :class="{iconBorder:iconBorder}"><img :src="item.focuspicaddr" alt="" v-if="activeNameProp===item.mname"></div>
                        <div class="icon" :class="{iconBorder:iconBorder}"><img :src="item.defaultpicaddr" alt="" v-if="activeNameProp!==item.mname"></div>
                        <!--<div class="text" :style="{'color':item.color}">{{item.mname}}</div>-->
                        <div class="text" :style="templateMenuTextStyleCom(item.color,item.mname)">{{item.mname}}</div>
                    </div>
                </div>
            </div>
            <div class="shade" @click.stop="hideShade" v-if="shadeShow"></div>
        </div>
        <upload-dialog :dialogParData="dialogUpload"
                       @uploadMaterialUrl="getUploadMaterialUrl" @changeDialogVisible="changeDialogVisible" @editTabLoadingMeth="editTabLoadingMeth"
                       :hotelContract="hotelContract"></upload-dialog>
    </div>
</template>

<script>
    import commonMeth from '../../../../api/method'
    import  qs from 'querystring'
    import Bus from '../../../../api/Bus'

    export default {
        name: "EditEelDetail",
        props:['hotelContract','hotelServiceProp','homePageProp','configStyleProp',
            'templateIdProp','onlyShow',
            'hotelTemplateType','modelReadOnly','activeNameProp','serviceType'],
        data:()=>({
            test:'测试hotelService',
            testTwo:'1',
            modelShowBtnName:'',
            modelLogoData:{
                configShow:false,
                moreType:'',
                moreDetailShow:false,
                arrowDirection:'down',
                fileName:''
            },
            modelOneData:{
                configShow:false,
                moreType:'',
                moreDetailShow:false,
                arrowDirection:'top',
                fileName:''
            },
            modelTwoData:{
                configShow:false,
                moreType:'',
                moreDetailShow:false,
                arrowDirection:'top',
                fileName:''
            },
            hotelService:{
                moreDetailShow:false,
                modelLogo:'',
                modelOne:{},
                modelTwo:{},
                word:'',
                pic:[]
            },
            defaultData:{
                coBack:'',
                moreDetailShow:false,
                colorDetailShow:false,
                modelLogo:'',
                modelOne:{},
                modelTwo:{},
            },
            visible:true,
            dialogUpload:{
                visible:false,
                agreenActive:'',
                imgMaterialList:[],
                videoMaterialList:[
                    {id:1,name:1,url:require('../../../../assets/img/video/1.mp4')},
                    {id:1,name:2,url:require('../../../../assets/img/video/2.mp4')},
                ],
                chooseItemIndex:'',
                uploadModel:'',
                uploadMaterialType:'',
                uploadMaterialUrl:''
            },
            headers: {
                token: '',
                Authorization: ''
            },
            initConfigBtnShow:false,
            shadeShow:false,
            templateRightModel:{
                threeModel:[],
                twoEditTextImg:['71','73','74'],
                twoEditImg:['75'],
            },
            templateMenuCurStr:'',
            blurBorderBg:false,
            borderBlueShadow:false,
            iconBorder:false,
            smallMenuItem:false,
          // homeRtBg:'http://source.ctlife.tv/20190731155725406_0.png',
            homeRtBg:'',
            curTime:{
                date:'',
                week:'',
                time:'',
            },
            curFocusTextBox:'',
            query:{}
        }),
        methods:{
            slideConfigBox(type){
                this.shadeShow=true;
                if(type==='modelLogo'){
                    var modelLogoData=this.modelLogoData;
                    modelLogoData.configShow=!this.modelLogoData.configShow;
                    if(modelLogoData.configShow){
                        this.shadeShow=true;
                        if(!this.initConfigBtnShow){
                            this.initConfigBtnShow=true;
                        }
                    }else{
                        this.shadeShow=false;
                    }
                    modelLogoData.moreType='';
                    modelLogoData.moreDetailShow=false;
                }else if(type==='modelOne'){
                    var modelOneData=this.modelOneData;
                    modelOneData.configShow=!this.modelOneData.configShow;
                    if(modelOneData.configShow){
                        this.shadeShow=true;
                    }else{
                        this.shadeShow=false;
                    }
                    modelOneData.moreType='';
                    modelOneData.moreDetailShow=false;
                }else if(type==='modelTwo'){
                    var modelTwoData=this.modelTwoData;
                    modelTwoData.configShow=!this.modelTwoData.configShow;
                    if(modelTwoData.configShow){
                        this.shadeShow=true;
                    }else{
                        this.shadeShow=false;
                    }
                    modelTwoData.moreType='';
                    modelTwoData.moreDetailShow=false;
                }
            },
            uploadMaterial(modelType,materialType){
                if(modelType==='modelLogo'){
                    this.modelLogoData.moreType=materialType;
                }else if(modelType==='modelOne'){
                    this.modelOneData.moreType=materialType;
                }else if(modelType==='modelTwo'){
                    this.modelTwoData.moreType=materialType;
                }
                var dialogUpload=this.dialogUpload;
                dialogUpload.uploadModel=modelType;
                dialogUpload.uploadMaterialType=materialType;
                dialogUpload.visible=true;
            },
            resetDialog(){
                var dialogUpload=this.dialogUpload;
                dialogUpload.chooseItemIndex='';
                dialogUpload.uploadModel='';
                dialogUpload.uploadMaterialType='';
                dialogUpload.uploadMaterialUrl='';
            },
            showDetailMeth(model,val){
                this.modelLogoData.moreType='';
                if(model==='logo'){
                    var modelLogoData=this.modelLogoData;
                    modelLogoData.moreDetailShow=!modelLogoData.moreDetailShow;
                    modelLogoData.moreDetailShow?modelLogoData.arrowDirection='top':modelLogoData.arrowDirection='down'
                }else if(model==='modelOne'){
                    this.modelOneData.moreDetailShow=!this.modelOneData.moreDetailShow;
                }else if(model==='modelTwo'){
                    var modelTwoData=this.modelTwoData;
                    modelTwoData.moreDetailShow=!modelTwoData.moreDetailShow;
                    modelTwoData.moreDetailShow?modelTwoData.arrowDirection='down':modelTwoData.arrowDirection='top'
                }
            },
            modelShowBtnMeth(type){
                if(!this.shadeShow){
                    this.modelShowBtnName=type;
                }
            },
            clearShowBtnName(){
                this.modelShowBtnName='';
                this.shadeShow=false;
            },
            getUploadMaterialUrl(url,fileName,md5){
                var hotelService=this.hotelService;
                var dialogUpload=this.dialogUpload;
                if(dialogUpload.uploadModel==='modelOne'){
                    hotelService.modelOne.url=url;
                    this.modelOneData.fileName=fileName;
                }else if(dialogUpload.uploadModel==='modelTwo'){
                    hotelService.modelTwo.url=url;
                    this.modelTwoData.fileName=fileName;
                }
                dialogUpload.visible=false;
            },
            getFormDataFile(val){
                var hotelService=this.hotelService;
                var modelOneData=this.modelOneData;
                var dialogUpload=this.dialogUpload;
                if(dialogUpload.uploadModel==='modelLogo'){
                    hotelService.modelLogoFile=val;
                }else if(dialogUpload.uploadModel==='modelOne'){
                    hotelService.modelOneFile=val;
                }
            },
            defaultDataMeth(){
                this.shadeShow=false;
                this.hotelService=commonMeth.objDataAssign(this.defaultData);
                this.hotelService.modelLogo=this.homePageProp.modelLogo;
            },
            resetItemMeth(type){
                var hotelService=this.hotelService;
                var defaultData=this.defaultData;
                if(type==='modelLogo'){
                    hotelService.modelLogo=defaultData.modelLogo;
                }else if(type==='modelOne'){
                    hotelService.modelOne=defaultData.modelOne;
                }else if(type==='modelTwo'){
                    hotelService.modelTwo=defaultData.modelTwo;
                }
            },
            hideShade(){
                this.shadeShow=false;
                this.modelLogoData.configShow=false;
                this.modelOneData.configShow=false;
                this.modelTwoData.configShow=false;
            },
            getData(){  //默认数据赋值
                var hotelServiceProp=this.hotelServiceProp;
                this.defaultData=commonMeth.objDataAssign(this.hotelServiceProp);
                this.defaultData.modelOne=hotelServiceProp.video;
                this.defaultData.pic=hotelServiceProp.pic;
                this.defaultData.word=hotelServiceProp.word;
                this.defaultData.modelTwo=hotelServiceProp.pic[Object.keys(hotelServiceProp.pic)[0]].picHorizontal;
                if(hotelServiceProp.pic[Object.keys(hotelServiceProp.pic)[1]]){
                    this.defaultData.modelThree=hotelServiceProp.pic[Object.keys(hotelServiceProp.pic)[1]].picHorizontal;
                }
                if(hotelServiceProp.pic[Object.keys(hotelServiceProp.pic)[2]]){
                    this.defaultData.modelFour=hotelServiceProp.pic[Object.keys(hotelServiceProp.pic)[2]].picHorizontal;
                }
                this.defaultData.moreDetailShow=false;
            },
            cancelMeth(){
                this.dialogUpload.visible=false;
            },
            clearFileName(){
                this.modelLogoData.fileName='';
                this.modelOneData.fileName='';
                this.modelTwoData.fileName='';
            },
            templateMenuStyleStr(){
                var templateMenuStyle=this.global.materialStyle.templateMenuStyle;
                var val=this.templateIdProp;
                var styleStr='';
                if(templateMenuStyle.focusBorder.indexOf(val)!==-1){
                    styleStr='focusBorder';
                }
                if(templateMenuStyle.focusBg.indexOf(val)!==-1){
                    styleStr='focusBg';
                }
                if(templateMenuStyle.focusBorderBg.indexOf(val)!==-1){
                    styleStr='focusBorderBg';
                }
                if(templateMenuStyle.focusBottomBorder.indexOf(val)!==-1){
                    styleStr='focusBottomBorder';
                }
                if(templateMenuStyle.focusNone.indexOf(val)!==-1){
                    styleStr='focusNone';
                }
                 if(val == 87){
                    styleStr='TSbg';
                }
                this.templateMenuCurStr=styleStr;
            },
            objForEach(){
                var obj=this.global.materialStyle.templateMenuColor;
                var filterObj={};
                Object.keys(obj).forEach((key)=>{
                    if(obj[key].id.indexOf(this.templateIdProp)!==-1){
                        filterObj=obj[key];
                    }
                });
                return filterObj;
            },
            editTabLoadingMeth(val,type){
                this.$emit('editTabLoadingMeth', val,type);
            },
            changeDialogVisible(val){
                this.dialogUpload.visible=val;
            },
            focusTextBoxMeth(val){
                this.curFocusTextBox=val;
                this.modelShowBtnName='word';
            },
            blurTextBoxMeth(){
                this.shadeShow=true;
                this.curFocusTextBox='';
            },
            toSortPic(id){
                var obj={
                    id,
                    tabActiveName:this.$attrs.activeName,
                    configData:this.mergeData(),
                    showType:this.$attrs.showType,
                };
                Bus.$emit('showConfigDetailDrawer',obj);
                this.shadeShow=false;
                this.modelOneData.configShow=false;
                this.modelTwoData.configShow=false;
                return false;
                this.setSessionData();
                this.$router.push({path:'./hotelManEditHotelService',query:{
                        fromUrl:'hotelManAllDetail',
                        id:id,hotelContract:this.hotelContract,
                        hotelId:this.query.id,
                        serviceType:this.serviceType}});
            },
            mergeData(){
                var hotelServiceMerge={};
                var hotelService=this.hotelService;
                var defaultData=this.defaultData;
                hotelServiceMerge.attr=defaultData.attr;
                hotelServiceMerge.id=defaultData.id;
                hotelServiceMerge.order=defaultData.order;
                hotelServiceMerge.title=defaultData.title;
                hotelServiceMerge.pic=hotelService.pic;
                hotelServiceMerge.video=hotelService.modelOne;
                hotelServiceMerge.word=hotelService.word;
                this.hotelServiceMergeData=hotelServiceMerge;
                return hotelServiceMerge;
            },
            inputMeth(){
                var word=this.hotelService.word;
                if(word.length>138){
                    this.$message.warning('输入内容不能超过138个字符');
                    this.hotelService.word=word.slice(0,138);
                }
            },
            showShadeMeth(){
                if(!this.$attrs.onlyShowModule){
                    this.shadeShow=true;
                }
            },
        },
        mounted(){
            this.getData();
            this.query=this.$route.query;
            this.headers.token=sessionStorage.getItem('token');
            this.defaultDataMeth();
            this.templateMenuStyleStr();
            setInterval(()=>{
                this.curTime=commonMeth.getCurrentDate();
            },1000);
        },
        computed:{
            modelMoreActiveClass() {
                return function(modelType,materialType) {
                    if(modelType==='modelLogo'){
                        return this.modelLogoData.moreType === materialType ? 'active' : ''
                    }else if(modelType==='modelOne'){
                        return this.modelOneData.moreType === materialType ? 'active' : ''
                    }else if(modelType==='modelTwo'){
                        return this.modelTwoData.moreType === materialType ? 'active' : ''
                    }
                }
            },
            arrowDirectionClass(){
                return function(msg) {
                    return this.hotelService.coBack.trim() === msg.trim() ? 'active' : ''
                }
            },
            configBtnClass(){
                return function(msg) {
                    // return this.modelShowBtnName === msg ? 'animate__fadeIn highIndex' : 'animate__fadeOut noneEl'
                    return this.modelShowBtnName === msg ? 'animate__fadeIn highIndex' : 'animate__fadeOut'
                }
            },
            modelHighIndexClass(){
                return function(msg) {
                    return this.modelShowBtnName === msg ? ' highIndex' : ' '
                }
            },
            hotelTemplateTypeCom(){
                var flag=false;
                this.hotelTemplateType==='ehotel'
                    ?flag=false
                    :flag=true;
                return flag;
            },
            templateMenuBoxStyleCom(){
                return function(color,name){
                    var str={};
                    var filterObj=this.objForEach();
                    var activeNameProp=this.activeNameProp;
                    if(this.templateMenuCurStr==='focusDoubleBorder' && activeNameProp===name){
                        str={'background':'#00173a','border':`2px solid ${color}`}
                    }
                    if(this.templateMenuCurStr==='focusBorder' && activeNameProp===name){

                        filterObj.borderBlueShadow
                            ?this.borderBlueShadow=true
                            :this.borderBlueShadow=false;
                        filterObj.borderDiff
                            ?str={'border':`2px solid ${filterObj.borderColor}`}
                            :str={'border':`2px solid ${filterObj.focusColor}`};
                    }
                    if(this.templateMenuCurStr==='focusBg' && activeNameProp===name){
                        str={'background':`${filterObj.bgColor}`}
                    }
                    if(this.templateMenuCurStr==='focusBorderBg' && activeNameProp===name){
                        filterObj.borderBlueShadow
                            ?this.borderBlueShadow=true
                            :this.borderBlueShadow=false;
                        if(filterObj.blurBorderBg){
                            this.blurBorderBg=true;
                            str={'background':`${filterObj.bgColor}`,'border':`2px solid ${filterObj.focusColor}`}
                        }else{
                            this.blurBorderBg=false;
                            str={'background':`${filterObj.bgColor}`,'border':`2px solid ${filterObj.focusColor}`,'border-radius':'5px'}
                        }
                    }
                    if(this.templateMenuCurStr==='focusBottomBorder' && activeNameProp===name){
                        // str={'background':'#114aa1','border-bottom':`2px solid ${filterObj.focusColor}`}
                        str={'background':`${filterObj.bgColor}`,'border-bottom':`2px solid ${filterObj.focusColor}`}
                    }
                    if(this.templateMenuCurStr==='focusNone' && activeNameProp===name){
                        str={}
                    }
                    if(this.templateMenuCurStr==='TSbg' && activeNameProp===name){
                        str={'background':`#786379`}
                    }
                    filterObj.iconBorder
                        ?this.iconBorder=true
                        :this.iconBorder=false;
                    filterObj.smallMenuItem
                        ?this.smallMenuItem=true
                        :this.smallMenuItem=false;
                    return str;
                }
            },
            templateMenuTextStyleCom(){
                return function(color,name){
                    var str={};
                    var filterObj=this.objForEach();
                    this.activeNameProp===name
                        ?str={'color':`${filterObj.focusColor}`}
                        :str={'color':`${filterObj.blurColor}`};
                    return str;
                }
            },
            templateMenuBoxClassCom(){
                return function(val){
                    var className='';
                    var templateIdProp=this.templateIdProp;
                    if(templateIdProp==='47'){
                        className='blurBorderBg'
                    }else if(
                        templateIdProp==='43' ||
                        templateIdProp==='45' ||
                        templateIdProp==='46' ||
                        templateIdProp==='48' ||
                        templateIdProp==='49' ||
                        templateIdProp==='56' ||
                        templateIdProp==='57' ||
                        templateIdProp==='63'
                    ){
                        className='defaultGrayGeneral'
                    }
                    return className;
                }
            },
            getModelTitleCom(){
                return function(index){
                    var obj=this.hotelService.pic;
                    var test=this.hotelService.pic[Object.keys(this.hotelService.pic)[index]];
                    var str='';
                    if(this.hotelService.pic[Object.keys(this.hotelService.pic)[index]]){
                        str=this.hotelService.pic[Object.keys(this.hotelService.pic)[index]].mname;
                    }
                    return str;
                }
            },
            getTextareaColorCom(){
                return function(color){
                    var str={};
                    if(color){
                        str={'color':`${color}`}
                    }else{
                        str={'color':'#fff'}
                    }
                    return str;
                }
            },
            getTextareaBgCom(){
                return function(bg){
                    var str={};
                    if(bg){
                        str={'backgroundImage':'url('+bg+')'}
                    }else{
                        str={'background':'rgba(255,255,255,0.4)'}
                    }
                    return str;
                }
            },
            showConfigText(){
                var str='';
                this.$attrs.showType==='action'?str='查看':str='编辑';
                return str;
            }

        },
        watch:{
            
               hotelContract:{
                  handler: function (newVal) {
                          this.hotelContract=newVal
                       
                },
                deep: true,
                immediate: true, 
            },
           
             serviceType:{
                 handler: function (newVal) {
                          this.serviceType=newVal
                },
                deep: true,
                immediate: true, 
            },
             activeNameProp:{
                 handler: function (newVal) {
                          this.activeNameProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             modelReadOnly:{
                 handler: function (newVal) {
                          this.modelReadOnly=newVal
                },
                deep: true,
                immediate: true, 
            },
          
             hotelTemplateType:{
                 handler: function (newVal) {
                          this.hotelTemplateType=newVal
                },
                deep: true,
                immediate: true, 
            },
             onlyShow:{
                 handler: function (newVal) {
                          this.onlyShow=newVal
                },
                deep: true,
                immediate: true, 
            },
             templateIdProp:{
                 handler: function (newVal) {
                          this.templateIdProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelServiceProp:{
                 handler: function (newVal) {
                          this.hotelServiceProp=newVal
                            this.getData();
                            this.defaultDataMeth();
                            this.templateMenuStyleStr();
                },
                deep: true,
                immediate: true, 
            },
             homePageProp:{
                 handler: function (newVal) {
                          this.homePageProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             configStyleProp:{
                 handler: function (newVal) {
                          this.configStyleProp=newVal
                },
                deep: true,
                immediate: true, 
            },
            modelShowBtnName(newVal){
                if(newVal!=='modelLogo'){
                    this.modelLogoData.configShow=false;
                }
                if(newVal!=='modelOne'){
                    this.modelOneData.configShow=false;
                }

                if(newVal!=='modelTwo'){
                    this.modelTwoData.configShow=false;
                }
            },
            'dialogUpload.visible'(newVal){
                // newVal?'':this.resetDialog();
            }
        }
    }
</script>

<style scoped lang="scss">
    .home_container{
        width:1152px;
        height:648px;
        padding:55px 100px;
        flex-direction:column;
        justify-content: space-between;
        position:relative;
        box-sizing:border-box;
       
    }
    .home_top{
        width:100%;
        height:70px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:10px;
    }
    .home_logo{
        width:auto;
        height:100%;
        position:relative;
        box-sizing:content-box;
        border:2px dashed rgba(28, 144, 238,0);
        transition:0.5s;
    }
    .home_logo.active{
        border:2px dashed rgba(28, 144, 238,1);
        transition:0.5s;
    }
    .home_logo>img{
        width:auto;
        height:100%;
    }
    .home_logo_group>div{
        float:left;
    }
    .home_scroll_box{
        /*width:36.6%!important;*/
        width:380px;
        height:100%;
        display:flex;
        align-items:center;
        justify-content: center;
        border:2px dashed rgba(28, 144, 238,0);
        transition:0.5s;
        padding:0 10px;
    }
    .home_scroll_box.active{
        border:2px dashed rgba(28, 144, 238,1);
        transition:0.5s;
    }
    .scroll_box{
        /*width:*/
    }
    .home_scroll_text{
        width:100%;
        border-radius:30px;
        color:#fff;
        padding:0 20px;
        display:flex;
        align-items: center;
        position:relative;
    }
    .home_scroll_text.coBlack{
        background:rgba(250,250,250,0.05);
    }
    .home_scroll_text.coTrans{
        background:none;
    }
    .txt{
        color:#fafafd!important;
        font-weight:400!important;
        outline:none;
        font-size:14px;
        overflow:auto;
        padding:5px 0;
    }
    .txt-clone{
        font-weight:400!important;
    }
    .home_scroll_text>div{
        white-space: nowrap;
    }
    .txt-scroll{
        width:100%;
        background:none!important;
    }
    .city_info{
        display:flex;
        height:45px;
    }
    .city_info div{
        color:#fff;
        letter-spacing:1px;
    }
    .info_one{
        height:100%;
        border-right:2px solid #fff;
        /*background:#81d4fa;*/
        padding-right:7px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_one .top{
        /*font-weight:600;*/
        font-size:22px;
    }
    .info_one .bottom{
        font-size:14px;
    }
    .info_two{
        padding-left:7px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_two div{
        font-size:14px;
    }
    /*中间主页导航*/
    .home_middle{
        width:100%;
        display:flex;
        justify-content: center;
        margin-top:40px;
    }
    .home_middle .menuItem.smallMenuItem{
        width:105px!important;
    }
    .home_middle .menuItem{
        width:130px;
        box-sizing:border-box;
        color:#fff;
        text-align: center;
        padding:10px 0;
        /*padding:10px 20px;*/
        font-size:16px;
        margin-right:10px;
        display:flex;
        flex-direction: column;
        align-items:center;
        :last-child{
            margin-right:0;
        }
        .icon{
            width:40px;
            height:auto;
        }
        .icon.iconBorder{
            width:55px!important;
            height:auto;
        }
        .text{
            margin-top:8px;
        }
    }
    /*失去焦点，有边框有背景色*/
    .menuItemBox.blurBorderBg {
        .menuItem{
            background:#fff;
            border:2px solid #91afb2!important;
        }
        .menuItem.active{
            background:#2aafc2;
        }
    }
    /*获得焦点，outLightBorder 外发光border*/
    .menuItemBox.defaultGrayGeneral {
        .menuItem.active{
            border:1px solid #fff;
            box-shadow:0 0 15px #a2794e;
        }
    }
    .menuItem.active.borderBlueShadow{
        box-shadow:0 0 15px #042fb8;
    }

    /*动态样式开始*/
    .home_middle.focusBorder .menuItem.active{
        border-radius:5px;
        color:#fff;
        transition:0.5s;
        border:2px solid #a2794e;
    }
    .focusBottomBorder.focusBottomBorder .menuItem.active{
        /*background:#114aa1;*/
        /*border-bottom:2px solid #fcac45;*/
    }
    .menuItemBox{
        display:flex;
    }
    /*动态样式结束*/
    .home_bottom{
        width:100%;
        height:350px;
        /*background:#ffecb3    ;*/
        display:flex;
        justify-content: space-between;
    }
    .bottom_left{
        width:42%;
        height:100%;
        position:relative;
    }
    .bottom_left>video{
        width:100%;
        height:100%;
        object-fit: cover;
    }
    .bottom_center{
        width:32%;
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .bottom_center>.top{
        width:100%;
        height:100%;
    }
    .bottom_center .top>img,.bottom_center .bottom>img{
        width: 100%;
        height:100%;
    }
    .modelTitle{
        width:100%;
        background:rgba(0,0,0,0.5);
        padding:5px;
        text-align: center;
        position:absolute;
        bottom:0;
        color:#fff;
        box-sizing:border-box;
    }
    .bottom_right{
        width:22%;
    }
    /*logo配置工具栏*/
    .logo_config_box{
        position:absolute;
        bottom:-35px;
        /*z-index:99999999999999999999999;*/
    }
    /*主页右上配置*/
    .home_top_config_box{
        position:absolute;
        top:0;
        right:-46px;
        /*right:-46px;*/
    }
    .home_bottom_config_box{
        position:absolute;
        bottom:0;
        right:-46px;
    }
    /*滚动字幕的confi_box*/
    .home_scroll_config_box{
        position:absolute;
        bottom:0;
        right:-46px;
    }
    .video_config_box{
        position:absolute;
        bottom:-33px;
        left:0;
    }
    /*配置开始*/
    .configBox{
        display:flex;
    }
    .configBox.logo,.configBox.modelOne{
        position:absolute;
        left:-75px;
        top:0;
    }
    .configBox.modelTwo{
        position:absolute;
        right:-90px;
        top:0;
    }
    .configBox.modelThree,.configBox.modelFour{
        position:absolute;
        bottom:-43px;
    }
    .configBox .btnItem{
        white-space:nowrap;
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.8)!important;
        cursor:pointer;
    }
    .configBtn{
        display:none;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .detail_box{
        /*background:#252527;*/
        background-color:rgba(0,0,0,0.8)!important;
        padding:5px 0;
    }
    .detail_box.up{
        position:absolute;
        /*left:10px;*/
        bottom:40px;
    }
    .detail_box.down{
        position:absolute;
        top:40px;
    }
    .detail_box .item{
        height:36px;
        color:#fff;
        padding:0 20px;
        cursor:pointer;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        transition:0.5s;
    }
    .slideBox{
        /*display:none;*/
        /*overflow:hidden;*/
        transition:2s;
    }
    .btnGroup{
        display:flex;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
        z-index:9999;
    }
    .configMarginRight{
        margin-right:10px;
    }
    .configMarginLeft{
        margin-left:10px;
    }
    .highIndex{
        z-index:100;
    }
    .color_match>.item,.more_file>.item{
        width:100%;
        height:36px;
        padding:0 20px;
        display:flex;
        align-items: center;
        font-size:14px;
        box-sizing:border-box;
    }
    .color_match>.item.active{
        background:#1b90ed;
    }
    .item.active .check_icon{
        visibility: visible;
    }
    .item .check_icon{
        visibility: hidden;
    }
    .color_match>.item>.check_icon{
        width:18px;
        height:20px;
        margin-right:10px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .color_match>.item>.show_icon{
        width:44px;
        height:24px;
        margin-right:10px;
        display:inline-block;
    }
    .noWarp{
        white-space:nowrap;
    }
    img,video{
        width:100%;
        height:100%;
        outline:none!important;
    }
    .arrowIcon.down{
        transform:rotate(180deg);
    }
    .noneOutLine{

    }
    /*配置结束*/
    .borderBox{
        border:2px dashed transparent;
        transition: 0.5s;
        cursor:pointer;
    }
    .borderBox:hover{
        border:2px dashed #1b90ed;
        transition: 0.5s
    }
    .borderBox.noHover:hover{
        border:2px dashed transparent;
        transition: 0.5s
    }
    .shade{
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.6);
        position:absolute;
        top:0;
        left:0;
        z-index:99;
        cursor:pointer;
        transition:0.5s;
    }
    .noneEl{
        visibility:hidden;
    }
    .videoBox{
        height:60%;
        position:relative;
        box-sizing: border-box;
    }
    .descBox{
        height:38%;
        margin-top:2%;
        color:#fff;
        padding:10px;
        line-height:30px;
        background:rgba(255,255,255,0.4);
        box-sizing: border-box;
    }
    .relativeEl{
        position:relative;
    }
    .descForm{
        height:100%;
        .textBorderBox{
            height:100%;

        }

    }
    .fullHeight{
        height:100%;
    }
     .home_middle.blueBg{
        background: #0C5DC6;
    }
</style>

<style>
    .textBorderBox textarea{
        color: inherit;
    }
</style>

