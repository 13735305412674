import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        visualContent: {},
        publishRoomStr: "",
        curDate: {
            yesDay: '',
            curDay: '',
            startWeek: '',
            endWeek: '',
            startMonth: '',
            endMonth: '',
            startYear: '',
            endYear: '',
        },
        configData: {

        }
    },
    getters: {
        publishRoomStr: state => {
            return state.publishRoomStr
        }
    },
    mutations: {
        saveVisualContent(state, val) {
            for (const key in val) {
                const element = val[key];
                state.visualContent[key] = element;
            }
        },
        publishRoomStr(state, n) {
            // 变更状态
            console.log(111, n);
            state.publishRoomStr = n;
            console.log(state.publishRoomStr);
        },
        getYesDay(state) {
            var time = (new Date).getTime() - 24 * 60 * 60 * 1000;
            var yesterday = new Date(time);
            var month = yesterday.getMonth() + 1;
            var day = yesterday.getDate();
            state.curDate.yesDay = yesterday.getFullYear() + "-" + (month > 9 ? (month) : "0" + (month)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
        },
        getCurDay(state) {
            var time = (new Date).getTime();
            var today = new Date(time);
            var month = today.getMonth() + 1;
            var day = today.getDate();
            state.curDate.curDay = today.getFullYear() + "-" + (month > 9 ? (month) : "0" + (month)) + "-" + (today.getDate() > 9 ? (today.getDate()) : "0" + (today.getDate()));
        },
        add0(m) {
            return m < 10 ? '0' + m : m
        },
        getCurWeek() {
            var startStop = new Array();
            //获取当前时间
            var currentDate = new Date();
            //返回date是一周中的某一天
            var week = currentDate.getDay();
            //返回date是一个月中的某一天
            var month = currentDate.getDate();
            //一天的毫秒数
            var millisecond = 1000 * 60 * 60 * 24;
            //减去的天数
            var minusDay = week != 0 ? week - 1 : 6;
            //alert(minusDay);
            //本周 周一
            var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
            //本周 周日
            var sunday = new Date(monday.getTime() + (6 * millisecond));
            var sy = monday.getFullYear();
            var sm = monday.getMonth() + 1;
            var sd = monday.getDate();
            var ey = sunday.getFullYear();
            var em = sunday.getMonth() + 1;
            var ed = sunday.getDate();
            // var s = sy+'-'+add0(sm)+'-'+add0(sd)+' 00:00:00';//开始
            // var e = ey+'-'+add0(em)+'-'+add0(ed)+' 23:59:59';//结束
            var s = sy + '-' + this.add0(sm) + '-' + this.add0(sd); //开始
            var e = ey + '-' + this.add0(em) + '-' + this.add0(ed); //结束
            this.curDate.startWeek = s;
            this.curDate.endWeek = this.curDate.yesDay;
        },
        getCurMonth(state) {
            var type = 's';
            var months = 0;
            var d = new Date();
            var year = d.getFullYear();
            var month = d.getMonth() + 1;
            if (Math.abs(months) > 12) {
                months = months % 12;
            }
            if (months != 0) {
                if (month + months > 12) {
                    year++;
                    month = (month + months) % 12;
                } else if (month + months < 1) {
                    year--;
                    month = 12 + month + months;
                } else {
                    month = month + months;
                };
            };
            month = month < 10 ? "0" + month : month;
            var date = d.getDate();
            var firstday = year + "-" + month + "-" + "01";
            var lastday = "";
            if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
                lastday = year + "-" + month + "-" + 31;
            } else if (month == "02") {
                if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                    lastday = year + "-" + month + "-" + 29;
                } else {
                    lastday = year + "-" + month + "-" + 28;
                };
            } else {
                lastday = year + "-" + month + "-" + 30;
            };
            var day = "";
            if (type == "s") {
                day = firstday;
            } else {
                // day = lastday;
                day = this.curDate.curDay;
            }
            return day;
        },
        getConfigData(state, val) {
            if (!val.tab) {
                //hihotel酒店
                state.configData.tab = JSON.parse(JSON.stringify(val));
                state.configData.tab.home.title = '主页';
                state.configData.tab.welcomePage.title = '欢迎页';
                state.configData.template_id = val.template_id;
                delete state.configData.tab.template_id;
            } else {
                //ehotel酒店
                state.configData = JSON.parse(JSON.stringify(val));
            }
        },
        editConfigData(state, obj) {
            var { tabActiveName, pic } = obj;
            state.configData.tab[tabActiveName].pic = JSON.parse(JSON.stringify(pic));
        },
    },
    actions: {},
    modules: {}
})