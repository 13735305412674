<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">信息发布</span>
        <span slot="third">定时插播</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <!-- 操作栏 -->
      <div>
        <el-form ref="form" :inline="true" :model="form" label-position="left">
          <el-form-item label="">
            <el-select v-model="form.publish_status" placeholder="发布状态" clearable>
              <el-option label="已发布" value="2">
              </el-option>
              <el-option label="已下架" value="3">
              </el-option>
              <el-option label="已结束" value="4">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="form.publish_type" placeholder="发布类型" clearable>
              <el-option label="弹窗" value="1">
              </el-option>
              <el-option label="滚动字幕" value="2">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="请输入任务名称关键词"></el-input>
          </el-form-item>
          <el-form-item v-if="auth.type!='monomer'">
            <el-input v-model="form.hotel_contract" placeholder="请输入酒店合同号"></el-input>
          </el-form-item>
          <el-form-item>

            <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
            <el-button type="primary" class="largeBtn" @click="reset">重置</el-button>

            <!-- <el-button type="primary" class="largeBtn" :loading='btnLoading' @click="importTable">导出</el-button> -->
          </el-form-item>
        </el-form>

      </div>
      <!-- 操作栏 -->

    </el-card>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">插播列表</div>
      <div class="btnRow">
        <el-button type="primary" class="largeBtn" @click="toCreate">新 建</el-button>
      </div>

      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent">\
        <template v-slot:publish_type='item'>
          <span>{{item.row.publish_type==1?'弹窗':'滚动字幕'}}</span>
        </template>
        <template v-slot:publish_status='item'>
          <span v-if="item.row.publish_status==2">已发布</span>
          <span v-if="item.row.publish_status==3">已下架</span>
          <span v-if="item.row.publish_status==4">已结束</span>
        </template>
      </operationTable>
      <!-- 表格 -->

    </el-card>
    <el-dialog :title="changeRoomTitle" :visible.sync="changeRoomVisible" :before-close="closeAddhotel" width="1080px" :modal-append-to-body="false">

      <checkroom ref="checkroom" :hotel_contract="hotel_contract" :delRoomList='delRoomList'></checkroom>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button class="cancelBtn" @click="changeRoomVisible=false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 单店显示 -->
    <el-dialog title='展示详情' :visible.sync="showRoomVisible" width="60%">

      <div class="legend">
        <div class="noShow"></div>
        <div>未展示</div>
        <div class="trueShow"></div>
        <div>已展示</div>
        <div class="downNoShow"></div>
        <div>下架未展示</div>
        <div class="downTrueShow"></div>
        <div>下架已展示</div>
      </div>
      <div class="showContent">
        <template v-for="(item, index) in showRoomList">
          <el-tooltip class="item" effect="dark" :content="item.room_num" placement="bottom" :key="index">
            <span :class="['roomCard', item.status == 2 ? 'roomCardActive' : '',item.status == 3 ? 'roomCardActive1' : '',item.status == 4 ? 'roomCardActive2' : '']">{{ item.room_num }}</span>
          </el-tooltip>
        </template>

      </div>
      <span slot="footer" class="dialog-footer">

        <el-button class="cancelBtn" @click="showRoomVisible=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import checkroom from "../hotelGroup/components/checkroom.vue";
import {
  getSendMessage,
  saveMessageRoom,
  getMessageRoom,
} from "./hotelMsgReleaseApi";
export default {
  name: "hotelMsgReleaseList",
  components: {
    checkroom,
  },
  data() {
    return {
      auth: {},
      form: {
        name: "",
        publish_status: "",
        publish_type: "",
        hotel_contract: "",
      },
      bread: {
        level: 3,
      },
      showRoomVisible: false,
      showRoomList: [],
      signList: [],
      btnLoading: false,
      changeRoomVisible: false,
      changeRoomTitle: "新增",
      send_id: "",
      hotel_contract: "",
      delRoomList: [],
      // 表格数据信息
      tableData: {
        tableList: [{}],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "任务名称",
            prop: "name",
            minWidth: "280",
            type: "text",
          },
          //  {
          //   label: "酒店合同号",
          //   prop: "hotel_contract",
          //   minWidth: "250",
          //   type: "text",
          // },
          {
            label: "发布状态",
            prop: "publish_status",
            minWidth: "100",
            type: "text",
            isSlot: true,
          },
          {
            label: "发布周期",
            prop: "time",
            minWidth: "300",
            type: "text",
          },
          {
            label: "发布类型",
            prop: "publish_type",
            minWidth: "100",
            type: "text",
            isSlot: true,
          },
          {
            label: "发布人",
            prop: "nickname",
            minWidth: "120",
            type: "text",
          },
          {
            label: "发布时间",
            prop: "createtimeText",
            minWidth: "160",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "280",
            type: "button",
            prop: "num",
            needDisabled: true,
            // 详情
            info: (item) => {
              console.log(this.$route.path);
              let currentUrl = this.$route.path;
              if (currentUrl == "/hotelMsgReleaseList") {
                this.$router.push({
                  path: `/hotelMsgReleaseInfo`,
                  query: {
                    item: item,
                  },
                });
              } else {
                this.$router.push({
                  path: `/onePage/hotelMsgReleaseInfo`,
                  query: {
                    item: item,
                  },
                });
              }
            },
            result: (item) => {
              this.showRoomVisible = true;

              getMessageRoom({ send_id: item.id }).then((res) => {
                if (res.data.code == 100000) {
                  console.log(res.data.data, "发布追踪");
                  this.showRoomList = res.data.data;
                } else {
                  this.$message.warning(res.data.msg);
                }
              });
            },
            addRoom: (item) => {
              this.changeRoomVisible = true;
              this.changeRoomTitle = "新增";
              this.hotel_contract = item.hotel_contract;
              this.send_id = item.id;
              this.delRoomList = [];
              let roomList = item.send_room.map((i) => {
                return i.room_num;
              });
              this.$nextTick(() => {
                this.$refs.checkroom.showCurrent = true;
                this.$refs.checkroom.intData(roomList, this.hotel_contract);
              });
            },
            downRoom: (item) => {
              this.changeRoomVisible = true;
              this.changeRoomTitle = "下架";
              this.hotel_contract = item.hotel_contract;
              this.delRoomList = item.send_room.map((i) => {
                return i.room_num;
              });
              this.send_id = item.id;
            },
          },
        ],
      },
      // 已选项
      checkList: [],
    };
  },

  methods: {
    closeAddhotel() {
      this.changeRoomVisible = false;
      this.changeRoomTitle = "";
      this.send_id = "";
      this.delRoomList = [];
    },
    submit() {
      this.$confirm("是否确认当前操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let list = this.$refs.checkroom.showRoomList.rooms;
        let json = {
          send_id: this.send_id,
          hotel_contract: this.hotel_contract,
          room_data: JSON.stringify(list),
        };
        if (this.changeRoomTitle == "新增房间") {
          json.type = 1;
        } else {
          json.type = 2;
        }
        saveMessageRoom(json).then((res) => {
          if (res.data.code == 100000) {
            this.$message.success(this.changeRoomTitle + "成功");
            this.changeRoomVisible = false;
            this.changeRoomTitle = "";
            this.send_id = "";
            this.getTableData();
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      });
    },
    toCreate() {
      let currentUrl = this.$route.path;
      if (currentUrl == "/hotelMsgReleaseList") {
        this.$router.push("/hotelMsgReleaseInfo");
      } else {
        this.$router.push("/onePage/hotelMsgReleaseInfo");
      }
    },
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    reset() {
      this.tableData.limit = 10;
      this.tableData.page = 1;
      this.form.name = "";
      this.form.publish_status = "";
      this.form.publish_type = "";
      this.form.hotel_contract = "";
      //   this.form.time = [];
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },

    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p ? p : 1,
        size: s ? s : 10,
        name: this.form.name,
        publish_status: this.form.publish_status,
        publish_type: this.form.publish_type,
        hotel_contract: this.form.hotel_contract,
      };
      getSendMessage(json)
        .then((res) => {
          if (res.data.code == 100000) {
            console.log(res, "列表数据");
            if (res.data.data.data.length > 0) {
              this.tableData.tableList = res.data.data.data.map((i) => {
                i.time = i.start_date + "~" + i.end_date;
                return i;
              });
            } else {
              this.tableData.tableList = [];
            }

            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
.el-form-item {
  margin-bottom: 0px;
}
.legend {
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.legend div {
  width: 100px;
  height: 20px;

  text-align: left;
  line-height: 20px;
}

.legend .noShow {
  width: 40px;
  height: 20px;
  background: #fff;
  border: 1px solid #31a5bc;
  color: #31a5bc;
  margin-right: 10px;
}
.legend .trueShow {
  width: 40px;
  height: 20px;
  border: #658bff;
  background: #658bff;
  margin-left: 20px;
  margin-right: 10px;
}
.legend .downTrueShow {
  width: 40px;
  height: 20px;
  border: #ccc;
  background: #ccc;
  margin-left: 20px;
  margin-right: 10px;
}
.legend .downNoShow {
  width: 40px;
  height: 20px;
  border: 1px solid #ccc;
  background: #fff;
  margin-left: 20px;
  margin-right: 10px;
}
.showContent {
  border: 1px solid #eee;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
}
.roomCard {
  display: inline-block;
  padding: 0px 20px;
  background: #fff;
  border: 1px solid #658bff;
  color: #658bff;
  box-sizing: border-box;
  width: 24.3%;
  margin-right: 0.6%;
  height: 36px;
  line-height: 36px;
  /* margin-left: 2.5%; */
  text-align: center;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.roomCard:nth-child(4n) {
  margin-right: 0;
}
.roomCardActive {
  border: 1px solid #658bff;
  background: #658bff;
  color: #fff;
}
.roomCardActive1 {
  border: 1px solid #ccc;
  background: #fff;
  color: #ccc;
}
.roomCardActive2 {
  border: 1px solid #ccc;
  background: #ccc;
  color: #fff;
}
</style>
