<template>
    <el-card class="box-card" v-if="showPartProp.self || showPartProp.other || showPartProp.videoRoom || showPartProp.virtual || showPartProp.date">
        <div class="quickSearchBox">
            <div class="quickDateSearch" v-if="showPartProp.date">
                <div class="item" @click="dateSearch('yesDay')" :class="dateCurActive('yesDay')">昨日</div>
                <div class="item" @click="dateSearch('week')" :class="dateCurActive('week')">本周</div>
                <div class="item" @click="dateSearch('month')" :class="dateCurActive('month')">本月</div>
                <div class="item" @click="dateSearch('year')" :class="dateCurActive('year')">本年</div>
            </div>
            <div class="quickDateSearch" :class="{rightBtn:showPartProp.date}">
                <div class="groupFilter" v-if="showPartProp.reqUrl">
                    <div class="item"  v-if="showPartProp.belong" @click="belongSearch('belong')" :class="[belongCurActive('belong')]">所属</div>
                    <div class="item"  v-if="showPartProp.allot" @click="belongSearch('allot')" :class="[belongCurActive('allot')]">归属</div>
                    <div class="item"  v-if="showPartProp.htrip" @click="belongSearch('htrip')" :class="[belongCurActive('htrip')]">htrip</div>
                    <div class="item"  v-if="showPartProp.subordinate" @click="belongSearch('subordinate')" :class="[belongCurActive('subordinate')]">下级收益</div>
                </div>
                <div class="groupFilter">
                    <div class="item" v-if="showPartProp.self" @click="natureSearch('self')" :class="[natureCurActive('self'),]">自有</div>
                    <div class="item" v-if="showPartProp.other" @click="natureSearch('other')" :class="[natureCurActive('other')]">聚屏</div>
                    <div class="item"  v-if="showPartProp.videoRoom" @click="natureSearch('videoRoom')" :class="[natureCurActive('videoRoom')]">影音房</div>
                    <div class="item" v-if="showPartProp.virtual" @click="natureSearch('virtual')" :class="[natureCurActive('virtual')]">虚拟运营</div>
                </div>
            </div>
        </div>
        <div class="shade" v-if="!allLoadingState"></div>
    </el-card>
</template>
<script>
    import SearchBus from './searchBus'
    import ExportBtn from "../ExportBtn"
    export default {
        name: "ScreenTypeSearch",
        props:{
            showPartProp:{},
            pageInfo:{
                type:Object,
                default:()=>{
                    return {
                        hotelState:''
                    }
                }
            },
            allLoadingState:{},
            setSessionDataProp:{
                default:true
            },
            dateCurProp:{
                default:''
            }
        },
        data:()=>({
            natureCur:['self'],
            curDate:{
                yesDay:'',
                curDay:'',
                startWeek:'',
                endWeek:'',
                startMonth:'',
                endMonth:'',
                startYear:'',
                endYear:'',
            },
            dateCur:'',
            belongCur:'belong',
            startTime:'',
            endTime:'',
        }),
        methods:{
            natureSearch(type){
                if(this.allLoadingState){
                    this.setTopSearchDefault(type);
                    var index=this.natureCur.indexOf(type);
                    if(index!==-1){
                        this.natureCur.splice(index,1);
                    }else{
                        this.natureCur.push(type);
                    }
                    this.setSessionData();
                    this.searchMeth();
                }
            },
            dateSearch(type){
                var curDate=this.curDate;
                this.setTopSearchDefault('date');
                if(this.dateCur===type){
                    this.dateCur='';
                    if(this.pageInfo.hotelState==='newList'){
                        this.startTime=curDate.curDay;
                        this.endTime=curDate.curDay;
                    }else{
                        this.startTime='';
                        this.endTime='';
                    }
                }else{
                    this.dateCur=type;
                    if(type==='week'){
                        this.startTime=curDate.startWeek;
                        this.endTime=curDate.endWeek;
                    }else if(type==='month'){
                        this.startTime=curDate.startMonth;
                        this.endTime=curDate.endMonth;
                    }else if(type==='year'){
                        this.startTime=curDate.startYear;
                        this.endTime=curDate.endYear;
                    }else if(type==='yesDay'){
                        this.startTime=curDate.yesDay;
                        this.endTime=curDate.yesDay;
                    }
                }
                this.setSessionData('date');
                this.searchMeth();
            },
            belongSearch(type){
                this.belongCur=type;
                this.searchMeth();
            },
            setTopSearchDefault(type){
                if(type==='virtual'){
                    SearchBus.$emit('setTopSearchDefault','hotel_use_state');
                }else if(type==='date'){
                    SearchBus.$emit('setTopSearchDefault','date');
                }
            },
            setSessionData(type){
                if(this.setSessionDataProp){
                    var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                    if(filterCon){
                        filterCon.screenType=this.natureCur;
                        if(type==='date'){
                            if(this.pageInfo.hotelState==='newList'){
                                filterCon.hotelCreateTimeStart=this.startTime;
                                filterCon.hotelCreateTimeEnd=this.endTime;
                            }else{
                                filterCon.startTime=this.startTime;
                                filterCon.endTime=this.endTime;
                            }
                            filterCon.dateCur=this.dateCur;
                        }
                        sessionStorage.setItem('filterCon',JSON.stringify(filterCon));
                    }
                }

            },
            searchMeth(){
                this.$emit('screenTypeSearch',this.natureCur.join(','),this.startTime,this.endTime,this.belongCur);
            },
            getYesDay(){
                var time=(new Date).getTime()-24*60*60*1000;
                var yesterday=new Date(time);
                var month=yesterday.getMonth()+1;
                var day=yesterday.getDate();
                this.curDate.yesDay=yesterday.getFullYear() + "-" + (month>9 ? (month) : "0" + (month)) + "-" +(yesterday.getDate()> 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
            },
            getCurDay(){
                var time=(new Date).getTime();
                var today=new Date(time);
                var month=today.getMonth()+1;
                var day=today.getDate();
                this.curDate.curDay=today.getFullYear() + "-" + (month> 9 ? (month) : "0" + (month)) + "-" +(today.getDate()> 9 ? (today.getDate()) : "0" + (today.getDate()));
            },
            add0(m){
                return m<10?'0'+m:m
            },
            getCurWeek(){
                var startStop = new Array();
                //获取当前时间
                var currentDate = new Date();
                //返回date是一周中的某一天
                var week = currentDate.getDay();
                //返回date是一个月中的某一天
                var month = currentDate.getDate();
                //一天的毫秒数
                var millisecond = 1000 * 60 * 60 * 24;
                //减去的天数
                var minusDay = week != 0 ? week - 1 : 6;
                //alert(minusDay);
                //本周 周一
                var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
                //本周 周日
                var sunday = new Date(monday.getTime() + (6 * millisecond));
                var sy = monday.getFullYear();
                var sm = monday.getMonth() + 1;
                var sd = monday.getDate();
                var ey = sunday.getFullYear();
                var em = sunday.getMonth() + 1;
                var ed = sunday.getDate();
                // var s = sy+'-'+add0(sm)+'-'+add0(sd)+' 00:00:00';//开始
                // var e = ey+'-'+add0(em)+'-'+add0(ed)+' 23:59:59';//结束
                var s = sy+'-'+this.add0(sm)+'-'+this.add0(sd);//开始
                var e = ey+'-'+this.add0(em)+'-'+this.add0(ed);//结束
                this.curDate.startWeek=s;
                this.curDate.endWeek=this.curDate.yesDay;
            },
            getCurMonth(type,months){
                var d = new Date();
                var year = d.getFullYear();
                var month = d.getMonth() + 1;
                if (Math.abs(months) > 12) {
                    months = months % 12;
                };
                if (months != 0) {
                    if (month + months > 12) {
                        year++;
                        month = (month + months) % 12;
                    } else if (month + months < 1) {
                        year--;
                        month = 12 + month + months;
                    } else {
                        month = month + months;
                    };
                };
                month = month < 10 ? "0" + month: month;
                var date = d.getDate();
                var firstday = year + "-" + month + "-" + "01";
                var lastday = "";
                if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
                    lastday = year + "-" + month + "-" + 31;
                } else if (month == "02") {
                    if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
                        lastday = year + "-" + month + "-" + 29;
                    } else {
                        lastday = year + "-" + month + "-" + 28;
                    };
                } else {
                    lastday = year + "-" + month + "-" + 30;
                };
                var day = "";
                if (type == "s") {
                    day = firstday;
                } else {
                    // day = lastday;
                    day=this.curDate.yesDay;
                };
                return day;
            },
            getCurYear(type,dates){
                var dd = new Date();
                var n = dates || 0;
                var year = dd.getFullYear() + Number(n);
                if (type == "s") {
                    var day = year + "-01-01";
                };
                if (type == "e") {
                    // var day = year + "-12-31";
                    var day=this.curDate.yesDay;
                };
                if (!type) {
                    var day = year + "-01-01/" + year + "-12-31";
                };
                return day;
            },
            getAllTimeMeth(){
                this.getYesDay();
                this.getCurDay();
                this.getCurWeek();
                this.curDate.startMonth=this.getCurMonth("s",0);
                this.curDate.endMonth=this.getCurMonth("e",0);
                this.curDate.startYear=this.getCurYear("s",0);
                this.curDate.endYear=this.getCurYear("e",0);
            },

        },
        mounted(){
            var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
            if(filterCon){
                var screenType=JSON.parse(sessionStorage.getItem('filterCon')).screenType;
                var dateCur=JSON.parse(sessionStorage.getItem('filterCon')).dateCur;
                if(screenType){
                    this.natureCur=screenType;
                }
                if(dateCur){
                    this.dateCur=dateCur;
                }
            }
            this.getAllTimeMeth();
            this.dateCur=this.dateCurProp;
        },
        created(){
            SearchBus.$on('setScreenTypeSearchDefault',type=>{
                if(type==='hotel_use_state'){
                    var index=this.natureCur.indexOf('virtual');
                    if(index!==-1){
                        this.natureCur.splice(index,1);
                    }
                }else if(type==='date'){
                    this.dateCur='';
                    this.startTime='';
                    this.endTime='';
                }
                else{
                    this.setSessionData();
                }
            })
        },
        computed:{
            natureCurActive(){
                return function(val){
                    var str='';
                    if(this.natureCur){
                        if(this.natureCur.indexOf(val)!==-1){
                            str='active'
                        }
                    }
                    return str;

                }
            },
            dateCurActive(){
                return function(val){
                    var str='';
                    if(this.dateCur){
                        this.dateCur===val?str='active':'';
                    }
                    return str;
                }
            },
            belongCurActive(){
                return function(val){
                    var str='';
                    if(this.belongCurActive){
                        this.belongCur===val?str='active':'';
                    }
                    return str;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .quickSearch{
        display:flex;
    }
    .quickSearch .item{
        border:1px solid #dcdfe6;
        color:#606266;
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
        padding: 12px 20px;
        cursor:pointer;
        margin-right:15px;

    }
    .quickDateSearch .item.active{
        color: #409EFF;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
    }
    .quickDateSearch .item.disabled{
        border-color: #ebeef5!important;
        color: #c0c4cc!important;
        cursor:wait;

    }
    .quickDateBox{
        display:flex;
        align-items: center;
    }
    .quickDateSearch{
        display:flex;
    }
    .quickDateSearch .item{
        border:1px solid #dcdfe6;
        color:#606266;
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
        // padding: 12px 20px;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor:pointer;
        margin-right:15px;
    }
    .quickDateSearch .item:last-child{
        margin-right:0;
    }
    .quickSearchBox{
        display:flex;
    }
    .groupFilter{
        display:flex;
        margin-right: 40px;
    }
    .groupFilter:last-child{
        margin-right:0;
    }
    .box-card{
        position:relative;
    }
    .shade{
        width:100%;
        height:100%;
        background:rgba(255,255,255,0.3);
        position:absolute;
        top:0;
        cursor:wait;
    }
</style>
