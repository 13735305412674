<template>
  <div>
    <!-- 筛选界面处理 -->
    <!-- 筛选界面处理 -->
    <el-card class="box-card searchCard">
      <el-input style="width:300px" placeholder="请输入酒店名称" v-model="hotelName" :clearable="true" class="input-with-select fullSelect">
      </el-input>
      <el-button style="margin-left:20px" type="primary" @click="search">搜 索</el-button>
      <el-button @click="reset">重 置</el-button>
    </el-card>
    <!-- 表格操作区 -->
    <el-card class="box-card">
      <div class="title blueTitle">NO广告酒店列表</div>
      <div class="btnRow">
        <!-- v-if="showFunModel('标签列表新建')" -->
        <el-button type="primary" @click="openCreatLabel">新 建</el-button>
        <el-button type="primary" class="largeBtn" @click="importList">导 出</el-button>
      </div>
      <el-table class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="loading">
        <el-table-column prop="id" label="ID" width="55"></el-table-column>
        <el-table-column prop="name" label="酒店名称" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip min-width="140">
          <template slot-scope="scope">
            <el-button type='text' @click="openInfo(scope.row)">标签详情</el-button>
            <el-button type='text' style="color:red" @click="delLabel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="hotel_contract" label="酒店合同号" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="200" show-overflow-tooltip></el-table-column>

        <!-- <el-table-column prop="status_text" label="状态"></el-table-column> -->
        <el-table-column prop="nickname" label="创建人"></el-table-column>
        <el-table-column prop="createtimeText" label="创建时间" min-width="160px"></el-table-column>

      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="新建NO广告酒店" :visible.sync="dialogCreateVisible" width="600px" :modal-append-to-body="false">
      <el-form ref="createForm" :rules="rules" :model="createForm" label-width="100px">
        <el-form-item label="选择酒店" prop='hotelId'>
          <el-select v-model="createForm.hotelId" placeholder="请选择酒店" class="hotelBrand fullSelect " filterable clearable @change="changeMeth" remote reserve-keyword :remote-method="remoteMethod" :loading="hotelLoading">
            <el-option v-for="(item) in hotelGroupList" :key="item.id" :label="item.hotel" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注说明" prop='remark'>
          <el-input v-model="createForm.remark" placeholder="请输入备注说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" :loading='subLoading' @click="checkTrue('createForm')">确 定</el-button>
        <el-button class="cancelBtn" @click="checkCancel">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="标签详情" :visible.sync="labelVisible" width="600px" :modal-append-to-body="false">
      <div v-loading="labelLoading">
        <el-tag style="margin: 0 10px 10px 0 " v-for="tag in labelList" :key="tag">
          {{tag}}
        </el-tag>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="labelVisible=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addNoAdv,
  getNoAdvList,
  delNoAdv,
  getLabelByHotel,
  exportHotel,
  exportAhotel
} from "@/api/label.js";
import qs from "querystring";
export default {
  name: "groupNoAdv",
  props: ["funArr"],

  data() {
    return {
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      createForm: {
        hotelId: "",
        hotelName: "",
        type: 0,
        remark: "",
        hotelContract: "",
      },
      rules: {
        hotelId: [{ required: true, message: "请选择酒店", trigger: "blur" }],
      },
      dialogCreateVisible: false,
      tableList: [{}],
      loading: false,
      selectIdArr: [],
      hotelGroupList: [],
      token: "",
      hotelName: "",
      hotelLoading: false,
      labelVisible: false,
      labelLoading: false,
      subLoading: false,
      labelList: [],
      auth:null,
    };
  },
  watch: {
    funArr: {
      handler: function (newVal) {
        this.funArr = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
        this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getNoAdvList();
  },
  computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    importList() {
      exportHotel()
        .then((res) => {
          if (!res.data.code) {
            console.log(res);
            const blobUrl = window.URL.createObjectURL(res.data);
            const title = `NO广告酒店列表.xlsx`;
            // const title = `${this.tableName}.csv`;
            const a = document.createElement("a");
            a.style.display = "none";
            a.download = title;
            a.href = blobUrl;
            a.click();
            document.body.removeChild(a);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    openInfo(item) {
      this.labelList = [];
      this.labelVisible = true;
      this.labelLoading = true;
      getLabelByHotel({ hotel_contract: item.hotel_contract })
        .then((res) => {
          if (res.data.code == 100000) {
            this.labelList = res.data.data.label;
          } else {
            this.$message.warning(res.data.msg);
          }
          this.labelLoading = false;
        })
        .catch((err) => {
          this.$message.warning("标签信息获取失败");
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.hotelLoading = true;

        var params = {
          token: this.token,
          keyword: query,
          page: 1,
          per_page: 15,
          sort: "id",
          order: "DESC",
          screenType: "self",
          refresh: false,
        };
        this.$http
          .get(this.global.search, {
            params,
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var tableData = res.data.data;
              this.hotelGroupList = tableData.items;
              this.hotelLoading = false;
            }
          });
      } else {
        this.hotelGroupList = [];
      }
    },
    changeMeth() {
      if (this.createForm.hotelId == "") {
        this.createForm.hotelName = "";
        this.createForm.hotelContract = "";
        return;
      }
      this.hotelGroupList.forEach((i, n) => {
        if (this.createForm.hotelId == i.id) {
          this.createForm.hotelName = i.hotel;
          this.createForm.hotelContract = i.hotel_contract;
        }
      });
    },
    checkTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 确认
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.subLoading = true;
              const json = {
                hotel_id: this.createForm.hotelId,
                remark: this.createForm.remark,
                type: 2,
                name: this.createForm.hotelName,
                hotel_contract: this.createForm.hotelContract,
                 user_name:this.auth.user_name,
              };
              addNoAdv(json)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("添加成功");
                    this.$emit("updata");
                    this.getNoAdvList();
                    this.dialogCreateVisible = false;
                  } else {
                    this.$message.warning(res.data.msg);
                  }

                  this.subLoading = false;
                })
                .catch((err) => {});
            })
            .catch(() => {});
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    checkCancel() {
      this.dialogCreateVisible = false;
    },
    search() {
      this.table.page = 1;
      this.getNoAdvList();
    },
    reset() {
      this.hotelName = "";
      this.table.page = 1;
      this.getNoAdvList();
    },
    openCreatLabel() {
      this.dialogCreateVisible = true;
      this.createForm = {
        hotelId: "",
        hotelName: "",
        type: 2,
        remark: "",
        hotelContract: "",
      };
    },
    // 删除
    delLabel(item) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let json = {
            id: item.id,
             user_name:this.auth.user_name,
          };
          var param = qs.stringify(json);
          delNoAdv(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("删除成功");
                this.$emit("updata");
                this.getNoAdvList();
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },

    // 分页
    handleSizeChange(val) {
      this.table.limit = val;
      this.getNoAdvList();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getNoAdvList();
    },
    // 获取列表
    getNoAdvList() {
      this.loading = true;
      const json = {
        page: this.table.page,
        size: this.table.limit,
        type: 2,
        name: this.hotelName,
      };
      getNoAdvList(json)
        .then((res) => {
          this.tableList = res.data.data.data;
          this.table.total = res.data.data.total;
          this.loading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.searchCard {
  padding-top: 20px;
}
.searchBtn {
  position: fixed;
  top: 110px;
  right: 10px;
}
.btnRow {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-top: 20px;
}
.btnRow .el-button {
  width: 100px;
  height: 40px;
}
</style>