<template>
  <div>
    <!-- <div style="padding-bottom: 20px">酒店名称：{{query.hotel}}</div> -->
    <div class="btnRow">
      <el-form ref="ruleForm" :inline="true" :model="listQuery" class="demo-form-inline">
      <el-form-item label="" prop="cmd_type">
        <el-select v-model="listQuery.cmd_type" placeholder="请选择">
          <el-option
            v-for="item in cmdList"
            :key="item.cmdType"
            :label="item.cmdName"
            :value="item.cmdType">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="" prop="hotel_name">
        <el-input v-model="listQuery.hotel_name" placeholder="酒店名称"></el-input>
      </el-form-item> -->
      <el-form-item label="" prop="room_no">
        <el-input v-model="listQuery.room_no" placeholder="房间号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="create_time">
        <el-date-picker
          v-model="listQuery.create_time"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择创建日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="close">
        <span>结果筛选：</span>
         <el-switch
          v-model="listQuery.close"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="on"
          inactive-value="off">
        </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
         <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changelimit" @defaultpage="changeCurrent" @defaultcheck="changeSelection" >
      <template 
        slot="operation" 
        slot-scope="{row}" >
        <el-image
        v-if="row.cmdType == 'screenshot' && row.info != null && row.info != ''"
        style="width: 100px; height: 100px"
        :src="row.info"
        fit="fit"
         :preview-src-list="[row.info]"></el-image>
        <a v-else-if="filterfn(row)" :href="row.info" target="_blank">{{row.info}}</a>
        <a v-else :href="`http://hihotelservice.ctlife.tv:8899/supertoolserver/${row.info}`" target="_blank">{{row.info}}</a>
      </template>
    </operationTable>
  </div>
</template>
<script>
import { getList,getCmdList} from "../../api/releaseList";
// import { getCmdList} from "../../api/releaseList";
// import { getList} from "../../api/PRO_REALEASE";
export default {
  props: {
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  name: "releaseList",
  data() {
    return {
      tableData: {
        tableList: [ ],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 2,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "80",
            type:"text",
          },
          // {
          //   label: "酒店名称",
          //   prop: "hotelName",
          //   minWidth: "100",
          //   type:"text",
          // },
          {
            label: "房间号",
            prop: "roomNo",
            minWidth: "100",
            type:"text",
          },
          {
            label: "指令说明",
            prop: "cmdName",
            minWidth: "150",
            type:"text",
          },
          {
            label: "参数",
            prop: "cmdParam",
            minWidth: "200",
            type:"text",
            showTooltip: true,
          },
          {
            label: "发送结果",
            prop: "state1",
            minWidth: "100",
            type:"text",
          },
          {
            label: "反馈结果",
            prop: "state2",
            minWidth: "100",
            type:"text",
          },
          {
            label: "反馈数据",
            prop: "info",
            minWidth: "150",
            type:"text",
            isSlot: true,
            showTooltip: true,
          },
          {
            label: "操作者",
            prop: "uname",
            minWidth: "120",
            type:"text",
          },
          {
            label: "时间",
            prop: "created_at",
            minWidth: "130",
            type:"text",
          },
        ],
      },
      cmdList: [],
      listQuery: {
        database: '',
        hotel_contract: '',
        cmd_type: '',
        hotel_name: '',
        room_no: '',
        create_time: '',
        close: '',
        page: 1,
        limit: 10
      },
      checkList: [],
    };
  },
  methods: {
    filterfn(row){
      if (!row.info)return false;
      return row.info.indexOf('http') != -1;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$refs.operationTable.resetTable();
      this.listQuery.close = '';
      this.getList(1);
    },
    changelimit(s, p) {
      this.listQuery.page = p ;
      this.listQuery.limit = s ;
      this.getList();      
    },
    changeCurrent(s, p) {
      this.listQuery.page = p ;
      this.listQuery.limit = s ;
      this.getList();
      
    },
    changeSelection(val) {
        this.checkList = val.map(v=>{
          return v.id
        })
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
        this.$refs.operationTable.resetTable();
      } 
      this.listQuery.database = this.query.hotelName;
      this.listQuery.hotel_contract = this.query.hotel_contract;
      getList(this.listQuery).then(res => {
        if (res.data.code == 100000) {
          this.$refs.operationTable.changeTotal(Number(res.data.data.total));
          if (res.data.data.total > 0) {
            this.tableData.tableList = res.data.data.list;
          } else {
            this.tableData.tableList = []
          }
        }
        this.tableData.tableLoading = false;
      })
    },
    search() {
      this.$refs.operationTable.resetTable();
      this.getList(1);
    }
  },
  mounted(){
    getCmdList().then(res => {
      if (res.data.code == 100000) {
        this.cmdList = res.data.data;
      }
    })
    this.listQuery.close = '';
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map(i=>{
      if(i.ltype==1){
        i.language ="中文"
      }else if(i.ltype==2){
         i.language ="英文"
      }else if(i.ltype==3){
         i.language ="日文"
      }else if(i.ltype==4){
         i.language ="韩文"
      }
      return i
    })
  },
  watch: {
    query: {
      handler: function (newVal) {
        this.query=newVal;
        this.listQuery.close = '';
        this.$nextTick(()=> {
          this.getList(1);
        })
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
