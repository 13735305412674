<template>
  <div class="outItem" v-if="transForm.group.length>0">
    <div class="top">
      <span>{{title}}</span>
    </div>
    <div class="bottom">
      <el-form :model="transForm" ref="form" class="transForm">
        <div class="item" v-for="(item,index) in transForm.group" v-if="type!=='defineValid'">
          <div class="colBox firstCol" v-if="hotelOrPartner==='partner' && type!=='flash' && type!=='market'">
            <span class="el-form-item" style="width:30px">大于</span>
            <el-form-item :prop="'group.'+index+'.num'" :rules="rules.numRules">
              <el-input v-model="item.num" style="width:150px" placeholder="数量" class="innerInput" :readonly="executingReadOnly" type="number" min="0">
                <template slot="append">{{transUnit(item.base_on)}}</template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="sys!==true&&index==0" class="principleSpan principleFormItem">
              <el-select style="width:160px" v-model="settlePrinciple" class="fullSelect" @change="principleChange" :disabled="executingReadOnly" v-if="sys!==true">
                <el-option v-if="sys!==true&&(type!=='flash' && type!=='market')" v-for="item in selectData.settlePrincipleAllSelect" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
                <el-option v-if="sys!==true&&(type==='flash' || type==='market')" v-for="item in selectData.settlePrincipleOnlyTerSelect" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <span class="el-form-item principleSpan" v-if="index!==0||sys==true">{{item.base_on_text}}</span>
            <!--<span class="el-form-item">按</span>-->
            <!--<el-form-item :prop="'group.'+index+'.price'" :rules="rules.priceRules">-->
            <!--<el-input v-model="item.price" placeholder="请输入内容" class="innerInput" :readonly="executingReadOnly">-->
            <!--<template slot="append">{{type==='flash' || type==='market'?'元 / 台':'%'}}</template>-->
            <!--</el-input>-->
            <!--</el-form-item>-->
            <!--<el-form-item v-if="index===0 && hotelOrPartner==='partner' && (type==='flash' || type==='market')" class="principleSpan priceItem" >-->
            <!--<el-select v-model="settlePrinciple" class="fullSelect" @change="principleChange" :disabled="executingReadOnly">-->
            <!--<el-option v-if="type!=='flash' && type!=='market'"-->
            <!--v-for="item in selectData.settlePrincipleAllSelect"-->
            <!--:key="item.key"-->
            <!--:label="item.value"-->
            <!--:value="item.key">-->
            <!--</el-option>-->
            <!--<el-option v-if="type==='flash' || type==='market'"-->
            <!--v-for="item in selectData.settlePrincipleOnlyTerSelect"-->
            <!--:key="item.key"-->
            <!--:label="item.value"-->
            <!--:value="item.key">-->
            <!--</el-option>-->
            <!--</el-select>-->
            <!--</el-form-item>-->
            <!--<span class="el-form-item">结算</span>-->
          </div>
          <div class="colBox">
            <span class="el-form-item">按</span>
            <el-form-item :prop="'group.'+index+'.price'" :rules="rules.priceRules">
              <el-input v-model="item.price" placeholder="百分比" style="width:160px" class="innerInput" :readonly="executingReadOnly" type="number" min="0">
                <template slot="append">{{type==='flash' || type==='market'?'元 / 台':'%'}}</template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="index===0 && hotelOrPartner==='partner' && (type==='flash' || type==='market')" class="principleSpan priceItem">
              <el-select v-model="settlePrinciple" class="fullSelect" @change="principleChange" :disabled="executingReadOnly">
                <el-option v-if="type!=='flash' && type!=='market'" v-for="item in selectData.settlePrincipleAllSelect" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
                <el-option v-if="type==='flash' || type==='market'" v-for="item in selectData.settlePrincipleOnlyTerSelect" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <span class="el-form-item" style="width:30px">结算</span>
          </div>
          <div class="colBox" v-if="(authType==='htrip' && type!=='flash' && type!=='market' && hotelOrPartner==='partner'&&type!=='cinemaOne'&&type!=='cinemaCard'&&type!=='independentOne'&&type!=='independentCard'&&type!=='vodonline'&&type!=='card')">
            <span class="el-form-item" style="width:100px">归属合作伙伴按</span>
            <el-form-item :prop="'group.'+index+'.allot_price'" :rules="rules.allotPriceRules">
              <el-input v-model="item.allot_price" style="width:130px" placeholder="请输入内容" class="innerInput" :readonly="executingReadOnly" type="number" min="0">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <span class="el-form-item" style="width:30px">结算</span>
          </div>
          <div class="colBox" v-if="!executingReadOnly">
            <el-button class="operateBtn" icon="el-icon-plus" size="mini" circle @click.prevent="partAdd(type,index)" v-if="!executingReadOnly && hotelOrPartner==='partner' &&(type!=='flash' && type!=='market')"></el-button>
            <el-button class="operateBtn" icon="el-icon-minus" size="mini" circle @click.prevent="partReduce(type,index)"></el-button>
          </div>
        </div>
        <div class="item" v-for="(item,index) in transForm.group" v-if="type==='defineValid'">
          <div class="colBox">
            <el-form-item :prop="'group.'+index+'.num'" :rules="rules.numRules">
              <el-input v-model="item.num" placeholder="请输入内容" class="innerInput" :readonly="executingReadOnly" type="number" min="0">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <span class="el-form-item ">内</span>
          </div>
          <div class="colBox">
            <span class="el-form-item" style="width:40px">任意有</span>
            <el-form-item :prop="'group.'+index+'.price'" :rules="rules.priceRules">
              <el-input v-model="item.price" placeholder="请输入内容" class="innerInput" :readonly="executingReadOnly" type="number" min="0">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <span class="el-form-item" style="width:100px">心跳的激活终端</span>
          </div>
          <div class="colBox" v-if="!executingReadOnly">
            <el-button class="operateBtn" icon="el-icon-minus" size="mini" circle @click.prevent="partReduce(type,index)"></el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "RateItem",
  inheritAttrs: false,
  props: [
    "form",
    "type",
    "id",
    "title",
    "executingReadOnly",
    "hotelOrPartner",
    "year",
    "sys",
  ],
  data() {
    var checkNumber = (rule, value, callback) => {
      if (value < 0) {
        return callback(new Error("不能小于0"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        numRules: [
          { required: true, message: "不能为空", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        priceRules: [
          { required: true, message: "不能为空", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        allotPriceRules: [
          { required: true, message: "不能为空", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
      },
      transForm: {
        group: [],
      },
      token: "",
      authType: "",
      selectData: {
        settlePrincipleAllSelect: [
          { key: "active_terminal_num", value: "激活终端数量" },
          { key: "valid_active_terminal_num", value: "有效激活终端数量" },
          { key: "new_movie_terminal_num", value: "新增影视有效激活终端" },
          { key: "dau", value: "日活量" },
          { key: "dar", value: "日活率" },
          { key: "hotel", value: "酒店" },
          { key: "order", value: "订单" },
        ],
        settlePrincipleOnlyTerSelect: [
          { key: "active_terminal_num", value: "激活终端数量" },
          { key: "valid_active_terminal_num", value: "有效激活终端数量" },
          { key: "new_movie_terminal_num", value: "新增影视有效激活终端" },
          { key: "dau", value: "日活量" },
          { key: "dar", value: "日活率" },
          { key: "hotel", value: "酒店" },
          { key: "order", value: "订单" },
        ],
      },
      settlePrinciple: "",
    };
  },
  methods: {
    groupPushData() {
      var obj = {};
      obj = this.transForm.group;
      var unitType = this.transForm.group[0].type;
      obj.push({
        num: null,
        base_on: this.settlePrinciple,
        base_on_text: this.getBaseOnText(),
        price: null,
        allot_price: 0,
        type: unitType,
        key: Date.now(),
      });
    },
    partAdd(type, index) {
      this.groupPushData(type, "inner", index);
      this.$emit("getNewVal", this.type, this.transForm);
    },
    partReduce(type, index) {
      this.transForm.group.splice(index, 1);
      this.transForm.group.length === 0
        ? (this.settlePrinciple = "active_terminal_num")
        : "";
      this.$emit("getNewVal", this.type, this.transForm);
    },

    getConfinumerm() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getPartnerConfigTerm, param).then((res) => {
        if (res.data.code === 100000) {
          this.selectData.settlePrincipleAllSelect = res.data.data;
          this.selectData.settlePrincipleOnlyTerSelect = res.data.data;
        }
      });
    },
    principleChange(val) {
      this.transForm.group.forEach((item, index) => {
        item.base_on = this.settlePrinciple;
        item.base_on_text = this.getBaseOnText();
      });
    },
    getBaseOnText() {
      var base_on_text = "";
      this.selectData.settlePrincipleAllSelect.filter((item) => {
        if (item.key === this.settlePrinciple) {
          base_on_text = item.value;
        }
      });
      return base_on_text;
    },
    getFormValid() {
      var partValid = false;
      if (this.$refs["form"]) {
        this.$refs["form"].validate((valid) => {
          partValid = valid;
        });
      } else {
        partValid = true;
      }
      return partValid;
    },
  },
  mounted() {
    var form = JSON.stringify(this.form);
    this.authType = JSON.parse(sessionStorage.getItem("auth")).type;
    this.transForm = JSON.parse(form);
    this.token = sessionStorage.getItem("token");
    this.getConfinumerm();
    if (this.transForm.group) {
      if (this.transForm.group.length > 0) {
        if (this.transForm.group[0].base_on) {
          this.settlePrinciple = this.transForm.group[0].base_on;
        }
      }
    }
  },
  computed: {
    transUnit() {
      return function (val) {
        if (val === "dar" || val === "dau") {
          return "%";
        } else if (val === "validTer" || val === "ter") {
          return "台";
        } else if (val === "hotel") {
          return "家";
        } else if (val === "order") {
          return "个";
        } else if (val === "validTer" || val === "ter") {
          return "台";
        } else {
          return "台";
        }
      };
    },
  },
  watch: {
    form: {
      handler: function (val) {
        this.transForm = JSON.parse(JSON.stringify(val));
        if (this.transForm.group) {
          if (this.transForm.group.length > 0) {
            if (this.transForm.group[0].base_on) {
              this.settlePrinciple = this.transForm.group[0].base_on;
            }
          }
        }
        if (
          this.type == "independentOne" ||
          this.type == "independentCard" ||
          this.type == "cinemaOne" ||
          this.type == "cinemaCard" ||
          this.type == "vodonline" ||
          this.type == "card"
        ) {
          if (this.transForm.group.length !== 0) {
            this.transForm.group[0].allot_price = 0;
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.outItem {
  width: 100%;

  border: 1px solid #8aa4c2;
  margin-bottom: 30px;
}
.top {
  display: flex;
  background: #f8fbff;
  align-items: center;
  border-bottom: 1px solid #e2e6eb;
  padding: 15px;
  .right {
    margin-left: auto;
    .el-button {
      margin-right: 20px;
    }
  }
  span {
    color: #2b466b;
    font-size: 14px;
  }
}
.bottom {
  padding: 20px 20px 0 20px;
  overflow-x: auto;
  span {
  }
  .item {
    display: flex;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .innerInput {
    width: 190px;
    margin: 0 10px;
  }
  .hideInput {
    visibility: hidden;
  }
  .el-col {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
.bottom span {
  font-size: 14px !important;
}
.saveBtn {
  margin-left: auto;
}
.addToType {
  margin-left: 30px !important;
}
.firstCol {
  margin-right: 0 !important;
}
.colBox {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.operateBtn {
  margin-bottom: 22px;
}
.principleSpan {
  width: 160px;
  margin-right: 10px;
}
.transForm {
  display: flex;
  flex-direction: column;
}
.priceItem {
  margin-right: 20px;
}
.principleFormItem {
  margin-right: 10px;
}
</style>
