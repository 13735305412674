<template>
  <div class="all">
    <div class="btnStyle" v-if="!isEdit">配置</div>
    <div class="btnStyle  hideBtn" v-if="btnShow!='picmore'&&!isEdit" @click="openEditPage">上传</div>
    <div class="btnStyle  hideBtn" v-if="(btnShow=='picmore'||btnShow=='picone')&&!isEdit" @click="openMore">编辑</div>
    <div class="btnStyle hideBtn"  v-if="showMore&&!isEdit" @click="showMoreLog">更多</div>
      <div class="btnStyle" v-if="isEdit&&showMore" @click="showMoreLog">更多</div>
    <!-- <div class="btnStyle hideBtn"  @click="recoveryData">恢复</div> -->
  </div>
</template>
<script>
import bus from "../bus";
export default {
  props:{
    msg:{
      type:String,
      default:'',
    },
    btnShow:{
      type:String,
      default:'',
    },
    showMore:{
      type:Boolean,
      default:false,
    },
    isEdit:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
    };
  },
  mounted() {
   
  },
  methods: {

    openMore(){
        this.$emit("openmore");
    },
    openEditPage() {
      this.$emit("openuppage");
    },
    recoveryData() {
      this.$emit("recoverydata");
    },
    showMoreLog() {
      this.$emit("showmore");
    },
  },
};
</script>
<style scoped>
.all {
  margin: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 9;
}
.btnStyle {
  height: 26px;
  width: 56px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  cursor: pointer;
  transition: 0.3s;
  
}
.btnStyle:hover {
  /* background: #4093ff; */
  background: rgba(60, 138, 255, 0.8);
}
.all:hover .hideBtn{
   display: flex;
}
.hideBtn {
  display: none;
  border-left: 1px solid #fff;
}
</style>
    
