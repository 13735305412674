<template>
  <div>
    <div class="outTopBox" style="margin-bottom:20px">
      <top-bread :bread="bread">
        <span slot="second">系统设置</span>
        <span slot="third">账户管理</span>
      </top-bread>
      <fold-top-search class="searchBtn" @fold="getFoldMeth"></fold-top-search>
    </div>
    <!-- 筛选界面处理 -->
    <!-- 筛选界面处理 -->
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" :topSearchSave="topSearchSave"></top-search>
    </el-card>
    <!-- 表格操作区 -->
    <el-card class="box-card">
      <div class="title blueTitle">账户列表</div>
      <div class="btnRow">
        <el-button type="primary" @click="openCreatAccount">新 建</el-button>
      </div>
      <el-table v-loading='tableLoading' class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle">
        <el-table-column prop="account_name" label="合作伙伴名称" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type='text' @click="openEditContent(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="合作伙伴编码" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="partner_type_text" label="合作类型"></el-table-column> -->
        <el-table-column prop="contact_name" label="联系人名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="contact_phone" label="联系人电话" show-overflow-tooltip></el-table-column>
        <el-table-column prop="apply_date" label="创建时间" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 表格操作区 -->
    <!-- 详情界面 -->
    <el-drawer :title="drawerTitle" :visible.sync="dialogTableVisible" size="100%" :modal="false">
      <el-form :model="creatForm" ref="creatForm" label-width="100px" :rules="rules">
        <el-form-item label="公司名称" prop="account_name">
          <el-input v-model="creatForm.account_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_name">
          <el-input v-model="creatForm.contact_name"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="contact_phone">
          <el-input v-model="creatForm.contact_phone"></el-input>
        </el-form-item>
        <el-form-item label="公司logo" prop="logo_url">
          <uploadLogo v-model="creatForm.logo_url" @change="changeData"></uploadLogo>
        </el-form-item>
         <el-form-item label="系统名称" prop="system_name">
          <el-input v-model="creatForm.system_name"></el-input>
        </el-form-item>
        <el-form-item label="选择角色" prop="group_id">
          <el-select style="width:100%" v-model="creatForm.group_id" placeholder="请选择角色" class="selectFull" clearable>
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="largeBtn" type="primary" @click="submitcompany('creatForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 详情界面 -->
  </div>
</template>
<script>
import qs from "querystring";
// 上传组件
import uploadLogo from "@/components/common/uploadLogo.vue";
// api
import { addSubset, editSubset, getSubsetList } from "@/api/sysSet.js";
export default {
  name: "SysAccountList",
  components: {
    uploadLogo,
  },
  data() {
    // 手机验证
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写手机号码"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
        return callback(new Error("请填写正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      topSearchSave: {},
      topSearchShow: {
        codekeyword: true,
        namekeyword: true,
        keyword: true,
        keywordPlaceholder: "请输入联系人关键字",
        codekeywordPlaceholder: "请输入电话号码关键字",
        namekeywordPlaceholder: "请输入合作伙伴名称关键字",
      },
      tableList: [],
      searchForm: {
        account_name: "",
        contact_phone: "",
        contact_name: "",
      },
      token: "",
      dialogTableVisible: false,
      creatForm: {
        account_name: "",
        contact_name: "",
        contact_phone: "",
        logo_url: "",
        group_id: "",
        system_name:"",
      },
      roleList: [],
      rules: {
        logo_url: [
          { required: true, message: "请上传公司logo", trigger: "blur" },
        ],
        system_name: [{ required: true, message: "请输入系统名称", trigger: "blur" }],
        group_id: [{ required: true, message: "请选择角色", trigger: "blur" }],
        account_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        contact_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
      },
      drawerTitle: "新建账户",
      editId: "",
      tableLoading: false,
    };
  },

  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getTableList();
    this.getRoleList();
  },

  methods: {
    //合作伙伴保存
    submitcompany(formName) {
      // 表单验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定保存", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // 编辑操作
              if (this.editId) {
                // 参数处理 增加id
                let json = JSON.parse(JSON.stringify(this.creatForm));
                json.id = this.editId;
                editSubset(json)
                  .then((res) => {
                    if (res.data.code == 100000) {
                      // 提示 刷新界面
                      this.$message.success("编辑成功");
                      this.getTableList();
                      this.dialogTableVisible = false;
                    } else {
                      this.$message.warning(res.data.msg);
                    }
                  })
                  .catch((err) => {
                    this.$message.warning("编辑失败");
                  });
              } else {
                // 新建账户 接口 
                addSubset(this.creatForm)
                  .then((res) => {
                    if (res.data.code == 100000) {
                    // 提示 刷新界面
                      this.$message.success("创建成功");
                      this.getTableList();
                      this.dialogTableVisible = false;
                    } else {
                      this.$message.warning(res.data.msg);
                    }
                  })
                  .catch((err) => {
                    this.$message.warning("创建失败");
                  });
              }
            })
            .catch(() => {});
          // alert('submit!');
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    // 获取 角色列表  
    getRoleList() {
      this.$http
        .get(this.global.indexWithPage, {
          params: {
            token: this.token,
            page: 1,
            limit: 999,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.roleList = tableData.items;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    // 展开新建界面 
    openCreatAccount() {
      // 表单重置
      this.creatForm = {
        account_name: "",
        contact_name: "",
        contact_phone: "",
        logo_url: "",
        system_name:"",
        group_id: "",
      };
      // 相关字段 重置
      this.editId = "";
      this.dialogTableVisible = true;
      this.drawerTitle = "创建账户";
    },
    // 编辑界面 展开  
    openEditContent(item) {
      // 表单回显1
      this.creatForm = {
        account_name: item.account_name,
        contact_name: item.contact_name,
        contact_phone: item.contact_phone,
        logo_url: item.logo_url,
        system_name:item.system_name,
        group_id: item.group_id,
      };
      // 相关字段 修改 
      this.editId = item.id;
      this.dialogTableVisible = true;
      this.drawerTitle = "账户信息";
    },
    // 图片 数据变动 监听  
    changeData(val) {
      this.form.logo_url = val;
    },
    // 筛选
    getFoldMeth(val) {
      this.fold = val;
    },
    // 分页
    handleSizeChange(val) {
      this.table.limit = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getTableList();
    },
    // 搜索
    getCondition(val) {
      // 筛选字段 赋值
      this.searchForm = {
        account_name: val.namekeyword,
        contact_phone: val.codekeyword,
        contact_name: val.keyword,
      };
      this.table.page = 1;
      this.getTableList();
    },
    // 获取列表
    getTableList() {
      // 参数  及界面字段处理 
      this.tableLoading = true;
      let json = {
        page: this.table.page,
        per_page: this.table.limit,
        account_name: this.searchForm.account_name,
        contact_phone: this.searchForm.contact_phone,
        contact_name: this.searchForm.contact_name,
      };
      // 获取列表
      getSubsetList(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableList = res.data.data.items;
            this.table.total = res.data.data.total;
            this.tableLoading = false;
          }else{
                this.$message.warning(res.data.msg);
          }
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.$message.warning("数据加载失败");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.searchBtn {
  position: fixed;
  top: 110px;
  right: 10px;
}
.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.btnRow .el-button {
  width: 100px;
  height: 40px;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;

  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  // padding: 0 20px;
  background-color: #f9f9f9;
}
// ::v-deep .el-drawer.rtl {
//   width: 95% !important;
// }
::v-deep :focus {
  outline: 0;
}
</style>