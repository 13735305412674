<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" v-if="curPage === '/indexShow'">
        <span slot="second">首页</span>
        <span slot="third">更多</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskToDo" v-if="curPage === '/taskToDo'">
        <span slot="second">任务管理</span>
        <span slot="third">待办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskDone" v-if="curPage === '/taskDone'">
        <span slot="second">任务管理</span>
        <span slot="third">已办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <el-card>
      <back @backMeth="backMeth"></back>
      <coop-tab-executing v-bind="$attrs" :contentData="contentData" :id="id" tabType="executing" :noDataExecuting="noDataExecuting" :noDataUnExecuted="noDataUnExecuted" :allData="executingData" @getDetail="getDetail" @getPreDetail="getPreDetail" @groupPushData="groupPushData" @copyDataToUnExected="copyDataToUnExected" :hotelOrPartner="hotelOrPartner" @getNewVal="getNewVal" :allotPartnerDefaultConfigure="allotPartnerDefaultConfigure">
      </coop-tab-executing>
      <div v-if="btnShow" class="bottom_btn">
        <el-button type="primary" @click="pass" class="largeBtn">通过</el-button>
        <el-button type="danger" @click="reject" class="largeBtn redBtn">驳回</el-button>
      </div>
    </el-card>
    <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "indexDoCoop",
  props: ["partnerId", "click"],
  data: () => ({
    id: "197",
    hotelOrPartner: "partner",
    contentData: "",
    executingData: {
      content: {},
      flashCost: {
        group: [],
      },
      marketCost: {
        group: [],
      },
      vodonlineCost: {
        group: [],
      },
      vodofflineCost: {
        group: [],
      },
      adCost: {
        group: [],
      },
      shopCost: {
        group: [],
      },
      defineValidCost: {
        group: [],
      },
      independentOneCost: {
        group: [],
      },
      independentCardCost: {
        group: [],
      },
      cinemaOne: {
        group: [],
      },
      cinemaCard: {
        group: [],
      },
      cardCost: {
        group: [],
      },
    },
    unExecutedData: {
      content: {},
      flashCost: {
        group: [],
      },
      marketCost: {
        group: [],
      },
      vodonlineCost: {
        group: [],
      },
      vodofflineCost: {
        group: [],
      },
      adCost: {
        group: [],
      },
      shopCost: {
        group: [],
      },
      defineValidCost: {
        group: [],
      },
      independentOneCost: {
        group: [],
      },
      independentCardCost: {
        group: [],
      },
      cinemaOne: {
        group: [],
      },
      cinemaCard: {
        group: [],
      },
      cardCost: {
        group: [],
      },
    },
    token: "",
    activeName: "2021",
    nextMonth: "",
    noDataExecuting: false,
    noDataUnExecuted: false,
    noDataUnExecutedDefault: false,
    executingBtn: "save",
    tabExecutingShow: false,
    allotPartnerDefaultConfigure: "",
    editFlag: false,
    yearsData: [],
    btnShow: false,
    curPage: "",
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/indexDoMore" },
    },
    breadTaskToDo: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/taskToDo" },
    },
    breadTaskDone: {
      level: 4,
      thirdPath: { path: "/taskDone" },
    },
    imageUrl: "",
    query: {
      id: "",
    },
    form: {
      performance: "",
      movieRate: "",
      movieStatus: "",
      adRate: "",
      adStatus: "",
    },
    allData: [],
    dialogTableVisible: false,
    rejectText: "",
  }),
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.curPage = sessionStorage.getItem("defaultActive");
    var query = this.$route.query;
    this.query = query;
    if (query.reviewStatus === "待审核") {
      this.btnShow = true;
    } else {
      this.btnShow = false;
    }
    this.getCurMonth();
    this.getAllotPartnerDefaultConfigure();
    this.yearTabs();
    if (!this.dataReset) {
      this.getDetail(this.id)
        .then(() => {
          return this.getPreDetail(this.id);
        })
        .then(() => {
          this.tabExecutingShow = true;
        });
    }
  },
  methods: {
    // 返回
    backMeth() {
      this.$router.push({ path: `./${this.query.fromUrl}` });
    },
    // 驳回
    reject() {
      this.$refs.rejectDialog.dialogShow(true);
    },
    // 按钮类型
    pass() {
      this.$confirm("确定通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.examine(1);
        })
        .catch(() => {});
    },
    // 提示信息框
    showMessage(str, type) {
      this.$message({
        message: str,
        type: type,
      });
    },
    // 提交审核
    examine(status) {
      var json = {
        token: this.token,
        id: this.query.id,
        status: status,
        message: this.$refs.rejectDialog.dialog.reason,
      };
      var param = qs.stringify(json);
      this.$http.post(this.global.checkConfigStatus, param).then((res) => {
        var str = "";
        status === 1 ? (str = "审核") : (str = "驳回");
        if (res.data.code === 100000) {
          this.showMessage(str + "成功", "success");
        } else {
          this.showMessage(str + "失败", "error");
        }
        this.backMeth();
      });
    },
    // 年份展示
    yearTabs() {
      var smallYears = 2019;
      //获取当前时间
      var date = new Date();
      var nowYears = date.getFullYear() - 1;
      var Years = nowYears - smallYears;
      var arrYear = [];
      for (var i = 0; i <= Years; i++) {
        arrYear.push(nowYears--);
      }
      arrYear.forEach((ele) => {
        this.yearsData.push({
          name: String(ele),
          label: ele + "年",
        });
      });
    },

    // 获取当前分成比例
    getDetail(id) {
      return new Promise((resolve, reject) => {
        var param = "";
        var global = this.global;
        var reqUrl = "";
        if (this.hotelOrPartner === "hotel") {
          reqUrl = global.getHotelConfigure;
          param = qs.stringify({
            token: this.token,
            hotelContract: this.$attrs.hotel_contract,
          });
        } else {
          reqUrl = global.registerReviewDetail;
          param = qs.stringify({
            token: this.token,
            id: this.query.id,
          });
        }
        this.$http.post(reqUrl, param).then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data.params;
            var executingData = this.executingData;
            this.contentData = res.data.data.partnerContract;
            executingData.flashCost.group = resData.flash ? resData.flash : [];
            executingData.marketCost.group = resData.market
              ? resData.market
              : [];
            executingData.vodonlineCost.group = resData.vodonline
              ? resData.vodonline
              : [];
            executingData.vodofflineCost.group = resData.vodoffline
              ? resData.vodoffline
              : [];
            executingData.adCost.group = resData.ad ? resData.ad : [];
            executingData.shopCost.group = resData.shop ? resData.shop : [];
            executingData.independentOneCost.group = resData.independentOne
              ? resData.independentOne
              : [];
            executingData.independentCardCost.group = resData.independentCard
              ? resData.independentCard
              : [];
            executingData.cinemaOne.group = resData.cinemaOne
              ? resData.cinemaOne
              : [];
            executingData.cinemaCard.group = resData.cinemaCard
              ? resData.cinemaCard
              : [];
            executingData.cardCost.group = resData.card ? resData.card : [];
            if (this.isNoData(executingData, "执行中")) {
              this.noDataExecuting = true;
            } else {
              this.noDataExecuting = false;
            }
          }
          resolve();
        });
      });
    },
    // 获取下个月分成比例
    getPreDetail(id) {
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
          partnerId: this.id,
        });
        var global = this.global;
        var reqUrl = "";
        if (this.hotelOrPartner === "hotel") {
          reqUrl = global.getHotelPreConfigure;
          param = qs.stringify({
            token: this.token,
            hotelContract: this.$attrs.hotel_contract,
          });
        } else {
          reqUrl = global.getPartnerPreConfigure;
          param = qs.stringify({
            token: this.token,
            partnerId: this.id,
          });
        }
        this.$http.post(reqUrl, param).then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            var unExecutedData = this.unExecutedData;
            if (this.activeName == "unExecuted") {
              this.contentData = resData.partnerContract;
            }

            unExecutedData.flashCost.group = resData.flash ? resData.flash : [];
            unExecutedData.marketCost.group = resData.market
              ? resData.market
              : [];
            unExecutedData.vodonlineCost.group = resData.vodonline
              ? resData.vodonline
              : [];
            unExecutedData.vodofflineCost.group = resData.vodoffline
              ? resData.vodoffline
              : [];
            unExecutedData.adCost.group = resData.ad ? resData.ad : [];
            unExecutedData.shopCost.group = resData.shop ? resData.shop : [];
            unExecutedData.independentOneCost.group = resData.independentOne
              ? resData.independentOne
              : [];
            unExecutedData.independentCardCost.group = resData.independentCard
              ? resData.independentCard
              : [];
            unExecutedData.cinemaOne.group = resData.cinemaOne
              ? resData.cinemaOne
              : [];
            unExecutedData.cinemaCard.group = resData.cinemaCard
              ? resData.cinemaCard
              : [];
            unExecutedData.cardCost.group = resData.card ? resData.card : [];
            if (this.isNoData(unExecutedData, "预配置")) {
              this.noDataUnExecuted = true;
              this.noDataUnExecutedDefault = true;
            } else {
              this.noDataUnExecuted = false;
              this.noDataUnExecutedDefault = false;
            }
            resolve(resData);
          }
        });
      });
    },

    copyDataToUnExected() {
      this.unExecutedData = JSON.parse(JSON.stringify(this.executingData));
      this.activeName = "unExecuted";
      this.editFlag = true;
    },
    // 月份修改
    getCurMonth() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      if (month === 12) {
        this.nextMonth = 1 + "月1日生效";
      } else {
        this.nextMonth = month + 1 + "月1日生效";
      }
    },
    // 无数据展示
    isNoData(obj, type) {
      var flag = true; //没有数据
      if (Array.isArray(obj)) {
        if (obj.length === 0) {
          flag = false;
        }
      } else {
        Object.keys(obj).forEach(function (key) {
          if (key !== "content") {
            if (obj[key].group) {
              if (obj[key].group.length > 0) {
                flag = false; //有数据
              }
            }
          }
        });
      }
      return flag;
    },
    groupPushData(tabType, configType) {
      var obj = {};
      var unitType = "P";
      var data =
        tabType === "executing" || tabType === "unExecuting"
          ? this.executingData
          : this.unExecutedData;
      switch (configType) {
        case "flash":
          obj = data.flashCost.group;
          unitType = "F";
          break;
        case "market":
          obj = data.marketCost.group;
          unitType = "F";
          break;
        case "vodonline":
          obj = data.vodonlineCost.group;
          break;
        case "vodoffline":
          obj = data.vodofflineCost.group;
          break;
        case "ad":
          obj = data.adCost.group;
          break;
        case "shop":
          obj = data.shopCost.group;
          break;
        case "defineValid":
          obj = data.defineValidCost.group;
          unitType = "F";
          break;
        case "independentOne":
          obj = data.independentOneCost.group;
          break;
        case "independentCard":
          obj = data.independentCardCost.group;
          break;
        case "cinemaOne":
          obj = data.cinemaOne.group;
          break;
        case "cinemaCard":
          obj = data.cinemaCard.group;
          break;
        case "card":
          obj = data.cardCost.group;
          break;
      }
      obj.push({
        num: null,
        base_on: "active_terminal_num",
        base_on_text: "",
        price: null,
        allot_price: this.allotPartnerDefaultConfigure,
        type: unitType,
        key: Date.now(),
      });
    },
    getNewVal(tabType, configType, val) {
      if (tabType === "executing") {
        switch (configType) {
          case "flash":
            this.executingData.flashCost = val;
            break;
          case "market":
            this.executingData.marketCost = val;
            break;
          case "vodonline":
            this.executingData.vodonlineCost = val;
            break;
          case "vodoffline":
            this.executingData.vodofflineCost = val;
            break;
          case "ad":
            this.executingData.adCost = val;
            break;
          case "shop":
            this.executingData.shopCost = val;
            break;
          case "defineValid":
            this.executingData.defineValidCost = val;
            break;
          case "independentOne":
            this.executingData.independentOneCost = val;
            break;
          case "independentCard":
            this.executingData.independentCardCost = val;
          case "cinemaOne":
            this.executingData.cinemaOne = val;
            break;
          case "cinemaCard":
            this.executingData.cinemaCard = val;
            break;
          case "card":
            this.executingData.cardCost = val;
            break;
        }
      } else if (tabType === "unExecuted") {
        switch (configType) {
          case "flash":
            this.unExecutedData.flashCost = val;
            break;
          case "market":
            this.unExecutedData.marketCost = val;
            break;
          case "vodonline":
            this.unExecutedData.vodonlineCost = val;
            break;
          case "vodoffline":
            this.unExecutedData.vodofflineCost = val;
            break;
          case "ad":
            this.unExecutedData.adCost = val;
            break;
          case "shop":
            this.unExecutedData.shopCost = val;
            break;
          case "defineValid":
            this.unExecutedData.defineValidCost = val;
            break;
          case "independentOne":
            this.unExecutedData.independentOneCost = val;
            break;
          case "independentCard":
            this.unExecutedData.independentCard = val;
            break;
          case "cinemaOne":
            this.unExecutedData.cinemaOne = val;
            break;
          case "cinemaCard":
            this.unExecutedData.cinemaCard = val;
            break;
          case "card":
            this.unExecutedData.cardCost = val;
            break;
        }
      }
    },
    getAllotPartnerDefaultConfigure() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http
        .post(this.global.getAllotPartnerDefaultConfigure, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.allotPartnerDefaultConfigure =
              res.data.data.allotPartnerDefaultConfigure;
          }
        });
    },
  },

  computed: {
    executingReadOnly() {
      if (
        this.type === "executing" &&
        !this.noDataExecuting &&
        !this.noDataUnExecuted
      ) {
        return true; //只读
      } else {
        return false;
      }
    },
  },
  watch: {
    unExecutedData: {
      handler: function (newVal) {
        if (this.isNoData(newVal)) {
          this.noDataUnExecuted = true;
        } else {
          this.noDataUnExecuted = false;
        }
      },
      deep: true,
      immediate: true,
    },
    id: {
      handler: function (newVal) {
        this.id = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.outItem {
  width: 90%;
  border: 1px solid #8aa4c2;
  margin-bottom: 30px;
}
.top {
  display: flex;
  background: #f8fbff;
  padding: 5px 10px;
  align-items: center;
  border-bottom: 1px solid #e2e6eb;

  .right {
    margin-left: auto;
    .el-button {
      margin-right: 20px;
    }
  }
  span {
    color: #2b466b;
    font-size: 14px;
  }
}
.bottom {
  padding: 20px;
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .innerInput {
    width: 190px;
    margin: 0 10px;
  }
  .hideInput {
    visibility: hidden;
  }
  .el-col {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
.saveBtn {
  margin-left: 20px;
}
.dialogOpeBtn {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.bottom_btn {
  float: right;
  margin-bottom: 20px;
}
</style>
