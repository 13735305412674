import { render, staticRenderFns } from "./coopDataReset.vue?vue&type=template&id=12f45715&scoped=true"
import script from "./coopDataReset.vue?vue&type=script&lang=js"
export * from "./coopDataReset.vue?vue&type=script&lang=js"
import style0 from "./coopDataReset.vue?vue&type=style&index=0&id=12f45715&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f45715",
  null
  
)

export default component.exports