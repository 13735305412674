<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">操作日志</span>
                <!--<span slot="third">新建</span>-->
            </top-bread>
        </div>

        <el-card class="box-card">
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-select v-model="topAttr.user" placeholder="请选择用户" class="fullInput">
                        <el-option
                                v-for="item in topAttr.userOption"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <el-date-picker class="fullInput"
                            v-model="topAttr.date"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-col>
            </el-row>
            <el-timeline :reverse="timeLine.reverse" class="box-card">
                <el-timeline-item
                        v-for="(item, index) in timeLine.activities"
                        :key="index"
                        :timestamp="item.timestamp">
                    {{item.content}}
                </el-timeline-item>
            </el-timeline>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "OperaLog",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
            },
            topAttr:{
                user:'',
                userOption:[
                    {label:'用户1',value:'1'},
                    {label:'用户2',value:'2'},
                    {label:'用户3',value:'3'},
                ],
                date:''
            },
            timeLine:{
                reverse: true,
                activities: [{
                    content: '活动按期开始',
                    timestamp: '2018-04-15'
                }, {
                    content: '通过审核',
                    timestamp: '2018-04-13'
                }, {
                    content: '创建成功',
                    timestamp: '2018-04-11'
                }]
            }
        })
    }
</script>

<style scoped lang="scss">

    .fullInput{
        width:100%;
    }
</style>
