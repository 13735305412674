<template>
    <!--运营酒店列表 tab-->
    <div>
        <no-data v-if="noData"><span slot="tips">暂不支持</span></no-data>
        <div v-if="!noData" class="mainBox hideShadeBox" @click="hideShade">
            <div class="btnBox" v-if="$attrs.showType!=='action'">
                <el-button class="largeBtn" type="primary" @click="publishDialogMeth('publish','发布')">发布</el-button>
                <el-button class="largeBtn" type="primary" @click="publishListMeth('publish','发布')">发布列表</el-button>
                <!--<div class="numShow">-->
                    <!--已更新房间数 ：<span class="blueText" @click="showRoomDialog(true)">{{roomLengthCom}}</span>-->
                <!--</div>-->
            </div>
            <div>
                <el-tabs v-model="activeName" @tab-click="handleClick" type="card" class="tabCenter noBorderTab">
                    <el-tab-pane :label="tabNameCom(0).name" :name="tabNameCom(0).key" >
                        <div v-if="tabNameCom(0).key==='welcomePage'">
                            <edit-wel-hihotel  ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='hihotel'" :modelReadOnly="modelReadOnly"
                                               :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-hihotel>
                            <edit-wel-ehotel-btn ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur===''" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                 :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-btn>
                            <edit-wel-ehotel-logo ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='logo'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-logo>
                            <edit-wel-ehotel-none ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='none'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-none>
                        </div>
                        <div v-if="tabNameCom(0).key==='home'">
                            <edit-home-hihotel ref="editHomeHihotel" :hotelContract="hotelContract" :homePageProp="getMsg.tab.home"
                                               v-if="tabShow && hotelTemplateType==='hihotel'" :hotelTemplateType="hotelTemplateType"
                                               :modelReadOnly="modelReadOnly" v-bind="$attrs"></edit-home-hihotel>
                            <edit-home-ehotel-standard ref="editHomeEhotel" :activeNameProp="tabNameCom(0).name"
                                                       :hotelContract="hotelContract"
                                                       :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                       v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur===''" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-standard>
                            <edit-home-ehotel-right-text ref="editHomeEhotel" :activeNameProp="tabNameCom(0).name"
                                                         :hotelContract="hotelContract"
                                                         :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                         v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='text'" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-text>
                            <edit-home-ehotel-right-img ref="editHomeEhotel" :activeNameProp="tabNameCom(0).name"
                                                        :hotelContract="hotelContract"
                                                        :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                        v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='img'"
                                                        :hotelTemplateType="hotelTemplateType" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-img>
                        </div>
                        <div v-if="tabNameCom(0).key==='service1'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(0).name" ref="hotelService"
                                                :hotelContract="hotelContract" serviceType="hotelService"
                                                :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config"
                                                :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75"
                                                :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                      v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(0).name" ref="hotelService"
                                                    :hotelContract="hotelContract" serviceType="hotelService"
                                                    :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceData.pic" :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home" v-if="tabShow" v-bind="$attrs"
                                        @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(0).key==='service2'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(0).name" ref="hotelServiceTwo"
                                                :hotelContract="hotelContract" serviceType="serviceTwo"
                                                :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75 " :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(0).name" ref="hotelServiceTwo"
                                                    :hotelContract="hotelContract" serviceType="serviceTwo"
                                                    :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceTwo"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceTwoData.pic" :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(0).key==='service3'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(0).name" ref="hotelServiceThree"
                                                      :hotelContract="hotelContract" serviceType="serviceThree"
                                                      :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(0).name" ref="hotelServiceThree"
                                                    :hotelContract="hotelContract" serviceType="serviceThree"
                                                    :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceThree"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceThreeData.pic" :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(0).key==='jumpUrl'">
                            <edit-jump-to-big-pic ref="jumpUrl" :jumpUrlProp="getMsg.tab.jumpUrl" v-if="tabShow" :hotelContract="hotelContract"></edit-jump-to-big-pic>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="tabNameCom(1).name" :name="tabNameCom(1).key" >
                        <div v-if="tabNameCom(1).key==='welcomePage'">
                            <edit-wel-hihotel  ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='hihotel'" :modelReadOnly="modelReadOnly"
                                               :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-hihotel>
                            <edit-wel-ehotel-btn ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur===''" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                 :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-btn>
                            <edit-wel-ehotel-logo ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='logo'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-logo>
                            <edit-wel-ehotel-none ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='none'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-none>
                        </div>
                        <div v-if="tabNameCom(1).key==='home'">
                            <edit-home-hihotel ref="editHomeHihotel" :hotelContract="hotelContract" :homePageProp="getMsg.tab.home"
                                               v-if="tabShow && hotelTemplateType==='hihotel'" :hotelTemplateType="hotelTemplateType"
                                               :modelReadOnly="modelReadOnly" v-bind="$attrs"></edit-home-hihotel>
                            <edit-home-ehotel-standard ref="editHomeEhotel" :activeNameProp="tabNameCom(1).name"
                                                       :hotelContract="hotelContract"
                                                       :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                       v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur===''" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-standard>
                            <edit-home-ehotel-right-text ref="editHomeEhotel" :activeNameProp="tabNameCom(1).name"
                                                         :hotelContract="hotelContract"
                                                         :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                         v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='text'" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-text>
                            <edit-home-ehotel-right-img ref="editHomeEhotel" :activeNameProp="tabNameCom(1).name"
                                                        :hotelContract="hotelContract"
                                                        :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                        v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='img'"
                                                        :hotelTemplateType="hotelTemplateType" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-img>
                        </div>
                        <div v-if="tabNameCom(1).key==='service1'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(1).name" ref="hotelService"
                                                :hotelContract="hotelContract" serviceType="hotelService"
                                                :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(1).name" ref="hotelService"
                                                    :hotelContract="hotelContract" serviceType="hotelService"
                                                    :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceData.pic" :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(1).key==='service2'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(1).name" ref="hotelServiceTwo"
                                                :hotelContract="hotelContract" serviceType="serviceTwo"
                                                :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel'  && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(1).name" ref="hotelServiceTwo"
                                                    :hotelContract="hotelContract" serviceType="serviceTwo"
                                                    :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceTwo"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceTwoData.pic" :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(1).key==='service3'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(1).name" ref="hotelServiceThree"
                                                      :hotelContract="hotelContract" serviceType="serviceThree"
                                                      :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel'  && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(1).name" ref="hotelServiceThree"
                                                    :hotelContract="hotelContract" serviceType="serviceThree"
                                                    :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceThree"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceThreeData.pic" :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(1).key==='jumpUrl'">
                            <edit-jump-to-big-pic ref="jumpUrl" :jumpUrlProp="getMsg.tab.jumpUrl" v-if="tabShow" :hotelContract="hotelContract" v-bind="$attrs"></edit-jump-to-big-pic>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="initConfigData && getMsg.tab[Object.keys(getMsg.tab)[2]]" :label="tabNameCom(2).name" :name="tabNameCom(2).key" >
                        <div v-if="tabNameCom(2).key==='welcomePage'">
                            <edit-wel-hihotel  ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='hihotel'" :modelReadOnly="modelReadOnly"
                                               :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-hihotel>
                            <edit-wel-ehotel-btn ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur===''" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                 :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-btn>
                            <edit-wel-ehotel-logo ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='logo'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-logo>
                            <edit-wel-ehotel-none ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='none'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-none>
                        </div>
                        <div v-if="tabNameCom(2).key==='home'">
                            <edit-home-hihotel ref="editHomeHihotel" :hotelContract="hotelContract" :homePageProp="getMsg.tab.home"
                                               v-if="tabShow && hotelTemplateType==='hihotel'" :hotelTemplateType="hotelTemplateType"
                                               :modelReadOnly="modelReadOnly" v-bind="$attrs"></edit-home-hihotel>
                            <edit-home-ehotel-standard ref="editHomeEhotel" :activeNameProp="tabNameCom(2).name"
                                                       :hotelContract="hotelContract"
                                                       :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                       v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur===''" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-standard>
                            <edit-home-ehotel-right-text ref="editHomeEhotel" :activeNameProp="tabNameCom(2).name"
                                                         :hotelContract="hotelContract"
                                                         :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                         v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='text'" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-text>
                            <edit-home-ehotel-right-img ref="editHomeEhotel" :activeNameProp="tabNameCom(2).name"
                                                        :hotelContract="hotelContract"
                                                        :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                        v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='img'"
                                                        :hotelTemplateType="hotelTemplateType" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-img>
                        </div>
                        <div v-if="tabNameCom(2).key==='service1'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(2).name" ref="hotelService"
                                                :hotelContract="hotelContract" serviceType="hotelService"
                                                :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(2).name" ref="hotelService"
                                                      :hotelContract="hotelContract" serviceType="hotelService"
                                                      :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceData.pic" :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(2).key==='service2'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(2).name" ref="hotelServiceTwo"
                                                :hotelContract="hotelContract" serviceType="serviceTwo"
                                                :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' " :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(2).name" ref="hotelServiceTwo"
                                                    :hotelContract="hotelContract" serviceType="serviceTwo"
                                                    :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceTwo"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceTwoData.pic" :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(2).key==='service3'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(2).name" ref="hotelServiceThree"
                                                      :hotelContract="hotelContract" serviceType="serviceThree"
                                                      :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer">

                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(2).name" ref="hotelServiceThree"
                                                    :hotelContract="hotelContract" serviceType="serviceThree"
                                                    :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceThree"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceThreeData.pic" :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(2).key=== 'jumpUrl'">
                            <edit-jump-to-big-pic ref="jumpUrl" :hotelContract="hotelContract" :jumpUrlProp="getMsg.tab.jumpUrl" v-if="tabShow" v-bind="$attrs"></edit-jump-to-big-pic>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="initConfigData && getMsg.tab[Object.keys(getMsg.tab)[3]]" :label="tabNameCom(3).name" :name="tabNameCom(3).key">
                        <div v-if="tabNameCom(3).key==='welcomePage'">
                            <edit-wel-hihotel  ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='hihotel'" :modelReadOnly="modelReadOnly"
                                               :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-hihotel>
                            <edit-wel-ehotel-btn ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur===''" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                 :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-btn>
                            <edit-wel-ehotel-logo ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='logo'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-logo>
                            <edit-wel-ehotel-none ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='none'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-none>
                        </div>
                        <div v-if="tabNameCom(3).key==='home'">
                            <edit-home-hihotel ref="editHomeHihotel" :hotelContract="hotelContract" :homePageProp="getMsg.tab.home"
                                               v-if="tabShow && hotelTemplateType==='hihotel'" :hotelTemplateType="hotelTemplateType"
                                               :modelReadOnly="modelReadOnly" v-bind="$attrs"></edit-home-hihotel>
                            <edit-home-ehotel-standard ref="editHomeEhotel" :activeNameProp="tabNameCom(3).name"
                                                       :hotelContract="hotelContract"
                                                       :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                       v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur===''" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-standard>
                            <edit-home-ehotel-right-text ref="editHomeEhotel" :activeNameProp="tabNameCom(3).name"
                                                         :hotelContract="hotelContract"
                                                         :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                         v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='text'" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-text>
                            <edit-home-ehotel-right-img ref="editHomeEhotel" :activeNameProp="tabNameCom(3).name"
                                                        :hotelContract="hotelContract"
                                                        :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                        v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='img'"
                                                        :hotelTemplateType="hotelTemplateType" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-img>
                        </div>
                        <div v-if="tabNameCom(3).key==='service1'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(3).name" ref="hotelService"
                                                :hotelContract="hotelContract" serviceType="hotelService"
                                                :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75 " :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(3).name" ref="hotelService"
                                                    :hotelContract="hotelContract" serviceType="hotelService"
                                                    :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceData.pic" :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(3).key==='service2'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(3).name" ref="hotelServiceTwo"
                                                :hotelContract="hotelContract" serviceType="serviceTwo"
                                                :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                                :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">

                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(3).name" ref="hotelServiceTwo"
                                                    :hotelContract="hotelContract" serviceType="serviceTwo"
                                                    :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceTwo"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceTwoData.pic" :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(3).key==='service3'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(3).name" ref="hotelServiceThree"
                                                      :hotelContract="hotelContract" serviceType="serviceThree"
                                                      :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">

                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(3).name" ref="hotelServiceThree"
                                                    :hotelContract="hotelContract" serviceType="serviceThree"
                                                    :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceThree"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceThreeData.pic" :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(3).key==='jumpUrl'">
                            <edit-jump-to-big-pic :hotelContract="hotelContract" ref="jumpUrl" :jumpUrlProp="getMsg.tab.jumpUrl" v-if="tabShow" v-bind="$attrs"></edit-jump-to-big-pic>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="initConfigData && getMsg.tab[Object.keys(getMsg.tab)[4]]" :label="tabNameCom(4).name" :name="tabNameCom(4).key">
                        <div v-if="tabNameCom(4).key==='welcomePage'">
                            <edit-wel-hihotel  ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='hihotel'" :modelReadOnly="modelReadOnly"
                                               :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-hihotel>
                            <edit-wel-ehotel-btn ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur===''" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                 :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-btn>
                            <edit-wel-ehotel-logo ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='logo'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-logo>
                            <edit-wel-ehotel-none ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='none'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-none>
                        </div>
                        <div v-if="tabNameCom(4).key==='home'">
                            <edit-home-hihotel ref="editHomeHihotel" :hotelContract="hotelContract" :homePageProp="getMsg.tab.home"
                                               v-if="tabShow && hotelTemplateType==='hihotel'" :hotelTemplateType="hotelTemplateType"
                                               :modelReadOnly="modelReadOnly" v-bind="$attrs"></edit-home-hihotel>
                            <edit-home-ehotel-standard ref="editHomeEhotel" :activeNameProp="tabNameCom(4).name"
                                                       :hotelContract="hotelContract"
                                                       :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                       v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur===''" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-standard>
                            <edit-home-ehotel-right-text ref="editHomeEhotel" :activeNameProp="tabNameCom(4).name"
                                                         :hotelContract="hotelContract"
                                                         :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                         v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='text'" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-text>
                            <edit-home-ehotel-right-img ref="editHomeEhotel" :activeNameProp="tabNameCom(4).name"
                                                        :hotelContract="hotelContract"
                                                        :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                        v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='img'"
                                                        :hotelTemplateType="hotelTemplateType" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-img>
                        </div>
                        <div v-if="tabNameCom(4).key==='service1'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(4).name" ref="hotelService"
                                                :hotelContract="hotelContract" serviceType="hotelService"
                                                :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">

                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(4).name" ref="hotelService"
                                                    :hotelContract="hotelContract" serviceType="hotelService"
                                                    :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceData.pic" :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(4).key==='service2'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(4).name" ref="hotelServiceTwo"
                                                :hotelContract="hotelContract" serviceType="serviceTwo"
                                                :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                v-if="tabShow && hotelTemplateType==='ehotel'  && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(4).name" ref="hotelServiceTwo"
                                                    :hotelContract="hotelContract" serviceType="serviceTwo"
                                                    :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceTwo"
                                    :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceTwoData.pic" :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(4).key==='service3'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(4).name" ref="hotelServiceThree"
                                                      :hotelContract="hotelContract" serviceType="serviceThree"
                                                      :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel'  && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">

                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(4).name" ref="hotelServiceThree"
                                                    :hotelContract="hotelContract" serviceType="serviceThree"
                                                    :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceThree"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceThreeData.pic" :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(4).key==='jumpUrl'">
                            <edit-jump-to-big-pic :hotelContract="hotelContract" ref="jumpUrl" :jumpUrlProp="getMsg.tab.jumpUrl" v-if="tabShow" v-bind="$attrs"></edit-jump-to-big-pic>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="initConfigData && getMsg.tab[Object.keys(getMsg.tab)[5]]" :label="tabNameCom(5).name" :name="tabNameCom(5).key">
                        <div v-if="tabNameCom(5).key==='welcomePage'">
                            <edit-wel-hihotel  ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='hihotel'" :modelReadOnly="modelReadOnly"
                                               :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-hihotel>
                            <edit-wel-ehotel-btn ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur===''" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                 :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-btn>
                            <edit-wel-ehotel-logo ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='logo'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-logo>
                            <edit-wel-ehotel-none ref="editWelDetail" :welPageProp="getMsg.tab.welcomePage" v-if="tabShow && hotelTemplateType==='ehotel' && templateWelTextModelCur==='none'" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth"
                                                  :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" v-bind="$attrs">
                            </edit-wel-ehotel-none>
                        </div>
                        <div v-if="tabNameCom(5).key==='home'">
                            <edit-home-hihotel ref="editHomeHihotel" :hotelContract="hotelContract" :homePageProp="getMsg.tab.home"
                                               v-if="tabShow && hotelTemplateType==='hihotel'" :hotelTemplateType="hotelTemplateType"
                                               :modelReadOnly="modelReadOnly" v-bind="$attrs"></edit-home-hihotel>
                            <edit-home-ehotel-standard ref="editHomeEhotel" :activeNameProp="tabNameCom(5).name"
                                                       :hotelContract="hotelContract"
                                                       :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                       v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur===''" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-standard>
                            <edit-home-ehotel-right-text ref="editHomeEhotel" :activeNameProp="tabNameCom(5).name"
                                                         :hotelContract="hotelContract"
                                                         :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                         v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='text'" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-text>
                            <edit-home-ehotel-right-img ref="editHomeEhotel" :activeNameProp="tabNameCom(5).name"
                                                        :hotelContract="hotelContract"
                                                        :homePageProp="getMsg.tab.home" :menuProp="getMsg.config.menu" :templateIdProp="getMsg.template_id" :bgUrlProp="getMsg.config.bgUrl"
                                                        v-if="tabShow && hotelTemplateType==='ehotel' && templateHomeTextModelCur==='img'"
                                                        :hotelTemplateType="hotelTemplateType" :modelReadOnly="modelReadOnly" @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs">
                            </edit-home-ehotel-right-img>
                        </div>
                        <div v-if="tabNameCom(5).key==='service1'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(5).name" ref="hotelService"
                                                      :hotelContract="hotelContract"
                                                      :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel'  && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(5).name" ref="hotelService"
                                                    :hotelContract="hotelContract"
                                                    :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceData.pic" :hotelServiceProp="hotelServiceData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(5).key==='service2'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(5).name" ref="hotelServiceTwo"
                                                      :hotelContract="hotelContract" serviceType="serviceTwo"
                                                      :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(5).name" ref="hotelServiceTwo"
                                                    :hotelContract="hotelContract" serviceType="serviceTwo"
                                                    :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceTwo"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceTwoData.pic" :hotelServiceProp="hotelServiceTwoData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(5).key==='service3'">
                            <edit-hotel-service-three :activeNameProp="tabNameCom(5).name" ref="hotelServiceThree"
                                                      :hotelContract="hotelContract" serviceType="serviceThree"
                                                      :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home" :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                      v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id!=75 " :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                      @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-three>
                            <edit-hotel-service-one :activeNameProp="tabNameCom(5).name" ref="hotelServiceThree"
                                                    :hotelContract="hotelContract" serviceType="serviceThree"
                                                    :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"  :configStyleProp="getMsg.config" :templateIdProp="getMsg.template_id"
                                                    v-if="tabShow && hotelTemplateType==='ehotel' && getMsg.template_id==75" :hotelTemplateType="hotelTemplateType " :modelReadOnly="modelReadOnly"
                                                    @editTabLoadingMeth="editTabLoadingMeth" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </edit-hotel-service-one>
                            <all-module :hotelContractProp="hotelContract" serviceTypeProp="serviceThree"
                                        :bgUrlProp="getMsg.config.bgUrl" style="margin-top:20px;"
                                        :picProp="hotelServiceThreeData.pic" :hotelServiceProp="hotelServiceThreeData" :homePageProp="getMsg.tab.home"
                                        v-if="tabShow" v-bind="$attrs" @showConfigDetailDrawer="showConfigDetailDrawer" :activeName="activeName">
                            </all-module>
                        </div>
                        <div v-if="tabNameCom(5).key==='jumpUrl'">
                            <edit-jump-to-big-pic :hotelContract="hotelContract" ref="jumpUrl" :jumpUrlProp="getMsg.tab.jumpUrl" v-if="tabShow" v-bind="$attrs"></edit-jump-to-big-pic>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <el-dialog
                title="房间列表"
                :visible.sync="roomDialog.dialogVisible"
                width="50%">
            <el-row :gutter="20" class="roleAccount">
                <el-col :span="2" :key="index" v-for="(item,index) in getMsg.roomList" class="roomItemBox">
                    <div class="roomItem">{{item}}</div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                     <el-button type="primary" @click="showRoomDialog(false)">取 消</el-button>
                </span>
        </el-dialog>
        <choice-room-dialog ref="choiceRoomDialog" :hotelContract="hotelContract"
                            @confirmMeth="confirmMeth" :userNameProp="userNameProp">
        </choice-room-dialog>
        <choose-room></choose-room>
        <hotel-service-detail v-bind="$attrs" :hotelContract="hotelContract" ></hotel-service-detail>
        <publish-list ref="publishList"></publish-list>
    </div>
</template>

<script>
    import qs from 'querystring'
    import Bus from '../../../api/Bus'
    export default {
        name: "MaterialEdit",
        props: {
            hotelContract: "",
            configData: {},
            hotelTemplateType: {},
            noData: {},
            userNameProp: {},
        },
        data: () => ({
            activeName: '',
            allDataObj: {},
            token: '',
            tabShow: false,
            templateWelTextModel:{
                standard:[],
                logo:['75'],  //iu
                none:['71'],
            },
            templateWelTextModelCur:'',
            templateHomeTextModel:{
                standard:[],
                text:['71','72','73','74'],
                img:['75'],  //iu
            },
            templateHomeTextModelCur:'',
            modelReadOnly:{},
            hotelServiceData:{},
            hotelServiceTwoData:{},
            hotelServiceThreeData:{},
            tabTitle:false,
            configDataTabTest:{
                home:{
                    id:1,
                    title:'主页'
                },
                welcomePage:{
                    id:2,
                    title:'欢迎'
                }
            },
            roomDialog:{
                dialogVisible:false,
            },
            initConfigData:false,
            query:{
                fromEditType:'',
            },
            getMsg:{},

        }),
        methods: {
            handleClick() {
                    
                // var activeName=this.activeName;
                // if(activeName==='welcomePage'){
                //     this.$refs.editWelDetail.clearShowBtnName();
                // }else if(activeName==='home'){
                //     if(this.hotelTemplateType==='hihotel'){
                //         this.$refs.editHomeHihotel.clearShowBtnName();
                //     }else if(this.hotelTemplateType==='ehotel'){
                //         this.$refs.editHomeEhotel.clearShowBtnName();
                //     }
                // }
            },
            editTabLoadingMeth(val,type){
                this.$emit('ediTabLoading', val,type);
            },
            publishDialogMeth(){
                this.$refs.choiceRoomDialog.dialogShowMeth(true);
            },
            publishPostMeth(info) {
                this.$emit('ediTabLoading', true);
                var allDataObj = this.allDataObj;
                var curEditHome=
                    this.hotelTemplateType==='hihotel'?this.$refs.editHomeHihotel:this.$refs.editHomeEhotel;
                var modelLogoFileName=curEditHome.modelLogoData.fileName;
                var modelOneFileName=curEditHome.modelOneData.fileName;
                var modelTwoFileName=curEditHome.modelTwoData.fileName;
                var modelThreeFileName=curEditHome.modelThreeData.fileName;
                allDataObj.welcomePage = this.$refs.editWelDetail.welPage;
                if (this.$refs.editWelDetail.welPage.type === 'img') {
                    allDataObj.welcomePage.type = 'pic'
                }
                allDataObj.home = curEditHome.homePage;
                if(this.$refs.hotelService){
                    allDataObj.service1 = this.$refs.hotelService.mergeData();
                }
                if(this.$refs.hotelServiceTwo){
                    allDataObj.service2 = this.$refs.hotelServiceTwo.mergeData();
                }
                if(this.$refs.jumpUrl){
                    allDataObj.jumpUrl = this.$refs.jumpUrl.mergeData();
                }
                allDataObj.info=info;
                var str = JSON.stringify(allDataObj);
                var json = JSON.parse(str);
                var param = qs.stringify({
                    token: this.token,
                    hotelContract: this.hotelContract,
                    data: str
                });
                this.$refs.choiceRoomDialog.cancelDialog(false);
                this.upPromise('modelImg',allDataObj.welcomePage.url,allDataObj.welcomePage.fileName).then((res)=>{
                    return  this.upPromise('modelLogo',allDataObj.home.modelLogo,modelLogoFileName);
                }).then((res)=>{
                    return  this.upPromise('modelOne',allDataObj.home.modelOne,modelOneFileName);
                }).then((res)=>{
                    return  this.upPromise('modelTwo',allDataObj.home.modelTwo,modelTwoFileName);
                }).then((res)=>{
                    return  this.upPromise('modelThree',allDataObj.home.modelThree,modelThreeFileName);
                }).then((res)=>{
                    this.$http.post(this.global.uploadHotelVisualData, param).then(res => {
                        if (res.data.code === 100000) {
                            this.$message.success('本次发布已提交管理员审核');
                            this.$emit('ediTabLoading', false);
                            // this.$emit('getHotelConfigInfo');
                            this.$refs.editWelDetail.clearFileName();
                            this.hotelTemplateType==='hihotel'
                            ?this.$refs.editHomeHihotel.clearFileName()
                                :this.$refs.editHomeEhotel.clearFileName();

                        } else {
                            this.$message.error('发布失败');
                            this.$emit('ediTabLoading', false);
                        }
                        this.tabTitle=show;
                    }).catch(() => {
                        // this.$message.error('返回错误');
                        this.$emit('ediTabLoading', false);
                    })
                })
            },
            //上传时候判断是否是临时地址
            isTempUrl(fileName) {
                if (fileName && fileName!=='') { //是临时
                    return 'temp';
                } else {
                    return 'formal';
                }
            },
            upPromise(modelItemStr,fileUrl,fileName) {
                return new Promise((resolve, reject) => {
                    var welPageData = this.$refs.editWelDetail.welPage;
                    var isTemp = this.isTempUrl(fileName);
                    var url='';
                    if(fileName && fileName!==''){
                        var paramFileUrl=fileUrl.substring(fileUrl.indexOf('/uploads'));
                    }
                    if(isTemp==='temp'){
                        if(modelItemStr==='modelOne'){
                            url=this.global.uploadHotelVideo
                        }else if(modelItemStr==='modelImg'){
                            if(welPageData.type==='video'){
                                url=this.global.uploadHotelVideo
                            }else{
                                url=this.global.uploadHotelPicture
                            }
                        }else{
                            url=this.global.uploadHotelPicture
                        }
                        var param=qs.stringify({
                            token:this.token,
                            hotelContract:this.hotelContract,
                            fileUrl:paramFileUrl,
                            fileName:fileName
                        });
                        this.$http.post(url, param).then(res => {
                            if (res.data.code === 100000) {

                            } else {

                            }
                            resolve(res);
                        }).catch((res) => {
                            this.$message.error('系统异常');
                        });
                    }else{
                        resolve();
                    }
                })
            },
            resetMeth() {
                if (this.activeName === 'welcomePage') {
                    this.$refs.editWelDetail.defaultDataMeth();
                } else if (this.activeName === 'home') {
                    this.$refs.editHomeHihotel.defaultDataMeth();
                }
                this.$message.success('重置成功');
            },
            confirmMeth(info) {
                this.$confirm(`确认发布?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.publishPostMeth(info);
                });
            },
            hideShade(){
                var editWelDetail=this.$refs.editWelDetail;
                var editHomeHihotel=this.$refs.editHomeHihotel;
                var editHomeEhotel=this.$refs.editHomeEhotel;
                var hotelService=this.$refs.hotelService;
                var hotelServiceTwo=this.$refs.hotelServiceTwo;
                var hotelServiceThree=this.$refs.hotelServiceThree;
                var jumpUrl=this.$refs.jumpUrl;
                if(editWelDetail){
                    this.$refs.editWelDetail.hideShade();
                }
                if(editHomeHihotel){
                    this.$refs.editHomeHihotel.hideShade();
                }
                if(editHomeEhotel){
                    this.$refs.editHomeEhotel.hideShade();
                }
                if(hotelService){
                    this.$refs.hotelService.hideShade();
                }
                if(hotelServiceTwo){
                    this.$refs.hotelServiceTwo.hideShade();
                }
                if(hotelServiceThree){
                    this.$refs.hotelServiceThree.hideShade();
                }
                if(jumpUrl){
                    this.$refs.jumpUrl.hideShade();
                }
            },
            getTemplateWelTextModelCur(){
                var modelStr='';
                var template_id=this.getMsg.template_id;
                if(this.templateWelTextModel.logo.indexOf(template_id)!==-1){
                    modelStr='logo';
                }
                if(this.templateWelTextModel.none.indexOf(template_id)!==-1){
                    modelStr='none';
                }
                this.templateWelTextModelCur=modelStr;
            },
            getTemplateHomeTextModelCur(){
                var modelStr='';
                var template_id=this.getMsg.template_id;
                if(this.templateHomeTextModel.text.indexOf(template_id)!==-1){
                    modelStr='text';
                }
                if(this.templateHomeTextModel.img.indexOf(template_id)!==-1){
                    modelStr='img';
                }
                this.templateHomeTextModelCur=modelStr;
            },
            modelIsReadOnly(){
                // this.$http.get('http://www.ssp_backstage.com/api/v1/Visual/getModelIsReadOnly?token=a4c632e4-56c7-45a0-adcc-947330694702&page=1&limit=5',{
                // this.$http.get(this.global.getModelIsReadOnly,{
                //     params:{
                //         template_id:this.configData.template_id,
                //         token:this.token
                //     }
                // }).then(res=>{
                //     if(res.data.code===100000){
                //         this.modelReadOnly=res.data.data;
                //
                //     }
                // })
            },
            getObjFirst(obj){
                for(var key in obj){
                    this.activeName=key;
                    return obj[key];
                }
            },
            filterHotelService(){
                var obj=this.$attrs.showType==='action'?this.getMsg.tab:this.$store.state.configData.tab;
                var that=this;
                Object.keys(obj).forEach(function(key){
                    if(key==='service1'){
                        that.hotelServiceData=obj[key];
                    }
                    if(key==='service2'){
                        that.hotelServiceTwoData=obj[key];
                    }
                    if(key==='service3'){
                        that.hotelServiceThreeData=obj[key];
                    }
                });
            },
            sortTabTitle(newVal){
                var arr=[];
                var order='';
                var obj=newVal.tab;
                Object.keys(obj).forEach(function(key){
                    order=obj[Object.keys(obj)[key]].order;
                    arr.push(order)
                });
                arr.sort(function(a,b){
                    if(parseInt(a)<parseInt(b)){
                        return -1;
                    }else if(parseInt(a) == parseInt(b)){
                        return 0;
                    }else{
                        return 1;
                    }
                });
            },
            showRoomDialog(val){
                if(val){
                    if(this.getMsg.roomList &&  this.getMsg.roomList.length!=0){
                        this.roomDialog.dialogVisible=val;
                    }
                }else{
                    this.roomDialog.dialogVisible=val;
                }
            },
            publishListMeth(){
                this.$refs.publishList.showDrawer(true);
                this.$refs.publishList.getHotelConfigInfo(this.hotelContract);
            },
            showConfigDetailDrawer(id){
                // console.log('触发弹窗',id);
                // Bus.$emit('showConfigDetailDrawer',id);
            }
        },
        mounted() {
            this.token = sessionStorage.getItem('token');
            this.query=this.$route.query;
            this.getObjFirst(this.getMsg.tab);
        },
        created: function () {
        this.getMsg = this.configData;
        },
        watch: {
            configData: {
                handler: function (newVal) {
                    console.log(newVal);
                    if(!this.noData){
                         this.getMsg = newVal;
                        this.tabShow = true;
                        this.getTemplateWelTextModelCur();
                        this.getTemplateHomeTextModelCur();
                        this.modelIsReadOnly();
                        this.initConfigData=true;
                        this.filterHotelService();
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        computed:{
            tabNameCom(){
                return function(index){
                    var outKey='';
                    var obj=this.getMsg.tab;
                    var str='';
                    if(this.getMsg.tab){
                        if(this.getMsg.tab[Object.keys(obj)[index]]){
                            str=this.getMsg.tab[Object.keys(obj)[index]].title;
                            Object.keys(obj).forEach(function(key){
                                if(obj[key].title==str){
                                    outKey=key;
                                }
                            });
                            return {
                                key:outKey,
                                name:str
                            };
                        }else{
                            return {
                                key:'',
                                name:''
                            };
                        }
                    }else{
                        return {
                            key:'',
                            name:''
                        };
                    }
                }
            },
            roomLengthCom(){
                if(this.getMsg.roomList && this.getMsg.roomList.length!=0){
                    return this.getMsg.roomList.length;
                }else{
                    return 0;
                }
            }
        },
        created(){
            Bus.$on('getHotelDataThree',val=>{
                console.log('edit组件保存后接收',this.$store.state.configData);

            })
        }
    }
</script>

<style scoped lang="scss">
    .rightBtn{
        margin-left:auto;
    }
    .testBox{
        width:100%;
        text-align: center;
        margin-top:40px;
    }
    .mainBox{
        flex-direction: column;
        align-items: center;
    }
    .roomItemBox{
        margin-bottom:10px;
    }
    .roomItem{
        padding:10px;
        border-radius:4px;
        text-align: center;
        border:1px solid #ddd;
        cursor:pointer;
        position:relative;
        color: #3085fc;
    }
    .btnBox{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: flex-start;
    }
    .numShow{
        margin-left:20px;
    }
    .blueText{
        color:#409eff;
        cursor:pointer;
    }
    ::v-deep .el-tabs__header{
        width: 100%;
    }
    ::v-deep .el-tabs__content{
        width: 100%;
    }
</style>
