<template>
  <div class="grid-content bg-purple chartCon">
    <div class="chartTitle blueTitle" v-if="chart.showTitle">收益数据走势</div>
    <el-tabs v-model="activeName" @tab-click="changeTab">
      <el-tab-pane label="影视预估收益" name="all" v-if="chart1Show">
        <div :id="chart.id" ref="chart1" v-if="activeName=='all'" tyle="width:96%;height:280px;" class=" echarts chart chartBox"></div>
      </el-tab-pane>
      <el-tab-pane label="独立影视预估收益" name="one" v-if="chart2Show">
        <div :id="chart2.id"   ref="chart2" v-if="activeName=='one'" style="width:96%;height:280px;" class=" echarts chart chartBox"></div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import echarts from "echarts";
import GLOBAL from "../../../api/globalVar.js";
export default {
  name: "LineChart",
  props: ["chart", "chart2", "chart1Show", "chart2Show"],
  data: () => ({
    activeName: "all",
    yAxis: [],
    yAxis2: [],
    allData: {},
  }),
  methods: {
    changeTab() {
      if (this.activeName == "all") {
        this.$nextTick(() => {
          this.getYaxis("元", "%", "元");
          this.transData("单点收益", "日活率", "天卡收益");
        });
      } else {
        this.$nextTick(() => {
          this.getYaxis2("元", "元");
          this.transData2("单点收益", "天卡收益", "");
        });
      }
    },
    chartRender() {
      var chart = echarts.init(this.$refs.chart1);
      var that = this;
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          x: "center",
          y: "bottom",
          padding: [0, 0, 0, 0],
          icon: "circle",
          data: this.chart.option.legend,
        },
        grid: {
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#ced7ec",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#333",
            },
          },
          data: this.chart.option.xData,
        },

        yAxis: this.yAxis,
        series: this.chart.option.series,
      };
      chart.setOption(option);
    },
    chartRender2() {
        var chart = echarts.init(this.$refs.chart2);
      var that = this;
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          x: "center",
          y: "bottom",
          padding: [0, 0, 0, 0],
          icon: "circle",
          data: this.chart2.option.legend,
        },
        grid: {
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#ced7ec",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#333",
            },
          },
          data: this.chart2.option.xData,
        },

        yAxis: this.yAxis2,
        series: this.chart2.option.series,
      };

      chart.setOption(option);
    },
    getYaxis(unitOne, unitTwo, unitThree) {
      this.yAxis = [
        {
          type: "value",
          axisLabel: {
            formatter: `{value}${unitOne}`,
            textStyle: {
              color: GLOBAL.chartColor[0],
            },
          },
          show: true,
          splitNumber: 5,
          position: "right", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            // formatter:`{value}(${unitTwo})`,
            formatter: `{value}${unitTwo}`,
            textStyle: {
              color: GLOBAL.chartColor[1],
            },
          },
          show: true,
          splitNumber: 5,
          position: "left", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            // formatter:`{value}(${unitTwo})`,
            formatter: `{value}${unitThree}`,
            textStyle: {
              color: GLOBAL.chartColor[2],
            },
          },
          show: true,
          splitNumber: 5,
          position: "left", //多个Y轴使用
          offset: 50,
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
      ];
    },
    getYaxis2(unitOne, unitTwo) {
      this.yAxis2 = [
        {
          type: "value",
          axisLabel: {
            formatter: `{value}${unitOne}`,
            textStyle: {
              color: GLOBAL.chartColor[2],
            },
          },
          show: true,
          splitNumber: 5,
          position: "left", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            // formatter:`{value}(${unitTwo})`,
            formatter: `{value}${unitTwo}`,
            textStyle: {
              color: GLOBAL.chartColor[3],
            },
          },
          show: true,
          splitNumber: 5,
          position: "right", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
      ];
    },
    calMin(arr) {
      var min = 0;
      arr.forEach((el) => {
        el.forEach((el1) => {
          if (!(el1 === undefined || el1 === "")) {
            if (min > el1) {
              min = el1;
            }
          }
        });
      });
      var minint = Math.floor(min / 10);
      var minval = minint * 10; //让显示的刻度是整数
      return minval;
    },
    calMax(arr) {
      var max = 0;
      arr.forEach((el) => {
        el.forEach((el1) => {
          if (!(el1 === undefined || el1 === "")) {
            if (parseInt(max) < parseInt(el1)) {
              max = el1;
            }
          }
        });
      });
      var maxint = Math.ceil(max / 9.5); //不让最高的值超过最上面的刻度
      var maxval = maxint * 10; //让显示的刻度是整数
      return maxval;
    },
    transData(nameOne, nameTwo, nameThree, nameFour) {
      var series = this.chart.option.series;
      series.filter((item, index) => {
        if (item.name === nameOne) {
          this.minMaxMeth(item.data, index);
        } else if (item.name === nameTwo) {
          this.minMaxMeth(item.data, index);
        } else if (item.name === nameThree) {
          this.minMaxMeth(item.data, index);
        } else if (item.name === nameFour) {
          this.minMaxMeth(item.data, index);
        }
      });
      this.chartRender();
    },
    transData2(nameOne, nameTwo, nameThree, nameFour) {
      var series2 = this.chart2.option.series;
      series2.filter((item, index) => {
        if (item.name === nameOne) {
          this.minMaxMeth2(item.data, index);
        } else if (item.name === nameTwo) {
          this.minMaxMeth2(item.data, index);
        }
      });
      this.chartRender2();
    },
    minMaxMeth(data, index) {
      var Min = this.calMin([data]);
      var Max = this.calMax([data]);
      Max = Max === 0 ? 10 : Max;
      this.yAxis[index].min = Min;
      this.yAxis[index].max = Max;
      this.yAxis[index].interval = (Max - Min) / 5;
    },
    minMaxMeth2(data, index) {
      var Min = this.calMin([data]);
      var Max = this.calMax([data]);
      Max = Max === 0 ? 10 : Max;
      this.yAxis2[index].min = Min;
      this.yAxis2[index].max = Max;
      this.yAxis2[index].interval = (Max - Min) / 5;
    },
  },
  mounted() {
    var id = this.id;
    var fromUrl = this.chart.fromUrl;
    if (this.chart1Show) {
      this.activeName = "all";
    } else {
      this.activeName = "one";
    }
    this.changeTab();
  },
  watch: {
    chart2: {
      handler: function (newVal) {
        this.chart2 = newVal;
        this.$nextTick(() => {
          this.getYaxis2("元", "元");
          this.transData2("单点收益", "天卡收益", "");
        });
      },
      deep: true,
      immediate: true,
    },
    chart: {
      handler: function (newVal) {
        this.chart = newVal;
        this.$nextTick(() => {
          this.getYaxis("元", "%", "元");
          this.transData("单点收益", "日活率", "天卡收益");
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.grid-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*padding:15px 20px;*/
  .leftBox {
    display: flex;
    align-items: center;
  }
  .numTitle {
    width: 150px;
  }
  .num {
    font-size: 24px;
    margin-right: 10px;
  }
}
.bg-purple {
  background: #fff;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  /*padding:20px 20px;*/
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.chartBox {
  height: 400px;
  overflow: hidden;
}
.grid-content {
  box-sizing: border-box;
}
.el-col {
  height: 100%;
  .grid-content {
    height: 100%;
  }
}
.chartTitle {
  width: 100%;
  margin-bottom: 15px;
}
.chartCon {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#chart,
.chart,
.chart2 {
  width: 96%;
  height: 280px;
  margin: 0 2%;
}
.el-tabs {
  width: 100%;
  height: 100%;
}
// .echarts{
//     width: 100%;
//     height: 300px;
// }
</style>
