<template>
  <div>
    <div class="btnRow">
      <!-- <el-button type="primary" class="largeBtn" @click="$refs.DailyLogDetail.open('新增',query.hotelName)">添 加</el-button> -->
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <DailyLogDetail ref="DailyLogDetail"></DailyLogDetail>
  </div>
</template>
<script>
import { getList,allDelete} from "../api/operationLog/DailyLog";
import DailyLogDetail from "./components/DailyLogDetail.vue";
export default {
  props: {
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  name: "DailyLog",
  components: {
    DailyLogDetail,
  },
  data() {
    return {
      tableData: {
        tableList: [ ],
        checked: false,
        tableLoading: false,
        page: 1,
        size: 2,
        props: [
          {
            label: "操作员",
            prop: "uname",
            minWidth: "100",
            type:"text",
          },
          {
            label: "操作信息",
            prop: "operate",
            minWidth: "400",
            type:"text",
          },
          {
            label: "时间",
            prop: "time",
            minWidth: "120",
            type:"text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            info: (row) => {
              this.$refs.DailyLogDetail.open('详情',this.query.hotelName,row)
            },
          },
        ],
      },
      listQuery: {
        database: this.query.hotelName,
        hotel_contract: this.query.hotel_contract,
        ipaddr: this.query.ipaddr,
        page: 1,
        size: 10
      },
      checkList: [],
    };
  },
  methods: {
    changeSize(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
      
    },
    changeCurrent(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
    },
    changeSelection(val) {
        this.checkList = val.map(v=>{
          return v.id
        })
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
      }
      getList(this.listQuery).then(res => {
        if (res.data.code == 100000) {
          this.$refs.operationTable.changeTotal(Number(res.data.data.totalNum));
          if (res.data.data.totalNum > 0) {
            this.tableData.tableList = res.data.data.row;
          } else {
            this.tableData.tableList = []
          }
          this.tableData.tableLoading = false;
        }
      })
    },
  },
  mounted(){
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map(i=>{
      if(i.ltype==1){
        i.language ="中文"
      }else if(i.ltype==2){
         i.language ="英文"
      }else if(i.ltype==3){
         i.language ="日文"
      }else if(i.ltype==4){
         i.language ="韩文"
      }
      return i
    })
  },
  watch: {
    query: {
      handler: function (newVal) {
       this.query=newVal;
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
