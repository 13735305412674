<template>
  <div>
    <uploadSourceMore ref="uploadSourceMore" sourceType="pic" @upsuccessmore="upSuccessMore"></uploadSourceMore>
    <uploadSource ref="uploadSource" :sourceType="sourceType" :size="sourceType=='pic'?'1920*1080':''" @upsuccess='upSuccess'></uploadSource>
    <div>

      <div class="itemBox" v-for="(item,index) in dataList" :key="index">
        <i class="el-icon-delete" @click="delItem(index)" v-if="(dataList.length>1||!is_first)&&!notOpt&&!isEdit"></i>
        <el-form :model="item">
          <el-row :gutter='24'>
            <el-col :span="3" class="first">
              <el-form-item prop="sort">
                <el-input :disabled="notOpt&&noChangeTitle" :readonly="isEdit" v-model="item.sort" placeholder="序号" @change="numberTrue(index)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="title">
                <el-input :disabled="notOpt&&noChangeTitle" :readonly="isEdit" v-model="item.title" :placeholder="sourceType=='pic'?'图片标题':'视频标题'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="is_first?12:16">
              <el-form-item prop="desc">
                <el-input :disabled="notOpt&&noChangeTitle" :readonly="isEdit" v-model="item.desc" :placeholder="sourceType=='pic'?'图片描述':'视频描述'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="is_first">
              <el-form-item prop="labelValue">
                <el-input :disabled="notOpt" :readonly="isEdit" v-model="item.labelValue" :placeholder="sourceType=='pic'?'图片标签':'视频标签'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="是否在线" v-if="!is_first&&sourceType!='pic'" style="margin-bottom:0">
            <div @click="getUpRef(index)">
              <el-radio-group v-model="item.isOnline" style='line-height:50px' @change="changeOnline">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item v-if="!item.isOnline||item.isOnline==0">
            <div @click="getUpRef(index)" class="felxBox" v-if="sourceType=='pic'">
              <div class="itemShowBox" style="width:150px;height:150px;position:relative" v-if='item.fileList.length>0' v-for="(itemPic,pic) in item.fileList" :key="pic">
                <img style="max-width:100%;max-height:100%" :src="itemPic.source_url" alt="">
                <div class="icon">
                  <i class="el-icon-zoom-in" @click="openShow(itemPic.source_url)"></i>
                  <i class="el-icon-close" v-if="!isEdit" @click="del(index,pic)"></i>
                </div>
              </div>
              <div class="addBox" @click="openAddPage(index)" v-if='item.fileList.length==0'>
                <i class="el-icon-plus"></i>
              </div>
            </div>
            <div @click="getUpRef(index)" class="felxBox" v-if="sourceType!='pic'">
              <div class="itemShowBox" style="width:150px;height:150px;position:relative" v-if='item.fileList.length>0' v-for="(itemPic,pic) in item.fileList" :key="pic">
                <video style="max-width:100%;max-height:100%" :src="itemPic.source_url" :controls='false' alt=""></video>
                <div class="icon">
                  <!-- <i class="el-icon-zoom-in" @click="openShow(item.url)"></i> -->
                  <i class="el-icon-close" v-if="!isEdit" @click="del(index,pic)"></i>
                </div>
              </div>
              <div class="addBox" @click="openAddPage(index)" v-if='(item.fileList.length==0||canUploadMore)&&!isEdit'>
                <i class="el-icon-plus"></i>
              </div>
              <!-- <uploadMorePicDivMore :list='item.fileList' :sourceType="sourceType" :is_first="is_first" :upIndex="index" @defaultData="getData"></uploadMorePicDivMore> -->
            </div>
          </el-form-item>
          <el-form-item v-if="item.isOnline==1" class="urlBox">
            <div v-for="(file,t) in item.fileList" :key="t+'url'" style="width:100%;margin-bottom:10px">
              <el-input v-model="file.source_url" style="width:calc(100% - 120px)"></el-input>

            </div>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="text" @click="addItem" v-if="!notOpt&&!isEdit">{{sourceType=='pic'?'添加内页':'添加视频'}}</el-button>
    </div>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import uploadSourceMore from "./uploadSourceMore";
import uploadSource from "./uploadSource";
export default {
  name: "uploadPicTextMore2",
  props: [
    "titleDataList",
    "sourceType",
    "is_first",
    "optTheindex",
    "notOpt",
    "isEdit",
    "noChangeTitle",
    "canUploadMore",
  ],
  components: {
    uploadSourceMore,
    uploadSource,
  },
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "",
      dataList: [],
      headers: {
        token: "",
      },
      type: "",
      optIndex: "",
      currentIndex: "",
      currentChildIndex: "",
      content: "",
      lid: "",
      ltype: "",
      mid: "",
      mname: "",
      type: "",
    };
  },
  watch: {
    titleDataList: {
      handler: function (newVal) {
        if (newVal[0] && newVal[0].fileList[0]) {
          this.content = newVal[0].fileList[0].content;
          this.lid = newVal[0].fileList[0].lid;
          this.ltype = newVal[0].fileList[0].ltype;
          this.mid = newVal[0].fileList[0].mid;
          this.mname =newVal[0].fileList[0].mname;
          this.type = newVal[0].fileList[0].type;
          this.dataList = newVal.map((i) => {
              i.isOnline = i.isOnline?`${i.isOnline}`:false;
            return i;
          });
        } else {
          this.dataList = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    dataList: {
      handler: function (newVal) {
        this.$emit("defaulttitledata", this.dataList, this.optTheindex);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    upSuccess(data) {
      let obj = {
        content: this.content,
        lid: this.lid,
        ltype: this.ltype,
        mid: this.mid,
        mname: this.mname,
        type: this.type,
        update: true,
        source_md5: data.md5,
        source_sort: 999,
        source_type: this.sourceType == "pic" ? "1" : "2",
        source_url: data.url,
      };
      this.dataList[this.currentIndex].fileList.push(obj);
    },
    upSuccessMore(data) {
      let obj = {
        content: this.content,
        lid: this.lid,
        ltype: this.ltype,
        mid: this.mid,
        mname: this.mname,
        type: this.type,
        update: true,
        source_md5: data.md5,
        source_sort: 999,
        source_type: this.sourceType == "pic" ? "1" : "2",
        source_url: data.url,
      };
      this.dataList[this.currentIndex].fileList.push(obj);
    },
    openAddPage(index, pic) {
      this.currentIndex = index;
      this.currentChildIndex = pic;
      this.$refs.uploadSource.dialogVisible = true;
    },
    del(index, pic) {
      this.dataList[index].fileList.splice(pic, 1);
    },
    openShow(url) {
      this.dialogVisible = true;
      this.dialogImageUrl = url;
    },
    changeOnline() {
      if( this.dataList[this.optIndex].isOnline =='1'){
        this.dataList[this.optIndex].fileList = [{source_url:''}];
      }else{
          this.dataList[this.optIndex].fileList =[]
      }    
    },
    delfile(index, t) {
      this.dataList[index].outFileList.splice(t, 1);
    },
    addfile(index) {
      this.dataList[index].outFileList.push({ url: "" });
    },
    // 数值验证
    numberTrue(index) {
      let flag = /^[0-9]+$/.test(this.dataList[index].sort);
      if (!flag) {
        this.dataList[index].sort = "";
        this.$message.warning("请输入整数进行排序");
      }
    },
    // 数据更新
    getData(data, index) {
      this.dataList[index].fileList = data;
    },
    // 删除项
    delItem(index) {
      this.$nextTick(() => {
        this.dataList.splice(index, 1);
      });
    },
    // 添加项
    addItem() {
      this.$emit("additem", this.optTheindex);
    },
    // 操作位置获取
    getUpRef(index) {
      this.optIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.felxBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .itemShowBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .addBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    cursor: pointer;
    i {
      font-size: 40px;
    }
  }
}

.icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;

  justify-content: center;
  align-items: center;
  z-index: 9;
  i {
    color: #fff;
    font-size: 16px;
    margin: 5px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #999;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    background: rgba($color: #000000, $alpha: 0.3);
  }
}
.itemShowBox:hover .icon {
  display: flex;
}
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
.itemBox {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px 20px 0;
  box-sizing: border-box;
  position: relative;
  .el-icon-delete {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #999;
    position: absolute;
    top: -12px;
    right: -12px;
    text-align: center;
    line-height: 24px;
    color: #999;
    background: #fff;
    transition: 0.3s;
  }
  .el-icon-delete:hover {
    border: 1px solid red;
    color: red;
  }
}

.el-col {
  padding: 0 12px 0 0 !important;
}
.first {
  padding-left: 12px !important;
}
.urlBox ::v-deep .el-form-item__content {
  flex-wrap: wrap;
}
</style>