<template>
    <coop-tab-rate  :id="partnerId"  hotelOrPartner="partner" :sys="true" :click="click"></coop-tab-rate>
</template>

<script>
    export default {
        name: "finance",
        props:['partnerId','click'],
        data: () => ({
            imageUrl:'',
            query:{
                id:''
            },
            form: {
                performance: '',
                movieRate:'',
                movieStatus:'',
                adRate:'',
                adStatus:'',
            },
            rules: {
                name: [
                    { required: true, message: '请输入保底业绩完成量', trigger: 'blur' },
                ],
            },
            flashCost:{
                switch:'',
                group:[
                    {base_on:'',num:'100',price:'200',switch:'',type:'F'},
                    {base_on:'',num:'100',price:'400',switch:'100',type:'F'},
                    {base_on:'',num:'200',price:'300',switch:'',type:'F'},
                ]
            },

            marketCost:{
                switch:'',
                group:[
                    {base_on:'',num:'',price:'',switch:'',type:'F'},
                    {base_on:'',num:'',price:'',switch:'',type:'F'},
                    {base_on:'',num:'',price:'',switch:'',type:'F'},
                ]
            },
            vodOnlineCost:{
                switch:'',
                group:[
                    {base_on:'',num:'',price:'',switch:''},
                    {base_on:'',num:'',price:'',switch:''},
                    {base_on:'',num:'',price:'',switch:''},
                ]
            },
            vodOfflineCost:{
                switch:'',
                group:[
                    {base_on:'',num:'',price:'',switch:''},
                    {base_on:'',num:'',price:'',switch:''},
                    {base_on:'',num:'',price:'',switch:''},
                ]
            },
            adCost:{
                switch:'',
                group:[
                    {base_on:'',num:'',price:'',switch:''},
                    {base_on:'',num:'',price:'',switch:''},
                    {base_on:'',num:'',price:'',switch:''},
                ]
            },
            allData:[],
        }),
        methods: {
        },
        mounted(){
        }

    }
</script>

<style scoped lang="scss">
    .box-card{
        margin-top:20px;
    }
    .idCard>.one{
        margin-right:40px;
    }
    .imgBox{
        height:150px;
        /*border-radius:4px;*/
        /*border:1px dashed #ddd;*/
        /*background:#f2f2f2;*/
        border:1px dashed #ddd;
    }
    .idCard .one{
        margin-right:20px;
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        /*background: #d3dce6;*/
        background: #f2f2f2;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .bottom{
        width:100%;
        padding:0 20px 20px 20px;
        overflow:hidden;
        .item{
            display:flex;
            /*justify-content: space-between;*/
            margin-bottom:20px;
        }
        .item:last-child{
            margin-bottom:0;
        }
        .first{
            display:flex;
            align-items: center;
            white-space: nowrap;
            margin-right:20px;
        }
        .two{
            white-space: nowrap;
        }
        .innerInput{
            width:190px;
            margin:0 10px;
        }
        .hideInput{
            visibility: hidden;
        }
    }

</style>
