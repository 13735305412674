<!-- 参数配置弹窗 -->
<template>
  <el-dialog :title="
      '参数' +
        (type == 'add' ? '增加' : type == 'edit' ? '修改' : '删除') +
        ' 批量任务创建'
    " :visible.sync="dialogVisible" width="550px" @closed="handleClose">
    <!-- 表单 -->
    <div>
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item label="参数名称：" prop="name" :rules="[{ required: true, message: '参数名称不能为空' }]">
          <el-select style="width:100%" v-model="form.name" placeholder="" clearable class="fullSelect" filterable>
            <el-option v-for="item in paramsList" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
          <!-- <el-input v-model="form.name"></el-input> -->
        </el-form-item>
        <el-form-item label="top值：" prop="x" v-if="type != 'del'">
          <el-input v-model="form.x"></el-input>
        </el-form-item>
        <el-form-item label="left值：" prop="y" v-if="type != 'del'">
          <el-input v-model="form.y"></el-input>
        </el-form-item>
        <el-form-item label="版本说明：" prop="versionIntro" :rules="[{ required: true, message: '版本说明不能为空' }]">
          <el-input v-model="form.versionIntro"></el-input>
        </el-form-item>
        <el-form-item label="酒店选择：">
          <HotelSelect ref="hotelSelect" :noInsert="true" type='set' v-model="hotels"></HotelSelect>
          <span class="select_tip">(已选择{{ hotels.length }})</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">发 布</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import HotelSelect from "../../../common/operation/components/hotelSelect";
import { paramBatch } from "../api/mul";
import { getList } from "../../SettingManage/api/params";
export default {
  name: "mulSetParamsDialog",
  components: { HotelSelect },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "add",
    },
    pageType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
        auth:null,
      paramsList: [],
      dialogVisible: this.visible,
      form: {
        name: "",
        x: "",
        y: "",
        versionIntro: "",
      },
      hotels: [],
    };
  },
  methods: {
    getParamsList(page) {
      getList({
        parameter_input: "",
        page: 1,
        per_page: 99999,
      }).then((data) => {
        if (this.$route.path == "/onePage/onlyHomeIndex") {
          this.paramsList = [
            {
              id: 8,
              name: "主页默认跳转",
              state: 1,
              create_time: 0,
              update_time: 0,
              create_time_text: "",
              update_time_text: "",
            },
          ];
        } else {
          this.paramsList = data.data.data.row;
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.hotels = [];
      this.$refs.hotelSelect.reset();
    },
    submit() {
      if (
        this.form.name.indexOf("--") != -1 ||
        this.form.x.indexOf("--") != -1 ||
        this.form.y.indexOf("--") != -1
      ) {
        this.$message.warning("不可以有英文字符--，请用其他字符替换");
        return false;
      }
      if (
        this.form.name.indexOf("'") != -1 ||
        this.form.x.indexOf("'") != -1 ||
        this.form.y.indexOf("'") != -1
      ) {
        this.$message.warning("不可以有英文字符 '，请用其他字符替换");
        return false;
      }
      if (
        this.form.name.indexOf('"') != -1 ||
        this.form.x.indexOf('"') != -1 ||
        this.form.y.indexOf('"') != -1
      ) {
        this.$message.warning('不可以有英文字符 "，请用其他字符替换');
        return false;
      }
      if (this.hotels.length == 0) {
        this.$message.warning("请选择酒店后进行操作");
        return false;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            intro: this.form.versionIntro,
            updateType: this.pageType,
            action: this.type,
            hotel_str: JSON.stringify(this.hotels),
            param_name: this.form.name,
            param_top: this.form.x,
            param_left: this.form.y,
            user_name:this.auth.user_name,
          };
          paramBatch(params).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.dialogVisible = false;
              this.$emit("successmeth");
            } else {
              this.$message.error(data.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {
    this.getParamsList();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>
<style lang="scss" scoped>
.select_tip {
  margin-left: 10px;
}
</style>
