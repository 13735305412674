<template>

  <div class="box" :style="{width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px'}">

    <div class="city_info" v-if="!style.type" :style="{color:style.color?style.color:'#fff'}">
      <div class="info_one" :style="{borderRight: `2px solid ${style.color?style.color:'#fff'}`}">
        <div class="top">{{curTime.time}}</div>
        <div class="bottom" style="letter-spacing: 0px;">{{curTime.date}}</div>
      </div>
      <div class="info_two">
        <div class="top">广州 晴</div>
        <div class="bottom">25~30℃</div>
      </div>
    </div>
    <div class="city_info" v-if="style.type=='4'" :style="{color:style.color?style.color:'#fff'}">
      <div class="info_one" :style="{borderRight: `2px solid rgba(40, 40, 40, 0.70)`}">
        <div class="top" style="color:#282828;fontSize:39px;marginBottom:10px;letter-spacing: -3px;">{{curTime.time}}</div>
        <div class="bottom" style="color:rgba(40, 40, 40, 0.70);fontSize:16px">{{curTime.msTime+' '+curTime.week}}</div>
      </div>
      <div class="info_two info_two2">
        <div class="top" style="width:100%;color:rgba(40, 40, 40, 0.70);fontSize:16px">广州 晴</div>
        <div class="bottom" style="color:rgba(40, 40, 40, 0.70);fontSize:16px">30℃</div>
      </div>
    </div>
    <div class="city_info" v-if="style.type=='1'" :style="{color:style.color?style.color:'#fff'}">
      <div class="info_one" :style="{borderRight: `2px solid rgba(40, 40, 40, 0.70)`}">
        <div class="top" style="color:#282828;fontSize:39px;marginBottom:10px;letter-spacing: -3px;">{{curTime.time}}</div>
        <div class="bottom" style="color:rgba(40, 40, 40, 0.70);fontSize:16px">{{curTime.msTime+' '+curTime.week}}</div>
      </div>
      <div class="info_two">
        <div class="top"><img style="width:39px;height:39px" src="@/assets/w00.png" alt=""></div>
        <div class="bottom" style="color:rgba(40, 40, 40, 0.70);fontSize:16px">30℃ 晴</div>
      </div>
    </div>
    <div class="city_info" v-if="style.type=='2'" :style="{color:style.color?style.color:'#fff'}">
      <div class="info_one" :style="{borderRight: `2px solid rgba(255, 255, 255, 0.70)`}">
        <div class="top" style="color:#fff;fontSize:39px;marginBottom:10px;letter-spacing: -3px;">{{curTime.time}}</div>
        <div class="bottom" style="color:rgba(255, 255, 255, 0.70);fontSize:16px">{{curTime.msTime+' '+curTime.week}}</div>
      </div>
      <div class="info_two">
        <div class="top"><img style="width:39px;height:39px" src="@/assets/w00.png" alt=""></div>
        <div class="bottom" style="color:rgba(255, 255, 255, 0.70);fontSize:16px">30℃ 晴</div>
      </div>
    </div>
    <div class="city_info city_info_LIFENG" v-if="style.type=='5'" :style="{color:style.color?style.color:'#fff'}">
      <img style="width:40px;height:40px;margin-right:6px" src="@/assets/w00.png" alt="">
      <div class="text">
        <div style="fontSize:20px;margin-right:5px">30℃</div>
        <div style="fontSize:30px;margin-right:8px;letter-spacing: -3px;">{{curTime.time}}</div>
        <div style="fontSize:20px;margin-right:10px">PM</div>
        <div style="fontSize:20px;letter-spacing: -1px;">{{curTime.date}}</div>
      </div>

    </div>
  </div>

</template>

<script>
import commonMeth from "@/api/method";
export default {
  name: "weatherBox",
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        let obj = JSON.parse(JSON.stringify(newVal));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      curTime: {
        date: "",
        week: "",
        time: "",
        msTime: "",
      },
    };
  },
  mounted() {
    setInterval(() => {
      this.curTime = commonMeth.getCurrentDate();
    }, 1000);
  },
  methods: {
    openUppage() {
      this.$refs.uploadSource.dialogVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: blue;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 3;
}
.city_info {
  display: flex;

  /*background:#80cbc4;*/
}
.city_info_LIFENG {
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  .text {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.city_info div {
  color: inherit;
  letter-spacing: 1px;
}
.info_one {
  height: 100%;

  /*background:#81d4fa;*/
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_one .top {
  /*font-weight:600;*/
  font-size: 22px;
}
.info_one .bottom {
  font-size: 14px;
}
.info_two {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.info_two2 {
  justify-content: center;
  align-items: flex-start;
  .bottom {
    margin-top: 20px;
  }
}
.info_two div {
  font-size: 14px;
}
</style>