<template>
  <div style="padding:0 15px">

    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">内容监管</span>
        <span slot="third">内容监管</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">内容监管列表</div>
      <!-- 操作栏 -->
      <div class="btnRow">
        <el-select style="width:15%;margin-right:10px" v-model="form.hotel" placeholder="请选择">
          <el-option v-for="item in hotelList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-select style="width:15%;margin-right:10px" v-model="form.area" placeholder="请选择">
          <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-date-picker style="width:15%;margin-right:10px" v-model="form.time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-select style="width:15%;margin-right:10px" v-model="form.level" placeholder="请选择">
          <el-option v-for="item in LevelList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
        <el-button type="primary" class="largeBtn" @click="reset">重 置</el-button>
        <!-- <el-button type="danger" class="largeBtn" @click="del">删 除</el-button> -->
      </div>
      <!-- 操作栏 -->
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />

    </el-card>

    <!-- 表格 -->

  </div>
</template>
<script>
export default {
  name: "warningList",

  data() {
    return {
      bread: {
        level: 3,
      },
      picName: "",
      hotelList: [
        { name: "考拉都市民宿沈阳北站店", id: 1 },
        { name: "希岸Deluxe酒店沈阳站店", id: 2 },
        { name: "麗枫酒店沈阳黎明广场地铁站店", id: 3 },
        { name: "沈阳于洪希尔顿惠庭酒店", id: 4 },
      ],
      areaList: [
        { name: "区域A", id: 1 },
        { name: "区域B", id: 2 },
        { name: "区域C", id: 3 },
        { name: "区域D", id: 4 },
      ],
      LevelList: [
        { name: "信息", id: 1 },
        { name: "警告", id: 2 },
        { name: "危险", id: 3 },
      ],
      // 表格数据信息
      tableData: {
        tableList: [
          {
            hotel: "考拉都市民宿沈阳北站店",
            time: "2021-02-23",
            area: "区域A",
            level: "信息",
            msg: "内容不合法",
          },
          {
            hotel: "希岸Deluxe酒店沈阳站店",
            time: "2021-02-23",
            area: "区域A",
            level: "信息",
            msg: "内容不合法",
          },
          {
            hotel: "麗枫酒店沈阳黎明广场地铁站店",
            time: "2021-02-23",
            area: "区域A",
            level: "信息",
            msg: "内容不合法",
          },
          {
            hotel: "沈阳于洪希尔顿惠庭酒店",
            time: "2021-02-23",
            area: "区域A",
            level: "信息",
            msg: "内容不合法",
          },
          {
            hotel: "测试酒店1",
            time: "2021-02-23",
            area: "区域A",
            level: "信息",
            msg: "内容不合法",
          },
          {
            hotel: "测试酒店1",
            time: "2021-02-23",
            area: "区域A",
            level: "信息",
            msg: "内容不合法",
          },
        ],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "酒店名称",
            prop: "hotel",
            minWidth: "80",
            type: "text",
          },
          {
            label: "区域名称",
            prop: "area",
            minWidth: "80",
            type: "text",
          },
          {
            label: "日期",
            prop: "time",
            minWidth: "120",
            type: "text",
          },
          {
            label: "报警级别",
            prop: "level",
            minWidth: "120",
            type: "text",
          },
          {
            label: "报警信息",
            prop: "msg",
            minWidth: "300",
            type: "text",
          },
        ],
      },

      // 表单字段
      form: {
        hotel: "",
        time: "",
        area: "",
        level: "",
      },
      checkList: [],
      n: 0,
    };
  },

  methods: {
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      //   this.getTableData(
      //     this.query.hotelName,
      //     this.tableData.limit,
      //     this.tableData.page
      //   );
    },
    reset() {
      this.form = {
        hotel: "",
        time: "",
        area: "",
        level: "",
      };
    },

    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      //   this.getTableData(this.query.hotelName, s, p);
    },
    // 选中项处理
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return i.id;
      });
    },
    // 获取表格数据
    getTableData(database, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        name: this.picName,
        page: p ? p : 1,
        limit: s ? s : 10,
      };
      getBasicPicture(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data.items;
            // 字段显示处理

            // 组件数据总数
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
</style>
