<template>
  <div>
    <el-form :model="form" ref="form" label-width="100px" class="demo-form" :rules="readonly?{}:rules">
      <!--<el-form-item label="账户ID" prop="account_id">-->
      <!--<el-form-item label="账户ID" >-->
      <!--<el-input v-model="form.account_id" :readonly="true"></el-input>-->
      <!--</el-form-item>-->
      <el-form-item label="公司名称" prop="company_name">
        <!--<el-form-item label="公司名称">-->
        <el-input v-model="form.company_name" :readonly="company_readonly"></el-input>
      </el-form-item>
      <el-form-item class="logoItem" label="公司logo" prop="logo_url">
        <!--<el-form-item label="公司名称">-->
        <uploadLogo v-model="form.logo_url" @change="changeData" :readonly="company_readonly"></uploadLogo>
      </el-form-item>
       <el-form-item label="系统名称" prop="system_name">
        <!--<el-form-item label="公司名称">-->
        <el-input v-model="form.system_name" :readonly="company_readonly"></el-input>
      </el-form-item>
      <!--<el-form-item label="合作类型" prop="type_text">-->
      <el-form-item label="合作类型">
        <!--<el-input v-model="form.type_text" :readonly="readonly"></el-input>-->
        <el-select v-model="form.type" :disabled="form.type=='htrip'" placeholder="请选择合作类型" class="selectFull" :readonly="type_readonly" clearable @change="typechange">
          <el-option :readonly="type_readonly" v-for="item in selectData.coopType" :key="item.key" :label="item.value" :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <!--<el-form-item label="上级渠道商" prop="superior">-->
      <el-form-item label="上级合作伙伴">
        <el-select v-model="form.superior" filterable :disabled="form.type=='htrip'" placeholder="请选择上级合作伙伴" class="selectFull" :readonly="channel_readonly" clearable @change="channelchange">
          <el-option :readonly="channel_readonly" v-for="item in channel" :key="item.id" :label="item.company_name" :value="item.company_name">
          </el-option>
        </el-select>
        <!--                <el-input v-model="form.superior" :readonly="company_readonly"></el-input>-->
      </el-form-item>
      <el-form-item label="联系人" prop="contact_name">
        <el-input v-model="form.contact_name" :readonly="readonly"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="contact_phone">
        <el-input v-model="form.contact_phone" :readonly="readonly"></el-input>
      </el-form-item>
      <el-form-item v-if="sysaccinfo && auth.type=='htrip'">
        <el-button v-if="!form.type=='htrip'" class="largeBtn" type="primary" @click="submitForm('form')">保存</el-button>
        <el-button v-if="!form.type=='htrip'" class="largeBtn" @click="resetForm('form')">重置</el-button>
      </el-form-item>
      <el-form-item v-if="!sysaccinfo||htriponly">
        <el-button class="largeBtn" type="primary" @click="submitcompany('form')">保存</el-button>
      </el-form-item>
      <el-form-item>
        <span class="tips"><i class="el-icon-warning-outline redFont"></i>如需更改信息，请拨打40000-77988联系管理员进行修改。</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import uploadLogo from "@/components/common/uploadLogo.vue";
import qs from "querystring";
export default {
  components: {
    uploadLogo,
  },
  name: "finance",
  props: ["readonly", "data", "allData", "sysaccinfo"],
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写手机号码"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
        return callback(new Error("请填写正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      htriponly: false,
      id: "",
      parentPartnerCode: "",
      company_name: "",
      accountType: "",
      company_readonly: true,
      type_readonly: true,
      channel_readonly: true,
      imageUrl: "",
      form: {
        id: "",
        parentPartnerCode: "",
        account_name: "",
        company_name: "",
        logo_url: "",
        type: "",
        superior: "",
        contact_name: "",
        contact_phone: "",
        type_text: "",
        system_name:"",
      },
      rules: {
        logo_url: [
          { required: true, message: "请上传公司logo", trigger: "blur" },
        ],
        account_id: [
          { required: true, message: "请输入账户ID", trigger: "blur" },
        ],
        account_name: [
          { required: true, message: "请输入账户名称", trigger: "blur" },
        ],
        system_name: [
          { required: true, message: "请输入系统名称", trigger: "blur" },
        ],
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        type_text: [
          { required: true, message: "请选择合作类型", trigger: "blur" },
        ],
        superior: [
          { required: true, message: "请选择上级渠道商", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contact_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
      },
      flashCost: {
        switch: "",
        group: [
          { base_on: "", num: "", price: "", switch: "", type: "F" },
          { base_on: "", num: "", price: "", switch: "", type: "F" },
          { base_on: "", num: "", price: "", switch: "", type: "F" },
        ],
      },
      selectData: {
        coopType: [],
      },
      channel: [],
      auth: {},
    };
  },
  methods: {
    changeData(val) {
      this.form.logo_url = val;
    },
    typechange() {
      this.$http
        .get(this.global.getPartnersByType, {
          params: {
            token: this.token,
            type: this.form.type,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.channel = res.data.data;
          }
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定保存", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (this.form.logo_url == "") {
                this.$message.warning("请上传公司logo");
              } else {
              this.submitMeth();
              }
            })
            .catch(() => {});
          // alert('submit!');
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },

    submitMeth() {
      var param = qs.stringify(this.form);
      this.$http
        .post(this.global.updateAccountInfoByCurrentAdmin, param, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$emit("successreturn");
            this.$message.success("保存成功");
            this.getDetail(this.allData);
          } else {
            this.$message.warning("配置失败");
          }
        });
    },
    //合作伙伴保存
    submitcompany(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定保存", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (this.form.logo_url == "") {
                this.$message.warning("请上传公司logo");
              } else {
                this.submint();
              }
            })
            .catch(() => {});
          // alert('submit!');
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    getcode(code) {
      var code = "";
      this.$http
        .get(this.global.partnerSearch, {
          params: {
            token: this.token,
            name: this.form.superior,
            page: 1,
            per_page: 1,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.parentPartnerCode = res.data.data.items[0].code;
            code = res.data.data.items[0].code;
          }
          return code;
        });
      return code;
    },
    channelchange() {
      this.$http
        .get(this.global.partnerSearch, {
          params: {
            token: this.token,
            name: this.form.superior,
            page: 1,
            per_page: 1,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.parentPartnerCode = res.data.data.items[0].code;
          }
        });
    },
    //提交合作伙伴
    submint() {
      this.$http
        .get(this.global.updatePartnerInfo, {
          params: {
            token: this.token,
            company_name: this.form.company_name,
            type: this.form.type,
            parentPartnerCode: this.parentPartnerCode,
            id: this.form.id,
            logo_url: this.form.logo_url,
            system_name:this.form.system_name         },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("保存成功");
            this.$emit("successreturn");
          } else {
            this.$message.success("配置失败");
          }
        });
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.form.company_name = "";
      this.form.contact_name = "";
      this.form.contact_phone = "";
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 < 500;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过500K!");
      }
      return isJPG && isLt2M;
    },

    getPartnerType() {
      this.$http
        .get(this.global.getIndexHeader, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.accountType = res.data.data.accountType;
            if (this.accountType === "1") {
              this.htriponly = true;
            } else {
              this.htriponly = false;
            }
          }
        });

      this.$http
        .get(this.global.partnerSearch, {
          params: {
            token: this.token,
            name: this.form.company_name,
            page: 1,
            per_page: 1,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            if (res.data.data.items[0]) {
              this.form.id = res.data.data.items[0].id;
            }
          }
        });
      this.$http
        .get(this.global.getPartnerTypes, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.coopType = res.data.data;
          }
        });
      this.$http
        .get(this.global.getPartnersByType, {
          params: {
            token: this.token,
            type: this.form.type,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.channel = res.data.data;
          }
        });
      this.$http
        .get(this.global.getIndexHeader, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.accountType = res.data.data.accountType;
            if (this.accountType == "1") {
              this.company_readonly = false;
              this.type_readonly = false;
              this.channel_readonly = false;
            }
          }
        });
    },
    getDetail(data) {
      var allData = data;
      if (allData.info) {
        this.form.company_name = allData.info.company_name;
        this.form.type = allData.info.type;
        this.form.logo_url = allData.info.logo_url ? allData.info.logo_url : "";
        this.form.system_name= allData.info.system_name ? allData.info.system_name : "";
        this.form.superior = allData.info.superior;
        allData.info.superior && allData.info.superior !== ""
          ? (this.form.superior = allData.info.superior)
          : (allData.info.superior = "暂无");
        // this.form.superior=allData.info.superior;
        this.form.contact_name = allData.info.contact_name;
        this.form.contact_phone = allData.info.contact_phone;
        this.company_name = allData.info.company_name;
        this.getPartnerType();
      }
    },
  },
  watch: {
    allData: {
      handler: function (newVal) {
        this.allData = newVal;
        this.getDetail(this.allData);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>

<style scoped lang="scss">
.idCard > .one {
  margin-right: 40px;
}
.imgBox {
  height: 150px;
  /*border-radius:4px;*/
  /*border:1px dashed #ddd;*/
  /*background:#f2f2f2;*/
  border: 1px dashed #ddd;
}
.idCard .one {
  margin-right: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  /*background: #d3dce6;*/
  background: #f2f2f2;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.selectFull {
  width: 100%;
}
.redFont {
  color: red;
  margin-right: 5px;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.logoItem ::v-deep .el-form-item__content{
  overflow: hidden;
}
</style>
