import request from "@/api/request";
import qs from "querystring";

//   apk任务下发列表
export function getList(param) {
    return request({
        url: `v2/batch_set/apkTask`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   无签名机型顶部导出
export function exportNoSign(param) {
    return request({
        url: `v2/batch_set/noPlatformExport`,
        method: "post",
        data: qs.stringify(param),
        responseType: "blob",
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   顶部表格数据导出
export function exportData(param) {
    return request({
        url: `v2/batch_set/contentAPKALLExport`,
        method: "post",
        data: qs.stringify(param),
        responseType: "blob",
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   表格数据导出
export function rowExport(param) {
    return request({
        url: `v2/batch_set/contentVersionExport`,
        method: "post",
        data: qs.stringify(param),
        responseType: "blob",
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   任务下发
export function taskSend(param) {
    return request({
        url: `v2/batch_set/releaseApk`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//   重发
export function reSend(param) {
    return request({
        url: `v2/batch_set/repeatApkVersion`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}

// ---------------------------------------

// 获取报障列表
export function staVersion(param) {
    return request({
        url: `v2/Hotelvisualization/staVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  导出接口
export function importVersion(param) {
    return request({
        url: `v2/Hotelvisualization/importVersion`,
        method: "post",
        data: qs.stringify(param),
        responseType: 'blob'
    });
}
//  获取模板列表
export function getTemplateList(param) {
    return request({
        url: `v2/Hotelvisualization/getTemplate`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取追踪详情
export function getHotelByVersion(param) {
    return request({
        url: `v2/Hotelvisualization/getHotelByVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取详情
export function getTemplateVersion(param) {
    return request({
        url: `v2/Hotelvisualization/getTemplateVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取房间数据
export function getHotelNoUpdate(param) {
    return request({
        url: `v2/Hotelvisualization/getHotelNoUpdate`,
        method: "post",
        data: qs.stringify(param),
    });
}

//  操作栏刷新
export function refreshNumber(param) {
    return request({
        url: `v2/Hotelvisualization/refreshNumber`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  操作栏重发接口
export function repeatLauncher(param) {
    return request({
        url: `v2/Hotelvisualization/repeatLauncher`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取apk列表
export function getApkList(param) {
    return request({
        url: `v2/Hotelvisualization/getApkList`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取签名列表
export function getSignList(param) {
    return request({
        url: `v2/Hotelvisualization/getSignList`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  apk发布
export function setBatchApk(param) {
    return request({
        url: `v2/Hotelvisualization/setBatchApk`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  批量更新加酒店
export function addHotel(param) {
    return request({
        url: `v2/Hotelvisualization/addHotel`,
        method: "post",
        data: qs.stringify(param),
    });
}