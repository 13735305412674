<template>
  <div class="box">
    <uploadSource ref="uploadSource" sourceType="pic" @upsuccess='upSuccess'></uploadSource>
    <el-form ref="form" :model="form" :rules="rules" class="formBox">
      <div v-if="auth.type!='channel'&&auth.type!='monomer'&&!notPage">
        <h4>模块显示</h4>
        <div style="display:flex">
          <el-form-item prop='hide'>
            <el-radio-group :disabled="isEdit" v-model="form.hide">
              <el-radio label="1">显示</el-radio>
              <el-radio label="2">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div>
        <h4>图标设置</h4>
        <div style="display:flex">
          <el-form-item class="picItem" prop='furl'>
            <div class="showPicBox" @click="changePic('F')">
              <i v-if="!form.furl" class="el-icon-plus" style="font-size:30px"></i>
              <img v-if="form.furl" :src="form.furl" alt="">
              <div class="btn" v-if="form.furl">
                <!-- <i class="el-icon-zoom-in" @click.stop="showPic('H')"></i> -->
                <i class="el-icon-delete" v-if="!isEdit" @click.stop="form.furl=''"></i>
                <!-- <i class="el-icon-refresh" @click.stop="recoverydata('F')"></i> -->
              </div>
            </div>
            <span class="namespan">焦点图标</span>
          </el-form-item>
          <el-form-item class="picItem" prop='durl'>
            <div class="showPicBox" @click="changePic('D')">
              <i v-if="!form.durl" class="el-icon-plus" style="font-size:30px"></i>
              <img v-if="form.durl" :src="form.durl" alt="">
              <div class="btn" v-if="form.durl">
                <!-- <i class="el-icon-zoom-in" @click.stop="showPic('H')"></i> -->
                <i class="el-icon-delete" v-if="!isEdit" @click.stop="form.durl=''"></i>
                <!-- <i class="el-icon-refresh" @click.stop="recoverydata('D')"></i> -->
              </div>
            </div>
            <span class="namespan">默认图标</span>
          </el-form-item>
        </div>

      </div>
      <div>
        <h4>栏目名称</h4>
        <el-form-item label="中文" prop='cname'>
          <el-input :readonly="isEdit" v-model="form.cname"></el-input>
        </el-form-item>
        <el-form-item label="英文" prop='ename'>
          <el-input :readonly="isEdit" v-model="form.ename"></el-input>
        </el-form-item>
      </div>
      <div v-if="(showData.TVSignal && showData.TVSignal[0]&&!notPage)||(showData.TVSignal && showData.TVSignal[0]&&formShow)">
        <h4>电视信号类型</h4>
        <el-form-item label="选择类型" prop='tv'>
          <el-select :disabled="isEdit" v-model="form.tv" placeholder="请选择电视信号类型">
            <el-option label="ATV" value="ATV"></el-option>
            <el-option label="DTMB" value="DTMB"></el-option>
            <el-option label="DTV" value="DTV"></el-option>
            <el-option label="ATV" value="ATV"></el-option>
            <el-option label="HDMI1" value="HDMI1"></el-option>
            <el-option label="HDMI2" value="HDMI2"></el-option>
            <el-option label="HDMI3" value="HDMI3"></el-option>
            <el-option label="IPTV" value="IPTV"></el-option>
            <el-option label="HDMI" value="HDMI"></el-option>
            <el-option label="AV" value="AV"></el-option>
            <el-option label="LOCAL" value="LOCAL"></el-option>
            <el-option label="IPTVXml" value="IPTVXml"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-if="(showData.apk&&!notPage)||(showData.apk&&formShow)">
        <h4> 应用配置</h4>
        <el-form-item label="应用上传" prop='apk' v-if="!isEdit">
          <uploadApk :apkAddr='form.apk' @uploadApkInfo="uploadApkInfo"></uploadApk>
        </el-form-item>
        <el-form-item label="包名" prop='apkpackage'>
          <el-input :readonly="isEdit" v-model="form.apkpackage"></el-input>
        </el-form-item>

      </div>
      <div v-if="(showData.IPTVConfig && showData.IPTVConfig[0]&&!notPage)||(showData.IPTVConfig && showData.IPTVConfig[0]&&formShow)">
        <h4> IPTV服务器配置</h4>
        <el-form-item label="服务器配置" prop="iptv">
          <el-input :readonly="isEdit" v-model="form.iptv" placeholder="请填写IPTV服务器配置信息"></el-input>
        </el-form-item>

      </div>
      <el-form-item v-if='!isEdit'>
        <div style="display:flex;  justify-content: flex-end;width:100%">
          <el-button type="primary" @click="saveData">保存</el-button>
        </div>

        <!-- <el-button>取消</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import uploadSource from "../common/uploadSource.vue";
import uploadApk from "../common/uploadApk.vue";
export default {
  name: "menuItemForm",
  components: {
    uploadSource,
    uploadApk,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
    notPage: {
      type: Boolean,
      default: false,
    },
       isEdit: {
      type: Boolean,
      default: false,
    },
    formShow: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      auth: {},
      form: {},
      showData: this.value,
      rules: {
        furl: [{ required: true, message: "请上传焦点图标", trigger: "blur" }],
        durl: [{ required: true, message: "请上传默认图标", trigger: "blur" }],
        cname: [{ required: true, message: "请输入中文名称", trigger: "blur" }],
        // ename: [{ required: true, message: "请输入英文名称", trigger: "blur" }],
        tv: [
          { required: true, message: "请选择电视信号类型", trigger: "blur" },
        ],
        // apk: [{ required: true, message: "请上传apk", trigger: "blur" }],
        // apkpackage: [
        //   { required: true, message: "请输入包名", trigger: "blur" },
        // ],
        // iptv: [
        //   { required: true, message: "请输入iptv服务器配置", trigger: "blur" },
        // ],
      },
    };
  },
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.form = {
      hide: String(this.showData.menu_hide),
      furl: this.showData.focal_point.source_url,
      durl: this.showData.default_point.source_url,
      cname: this.showData.menu_name.content,
      ename: this.showData.menu_enname.content,
      tv: this.showData.TVSignal ? this.showData.TVSignal[0].x : null,
      apk:
        this.showData.apk && this.showData.apk[0]
          ? this.showData.apk[0].apk_name
          : null,
      apkpackage:
        this.showData.apk && this.showData.apk[0]
          ? this.showData.apk[0].apk_package
          : null,
      iptv: this.showData.IPTVConfig ? this.showData.IPTVConfig[0].x : null,
    };
  },
  methods: {
    uploadApkInfo(data) {
      this.form.apk = data.apkAddr;
      this.form.apkpackage = data.eapk_package;
      this.form.apkchange = true;
      this.form.apkmd5 = data.apkMd5;
      this.form.eapkcode = data.eapk_code;
    },
    changePic(type) {
      if (type == "D" && !this.form.durl) {
        this.$refs.uploadSource.dialogVisible = true;
        this.$refs.uploadSource.HV = "D";
      } else if (type == "F" && !this.form.furl) {
        this.$refs.uploadSource.dialogVisible = true;
        this.$refs.uploadSource.HV = "F";
      }
    },
    upSuccess(data, hv) {
      if (hv == "D") {
        this.form.durl = data.url;
        this.form.durlupdate = true;
        this.form.dmd5 = data.md5;
      } else {
        this.form.furl = data.url;
        this.form.furlupdate = true;
        this.form.fmd5 = data.md5;
      }
    },
    recoverydata(type) {
      if (type == "D") {
        this.form.durl = this.historyData.default_point.source_url;
      } else {
        this.form.furl = this.historyData.focal_point.source_url;
      }
    },
    saveData() {
      this.$refs["form"].validate((valid, obj) => {
        if (valid) {
          if (this.showData.menu_hide != this.form.hide) {
            this.showData.menu_hide = this.form.hide;
            this.showData.update = true;
          }
          this.showData.menu_hide = this.form.hide;
          this.showData.menu_name.content = this.form.cname;
          this.showData.menu_name.update = true;
          this.showData.menu_enname.content = this.form.ename;
          this.showData.menu_enname.update = true;

          if (this.form.durlupdate) {
            this.showData.default_point.source_url = this.form.durl;
            this.showData.default_point.update = true;
            this.showData.default_point.source_md5 = this.form.dmd5;
          }

          if (this.form.furlupdate) {
            this.showData.focal_point.source_url = this.form.furl;
            this.showData.focal_point.update = true;
            this.showData.focal_point.source_md5 = this.form.fmd5;
          }
          if (this.form.tv) {
            this.showData.TVSignal[0].x = this.form.tv;
            this.showData.TVSignal[0].update = true;
          }
          if (this.form.iptv) {
            this.showData.IPTVConfig[0].x = this.form.iptv;
            this.showData.IPTVConfig[0].update = true;
          }
          if (this.form.apkchange) {
            this.showData.apk[0] = {
              apk_code: this.form.eapkcode,
              apk_name: this.form.apk,
              apk_package: this.form.apkpackage,
              apk_platform: "0",
              md5: this.form.apkmd5,
              update: true,
            };
          }
          this.$emit("change", this.showData);
          this.$emit("canrelease");
          this.$emit("savedata", this.showData.menu_id);
          this.$message.success("保存成功");
        } else {
          this.$message.warning("请将表单内容补充完整后进行保存");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-left: 15px;
  margin-top: 40px;
  height: 628px;
  width: 300px;
  border: 1px solid #ddd;
  padding: 15px;
  overflow-y: auto;
}
h4 {
  margin: 10px 0 15px;
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}

.picItem ::v-deep .el-form-item__content {
  margin-right: 50px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.namespan {
  height: 24px;
  line-height: 24px;
}
.showPicBox {
  height: 88px;
  width: 88px;
  position: relative;
  border: 1px dashed #999;
  background: rgba($color: #ddd, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-height: 100%;
    max-width: 100%;
  }
  .btn {
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.3);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    transition: 0.3s;
    i {
      margin: 5px;
      color: #fff;
      font-size: 24px;
      transition: 0.3s;
    }
    i:hover {
      color: #409eff;
    }
  }
}
.showPicBox:hover .btn {
  display: flex;
}
</style>