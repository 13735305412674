import { render, staticRenderFns } from "./params.vue?vue&type=template&id=4acc3ece&scoped=true"
import script from "./params.vue?vue&type=script&lang=js"
export * from "./params.vue?vue&type=script&lang=js"
import style0 from "./params.vue?vue&type=style&index=0&id=4acc3ece&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4acc3ece",
  null
  
)

export default component.exports