<template>
    <div>
        <div class="homeContentBox">
            <div class="model" v-if="logoHidden||videoHidden||Pic1Hidden||Pic2Hidden" @click="closeModel"></div>
            <edit-btn @ishidden="isHidden" :upType="'pic'" :msg="'homeLogo'"  :class="['editBtnStyle','editBtnLogoStyle',logoHidden?'btnLight':'',]" :hidden="logoHidden" @recoverydata="recoveryData"  @mouseenter.native="borderShow('logo')" @mouseleave.native="borderHidden('logo')"></edit-btn>
            <edit-btn @ishidden="isHidden" :upType="'video'" :msg="'homeVideo'" :class="['editBtnStyle','editBtnVideoStyle',videoHidden?'btnLight':'',]"  :hidden="videoHidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('video')" @mouseleave.native="borderHidden('video')"></edit-btn>
            <edit-btn @ishidden="isHidden" :upType="'pic'" :msg="'homePic1'"  :class="['editBtnStyle','editBtnPic1Style',Pic1Hidden?'btnLight':'',]" :hidden="Pic1Hidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('pic1')" @mouseleave.native="borderHidden('pic1')"></edit-btn>
            <edit-btn @ishidden="isHidden" :upType="'pic'" :msg="'homePic2'"  :class="['editBtnStyle','editBtnPic2Style',Pic2Hidden?'btnLight':'',]" :hidden="Pic2Hidden" @recoverydata="recoveryData" @mouseenter.native="borderShow('pic2')" @mouseleave.native="borderHidden('pic2')"></edit-btn>
            <img class="bgPic" :src="btnList.bgUrl" alt="">
            <div class="logoBox">
                <!-- @mouseenter="borderShow('logo')" @mouseleave="borderHidden('logo')" -->
                <div :class="['logoBoxLeft',logoHidden?'contentLight':'',logoBorderShow?'borderActive':'']" >
                    <img :src="pageData.modelLogo" alt="">
                </div>
                <div class="logoBoxRight">
                <div class="info_one">
                    <div class="top">{{curTime.time}}</div>
                    <div class="bottom">{{curTime.date}}</div>
                </div>
                <div class="info_two">
                    <div class="top">广州 晴</div>
                    <div class="bottom">25~30℃</div>
                </div>
                </div>
            </div>
             <div class="contentShowBox">
                 <!-- @mouseenter="borderShow('video')" @mouseleave="borderHidden('video')" -->
                 <div  :class="['videoBox',videoHidden?'contentLight':'',videoBorderShow?'borderActive':'']" >
                     <video :src="pageData.modelOne" controls></video>
                 </div>
                  <div class="picBox">
                       <!-- @mouseenter="borderShow('pic1')" @mouseleave="borderHidden('pic1')" -->
                      <div  :class="['picBoxTop',Pic1Hidden?'contentLight':'',Pic1BorderShow?'borderActive':'']">
                           <img :src="pageData.modelTwo">
                      </div>
                      <!-- @mouseenter="borderShow('pic2')" @mouseleave="borderHidden('pic2')" -->
                     <div  :class="['picBoxBottom',Pic2Hidden?'contentLight':'',Pic2BorderShow?'borderActive':'']" >
                           <img :src="pageData.modelThree">
                      </div>
                 </div>
            </div>
            <div class="btnList">
                <draggable class="btndragBox" v-model="btnList.menu" @update="dragSuccess"   chosenClass="chosen" forceFallback="true" group="people" animation="300">
                    <transition-group class="animatBox">
                    <div  :class="['btnCard',pageData.title == item.mname?'active':'']" v-for="(item,index) in btnList.menu" :key="index">
                     <img  v-if="pageData.title == item.mname" :src="item.focuspicaddr" alt="">
                    <img v-if="pageData.title !== item.mname" :src="item.defaultpicaddr" alt="">
                    <span>{{item.mname}}</span>
                </div>
                    </transition-group>
                </draggable> 
            </div>
        </div>
        <to-upload :hotelContract="hotelContract" @upsuccess="upSuccess"></to-upload>
    </div>
</template>
<script>
import Draggable from 'vuedraggable'
import editBtn from "../common/editBtn";
import toUpload from "../common/toUpload"
import commonMeth from "@/api/method";
export default {
    props:["attrName","data","optBtnList","hotelContract","modelShow"],
    components:{
        editBtn,
        Draggable,
        toUpload
    },
    data(){
        return{
            logoHidden:false,
            logoBorderShow:false,
            videoHidden:false,
             videoBorderShow:false,
            Pic1Hidden:false,
             Pic1BorderShow:false,
            Pic2Hidden:false,
             Pic2BorderShow:false,
            pageData:{},
            btnList:{},
            saveData:{},
            historyData:{},
             curTime: {
                date: "",
                week: "",
                time: "",
            },
        }
    },
    methods: {
        // 上传成功
      upSuccess(data,msg){
        if(msg=='homeLogo'){
             this.saveData[this.attrName].modelLogo =data.url;
             this.pageData.modelLogo =data.url;
             this.sendDataForVuex();
        }else if(msg=='homeVideo'){
             this.saveData[this.attrName].modelOne =data.url;
             this.pageData.modelOne =data.url;
             this.sendDataForVuex();
        }else if(msg=='homePic1'){
             this.saveData[this.attrName].modelTwo =data.url;
             this.pageData.modelTwo =data.url;
             this.sendDataForVuex();   
        }else if(msg=='homePic2'){
             this.saveData[this.attrName].modelThree =data.url;
             this.pageData.modelThree =data.url;
             this.sendDataForVuex();
        }
      },
      // 恢复历史文件
      recoveryData(val){
          if(val=='homeLogo'){
             this.saveData[this.attrName].modelLogo =this.historyData.modelLogo;
             this.pageData.modelLogo =this.historyData.modelLogo
             this.sendDataForVuex();
        }else if(val=='homeVideo'){
             this.saveData[this.attrName].modelOne =this.historyData.modelOne;
             this.pageData.modelOne =this.historyData.modelOne;
             this.sendDataForVuex();
        }else if(val=='homePic1'){
             this.saveData[this.attrName].modelTwo =this.historyData.modelTwo;
             this.pageData.modelTwo =this.historyData.modelTwo;
             this.sendDataForVuex();
        }else if(val=='homePic2'){
            console.log(this.historyData.modelThree);
             this.saveData[this.attrName].modelThree =this.historyData.modelThree;
             this.pageData.modelThree =this.historyData.modelThree;
             this.sendDataForVuex();
        }
      },
         // 保存初始历史数据
      saveHistory(){
          this.historyData.modelLogo=this.data.modelLogo;
          this.historyData.modelOne=this.data.modelOne;
          this.historyData.modelThree=this.data.modelThree;
          this.historyData.modelTwo=this.data.modelTwo;
      },
         // 保存数据至vuex
      sendDataForVuex(){
        this.$store.commit("saveVisualContent",this.saveData)
      },
        dragSuccess(e){
            console.log(e);
            console.log(this.btnList);
            // this.sendDataForVuex();
        },
           // 按钮
    isHidden(val) {
      if (val == "homeLogo") {
        this.logoHidden = !this.logoHidden;
      }else if(val == 'homeVideo'){
           this.videoHidden = !this.videoHidden;
      }else if(val == 'homePic1'){
           this.Pic1Hidden = !this.Pic1Hidden;
      }else if(val == 'homePic2'){
           this.Pic2Hidden = !this.Pic2Hidden;
      }
    },
    // 边框显示
    borderShow(val){
        if(val =='logo'){
            this.logoBorderShow=true;
        }else if(val == 'video'){
           this.videoBorderShow =true;
      }else if(val == 'pic1'){
           this.Pic1BorderShow =true;
      }else if(val == 'pic2'){
           this.Pic2BorderShow =true;
      }
    },
    // 边框隐藏
    borderHidden(val){
        if(val =='logo'){
            this.logoBorderShow=false;
        }else if(val == 'video'){
           this.videoBorderShow =false;
      }else if(val == 'pic1'){
           this.Pic1BorderShow =false;
      }else if(val == 'pic2'){
           this.Pic2BorderShow=false;
      }
    },
    // 关闭遮罩
    closeModel(){
            this.logoHidden=false;
            this.videoHidden=false;
            this.Pic1Hidden=false;
            this.Pic2Hidden=false;    
    },
    },
    created(){},
    mounted(){
         this.saveData[this.attrName]=this.data;
        this.saveHistory();
        this.sendDataForVuex();
          setInterval(() => {
      this.curTime = commonMeth.getCurrentDate();
    }, 1000);
    },
    watch:{
        modelShow:{
        handler: function (newVal) {
            this.closeModel();
        },
      deep: true,
      immediate: true,
    },
         data: {
      handler: function (newVal) {
        this.pageData = newVal;
      },
      deep: true,
      immediate: true,
    },
     optBtnList: {
      handler: function (newVal) {
        this.btnList = newVal;
      },
      deep: true,
      immediate: true,
    },
    },
}
</script>
<style  scoped>
.editBtnStyle {
  position: absolute;
  width: 16%;
  height: 5%;
  z-index: 999;
}
.editBtnLogoStyle{
    top: 6%;
    left: 0%;
}
.editBtnVideoStyle{
    top: 17%;
    left: 0%;
}
.editBtnPic1Style{
    top: 12%;
    left: 64.6%;
}
.editBtnPic2Style{
    top: 38%;
    right: 19%;
}
.homeContentBox{
    width: 100%;
    height: 100%;
    position: relative;
}
.bgPic{
    width: 100%;
    height: 100%;
}
.logoBox{
    position: absolute;
    top: 0;
    left: 0;
    height: 17.5%;
    width: 88%;
    margin: 0 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
.borderActive{
     border: 2px dashed #4093ff;
     transition: .2s;
}
.contentLight{
    position: relative;
    z-index: 2000;
}
.btnLight{
     z-index: 2001;
}
.logoBoxLeft{
    height: 52%;
    /* box-sizing: border-box; */
}
.logoBoxLeft img{
    height: 100%;
}
.logoBoxRight {
  height: 50%;
  display: flex;
  font-size: 1.8vw;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}
.info_two,
.info_one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_one{
    padding-right: 15px;
}
.info_two {
     padding-left: 15px;
  border-left: 1px solid #fff;
}
.info_two .top {
  margin-top: 2%;
}
.logoBox .bottom,
.info_two {
  font-size: 1vw;
}
.contentShowBox{
    position: absolute;
    top: 17.5%;
    left: 0;
    height: 61.5%;
   width: 88%;
    margin: 0 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.videoBox{
    width: 65.5%;
    height: 100%;
}
.picBox{
      width: 33.3%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.picBoxTop{
    height: 30%;
    width: 100%;
}

.picBoxBottom{
    height: 67%;
    width: 100%;
}

.picBox img{
    height: 100%;
    width: 100%;
}
.videoBox video{
     width: 100%;
    height: 100%;
}
.btnList{
     position: absolute;
    top: 82%;
    left: 0;
    height: 11%;
    width: 88%;
    margin: 0 6%;
  
    display: flex;
    justify-content: center;
    align-items: center; 
}
.btndragBox{
    width: 100%;
    height: 100%;
}
.animatBox{
     display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 100%;
}
.btnCard{
     display: flex;
     width: 10.5%;
     height: 100%;
     margin-right: 1.6%;
     flex-direction: column;
    justify-content: center;
    align-items: center; 
    font-size: 1vw;
    color: #a4a4a4;
}
.btnCard.active{
    background: rgba(7, 0, 2, .3);
 border: 2px solid #fff;
 box-shadow: 0px 0px 10px 1px rgba(0, 162, 255, 75%);
  color: #fff;
}
.btnCard:last-child{
     margin-right: 0%;
}
.btnCard img{
    height: 42%;
}
.btnCard span{
    margin-top: 8%;
}
.model{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 1000;
}
</style>