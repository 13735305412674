<template>
  <div style="poition:relative">
    <el-upload :class="[uploadPicTrue?'hide':'show']" :disabled="readonly" :action="global.materialUpload" :headers="headers" list-type="picture-card" :on-remove="handleRemove" :on-success="handleSuccess" :on-change="handleChange" :on-preview="handlePictureCardPreview" :before-remove='beforeRemove' :before-upload="beforeAvatarUpload" :limit="1" :file-list="fileList" ref="uploadPic" accept=".mp4,.m4v,.mov,.flv,.avi">
      <i class="el-icon-plus"></i>
      <span>上传视频，100M以内</span>
      <span style="padding:0 10px">支持mp4,m4v,mov,flv,avi格式</span>
    </el-upload>
    <div v-if="fileSrc" class="videoBox">
      <div v-if="fileSrc" class="black">
        <i class="el-icon-delete" @click="handleRemove"></i>
      </div>
      <video width="100%" :src="fileSrc" alt="" controls />
    </div>
  </div>
</template>
<script>
export default {
  name: "uploadVideo",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: () => "",
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fileList: [],
      uploadPicTrue: false,
      dialogVisible: false,
      dialogImageUrl: "",
      headers: {
        token: "e3498af9-6a77-43ce-95aa-21f785647571",
      },
      fileSrc: this.value,
    };
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.fileSrc = newVal;
        if (this.fileSrc) {
          let obj = {
            url: this.fileSrc.split("#")[0],
            md5: this.fileSrc.split("#")[1],
          };
          this.fileList = [];
          this.fileList.push(obj);
          this.uploadPicTrue = true;
        } else {
          this.fileList = [];
          this.uploadPicTrue = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    // 删除上传文件确认
    beforeRemove(file, fileList) {
      this.uploadPicTrue = false;
    },
    // 上传文件时钩子函数
    beforeAvatarUpload(file) {
      var flag =
        file.type === "video/mp4" ||
        file.type === "video/m4v" ||
        file.type === "video/mov" ||
        file.type === "video/flv" ||
        file.type === "video/avi";
      var is100M = file.size / 1024 / 1024 < 100;
      if (!flag) {
        this.$message.warning("视频格式不正确");
      }
      if (!is100M) {
        this.$message.warning("视频大小不能超过100M");
      }
      return flag && is100M;
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.uploadPicTrue = false;
      this.fileList = [];
      this.fileSrc = "";
      this.$emit("change", this.fileSrc);
    },
    // 文件预览
    handlePictureCardPreview(file, fileList) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    // 文件列表变化
    handleChange() {
      if (this.fileList.length >= 1) {
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      this.uploadPicTrue = true;
      this.fileList = fileList;
      this.fileSrc = file.response.data.url+"#"+file.response.data.md5;
      this.$emit("change", this.fileSrc);
    },
  },
};
</script>
<style lang="scss" scoped>
.videoBox {
  width: 384px;
  height: 216px;
  position: relative;
}
.videoBox:hover  .black {
  background: rgba($color: #000000, $alpha: 0.3);
}
.videoBox:hover  .black .el-icon-delete{
  display: block;
}
.black {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
  background: rgba($color: #000000, $alpha: 0);
  .el-icon-delete {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    color: #fff;
    cursor: pointer;
  }
}
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
.hide ::v-deep .el-upload-list__item.is-uploading {
  display: none;
}
.hide ::v-deep .el-upload-list {
  display: none;
}

.hide ::v-deep .el-upload-list--picture-card {
  line-height: 0;
}

.hide ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin: 0;
}
.show ::v-deep .el-upload-list--picture-card {
  display: none;
}
::v-deep .el-upload--picture-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
</style>