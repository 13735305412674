<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">渠道商政策</span>
                <span slot="third">渠道商政策列表</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card">
            <el-row class="btnRow" v-if="showFunModel('标记为已读')">
                <div class="grid-content bg-purple colDiv paddingLeft allCheck">
                    <el-checkbox v-model="checkAll" :isIndeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
                </div>
                <el-button type="primary" @click="markConfirm" >标记为已读</el-button>
            </el-row>
            <div v-loading="table.loading">
                <el-row class="noData" v-if="noData">
                    暂无数据
                </el-row>
                <el-checkbox-group  v-model="ids" >
                    <div class="newsBox" :key="index" v-for="(item,index) in table.tableList">
                        <div class="flexBox">
                            <el-checkbox :key="item.id" :label="item.id" class="labelNone checkItem"></el-checkbox>
                        </div>
                        <div class="newsConBox">
                            <div class="newsTitle flexBox" @click="getNewsDetail(item.info_id,item.id)">
                                <div class="point red" :class="item.status===0?'':'noneEl'"></div>
                                <div>{{item.title}}</div>
                                <div class="time">{{item.create_time}}</div>
                            </div>
                            <div class="newsCon">
                                <div class="point red noneEl"></div>
                                <div class="textBox"  v-html="escapeStringHTMLCom(item.text)"></div>
                            </div>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="block pageBox">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="table.page"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="table.limit"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="table.total">
                </el-pagination>
            </div>
            <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading" v-if="testShow"></table-list>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
        <el-drawer
        title="政策详情"
        :visible.sync="contentShow"
        
        size="95%" :modal="false" :before-close="handleClose" :wrapperClosable="false" :destroy-on-close="true"
        >
               <policy-detail ref="policyDetail" :query='query'></policy-detail>
        </el-drawer>
 
    </div>
</template>
<script>
    import  qs from 'querystring'
    import commonMeth from '../../api/method'
    import policyDetail  from "./PolicyDetail"
    export default {
        name: "hotelManAll",
        components:{
            policyDetail
        },
        data: () => ({
            contentShow:false,
            testShow:false,
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: ''},
                fourthPath:{path: '/infoPubCoop'},
            },
            topAttr:{
                terminal:'',
                terminalOption:[
                    {label:'country1',value:'1'},
                    {label:'country2',value:'2'},
                    {label:'country3',value:'3'},
                ],
            },
            newsList:[
                {id:1},
                {id:1},
                {id:1},
                {id:1},
                {id:1},
            ],
            table:{
                tableHeader:[
                    {prop:'title',label:'标题',width:''},
                    {prop:'publish_time',label:'发布时间',width:''},
                    {prop:'publish_man',label:'发布人',width:''},
                    {prop:'status_text',label:'状态',width:''},
                ],
                tableList:[

                ],
                page:5,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'policyList',
                option:true,
                select:true,
                loading:true
            },
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px',
            dialogExport:{
                dialog: false,

            },
            active: 0,
            topSearchShow:{ },
            condition:{},
            operateIds:'',
            isHtrip:'',
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            query:{},
            ids:[],
            noData:'',
            funArr:[],
            allData:{},
            checkAll:false,
            isIndeterminate:true,
            idOption:[],
            topSearchSave:{},
            allLoadingState:false,
        }),

        methods:{
            newBuild(){
                this.$router.push({path:'/infoPubCoopNew',query:{fromUrl:'new',showType:'coopPolicy',fromType:'new'}});
            },
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // this.delMeth();
                        if(type==='del'){
                            this.delMeth('del','删除');
                        }else if(type==='publish'){
                            this.delMeth('publish','发布');
                        }
                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '请选择政策',
                        type: 'warning'
                    });
                }
            },
            delMeth(type,str){
                var param='';
                var url='';
                var obj={};
                if(type==='del'){
                    url=this.global.infoDestory;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };

                }else if(type==='publish'){
                    url=this.global.infoPublish;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };
                }
                param=qs.stringify(obj);
                this.$http.post(url,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success(str+'成功');
                        this.tableMeth();
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(()=>{
                    this.$message.error('返回错误');
                })
            },
            next() {
                if (this.active++ > 2) this.active = 0;
            },
            tableMeth(type){
                this.table.loading=true;
                this.checkAll=[];
                this.ids=[];
                this.$http.get(this.global.informationMessage,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        limit:this.table.limit,
                        per_page:this.table.limit,
                        type:'info',
                        status:this.condition.noticeStatus,
                        keyword:this.condition.keyword,
                    }
                }).then(res=>{
                    if(type==='mounted'){
                        this.table.page=sessionStorage.getItem('filterPage')*1;
                    }
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                        tableData.items.length===0?this.noData=true:this.noData=false;
                        var idOption=[];
                        tableData.items.forEach(function(item,index){
                            idOption.push(item.id);
                        });
                        this.idOption=idOption;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getCondition(val,filterPage,filterLimit,type) {
                this.condition.noticeStatus=Array.isArray(val.noticeStatus)?val.noticeStatus.join(','):''; //ka
                this.condition.keyword=val.keyword; //ka
                this.getFilterTableCon(filterPage,filterLimit,type);
                this.$forceUpdate();
                this.tableMeth(type);
            },
           handleClose(done) {
            done();
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='group'  || auth_type==='factory'){
                    this.topSearchShow={
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        keyword:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder='请输入标题关键词';
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getTableSelect(val){
                var operateIds='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+','
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            getFoldMeth(val){
                this.fold=val;
            },
            handleSizeChange(val) {
                sessionStorage.setItem('filterLimit',val);
                this.table.limit=val;
                this.tableMeth();
            },
            handleCurrentChange(val) {
                sessionStorage.setItem('filterPage',val);
                this.table.page=val;
                this.tableMeth();
            },
            getNewsDetail(info_id,id){
                
                this.markMeth('showCon',id);
                this.query={
                    id:info_id,
                    fromType:'detail',
                    showType:'coopPolicy',
                    type:'edit'
                }
                 this.contentShow=true;
                this.$refs.policyDetail.getInfoDetail(this.query.id);
               
                // this.$router.push({path:'policyDetail',query:{id:info_id,fromType:'detail',showType:'coopPolicy',type:'edit'}})
            },
            markConfirm(){
                if(this.ids.length>0){
                    this.$confirm('确定标为已读?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.markMeth('btnMark');
                    }).catch(() => {

                    });
                }else{
                    this.$message.warning('请选择消息');
                }
            },
            markMeth(type,paramId){
                var ids='';
                type==='btnMark'? ids=this.ids.join(','):ids=paramId;
                var param=qs.stringify({
                    token:this.token,
                    ids:ids
                });
                this.$http.post(this.global.alreadyRead,param).then(res=>{
                    if(res.data.code===100000){
                        type==='btnMark'?this.$message.success('标记成功'):'';
                        this.ids=[];
                        this.tableMeth();
                    }else{
                        this.$message.error('标记失败');
                    }
                }).catch(()=>{
                    this.$message.error('返回错误');
                })
            },
            handleCheckAllChange(val) {
                this.ids = val ? this.idOption : [];
                this.isIndeterminate = false;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
                this.$forceUpdate();
            },
            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                this.getCondition(filterCon,filterPage*1,filterLimit*1,'mounted');
            },

        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            var auth=JSON.parse(sessionStorage.getItem('auth'));
            auth.type==='htrip'?this.isHtrip=true:this.isHtrip=false;
            this.getTopSearchShow();
            this.getFilterCon();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
            });
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill
            },
            escapeStringHTMLCom(){
                return function(str){
                    str = str.replace(/&lt;/g, '<');
                    str = str.replace(/&gt;/g, '>');
                    var first='';
                    var index=str.indexOf('</p');
                    var tranStr=str.substring(0,index);
                    tranStr=tranStr+'</p>';
                    var imgIndex=str.indexOf('img');
                    var imgStr=tranStr.substring(0,imgIndex+1);
                    if(imgIndex!==-1){
                        return imgStr;
                    }else{
                        return tranStr;
                    }
                }
            },
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading);
            },
        },

    }
</script>

<style scoped lang="scss">
    .btnRow{
        display:flex;
        align-items: center;
        padding-left:15px;
    }
    .allCheck{
        margin-right:15px;
    }
    .hotelBrand{
        width:100%;
    }
    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .rightBtn{
        margin-left:auto;
    }
    .checkItem{
        margin-right:20px;
    }
    .newsBox{
        display:flex;
        padding:15px;
        box-sizing: border-box;
        box-shadow:0px 0px 13px 0px rgba(128,128,128,0.2);
        border-radius:6px;
        margin-bottom:20px;
    }
    .newsConBox{
        flex:1;
        .textBox{
            flex:0.6;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
    .flexBox{
        display:flex;
        align-items: center;
    }
    .newsTitle{

        font-size:16px;
        font-weight:600;
        margin-bottom:10px;
        cursor:pointer;
    }
    .newsTitle .time{
        margin-left:auto;
        font-size:14px;
    }
    .newsTime{
        font-size:12px;
        color:#969696;
        margin-bottom:10px;
    }
    .newsCon{
        font-size:14px;
        /*color:#969696;*/
        /*margin-bottom:10px;*/
        color:#4a4a4a;
        line-height:24px;
        display:flex;

    }
    .point{
        width:8px;
        height:8px;
        border-radius:50%;
        /*background:green;*/
        margin-right:20px;
        &.red{
            background:#f35c5d!important;
        }
    }
    .noData{
        width:100%;
        display:flex;
        align-items:center;
        justify-content: center;
        /*padding-bottom:20px;*/
        padding:20px 0;
        border-top:1px solid #EBEEF5;
        border-bottom:1px solid #EBEEF5;
        font-size:14px;
        color:#909399;
    }
    .noneEl{
        visibility: hidden;
    }
    ::v-deep .el-drawer__body {
      overflow: auto;
        background-color: #f9f9f9;
        }
</style>
