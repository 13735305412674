<template>
  <div>
    <p class="btnROW">
      <span class="Redtext">*需确保已上传所有内容，否则无法发起验收</span>
      <el-button class="btnSumnit" type="primary" @click="submitContent"
        >提交</el-button
      >
    </p>
    <!-- 验收测试 -->
    <div class="uploadBox">
      <p class="title">首台验收视频</p>
      <!-- 视频上传 -->
      <el-upload
        :disabled="videoUploadTrue"
        :class="{ videoUpload: true, hide: uploadsuccesshide }"
        :action="global.upload"
        :file-list="VideoList"
        :on-preview="handleVideoCardPreview"
        :on-change="uploadVideoChange"
        :on-remove="videoRemove"
        :on-success="uploadVideosuccess"
        :before-upload="beforeVideoUpload"
        :limit="1"
        ref="video"
      >
        <video
          v-if="VideoList[0]"
          :src="videoSrc"
          class="avatar"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i v-if="!VideoList[0]" class=" iconColor el-icon-plus"></i>
        <span class="clickUplaod" v-if="!VideoList[0]">点击上传</span>
        <span v-if="!VideoList[0]">首台上线验收视频</span>
        <span v-if="!VideoList[0]">MP4格式，30M以内</span>
      </el-upload>
      <!-- 图片上传 -->
      <p class="title1 title">门店照片</p>

      <!-- 图片一上传 -->
      <el-upload
        :class="{ picUpload: true, hide: uploadPic }"
        :action="global.upload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-change="uploadPicChange"
        :on-remove="uploadPicremove"
        :on-success="uploadpicsuccess"
        :before-upload="beforepicUpload"
        :limit="1"
        :file-list="fileList"
        ref="pic"
      >
        <i class="el-icon-plus iconColor"></i>
        <span class="clickUplaod">点击上传</span>
        <span>酒店门头图片</span>
        <span>jpg格式，500k以内</span>
      </el-upload>

      <!-- 图片222上传 -->
      <el-upload
        :class="{ picUpload: true, hide: uploadPic1 }"
        :action="global.upload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview1"
        :on-change="uploadPicChange1"
        :on-remove="uploadPicremove1"
        :on-success="uploadpicsuccess1"
        :before-upload="beforepicUpload1"
        :limit="1"
        ref="pic"
        :file-list="fileList1"
      >
          <i class="el-icon-plus iconColor"></i>
        <span class="clickUplaod">点击上传</span>
        <span>酒店前台照片</span>
        <span>jpg格式，500k以内</span>
      </el-upload>
      <!-- 图片3333上传 -->
      <el-upload
        :class="{ picUpload: true, hide: uploadPic2 }"
        :action="global.upload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview2"
        :on-change="uploadPicChange2"
        :on-remove="uploadPicremove2"
        :on-success="uploadpicsuccess2"
        :before-upload="beforepicUpload2"
        :limit="1"
        ref="pic"
        :file-list="fileList2"
      >
        <i class="el-icon-plus iconColor"></i>
        <span class="clickUplaod">点击上传</span>
        <span>客房照片</span>
        <span>jpg格式，500k以内</span>
      </el-upload>
      <!-- 图片44444上传 -->
      <el-upload
        :class="{ picUpload: true, hide: uploadPic3 }"
        :action="global.upload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview3"
        :on-change="uploadPicChange3"
        :on-remove="uploadPicremove3"
        :on-success="uploadpicsuccess3"
        :before-upload="beforepicUpload3"
        :limit="1"
        ref="pic"
        :file-list="fileList3"
      >
          <i class="el-icon-plus iconColor"></i>
        <span class="clickUplaod">点击上传</span>
        <span>房间电视界面欢迎页带酒店名称+床取景照</span>
        <span>jpg格式，500k以内</span>
      </el-upload>
      <!-- 图片5555上传 -->
      <el-upload
        :class="{ picUpload: true, hide: uploadPic4 }"
        :action="global.upload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview4"
        :on-change="uploadPicChange4"
        :on-remove="uploadPicremove4"
        :on-success="uploadpicsuccess4"
        :before-upload="beforepicUpload4"
        :limit="1"
        ref="pic"
        :file-list="fileList4"
      >
       <i class="el-icon-plus iconColor"></i>
        <span class="clickUplaod">点击上传</span>
        <span>有酒店全称的房卡类物品照</span>
        <span>jpg格式，500k以内</span>
      </el-upload>
     <p class="title">验收单</p>
      <el-upload
        :class="{ picUpload: true, hide: uploadPic5 }"
        :action="global.upload"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview5"
        :on-change="uploadPicChange5"
        :on-remove="uploadPicremove5"
        :on-success="uploadpicsuccess5"
        :before-upload="beforepicUpload5"
        :limit="1"
        ref="pic"
        :file-list="fileList5"
      >
        <i class="el-icon-plus iconColor"></i>
        <span class="clickUplaod">点击上传</span>
        <span>酒店已盖章验收单</span>
        <span>jpg格式，500k以内</span>
      </el-upload>
    </div>
    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>

import { uploadEnclosureUrlsWaitForCheck,getEnclosureUrls } from "@/api/hotel.js";
import qs from "querystring";
export default {
  props: ["hotelContract", "upload_drawer"],
  data() {
    return {
      videoSrc: "",
      dialogVisible: false,
      dialogImageUrl: "",
      VideoList: [],
      videoUploadTrue: false,
      uploadsuccesshide: false,
      uploadPic: false,
      fileList: [],

      uploadPic1: false,
      fileList1: [],

      uploadPic2: false,
      fileList2: [],

      uploadPic3: false,
      fileList3: [],

      uploadPic4: false,
      fileList4: [],
      uploadPic5: false,
      fileList5: [],
    };
  },
  beforeDestroy() {
    this.VideoList = [];
    this.videoUploadTrue = false;
    this.uploadsuccesshide = false;
    this.fileList = [];
    this.uploadPic = false;
    this.fileList1 = [];
    this.uploadPic1 = false;
    this.fileList2 = [];
    this.uploadPic2 = false;
    this.fileList3 = [];
    this.uploadPic3 = false;
    this.fileList4 = [];
    this.uploadPic4 = false;
    this.fileList5 = [];
    this.uploadPic5 = false;
  },
    mounted() {
        this.getEditData();
    },
  methods: {
      getEditData(){
           var json = {
      hotel_contract: this.hotelContract,
    };
    var param = qs.stringify(json);
    getEnclosureUrls(param)
      .then((res) => {
        if (res.data.code = 100000&&res.data.data.video_str) {
          var showFileData = res.data.data;
          var videourl = {
            url:
              this.global.baseUrl +
              showFileData.video_str,
          };
          
          this.VideoList.push(videourl);
           this.videoSrc =videourl.url;
          var pic5 = {
            url:
              this.global.baseUrl +
              showFileData.acceptance_form_str,
          };
          this.fileList5.push(pic5);
          var pic = {
            url:
              this.global.baseUrl +
              showFileData.store_photo.pic,
              upurl:  showFileData.store_photo.pic,
          };
          this.fileList.push(pic);
          var pic1 = {
            url:
             this.global.baseUrl +
              showFileData.store_photo.pic1,
               upurl:  showFileData.store_photo.pic1,
          };
          this.fileList1.push(pic1);
          var pic2 = {
            url:
             this.global.baseUrl +
              showFileData.store_photo.pic2,
               upurl:  showFileData.store_photo.pic2,
          };
          this.fileList2.push(pic2);
          var pic3 = {
            url:
             this.global.baseUrl +
              showFileData.store_photo.pic3,
               upurl:  showFileData.store_photo.pic3,
          };
          this.fileList3.push(pic3);
          var pic4 = {
            url:
              this.global.baseUrl +
              showFileData.store_photo.pic4,
               upurl:  showFileData.store_photo.pic4,
          };
          this.fileList4.push(pic4);

            this.videoUploadTrue = true;
              this.uploadsuccesshide = true;
            
              this.uploadPic = true;
              
              this.uploadPic1 = true;
           
              this.uploadPic2 = true;
          
              this.uploadPic3 = true;
      
              this.uploadPic4 = true;
             
              this.uploadPic5 = true;
        } else {
          this.uploadsuccesshide = false;
          this.uploadPic = false;
          this.uploadPic1 = false;
          this.uploadPic5 = false;
          this.uploadPic4 = false;
          this.uploadPic3 = false;
          this.uploadPic2 = false;

          this.VideoList = [];
          this.fileList = [];
          this.fileList1 = [];
          this.fileList2 = [];
          this.fileList3 = [];
          this.fileList4 = [];
          this.fileList5 = [];
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
      },
    submitContent() {
      var flag =
        this.VideoList.length > 0 &&
        this.fileList.length > 0 &&
        this.fileList1.length > 0 &&
        this.fileList2.length > 0 &&
        this.fileList3.length > 0 &&
        this.fileList4.length > 0 &&
        this.fileList5.length > 0;
      if (flag) {

        var videoUrl = this.VideoList[0].response?this.VideoList[0].response.data.url:'';
        var hotelPicUrl = {
          pic:this.fileList[0].response?this.fileList[0].response.data.url:this.fileList[0].upurl,
          pic1: this.fileList1[0].response?this.fileList1[0].response.data.url:this.fileList1[0].upurl,
          pic2: this.fileList2[0].response?this.fileList2[0].response.data.url:this.fileList2[0].upurl,
          pic3: this.fileList3[0].response?this.fileList3[0].response.data.url:this.fileList3[0].upurl,
          pic4: this.fileList4[0].response?this.fileList4[0].response.data.url:this.fileList4[0].upurl,
        };
        var payTruePicUrl = this.fileList5[0].response?this.fileList5[0].response.data.url:'';
        const json = {
          hotel_contract: this.hotelContract,
          video_str: videoUrl,
          store_photo_json:JSON.stringify(hotelPicUrl),
          // store_photo_json: hotelPicUrl,
          acceptance_form_str: payTruePicUrl,
        };
        var param = qs.stringify(json);
        uploadEnclosureUrlsWaitForCheck(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.$message.success("附件上传成功");
             
              this.$emit("uploadSuccess");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error("附件内容不完整，无法进行提交");
      }
    },

    uploadVideoChange(file, fileList) {
      if (this.VideoList.length >= 1) {
        this.videoUploadTrue = true;
        this.uploadsuccesshide = true;
      }
    },
    handleVideoCardPreview() {
      this.VideoList = [];
      this.videoUploadTrue = false;
      this.uploadsuccesshide = false;
    },
    beforeVideoUpload(file) {
      const isJPG = file.type === "video/mp4";
      const isLt2M = file.size / 1024 / 1024 < 30;

      if (!isJPG) {
        this.$message.error("上传图片只能是 mp4 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 30M!");
      }
      return isJPG && isLt2M;
    },
    videoRemove(file) {
      this.VideoList = [];
      this.uploadsuccesshide = false;
    },
    uploadVideosuccess(response, file, fileList) {
      this.videoSrc =
        this.global.baseUrl+
        file.response.data.url;
      this.VideoList.push(file);
    },
    /// 视屏上传------------------------------------------

    /// 图片1
    beforepicUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt500K = file.size / 1024 / 1024 < 0.5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 500k!");
      }
      return isJPG && isLt500K;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange(file, fileList) {
      if (this.fileList.length >= 1) {
        this.uploadPic = true;
      }
    },
    uploadPicremove(file) {
      this.fileList = [];
      this.uploadPic = false;
    },
    uploadpicsuccess(response, file, fileList) {
      this.fileList = fileList;
    },

    /// 图片2
    beforepicUpload1(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt500K = file.size / 1024 / 1024 < 0.5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 500k!");
      }
      return isJPG && isLt500K;
    },
    handlePictureCardPreview1(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange1(file, fileList) {
      if (this.fileList1.length >= 1) {
        this.uploadPic1 = true;
      }
    },
    uploadPicremove1(file) {
      this.fileList1 = [];
      this.uploadPic1 = false;
    },
    uploadpicsuccess1(response, file, fileList) {
      this.fileList1 = fileList;
    },

    /// 图片3
    beforepicUpload2(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt500K = file.size / 1024 / 1024 < 0.5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 500k!");
      }
      return isJPG && isLt500K;
    },
    handlePictureCardPreview2(file) {
      
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange2(file, fileList) {
      if (this.fileList2.length >= 1) {
        this.uploadPic2 = true;
      }
    },
    uploadPicremove2(file) {
      this.fileList2 = [];
      this.uploadPic2 = false;
    },
    uploadpicsuccess2(response, file, fileList) {
      this.fileList2 = fileList;
    },

    /// 图片4
    beforepicUpload3(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt500K = file.size / 1024 / 1024 < 0.5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 500k!");
      }
      return isJPG && isLt500K;
    },
    handlePictureCardPreview3(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange3(file, fileList) {
      if (this.fileList3.length >= 1) {
        this.uploadPic3 = true;
      }
    },
    uploadPicremove3(file) {
      this.fileList3 = [];
      this.uploadPic3 = false;
    },
    uploadpicsuccess3(response, file, fileList) {
      this.fileList3 = fileList;
    },
    /// 图片5
    beforepicUpload4(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt500K = file.size / 1024 / 1024 < 0.5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 500k!");
      }
      return isJPG && isLt500K;
    },
    handlePictureCardPreview4(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange4(file, fileList) {
      if (this.fileList4.length >= 1) {
        this.uploadPic4 = true;
      }
    },
    uploadPicremove4(file) {
      this.fileList4 = [];
      this.uploadPic4 = false;
    },
    uploadpicsuccess4(response, file, fileList) {
      this.fileList4 = fileList;
    },

    /// 图片666
    beforepicUpload5(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt500K = file.size / 1024 / 1024 < 0.5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt500K) {
        this.$message.error("上传图片大小不能超过 500k!");
      }
      return isJPG && isLt500K;
    },
    handlePictureCardPreview5(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange5(file, fileList) {
      if (this.fileList5.length >= 1) {
        this.uploadPic5 = false;
      }
    },
    uploadPicremove5(file) {
      this.fileList5 = [];
      this.uploadPic5 = false;
    },
    uploadpicsuccess5(response, file, fileList) {
      this.fileList5 = fileList;
    },
  },
};
</script>
<style scoped>
/* 验收测试 */
>>> .el-upload-list__item {
  transition: none !important;
}
.uploadBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.videoUpload {
  width: 300px;
  height: 200px;
}
.videoUpload /deep/ .el-upload--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px dashed #ccc;
}
.videoUpload /deep/ .el-upload--text .clickUplaod{
  font-size: 14px;
  margin: 10px 0;
   color: #999;
}
.videoUpload /deep/ .el-upload--text span {
  height: 20px;
  line-height: 20px;
  color: #bbb;
  font-size: 12px;
}
.videoUpload /deep/ .el-upload--text:hover span {
  color: skyblue;
}
.videoUpload /deep/ .el-upload--picture-card {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoUpload /deep/ .el-upload--picture-card span {
  height: 20px;
  line-height: 20px;
   font-size: 12px;
}
.videoUpload /deep/ .el-upload--picture-card .clickUplaod {
  font-size: 14px;
  margin: 10px 0;
}
/* .videoUpload /deep/ .el-upload-list__item.is-success {
  width: 300px;
  height: 30px;
} */

.videoUpload /deep/ .el-upload-list__item.is-uploading {
  width: 300px;
  height: 30px;
}
.hide /deep/ .el-upload--picture-card {
  display: none;
}
.videoUpload /deep/ .el-upload-list__item-actions {
  width: 300px;
  height: 200px;
}
.picUpload {
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
}

.picUpload /deep/ .el-upload--picture-card {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.picUpload /deep/ .el-upload--picture-card span {
  height: 20px;
  line-height: 20px;
  color: #bbb;
   font-size: 12px;
}
.picUpload /deep/ .el-upload--picture-card .clickUplaod {
  font-size: 14px;
   color: #999;
  margin: 10px 0;
}

.videoUpload /deep/ .el-upload--text:hover .iconColor {
  color: skyblue;
}
.videoUpload /deep/ .el-upload--text:hover  {
  border: 1px dashed skyblue;
}
.picUpload /deep/ .el-upload--picture-card:hover span {
  color: skyblue;
}
.picUpload /deep/ .el-upload--picture-card:hover i {
  color: skyblue;
}
.picUpload /deep/ .el-upload-list__item {
  width: 300px;
  height: 200px;
}
.picUpload /deep/ .el-upload-list__item.is-uploading {
  width: 300px;
  height: 200px;
}
.picUpload /deep/ .el-upload-list__item.is-success {
  width: 300px;
  height: 200px;
}
.hide /deep/ .el-upload--picture-card {
  display: none;
}
.title {
   border-left: 3px solid #909ca7;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 14px;
  width: 100%;
  margin-bottom: 20px;
}
.title1 {
  margin-top: 40px;
}
.avatar {
  width: 300px;
  height: 200px;
}
.btnROW {
 padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnROW .el-button{
  width: 100px;
  height: 40px;
}
.Redtext {
  color: red;
}
.picture-card {
  font-size: 14px;
  color: #ccc;
}
.iconColor{
  font-size: 40px;
  color: #999;
}
</style>
    
