<template>
    <div>
        <div class="swiper-container gallery-top">
            <div class="swiper-wrapper">
                <div class="swiper-slide" style="background-image:url(https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=3418434911,67165866&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3366668954,3895285925&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2676988361,640854359&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2037006078,4204402083&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=2726523322,3302652199&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3748302122,4235372866&fm=26&gp=0.jpg)"></div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next swiper-button-white"></div>
            <div class="swiper-button-prev swiper-button-white"></div>
        </div>
        <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
                <div class="swiper-slide" style="background-image:url(https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=3418434911,67165866&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3366668954,3895285925&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2676988361,640854359&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2037006078,4204402083&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=2726523322,3302652199&fm=26&gp=0.jpg)"></div>
                <div class="swiper-slide" style="background-image:url(https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3748302122,4235372866&fm=26&gp=0.jpg)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    export default {
        name: "test",
        data:()=>({


        }),
        methods:{
            initSwiper(){

            }

        },
        mounted(){

        },

    }
</script>

<style scoped>
    @import '../../../node_modules/swiper/swiper-bundle.css';
    @import '../../../node_modules/swiper/swiper-bundle.min.css';

    .swiper-container {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .gallery-top {
        height: 80%;
        width: 100%;
    }

    .gallery-thumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .gallery-thumbs .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }

    .gallery-thumbs .swiper-slide-thumb-active {
        opacity: 1;
    }
</style>