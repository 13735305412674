<template>
  <div style="height: 100%" >
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">数字资产</span>
        <span slot="third">阵地资产</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search ref="topSearch"  :topSearchShow="topSearchShow"
         parentUrl="dataAssetPosition" @condition="getCondition"
       :isQuickDateCur="true" :allLoadingState="allLoadingState"></top-search>
    </el-card>
    <screen-type-search @screenTypeSearch="screenTypeSearch" :showPartProp="showPart" dateCurProp="yesDay"
                        :allLoadingState="allLoadingState" :setSessionDataProp="false">
    </screen-type-search>
    <!--<top-data ref="dataAssetSearch"-->
              <!--:showRateBtn="false" :asOfYesterday="false" :hotelNatureBtn="true"-->
            <!--@conditionDataAsset="conditionDataAsset"-->
            <!--:topDataAssetSearchShow="topDataAssetSearchShow" @showTime="showTime"-->
    <!--&gt;</top-data>-->
    <el-card class="box-card" v-loading="loading.hotelStatisticLoading">
      <div class="rowBox vertical">
        <div class="titleBox">
          <div class="title">阵地资产概览</div>
          <div class="stateBox minFont numTitle">
            {{ condition.hotel_use_state_label }}
          </div>
        </div>
        <el-row :gutter="10" style="margin-top: 20px">
          <index-four-row-num-unit  :span="8" :type="type"
                                    :toolText="toolText.hotelNum" :parShowDate="showData.hotelStatistic.hotel_num">
            <span slot="numTitle">酒店数量</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="num">{{showDataCom(showData.hotelStatistic.hotel_num)}}</span>
            <span slot="unit">家</span>
          </index-four-row-num-unit >
          <index-four-row-num-unit  :span="8" :type="type" :toolText="toolText.hotelNum" :parShowDate="showData.hotelStatistic.active_terminal_num">
            <span slot="numTitle">激活终端数</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="num">{{showDataCom(showData.hotelStatistic.active_terminal_num)}}</span>
            <span slot="unit">台</span>
          </index-four-row-num-unit >
          <index-four-row-num-unit  :span="8" :type="type" :toolText="toolText.hotelNum" :parShowDate="showData.hotelStatistic.day_active_rate" parShowUnit="money">
            <span slot="numTitle">日活率</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="num">{{showDataCom(showData.hotelStatistic.day_active_rate,'money')}}</span>
            <span slot="unit">%</span>
          </index-four-row-num-unit >
        </el-row>
      </div>
    </el-card>
    <el-card class="box-card" v-loading="loading.operateLoading">
      <div class="rowBox vertical">
        <div class="flexAlignBox">
          <div class="title blueTitle">阵地资产统计</div>
          <div class="quickDateSearch rightBtn">
            <div class="item" @click="quickTotalSearchMeth('total')" :class="filterGroup.totalOrIncrement==='total'?'active':''">累计</div>
            <div class="item" @click="quickTotalSearchMeth('increment')" :class="filterGroup.totalOrIncrement==='increment'?'active':''">新增</div>
          </div>
          <div class="quickDateSearch rightBtn" v-if="hideEl">
            <div class="item" @click="quickRateSearchMeth('year')" :class="quickRateCurActive('yearOrChain','year')">同比</div>
            <div class="item" @click="quickRateSearchMeth('chain')" :class="quickRateCurActive('yearOrChain','chain')">环比</div>
          </div>
        </div>
        <el-row :gutter="10" style="margin-top: 20px">
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           :filterGroup="filterGroup" showType="" :allData="showData.operation.active">
            <span slot="numTitle">激活终端数总计</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           :filterGroup="filterGroup" showType="yearOrChain" :allData="showData.operation.operation">
            <span slot="numTitle">可运营激活终端数</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type"
                           :toolText="toolText.hotelNum"
                           :filterGroup="filterGroup"
                           showType="yearOrChain" :allData="showData.operation.noOperation">
            <span slot="numTitle">不可运营激活终端数</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
        </el-row>
      </div>
    </el-card>
    <el-card class="box-card" v-loading="loading.terminalLoading">
      <div class="rowBox vertical" >
        <div class="flexAlignBox" >
          <div class="title blueTitle">应用阵地资产统计</div>
          <div class="quickDateSearch">
            <div class="item" @click="quickMovieTypeSearchMeth('film')" :class="quickRateCurActive('filmOrAd','film')">电影媒资</div>
            <div class="item" @click="quickMovieTypeSearchMeth('ad')" :class="quickRateCurActive('filmOrAd','ad')">广告</div>
            <div class="item" @click="quickMovieTypeSearchMeth('shop')" :class="quickRateCurActive('filmOrAd','shop')">商城</div>
          </div>
          <div class="quickDateSearch rightBtn">
            <div class="item" @click="quickBadSearchMeth('isIncrement')" :class="quickRateCurActive('newIncreaseOrBad','isIncrement')">新增</div>
            <div class="item" @click="quickBadSearchMeth('isBadness')" :class="quickRateCurActive('newIncreaseOrBad','isBadness')">不良</div>
          </div>
        </div>
        <el-row :gutter="10" style="margin-top: 20px" v-if="showFilmOrAdCom('film')">
          <data-show-bottom :span="8" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.电影媒资.极光TV">
            <span slot="numTitle">极光TV</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="8" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.电影媒资.风霆迅">
            <span slot="numTitle">风霆迅</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="8" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.电影媒资.爱奇艺">
            <span slot="numTitle">爱奇艺</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
        </el-row>
        <el-row :gutter="10" style="margin-top: 20px" v-if="showFilmOrAdCom('film')">
          <data-show-bottom :span="8" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.电影媒资.三方点播">
            <span slot="numTitle">三方点播</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="8" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.电影媒资.影视未分配">
            <span slot="numTitle">影视未分配</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
        </el-row>
        <el-row :gutter="10" style="margin-top: 20px" v-if="showFilmOrAdCom('ad')">
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.广告.NO广告">
            <span slot="numTitle">NO广告</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.广告.广告V1">
            <span slot="numTitle">广告V1.0</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.广告.广告V2">
            <span slot="numTitle">广告V2.0</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.广告.广告未分配">
            <span slot="numTitle">广告未分配</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
        </el-row>
        <el-row :gutter="10" style="margin-top: 20px" v-if="showFilmOrAdCom('shop')">
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.商城.商城V1">
            <span slot="numTitle">商城V1.0</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.商城.商城V2">
            <span slot="numTitle">商城V2.0</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.商城.商城未分配">
            <span slot="numTitle">商城未分配</span>
            <span slot="time">{{showDataTime}}</span>>
            <span slot="unit">家</span>
          </data-show-bottom>
          <data-show-bottom :span="6" :type="type" :toolText="toolText.hotelNum"
                            :filterGroup="filterGroup" :allData="showData.terminal.商城.商城隐藏">
            <span slot="numTitle">商城隐藏</span>
            <span slot="time">{{showDataTime}}</span>>
            <span slot="unit">家</span>
          </data-show-bottom>
        </el-row>


      </div>
    </el-card>
    <el-card class="box-card" v-loading="loading.badLoading">
      <div class="rowBox vertical">
        <div class="flexAlignBox">
          <div class="title blueTitle">运营不良阵地资产统计</div>
          <div class="grayFonts comment">（七日平均日活率0%-30%）</div>
          <div class="rightBtn">
            <el-button class="openMore" type="primary" @click="badFoldMeth">{{badFoldBtn}}</el-button>
          </div>
        </div>
        <el-row :gutter="10" style="margin-top: 20px" >
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.active">
            <span slot="numTitle">激活终端数总计</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.operation">
            <span slot="numTitle">可运营激活终端数</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.noOperation" offLineShowProp="true">
            <span slot="numTitle">不可运营激活终端数</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
        </el-row>

        <el-row :gutter="10" style="margin-top: 20px" v-if="badFoldBtn==='收起'">
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.operation.movie">
            <span slot="numTitle">电影媒资（可运营）</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.operation.shop">
            <span slot="numTitle">商城（可运营）</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.operation.ad">
            <span slot="numTitle">广告（可运营）</span>
            <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
        </el-row>
        <el-row :gutter="10" style="margin-top: 20px" v-if="badFoldBtn==='收起'">
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.noOperation.movie">
            <span slot="numTitle">电影媒资（不可运营）</span>
           <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.noOperation.shop">
            <span slot="numTitle">商城（不可运营）</span>
           <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>
          <data-show-right :span="8" :type="type" :toolText="toolText.hotelNum"
                           showType="percent" :allData="showData.badness.noOperation.ad">
            <span slot="numTitle">广告（不可运营）</span>
           <span slot="time">{{showDataTime}}</span>
            <span slot="unit">台</span>
          </data-show-right>

        </el-row>
      </div>
    </el-card>
    <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
  </div>
</template>

<script>
import qs from "querystring";
import commonMeth from '../../api/method'
export default {
  name: "DataAssetSoft",
  data: () => ({
    distanceTopLoading: false,
    checkList: ["电影媒资", "商城", "广告"],
    checkListOperate: ["电影媒资", "商城", "AD"],
    condition: {},
    type: "timeCenter",
    toolText: {
      hotelNum: "时间范围内酒店状态为实施中/待上线/使用中/已离线状态去重酒店数",
      roomNum: "时间范围内酒店状态为实施中/待上线/使用中状态去重激活终端数",
      active: "昨日心跳房间数不随时间筛选而变化",
      rate: "时间范围内日活量/激活终端数量 * 100%",
    },
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/dataAssetPosition" },
    },
    allAppData: [], // 所有APP数据
    allSumData: {},
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    // 选择日期
    topDataAssetSearchShow: {
      dateSelect: true,
    },
    // 显示的搜索条件
    topSearchShow: {
      hotel_brand: true,
      BD: true,
      keyword: true,
      coopType: true,
      keywordPlaceholder: "请输入酒店名称关键词",
      date:true,
      hotel_use_state:true,
    },
    quickDateCur:'',
    filterGroup:{
      totalOrIncrement:'total',
      yearOrChain:[],
      filmOrAd:['film'],
      newIncreaseOrBad:[],
    },
    form:{
      isChain:0,
      isYear:0,
      isIncrement:0,
      isBadness:0,
      startTime:'',
      endTime:'',
      numerica:'total',
      isAccumulative:0,
      screenType:'self',
    },
    token:'',
    auth:{},
    showData:{
      badness:{
        active:{
          total:'0',
          zero:'0',
          ten:'0',
          thirty:'0',
        },
        noOperation:{
          ad:{
            total:'0',
            zero:'0',
            ten:'0',
            thirty:'0',
          },
          movie:{
            total:'0',
            zero:'0',
            ten:'0',
            thirty:'0',
            offline:'0'
          },
          shop:{
            total:'0',
            zero:'0',
            ten:'0',
            thirty:'0',
          },
          total:'0',
          zero:'0',
          ten:'0',
          thirty:'0',
        },
        operation:{
          total:'0',
          zero:'0',
          ten:'0',
          thirty:'0',
        },
      },
      hotelStatistic:{
        hotel_num:0,
        active_terminal_num:0,
        day_active_rate:0,
      },
      operation:{
        active:{
          ad:'0',
          chainLevel:'0',
          chainPercent:'0',
          movie:'0',
          number:'0',
          shop:'0',
          yearLevel:'0',
          yearPercent:'0',
        },
        noOperation:{
          ad:'0',
          chainLevel:'0',
          chainPercent:'0',
          movie:'0',
          number:'0',
          shop:'0',
          yearLevel:'0',
          yearPercent:'0',
        },
        operation:{
          ad:'0',
          chainLevel:'0',
          chainPercent:'0',
          movie:'0',
          number:'0',
          shop:'0',
          yearLevel:'0',
          yearPercent:'0',
        },
      },
      terminal:{
        '商城':{
          '商城V1':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          '商城V2':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          '商城未分配':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          '商城隐藏':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
        },
        '广告':{
          '广告V1':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          '广告V2':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          'NO广告':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          '广告未分配':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
        },
        '电影媒资':{
          '三方点播':{
            '终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
          },
          '影视未分配':{'终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },},
          '极光TV':{'终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },},
          '爱奇艺':{'终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },},
          '风霆迅':{'终端数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            },
            '酒店数':{
              badnessThirty: "0",
              badnessZero: "0",
              chainLevel: '0',
              chainPercent: "0",
              increment: "0",
              total: "0",
              yearLevel: '0',
              yearPercent: "0"
            }},
        },
      }
    },
    date:{
      start:'',
      end:'',
    },
    hideEl:false,
    startTime:'',
    endTime:'',
    hotel_use_state:'',
    curDate:{
      yesDay:'',
    },
    badFoldBtn:'展开',
    loading:{
      hotelStatisticLoading:false,
      badLoading:false,
      operateLoading:false,
      terminalLoading:false,
    },
    allLoadingState:false,
    showPart:{
      date:true,
      self:true,
      other:true,
    },
  }),
  methods: {
    getCondition(e) {
      this.distanceTopLoading = true;
      this.provinceOne =
        e.provinceLabel.length != 0 ? e.provinceLabel.join() : "";
      this.hotel_brand = e.hotel_brand.length != 0 ? e.hotel_brand.join() : "";
      this.accountManagerId = e.BD.length != 0 ? e.BD.join() : "";
      this.partner_id = e.partner_id.length != 0 ? e.partner_id.join() : "";
      this.cityLabel = e.cityLabel;
      this.countyLabel = e.countyLabel;
      this.keyword = e.keyword;
      this.startTime=e.startTime;
      this.endTime=e.endTime;
      this.hotel_use_state= Array.isArray(e.hotel_use_state) ? e.hotel_use_state.join(',') : '';
      this.showDataMeth();
    },
    conditionDataAsset(e) {
      this.distanceTopLoading = true;
      this.startTime = e.startTime;
      this.endTime = e.endTime;
      this.form.isAccumulative=e.isAccumulative;
      this.form.screenType=e.screenType;
      this.showDataMeth();
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    quickRateSearchMeth(type){
      this.form.isYear=0;
      this.form.isChain=0;
      var yearOrChain=this.filterGroup.yearOrChain;
      var index=yearOrChain.indexOf(type);
      if(index!==-1){
        this.filterGroup.yearOrChain.splice(index,1);
      }else{
        this.filterGroup.yearOrChain.push(type);
      }
      yearOrChain.forEach((item,index)=>{
        if(item==='year'){
          this.form.isYear=1;
        }else if(item==='chain'){
          this.form.isChain=1;
        }else{
          this.form.isYear=0;
          this.form.isChain=0;
        }
      });
      this.showDataMeth();
    },
    quickTotalSearchMeth(val){
      var totalOrIncrement=this.filterGroup.totalOrIncrement;
      if(totalOrIncrement===val){
        this.filterGroup.totalOrIncrement='';
      }else{
        this.filterGroup.totalOrIncrement=val;
      }
      this.form.numerica=this.filterGroup.totalOrIncrement;
      this.showDataMeth('operation');
    },
    quickMovieTypeSearchMeth(type){
      var filmOrAd=this.filterGroup.filmOrAd;
      var index=filmOrAd.indexOf(type);
      if(index!==-1){
        this.filterGroup.filmOrAd.splice(index,1);
      }else{
        this.filterGroup.filmOrAd.push(type);
      }
    },
    quickBadSearchMeth(type){
      this.form.isIncrement=0;
      this.form.isBadness=0;
      var newIncreaseOrBad=this.filterGroup.newIncreaseOrBad;
      var index=newIncreaseOrBad.indexOf(type);
      if(index!==-1){
        this.filterGroup.newIncreaseOrBad.splice(index,1);
      }else{
        this.filterGroup.newIncreaseOrBad.push(type);
      }
      newIncreaseOrBad.forEach((item,index)=>{
        if(item==='isIncrement'){
          this.form.isIncrement=1;
        }else if(item==='isBadness'){
          this.form.isBadness=1;
        }else{
          this.form.isIncrement=0;
          this.form.isBadness=0;
        }
      });
    },
    showDataMeth(type) {
      return new Promise((resolve,reject)=>{
        this.showLoading(type,true);
        var form=this.form;
        var params = {
          token: this.token,
          province: this.provinceOne,
          city: this.cityLabel,
          county: this.countyLabel,
          start_time: this.startTime,
          end_time: this.endTime,
          hotelBrand: this.hotel_brand,
          accountManagerId: this.accountManagerId,
          partner_id: this.partner_id,
          keyword: this.keyword,
          hotel_use_state:this.hotel_use_state,
          // isChain: form.isChain,
          // isYear: form.isYear,
          isIncrement: form.isIncrement,
          isBadness: form.isBadness,
          numerical: form.numerica,
        };
        if(this.auth.type==='htrip'){
          params.screenType=form.screenType
        }
        this.$http.post(this.global.getPositionAssets,qs.stringify(params)).then(res => {
          var code = res.data.code;
          if (code === 100000) {
            this.showData = res.data.data;
          }
          this.showLoading(type,false);
          resolve();
        }).catch(() => {
          // this.showLoading = false;
        })
      })
    },
    showLoading(type,val){
      if(val){
        if(type==='operation'){
          this.loading.operateLoading=true;
          this.loading.hotelStatisticLoading=false;
          this.loading.badLoading=false;
          this.loading.terminalLoading=false;
        }else{
          this.loading.hotelStatisticLoading=true;
          this.loading.badLoading=true;
          this.loading.operateLoading=true;
          this.loading.terminalLoading=true;
        }
      }else{
        this.loading.hotelStatisticLoading=val;
        this.loading.badLoading=val;
        this.loading.operateLoading=val;
        this.loading.terminalLoading=val;
      }
    },
    showTime(start,end,type){
      this.$refs.topSearch.setDefault('date');
      this.startTime=start;
      this.endTime=end;
    },
    getYesDay(){
      var time=(new Date).getTime()-24*60*60*1000;
      var yesterday=new Date(time);
      var month=yesterday.getMonth()+1;
      var day=yesterday.getDate();
      this.curDate.yesDay=yesterday.getFullYear() + "-" + (month>9 ? (month) : "0" + (month)) + "-" +(yesterday.getDate()> 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
    },
    badFoldMeth(){
      this.badFoldBtn==='收起'?this.badFoldBtn='展开':this.badFoldBtn='收起';
    },
    screenTypeSearch(screenType,startTime,endTime){
      this.form.screenType=screenType;
      this.startTime=startTime;
      this.endTime=endTime;
      this.showDataMeth();
    },
    getScreenTypeSearchShow(){
      if(this.auth.type==='htrip'){
        this.showPart={
          date:true,
          self:true,
          other:true,
        }
      }else{
        this.showPart={
          date:true,
          self:false,
          other:false,
        }
      }
    }
  },
  mounted() {
    this.token=sessionStorage.getItem("token");
    this.auth=JSON.parse(sessionStorage.getItem('auth'));
    this.showDataMeth();
    this.getYesDay();
    this.getScreenTypeSearchShow();
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
    noData(val) {
      var str = "";
      if (val) {
        str = val;
      } else {
        str = "暂无数据";
      }
    },
    quickRateCurActive(){
      return function(type,val){
        var str='';
        if(type==='yearOrChain'){
          if(this.filterGroup.yearOrChain.indexOf(val)!==-1){
            str='active'
          }
        }else if(type==='filmOrAd'){
          if(this.filterGroup.filmOrAd.indexOf(val)!==-1){
            str='active'
          }
        }else if(type==='totalOrIncrement'){
          if(this.filterGroup.totalOrIncrement===val){
            str='active'
          }
        }else if(type==='newIncreaseOrBad'){
          if(this.filterGroup.newIncreaseOrBad.indexOf(val)!==-1){
            str='active'
          }
        }
        return str;
      }
    },
    showDataTime(){
      var time='';
      var startTime=this.startTime;
      var endTime=this.endTime;
      var startTrans=new Date(startTime.replace(/-/g,'\/'));
      var endTrans=new Date(endTime.replace(/-/g,'\/'));
      if(startTime==='' || ((startTime===endTime) && (startTime===this.curDate.yesDay))){
        time= '【昨日】'
      }else if(startTrans>endTrans){
        time='~'
      }else{
        time='【'+this.startTime+'~'+this.endTime+'】';
      }
      return time;
    },
    showFilmOrAdCom(){
      return function(val){
        var filmOrAd=this.filterGroup.filmOrAd;
        var flag=false;
        if(filmOrAd.length>0){
          if(filmOrAd.indexOf(val)!==-1){
            flag=true;
          }else{
            flag=false;
          }
        }else{
          flag=true;
        }
        return flag;
      }
    },
    allLoadingStateCom(){
      var loading=this.loading;
      this.allLoadingState=commonMeth.getAllLoading(loading.hotelStatisticLoading,
              loading.operateLoading,loading.terminalLoading,loading.badLoading);
    },

  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  border-left: 3px solid #909ca7;
  padding-left: 10px;
  box-sizing: border-box;
}
// 复选框为空
.checkListNull {
  height: 140px;
  line-height: 140px;
  text-align: center;
  font-size: 16px;
  width: 100%;
  /*color:#939495;*/
}
.checkListNull.operate {
  height: 152px;
}
.dinNumber {
  font-family: DIN !important;
}
.distanceTop {
  padding-top: 20px;
  overflow-y: auto;
}
.relativeChartLine {
  min-width: 1440px;
}

.fontBold {
  font-weight: bold;
  font-size: 28px;
  color: rgba(1, 1, 1, 1);
  padding: 12px 0;
  font-family: DIN !important;
}
.widthCompare {
  width: 158px;
  color: #676e74;
}
.redFonts {
  font-family: DIN !important;
  color: rgba(255, 8, 8, 1);
}
.greenFonts {
  font-family: DIN !important;
  color: rgba(57, 187, 40, 1);
}
.grayFonts {
  font-family: DIN !important;
  color: #939495;
}
.occupy {
  visibility: hidden;
  margin-left: -4px;
}

.checkboxGroup {
  border-bottom: solid 1px #a0a0a0;
  width: 100%;
  padding-bottom: 14px;
  .heightBlank {
    height: 18px;
  }
}
.noWrap {
  white-space: nowrap;
}
.detailsAsset {
  display: flex;
  .polygon {
    margin-top: 9px;
    background-color: #f8fbff;
    // width: 60%;
    width: 1155px;
    padding: 30px 30px 30px 0;
    // height: 126px;
    display: flex;

    .polygonItems {
      font-size: 16px;
      width: 200px;
      text-align: left;
      color: #676e74;
    }
  }
  .polygonOne {
    // width: 61.2%;
    padding-top: 0 !important;
    padding-bottom: 20px;
    width: 1172px;
  }
  .polygonTwo {
    // width: 62.6%;
    width: 1189px;
    padding: 0 30px 20px 0;
    flex-wrap: wrap;
  }
  .noPaddingRight {
    padding-right: 0 !important;
  }
  ul {
    border-radius: 10px;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    /*padding-top: 20px;*/
    margin-top: 20px;
    width: 1206px;
    li:last-child {
      padding-right: 0 !important;
    }
    li {
      display: flex;
      padding-right: 20px;
      .terminalTtile {
        // padding: 120px 0;
        width: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        // text-align: center;
        color: #333435;
        font-size: 16px;
        background-color: #eaeef2;
      }
      .terminalContent {
        display: flex;
        padding: 20px;
        background-color: #f0f4f8;
        min-width: 198px;
        .terminalContentTitle {
          color: #1a3563;
          font-size: 16px;
          font-weight: 900;
          white-space: nowrap;
        }
        .fontBold {
          padding: 20px 0 10px 0;
        }
      }
    }
  }
  .polygonThree {
    overflow-y: hidden;
    overflow-x: auto;
    width: 1205px;
    flex-wrap: wrap;
    // width: calc(100% - 37%);
    padding: 0 30px 0 0;
    // width:calc(100%-144px);

    .polygonItems {
      padding-right: 22px;
    }
  }
  .trapezoid {
    margin-top: 9px;
    width: 238px;
    height: 0;
    border-top: 145px solid #3b58e8;
    border-right: 16px solid transparent;
    border-left: 16px solid #f8fbff;
    position: relative;
    z-index: 1;
    .location {
      position: absolute;
      /*<!--top: -78px;-->*/
      top: -98px;
      left: 50px;
      color: #fff;
      font-size: 20px;
    }
  }

  .trapezoidOne {
    width: 206px;
    /*border-top: 145px solid #7389ef;*/
    border-top: 192px solid #7389ef;
    /*padding:20px 30px;*/
  }
  .trapezoidTwo {
    width: 174px;
    border-top: 192px solid #99a9f3;
    /*border-top: 250px solid #99a9f3;*/
    /*// border-top: 145px solid #99a9f3;*/
    .location {
      left: 28px;
      top: -128px;
    }
  }
  .trapezoidThree {
    width: 144px;
    border-top: 145px solid rgba(59, 88, 232, 0.3);
  }
}
.sumBox {
  width: 240px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  .title,
  .num {
    white-space: nowrap;
  }
  .numBox {
    display: flex;
    padding-top: 20px;
    margin-top: 10px;
    border-top: 2px solid #ddd;
    .item {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      p {
        text-align: center;
      }
    }
  }
  .numBold {
    font-weight: bold;
    font-size: 28px;
    color: rgba(1, 1, 1, 1);
    padding-top: 20px;
    font-family: DIN !important;
  }
  // .line{
  //   width:2px;
  //   height:100%;
  //   background:#ddd;
  // }

  &.terminalTtile.noBack {
    background: none !important;
  }
}
.liBox {
  display: flex;
}
.noPaddingBottom {
  padding-bottom: 0 !important;
}
.pointer {
  cursor: pointer;
}
.quickDateSearch{
  margin-left:15px;
  display:flex;
}
.quickDateSearch .item{
  border:1px solid #dcdfe6;
  color:#606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  // padding: 12px 20px;
   width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
  cursor:pointer;
  margin-left:15px;
}
.quickDateSearch .item.active{
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
  .blueTitle{
    border-left: 3px solid #909ca7;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .noBorder{
    border:none!important;
  }
  .flexAlignBox{
    display:flex;
    align-items: center;
  }
.rightBtn{
  margin-left:auto;
}
.openMore{
  width: 100px;
  height: 40px;
}


</style>
