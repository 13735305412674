<template>
  <div v-loading="pageLoading">
    <!-- 面包屑 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">首页</span>
        <span slot="third">更多</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <!-- 面包屑 -->
    <el-card class="box-card">
      <!-- 按钮与标题 -->
      <div class="backHistroy">
        <el-button class="largeBtn" type="primary" @click="backPage">返回</el-button>
        <el-button class="largeBtn" type="primary" v-show="btnShow" @click="pass">确定</el-button>
      </div>
      <div class="StatementAccountTitle">
        <p class="monthTitle">{{ monthName }}月份对账单</p>
        <p class="AccountNumber">流水号：{{ pageData.reconciliation_id }}</p>
      </div>
      <!-- 按钮与标题 -->
      <el-tabs v-model="activeName" :before-leave="handleClick">
        <!-- 单点 -->
        <el-tab-pane label="影视单片点播" name="001001" v-if="reconciliationType!='2'">
          <div class="showNewBox">
            <el-row :gutter="24" class="rowContent">
              <el-col :span="10" class="rowContentLeft">
                <p>
                  <span>对账公司名称：</span>
                  {{ pageData.partner_name }}
                </p>
                <p>
                  <span>合作伙伴终端编码：</span>
                  {{ pageData.partner_code }}
                </p>
                <p>
                  <span>合同有效期：</span>
                  {{
                    pageData.contract_date_begin && pageData.contract_date_end
                      ? pageData.contract_date_begin.substr(0, 10) +
                        " ~ " +
                        pageData.contract_date_end.substr(0, 10)
                      : ""
                  }}
                </p>
                <p>
                  <span>对账周期：</span>
                  {{
                    pageData.reconciliation_cycle_begin &&
                    pageData.reconciliation_cycle_end
                      ? pageData.reconciliation_cycle_begin.substr(0, 10) +
                        " ~ " +
                        pageData.reconciliation_cycle_end.substr(0, 10)
                      : ""
                  }}
                </p>
              </el-col>
              <el-col :span="14" class="rowContentRight">
                <p class="rowContentShow">
                  <span>订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.total_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">订单金额（元）：</span><span class="numberShow">{{
                    pageData.total_order_income
                  }}</span>
                </p>
                <p class="rowContentShow">
                  <span>退款订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.refund_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">退款金额（元）：</span><span class="numberShow">{{ pageData.refund_order_income }}</span>

                </p>
                <p class="rowContentShow">
                  <span>对账订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">对账金额（元）：</span><span class="numberShow">{{
                    pageData.amount
                  }}</span>

                </p>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <!-- 单点 -->
        <!-- 天卡 -->
        <el-tab-pane label="影视天卡销售" name="001002" v-if="reconciliationType!='1'">
          <div class="showNewBox">
            <el-row :gutter="24" class="rowContent">
              <el-col :span="10" class="rowContentLeft">
                <p>
                  <span>对账公司名称：</span>
                  {{ pageData.partner_name }}
                </p>
                <p>
                  <span>合作伙伴终端编码：</span>
                  {{ pageData.partner_code }}
                </p>
                <p>
                  <span>合同有效期：</span>
                  {{
                    pageData.contract_date_begin && pageData.contract_date_end
                      ? pageData.contract_date_begin.substr(0, 10) +
                        " ~ " +
                        pageData.contract_date_end.substr(0, 10)
                      : ""
                  }}
                </p>
                <p>
                  <span>对账周期：</span>
                  {{
                    pageData.reconciliation_cycle_begin &&
                    pageData.reconciliation_cycle_end
                      ? pageData.reconciliation_cycle_begin.substr(0, 10) +
                        " ~ " +
                        pageData.reconciliation_cycle_end.substr(0, 10)
                      : ""
                  }}
                </p>
              </el-col>
              <el-col :span="14" class="rowContentRight">
                <p class="rowContentShow">
                  <span>订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.total_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">订单金额（元）：</span><span class="numberShow">{{
                    pageData.total_order_income
                  }}</span>
                </p>
                <p class="rowContentShow">
                  <span>退款订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.refund_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">退款金额（元）：</span><span class="numberShow">{{ pageData.refund_order_income }}</span>

                </p>
                <p class="rowContentShow">
                  <span>对账订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">对账金额（元）：</span><span class="numberShow">{{
                    pageData.amount
                  }}</span>

                </p>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <!-- 天卡 -->
      </el-tabs>
    </el-card>
    <!-- 酒店列表 -->
    <el-card class="box-card">
      <el-table ref="singleTable" :data="tableData" style="width: 100%" :header-cell-style="headerStyle" :height="tableData.length>10?'550px':'330px'">
        <el-table-column fixed type="index" label="序号" width="50"></el-table-column>
        <el-table-column min-width="120" v-if="btnShow">
          <template slot-scope="scope">
            <el-switch class="tableScopeSwitch" v-model="scope.row.is_reject" active-color="red" active-text="驳回" inactive-color="#DCDCDC" @change="switchChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="hotel_name" label="酒店名称" min-width="300"></el-table-column>
        <el-table-column label="操作" min-width="120">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row, 1)">详细</el-button>
            <el-button v-show="scope.row.message" type="text" size="mini" @click="openContent(scope.row, 2)">驳回原因
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="日期"> </el-table-column>
        <el-table-column label="客房数" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.room_num?scope.row.room_num:"0"}}</span>
          </template>
        </el-table-column>
        <el-table-column width="130" label="激活终端数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.active_terminal_num?scope.row.active_terminal_num:"0"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="valid_active_terminal_num" width="180" label="影视有效激活终端数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.valid_active_terminal_num?scope.row.valid_active_terminal_num:"0"}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_num" label="订单数"></el-table-column>
        <el-table-column prop="amount" label="收益金额（元）" min-width="120px"></el-table-column>
        <el-table-column prop="refund_order_num" label="退款订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="refund_order_income" label="退款收益" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reconciliation_order_num" label="对账订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reconciliation_order_income" label="对账收益" show-overflow-tooltip></el-table-column>
        <el-table-column prop="day_active_rate" label="平均日活率" min-width="120px"></el-table-column>
      </el-table>
    </el-card>
    <!-- 酒店列表 -->
    <!-- 详情信息 -->
    <el-drawer :title="contentTitle" :visible.sync="contentShowVisible" :size="drawerWidth" :modal="false" :wrapperClosable="false">
      <!-- 订单列表详情 -->
      <div class="picShow" v-if="contentType == 1">
        <el-table ref="singleTable" :data="contentTableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="Loading">
          <el-table-column label="时间" min-width="250px">
            <template slot-scope="scope">
              <span>{{ scope.row.order_date }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="room_addtime" v-if="query.type !== 'independentReconciliation'" label="上线时间" min-width="200px"></el-table-column>
          <el-table-column prop="order_room" label="房间号" min-width="150px"></el-table-column>
          <el-table-column prop="order_paysn" min-width="250px" label="订单号"></el-table-column>
          <el-table-column prop="order_phone" label="手机号" min-width="150px"></el-table-column>
          <el-table-column prop="order_goods_name" label="电影名称" show-overflow-tooltip min-width="200"></el-table-column>
          <el-table-column prop="order_amount" label="单价"></el-table-column>
          <el-table-column prop="calculate" label="分成比例"></el-table-column>
          <el-table-column prop="income" label="收益"></el-table-column>
        </el-table>

      </div>
      <!-- 订单列表详情 -->
      <!-- 驳回原因 -->
      <div class="picShow picShow1" v-if="contentType == 2">
        <span>{{ returnMessageShow }}</span>
      </div>
      <!-- 驳回原因 -->
    </el-drawer>
    <!-- 详情信息 -->
    <!-- 驳回原因对话框 -->
    <el-dialog title="驳回原因填写" :visible.sync="returnVisible" width="30%" :before-close="handleClose">
      <el-input type="textarea" placeholder="请输入内容" v-model="returnmessge" clearable :rows="4" resize="none"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelReturn">取 消</el-button>
        <el-button type="primary" @click="trueReturn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 驳回原因对话框 -->
  </div>
</template>

<script>
import {
  getReconciliationContengByID,
  reconciliationAction,
  getReconciliationDetailByContractAndDate,
} from "@/api/financialManagement.js";
import qs from "querystring";
import {
  independentReconciliationAction,
  getIddReconciliationDetailByContractAndDate,
} from "@/api/iddfinan.js";
export default {
  name: "IndexDoVisual",
  data: () => ({
    Loading: false,
    drawerWidth: "95%",
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/indexDoMore" },
    },
    breadTaskToDo: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/taskToDo" },
    },
    breadTaskDone: {
      level: 4,
      thirdPath: { path: "/taskDone" },
    },
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    contentTitle: "明细",
    pageData: {},
    pageData1: {},
    tableData1: null,
    contentType: 1,
    returnMessageShow: "",
    tableData: [],
    monthName: "",
    btnShow: "",
    returnmessge: "",
    returnVisible: false,
    contentTableList: [],
    contentShowVisible: false,
    activeName: "first",
    query: {},
    messageList: [],
    clickSwitchRow: {},
    reconciliationType: "1",
    pageLoading: false,
    changeBln: true,
  }),
  methods: {
    // 开关
    switchChange(row) {
      if (row.is_reject) {
        this.returnVisible = true;
        Object.assign(this.clickSwitchRow, row);
      } else {
        this.messageList.forEach((item, index) => {
          if (item.hotelReconciliationId == row.id) {
            this.messageList.splice(index, 1);
          }
        });
      }
    },
    // 发送审核结果
    pass() {
      this.$confirm("是否确认审核结果", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var arr = [];
          Object.assign(arr, this.messageList); // 赋值驳回对象
          var jsonStr = JSON.stringify(arr);
          const json = {
            id: this.query.id,
            hotelInfo: jsonStr,
          };
          var param = qs.stringify(json);
          if (this.query.type !== "independentReconciliation") {
            // 独立影视对账流程
            reconciliationAction(param)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("审批成功");
                  this.backPage();
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((err) => {});
          } else {
            //  渠道商对账单流程
            independentReconciliationAction(param)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("审批成功");
                  this.backPage();
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((err) => {});
          }
        })
        .catch(() => {});
    },
    // 驳回确认
    trueReturn() {
      if (this.returnmessge == "") {
        this.$message({
          message: "请填写驳回原因",
          type: "warning",
        });
      } else {
        this.returnVisible = false;
        const json = {
          hotelReconciliationId: this.clickSwitchRow.id,
          message: this.returnmessge,
        };
        this.messageList.push(json);
        this.returnmessge = "";
      }
    },
    // 驳回取消
    cancelReturn() {
      this.returnmessge = "";
      this.returnVisible = false;
      var id = this.clickSwitchRow.id;
      this.tableData.forEach((item) => {
        if (item.id == id) {
          item.is_reject = false;
        }
      });
    },
    // 关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          this.cancelReturn();
        })
        .catch((_) => {});
    },
    // 返回
    backPage() {
      this.$router.push("/" + this.query.fromUrl);
    },
    // 详情
    openContent(row, num) {
      this.Loading = true;
      if (num == 1) {
        //获取酒店详情
        this.contentTitle = "明细";
        this.contentType = 1;
        this.drawerWidth = "95%";
        this.returnshowTrue = false;
        this.contentShowVisible = true;
        const json = {
          date: row.date,
          hotelContract: row.hotel_contract,
          partnerId: this.pageData.partner_id,
          reconciliationType: this.activeName,
        };
        var param = qs.stringify(json);
        if (this.query.type !== "independentReconciliation") {
          //独立影视
          getReconciliationDetailByContractAndDate(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.contentTableList = res.data.data;
                this.Loading = false;
              }
            })
            .catch((err) => {});
        } else {
          // 渠道商
          getIddReconciliationDetailByContractAndDate(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.contentTableList = res.data.data;
                this.Loading = false;
              }
            })
            .catch((err) => {});
        }
      } else {
        // 驳回原因
        this.contentTitle = "驳回原因";
        this.drawerWidth = "45%";
        this.returnshowTrue = true;
        this.returnMessageShow = row.message;
        this.contentType = 2;
        this.contentShowVisible = true;
      }
    },
    //关闭
    closeDialog() {
      this.contentShowVisible = false;
    },
    // 当前对账单有多类别时的切换处理
    handleClick(activeName, oldActiveName) {
      if (this.changeBln) {
        this.changeBln = false;
      } else {
        if (activeName != oldActiveName) {
          if (this.reconciliationType == "3") {
            var page = JSON.parse(JSON.stringify(this.pageData));
            var table = JSON.parse(JSON.stringify(this.tableData));
            this.pageData = JSON.parse(JSON.stringify(this.pageData1));
            this.tableData = JSON.parse(JSON.stringify(this.tableData1));
            this.pageData1 = JSON.parse(JSON.stringify(page));
            this.tableData1 = JSON.parse(JSON.stringify(table));
          }
        }
      }
    },
    // 页面数据加载
    getData(id) {
      this.pageLoading = true;
      const json = {
        id: id,
      };
      var param = qs.stringify(json);
      getReconciliationContengByID(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.reconciliationType = res.data.data.reconciliationType;
            if (res.data.data.reconciliationType == 1) {
              //只有单点
              this.activeName = "001001";
              this.pageData = res.data.data.reconciliationRow.mcu;
              this.tableData = res.data.data.items.mcu;
            } else if (res.data.data.reconciliationType == 2) {
              // 只有天卡
              this.pageData = res.data.data.reconciliationRow.day_card;
              this.tableData = res.data.data.items.day_card;
              this.activeName = "001002";
            } else {
              // 天卡对账共存
              this.activeName = "001001";
              this.pageData = res.data.data.reconciliationRow.mcu;
              this.tableData = res.data.data.items.mcu;
              this.pageData1 = res.data.data.reconciliationRow.day_card;
              this.tableData1 = res.data.data.items.day_card;
            }

            // 判断当前酒店审核状态是否存在驳回
            if (this.tableData) {
              this.tableData.forEach((item) => {
                if (item.is_reject) {
                  const json = {
                    hotelReconciliationId: item.id,
                    message: item.message,
                  };
                  this.messageList.push(json);
                }
              });
            }
            if (this.tableData1) {
              this.tableData1.forEach((item) => {
                if (item.is_reject) {
                  const json = {
                    hotelReconciliationId: item.id,
                    message: item.message,
                  };
                  this.messageList.push(json);
                }
              });
            }

            var month = this.pageData.date_text.substring(5, 7);
            if (month[0] == 0) {
              this.monthName = Number(month.substring(1, 2));
            } else {
              this.monthName = Number(month);
            }

            this.pageLoading = false;
          } else {
            this.pageLoading = false;
          }
        })
        .catch((err) => {});
    },
  },
  // 数据挂载阶段处理
  mounted() {
    var query = this.$route.query;
    this.query = query;
    if (this.query.reviewStatus == "待审核") {
      this.btnShow = true;
    } else {
      this.btnShow = false;
    }

    this.getData(this.query.id);
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #ecf3fd;
}
.tableBox {
  width: 100%;
  height: calc(100% - 140px);
  overflow: auto;
}
.grayBox {
  height: 20px;
  width: 112%;
  margin-left: -5%;
  background-color: #f9f9f9;
}
.StatementAccountTitle p {
  text-align: center;
  margin-bottom: 17px;
}
.showNewBox {
  padding: 0 15px;
}
.inlineBlock {
  display: inline-block;
  width: 100px;
  font-size: 12px;
  text-align: right;
}
.el-tabs__header {
  margin: 0 !important;
}
.rowContent {
  padding: 25px;
  height: 200px;
  box-sizing: border-box;
}

.rowContentLeft,
.rowContentRight {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.rowContentLeft {
  font-size: 14px;
  color: #333;
}
.rowContentLeft p span:first-child {
  display: inline-block;
  width: 170px;
  text-align: right;
  font-size: 14px;
  color: #70747a;
}
.rowContentRight p span:first-child {
  display: inline-block;
  text-align: right;
  font-size: 14px;
  color: #70747a;
}
.rowContentRight .numberShow1 {
  font-size: 16px !important;
}
.rowContentRight .numberShow {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 10px;
  font-family: "DINAlternate";
  color: #333;
  font-size: 14px;
}
.rowContentRight p span {
  font-size: 14px;
  color: #70747a;
  display: inline-block;
}
.rowContentShow {
  display: flex;
  align-items: center;
}
.rowContentShow span {
  flex: 1;
}
.rowContentShow span:first-child {
  flex: 2;
  text-align: right;
}

.box-card {
  position: relative;
}
.backHistroy {
  display: flex;
  justify-content: space-between;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  width: 50%;
  padding: 10px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background: #b6babf;
}
.dialogTitle {
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-align: left;
  padding: 0 10px;
  border-bottom: 1px solid #ecf3fd;
}

.picShow {
  position: relative;
  z-index: 20;
  width: 100%;
}
.picShow1 {
  box-sizing: border-box;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.monthTitle {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.AccountNumber {
  color: #adb6c1;
  font-size: 14px;
}
.el-tabs ::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #e4e7ed;
  z-index: 1;
}
.el-tabs /deep/ .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  color: #303030;
  position: relative;
}
.el-tabs /deep/ .el-tabs__active-bar {
  width: 84px !important;
}
.tableScopeSwitch /deep/ .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
.tableScopeSwitch /deep/ .el-switch__label--right {
  z-index: 1;
  right: 22px;
}
.tableScopeSwitch /deep/ .el-switch__label.is-active {
  display: block;
}
.tableScopeSwitch.el-switch /deep/ .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}
::v-deep .el-drawer {
  overflow: visible !important;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  background-color: #f9f9f9;
  overflow: auto;
}
.contentBox {
  background-color: #fff;
  padding: 20px;
}
::v-deep :focus {
  outline: 0;
}
</style>