<template>
  <el-alert class="subAlert" type="warning" show-icon :closable="false" @click.native="infoComplete">
    <span>
      {{title}}
      <span style="color:#3085fc;">点击前往</span>
    </span>
  </el-alert>
</template>

<script>
export default {
  name: "SubAlert",
  props: ["title"],
  data: () => ({}),
  methods: {
    infoComplete() {
      this.$router.push({
        path: "/sysAccInfo",
        query: { fromType: "complete" },
      });
      var obj = { path: "/sysAccInfo", id: 107 };
      this.$emit("toAccInfo", obj);
    },
  },
};
</script>

<style scoped lang="scss">
.subAlert {
  margin-bottom: 20px;
  cursor: pointer;
}
::v-deep.el-alert .el-alert__icon {
  font-size: 16px !important;
}
::v-deep.el-alert .el-alert__description {
  font-size: 12px;
  margin: 0;
}
</style>
