<template>
  <div v-loading="contentLoading" class="main">
    <el-drawer class="box" :before-close="handleClose" :destroy-on-close="true"  :visible.sync="contentShow"  size="123%" :title="title" v-if="!query.is_newPage">
      <div class="changeType">
        <div v-for="(item, index) in typeList" v-show="item.show" :key="index" :class="{ active: type == item.msg ? true : false }" @click="changeType(item.msg,item.name)">
          {{ item.name }}
        </div>
      </div>
      <div class="current_hotel" v-if="query.parentName">{{query.parentName +"（"+query.hotel+"）" }}</div>
      <div class="current_hotel" v-if="!query.parentName">{{query.hotel}}</div>
      <div v-if="type==='launcher'">
        <launcherSet :query="query" />
      </div>
      <div v-if="type==='app'">
        <Application :query="query"></Application>
      </div>
      <div v-if="type==='basic'">
        <Parameter :query="query"></Parameter>
      </div>
      <div v-if="type==='version'">
        <versionControl :query="query"></versionControl>
      </div>
      <div v-if="type==='log'">
        <OperationLog :query="query"></OperationLog>
      </div>

    </el-drawer>
    <div v-if="query.is_newPage">
      <!-- <el-tabs v-model="type">
        <el-tab-pane v-for="(item, index) in typeList" v-show="item.show" :key="index" :class="{ active: type == item.msg ? true : false }" :label="item.name " :name="item.msg"></el-tab-pane>
      </el-tabs>
      <div class="current_hotel" v-if="query.parentName">{{query.parentName +"（"+query.hotel+"）" }}</div>
      <div class="current_hotel" v-if="!query.parentName">{{query.hotel}}</div> -->
      <div v-if="type==='launcher'">
        <launcherSet :query="query" />
      </div>
      <div v-if="type==='app'">
        <Application :query="query"></Application>
      </div>
      <div v-if="type==='basic'">
        <Parameter :query="query"></Parameter>
      </div>
      <div v-if="type==='version'">
        <versionControl :query="query"></versionControl>
      </div>
      <div v-if="type==='log'">
        <OperationLog :query="query"></OperationLog>
      </div>
    </div>
  </div>
</template>
<script>
import Application from "./application/index.vue";
import Parameter from "./infrastructure/Parameter.vue";
import OperationLog from "./operationLog/index.vue";
import launcherSet from "./components/system/launcherSet.vue";
import versionControl from "./components/system/versionControl.vue";
export default {
  name: "systemDrawer",
  components: {
    Application,
    launcherSet,
    versionControl,
    Parameter,
    OperationLog,
  },
  data: () => ({
    funArr: [],
    contentLoading: false,
    type: "launcher",
    title: "launcher",
    contentShow: false,
    query: {},
    typeList: [
      {
        msg: "launcher",
        name: "launcher配置",
        show: true,
      },
      {
        msg: "app",
        name: "应用配置",
        show: true,
      },
      {
        msg: "basic",
        name: "基础设置",
        show: true,
      },
      {
        msg: "version",
        name: "版本控制",
        show: true,
      },
      {
        msg: "log",
        name: "操作日志",
        show: true,
      },
    ],
    auth: {},
    token: "",
    funArr: [],
    hotelContract: "",
  }),

  methods: {
    changeType(msg, name) {
      this.type = msg;
      this.title = name;
    },
    open(query, arr,not_only) {
      this.type = "launcher"; 
    this.funArr = arr;
      this.typeList.forEach((i) => {
        if (this.funArr.indexOf(i.name)!=-1) {
          i.show = true;
        } else {
          i.show = false;
        }
      });
      this.$emit('syslist',this.typeList)
      this.query = {...query,not_only:not_only};
      console.log(this.query);
      
      this.contentShow = true;

    },
    // 开关
    handleClose(done) {
      this.contentShow = false;
    },
    showFunModel() {
      return function (val) {
        console.log(val);
      
        console.log(this.funArr.indexOf(val));
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body::-webkit-scrollbar {
  //   width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}
.main {
  height: 100%;
}
.el-table {
  margin-top: 15px;
}
.changeType {
  position: absolute;
  left: -120px;
  top: 0px;
  width: 120px;

  border: 1px solid #e2e2e2;
  z-index: 999;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}

.changeType div {
  width: 120px;
  height: 55px;
  font-size: 14px;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
}

.active {
  background-color: #f1f9ff;
  color: #0078ff;
}

::v-deep .el-drawer__body {
  overflow-y: scroll;
  padding: 15px 20px 15px !important;
}
.current_hotel {
  font-size: 18px;
  margin: 20px 0;
}
</style>
