<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">系统设置</span>
        <span slot="third">账户信息</span>
      </top-bread>
    </div>
    <el-card class="box-card" v-loading="!showData">
      <el-tabs v-model="tab" @tab-click="handleClick">
        <el-tab-pane label="账户信息" name="basic" v-if="showFunModel('账户信息')">
          <coop-tab-basic ref="basic" v-if="showData" :data="allData.info" :readonly="true" :allData="allData" :sysaccinfo="true"></coop-tab-basic>
        </el-tab-pane>
        <el-tab-pane label="认证中心" name="identify" v-if="showFunModel('认证中心')">
          <coop-tab-finance v-if="showData" :bankData="allData.bank" :contractData="allData.contract" :infoData="allData.info" :test="allData.info" :readonly="isReadOnly" :allData="allData" showEditInfoTips="true" ref="finance" @getDetail="getDetail"></coop-tab-finance>
        </el-tab-pane>
        <!--<el-tab-pane label="目标及分成比例" name="rate" v-if="auth.type!=='htrip'" >-->
        <el-tab-pane label="目标及分成比例" name="rate" v-if="showFunModel('目标及分成比例')">
          <sys-tab-rate v-if="showData" :partnerId="partnerId" @click="click_rate" :click="clickRate"></sys-tab-rate>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import qs from "querystring";
import commonMeth from "../../api/method";
export default {
  name: "finance",
  data: () => ({
    clickRate: false,
    partnerId: "",
    bread: {
      level: 3,
      firstPath: { path: "/infdexShow" },
    },
    tab: "basic",
    showData: false,
    allData: {},
    dateRange: "",
    token: "",
    auth: "",
    isReadOnly: true,
    funArr: [],
    allFunData: [],
  }),
  methods: {
    getPartnerId() {
      this.showData = false;
      this.$http
        .get(this.global.getIndexHeader, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.partnerId = res.data.data.partnerId;
            this.showData = true;
          }
        });
    },
    handleClick(tab, event) {},
    click_rate() {
      this.clickRate = true;
    },
    getDetail() {
      this.showData = false;
      this.$http
        .get(this.global.getAccountDetailByCurrentAdmin, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.allData = res.data.data;
            // this.$nextTick(() => {
            //   this.$refs.basic.getDetail(this.allData);
            //   this.$refs.finance.getData(this.allData);
            // });
             var isShowSave = res.data.data.isShowSave;
            this.showData = true;
            if (this.auth.ca_review_status !== 1) {
              //资质审核没有通过
              if (!isShowSave) {
                //提交了申请，没有审核
                this.isReadOnly = true;
              } else {
                this.isReadOnly = false;
              }
            } else {
              //资质没有通过，但是isShowSave 为 false
              this.isReadOnly = true;
            }
            this.showData = true;
          }
          console.log(this.isReadOnly);
        });
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getPartnerId();
    var query = this.$route.query;
    var auth = JSON.parse(sessionStorage.getItem("auth"));
    this.auth = auth;
    if (query.fromType === "complete") {
      this.tab = "identify";
    }
    this.$nextTick(function () {
      this.getDetail();
    });
    commonMeth.getTreeData().then((res) => {
      this.funArr = res.funArr;
    });
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
};
</script>

<style scoped lang="scss">
</style>
