<template>
  <div style="height:100%;width:100%">
    <div :id="id" ref="chart1" style="width:100%;height:100%" class=" echarts chart chartBox"></div>
  </div>
</template>
<script>
import echarts from "echarts";

export default {
  name: "barCard",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  watch: {},
  data() {
    return {
      userInfo: {},
      hotel_contract: null,
      date: [],
      yAxis: {},
      xAxisList: [],
      seriesList: [],
      echartsObj: {},
    };
  },
  methods: {
    // 获取时间区间
    getCurrentTime(type) {
      this.type = type;
      // let arr = this.publicMeth.getdefaultTime(type);
      // this.date = arr;
      this.getDataList();
    },
    // 获取图表数据
    getDataList() {
      if (this.id == "bar1") {
        this.xAxisList = [
          "02:00",
          "04:00",
          "06:00",
          "08:00",
          "10:00",
          "12:00",
          "14:00",
          "16:00",
          "18:00",
          "20:00",
          "22:00",
          "24:00",
        ];
        this.seriesList = [6156, 4520,2015,  7645, 9652, 13264, 12154, 14154, 16154, 15154,13154,9523];
      } else {
        this.xAxisList = [
          "2021-10-20",
          "2021-10-21",
          "2021-10-22",
          "2021-10-23",
          "2021-10-24",
        ];
        this.seriesList = [10, 20, 2, 15, 6];
      }

      this.getYaxis("", this.seriesList);
      return;
      let json = {
        hotel_contract: this.hotel_contract,
        start_date: this.date[0],
        end_date: this.date[1],
      };
      getBroadcastTimes(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (
              res.data.data &&
              res.data.data.rows &&
              res.data.data.rows.length > 0
            ) {
              let arr = res.data.data.rows;
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = arr.map((i) => {
                return i.dateValue;
              });
            } else {
              this.xAxisList = [];
              this.seriesList = [];
            }
            this.getYaxis("", this.seriesList);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },

    // 图表渲染
    chartRender() {
      this.echartsObj = echarts.init(this.$refs.chart1);

      var option = {
        tooltip: {
          trigger: "axis",
          padding: 10,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: 40,
          bottom: 30,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          zlevel: 15,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,.2)",
            },
          },
          axisLabel: {
            color: "#769bde",
            fontSize: "13px",
            margin: 15,
          },
          splitLine: {
            show: false,
          },
          data: this.xAxisList,
        },
        yAxis: this.yAxis,

        series: [
          {
            data: this.seriesList,
            type: "bar",
            barWidth: "20%",
            label: {
              show: true,
              color: "#fff",
              fontSize: "14px",
              position: "top",
            },
            lineStyle: {
              color: "#00ffff",
              width: 1,
            },
            itemStyle: {
              color: (param) => {
                if (param.dataIndex % 2 == 0) {
                  return {
                    type: "linear",
                    y: 0,
                    x: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(0,209,255, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(141,212,253, 0)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  };
                } else {
                  return {
                    type: "linear",
                    y: 0,
                    x: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(122,152,255, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(0,107,255, 0.0)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  };
                }
              },
            },
          },
        ],
      };
      this.echartsObj.setOption(option);
      this.watchSize();
    },
    // y轴配置
    getYaxis(unit, list) {
      let arr = [...list];
      arr = arr.sort((a, b) => a - b);
      var minint = Math.floor(arr[0] / 10);
      var minval = minint * 10;
      var maxint = Math.ceil(arr[arr.length - 1] / 9.5);
      var maxval = maxint * 10;
      this.yAxis = {
        type: "value",
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: `{value}${unit}`,
          color: "#769bde",
          fontSize: "13px",
        },

        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "rgba(255,255,255,.3)",
          },
        },

        min: minval,
        max: maxval,
        splitNumber: 5,
        interval: (maxval - minval) / 5,
      };
      this.chartRender();
    },
    // 监听界面
    watchSize() {
      this.echartsObj.resize();
    },
  },
  mounted() {
    this.getCurrentTime("seven");
    window.addEventListener("resize", this.watchSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.watchSize);
  },
};
</script>

<style scoped lang="scss">
#chart,
.chart,
.chart2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
