<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">数据中心</span>
                <span slot="third"> 收益统计</span>
            </top-bread>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="incomeTab" style="margin-bottom:20px;">
            <el-tab-pane label="影视单点收益预估" name="movie" >
                <inTabMovie v-if="activeName==='movie'" :tabType="movie"></inTabMovie>
            </el-tab-pane>
            <el-tab-pane label="影视天卡收益预估" name="day">
                <inTabMovie v-if="activeName==='day'" :tabType="day"></inTabMovie>
            </el-tab-pane>
            <el-tab-pane label="历史收益" name="history" v-if="showFunModel('历史收益')">
                <historySta  :tabType="day"></historySta>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import echarts from 'echarts'
    import commonMeth from '../../api/method'
    export default {
        name: "idincomeSta",
        props:['test'],
        data: () => ({
            movie:'单点',
            day:'天卡',
            bread:{
                level:3,
            },
            hotelList:[
            ],
            queryInfo:{
                query:'',
                pageNum:10,
                pageSize:5,   //当前每页显示多少条
                total:''
            },
            topAttr:{
                KA:'',
                KAOption:[
                    {label:'KA1',value:'1'},
                    {label:'KA2',value:'2'},
                    {label:'KA3',value:'3'},
                ],
                province:'',
                provinceOption:[
                    {label:'province1',value:'1'},
                    {label:'province2',value:'2'},
                    {label:'province3',value:'3'},
                ],
                city:'',
                cityOption:[
                    {label:'city1',value:'1'},
                    {label:'city2',value:'2'},
                    {label:'city3',value:'3'},
                ],
                country:'',
                countryOption:[
                    {label:'country1',value:'1'},
                    {label:'country2',value:'2'},
                    {label:'country3',value:'3'},
                ],
                terminal:'',
                terminalOption:[
                    {label:'country1',value:'1'},
                    {label:'country2',value:'2'},
                    {label:'country3',value:'3'},
                ],
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value1: '',
            value2: '',
            activeName:'',
            tabName:'',
            token:'',
            auth:{},
            funArr:[],
            allData:{}
        }),

        methods:{
            getOrder(){
                if (this.$route.query.order_type==='channel_card'){
                    this.activeName='day'
                }else if (this.$route.query.order_type==='channel_one'){
                    this.activeName='movie'
                }else {
                    this.activeName='movie'
                }
            },
            handleClick(tab, event) {
                this.tabName=tab.name;
            },
        },
        created(){
            this.queryInfo.total=this.hotelList.length;
            this.getOrder();
        },
        mounted(){
            this.getOrder();
            this.token=sessionStorage.getItem('token');
            this.auth=JSON.parse(sessionStorage.getItem('auth'));
            commonMeth.getTreeData().then((res)=>{
                this.funArr=res.funArr;
                    console.log(this.funArr);
            });
        },
        computed:{
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                    
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .hotelBrand{
        width:100%;
    }
    .rowBox{
        margin-top:20px;
    }
    .vertical{
        box-sizing: border-box;
        .grid-content{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            border:1px solid #ddd;

            .numTitle{
                margin-bottom:20px;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
    }

    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .bg-purple {
        /*background: #d3dce6;*/
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        /*min-height: 36px;*/
        padding:20px 20px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    /*.lineContent{*/
    /*margin-top:20px;*/
    /*}*/
    /*.chartBox{*/
    /*height:500px;*/
    /*margin-bottom:20px!important;*/
    /*overflow:hidden;*/
    /*}*/

    /*横排两个*/
    .rowBox.horizontal {
        .grid-content {
            height:100%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            padding:15px 20px;
            border:1px solid #ddd;
            .leftBox{
                display:flex;
                align-items: center;
            }
            .numTitle{
                width:150px;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
    }

</style>
