<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">收益统计</span>
                <span slot="third">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <back @backMeth="backMeth" class="backBtn"></back>
            <table-list :table="table" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
        </el-card>
    </div>
</template>

<script>
    import qs from 'querystring'
    import Bus from "../../api/Bus";
    export default {
        name: "MovieDetail",
        data:()=>({
            token:'',
            query:{},
            bread:{
                level:3,
                firstPath:{path: '/incomeSta'},
            },
            table: {
                name: 'incomeStaMovieDetail',
                tableHeader: [
                    {prop: 'datetime_text', label: '时间', width: ''},
                    {prop: 'hotel_name', label: '酒店名称', width: '400px'},
                    {prop: 'active_terminal_num', label: '激活终端数量（台）', width: ''},
                    {prop: 'day_active_rate', label: '日活率（%）', width: ''},
                    {prop: 'watch_film_num', label: '观影订单数量（个）', width: ''},
                    {prop: 'watch_film_income', label: '观影订单收益（元）', width: ''},
                ],
                tableList: [],
                page: 1,
                limit: 5,   //当前每页显示多少条
                total: 0,
                fromUrl: 'incomeStaMovieDetail',
                option: true,
                loading: true,
                pageShow: true,
            },
        }),
        methods:{
            tableMeth() {
                this.table.loading = true;
                console.log(this.query)
                var {startTime,endTime,incomeSource}=this.query;
                var params = {
                    token: this.token,
                    hotelContract:this.query.hotel_contract,
                    incomeSource
                };

                this.$http.post(this.global.getMovieIncomeInfoByContract, qs.stringify(params)).then(res => {
                    var code = res.data.code;
                    if (code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading = false;
                }).catch(() => {
                    this.table.loading = false;
                })
            },
            backMeth() {
                this.$router.push({
                    path: `./incomesta` ,
                    query: {
                        order_type:this.query.order_goods_type,
                    }
                });
            },
        },
        mounted(){
            this.query=this.$route.query;
            this.token = sessionStorage.getItem('token');
            this.tableMeth();
        },
        created() {
            // alert('测试');
            // Bus.$on("getIncomeStaCondition", (obj) => {
            //     console.log('得到条件',obj)
            // });
        },
    }
</script>

<style scoped>
    .backBtn{
        margin-bottom:10px;
    }

</style>
