<!-- 批量设置 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">内容管理</span>
        <span slot="third">批量配置</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <el-tabs v-model="activeTag" @tab-click="handleClick" v-if="$route.path=='/MulSetting'">
        <el-tab-pane label="参数配置列表" name="SqlParam">
        </el-tab-pane>
        <el-tab-pane label="服务配置列表" name="SqlService">
        </el-tab-pane>
        <el-tab-pane label="下载配置列表" name="SqlDownload">
        </el-tab-pane>
      </el-tabs>
      <paramPage ref="paramPage" :pageType='activeTag'></paramPage>
    </el-card>
  </div>
</template>
<script>
import MulOper from "@/components/operation/MulSetting/mulOper";
import paramPage from "@/components/operation/MulSetting/paramPage";
export default {
  name: "mulSetting",
  components: { MulOper, paramPage },
  data() {
    return {
      bread: {
        level: 3,
        // firstPath: { path: "/indexShow" },
        // thirdPath: { path: "/hotelManAll" },
        // fourthPath: { path: "/hotelManAll" },
      },
      activeTag: "SqlParam",
    };
  },
  methods: {
    handleClick(e) {
      this.$refs.paramPage.pageType =  e.name;
      this.$refs.paramPage.reset();
    },
  },
  mounted() { 
  },
};
</script>
<style lang="scss" scoped></style>
