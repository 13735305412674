<template>
  <!--运营酒店列表 tab-->
  <div>
    <no-data v-if="noData"><span slot="tips">暂不支持</span></no-data>
    <div v-if="!noData" class="mainBox hideShadeBox">
      <div class="btnBox">
        <el-button class="largeBtn" type="primary" @click="publishDialogMeth('publish','发布')">发布</el-button>
        <el-button class="largeBtn" type="primary" @click="publishListMeth('publish','发布')">发布列表</el-button>
        <!--<div class="numShow">-->
        <!--已更新房间数 ：<span class="blueText" @click="showRoomDialog(true)">{{roomLengthCom}}</span>-->
        <!--</div>-->
      </div>
      <!-- <edit-btn class="editBtnStyle" ></edit-btn> -->
      <div>
        <el-tabs v-model="activeName"  type="card" class="tabStyle" @tab-click="closeModal">
          <el-tab-pane v-for="(item,key) in tabData" :key="item.id" :label="item.title" >
            <div class="contentBox">
              <div class="hotelContentBox">
                <wel-page v-if="item.title==='欢迎页模块'" :attrName="key" :data="item" :hotelContract="hotelContract"></wel-page>
                <home-page :modelShow="modelShow" :hotelContract="hotelContract" v-if="item.title==='主页'" :data="item" :attrName="key" :optBtnList="btnList"></home-page>
                <hotel-service :modelShow="modelShow" v-if="item.title==='服务'||item.title==='酒店服务'" :hotelContract="hotelContract" :attrName="key" :logoUrl="logoUrl" :data="item" :optBtnList="btnList"></hotel-service>
                <hotel-service-list :modelShow="modelShow" v-if="item.title==='酒店服务'" :attrName="key" :logoUrl="logoUrl" :data="item" :hotelContract="hotelContract" :optBtnList="btnList"></hotel-service-list>
                <hotel-service :modelShow="modelShow" v-if="item.title==='顾客体验'" :hotelContract="hotelContract" :attrName="key" :logoUrl="logoUrl" :data="item" :optBtnList="btnList"></hotel-service>
                <hotel-service-list :modelShow="modelShow" v-if="item.title==='顾客体验'" :attrName="key" :logoUrl="logoUrl" :data="item" :hotelContract="hotelContract" :optBtnList="btnList"></hotel-service-list>
                <jump-page v-if="item.title==='跳转大图'" :hotelContract="hotelContract" :attrName="key" :data="item"></jump-page>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <choice-room-dialog ref="choiceRoomDialog" :hotelContract="hotelContract" @confirmMeth="confirmMeth" :userNameProp="userNameProp">
    </choice-room-dialog>
    <choose-room></choose-room>
    <publish-list ref="publishList"></publish-list>
  </div>
</template>
<script>
import editBtn from "./common/editBtn";
import welPage from "./visualWelPage/welPage";
import homePage from "./home/homePage";
import hotelService from "./hotelService/hotelService";
import hotelServiceList from "./hotelService/hotelserviceList";
import jumpPage from "./jumpPage/jumpPage";
import qs from "querystring";
import bus from "./bus.js";
export default {
  name: "MaterialEdit",
  props: ["hotelContract", "hotelTemplateType"],
  components: {
    editBtn,
    welPage,
    homePage,
    hotelService,
    hotelServiceList,
    jumpPage,
  },
  data: () => ({
    logoUrl: "",
    activeName: "",
    userNameProp: "",
    token: "",
    noData: false,
    tabData: {},
    btnList: {},
    roomList: [],
    template_id: "",
    boxSize: {
      boxWidth: "",
      boxHeight: "",
    },
    modelShow:false,
  }),
  methods: {
    publishDialogMeth() {
      this.$refs.choiceRoomDialog.dialogShowMeth(true);
    },
     publishListMeth(){
      this.$refs.publishList.showDrawer(true);
      this.$nextTick(function(){
           this.$refs.publishList.getHotelConfigInfo(this.hotelContract);
      })
    },
    closeModal(){
      this.modelShow = !this.modelShow;
    },
    confirmMeth(val) {
      const json = this.$store.state.visualContent;
      json.info = val;
      console.log("对象数据",json);
      for (let key in json) {
        json[key] = qs.stringify(json[key]);
      }
      console.log("里对象转码模式",json);
      var str = qs.stringify(json);
       console.log("对象转码模式",str);
      var param = qs.stringify({
        token: this.token,
        hotelContract: this.hotelContract,
        data: str,
      });
      this.$http
        .post(this.global.uploadHotelVisualData, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("发布成功");
              this.$refs.choiceRoomDialog.dialogShowMeth(false);
          } else {
            this.$message.error("发布失败");
          }
          this.tabTitle = show;
        })
        .catch(() => {});
    },
    getHotelConfigInfo(hotelContract,token) {
      var param = qs.stringify({
        token: token,
        hotelContract:hotelContract,
      });
      this.$http
        .post(this.global.getHotelCurrentVisualInfo, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.tabData = res.data.data.tab;
            this.logoUrl = this.tabData.home.modelLogo;
            this.btnList = res.data.data.config;
            this.roomList = res.data.data.roomList;
            this.template_id = res.data.data.template_id;
              this.noData = false;
          } else if (res.data.msg === "暂不支持") {
            this.noData = true;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          // debugger;
        });
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
 
  },
  watch: {
    hotelContract: {
      handler: function (newVal) {
        this.hotelContract=newVal;
         this.token = sessionStorage.getItem("token");
        this.getHotelConfigInfo(newVal,this.token);
      },
      deep: true,
      immediate: true,
    },
    hotelTemplateType: {
      handler: function (newVal) {},
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  created() {},
};
</script>

<style scoped >
>>> .el-tabs__item {
  text-align: center;
  border: 1px solid #eee !important;
}
>>> .el-tabs__item:first-child {
  border-left: 1px solid #eee !important;
}
>>> .el-tabs__header {
  border: none;
}
>>> .el-tabs__nav-scroll {
  border: none;
  margin-left: 2px;
}
>>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
.el-card {
  overflow: visible;
}
.contentBox {
  width: 100%;
  max-width: 1920px;
  padding-bottom: 56.25%;
  position: relative;
}
.hotelContentBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 1080px;
  border: none;
  transition: all 1s ease;
  background: #eee;
  box-sizing: content-box;
}
.hotelContentBox:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
  box-sizing: content-box;
  z-index: -1;
}
.hotelContentBox:hover:after {
  border: 1px dashed blue;
  -moz-transition: 1px dashed blue;
  -webkit-transition: 1px dashed blue;
  -o-transition: 1px dashed blue;
}
.editBtnStyle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 15%;
  height: 5%;
}
.btnBox {
  margin-bottom: 15px;
}
</style>
