<template>
  <div style="padding:0 15px">
    <!-- 操作栏 -->
    <div class="btnRow">
      <el-input style="width:300px" placeholder="图片名称" v-model="picName" :clearable="true" class="input-with-select fullSelect "></el-input>
      <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
      <el-button type="primary" class="largeBtn" @click="addBasicBox">添 加</el-button>
      <el-button type="danger" class="largeBtn" @click="del('2')">删 除</el-button>
       <el-button type="danger" class="largeBtn" @click="del('1')" v-if='query.not_only&&query.hotel_alias=="默认"'>同步删除</el-button>
    </div>
    <!-- 操作栏 -->
    <!-- 表格 -->
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <!-- 表格 -->
    <!-- 添加表单 -->
    <el-dialog title="添加图片" :visible.sync="dialogVisible" width="60%" :before-close="handleClose" :append-to-body="true">
      <el-form ref="basicForm" :rules="rules" :model="basicForm" label-width="80px">
        <el-form-item label="图片名称" prop='name1'>
          <el-input v-model="basicForm.name1" @change="nameChange"></el-input>
        </el-form-item>
        <el-form-item label="图片上传">
          <el-button :disabled="basicForm.name1==''" type="primary" class="largeBtn" @click="openUPload('1')">中文图片</el-button>
          <el-button :disabled="basicForm.name1==''" type="primary" class="largeBtn" @click="openUPload('2')">英文图片</el-button>
          <el-button :disabled="basicForm.name1==''" type="primary" class="largeBtn" @click="openUPload('3')">日文图片</el-button>
          <el-button :disabled="basicForm.name1==''" type="primary" class="largeBtn" @click="openUPload('4')">韩文图片</el-button>
          <el-button :disabled="basicForm.name1==''" type="primary" class="largeBtn" @click="openUPload('5')">法文图片</el-button>
          <el-button :disabled="basicForm.name1==''" type="primary" class="largeBtn" @click="openUPload('6')">西班牙文图片</el-button>
        </el-form-item>
        <el-form-item label="属性">
          <span>图文</span>
        </el-form-item>
        <el-form-item label="上级名称">
          <span>主菜单</span>
        </el-form-item>
        <el-form-item label="排序" prop='morder'>
          <el-input v-model="basicForm.morder"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addBasic('basicForm','1')" v-if='query.not_only&&query.hotel_alias=="默认"'>同步确认</el-button>
        <el-button type="primary" @click="addBasic('basicForm','2')">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加表单 -->
    <!-- 添加表单 -->
    <el-dialog title="修改图片" :visible.sync="editVisible" width="60%" :before-close="editClose" :append-to-body="true">
      <el-form ref="basicForm" :model="editForm" label-width="80px">
        <el-form-item label="标题名称">
          <el-input :readonly="true" v-model="editForm.content"></el-input>
        </el-form-item>
        <el-form-item label="图片上传">
          <uploadOnePic @sendmd5="getMd5" v-model="editForm.picadd" acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片"></uploadOnePic>
        </el-form-item>
        <el-form-item label="语言">
          <el-input :readonly="true" v-model="editForm.language"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editBasic('1')" v-if='query.not_only&&query.hotel_alias=="默认"'>同步确认</el-button>
        <el-button type="primary" @click="editBasic('2')">确 定</el-button>
        <el-button @click="editClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加表单 -->
    <!-- 批量添加图片 -->
    <uploadMorePic ref="uploadMorePic" @defaultData="getPicList" />
    <!-- 批量添加图片 -->
  </div>
</template>
<script>
import {
  getMenuAttrList,
  getBasicPicture,
  addMenu,
  delallpic,
  editPicture,
} from "../../api/launcher";
import uploadMorePic from "../common/uploadMorePic.vue";
import uploadOnePic from "../common/uploadOnePic.vue";
export default {
  name: "basicList",
  props: ["query"],
  components: {
    uploadMorePic,
    uploadOnePic,
  },
  data() {
    return {
      picName: "",
      // 表格数据信息
      tableData: {
        tableList: [],
        checked: true,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "60",
            type: "text",
          },
          {
            label: "标题",
            prop: "content",
            minWidth: "80",
            type: "text",
          },
          {
            label: "语言",
            prop: "language",
            minWidth: "80",
            type: "text",
          },
          {
            label: "缩略图",
            prop: "picadd",
            minWidth: "80",
            type: "pic",
          },
          {
            label: "操作",
            minWidth: "60",
            type: "button",
            prop: "num",
            // 详情
            edit: (item) => {
              Object.assign(this.editForm, item);
              this.editVisible = true;
            },
          },
        ],
      },
      // 表单字段验证
      rules: {
        name1: [{ required: true, message: "请输入图片名称", trigger: "blur" }],
        // morder: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      // 表单字段
      basicForm: {
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        name5: "",
        name6: "",
        attr: "3",
        parentname: "0",
        morder: "",
        id: "",
        picList_1: "",
        picList_2: "",
        picList_3: "",
        picList_4: "",
        picList_5: "",
        picList_6: "",
      },
      fileList: [],
      dialogVisible: false,
      // 已选项
      checkList: [],
      editVisible: false,
      editForm: {
        content: "",
        picadd: "",
        language: "",
        id: "",
        md5: "",
      },
    };
  },
  watch: {
    // 酒店数据监听
    query: {
      handler: function (newVal) {
        this.query = newVal;
        console.log(this.query,'this.querythis.querythis.query');
        
        this.getTableData(this.query.hotelName);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 修改
    editBasic(type) {
      if (this.editForm.picadd == "") {
        this.$message.warning("请上传素材进行修改操作");
      } else {
        this.$confirm("是否确认删除选中项？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let json = {
            id: this.editForm.id,
            picadd: this.editForm.picadd,
            md5: this.editForm.md5,
            database: this.query.hotelName,
            synStation:type,
          };
          editPicture(json)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("编辑成功");
                this.getTableData(
                  this.query.hotelName,
                  this.tableData.limit,
                  this.tableData.page
                );
                this.editClose();
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.warning("编辑失败");
            });
        });
      }
    },
    // 关闭修改对话
    editClose() {
      this.editVisible = false;
      this.editForm = {
        content: "",
        picadd: "",
        language: "",
        id: "",
        md5: "",
      };
    },
    // 获取MD5
    getMd5(md5) {
      this.editForm.md5 = md5;
    },
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      this.getTableData(
        this.query.hotelName,
        this.tableData.limit,
        this.tableData.page
      );
    },
    // 名称输入同步
    nameChange() {
      this.basicForm.name2 = this.basicForm.name1;
      this.basicForm.name3 = this.basicForm.name1;
      this.basicForm.name4 = this.basicForm.name1;
      this.basicForm.name5 = this.basicForm.name1;
      this.basicForm.name6 = this.basicForm.name1;
    },
    // 字段拼接
    getString(list) {
      let str = "";
      for (let i = 0; i < this.checkList.length; i++) {
        str += this.checkList[i] + ",";
      }
      return str;
    },
    // 删除项
    del(type) {
      if (this.checkList.length == 0) {
        this.$message.warning("请至少选择一项在进行删除操作");
        return false;
      }
      // 确认
      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 字段
          const json = {
            database: this.query.hotelName,
            arr: this.getString(this.checkList),
            synStation:type,
          };
          delallpic(json)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("删除成功");
                this.getTableData(
                  this.query.hotelName,
                  this.tableData.limit,
                  this.tableData.page
                );
                this.handleClose();
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.warning("删除失败");
            });
        })
        .catch(() => {});
    },
    // 添加项
    addBasic(formName,type) {
      // 表单字段验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 上传数据验证
          if (
            this.basicForm.picList_1 == "" &&
            this.basicForm.picList_2 == "" &&
            this.basicForm.picList_3 == "" &&
            this.basicForm.picList_4 == "" &&
            this.basicForm.picList_5 == "" &&
            this.basicForm.picList_6 == ""
          ) {
            this.$message.warning("请上传图片素材");
          } else {
            // 确认
            this.$confirm("是否确认添加？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                // 参数
                const obj = {
                  name1: this.basicForm.name1,
                  name2: this.basicForm.name2,
                  name3: this.basicForm.name3,
                  name4: this.basicForm.name4,
                  name5: this.basicForm.name5,
                  name6: this.basicForm.name6,
                  attr: "3",
                  parentname: "0",
                  morder: this.basicForm.morder,
                  id: "",
                  picList_1: this.basicForm.picList_1,
                  picList_2: this.basicForm.picList_2,
                  picList_3: this.basicForm.picList_3,
                  picList_4: this.basicForm.picList_4,
                  picList_5: this.basicForm.picList_5,
                  picList_6: this.basicForm.picList_6,
                };
                const json = {
                  datas: JSON.stringify(obj),
                  database: this.query.hotelName,
                  synStation:type,
                };
                addMenu(json)
                  .then((res) => {
                    if (res.data.code == 100000) {
                      this.$message.success("添加成功");
                      this.getTableData(
                        this.query.hotelName,
                        this.tableData.limit,
                        this.tableData.page
                      );
                      this.handleClose();
                    } else {
                      this.$message.warning(res.data.msg);
                    }
                  })
                  .catch((err) => {
                    this.$message.warning("添加失败");
                  });
              })
              .catch((err) => {});
          }
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
    // 获取上传图片信息并保存
    getPicList(val, num) {
      let arr = val.map((i) => {
        return i.url;
      });
      if (num == "1") {
        this.basicForm.picList_1 = arr.join(",");
      } else if (num == "2") {
        this.basicForm.picList_2 = arr.join(",");
      } else if (num == "3") {
        this.basicForm.picList_3 = arr.join(",");
      } else if (num == "4") {
        this.basicForm.picList_4 = arr.join(",");
      } else if (num == "5") {
        this.basicForm.picList_5 = arr.join(",");
      } else if (num == "6") {
        this.basicForm.picList_6 = arr.join(",");
      }
    },
    // 展开上传界面 并显示对应内容
    openUPload(num) {
      if (num == "1") {
        this.$refs.uploadMorePic.open(this.basicForm.picList_1, num);
      } else if (num == "2") {
        this.$refs.uploadMorePic.open(this.basicForm.picList_2, num);
      } else if (num == "3") {
        this.$refs.uploadMorePic.open(this.basicForm.picList_3, num);
      } else if (num == "4") {
        this.$refs.uploadMorePic.open(this.basicForm.picList_4, num);
      } else if (num == "5") {
        this.$refs.uploadMorePic.open(this.basicForm.picList_5, num);
      } else if (num == "6") {
        this.$refs.uploadMorePic.open(this.basicForm.picList_6, num);
      }
    },
    // 打开添加界面
    addBasicBox() {
      this.dialogVisible = true;
    },
    // 关闭界面
    handleClose() {
      this.dialogVisible = false;
      this.basicForm = {
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        name5: "",
        name6: "",
        attr: "3",
        parentname: "0",
        morder: "",
        id: "",
        picList_1: "",
        picList_2: "",
        picList_3: "",
        picList_4: "",
        picList_5: "",
        picList_6: "",
      };
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(this.query.hotelName, s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.query.hotelName, s, p);
    },
    // 选中项处理
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return i.id + "#" + i.type + "#" + i.mid;
      });
    },
    // 获取表格数据
    getTableData(database, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        database: database,
        name: this.picName,
        page: p ? p : 1,
        limit: s ? s : 10,
      };
      getBasicPicture(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data.items;
            // 字段显示处理
            this.tableData.tableList = this.tableData.tableList.map((i) => {
              if (i.ltype == 1) {
                i.language = "中文";
              } else if (i.ltype == 2) {
                i.language = "英文";
              } else if (i.ltype == 3) {
                i.language = "日文";
              } else if (i.ltype == 4) {
                i.language = "韩文";
              } else if (i.ltype == 10) {
                i.language = "法文";
              } else if (i.ltype == 11) {
                i.language = "西班牙文";
              }
              return i;
            });
            // 组件数据总数
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
</style>
