<template>
  <div style="padding:0 15px">

    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">内容监管</span>
        <span slot="third">监管酒店</span>
        <span slot="fourth">酒店详情</span>
      </top-bread>
    </div>
    <el-card class="box-card" style="position:relative">
      <el-button class="largeBtn rightBtn" @click="back">返 回</el-button>
      <div class="textRow">
        <span>酒店名称：{{query.hotel}}</span>
        <span> 合同有效时间：{{query.contract_start}} ~ {{query.contract_end}}</span>

        <!-- -->
      </div>
      <div class="textRow">
        <span>客房数：{{query.hotel_room}} </span>
        <span> 终端数：{{query.n_rooms_connected}} </span>

      </div>
      <div class="textRow">
        <span>直播信源：广电组播</span>
        <span> 播放方式：网络</span>

      </div>
      <div class="textRow">
        <div class="text">
          <div class="rowSpan">直播巡查状态：信号源：ok </div>
          <div class="rowSpan">频道数：ok </div>
          <div class="rowSpan">授权数：ok </div>
          <div class="rowSpan">信号源比对：ok</div>
        </div>

      </div>
      <div class="textRow">
        <div class="text">
          <div class="rowSpan">内容巡查状态：内容审查：ok</div>
          <div class="rowSpan">审查方式：在线 </div>
          <div class="rowSpan">时间：2023-05-23</div>
        </div>

      </div>
    </el-card>
    <el-card class="box-card ">
      <div class="title chartTitle blueTitle">直播流信息</div>
      <div class="showView showView1">
        <div class="tvListBox">

          <div :class="['tvItem','tvHeader']">
            <div>
              直播列表
            </div>
          </div>
          <div :class="['tvItem',activeIndex==index?'active':'']" v-for="(item,index) in tvList" :key="index" @click="changeTv(item,index)">
            <div>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="videoBox" id="video-mm">
          <video id="my-video" class="video-js vjs-default-skin" style='position: relative;width:100%;height:100%; overflow: hidden;'>
            <source :src="videoUrl" type="application/x-mpegURL" />
          </video>
        </div>
      </div>

    </el-card>
    <el-card class="box-card ">
      <div class="title chartTitle blueTitle">可视化信息</div>
      <div class="showView">
        <visiual-setting @defaultdata='getDefaultdata' ref="visiualSetting" :notPage='true' :isEdit="true"> </visiual-setting>
      </div>

    </el-card>

  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import { getLauncher } from "@/api/visual.js";
export default {
  mounted() {
    console.log(this.$route.query);
    this.query = this.$route.query;
    this.getPageData();

    this.player = videojs(
      "my-video",
      {
        muted: "muted",
      },
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
    this.player.play();

    this.timer = window.setInterval(this.loopPlayer, 30000);
  },

  beforeDestroy() {
    window.clearInterval(this.timer);
    this.timer = null;
    this.player.dispose();
    this.player = null;
  },
  data() {
    return {
      player: null,
      timer: null,
      bread: {
        level: 4,
      },
      query: {},
      tvList: [
        {
          name: "cctv-1",
          url: "http://webtest.travelink.com.cn:9000/share/tv/1/cctv1.m3u8",
        },
        {
          name: "cctv-2",
          url: "http://webtest.travelink.com.cn:9000/share/tv/2/cctv2.m3u8",
        },
        {
          name: "cctv-3",
          url: "http://webtest.travelink.com.cn:9000/share/tv/3/cctv3.m3u8",
        },
        {
          name: "cctv-4",
          url: "http://webtest.travelink.com.cn:9000/share/tv/4/cctv4.m3u8",
        },
        {
          name: "cctv-5",
          url: "http://webtest.travelink.com.cn:9000/share/tv/5/cctv5.m3u8",
        },
        {
          name: "北京卫视",
          url: "http://webtest.travelink.com.cn:9000/share/tv/beijing/beijing.m3u8",
        },
        {
          name: "浙江卫视",
          url: "http://webtest.travelink.com.cn:9000/share/tv/zhejiang/zhejiang.m3u8",
        },
        {
          name: "湖南卫视",
          url: "http://webtest.travelink.com.cn:9000/share/tv/hunan/hunan.m3u8",
        },
      ],
      activeIndex: 0,
      videoUrl: "http://webtest.travelink.com.cn:9000/share/tv/1/cctv1.m3u8",
    };
  },
  methods: {
    getPageData(template_id) {
      getLauncher({
        database: this.query.hotelName,
      })
        .then((res) => {
          console.log(999);
          if (res.data.code == 100000 && res.data.data) {
            this.$refs.visiualSetting.init(res.data.data);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("可视化数据网络加载故障");
        });
    },
    loopPlayer() {
      this.player.dispose();
      this.player = null;
      // 要执行的函数
      if (this.activeIndex == this.tvList.length - 1) {
        this.activeIndex = 0;
        this.videoUrl = this.tvList[this.activeIndex].url;
      } else {
        this.activeIndex++;
        this.videoUrl = this.tvList[this.activeIndex].url;
        console.log(this.videoUrl);
      }
      let videoBox = document.getElementById("video-mm");
      console.log(videoBox);
      videoBox.innerHTML = `<video id="my-video"  style='position: relative;width:100%;height:100%; overflow: hidden;' class="video-js vjs-default-skin"  >
       <source src="${this.videoUrl}" type="application/x-mpegURL" /></video>`;
      this.$nextTick(() => {
        this.player = videojs(
          "my-video",
          {
            muted: "muted",
          },
          function onPlayerReady() {
            console.log("onPlayerReady", this);
          }
        );
        this.player.play();
      });
    },
    back() {
      window.clearInterval(this.timer);
      this.timer = null;
      this.$router.go(-1);
    },
    loopList() {},
    changeTv(item, index) {
      this.player.pause();
      this.player.dispose();
      this.player = null;
      this.videoUrl = item.url;
      this.activeIndex = index;
      let videoBox = document.getElementById("video-mm");
      console.log(videoBox);
      videoBox.innerHTML = `<video id="my-video" style='position: relative;width:100%;height:100%; overflow: hidden;' class="video-js vjs-default-skin"  >
       <source src="${this.videoUrl}" type="application/x-mpegURL" /></video>`;
      this.player = videojs(
        "my-video",
        {
          muted: "muted",
        },
        function onPlayerReady() {
          console.log("onPlayerReady", this);
        }
      );
      this.player.play();
    },
    getDefaultdata() {},
  },
};
</script>

<style scoped lang='scss'>
.rightBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}
#my-video {
  position: relative;
}
.blueTitle {
  font-size: 18px;
}
.textRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    width: 50%;
    height: 30px;
    line-height: 30px;
    white-space: pre-wrap;
  }

  .text {
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .rowSpan {
      display: inline-block;
      width: 308px;
    }
  }
}
.showView {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showView1 {
  height: 648px;
  .tvListBox {
    height: 648px;
    width: 350px;
    border: 1px solid #ddd;
    overflow: auto;
    .tvHeader {
      background: #2d046e;
      color: #fff;
    }
    .tvItem {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
    }
    .active {
      background: #409eff;
      color: #fff;
    }
  }
  .videoBox {
    width: 1158px;
    height: 648px;
  }
}
</style>