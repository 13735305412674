<!--
 * @Descripttion: 上传文件封装
 * @version: V1.0
 * @Author: FKC
 * @Date: 2021.10.27
 * @LastEditors: FKC
 * @LastEditTime: 2021.10.27
 * @props:
    1.v-model        --String        绑定属性
    2.type           --String              上传文件类型(excel/ppt/word/pdf)
    3.text           --String              按钮文案
-->
<template>
  <div class="upload-file-box">
    <div v-show="file != '' || fileName != ''">
      {{ fileName == "" ? file.name : fileName }}
      <i class="el-icon-error cp" @click="clearFile"></i>
    </div>
    <div class="up-file-box cp" v-show="file == '' && fileName == ''">
      {{ text }}
      <input
        type="file"
        name=""
        @change="fileChange"
        style=""
        title=""
        class="upload-input"
        ref="uploadF"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "uploadFile",
  model: {
    prop: "fileValue",
    event: "uploadChange",
  },
  props: {
    text: {
      default: "上传资源",
    },
    type: {
      default: "",
    },
    fileName: {
      type: String,
      default: "",
    },
    // size: {
    //   default: 50,
    // },
  },
  data() {
    return {
      file: "",
    };
  },
  methods: {
    clearFile() {
      this.file = "";
      this.fileName = "";
      this.$refs.uploadF.value = "";
      this.$emit("uploadChange", this.file);
    },
    fileChange() {
      let inputDom = this.$refs.uploadF;
      let file = inputDom.files[0];
      let type = file.type.slice(file.type.indexOf("/") + 1);
      // 格式判断
      // 多种格式 type传数组
      if (Array.isArray(this.type)) {
        if (!this.type.includes(type)) {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      // 单种格式
      if (this.type == "txt") {
        if (type != "plain") {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      if (this.type == "mp3") {
        if (type != "mp3") {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      if (this.type == "mp4") {
        if (type != "mp4") {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      if (this.type == "excel") {
        if (type != "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      if (this.type == "ppt") {
        if (
          type !=
          "vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      if (this.type == "word") {
        if (
          type != "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          this.$message.error("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      if (this.type == "pdf") {
        if (type != "pdf") {
          this.errorMsg("文件格式错误");
          inputDom.value = "";
          return;
        }
      }
      // 大小size判断
      // if (file.size / 1000 > this.size) {
      //   this.errorMsg("文件过大");
      //   inputDom.value = "";
      //   return;
      // }
      this.file = file;
      this.$emit("uploadChange", this.file);
      this.$emit("change");
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.upload-file-box {
  display: inline-block;
  .up-file-box {
    height: 40px;
    line-height: 40px;
    color: white;
    background: #409eff;
    font-size: 14px;
    cursor: pointer;
    border-radius: 3px;
    padding: 0px 12px;
    position: relative;
    .upload-input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      cursor: pointer;
      z-index: 99;
    }
  }
}
</style>
