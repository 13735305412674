import request from "@/api/request";
import qs from "querystring";

//  批量配置热点信息 
export function setHotspot(param) {
    return request({
        url: `v2/batch_set/setHotspot`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  批量配置开机视频
export function setBootVideo(param) {
    return request({
        url: `v2/batch_set/setBootVideo`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取批量更新信息
export function updateLogs(param) {
    return request({
        url: `v2/batch_set/updateLogs`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取批量更新记录跟踪信息
export function updateLogsDetail(param) {
    return request({
        url: `v2/batch_set/updateLogsDetail`,
        method: "post",
        data: qs.stringify(param),
    });
}