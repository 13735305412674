<template>
  <div class="test" style="height:100%;position:relative;margin-top: 15px">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select v-model="topAttr.state" placeholder="状态" class="hotelBrand fullSelect" @change="table.page = 1;clickFilter($event,'state')" :clearable="true">
          <el-option v-for="item in topAttr.onlineState" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <!-- <el-col :span="4">
        <el-select v-model="topAttr.pic" placeholder="上传凭证" @change="clickFilter($event,'pic')" :clearable="true" class="fullSelect">
          <el-option v-for="item in topAttr.picState" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col> -->
      <!-- <el-col :span="4">
                <el-select v-model="topAttr.brush" placeholder="是否自刷" @change="clickFilter($event,'brush')" :clearable="true" class="fullSelect">
                    <el-option
                            v-for="item in topAttr.selfBrush"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-col> -->
      <el-col :span="4">
        <el-input placeholder="请输入设备名称" class="input-with-select fullSelect" v-model="topAttr.device_name" :clearable="true">
          <el-button slot="append" icon="el-icon-search" @click="table.page = 1;clickFilter()"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入房间号" class="input-with-select fullSelect" v-model="topAttr.query" :clearable="true">
          <el-button slot="append" icon="el-icon-search" @click="table.page = 1;clickFilter()"></el-button>
        </el-input>
      </el-col>
      <el-col :span="2">
        <el-button @click="resetForm">重置</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" class="largeBtn" v-if="showImport" :loading='btnLoading' @click="importTable">导出</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" class="largeBtn" v-if="showImport" @click="importLBOut">乐播导出</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" class="largeBtn" v-if="showImport" @click="ImportVisible=true">乐播导入</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="vertical rowBox showDataBox" style="margin-top:20px;">
      <index-four-row :type="type">
        <span slot="numTitle">酒店房间数</span>
        <span slot="num">{{showDataCom(bottomNum.input)}}</span>
        <span slot="unit">间</span>
      </index-four-row>
      <index-four-row :type="type">
        <span slot="numTitle">激活房间数</span>
        <span slot="num">{{showDataCom(bottomNum.connected)}}</span>
        <span slot="unit">间</span>
      </index-four-row>
      <index-four-row :type="type">
        <span slot="numTitle">在线房间数</span>
        <span slot="num">{{showDataCom(bottomNum.online)}}</span>
        <span slot="unit">间</span>
      </index-four-row>
      <!-- <index-four-row :type="type">
        <span slot="numTitle">上传凭证房间数</span>
        <span slot="num">{{showDataCom(bottomNum.picNum)}}</span>
        <span slot="unit">间</span>
      </index-four-row> -->
    </el-row>
    <div class="models flexBox">
      <div class="models-item" v-for="(item,index) in roomManagement" :key="index">
        <el-button class="btn" type="primary" size="small" @click="openRoomIofo(item.platform)">{{item.platform}}</el-button>
        <span>={{item.num}}间;</span>
      </div>
    </div>
    <div class="roomBox">
      <el-col :span="4" v-for="(item,index) in searchList" :key="index" class="roomItemBox">
        <div class="roomItem" :class="[item.state==1?'online':'offline',item.pic_state==0?'noPic':'']" @click="showPic(item.room,item.pic_state,item.pic_url,item.phone,item.flag)">{{item.room}}
          <!-- <div class='brushBox' v-if="item.flag==1">
                        <img src="../../../assets/img/selfBrush.png" alt="" >
                    </div> -->
        </div>
      </el-col>
      <el-col :span="2" class="roomItemBox">
        <!--<div class="roomItem" :class="[item.state==1?'online':'offline',item.pic_state==0?'noPic':'']">{{item.room}}-->
        <!--<div class="roomItem online" >ww-->
        <!--<div class='brushBox'>-->
        <!--<img src="../../../assets/img/selfBrush.png" alt="" >-->
        <!--</div>-->
        <!--</div>-->
      </el-col>
    </div>
    <div style="padding-bottom:20px">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[ 90, 120, 150, 180,210,240]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total"></el-pagination>
    </div>

    <el-dialog title="查看房间信息" :visible.sync="dialog.dialogTableVisible" width="600px" :modal-append-to-body="false">
      <el-tabs v-model="tabActiveName" @tab-click="handleClick">
        <el-tab-pane label="凭证" name="credentials">
          <div class="showPicBox">
            <div class="title">刷机人：{{dialog.brushName}}</div>
            <div class="middle">
              <div class="picBox" v-if="dialog.pic_state===0">
                <div class="inner">
                  <i class="el-icon-picture"></i>
                  <div class="text">暂无凭证</div>
                </div>
              </div>
              <img :src="dialog.pic_url" alt="" v-if="dialog.pic_state===1">
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="终端信息" name="terminal">
          <div class="terminal-info">
            <div class="info-item flexBox">
              <div class="label">房间号</div>
              <div class="grayColor">{{equipmentInfo.rname }}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">IP地址</div>
              <div class="grayColor">{{equipmentInfo.clientip}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">有线mac</div>
              <div class="grayColor">{{equipmentInfo.mac|| '--'}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">{{hotelItem.hotel_template_id==24?'Movieplayer版本':'ehotel版本'}}</div>
              <div class="grayColor">{{equipmentInfo.ehotelVer}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">{{hotelItem.hotel_template_id==24?'tvvideo版本':'upgrade版本'}}</div>
              <div class="grayColor">{{equipmentInfo.upgradeVer}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">总空间</div>
              <div class="grayColor">{{equipmentInfo.totalSpace}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">剩余空间</div>
              <div class="grayColor">{{equipmentInfo.remainingSpace}}</div>
            </div>
            <div class="info-item flexBox" v-if="hotelItem.hotel_template_id!=24">
              <div class="label">存储位置</div>
              <div class="grayColor">{{equipmentInfo.storageLocation}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">devicename</div>
              <div class="grayColor">{{equipmentInfo.platform}}</div>
            </div>
            <div class="info-item flexBox" v-if="hotelItem.hotel_template_id!=24">
              <div class="label">资源版本号</div>
              <div class="grayColor">{{equipmentInfo.resource_version}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">MAC首次注册时间</div>

              <div class="grayColor">{{equipmentInfo.macDate}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">本房间首次上线时间</div>

              <div class="grayColor">{{equipmentInfo.room_date}}</div>
            </div>
            <div class="info-item flexBox">
              <div class="label">最新在线时间</div>
              <div class="grayColor">{{equipmentInfo.heartBeatTime}}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 分组导入 -->
    <el-dialog title="乐播导入" :visible.sync="ImportVisible" width="50%">

      <div class="uploadBox " v-loading='importLoading'>
        <el-upload class="upload-demo" drag accept=".xlsx" :action="'--'" :auto-upload='false' :on-change="onChange">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em style="color:#658bff">点击上传</em></div>
        </el-upload>
      </div>
      <div class="notice">
        <div class="title">*Excel文件请符合以下标准</div>
        <div class="item">请按照模板格式导入 <span class="downLoad"><span style="color:#658bff;  cursor: pointer;" @click="downloadFile">下载导入模板</span></span></div>
        <div class="item">后缀名需为xls或者xlsx</div>
        <div class="item">数据请勿放在合并的单元格中</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="toInfo">确 认</el-button> -->
        <el-button class="cancellargeBtn" @click="cannelImport">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import qs from "querystring";
import {
  roomManagement,
  getRoomsDetail,
  getRoomByPlatform,
  exportRoomsNew,
} from "../../../api/allTab/Online";
const axios = require("axios");
export default {
  name: "Online",
  props: ["id", "showData", "hotelInFo"],
  data: () => ({
    type: "timeCenter",
    table: {
      page: 1,
      limit: 240,
      total: 0,
    },
    btnLoading: false,
    topAttr: {
      terminal: "",
      picState: [
        { label: "已上传", value: "1" },
        { label: "未上传", value: "0" },
      ],
      onlineState: [
        { label: "在线", value: "1" },
        { label: "离线", value: "0" },
      ],
      selfBrush: [
        { label: "自刷", value: "1" },
        { label: "他刷", value: "0" },
      ],
      query: "",
      state: "",
      pic: "",
      brush: "",
      device_name: "",
    },
    roomList: [
      {
        room: "1001",
        state: "1001",
        flag: "",
        pic_state: "",
        pic_url: "",
        name: "",
      },
      // {label:'1001',value:'1001'},
    ],
    searchList: [],
    bottomNum: {
      input: null, //酒店房间数
      connected: null, //激活房间数
      online: null, //在线房间数
      picNum: null, //上传凭证房间数
    },
    query: {
      fromUrl: "",
      id: "", //酒店id
      type: "check",
    },
    clickActive: "", //选中搞得
    dialog: {
      dialogTableVisible: false,
      pic_state: 0,
      pic_url: "",
      brushName: "brushName",
      room: "",
    },
    token: "",
    tabActiveName: "terminal",
    roomParams: {
      hotel_contract: "",
      database: "",
      rname: "",
    },
    equipmentInfo: {}, // 终端设备信息
    roomManagement: [],
    hotelItem: { hotel_template_id: 0 },
    showImport: true,
    LBOrigin: process.env.VUE_APP_APAAS_URL,
    ImportVisible: false,
    importLoading: false,
    file: null,
  }),
  methods: {
    onChange(file) {
      this.file = file;
      this.toInfo();
    },
    toInfo() {
      this.fileObj = this.file;
      // 创建文件流
      const formData = new FormData();
      formData.append("file", this.fileObj.raw);
      // Format封装在utilsg工具函数中
      axios({
        url: this.LBOrigin + "/api/v1/lebo/import-sn",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("导入成功");
            this.ImportVisible = false;
          } else {
            this.$message.warning("导入失败");
            this.ImportVisible = false;
          }
        })
        .catch(() => {
          this.$message.warning("导入失败");
        });
    },

    // 取消导入
    cannelImport() {
      this.ImportVisible = false;
      this.importLoading = false;
    },
    // 下载导入模板
    downloadFile() {
      var a = document.createElement("a");
      a.setAttribute("href", "./static/乐播投屏方案B预授权模板.xlsx");
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    importLBOut() {
      axios({
        method: "get",
        url:
          this.LBOrigin +
          "/api/v1/lebo/export-sn?hotelContract=" +
          this.roomParams.hotel_contract,
        responseType: "blob",
      })
        .then((res) => {
          //导出接口失败 返回的也是blob type为application/json
          if (res.data?.type === "application/json") throw new Error();

          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          if ("download" in document.createElement("a")) {
            // 非IE浏览器下载
            // 创建a标签
            const link = document.createElement("a");
            // 规定下载的超链接
            link.setAttribute("download", `乐播投屏信息导出.xlsx`);
            // 未点击前隐藏a链接
            link.style.display = "none";
            // 创建URL对象，指向该文件url
            link.href = URL.createObjectURL(blob);
            // 将a标签添加到dom中
            document.body.append(link);
            // 触发a标签点击事件
            link.click();
            // 释放之前的URL对象
            URL.revokeObjectURL(link.href);
            // 从dom中移除该a链接
            document.body.removeChild(link);
          } else {
            // IE10+ 下载
            navigator.msSaveBlob(blob, filename);
          }
          this.$message.success("导出成功");
        })
        .catch(() => {
          this.$message.warning("导出失败");
        });
    },
    init(query) {
      console.log(query, "dsnaushduahdaigui");
      this.hotelItem = query.res.data.data;
    },
    // 条目
    handleSizeChange(val) {
      this.table.limit = val;
      this.clickFilter();
    },
    // 页码
    handleCurrentChange(val) {
      this.table.page = val;
      this.clickFilter();
    },
    importTable() {
      this.btnLoading = true;
      this.$message.success("操作成功，结果下载需要时间，请稍后....");

      const params = {
        token: this.token,
        id: this.query.id,
        state: this.topAttr.state,
        pic_state: this.topAttr.pic,
        flag: this.topAttr.brush,
        keyword: this.topAttr.query,
        device_name: this.topAttr.device_name,
        hotel_project: this.roomParams.database,
      };
      exportRoomsNew(params)
        .then((res) => {
          const blobUrl = window.URL.createObjectURL(res.data);
          const title = `${this.roomParams.hotel}房间列表.xlsx`;
          // const title = `${this.tableName}.csv`;
          const a = document.createElement("a");
          a.style.display = "none";
          a.download = title;
          a.href = blobUrl;
          a.click();
          this.btnLoading = false;
          this.$message.success("导出成功");
          document.body.removeChild(a);
        })
        .catch((err) => {
          // this.$message.warning("导出失败");
        });
    },
    resetForm() {
      this.topAttr.device_name = "";
      this.topAttr.state = "";
      this.topAttr.pic = "";
      this.topAttr.query = "";
      this.table.page = 1;
      this.clickFilter();
    },
    openRoomIofo(platform) {
      this.topAttr.device_name = platform;
      this.clickFilter();
      // let params = {
      //   database: this.roomParams.database,
      //   platform: platform,
      // };
      // getRoomByPlatform(params).then((res) => {
      //   if (res.data.code == 100000) {
      //     console.log(res);
      //     this.$alert(res.data.data.room_str, "房间信息", {
      //       confirmButtonText: "确定",
      //       callback: (action) => {},
      //     });
      //   } else {
      //     this.$message.warning("房间信息获取失败");
      //   }
      // });
    },
    getRoom() {
      this.$http
        .get(this.global.getRoomsNews, {
          params: {
            token: this.token,
            id: this.query.id,
            hotel_project: this.roomParams.database,
            // id:5268\
            page: this.table.page,
            size: this.table.limit,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var roomData = res.data.data;
            this.roomList = roomData.details.data;

            this.searchList = roomData.details.data;
            this.table.total = roomData.details.total;
            this.bottomNum.input = roomData.totalnum.input; //酒店房间数
            this.bottomNum.connected = roomData.totalnum.connected; //激活房间数
            this.bottomNum.online = roomData.totalnum.online; //在线房间数
            this.bottomNum.picNum = roomData.totalnum.picNum; //上传凭证房间数
            console.log("this.roomList", this.roomList);
          }
        });
    },
    getRoomReceive() {
      var param = qs.stringify({
        token: this.token,
        receiving_number: this.query.receiving_number,
      });
      this.$http
        .post(this.global.getRootDetailByReceiving, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var roomData = res.data.data;
            this.roomList = roomData;
            this.searchList = roomData;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    clickFilter(val, type) {
      this.$http
        .get(this.global.getRoomsNews, {
          params: {
            page: this.table.page,
            size: this.table.limit,
            token: this.token,
            id: this.query.id,
            state: this.topAttr.state,
            pic_state: this.topAttr.pic,
            flag: this.topAttr.brush,
            keyword: this.topAttr.query,
            device_name: this.topAttr.device_name,
            hotel_project: this.roomParams.database,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var roomData = res.data.data;
            this.roomList = roomData.details.data;
            this.searchList = roomData.details.data;
            this.table.total = roomData.details.total;
          }
        });
    },
    showPic(room, pic_state, pic_url, brushName, flag) {
      this.dialog.room = room;
      this.dialog.pic_state = pic_state;
      this.dialog.pic_url = pic_url;
      this.dialog.brushName = brushName;
      this.dialog.dialogTableVisible = true;
      this.roomParams.rname = room;
      this.getRoomDetail();
    },
    getRoomDetail() {
      getRoomsDetail(this.roomParams).then((res) => {
        if (res.data.code == 100000) {
          this.equipmentInfo = res.data.data;
        } else {
          this.equipmentInfo = {};
          this.$message.warning(res.data.msg);
        }
      });
    },
    getRoomManagement() {
      let params = {
        database: this.roomParams.database,
        token: this.token,
      };
      console.log(454564);
      roomManagement(params).then((res) => {
        if (res.data.code == 100000) {
          this.roomManagement = res.data.data;
        }
      });
    },
    handleClick() {},
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    let data = JSON.parse(sessionStorage.getItem("hoteliitem"));
    if (data && data.isApaas) {
      this.hotelItem = data.res.data.data;
      this.showImport = data.deviceOnlineExport;
    }

    var query = this.$route.query;
    this.query.id = this.id; //酒店id
    this.query.type = query.type; //酒店id

    let { id, hotel_contract, hotelName, hotel } = this.hotelInFo;
    // if (!hotel_contract) return;
    this.roomParams = {
      database: hotelName,
      hotel_contract,
      hotel,
    };
    if (this.roomParams.database) {
      this.getRoom();
      this.getRoomManagement();
    }
  },

  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
  },
  watch: {
    "dialog.dialogTableVisible"(newVal) {
      if (!newVal) {
        this.dialog.room = "";
        this.dialog.pic_state = "";
        this.dialog.pic_url = "";
        this.dialog.brushName = "";
      }
    },
    hotelInFo: {
      handler: function (newVal) {
        let { id, hotel_contract, hotelName, program_name } = newVal;
        if (!hotel_contract) return;
        this.roomParams = {
          database: program_name ? program_name : hotelName,
          hotel_contract,
        };
        console.log(this.roomParams);
        let query = this.$route.query;
        this.query.id = this.id; //酒店id
        this.query.type = query.type; //酒店id
        this.getRoomManagement();
        if (this.roomParams.database) {
          this.getRoom();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.flexBox {
  display: flex;
  flex-wrap: wrap;
}
.roomBox {
  overflow: hidden;
  padding-bottom: 20px;
}
.roomBox .el-col {
  padding: 10px 20px 10px 0;
}
.roomItem {
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

.numBox {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 20px;
  background: #fff;
  .el-col {
    padding: 30px 0;
  }
}

/*图例*/
.legendTop {
  align-items: center;
}
.legendTop,
.legendTop > .left,
.legendTop > .left > .item {
  display: flex;
}
.legendTop > .right {
  display: flex;
  flex: 1;
  justify-content: space-around;
}
.legendTop {
  padding: 20px 0;
  display: flex;
}

.legendTop .item {
  margin-right: 26px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 8px;
  padding: 10px 6px;
}
.legendTop .item.active {
  background: #1199ff;
  color: white;
}

.colorShow {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 50%;
}

.colorShow.online {
  background: #78bf2f;
}

.colorShow.offline {
  border: 1px solid #eae8e9;
  background: #f6f4f5;
  box-sizing: border-box;
}
.colorShow.noPic {
  border: 1px solid #eae8e9;
  background: red;
  box-sizing: border-box;
}

.colorShow.selfBrush {
  background: #f6f4f5;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.roomItemBox {
  font-size: 14px;
  position: relative;
}
.brushBox {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: -14px;
  right: -14px;
}
.brushBox img {
  width: 100%;
  height: 100%;
}
.roomBox .roomItem.online {
  background: #78bf31;
  color: #fff;
}
.roomBox .roomItem.offline {
  background: #f6f4f5;
  color: #333;
}
.roomBox .roomItem.noPic {
  border-left: 4px solid red;
}
.models {
  .models-item {
    padding: 0 20px 20px 0;
    .btn {
      margin-right: 10px;
    }
  }
  padding: 10px;
}
/* 凭证弹窗开始 */
.showPicBox {
  width: 100%;
  background: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  transition: 0.3s;
}
.showPicBox .title {
  width: 100%;
  text-align: left;
  transition: 0.3s;
  padding-bottom: 30px;
  box-sizing: border-box;
  font-size: 16px;
}

.middle {
  width: 100%;
  height: 300px;
  /*padding:0 40px;*/
  box-sizing: border-box;
}
.middle img {
  width: 100%;
  height: 100%;
}
.picBox {
  width: 100%;
  height: 100%;
  margin: 0 auto 30px auto;
  position: relative;
  transition: 0.3s;
  border-radius: 7px;
  box-sizing: border-box;
  background: #e7f5ff;
}

.picBox .inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inner i {
  font-size: 116px;
  margin-bottom: 30px;
  color: #afcade;
}
.inner .text {
  font-size: 18px;
  color: #afcade;
}
.showDataBox {
  margin: 20px 0 10px 0;
}
.fullSelect {
  width: 100%;
}

/* 凭证弹窗结束 */
// 查看终端信息
.terminal-info {
  padding: 20px;
  .info-item {
    padding-bottom: 20px;
    .label {
      width: 130px;
      color: #333;
    }
  }
  .info-item + .info-item {
    padding-top: 0px;
  }
}
.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}
.notice {
  width: 100%;
  background: #eef2f7;
  padding: 20px 20px;
  margin-top: 30px;
  box-sizing: border-box;
}
.notice div {
  color: #909ca7;
}
.notice .title {
  font-size: 16px;
  padding-bottom: 20px;
}
.notice .item {
  margin-bottom: 20px;
}
.notice .item:last-child {
  margin-bottom: 0;
}
.notice .downLoad > a {
  color: #418efa;
  margin-left: 20px;
  cursor: pointer;
}
::v-deep .el-upload-list {
  display: none !important;
}
.errorDownload {
  color: #658bff;
  cursor: pointer;
}
</style>
