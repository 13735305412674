<template>
    <div>
        <fold-top-search @fold="getFoldMeth" class="absoluteFoldBox"></fold-top-search>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card" v-loading="showLoading">
            <div class=" rowBox vertical">
                <div class="title lineTitle blueTitle">集团观影收益统计</div>
                <el-row :gutter="20" class="rowBox vertical borderBox">
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.mcu_income" parShowUnit="money">
                        <span slot="numTitle">观影订单收益</span>
                        <span slot="time">{{showDataTime('manyDay')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.lyc_income" parShowUnit="money">
                        <span slot="numTitle">麗管家售卡收益</span>
                        <span slot="time">{{showDataTime('manyDay')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.tv_income" parShowUnit="money">
                        <span slot="numTitle">电视平台售卡收益</span>
                        <span slot="time">{{showDataTime('manyDay')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.rec_income" parShowUnit="money">
                        <span slot="numTitle">酒店前台售卡收益</span>
                        <span slot="time">{{showDataTime('manyDay')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row-num-unit>
                </el-row>
                <el-row :gutter="20" class="rowBox vertical borderBox">
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.hotel_num">
                        <span slot="numTitle">运营酒店数量</span>
                        <span slot="time">{{showDataTime('oneDay')}}</span>
                        <span slot="unit">家</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.room_num">
                        <span slot="numTitle">客房数量</span>
                        <span slot="time">{{showDataTime('oneDay')}}</span>
                        <span slot="unit">间</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.active_terminal_num">
                        <span slot="numTitle">激活终端数量</span>
                        <span slot="time">{{showDataTime('oneDay')}}</span>
                        <span slot="unit">台</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :span="6" :type="type" :parShowDate="showData.day_active_rate" parShowUnit="money">
                        <span slot="numTitle">日活率</span>
                        <span slot="time">{{showDataTime('manyDay')}}</span>
                        <span slot="unit">%</span>
                    </index-four-row-num-unit>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card" v-loading="lineLoading">
            <div class="lineContent chartBox">
                <!--<line-chart :chart="lineChart" ></line-chart>-->
                <!--<line-chart v-if="lineChart.flag && !lineChart.noData" :chart="lineChart"></line-chart>-->
                <index-line-chart v-if="lineChart.flag && !lineChart.noData" :chart="lineChart"></index-line-chart>
                <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">暂无数据</div>
            </div>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
        <!--<div class="lineContent">-->
            <!--<line-title>分类销售指数</line-title>-->
            <!--<el-row :gutter="20" class="rowBox vertical">-->
                <!--<two-line :chart="signalPointChart" >-->
                    <!--<span slot="title">影片单点数据</span>-->
                    <!--<span slot="date">{{showDataTime('manyDay')}}</span>-->
                    <!--<span slot="amount">{{signalPointChart.amount}}</span>-->
                    <!--<span slot="top">付费影片数量：{{signalPointChart.watchMovieNum}}</span>-->
                    <!--<span slot="bottom">付费房间数量：{{signalPointChart.watchRoomNum}}</span>-->
                <!--</two-line>-->
                <!--<two-line :chart="frontDeskChart">-->
                    <!--<span slot="title">酒店前台销售数据</span>-->
                    <!--<span slot="date">{{showDataTime('manyDay')}}</span>-->
                    <!--<span slot="amount">{{frontDeskChart.amount}}</span>-->
                    <!--<span slot="top">销卡数量：{{frontDeskChart.sellCardNum}}</span>-->
                    <!--<span slot="bottom">销售酒店数量：{{frontDeskChart.sellCardHotelNum}}</span>-->
                <!--</two-line>-->
            <!--</el-row>-->
        <!--</div>-->
    </div>
</template>

<script>
    import  qs from 'querystring'
    import GLOBAL from '../../../api/globalVar.js'
    import commonMeth from '../../../api/method'
    export default {
        name: "movie",
        data: () => ({
            type:'timeCenter',
            lineChart:{
                id:'chart',
                showTitle:true,
                flag:false,
                noData:false,
                fromUrl:'inTabViewMovie',

                option:{
                    legend:[],
                    xData:[],
                    grid: {
                        top:'3%',
                        left: '3%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    series:[
                        {
                            name: '观影订单收益',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color:GLOBAL.chartColor[0] , //改变折线点的颜色
                                    lineStyle: {
                                        color: GLOBAL.chartColor[0] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '麗管家售卡收益',
                            type: 'line',
                            smooth:true,
                            yAxisIndex:1,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[1], //改变折线点的颜色
                                    lineStyle: {
                                        color:GLOBAL.chartColor[1] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '酒店前台售卡收益',
                            type: 'line',
                            smooth:true,
                            yAxisIndex:2,
                            itemStyle: {
                                normal: {
                                    color:GLOBAL.chartColor[2], //改变折线点的颜色
                                    lineStyle: {
                                        color: GLOBAL.chartColor[2] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '电视平台售卡收益',
                            type: 'line',
                            smooth:true,
                            yAxisIndex:3,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[3], //改变折线点的颜色
                                    lineStyle: {
                                        color: GLOBAL.chartColor[3] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },

                    ]
                }
            },
            signalPointChart:{   //单点
                id:'signalPointChart',
                showTitle:false,
                flag:false,
                noData:false,
                amount:'',
                watchMovieNum:'',
                watchRoomNum:'',
                option:{
                    legend:[],
                    xData:[],
                    series:[
                        {
                            name: '付费影片数量',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#2194f4', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#2194f4' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '付费房间数量',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#ea9856', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#ea9856 ' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                    ]
                }
            },
            frontDeskChart:{   //单点
                id:'frontDeskChart',
                showTitle:false,
                flag:false,
                noData:false,
                amount:'',
                sellCardHotelNum:'',
                sellCardNum:'',
                option:{
                    legend:[],
                    xData:[],
                    series:[
                        {
                            name: '销卡数量',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#2194f4', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#2194f4' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '销售酒店数量',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#ea9856', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#ea9856 ' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                    ]
                }
            },
            tvCard:{   //单点
                id:'tvCard',
                showTitle:false,
                option:{
                    legend:['激活终端数量', '日活量'],
                    xData:['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    series:[
                        {
                            name: '激活终端数量',
                            type: 'line',
                            stack: '总量',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#2194f4', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#2194f4' //改变折线颜色
                                    }
                                }
                            },
                            data: [120, 132, 101, 134, 90, 230, 210]
                        },
                        {
                            name: '日活量',
                            type: 'line',
                            stack: '总量',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#ea9856', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#ea9856 ' //改变折线颜色
                                    }
                                }
                            },
                            data: [220, 182, 191, 234, 290, 330, 310]
                        },
                    ]
                }
            },
            tvShop:{   //单点
                id:'tvShop',
                showTitle:false,
                option:{
                    legend:['激活终端数量', '日活量'],
                    xData:['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    series:[
                        {
                            name: '激活终端数量',
                            type: 'line',
                            stack: '总量',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#2194f4', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#2194f4' //改变折线颜色
                                    }
                                }
                            },
                            data: [120, 132, 101, 134, 90, 230, 210]
                        },
                        {
                            name: '日活量',
                            type: 'line',
                            stack: '总量',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#ea9856', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#ea9856 ' //改变折线颜色
                                    }
                                }
                            },
                            data: [220, 182, 191, 234, 290, 330, 310]
                        },
                    ]
                }
            },
            lfShop:{   //单点
                id:'lfShop',
                showTitle:false,
                option:{
                    legend:['激活终端数量', '日活量'],
                    xData:['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    series:[
                        {
                            name: '激活终端数量',
                            type: 'line',
                            stack: '总量',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#2194f4', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#2194f4' //改变折线颜色
                                    }
                                }
                            },
                            data: [120, 132, 101, 134, 90, 230, 210]
                        },
                        {
                            name: '日活量',
                            type: 'line',
                            stack: '总量',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#ea9856', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#ea9856 ' //改变折线颜色
                                    }
                                }
                            },
                            data: [220, 182, 191, 234, 290, 330, 310]
                        },
                    ]
                }
            },
            table:{
                tableHeader:[
                    {prop:'name',label:'名称',width:''},
                    {prop:'type',label:'任务类型',width:''},
                    {prop:'state',label:'任务状态',width:''},
                    {prop:'getTime',label:'接收时间',width:''},
                    {prop:'handleTile',label:'处理时间',width:''},
                ],
                tableList:[
                    {id:1,name:'长沙泰牛网络科技有限公司',type:'渠道商申请',state:0,getTime:'2020-1-2',handleTile:'2020-1-2'},
                    {id:1,name:'长沙泰牛网络科技有限公司',type:'渠道商申请',state:0,getTime:'2020-1-2',handleTile:'2020-1-2'},
                    {id:1,name:'长沙泰牛网络科技有限公司',type:'渠道商申请',state:0,getTime:'2020-1-2',handleTile:'2020-1-2'},
                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                startTime:'',
                endTime:'',
                fromUrl:'incomeStaMovie',
                name:'incomeStaMovie',
            },
            showData:{
                hotel_num:0,
                room_num:0,
                active_terminal_num:0,
                day_active_rate:0,
                mcu_income:0,
                lyc_income:0,
                tv_income:0,
                rec_income:0,
            },
            condition:{
                startTime:'',
                endTime:'',
                province:'',
                city:'',
                country:'',
                hotelBrand:'',
                accountManagerId:''
            },
            topSearchShow:{ },
            token:'',
            showLoading:true,
            lineLoading:true,
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            allLoadingState:false,
        }),
        methods:{
            //监听pageSize改变
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
            },
            //监听页码值改变
            handleCurrentChange(newPage){
                this.queryInfo.currentPage=newPage;
            },
            handleClick(tab, event) {
            },

            //折线图
            getLineChart(){
                this.lineLoading=true;
                this.lineChart.flag=false;
                var param=qs.stringify({
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    country:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                });
                this.$http.post(this.global.getMovieCardIncomeLine,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        if(chartData.series){
                            this.lineChart.option.legend=chartData.legend;  //图例
                            this.lineChart.option.xData=chartData.xAxis;  //x轴
                            this.lineChart.option.series[0].data=chartData.series['观影订单收益'];  //x轴
                            this.lineChart.option.series[1].data=chartData.series['麗管家售卡收益'];  //x轴
                            this.lineChart.option.series[2].data=chartData.series['酒店前台售卡收益'];  //x轴
                            this.lineChart.option.series[3].data=chartData.series['电视平台售卡收益'];  //x轴
                        }else{
                            this.lineChart.noData=true;
                        }
                    }else if(code===100001){
                        // this.errorText='验证码错误'
                    }
                    this.lineChart.flag=true;
                    this.lineLoading=false;
                });
            },

            //影片单点数据-折线图
            getSignalPointChart(){
                var param=qs.stringify({
                    token:this.token,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    province:this.condition.province,
                    city:this.condition.city,
                    country:this.condition.country,
                    hotelBrand:this.condition.hotelBrand,
                    accountManagerId:this.condition.BD,
                    test:'test'
                });
                this.$http.post(this.global.getMovieCardMCULine,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        if(chartData.series){
                            this.signalPointChart.option.legend=chartData.legend;  //图例
                            this.signalPointChart.option.xData=chartData.xAxis;  //x轴
                            this.signalPointChart.amount=chartData.amount;  //总量
                            this.signalPointChart.watchMovieNum=chartData.watchMovieNum;  //付费影片
                            this.signalPointChart.watchRoomNum=chartData.watchRoomNum;  //付费房间
                            this.signalPointChart.option.series[0].data=chartData.series['付费影片数量'];  //x轴
                            this.signalPointChart.option.series[1].data=chartData.series['付费房间数量'];  //x轴

                        }else{
                            this.signalPointChart.noData=true;
                        }
                    }else if(code===100001){
                        // this.errorText='验证码错误'
                    }
                    this.signalPointChart.flag=true;

                });
            },

            //酒店前台
            getFrontDeskChart(){
                var param=qs.stringify({
                    token:this.token,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    province:this.condition.province,
                    city:this.condition.city,
                    country:this.condition.country,
                    hotelBrand:this.condition.hotelBrand,
                    accountManagerId:this.condition.BD
                });
                this.$http.post(this.global.getMovieCardRECLine,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        if(chartData.series){
                            this.frontDeskChart.option.legend=chartData.legend;  //图例
                            this.frontDeskChart.option.xData=chartData.xAxis;  //x轴
                            this.frontDeskChart.amount=chartData.amount;  //总量
                            this.frontDeskChart.sellCardHotelNum=chartData.sellCardHotelNum;  //销售酒店数量
                            this.frontDeskChart.sellCardNum=chartData.sellCardNum;  //销卡数量
                            this.frontDeskChart.option.series[0].data=chartData.series['销卡数量'];  //x轴
                            this.frontDeskChart.option.series[1].data=chartData.series['销售酒店数量'];  //x轴

                        }else{
                            this.frontDeskChart.noData=true;
                        }
                    }else if(code===100001){
                        // this.errorText='验证码错误'
                    }
                    this.frontDeskChart.flag=true;

                });
            },
            getCondition(val){
                this.condition.hotel_brand=Array.isArray(val.hotel_brand)?val.hotel_brand.join(','):'';
                this.condition.hotel_use_state=Array.isArray(val.hotel_use_state)?val.hotel_use_state.join(','):'';
                this.condition.BD=Array.isArray(val.BD)?val.BD.join(','):'';
                this.condition.partner_id=Array.isArray(val.noTypePartnerCode)?val.noTypePartnerCode.join(','):'';
                if(typeof val.citycode==='string'){
                    this.condition.citycode=val.citycode;
                }else{
                    this.condition.citycode=Array.isArray(val.citycode)?val.citycode.join(','):''; //citycode
                }
                this.condition.provinceLabel=Array.isArray(val.provinceLabel)?val.provinceLabel.join(','):'';
                this.condition.cityLabel=val.cityLabel;
                this.condition.countyLabel=val.countyLabel;
                this.condition.startTime=val.startTime;
                this.condition.endTime=val.endTime;
                this.condition.keyword=val.keyword;
                this.getLineChart();
                this.showDataMeth();
            },
            showDataMeth(){
                this.showLoading=true;
                var param=qs.stringify({
                    token:this.token,

                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                });
                this.$http.post(this.global.getMovieCardIncomeStatistics,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        this.showData=res.data.data;
                        // this.showData.time='【昨日】';

                    }
                    this.showLoading=false;
                }).catch(()=>{
                    this.showLoading=false;
                })
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='factory'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                    }
                } if(auth_type==='group'){
                    this.topSearchShow={
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        coopType:true,
                        coopPartner:true,
                        BD:true,
                        date:true,
                        keyword:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder='请输入酒店名称关键词';
            },
            getFoldMeth(val){
                this.fold=val;
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getTopSearchShow();
            this.showDataMeth();
            this.lineChart.option.series.forEach((item,index)=>{
                item.symbolSize=this.global.symbolSize;
            });
            this.getLineChart();
            this.getSignalPointChart();
            this.getFrontDeskChart();
        },
        computed:{
            showDataCom(){
                return function(s,type='',n=2){
                    if(type==='money'){
                        if(s===null){
                            return 0;
                        }else{
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    }else{
                        if(s===null){
                            return 0;
                        }else{
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            showDataTime(){
                return function(val){
                    if(val==='manyDay'){   //日活率
                        if(this.condition.startTime===''){
                            return '【昨日】'
                        }else{
                            var time='【'+this.condition.startTime+'~'+this.condition.endTime+'】';
                            return time;
                        }
                    }else {   //其他数据
                        if(this.condition.startTime===''){
                            return '【昨日】'
                        }else if(this.condition.startTime!==this.condition.endTime){
                            var time='【'+this.condition.endTime+'】';
                            return time;
                        } else{
                            var time='【'+this.condition.startTime+'~'+this.condition.endTime+'】';
                            return time;
                        }
                    }

                }
            },
            twoPointCom(){
                return function(x){
                    !x?x=0:'';
                    var f = parseFloat(x);
                    if (isNaN(f)) {
                        return false;
                    }
                    var f = Math.round(x*100)/100;
                    var s = f.toString();
                    var rs = s.indexOf('.');
                    if (rs < 0) {
                        rs = s.length;
                        s += '.';
                    }
                    while (s.length <= rs + 2) {
                        s += '0';
                    }
                    return s;
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.lineLoading,this.showLoading);
            }
        },
    }
</script>

<style scoped lang="scss">
    .chartBox{
        height:400px;
        overflow:hidden;
    }
    .chartBoxLittle{
        height:300px;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .colBox{
        border:1px solid #ddd;
        border-radius:4px;
        padding:20px;
        .date{
            color:#bababa;
            margin-top:15px;
            font-size:14px;
        }
        .top{
            display:flex;
            justify-content: space-between;
            margin-bottom:20px;

            .right{
                span{
                    color:#559afc;
                }
                .check{
                    margin-right:5px;
                }
            }
        }
        .bottom{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .num{
                font-size:28px;
                margin-right:10px;
            }
            .right{
                border-left:1px solid #ddd;
                padding-left:15px;
                div:first-child{
                    margin-bottom:15px;
                }
            }
        }

    }
    .foldBox{
        padding:10px 0;
        overflow:hidden;
    }
</style>
