<template>
  <div>
    <div class="showBox">
      <div class="pic"></div>
      <div class="pageTitle">沈阳市文化内容监管平台</div>

      <div class="scrollText">
        <div class="textContent">提示信息：1、禁止使用或者变相使用中华人民共和国的国旗、国歌、国徽，军旗、军歌、军徽；

          2、禁止使用或者变相使用国家机关、国家机关工作人员的名义或者形象；

          3、广告素材及标题中不能出任何政治相关的元素，不能出现“特供”、“专供”等相关词汇；

          4、严禁推广假货、山寨及高仿的商品，如仿iphone、三星的山寨手机；

          5、严禁使用未经证实的媒体或活动方的指定、推荐、合作信息，以及未经证实的奖项、评比结果、专利技术；如某大会指定商品、某网合作伙伴、老字号、中国驰名商标、专利产品等。如有资质请提供相关证明；</div>
      </div>
      <div class="leftBox">
        <div class="storeOnLine">
          <div class="moudleTitle">昨日直播观看次数统计</div>
          <barCard id='bar1'></barCard>

        </div>
        <div class="storePlay">
            <div class="moudleTitle" style="top:30px">频道热播Top5</div>
          <storeTop type='2'></storeTop>
      

        </div>
        <div class="storeTop">
              <div class="moudleTitle" style="top:30px">各酒店终端排行Top5</div>
          <storeTop type='1'></storeTop>
        </div>
      </div>
      <div class="centerBox">
        <div class="dataBox">
          <div class="dataCard">
            <div class="moudleTitle1 card">监管酒店数</div>
            <div class="cardNum">{{pageData.onlineTotal}}</div>

          </div>
          <div class="dataCard">
            <div class="moudleTitle1 card">监管终端数</div>
            <div class="cardNum">{{pageData.onlineNum}}</div>
          </div>

        </div>
        <div class="mapBox">
          <map-box></map-box>
        </div>
        <div class="listBox">
          <div class="model"></div>
          <div class="moudleTitle">警报信息列表</div>
          <div class="listShow">
            <div class="item">
              <div class="storeName">酒店名称</div>
              <div class="channelName">警报等级</div>
              <div class="channelName">警报信息</div>
            </div>
            <div class="scrollBox">
              <div class="allBox">
                <div class="item" v-for="(item,index) in hotelList" :key="index">
                  <div class="storeName">{{item.name}}</div>
                  <div class="channelName">{{item.channel_name}}</div>
                  <div class="channelName">{{item.msg}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="channelNum">
          <div class="moudleTitle">近期酒店警报统计</div>
          <lineCard id='line'></lineCard>
        </div>
        <div class="channelTop">
          <div class="moudleTitle">酒店警报类型占比</div>
          <pieCard id='pie2'></pieCard>
        </div>
        <div class="channelTimeTop">
          <div class="moudleTitle">近期监管执行统计</div>
          <barCard id='bar'></barCard>
        </div>
      </div>
    </div>
    <div class="modelContent"></div>
  </div>

</template>

<script>
import mapBox from "./conmonents/map.vue";
import lineCard from "./conmonents/lineCard.vue";
import barCard from "./conmonents/barCard.vue";
import pieCard from "./conmonents/pieCard.vue";
import storeTop from "./conmonents/storeTop.vue";
export default {
  components: {
    mapBox,
    lineCard,
    barCard,
    pieCard,
    storeTop,
  },
  data() {
    return {
      timer: null,
      pageData: {
        onlineNum: 11561,
        onlineRate: 11561,
        onlineTotal: 1515,
      },
      hotelList: [
        {
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },
       {
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },{
          name: '考拉都市民宿沈阳北站店',
          channel_name: "信息",
          msg:"内容不合法"
        },
        {
          name: '沈阳于洪希尔顿惠庭酒店',
          channel_name: '信息',
             msg:"内容不合法"
        },
        {
          name: '白玉兰酒店沈阳黎明广场地铁站店',
          channel_name: '警告',
             msg:"酒店直播到期"
        },
        {
          name: '麗枫酒店沈阳铁西广场万象汇地铁站店',
          channel_name: "危险",
             msg:"监播链路故障"
        },
      ],
    };
  },
  mounted() {
    let heightMax = this.hotelList.length * 28;
    if (heightMax <= 140) {
      return;
    } else {
      // 上移距离
      let moveNum = heightMax - 140;
      // 判断页面有没有写入动画@key。。
      let DStyle = document.querySelector("[user]");
      if (DStyle) {
        // 写入更新
        DStyle.innerHTML =
          `@keyframes dataShowMove` +
          `{
            from {
                top: 0px;
            }
             to {
                   top: ${-moveNum}px;
            }`;

        let box = document.querySelector(".allBox");
        // 动画时间
        let moveTime = ((moveNum / 2660) * 120).toFixed(0);
        // 样式属性修改
        box.style.animation = `dataShowMove ${moveTime}s linear infinite`;
        box.style[
          "-webkit-animation"
        ] = `dataShowMove ${moveTime}s linear infinite`;
      } else {
        // 没有写入动画@key。。
        let style = document.createElement("style");
        style.setAttribute("type", "text/css");
        // 写入识别属性
        style.setAttribute("user", "dataShow");
        document.head.appendChild(style);
        style.innerHTML =
          `@keyframes dataShowMove` +
          `{
            from {
                top: 0px;
            }
             to {
                   top: ${-moveNum}px;
            }`;
        let box = document.querySelector(".allBox");
        // 动画时间
        let moveTime = ((moveNum / 2660) * 120).toFixed(0);
        // 样式属性修改
        box.style.animation = `dataShowMove ${moveTime}s linear infinite`;
        box.style[
          "-webkit-animation"
        ] = `dataShowMove ${moveTime}s linear infinite`;
      }
    }
  },

  beforeDestroy() {},
  methods: {
    // 获取酒店列表
    getHotelList() {
      let json = {
        hotel_contract: this.hotel_contract,
        is_rand: 1,
      };
      getOnLineLocation(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.hotelList = res.data.data.rows;
            //  计算容器高度
            let heightMax = this.hotelList.length * 28;
            if (heightMax <= 140) {
              return;
            } else {
              // 上移距离
              let moveNum = heightMax - 140;
              // 判断页面有没有写入动画@key。。
              let DStyle = document.querySelector("[user]");
              if (DStyle) {
                // 写入更新
                DStyle.innerHTML =
                  `@keyframes dataShowMove` +
                  `{
            from {
                top: 0px;
            }
             to {
                   top: ${-moveNum}px;
            }`;

                let box = document.querySelector(".allBox");
                // 动画时间
                let moveTime = ((moveNum / 2660) * 120).toFixed(0);
                // 样式属性修改
                box.style.animation = `dataShowMove ${moveTime}s linear infinite`;
                box.style[
                  "-webkit-animation"
                ] = `dataShowMove ${moveTime}s linear infinite`;
              } else {
                // 没有写入动画@key。。
                let style = document.createElement("style");
                style.setAttribute("type", "text/css");
                // 写入识别属性
                style.setAttribute("user", "dataShow");
                document.head.appendChild(style);
                style.innerHTML =
                  `@keyframes dataShowMove` +
                  `{
            from {
                top: 0px;
            }
             to {
                   top: ${-moveNum}px;
            }`;
                let box = document.querySelector(".allBox");
                // 动画时间
                let moveTime = ((moveNum / 2660) * 120).toFixed(0);
                // 样式属性修改
                box.style.animation = `dataShowMove ${moveTime}s linear infinite`;
                box.style[
                  "-webkit-animation"
                ] = `dataShowMove ${moveTime}s linear infinite`;
              }
            }
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang='scss'>
.scrollText {
  color: rgb(45, 76, 180);
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  overflow: hidden;
  height: 40px;
  .textContent {
    position: absolute;
    top: 0;
    left: 700px;
    white-space: nowrap;
    color: yellow;
    font-size: 20px;
    animation: move 30s linear infinite;
  }
}
@keyframes move {
  0% {
    transform: translateX(0px);
    left: 700px;
  }
  100% {
    transform: translateX(-100%);
    left: 0;
  }
}
.moudleTitle {
  color: rgb(112, 162, 209);
  font-family: "DBold";
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 30px;
  // transform: translateX(-50%);
  height: 28px;
  line-height: 28px;
}
.moudleTitle1 {
  color: #fff;
  font-family: "DBold";
  font-size: 32px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 28px;
  line-height: 28px;
}
.card {
  height: 50px;
  line-height: 50px;
  font-size: 24px;
}
.modelContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #130841, $alpha: 1);
}
.showBox {
  min-height: 1080px;
  min-width: 1920px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba($color: #130841, $alpha: 1);
  background-size: 100% 100%;
  margin: 0 auto;
  padding: 0 36px;
  position: relative;
  z-index: 99;
  .pageTitle {
    font-size: 38px;
    height: 110px;
    line-height: 110px;
    width: 100%;
    font-weight: 600;
    position: relative;
    margin: 0 auto;
    text-align: center;
    text-shadow: 4px 4px 3px rgba($color: #0d193f, $alpha: 0.5);
  }
  .pageTitle::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    content: "沈阳市文化内容监管平台";
    // 渐变样式
    background-image: linear-gradient(#e6f5ff, #72b1ee);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    // 去除继承父级样式
    text-shadow: none;
  }
  .leftBox {
    width: 560px;
    height: calc(100% - 80px);
    position: absolute;
    left: 36px;
    top: 80px;
    .storeOnLine {
      background-size: 100% 100%;
      width: 100%;
      height: 32%;
      position: relative;
      padding-top: 30px;
      box-sizing: border-box;
    }
    .storePlay {
      width: 100%;
      height: 32%;
      margin-top: 14px;
      padding-top: 30px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .storeTop {
      width: 100%;
      height: 32%;
      margin-top: 14px;
      padding-top: 30px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .centerBox {
    width: 695px;
    margin-top: -30px;
    margin: 36px auto 0;
    padding: 0 15px;
    .dataBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dataCard {
        position: relative;
        width: 300px;
        height: 128px;
        // background: rgba($color: #162f56, $alpha: 1);
        border-radius: 30px;
        border: 3px solid  #162f56;
        box-shadow: 2px 3px 15px 5px #162f56;
        background-size: 100% 100%;
        .cardNum {
          font-family: "DBold";
          color: #04c5f9;
          font-size: 64px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -30%);
        }
      }
    }
    .mapBox {
      height: 565px;
    }
    .listBox {
      width: 100%;
      height: 220px;

      background-size: 100% 100%;
      position: relative;
      padding-top: 39px;
      box-sizing: border-box;
      .model {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 145px;
        border-radius: 20px;
        background: linear-gradient(
          to bottom,
          rgba(8, 33, 82, 0),
          rgba(19, 37, 81, 0.7)
        );
        z-index: 22;
      }
      .listShow {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        height: 185px;
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 30px;
          box-sizing: border-box;
          padding: 0 20px;
          color: #d0e8ff;
          .storeName {
            width: calc((100% - 100px) / 2);
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .channelName {
            width: calc((100% - 100px) / 2);
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .onLine {
            width: 68px;
          }
          div {
            padding: 0 16px;
          }
        }
        .item:first-child {
          background: rgba($color: #accbf5, $alpha: 0.1);
          color: #d0e8ff;
        }
      }
      .scrollBox {
        width: 100%;
        height: 140px;
        overflow: hidden;
        position: relative;

        .allBox {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 28px;
            box-sizing: border-box;
            padding: 0 20px;
            color: #d0e8ff;
            .storeName {
              width: calc((100% - 100px) / 2);
              box-sizing: border-box;
            }
            .channelName {
              width: calc((100% - 100px) / 2);
              box-sizing: border-box;
            }
            .onLine {
              width: 68px;
            }
            div {
              padding: 0 16px;
            }
          }
          .item:first-child {
            background: none;
            margin-top: 3px;
            color: #d0e8ff;
          }
        }
      }
    }
  }
  .rightBox {
    width: 560px;
    height: calc(100% - 80px);
    position: absolute;
    right: 36px;
    top: 80px;
    .channelNum {
      width: 100%;
      height: 32%;

      padding-top: 30px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .channelTop {
      width: 100%;
      height: 32%;
      margin-top: 14px;
      padding-top: 30px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .channelTimeTop {
      width: 100%;
      height: 32%;
      margin-top: 14px;
      padding-top: 30px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>