<!-- 下载配置弹窗 -->
<template>
  <el-dialog :title="
      '下载' +
        (type == 'add' ? '增加' : type == 'mod' ? '修改' : '删除') +
        ' 批量任务创建'
    " :visible.sync="dialogVisible" width="550px" @close="handleClose">
    <!-- 表单 -->
    <div>
      <el-form :model="form" ref="form" label-width="110px">
        <el-form-item label="下载项名称：" prop="name" :rules="[{ required: true, message: '下载项名称不能为空' }]">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="路径：" prop="x" v-if="type != 'del'">
          <upload-file ref="uploadFile" type="" @uploadChange="upload"></upload-file>
        </el-form-item>
        <el-form-item label="类型：" prop="y" v-if="type != 'del'">
          <el-select v-model="form.y" placeholder="">
            <el-option label="音乐" value="0"> </el-option>
            <el-option label="字体" value="1"> </el-option>
            <el-option label="视频" value="2"> </el-option>
            <el-option label="其他" value="3"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本说明：" prop="versionIntro" :rules="[{ required: true, message: '版本说明不能为空' }]">
          <el-input v-model="form.versionIntro"></el-input>
        </el-form-item>
        <el-form-item label="酒店选择：">
          <HotelSelect ref="hotelSelect" :noInsert="true" type='set' v-model="hotels"></HotelSelect>
          <span class="select_tip">(已选择{{ hotels.length }})</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">发 布</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import HotelSelect from "../../../common/operation/components/hotelSelect";
import UploadFile from "../../../common/operation/components/uploadFile";
import { downloadBatch, upload } from "../api/mul";
export default {
  name: "mulSetDownloadDialog",
  components: { HotelSelect, UploadFile },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "add",
    },
        pageType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      auth:null,
      dialogVisible: this.visible,
      form: {
        name: "",
        x: "",
        md5: "",
        y: "",
        versionIntro: "",
      },
      hotels: [],
    };
  },
  methods: {
    upload(file) {
      if (file == undefined || file == "") {
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      upload(formData).then((data) => {
        this.form.x = data.data.data.addr;
        this.form.md5 = data.data.data.md5;
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.$refs.hotelSelect.reset();
      if (this.$refs.uploadFile) {
        this.$refs.uploadFile.clearFile();
      }
    },
    submit() {
      if (
        this.form.name.indexOf("--") != -1 ||
        this.form.x.indexOf("--") != -1 ||
        this.form.y.indexOf("--") != -1
      ) {
        this.$message.warning("不可以有英文字符--，请用其他字符替换");
        return false;
      }
      if (
        this.form.name.indexOf("'") != -1 ||
        this.form.x.indexOf("'") != -1 ||
        this.form.y.indexOf("'") != -1
      ) {
        this.$message.warning("不可以有英文字符 '，请用其他字符替换");
        return false;
      }
      if (
        this.form.name.indexOf('"') != -1 ||
        this.form.x.indexOf('"') != -1 ||
        this.form.y.indexOf('"') != -1
      ) {
        this.$message.warning('不可以有英文字符 "，请用其他字符替换');
        return false;
      }
      if (this.hotels.length == 0) {
        this.$message.warning("请选择酒店后进行操作");
        return false;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            intro: this.form.versionIntro,
            updateType: this.pageType,
            action: this.type,
            hotel_str: JSON.stringify(this.hotels),
            music_name: this.form.name,
            music_addr: this.form.x,
            music_md5: this.form.md5,
            video_type: this.form.y,
             user_name:this.auth.user_name,
          };
          downloadBatch(params).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.dialogVisible = false;
              this.$emit("successmeth")
            } else {
              this.$message.error(data.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {   this.auth = JSON.parse(sessionStorage.getItem("auth"));},
};
</script>
<style lang="scss" scoped>
.select_tip {
  margin-left: 10px;
}
</style>
