<!-- apk配置新增/修改弹窗 -->
<template>
  <el-dialog title="apk管理" :visible.sync="dialogVisible" custom-class="oper-dia" width="550px" @open="open" @closed="handleClose">
    <el-form :model="form" label-width="100px" ref="form">
      <el-form-item label="版本号：" prop="version">
        <el-input v-model="form.version"></el-input>
      </el-form-item>
      <el-form-item label="apk：" prop="apk">
        <UploadApk ref="uploadFile"  @uploadApkInfo="uploadAPK"></UploadApk>
      </el-form-item>
      <el-form-item label="包名：" prop="package">
        <el-input v-model="form.package"></el-input>
      </el-form-item>
      <el-form-item label="签名：" prop="sign">
        <el-select v-model="form.sign" :disabled="current.id" placeholder="" style="width:100%">
          <el-option v-for="item in signList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="apk类型：" prop="apkType">
        <el-select v-model="form.apkType" placeholder="" style="width:100%">
          <el-option label="autoService" value="autoService"> </el-option>
          <el-option label="Ehotel" value="Ehotel"> </el-option>
          <el-option label="Upgrade" value="Upgrade"> </el-option>
          <el-option label="movieplayer" value="movieplayer"> </el-option>
          <el-option label="supertools" value="supertools"> </el-option>
          <el-option label="datamanage" value="datamanage"> </el-option>
          <el-option label="htripshop" value="htripshop"> </el-option>
          <el-option label="其他" value="other"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="releasinfo：" prop="releasinfo：">
        <el-input v-model="form.releasinfo"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import UploadApk from "../../../../components/common/operation/application/components/uploadApk.vue";
import { apkAdd, apkCheck } from "../api/apkSet";
// import UploadFile from "../../../common/operation/components/uploadFile";
export default {
  name: "apkSetOper",
  components: { UploadApk },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
    signList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      form: {
        version: "",
        apk: "",
        apkMd5: "",
        package: "",
        sign: "",
        apkType: 0,
        releasinfo: "",
      },
    };
  },
  methods: {
    open() {
      let obj = { ...this.current };
      if (JSON.stringify(this.current) != "{}") {
        this.form.version = obj.versionApk;
        this.form.apk = obj.apk_url;
        this.form.apkMd5 = obj.apk_md5;
        this.form.package = obj.apkPackage;
        this.form.sign = obj.signed_id;
        this.form.apkType = obj.typeApk;
        this.form.releasinfo = obj.releasinfo;
        // 文件名 回显apk上传组件
        // this.form.sign = this.current.nameApk;
      } else {
        this.form = {
          version: "",
          apk: "",
          apkMd5: "",
          package: "",
          sign: "",
          apkType: "",
          releasinfo: "",
        };
      }
    },
    uploadAPK(val) {
      this.form.apk = val.apkAddr;
      this.form.apkMd5 = val.apkMd5;
      this.form.package = val.eapk_package;
      // let formData = new FormData();
      // formData.append("filename", val.apkAddr);
      // formData.append("md5", val.apkMd5);
      // formData.append("apk_package", val.eapk_package);
      // apkCheck(formData).then((data) => {
      //   data;
      // });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.apk = "";
      this.form.apkMd5 = "";
      this.form.package = "";
      this.$refs.uploadFile.reset();
      
      this.dialogVisible = false
    },
    submit() {
      let params = {
        apkAddr: this.form.apk,
        versionApk: this.form.version,
        signedApk:
          this.form.sign +
          "#" +
          this.signList.find((item) => item.id == this.form.sign).name,
        typeApk: this.form.apkType,
        apkPackageNew: this.form.package,
        releasinfo: this.form.releasinfo,
        apkMd5: this.form.apkMd5,
      };
      if (JSON.stringify(this.current) != "{}") {
        params.id = this.current.id;
      }
      apkAdd(params).then((data) => {
        if (data.data.code == 100000) {
          this.$message.success(data.data.msg);
          this.handleClose();
          this.$emit("refresh");
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
