import request from '@/api/request';
import qs from "querystring";

export function addHotelGroup(data) {
    return request({
        url: `/v2/hotel_sub/add`,
        method: 'post',
        data: qs.stringify(data)
    });
}
export function editHotelSub(data) {
    return request({
        url: `/v2/hotel_sub/editHotelSub`,
        method: 'post',
        data: qs.stringify(data)
    });
}
// 获取酒店分组信息
export function getHotelGroup(data) {
    return request({
        url: `/v2/hotel_sub/getHotelSubGroup`,
        method: 'post',
        data: qs.stringify(data)
    });
}
// 获取酒店分组信息 -不含默认分组
export function getHotelInfo(data) {
    return request({
        url: `/v2/Hotelrooms/getHotelInfo`,
        method: 'post',
        data: qs.stringify(data)
    });
}
// 获取酒店所有分组信息
export function getHotelAllInfo(data) {
    return request({
        url: `v2/hotel_sub/getHotelGroup`,
        method: 'post',
        data: qs.stringify(data)
    });
}
// 获取酒店所有分组及其下房间号
export function getSubByHotelContract(data) {
    return request({
        url: `/v2/hotel/getSubByHotelContract`,
        method: 'post',
        data: qs.stringify(data)
    });
}
// 获取应用列表及状态接口
export function getAppList(data) {
    return request({
        url: `/v1/Apppublish/getAppList`,
        method: 'post',
        data: qs.stringify(data)
    });
}
// 获取最新应用接口  
export function getAppUpdate(data) {
    return request({
        url: `/v1/Apppublish/getAppUpdate`,
        method: 'post',
        data: qs.stringify(data)
    });
}