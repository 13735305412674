<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="API日志" name="first">
        <APILog :query="query"></APILog>
      </el-tab-pane>
      <el-tab-pane label="平台操作日志" name="second">
        <DailyLog :query="query"></DailyLog>
      </el-tab-pane>
  </el-tabs>
  </div>
</template>

// <script>
// import ApkList from "./ApkList.vue";
// import ServiceConfig from "./ServiceConfig.vue";
// import DownloadConfig from "./DownloadConfig.vue";
import APILog from "./APILog.vue";
import DailyLog from "./DailyLog.vue";
export default {
  props: {
    database: {
      type: String,
      default: 'hr800042'
    },
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  components: {
    APILog,
    DailyLog
  },
  data () {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick() {}
  }
}
</script>

<style>

</style>