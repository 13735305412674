<template >
  <div>
      <div class="model" v-if="firstLineInput" @click="closeModel"></div>
    <div class="welContentBox">
      <edit-btn @ishidden="isHidden" :upType="pageData.type" @recoverydata="recoveryData" :msg="'welVideo'" class="editBtnStyle" :hidden="btnhidden"></edit-btn>
      <video v-if="pageData.type==='video'" loop class="videoPlayer" @click="getVideoStatus" ref="videoPlayer" :src="pageData.url" controls></video>
      <img v-if="pageData.type==='pic'|| pageData.type==='img'" class="videoPlayer" @click="getVideoStatus" ref="videoPlayer" :src="pageData.url" controls>
      <div class="player" v-if="flag" @click="videoPaly"><i class="el-icon-video-play"></i></div>
        <div class="player paused"   @click="videoPause" v-if="!flag"><i class="el-icon-video-pause"></i></div>
      <div class="welBottom">
        <div class="welBottomLeft">
          <div class="imgShow">
            <img :src="pageData.chineseBtn" alt="">
            <img :src="pageData.englishBtn" alt="">
          </div>
          <div class="textShow">
            <p>按“OK”键进入系统主页</p>
            <p class="smallText">即为同意 <span> 账号使用规则和隐私政策 </span> &amp; <span> &copy; </span></p>
          </div>
        </div>
        <div class="welBottomCenter">
            <div v-if="!firstLineInput" @click="toInput">{{pageData.firstLine}}</div>
          <el-input @blur="showText" v-if="firstLineInput"  class="textInput" type="text" size="medium" placeholder="请输入内容"  v-model="pageData.firstLine">
          </el-input>
        </div>
        <div class="welBottomRight">
          <div class="info_one">
            <div class="top">{{curTime.time}}</div>
            <div class="bottom">{{curTime.date}}</div>
          </div>
          <div class="info_two">
            <div class="top">广州 晴</div>
            <div class="bottom">25~30℃</div>
          </div>
        </div>
      </div>
    </div>
  <to-upload :hotelContract="hotelContract" @upsuccess="upSuccess"></to-upload>
  </div>
</template>
<script>
import editBtn from "../common/editBtn";
import toUpload from "../common/toUpload"
import commonMeth from "@/api/method";
export default {
  components: {
    editBtn,
    toUpload,
  },
  props: {
    attrName:{
      type:String
    },
    hotelContract:{
      type:String
    },
    data: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
        flag:false,
      pageData: {},
      saveData:{},
       historyData:{},
      btnhidden: false,
      firstLineInput:false,
      curTime: {
        date: "",
        week: "",
        time: "",
      },
    };
  },
  methods: {
    // 上传文件
    upSuccess(data){
      this.flag=true;
        this.saveData[this.attrName].url =data.url;
        this.pageData.url =data.url;
        this.sendDataForVuex();
    },
    // 恢复文件
    recoveryData(val){
      if (val == "welVideo") {
          this.flag=true;
        this.saveData[this.attrName].url = this.historyData.url;
        this.pageData.url = this.historyData.url;
         this.sendDataForVuex(); 
        }
    },
    // 保存初始历史数据
     saveHistory(){
          this.historyData.url=this.data.url; 
      },
    // 保存修改信息至vuex
       sendDataForVuex(){
        this.$store.commit("saveVisualContent",this.saveData)
      },
      // 按钮
    isHidden(val) {
      if (val == "welVideo") {
        this.btnhidden = !this.btnhidden;
      }
    },
    //  修改欢迎语
    toInput(){
        this.firstLineInput=true;
    },
    // 修改欢迎语完毕
    showText(){
        this.firstLineInput=false;
        this.saveData[this.attrName].firstLine=this.pageData.firstLine;
        this.sendDataForVuex();
    },
    //  遮罩关闭
    closeModel(){
         this.firstLineInput=false;
    },
    // 视频播放--按钮
    videoPaly(){
        this.$refs.videoPlayer.play();
        this.flag = false;
    },
    // 视频暂停--按钮
    videoPause(){
         this.$refs.videoPlayer.pause();
         this.flag = true;
    },
    // 根据视频状态 控制按钮
    getVideoStatus(){
         this.flag =!this.$refs.videoPlayer.paused;
    },
  },
  created() {
    this.pageData = this.data;
  },
  mounted() {
      this.saveData[this.attrName]=this.data;
       this.saveHistory();
       this.sendDataForVuex();
      this.videoPause();
    setInterval(() => {
      this.curTime = commonMeth.getCurrentDate();
    }, 1000);
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.pageData = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
.welContentBox {
  width: 100%;
  height: 100%;
}
.videoPlayer {
  width: 100%;
  height: 100%;
}
.editBtnStyle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20%;
  height: 5%;
  z-index: 10;
}
.welBottom {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20%;
  width: 100%;
  padding: 0 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
}
.welBottomLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.imgShow {
  height: 30%;
}
.imgShow img {
  height: 100%;
  margin-right: 10%;
  width: auto;
}
.imgShow img:nth-child(2){
  margin-right: 0%;
}
.textShow {
  margin-top: 5%;
  font-size: 1.0vw;
}
.smallText {
  margin-top: 5%;
  font-size: 50%;
}
.smallText span {
  color: red;
}
.welBottomCenter {
  font-size:1.5vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 5px;
    z-index: 1800;
}
.welBottomCenter:hover {
  border: 2px dashed #4093ff;
      -moz-transition:2px dashed #4093ff;; 
      -webkit-transition:2px dashed #4093ff;; 
      -o-transition:2px dashed #4093ff;;
     
}
.textInput{
    position: relative;
    z-index: 5000;
}
>>>.el-input__inner{
    width: 100%;
    padding: 0;
     font-size: 1.5vw;
    background: transparent;
    border: none;
    color: #fff;
    position: relative;
    z-index: 2001;
}
.welBottomRight {
  height: 40%;
  display: flex;
  font-size: 1.8vw;
  justify-content: flex-start;
  align-items: center;
}
.info_two,
.info_one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_one{
    padding-right: 15px;
}
.info_two {
     padding-left: 15px;
  border-left: 1px solid #fff;
}
.info_two .top {
  margin-top: 7%;
}
.welBottomRight .bottom,
.info_two {
  font-size: 1.0vw;
}
.model{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 999;
}
 .player{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 80px;
        height: 80px;
        border-radius: 40px;
        font-size: 80px;
        line-height: 75px;
        text-align: center;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
    }
    .paused{
        display: none;
    }
    .welContentBox:hover .paused{
        display: block;
    }
</style>