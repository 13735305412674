<template>
    <div class="container">
        <div class="main">
            <div class="top">
                <i class="el-icon-success"></i>
                <div class="text">恭喜您，信息提交成功！</div>
            </div>
            <!--<div class="bottom">-->
                <!--<el-button type="primary" class="loginBtn" @click="toLogin">点击登录</el-button>-->
            <!--</div>-->
            <div>系统将在{{second}}秒后自动跳转到首页，如果没有请 <span @click="toIndex" class="toIndex">点击手动跳转</span></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RegisterSuccess",
        data(){
            return {
                second:5
            }
        },
        methods:{
            toLogin(){
                this.$router.push({path:'/login'})
                // this.$router.push({path:'/indexShow'})
            },
            toIndex(){
                this.$router.push({path:'/indexShow'})
            },
        },
        mounted(){
            var interval = setInterval(() => {
                --this.second
            }, 1000);
            setTimeout(() => {
                clearInterval(interval);
                this.second = 5;
                this.toIndex();
            }, 5000);
        }
    }
</script>

<style scoped>
    .container{
        width:100vw;
        height:100vh;
        background:#f2f2f2;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .main{
        width:900px;
        height:300px;
        background:#fff;
        display:flex;
        align-content: center;
        flex-direction:column;
        padding:0 200px;
        box-sizing: border-box;
        justify-content: center;
    }
    .top,.bottom{
        display:flex;
        justify-content: center;
        align-items: center;
        /*align-items: ;*/
    }
    .loginBtn{

    }
    .top{
        margin-bottom:30px;
    }

    .top>i{
        font-size:40px;
        color:#66b1ff;
        margin-right:10px;
    }
    .loginBtn{
        width:140px;
    }

    .toIndex{
        color:#66b1ff;
        margin-left:5px;
        cursor:pointer;
    }


</style>