<script src="../../api/label1.js"></script>
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">首页</span>
      </top-bread>
      <div class="right">
        <div class="btnBox">
          <el-button class="largeBtn blueBtn" @click="newBuild" :disabled="auth.type!=='htrip'" v-if="showFunModel('创建酒店')" index="">创建酒店</el-button>
          <el-button class="largeBtn blueBtn" @click="incomeSta" v-if="showFunModel('收益统计')">收益统计</el-button>
          <el-button class="largeBtn blueBtn" @click="performanceSta" v-if="showFunModel('业绩完成统计')">上线终端统计</el-button>
        </div>
      </div>
    </div>
    <div v-if="topcard">
      <el-card class="searchBox">
        <div class="quickDateSearch">
          <div class="groupFilter">
            <div class="item" v-if="btn.belong" :class="[natureCurActive('belong'),]" @click="search('belong')">所属</div>
            <div class="item" v-if="btn.allot" :class="[natureCurActive('allot')]" @click="search('allot')">归属</div>
            <div class="item" v-if="btn.subordinate" :class="[natureCurActive('subordinate')]" @click="search('subordinate')">下级</div>
          </div>
          <div class="groupFilter">
            <div class="item" v-if="btn.self" :class="[natureCurActive('self')]" @click="search('self')">自有</div>
            <div class="item" v-if="btn.other" :class="[natureCurActive('other')]" @click="search('other')">聚屏</div>
            <div class="item" v-if="btn.videoRoom" :class="[natureCurActive('videoRoom')]" @click="search('videoRoom')">影音房</div>
            <div class="item" v-if="btn.virtual" :class="[natureCurActive('virtual')]" @click="search('virtual')">虚拟运营</div>
          </div>
        </div>
      </el-card>
    </div>
    <el-card class="box-card" v-if="(showFunModel('数据概览') || showFunModel('总收益'))&&auth.type=='htrip' " v-loading="showLoading">
      <div class=" rowBox vertical">
        <div class="titleBox">
          <div class="title blueTitle">终端数据概览</div>
        </div>
        <el-row :gutter="10">
          <index-four-row-num-unit :color="greenColor" :iconShow="iconShow" :span="spanNum" v-if="showFunModel('数据概览')" :parShowDate="showData.hotelNum" parShowUnit="">
            <span slot="numTitle">酒店数量</span>
            <span slot="num">{{showDataCom(showData.hotelNum)}}</span>
            <span slot="unit">家</span>
            <span slot="time">【昨日】</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="orangeColor" :iconShow="iconShow" :span="spanNum" v-if="showFunModel('数据概览')" :parShowDate="showData.roomNum" parShowUnit="">
            <span slot="numTitle">客房数量</span>
            <span slot="num">{{showDataCom(showData.roomNum)}}</span>
            <span slot="unit">间</span>
            <span slot="time">【昨日】</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="blueColor" :iconShow="iconShow" :span="spanNum" v-if="showFunModel('数据概览')" :parShowDate="showData.activeTerminalNum" parShowUnit="">
            <span slot="numTitle">激活终端数量</span>
            <span slot="num">{{showDataCom(showData.activeTerminalNum)}}</span>
            <span slot="unit">台</span>
            <span slot="time">【昨日】</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="redColor" :iconShow="iconShow" :span="spanNum" v-if="showFunModel('总收益')" :parShowDate="showData.totalIncome" parShowUnit="money">
            <span slot="numTitle">本年预估总收益</span>
            <span slot="num">{{showDataCom(showData.totalIncome,'money')}}</span>
            <span slot="unit">元</span>
            <span slot="time">【截止到昨日】</span>
          </index-four-row-num-unit>
        </el-row>
      </div>
    </el-card>
    <el-card class="box-card" v-if="showFunModel('渠道终端概览') &&accountType==5&&auth.type=='channel' " v-loading="showLoading">
      <div class=" rowBox vertical">
        <div class="titleBox">
          <div class="title blueTitle">终端数据概览</div>
        </div>
        <el-table :data="thisYearData" style="width: 100%; margin-top: 10px" :header-cell-style="headerStyle" class="top_table_header">
          <el-table-column prop='year' show-overflow-tooltip min-width="80px">
            <template slot="header">
              <table>
                <tbody>
                  <tr>
                    <td class="slash">
                      <span style="position: absolute;left: 6px;bottom: 4px;z-index: 1;">年份</span>
                      <span style="position: absolute;right: 6px;top: 4px;z-index: 1;">项目</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </el-table-column>
              <el-table-column prop='new_add_hotel_num' label="新增酒店数量" show-overflow-tooltip min-width="100px"></el-table-column>
          <el-table-column prop='new_add_room_num' label="新增激活终端数量" show-overflow-tooltip min-width="100px"></el-table-column>
          <el-table-column prop='total_hotel_num' label="累计酒店数量" show-overflow-tooltip min-width="100px"></el-table-column>
          <el-table-column prop='total_room_num' label="累计激活终端数量" show-overflow-tooltip min-width="100px"></el-table-column>
          <el-table-column prop='stop_hotel_num' label="累计停止合作酒店数量" show-overflow-tooltip min-width="100px"></el-table-column>
          <el-table-column prop='stop_room_num' label="累计停止合作激活终端数量" show-overflow-tooltip min-width="100px"></el-table-column>
        </el-table>
        <div>
          <div v-if="showHistoryYearData==false&&dataOverviewShowData.length>1" style="margin:10px 0">
            <el-link type="primary" @click="showHistoryYear('show')" class="show_year">展开历史年份</el-link>
          </div>
          <div v-if="showHistoryYearData==true" style="margin:10px 0">
            <el-link type="primary" @click="showHistoryYear('hidden')" class="show_year">收起历史年份</el-link>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" v-if="showFunModel('渠道合同列表') && accountType==5&&auth.type=='channel'">
      <div class="title blueTitle">合同信息概览</div>
      <contractListShow></contractListShow>
    </el-card>
    <el-card class="box-card" v-if="showFunModel('渠道收益概览') && accountType==5&&auth.type=='channel'" v-loading="showLoading">
      <div class="title blueTitle">收益数据概览 </div>
      <dataShowBox :tableList="dataOverviewList"></dataShowBox>
    </el-card>

    <el-card class="box-card" v-if="showFunModel('点播收益') || showFunModel('售卡收益')" v-loading="showLoading">
      <div class=" rowBox vertical" v-if="showFunModel('点播收益')">
        <div class="title blueTitle">影视预估收益</div>
        <el-row :gutter="10">
          <index-four-row-num-unit :color="greenColor" :iconShow="iconShow" :parShowDate="showData.movieYesterdayIncome" parShowUnit="money">
            <span slot="numTitle">单点昨日收益</span>
            <span slot="num">{{showDataCom(showData.movieYesterdayIncome,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="orangeColor" :iconShow="iconShow" :parShowDate="showData.movieLastWeekIncome" parShowUnit="money">
            <span slot="numTitle">单点本周收益</span>
            <span slot="num">{{showDataCom(showData.movieLastWeekIncome,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="blueColor" :iconShow="iconShow" :parShowDate="showData.movieCurrentMonthIncome" parShowUnit="money">
            <span slot="numTitle">单点本月收益</span>
            <span slot="num">{{showDataCom(showData.movieCurrentMonthIncome,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="redColor" :iconShow="iconShow" :parShowDate="showData.movieTotalIncome" parShowUnit="money">
            <span slot="numTitle">单点总收益</span>
            <span slot="num">{{showDataCom(showData.movieTotalIncome,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
        </el-row>
        <el-row :gutter="10">
          <index-four-row-num-unit :color="greenColor" :iconShow="iconShow" :parShowDate="showData.movieYesterdayIncomeDay" parShowUnit="money">
            <span slot="numTitle">天卡昨日收益</span>
            <span slot="num">{{showDataCom(showData.movieYesterdayIncomeDay,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="orangeColor" :iconShow="iconShow" :parShowDate="showData.movieLastWeekIncomeDay" parShowUnit="money">
            <span slot="numTitle">天卡本周收益</span>
            <span slot="num">{{showDataCom(showData.movieLastWeekIncomeDay,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="blueColor" :iconShow="iconShow" :parShowDate="showData.movieCurrentMonthIncomeDay" parShowUnit="money">
            <span slot="numTitle">天卡本月收益</span>
            <span slot="num">{{showDataCom(showData.movieCurrentMonthIncomeDay,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="redColor" :iconShow="iconShow" :parShowDate="showData.movieTotalIncomeDay" parShowUnit="money">
            <span slot="numTitle">天卡总收益</span>
            <span slot="num">{{showDataCom(showData.movieTotalIncomeDay,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
        </el-row>
      </div>

      <!--<div class=" rowBox vertical" style="margin-top:20px;">-->
      <!--<div class="title">广告收益</div>-->
      <!--<el-row :gutter="20">-->
      <!--<index-four-row-num-unit :color="greenColor">-->
      <!--<span slot="numTitle">广告昨日收益</span>-->
      <!--<span slot="num" >{{showDataCom(showData.advertisingYesterdayIncome)}}</span>-->
      <!--<span slot="unit">元</span>-->
      <!--</index-four-row-num-unit>-->
      <!--<index-four-row-num-unit  :color="orangeColor">-->
      <!--<span slot="numTitle">广告本周收益</span>-->
      <!--<span slot="num" >{{showDataCom(showData.advertisingLastWeekIncome)}}</span>-->
      <!--<span slot="unit">元</span>-->
      <!--</index-four-row-num-unit>-->
      <!--<index-four-row-num-unit  :color="blueColor">-->
      <!--<span slot="numTitle">广告本月收益</span>-->
      <!--<span slot="num" >{{showDataCom(showData.advertisingCurrentMonthIncome)}}</span>-->
      <!--<span slot="unit">元</span>-->
      <!--</index-four-row-num-unit>-->
      <!--<index-four-row-num-unit  :color="redColor">-->
      <!--<span slot="numTitle">广告总收益</span>-->
      <!--<span slot="num" >{{showDataCom(showData.advertisingTotalIncome)}}</span>-->
      <!--<span slot="unit">元</span>-->
      <!--</index-four-row-num-unit>-->
      <!--</el-row>-->
      <!--</div>-->
      <!--<div class=" rowBox vertical tenMarginTop"  v-if="auth==='group'">-->
      <!--            <div class=" rowBox vertical " :class="showFunModel('售卡收益')&&showFunModel('点播收益') ?'marginTop':''" v-if="showFunModel('售卡收益')">-->
      <!--                <div class="title blueTitle">售卡收益</div>-->
      <!--                <el-row :gutter="10">-->
      <!--                    <index-four-row-num-unit  :color="greenColor" :iconShow="iconShow" :parShowDate="showData.cardYesterdayIncome" parShowUnit="money">-->
      <!--                        <span slot="numTitle">售卡昨日收益</span>-->
      <!--                        <span slot="num">{{showDataCom(showData.cardYesterdayIncome,'money')}}</span>-->
      <!--                        <span slot="unit">元</span>-->
      <!--                    </index-four-row-num-unit>-->
      <!--                    <index-four-row-num-unit  :color="orangeColor" :iconShow="iconShow" :parShowDate="showData.cardLastWeekIncome" parShowUnit="money">-->
      <!--                        <span slot="numTitle">售卡本周收益</span>-->
      <!--                        <span slot="num" >{{showDataCom(showData.cardLastWeekIncome,'money')}}</span>-->
      <!--                        <span slot="unit">元</span>-->
      <!--                    </index-four-row-num-unit>-->
      <!--                    <index-four-row-num-unit  :color="blueColor" :iconShow="iconShow" :parShowDate="showData.cardCurrentMonthIncome" parShowUnit="money">-->
      <!--                        <span slot="numTitle">售卡本月收益</span>-->
      <!--                        <span slot="num" >{{showDataCom(showData.cardCurrentMonthIncome,'money')}}</span>-->
      <!--                        <span slot="unit">元</span>-->
      <!--                    </index-four-row-num-unit>-->
      <!--                    <index-four-row-num-unit  :color="redColor" :iconShow="iconShow" :parShowDate="showData.cardTotalIncome" parShowUnit="money">-->
      <!--                        <span slot="numTitle">售卡总收益</span>-->
      <!--                        <span slot="num" >{{showDataCom(showData.cardTotalIncome,'money')}}</span>-->
      <!--                        <span slot="unit">元</span>-->
      <!--                    </index-four-row-num-unit>-->
      <!--                </el-row>-->
      <!--            </div>-->
    </el-card>
    <el-card class="box-card" v-loading="showLoading" v-if="showFunModel('独立影视收益')">
      <!-- v-if="showFunModel('点播收益')" -->
      <div class=" rowBox vertical">
        <div class="title blueTitle">独立影视预估收益</div>
        <el-row :gutter="10">
          <index-four-row-num-unit :color="greenColor" :iconShow="iconShow" :parShowDate="showData.movie_income_yesterday_one" parShowUnit="money">
            <span slot="numTitle">单点昨日收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_yesterday_one,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="orangeColor" :iconShow="iconShow" :parShowDate="showData.movie_income_week_one" parShowUnit="money">
            <span slot="numTitle">单点本周收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_week_one,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="blueColor" :iconShow="iconShow" :parShowDate="showData.movie_income_month_one" parShowUnit="money">
            <span slot="numTitle">单点本月收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_month_one,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="redColor" :iconShow="iconShow" :parShowDate="showData.movie_income_total_one" parShowUnit="money">
            <span slot="numTitle">单点总收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_total_one,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
        </el-row>
        <el-row :gutter="10">
          <index-four-row-num-unit :color="greenColor" :iconShow="iconShow" :parShowDate="showData.movie_income_yesterday_day" parShowUnit="money">
            <span slot="numTitle">天卡昨日收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_yesterday_day,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="orangeColor" :iconShow="iconShow" :parShowDate="showData.movie_income_week_day" parShowUnit="money">
            <span slot="numTitle">天卡本周收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_week_day,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="blueColor" :iconShow="iconShow" :parShowDate="showData.movie_income_month_day" parShowUnit="money">
            <span slot="numTitle">天卡本月收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_month_day,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :color="redColor" :iconShow="iconShow" :parShowDate="showData.movie_income_total_day" parShowUnit="money">
            <span slot="numTitle">天卡总收益</span>
            <span slot="num">{{showDataCom(showData.movie_income_total_day,'money')}}</span>
            <span slot="unit">元</span>
          </index-four-row-num-unit>
        </el-row>
      </div>
    </el-card>
    <el-row :gutter="10" class="rowBox chartBox tenMarginTop" v-if="showFunModel('数据走势') || showFunModel('待办事项')">
      <el-col :span="showFunModel('待办事项')?14:24" class="chartCol " v-if="showFunModel('数据走势')" v-loading="lineLoading">
        <div class="shadowBox" style="height:400px;background:#fff;">
          <index-show-echarts :chart="lineChart" :chart2="lineChart2" :chart1Show="showFunModel('数据走势')" :chart2Show="showFunModel('独立影视收益')" v-if="(lineChart.flag && !lineChart.noData)||(lineChart2.flag && !lineChart2.noData)"></index-show-echarts>
          <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center;flex:1">暂无数据</div>
        </div>
      </el-col>
      <el-col :span="10" class="listBox " v-if="showFunModel('待办事项')" v-loading="tableLoading">
        <div class="grid-content bg-purple listCon shadowBox">
          <div class="title">
            <div class="blueTitle">待办事项</div>
            <!--声明式导航-->
            <router-link class="blueText" to="/indexDoMore">更多</router-link>
          </div>
          <div v-if="table.noData" style="" class="minFont noData">暂无数据</div>
          <div class="container" v-if="!table.noData">
            <div class="item" :key="index" v-for="(item,index) in table.tableList" @click="reviewClick(item.id,item.type,item.status,item.review_do_msg,item.hotel_id)">
              <div class="top">
                <div class="name">{{item.review_do_msg}}</div>
              </div>
              <div class="bottom">
                <div class="type minFont">{{item.review_title}}</div>
                <div class="time minFont">{{item.createtime}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
  </div>

</template>

<script>
import echarts from "echarts";
import GLOBAL from "../../api/globalVar.js";
import commonMeth from "../../api/method";
import qs from "querystring";

export default {
  name: "indexShow",
  data: () => ({
    activeName: "all",
    topcard: true,
    natureCur: [],
    accountType: "",
    indexImg: "greenLine",
    bread: {
      level: 2,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/hotelManAll" },
      fourthPath: { path: "/hotelManAll" },
    },
    lineChart: {
      screenType: "",
      id: "lineChart",
      showTitle: true,
      apiUrl: "",
      flag: false,
      noData: false,
      fromUrl: "indexShow",
      option: {
        legend: {
          icon: "circle",
          // left:'center',
          // top:'center',
          data: [],
        },
        tooltip: {
          // formatter:''
        },
        xData: [],
        series: [
          {
            name: "单点收益",
            type: "line",
            smooth: false,
            itemStyle: {
              normal: {
                color: GLOBAL.chartColor[0], //改变折线点的颜色
                lineStyle: {
                  color: GLOBAL.chartColor[0], //改变折线颜色
                },
              },
            },
            data: [],
          },
          {
            name: "日活率",
            type: "line",
            smooth: false,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: GLOBAL.chartColor[1], //改变折线点的颜色
                lineStyle: {
                  color: GLOBAL.chartColor[1], //改变折线颜色
                },
              },
            },
            data: [],
          },
          {
            name: "天卡收益",
            type: "line",
            smooth: false,
            yAxisIndex: 2,
            itemStyle: {
              normal: {
                color: GLOBAL.chartColor[2], //改变折线点的颜色
                lineStyle: {
                  color: GLOBAL.chartColor[2], //改变折线颜色
                },
              },
            },
            data: [],
          },
        ],
      },
    },
    lineChart2: {
      screenType: "",
      id: "lineChart2",
      showTitle: true,
      apiUrl: "",
      flag: false,
      noData: false,
      fromUrl: "indexShow",
      option: {
        legend: {
          icon: "circle",
          data: [],
        },
        tooltip: {},
        xData: [],
        series: [
          {
            name: "单点收益",
            type: "line",
            smooth: false,
            itemStyle: {
              normal: {
                color: GLOBAL.chartColor[2], //改变折线点的颜色
                lineStyle: {
                  color: GLOBAL.chartColor[2], //改变折线颜色
                },
              },
            },
            data: [],
          },
          {
            name: "天卡收益",
            type: "line",
            smooth: false,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: GLOBAL.chartColor[3], //改变折线点的颜色
                lineStyle: {
                  color: GLOBAL.chartColor[3], //改变折线颜色
                },
              },
            },
            data: [],
          },
        ],
      },
    },
    showData: {
      totalIncome: 0,
      estimateIncome: 0,
      movieYesterdayIncome: 0,
      movieLastWeekIncome: 0,
      movieCurrentMonthIncome: 0,
      movieTotalIncome: 0,
      movieYesterdayIncomeDay: 0,
      movieLastWeekIncomeDay: 0,
      movieCurrentMonthIncomeDay: 0,
      movieTotalIncomeDay: 0,
      advertisingYesterdayIncome: 0,
      advertisingLastWeekIncome: 0,
      advertisingCurrentMonthIncome: 0,
      advertisingTotalIncome: 0,
      cardYesterdayIncome: 0,
      cardLastWeekIncome: 0,
      cardCurrentMonthIncome: 0,
      cardTotalIncome: 0,
      hotelNum: 0,
      roomNum: 0,
      activeTerminalNum: 0,
      movie_income_month_day: 0,
      movie_income_month_one: 0,
      movie_income_total_day: 0,
      movie_income_total_one: 0,
      movie_income_week_day: 0,
      movie_income_week_one: 0,
      movie_income_yesterday_day: 0,
      movie_income_yesterday_one: 0,
    },
    btn: {},
    table: {
      tableList: [],
      page: 1,
      limit: 4,
      noData: false,
    },
    token: "",
    auth: "",
    loadFlag: "",
    showLoading: true,
    lineLoading: true,
    tableLoading: true,
    greenColor: "green",
    orangeColor: "orange",
    blueColor: "blue",
    redColor: "red",
    iconShow: true,
    allData: {},
    funArr: [],
    spanNum: 0,
    screenType: "",
    showPart: {},
    allLoadingState: true,
    dataOverviewList: [],
    dataOverviewShow: {},
    thisYearData: [],
    showHistoryYearData: false,
    historyYearData: [],
    dataOverviewShowData: [],
    // 表格头样式
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
  }),
  computed: {
    natureCurActive() {
      return function (val) {
        var str = "";
        if (this.natureCur) {
          if (this.natureCur.indexOf(val) !== -1) {
            str = "active";
          }
        }
        return str;
      };
    },
    contractLift() {
      //合同有效期
      if (this.showData.contractLift === "~") {
        return "暂未设置";
      } else {
        return this.showData.contractLift;
      }
    },
    showDataCom() {
      return function (s, type = "", n = 2) {
        if (type === "money") {
          if (s === null) {
            return 0;
          } else {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
            var l = s.split(".")[0].split("").reverse(),
              r = s.split(".")[1];
            var t = "";
            for (var i = 0; i < l.length; i++) {
              t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
            }
            return t.split("").reverse().join("") + "." + r;
          }
        } else {
          if (s === null) {
            return 0;
          } else {
            return parseFloat(s).toLocaleString();
          }
        }
      };
    },
    twoPointCom() {
      return function (x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
          return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf(".");
        if (rs < 0) {
          rs = s.length;
          s += ".";
        }
        while (s.length <= rs + 2) {
          s += "0";
        }
        return s;
      };
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(
        false,
        false,
        this.showLoading
      );
    },
  },
  methods: {
    // 判断隐藏展开历史年份
    showHistoryYear(val) {
      if (val == "show") {
        this.thisYearData = [];
        for (var i = this.dataOverviewShowData.length; i > 0; i--) {
          this.thisYearData.push(this.dataOverviewShowData[i - 1]);
        }
        this.showHistoryYearData = true;
      } else {
        this.thisYearData = [];
        this.thisYearData.push(
          this.dataOverviewShowData[this.dataOverviewShowData.length - 1]
        );
        this.showHistoryYearData = false;
      }
    },
    //查询
    search(type) {
      var index = this.natureCur.indexOf(type);
      if (index !== -1) {
        this.natureCur.splice(index, 1);
      } else {
        this.natureCur.push(type);
      }
      var type = JSON.parse(JSON.stringify(this.natureCur));
      this.screenType = type.join(",");
      this.lineChart.screenType = type.join(",");
      this.getLineChart();
      this.showDataMeth();
      this.showChannelDataMeth();
      this.tableMeth();
    },
   
    incomeSta() {
      this.setDefaultActiveId("54");
      this.$router.push({ path: "/incomeSta" });
    },
    performanceSta() {
      this.setDefaultActiveId("55");
      this.$router.push({ path: "/performanceSta" });
    },
    //数据统计
    showDataMeth() {
      this.showLoading = true;
      this.$http
        .post(this.global.getIndexStatistics, qs.stringify(this.getParam()))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showData = res.data.data;
            this.loadFlag++;
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    //渠道商数据统计
    showChannelDataMeth() {
      this.showLoading = true;
      this.$http
        .post(
          this.global.getIndexContractStatistics,
          qs.stringify(this.getParam())
        )
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            res.data.data.contractList.forEach((i) => {
              if (i.contractInfo == "total") {
                i.contractInfo = "总计";
              }
            });
            this.dataOverviewList = res.data.data.contractList;
            this.dataOverviewShow = res.data.data.newAddNumData;
            for (let [key, value] of Object.entries(this.dataOverviewShow)) {
              var data = {
                year: key,
                new_add_hotel_num: value.new_add_hotel_num,
                new_add_room_num: value.new_add_room_num,
                stop_hotel_num: value.stop_hotel_num
                  ? value.stop_hotel_num
                  : "-",
                stop_room_num: value.stop_room_num ? value.stop_room_num : "-",
                total_hotel_num: value.total_hotel_num,
                total_room_num: value.total_room_num,
              };
              this.this.thisYearData = [].push(data);
            }
            this.thisYearData.push(
              this.dataOverviewShowData[this.dataOverviewShowData.length - 1]
            );
            console.log(this.thisYearData);
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    //首次刷新数据统计
    showDataMethfirst() {
      this.showLoading = true;
      this.$http
        .post(
          this.global.getIndexStatistics,
          qs.stringify(this.getParamfrist())
        )
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showData = res.data.data;
            this.loadFlag++;
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    //首次刷新渠道商数据统计
    showChannelDataMethfirst() {
      this.showLoading = true;
      this.$http
        .post(
          this.global.getIndexContractStatistics,
          qs.stringify(this.getParamfrist())
        )
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            res.data.data.contractList.forEach((i) => {
              if (i.contractInfo == "total") {
                i.contractInfo = "总计";
              }
            });
            this.dataOverviewList = res.data.data.contractList;
            this.dataOverviewShow = res.data.data.newAddNumData;
            for (let [key, value] of Object.entries(this.dataOverviewShow)) {
              var data = {
                year: key,
                new_add_hotel_num: value.new_add_hotel_num,
                new_add_room_num: value.new_add_room_num,
                stop_hotel_num: value.stop_hotel_num
                  ? value.stop_hotel_num
                  : "-",
                stop_room_num: value.stop_room_num ? value.stop_room_num : "-",
                total_hotel_num: value.total_hotel_num,
                total_room_num: value.total_room_num,
              };
              this.dataOverviewShowData.push(data);
            }
            this.thisYearData.push(
              this.dataOverviewShowData[this.dataOverviewShowData.length - 1]
            );
            console.log(this.thisYearData);
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    //折线图
    getLineChart() {
      this.lineLoading = true;
      this.lineChart.flag = false;
      this.$http
        .post(this.global.getIndexLine, qs.stringify(this.getParam()))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.lineChart.flag = true;
            var chartData = res.data.data;
            if (chartData.series) {
              this.lineChart.option.legend = chartData.legend; //图例
              this.lineChart.option.xData = chartData.xAxis; //x轴
              var dataTransEd = commonMeth.dataTrans(chartData);
              this.lineChart.option.series[0].data =
                chartData.series["点播收益"]; //x轴
              this.lineChart.option.series[1].data = chartData.series["日活率"]; //x轴
              this.lineChart.option.series[2].data =
                chartData.series["点播天卡收益"]; //x轴
            } else {
              this.lineChart.noData = true;
            }
            this.lineChart2.flag = true;
            if (chartData.seriesIn) {
              this.lineChart2.option.legend = chartData.legendIn; //图例
              this.lineChart2.option.xData = chartData.xAxis; //x轴
              var dataTransEd = commonMeth.dataTrans(chartData);
              this.lineChart2.option.series[1].data =
                chartData.seriesIn["天卡收益"]; //x轴
              this.lineChart2.option.series[0].data =
                chartData.seriesIn["单点收益"]; //x轴
            } else {
              this.lineChart2.noData = true;
            }
            // this.loadFlag++;
          } else {
            this.$message.error("折线图获取失败");
          }
          this.lineLoading = false;
        });
    },
    //首次刷新折线图
    getLineChartfirst() {
      this.lineLoading = true;
      this.lineChart.flag = false;
      this.lineChart2.flag = false;
      this.$http
        .post(this.global.getIndexLine, qs.stringify(this.getParamfrist()))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.lineChart.flag = true;
            var chartData = res.data.data;
            if (chartData.series) {
              this.lineChart.option.legend = chartData.legend; //图例
              this.lineChart.option.xData = chartData.xAxis; //x轴
              var dataTransEd = commonMeth.dataTrans(chartData);
              this.lineChart.option.series[0].data =
                chartData.series["点播收益"]; //x轴
              this.lineChart.option.series[1].data = chartData.series["日活率"]; //x轴
              this.lineChart.option.series[2].data =
                chartData.series["点播天卡收益"]; //x轴
            } else {
              this.lineChart.noData = true;
            }

            this.lineChart2.flag = true;
            if (chartData.seriesIn) {
              this.lineChart2.option.legend = chartData.legendIn; //图例
              this.lineChart2.option.xData = chartData.xAxis; //x轴
              var dataTransEd = commonMeth.dataTrans(chartData);
              this.lineChart2.option.series[1].data =
                chartData.seriesIn["天卡收益"]; //x轴
              this.lineChart2.option.series[0].data =
                chartData.seriesIn["单点收益"]; //x轴
            } else {
              this.lineChart2.noData = true;
            }
            // this.loadFlag++;
          } else {
            this.$message.error("折线图获取失败");
          }
          this.lineLoading = false;
        });
    },
    //待办
    tableMeth() {
      this.tableLoading = true;
      this.$http
        .get(this.global.getReviewListToDo, {
          params: this.getParam(),
        })
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var tableData = res.data.data;
            if (tableData.length !== 0) {
              this.table.tableList = tableData;
              // this.table.total=tableData.total;
              this.table.noData = false;
            } else {
              this.table.noData = true;
            }
            this.loadFlag++;
          } else {
            this.table.noData = true;
          }
          this.tableLoading = false;
        });
    },
    reviewClick(id, type, status, createType, hotelId) {
      if (type === "register") {
        //注册审核
        this.$router.push({
          path: "/indexDoDetail",
          query: { id: id, reviewStatus: status, fromUrl: "indexShow" },
        });
      } else if (type === "partner") {
        //资质
        this.$router.push({
          path: "./indexDoQualify",
          query: { id: id, reviewStatus: status, fromUrl: "indexShow" },
        });
      } else if (type === "check") {
        //reconciliation
        this.$router.push({
          path: "/indexDoBrushChange",
          query: { id: id, reviewStatus: status, fromUrl: "indexShow" },
        });
      } else if (type === "rootReceiving") {
        //刷机
        this.$router.push({
          path: "/indexDoBrush",
          query: { id: id, reviewStatus: status, fromUrl: "indexShow" },
        });
      } else if (type === "partnerConfig") {
        this.$router.push({
          path: "./indexDoCoop",
          query: {
            id: id,
            reviewStatus: status,
            fromUrl: "indexShow",
          },
        });
      } else if (
        type === "reconciliation" ||
        type === "independentReconciliation"
      ) {
        //indexDoAdOrder
        this.$router.push({
          path: "/IndexDoReconChange",
          query: {
            id: id,
            reviewStatus: status,
            createType: createType,
            type: type,
            editType: "check",
            fromUrl: "indexShow",
          },
        });
      } else if (
        type === "partnerWithdraw" ||
        type === "independentWithdraw" ||
        type === "youkeWithdraw"
      ) {
        this.$router.push({
          // indexDoWithdraw  indexDoAdOrder
          path: "/indexDoWithdraw",
          query: {
            id: id,
            type: type,
            reviewStatus: status,
            editType: "check",
            fromUrl: "indexShow",
          },
        });
      } else if (type === "adFrequency") {
        this.$router.push({
          path: "./indexDoAdOrder",
          query: { id: id, reviewStatus: status, fromUrl: "indexShow" },
        });
      } else if (type === "visual") {
        this.$router.push({
          path: "./indexDoVisual",
          query: {
            id: id,
            reviewStatus: status,
            fromUrl: "indexShow",
            hotelId: hotelId,
          },
        });
      }
    },
    dataTrans(chartData) {
      for (let key in chartData.series) {
        for (let a in chartData.series[key]) {
          if (!chartData.series[key][a]) {
            chartData.series[key][a] = 0;
          }
        }
      }
      return chartData;
    },
    newBuild() {
      this.$router.push("/hotelManNew");
    },
    twoPoint(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    getGroupLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.groupLevel, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.curId = res.data.data.pid[0];
            }
            resolve(res.data.data.pid[0]);
          });
      });
    },
    getTreeData() {
      this.$http
        .get(this.global.getUserRuleLists, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var treeData = res.data.data;
            this.allData = treeData;
            this.allData.forEach((item, index) => {
              var menuId = sessionStorage.getItem("defaultActiveId");
              if (item.parent == menuId) {
                this.funArr.push(item.text);
              }
            });
          }
        });
    },
    showFunModelCondition() {
      if (
        this.funArr.indexOf("数据概览") !== -1 &&
        this.funArr.indexOf("总收益") !== -1
      ) {
        this.spanNum = 6;
      } else if (
        this.funArr.indexOf("数据概览") !== -1 &&
        this.funArr.indexOf("总收益") === -1
      ) {
        this.spanNum = 8;
      } else if (
        this.funArr.indexOf("数据概览") === -1 &&
        this.funArr.indexOf("总收益") !== -1
      ) {
        this.spanNum = 24;
      }
    },
    setDefaultActiveId(id) {
      sessionStorage.setItem("defaultActiveId", id);
    },
    //按钮开屏选中
    screenTypeSearch() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
        switch (this.accountType) {
          case 1:
            this.natureCur = ["self"];
            break;
          case 2:
            this.natureCur = ["self"];
            break;
          case 3:
            this.accountType = "3";
            this.topcard = false;
            break;
          case 4:
            this.topcard = false;
            accountType: this.accountType;
            break;
          case 5:
            this.natureCur = ["belong"];
            break;
          case 6:
            this.natureCur = ["belong"];
        }
      });
    },
    //控制按钮显示
    getScreenTypeSearchShow() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
        switch (this.accountType) {
          case 1:
            this.btn = {
              self: true,
              other: true,
              videoRoom: true,
            };
          case 2:
            this.btn = {
              self: true,
              other: true,
              videoRoom: true,
            };
            break;
          case 3:
            this.btn = {
              self: true,
            };
            break;
          case 4:
            this.topcard = false;
            break;
          case 5:
            this.btn = {
              belong: true,
              // subordinate: true,
            };
            break;
          case 6:
            this.btn = {
              allot: true,
              belong: true,
              subordinate: true,
            };
        }
      });
    },
    getParam() {
      var param = {};
      param = {
        token: this.token,
        screenType: this.screenType,
        accountType: this.accountType,
        refresh: false,
      };
      return param;
    },
    //首次刷新使用方法
    getParamfrist() {
      var param = {};
      param = {
        token: this.token,
        refresh: true,
        screenType: this.screenType,
        accountType: this.accountType,
      };
      return param;
    },
  },
  mounted() {
    commonMeth.getTreeData().then((res) => {
      this.funArr = res.funArr;
      this.showFunModelCondition();
    });
    this.token = sessionStorage.getItem("token");
    var auth = JSON.parse(sessionStorage.getItem("auth"));
    this.auth = auth;
    this.tableMeth();
    this.screenTypeSearch();
    this.getLineChartfirst();
    this.showChannelDataMethfirst();
    this.showDataMethfirst();
    this.getScreenTypeSearchShow();
    this.lineChart.option.series.forEach((item, index) => {
      item.symbolSize = this.global.symbolSize;
    });
  },
};
</script>

<style scoped lang="scss">
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
/*公司介绍开始*/
.infoBox {
  display: flex;
  .left {
    flex: 1;
    .item {
      margin-top: 20px;
    }
  }
  .right {
    height: auto;
    position: relative;
    display: flex;
    .btnBox {
      margin-left: auto;
      margin-top: auto;
    }
  }
}
/*公司介绍结束*/
.searchBox {
  margin-top: 10px;
}
/*总收益一行开始*/
/*总收益一行结束*/
.el-row {
  margin-top: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.rowBox.horizontal {
  .grid-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding:15px 20px;*/

    .leftBox {
      display: flex;
      align-items: center;
    }

    .bottom {
      display: flex;
      /*align-items: center;*/
      justify-content: space-between;
    }
    .numTitle {
      width: 150px;
      /*font-size:14px;*/
    }
    .num {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.listBox {
  .grid-content {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    .title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px !important;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
      .blueText {
        color: #5a9dfc;
        cursor: pointer;
      }
    }
    .container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .container .item {
      height: 85px;
      padding: 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 3px solid transparent;
      :last-child {
        border-bottom: 1px solid transparent !important;
      }
      .leftBox {
        flex: 1;
        display: flex;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        /*div{*/
        /*font-size:14px;*/
        /*}*/
      }
      &:hover {
        border-left: 3px solid #5096f8;
        background: rgba(164, 190, 223, 0.1);
      }
      &.active {
        border-left: 3px solid #5096f8;
        background: rgba(164, 190, 223, 0.1);
      }
      .name {
        font-size: 16px;
        color: #010101;
        width: 60%;
        font-family: Microsoft YaHei;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .type {
        flex: 1;
      }
      .type,
      .time {
        color: #949494;
        white-space: nowrap;
      }

      .second {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        span:first-child {
          display: inline-block;
          width: 60%;
        }
        span:last-child {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
    .textOverflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item:last-child {
      margin: 0;
    }
    .item:nth-child(4) {
      border-bottom: 1px solid transparent;
    }
  }
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #fff;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  /*padding:20px 20px;*/
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.chartBox {
  height: 400px;
  margin-bottom: 20px !important;
  overflow: hidden;
  .grid-content {
    box-sizing: border-box;
  }
  .el-col {
    height: 100%;
    .grid-content {
      height: 100%;
    }
  }
  .chartTitle {
    margin-bottom: 15px;
  }
  .chartCon {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
  }
  .listCon {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
}
#chart {
  flex: 1;
  /*background:yellow;*/
}

/*首页一行两个开始*/
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #fff;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  padding: 20px 20px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-row {
  margin-top: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.rowBox.horizontal {
  .grid-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    .leftBox {
      display: flex;
      align-items: center;
    }
    .numTitle {
      width: 150px;
      font-family: DIN !important;
    }
    .num {
      font-size: 24px;
      margin-right: 10px;
      font-family: DIN !important;
    }
  }
}
/*首页一行两个结束*/

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: #a3a7af;
}
.notice {
  /*margin-left:10px;*/
  color: red;
}
.blueBtn {
  border: 1px solid #007aff !important;
  color: #007aff !important;
}
.blueBtn:hover {
  border: 1px solid #2e63a6 !important;
  color: #2e63a6 !important;
  background: #fff;
}
.outTopBox {
  padding: 0 0 0 20px;
}
.marginTop {
  margin-top: 30px !important;
}
.shadowBox {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
}
.numUnit {
  font-size: 14px;
}
.quickSearch {
  display: flex;
}
.quickSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}
.quickDateSearch .item.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.quickDateSearch .item.disabled {
  border-color: #ebeef5 !important;
  color: #c0c4cc !important;
  cursor: wait;
}
.quickDateBox {
  display: flex;
  align-items: center;
}
.quickDateSearch {
  display: flex;
}
.quickDateSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  // padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}
.quickDateSearch .item:last-child {
  margin-right: 0;
}
.quickSearchBox {
  display: flex;
}
.groupFilter {
  display: flex;
}
.groupFilter:last-child {
  margin-right: 0;
}
.box-card {
  position: relative;
}
.shade {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  cursor: wait;
}

.title_text {
  font-size: 14px;
}
.title_year {
  font-size: 14px;
  margin-left: 80px;
}
.show_year {
  font-size: 14px;
  margin-left: 8px;
}
.content_text {
  font-size: 1 2px;
}
// 下方背景
.slash {
  background-color: #ebeef5;
  width: 100px;
  height: 50px;
  position: relative;
  padding: 0 !important;
}
// 左
.slash::before {
  content: "";
  display: block;
  width: 100%;
  height: 50.5px;
  background: rgb(248, 251, 255);
  clip-path: polygon(0px 0.5px, 0px 100%, calc(100%) calc(100%));
  position: absolute;
  top: 0;
}
// 右
.slash::after {
  content: "";
  display: block;
  width: 100%;
  height: 50px;
  background: rgb(248, 251, 255);
  clip-path: polygon(100% calc(100% - 0.5px), 100% 0px, 0px -0.5px);
  position: absolute;
  top: 0;
}
::v-deep .top_table_header table thead tr :first-child {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
</style>
