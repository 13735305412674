<template>
  <div class="inputColorChange inputColorNone">
    <div class="outTopBox">
      <top-bread :bread="bread" v-if="curPage==='/indexShow'">
        <span slot="second">首页</span>
        <span slot="third">更多</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskToDo" v-if="curPage==='/taskToDo'">
        <span slot="second">任务管理</span>
        <span slot="third">待办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskDone" v-if="curPage==='/taskDone'">
        <span slot="second">任务管理</span>
        <span slot="third">已办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <el-form label-position="right" label-width="200px" :rules="rules" :model="form" ref="register">
      <el-card class="box-card">
        <back @backMeth="backMeth"></back>
        <line-title>标签信息</line-title>

        <el-form-item prop="labelName" label="标签名称">
          <el-input class="input-with-select fullSelect" style="width:300PX" :readonly="true" v-model="form.labelName"></el-input>
        </el-form-item>
        <el-form-item prop="labelCode" label="标签编码">
          <el-input class="input-with-select fullSelect" style="width:300PX" :readonly="true" v-model="form.labelCode"></el-input>
        </el-form-item>
        <el-form-item prop="labelCode" label="提审人员">
          <el-input class="input-with-select fullSelect" style="width:300PX" :readonly="true" v-model="form.optName"></el-input>
        </el-form-item>
        <el-form-item prop="labelGroup" label="关联标签组">
          <el-select v-model="form.labelGroup" style="width:300PX" :disabled="!btnShow" placeholder="请选择" class="selectItem" clearable multiple collapse-tags filterable>
            <el-option v-for="item in labelGroupList" :key="item.id" :label="item.label_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="btnShow">
          <el-button type="primary" @click="pass" class="largeBtn">通过</el-button>
          <el-button type="danger" @click="reject" class="largeBtn">驳回</el-button>
        </el-form-item>

      </el-card>
    </el-form>
    <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
  </div>
</template>

<script>
import commonMeth from "../../api/method";
import { getLabelAdv, advAction } from "@/api/advertisement.js";

import qs from "querystring";
export default {
  name: "IndexDoDetail",
  data: () => ({
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/indexDoMore" },
    },
    breadTaskToDo: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/taskToDo" },
    },
    breadTaskDone: {
      level: 4,
      thirdPath: { path: "/taskDone" },
    },
    id: "", //接收参数
    readOnly: true,
    labelPosition: "top",
    second: "60",
    showCountDown: "",
    labelGroupList: [
      { name: "11", id: 0 },
      { name: 2584, id: 9 },
    ],
    form: {
      labelName: "",
      labelCode: "",
      labelGroup: "",
      optName: "",
    },

    rules: {
      labelName: [
        { required: true, message: "标签名称不能为空", trigger: "blur" },
      ],
      labelCode: [
        { required: true, message: "标签编码不能为空", trigger: "blur" },
      ],
      labelGroup: [
        { required: true, message: "请选择需关联标签组", trigger: "blur" },
      ],
    },
    query: {},
    token: "",
    btnShow: "",
    mainLoading: true,
    curPage: "",
  }),
  methods: {
    getLabelList() {
      getLabelAdv().then((res) => {
        console.log(res, "广告标签组列表");
        this.labelGroupList = res.data.data;
      });
    },
    reject() {
      this.$refs.rejectDialog.dialogShow(true);
    },
    pass() {
      this.$refs["register"].validate((valid) => {
        if (valid) {
          this.$confirm("确定通过?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.examine(1);
            })
            .catch(() => {});
        } else {
          this.$message.warning("请完善审核信息");
        }
      });
    },
    showMessage(str, type) {
      this.$message({
        message: str,
        type: type,
      });
    },

    examine(status) {
      var param = {
        token: this.token,
        id: this.query.id,
        status: status,
        message: this.$refs.rejectDialog.dialog.reason,
        // label_name: this.form.labelName,
        // tag_name: this.form.labelCode,
        group_ids: this.form.labelGroup.length>0?this.form.labelGroup.join(','):'',
        // user_name: this.form.optName,
      };
      advAction(param).then((res) => {
        var str = "";
        status === 1 ? (str = "审核") : (str = "驳回");
        if (res.data.code === 100000) {
          this.showMessage(str + "成功", "success");
          this.$router.push(this.query.fromUrl);
        } else {
          this.showMessage(res.data.msg, "error");
        }
      });
    },
    getDetail() {
      this.mainLoading = true;
      this.$http
        .get(this.global.registerReviewDetail, {
          params: {
            token: this.token,
            id: this.query.id,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            console.log(res);
            var formData = res.data.data;
            this.form.labelName = formData.label_name;
            this.form.labelCode = formData.tag_name;
            this.form.labelGroup = formData.label_group;
            this.form.optName = formData.user_name;
          } else {
            this.$message.warning(res.data.msg);
          }
          this.mainLoading = false;
        })
        .catch((res) => {
          // this.$message.warning('获取信息失败')
        });
    },
    backMeth() {
      this.$router.push({ path: `./${this.query.fromUrl}` });
    },
  },

  mounted() {
    this.token = sessionStorage.getItem("token");
    var query = this.$route.query;
    this.query = query;
    this.curPage = sessionStorage.getItem("defaultActive");
    query.reviewStatus === "待审核"
      ? (this.btnShow = true)
      : (this.btnShow = false);
    this.getLabelList();
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.btnRow {
  display: flex;
  direction: rtl;
  .el-button:first-child {
    margin-left: 10px;
  }
}
.formBox {
  width: 100%;
  /*form{*/
  /*width:900px;*/
  /*margin:0 auto;*/
  /*}*/
}
.el-input > input {
  border: none !important;
}
.selectItem {
  width: 100%;
}
.halfWidth {
  width: 50%;
}
.radioBox {
  margin-top: 12px;
}
</style>
