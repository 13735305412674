<template>
    <div>
        <el-col :span="12" class="whiteItem">
            <div class="grid-content bg-purple conBox">
                <div class="leftBox">
                    <img v-if="img==='redLine'" src="../../../assets/img/redLine.png" alt="">
                    <img v-if="img==='greenLine'" src="../../../assets/img/greenLine.png" alt="">
                </div>
                <div class="rightBox">
                    <div class="indexNum marginBottom">
                        ￥ <slot name="num"></slot>
                    </div>
                    <div class="numTitle">
                        <slot name="numTitle"></slot>
                    </div>
                </div>
            </div>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: "TwoRow",
        props:{
            img:{
                default:'redLine'
            }
        },
        data: () => ({

        })
    }
</script>

<style scoped lang="scss">
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        padding:20px 20px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .rowBox.horizontal {
        .grid-content {
            height:100%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            padding:15px 20px;
            /*.leftBox{*/
                /*display:flex;*/
                /*align-items: center;*/
            /*}*/
            /*.numTitle{*/
                /*width:150px;*/
            /*}*/
            /*.num{*/
                /*font-size:24px;*/
                /*margin-right:10px;*/
            /*}*/
        }
    }
    .conBox{
        display:flex;
        justify-content: space-between;
    }
    .leftBox{
        width:230px;
        height:100%;
        img{
            width:100%;
            height:100%;
        }
    }
    .rightBox{
        display:flex;
        flex-direction: column;
    }
    .indexNum{
        font-size:30px;
        font-family: DIN!important;
        font-weight:600;
    }
    .indexNum.marginBottom{
        margin-bottom:10px;
    }
    .numTitle{
        /*color:#3c3c3c;*/
        font-size:14px;
        color:#a3a7af;
    }
</style>
