<template>
  <div>
    <div class="btnRow">
      <el-button type="primary" class="largeBtn" @click="$refs.AddServiceConfigDialog.open('新增服务',query.hotelName)">添 加</el-button>
      <el-button type="danger" class="largeBtn" @click="deleteData()">批量删除</el-button>
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <AddServiceConfigDialog ref="AddServiceConfigDialog"></AddServiceConfigDialog>
  </div>
</template>
<script>
import { getList ,allDelete } from "../api/application/serviceConfig";
import AddServiceConfigDialog from "./components/AddServiceConfigDialog.vue";
export default {
  props: {
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  name: "ServiceConfig",
  components: {
    AddServiceConfigDialog,
  },
  data() {
    return {
      tableData: {
        tableList: [ ],
        checked: true,
        tableLoading: false,
        page: 1,
        size: 2,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "50",
            type:"text",
          },
          {
            label: "版本号",
            prop: "version",
            minWidth: "50",
            type:"text",
          },
          {
            label: "apk名称",
            prop: "apkName",
            minWidth: "120",
            type:"text",
          },
          {
            label: "包名",
            prop: "package",
            minWidth: "80",
            type:"text",
          },
          {
            label: "服务名称",
            prop: "serviceName",
            minWidth: "80",
            type:"text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            edit: (row) => {
              this.$refs.AddServiceConfigDialog.open('修改服务',this.query.hotelName,row)
            },
          },
        ],
      },
      listQuery: {
        database: this.query.hotelName,
        page: 1,
        size: 10
      },
      checkList: [],
    };
  },
  methods: {
    changeSize(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
      
    },
    changeCurrent(s, p) {
      this.listQuery.page = p ? p : 1,
      this.listQuery.size = s ? s : 10,
      this.getList();
    },
    changeSelection(val) {
        this.checkList = val.map(v=>{
          return v.id
        })
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
        this.$refs.operationTable.resetTable();
      }
      getList(this.listQuery).then(res => {
        if (res.data.code == 100000) {
          this.tableData.tableList = res.data.data.row || [];
          this.$refs.operationTable.changeTotal(Number(res.data.data.totalNum));
        }
        this.tableData.tableLoading = false;
      })
    },
    deleteData() {
      if (this.checkList.length <= 0 ) {
        return this.$message.error('请选择配置');
      }
      this.$confirm('此操作将永久删除配置？, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          allDelete(
            {
              ids:`${this.checkList.join()}`,
              database:this.listQuery.database
            }).then(res => {
              if (res.data.code === 100000) {
                this.getList(1);
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
              } else {
                this.$message.error(es.data.msg)
              }
          })
        }).catch(() => {
        });
    },
  },
  mounted(){
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map(i=>{
      if(i.ltype==1){
        i.language ="中文"
      }else if(i.ltype==2){
         i.language ="英文"
      }else if(i.ltype==3){
         i.language ="日文"
      }else if(i.ltype==4){
         i.language ="韩文"
      }
      return i
    })
  },
  watch: {
    query: {
      handler: function (newVal) {
       this.query=newVal;
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
