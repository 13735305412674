<template>
    <div class="grid-content bg-purple chartCon">
        <div class="chartTitle blueTitle" v-if="chart.showTitle">数据走势</div>
        <div :id="chart.id" class="chart chartBox"></div>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import GLOBAL from '../../../api/globalVar.js'

    export default {
        name: "LineChart",
        props:['chart'],
        data: () => ({
            yAxis:[],
            allData:{

            },

        }),
        methods:{
            chartRender(fromUrl){
                var chart = echarts.init(document.getElementById(this.chart.id));
                var option={
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            lineStyle: {
                                color: '#ced7ec',
                            }
                        },
                        axisLabel:{
                            textStyle:{
                                color:'#333'
                            }
                        },
                        data: this.chart.option.xData
                    },
                    yAxis: [
                        {
                            type:'value',
                            axisLabel:{
                                // formatter:`{value}(${unitOne})`,
                                formatter:`{value}台`,
                                textStyle:{
                                    color:GLOBAL.chartColor[0]
                                }
                            },
                            show:true,
                            splitNumber: 5,
                            position:"left",     //多个Y轴使用
                            axisLine:{show:false},
                            axisTick:{show:false},
                            splitLine:{
                                show:true,
                                lineStyle:{
                                    type:'dashed',
                                    color:'#ebeef5'
                                }
                            },
                        },
                    ],
                    legend: {
                        x:'center',
                        y:'bottom',
                        icon:'circle',
                        padding:[0,0,0,0],
                        data: this.chart.legend
                    },
                    grid: {
                        top:'3%',
                        left: '3%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    xData:[],

                    series:this.chart.option.series,
                    //     series: [
                    //     {
                    //         name: '维也纳',
                    //         type: 'line',
                    //         stack: '总量',
                    //         smooth:true,
                    //         itemStyle: {
                    //             normal: {
                    //                 color: GLOBAL.chartColor[0], //改变折线点的颜色
                    //                 lineStyle: {
                    //                     color:GLOBAL.chartColor[0]//改变折线颜色
                    //                 },
                    //             },
                    //         },
                    //         // data: [0,0,0,0,0,0,0,0,0,'0','0']
                    //         data: this.chart.option.series[0].data
                    //     },
                    //     {
                    //         name: '希尔顿欢朋',
                    //         type: 'line',
                    //         stack: '总量',
                    //         smooth:true,
                    //         itemStyle: {
                    //             normal: {
                    //                 color: GLOBAL.chartColor[1], //改变折线点的颜色
                    //                 lineStyle: {
                    //                     color:GLOBAL.chartColor[1]//改变折线颜色
                    //                 },
                    //             },
                    //         },
                    //         data: [220, 182, 191, 234, 290, 330, 310]
                    //     },
                    // ],
                };
                chart.setOption(option);
            },


        },
        mounted(){
            this.chartRender();

        },
        watch:{
            chart(val){

            }
        }
    }
</script>

<style scoped lang="scss">

    .grid-content {
        height:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        /*padding:15px 20px;*/
        .leftBox{
            display:flex;
            align-items: center;
        }
        .numTitle{
            width:150px;
        }
        .num{
            font-size:24px;
            margin-right:10px;
        }
    }
    .bg-purple {
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        /*padding:20px 20px;*/
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .chartBox{
        height:400px;
        overflow:hidden;
    }
    .grid-content{
        box-sizing:border-box;
    }
    .el-col{
        height:100%;
        .grid-content{
            height:100%;
        }
    }
    .chartTitle{
        width:100%;
        margin-bottom:15px;
        color:#333;
    }
    .chartCon{
        height:100%;
        display:flex;
        flex-direction:column;
    }
    #chart,.chart{
        width:100%;
        /*height:300px;*/
        flex:1;
    }


</style>
