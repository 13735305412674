<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">分组管理</span>
        <span slot="third">分组列表</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <!-- 操作栏 -->
      <div>
        <el-form ref="form" :inline="true" :model="form" label-position="left">
            <el-form-item v-if="auth.type!='monomer'">
            <el-input v-model="form.hotel_name" placeholder="请输入酒店名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="请输入分组名称"></el-input>
          </el-form-item>
          <el-form-item>

            <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
            <el-button type="primary" class="largeBtn" @click="reset">重置</el-button>

            <!-- <el-button type="primary" class="largeBtn" :loading='btnLoading' @click="importTable">导出</el-button> -->
          </el-form-item>
        </el-form>

      </div>
      <!-- 操作栏 -->

    </el-card>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">分组列表</div>
      <div class="btnRow">
        <el-button type="primary" class="largeBtn" @click="toCreate">新建分组</el-button>
      </div>

      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" />
      <!-- 表格 -->

    </el-card>
  </div>

</template>
<script>
import { getRoomGroup ,delRoomGroup} from "./hotelGroupApi";
export default {
  name: "hotelGroupList",

  data() {
    return {
      auth:{},
      form: {
        name: "",

        hotel_name: "",
        // time: "",
      },
      bread: {
        level: 3,
      },
      btnLoading: false,
      // 表格数据信息
      tableData: {
        tableList: [{}],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "分组名称",
            prop: "name",
            minWidth: "100",
            type: "text",
          },
          {
            label: "所属门店",
            prop: "hotel_name",
            minWidth: "280",
            type: "text",
          },
          {
            label: "更新人",
            prop: "nickname",
            minWidth: "120",
            type: "text",
          },
          {
            label: "更新时间",
            prop: "createtimeText",
            minWidth: "160",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "280",
            type: "button",
            prop: "num",
            // 详情
            edit: (item) => {
              this.$router.push({
                path: `/addHotelGroup`,
                query: {
                  item: item,
                },
              });
            },
            del: (item) => {
              this.$confirm("是否确认删除分组？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                delRoomGroup({
                  ids: item.id,
                }).then((res) => {
                    if(res.data.code == 100000){
                        this.$message.success("删除成功")
                        this.reset();
                    }else{
                        this.$message.warning(res.data.msg)
                    }
                });
              });
            },
          },
        ],
      },
      // 已选项
      checkList: [],
    };
  },

  methods: {
    toCreate() {
      this.$router.push("/addHotelGroup");
    },
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    reset() {
      this.tableData.limit = 10;
      this.tableData.page = 1;
      this.form.name = "";
      this.form.hotel_name = "";
      //   this.form.time = [];
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },

    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p ? p : 1,
        size: s ? s : 10,
        name: this.form.name,
        hotel_name: this.form.hotel_name,
        hotel_contract:'',
      };
      getRoomGroup(json)
        .then((res) => {
          if (res.data.code == 100000) {
            console.log(res, "列表数据");
            this.tableData.tableList = res.data.data.data;
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem('auth'))
    this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
.el-form-item {
  margin-bottom: 0px;
}
</style>
