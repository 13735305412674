<template>
    <div class="root" :class="[$attrs.onlyShowModule?'onlyShowModule':'']">
        <div class='imgBox borderBox'
             :class="[modelShowBtnName==='modelImg'?'active':'']"
             @click="fullClick" @mouseenter.stop="modelShowBtnMeth('modelImg')"
             @mousemove.stop="modelShowBtnMeth('modelImg')">
            <img :src="welPage.url"  v-if="welPage.type==='pic' || welPage.type==='img'" alt="">
            <div class="player" v-if="flag"><i class="el-icon-video-play"></i></div>
            <div class="player paused"  v-if="!flag"><i class="el-icon-video-pause"></i></div>
            <video ref="videoPlayer" title="标题" :src="welPage.url"
                   v-if="welPage.type==='video'"  controls width="100%" @click="videoSwitch"></video>
            <div class='textBox borderBox'
                 @mouseenter.stop="modelShowBtnMeth('modelText')"
                 @mousemove.stop="modelShowBtnMeth('modelText')"
                 :class="[welPage.coBack,welPage.align,welPage.po,!welPage.disabled?'coTrans':'',modelShowBtnName==='modelText'?'active':'',modelHighIndexClass('modelText'),modelHighIndexClass('firstLine')]">
                <div class="textBtnGroup">
                    <div class="btnBox">
                        <div class="chBtn btnItem" v-if="welPageProp.chineseBtn">
                            <img :src="welPageProp.chineseBtn" alt="">
                        </div>
                        <div class="enBtn btnItem" v-if="welPageProp.englishBtn">
                            <img :src="welPageProp.englishBtn" alt="">
                        </div>
                        <div class="otherBtn btnItem" v-if="welPageProp.thirdBtn">
                            <img :src="welPageProp.thirdBtn" alt="">
                        </div>
                    </div>
                </div>
                <el-form :model="welPage" class="noBgInput firstLineForm" v-if="welPage.disabled">
                    <div @click.stop="showShadeMeth('firstLine')" class="inputBox"
                         @mouseover.stop="modelShowBtnMeth('firstLine')" :class="[modelHighIndexClass('firstLine')]">
                        <el-input  v-model="welPage.firstLine" placeholder="请输入欢迎语，不能超过30个字符" @input="inputMeth" text-align="center" max="4" :disabled="$attrs.onlyShowModule"
                                   class="firstLine textBorderBox noDisabledColor"
                                   @focus="focusTextBoxMeth('firstLine')"
                                   @blur="blurTextBoxMeth"
                                   :class="{focusTextBox:curFocusTextBox==='firstLine'}"></el-input>
                    </div>
                </el-form>
                <div class="city_info">
                    <div class="info_one">
                        <div class="top">{{curTime.time}}</div>
                        <div class="bottom">{{curTime.date}}</div>
                    </div>
                    <div class="info_two">
                        <div class="top">广州 晴</div>
                        <div class="bottom">25~30℃</div>
                    </div>
                </div>
                <div class="configBox modelText highIndex 1">
                    <div class="slideBox animate__animated" v-if="modelTextData.configShow">
                        <div class="btnGroup">
                            <div class="btnGroupItem" v-if="welPage.disabled">
                                <div class="btnItem" @click.stop="showDetailMeth('colorDetailShow')">配色
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up color_match animate__animated" v-if="modelTextData.colorDetailShow">
                                    <div class="item co_transparent" :class="coActiveClass('coTrans')" id='coTrans' @click.stop="clickCo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/color_match_trans.png" alt=""></span>
                                        <span  class="noWarp">全透</span>
                                    </div>
                                    <div class="item  co_black"  :class="coActiveClass('coBlack')" id='coBlack' @click.stop="clickCo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/color_match_black.png" alt=""></span>
                                        <span class="noWarp">黑色</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem" v-if="welPage.disabled">
                                <div class="btnItem" @click.stop="showDetailMeth('alignDetailShow')">
                                    对齐
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up align_mode animate__animated" v-if="modelTextData.alignDetailShow">
                                    <div class="item po_left" :class="alignActiveClass('alignLeft')" id="alignLeft" @click.stop="clickAlign">
                                        <i class="iconfont icon-zuoduiqi"></i>
                                    </div>
                                    <div class="item po_center" :class="alignActiveClass('alignCenter')" id="alignCenter" @click.stop="clickAlign">
                                        <i class="iconfont icon-733bianjiqi_juzhongduiqi"></i>
                                    </div>
                                    <div class="item po_right" :class="alignActiveClass('alignRight')" id="alignRight" @click.stop="clickAlign">
                                        <i class="iconfont icon-youduiqi"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem" v-if="welPage.disabled">
                                <div class="btnItem" @click.stop="showDetailMeth('poDetailShow')">
                                    位置
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up position_mode animate__animated" v-if="modelTextData.poDetailShow">
                                    <div class="item po_top" :class="poActiveClass('poTop')" id="poTop" @click.stop="clickPo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/po_top.png" alt=""></span>
                                        <span class="itemText">居上</span>
                                    </div>
                                    <div class="item po_middle"  :class="poActiveClass('poCenter')" id="poCenter" @click.stop="clickPo">
                                        <span class="check_icon"> <i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/po_center.png" alt=""></span>
                                        <span class="itemText">居中</span>
                                    </div>
                                    <div class="item  po_bottom"  :class="poActiveClass('poBottom')" id="poBottom" @click.stop="clickPo">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="show_icon"><img class="img_attr" src="../../../../assets/img/po_bottom.png" alt=""></span>
                                        <span class="itemText">居下</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem">
                                <div class="btnItem" @click.stop="showDetailMeth('stateDetailShow')">
                                    显示状态
                                    <i class="el-icon-caret-top"></i>
                                </div>
                                <div class="detail_box up position_mode animate__animated" v-if="modelTextData.stateDetailShow">
                                    <div class="item po_top" :class="stateActiveClass(true)" id="stateShow" @click.stop="clickState">
                                        <span class="check_icon"><i class="el-icon-check"></i></span>
                                        <span class="itemText">显示</span>
                                    </div>
                                    <div class="item po_middle"  :class="stateActiveClass(false)" id="stateHide" @click.stop="clickState">
                                        <span class="check_icon"> <i class="el-icon-check"></i></span>
                                        <span class="itemText">隐藏</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnGroupItem">
                                <div class="btnItem" @click="resetItemMeth('modelText')">恢复</div>
                            </div>
                        </div>
                    </div>
                    <div class="btnItem highIndex configBtn" @click.stop="slideConfigBox('modelText')" v-if="hotelTemplateTypeCom">配置</div>
                </div>
            </div>
            <div class="tips">
                <p class="tipsFirst">按“OK”键进入>></p>
                <p class="tipsSecond">
                    即为同意 <span class="redText">账号使用规则和隐私政策 </span> &  <span class="redText">©</span>
                </p>
            </div>
            <div class="configBox modelImg highIndex animate__animated 1" style="z-index:102!important;">
                 <div class="btnItem highIndex configBtn" @click.stop="slideConfigBox('modelImg')">配置</div>
                <div class="slideBox animate__animated" v-if="modelImgData.configShow">
                    <div class="btnGroup">
                        <div class="btnGroupItem">
                            <div class="btnItem" @click.stop="uploadMaterial('modelImg','video')" >上传视频</div>
                        </div>
                        <div class="btnGroupItem">
                            <div class="btnItem" @click="resetItemMeth('modelImg')">恢复</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shade animate__animated" @click="hideShade" v-if="shadeShow"></div>
        </div>
        <upload-dialog
                :dialogParData="dialogUpload" @changeDialogVisible="changeDialogVisible" @editTabLoadingMeth="editTabLoadingMeth"
                @uploadMaterialUrl="getUploadMaterialUrl"
                :hotelContract="hotelContract"></upload-dialog>
    </div>
</template>

<script>
    import commonMeth from '../../../../api/method'
    export default {
        name: "EditEelDetail",
        props:['hotelContract','welPageProp','onlyShow','hotelTemplateType','modelReadOnly'],
        data:()=>({
            modelImgData:{
                configShow:false,
                moreType:'',
                moreDetailShow:false,
                arrowDirection:'up'
            },
            modelTextData:{
                configShow:false,
                colorDetailShow:false,
                alignDetailShow:false,
                poDetailShow:false,
                stateDetailShow:false,
            },
            welPage:{
                url:'',
                fileName:'',
                firstLine:'',
                secondLine:'',
                coBack:'',
                align:'',
                po:'',
                disabled:false,
                type:'',
            },
            defaultData:{
                url:'',
                firstLine:'',
                secondLine:'',
                coBack:'',
                align:'',
                po:'',
                disabled:'',
                type:'',
                title:''
            },
            modelShowBtnName:'',
            dialogUpload:{
                visible:false,
                agreenActive:'',
                chooseItemIndex:'',
                uploadModel:'',
                uploadMaterialType:'',
                uploadMaterialUrl:''
            },
            shadeShow:false,
            uploadAccept:'',
            uploadAcceptImg:'.jpg,.jpeg,.png,JPG,.JPEG',
            uploadAcceptVideo:'.mp4',
            textAlign:'center',
            // chBtnBgUrl:'http://source.ctlife.tv/20200115104213676_0.png',
            // enBtnBgUrl:'http://source.ctlife.tv/20200115104257740_0.png',
            // otherBtnBgUrl:'http://source.ctlife.tv/20200115104349707_0.png',
              chBtnBgUrl:'',
            enBtnBgUrl:'',
            otherBtnBgUrl:'',
            curTime:{
                date:'',
                week:'',
                time:'',
            },
            curFocusTextBox:'',
            query:{},
            flag:true
        }),
        methods:{
            videoSwitch(){
                var flag = this.$refs.videoPlayer.paused;
                this.flag = !flag;
                console.log(this.flag);
            },
            showDetailMeth(val){
                var modelTextData=this.modelTextData;
                if(val==='colorDetailShow'){
                    modelTextData.colorDetailShow=!modelTextData.colorDetailShow;
                    modelTextData.alignDetailShow=false;
                    modelTextData.poDetailShow=false;
                    modelTextData.stateDetailShow=false;
                }else if(val==='alignDetailShow'){
                    modelTextData.alignDetailShow=!modelTextData.alignDetailShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.poDetailShow=false;
                    modelTextData.stateDetailShow=false;
                }else if(val==='poDetailShow'){
                    modelTextData.poDetailShow=!modelTextData.poDetailShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.alignDetailShow=false;
                    modelTextData.stateDetailShow=false;
                }else if(val==='stateDetailShow'){
                    modelTextData.stateDetailShow=!modelTextData.stateDetailShow;
                    modelTextData.colorDetailShow=false;
                    modelTextData.alignDetailShow=false;
                    modelTextData.poDetailShow=false;
                }else if(val==='modelImg'){
                    this.modelImgData.moreDetailShow=!this.modelImgData.moreDetailShow;
                }
            },
            clickCo(e) {
                this.welPage.disabled ? this.welPage.coBack = e.currentTarget.id : "";
            },
            clickAlign(e) {
                this.welPage.disabled ? this.welPage.align = e.currentTarget.id : "";
            },
            clickPo(e) {
                this.welPage.disabled ? this.welPage.po = e.currentTarget.id : "";
            },
            clickState(e) {
                e.currentTarget.id==='stateShow'
                    ?this.welPage.disabled=true
                    :this.welPage.disabled=false;
            },
            showMeth(val){
                val==='hide'?this.welPage.disabled=false:this.welPage.disabled=true;
                this.resetDetailShow();
            },
            fullClick(){
                var modelTextData=this.modelTextData;
                modelTextData.colorDetailShow=false;
                modelTextData.alignDetailShow=false;
                modelTextData.poDetailShow=false;
            },
            slideConfigBox(type){
                var modelImgData=this.modelImgData;
                var modelTextData=this.modelTextData;
                if(type==='modelImg'){
                    modelImgData.moreType='';
                    modelImgData.configShow=!modelImgData.configShow;
                    modelImgData.moreDetailShow=false;
                    modelTextData.configShow=false;
                    this.shadeShow=false;
                }else if(type==='modelText'){
                    modelTextData.configShow=!modelTextData.configShow;
                    if(modelTextData.configShow){
                        this.shadeShow=true;
                    }else{
                        this.shadeShow=false;
                    }
                    modelTextData.colorDetailShow=false;
                    modelTextData.alignDetailShow=false;
                    modelTextData.poDetailShow=false;
                    modelTextData.stateDetailShow=false;
                    modelImgData.configShow=false;
                }
            },
            resetDetailShow(){
                var modelTextData=this.modelTextData;
                modelTextData.colorDetailShow=false;
                modelTextData.alignDetailShow=false;
                modelTextData.poDetailShow=false;
            },
            modelShowBtnMeth(type){
                if(type==='modelImg'){
                    if(!this.shadeShow){
                        this.modelShowBtnName = type;
                    }
                }else{
                    this.modelShowBtnName = type;
                }
            },
            clearShowBtnName(){
                this.modelShowBtnName='';
                this.shadeShow=false;
                this.modelImgData.configShow=false;
                this.modelTextData.configShow=false;
            },
            uploadMaterial(modelType,materialType){
                this.modelImgData.moreType=materialType;
                var dialogUpload=this.dialogUpload;
                dialogUpload.uploadModel=modelType;
                dialogUpload.uploadMaterialType=materialType;
                dialogUpload.visible=true;
            },
            resetDialog(){
                var dialogUpload=this.dialogUpload;
                dialogUpload.chooseItemIndex='';
                dialogUpload.uploadModel='';
                dialogUpload.uploadMaterialType='';
                dialogUpload.uploadMaterialUrl='';
            },
            getUploadMaterialUrl(url,fileName){
                this.welPage.type=this.modelImgData.moreType;
                this.welPage.url=url;
                this.welPage.fileName=fileName;
                this.dialogUpload.visible=false;
            },
            defaultDataMeth(){
                this.shadeShow=false;
                this.welPage=commonMeth.objDataAssign(this.defaultData);
                this.welPage.fileName='';
                if(this.hotelTemplateType==='ehotel'){
                    this.welPage.align='alignCenter';
                    this.welPage.coBack='coBlack';
                    this.welPage.po='poBottom';
                }
            },
            divEditChange(newVal){
                this.welPage.firstLine=document.getElementById('firstLine').innerHTML;
                this.welPage.secondLine=document.getElementById('secondLine').innerHTML;
            },
            showShadeMeth(){
                if(!this.$attrs.onlyShowModule){
                    this.shadeShow=true;
                }
            },
            hideShade(){
                this.shadeShow=false;
                this.modelImgData.configShow=false;
                this.modelTextData.configShow=false;
            },
            getData(){
                this.defaultData=commonMeth.objDataAssign(this.welPageProp);
            },
            cancelMeth(){
                this.dialogUpload.visible=false;
            },
            resetItemMeth(type){
                var welPage=this.welPage;
                var defaultData=this.defaultData;
                if(type==='modelImg'){
                    welPage.url=defaultData.url;
                    welPage.fileName='';
                    welPage.type=defaultData.type;
                }else if(type==='modelText'){
                    welPage.disabled=defaultData.disabled;
                    welPage.firstLine=defaultData.firstLine;
                    welPage.secondLine=defaultData.secondLine;
                    welPage.coBack=defaultData.coBack;
                    welPage.align=defaultData.align;
                    welPage.po=defaultData.po;
                }
            },
            clearFileName(){
                this.welPage.fileName='';
            },
            editTabLoadingMeth(val,type){
                this.$emit('editTabLoadingMeth', val,type);
            },
            changeDialogVisible(val){
                this.dialogUpload.visible=val;
            },
            focusTextBoxMeth(val){
                this.curFocusTextBox=val;
            },
            blurTextBoxMeth(){
                this.curFocusTextBox='';
            },
            mergeData(){
                var welcomePageMerge={};
                var welPage=this.welPage;
                var defaultData=this.defaultData;
                welcomePageMerge.url=welPage.url;
                welcomePageMerge.fileName=welPage.fileName;
                welcomePageMerge.firstLine=welPage.firstLine;
                welcomePageMerge.secondLine=welPage.secondLine;
                welcomePageMerge.coBack=welPage.coBack;
                welcomePageMerge.align=welPage.align;
                welcomePageMerge.po=welPage.po;
                welcomePageMerge.disabled=welPage.disabled;
                welcomePageMerge.type=welPage.type;
                welcomePageMerge.title=welPage.title;
                return welcomePageMerge;
            },
            inputMeth(){
                var firstLine=this.welPage.firstLine;
                if(firstLine.length>30){
                    this.$message.warning('输入内容不能超过30个字符');
                    this.welPage.firstLine=firstLine.slice(0,30);
                }
            }
        },
        mounted(){
            this.getData();
            setInterval(()=>{
                this.curTime=commonMeth.getCurrentDate();
            },1000);
            this.query=this.$route.query;
            this.defaultDataMeth();
        },
        computed:{
            coActiveClass() {
                return function(msg) {
                    // return this.welPage.coBack.trim() === msg.trim() ? 'active' : ''
                    return this.welPage.coBack === msg? 'active' : ''
                }
            },
            alignActiveClass() {
                return function(msg) {
                    return this.welPage.align === msg ? 'active' : ''
                }
            },
            poActiveClass() {
                return function(msg) {
                    return this.welPage.po === msg ? 'active' : ''
                }
            },
            stateActiveClass() {
                return function(msg) {
                    return this.welPage.disabled === msg ? 'active' : ''
                }
            },
            configBtnClass(){
                return function(msg) {
                    return this.modelShowBtnName === msg ? 'animate__fadeOut' : 'animate__fadeIn'
                }
            },
            animateClass() {
                return function(val,direction) {
                    var className='';
                    if(direction==='down'){
                        if(val){
                            className='animate__fadeInDown';
                        }else{
                            className='animate__fadeOutRight noneEl';
                        }
                    }else if(direction==='up'){
                        if(val){
                            className='animate__fadeInUp';
                        }else{
                            className='animate__fadeOutRight noneEl';
                        }
                    }else if(direction==='right'){
                        if(val){
                            className='animate__fadeInRight';
                        }else{
                            className='animate__fadeOutRight noneEl';
                        }
                    }
                    return className;
                }
            },
            modelMoreActiveClass() {
                return function(modelType,materialType) {
                    return this.modelImgData.moreType === materialType ? 'active' : ''
                }
            },
            shadeShowClass(){
                return this.shadeShow  ? 'animate__fadeIn highIndex' : 'animate__fadeOut noneEl'
            },
            hotelTemplateTypeCom(){
                var flag=false;
                this.welPage.align='alignLeft';
                this.welPage.coBack='coBlack';
                this.welPage.po='poBottom';
                if(this.hotelTemplateType==='ehotel'){
                    this.welPage.align='alignCenter';
                    flag=false;
                }else{
                    flag=true;
                }
                return flag;
            },
            modelHighIndexClass() {
                return function (msg) {
                    return this.modelShowBtnName === msg ? ' highIndex' : ' '
                }
            },
        },
        watch:{
            hotelContract:{
                  handler: function (newVal) {
                          this.hotelContract=newVal
                        this.getData();
                        this.defaultDataMeth();
                },
                deep: true,
                immediate: true, 
            },
             welPageProp:{
                 handler: function (newVal) {
                          this.welPageProp=newVal
                        this.getData();
                        if(this.$refs.videoPlayer){
                            var flag = this.$refs.videoPlayer.paused;
                        this.flag = flag;
                        }
                          
                        this.defaultDataMeth();
                },
                deep: true,
                immediate: true, 
            },
             onlyShow:{
                 handler: function (newVal) {
                          this.onlyShow=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelTemplateType:{
                 handler: function (newVal) {
                          this.hotelTemplateType=newVal
                },
                deep: true,
                immediate: true, 
            },
             modelReadOnly:{
                 handler: function (newVal) {
                          this.modelReadOnly=newVal
                },
                deep: true,
                immediate: true, 
            },
         
            activeName(newVal){
                this.$nextTick(()=>{
                    this.$refs[`${newVal}Chart`].resize();
                })
            },
            modelShowBtnName(newVal){
                if(newVal!=='modelImg'){
                    // this.modelImgData.configShow=false;
                }
                if(newVal!=='modelText'){
                    // this.modelTextData.configShow=false;
                }
            },

        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../../node_modules/animate.css/animate.css";
    .root{

    }
    .imgBox {
        /*width: 80%;*/
        /*height: 596px;*/
        /*overflow: hidden;*/
        width:1152px;
        height:648px;
        position: relative;
        
    }
    .imgBox img,.imgBox video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .textBox {
        width: 100%;
        height: 140px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        position: absolute;
        box-sizing: border-box;
        transition: 0.5s;
        z-index:5;
    }
    .textBox.test{
        width:100px;
        height:100px;
        background:red!important;
        position:absolute;
        top:0!important;
        right:0!important;
    }
    .textBox.whiteBorder{
        border: 2px dashed #fff;
    }
    .textBox.noBorder {
        border: none;
    }
    .textBox .firstLine {
        font-size:18px;
    }
    .firstLineForm{
        width:100%;
    }
    .textBox .textLine {
        width: 100%;
        color: white;
        font-size: 18px;
        letter-spacing: 2px;
        transition: 0.5s;
        outline:none;
    }

    /* 背景色 */
    .textBox.coTrans {
        background: none!important;
        transition: 0.5s;
    }

    .textBox.coBlack {
        background: rgba(0, 0, 0, 0.6);
        transition: 0.5s;
    }

    .textBox.coWhite {
        background: rgba(0, 0, 0, 0.4);
        transition: 0.5s;
    }

    /* 文字对齐 */
    .textBox.alignLeft input {
        text-align: left;
        transition: 0.5s;
    }

    .textBox.alignCenter input {
        text-align: center;
        transition: 0.5s;
    }

    .textBox.alignRight input {
        text-align: right;
        transition: 0.5s;
    }

    /* 文字位置 */
    .textBox.poTop {
        top: 0;
        transition: 0.5s;
    }

    .textBox.poCenter {
        top: 248px;
        transition: 0.5s;
    }
    .textBox{
        transition: 0.5s;
    }
    .textBox.poBottom {
        top:503px;
        transition: 0.5s;
    }
    .configBox{
        display:flex;
        position:absolute;
    }
    .configBox.modelText{
        top:-50px;
        right:50px;
    }
    .configBox.modelImg{
        top:0;
        left:10px;
    }
    .configBox .btnItem{
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.3)!important;
        cursor:pointer;
    }
    .slideBox{
        transition:2s;
    }
    .btnGroup{
        display:inline-block;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
    }

    .detail_box{
        /*background:rgba(0,0,0,0.6);*/
        background:#252527;
        padding:5px 0;
    }
    .detail_box.up{
        position:absolute;
        left:0;
        bottom:40px;
    }
    .detail_box .item{
        color:#fff;
        cursor:pointer;
    }

    /*配色详情*/
    /*.color_match,.position_mode{*/
        /*overflow:hidden;*/
        /*padding:5px 0;*/
        /*background:rgba(0,0,0,0.4);*/
        /*position:absolute;*/
        /*!*top:-130px;*!*/
        /*display:none;*/
        /*border-radius:2px;*/

    /*}*/
    /*.more_file{*/
        /*overflow:hidden;*/
        /*padding:5px 0;*/
        /*background:rgba(0,0,0,0.4);*/
        /*position:absolute;*/
        /*!*top:-130px;*!*/
        /*display:none;*/
        /*border-radius:2px;*/
    /*}*/
    /*.color_match.active,.more_file.active{*/
        /*!*display:block;*!*/
    /*}*/
    .color_match>.item,.more_file>.item,.position_mode>.item{
        width:100%;
        height:36px;
        padding:12px 20px;
        display:flex;
        align-items: center;
        font-size:14px;
        box-sizing:border-box;
    }
    .color_match>.item.active,.more_file>.item.active,.position_mode>.item.active{
        background:#1b90ed;
    }
    .item.active .check_icon{
        visibility: visible;
    }

    .item .check_icon{
        visibility: hidden;
    }

    .color_match>.item>.check_icon,.position_mode>.item>.check_icon{
        width:18px;
        height:20px;
        margin-right:10px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .color_match>.item>.show_icon,.position_mode>.item>.show_icon{
        width:44px;
        height:24px;
        margin-right:10px;
        display:inline-block;
    }
    .none{
        display:none!important;
    }
    /*对齐方式*/
    .align_mode{
        width:82px;
        /*height:86px;*/
        padding:5px 5px;
        box-sizing:border-box;
        background:rgba(0,0,0,0.4);
        overflow:hidden;
        /*display:none;*/
        border-radius:2px;
    }
    .align_mode>.item{
        width:36px;
        height:36px;
        border-radius:5px;
        float:left;
        box-sizing:border-box;
        padding:5px;
    }
    .align_mode>.item.active{
        background:#fff;
        color:#333;
    }
    /*位置按钮*/
    .position_mode{
        /*display:none;*/
    }
    /*显示按钮详情*/
    .detail_box.active{
        /*display:block;*/
    }
    .detail_box{
        background:rgba(0,0,0,0.6);
    }
    div.upload_tab_box{
        width:100%;
        /*height:400px;*/
        /*background:#b39ddb;*/
        display:flex!important;
        flex-direction:column!important;
        padding:0!important;
        margin:0!important;

    }
    .itemText{
        white-space: nowrap;
    }
    .cloud_icon img{
        width:100%;
        height:100%;
    }
    .gray_tips img{
        width:100%;
        height:100%;
    }
    .highIndex{
        z-index:100;
    }
    .noWarp{
        white-space:nowrap;
    }
    .borderBox{
        border:2px dashed transparent;
        transition: 0.5s;
        cursor:pointer;
    }
    /*.borderBox:hover{*/
        /*border:4px dashed #1b90ed;*/
        /*transition: 0.5s*/
    /*}*/
    .borderBox.active{
        border:2px dashed #1b90ed;
        transition: 0.5s
    }

    .configBtn{
        transition:0.5s;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .shade{
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.6);
        position:absolute;
        top:0;
        left:0;
        z-index:99;
        cursor:pointer;
        transition:2s;
    }
    .noneEl{
        visibility:hidden;
    }
    .editWelBox{
        width:80%;
    }
    .po_center i{
        font-size:24px;
    }
    .po_left i,.po_right i{
        font-size:20px;
    }
    .align_mode>.item{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .btnGroupItem>.btnItem{
        z-index:9999;
    }
    .tips{
        position:absolute;
        bottom:15px;
        left:0;
        height:50px;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right:15px;
        color:#fff;
        z-index:99;
        padding-left:32px;
    }
    .redText{
        color:red;
    }
    .tipsFirst{
        font-size:14px;
    }
    .tipsSecond{
        font-size:12px;
    }
    /*文字模块版式日期时间开始*/
    .city_info{
        display:flex;
        height:45px;
        position:absolute;
        right:32px;
        /*background:#80cbc4;*/
    }
    .city_info div{
        color:#fff;
        letter-spacing:1px;
    }
    .info_one{
        height:100%;
        border-right:2px solid #fff;
        /*background:#81d4fa;*/
        padding-right:7px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_one .top{
        /*font-weight:600;*/
        font-size:22px;
    }
    .info_one .bottom{
        font-size:14px;
    }
    .info_two{
        padding-left:7px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_two div{
        font-size:14px;
    }
    /*文字模块版式日期时间结束*/
    .textBtnGroup{
        height:100%;
        position: absolute;
        top:20px;
        .btnBox{
            display:flex;
            height:auto;
            .btnItem{
                width:85px;
                margin-right:10px;
                display:flex;
                align-items:center;
                justify-content: center;
                img{
                    width:100%;
                    height:auto;
                }
            }
        }
    }
    .firstLineForm{
        display:flex;
        justify-content: center;
    }
    .inputBox{
        width:60%;
    }
    .player{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 80px;
        height: 80px;
        border-radius: 40px;
        font-size: 80px;
        line-height: 75px;
        text-align: center;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;

    }
    .paused{
        display: none;
    }
    .imgBox:hover .paused{
        display: block;
    }
</style>
