import request from "@/api/request";
import qs from "querystring";


// 获取报障列表
export function getHotelRoomRemaining(param) {
    return request({
        url: `v2/Hotelrooms/getHotelRoomRemaining`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  导出接口
export function exportRoomRemaining(param) {
    return request({
        url: `v2/Hotelrooms/exportRoomRemaining`,
        method: "post",
        data: qs.stringify(param),
        responseType: 'blob'
    });
}