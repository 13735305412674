import request from './request';

//  上传汇款单
export function uploadWithdrawPic(param) {
    return request({
        url: `v1/partnerwithdrawindependent/updateInfo`,
        method: 'post',
        data: param,
    });
}
//  修改汇款状态
export function updatePayAll(param) {
    return request({
        url: `v1/partnerwithdrawindependent/updateAll`,
        method: 'post',
        data: param,
    });
}
//  提现审核
export function iddWithdrawPassOrNo(param) {
    return request({
        url: `v1/partnerwithdrawindependent/checkStatus`,
        method: 'post',
        data: param,
    });
}
//  影院房现审核
export function ukWithdrawPassOrNo(param) {
    return request({
        url: `v1/Partnerwithdrawinyouke/checkStatus`,
        method: 'post',
        data: param,
    });
}
//  查询扣款列表详情
export function getDeductedList(param) {
    return request({
        url: `v1/partnerwithdrawindependent/deductedList?` + param,
        method: 'get',

    });
}
//  提现统计数据汇总
export function getStatisticalAll(param) {
    return request({
        url: `v1/partnerwithdrawindependent/statisticalAll`,
        method: 'post',
        data: param,
    });
}
//  提现统计  管理合伙人提现列表获取
export function getpartnerListAll(param) {
    return request({
        url: `v1/partnerwithdrawindependent/partnerList`,
        method: 'post',
        data: param,
    });
}
//  提现统计  扣款操作
export function createDeducted(param) {
    return request({
        url: `v1/partnerwithdrawindependent/createDeducted`,
        method: 'post',
        data: param,
    });
}
// 获取扣款详情通过data
export function getDeductedEventList(param) {
    return request({
        url: `v1/partnerwithdrawindependent/dateToDeductedList?` + param,
        method: 'GET',
        // data: param,
    });
}
// 获取提现申请通过data
export function getPartnerWithdrawEventList(param) {
    return request({
        url: `v1/partnerwithdrawindependent/dateToPartnerWithdraw?` + param,
        method: 'GET',
        // data: param,
    });
}
//  独立影视 对账
//  独立影视对账单审核
export function independentReconciliationAction(param) {
    return request({
        url: `v1/review/independentReconciliationAction`,
        method: 'post',
        data: param,
    });
}
//  查询对账单账单驳回信息  
export function getNoPassMessage(param) {
    return request({
        url: `v1/reconciliation/getPreReconciliationMessage`,
        method: 'post',
        data: param,
    });
}
//  查询对账单账单详细信息  
export function getIddReconciliationDetailByContractAndDate(param) {
    return request({
        url: `v1/Independentreconciliation/getReconciliationDetailByContractAndDate`,
        method: 'post',
        data: param,
    });
}
// 获取预对账酒店记录流水号
export function InitiateReconciliation(param) {
    return request({
        url: `v1/Independentreconciliation/generateReconciliation`,
        method: 'post',
        data: param,
    });
}
// 获取对账记录列表
export function getReconciliationList(param) {
    return request({
        url: `v1/Independentreconciliation/getReconciliationList`,
        method: 'post',
        data: param,
    });
}

// 获取对账预览列表    
export function getReconciliationPreview(param) {
    return request({
        url: `v1/Independentreconciliation/getPreviewReconciliation`,
        method: 'post',
        data: param,
    });
}
// 获取合作伙伴各收益方式汇总数据   

export function getCheckAmountByPartner(param) {
    return request({
        url: `v1/Independentreconciliation/getCheckAmountByPartner`,
        method: 'post',
        data: param,
    });
}
// 获取合作伙伴对账单列表 
export function getPartnerReconciliationList(param) {
    return request({
        url: `v1/Independentreconciliation/getPartnerReconciliationList`,
        method: 'post',
        data: param,
    });
}
// 根据对账单流水号获取对账酒店列表
export function getReconciliationHotelList(param) {
    return request({
        url: `v1/reconciliation/getReconciliationHotelList`,
        method: 'post',
        data: param,
    });
}
// 根据流水发起审核工作
export function sponsorReconciliationFlow(param) {
    return request({
        url: `v1/Independentreconciliation/sponsorReconciliationFlow`,
        method: 'post',
        data: param,
    });
}
// 根据待办id获取对账单详情
export function getReconciliationContengByID(param) {
    return request({
        url: `v1/review/view?` + param,
        method: 'GET',
        // data: param,
    });
}
// 对账单审核接口
export function reconciliationAction(param) {
    return request({
        url: `v1/review/reconciliationAction`,
        method: 'POST',
        data: param,
    });
}
//  字典表列表  tab类别  
export function getTabList(param) {
    return request({
        url: `v1/sysdic/list?parent_id=001`,
        method: 'GET',
    });
}
// 判断当前账号本月是否发起对账单
export function checkReconciliationExistMonth(param) {
    return request({
        url: `v1/independentreconciliation/checkReconciliationExistMonth`,
        method: 'post',
        data: param,
    });
}
// 获取独立影视合作伙伴下全部酒店id
export function getAllIds(param) {
    return request({
        url: `v1/Independentreconciliation/getAllIds`,
        method: 'post',
        data: param,
    });
}