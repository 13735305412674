<!-- 酒店分类 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        
        <span v-if='!query' slot="second">酒店管理</span>
        <span v-if='!query' slot="third">酒店分类</span>
        <span v-if='query' slot="second">酒店分类</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <!-- tab -->
      <div class="tab_box">
        <el-tabs v-model="activeTag" @tab-click="handleClick">
          <el-tab-pane label="酒店集团" name="group">
            <Group ref="group"></Group>
          </el-tab-pane>
          <el-tab-pane label="酒店品牌" name="brand">
            <Brand ref="brand"></Brand>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
<script>
import Group from "@/components/operation/HotelClassify/group";
import Brand from "@/components/operation/HotelClassify/brand";
export default {
  name: "hotelClassify",
  components: { Group, Brand },
  data() {
    return {
      bread: {
        level: 3,
        // firstPath: { path: "/indexShow" },
        // thirdPath: { path: "/hotelManAll" },
        // fourthPath: { path: "/hotelManAll" },
      },
      activeTag: "group",
      tableDataBrand: [],
      brandOperShow: false,
      current: {},
      paginationII: {
        total: 0,
        currentPage: 1,
      },
      query:null,
    };
  },
  methods: {
    init() {},
    handleClick() {
      this.$refs[this.activeTag].getList(1);
    },
    handleMod() {},
  },
  mounted() {
    this.init();
    this.query = sessionStorage.getItem("apaasData");
  },
};
</script>
<style lang="scss" scoped>
.tab_box {
  // margin-top: 20px;
}
</style>
