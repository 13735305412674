<template>
  <div class="allPageContent">
    <div id="china-map"></div>
    <div id="china-map2"></div>
  </div>
</template>
<script>
import "../china.js";
// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/chart/pie");
require("echarts/lib/component/markLine");
require("echarts/lib/component/geo");
require("echarts/lib/chart/scatter");
require("echarts/lib/chart/map");
import city from "echarts/map/json/china-cities.json";

import "./shengyang";
export default {
  data() {
    return {
      numberList: [],
      pointIndexList: [],
      showPointList: [],
      allPointList: [
        ["123.498137", "41.814742"],
        ["123.431886", "41.807722"],
        ["123.427785", "41.797898"],
        ["123.485209", "41.862114"],
        ["123.500788", "41.854307"],
        ["123.434731", "41.851099"],
        ["123.46998", "41.757794"],
        ["123.66317", "41.873088"],
        ["123.694018", "42.007064"],
        ["123.431399", "41.807924"],
        ["123.426503", "41.792997"],
        ["123.430472", "41.807972"],
        ["123.430409", "41.80872"],
        ["123.434576", "41.813348"],
        ["123.420241", "41.804375"],
        ["123.466155", "41.800246"],
        ["123.465812", "41.78863"],
        ["123.47872", "41.779136"],
        ["123.461584", "41.804924"],
        ["123.465477", "41.787817"],
        ["123.473027", "41.787925"],
        ["123.495997", "41.848989"],
        ["123.522472", "41.80922"],
        ["123.475775", "41.823611"],
        ["123.487403", "41.829177"],
        ["123.50246", "41.802694"],
        ["123.495032", "41.845101"],
        ["123.522535", "41.929846"],
        ["123.434279", "41.85742"],
        ["123.42224", "41.825943"],
        ["123.438832", "41.866184"],
        ["123.408702", "41.83553"],
        ["123.387463", "41.904547"],
        ["123.433625", "41.848889"],
        ["123.426951", "41.829861"],
        ["123.43987", "41.850966"],
        ["123.462877", "41.858582"],
        ["123.434066", "41.85899"],
        ["123.422401", "41.852847"],
        ["123.434089", "41.856438"],
        ["123.417132", "41.830693"],
        ["123.366151", "41.786015"],
        ["123.328743", "41.783901"],
        ["123.390849", "41.820472"],
        ["123.385751", "41.805818"],
        ["123.679153", "41.579925"],
        ["123.31967", "41.570146"],
        ["123.544183", "41.633839"],
        ["123.315031", "41.690866"],
        ["123.354207", "41.667324"],
        ["123.432126", "41.699666"],
        ["123.640892", "41.837385"],
        ["123.66431", "41.868951"],
        ["123.658046", "41.864306"],
        ["123.596262", "41.839898"],
        ["123.600787", "41.842774"],
        ["123.691786", "41.939508"],
        ["123.663583", "41.873551"],
        ["123.646678", "41.862458"],
        ["123.538381", "42.049691"],
        ["123.607076", "41.902059"],
        ["123.480137", "41.972357"],
        ["123.637439", "42.025801"],
        ["123.359607", "41.761567"],
        ["122.832973", "41.480816"],
        ["123.222657", "42.529286"],
        ["123.223332", "42.526443"],
        ["123.223531", "42.525148"],
        ["123.455083", "42.505698"],
        ["123.223375", "42.52309"],
        ["123.187338", "42.301387"],
        ["123.454835", "42.505265"],
        ["122.785229", "42.147989"],
        ["123.068162", "41.787301"],
        ["122.867868", "41.968118"],
        ["122.959094", "41.866399"],
        ["122.568406", "42.055967"],
        ["123.418796", "41.80314"],
        ["123.414644", "41.801897"],
        ["123.419956", "41.801816"],
        ["123.434704", "41.812366"],
        ["123.405538", "41.792256"],
        ["123.416311", "41.77476"],
        ["123.418259", "41.803169"],
        ["123.466966", "41.789681"],
        ["123.468144", "41.809472"],
        ["123.485452", "41.778784"],
        ["123.461552", "41.805241"],
        ["123.462202", "41.804413"],
        ["123.47801", "41.76358"],
        ["123.497925", "41.796305"],
        ["123.523929", "41.846778"],
        ["123.507857", "41.811427"],
        ["123.489919", "41.815245"],
        ["123.499477", "41.815177"],
        ["123.403046", "41.820813"],
        ["123.43462", "41.857214"],
        ["123.412441", "41.84245"],
        ["123.158849", "41.637685"],
        ["123.522227", "41.763548"],
        ["123.490488", "41.675601"],
        ["123.617726", "41.834824"],
        ["123.423636", "41.921668"],
        ["123.541798", "42.055375"],
        ["123.479112", "41.97152"],
        ["123.644002", "42.070502"],
        ["123.34898", "41.917984"],
        ["123.414937", "41.848286"],
        ["123.210858", "42.00889"],
        ["123.320432", "41.80537"],
        ["123.38988", "41.896604"],
        ["123.331991", "41.84539"],
        ["123.247361", "41.903261"],
        ["123.19236", "41.870709"],
        ["122.898245", "41.525314"],
        ["122.974352", "41.690603"],
        ["122.873419", "41.708247"],
        ["122.745013", "41.521161"],
        ["122.858746", "42.668059"],
        ["123.360352", "42.743648"],
        ["123.340343", "42.389566"],
        ["123.352489", "42.409481"],
        ["123.368207", "42.405627"],
        ["123.182698", "42.300287"],
        ["123.155507", "41.831263"],
        ["123.410935", "41.784216"],
        ["123.426021", "41.771862"],
        ["123.413572", "41.76765"],
        ["123.431722", "41.771843"],
        ["123.41584", "41.771404"],
        ["123.43482", "41.813354"],
        ["123.427489", "41.772303"],
        ["123.418438", "41.802432"],
        ["123.429084", "41.81345"],
        ["123.464547", "41.800958"],
        ["123.464151", "41.803003"],
        ["123.467359", "41.809073"],
        ["123.46226", "41.803916"],
        ["123.466234", "41.790724"],
        ["123.463333", "41.805176"],
        ["123.462687", "41.804764"],
        ["123.46487", "41.799958"],
        ["123.462847", "41.804273"],
        ["123.461698", "41.804623"],
        ["123.462843", "41.804508"],
        ["123.46241", "41.803289"],
        ["123.503282", "41.774153"],
        ["123.464096", "41.781981"],
        ["123.488769", "41.814108"],
        ["123.51341", "41.804043"],
        ["123.502753", "41.801901"],
        ["123.468264", "41.823811"],
        ["123.513797", "41.802629"],
        ["123.494839", "41.845018"],
        ["123.494427", "41.803315"],
        ["123.444942", "41.848434"],
        ["123.434101", "41.858882"],
        ["123.435871", "41.848855"],
        ["123.434541", "41.857538"],
        ["123.436201", "41.856574"],
        ["123.382927", "41.83872"],
        ["123.43658", "41.853667"],
        ["123.434636", "41.855658"],
        ["123.434632", "41.857051"],
        ["123.331813", "41.779442"],
        ["123.356075", "41.797656"],
        ["123.381747", "41.813358"],
        ["123.356051", "41.783092"],
        ["123.367287", "41.782532"],
        ["123.337483", "41.782803"],
        ["123.369752", "41.812339"],
        ["123.459918", "41.485937"],
        ["123.331167", "41.722691"],
        ["123.313202", "41.690801"],
        ["123.589871", "41.507769"],
        ["123.310829", "41.661323"],
        ["123.744161", "41.597393"],
        ["123.600064", "41.846071"],
        ["123.648848", "41.94103"],
        ["123.597614", "41.834127"],
        ["123.443385", "41.946448"],
        ["123.54673", "41.99275"],
        ["123.647649", "42.072648"],
        ["123.337872", "41.855898"],
        ["123.350397", "41.860951"],
        ["123.299806", "41.837519"],
        ["123.348336", "41.862706"],
        ["123.389944", "41.896546"],
        ["123.389751", "41.896428"],
        ["123.408304", "41.858556"],
        ["123.035919", "41.625554"],
        ["122.743285", "41.5253"],
        ["122.737768", "41.525014"],
        ["122.790896", "41.516863"],
        ["123.340686", "42.770875"],
        ["123.331455", "42.74759"],
        ["123.364105", "42.72148"],
        ["123.356018", "42.73618"],
        ["123.44596", "42.502722"],
        ["123.385585", "42.394978"],
        ["123.423971", "42.514491"],
        ["123.315763", "42.339056"],
        ["123.315222", "42.339311"],
        ["123.315482", "42.337479"],
        ["122.789425", "42.149018"],
        ["122.786512", "42.147921"],
        ["122.788137", "42.146761"],
        ["122.785634", "42.149327"],
        ["122.59971", "41.883301"],
      ],
      timer: null,
      timer2: null,
    };
  },
  mounted() {
    // this.drawCharts2();
    // this.drawCharts([]);

    this.getpointDate();
    // this.getHotelList(true);
    this.timer = setInterval(() => {
      this.getpointDate();
    }, 3000);
    // this.timer2 = setInterval(() => {
    //   this.getHotelList(false);
    // }, 180000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timer2);
    this.timer2 = null;
  },
  methods: {
    // 获取点位信息列表
    getHotelList(flag) {
      let json = {
        hotel_contract: this.hotel_contract,
        is_rand: 0,
      };
      getOnLineLocation(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (
              res.data.data &&
              res.data.data.rows &&
              res.data.data.rows.length > 0
            ) {
              this.allPointList = res.data.data.rows.filter((i) => {
                if (i.status == 1 && i.lng != "" && i.lat != "") {
                  return i;
                }
              });
              this.allPointList = this.allPointList.map((i) => {
                return [i.lng, i.lat];
              });
              if (flag) {
                this.getpointDate();
              }
            } else {
              this.getpointDate();
              return;
            }
          } else {
            this.allPointList = [];
            this.getpointDate();
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    // 随机数
    random(min, max, listName) {
      let n = Math.floor(Math.random() * (max - min)) + min;
      let flag;
      if (listName == "point") {
        flag = this.pointIndexList.indexOf(n) == -1;
      } else {
        flag = this.numberList.indexOf(n) == -1;
      }
      if (n && flag) {
        return n;
      } else {
        return this.random(min, max, listName);
      }
    },
    // 点位数据更新
    getpointDate() {
      this.numberList = [];
      // 大小随机   停用
      for (var i = 1; i <= 15; i++) {
        let n = this.random(1, 100, "number");
        this.numberList.push(n);
      }
      // 展示随机元素
      this.pointIndexList = [];
      if (this.allPointList.length <= 15) {
        this.pointIndexList = [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
        ];
      } else {
        for (var i = 1; i <= 15; i++) {
          let n = this.random(0, this.allPointList.length, "point");
          this.pointIndexList.push(n);
        }
      }
      this.showPointList = this.allPointList.filter((i, n) => {
        if (this.pointIndexList.indexOf(n) != -1) {
          return i;
        }
      });
      this.showPointList = this.showPointList.map((i, n) => {
        i[2] = this.numberList[n];
        return i;
      });
      this.drawCharts2();
      this.drawCharts(this.showPointList);
    },
    // 渲染图
    drawCharts(list) {
      // 基于准备好的dom，初始化echarts实例
      var chinaMap = echarts.init(document.getElementById("china-map"));
      window.onresize = chinaMap.resize; // 窗口或框架被调整大小时执行chinaMap.resize
      chinaMap.setOption({
        geo: {
          // 这个是重点配置区
          map: "沈阳市", // 表示中国地图
          roam: true,
          zoom: 1.2,
          zlevel: 15,
          scaleLimit: {
            min: 1.2,
            max: 1.2,
          },
          roam: "scale",
          silent: true,
          //   center: [115.97, 29.71],
          label: {
            show: false, // 是否显示对应地名
            textStyle: {
              color: "#fff",
            },
          },
          regions: [
            {
              name: "铁西区",
              itemStyle: {
                areaColor: "#000b38",
                color: "#000b38",
              },
            },
            {
              name: "康平县",
              itemStyle: {
                areaColor: "#000b38",
                color: "#000b38",
              },
            },
            {
              name: "大东区",
              itemStyle: {
                areaColor: "#020F44",
                color: "#020F44",
              },
            },
            {
              name: "沈北新区",
              itemStyle: {
                areaColor: "#000b38",
                color: "#000b38",
              },
            },
            {
              name: "法库县",
              itemStyle: {
                areaColor: "#020F44",
                color: "#020F44",
              },
            },
            {
              name: "于洪区",
              itemStyle: {
                areaColor: "#020F44",
                color: "#020F44",
              },
            },
            {
              name: "康平县",
              itemStyle: {
                areaColor: "#020F44",
                color: "#020F44",
              },
            },
          ],
          itemStyle: {
            normal: {
              borderColor: "rgba(20,54,143)",
              borderWidth: "1",
              areaColor: "#071654",
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },

        series: [
          {
            name: "启动次数", // 浮动框的标题
            type: "map",
            geoIndex: 1,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo", // 对应上方配置
            zlevel: 48,
            effectType: "ripple",
            showEffectOn: "render",
            itemStyle: {
              color: "#01d0fd",
            },
            rippleEffect: {
              color: "#01d0fd",
              number: 3,
              scale: 6,
              brushType: "fill",
            },
            symbol: "circle",
            symbolSize: 5,
            // symbolSize: function (params) {
            //   return (params[2] / 100) * 5 + 3;
            // },
            data: list,
          },
        ],
      });
    },
    // 底层效果图
    drawCharts2() {
      // 基于准备好的dom，初始化echarts实例
      var chinaMap = echarts.init(document.getElementById("china-map2"));
      window.onresize = chinaMap.resize; // 窗口或框架被调整大小时执行chinaMap.resize
      chinaMap.setOption({
        tooltip: {
          formatter(params) {
            return "省份： " + params.name;
          },
        }, // 鼠标移到图里面的浮动提示框
        dataRange: {
          show: false,
          min: 0,
          max: 1000,
          text: ["High", "Low"],
          realtime: true,
          calculable: true,
          color: ["#000a32", "#000b38", "#06134a"],
        },
        geo: {
          // 这个是重点配置区
          map: "沈阳市", // 表示中国地图
          zlevel: 1,
          roam: true,
          zoom: 1.2,
          scaleLimit: {
            min: 1.2,
            max: 1.2,
          },
          roam: "scale",
          silent: true,
          //   center: [115.97, 29.71],
          label: {
            show: false, // 是否显示对应地名
            textStyle: {
              color: "#fff",
            },
          },
          regions: [
            {
              name: "铁西区",
              itemStyle: {
                areaColor: "#000b38",
                color: "#000b38",
              },
            },
            {
              name: "于洪区",
              itemStyle: {
                areaColor: "#000a32",
                color: "#000a32",
              },
            },
            {
              name: "贵州",
              itemStyle: {
                areaColor: "#000b38",
                color: "#000b38",
              },
            },
            {
              name: "江西",
              itemStyle: {
                areaColor: "#000b38",
                color: "#000b38",
              },
            },
            {
              name: "四川",
              itemStyle: {
                areaColor: "#000a32",
                color: "#000a32",
              },
            },
            {
              name: "陕西",
              itemStyle: {
                areaColor: "#000a32",
                color: "#000a32",
              },
            },
          ],
          itemStyle: {
            normal: {
              borderColor: "rgba(84,158,255)",
              borderWidth: "3",
              areaColor: "#06134a",
              shadowColor: "rgba(84,158,255)",
              shadowBlur: 6,
              shadowOffsetX: 0,
              shadowOffsetY: -1,
              borderCap: "round",
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },

        series: [
          {
            name: "启动次数", // 浮动框的标题
            type: "map",
            geoIndex: 0,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.allPageContent {
  height: 565px;
  width: 695px;
  color: #fff;
  position: relative;
  #china-map {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 565px;
    width: 695px;
    z-index: 3;
  }
  #china-map2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 565px;
    width: 695px;
    z-index: 1;
  }
}
</style>
