<template>
    <div>
        <!-- <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">渠道商政策</span>
                <span slot="third">渠道商政策列表</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div> -->

        <el-card class="box-card">
            <!-- <back @backMeth="backMeth"></back> -->
            <div class="newsBox">
                <div class="newsTitle">{{title}}</div>
                <!--<div class="newsInfo">-->
                    <!--<span class="timeBox">2020年07月15日 20:15:51</span>-->
                    <!--<span>发布人：张三</span>-->
                <!--</div>-->
                <div class="newsDetail" v-html="htmlCon">
                    <!--【南方+7月15日讯】英国首相约翰逊7月14日宣布从明年起，禁止英国电信公司向华为购买新的5G设备，并表示到2027年将从英国的5G网络中移除所有华为公司的设备。这与约翰逊今年1月对华为的态度，截然不同。那么英国政府对华为的-->
                    <!--态度是何时发生转变的呢？就让我们来梳理一下，华为与英国政府之间的时间线。-->
                    <!--<img src="https://x0.ifengimg.com/ucms/2020_29/F21287E8E7875174B2CB74F632D2D365E26B0EDF_w700_h394.jpg" alt="">-->
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "InfoPubCoopDetail",
        props:{
            query:{
                type:Object,
                default:{}
            }
        },
        data:()=>({
            getMsg:{},
            htmlCon:'',
            title:'',
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/policyList'},
            },
            token:''
        }),
        methods:{
            getInfoDetail(id){
                this.$http.get(this.global.infoView,{
                    params:{
                        token:this.token,
                        id:id
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.title=res.data.data.title;
                        this.htmlCon=res.data.data.text;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            backMeth(){
                this.$router.push({path:'./PolicyList'})
            },
        },
         created: function () {
            this.getMsg = this.query
        },
        watch:{
           query (newValue, oldValue) {
            this.getMsg = newValue
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            // var query=this.$route.query;
            this.getInfoDetail(this.getMsg.id);
        }

    }
</script>

<style scoped>
.box-card{
    margin-top: -10px;
}
    .newsDetail img{
        width:100%!important;
    }
    img,video{
        width:100%!important;
        height:100%!important;
    }
    .newsTitle{
        text-align: center;
        font-size:18px;
        font-weight:600;
    }
    .newsBox{
        padding:0 20px;
    }
    .newsInfo{
        margin-top:20px;
        font-size:14px;
        color:#acb3b8;
    }
    .timeBox{
        margin-right:10px;
    }
    .newsDetail{
        margin-top:20px;
        line-height:30px;
        letter-spacing: 1px;
        /*text-indent:50px;*/
    }
</style>
