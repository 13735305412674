<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">财务管理</span>
                <span slot="third">收益管理</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>

        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow"
                        @condition="getCondition" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card" v-if="showFunModel('数据统计')">
            <div class="title blueTitle">数据统计</div> 
            <div class="lineContent">
                <el-row :gutter="20" class="rowBox vertical borderBox">
                    <index-four-row :span="8" :type="type">
                        <span slot="numTitle">总收益</span>
                        <span slot="num">{{showDataCom(showData.totalWithdraw,'money')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row>
                    <index-four-row :span="8" :type="type">
                        <span slot="numTitle">已提现金额</span>
                        <span slot="num">{{showDataCom(showData.alreadyWithdraw,'money')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row>
                    <index-four-row :span="8" :type="type">
                        <span slot="numTitle">未提现金额</span>
                        <span slot="num">{{showDataCom(showData.notWithdraw,'money')}}</span>
                        <span slot="unit">元</span>
                    </index-four-row>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card" v-if="showFunModel('列表')" >
            <div class="title chartTitle blueTitle">收益管理列表</div>
            <table-list :table="table" @tableMeth="tableMeth" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading"></table-list>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import commonMeth from '../../api/method'
    export default {
        name: "adManCustom",
        data: () => ({
            type:'timeCenter',
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
            },
            table:{
                tableHeader:[
                    {prop:'partner_name',label:'合作伙伴名称',width:''},
                    {prop:'totalWithdraw',label:'总收益',width:''},
                    {prop:'alreadyWithdraw',label:'已提现金额',width:''},
                    {prop:'notWithdraw',label:'未提现金额',width:''},
                ],
                tableList:[ ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'finManIncome',
                name:'finManIncome',
                option:false,
                loading:false
            },
            topSearchShow:{ },
            condition:{},
            operateIds:'',
            token:'',
            showData:{
                totalWithdraw:0,
                alreadyWithdraw:0,
                notWithdraw:0,
            },
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            allData:{},
            funArr:[],
            allLoadingState:false,
        }),
        methods:{
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if(type==='start'){
                            this.operateMeth('start','启用');
                        }else if(type==='stop'){
                            this.operateMeth('stop','暂停');
                        }else if(type==='del'){
                            this.operateMeth('del','删除');
                        }
                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '财务信息',
                        type: 'warning'
                    });
                }
            },
            startMeth(){

            },
            stopMeth(){

            },
            operateMeth(){
                var param='';
                var url='';
                var obj={};
                if(type==='start'){
                    url=this.global.infoDestory;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };

                }else if(type==='stop'){
                    url=this.global.infoPublish;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };
                }else if(type==='del'){
                    url=this.global.infoPublish;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };
                }
                param=qs.stringify(obj);
                // this.$http.post(url,param).then(res=>{
                //     console.log('删除,发布',res);
                //     if(res.data.code===100000){
                //         this.$message.success(str+'成功');
                //         this.tableMeth();
                //     }else{
                //         this.$message.error(res.msg);
                //     }
                // }).catch(()=>{
                //     this.$message.error('返回错误');
                // })
            },
            newBuild(){
                this.$router.push({path:'/adManCusNew'})
            },
            tableMeth(orderVal){
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.table.loading=true;
                this.$http.get(this.global.getIncomePartnerList,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        limit:this.table.limit,
                        partner_code:this.condition.partner_id,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=tableData.total;
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.table.loading=true;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.table.loading=true;
                this.tableMeth();
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='group'  || auth_type==='factory'){
                    this.topSearchShow={
                        coopType:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        coopType:true,
                    }
                }
            },
            getCondition(val){
                this.condition.partner_id=Array.isArray(val.noTypePartnerCode)?val.noTypePartnerCode.join(','):'';
                this.condition.keyword=val.keyword;
                this.table.page=1;
                this.tableMeth();
                this.showDataMeth();
            },
            getTableSelect(val){
                var operateIds='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+','
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            showDataMeth(){
                this.$http.get(this.global.getIncomeStatistics,{
                    params:{
                        token:this.token,
                        partner_code:this.condition.partner_id,
                    }
                }).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        this.showData=res.data.data;
                    }else{
                        this.$message.error('返回错误');
                    }
                    this.showLoading=false;
                }).catch(()=>{
                    this.showLoading=false;
                })
            },
            getFoldMeth(val){
                this.fold=val;
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getTopSearchShow();
            this.tableMeth();
            this.showDataMeth();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
            });
        },
        computed:{
            showDataCom(){
                return function(s,type='',n=2){
                    if(type==='money'){
                        if(s===null){
                            return 0;
                        }else{
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    }else{
                        if(s===null){
                            return 0;
                        }else{
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading,false,this.showLoading);
            },
        }
    }
</script>

<style scoped lang="scss">

    .rightBtn{
        margin-left:auto;
    }
    .rowBox{
        margin-top:20px;
        box-sizing: border-box;
    }

</style>
