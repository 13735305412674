//公共js绑定对象 
var publicObj = {
        // 聚屏商酒店导入json数据转xsl处理
        screenTableToExcel(json, fileName) {
            // 要导出的json数据
            const jsonData = json;
            // 列标题
            let str = `<tr><td>酒店名称</td><td>失败原因</td></tr>`;
            // 循环遍历，每行加入tr标签，每个单元格加td标签
            for (let i = 0; i < jsonData.length; i++) {
                str += '<tr>';
                for (const item in jsonData[i]) {
                    str += `<td>${jsonData[i][item]}\t</td>`;
                }
                str += '</tr>';
            }
            // Worksheet名
            const worksheet = fileName, //文件名称
                uri = 'data:application/vnd.ms-Excel;base64,',
                // 下载的表格模板数据
                template = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
            <head><meta http-equiv= 'Content-Type' content='text/html'; charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
              <x:Name>${worksheet}</x:Name>
              <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
              </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
              </head><body><table>${str}</table></body></html>`,
                // 下载模板
                elink = document.createElement('a');
            elink.download = fileName + '.xls';
            // elink.download = fileName + '.xls';
            elink.style.display = 'none';
            elink.href = uri + base64(template); //base64编码
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            // window.location.href = uri + base64(template);
        },
        // 聚屏商酒店导入json数据转xsl处理
        screenTableRoomToExcel(json, fileName) {
            // 要导出的json数据
            const jsonData = json;
            // 列标题
            let str = `<tr><td>酒店名称</td><td>房间号</td><td>失败原因</td></tr>`;
            // 循环遍历，每行加入tr标签，每个单元格加td标签
            for (let i = 0; i < jsonData.length; i++) {
                str += '<tr>';
                for (const item in jsonData[i]) {
                    str += `<td>${jsonData[i][item]}\t</td>`;
                }
                str += '</tr>';
            }
            // Worksheet名
            const worksheet = fileName, //文件名称
                uri = 'data:application/vnd.ms-Excel;base64,',
                // 下载的表格模板数据
                template = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta http-equiv= 'Content-Type' content='text/html'; charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                  <x:Name>${worksheet}</x:Name>
                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                  </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                  </head><body><table>${str}</table></body></html>`,
                // 下载模板
                elink = document.createElement('a');
            elink.download = fileName + '.xls';
            // elink.download = fileName + '.xls';
            elink.style.display = 'none';
            elink.href = uri + base64(template); //base64编码
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            // window.location.href = uri + base64(template);
        },
        // 资产报表影视 导入json数据转xsl处理
        assetsMovieToExcel(json, fileName) {
            // 要导出的json数据
            const jsonData = json;
            // 列标题
            let str = `<tr><td>类别</td><td>酒店数量</td><td>客房数</td><td>激活终端数</td><td>终端统计数</td><td>单间ARPU(元/间)</td></tr>`;
            // 循环遍历，每行加入tr标签，每个单元格加td标签
            for (let i = 0; i < jsonData.length; i++) {
                str += '<tr>';
                for (const item in jsonData[i]) {
                    str += `<td>${jsonData[i][item]}\t</td>`;
                }
                str += '</tr>';
            }
            // Worksheet名
            const worksheet = fileName, //文件名称
                uri = 'data:application/vnd.ms-Excel;base64,',
                // 下载的表格模板数据
                template = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta http-equiv= 'Content-Type' content='text/html'; charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                  <x:Name>${worksheet}</x:Name>
                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                  </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                  </head><body><table>${str}</table></body></html>`,
                // 下载模板
                elink = document.createElement('a');
            elink.download = fileName + '.xls';
            // elink.download = fileName + '.xls';
            elink.style.display = 'none';
            elink.href = uri + base64(template); //base64编码
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            // window.location.href = uri + base64(template);
        },
        // 资产报表广告 导入json数据转xsl处理
        assetsAdvertisementToExcel(json, fileName) {
            // 要导出的json数据
            const jsonData = json;
            // 列标题
            let str = `<tr><td>类别</td><td>酒店数量</td><td>客房数</td><td>激活终端数</td></tr>`;
            // 循环遍历，每行加入tr标签，每个单元格加td标签
            for (let i = 0; i < jsonData.length; i++) {
                str += '<tr>';
                for (const item in jsonData[i]) {
                    str += `<td>${jsonData[i][item]}\t</td>`;
                }
                str += '</tr>';
            }
            // Worksheet名
            const worksheet = fileName, //文件名称
                uri = 'data:application/vnd.ms-Excel;base64,',
                // 下载的表格模板数据
                template = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta http-equiv= 'Content-Type' content='text/html'; charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                  <x:Name>${worksheet}</x:Name>
                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                  </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                  </head><body><table>${str}</table></body></html>`,
                // 下载模板
                elink = document.createElement('a');
            elink.download = fileName + '.xls';
            // elink.download = fileName + '.xls';
            elink.style.display = 'none';
            elink.href = uri + base64(template); //base64编码
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            // window.location.href = uri + base64(template);
        },
        // 资产报表终端 导入json数据转xsl处理
        assetsTermnalToExcel(json, fileName) {
            // 要导出的json数据
            const jsonData = json;
            // 列标题
            let str = `<tr><td>类别</td><td>酒店数量</td><td>客房数</td><td>激活终端数</td><td>日活率</td><td>终端统计数</td></tr>`;
            // 循环遍历，每行加入tr标签，每个单元格加td标签
            for (let i = 0; i < jsonData.length; i++) {
                str += '<tr>';
                for (const item in jsonData[i]) {
                    str += `<td>${jsonData[i][item]}\t</td>`;
                }
                str += '</tr>';
            }
            // Worksheet名
            const worksheet = fileName, //文件名称
                uri = 'data:application/vnd.ms-Excel;base64,',
                // 下载的表格模板数据
                template = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta http-equiv= 'Content-Type' content='text/html'; charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                  <x:Name>${worksheet}</x:Name>
                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                  </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                  </head><body><table>${str}</table></body></html>`,
                // 下载模板
                elink = document.createElement('a');
            elink.download = fileName + '.xls';
            // elink.download = fileName + '.xls';
            elink.style.display = 'none';
            elink.href = uri + base64(template); //base64编码
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            // window.location.href = uri + base64(template);
        },
        // 错误no广告信息导出
        errorNoAdvList(json, fileName) {
            // 要导出的json数据
            const jsonData = json;
            // 列标题
            let str = `<tr><td>酒店名称</td><td>酒店合同号</td></tr>`;
            // 循环遍历，每行加入tr标签，每个单元格加td标签
            for (let i = 0; i < jsonData.length; i++) {
                str += '<tr>';
                for (const item in jsonData[i]) {
                    str += `<td>${String(jsonData[i][item])}\t</td>`;
                }
                str += '</tr>';
            }
            // Worksheet名
            const worksheet = fileName, //文件名称
                uri = 'data:application/vnd.ms-Excel;base64,',
                // 下载的表格模板数据
                template = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
                <head><meta http-equiv= 'Content-Type' content='text/html'; charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                  <x:Name>${worksheet}</x:Name>
                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                  </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                  </head><body><table>${str}</table></body></html>`,
                // 下载模板
                elink = document.createElement('a');
            elink.download = fileName + '.xls';
            // elink.download = fileName + '.xls';
            elink.style.display = 'none';
            elink.href = uri + base64(template); //base64编码
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
            // window.location.href = uri + base64(template);
        }
    }
    // 文件转码
function base64(s) {
    return window.btoa(unescape(encodeURIComponent(s)));
}


export default publicObj;