import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
//  
export function hotelRoomPreview(data) {
  return request({
    url: `${vi}/Hotelrooms/hotelRoomPreview`,
    method: 'post',
    data,
  });
}
export function importVirtualHotel(data) {
  return request({
    url: `${vi}/Hotelrooms/importVirtualHotel`,
    method: 'post',
    data,
  });
}


