<template>
    <div class="main">
        <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px" class="demo-dynamic">
            <el-form-item
                    v-for="(domain, index) in dynamicValidateForm.test"
                    :label="'域名' + index"
                    :key="domain.key"
                    :prop="'test.' + index + '.value'"
                    :rules="rules">
                <el-input v-model="domain.value"></el-input><el-button @click.prevent="removeDomain(domain)">删除</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button>
                <el-button @click="addDomain">新增域名</el-button>
                <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    export default {
        name: "test",
        data:()=>({
            test:"1112222",
            thumbIndex:0,
            formData:{
                modelName:'酒店餐厅',
                horizontalImg:'',
                verticalImg:'',

            },
            dynamicValidateForm: {
                test: [{
                    value: ''
                }],
                email: ''
            },
            rules:{
                required: true, message: '1域名不能为空', trigger: 'blur'}

        }),
        methods:{
            swiperInit(){
                var galleryThumbs = new Swiper('.gallery-thumbs', {
                    observer: true, //修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, //修改swiper的父元素时，自动初始化swiper
                    spaceBetween: 10,
                    slidesPerView: 4,
                    freeMode: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                });
                var galleryTop = new Swiper('.gallery-top', {
                    observer: true, //修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, //修改swiper的父元素时，自动初始化swiper
                    spaceBetween: 10,
                    // slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    thumbs: {
                        swiper: galleryThumbs
                    }
                });
                galleryTop.on("transitionEnd",function(e){
                    galleryThumbs.slideTo(e.activeIndex);
                });
                galleryThumbs.on("click",function(e){
                    this.thumbIndex=e.clickedIndex;
                    console.log('点击',e);
                    console.log('结束-index',e.clickedIndex);
                    console.log('结束-index',this.thumbIndex);
                    galleryTop.slideTo(e.clickedIndex);
                })
            },
            initTwo(){
                var thumbsSwiper = new Swiper('#thumbs',{
                    spaceBetween: 10,
                    slidesPerView: 4,
                    watchSlidesVisibility: true,//防止不可点击
                })
                var gallerySwiper = new Swiper('#gallery',{
                    spaceBetween: 10,
                    thumbs: {
                        swiper: thumbsSwiper,
                    }
                })
            },
            prev(){
                this.$refs.swiper.$swiper.slidePrev();
            },
            next(){
                this.$refs.swiper.$swiper.slideNext();
            },
            onSubmit() {
                console.log('submit!');
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            removeDomain(item) {
                var index = this.dynamicValidateForm.domains.indexOf(item);
                if (index !== -1) {
                    this.dynamicValidateForm.domains.splice(index, 1)
                }
            },
            addDomain() {
                this.dynamicValidateForm.domains.push({
                    value: '',
                    key: Date.now()
                });
            }

        },

        mounted(){
          // this.swiperInit();
          this.initTwo();
        },
        computed:{
            testOne(){
                return function(val){
                    var str='';
                    if(val===this.thumbIndex){
                        str='swiper-slide-thumb-active'
                    }else{

                    }
                    return str;
                }
            }
        },

    }
</script>

<style scoped>
    @import '../../../node_modules/swiper/swiper-bundle.css';
    @import '../../../node_modules/swiper/swiper-bundle.min.css';
    .main{
        width:100%;
        height:100%;
    }
    .swiper-container {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .gallery-top {
        height: 80%;
        width: 100%;
    }

    .gallery-thumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .gallery-thumbs .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }

    .gallery-thumbs .swiper-slide-thumb-active {
        opacity: 1;
    }
    .gallery-thumbs .swiper_slide_thumb_active {
        opacity: 1;
    }
</style>
