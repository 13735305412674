import request from "@/api/request";
import qs from "querystring";

//  签名列表
export function getList(param) {
  return request({
    url: `v2/devicemanage/signatureList`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  新增签名
export function addSign(param) {
  return request({
    url: `v2/devicemanage/signatureCreate`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  修改签名
export function editSign(param) {
  return request({
    url: `v2/devicemanage/signatureUpdate`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  删除签名
export function delSign(param) {
  return request({
    url: `v2/devicemanage/signatureDelete`,
    method: "post",
    data: qs.stringify(param),
  });
}
