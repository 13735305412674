<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">分组管理</span>
        <span slot="third">分组列表</span>
        <span slot="fourth">分组添加</span>
      </top-bread>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width='80px'>
      <el-card class="box-card">

        <div class="title chartTitle blueTitle">第一步，选择酒店</div>
        <el-form-item label="选择酒店" prop='hotel_contract'>
          <el-select v-if="!query.item" :disabled="disabledHotel" style="width:300px" v-model="form.hotel_contract" filterable remote reserve-keyword placeholder="请输入酒店名称" :remote-method="remoteMethod" :loading="loading" @change="selectChange">
            <el-option v-for="item in hotelList" :key="item.hotel_contract" :label="item.hotel" :value="item.hotel_contract">
            </el-option>
          </el-select>
          <el-input v-if="query.item" style="width:300px" :disabled="true" v-model="form.hotel_name" placeholder="请输入酒店名称"></el-input>
          <!-- <el-input style="width:300px" v-model="form.hotel_contract" placeholder="请输入酒店合同号"></el-input> -->
        </el-form-item>

      </el-card>
      <el-card class="box-card">

        <div class="title chartTitle blueTitle">第二步，填写分组名称</div>
        <el-form-item label="分组名称" prop='name'>
          <el-input style="width:300px" v-model="form.name" placeholder="请输入分组名称"></el-input>
        </el-form-item>

      </el-card>
      <el-card class="box-card">
        <div class="title chartTitle blueTitle">第三步，选择房间</div>

        <el-form-item label="选择房间" prop="roomstr">
          <checkroom ref="checkroom" :hotel_contract="form.hotel_contract"></checkroom>
        </el-form-item>
        <el-form-item>
          <div class="rightBtn">
            <el-button class="trueBtn" type="primary" @click="save('form')">保 存</el-button>
            <el-button class="cancelBtn" @click="back">返 回</el-button>
          </div>

        </el-form-item>
      </el-card>

    </el-form>

  </div>

</template>
<script>
import checkroom from "./components/checkroom.vue";
import { saveRoomGroup, editData } from "./hotelGroupApi";
export default {
  name: "addHotelGroup",
  components: {
    checkroom,
  },
  data() {
    return {
      bread: {
        level: 4,
      },
      form: {
        name: "",
        hotel_contract: "",
        hotel_name: "",
        roomstr:"",
      },
      rules: {
        name: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
        hotel_contract: [
          { required: true, message: "请选择酒店", trigger: "blur" },
        ],
        roomstr: [{ required: true, message: "请选择房间", trigger: "blur" }],
      },
      hotelList: [],
      loading: false,
      disabledHotel:false,
      token: "",
      query: {},
    };
  },

  methods: {
    selectChange() {
      this.hotelList.forEach((i) => {
        if (i.hotel_contract == this.form.hotel_contract) {
          this.form.hotel_name = i.hotel;
        }
      });
      console.log(this.form);
    },
    init() {
      this.form.name = this.query.item.name;
      this.$refs.checkroom.editPage = true;
      this.form.hotel_contract = this.query.item.hotel_contract;
      this.form.hotel_name = this.query.item.hotel_name;
        console.log(this.query.item.room_str);
      this.$refs.checkroom.intData(this.query.item.room_str.split(","), this.form.hotel_contract);
    },
    back() {
      this.$router.push("/hotelGroupList");
    },
    save(form) {
      let list = this.$refs.checkroom.showRoomList.rooms;
      this.form.roomstr = list.join(",");
      // if (list.length == 0) {
      //   this.$message.warning("请选择分组房间");
      //   return;
      // }
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认保存分组？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let params = {};
            Object.assign(params, this.form);
            params.room_str = list.join(",");
            if (this.query.item) {
              params.id = this.query.item.id;
              editData(params)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("分组修改成功");
                    this.back();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {});
            } else {
              saveRoomGroup(params)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("分组添加成功");
                    this.back();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {});
            }
          });
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    getHotelList() {
       this.disabledHotel = true;
      var sortStr = "id";
      var orderStr = "DESC";
      var params = {
        token: this.token,
        page: 1,
        per_page: 20,
        keyword: "",
        sort: sortStr,
        order: orderStr,
        hotelContract: "",
        screenType: "self,other,videoRoom",
        refresh: false,
      };
      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          console.log(res);
          if (res.data.data.items.length > 0) {
            this.hotelList = res.data.data.items;
            this.form.hotel_contract = this.hotelList[0].hotel_contract;
          } else {
            this.$message.warning("无酒店信息，无法进行分组操作");
          }

          this.loading = false;
            this.disabledHotel = false;
        })
        .catch((res) => { this.loading = false; this.disabledHotel = false;});
    },
    remoteMethod(query) {
      
      if (query !== "") {
        this.loading = true;
        var sortStr = "id";
        var orderStr = "DESC";
        var params = {
          token: this.token,
          page: 1,
          per_page: 20,
          keyword: query,
          sort: sortStr,
          order: orderStr,
          hotelContract: "",
          screenType: "self,other,videoRoom",
          refresh: false,
        };
        this.$http
          .get(this.global.search, {
            params,
          })
          .then((res) => {
            console.log(res);
            this.hotelList = res.data.data.items;
            this.loading = false;
          })
          .catch((res) => {
             this.loading = false;
          });
      } else {
        this.hotelList = [];
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");

    let query = this.$route.query;
    console.log(query);
    if (query.item) {
      this.query = query;
      this.init();
    } else {
      this.getHotelList();
    }
    // this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
  padding-bottom: 0;
}
.rightBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
