<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">账户列表</span>
                <span slot="fourth">新建</span>
            </top-bread>
        </div>

        <el-card class="box-card">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
                <el-form-item label="用户名" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="所属公司名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="name">
                    <span class="getCodeSpan" style="position:absolute;right:0;bottom:0;z-index:99;padding:0 10px;color:#559bfc" @click="sendCode" v-if="!showCountDown">获取验证码</span>
                    <span class="getCodeSpan" style="position:absolute;right:0;bottom:0;z-index:99;padding:0 10px"  v-if="showCountDown">{{second}}s后重新获取</span>
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>

            </el-form>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "SysAccNew",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/sysAccList'},
                fourthPath:{path: '/sysAccList'},
            },
            ruleForm: {
                name: '',
                idCardFront:'',
                idCardBack:'',
                license:''
            },
            rules: {
                name: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
            },
            showCountDown:'',
            seconds:60,

        }),
        methods:{
            sendCode(){      //部分表单验证
                // var flag=true;
                // this.$refs['registerForm'].validateField('mobile',err => {
                //     flag=false;
                // });
                // flag ? this.sendCodeMeth() : '';
            },
        }
    }
</script>

<style scoped lang="scss">

</style>
