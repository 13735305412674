<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
      append-to-body>
      <div class="container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="中文名称" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入中文名称"></el-input>
          </el-form-item>
          <el-form-item label="英文名称" prop="nameEn">
            <el-input v-model="ruleForm.nameEn" placeholder="请输入英文名称"></el-input>
          </el-form-item>
          <el-form-item label="频点(地址)" prop="frequency">
            <el-input v-model="ruleForm.frequency" placeholder="请输入频点(地址)"></el-input>
          </el-form-item>
          <el-form-item  label="模拟频点(地址)" prop="freNew">
            <el-input v-model="ruleForm.freNew" placeholder="请输入模拟频点(地址)"></el-input>
          </el-form-item>
          <el-form-item  label="排序" prop="number">
            <el-input v-model="ruleForm.number" placeholder="请输入排序"></el-input>
          </el-form-item>
          <!-- <el-form-item  label="图标" prop="iconUrl">
              <UploadImage :urlFile="ruleForm.iconUrl" @uploadFileChange="UploadImageChange"></UploadImage>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { create ,updateData} from "../../api/application/TvList";
// import UploadImage from "./UploadImage.vue";
export default {
  components: {
    // UploadImage
  },
  data () {
    return {
      title: '',
      database: '',
      dialogVisible: false,
      ruleForm: {
        database: '',
        id: '',
        name: '', 
        nameEn: '',
        frequency: '', // 频点(地址)
        freNew: '', //  模拟频点(地址)
        number: '', //排序
        iconUrl: ''
      },
      typeList: [
        {value: '0',label: '音乐'},
        {value: '1',label: '字体'},
        {value: '2',label: '视频'},
        {value: '3',label: '其他'},
      ],
      emusicTypeList: [
        {value: '1', label: '中文' },
        {value: '2', label: '英文' },
        {value: '3', label: '日文' },
        {value: '4', label: '韩文' }
      ],
      rules: {
        name: [
          { required: true, message: '请输入中文名称', trigger: 'blur' },
        ],
        nameEn: [
          { required: true, message: '请输入英文名称', trigger: 'blur' }
        ],
        frequency: [
          { required: true, message: '请输入频点(地址)', trigger: 'blur' }
        ],
         freNew: [
          { required: true, message: '请选择属性', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        // iconUrl: [
        //   { required: true, message: '请上传图标', trigger: 'blur' }
        // ],
      },
      btnLoading: false
    }
  },
  methods: {
    open(title,database,row,) {
      this.title = title;
      this.database = database;
      this.dialogVisible = true;
      if (!row || row.id == '' || row.id === null) {
        // 新增
        for (const key in this.ruleForm) {
          if (Object.hasOwnProperty.call(this.ruleForm, key)) {
            this.ruleForm[key] = '';
          }
        }
      } else {
        // 编辑
        console.log(row);
         for (const key in this.ruleForm) {
          if (Object.hasOwnProperty.call(this.ruleForm, key)) {
            this.ruleForm[key] = row[key];
          }
        }
      }
      
    },
    handleClose() {
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = ''
        }
      }
      this.dialogVisible = false
    },
    // UploadImageChange(fileUrl) {
    //   this.ruleForm.iconUrl = fileUrl
    // },
    async submitForm(formName) {
      this.$refs[formName].validate( async ( valid) => {
        if (valid) {
          let res;
          this.ruleForm.database = this.database;
          this.btnLoading = true;
          if (this.ruleForm.id) {
            // 编辑
            res = await updateData(this.ruleForm);
          } else {
            // 新增
            res = await create(this.ruleForm);
          }
          if (res.data.code === 100000) {
            let tips = this.ruleForm.id? '编辑' : '新增'
            this.$message({
              showClose: true,
              message: `${tips}成功`,
              type: 'success'
            });
            this.dialogVisible = false;
            this.$parent.getList();
            this.btnLoading = false;
          } else {
            this.$message.error(res.data.msg);
          }
          this.btnLoading = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style>

</style>