<template>
    <div>
        <fold-top-search @fold="getFoldMeth" class="foldBox absoluteFoldBox"></fold-top-search>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow"  @condition="getCondition" ></top-search>
        </el-card>
       <el-card class="box-card">
           <div class="lineContent" v-loading="showLoading">
               <div class="title">广告收益统计</div>
               <!--<line-title>广告收益统计</line-title>-->
               <el-row :gutter="20" class="rowBox vertical borderBox">
                   <index-four-row :span="12">
                       <span slot="numTitle">广告展现CPD（次/房间/天）</span>
                       <span slot="num">{{showDataCom(showData.advertising_show_cpd)}}</span>
                       <span slot="unit">元</span>
                       <span slot="date">{{showDataTime}}</span>
                   </index-four-row>
                   <index-four-row :span="12">
                       <span slot="numTitle">广告收益</span>
                       <span slot="num">{{showDataCom(showData.advertising_income)}}</span>
                       <span slot="unit">元</span>
                       <span slot="date">{{showDataTime}}</span>
                   </index-four-row>
               </el-row>
               <el-row :gutter="20" class="rowBox vertical borderBox">
                   <index-four-row>
                       <span slot="numTitle">运营酒店数量</span>
                       <span slot="time">{{showDataTime}}</span>
                       <span slot="num">{{showDataCom(showData.hotel_num)}}</span>
                       <span slot="unit">间</span>
                   </index-four-row>
                   <index-four-row>
                       <span slot="numTitle">发布广告酒店数量</span>
                       <span slot="time">{{showDataTime}}</span>
                       <span slot="num">{{showDataCom(showData.advertising_hotel_num)}}</span>
                       <span slot="unit">间</span>
                   </index-four-row>
                   <index-four-row>
                       <span slot="numTitle">激活终端数量</span>
                       <span slot="time">{{showDataTime}}</span>
                       <span slot="num">{{showDataCom(showData.active_terminal_num)}}</span>
                       <span slot="unit">台</span>
                   </index-four-row>
                   <index-four-row>
                       <span slot="numTitle">发布广告终端数量</span>
                       <span slot="time">{{showDataTime}}</span>
                       <span slot="num">{{showDataCom(showData.advertising_terminal_num)}}</span>
                       <span slot="unit">台</span>
                   </index-four-row>
               </el-row>
           </div>
       </el-card>
        <el-card class="box-card">
            <div class="lineContent">
                <!--<line-title>广告参考数据统计</line-title>-->
                <!--<div class="title">广告参考数据统计</div>-->
                <!--<el-row :gutter="20" class="rowBox vertical borderBox">-->
                <!--<four-row :span="8">-->
                <!--<span slot="numTitle">广告曝光次数</span>-->
                <!--<span slot="time">{{showDataTime}}</span>-->
                <!--<span slot="num">0.00</span>-->
                <!--<span slot="unit">次</span>-->
                <!--</four-row>-->
                <!--<four-row :span="8">-->
                <!--<span slot="numTitle">商业广告曝光次数</span>-->
                <!--<span slot="time">{{showDataTime}}</span>-->
                <!--<span slot="num">0.00</span>-->
                <!--<span slot="unit">次</span>-->
                <!--</four-row>-->
                <!--<four-row :span="8">-->
                <!--<span slot="numTitle">商业免费曝光次数</span>-->
                <!--<span slot="time">{{showDataTime}}</span>-->
                <!--<span slot="num">0.00</span>-->
                <!--<span slot="unit">次</span>-->
                <!--</four-row>-->
                <!--</el-row>-->
            </div>
            <div class="lineContent chartBox">
                <!--<line-chart :chart="lineChart"></line-chart>-->
                <line-chart :chart="lineChart" v-if="lineChart.flag && !lineChart.noData"></line-chart>
                <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">暂无数据</div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div class="lineContent" v-loading="table.loading">
                <div class="title chartTitle">广告参酒店列表</div>
                <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableMeth="tableMeth"></table-list>
            </div>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    import commonMeth from '../../../api/method'
    export default {
        name: "movie",
        data: () => ({
            lineChart:{
                id:'adChart',
                showTitle:true,
                flag:false,
                noData:false,
                option:{
                    legend:[],
                    xData:[],
                    series:[
                        {
                            name: '广告展现CPD',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#2194f4', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#2194f4' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '广告收益',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#19cc78', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#19cc78 ' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '激活终端数量',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: '#ea9856', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#ea9856 ' //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                    ]
                }
            },
            table:{
                tableHeader:[
                    {prop:'datetime_text',label:'时间',width:''},
                    {prop:'hotel_name',label:'酒店名称',width:'400'},
                    {prop:'active_terminal_num',label:'激活终端数量（台）',width:''},
                    {prop:'day_active_rate',label:'日活率（%）',width:''},
                    {prop:'advertising_cost',label:'广告收益（元）',width:''},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                startTime:'',
                endTime:'',
                fromUrl:'incomeStaMovie',
                loading:true
                // option:true
            },

            showData:{
                active_room_num:0,   //激活终端数量
                advertising_hotel_num:0,  //发布广告酒店数量
                advertising_income: 0,  //广告收益
                advertising_show_cpd: 0,  //广告展现CPD
                advertising_terminal_num: 0,  //发布广告终端数量
                hotel_num:0   //运营酒店数量
            },
            condition:{
                startTime:'',
                endTime:'',
                province:'',
                city:'',
                country:'',
                hotelBrand:'',
                accountManager:''
            },
            topSearchShow:{ },
            token:'',
            showLoading:true,
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            }
        }),
        methods:{
            //监听pageSize改变
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
            },
            //监听页码值改变
            handleCurrentChange(newPage){
                this.queryInfo.currentPage=newPage;
            },
            handleClick(tab, event) {
            },
            //折线图
            getLineChart(){
                this.lineChart.flag=false;
                var param=qs.stringify({
                    token:this.token,
                    page:this.table.page,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    partner_id:this.condition.partner_id,
                    accountManager:this.condition.BD,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                });
                this.$http.post(this.global.getAdvertisingIncomeLine,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        this.lineChart.flag=true;
                        if(chartData.series){
                            this.lineChart.option.legend=chartData.legend;  //图例
                            this.lineChart.option.xData=chartData.xAxis;  //x轴
                            var dataTransEd=commonMeth.dataTrans(chartData);
                            this.lineChart.option.series[0].data=dataTransEd.series['广告展现CPD'];  //x轴
                            this.lineChart.option.series[1].data=dataTransEd.series['广告收益'];  //x轴
                            this.lineChart.option.series[2].data=dataTransEd.series['激活终端数量'];  //x轴
                            this.lineChart.noData=false;
                        }else{
                            this.lineChart.noData=true;
                        }
                    }else if(code===100001){
                        // this.errorText='验证码错误'
                    }
                }).catch(()=>{
                    // this.$message.error('暂时无法访问');
                })
            },

            showDataMeth(){
                this.showLoading=true;
                var param=qs.stringify({
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    partner_id:this.condition.partner_id,
                    accountManager:this.condition.BD,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                });
                this.$http.post(this.global.getAdvertisingIncomeStatistics,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        this.showData=res.data.data;
                    }
                    this.showLoading=false;
                }).catch(()=>{
                    this.showLoading=false;
                })
            },
            tableMeth(orderVal){
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.table.loading=true;
                var param=qs.stringify({
                    token:this.token,
                    page:this.table.page,
                    limit:this.table.limit,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    partner_id:this.condition.partner_id,
                    accountManager:this.condition.BD,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    sort:sortStr,
                    order:orderStr,
                });
                this.$http.post(this.global.getAdvertisingIncomeList,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=tableData.total;
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getCondition(val){
                this.condition.hotel_brand=Array.isArray(val.hotel_brand)?val.hotel_brand.join(','):'';
                this.condition.hotel_use_state=Array.isArray(val.hotel_use_state)?val.hotel_use_state.join(','):'';
                this.condition.BD=Array.isArray(val.BD)?val.BD.join(','):'';
                this.conditionRoom.partner_id=Array.isArray(val.noTypePartnerCode)?val.noTypePartnerCode.join(','):'';
                this.condition.citycode=val.citycode;
                this.condition.startTime=val.startTime;
                this.condition.endTime=val.endTime;
                this.condition.keyword=val.keyword;
                this.condition.lineTime={};
                this.showDataMeth();
                this.getLineChart();
                this.tableMeth();
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' ||  auth_type==='screen' ||auth_type==='factory' ){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                    }
                } if(auth_type==='group'){
                    this.topSearchShow={
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        coopType:true,
                        BD:true,
                        date:true,
                        keyword:true,
                    }
                }
            },
            dataTrans(chartData){
                for(let key in chartData.series){
                    for(let a in chartData.series[key]){
                        if(!chartData.series[key][a]){
                            chartData.series[key][a]=0;
                        }
                    }
                }
                return chartData;
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getFoldMeth(val){
                this.fold=val;
            }

        },
        computed:{
            showDataCom(){
                return function(val){
                    if(val===null){
                        return 0;
                    }else{
                        return parseFloat(val).toLocaleString();
                    }
                }
            },
            showDataTime(){
                if(this.condition.startTime===''){
                    return '【昨日】'
                }else{
                    var time=this.condition.startTime+'~'+this.condition.endTime;
                    return time;
                }
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.showDataMeth();
            this.tableMeth();
            this.getLineChart();
            this.getTopSearchShow();
        }
    }
</script>

<style scoped lang="scss">
    .lineContent{
        /*margin-top:20px;*/
    }
    .chartBox{
        height:400px;
        margin-bottom:20px!important;
        overflow:hidden;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .foldBox{
        padding:10px 0;
        overflow:hidden;
    }
</style>
