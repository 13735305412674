<template>
  <div class="roomSearchBox">
    <div class="checkCondition" v-loading="roomLoading">
      <!-- 房间选择界面 -->
      <div class="checkConditionBox address">
        <p class="title"><span>{{type=='sys'?'后台':'分组'}}</span><span style="margin-right:10px">{{checkedRoom.length+'/'+rooms.length}}</span></p>
        <div class="checkList">
          <!-- <div class="searchBox">
            <el-input class="searchInput" :disabled="isEdit" v-model="room_name" style="width: 20%;margin-right:10px " placeholder="房间名称关键字"></el-input>
            <el-button class="trueBtn" type="primary" v-if="!isEdit" @click="searchRoomList">搜索</el-button>
            <el-button class="cancelBtn" @click="recetRoomList" v-if="!isEdit">重置</el-button>
          </div> -->
          <div style="margin: 10px 0"></div>
          <el-checkbox :disabled="isEdit" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="checkedRoom" @change="checkedRoomChange">
            <div class="checkBoxShow">
              <template v-for="room in leftShowRoom">
                <el-checkbox :disabled="isEdit" :label="room.program_name" :key="room.program_name">
                  <span> {{ room.hotel_alias }}</span>
                </el-checkbox>
              </template>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 房间选择界面 -->
    </div>
    <!-- 已选房间 -->
    <div class="showCondition" v-loading="roomLoading">
      <div class="checkConditionBox">
        <p class="title"><span>已选定向</span>
          <span>
            <span style="margin-right:10px;font-size:12px">{{showRoomList.rooms.length+'个'}}</span>
            <span class="clearAll" v-if="!isEdit" @click="clearAll">清空</span>
          </span>
        </p>
        <!-- <div class="searchBox" style="padding :10px">

          <el-input class="searchInput" v-model="check_room_name" style="width: 25%;margin-right:10px " placeholder="房间名称关键字"></el-input>
          <el-button class="trueBtn" type="primary" @click="checkSearch">搜索</el-button>
          <el-button class="cancelBtn" @click="recetcheckList">重置</el-button>
        </div> -->
        <div class="contentShow">
          <div>
            <span style=" position: relative" class="checkCard" v-for="item in showRoomList.showrooms" :key="item.program_name">
              <span> {{ item.hotel_alias }}</span>
              <i class="el-icon-close" v-if="!isEdit" @click="delCheckRoom(item)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 已选房间 -->
  </div>
</template>

<script>
import { getSubByHotelContract } from "@/api/hotelGroup.js";
import { getRoomGroup } from "../../hotelGroup/hotelGroupApi";
export default {
  props: ["hotel_contract", "type"],
  watch: {
    hotel_contract: {
      handler: function (newVal) {
        this.hotel_contract = newVal;
        if (this.hotel_contract) {
          this.getRoomList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      isEdit: false,
      checkAll: false,
      isIndeterminate: true,
      provinceList: [],
      cityList: [],
      province: null,
      city: null,
      room_id: "",
      room_name: "",
      checkedRoom: [],
      checkedRoomAll: [],
      rooms: [],
      checkRoomList: [],
      showRoomList: {
        rooms: [],
        roomsId: [],
        showrooms: [],
      },
      roomLoading: false,
      leftShowRoom: [],
      token: "",
      check_room_name: "",
      template_id: "",
    };
  },
  methods: {
    // 清空
    clearAll() {
      this.checkedRoom = [];
      this.rooms = [];
      this.checkAll = false;
      this.showRoomList = {
        rooms: [],
        roomsId: [],
        showrooms: [],
      };
    },
    // 数据回显写入
    intData(data) {
      this.showRoomList.rooms = data;
      this.showRoomList.roomsId = data.map((i) => {
        return i;
      });
      this.showRoomList.showrooms = data;
      this.checkRoomList = this.showRoomList.rooms;
      this.checkedRoom = this.showRoomList.roomsId;
      this.roomLoading = false;
    },

    // 已选房间删除
    delCheckRoom(item) {
      console.log(item, this.showRoomList.rooms);
      this.showRoomList.rooms = this.showRoomList.rooms.filter((i) => {
        if (i.program_name != item.program_name) {
          return i;
        } else {
          this.checkRoomList = this.checkRoomList.filter((i) => {
            if (i.program_name != item.program_name) {
              return i;
            }
          });
        }
      });
      console.log(this.showRoomList.rooms);
      this.showRoomList.roomsId = this.showRoomList.rooms.map((i) => {
        return i.program_name;
      });
      this.checkedRoom = this.checkRoomList.map((i) => {
        return i.program_name;
      });
      this.showRoomList.showrooms = [...this.showRoomList.rooms];
    },
    handleCheckAllChange(val) {
      if (val) {
        this.checkedRoomAll.forEach((i) => {
          if (this.checkedRoom.indexOf(i) == -1) {
            this.checkedRoom.push(i);
          }
        });
      } else {
        this.checkedRoom = this.checkedRoom.filter((i) => {
          if (this.checkedRoomAll.indexOf(i) == -1) {
            return i;
          }
        });
      }
      this.checkRoomList = this.rooms.filter((i) => {
        if (this.checkedRoom.includes(i.program_name)) {
          return i;
        }
      });
      let list = this.rooms.map((i) => {
        return i.program_name;
      });
      var arr1 = this.showRoomList.rooms.filter((i) => {
        if (list.indexOf(i.program_name) == -1) {
          return i;
        }
      });
      let arr = this.checkRoomList;
      this.showRoomList.rooms = [...arr1, ...arr];
      this.showRoomList.roomsId = this.showRoomList.rooms.map((i) => {
        return i.program_name;
      });
      this.showRoomList.showrooms = [...this.showRoomList.rooms];
      this.isIndeterminate = false;
    },
    //  房间选择
    checkedRoomChange(value) {
      let checklist = this.checkedRoom.filter((i) => {
        if (this.checkedRoomAll.indexOf(i) != -1) {
          return i;
        }
      });
      if (checklist.length == this.checkedRoomAll.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.checkRoomList = [];
      this.checkRoomList = this.rooms.filter((i) => {
        if (this.checkedRoom.includes(i.program_name)) {
          return i;
        }
      });
      let list = this.rooms.map((i) => {
        return i.program_name;
      });
      var arr1 = this.showRoomList.rooms.filter((i) => {
        if (list.indexOf(i.program_name) == -1) {
          return i;
        }
      });
      let arr = this.checkRoomList;
      this.showRoomList.rooms = [...arr1, ...arr];
      this.showRoomList.roomsId = this.showRoomList.rooms.map((i) => {
        return i.program_name;
      });
      this.showRoomList.showrooms = [...this.showRoomList.rooms];
      //   this.showRoomList.rooms.
    },

    // 获取房间列表
    getRoomList() {
      this.roomLoading = true;
      console.log(this.type, "-------------------------");
      if (this.type == "group") {
        const json1 = {
          page: 1,
          size: 99999,
          name: "",
          hotel_name: "",
          hotel_contract: this.hotel_contract,
        };
        getRoomGroup(json1)
          .then((res) => {
            if (res.data.code == 100000 && res.data.data.data) {
              console.log(res, "列表数据");
              if (res.data.data.data.length == 0) {
                 this.roomLoading = false;
                return;
              }
              let list = res.data.data.data.map((i) => {
                i.program_name = i.id;
                i.hotel_alias = i.name;
                return i;
              });
              this.rooms = [...list];
              this.leftShowRoom = [...list];
              this.checkedRoomAll = this.rooms.map((i) => {
                return i.program_name;
              });
              let checklist = this.checkedRoom.filter((i) => {
                if (this.checkedRoomAll.indexOf(i) != -1) {
                  return i;
                }
              });
              if (checklist.length == this.checkedRoomAll.length) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
              this.roomLoading = false;
            } else {
              this.$message.warning(res.data.msg);

              this.roomLoading = false;
            }
          })
          .catch((err) => {
            this.$message.warning("数据加载失败");
          });
      } else if (this.type == "sys") {
        let json = {
          hotel_contract: this.hotel_contract,
        };
        getSubByHotelContract(json).then((res) => {
          if (res.data.code == 100000 || res.data.code == 100001) {
            if (res.data.data.length == 0) {
              return;
            }
            let list = res.data.data.map((i) => {
              i.program_name = i.id;
              i.hotel_alias = i.name;
              return i;
            });
            this.rooms = [...list];
            this.leftShowRoom = [...list];
            this.checkedRoomAll = this.rooms.map((i) => {
              return i.program_name;
            });
            let checklist = this.checkedRoom.filter((i) => {
              if (this.checkedRoomAll.indexOf(i) != -1) {
                return i;
              }
            });
            if (checklist.length == this.checkedRoomAll.length) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
            this.roomLoading = false;
          } else {
            this.$message.warning(res.data.msg);
            this.roomLoading = false;
          }
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.roomSearchBox {
  width: 100%;
  height: 400px;

  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.checkCondition {
  width: 50%;
  height: 100%;
  border: 1px solid #ebeef5;
  display: flex;
  justify-content: flex-start;
  margin-right: 16px;
}
.showCondition {
  width: calc(50% - 16px);
  height: 100%;
  border: 1px solid #ebeef5;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-top: 1px solid #e4e7ed !important;
  border-bottom: 1px solid #e4e7ed !important;
  border-radius: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: 1px solid #e4e7ed !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  border-right: 1px solid #e4e7ed !important;
}
.checkCondition .checkConditionBox {
  flex: 1;
}

.checkCondition .address:last-child {
  border-right: none;
}
.checkCondition .title,
.showCondition .title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ebeef5;
  background: #f7f8fa;
  color: #222;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title span:first-child {
  color: #596080;
  font-size: 16px;
}
.title span:last-child {
  color: #8a8c99;
  font-size: 12px;
}
.checkList {
  padding: 10px;
  height: 340px;
}
.checkBoxShow {
  height: 240px;
  overflow: hidden;
  overflow-y: visible;
}
.el-checkbox {
    display: block;
  height: 30px;
}
.showCondition .checkConditionBox {
  height: calc(100%);
}
.showCondition .contentShow {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: calc(100% - 130px);
  overflow: hidden;
  overflow-y: visible;
}
.showCondition .contentShow > div {
  width: 100%;
}
.checkType {
  margin-right: 5px;
  font-size: 14px;
}
.checkCard {
  display: inline-block;

  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ebebed;
  background: #f8f9fa;
  color: #596080;
  padding: 0 20px 0 10px;
  border-radius: 3px;
}
::v-deep .el-checkbox__label {
  width: 100%;
}
::v-deep .el-checkbox:last-of-type {
  margin-right: 30px !important;
}
.openPlace {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.openPlace i {
  font-size: 14px;
  cursor: pointer;
}
.openPlace i:hover {
  color: #658bff;
}
.el-radio {
  display: block;
}
.greenBtn {
  width: 15%;
  max-width: 100px;
  background: #658bff;
  border: 1px solid #658bff;
  color: #fff;
}
.whiteBtn {
  width: 15%;
  max-width: 100px;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.el-icon-close {
  position: absolute;
  top: 50%;
  right: -2px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.el-checkbox {
  margin-bottom: 10px;
}
.clearAll {
  border: 1px solid #ebebed;
  margin-right: 10px;
  height: 28px;
  padding: 0 10px;
  line-height: 28px;
  color: #596080 !important;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
</style>