<!--
 * @Descripttion: 酒店选择弹窗
 * @version: V1.0
 * @props:
    1.v-model --Array v-model绑定值 返回已选酒店数组
    2.id 后台selectedTadkID参数（用于初始化模块/品牌/省）
    3.noInsert --Boolean 是否不需要导入功能
    3.重置已选值 $refs.xxx.reset()
-->

<template>
  <div class="hotel_select_box">

    <el-button type="primary" @click="dialogVisible = true" v-if="!showDiv">选择酒店</el-button>
    <!-- <span>已选择({{ selected.length }})</span> -->
    <!-- 弹出框 -->
    <el-dialog :title="readonly?'已选酒店':'选择酒店'" :visible.sync="dialogVisible" width="1000px" @open="open" @closed="handleClose" append-to-body>
      <div class="content_box" v-if="readonly">
        <div class="item" style="width:100%">
          <div class="select_hotel_box">
            <div class="hotel_select_item" v-for="(item) of selectedHotels" :key="item.hotelName + 'select'">
              {{ item.hotel }}
            </div>
          </div>
        </div>
      </div>
      <div class="content_box" v-if="!readonly">
        <!-- left -->
        <div class="item">
          <el-form :model="form" status-icon ref="form" label-width="100px">
            <el-form-item label="导入酒店" prop="excel_file" v-if="type!='yaduo'&&type!='batch'" label-width="100px">
              <UploadFile ref="UploadFile" v-model="form.excel_file" text="excel导入(G列表为程序名)" @uploadChange="insertExcel"></UploadFile>
            </el-form-item>
            <!-- type=='apk' -->
            <el-form-item label="签名" prop="sign" v-if="type=='apk'">
              <el-select v-model="form.sign" placeholder="选择签名" style="width:100%" collapse-tags disabled>
                <el-option v-for="item in signList" :key="item.id + 'sign'" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="DeviceName" prop="type" v-if="type=='apk'">
              <div class="deviceRow">
                <el-select v-model="form.type" placeholder="选择DeviceName" style="width:100%" collapse-tags multiple filterable clearable>
                  <el-option v-for="item in equipmentRow" :key="item.id + 'equipment'" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
                <el-button type="primary" style="marginLeft:5px" @click="checkAllEquipmentRow">全选</el-button>
              </div>

            </el-form-item>
            <el-form-item label="不含标签" prop="unTag" v-if="false">
              <el-select v-model="form.unTag" placeholder="不含标签" style="width:100%" collapse-tags multiple filterable value-key="id" clearable>
                <el-option v-for="item in labelList" :key="item.id + 'label'" :label="item.tag_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="酒店状态" prop="hotelState" v-if="false">
              <el-select v-model="form.hotelState" placeholder="酒店状态" style="width:100%" collapse-tags multiple clearable value-key="value">
                <el-option v-for="item in hotelState" :key="item.value + 'hotelState'" :label="item.name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="模板" prop="model" v-if="type=='yaduo'&&type!='batch'">
              <el-select v-model="form.model" placeholder="选择模板" style="width:100%" clearable>
                <el-option v-for="item in tagsList" :key="item.id + 'tag'" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="酒店品牌" prop="brand" v-if="type=='yaduo'||type=='batch'">
              <el-select v-model="form.brand" placeholder="酒店品牌" style="width:100%" clearable multiple collapse-tags>
                <el-option v-for="item in rowhotelGroup" :key="item.id" :label="item.groupname" :value="{
                    name: item.groupname,
                    value: item.id,
                    disabled: false,
                  }">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所在省份" prop="province" v-if="type=='yaduo'||type=='batch'">
              <el-select v-model="form.province" placeholder="省份" style="width:33%;marginRight:8px" clearable @change="
                  form.city = '';
                  form.country = '';
                  searchCity('city');
                ">
                <el-option v-for="item in areas.province" :key="item.assign + 'province'" :label="item.cityName" :value="item.assign">
                </el-option>
              </el-select>
              <el-select v-model="form.city" placeholder="市级" style="width:32%;marginRight:8px" clearable @change="
                  form.country = '';
                  searchCity();
                ">
                <el-option v-for="item in areas.city" :key="item.code + 'city'" :label="item.cityName" :value="item.code">
                </el-option>
              </el-select>
              <el-select v-model="form.country" placeholder="区级" style="width:33%" clearable>
                <el-option v-for="item in areas.area" :key="item.code + 'area'" :label="item.cityName" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="酒店名称" prop="hotelName" v-if="type=='yaduo'||type=='batch'||type=='apk'||type=='set'">
              <el-input v-model="form.hotelName" placeholder="酒店名称搜索"></el-input>
              <el-button type="primary" style="marginLeft:5px" @click="
                  isIndeterminate = false;
                  checkAll = false;
                  getHotel();
                ">搜索</el-button>
            </el-form-item>
          </el-form>
          <div class="line"></div>
          <!-- 酒店选择 -->
          <div class="hotel_box" v-loading="loading">
            <div v-show="hotelList.length == 0" class="empty_tip">暂无数据</div>
            <el-checkbox v-show="hotelList.length != 0" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="hotel_checkbox_box">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="selected" class="hotel_checkbox_box" ref="checkboxGroup" @change="handleCheckedChange">
              <el-checkbox style="display:block" class="hotel_checkbox" v-for="item of hotelList" :label="item.hotelName" :key="item.hotelName" @change="(val) => checkItemChange(val, item.hotelName)">
                <span> {{ item.hotel }}</span>
                <span v-if="item.hotel_alias!='默认'"> {{"("+item.hotel_alias+")"}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <!-- right -->
        <div class="item">
          <div>
            已选择{{selectedHotels.length}}家
            <el-button type="primary" size="mini" style="marginLeft:5px" @click="
                selected = [];
                selectedHotels = [];
                checkAll = false;
                isIndeterminate = false;
              ">清空</el-button>
          </div>
          <!-- 已选box -->
          <div class="select_hotel_box">
            <div class="hotel_select_item" v-for="(item, index) of selectedHotels" :key="item.hotelName + 'select'">
              <span> {{ item.hotel }}</span>
              <span v-if="item.hotel_alias!='默认'"> {{"("+item.hotel_alias+")"}}</span>
              <i class="el-icon-error" @click="removeHotel(index, item.hotelName)"></i>
            </div>
          </div>
          <div class="foot_btn_row">
            <el-button type="primary" @click="submit">提交</el-button>
            <el-button @click="cancel">关闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="content_box" v-if="showDiv">
      <!-- left -->
      <div class="item">
        <el-form :model="form" status-icon ref="form" label-width="100px">
          <el-form-item label="导入酒店" prop="excel_file" v-if="type!='yaduo'&&type!='batch'" label-width="100px">
            <UploadFile ref="UploadFile" v-model="form.excel_file" text="excel导入(G列表为程序名)" @uploadChange="insertExcel"></UploadFile>
          </el-form-item>
          <!-- type=='apk' -->
          <el-form-item label="签名" prop="sign" v-if="type=='apk'">
            <el-select v-model="form.sign" placeholder="选择签名" style="width:100%" collapse-tags disabled>
              <el-option v-for="item in signList" :key="item.id + 'sign'" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="DeviceName" prop="type" v-if="type=='apk'">
            <div class="deviceRow">
              <el-select v-model="form.type" :disabled="showDiv" placeholder="选择DeviceName" style="width:100%" collapse-tags multiple filterable clearable @change="typeChange">
                <el-option v-for="item in equipmentRow" :key="item.id + 'equipment'" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
              <el-button type="primary" v-if="!showDiv" style="marginLeft:5px" @click="checkAllEquipmentRow">全选</el-button>
            </div>

          </el-form-item>
          <el-form-item label="不含标签" prop="unTag" v-if="false">
            <el-select v-model="form.unTag" placeholder="不含标签" style="width:100%" collapse-tags multiple filterable value-key="id" clearable>
              <el-option v-for="item in labelList" :key="item.id + 'label'" :label="item.tag_name" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="酒店状态" prop="hotelState" v-if="false">
            <el-select v-model="form.hotelState" placeholder="酒店状态" style="width:100%" collapse-tags multiple clearable value-key="value">
              <el-option v-for="item in hotelState" :key="item.value + 'hotelState'" :label="item.name" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板" prop="model" v-if="type=='yaduo'&&type!='batch'">
            <el-select v-model="form.model" placeholder="选择模板" style="width:100%" clearable>
              <el-option v-for="item in tagsList" :key="item.id + 'tag'" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="酒店品牌" prop="brand" v-if="type=='yaduo'||type=='batch'">
            <el-select v-model="form.brand" placeholder="酒店品牌" style="width:100%" clearable multiple collapse-tags>
              <el-option v-for="item in rowhotelGroup" :key="item.id" :label="item.groupname" :value="{
                    name: item.groupname,
                    value: item.id,
                    disabled: false,
                  }">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在省份" prop="province" v-if="type=='yaduo'||type=='batch'">
            <el-select v-model="form.province" placeholder="省份" style="width:33%;marginRight:8px" clearable @change="
                  form.city = '';
                  form.country = '';
                  searchCity('city');
                ">
              <el-option v-for="item in areas.province" :key="item.assign + 'province'" :label="item.cityName" :value="item.assign">
              </el-option>
            </el-select>
            <el-select v-model="form.city" placeholder="市级" style="width:32%;marginRight:8px" clearable @change="
                  form.country = '';
                  searchCity();
                ">
              <el-option v-for="item in areas.city" :key="item.code + 'city'" :label="item.cityName" :value="item.code">
              </el-option>
            </el-select>
            <el-select v-model="form.country" placeholder="区级" style="width:33%" clearable>
              <el-option v-for="item in areas.area" :key="item.code + 'area'" :label="item.cityName" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="酒店名称" prop="hotelName" v-if="type=='yaduo'||type=='batch'||type=='apk'||type=='set'">
            <el-input v-model="form.hotelName" placeholder="酒店名称搜索"></el-input>
            <el-button type="primary" style="marginLeft:5px" @click="
                  isIndeterminate = false;
                  checkAll = false;
                  getHotel();
                ">搜索</el-button>
          </el-form-item>
        </el-form>
        <div class="line"></div>
        <!-- 酒店选择 -->

        <ul class="infinite-list hotel_box" :infinite-scroll-throttle-delay='500' :infinite-scroll-disabled="busy" v-infinite-scroll="load" style="overflow:auto" :infinite-scroll-immediate='false'>
          <div v-show="hotelList.length == 0" class="empty_tip">暂无数据</div>
          <el-checkbox v-show="hotelList.length != 0" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="hotel_checkbox_box">全选{{hotelList.length}}家</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="selected" v-loading="loading" class="hotel_checkbox_box" ref="checkboxGroup" @change="handleCheckedChange">
            <el-checkbox style="display:block" class="hotel_checkbox" v-for="item of hotelListShow" :label="item.hotelName" :key="item.hotelName" @change="(val) => checkItemChange(val, item.hotelName)">
              <span> {{ item.hotel }}</span>
              <span v-if="item.hotel_alias!='默认'"> {{"("+item.hotel_alias+")"}}</span>
            </el-checkbox>
          </el-checkbox-group>
          <!-- <li v-for="i in count" class="infinite-list-item">{{ i }}</li> -->
        </ul>

      </div>
      <!-- right -->
      <div class="item">
        <div>
          已选择{{selectedHotels.length}}家
          <el-button type="primary" size="mini" style="marginLeft:5px" @click="
                selected = [];
                selectedHotels = [];
                checkAll = false;
                isIndeterminate = false;
                 selectedShow= [];
           selectedShowPage= [];
         selectedShowPageNum= 0;
          selectedShowPageTotalNum= 0;
              ">清空</el-button>
        </div>
        <!-- 已选box -->

        <ul class="infinite-list select_hotel_box" :infinite-scroll-throttle-delay='500' :infinite-scroll-disabled="selectedbusy" v-infinite-scroll="selectedload" style="overflow:auto" :infinite-scroll-immediate='false'>
          <div class="hotel_select_item" v-for="(item, index) of selectedShow" :key="item.hotelName + 'select'">
            <span> {{ item.hotel }}</span>
            <span v-if="item.hotel_alias!='默认'"> {{"("+item.hotel_alias+")"}}</span>
            <i class="el-icon-error" @click="removeHotel(index, item.hotelName)"></i>
          </div>
          <!-- <li v-for="i in count" class="infinite-list-item">{{ i }}</li> -->
        </ul>

        <div class="foot_btn_row">
          <el-button type="primary" @click="submit">提交</el-button>
          <el-button @click="cancel">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadFile from "../uploadFile";
import {
  getInitData,
  searchCity,
  searchHotel,
  insertExcel,
  getHotelSearch,
  getDeviceName,
} from "./api/hotel";
export default {
  name: "hotelSelect",
  components: { UploadFile },
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showDiv: {
      default: false,
    },
    // selectedTadkID
    id: {
      default: "",
    },
    // 弹窗类型区分
    type: {
      type: String,
      default: "",
    },
    // 是否不需要导入功能
    noInsert: {
      default: false,
    },
    readonly: {
      default: false,
    },
    // apk任务下发 签名id
    signId: {
      type: String,
      default: "",
    },
    signList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    apk: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      isIndeterminate: false,
      checkAll: false,
      tagsList: [],
      labelList: [],
      rowhotelGroup: [],
      equipmentRow: [],
      hotelState: [
        {
          name: "已工勘",
          value: "4",
        },
        {
          name: "实施中",
          value: "5",
        },
        {
          name: "待上线",
          value: "6",
        },
        {
          name: "使用中",
          value: "7",
        },
        {
          name: "已暂停",
          value: "9",
        },
        {
          name: "虚拟运营",
          value: "10",
        },
        {
          name: "已离线",
          value: "11",
        },
        {
          name: "测试演示",
          value: "12",
        },
      ],
      form: {
        excel_file: "",
        sign: "",
        unTag: [],
        hotelState: [],
        type: [],
        model: "",
        brand: [],
        province: "",
        city: "",
        country: "",
        hotelName: "",
      },
      areas: {
        province: [],
        city: [],
        area: [],
      },
      hotelList: [],
      hotelListShow: [],
      hotelListShowPage: [],
      hotelListShowPageNum: 0,
      hotelListShowPageTotalNum: 0,
      busy: false,
      selected: [],

      selectedHotels: [],
      selectedShow: [],
      selectedShowPage: [],
      selectedShowPageNum: 0,
      selectedShowPageTotalNum: 0,
      selectedbusy: false,
      oldSelected: [],
    };
  },
  methods: {
    load() {
      this.busy = true;
      if (this.hotelListShowPageNum >= this.hotelListShowPageTotalNum - 1) {
        this.busy = false;
        if (this.hotelListShowPageNum == 0) {
          return;
        }
        this.$message.warning("，已加载所有，没有更多数据");
      } else {
        console.log(this.hotelListShowPageNum);
        this.hotelListShowPageNum = this.hotelListShowPageNum + 1;
        console.log(this.hotelListShowPageNum);
        this.hotelListShow = [
          ...this.hotelListShow,
          ...this.hotelListShowPage[this.hotelListShowPageNum],
        ];
        this.busy = false;
      }
    },
    selectedload() {
      this.selectedbusy = true;
      if (this.selectedShowPageNum >= this.selectedShowPageTotalNum - 1) {
        this.selectedbusy = false;
        if (this.selectedShowPageNum == 0) {
          return;
        }
        this.$message.warning("，已加载所有，没有更多数据");
      } else {
        console.log(this.selectedShowPageNum);
        this.selectedShowPageNum = this.selectedShowPageNum + 1;
        console.log(this.selectedShowPageNum);
        this.selectedShow = [
          ...this.selectedShow,
          ...this.selectedShowPage[this.selectedShowPageNum],
        ];
        this.selectedbusy = false;
      }
    },
    checkAllEquipmentRow() {
      this.form.type = this.equipmentRow.map((i) => {
        return i.name;
      });
      console.log(this.form.type);
      this.$emit("platfrom", this.form.type);
    },
    typeChange() {
      this.$emit("platfrom", this.form.type);
    },
    // 导入excel
    insertExcel(file) {
      let formData = new FormData();
      formData.append("file", file);
      insertExcel(formData).then((data) => {
        this.form.excel_file = data.data.data.url;
      });
    },
    // 重置已选择酒店
    reset() {
      this.selected = [];
      this.selectedHotels = [];
    },
    // 重置已选择酒店
    clear() {
      this.selected = [];
      this.selectedHotels = [];
      this.$emit("change", this.selectedHotels);
    },
    // 关闭按钮
    cancel() {
      this.selectedHotels = this.oldSelected;
      this.selected = [];

      if (this.$refs.UploadFile) {
        this.$refs.UploadFile.clearFile();
      }
      this.$emit("close", 1);
      this.$emit("change", this.selectedHotels);
      this.handleClose();
    },
    // 移除选择酒店
    removeHotel(index, hotelName) {
      this.selected.splice(this.selected.indexOf(hotelName), 1);
      this.selectedHotels.splice(
        this.selectedHotels.findIndex((item) => item.hotelName == hotelName),
        1
      );
      this.selectedShow.splice(
        this.selectedShow.findIndex((item) => item.hotelName == hotelName),
        1
      );
    },
    // 多选半选状态处理
    handleCheckAllChange(val) {
      this.selectedShow = [];
      this.selectedShowPage = [];
      this.selected = val
        ? this.hotelList.map((item) => {
            return item.hotelName;
          })
        : [];
      if (val) {
        let arr = this.hotelList.filter(
          (item) =>
            this.selectedHotels.find(
              (hotel) => hotel.hotelName == item.hotelName
            ) == undefined
        );
        this.selectedHotels = this.selectedHotels.concat(arr);

        this.changeSelectHotel();
      } else {
        this.selectedHotels = this.selectedHotels.filter(
          (item) =>
            this.hotelList.find((hotel) => hotel.hotelName == item.hotelName) ==
            undefined
        );

        this.changeSelectHotel();
      }
      this.isIndeterminate = false;
    },
    handleCheckedChange() {
      let checkedCount = this.selected.length;
      this.checkAll = checkedCount === this.hotelList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.hotelList.length;
      this.$forceUpdate();
    },
    // 酒店勾选处理
    checkItemChange(val, hotelName) {
      this.selectedShow = [];
      this.selectedShowPage = [];
      if (val) {
        let obj = this.hotelList.find((item) => item.hotelName == hotelName);
        this.selectedHotels.push(obj);
        this.changeSelectHotel();
      } else {
        let idx = this.selectedHotels.findIndex(
          (item) => item.hotelName == hotelName
        );
        this.selectedHotels.splice(idx, 1);
        this.changeSelectHotel();
      }
      this.handleCheckedChange();
    },
    changeSelectHotel() {
      if (this.selectedHotels.length > 20) {
        let listLength = this.selectedHotels.length;
        let n = 20; // 每页多少条
        let lineNum =
          listLength % n === 0
            ? listLength / n
            : Math.floor(listLength / n + 1);
        this.selectedShowPageNum = 0;
        this.selectedShowPageTotalNum = lineNum;

        for (let i = 0; i < lineNum; i++) {
          let temp = this.selectedHotels.slice(i * n, i * n + n);
          this.selectedShowPage.push(temp);
        }
        this.selectedShow = [...this.selectedShow, ...this.selectedShowPage[0]];
      } else {
        this.selectedShow = [...this.selectedHotels];
      }
    },
    // 搜索下级城市/区域
    searchCity(tag) {
      searchCity({
        code: tag == "city" ? this.form.province : this.form.city,
      }).then((data) => {
        if (tag == "city") {
          this.areas.city = data.data.data;
        } else {
          this.areas.area = data.data.data;
        }
      });
    },
    // 弹窗打开 初始化
    open() {
      this.form.sign = this.signId;
      this.oldSelected = [...this.selectedHotels];
      this.selected = this.selectedHotels.map((item) => {
        return item.hotelName;
      });
      this.init();
      this.showData({});
    },
    showData(item) {
      if (item.signed_id) {
        this.form.sign = item.signed_id;
        this.init(item.apk_name, item.signed_id);
        if (item.data_device_name) {
          this.form.type = item.data_device_name.split(",");
        }
      }

      if (this.$refs.UploadFile) {
        this.$refs.UploadFile.clearFile();
      }
      this.oldSelected = [];
      this.selected = [];
      this.selectedHotels = [];
      this.hotelList = [];
      this.hotelListShowPage = [];
      this.hotelListShow = [];
      this.selectedShow = [];
      this.selectedShowPage = [];
      this.hotelListShowPageNum = 0;
      this.hotelListShowPageTotalNum = 0;
      this.selectedShowPageNum = 0;
      this.selectedShowPageTotalNum = 0;
      this.checkAll = false;
    },
    // 弹窗关闭处理
    handleClose() {
      // this.form = {
      //   excel_file: "",
      //   model: "",
      //   brand: [],
      //   province: "",
      //   city: "",
      //   country: "",
      //   hotelName: "",
      // };
      if (this.$refs.UploadFile) {
        this.$refs.UploadFile.clearFile();
      }
      this.$emit("platfrom", this.form.type);
      this.$refs.form.resetFields();
      this.form.province = "";
      this.form.city = "";
      this.form.country = "";
      this.areas = {
        province: [],
        city: [],
        area: [],
      };
      this.hotelList = [];
      // this.selected = [];
      this.isIndeterminate = false;
      this.checkAll = false;
      this.tagsList = [];
      this.rowhotelGroup = [];
      this.dialogVisible = false;
    },
    // 搜索酒店
    getHotel(type) {
      this.loading = true;
      if (this.type == "apk" || this.type == "set") {
        if (this.form.type.length == 0 && this.type == "apk") {
          this.$message.warning("请选择DeviceName");
          this.loading = false;
          return;
        }
        let param = {
          token: sessionStorage.getItem("token"),
          keyword: this.form.hotelName,
          hotelName: "",
          citycode: "",
          province: "",
          city: "",
          excel_file: this.form.excel_file,
          deviceName: this.form.type.join(","),
        };
        this.hotelList = [];
        this.hotelListShowPage = [];
        this.hotelListShow = [];
        getHotelSearch(param).then((res) => {
          if (res.data.code === 100000 && res.data.data) {
            var tableData = res.data.data;
            let arr = tableData.filter((i) => {
              if (i.hotel_use_state != 2) {
                return i;
              }
            });
            this.hotelList = arr;
            if (this.hotelList.length > 20) {
              let listLength = arr.length;
              let n = 20; // 每页多少条
              let lineNum =
                listLength % n === 0
                  ? listLength / n
                  : Math.floor(listLength / n + 1);
              this.hotelListShowPageNum = 0;
              this.hotelListShowPageTotalNum = lineNum;

              for (let i = 0; i < lineNum; i++) {
                let temp = this.hotelList.slice(i * n, i * n + n);
                this.hotelListShowPage.push(temp);
              }
              this.hotelListShow = [
                ...this.hotelListShow,
                ...this.hotelListShowPage[0],
              ];
              console.log(this.hotelListShowPageTotalNum);
              console.log(this.hotelListShowPage);
            } else {
              this.hotelListShow = this.hotelList;
            }

            // this.hotelListShow =
            // this.hotelListShowPage =
          } else {
            this.$message.warning(res.data.msg);
          }
          this.loading = false;
        });
      } else {
        let cityCode =
          this.form.province == ""
            ? 0
            : this.form.city == ""
            ? this.form.province
            : this.form.country == ""
            ? this.form.city
            : this.form.country;
        let params = {
          taskType: "",
          excel_file: this.form.excel_file,
          h_name: this.form.hotelName,
          h_city_code: cityCode,
          h_hotel_template: this.form.model,
          // noTagsSelect: this.form.unTag,
          hotelStateSelect: this.form.hotelState,
          a07_label_group: this.form.sign,
          // h_group: this.form.brand,
        };
        for (let idx in this.form.brand) {
          params["h_group[" + idx + "][value]"] = this.form.brand[idx].value;
          params["h_group[" + idx + "][name]"] = this.form.brand[idx].name;
          params["h_group[" + idx + "][disabled]"] = false;
        }
        for (let idx in this.form.type) {
          params["a08_equipment:[" + idx + "][name]"] = this.form.type[idx];
          params["a08_equipment:[" + idx + "][value]"] = this.form.type[idx];
          params["a08_equipment[" + idx + "][disabled]"] = false;
        }
        for (let idx in this.form.unTag) {
          params["noTagsSelect:[" + idx + "][name]"] =
            this.form.unTag[idx].tag_name;
          params["noTagsSelect:[" + idx + "][value]"] = this.form.unTag[idx].id;
          params["noTagsSelect[" + idx + "][disabled]"] = false;
        }
        for (let idx in this.form.hotelState) {
          params["noTagsSelect:[" + idx + "][name]"] =
            this.form.hotelState[idx].name;
          params["noTagsSelect:[" + idx + "][value]"] =
            this.form.hotelState[idx].value;
          params["noTagsSelect[" + idx + "][disabled]"] = false;
        }
        // 判断是否导入文件
        if (type == "excel") {
          if (this.form.excel_file == undefined) {
            this.hotelList = [];
            this.loading = false;
            return;
          }
          params = {
            taskType: "",
            excel_file: this.form.excel_file,
          };
        }
        searchHotel(params).then((data) => {
          this.loading = false;
          if (data.data.data.msg == null) {
            this.hotelList = [];
            return;
          }
          this.hotelList = data.data.data.msg || [];
          this.handleCheckedChange();
        });
      }
    },
    // 提交
    submit() {
      this.$emit("change", this.selectedHotels);
      this.$emit("close");
      this.$emit("platfrom", this.form.type);
      console.log(this.selectedHotels);

      this.dialogVisible = false;
    },
    // 初始化数据 模板/品牌/省份
    init(apkname, signId) {
      getInitData({
        selectedTadkID: this.id,
        apk: apkname ? apkname : this.apk,
      }).then((data) => {
        let info = data.data.data;
        this.tagsList = info.tags || [];
        this.rowhotelGroup = info.rowhotelGroup || [];
        this.areas.province = info.cities || [];
        // this.equipmentRow = info.equipmentRow || [];
        this.labelList = info.noTags || [];
      });
      getDeviceName({
        sign_id: signId ? signId : this.signId,
      }).then((data) => {
        console.log(data, "dwadawaawa");
        if (data.data.code == 100000) {
          this.equipmentRow = data.data.data;
        } else {
          this.equipmentRow = [];
        }
      });
    },
  },
  watch: {
    signId(val) {
      this.form.sign = val;
    },
    signList: {
      handler: function (newVal) {
        this.signList = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.hotel_select_box {
  span {
    margin-left: 10px;
  }
}
.content_box {
  display: flex;
  justify-content: space-between;
  .item {
    width: 49%;
    .btn_row {
      float: right;
    }
    .line {
      width: 100%;
      height: 2px;
      // background: #e6e6e6;
    }
    .hotel_box {
      width: 100%;
      height: 275px;
      // max-height: 275px;
      box-sizing: border-box;
      padding-top: 10px;
      overflow-y: auto;
      margin-top: 10px;
      border: 1px solid #ddd;
      .hotel_checkbox_box {
        padding: 0px 20px;
      }
      .hotel_checkbox {
        display: block;
        margin-bottom: 5px;
      }
      .empty_tip {
        padding: 10px;
        text-align: center;
      }
    }
    .select_hotel_box {
      width: 100%;
      height: 500px;
      max-height: 500px;
      overflow-y: auto;
      margin-top: 10px;
      // box-shadow: 5px 5px 20px #e6e6e6;
      border: 1px solid #ddd;
      .hotel_select_item {
        padding: 10px 20px;
        i {
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }
    .foot_btn_row {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-top: 15px;
    }
  }
}
.deviceRow {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
