import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
    //  apk列表
export function getList(data) {
    return request({
        url: `${vi}/Hotelrooms/deviceManagement`,
        method: 'post',
        data,
    });
}
//  客房导出
export function exportDeviceInfo(data) {
    return request({
        url: `v2/hotelrooms/exportDeviceInfo`,
        method: 'post',
        data: qs.stringify(data),
        responseType: 'blob'
    });
}
// 新增修改
export function editUpdate(data) {
    return request({
        url: `${vi}/Hotelrooms/editDevice`,
        method: 'post',
        data,
    });
}
// 删除
export function delall(data) {
    return request({
        url: `${vi}/Hotelrooms/delallDevice`,
        method: 'post',
        data,
    });
}
// 复位
export function resetDevice(data) {
    return request({
        url: `${vi}/Hotelrooms/resetDevice`,
        method: 'post',
        data,
    });
}
// 获取所有设备
export function getApkPlatform(data) {
    return request({
        url: `${vi}/Hotelapks/getApkPlatform`,
        method: 'post',
        data,
    });
}

//上传apk校验
export function checkApk(data) {
    return request({
        url: `${vi}/Hotelapks/checkApk`,
        method: 'post',
        data,
    });
}
//上传文件
export function uploadFile(data) {
    return request({
        url: '/ajax/uploadtos3',
        method: 'post',
        data,
    });
}