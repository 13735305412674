<template>
    <!--<div class="root" :class="[$attrs.onlyShowModule?'onlyShowModule':'']">-->
    <div class="root">
        <div :style="{'backgroundImage':'url('+bgUrlProp+')'}" class="bgBox"  @click="hideShade">
            <div class="home_top">
                <div class="home_logo" >
                    <img :src="homePageProp.modelLogo" alt="" >
                </div>
                <div class="city_info">
                    <div class="info_one">
                        <div class="top">{{curTime.time}}</div>
                        <div class="bottom">{{curTime.date}}</div>
                    </div>
                    <div class="info_two">
                        <div class="top">广州 晴</div>
                        <div class="bottom">25~30℃</div>
                    </div>
                </div>
            </div>
            <div class="modelBox" @click="hideShade">
                <div class="swiper-father" v-if="swiperShow">
                    <swiper :options="swiperOption" ref="swiper">
                        <swiper-slide  v-for="(val,key) in pic"
                                       :key="key" >
                            <div class="slideBox" :class="{borderBox:!shadeShow || (shadeShow && modelShowBtnName==key)}">
                                <img :src="pic[key].picVertical.url" alt="" v-if="pic[key].picVertical">
                                <img src="" alt="" v-else>
                                <div class="nameBox">{{pic[key].mname}}</div>
                                <config-btn class="configBox" ref="configBtn"
                                            :idProp="pic[key].id" :keyProp="key"
                                            :hotelContractProp="hotelContractProp"
                                            :serviceTypeProp="serviceTypeProp" @shadeMeth="shadeMeth" @modelShowBtnMeth="modelShowBtnMeth"
                                            :hotelServiceProp="hotelServiceProp" v-bind="$attrs">
                                </config-btn>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev swiper-button-white" slot="button-prev"  @click="prev" v-if=""></div>
                    <div class="swiper-button-next swiper-button-white" slot="button-next" @click="next"></div>
                </div>
            </div>
        </div>
        <div class="shade" @click="hideShade" v-if="shadeShow"></div>
    </div>
</template>

<script>
    import commonMeth from '../../../../api/method'
    import Bus from '../../../../api/Bus'
    // import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import Swiper from "swiper"
    export default {
        name: "AllModule",
        props:['bgUrlProp','picProp','hotelServiceProp','homePageProp','hotelContractProp','serviceTypeProp'],
        data:()=>({
            curTime:{
                date:'',
                week:'',
                time:'',
            },
            pic:{},
            query:{},
            shadeShow:false,
            modelShowBtnName:'',
            swiperOption: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                slidesPerView: 3,
                spaceBetween: 30,
                observer: true,
                pagination: '.swiper-pagination',
                paginationClickable: true,
                autoplay: 2500,
                autoplayDisableOnInteraction: false,
                loop: false,
                coverflow: {
                    rotate: 30,
                    stretch: 10,
                    depth: 60,
                    modifier: 2,
                    slideShadows : true
                }
            },
            swiperShow:false,
        }),
        methods:{
            slideConfigBox(type){
                // this.shadeShow=true;
            },
            shadeMeth(val){
                // this.shadeShow=val;
            },
            hideShade(){
                for(var i=0;i<Object.keys(this.pic).length;i++){
                    var el=this.$refs.configBtn[i];
                    if(el){
                        this.$refs.configBtn[i].hideConfigBox();
                    }
                }
            },
            modelShowBtnMeth(type){
                this.modelShowBtnName=type;
            },
            prev(){
                this.$refs.swiper.$swiper.slidePrev();
            },
            next(){
                this.$refs.swiper.$swiper.slideNext();
            }
        },
        computed:{
            modelHighIndexClass(){
                return function(msg) {
                    return this.modelShowBtnName == msg ? ' highIndex' : ' '
                }
            },
        },
        mounted(){
            this.query=this.$route.query;
            this.pic=JSON.parse(JSON.stringify(this.hotelServiceProp.pic));
            setInterval(()=>{
                this.curTime=commonMeth.getCurrentDate();
            },1000);
        },

        watch:{
           
            bgUrlProp:{
                  handler: function (newVal) {
                          this.bgUrlProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             picProp:{
                 handler: function (newVal) {
                          this.picProp=newVal    
                },
                deep: true,
                immediate: true, 
            },
             hotelServiceProp:{
                 handler: function (newVal) {
                          this.hotelServiceProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             homePageProp:{
                 handler: function (newVal) {
                          this.homePageProp=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelContractProp:{
                 handler: function (newVal) {
                          this.hotelContractProp=newVal
                },
                deep: true,
                immediate: true, 
            },
              serviceTypeProp:{
                 handler: function (newVal) {
                          this.serviceTypeProp=newVal
                },
                deep: true,
                immediate: true, 
            },
            pic(newVal){
                this.$nextTick(()=>{
                    this.swiperShow=true;
                })
            }
        },
        created(){
            Bus.$on('getHotelDataThree',val=>{
                this.pic=JSON.parse(JSON.stringify(this.hotelServiceProp.pic));
                Bus.$emit('closeDrawer',false);
            });

        }
    }
</script>

<style scoped lang="scss">
    @import '../../../../../node_modules/swiper/swiper-bundle.css';
    @import '../../../../../node_modules/swiper/swiper-bundle.min.css';
    .highIndex{
        z-index:100;
    }
    .root{
        position: relative;
    }
    .bgBox{
        width: 1152px;
        height: 648px;
        padding: 55px 100px 60px 100px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        box-sizing: border-box;
       
    }
    /*顶部样式开始*/
    .home_top{
        width:100%;
        height:70px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:10px;
    }

    .home_logo{
        width:auto;
        height:100%;
        position:relative;
        box-sizing:content-box;
        border:2px dashed rgba(28, 144, 238,0);
        transition:0.5s;
        .configBox{
            z-index:101;
        }
    }
    .home_logo.active{
        border:2px dashed rgba(28, 144, 238,1);
        transition:0.5s;
    }
    .home_logo>img{
        width:auto;
        height:100%;
    }
    .home_logo_group>div{
        float:left;
    }
    .home_scroll_box{
        /*width:36.6%!important;*/
        width:380px;
        height:100%;
        display:flex;
        align-items:center;
        justify-content: center;
        border:2px dashed rgba(28, 144, 238,0);
        transition:0.5s;
        padding:0 10px;
    }
    .home_scroll_box.active{
        border:2px dashed rgba(28, 144, 238,1);
        transition:0.5s;
    }
    .scroll_box{
        /*width:*/
    }
    .home_scroll_text{
        width:100%;
        border-radius:30px;
        color:#fff;
        padding:0 20px;
        display:flex;
        align-items: center;
        position:relative;
    }
    .home_scroll_text.coBlack{
        background:rgba(250,250,250,0.05);
    }
    .home_scroll_text.coTrans{
        background:none;
    }
    .txt{
        color:#fafafd!important;
        font-weight:400!important;
        outline:none;
        font-size:14px;
        overflow:auto;
        padding:5px 0;
    }
    .txt-clone{
        font-weight:400!important;
    }
    .home_scroll_text>div{
        /*width:100%;*/
        white-space: nowrap;
        /*overflow:hidden;*/
        /*text-overflow: ellipsis;*/
    }
    .txt-scroll{
        width:100%;
        background:none!important;
    }
    .city_info{
        display:flex;
        height:45px;
        /*background:#80cbc4;*/
    }
    .city_info div{
        color:#fff;
        letter-spacing:1px;
    }
    .info_one{
        height:100%;
        border-right:2px solid #fff;
        /*background:#81d4fa;*/
        padding-right:7px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_one .top{
        /*font-weight:600;*/
        font-size:22px;
    }
    .info_one .bottom{
        font-size:14px;
    }
    .info_two{
        padding-left:7px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info_two div{
        font-size:14px;
    }
    /*顶部样式结束*/

    .modelBox{
        width:100%;
        /*background:red;*/
        flex:1;
        overflow:hidden;
        z-index:100;
    }
    .swiper-container{
        height:100%;
    }
    .swiper-slide{
        width:25%;
        /*background:yellow;*/
        margin-right:30px;
        position:relative;
    }
    img{
        width:100%;
        height:100%;
    }
    .nameBox{
        width:100%;
        padding:10px 0;
        text-align: center;
        background:rgba(0,0,0,0.6);
        position:absolute;
        bottom:0;
        color:#fff;
        font-size:16px;
        letter-spacing: 1px;
    }
    /*配置开始*/
    .configBox{
        display:flex;
        position: absolute;
        top:0;
    }

    .configBox .btnItem{
        white-space:nowrap;
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.8)!important;
        cursor:pointer;
    }
    .configBtn{
        display:none;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .detail_box{
        /*background:#252527;*/
        background-color:rgba(0,0,0,0.8)!important;
        padding:5px 0;
    }
    .detail_box.up{
        position:absolute;
        /*left:10px;*/
        bottom:40px;
    }
    .detail_box.down{
        position:absolute;
        top:40px;
    }
    .detail_box .item{
        height:36px;
        color:#fff;
        padding:0 20px;
        cursor:pointer;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        transition:0.5s;
    }
    .slideBox{
        /*display:none;*/
        /*overflow:hidden;*/
        transition:2s;
    }
    .btnGroup{
        display:flex;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
        z-index:9999;
    }
    .configMarginRight{
        margin-right:10px;
    }
    .configMarginLeft{
        margin-left:10px;
    }
    .highIndex{
        z-index:100;
    }
    .color_match>.item,.more_file>.item{
        width:100%;
        height:36px;
        padding:0 20px;
        display:flex;
        align-items: center;
        font-size:14px;
        box-sizing:border-box;
    }
    .color_match>.item.active{
        background:#1b90ed;
    }
    .item.active .check_icon{
        visibility: visible;
    }
    .item .check_icon{
        visibility: hidden;
    }
    .color_match>.item>.check_icon{
        width:18px;
        height:20px;
        margin-right:10px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .color_match>.item>.show_icon{
        width:44px;
        height:24px;
        margin-right:10px;
        display:inline-block;
    }
    .noWarp{
        white-space:nowrap;
    }
    img,video{
        width:100%;
        height:100%;
        outline:none!important;
    }
    .arrowIcon.down{
        transform:rotate(180deg);
    }
    .noneOutLine{

    }
    /*配置结束*/
    .borderBox{
        border:2px dashed transparent;
        transition: 0.5s;
        cursor:pointer;
    }
    .borderBox:hover{
        border:2px dashed #1b90ed!important;
        transition: 0.5s!important;
    }
    .borderBox.noHover:hover{
        border:2px dashed transparent;
        transition: 0.5s
    }
    .innerShade{
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        z-index:100;
        background:rgba(0,0,0,0.6);
    }
    .shade{
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.6);
        position:absolute;
        top:0;
        left:0;
        z-index:99;
        cursor:pointer;
        transition:0.5s;
    }
    .slideBox{
        height:100%;
        box-sizing: border-box;
        border:2px solid transparent;
    }
    img{
        object-fit: cover;
    }
    .swiperItem{
        background:red!important;
    }
    .swiper-father{
        height:100%;
    }
</style>
