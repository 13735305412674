<template>
  <div >
    <!-- <div class="back_ground" v-if="dialog.visible"></div> -->
    <el-row class="btnRow">
      <el-button v-if="showFunModel('删除实施人员')&&check==true" class="largeBtn" type="danger" @click="delPersonMeth">删除</el-button>
      <el-button v-if="showFunModel('添加实施人员')&&check==true" class="rightBtn largeBtn" type="primary" @click="dialogShow(true)">增加</el-button>
    </el-row>
    <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading" @getTableData="getTableData"></table-list>

    <el-dialog title="新增人员" :visible.sync="dialog.visible" :append-to-body='true' width="650px"  class="person_dialog">
      <el-form :model="dialog" ref="dialogForm" class="dialogForm">
        <div v-for="(domain, index) in dialog.personArr" class="personItem">
          <div class="formBox">
            <el-form-item label="姓名" label-width="100px" :key="domain.key" :prop="'personArr.' + index + '.name'" :rules="dialog.rules.name">
              <el-input v-model="domain.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" label-width="100px" :key="domain.key" :prop="'personArr.' + index + '.phone'" :rules="dialog.rules.phone">
              <el-input v-model="domain.phone" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <div class="colBox">
            <i class="operateBtn el-icon-circle-plus addIcon" @click.prevent="partAdd(index)"></i>
            <i class="operateBtn el-icon-remove-outline addIcon" @click.prevent="partReduce(index)" :class="index === 0 ? 'hiddenEl' : ''"></i>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShow(false)">取 消</el-button>
        <el-button type="primary" @click="submitForm(true)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import qs from "querystring";
import commonMeth from "../../../api/method";
export default {
  name: "Person",
  props: ["id", "hotel_contract"],
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写手机号码"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
        return callback(new Error("请填写正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      check: "",
      funArr: [],
      table: {
        tableHeader: [
          { prop: "name", label: "姓名", width: "" },
          { prop: "phone", label: "电话号码", width: "" },
          { prop: "weight", label: "角色", width: "" },
          { prop: "room_num", label: "上线房间数", width: "" },
        ],
        tableList: [
          // {id:1,name:'张三',mobile:'110',role:0,roomNum:'10'},
        ],
        hotel_contract: "",
        page: 1,
        limit: 5, //当前每页显示多少条
        total: 0,
        select: true,
        option: true,
        radio: true,
        fromUrl: "personConfig",
        loading: false,
        isManager: false, //不是管理员
        adminId: "",
      },
      query: {
        fromUrl: "",
        id: "",
      },
      token: "",
      dialog: {
        personArr: [{ name: "", phone: "" }],
        visible: false,
        rules: {
          name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
          phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        },
      },
      operateIds: "",
      curAccountPhone: "",
      adminId: "",
    };
  },
  created() {
    this.table.hotel_contract = this.hotel_contract;
  },
  methods: {
    hotelcheck(id) {
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id:id,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.hotel_contract = res.data.data.hotel_contract;
            var obj = {
              hotelContract: this.hotel_contract,
              token: this.token,
            };
            var params = qs.stringify(obj);
            this.$http
              .post(this.global.HotelMemberCheck, params)
              .then((res) => {
                if (res.data.code === 100000) {
                  this.check = res.data.data.checkStatue;
                } else {
                }
              });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    tableMeth(id) {
      return new Promise((resolve, reject) => {
        this.table.loading = true;
        this.$http
          .get(this.global.getHotelMembers, {
            params: {
              token: this.token,
              id: this.id,
              page: this.table.page,
              per_page: this.table.limit,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var tableData = res.data.data;
              console.log(tableData);
              this.table.tableList = tableData.items;
              this.table.total = tableData.total;
            }
            this.table.loading = false;
            resolve();
          });
      });
    },
    getIndexHeader() {
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
        });
        this.$http.post(this.global.getIndexHeader, param).then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.curAccountPhone = res.data.data.mobile;
            var managerData = this.table.tableList.filter((item) => {
              return (
                item.phone === res.data.data.mobile && item.weight === "管理员"
              );
            });
            managerData.length > 0
              ? (this.table.isManager = true)
              : (this.table.isManager = false);
            if (managerData.length > 0) {
              this.table.isManager = true;
              this.table.adminId = managerData[0].id;
            } else {
              this.table.isManager = false;
            }
          } else {
            this.$message.error(res.data.msg);
          }
          resolve();
        });
      });
    },
    getNewPage(newPage) {
      this.table.page = newPage;
      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
      this.tableMeth();
    },
    delPersonMeth() {
      if (this.operateIds == "") {
        this.$message.warning("请选择要删除的人员");
      } else {
        this.$confirm("确定删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var param = qs.stringify({
              token: this.token,
              hotel_contract: this.hotel_contract,
              member_id: this.operateIds,
            });
            this.$http.post(this.global.delHotelMember, param).then((res) => {
              if (res.data.code === 100000) {
                this.$message.success("删除成功");
                this.tableMeth();
              } else {
                this.$message.success(res.data.msg);
              }
            });
          })
          .catch(() => {});
      }
    },
    partAdd(index) {
      this.dialog.personArr.splice(index + 1, 0, { name: "", phone: "" });
    },
    partReduce(index) {
      this.dialog.personArr.splice(index, 1);
    },
    submitForm() {
      this.$refs["dialogForm"].validate((valid, obj) => {
        if (valid) {
          this.$confirm("确定保存？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.addHotelMember();
            })
            .catch(() => {});
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    addHotelMember() {
      // this.dialog.visible=false;
      // return false;
      var param = qs.stringify({
        token: this.token,
        hotel_contract: this.hotel_contract,
        data: JSON.stringify(this.dialog.personArr),
      });
      this.$http.post(this.global.addHotelMember, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message.success("添加成功");
          this.tableMeth();
          this.dialog.visible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    dialogShow(val) {
      this.dialog.personArr = [{ name: "", phone: "" }];
      this.dialog.visible = val;
    },
    getTableSelect(val) {
      this.operateIds = val.id;
    },
    getTableData(id) {
      this.token = sessionStorage.getItem("token");
      this.tableMeth(id).then(() => {
        this.getIndexHeader();
      });
    },
  },
  mounted() {
    commonMeth.getTreeData(this.table.option).then((res) => {
      this.funArr = res.funArr;
      this.table.option = res.flag;
    });
    this.token = sessionStorage.getItem("token");
    this.query = this.$route.query;
    this.query.id = this.id; //酒店id
  },
  watch: {
    hotel_contract: {
      handler: function (newVal) {
        this.hotel_contract = newVal;
        this.table.hotel_contract = newVal;
      },
      deep: true,
      immediate: true,
    },
    id: {
      handler: function (newVal) {
        this.id = newVal;
        if(newVal){
        this.getTableData(this.id);
        this.hotelcheck(this.id);
        }
       
      },
      deep: true,
      immediate: true,
    },
    "dialog.visible"(newVal) {
      if (!newVal) {
        this.dialog.personArr = [{ name: "", phone: "" }];
      }
    },
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    judgeIsManager() {},
  },
};
</script>

<style scoped lang="scss">
.personItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.formBox {
  background: #f7fbfe;
  border: 1px solid #f1f2f5;
  border-radius: 10px;
  margin-right: 10px;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  flex: 1;
}
.hiddenEl {
  visibility: hidden;
}
.addIcon {
  margin-right: 10px;
}
.operateBtn {
  color: #1775ef;
  font-size: 24px;
}
.btnRow {
  display: flex;
  margin-top: 20px;
}
.dialogForm {
  max-height: 500px;
}
.back_ground {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9998 !important;
}

</style>
