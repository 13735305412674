<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">通知</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>

        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
        </el-card>
        <el-card class="box-card">
            <el-row :gutter="10" class="rowBtn noMarginBottom" >
                <div class="grid-content bg-purple colDiv paddingLeft allCheck">
                    <el-checkbox v-model="checkAll" :isIndeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
                </div>
                <div class="grid-content bg-purple paddingLeft">
                    <div class="item">
                        <div class="top">
                            <div class="title">
                                <el-button type="primary" @click="markConfirm" size="">标记为已读</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-row>
            <el-row class="noData" v-if="noData">
                暂无数据
            </el-row>
            <el-checkbox-group  v-model="ids" v-loading="table.loading">
                <el-row :gutter="10" class="rowBox noMarginBottom showItem" v-for="(item,index) in table.tableList" style="display:flex;">
                    <div class="grid-content bg-purple colDiv checkColDiv paddingLeft" >{{item.id}}
                        <el-checkbox :key="item.id" :label="item.id" class="labelNone"></el-checkbox>
                    </div>
                    <div class="grid-content bg-purple paddingLeft conColDiv" @click="drawerShow(item.title,item.time,item.text,item.id)">
                        <div class="item" @click="drawerShow(item.title,item.create_time,item.text,item.id)">
                            <div class="newsTitle" >
                                <div class="point" :class="item.status===0?'red':''"></div>
                                <div class="con">{{item.title}}</div>
                                <div class="time">{{item.create_time}}</div>
                            </div>
                            <div class="bottom">
                                <div class="point red"></div>
                                <div class="con" v-html="escapeStringHTMLCom(item.text)"></div>
                            </div>
                        </div>
                    </div>
                    <!--<el-col :span="4">-->
                        <!--<div class="grid-content bg-purple colDiv" @click="drawerShow(item.title,item.time,item.text,item.id)" >-->
                            <!--{{item.time}}-->
                        <!--</div>-->
                    <!--</el-col>-->
                </el-row>
            </el-checkbox-group>
            <el-drawer
                    :title="drawer.title"
                    :visible.sync="drawer.show"
                    :direction="drawer.direction"
                    :before-close="handleClose"
                     size="750" class="noticeDrawer">
                 <div class="drawerBox">
                     <div class="drawerTitle">{{drawer.title}}</div>
                     <div class="drawerTime">{{drawer.time}}</div>
                     <!--<div class="drawerCon" v-html="escapeStringHTMLCom(drawer.text)"></div>-->
                     <div class="drawerCon newsDetail" ref="drawerConShow" v-html="drawer.text"></div>
                 </div>
            </el-drawer>
            <div class="block pageBox">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="table.page"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="table.limit"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="table.total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    // 工具栏配置
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
        ["blockquote", "code-block"], // 引用  代码块
        [{ header: 1 }, { header: 2 }], // 1、2 级标题
        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
        [{ script: "sub" }, { script: "super" }], // 上标/下标
        [{ indent: "-1" }, { indent: "+1" }], // 缩进
        // [{'direction': 'rtl'}],                         // 文本方向
        [{ size: ["small", false, "large", "huge"] }], // 字体大小
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
        [{ font: [] }], // 字体种类
        [{ align: [] }], // 对齐方式
        ["clean"], // 清除文本格式
    ];
    import { quillEditor } from "vue-quill-editor"; //调用编辑器
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";

    export default {
        name: "OperaLog",
        metaInfo: {
            meta: [
                { name: 'referrer', content: 'no-referrer' }
            ]
        },
        data: () => ({
            bread:{
                level:2,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAll'},
            },
            topAttr:{
                type:'',
                typeOption:[
                    {label:'渠道商申请',value:'1'},
                    {label:'内容分发审批',value:'2'},
                    {label:'提现申请',value:'3'},
                    {label:'广告订单确认',value:'4'},
                ],
                date:''
            },
            table:{
                tableHeader:[
                    {prop:'hotel',label:'酒店名称',width:'350'},
                    {prop:'hotel_brand',label:'集团品牌',width:''},
                    {prop:'hotel_use_state',label:'客户终端状态',width:''},
                    {prop:'h_province',label:'省份',width:'110'},
                    {prop:'h_city',label:'城市',width:'110'},
                    {prop:'h_county',label:'区县',width:'110'},
                    {prop:'hotel_room',label:'客房总量（间）',width:''},
                    {prop:'n_connected',label:'激活终端数量（台）',width:''},
                    {prop:'addtime',label:'上线时间',width:'180'},
                ],
                tableList:[ ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'hotelManAll',
                option:true,
                loading:true
            },
            timeLine:{
                reverse: true,
                activities: [{
                    content: '活动按期开始',
                    timestamp: '2018-04-15'
                }, {
                    content: '通过审核',
                    timestamp: '2018-04-13'
                }, {
                    content: '创建成功',
                    timestamp: '2018-04-11'
                }]
            },

            drawer:{
                show: false,
                direction: 'rtl',
                title:'',
                con:'',
                // time:''
            },
            checked:'',
            page:{
                Page:'',
                limit:'',
                total:''

            },
            topSearchShow:{ },
            condition:{},
            ids:[],
            checkAll:false,
            isIndeterminate:true,
            idOption:[],
            token:'',
            noData:'',
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },

        }),
        methods:{
            handleClose(done) {
                done();
            },
            drawerShow(title,time,text,id){
                this.drawer.title=title;
                this.drawer.text=text;
                this.drawer.time=time;
                this.drawer.show=true;
                this.markMeth('showCon',id);
            },
            handleSizeChange(val) {
                this.table.limit=val;
                this.tableMeth();
            },
            handleCurrentChange(val) {
                this.table.page=val;
                this.tableMeth();
            },

            //
            tableMeth_test(){
                this.$http.get(this.global.infoSearch,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        limit:this.table.limit,
                        per_page:this.table.limit,
                        type:'notice',
                        status:this.condition.noticeStatus,
                        keyword:this.condition.keyword,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                        var idOption=[];
                        tableData.items.forEach(function(item,index){
                            idOption.push(item.id);
                        });
                        this.idOption=idOption;
                        tableData.items.length===0?this.noData=true:this.noData=false;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            tableMeth(){
                this.table.loading=true;
                this.checkAll=[];
                this.ids=[];
                this.$http.get(this.global.informationMessage,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        limit:this.table.limit,
                        per_page:this.table.limit,
                        keyword:this.condition.keyword,
                        type:'notice'
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                        var idOption=[];
                        tableData.items.forEach(function(item,index){
                            idOption.push(item.id);
                        });
                        this.idOption=idOption;
                        tableData.items.length===0?this.noData=true:this.noData=false;
                        this.$emit('getNewMessage',tableData.newmessage);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='group'  || auth_type==='factory'){
                    this.topSearchShow={
                        infoType:true,
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        infoType:true,
                        keyword:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder='请输入通知名称关键词';
            },
            getCondition(val){
                this.condition.info_type=Array.isArray(val.info_type)?val.info_type.join(','):''; //ka
                this.condition.keyword=val.keyword; //ka
                this.tableMeth();
            },
            markConfirm(){
                if(this.ids.length>0){
                    this.$confirm('确定标为已读?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.markMeth('btnMark');
                    }).catch(() => {

                    });
                }else{
                    this.$message.warning('请选择消息');
                }
            },
            markMeth(type,paramId){
                var ids='';
                type==='btnMark'? ids=this.ids.join(','):ids=paramId;
                var param=qs.stringify({
                    token:this.token,
                    ids:ids
                });
                this.$http.post(this.global.alreadyRead,param).then(res=>{
                    if(res.data.code===100000){
                        type==='btnMark'?this.$message.success('标记成功'):'';
                        this.ids=[];
                        this.tableMeth();
                    }else{
                        this.$message.error('标记失败');
                    }
                }).catch(()=>{
                    this.$message.error('返回错误');
                })
            },
            handleCheckAllChange(val) {
                this.ids = val ? this.idOption : [];
                this.isIndeterminate = false;
            },

//把实体格式字符串转义成HTML格式的字符串

            getFoldMeth(val){
                this.fold=val;
            },
            getDetail(id){
                this.$http.get(this.global.infoView,{
                    params:{
                        token:this.token,
                        id:id
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.title=res.data.data.title;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            checkHtml(htmlStr){
                var  reg = /<[^>]+>/g;
                return reg.test(htmlStr);
            },

        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getTopSearchShow();
            this.tableMeth();
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill
            },
            escapeStringHTMLCom(){
                return function(str){
                    if(str){
                        if(this.checkHtml(str)){
                            str = str.replace(/&lt;/g, '<');
                            str = str.replace(/&gt;/g, '>');
                            str = str.replace('<br>', '');
                            var index=str.indexOf('</p');
                            var tranStr=str;
                            index!==-1
                            ?tranStr=str.substring(0,index)
                                :'';
                            tranStr=tranStr+'</p>';
                            var imgIndex=str.indexOf('img');
                            var imgStr=tranStr.substring(0,imgIndex+1);
                            if(imgIndex!==-1){
                                return imgStr;
                            }else{
                                return tranStr;
                            }
                        }else{
                            return str;
                        }
                    }
                }
            },
            escapeStringHTMLComTest(){
                return function(str){
                    if(str){
                        str = str.replace(/&lt;/g, '<');
                        str = str.replace(/&gt;/g, '>');
                        var first='';
                        var index=str.indexOf('</p');
                        var tranStr=str.substring(0,index);
                        tranStr=tranStr+'</p>';
                    }
                    return tranStr;
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    p{
        color:red;
        white-space:pre;
    }
    .whitePre{
        white-space:pre;
    }

    .fullInput{
        width:100%;
    }
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        /*background: #99a9bf;*/
    }
    .bg-purple {
        /*background: #d3dce6;*/
    }
    .bg-purple-light {
        /*background: #e5e9f2;*/
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .item{
        padding:15px 0;
    }
    .newsTitle{
        display:flex;
        margin-bottom:10px;
        align-items: center;

    }
    .newsTitle>.con{
        font-size:16px;
        font-weight:600;
    }
    .newsTitle>.time{
        margin-left:auto;
        font-size:14px;
    }
    .top{
        display:flex;
        align-items: center;
        /*margin-bottom:15px;*/
    }
    .bottom{
        display:flex;
        align-items: center;
        /*margin-bottom:15px;*/
        overflow: hidden;
        /*display: -webkit-box;*/
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .point{
        width:8px;
        height:8px;
        border-radius:50%;
        /*background:green;*/
        margin-right:20px;
        &.red{
            background:#f35c5d!important;
        }
    }
    .rowBox{
        border-bottom:1px solid #ddd;
        cursor:pointer;
    }
    .con{
        overflow: hidden;
        text-overflow: ellipsis;
        font-size:14px;
        padding-bottom:3px;
        line-height:26px;
    }
    .bottom .point{
        visibility: hidden;
    }
    .bottom .con{
        flex:0.6;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .colDiv{
        /*padding-top:20px;*/
    }
    .checkColDiv{
        width:34px;
        display:flex;
    }
    .conColDiv{
        flex:1;
    }
    .rowBtn{
        display:flex;
        align-items: center;
        border-bottom:1px solid #EBEEF5;
        background:#fafbfe;
        padding:15px 0;
        .point{
            visibility: hidden;
        }
        .item{
            padding-top:0!important;
            padding-bottom:0!important;
        }
    }
    /*抽屉样式开始*/
    .drawerBox{
        padding:0 20px;
        max-height:100vh;
        overflow:auto;
        .drawerTitle{
            margin-top:40px;
            font-weight:600;
        }

        .drawerTime{
            padding:10px 0;
            font-size:14px;
            color:#959595;
        }
        .drawerCon{
            color:#8b8b8b;
            padding-top:20px;
            line-height:30px;
            letter-spacing:1px;

        }
    }
    /*抽屉样式结束*/

    /*分页开始*/
    .pageBox{
        margin-top:20px;
    }
    /*分页结束*/
     .editor {
         line-height: normal;
         height: 220px;
     }
     .noMarginBottom{
         margin-bottom:0;
     }
     .tableHead{

     }
     .paddingLeft{
         padding-left:10px;
     }
     .showItem{
         display:flex;
     }
     .allCheck{
         margin-right:12px;
         display:flex;
         align-items: center;
     }

    .ql-snow .ql-tooltip[data-mode=link]::before {
        content: "请输入链接地址:";
    }
    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: '保存';
        padding-right: 0px;
    }

    .ql-snow .ql-tooltip[data-mode=video]::before {
        content: "请输入视频地址:";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: '14px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
        content: '10px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
        content: '18px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
        content: '32px';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: '文本';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: '标题1';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: '标题2';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: '标题3';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: '标题4';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: '标题5';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: '标题6';
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
        content: '标准字体';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
        content: '衬线字体';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
        content: '等宽字体';
    }
    #disabledMsg.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
        color: #bfd9d8;
        background-color: #eef6f6;
        border-color: #d1e5e5;
    }
    .noData{
        width:100%;
        display:flex;
        align-items:center;
        justify-content: center;
        padding-bottom:20px;
        border-bottom:1px solid #EBEEF5;
        font-size:14px;
        color:#909399;
    }
</style>
