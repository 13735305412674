<!-- 酒店集团添加/编辑 弹窗 -->
<template>
  <el-dialog
    :title="JSON.stringify(current) == '{}' ? '添加集团' : '集团编辑'"
    :visible.sync="dialogVisible"
    custom-class="oper-dia"
    width="550px"
    @open="open"
    @close="close"
  >
    <el-form
      :model="form"
      label-width="100px"
      ref="groupOperDia"
      :rules="rules"
    >
      <el-form-item label="酒店集团：" prop="name" required>
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <!-- <el-form-item label="联系人：">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item label="手机：">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item label="座机：">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item label="其他：">
        <el-input v-model="form.contact"></el-input>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { addGroup, editGroup } from "../api/group";
export default {
  name: "hotelGroupOperDia",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "酒店集团不能为空" }],
      },
    };
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.groupOperDia.resetFields();
        if (JSON.stringify(this.current) != "{}") {
          this.form.name = this.current.name;
        } else {
          this.form.name = "";
        }
      });
    },
    close() {
      // this.$refs.groupOperDia.resetFields();
    },
    submit() {
      this.$refs.groupOperDia.validate((valid) => {
        if (valid) {
          // 新增
          if (JSON.stringify(this.current) == "{}") {
            addGroup({
              name: this.form.name,
            }).then((data) => {
              if (data.data.code == 100000) {
                this.$message.success(data.data.msg);
                this.dialogVisible = false;
                this.$emit("refresh");
              } else {
                this.$message.error(data.data.msg);
              }
            });
          }
          // 编辑
          else {
            editGroup({
              id: this.current.id,
              name: this.form.name,
            }).then((data) => {
              if (data.data.code == 100000) {
                this.$message.success(data.data.msg);
                this.dialogVisible = false;
                this.$emit("refresh");
              } else {
                this.$message.error(data.data.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
