<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">账号管理</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card" v-loading="table.loading">
            <div class="title chartTitle blueTitle">账号管理列表</div>
            <el-row  class="btnRow">
                <div>
                    <el-button class="largeBtn" type="danger" @click="confirmMeth('del','删除')" size="medium" v-if="showFunModel('删除')">删除</el-button>
                    <export-btn class="largeBtn " :exportUrlProp="global.accountExportCsv" :conditionProp="condition" parentProp="sysAccMan" v-if="showFunModel('导出')"></export-btn>
                </div>
                <div class="rightBtn">
                    <el-button type="" @click="editCodeMeth" size="medium" class="largeBtn" v-if="showFunModel('修改验证码')">修改验证码</el-button>
                    <el-button type="primary" @click="newBuild" size="medium" class="largeBtn" v-if="showFunModel('新建')">新建</el-button>
                </div>
                <!--<export-btn :exportUrlProp="global.exportCreateHotel" :conditionProp="condition" parentProp="sysAccMan" v-if="showFunModel('导出')"></export-btn>-->
            </el-row>
            <table-list
                    :table="table"
                    @tableMeth="tableMeth"
                    @page="getNewPage"
                    @limit="getNewLimit"
                    @tableSelect="getTableSelect"
                    v-loading="table.loading">
            </table-list>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
        <!--修改验证码弹窗开始-->
        <el-dialog
                title="修改验证码"
                :visible.sync="editCodeDialog.dialogVisible" :close-on-click-modal="false"
                width="440px">
            <el-form :model="editCodeForm" :rules="editCodeRules" ref="editCodeForm" style="width:400px">
                <el-form-item prop="mobile" class="formItemNoMarginBottom">
                    <el-input v-model="editCodeForm.mobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item prop="event">
                    <el-select class="fullSelect"
                               v-model="editCodeForm.event"
                               clearable
                               collapse-tags
                               placeholder="请选择事件类型">
                        <el-option
                                v-for="item in selectData.editCodeEvent"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="code" class="formItemNoMarginBottom">
                    <el-input v-model="editCodeForm.code" placeholder="请输入验证码" type="number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button  @click="cancelEditCodeMeth">取 消</el-button>
                <el-button type="primary" @click="editCodeConfirmMeth">提交</el-button>
            </div>
        </el-dialog>
        <!--修改验证码弹窗结束-->
    </div>
</template>

<script>
    import  qs from 'querystring'
    import commonMeth from '../../api/method'
    export default {
        name: "SysAccMan",
        data(){
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请填写手机号码'))
                } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
                    return callback(new Error('请填写正确的手机号码'))
                }else{
                    callback()
                }
            };
            return{
                bread:{
                    level:3,
                    firstPath:{path: '/indexShow'},
                },
                tab:'basic',
                showData:false,
                allData:{},
                dateRange:'',
                token:'',
                auth:'',
                treeList:[
                    {
                        id:101,
                        authName:'酒店管理',
                        path:null,
                        pid:0,
                        children:[
                            {
                                id:104,
                                authName:'所有酒店列表',
                                path:null,
                                pid:101,
                                children:[
                                    {
                                        id:105,
                                        authName:'新建按钮',
                                        path:null,
                                        pid:'101,104'
                                    }
                                ]
                            }
                        ]
                    }
                ],
                table:{
                    tableHeader:[
                        {prop:'username',label:'账号名称'},
                        {prop:'company_name',label:'合作伙伴名称',width:''},
                        {prop:'contact_name',label:'联系人',width:''},
                        {prop:'contact_phone',label:'手机号码'},
                        {prop:'group_name',label:'所属角色'},
                        {prop:'parent_account',label:'上级名称'},
                    ],
                    tableList:[
                        // {id:1,username:'华南大区总监',company_name:'合作伙伴1',contactName:'联系人1',mobile:'110',group_name:'渠道商',parent_account:'上级名称',status:0},
                        // {id:2,name:'华南大区总监',coopName:'合作伙伴1',contactName:'联系人1',contactPhone:'110',role:'渠道商',higherName:'上级名称',status:1},
                        // {id:3,name:'华南大区总监',coopName:'合作伙伴1',contactName:'联系人1',contactPhone:'110',role:'渠道商',higherName:'上级名称',state:false},
                    ],
                    page:1,
                    limit:5,   //当前每页显示多少条
                    total:0,
                    fromUrl:'sysAccMan',
                    name:'sysAccMan',
                    option:true,
                    select:true,
                    loading:false,
                    switch:true,
                    switchVal:''
                },
                topSearchShow:{},
                operateIds:'',
                condition:{},
                funArr:[],
                allFunData:{},
                fold:{
                    showSearch:false,
                    foldText:'展开筛选项'
                },
                topSearchSave:{},
                allLoadingState:false,
                selectData:{
                    editCodeEvent:[
                        {value:'login',name:'登录'},
                        {value:'register',name:'注册'}
                    ]
                },
                editCodeDialog:{
                    dialogVisible:false,
                },
                editCodeForm:{
                    event:'',
                    mobile:'',
                    code:''
                },
                editCodeRules:{
                    event: [
                        { required: true, message: '请选择修改事件类型', trigger: 'blur' }
                    ],
                    mobile: [
                        { required: true, validator:checkPhone, trigger: 'blur' }
                    ],
                    code: [
                        {required: true, message: '请输入验证码', trigger: 'blur' },
                        {min: 4,max: 4, message: '长度为4个字符', trigger: 'blur' }
                    ],
                },
            }
        },

        methods:{
            tableMeth(orderVal) {
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }
                this.$http.get(this.global.accountIndex, {
                    params: {
                        token: this.token,
                        page: this.table.page,
                        size: this.table.limit,
                        code: this.condition.partner_id,
                        group_id: this.condition.role,
                        status: this.condition.sysAccStatus,
                        account_name: this.condition.keyword,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var tableData = res.data.data;
                        this.table.tableList = tableData.data;
                        this.table.total = tableData.total;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading = false;
                }).catch((res) => {
                    this.$message.error(res.data.msg);
                    this.table.loading = false;
                })
            },
            getTableSelect(val){
                var operateIds='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+','
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            getNewPage(newPage) {
                this.table.page = newPage;
                this.table.loading = true;
                this.tableMeth();
            },
            getNewLimit(newLimit) {
                this.table.limit = newLimit;
                this.table.loading = true;
                this.tableMeth();
            },
            getTopSearchShow(type) {
                var auth = JSON.parse(sessionStorage.getItem('auth'));
                var auth_type = auth.type;
                this.topSearchShow = {
                    coopType: true,
                    role:true,
                    sysAccStatus:true,
                    keyword:true,
                };
                this.topSearchShow.keywordPlaceholder='请输入联系人关键词';
            },
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.delMeth();
                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '请选择要删除的账号',
                        type: 'warning'
                    });
                }
            },
            delMeth(){
                this.$http.get(this.global.accountDel,{
                    params:{
                        token:this.token,
                        ids:this.operateIds
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('删除成功');
                        this.tableMeth();
                    }else {
                        this.$message.error(res.data.code)
                    }
                });
            },
            newBuild(){
                this.$router.push({path:'/sysAccManNew'})
            },
            getCondition(val,filterPage,filterLimit,type) {
                this.condition.partner_id=Array.isArray(val.noTypePartnerCode)?val.noTypePartnerCode.join(','):'';
                this.condition.role = Array.isArray(val.role) ? val.role.join(',') : '';
                this.condition.sysAccStatus = val.sysAccStatus;
                this.condition.keyword = val.keyword;
                this.getFilterTableCon(filterPage,filterLimit,type);
                this.tableMeth();
            },
            switchChange(val){
                this.$message.success('修改成功');
            },
            showFunModelCondition(){
                this.funArr.indexOf('状态')!==-1
                    ? this.table.switch=true
                    : this.table.switch=false
            },
            getFoldMeth(val){
                this.fold=val;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
            },
            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                this.getCondition(filterCon,filterPage*1,filterLimit*1,'mounted');
            },
            editCodeMeth(val){
                this.editCodeForm={
                    event:'',
                    mobile:'',
                    code:'',
                };
                this.editCodeDialog.dialogVisible=true;
            },
            cancelEditCodeMeth(){
                this.editCodeDialog.dialogVisible=false;
                this.editCodeReset();
            },
            editCodeConfirmMeth(){
                this.$refs['editCodeForm'].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定修改?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.editCodePost();
                            this.editCodeDialog.dialogVisible=false;
                        })
                    } else {
                        this.$message.warning('请完善信息');
                    }
                });
            },
            editCodePost(){
                var param=qs.stringify({
                    token:this.token,
                    event:this.editCodeForm.event,
                    mobile:this.editCodeForm.mobile,
                    code:this.editCodeForm.code,
                });
                this.$http.post(this.global.resetSmsCode,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        this.$message.success('修改成功');
                        this.editCodeDialog.dialogVisible=false;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            editCodeReset(formName) {
                this.$refs['editCodeForm'].resetFields();
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            var auth=JSON.parse(sessionStorage.getItem('auth'));
            this.auth=auth;
            this.getFilterCon();
            this.getTopSearchShow();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
                this.showFunModelCondition();
            });
        },
        computed:{
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading);
            },
        }
    }
</script>

<style scoped lang="scss">
    .rightBtn{
        margin-left:auto;
    }
    .largeBtn{
        min-width: 100px;
        height: 40px;
    }
</style>
