<template>
<!--    <hotel-all :pageInfo="pageInfo"></hotel-all>-->
	<hotel-man-index :pageInfo="pageInfo"></hotel-man-index>
</template>

<script>
import { onMounted } from 'vue';
    import HotelManAll from "./Operate";
    export default {
        name: "HotelManNew",
        components: {HotelManAll},
        data:()=>({
            pageInfo:{
                hotelState:'all',
                text:'所有',
                fromUrl:'HotelManAll'
            }
        }),
         mounted(){
         sessionStorage.setItem("defaultActiveId", 247);
    }
    }
   
</script>

<style scoped>

</style>