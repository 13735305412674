<template>
  <div>

    <el-dialog class="publishListDrawer" title="发布列表" :visible.sync="drawer" width="70%" :append-to-body="true">
      <div class="mainBox" style="height:100%;position:relative">
        <div class="tableBox">
          <el-table :data="tableData" v-loading="loading">
            <el-table-column prop="id" label="编号" width="60px"></el-table-column>
            <el-table-column prop="name" label="发布说明" width="230">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="module_type" label="发布模式" width="200px"></el-table-column>
            <el-table-column prop="issued_room" label="发布房间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createtimeText" label="发布时间" width="200"></el-table-column>
            <!-- <el-table-column prop="state3" label="已更新终端数" width="150px">
                            <template slot-scope="scope">
                                <span>{{scope.row.state3}}</span>
                            </template>
                        </el-table-column> -->
            <el-table-column prop="user_name" label="发布人" width="180"></el-table-column>
            <el-table-column prop="operate" label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="info(scope.row)">详情</el-button>
                <!--<el-button type="text" size="small" @click="partMeth(scope.row)">部分</el-button>-->
                <el-button v-if="scope.row.module_type!='all'" type="text" size="small" @click="addAll(scope.row)">全部</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <!-- 操作详情界面 -->
    <el-dialog title="详情" class="info" :visible.sync="infoVisible" width="40%" :append-to-body="true">
      <div v-loading="infoLoading">
        <el-row class="tableRow  tableRowHeader" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">状态</el-col>
          <el-col class="tableCell tableRightCell" :span="12">房间号</el-col>
        </el-row>
        <el-row class="tableRow" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">未执行({{infoData.diff.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.diff.join(',')" placement="top"> -->
              <span>{{infoData.diff.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
        <el-row class="tableRow" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">已获取({{infoData.state1.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.state1.join(',')" placement="top"> -->
              <span>{{infoData.state1.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
        <el-row class="tableRow" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">已执行({{infoData.state2.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.state2.join(',')" placement="top"> -->
              <span>{{infoData.state2.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
        <el-row class="tableRow tableRowFooter" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">已完成({{infoData.state3.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.state3.join(',')" placement="top"> -->
              <span>{{infoData.state3.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="infoVisible = false">取 消</el-button>
      </span>
    </el-dialog>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from "querystring";
import { editVersion, getFinishUpdateRooms, getVersion } from "@/api/visual.js";
export default {
  isActive: false,
  props: ["hotelContract", "database", "ipaddr"],
  data: () => ({
    direction: "rtl",
    tableData: [],
    details: [],
    dialogdetail: false,
    part: false,
    token: "",
    query: {},
    configData: {},
    loading: false,
    userName: "",
    curInfo: {
      module: "",
      id: "",
    },
    drawer: false,
    infoData: {
      diff: [],
      state1: [],
      state2: [],
      state3: [],
    },
    table: {
      total: 0,
      limit: 5,
      page: 1,
    },
    infoVisible: false,
    infoLoading: false,
  }),
  methods: {
    showDrawer(val) {
      this.drawer = val;
    },

    getHotelConfigInfo() {
      this.loading = true;
      var param = {
        database: this.database,
        page: this.table.page,
        size: this.table.limit,
      };
      getVersion(param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.tableData = res.data.data.data;
            this.table.total = res.data.data.total;
            this.userName = res.data.auth.user_name;
          }
          this.loading = false;
        })
        .catch(() => {
          // debugger;
        });
    },
    handleSizeChange(limit) {
      this.table.limit = limit;
      this.getHotelConfigInfo();
    },
    handleCurrentChange(page) {
      this.table.page = page;
      this.getHotelConfigInfo();
    },
    // 全部添加按钮
    addAll(item) {
      this.$confirm("是否确认全部执行操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const json = {
            id: item.service_code,
            module: "all",
            database: this.database,
            ipaddr: this.ipaddr,
          };
          this.loading = true;
          editVersion(json)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("执行成功");
                this.getHotelConfigInfo();
              } else {
                this.$message.warning(res.data.msg);
                this.loading = false;
              }
            })
            .catch((err) => {
              this.$message.warning("执行失败");
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 详情
    info(item) {
      const json = {
        id: item.service_code,
        database: this.database,
        ipaddr: this.ipaddr,
      };
      this.infoLoading = true;
      getFinishUpdateRooms(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.infoData = res.data.data;
          } else {
            this.$message.warning(res.data.msg);
          }
          this.infoLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
          this.infoLoading = false;
        });
      this.infoVisible = true;
    },
    publishPostMeth(info) {
      this.loading = true;
      var str = JSON.stringify({ info: info });
      this.$refs.choiceRoomDialog.cancelDialog(false);
      this.$refs.choiceRoomDialog.removeSessionData();
      var param = qs.stringify({
        token: this.token,
        hotelContract: this.query.hotelContract,
        data: str,
      });
      this.$http
        .post(this.global.uploadHotelVisualData, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("发布成功");
          } else {
            this.$message.error("发布失败");
          }
          this.loading = false;
        })
        .catch(() => {});
    },

    allMeth(data) {
      this.$confirm("确定更新所有房间吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var info = {
            intro: data.intro,
            module: "all",
            roomStr: "",
            uname: this.userNameProp,
            id: data.id,
          };
          this.publishPostMeth(info);
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.query = this.$route.query;
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null) {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
  },
};
</script>


<style scoped >
::v-deep :focus {
  outline: 0;
}

.btn {
  position: absolute;
  width: 100px;
  right: 20px;
}
.ybtn {
  margin-bottom: 40px;
}
.room {
  text-align: center;
  color: #3085fc;
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 27px;
  cursor: pointer;
  height: 30px;
}
.room:first-child {
  margin: 0;
}
.change {
  color: #3085fc;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.roomBox {
  overflow: hidden;
  padding-bottom: 90px;
}
.roomBox .el-col {
  padding: 20px 10px 10px 10px;
  text-align: center;
  float: left;
}
.el-col:nth-child(12n) {
  padding: 20px 0px 10px 20px;
  float: right;
}
.el-col:first-child {
  padding: 20px 20px 10px 0px;
}
.el-col:nth-child(12n + 1) {
  padding: 20px 20px 10px 0;
  float: left;
}
.mainBox {
  padding: 0 20px;
}
.drawerFooter {
  display: flex;
}
.btnBox {
  margin-left: auto;
}
.dialogdetailModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2001;
}
.boxBlack {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
::v-deep .el-dialog {
  height: 100%;
  max-height: calc(100%);
}
.info{
  height: 600px;
  top: 50%;
  transform: translateY(-50%);
}
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin-bottom: 10px;
}

.tableRow {
  font-size: 14px;
   min-height: 50px;
  border: 1px solid #ddd;
  border-bottom: none;
    display: flex;
  justify-content: center;
  align-items: center;
}
.tableRowHeader {
  background: #f8fbff;
  color: #606266;
}
.tableRowFooter {
  border-bottom: 1px solid #ddd;
}
.tableCell {
  text-align: center;
  line-height: 30px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}
.tableLeftCell {
    height: 100%;
    display: flex;
  justify-content: center;
  align-items: center;
}
.tableRightCell{
 border-left: 1px solid #ddd;
}
.tableCell span {
  display: block;
  width: 90%;

}
.el-tooltip.aaa {
  color: red;
  width: 400px !important;
}
</style>

