<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">广告管理</span>
                <span slot="third">订单管理</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <table-list class="table" :table="firstTable" @tableMeth="firstTableMeth" @page="getNewPage" @limit="getNewLimit" v-loading="firstTable.loading"></table-list>
            <table-list class="table" :table="secondTable" @tableMeth="secondTable" @page="getNewPage" @limit="getNewLimit" v-loading="secondTable.loading"></table-list>
            <table-list class="table" :table="thirdTable" @tableMeth="thirdTable" @page="getNewPage" @limit="getNewLimit" v-loading="thirdTable.loading"></table-list>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "AdManCusDetail",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/adManOrder'},
            },
            firstTable:{
                tableHeader:[
                    {prop:'orderNum',label:'订单号',width:''},
                    {prop:'customer',label:'客户',width:''},
                    {prop:'hotelNum',label:'投放酒店数量（间）',width:''},
                    {prop:'roomNum',label:'投放房间数量（间）',width:'110'},
                    {prop:'startTime',label:'开始时间',width:'110'},
                    {prop:'endTime',label:'结束时间',width:'110'},
                    {prop:'price',label:'价格（元）',width:''},
                ],
                tableList:[
                    {id:1,orderNum:'995434208435989446',customer:'客户',hotelNum:'200',roomNum:'300',startTime:'2020-05-01',endTime:'2020-05-06',price:'300'},
                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'hotelManAll',
                option:false,
                loading:false,
                pageShow:false,
                headerStyle:{
                    background:'#eef1f6',
                    color:'#606266'
                }
            },
            secondTable:{
                tableHeader:[
                    {prop:'orderNum',label:'序号',width:''},
                    {prop:'adName',label:'自定义广告位名称',width:''},
                    {prop:'kanliName',label:'自定义刊位名称',width:''},
                    {prop:'material',label:'素材内容',width:''},
                ],
                tableList:[
                    {id:1,orderNum:1,adName:'启动开机',kanliName:'启动视频广告',material:'图片'},
                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'hotelManAll',
                option:false,
                loading:false,
                pageShow:false,
                headerStyle:{
                    background:'#eef1f6',
                    color:'#606266'
                }
            },
            thirdTable:{
                tableHeader:[
                    {prop:'orderNum',label:'序号',width:''},
                    {prop:'adName',label:'自定义广告位名称',width:''},
                    {prop:'kanliName',label:'自定义刊位名称',width:''},
                    {prop:'material',label:'素材内容',width:''},
                ],
                tableList:[
                    {id:1,orderNum:1,adName:'启动开机',kanliName:'启动视频广告',material:'图片'},
                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'hotelManAll',
                option:false,
                loading:false,
                pageShow:false,
                headerStyle:{
                    background:'#eef1f6',
                    color:'#606266'
                }
            },
        }),
        methods:{
            getNewPage(){

            },
            getNewLimit(){

            },
            firstTableMeth(){

            },
            secondTableMeth(){

            },
            thirdTableMeth(){

            },

        }
    }
</script>

<style scoped lang="scss">

    .table{
        margin-bottom:60px;
    }
</style>
