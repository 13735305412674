<template>

  <div :class="['box',showData[0]?'':'box1']" :style="{position:content.positioning?content.positioning:'absolute',width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px',zIndex:style.zIndex}">
    <div class="borderBox"></div>
    <visiualButton class="hide" :showMore="showData[0].menuChild.length>0" @openmore='openUppage' @recoverydata="recoverydata" btnShow='picmore' @showmore='showMore'></visiualButton>
    <uploadSource ref="uploadSource" sourceType="pic" :size='content.sourceDesc?content.sourceDesc:""' @upsuccess='upSuccess'></uploadSource>
    <img v-if="showData[0]" :src="showData[0].fileList[0].source_url" alt="" :style="{width: '100%', height: '100%'}">
    <div class="title" v-if="content.showTextType != 2&&content.showTextType!=4">{{showData[0].title}}</div>
    <div class="title2" v-if="content.showTextType == 2">
      <div>{{showData[0].title}}</div>
      <div style="font-size:15px;margin-top:12px">{{showData[0].desc}}</div>
    </div>
    <div class="label" v-if="content.showTextType == 3">{{showData[0].labelValue}}</div>
    <el-dialog title="编辑层级图文信息" class="info" :visible.sync="infoVisible" width="40%" :append-to-body="true" :before-close='cannel'>
      <hierarchy1 ref="hierarchy1" :notOpt='content.notOpt' :noChangeTitle='content.noChangeTitle' :titleDataList="titleDataList" sourceType="pic" @defaulttitledata="gettitleData" :is_first='true' :isEdit='isEdit' style="margin-bottom:20px" :canUploadMore='content.canUploadMore'></hierarchy1>
      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button class="largeBtn" type="primary"  @click="save">保 存</el-button>
        <el-button class="cancelBtn" @click="cannel">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑子级素材内容" class="info" :visible.sync="moreVisible" width="1300px" :append-to-body="true">
      <div class='showMoreBox'>
        <hierarchyVideoBox v-for="(item,hp) in showData[0].menuChild" :key="hp+'hierarchyVideo'" :boxStyle='styleList[hp]' v-model="showData[0].menuChild[hp]" :isEdit='isEdit' @canrelease='canRelease' :historyData='history[hp]'></hierarchyVideoBox>
            <picBox :isEdit='isEdit' v-for="(item,p) in showData[0].picMenuChild" :key="p+'pic'" :boxStyle='picstyleList[p]' v-model="showData[0].picMenuChild[p]" :historyData='pichistory[p]' @canrelease='canRelease'></picBox>
        <div v-if='showDiv' class="spaceBox" :key="p+'pic'" v-for="(item,p) in picstyleList" :style='picstyleList[p].position1'>
          暂无数据
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="largeBtn" type="primary" @click="moreVisible=false">确认</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";
import uploadSource from "../common/uploadSource.vue";
import hierarchy1 from "../common/hierarchy1.vue";
import hierarchyVideoBox from "./hierarchyVideoBox.vue";
import picBox from "./picBox.vue";
export default {
  name: "hierarchyPicBox",
  components: {
    visiualButton,
    uploadSource,
    hierarchy1,
    hierarchyVideoBox,
      picBox,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => {},
    },
    historyData: {
      type: Array,
      default: () => {},
    },
    is_logo: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
 
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        this.content = JSON.parse(JSON.stringify(newVal));
        let obj = JSON.parse(JSON.stringify(this.content.position));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      content: {},
      showData: this.value,
      infoVisible: false,
      moreVisible: false,
      titleDataList: [],
      noPicList: [],
      noTitleList: [],
      noSortList: [],
      noPicList2: [],
      history: {},
        pichistory: {},
      picstyleList: [
        {
          name: "picBox",
          notOpt: true,
          sourceDesc: "140*210",
          position: { width: 140, height: 210, top: 0, left: 0 },
          position1: { width: "140px", height: "210px", top: 0, left: 0 },
        },
        {
          name: "picBox1",
          notOpt: true,
          sourceDesc: "140*210",
          position: { width: 140, height: 210, top: 220, left: 0 },
          position1: { width: "140px", height: "210px", top: "220px", left: 0 },
        },
      ],
      styleList: [
        {
          name: "hierarchyVideo1",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 0, left: 150 },
        },
        {
          name: "hierarchyVideo2",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 0, left: 370 },
        },
        {
          name: "hierarchyVideo3",
          notOpt: true,
          sourceDesc: "",
          sourceDesc: "",
          position: { width: 210, height: 210, top: 0, left: 590 },
        },
        {
          name: "hierarchyVideo4",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 0, left: 810 },
        },
        {
          name: "hierarchyVideo9",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 0, left: 1030 },
        },
        {
          name: "hierarchyVideo5",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 220, left: 150 },
        },
        {
          name: "hierarchyVideo6",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 220, left: 370 },
        },
        {
          name: "hierarchyVideo7",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 220, left: 590 },
        },
        {
          name: "hierarchyVideo8",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 220, left: 810 },
        },
        {
          name: "hierarchyVideo0",
          notOpt: true,
          sourceDesc: "",
          position: { width: 210, height: 210, top: 220, left: 1030 },
        },
      ],
       showDiv: false,
    };
  },
  mounted() {
    this.titleDataList = JSON.parse(JSON.stringify(this.showData));
    this.history = JSON.parse(JSON.stringify(this.showData[0].menuChild));
     if (
      this.showData[0].picMenuChild &&
      this.showData[0].picMenuChild.length > 0
    ) {
      this.pichistory = JSON.parse(
        JSON.stringify(this.showData[0].picMenuChild)
      );
    } else {
      this.showDiv = true;
    }
  },
  methods: {
    showMore() {
      this.moreVisible = true;
    },
    cannel() {
      this.titleDataList = JSON.parse(JSON.stringify(this.showData));
      this.infoVisible = false;
    },
    save() {
      if (this.noPicList.length != 0) {
        this.$message.warning("请将素材内容补充完整");
        return false;
      }
      if (this.noPicList2.length != 0) {
        this.$message.warning("请将素材内容补充完整");
        return false;
      }
      if (this.noTitleList.length != 0) {
        this.$message.warning("请将标题补充完整");
        return false;
      }
      if (this.noSortList.length != 0) {
        this.$message.warning("请将排序补充完整");
        return false;
      }
      this.showData = JSON.parse(JSON.stringify(this.titleDataList));

      let str1 = JSON.stringify(this.showData);
      let str2 = JSON.stringify(this.historyData);
      if (str1 != str2) {
        this.showData[0].update = true;
      }
      this.infoVisible = false;

      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    canRelease() {
      this.showData[0].update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    // 描述图文数据获取与验证列表
    gettitleData(data) {
      this.titleDataList = data;
      let childList = [];
      this.titleDataList.forEach((j) => {
        childList = [...j.child, ...childList];
      });
      this.noPicList = this.titleDataList.filter((i) => {
        if (i.fileList.length == 0) {
          return i;
        }
      });
      let childFileList = childList.filter((i) => {
        if (i.fileList.length == 0) {
          return i;
        }
      });
      this.noPicList = [...this.noPicList, ...childFileList];
      this.noTitleList = this.titleDataList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      let childTitleList = childList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      this.noTitleList = [...this.noTitleList, ...childTitleList];
      this.noSortList = this.titleDataList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      let childSortList = childList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      this.noSortList = [...this.noSortList, ...childSortList];
      this.noPicList2 = this.titleDataList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }
        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
      let childoutList = childList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }

        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
      this.noPicList2 = [...this.noPicList2, ...childoutList];
    },
    upSuccess(data) {
      this.showData.source_md5 = data.md5;
      this.showData.source_url = data.url;
      this.showData.update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
      // showData:this.value,
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
    },
    openUppage() {
      this.infoVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.spaceBox {
  position: absolute;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showMoreBox {
  position: relative;
  height: 400px;
}
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 4;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}
.title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 9;
  color: #fff;
  line-height: 57px;
  padding: 0 20px;
  box-sizing: border-box;
}
.title2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 76.6px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 9;
  color: #fff;
  font-size: 24px;
  padding: 5px 20px;
  box-sizing: border-box;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  border-radius: 0 0 20px 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
}
.hide {
  display: none;
}
</style>