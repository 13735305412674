import { render, staticRenderFns } from "./CoopType.vue?vue&type=template&id=c27159aa&scoped=true"
import script from "./CoopType.vue?vue&type=script&lang=js"
export * from "./CoopType.vue?vue&type=script&lang=js"
import style0 from "./CoopType.vue?vue&type=style&index=0&id=c27159aa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c27159aa",
  null
  
)

export default component.exports