<template>

  <div :class="['box',showData?'':'box1']" :style="style">
    <div class="borderBox"></div>
    <visiualButton class="hide" btnShow='picmore' @openmore='openUppage' @recoverydata="recoverydata"></visiualButton>
    <uploadSource ref="uploadSource" sourceType="video" @upsuccess='upSuccess'></uploadSource>
    <div class="player" v-if="flag"><i class="el-icon-video-play" @click="playMedia"></i></div>
    <div class="player paused" v-if="!flag"><i class="el-icon-video-pause" @click="playMedia"></i></div>
    <video :id="content.name" @click="playMedia" :controls="false" v-if="showData" :src="showData.source_url" alt="" :style="{width: '100%', height: '100%'}"></video>

    <el-dialog title="编辑开机视频" class="info" :visible.sync="infoVisible" width="500px" :append-to-body="true" :before-close='cannel'>
      <el-form ref="form" :model="form" :rules="rules" class="formBox">
        <el-form-item label="是否播放" prop='open'>
          <el-switch :disabled="isEdit" style="height:40px" v-model="form.play" active-color="#658BFF" inactive-color="rgba(191, 191, 191, 1)" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="是否播放" prop='open'>
          <div class="sourceBox">

            <video :controls="false" :src="form.source_url" alt="" :style="{width: '350x', height: '150px'}"></video>
            <el-button :disabled="isEdit" class="" type="primary" @click="openUp">更换素材</el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button class="" type="primary" @click="save('form')">保 存</el-button>
        <el-button class="cancelBtn" @click="cannel">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";
import uploadSource from "../common/uploadSource.vue";
export default {
  name: "videoBox",
  components: {
    visiualButton,
    uploadSource,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        this.content = JSON.parse(JSON.stringify(newVal));
        let obj = JSON.parse(JSON.stringify(this.content.position));
        for (var i in obj) {
          this.style[i] = obj[i] + "px";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      content: {},
      showData: this.value,
      flag: true,
      video: null,
      infoVisible: false,
      form: {
        play: 1,
        source_url: "",
        source_md5: "",
      },
      rules: {
        source_url: [
          { required: true, message: "请上传开机视频", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.video = document.getElementById(this.content.name);
    this.video.addEventListener("ended", this.flagReset);
    this.form = {
      play: String(this.showData.play),
      source_url: String(this.showData.source_url),
      source_md5: String(this.showData.source_md5),
    };
  },

  beforeDestroy() {
    this.video.removeEventListener("ended", this.flagReset);
  },
  methods: {
    cannel() {
      this.form.play = this.showData.play;
      this.form.source_url = this.showData.source_url;
      this.form.source_md5 = this.showData.source_md5;
      this.showData.update = false;
      this.infoVisible = false;
    },
    save() {
      this.$refs["form"].validate((valid, obj) => {
        if (valid) {
          this.showData.play = this.form.play;
          this.showData.source_url = this.form.source_url;
          this.showData.source_md5 = this.form.source_md5;
          this.showData.update = true;
          this.infoVisible = false;
          this.$emit("change", this.showData);
          this.$emit("canrelease");
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    flagReset() {
      this.flag = true;
    },
    playMedia() {
      let video = document.getElementById(this.content.name);
      if (video.paused) {
        video.play();
        this.flag = false;
      } else {
        video.pause();
        this.flag = true;
      }
    },
    upSuccess(data) {
      this.form.source_md5 = data.md5;
      this.form.source_url = data.url;
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    openUp() {
      this.$refs.uploadSource.dialogVisible = true;
    },
    openUppage() {
      this.infoVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 2;
  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: none;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
.player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 40px;
  font-size: 80px;
  line-height: 75px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  z-index: 9;
}
.paused {
  display: none;
  z-index: 9;
}
.box:hover .paused {
  display: block;
  z-index: 9;
}
.sourceBox {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
</style>