import request from '../../api/request';
import qs from "qs"

//   获取发布列表
export function getSendMessage(param) {
    return request({
        url: `v2/Sendmessage/getSendMessage`,
        method: 'post',
        data: qs.stringify(param),
    });
}



//   插播发布接口
export function saveSendMessage(param) {
    return request({
        url: `v2/Sendmessage/saveSendMessage`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  上架房间或者下架房间接口
export function saveMessageRoom(param) {
    return request({
        url: `v2/Sendmessage/saveMessageRoom`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  发布追踪
export function getMessageRoom(param) {
    return request({
        url: `v2/Sendmessage/getMessageRoom`,
        method: 'post',
        data: qs.stringify(param),
    });
}