<template>
  <div style="height: 100%;;width:100%">
    <div :id="id" ref="chart1" tyle="width:100%;height:100%" class=" echarts chart chartBox"></div>
  </div>
</template>
<script>
import echarts from "echarts";
export default {
  name: "lineCard",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userInfo: {},
      hotel_contract: null,
      yAxis: {},
      xAxisList: [],
      seriesList: [],
      echartsObj: {},
      date: [],
    };
  },
  methods: {
    // 获取时间区间
    getCurrentTime(type) {
      this.type = type;
    
      this.getLineList();
    },
    // 获取图表数据
    getLineList() {
        this.xAxisList = [
        "2021-10-20",
        "2021-10-21",
        "2021-10-22",
        "2021-10-23",
        "2021-10-24",
      ];
      this.seriesList = [203, 360, 1023, 242, 900];
      this.getYaxis("", this.seriesList);
      return 
      let json = {
        hotel_contract: this.hotel_contract,
        start_date: this.date[0],
        end_date: this.date[1],
      };
      getHotelROL(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (
              res.data.data &&
              res.data.data.rows &&
              res.data.data.rows.length > 0
            ) {
              let arr = res.data.data.rows;
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = arr.map((i) => {
                return i.dateValue;
              });
            } else {
              this.xAxisList = [];
              this.seriesList = [];
            }
            this.getYaxis("", this.seriesList);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },

    // 图表渲染
    chartRender() {
      this.echartsObj = echarts.init(this.$refs.chart1);
      var option = {
        tooltip: {
          trigger: "axis",
          padding: 10,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: 40,
          bottom: 30,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,.4)",
            },
          },
          axisLabel: {
            color: "#769bde",
            fontSize: "13px",
            margin: 15,
          },
          splitLine: {
            show: false,
          },
          data: this.xAxisList,
        },
        yAxis: this.yAxis,
        series: [
          {
            data: this.seriesList,
            type: "line",
            label: {
              show: true,
              formatter: (param) => {
                return param.data
              },
              //  + "%";
              color: "#fff",
              fontSize: "14px",
            },
            lineStyle: {
              color: "#00ffff",
              width: 1,
            },
            itemStyle: {
              color: "#00ffff",
            },
            areaStyle: {
              color: {
                type: "linear",
                y: 0,
                x: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(13,142,151, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(2,11,50, 0.3)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      this.echartsObj.setOption(option);
      this.watchSize();
    },
    // y轴配置
    getYaxis(unit, list) {
      let arr = [...list];
      arr = arr.sort((a, b) => a - b);
      var minint = Math.floor(arr[0] / 10);
      var minval = minint * 10;
      var maxint = Math.ceil(arr[arr.length - 1] / 9.5);
      var maxval = maxint * 10;
      this.yAxis = {
        show: false,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#D5D9E0",
          },
        },
        axisLabel: {
          formatter: `{value}${unit}`,
          color: "#A2ACBB",
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        min: minval,
        max: maxval,
        splitNumber: 5,
        interval: (maxval - minval) / 5,
      };
      this.chartRender();
    },
    // 监听时间
    watchSize() {
      this.echartsObj.resize();
    },
  },
  mounted() {
  
    this.getCurrentTime("seven");
    window.addEventListener("resize", this.watchSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.watchSize);
  },
};
</script>

<style scoped lang="scss">
#chart,
.chart,
.chart2 {
 width: 100%;
  height:100%;
  overflow: hidden;
}
</style>
