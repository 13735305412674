<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second" v-if="!query">系统设置</span>
        <span slot="third" v-if="!query"> 标签管理</span>
        <span slot="fourth" v-if="!query"> 标签记录</span>
        <span slot="fiveth" v-if="!query"> 打标签</span>
        <span slot="second"> 打标签</span>
      </top-bread>
    </div>
    <el-card style="margin-top: 15px">
      <el-button type="primary" class="btn" @click="cancel" style="margin-bottom: 10px">取消</el-button>
      <div class="title" style="margin: 15px 0">第一步，选择酒店</div>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-select v-model="form.hotel_brand" multiple placeholder="集团品牌" class="hotelBrand fullSelect" filterable collapse-tags clearable>
            <el-option v-for="item in topAttr.hotelGroupList" :key="item.id" :label="item.groupname" :value="item.groupname">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.province" multiple placeholder="省份" @change="provinceChange" collapse-tags clearable class="fullSelect" filterable>
            <el-option v-for="item in topAttr.province" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.city" placeholder="城市" @change="cityChange($event,'city')" clearable class=" fullSelect" filterable collapse-tags>
            <el-option v-for="item in topAttr.city" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.country" placeholder="区县" @change="countyChange($event,'county')" clearable class=" fullSelect" filterable>
            <el-option v-for="item in topAttr.country" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.channelCode" placeholder="请输入渠道商编码"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :span="7">
          <el-input v-model="form.hotelContract" placeholder="请输入合同号"></el-input>
        </el-col>
        <el-col :span="9">
          <el-input v-model="form.hotelName" placeholder="请输入酒店名称关键词"></el-input>
        </el-col>
        <el-col :span="7">
          <el-button type="primary" class="btn" @click="searchHotel">搜索</el-button>
          <el-button class="btn" @click="reset">重置</el-button>
        </el-col>
      </el-row>
      <div class="contentAllBox">
        <ul class="leftlist" v-loading="listLoading,allLoading">
          <li class="left_text">酒店
            <span class="all_select">
              <el-checkbox :checked="allLabel" @change="allCheck">全选</el-checkbox>
              <el-checkbox :checked="reverse" @change="reverseCheck" style="margin-right: 20px">反选</el-checkbox>
            </span>
          </li>
          <div class="leftHotelBox">
            <template v-for="(item, index) in info">
              <li :key="index" style="margin-top: 15px;padding: 0 20px">
                <input type="checkbox" name="checkbox" :checked="item.select" class="left_checkbox" @click="item.select=!item.select,leftPush(item)" />
                <label class="choose_text">{{ item.hotelName }} </label>
              </li>
            </template>
          </div>
        </ul>
        <!-- 右框 -->
        <ul class="rightlist" v-loading="allLoading">
          <li class="right_text">
            <span>已选 &nbsp;
              <span style="color: #3688F8" v-if="!searchType">{{ newInfo.length }} </span>
              <span style="color: #3688F8" v-if="searchType">{{ SearchInfolength }} </span>
              &nbsp;家酒店</span>
            <span style="float: right">
              <el-link :underline="false" @click="clearHotel" type="primary">清空</el-link>
            </span>
          </li>
          <div style="width: 100%;">
            <el-input placeholder="酒店名称关键词搜索" class="hotelSearch" v-model="searchNewInfoText" style="margin-left: 10px">
              <el-button slot="append" icon="el-icon-search" @click="searchNewInfo"></el-button>
            </el-input>
            <el-link type="primary" @click="resetNewInfo" :underline="false" style="margin-left: 20px">重置
            </el-link>
          </div>
          <div class="rightHotelBox">
            <li class="list_right checkedLabelBox">
              <template v-for="(item, index) in newInfo">
                <el-tooltip class="item" :key="index" effect="dark" placement="top" :content="item.hotelName">
                  <div class="checkedLabel checkedLabel1">{{ item.hotelName }} <span class="delBtn">| <i class="el-icon-close" @click="rightPush(item)"></i></span></div>
                </el-tooltip>
              </template>
            </li>
          </div>
        </ul>
      </div>

    </el-card>
    <el-card style="margin-top: 10px">
      <div class="title" style="margin-bottom: 15px">第二步，选择标签</div>
      <label-show :label="labeladd" ref="labelshow" @senddata="getLabel" :funArr="funArr"></label-show>
      <div class="bottom_btn_box">
        <el-button type="primary" class="btn" style="margin-left: 2vw" @click="open">确定</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { labelToManyHotels, searchHotelsFromEs } from "@/api/label.js";
import qs from "querystring";

export default {
  name: "",
  props: [""],
  data: () => ({
    auth:null,
    query: null,
    funArr: [],
    allData: {},
    searchType: false,
    SearchInfolength: 0,
    allLoading: false,
    reverse: false,
    listLoading: false,
    searchNewInfoText: "",
    token: "",
    hotel_contract: "",
    labeladd: false,
    bread: {
      level: 5,
    },
    labelValue: {},
    hotel_brand: "",
    form: {
      hotel_brand: [],
      citycode: [],
      province: "",
      city: "",
      country: "",
      provinceLabel: [],
      countyLabel: [],
      cityLabel: [],
      channelCode: "",
      hotelContract: "",
      hotelName: "",
    },
    region: {
      prov_code: "",
      city_code: "",
      country: "",
    },
    topAttr: {
      hotelGroupList: "",
      province: "",
      city: "",
      country: "",
    },
    info: [],
    newInfo: [], //右框数据
    newInfoLength: 0,
    allLabel: false,
    labelNum: "",
    hotel_name: "",
    searchInfo: [],
    showNew: [],
    searchHis: [],
    groupValue: "",
    groupNum: "",
  }),
  methods: {
    //   获取权限传入labelshow组件
    getGroupLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.groupLevel, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.curId = res.data.data.pid[0];
            }
            resolve(res.data.data.pid[0]);
          });
      });
    },
    getTreeData() {
      this.$http
        .get(this.global.getUserRuleLists, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var treeData = res.data.data;
            this.allData = treeData;
            console.log("123");
            this.allData.forEach((item, index) => {
              var menuId = sessionStorage.getItem("defaultActiveId");
              if (item.parent == menuId) {
                this.funArr.push(item.text);
              }
            });
          }
        });
    },
    //拿酒店品牌
    getOption() {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getOptions, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var optionData = res.data.data;
              this.topAttr.hotelGroupList = optionData.hotelGroupList;
            }
            resolve();
          });
      });
    },
    clearHotel() {
      this.newInfo = [];
      this.searchHis = [];
      this.newInfoLength = 0;
      this.allLabel = false;
      for (var i = 0; i < this.info.length; i++) {
        this.info[i].select = false;
      }
    },
    getLabel(tag_name, tag_num, group_name, group_num) {
      this.labelValue = tag_name;
      this.labelNum = tag_num;
      this.groupValue = group_name;
      this.groupNum = group_num;
    },
    provinceChange(val) {
      if (val.length === 1) {
        this.region.prov_code = val.value;
      } else {
        this.region.prov_code = "";
      }
      this.form.citycode = [];
      this.form.provinceLabel = [];
      this.form.city = "";
      this.form.country = "";
      this.form.cityLabel = "";
      this.form.countyLabel = "";
      val.forEach((item, index) => {
        this.form.citycode.push(item.value);
        this.form.provinceLabel.push(item.label);
      });
      if (val.length === 1) {
        this.getCity(val[0].value);
        this.region.prov_code = val[0].value;
      } else {
        this.topAttr.city = [];
        this.topAttr.country = [];
      }
    },
    cityChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.prov_code;
        this.form.cityLabel = "";
        this.form.countyLabel = "";
        this.form.country = "";
      } else {
        this.region.city_code = value;
        this.form.citycode = value;
        this.form.country = "";
        this.form.cityLabel = label;
      }
      this.getCountry();
    },
    countyChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.city_code;
        this.form.countyLabel = "";
      } else {
        this.region.citycode = value;
        this.form.citycode = value;
        this.form.countyLabel = label;
      }
    },
    getProvince() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getProvince, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.province = res.data.data;
            }
            resolve();
          });
      });
    },
    getCity(prov_code) {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.city = res.data.data;
            }
            resolve();
          });
      });
    },
    getCountry() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getDistricts, {
            params: {
              token: this.token,
              prov_code: this.region.prov_code,
              city_code: this.region.city_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.country = res.data.data;
            }
            resolve();
          });
      });
    },
    provinceDataInit(val) {
      if (val) {
        if (val.length === 1) {
          this.region.prov_code = val.value;
        } else {
          this.region.prov_code = "";
        }
        val.forEach((item, index) => {
          this.form.citycode.push(item.value);
          this.form.provinceLabel.push(item.label);
        });
        if (val.length === 1) {
          this.region.prov_code = val[0].value;
          this.getCity(val[0].value)
            .then(() => {
              this.form.city = this.topSearchSave.city;
              this.region.city_code = this.form.city.value;
              this.getCountry();
            })
            .then(() => {
              this.form.country = this.topSearchSave.country;
            });
        } else {
          this.topAttr.city = [];
          this.topAttr.country = [];
        }
      }
    },
    getDefaultData(topSearchSave) {
      var form = this.form;
      this.getProvince().then(() => {
        form.province = topSearchSave.province;
        this.provinceDataInit(form.province);
      });
    },
    //左往右，两种情况
    leftPush(item) {
      if (item.select == true) {
        this.newInfo.push(item);
        this.searchHis.push(item);
        this.newInfoLength++;
      } else {
        for (var i = 0; i < this.newInfo.length; i++) {
          if (this.newInfo[i].hotelName == item.hotelName) {
            for (var j = 0; j < this.searchHis.length; j++) {
              if (this.searchHis[j].hotelName == item.hotelName) {
                this.searchHis.splice(j, 1);
              }
            }
            this.newInfo.splice(i, 1);
          }
        }
        this.newInfoLength = this.newInfoLength - 1;
      }
      if (this.newInfoLength == 0) {
        this.allLabel = false;
      } else if (this.newInfo.length == this.info.length) {
        this.allLabel = true;
      }
    },
    // 右边移除数据
    rightPush(item) {
      this.SearchInfolength = this.SearchInfolength - 1;
      for (var i = 0; i < this.newInfo.length; i++) {
        if (this.newInfo[i].hotelName == item.hotelName) {
          for (var j = 0; j < this.searchHis.length; j++) {
            if (this.searchHis[j].hotelName == item.hotelName) {
              this.searchHis.splice(j, 1);
            }
          }
          this.newInfo.splice(i, 1);
        }
      }
      for (var i = 0; i < this.info.length; i++) {
        if (this.info[i].hotelName == item.hotelName) {
          this.info[i].select = false;
        }
      }
      this.newInfoLength = this.newInfoLength - 1;
      if (this.newInfoLength == 0) {
        this.allLabel = false;
      } else if (this.newInfo.length == this.info.length) {
        this.allLabel = true;
      }
    },
    //全选
    allCheck() {
      this.allLoading = true;
      this.allLabel = !this.allLabel;
      if (this.allLabel == true) {
        this.newInfo = [];
        this.searchHis = [];
        this.newInfoLength = 0;
        for (var i = 0; i < this.info.length; i++) {
          this.info[i].select = true;
          this.leftPush(this.info[i]);
        }
      } else {
        for (var i = 0; i < this.info.length; i++) {
          this.info[i].select = false;
          this.leftPush(this.info[i]);
        }
      }
      this.allLoading = false;
    },
    //反选
    reverseCheck() {
      this.reverse = !this.reverse;
      for (var i = 0; i < this.info.length; i++) {
        if (this.info[i].select == true) {
          this.info[i].select = false;
          this.leftPush(this.info[i]);
          for (var j = 0; j < this.newInfo.length; j++) {
            if (this.info[i].hotelName == this.newInfo[j].hotelName) {
              for (var k = 0; k < this.searchHis.length; k++) {
                if (this.searchHis[k].hotelName == this.newInfo[j].hotelName) {
                  this.searchHis.splice(k, 1);
                }
              }
              this.newInfo.splice(i, 1);
              this.newInfoLength = this.newInfoLength - 1;
            }
          }
        } else {
          this.info[i].select = true;
          this.leftPush(this.info[i]);
        }
      }
    },
    cancel() {
      if (this.query) {
        this.$router.push({ path: `/apaas/sysLabelHistory` });
      } else {
        this.$router.push({ path: `./sysLabelHistory` });
      }
    },
    open() {
      this.$confirm("确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submit();
        })
        .catch(() => {});
    },
    submit() {
      var eventId = 0;
      var hotelNum = 0;
      this.$refs.labelshow.sendCheckList();
      this.hotel_contract = "";
      this.hotel_name = "";
      for (var i = 0; i < this.newInfo.length; i++) {
        this.hotel_contract += this.newInfo[i].hotelContract + ",";
        this.hotel_name += this.newInfo[i].hotelName + ",";
        hotelNum++;
      }
      this.hotel_contract = this.hotel_contract.substring(
        0,
        this.hotel_contract.length - 1
      );
      this.hotel_name = this.hotel_name.substring(
        0,
        this.hotel_name.length - 1
      );
      //判断事件ID
      if (hotelNum == 0 || this.labelNum == 0) {
        this.$message.error("请完善信息");
        //    单店打标签
      } else if (hotelNum == 1 && this.groupNum == 0) {
        eventId = 1;
        //    单店打标签组
      } else if (hotelNum == 1 && this.groupNum > 0) {
        eventId = 2;
        //    批量打标签
      } else if (hotelNum > 1 && this.groupNum == 0) {
        eventId = 3;
        //    批量打标签组
      } else if (hotelNum > 1 && this.groupNum > 0) {
        eventId = 4;
      }
      const json = {
        token: this.token,
        hotel_contract: this.hotel_contract,
        label: this.labelValue,
        type: 1,
        event: eventId,
        group: this.groupValue,
        hotel_name: this.hotel_name,
         user_name: this.auth.user_name,

      };
      if(this.query){
        json.isApaas=1;
      }
      var param = qs.stringify(json);
      if (eventId != 0) {
        labelToManyHotels(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.hotel_contract = "";
              eventId = 0;
              hotelNum = 0;
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.cancel();
            } else {
              this.$confirm(`${res.data.msg}`, "操作失败", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {})
                .catch(() => {});
            }
          })
          .catch((err) => {
            this.hotel_contract = "";
            eventId = 0;
            hotelNum = 0;
          });
      }
    },
    searchHotel() {
      this.newInfo = [];
      this.searchHis = [];
      this.newInfoLength = 0;
      this.$refs.labelshow.sendCheckList();
      this.listLoading = true;
      var hotel_brand = "";
      for (var i = 0; i < this.form.hotel_brand.length; i++) {
        hotel_brand += this.form.hotel_brand[i] + ",";
      }
      hotel_brand = hotel_brand.substring(0, hotel_brand.length - 1);
      this.hotel_brand = hotel_brand;
      const json = {
        hotel_contract: this.form.hotelContract,
        brand: this.hotel_brand,
        province: this.form.provinceLabel,
        city: this.form.cityLabel,
        district: this.form.countyLabel,
        hotel_name: this.form.hotelName,
        channelCode: this.form.channelCode,
      };
      if (
        json.hotel_contract == "" &&
        json.brand == "" &&
        json.province == "" &&
        json.city == "" &&
        json.district == "" &&
        json.hotel_name == "" &&
        json.channelCode == ""
      ) {
        this.$message.error("请输入筛选条件");
      } else {
        var param = qs.stringify(json);
        searchHotelsFromEs(param)
          .then((res) => {
            if (res.data.code == 100000) {
              var hotel = res.data.data.hotels;
              var select = { select: false };
              for (var i = 0; i < hotel.length; i++) {
                hotel[i].select = false;
              }
              this.info = hotel;
              this.listLoading = false;
            }
          })
          .catch((err) => {});
      }
    },
    reset() {
      this.form.hotelContract = "";
      this.form.provinceLabel = "";
      this.form.cityLabel = "";
      this.form.countyLabel = "";
      this.form.hotel_brand = "";
      this.form.province = "";
      this.form.city = "";
      this.form.country = "";
      this.form.channelCode = "";
      this.form.hotelContract = "";
      this.form.hotelName = "";
      this.info = [];
      this.newInfo = [];
      this.searchHis = [];
      this.newInfoLength = 0;
    },
    //右侧筛选
    searchNewInfo() {
      this.showNew = [];
      if (this.searchNewInfoText == "") {
      } else {
        for (var i = 0; i < this.newInfo.length; i++) {
          if (this.newInfo[i].hotelName.indexOf(this.searchNewInfoText) != -1) {
            this.allLabel = false;
            this.showNew.push(this.newInfo[i]);
          } else {
          }
        }
        this.newInfo = this.showNew;
        this.searchType = true;
        this.SearchInfolength = this.newInfo.length;
      }
    },
    resetNewInfo() {
      this.searchType = false;
      this.allLabel = true;
      this.newInfo = this.searchHis;
      this.newInfoLength = this.newInfo.length;
      for (var i = 0; i < this.searchHis.length; i++) {
        for (var j = 0; j < this.info.length; j++) {
          if (this.searchHis[i].hotelName == this.info[j].hotelName) {
            this.info[j].select = true;
          }
        }
      }
      this.searchNewInfoText = "";
    },
  },

  created() {},
  mounted() {
    this.token = sessionStorage.getItem("token");
     this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getOption();
    this.getProvince();
    this.query = sessionStorage.getItem("apaasData");
    if (this.query) {
      this.bread.level = 3;
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      this.getGroupLevel().then((res) => {
        this.getTreeData(res);
      });
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.contentAllBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowBox {
  margin-top: 20px;
}

.el-table {
  margin-top: 15px;
}

.row-bg {
  padding: 10px 0;
  background-color: rgb(220, 223, 230);
}

.btn {
  width: 100px;
  height: 40px;
}

.left_text {
  height: 50px;
  line-height: 30px;
  border-bottom: 1px solid rgb(220, 223, 230);
  padding: 10px !important;
  margin: auto;
}

.right_text {
  height: 50px;
  line-height: 30px;
  border-bottom: 1px solid rgb(220, 223, 230);
  padding: 10px !important;
  margin: auto;
}

.contentAllBox li {
  padding: 0 20px;
  list-style: none;
  box-sizing: border-box;
}

.scrollbar .el-scrollbar__wrap {
  overflow: hidden;
  overflow-y: visible;
}

.leftlist {
  width: 43%;
  border: 1px solid rgb(220, 223, 230);
  list-style: none;
  overflow: hidden;
}

.leftHotelBox,
.rightHotelBox {
  height: 400px;
  overflow: hidden;
  overflow-y: visible;
}

.leftHotelBox {
  height: 460px;
}

.rightlist {
  width: 55%;
  border: 1px solid rgb(220, 223, 230);
  list-style: none;
  overflow: hidden;
  overflow-y: visible;
}

.content {
  font-size: 30px;
  margin: 0 20px;
}

.li {
  padding: 5px;
  box-sizing: border-box;
}

.list_right {
  display: flex;
  float: left;
}

.del {
  color: #3688f8;
  margin-left: auto;
  margin-right: 10px;
}

.bottom_btn_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hotelSearch {
  width: 25vw;
  margin: 10px 0;
}

body .el-scrollbar__wrap {
  overflow-x: hidden;
}

.left_checkbox {
  border-radius: 2px;
  margin-right: 5px;
}

.all_select {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delBtn {
  position: absolute;
  top: 0;
  right: 10px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delBtn span {
  width: 1px;
  height: 18px;
  background: #3688f8;
}

.checkedLabelBox {
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.checkedLabel {
  width: calc(100% / 3 - 2%);
  text-align: center;
  height: 32px;
  line-height: 32px;
  padding: 0 43px 0 15px;
  margin: 0 1% 1%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  border: 1px solid #3688f8;
  color: #3688f8;
}

.checkedLabel1 {
  background: #f1f8ff;
}

.choose_text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.choose_text_left {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
</style>