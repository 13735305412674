import request from './request';

//  验收酒店上传附件
export function uploadEnclosureUrlsWaitForCheck(param) {
    return request({
        url: `v1/hotel/uploadEnclosureUrlsWaitForCheck`,
        method: 'post',
        data: param,
    });
}
//  验收酒店上传附件查看
export function getEnclosureUrls(param) {
    return request({
        url: `v1/hotel/getEnclosureUrls`,
        method: 'post',
        data: param,
    });
}

//  验收检测
export function getReasonWaitForCheck(param) {
    return request({
        url: `v1/hotel/getReasonWaitForCheck`,
        method: 'post',
        data: param,
    });
}


//  生成酒店预览单
export function generateHotelPreview(param) {
    return request({
        url: `v1/hotel/generateHotelCheck`,
        method: 'post',
        data: param,
    });
}
//  获取酒店预览单
export function getHotelListByCheckListId(param) {
    return request({
        url: `v1/hotel/getHotelListByCheckListId?` + param,
        method: 'get',
    });
}
//  发起验收审核工作流
export function sponsorCheckFlow(param) {
    return request({
        url: `v1/hotel/sponsorCheckFlow`,
        method: 'post',
        data: param,
    });
}
//  审核页面获取验收酒店列表
export function getHotelAcceptance(param) {
    return request({
        url: `v1/review/view?` + param,
        method: 'get',
    });
}

//  验收酒店审核确认
export function passHotelAcceptance(param) {
    return request({
        url: `v1/review/checkAction`,
        method: 'post',
        data: param,
    });
}

//  酒店授权接口
// 酒店影视授权书 查询 
export function searchCertificate(param) {
    return request({
        url: `v1/Certificate/searchCertificate`,
        method: 'post',
        data: param,
    });
}
// 腾讯影视授权书上传
export function uploadCertificate(param) {
    return request({
        url: `v1/Certificate/uploadCertificate`,
        method: 'post',
        data: param,
    });
}
// 酒店影视授权书关闭
export function closeCertificate(param) {
    return request({
        url: `v1/Certificate/closeCertificate`,
        method: 'post',
        data: param,
    });
}
// 酒店影视授权书打开
export function openCertificate(param) {
    return request({
        url: `v1/Certificate/openCertificate`,
        method: 'post',
        data: param,
    });
}

// 酒店影视授权书生成
export function produceCertificate(param) {
    return request({
        url: `v1/Certificate/produceCertificate`,
        method: 'post',
        data: param,
    });
}
// 酒店影视授权记录列表查询
export function getCertificateList(param) {
    return request({
        url: `v1/Certificatelog/list`,
        method: 'post',
        data: param,
    });
}
// 酒店影视授权记录列表导出
export function exportCertificate(param) {
    return request({
        url: `v1/Certificate/exportCertificate`,
        method: 'post',
        responseType: "blob",
        data: param,
    });
    batchUploadTxCertificate
}
// 酒店影视授权批量腾讯
export function createTxCertificate(param) {
    return request({
        url: `v1/Certificate/batchUploadTxCertificate`,
        method: 'post',
        data: param,
    });
}
//  获取酒店列表
export function getSearchMt(param) {
    return request({
        url: `v2/hotel/getSearchMt`,
        method: 'post',
        data: param,
    });
}