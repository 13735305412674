<template>
  <div>
    <!-- 数据表单 -->
    <el-drawer title="新建标签" size="100%" :modal="false" :visible.sync="drawerShow" direction="rtl" :before-close="handleClose" :destroy-on-close="true">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标签类型" prop="labelType">
          <el-radio-group v-model="ruleForm.labelType" @change="TypeChange" :disabled="edit">
            <el-radio label="0">标签</el-radio>
            <el-radio label="1">标签组</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.labelType==0" label="标签名称" prop="name">
          <el-input :readonly="readOnly||ruleForm.belonType==1" v-model="ruleForm.name" placeholder="请填写标签名称"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.labelType==1" label="标签组名称" prop="name">
          <el-input :readonly="readOnly||ruleForm.belonType==1" v-model="ruleForm.name" placeholder="请填写标签组名称"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" prop="belonType">
          <template v-if="!this.edit||ruleForm.belonType!=1">
            <el-select :disabled="readOnly||ruleForm.belonType==1" style="width:100%" v-model="ruleForm.belonType" placeholder="请选择所属分类" @change="belonTypeChange">
              <template v-for="(item,index) in labelTypeList">
                <el-option v-if="item.id != 1" :key="index" :label="item.classify_name" :value="item.id"></el-option>
              </template>
            </el-select>
          </template>
          <template v-if="this.edit&&ruleForm.belonType==1">
            <el-select :disabled="readOnly||ruleForm.belonType==1" style="width:100%" v-model="ruleForm.belonType" placeholder="请选择所属分类" @change="belonTypeChange">
              <el-option v-for="(item,index) in labelTypeList" :key="index" :label="item.classify_name" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="标签性质" prop="labelNature">
          <el-checkbox-group :disabled="readOnly||ruleForm.belonType==1" v-model="ruleForm.labelNature">
            <el-checkbox label="1">业务标签</el-checkbox>
            <el-checkbox label="2">阵地标签</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.labelType==1" label="关联标签" prop="labelList">
          <div class="checkLabelBox">
            <div class="checkedBox">
              <div v-if="!readOnly || !ruleForm.belonType==1" class="checkedLabel addCheckedLabel" @click="toCheckLabel"> 添加 <i class="el-icon-plus"></i></div>
              <template v-for="(item,ind) in ruleForm.labelList">
                <el-tooltip class="item" :key="ind" effect="dark" :content="item.label_name" placement="top">
                  <div class="checkedLabel checkedLabel1">{{item.label_name}} <span class="delBtn"><span></span><i class="el-icon-close" @click="delCheckedLabel(item,ind)"></i></span></div>
                </el-tooltip>
              </template>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="desc">
          <el-input :readonly="readOnly||ruleForm.belonType==1" type="textarea" resize="none" placeholder="请输入备注信息" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <el-form-item style="margin-top:40px;" v-if="!readOnly">
          <div class="formBtn" v-if="ruleForm.belonType !=1">
            <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 数据表单 -->
    <!-- 关联标签 -->
    <el-dialog title="选择关联标签" :visible.sync="checkLabelVisible" width="30%">
      <el-form :model="checkForm">
        <el-form-item prop="checklabelType" style="margin:0">
          <div class="toCheckBox">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 5px 0;"></div>
            <el-checkbox-group v-model="checkedLabelList" @change="handleCheckedCitiesChange">
              <template v-for="item in toCheckLabelList">
                <el-checkbox v-if="!ruleForm.labelListId.includes(item.id)" :label="item.id" :key="item.id">{{item.label_name}}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="trueCheckLabel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 关联标签  -->
  </div>
</template>
<script>
// 汉字验证cc
let chineseWord = function (rule, value, callback) {
  if (/^[\u4E00-\u9FA5A-Za-z0-9]*$/.test(value) == false) {
    callback(new Error("标签及标签组名称只支持汉字、字母与数字"));
  } else {
    callback();
  }
};
import qs from "querystring";
import { getClassifyList, getLabelList, setAndAddLabel } from "@/api/label.js";
export default {
  name: "labelList",
  data() {
    return {
      auth: null,
      checkAll: false,
      checkedLabelList: [],
      labelListOptions: [],
      toCheckLabelList: [],
      isIndeterminate: true,
      checkForm: {
        checklabelType: "",
      },
      checkLabelVisible: false,
      drawerShow: false,
      ruleForm: {
        name: "",
        admin_name: "",
        belonType: "",
        labelNature: [],
        labelType: "0",
        desc: "",
        labelList: [],
        labelListId: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入标签或标签组名称",
            trigger: "blur",
          },
          {
            validator: chineseWord,
            trigger: "blur",
          },
        ],
        belonType: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
        labelNature: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个标签性质",
            trigger: "change",
          },
        ],
        labelType: [
          { required: true, message: "请选择标签类型", trigger: "change" },
        ],
        labelList: [
          { required: true, message: "请选择关联标签", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写备注信息", trigger: "blur" }],
      },
      labelTypeList: [],
      edit: false,
      readOnly: false,
    };
  },
  mounted() {
    this.getData();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
  methods: {
    // 获取页面信息
    getData() {
      const json = {
        page: 1,
        limit: 99999,
      };
      var param = qs.stringify(json);
      getClassifyList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.labelTypeList = res.data.data.data;
          }
        })
        .catch((err) => {});
    },
    // 打开界面
    open(item, noedit) {
      this.drawerShow = true;
      if (item) {
        this.edit = true;
        if (noedit) {
          this.readOnly = false;
        } else {
          this.readOnly = true;
        }
        this.ruleForm.id = item.id;
        this.ruleForm.name = item.label_name;
        this.ruleForm.admin_name = item.admin_name;
        this.ruleForm.belonType = item.classify_id;
        var arr = String(item.label_type).split(",");
        this.ruleForm.labelNature = arr;
        this.ruleForm.labelType = String(item.is_group);
        this.ruleForm.desc = item.remark;
        if (item.child) {
          this.ruleForm.labelList = item.child;
          this.ruleForm.labelListId = item.child.map((i) => {
            return i.id;
          });
          this.checkedLabelList = this.ruleForm.labelListId;
        }
      }
      this.ruleForm.id = item.id;
    },
    // 取消操作
    cancelForm() {
      this.ruleForm.id = "";
      this.ruleForm.name = "";
      this.ruleForm.belonType = "";
      this.ruleForm.labelNature = [];
      this.ruleForm.labelType = "0";
      this.ruleForm.desc = "";
      this.ruleForm.labelList = [];
      this.ruleForm.labelListId = [];
      this.edit = false;
    },
    // 确认选择标签
    trueCheckLabel() {
      this.ruleForm.labelList = [];
      this.ruleForm.labelListId = [];
      this.checkLabelVisible = false;
      this.toCheckLabelList.forEach((i, n) => {
        if (this.checkedLabelList.includes(i.id)) {
          this.ruleForm.labelList.push(JSON.parse(JSON.stringify(i)));
          this.ruleForm.labelListId.push(i.id);
        }
      });
    },
    // 删除已选标签
    delCheckedLabel(item, index) {
      this.ruleForm.labelList.splice(index, 1);
      this.ruleForm.labelListId.splice(index, 1);
      this.checkedLabelList.forEach((i, n) => {
        if (item.id == i) {
          this.checkedLabelList.splice(n, 1);
        }
      });
      if (this.checkedLabelList.length == 0) {
        this.checkAll = false;
        this.isIndeterminate = true;
      }
    },
    // 类别变化
    belonTypeChange() {
      this.checkedLabelList = [];
      this.ruleForm.labelList = [];
      this.ruleForm.labelListId = [];
      this.checkAll = false;
      this.isIndeterminate = true;
    },
    // 全选
    handleCheckAllChange(val) {
      this.checkedLabelList = val ? this.labelListOptions : [];
      this.isIndeterminate = false;
    },
    // 单选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.toCheckLabelList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.toCheckLabelList.length;
    },
    // 去选择标签
    toCheckLabel() {
      if (this.ruleForm.belonType == "") {
        this.$message.warning("请选择所属分类");
      } else {
        const json = {
          page: 1,
          limit: 99999,
          classify_id: this.ruleForm.belonType,
          is_group: 0,
        };
        var param = qs.stringify(json);
        getLabelList(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.toCheckLabelList = res.data.data.data;
              this.labelListOptions = this.toCheckLabelList.map((i) => {
                return i.id;
              });
              this.checkLabelVisible = true;
            }
          })
          .catch((err) => {});
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认操作?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (
                this.ruleForm.labelListId.length == 0 &&
                this.ruleForm.labelType == 1
              ) {
                this.$message.warning("关联标签不能为空");
              } else {
                var str = this.ruleForm.labelListId.join(",");
                let json = {
                  is_group: this.ruleForm.labelType,
                  label_name: this.ruleForm.name,
                  classify_id: this.ruleForm.belonType,
                  label_type: this.ruleForm.labelNature.join(","),
                  remark: this.ruleForm.desc,
                  relation_ids: str,
                  id: this.ruleForm.id,
                };
                if (json.id) {
                  json.admin_name = this.ruleForm.admin_name;
                  json.update_admin_name = this.auth.user_name;
                } else {
                  json.admin_name = this.auth.user_name;
                }
                var param = qs.stringify(json);
                setAndAddLabel(param)
                  .then((res) => {
                    if (res.data.code == 100000) {
                      this.$message.success("操作成功");
                      this.$refs.ruleForm.resetFields();
                      this.cancelForm();
                      this.drawerShow = false;
                      this.$emit("loadpagelist");
                    } else {
                      this.$message.warning(res.data.msg);
                    }
                  })
                  .catch((err) => {});
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    // 类型切换
    TypeChange() {
      var n = this.ruleForm.labelType;
      this.$refs.ruleForm.resetFields();
      this.ruleForm.name = "";
      this.ruleForm.belonType = "";
      this.ruleForm.labelType = n;
      this.ruleForm.labelNature = [];
      this.ruleForm.desc = "";
      this.ruleForm.labelList = [];
    },
    // 关闭弹窗
    handleClose(done) {
      this.cancelForm();
      done();
    },
  },
};
</script>
<style scoped>
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  background-color: #fff;
  padding: 20px;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
.el-radio-group {
  height: 40px;
  line-height: 40px;
}
.el-radio {
  height: 40px;
  line-height: 40px;
}
.checkLabelBox {
  height: 200px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.checkedBox {
  margin-top: 10px;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.checkedLabel {
  width: calc(100% / 3 - 2%);
  text-align: center;
  height: 32px;
  line-height: 32px;
  padding: 0 43px 0 15px;
  margin: 0 1% 1%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  border: 1px solid #3688f8;
  color: #3688f8;
}
.addCheckedLabel {
  padding: 0 29px;
}
.checkedLabel1 {
  background: #f1f8ff;
}
.toCheckBox {
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}
.toCheckBox .el-checkbox {
  height: 40px;
  line-height: 40px;
  padding: 0;
  width: 100%;
}
.formBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.el-button {
  width: 100px;
  height: 40px;
}
.delBtn {
  position: absolute;
  top: 0;
  right: 10px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delBtn span {
  width: 1px;
  height: 18px;
  background: #3688f8;
}
.el-icon-close {
  margin-left: 3px;
  font-size: 18px;
}
</style>