<template>
  <div>
    <div class="outTopBox">
      <!-- 面包屑+筛选-->
      <top-bread :bread="bread">
        <span slot="second">合作伙伴管理</span>
        <span slot="third">数据修正管理</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <!-- 面包屑+筛选-->
    <!-- 列表展示 -->
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">数据修正列表</div>
      <div class="btnRow">
        <el-button class="largeBtn" @click="openResetConfigure" type="primary">数据修正</el-button>
      </div>
      <el-table class="tableBox" ref="singleTable" :row-key="getRowKey" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="tableLoading" @selection-change="selectionChange">
        <el-table-column type="selection" width="55" :reserve-selection="true" :selectable="selectable"></el-table-column>
        <el-table-column prop="company_name" label="渠道商名称" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type='text' @click="openContent(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="task_status_remarks" label="状态" show-overflow-tooltip></el-table-column>
        <el-table-column prop="current_name" label="修正人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="settlement_year" label="合同年份" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="修正时间" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 列表展示 -->
    <!-- 配置详情 -->
    <el-drawer :modal="false" :destroy-on-close="true" :title="drawerTitle" :visible.sync="dataResetVisible" size="100%" :before-close="closeDrawer" v-loading="drawerLoading">
      <el-card class="box-card">
        <el-form :model="dataResetForm" :rules="rules" ref="dataResetForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="合同年份" prop="year">
            <el-select style="width:320px" v-model="dataResetForm.year" placeholder="请选择年份" @change="yearChange" :disabled="showDetails">
              <el-option v-for="(item,index) in yearList" :key="index" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="渠道商" prop="channel">
            <el-select style="width:320px" v-model="dataResetForm.channel" @change="channelChange" placeholder="请选择渠道商" :disabled="showDetails" filterable>
              <el-option v-for="item in channelList" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="修正起止时间" prop="month_day">
            <el-date-picker style="width:320px" :disabled="!dataResetForm.year||showDetails" v-model="dataResetForm.month_day" :clearable='false' type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card class="box-card" v-show="dataResetForm.channel&&dataResetForm.year">
        <coop-tab-rate ref="cooprate" @nonextmonth="getNextMonth" @noContract="getNextContract" :id="dataResetForm.channel" hotelOrPartner="partner" :dataReset="true"></coop-tab-rate>
      </el-card>
      <div class="btnRowDrawer">
        <el-button class="largeBtn" @click="resetData" type="primary" v-if="drawerTitle == '数据修正'">确 定</el-button>
      </div>
    </el-drawer>
    <!-- 配置详情 -->
  </div>
</template>
<script>
import {
  getAllPartner,
  DataCorrectionSearch,
  addDataCorrectionTask,
} from "@/api/dataCenter.js";
import qs from "querystring";
let year = "";
export default {
  name: "coopDataReset",
  data() {
    return {
      tableLoading: false,
      drawerLoading: false,
      token: "",
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      topSearchShow: {
        keyword: true,
        keywordPlaceholder: "请输入渠道商名称关键词",
      },
      keyword: "",
      tableList: [{ name: 11 }],
      table: {
        limit: 5,
        page: 1,
        total: 0,
      },
      loading: false,
      selectIdArr: [],
      dataResetVisible: false,
      drawerTitle: "数据修正",
      dataResetForm: {
        year: "",
        month_day: [],
        channel: "",
        channelId: "",
      },
      rules: {
        year: [{ required: true, message: "请选择年份", trigger: "blur" }],
        month_day: [
          { required: true, message: "请选择起止时间", trigger: "blur" },
        ],
        channel: [{ required: true, message: "请选择渠道商", trigger: "blur" }],
      },
      yearList: [],
      channelList: [],
      noNextMonth: false,
      noContract: false,
      showDetails: false,
      presentYear: "",
    };
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getYear();
    this.getTableData();
    this.getPartnerData();
  },
  methods: {
    // 获取19年后年份
    getYear() {
      var prDate = new Date();
      this.presentYear = prDate.getFullYear(); //当前年份
      for (var i = 2019; i <= this.presentYear; i++) {
        var year = {
          value: i,
        };
        this.yearList.push(year);
      }
    },
    // 获取所有渠道商
    getPartnerData() {
      let json = {};
      var param = qs.stringify(json);
      getAllPartner(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.channelList = res.data.data;
            for (var i = 0; i < this.channelList.length; i++) {
              this.channelList[i].id = this.channelList[i].id.toString();
            }
          } else {
          }
        })
        .catch((err) => {});
    },
    // 获取表格数据
    getTableData() {
      this.tableLoading = true;
      let json = {
        name: this.keyword,
        page: this.table.page,
        per_page: this.table.limit,
      };
      var param = qs.stringify(json);
      DataCorrectionSearch(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableList = res.data.data.items;
            // 后台传的修正时间是时间戳，需要进行转换
            for (var i = 0; i < this.tableList.length; i++) {
              this.tableList[i].create_time = this.format(
                this.tableList[i].create_time * 1000
              );
            }
            this.tableLoading = false;
            this.table.total = res.data.data.total;
          } else {
          }
        })
        .catch((err) => {});
    },
    // 时间添0
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    // 时间戳转换
    format(createTime) {
      var time = new Date(createTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
    // 获取下个月数据
    getNextMonth(val) {
      this.noNextMonth = val;
    },
    getNextContract(val) {
      this.noContract = val;
    },
    // 选择年份
    yearChange() {
      year = this.dataResetForm.year;
      // 默认展示本年1号到昨日的信息
      var yesday = new Date().getTime() - 24 * 60 * 60 * 1000;
      yesday = this.format(yesday).substring(0, 10);
      var monthValue = [this.presentYear + "-01-01", yesday];
      this.$set(this.dataResetForm, "month_day", monthValue);
      if (this.dataResetForm.year && this.dataResetForm.channel) {
        this.$refs.cooprate.getDataResetShow(
          this.dataResetForm.channel,
          this.dataResetForm.year
        );
      }
    },
    // 选择渠道商
    channelChange() {
      if (this.dataResetForm.year && this.dataResetForm.channel) {
        this.$refs.cooprate.getDataResetShow(
          this.dataResetForm.channel,
          this.dataResetForm.year
        );
      }
    },
    //   关闭侧边界面
    closeDrawer() {
      this.dataResetVisible = false;
      this.drawerTitle = "数据修正";
      this.dataResetForm.year = "";
      this.dataResetForm.channel = "";
      this.dataResetForm.month_day = [];
    },
    // 提示信息
    confirmMsg(msg) {
      return new Promise((res, ret) => {
        this.$confirm(msg, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            res();
          })
          .catch(() => {});
      });
    },

    //   打开数据修正界面
    openResetConfigure() {
      this.showDetails = false;
      this.dataResetVisible = true;
    },

    // 打开详情
    openContent(value) {
      this.drawerLoading = true;
      this.drawerTitle = "记录详情";
      this.showDetails = true;
      this.dataResetVisible = true;
      this.dataResetForm.year = value.settlement_year.toString();
      this.dataResetForm.channel = value.partner_id.toString();
      var startTime = value.start_time.slice(0, 10);
      var endTime = value.end_time.slice(0, 10);
      this.dataResetForm.month_day = [startTime, endTime];
      var that = this;
      this.$nextTick(function () {
        that.$refs.cooprate.getDataResetShow(
        that.dataResetForm.channel,
        that.dataResetForm.year
        );
        that.drawerLoading = false;
      });
    },

    // 确认数据修正
    resetData() {
      if (!this.noNextMonth) {
        this.$message.warning(
          "当前渠道商存在新的分成配置,无法进行数据修正操作"
        );
      } else if (!this.noContract) {
        this.$message.warning("当前渠道商无合同信息,无法进行数据修正操作");
      } else {
        this.$refs.dataResetForm.validate((valid) => {
          if (valid) {
            this.confirmMsg("是否确认修正数据?").then((res) => {
              let json = {
                partner_id: this.dataResetForm.channel,
                settlement_year: this.dataResetForm.year,
                start_time: this.dataResetForm.month_day[0],
                end_time: this.dataResetForm.month_day[1],
              };
              var param = qs.stringify(json);
              addDataCorrectionTask(param)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success(res.data.msg);
                    this.dataResetVisible = false;
                    this.getTableData();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {});
            });
          } else {
            this.$message.warning("请将数据补充完整");
            return false;
          }
        });
      }
    },
    //   筛选
    getCondition(val) {
      this.keyword = val.keyword;
      this.getTableData();
    },
    // 展开筛选框
    getFoldMeth(val) {
      this.fold = val;
    },
    // 获取每一行的key值
    getRowKey(row) {
      return row.id;
    },
    // 分页
    handleSizeChange(val) {
      this.table.limit = val;
    },
    handleCurrentChange(val) {
      this.table.page = val;
    },
    // 表格选中
    selectionChange(val) {
      this.selectIdArr = val;
    },
    // 列表选中禁用禁用
    selectable(row, index) {
      //   if (row.classify_id == 1) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      return true;
    },
  },
};
</script>
<style scoped>
.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnRowDrawer {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.largeBtn {
  min-width: 100px;
  height: 40px;
}
</style>