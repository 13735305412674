<template>
  <div class="inputColorChange">
    <el-card class="box-card" v-if="showSearchProp">
      <section class="positionSelect" v-if="topDataAssetSearchShow.movieSelect">
        <div class="flexAlignBox">
          <div class="positionSelectTest">电影媒资</div>
          <ul class="filmWidth">
            <li class="filterWidthBtn"
                    :class="{'activeLi': filmActiveLi.includes(item)}"
                    v-for="(item, index) in filmLists"
                    :key="index"
                    @click="filmActiveLiSelect(item)"
            >{{ item.name }}</li>
          </ul>
        </div>
        <section class="rightBtn">
          <div class="occupy"></div>
          <el-button type="warning"
                     @click="exportMeth('export','导出')"
                     size="medium"
                     class="largeBtn exportBtn" :loading="exportBtn.loading">{{exportBtn.text}}</el-button>
        </section>
      </section>
      <section class="positionSelect" v-if="topDataAssetSearchShow.shoppingSelect">
        <div class="positionSelectTest">商城版本</div>
        <ul>
          <li  class="filterWidthBtn"
                  :class="{'activeLi': shoppingctiveLi.includes(item)}"
                  v-for="(item, index) in shoppingMallLists"
                  :key="index"
                  @click="shoppingMallActiveSelect(item)"
          >{{ item.name }}</li>
        </ul>
      </section>
      <section class="positionSelect" v-if="topDataAssetSearchShow.adSelect">
        <div class="positionSelectTest">广告版本</div>
        <ul>
          <li  class="filterWidthBtn"
                  :class="{'activeLi': activeLiAD.includes(item)}"
                  v-for="(item, index) in adLists"
                  :key="index"
                  @click="activeSelect(item)"
          >{{ item.name }}</li>
        </ul>
      </section>

      <section class="positionSelect flexSpaceBox" v-if="topDataAssetSearchShow.dateSelect">
        <div class="dateBox">
          <div class="positionSelectTest">日期选择</div>
          <ul>
            <li class="pickerData noPadding">
              <el-date-picker
                      v-model="form.date"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerIsToday?pickerOptionsToday:pickerOptionsYesterday"
                      @change="dateChange"
              ></el-date-picker>
            </li>
          </ul>
        </div>
      </section>
      <section class="positionSelect" v-if="topDataAssetSearchShow.positionSelect && hideFlag">
        <div class="positionSelectTest">选择阵地</div>
        <div class="positionSelectMeth">
          <el-select
                  class="try"
                  v-model="form.province"
                  multiple
                  placeholder="省份"
                  @change="mulSearchMeth($event,'province')"
                  clearable
          >
            <el-option
                    v-for="item in topAttr.province"
                    :key="item.value"
                    :label="item.label"
                    :value="{value:item.value,label:item.label}"
            ></el-option>
          </el-select>
          <el-select
                  v-model="form.city"
                  placeholder="城市"
                  @change="cityChange($event,'city')"
                  clearable
                  class="fullSelect try"
          >
            <el-option
                    v-for="item in topAttr.city"
                    :key="item.value"
                    :label="item.label"
                    :value="{value:item.value,label:item.label}"
            ></el-option>
          </el-select>
          <el-select
                  class="try"
                  v-model="form.country"
                  placeholder="区县"
                  @change="countyChange($event,'county')"
                  clearable
          >
            <el-option
                    v-for="item in topAttr.country"
                    :key="item.value"
                    :label="item.label"
                    :value="{value:item.value,label:item.label}"
            ></el-option>
          </el-select>
        </div>
      </section>
    </el-card>
    <el-card class="box-card">
      <div class="topSearch positionSelect" >
        <div class="dateBox">
          <ul>
            <li
                    v-for="(item,index) in futureLists"
                    :key="index"
                    :class="{'activeLi': curIndex == index}"
                    @click="futureActiveSelect(index,item)"
            >{{item}}</li>
          </ul>
        </div>
        <div class="rightBox">
          <ul class="groupFilter" v-if="showRateBtn">
            <li @click="quickRateSearchMeth('year')" :class="quickRateCurActive('year')">同比</li>
            <li @click="quickRateSearchMeth('chain')" :class="quickRateCurActive('chain')" class="noMarginRight">环比</li>
          </ul>
          <ul class="groupFilter" v-if="hotelNatureBtn">
            <li @click="quickNatureSearchMeth('self')" :class="quickNatureCurActive('self')">自有</li>
            <li @click="quickNatureSearchMeth('other')" :class="quickNatureCurActive('other')" class="noMarginRight">聚屏</li>
          </ul>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "TopDataAssetSearch",
  props: {
    topDataAssetSearchShow: {},
    pickerIsToday: {
      default: false,
    },
    fromUrl: {
      default: "",
    },
    showSearchProp:{},
    exportBtn:{},
    showRateBtn:{},
    asOfYesterday:{},
    hotelNatureBtn:{},
  },
  data: () => ({
    futureActiveLi: [],
    curIndex: 0, //日期激活的颜色
    // futureLists: ["昨日",'截止昨日','本周','本月', "近一月", "近一年"],
    futureLists: [],
    activeLiAD: [], // AD激活时的颜色
    adLists: [],
    shoppingctiveLi: [], // 商城激活时的颜色
    shoppingMallLists: [],
    filmActiveLi: [],
    filmLists: [],
    topAttr: {
      province: "",
      city: "",
      country: "",

      partner_id: [],
    },
    pickerOptionsToday: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
      disabledDate(time) {
        return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
        // return time.getTime() > Date.now() ;//如果没有后面的-8.64e6就是不可以选择今天的
      },
    },
    pickerOptionsYesterday: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
      disabledDate(time) {
        // return time.getTime() > Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
        // return time.getTime() > Date.now() ;//如果没有后面的-8.64e6就是不可以选择今天的
        var times = Date.now() - 24 * 60 * 60 * 1000;
        return time.getTime() > times;
      },
    },
    value1: "",
    value2: "",
    region: {
      prov_code: "",
      city_code: "",
      country: "",
    },
    form: {
      date: "",
      province: "",
      city: "",
      country: "",
      startTime: "",
      endTime: "",
      futureActiveLi: "",
      activeLiAD: [],
      shoppingctiveLi: [],
      filmActiveLi: [],
      isChain:0,  //1 同比
      isYear:0,   //1 环比
      isAccumulative:0,  //截至昨日
      screenType:'self',
    },
    token: "",
    colorOne: "",
    hideFlag:false,
    curDate:{
      yesDay:'',
      curDay:'',
      startWeek:'',
      endWeek:'',
      startMonth:'',
      endMonth:'',
      startYear:'',
      endYear:'',
    },
    quickRateCur:[],
    quickDateCur:'',
    quickNatureCur:['self'],
    filterConArr:[],
  }),
  methods: {
    //获取当前日期的前一个月时间
    getPrevDta() {
      var nowdate = new Date(new Date() - 24 * 60 * 60 * 1000);
      //获取系统前一个月的时间
      var y = nowdate.getFullYear(); // 年份
      var m = nowdate.getMonth() + 1; // 上一个月
      var d = nowdate.getDate(); // 日期
      if (m < 10) {
        m = "0" + m;
      }
      if (d < 10) {
        d = "0" + d;
      }
      this.form.startTime = y + "-" + m + "-" + d;
      this.form.endTime = y + "-" + m + "-" + d;
    },

    //获取当前日期的前一个月时间
    getPrevMonth() {
      var nowdate = new Date(new Date() - 24 * 60 * 60 * 1000);
      //获取系统前一个月的时间
      nowdate.setMonth(nowdate.getMonth() - 1);
      var y = nowdate.getFullYear(); // 年份
      var m = nowdate.getMonth() + 1; // 上一个月
      var m1 = nowdate.getMonth() + 2;
      var d = nowdate.getDate(); // 日期
      if (m < 10) {
        m = "0" + m;
      }
      if (m1 < 10) {
        m1 = "0" + m1;
      }
      if (d < 10) {
        d = "0" + d;
      }
      this.form.startTime = y + "-" + m + "-" + d;
      this.form.endTime = y + "-" + m1 + "-" + d;
    },
    //获取当前日期的近一年时间
    getPrevYear() {
      var nowdate = new Date(new Date() - 24 * 60 * 60 * 1000);
      //获取系统前一个月的时间
      nowdate.setFullYear(nowdate.getFullYear() - 1);
      var y = nowdate.getFullYear(); // 年份
      var y1 = nowdate.getFullYear() + 1; // 年份
      var m = nowdate.getMonth() + 1; // 上一个月
      var d = nowdate.getDate(); // 日期
      if (m < 10) {
        m = "0" + m;
      }
      if (d < 10) {
        d = "0" + d;
      }
      this.form.startTime = y + "-" + m + "-" + d;
      this.form.endTime = y1 + "-" + m + "-" + d;
    },
    filmActiveLiSelect(e) {
      let arrIndex = this.filmActiveLi.indexOf(e);
      if (arrIndex > -1) {
        this.filmActiveLi.splice(arrIndex, 1);
      } else {
        this.filmActiveLi.push(e);
      }
      this.getFIlterConArr(e.name);
      this.form.filmActiveLi = this.filmActiveLi;
      this.filterConMeth();
      // this.searchMeth();
    },
    shoppingMallActiveSelect(e) {
      let arrIndex = this.shoppingctiveLi.indexOf(e);
      if (arrIndex > -1) {
        this.shoppingctiveLi.splice(arrIndex, 1);
      } else {
        this.shoppingctiveLi.push(e);
      }
      this.getFIlterConArr(e.name);
      this.form.shoppingctiveLi = this.shoppingctiveLi;
      this.filterConMeth();
      // this.searchMeth();
    },
    futureActiveSelect(e, item) {
      // this.curIndex = e;
      console.log('e',e);
      this.form.date = "";
      this.isAccumulative=0;
      if (e != this.curIndex) {
        this.curIndex = e;
      } else {
        this.curIndex = -1;
        this.form.startTime = "";
        this.form.endTime = "";
        this.searchMeth();
        return
      }

      if (
        (item == "昨日" && item == "近一月" && item == "近一年") ||
        item == "近一年"
      ) {
        this.getPrevYear();
        this.form.futureActiveLi = item;
      } else if ((item == "昨日" && item == "近一月") || item == "近一月") {
        this.getPrevMonth(new Date());
        this.form.futureActiveLi = item;
      } else if (item == "昨日") {
        this.form.isAccumulative='';
        this.getPrevDta();
        this.form.futureActiveLi = item;
      } else if(item==='本周'){
        this.form.isAccumulative='';
        this.compareDate(this.curDate.startWeek,this.curDate.yesDay);
      } else if(item==='本月'){
        this.form.isAccumulative='';
        this.compareDate(this.curDate.startMonth,this.curDate.yesDay);
      }else if(item==='本年'){
        this.form.isAccumulative='';
        this.form.startTime=this.curDate.startYear;
        this.form.endTime=this.curDate.endYear;
      }else if(item==='截止昨日'){
        this.form.isAccumulative=1;
        this.form.startTime = "";
        this.form.endTime = "";
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
        this.curIndex = -1;
      }
      this.$emit('showTime',this.form.startTime,this.form.endTime,item);
      this.searchMeth('filter');
    },
    compareDate(start,end){
      var startTrans=new Date(start.replace(/-/g,'\/'));
      var endTrans=new Date(end.replace(/-/g,'\/'));
      if(startTrans>endTrans){
        this.form.startTime=start;
        this.form.endTime=start;
      }else{
        this.form.startTime=start;
        this.form.endTime=end;
      }
    },
    getFIlterConArr(val){
      var filterIndex=this.filterConArr.indexOf(val);
      if(filterIndex!==-1){
        this.filterConArr.splice(filterIndex, 1);
      }else{
        this.filterConArr.push(val);
      }
    },
    //  当前激活
    activeSelect(e) {
      let arrIndex = this.activeLiAD.indexOf(e);
      if (arrIndex > -1) {
        this.activeLiAD.splice(arrIndex, 1);
      } else {
        this.activeLiAD.push(e);
      }
      this.getFIlterConArr(e.name);
      this.form.activeLiAD = this.activeLiAD;
      this.filterConMeth();

      // this.searchMeth();
    },
    dateChange(val) {
      if (val) {
        this.curIndex = -1;
        this.form.startTime = val[0];
        this.form.endTime = val[1];
        this.form.dateRange = val;
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
      this.setDefaultQuickDateCur(this.form.startTime);
      this.searchMeth();
    },
    cityChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.prov_code;
        this.form.cityLabel = "";
        this.form.countyLabel = "";
      } else {
        this.region.city_code = value;
        this.form.citycode = value;
        this.form.country = "";
        this.form.cityLabel = label;
      }
      this.getCountry();
      this.searchMeth();
    },
    countyChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.city_code;
        this.form.countyLabel = "";
      } else {
        this.region.citycode = value;
        this.form.citycode = value;
        this.form.countyLabel = label;
      }
      this.searchMeth();
    },
    getProvince() {
      this.$http
        .get(this.global.getProvince, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.topAttr.province = res.data.data;
          }
        });
    },
    getCity(prov_code) {
      this.$http
        .get(this.global.getCitys, {
          params: {
            token: this.token,
            prov_code: prov_code,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.topAttr.city = res.data.data;
          }
        });
    },
    getCountry() {
      this.$http
        .get(this.global.getDistricts, {
          params: {
            token: this.token,
            prov_code: this.region.prov_code,
            city_code: this.region.city_code,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.topAttr.country = res.data.data;
          }
        });
    },
    searchMeth(type) {
      this.$emit("conditionDataAsset", this.form,type);
    },
    mulSearchMeth(val, type) {
      if (type === "province") {
        if (val.length === 1) {
          this.region.prov_code = val.value;
        } else {
          this.region.prov_code = "";
        }
        this.form.citycode = [];
        this.form.provinceLabel = [];
        this.form.city = "";
        this.form.country = "";
        val.forEach((item, index) => {
          this.form.citycode.push(item.value);
          this.form.provinceLabel.push(item.label);
        });
        if (val.length === 1) {
          this.getCity(val[0].value);
          this.region.prov_code = val[0].value;
        } else {
          this.topAttr.city = [];
          this.topAttr.country = [];
        }
      }
      if (type === "hotel_use_state") {
        this.form.hotel_use_state = [];
        this.form.hotel_use_state_label = [];
        val.forEach((item, index) => {
          this.form.hotel_use_state.push(item.value);
          this.form.hotel_use_state_label.push(item.name);
        });
      }
      if (type === "coopPartnerType") {
        this.form.belongPartnerCode = "";
        this.form.allotPartnerCode = "";
      }
      if (type === "belongPartnerCode") {
      }
      if (type === "allotPartnerCode") {
      }
      this.$emit("conditionDataAsset", this.form);
    },
    allSelect() {
      let param = qs.stringify({
        token: sessionStorage.getItem("token"),
      });
      this.$http
        .post(this.global.getSelect, param)
        .then((res) => {
          let { data } = res;
          if (data.code == 100000) {
            data.data.forEach((item) => {
              if (item.type == 1) {
                this.adLists.push(item);
              } else if (item.type == 2) {
                this.shoppingMallLists.push(item);
              } else if (item.type == 3) {
                this.filmLists.push(item);
              }
            });
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    getYesDay(){
      var time=(new Date).getTime()-24*60*60*1000;
      var yesterday=new Date(time);
      var month=yesterday.getMonth()+1;
      var day=yesterday.getDate();
      this.curDate.yesDay=yesterday.getFullYear() + "-" + (month>9 ? (month) : "0" + (month)) + "-" +(yesterday.getDate()> 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
    },
    add0(m){
      return m<10?'0'+m:m
    },
    getCurWeek(){
      var startStop = new Array();
      //获取当前时间
      var currentDate = new Date();
      //返回date是一周中的某一天
      var week = currentDate.getDay();
      //返回date是一个月中的某一天
      var month = currentDate.getDate();
      //一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      //减去的天数
      var minusDay = week != 0 ? week - 1 : 6;
      //alert(minusDay);
      //本周 周一
      var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
      //本周 周日
      var sunday = new Date(monday.getTime() + (6 * millisecond));
      var sy = monday.getFullYear();
      var sm = monday.getMonth() + 1;
      var sd = monday.getDate();
      var ey = sunday.getFullYear();
      var em = sunday.getMonth() + 1;
      var ed = sunday.getDate();
      // var s = sy+'-'+add0(sm)+'-'+add0(sd)+' 00:00:00';//开始
      // var e = ey+'-'+add0(em)+'-'+add0(ed)+' 23:59:59';//结束
      var s = sy+'-'+this.add0(sm)+'-'+this.add0(sd);//开始
      var e = ey+'-'+this.add0(em)+'-'+this.add0(ed);//结束
      this.curDate.startWeek=s;
      this.curDate.endWeek=this.curDate.yesDay;
    },
    getCurMonth(type,months){
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      if (Math.abs(months) > 12) {
        months = months % 12;
      };
      if (months != 0) {
        if (month + months > 12) {
          year++;
          month = (month + months) % 12;
        } else if (month + months < 1) {
          year--;
          month = 12 + month + months;
        } else {
          month = month + months;
        };
      };
      month = month < 10 ? "0" + month: month;
      var date = d.getDate();
      var firstday = year + "-" + month + "-" + "01";
      var lastday = "";
      if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
        lastday = year + "-" + month + "-" + 31;
      } else if (month == "02") {
        if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
          lastday = year + "-" + month + "-" + 29;
        } else {
          lastday = year + "-" + month + "-" + 28;
        };
      } else {
        lastday = year + "-" + month + "-" + 30;
      };
      var day = "";
      if (type == "s") {
        day = firstday;
      } else {
        // day = lastday;
        day=this.curDate.curDay;
      };
      return day;
    },
    getCurYear(type,dates){
      var dd = new Date();
      var n = dates || 0;
      var year = dd.getFullYear() + Number(n);
      if (type == "s") {
        var day = year + "-01-01";
      };
      if (type == "e") {
        // var day = year + "-12-31";
        var day=this.curDate.curDay;
      };
      if (!type) {
        var day = year + "-01-01/" + year + "-12-31";
      };
      return day;
    },
    quickRateSearchMeth(type){
      this.form.isYear=0;
      this.form.isChain=0;
      var index=this.quickRateCur.indexOf(type);
      if(index!==-1){
        this.quickRateCur.splice(index,1);
      }else{
        this.quickRateCur.push(type);
      }
      this.quickRateCur.forEach((item,index)=>{
        if(item==='year'){
          this.form.isYear=1;
        }else if(item==='chain'){
          this.form.isChain=1;
        }else{
          this.form.isYear=0;
          this.form.isChain=0;
        }
      });
      this.$emit('getRateFilter',this.form.isYear,this.form.isChain);
      this.searchMeth('filter');
    },
    quickNatureSearchMeth(type){
      var index=this.quickNatureCur.indexOf(type);
      if(index!==-1){
        this.quickNatureCur.splice(index,1);
      }else{
        this.quickNatureCur.push(type);
      }
      if(this.quickNatureCur.length>1){
        this.form.screenType='';
      }else{
        this.form.screenType=this.quickNatureCur.join(',');
      }
      this.searchMeth();
    },
    filterConMeth(){
      this.$emit('getFilterStr',
              this.activeLiAD,
              this.shoppingctiveLi,this.filmActiveLi);
      this.$emit('filterMeth',
              this.filterConArr,
              this.activeLiAD,this.shoppingctiveLi,this.filmActiveLi)
    },
    exportMeth(){
      this.$emit('exportMeth');
    },
    setDefaultQuickDateCur(startTime){
      if(startTime!==''){
        this.curIndex=-1;
      }else{
        this.curIndex=0;
      }
    },
  },
  created() {
    this.allSelect(); // 版本数据
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getProvince();
    this.getYesDay();
    this.getCurWeek();
    this.curDate.startMonth=this.getCurMonth("s",0);
    this.curDate.endMonth=this.curDate.yesDay;
    this.curDate.startYear=this.getCurYear("s",0);
    this.curDate.endYear=this.curDate.yesDay;
    if(this.asOfYesterday){
      this.futureLists=['昨日','截止昨日','本周','本月','本年'];
    }else{
      this.futureLists=['昨日','本周','本月','本年'];
    }
  },
  computed:{
    quickRateCurActive(){
      return function(val){
        var str='';
        if(this.quickRateCur.indexOf(val)!==-1){
          str='activeLi'
        }
        return str;
      }
    },
    quickNatureCurActive(){
      return function(val){
        var str='';
        if(this.quickNatureCur.indexOf(val)!==-1){
          str='activeLi'
        }
        return str;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.try ::v-deep.el-input__inner {
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;
  width: 132px;
}
.try ::v-deep.el-input__inner::-webkit-input-placeholder {
  color: #606266 !important;
}
.try ::v-deep.inputColorChange .el-select .el-input .el-select__caret {
  color: #606266 !important;
}
.positionSelect {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  .el-input__inner {
    border: 1px solid #dcdfe6 !important;
    color: #606266 !important;
  }
  .positionSelectTest {
    // font-size: 20px;
    width: 84px;
    text-align: left;
    margin-right: 16px;
    // color: rgba(46, 99, 166, 1);
  }
  .fullSelect {
    margin: 0 22px;
  }
  ul {
    display: flex;
    li {
      padding: 12px 20px;
      // width: 215px;
      font-size: 14px;
      border: 1px solid #dcdfe6;
      color: #606266;
      border-radius: 4px;
      margin-right: 22px;
      cursor: pointer;
      .el-input__inner {
        border: 1px solid #dcdfe6 !important;
        color: #606266 !important;
        ::-webkit-input-placeholder {
          color: #606266 !important;
        }
      }
    }
    .activeLi {
      color: #409eff;
      border-color: #c6e2ff;
      background-color: #ecf5ff;
    }
    .pickerData {
      border: none;
    }
  }
  .filmWidth{
    li{
      width: auto;
    }
  }
}

.pointer {
  cursor: pointer;
}
  .positionSelect:last-child{
    padding-bottom: 0;
  }
  .dateBox{
    display:flex;
    align-items: center;
  }
  .dateBox li{
  height: 40px;
  width: 100px;
  text-align: center;
  box-sizing: border-box;
  }
  .flexSpaceBox{
    display:flex;
    justify-content: space-between;
  }
  .topSearch{
    display:flex;
  }
  .rightBox{
    margin-left:auto;
    display:flex;
  }
  .noPadding{
    padding:0!important;
  }
  .flexAlignBox{
    display:flex;
    align-items: center;
  }
  .noMarginRight{
    margin-right:0!important;
  }
  .filterWidthBtn{
    width:162px!important;
    padding:12px 0!important;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right:25px;
    :last-child{
      margin-right:0;
    }
  }
  .groupFilter{
    margin-right:40px;
  }
  .groupFilter li{
    width: 100px;
    height: 40px;
    // line-height: 40px;
    box-sizing: border-box;
    text-align: center;
  }
  .groupFilter:last-child{
    margin-right:0;
  }
</style>
