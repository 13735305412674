import request from "@/api/request";
import qs from "querystring";

// 包名列表
export function getList(param) {
  return request({
    url: `v2/Packageconfig/apkPackage`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 包名类型
export function getType(param) {
  return request({
    url: `v2/Packageconfig/getApkType`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 包名属性
export function getAttr(param) {
  return request({
    url: `v2/Packageconfig/getApkAttr`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 新增包名
export function add(param) {
  return request({
    url: `v2/Packageconfig/addApk`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 修改包名
export function edit(param) {
  return request({
    url: `v2/Packageconfig/editApk`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 删除包
export function del(param) {
  return request({
    url: `v2/Packageconfig/delApk`,
    method: "post",
    data: qs.stringify(param),
  });
}
