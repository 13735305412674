<template>
  <div>

    <el-dialog title="终端门店系统版式选择" :append-to-body='true' :visible.sync="visible" width="50%" height="20%"  :close-on-click-modal="false">
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="特别推荐" name="tabRecommend">
            <hotel-item ref="one" :hotelList="tabRecommendList" :brandList="brandList" hotelType="tabRecommend" :selectedHotelProp="selectedHotel" @chooseHotel="chooseHotel"></hotel-item>
          </el-tab-pane>
          <el-tab-pane label="eHotel" name="tabStandard" >
            <hotel-item ref="two" :hotelList="tabStandardList" :brandList="brandList" hotelType="标准版" :selectedHotelProp="selectedHotel" @chooseHotel="chooseHotel"></hotel-item>
          </el-tab-pane>
          <el-tab-pane label="Hihotel" name="tabHihotel" v-if="false">
            <hotel-item ref="three" :hotelList="tabHihotelList" :brandList="brandList" hotelType="hihotel" :selectedHotelProp="selectedHotel" @chooseHotel="chooseHotel"></hotel-item>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取消</el-button>
        <el-button type="primary" @click="dialogConfirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EhotelDialog",
  props: ["selectedHotelCheck"],
  watch: {
    selectedHotelCheck: {
      handler: function (newVal) {
        this.selectedHotelCheck = newVal;
        Object.assign(this.selectedHotel, newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      visible: false,
      activeName: "tabRecommend",
      tabRecommendList: [],
      tabStandardList: [],
      tabHihotelList: [],
      isPaiwFlag:0,
      selectedHotel: {
        id: "",
        label: "",
        type: "",
        buildingProjectName:"",
      },
      brandList: [],
    };
  },
  methods: {
    showDialog(val) {
      this.visible = true;
    },
    getHotelTemplate() {
      this.$http
        .get(this.global.getHotelTemplate, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            var { group, recommend } = resData;
            this.isPaiwFlag = resData.isPaiwFlag;
            this.tabRecommendList = recommend.list;
            this.brandList = group;
            this.assignTemplateData(resData);
          }
        });
    },
    assignTemplateData(obj) {
      var that = this;
      Object.keys(obj).forEach(function (key) {
        switch (obj[key].label) {
          case "标准版":
            that.tabStandardList = obj[key].list;
            break;
          case "hihotel":
            if (obj[key].list) {
              that.tabHihotelList = obj[key].list;
            } else {
              that.tabHihotelList = [];
            }
            break;
        }
      });
    },
    dialogCancel() {
      this.visible = false;
    },
    dialogConfirm() {
      var activeName = "";
      this.selectedHotel.type === "标准版"
        ? (activeName = "标准")
        : (activeName = "hihotel");
      console.log(this.selectedHotel, activeName);
      this.$emit("getSelectedHotel", this.selectedHotel, activeName);
      this.visible = false;
    },
    chooseHotel(val) {
      this.selectedHotel = val;
      this.getRecommendTemType(val);
    },
    getRecommendTemType(val) {
      var flag = true;
      if (val.type === "tabRecommend") {
        this.tabStandardList.filter((item, index) => {
          if (item.id === val.id) {
            this.selectedHotel.type = "标准版";
            flag = false;
          }
        });
        if (flag) {
          this.tabHihotelList.filter((item, index) => {
            if (item.id === val.id) {
              this.selectedHotel.type = "hihotel";
            }
          });
        }
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    // this.$emit('getHotelTemplateId','hihotel');
    this.getHotelTemplate();
  },
};
</script>

<style scoped lang="scss">
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.saveBtn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .el-button {
    width: 120px;
    margin-left: auto;
  }
}
.selectItem {
  width: 100%;
}
img,
video {
  width: 100%;
  height: 100%;
}
.map {
  width: 100%;
  height: 0;
}
#map {
  width: 100%;
}
.pointer {
  cursor: pointer;
}

.model {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
</style>
