<template>
  <div>
    <div class="btnRow">
      <el-button type="primary" class="largeBtn" @click="$refs.AddParameterDialog.open('新增',query.hotelName)">添 加</el-button>
      <!-- <el-button type="danger" class="largeBtn" @click="deleteData()">批量删除</el-button> -->
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <AddParameterDialog ref="AddParameterDialog"></AddParameterDialog>
  </div>
</template>
<script>
import { getList,allDelete} from "../api/infrastructure/Parameter";
import AddParameterDialog from "./components/AddParameterDialog.vue";
export default {
  props: {
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  name: "TvList",
  components: {
    AddParameterDialog,
  },
  data() {
    return {
      tableData: {
        tableList: [ ],
        checked: false,
        tableLoading: false,
        noPagination:true,
        page: 1,
        size: 2,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "50",
            type:"text",
          },
          {
            label: "名称",
            prop: "name",
            minWidth: "150",
            type:"text",
          },
          {
            label: "top值",
            prop: "x",
            minWidth: "150",
            type:"text",
          },
          {
            label: "left值",
            prop: "y",
            minWidth: "150",
            type:"text",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            edit: (row) => {
              this.$refs.AddParameterDialog.open('修改',this.query.hotelName,row)
            },
          },
        ],
      },
      listQuery: {
        database: this.query.hotelName,
        page: 1,
        size: 999
      },
      checkList: [],
    };
  },
  methods: {
    changeSize(s, p) {
      this.listQuery.page = p ;
      this.listQuery.size = s ;
      this.getList();
      
    },
    changeCurrent(s, p) {
      this.listQuery.page = p ? p : 1,
      this.listQuery.size = s ? s : 10,
      this.getList();
    },
    changeSelection(val) {
        this.checkList = val.map(v=>{
          return v.id
        })
    },
    getList(page) {
      this.tableData.tableLoading = true;
      if (page) {
        this.listQuery.page = page;
      }
      getList(this.listQuery).then(res => {
        if (res.data.code == 100000) {
          let typeList = {
            0: '音乐',
            1: '字体',
            2: '视频',
            3: '其他'
          }
          if (res.data.data.totalNum > 0) {
             res.data.data.row.forEach(v => {
            v.music_type_text = typeList[v.music_type]
            });
            this.tableData.tableList = res.data.data.row;
          } else {
            this.tableData.tableList = [];
          }
         
          this.$refs.operationTable.changeTotal(Number(res.data.data.totalNum));
          this.tableData.tableLoading = false;
        }
      })
    },
    deleteData() {
      if (this.checkList.length <= 0 ) {
        return this.$message.error('请选择服务配置');
      }
      this.$confirm('此操作将永久删除服务配置？, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          allDelete(
            {
              ids:`${this.checkList.join()}`,
              database:this.listQuery.database
            }).then(res => {
              if (res.data.code === 100000) {
                this.getList();
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
              } else {
                this.$message.error(es.data.msg)
              }
          })
        }).catch(() => {
        });
    },
  },
  mounted(){
    this.getList();
    this.tableData.tableList = this.tableData.tableList.map(i=>{
      if(i.ltype==1){
        i.language ="中文"
      }else if(i.ltype==2){
         i.language ="英文"
      }else if(i.ltype==3){
         i.language ="日文"
      }else if(i.ltype==4){
         i.language ="韩文"
      }
      return i
    })
  },
  watch: {
    query: {
      handler: function (newVal) {
       this.query=newVal;
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
