<template>
  <div >
    <div class="outTopBox">
      <top-bread :bread="bread" v-if="curPage === '/indexShow'">
        <span slot="second">首页</span>
        <span slot="third">更多</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskToDo" v-if="curPage === '/taskToDo'">
        <span slot="second">任务管理</span>
        <span slot="third">待办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskDone" v-if="curPage === '/taskDone'">
        <span slot="second">任务管理</span>
        <span slot="third">已办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <el-card>
      <div class="title blueTitle">可验收酒店列表</div>
      <div class="btnRow" style="margin-top: 15px">
        <el-button class="largeBtn" type="primary" @click="backMeth">返回</el-button>
        <el-button v-if="btnShow" class="largeBtn" type="primary" @click="trueMeth">确定</el-button>
      </div>
      <el-table :data="tableData" ref="accountTable" style="width: 100%; margin-top: 10px" v-loading="loading" :header-cell-style="headerStyle">
        <el-table-column min-width="120" v-if="btnShow">
          <template slot-scope="scope">
            <el-switch class="tableScopeSwitch" v-model="scope.row.is_reject" active-color="red" active-text="驳回" inactive-color="#DCDCDC" @change="switchChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="hotel" label="酒店名称" show-overflow-tooltip min-width="300">
        </el-table-column>
        <el-table-column min-width="180" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row)">详情</el-button>
            <el-button v-if="scope.row.refuse_reason" type="text" size="mini" @click="returnShow(scope.row)">驳回原因</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="hotelName" min-width="150" label="程序名称">
        </el-table-column>
        <el-table-column label="验收状态">
          <template slot-scope="scope">
            <span :style="{ background: statusCircleColor(scope.row.check_state) }" v-if="scope.row.check_state" class="statusCircle"></span>
            <span>{{ scope.row.check_state_text }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="total_num" label="应上线终端数量">
        </el-table-column>
        <el-table-column min-width="150" prop="n_rooms_connected" label="激活终端数量">
        </el-table-column>
        <el-table-column min-width="130" prop="dar" label="平均日活率">
        </el-table-column>
        <el-table-column min-width="130" prop="update_version_num" label="更新版本数量">
        </el-table-column>
        <el-table-column min-width="150" prop="max_update_nums" label="最大更新终端数量">
        </el-table-column>
        <el-table-column prop="channel_name" label="所属合作伙伴" min-width="300" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </el-card>
    <el-drawer title="驳回原因" :visible.sync="result_drawer" :direction="direction" :before-close="handleClose" size="45%" :modal="false" :wrapperClosable="false">
      {{result}}
    </el-drawer>
    <el-drawer title="详情" :visible.sync="detail_drawer" :direction="direction" :modal-append-to-body="true" :before-close="handleClose" size="120%" :destroy-on-close="true" :modal="false" :wrapperClosable="false">
      <div class="changeType">
        <div :class="{ active: type == index ? true : false }" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="contentShow" v-show="type == 0">
        <detail-no :edit="true" :id="congtentId" v-if="auth.type !== 'htrip' && auth.type !== 'screen'"></detail-no>
        <detail-juping :edit="true" :id="congtentId" v-if="auth.type === 'screen'"></detail-juping>
        <detail-has :edit="true" :id="congtentId" v-if="auth.type === 'htrip'"> </detail-has>
      </div>
      <div class="contentShow" v-show="type == 1">
        <all-tab-online :id="congtentId" :showData="true" v-if="type == 1"></all-tab-online>
      </div>
      <div class="contentShow" v-show="type == 2">
        <showfile ref="fileshow" :hotelContract="hotelContract" :showFileData="showFileData"></showfile>
      </div>
    </el-drawer>
    <el-dialog title="驳回原因填写" :visible.sync="returnVisible" width="30%" :before-close="handleClose">
      <el-input type="textarea" placeholder="请输入内容" v-model="returnmessge" clearable :rows="4" resize="none"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelReturn">取 消</el-button>
        <el-button type="primary" @click="trueReturn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEnclosureUrls,
  getHotelAcceptance,
  passHotelAcceptance,
} from "@/api/hotel.js";
import showfile from "../hotelMan/CPTForUpload/fileShow";
import qs from "querystring";
export default {
  components: {
    showfile,
  },
  name: "IndexDoQualify",
  data: () => ({
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/indexDoMore" },
    },
    breadTaskToDo: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/taskToDo" },
    },
    breadTaskDone: {
      level: 4,
      thirdPath: { path: "/taskDone" },
    },
    swhich: false,
    type: 0,
    query: null,
    hotelContract: null,
    congtentId: null,
    showFileData: null,
    result: "",
    loading: true,
    typeList: [
      {
        name: "酒店信息",
      },
      {
        name: "上线凭据",
      },
      {
        name: "附件验收",
      },
    ],
    btnShow: true,
    curPage: "",
    tableData: [],
    table: {
      page: 1,
      limit: 5,
      total: 0,
    },
    auth: {},
    returnVisible: false,
    result_drawer: false,
    detail_drawer: false,
    direction: "rtl",
    returnmessge: "",
    messageList: [],
    clickSwitchRow: {},
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
  }),
  methods: {
    // 关闭弹框 通用
    handleClose(done) {
      done();
      this.cancelReturn();
    },
    // 打开详情
    openContent(val) {
      this.hotelContract = val.hotel_contract;
      this.congtentId = val.manage_hotel_id;
      this.detail_drawer = true;
      this.result_drawer = false;
    },
    // 审核状态标记颜色 抛出
    statusCircleColor(txt) {
      if (txt == 3) {
        return "#FE8637";
      } else if (txt == 7) {
        return "#3774FE";
      } else if (txt == 6) {
        return "#53D439";
      }
    },
    // 开关变化
    switchChange(row) {
      if (row.is_reject) {
        this.returnVisible = true;
        Object.assign(this.clickSwitchRow, row);
      } else {
        this.messageList.forEach((item, index) => {
          if (item.hotelContract == row.hotel_contract) {
            this.messageList.splice(index, 1);
            item.is_reject = false;
          }
        });
      }
    },
    // 取消驳回
    cancelReturn() {
      this.returnmessge = "";
      this.returnVisible = false;
      var id = this.clickSwitchRow.id;
      this.tableData.forEach((item) => {
        if (item.id == id) {
          item.is_reject = false;
        }
      });
    },
    // 驳回确认
    trueReturn() {
      this.returnVisible = false;
      const json = {
        hotelContract: this.clickSwitchRow.hotel_contract,
        message: this.returnmessge,
      };
      this.messageList.push(json);
      this.returnmessge = "";
    },
    // 详情展示列表点击
    changeType(index) {
      if (index == 2) {
        this.type = index;
        var json = {
          hotel_contract: this.hotelContract,
        };
        var param = qs.stringify(json);
        getEnclosureUrls(param)
          .then((res) => {
            if ((res.data.code = 100000)) {
              this.showFileData = res.data.data;
            
              this.$emit("showFileData", this.showFileData);
            }
          })
          .catch((err) => {});
      } else {
        this.type = index;
        return;
      }
    },
    //打开驳回原因
    returnShow(row) {
      this.result = row.refuse_reason;
      this.result_drawer = true;
      this.detail_drawer = false;
    },
    // 获取页面数据
    getDetail() {
      this.loading = true;
      const json = {
        id: this.query.id,
      };
      var param = qs.stringify(json);
      getHotelAcceptance(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData = res.data.data;
             this.tableData.forEach(item=>{
             if(item.is_reject){
               const json = {
                  hotelContract: item.hotel_contract,
                  message:item.refuse_reason,
               }
               this.messageList.push(json);
             }
            })
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 返回上一层页面
    backMeth() {
      this.$router.push({ path: `./${this.query.fromUrl}` });
    },
    // 审核确认
    trueMeth() {
      this.$confirm("是否确认审核结果?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var arr = [];
          Object.assign(arr, this.messageList);
          var jsonStr = JSON.stringify(arr);
          const json = {
            id: this.query.id,
            hotelInfo: jsonStr,
          };
          var param = qs.stringify(json);
              passHotelAcceptance(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("审批成功");
                this.backMeth();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
  },
  mounted() {
    var query = this.$route.query;
    this.curPage = sessionStorage.getItem("defaultActive");
    var auth = JSON.parse(sessionStorage.getItem("auth"));
    this.auth = auth;
    this.query = query;
    if (query.reviewStatus === "待审核") {
      this.btnShow = true;
    } else {
      this.btnShow = false;
    }
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.bread {
  flex: 1;
}
.result_btn {
  margin-left: 38vw;
  width: 100px;
}
.changeType {
  position: absolute;
  left: -85px;
  top: 0px;
  width: 85px;
  height: 165px;
  border: 1px solid #e2e2e2;
  z-index: 999;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}
.changeType div {
  width: 85px;
  height: 55px;
  text-align: center;
  font-size: 14px;
  line-height: 55px;
  cursor: pointer;
}
.statusCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.btnRow {
  display: flex;
  justify-content: space-between;
}
.active {
  background-color: #f1f9ff;
  color: #0078ff;
}
.tableScopeSwitch ::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
.tableScopeSwitch ::v-deep .el-switch__label--right {
  z-index: 1;
  right: 22px;
}
.tableScopeSwitch ::v-deep .el-switch__label.is-active {
  display: block;
}
::v-deep .el-drawer {
  overflow: visible !important;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible !important;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}
::v-deep :focus {
  outline: 0;
}
::v-deep .el-drawer__body {
  overflow: scroll;
  padding: 15px 20px 0;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
.tableScopeSwitch.el-switch ::v-deep .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}
</style>
