<template>
    <div>
        welcome
    </div>
</template>

<script>
    export default {
        name: "Index",
        data: () => ({})
    }
</script>

<style scoped lang="scss">

</style>
