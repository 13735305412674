import request from "@/api/request";
import qs from "querystring";


// 获取报障列表
export function staVersion(param) {
    return request({
        url: `v2/Hotelvisualization/staVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  导出接口
export function importVersion(param) {
    return request({
        url: `v2/Hotelvisualization/importVersion`,
        method: "post",
        data: qs.stringify(param),
        responseType: 'blob'
    });
}
//  获取模板列表
export function getTemplateList(param) {
    return request({
        url: `v2/Hotelvisualization/getTemplate`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取追踪详情
export function getHotelByVersion(param) {
    return request({
        url: `v2/Hotelvisualization/getHotelByVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取详情
export function getTemplateVersion(param) {
    return request({
        url: `v2/Hotelvisualization/getTemplateVersion`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取房间数据
export function getHotelNoUpdate(param) {
    return request({
        url: `v2/Hotelvisualization/getHotelNoUpdate`,
        method: "post",
        data: qs.stringify(param),
    });
}

//  操作栏刷新
export function refreshNumber(param) {
    return request({
        url: `v2/Hotelvisualization/refreshNumber`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  操作栏重发接口
export function repeatLauncher(param) {
    return request({
        url: `v2/Hotelvisualization/repeatLauncher`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  批量更新加酒店
export function addHotel(param) {
    return request({
        url: `v2/Hotelvisualization/addHotel`,
        method: "post",
        data: qs.stringify(param),
    });
}