<template>
  <div v-loading="downloadFile">
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">数据中心</span>
        <span slot="third">影视总览</span>
      </top-bread>

    </div>
    <el-card class="box-card searchCard">
      <div class="searchBox">
        <el-date-picker class="fullSelect" :clearable='false' v-model="searchTime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
        <el-input v-model="keyWord" style="width:300px;margin-left:20px;" placeholder="请输入合作伙伴名称关键字"></el-input>
        <el-button type="primary" @click="searchData">搜索</el-button>
        <div class="reset" @click="dataReset">重置</div>
      </div>
    </el-card>
    <el-card class="box-card searchCard">
      <div class="title blueTitle">影视收益数据概览</div>
      <el-row class="rowContent" v-loading="pageDataLoading">
        <div class="showCard">
          <p style="font-size:14px;margin-bottom:20px;">合作伙伴分成收益</p>
          <p style="font-size:12px;margin-bottom:20px;">{{yesterdayData.title}}</p>
          <div class="NumberShow">
           <div class="Number">{{showDataCom((parseFloat(yesterdayData.mcu)+parseFloat(yesterdayData.card)),'money')}}<span style="font-size:14px;color:#a3a7af"> 元</span></div>
            <div class="typeOther" style="font-size:12px">
              <p>【单点】<span>{{showDataCom(yesterdayData.mcu,'money')}} 元</span></p>
              <p>【天卡】<span>{{showDataCom(yesterdayData.card,'money')}} 元</span></p>
            </div>
          </div>
        </div>
        <div :span='6' class="showCard">
          <p style="font-size:14px;margin-bottom:20px;">合作伙伴分成收益</p>
          <p style="font-size:12px;margin-bottom:20px;">本周</p>
          <div class="NumberShow">
            <div class="Number">{{showDataCom((parseFloat(pageData.week.mcu)+parseFloat(pageData.week.card)),'money')}}<span style="font-size:14px;color:#a3a7af"> 元</span></div>
            <div class="typeOther" style="font-size:12px">
              <p>【单点】<span>{{showDataCom(pageData.week.mcu,'money')}} 元</span></p>
              <p>【天卡】<span>{{showDataCom(pageData.week.card,'money')}} 元</span></p>
            </div>
          </div>
        </div>
        <div :span='6' class="showCard">
          <p style="font-size:14px;margin-bottom:20px;">合作伙伴分成收益</p>
          <p style="font-size:12px;margin-bottom:20px;">本月</p>
          <div class="NumberShow">
            <div class="Number">{{showDataCom((parseFloat(pageData.month.mcu)+parseFloat(pageData.month.card)),'money')}}<span style="font-size:14px;color:#a3a7af"> 元</span></div>
            <div class="typeOther" style="font-size:12px">
              <p>【单点】<span>{{showDataCom(pageData.month.mcu,'money')}} 元</span></p>
              <p>【天卡】<span>{{showDataCom(pageData.month.card,'money')}} 元</span></p>
            </div>
          </div>
        </div>
        <div :span='6' class="showCard">
          <p style="font-size:14px;margin-bottom:20px;">合作伙伴分成收益</p>
          <p style="font-size:12px;margin-bottom:20px;">本年</p>
          <div class="NumberShow">
            <div class="Number">{{showDataCom((parseFloat(pageData.year.mcu)+parseFloat(pageData.year.card)),'money')}}<span style="font-size:14px;color:#a3a7af"> 元</span></div>
            <div class="typeOther" style="font-size:12px">
              <p>【单点】<span>{{showDataCom(pageData.year.mcu,'money')}} 元</span></p>
              <p>【天卡】<span>{{showDataCom(pageData.year.card,'money')}} 元</span></p>
            </div>
          </div>
        </div>
      </el-row>
    </el-card>
    <el-card class="box-card searchCard">
      <el-button class="btnForList" type="warning" @click="exportTableList">导出</el-button>
      <div class="title blueTitle">
        <span>分成金额列表【{{yesterdayData.title1}}】</span>
      </div>
      <el-table v-loading="tableDataLoading" class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" :height="table.limit>=20 &&table.total>10?'550px':'300px'">
        <el-table-column prop="partner_name" label="合作伙伴名称" min-width="300px" fixed></el-table-column>
        <el-table-column prop="total_income_original" label="订单总金额" min-width="120px"></el-table-column>
          <el-table-column prop="total_income" label="分成总收益" min-width="120px"></el-table-column>
          <el-table-column prop="mcu_order_num" label="单点订单数量" min-width="120px"></el-table-column>
          <el-table-column prop="mcu_income_original" label="单点订单金额" min-width="160px"></el-table-column>
          <el-table-column prop="mcu_income" label="单点分成收益" min-width="120px"></el-table-column>
        <el-table-column prop="card_order_num" label="天卡订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="card_income_original" label="天卡订单金额" min-width="160px"></el-table-column>
        <el-table-column prop="card_income" label="天卡分成收益" min-width="120px"></el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  getAllPartnerStatistics,
  getAllPartnerList,
  exportAllPartnerList
} from "@/api/dataCenter.js";
import qs from "querystring";
export default {
  data() {
    return {
      pickerOptions: {
          disabledDate(time) {
           return time.getTime() > Date.now() - 8.64e7   ;//如果没有后面的-8.64e6就是不可以选择今天的
           }
      },
      bread: {
        level: 3,
      },
      searchTime: [],
      keyWord: "",
      tableList: [],
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      pageDataLoading: false,
      tableDataLoading: false,
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      yesterdayData: {
        title: "昨日",
        title1:"截止昨日",
        mcu: 0.0,
        card: 0.0,
        mcu_original: 0.0,
        card_original: 0.0,
      },
      pageData: {
        month: { mcu: 0.0, mcu_original: 0.0, card_original: 0.0, card: 0.0 },
        week: { mcu: 0.0, mcu_original: 0.0, card_original: 0.0, card: 0.0 },
        year: { mcu: 0.0, mcu_original: 0.0, card_original: 0.0, card: 0.0 },
      },
      tableName:"影视收益分成金额表",
      downloadFile:false,
    };
  },
  mounted() {
    this.getPageData();
    this.getTableList();
  },
   computed:{
      showDataCom() {
      return function (s, type = "", n = 2) {
        if (type === "money") {
          if (s === null) {
            return 0;
          } else {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
            var l = s.split(".")[0].split("").reverse(),
              r = s.split(".")[1];
            var t = "";
            for (var i = 0; i < l.length; i++) {
              t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
            }
            return t.split("").reverse().join("") + "." + r;
          }
        } else {
          if (s === null) {
            return 0.00;
          } else {
            return parseFloat(s).toFixed(2);
          }
        }
      };
    },
  },
  methods: {
     exportTableList() {
       this.downloadFile=true;
         this.$message({
                    message:'导出请求已提交，导出所需时间较长，请耐心等待……',
                    type:'success',
                    duration:6000
                });
      const json = {
        startTime: this.searchTime[0] ? this.searchTime[0] : "",
        endTime: this.searchTime[1] ? this.searchTime[1] : "",
        partnerName: this.keyWord,
      };
      var param = qs.stringify(json);
      exportAllPartnerList(param)
        .then((res) => {
          const blobUrl = window.URL.createObjectURL(res.data);
          const title = `${this.tableName}.xlsx`;
          const a = document.createElement("a");
          a.style.display = "none";
          a.download = title;
          a.href = blobUrl;
          a.click();
          this.downloadFile=false;
          document.body.removeChild(a);
        })
        .catch((res) => {
        });
    },
    handleSizeChange(val) {
      this.table.limit = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getTableList();
    },
    searchData() {
      if (this.searchTime.length !== 0) {
        this.yesterdayData.title =
          this.searchTime[0] + " ~ " + this.searchTime[1];
            this.yesterdayData.title1 =
          this.searchTime[0] + " ~ " + this.searchTime[1];
      } else {
        this.yesterdayData.title = "昨日";
        this.yesterdayData.title1 = "截止昨日";
      }
       this.table.page=1;
      this.getPageData();
      this.getTableList();
    },
    dataReset() {
      this.searchTime = [];
      this.keyWord = "";
      this.yesterdayData.title = "昨日";
      this.yesterdayData.title1 = "截止昨日昨日";
      this.getPageData();
      this.getTableList();
    },
    //  获取表格数据
    getTableList(){
        const json2 = {
        page: this.table.page,
        limit: this.table.limit,
        startTime: this.searchTime[0] ? this.searchTime[0] : "",
        endTime: this.searchTime[1] ? this.searchTime[1] : "",
        partnerName: this.keyWord,
      };
      var param2 = qs.stringify(json2);
      getAllPartnerList(param2)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableList = res.data.data.items;
            this.table.total = res.data.data.total;
            this.tableDataLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPageData() {
      this.pageDataLoading = true;
      this.tableDataLoading = true;
      const json = {
        startTime: this.searchTime[0] ? this.searchTime[0] : "",
        endTime: this.searchTime[1] ? this.searchTime[1] : "",
        partnerName: this.keyWord,
      };
      var param = qs.stringify(json);
      getAllPartnerStatistics(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.pageData = res.data.data;
            this.yesterdayData.mcu = this.pageData.yesterday.mcu;
            this.yesterdayData.card = this.pageData.yesterday.card;
              this.yesterdayData.mcu_original = this.pageData.yesterday.mcu_original;
            this.yesterdayData.card_original = this.pageData.yesterday.card_original;
            this.pageDataLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.el-button {
  width: 100px;
  height: 40px;
  margin-left: 20px;
}
.searchBox ::v-deep .el-input__inner {
  border: 1px solid #2e63a6 !important;
  color: #2e63a6 !important;
}
.reset {
  display: inline-block;
  border: 1px solid #2e63a6;
  width: 100px;
  height: 40px;
  margin-left: 20px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  color: #2e63a6;
  line-height: 40px;
  cursor: pointer;
  box-sizing: border-box;
}
.outTopBox {
  margin-top: 10px;
}
.searchBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.rowContent {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding: 0 0px;
}
.showCard {
  flex: 1;
  margin-right: 10px;
  color: #a3a7af;
  margin-top: 20px;
  border-radius: 4px;
  padding: 20px !important;
  box-shadow: 0 0 11px 2px rgb(94, 151, 255, 0.15);
  box-sizing: border-box !important;
}
.showCard:last-child {
  margin-right: 0;
}
.NumberShow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.NumberShow div {
  flex: 1;
}
.typeOther {
  display: flex;
  flex-direction: column;
}
.typeOther p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 0 0;
  font-family: DIN !important;
}
.Number {
  font-family: DIN !important;
  color: #333;
  font-size: 28px;
}
.blueTitle {
  margin-top: 20px;
}
.btnForList {
  position: absolute;
  right: 20px;
  top: 10px;
}
.tableBox {
  margin-top: 30px;
}
::v-deep .el-table th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  box-sizing: border-box;
  border-bottom: none !important;
}
::v-deep .el-table__footer-wrapper,
::v-deep .el-table__header-wrapper {
  border-radius: 4px !important;

  border: 1px solid #ebeef5 !important;
  border-top: none !important;
}
</style>