<template>
    <div>
        <div>
            <div class="banner" style="width:60%;background:yellow;margin:0 auto 100px auto;">
                <div class="swiper-father">
                    <swiper :options="swiperOption" ref="swiper" class="swiper  swiper-no-swiping">
                        <swiper-slide>{{item.name}}</swiper-slide>
                        <swiper-slide>22</swiper-slide>
                        <swiper-slide>33</swiper-slide>
                        <swiper-slide>44</swiper-slide>
                        <swiper-slide>55</swiper-slide>
                        <swiper-slide>66</swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div class="swiper-button-prev" slot="button-prev"  @click="prev"></div>
                        <div class="swiper-button-next" slot="button-next" @click="next"></div>
                    </swiper>
                    <div class="swiper-button-prev" slot="button-prev"  @click="prev"></div>
                    <div class="swiper-button-next" slot="button-next" @click="next"></div>
                    <div class="jc"></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    export default {
        name: "TestTwo",
        data:()=>({
            swiperOption: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                slidesPerView: 3,
                spaceBetween: 30,
                pagination: '.swiper-pagination',
                paginationClickable: true,
                autoplay: 2500,
                autoplayDisableOnInteraction: false,
                loop: false,
                coverflow: {
                    rotate: 30,
                    stretch: 10,
                    depth: 60,
                    modifier: 2,
                    slideShadows : true
                },

            },
            hideEl:false,
            swiper:{},
            list:[
                {name:0,value:0}
            ]

        }),
        methods:{
            prev(){
                this.$refs.swiper.$swiper.slidePrev();
            },
            next(){
                this.$refs.swiper.$swiper.slideNext();
            },
            getResult(){
                var str=302086;
                var trans=Math.floor((str /10000) * 100) / 100;
                var transTwoPoint=str.toFixed(2);
                var test=30.20;
                var transTwoPointTest=test.toFixed(2);
                var testThree=parseFloat(str).toLocaleString();
                var testFour=parseFloat(transTwoPoint).toLocaleString();
            }
        },
        mounted(){
            setTimeout(()=>{
                this.list=[
                    {name:1,value:1},
                    {name:2,value:2},
                    {name:3,value:3},
                    {name:4,value:4},
                    {name:5,value:5},
                    {name:6,value:6},
                ];
            },1000);
            this.getResult();
        }
    }
</script>

<style scoped>
    @import '../../../node_modules/swiper/swiper-bundle.css';
    @import '../../../node_modules/swiper/swiper-bundle.min.css';
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

</style>