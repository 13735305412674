<template>
  <div>
    <div :id="chart.id" style="width:100%;height:300px;">
    </div>
    <div v-if="chart.noData" style="line-height:300px;text-align:center;width:100;">暂无数据</div>
  </div>
</template>
<script>
import echarts from "echarts";
import GLOBAL from "@/api/globalVar.js";
export default {
  props: ["chart"],
  data() {
    return {
      yAxis: [],
    };
  },
  methods: {
    drawEcharts() {
      var chart = echarts.init(document.getElementById(this.chart.id));
      var that = this;
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          x: "center",
          y: "bottom",
          padding: [0, 0, 0, 0],
          icon: "circle",
          data: this.chart.option.legend,
        },
        grid: {
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#ced7ec",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#333",
            },
          },
          data: this.chart.option.xData,
        },

        yAxis: this.yAxis,
        series: this.chart.option.series,
      };
      chart.setOption(option);
    },
    getYaxis(unitOne, unitTwo, unitThree, unitFour) {
      this.yAxis = [
        {
          type: "value",
          axisLabel: {
            formatter: `{value}${unitOne}`,
            textStyle: {
              color: GLOBAL.chartColor[0],
            },
          },
          show: true,
          splitNumber: 5,
          position: "left", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            formatter: `{value}${unitTwo}`,
            textStyle: {
              color: GLOBAL.chartColor[1],
            },
          },
          show: true,
          splitNumber: 5,
          offset: 60,
          position: "left", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            formatter: `{value}${unitThree}`,
            textStyle: {
              color: GLOBAL.chartColor[2],
            },
          },
          show: true,
          splitNumber: 5,
          position: "right", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            formatter: `{value}${unitFour}`,
            textStyle: {
              color: GLOBAL.chartColor[3],
            },
          },
          show: true,
          splitNumber: 5,
          offset: 60,
          position: "right", //多个Y轴使用
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#ebeef5",
            },
          },
        },
      ];
    },
    calMin(arr) {
      var min = 0;
      arr.forEach((el) => {
        el.forEach((el1) => {
          if (!(el1 === undefined || el1 === "")) {
            if (min > el1) {
              min = el1;
            }
          }
        });
      });
      var minint = Math.floor(min / 10);
      var minval = minint * 10; //让显示的刻度是整数
      return minval;
    },
    calMax(arr) {
      var max = 0;
      arr.forEach((el) => {
        el.forEach((el1) => {
          if (!(el1 === undefined || el1 === "")) {
            if (parseInt(max) < parseInt(el1)) {
              max = el1;
            }
          }
        });
      });
      var maxint = Math.ceil(max / 9.5); //不让最高的值超过最上面的刻度
      var maxval = maxint * 10; //让显示的刻度是整数
      return maxval;
    },
    transData(nameOne, nameTwo, nameThree, nameFour) {
      var series = this.chart.option.series;
      series.filter((item, index) => {
        if (item.name === nameOne) {
          this.minMaxMeth(item.data, index);
        } else if (item.name === nameTwo) {
          this.minMaxMeth(item.data, index);
        } else if (item.name === nameThree) {
          this.minMaxMeth(item.data, index);
        } else if (item.name === nameFour) {
          this.minMaxMeth(item.data, index);
        }
      });
      this.drawEcharts();
    },
    minMaxMeth(data, index) {
      var Min = this.calMin([data]);
      var Max = this.calMax([data]);
      Max = Max === 0 ? 10 : Max;
      this.yAxis[index].min = Min;
      this.yAxis[index].max = Max;
      this.yAxis[index].interval = (Max - Min) / 5;
    },
  },
};
</script>
<style scoped>
</style>