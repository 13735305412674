<template>
  <div class="box" v-loading="loading">
    <div class="picShow" v-for="(item,index) in fileList" :key="index">
      <video controls v-if="sourceType=='video'" :src="item.url" style="width:100%;height:100%"></video>
      <img v-if="sourceType=='pic'" :src="item.url" style="width:100%;height:100%">
      <div v-if="sourceType=='pic'" class="model">
        <div class="icon">
          <i class="el-icon-zoom-in" @click="openShow(item.url)"></i>
          <i class="el-icon-delete" @click="del(index)"></i>
        </div>
      </div>
      <i v-if="sourceType=='video'" class=" del el-icon-delete" @click="del(index)"></i>
    </div>
    <el-upload :class="[uploadPicTrue?'hide':'show']" multiple :action="global.materialUpload" :headers="headers" list-type="picture-card" :on-remove="handleRemove" :on-success="handleSuccess" :on-change="handleChange" :on-preview="handlePictureCardPreview" :before-remove='beforeRemove' :on-error="handleError" :before-upload="beforeAvatarUpload" :file-list="fileList" ref="uploadPic">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "uploadMorePicDiv",
  props: ["list", "upIndex", "sourceType"],
  data() {
    return {
      uplaodVisible: false,
      fileList: [],
      uploadPicTrue: false,
      dialogVisible: false,
      loading: false,
      dialogImageUrl: "",
      headers: {
        token: "",
      },
      type: "",
    };
  },
  watch: {
    list: {
      handler: function (newVal) {
        this.open(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
    console.log(this.sourceType);
  },
  methods: {
    // 界面展示 数据显示处理
    open(list) {
      if (list.length == 0) {
        this.fileList = [];
      } else {
        this.fileList = list;
      }
      this.uplaodVisible = true;
    },
    // 删除上传文件确认
    beforeRemove(file, fileList) {
      this.uploadPicTrue = false;
    },
    // 上传文件时钩子函数
    beforeAvatarUpload(file) {
      var jpeg = file.type === "image/jpeg";
      var png = file.type === "image/png";
      var mp4 = file.type === "video/mp4";
      var size = file.size / 1024 / 1024 < 50;
      var size2 = file.size / 1024 / 1024 < 2;
      if (this.sourceType == "pic") {
        if (!jpeg && !png) {
          this.$message.warning("格式不正确，仅支持jpg,png格式素材");
          return false;
        }
        if (!size2) {
          this.$message.warning("超出限制大小，素材大小需低于2M");
          return false;
        }
      } else {
        if (!mp4) {
          this.$message.warning("格式不正确，仅支持MP4格式素材");
          return false;
        }
        if (!size) {
          this.$message.warning("超出限制大小，素材大小需低于50M");
          return false;
        }
      }

      this.uploadPicTrue = true;
      this.loading = true;
      if (this.sourceType == "pic") {
        return (jpeg || png) && size2;
      } else {
        return mp4 && size;
      }
    },
    del(index) {
      this.uploadPicTrue = false;
      this.fileList.splice(index, 1);
      this.$emit("defaultData", this.fileList, this.upIndex);
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.uploadPicTrue = false;
      this.fileList = [...fileList];
      this.$emit("defaultData", this.fileList, this.upIndex);
    },
    // 文件预览
    openShow(url) {
      this.dialogVisible = true;
      this.dialogImageUrl = url;
    },
    // 文件预览
    handlePictureCardPreview(file, fileList) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    // 文件列表变化
    handleChange(file, fileList) {
      if (this.fileList.length >= 1) {
        this.uploadPicTrue = true;
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      if (res.code == 100000) {
        this.fileList = [...fileList];
        let list = this.fileList.map((i) => {
          if (i.response) {
            const json = {
              url: i.response.data.url,
              md5: i.response.data.md5,
            };
            return json;
          } else {
            return i;
          }
        });
        this.$emit("defaultData", list, this.upIndex);
      } else {
        this.$message.warning(res.msg);
      }
      this.loading = false;
    },
    handleError(res, file, fileList) {},
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.picShow {
  height: 148px;
  width: 148px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0px 10px 10px 0;
  overflow: hidden;
  position: relative;
  .del {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
    transition: 0.3s;
    cursor: pointer;
    background: rgba($color: #000000, $alpha: 0.3);
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 15px;
  }
  .model {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0);
    transition: 0.3s;
    .icon {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 20px;
      transition: 0.3s;
      i {
        display: inline;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}
.picShow:hover .el-icon-delete {
  display: block;
}
.picShow:hover .model {
  background: rgba($color: #000000, $alpha: 0.3);
}
.picShow:hover .model .icon {
  display: block;
}
.hide ::v-deep .el-upload-list--picture-card {
  display: none;
}
.hide ::v-deep .el-upload-list__item.is-uploading {
  display: none;
}
.hide ::v-deep .el-upload-list--picture-card {
  line-height: 0;
}

.hide ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin: 0;
}
.show ::v-deep .el-upload-list--picture-card {
  display: none;
}
::v-deep .el-upload--picture-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
</style>