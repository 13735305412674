<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "jumppage",
  data() {
    return {
      query: {},
    };
  },
  mounted() {
    this.query = JSON.parse(decodeURIComponent(this.$route.query.data));

    this.$http
      .get(this.global.view, {
        params: {
          id: this.query.id,
          hotel_project: this.query.hotelName,
        },
      })
      .then((res) => {
        if (res.data.code === 100000) {
          this.query.hotel_use_state = res.data.data.hotel_use_state;
          this.query.res = res;
          this.query.hotel_id = this.query.id;
          this.query.isApaas = true;
          let dataItem = Object.assign(this.query);
          dataItem.is_newPage = true;
          dataItem.is_newPage = true;
          sessionStorage.setItem("hoteliitem", JSON.stringify(dataItem));
          sessionStorage.setItem("firsthoteliitem", JSON.stringify(dataItem));
          let funarr = [
            "应用下载",
            "数据概览",
            "列表",
            "数据走势",
            "酒店详情（详情）",
            "实施人员（详情）",
            "上线情况（详情）",
            "素材编辑（详情）",
            "删除",
            "详情",
            "导出",
            "广告下架",
            "添加实施人员",
            "删除实施人员",
            "标签管理",
            "标签编辑",
            "模式修改",
            "广告标签编辑",
            "跳转",
            "终端管理密码（详情）",
            "授权管理",
            "授权关闭",
            "授权生成",
            "远程维护",
            "系统管理",
            "发布列表",
            "指令发布",
            "launcher配置",
            "应用配置",
            "版本控制",
            "操作日志",
            "基础设置",
            "房间管理",
            "刊位管理",
            "虚拟房间",
            "新建分组",
            "修改名称",
            "MAC解绑",
            "乐播授权查询",
            "发布",
            "发布审核",
          ];
          sessionStorage.setItem("funArr", JSON.stringify(funarr));
          sessionStorage.setItem("curCompanyName", "携旅集团");
          let auth = {
            type: "htrip",
            register_review_status: 1,
            ca_review_status: 1,
            user_name: this.query.user,
            register_message: "",
          };
          sessionStorage.setItem("auth", JSON.stringify(auth));
          sessionStorage.setItem("filterCon", "{}");
          sessionStorage.setItem("filterPage", "");
          sessionStorage.setItem("filterLimit", "");
          sessionStorage.setItem("defaultActive", "/hotelManAll");
          sessionStorage.setItem("defaultActiveId", "247");
          sessionStorage.setItem("curCompanyName", "携旅集团");
          let list = [
            {
              id: 1,
              parent: 0,
              text: "首页",
              rulename: "index",
              ismenu: "1",
            },
            {
              id: 2,
              parent: 0,
              text: "酒店管理",
              rulename: "hotel",
              ismenu: "1",
            },
            {
              id: 3,
              parent: 0,
              text: "数据中心",
              rulename: "datacenter",
              ismenu: "1",
            },
            {
              id: 4,
              parent: 0,
              text: "财务管理",
              rulename: "finance",
              ismenu: "1",
            },
            {
              id: 5,
              parent: 9,
              text: "渠道商政策发布",
              rulename: "infopubcoop",
              ismenu: "1",
            },
            {
              id: 6,
              parent: 0,
              text: "广告位管理",
              rulename: "advertising",
              ismenu: "1",
            },
            {
              id: 8,
              parent: 0,
              text: "合作伙伴管理",
              rulename: "partner",
              ismenu: "1",
            },
            {
              id: 9,
              parent: 0,
              text: "信息中心",
              rulename: "information",
              ismenu: "1",
            },
            {
              id: 10,
              parent: 0,
              text: "系统设置",
              rulename: "config",
              ismenu: "1",
            },
            {
              id: 11,
              parent: 2,
              text: "运营酒店列表",
              rulename: "hotelmanoperate",
              ismenu: "0",
            },
            {
              id: 12,
              parent: 2,
              text: "所有酒店列表",
              rulename: "hotelmanall",
              ismenu: "0",
            },
            {
              id: 13,
              parent: 2,
              text: "未分配酒店列表",
              rulename: "hotelmanassign",
              ismenu: "0",
            },
            {
              id: 14,
              parent: 6,
              text: "广告位列表",
              rulename: "admanspace",
              ismenu: "1",
            },
            {
              id: 15,
              parent: 6,
              text: "订单管理",
              rulename: "admanorder",
              ismenu: "0",
            },
            {
              id: 20,
              parent: 8,
              text: "合作伙伴列表",
              rulename: "cooplist",
              ismenu: "1",
            },
            {
              id: 21,
              parent: 4,
              text: "对账提现统计",
              rulename: "finmanfin",
              ismenu: "1",
            },
            {
              id: 22,
              parent: 4,
              text: "对账管理",
              rulename: "finmanrecon",
              ismenu: "1",
            },
            {
              id: 23,
              parent: 4,
              text: "申请提现",
              rulename: "finmanapply",
              ismenu: "1",
            },
            {
              id: 24,
              parent: 4,
              text: "收益管理",
              rulename: "finmanincome",
              ismenu: "1",
            },
            {
              id: 25,
              parent: 4,
              text: "提现管理",
              rulename: "finmancashout",
              ismenu: "1",
            },
            {
              id: 26,
              parent: 5,
              text: "新建",
              rulename: "policy/create",
              ismenu: "0",
            },
            {
              id: 27,
              parent: 9,
              text: "通知发布",
              rulename: "infopubnotice",
              ismenu: "1",
            },
            {
              id: 29,
              parent: 10,
              text: "账号管理",
              rulename: "sysaccman",
              ismenu: "0",
            },
            {
              id: 31,
              parent: 10,
              text: "操作日志",
              rulename: "sysoperalog",
              ismenu: "0",
            },
            {
              id: 32,
              parent: 10,
              text: "系统配置",
              rulename: "sysconfig",
              ismenu: "1",
            },
            {
              id: 53,
              parent: 3,
              text: "酒店数据统计",
              rulename: "hotelsta",
              ismenu: "1",
            },
            {
              id: 54,
              parent: 3,
              text: "收益统计",
              rulename: "incomesta",
              ismenu: "1",
            },
            {
              id: 55,
              parent: 3,
              text: "上线终端统计",
              rulename: "performancesta",
              ismenu: "1",
            },
            {
              id: 56,
              parent: 53,
              text: "列表",
              rulename: "datacenter/gethotellist",
              ismenu: "0",
            },
            {
              id: 57,
              parent: 53,
              text: "数据走势",
              rulename: "datacenter/gethotelline",
              ismenu: "0",
            },
            {
              id: 58,
              parent: 53,
              text: "数据统计",
              rulename: "datacenter/gethoteldatastatistics",
              ismenu: "0",
            },
            {
              id: 76,
              parent: 14,
              text: "新建",
              rulename: "cases/createcases",
              ismenu: "0",
            },
            {
              id: 77,
              parent: 14,
              text: "详情",
              rulename: "cases/getscasesinfo",
              ismenu: "0",
            },
            {
              id: 78,
              parent: 14,
              text: "启用",
              rulename: "cases/batchenable",
              ismenu: "0",
            },
            {
              id: 79,
              parent: 14,
              text: "停用",
              rulename: "cases/batchdisable",
              ismenu: "0",
            },
            {
              id: 80,
              parent: 14,
              text: "删除",
              rulename: "cases/batchdel",
              ismenu: "0",
            },
            {
              id: 81,
              parent: 15,
              text: "详情",
              rulename: "cases/hlcases",
              ismenu: "0",
            },
            {
              id: 87,
              parent: 20,
              text: "删除",
              rulename: "partner/destory",
              ismenu: "0",
            },
            {
              id: 88,
              parent: 20,
              text: "导出",
              rulename: "partner/export",
              ismenu: "0",
            },
            {
              id: 89,
              parent: 20,
              text: "详情",
              rulename: "partner/view",
              ismenu: "0",
            },
            {
              id: 91,
              parent: 20,
              text: "批量配置",
              rulename: "partner/batch",
              ismenu: "0",
            },
            {
              id: 92,
              parent: 20,
              text: "基础信息（详情）",
              rulename: "partner/getpartner",
              ismenu: "0",
            },
            {
              id: 93,
              parent: 20,
              text: "计划及分成比例（详情）",
              rulename: "partner/getpartnertypes",
              ismenu: "0",
            },
            {
              id: 95,
              parent: 27,
              text: "新建",
              rulename: "information/create",
              ismenu: "0",
            },
            {
              id: 100,
              parent: 27,
              text: "发布",
              rulename: "information/publish",
              ismenu: "0",
            },
            {
              id: 101,
              parent: 27,
              text: "删除",
              rulename: "information/destory",
              ismenu: "0",
            },
            {
              id: 105,
              parent: 14,
              text: "状态",
              rulename: "cases/batchenablesam",
              ismenu: "0",
            },
            {
              id: 107,
              parent: 10,
              text: "账户信息",
              rulename: "sysaccinfo",
              ismenu: "1",
            },
            {
              id: 108,
              parent: 107,
              text: "认证中心",
              rulename: "partner/center",
              ismenu: "0",
            },
            {
              id: 109,
              parent: 107,
              text: "账户信息",
              rulename: "partner/getaccountdetailbycurrentadmin",
              ismenu: "0",
            },
            {
              id: 111,
              parent: 247,
              text: "应用下载",
              rulename: "downindex",
              ismenu: "0",
            },
            {
              id: 121,
              parent: 139,
              text: "新建",
              rulename: "account/add",
              ismenu: "0",
            },
            {
              id: 122,
              parent: 139,
              text: "详情",
              rulename: "account/edit",
              ismenu: "0",
            },
            {
              id: 128,
              parent: 136,
              text: "新建",
              rulename: "group/add",
              ismenu: "0",
            },
            {
              id: 129,
              parent: 136,
              text: "详情",
              rulename: "group/edit",
              ismenu: "0",
            },
            {
              id: 130,
              parent: 136,
              text: "删除",
              rulename: "group/del",
              ismenu: "0",
            },
            {
              id: 136,
              parent: 10,
              text: "角色管理",
              rulename: "sysroleman",
              ismenu: "1",
            },
            {
              id: 139,
              parent: 10,
              text: "账号管理",
              rulename: "sysaccman",
              ismenu: "1",
            },
            {
              id: 149,
              parent: 1,
              text: "创建酒店",
              rulename: "createhotel",
              ismenu: "0",
            },
            {
              id: 150,
              parent: 1,
              text: "收益统计",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 151,
              parent: 1,
              text: "业绩完成统计",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 152,
              parent: 1,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 153,
              parent: 1,
              text: "总收益",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 154,
              parent: 1,
              text: "点播收益",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 155,
              parent: 1,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 156,
              parent: 1,
              text: "待办事项",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 158,
              parent: 12,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 159,
              parent: 12,
              text: "新建",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 160,
              parent: 12,
              text: "导入",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 161,
              parent: 12,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 162,
              parent: 12,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 163,
              parent: 12,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 164,
              parent: 12,
              text: "实施人员（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 165,
              parent: 12,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 166,
              parent: 11,
              text: "运营酒店地图",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 167,
              parent: 11,
              text: "数据统计",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 168,
              parent: 11,
              text: "排行榜",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 169,
              parent: 11,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 170,
              parent: 11,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 171,
              parent: 11,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 172,
              parent: 11,
              text: "可视化配置（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 173,
              parent: 11,
              text: "广告位管理（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 174,
              parent: 13,
              text: "分配合作伙伴",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 175,
              parent: 13,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 179,
              parent: 54,
              text: "上线费用统计",
              rulename: "index4",
              ismenu: "0",
            },
            {
              id: 181,
              parent: 54,
              text: "集团观影收益统计",
              rulename: "index6",
              ismenu: "0",
            },
            {
              id: 183,
              parent: 54,
              text: "影视收益统计",
              rulename: "index1",
              ismenu: "0",
            },
            {
              id: 185,
              parent: 55,
              text: "新增激活终端数量统计",
              rulename: "index12",
              ismenu: "0",
            },
            {
              id: 186,
              parent: 55,
              text: "新增激活终端数据走势",
              rulename: "index13",
              ismenu: "0",
            },
            {
              id: 187,
              parent: 55,
              text: "计划完成统计",
              rulename: "index14",
              ismenu: "0",
            },
            {
              id: 188,
              parent: 55,
              text: "计划完成数据走势",
              rulename: "index15",
              ismenu: "0",
            },
            {
              id: 189,
              parent: 16,
              text: "新建",
              rulename: "download/create",
              ismenu: "0",
            },
            {
              id: 190,
              parent: 16,
              text: "删除",
              rulename: "download/del",
              ismenu: "0",
            },
            {
              id: 191,
              parent: 16,
              text: "详情",
              rulename: "download/info",
              ismenu: "0",
            },
            {
              id: 192,
              parent: 111,
              text: "下载",
              rulename: "download/infos",
              ismenu: "0",
            },
            {
              id: 193,
              parent: 111,
              text: "查看更新日志",
              rulename: "download/infolog",
              ismenu: "0",
            },
            {
              id: 194,
              parent: 111,
              text: "查看接入说明文档",
              rulename: "download/infodoc",
              ismenu: "0",
            },
            {
              id: 195,
              parent: 21,
              text: "对账金额统计",
              rulename: "finmanfin/data",
              ismenu: "0",
            },
            {
              id: 196,
              parent: 21,
              text: "通过",
              rulename: "finmanfin/pass",
              ismenu: "0",
            },
            {
              id: 197,
              parent: 21,
              text: "驳回",
              rulename: "finmanfinf/reject",
              ismenu: "0",
            },
            {
              id: 198,
              parent: 22,
              text: "数据统计",
              rulename: "finmanrecon/data",
              ismenu: "0",
            },
            {
              id: 199,
              parent: 22,
              text: "列表",
              rulename: "finmanrecon/list",
              ismenu: "0",
            },
            {
              id: 200,
              parent: 23,
              text: "申请提现",
              rulename: "finmanapply/cash",
              ismenu: "0",
            },
            {
              id: 201,
              parent: 23,
              text: "提现记录列表",
              rulename: "finmanapply/record",
              ismenu: "0",
            },
            {
              id: 202,
              parent: 24,
              text: "数据统计",
              rulename: "finmancome",
              ismenu: "0",
            },
            {
              id: 203,
              parent: 24,
              text: "列表",
              rulename: "finmancome/list",
              ismenu: "0",
            },
            {
              id: 204,
              parent: 25,
              text: "通过",
              rulename: "finmancashout/pass",
              ismenu: "0",
            },
            {
              id: 205,
              parent: 25,
              text: "驳回",
              rulename: "finmancashout/reject",
              ismenu: "0",
            },
            {
              id: 207,
              parent: 25,
              text: "详情",
              rulename: "finmancashout/info",
              ismenu: "0",
            },
            {
              id: 209,
              parent: 5,
              text: "删除",
              rulename: "policy/del",
              ismenu: "0",
            },
            {
              id: 210,
              parent: 5,
              text: "发布",
              rulename: "policy/send",
              ismenu: "0",
            },
            {
              id: 211,
              parent: 139,
              text: "状态",
              rulename: "account/changestat",
              ismenu: "0",
            },
            {
              id: 212,
              parent: 32,
              text: "详情",
              rulename: "sysconfig/edit",
              ismenu: "0",
            },
            {
              id: 213,
              parent: 54,
              text: "广告收益",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 214,
              parent: 20,
              text: "认证信息（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 215,
              parent: 21,
              text: "对账单列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 216,
              parent: 21,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 217,
              parent: 22,
              text: "通过",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 218,
              parent: 22,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 219,
              parent: 107,
              text: "目标及分成比例",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 220,
              parent: 5,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 221,
              parent: 27,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 222,
              parent: 0,
              text: "任务管理",
              rulename: "task",
              ismenu: "1",
            },
            {
              id: 223,
              parent: 222,
              text: "待办任务",
              rulename: "tasktodo",
              ismenu: "1",
            },
            {
              id: 224,
              parent: 222,
              text: "已办任务",
              rulename: "taskdone",
              ismenu: "1",
            },
            {
              id: 225,
              parent: 3,
              text: "数字资产",
              rulename: "dataasset",
              ismenu: "1",
            },
            {
              id: 226,
              parent: 225,
              text: "阵地资产",
              rulename: "dataassetposition",
              ismenu: "1",
            },
            {
              id: 227,
              parent: 225,
              text: "软件资产",
              rulename: "dataassetsoft",
              ismenu: "1",
            },
            {
              id: 228,
              parent: 223,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 229,
              parent: 224,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 230,
              parent: 0,
              text: "渠道商政策",
              rulename: "policy",
              ismenu: "1",
            },
            {
              id: 231,
              parent: 230,
              text: "渠道商政策列表",
              rulename: "policylist",
              ismenu: "1",
            },
            {
              id: 232,
              parent: 1,
              text: "售卡收益",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 233,
              parent: 231,
              text: "标记为已读",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 234,
              parent: 139,
              text: "删除",
              rulename: "account/del",
              ismenu: "0",
            },
            {
              id: 237,
              parent: 2,
              text: "所有酒店管理",
              rulename: "index",
              ismenu: "1",
            },
            {
              id: 238,
              parent: 237,
              text: "实施中酒店",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 240,
              parent: 237,
              text: "实施中酒店",
              rulename: "hotelmanallimplementation",
              ismenu: "1",
            },
            {
              id: 241,
              parent: 237,
              text: "使用中酒店",
              rulename: "hotelmanallusing",
              ismenu: "1",
            },
            {
              id: 242,
              parent: 2,
              text: "新建酒店管理",
              rulename: "hotelmannewmenu",
              ismenu: "1",
            },
            {
              id: 243,
              parent: 242,
              text: "新建酒店",
              rulename: "hotelmannewlist",
              ismenu: "1",
            },
            {
              id: 244,
              parent: 237,
              text: "已验收酒店",
              rulename: "hotelmanallwaitforchecking",
              ismenu: "1",
            },
            {
              id: 245,
              parent: 237,
              text: "可运营酒店",
              rulename: "hotelmanalloperate",
              ismenu: "1",
            },
            {
              id: 246,
              parent: 237,
              text: "离线酒店",
              rulename: "hotelmanalloffline",
              ismenu: "1",
            },
            {
              id: 247,
              parent: 237,
              text: "所有酒店",
              rulename: "hotelmanall",
              ismenu: "1",
            },
            {
              id: 248,
              parent: 2,
              text: "删除酒店管理",
              rulename: "hotelmandelmenu",
              ismenu: "1",
            },
            {
              id: 249,
              parent: 2,
              text: "未分配酒店管理",
              rulename: "hotelmanassignmenu",
              ismenu: "1",
            },
            {
              id: 250,
              parent: 248,
              text: "删除酒店",
              rulename: "hotelmandel",
              ismenu: "1",
            },
            {
              id: 251,
              parent: 249,
              text: "未分配酒店",
              rulename: "hotelmanassigntest",
              ismenu: "1",
            },
            {
              id: 252,
              parent: 240,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 253,
              parent: 240,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 254,
              parent: 240,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 255,
              parent: 240,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 256,
              parent: 240,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 257,
              parent: 240,
              text: "实施人员（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 258,
              parent: 240,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 259,
              parent: 240,
              text: "素材编辑（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 262,
              parent: 245,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 263,
              parent: 245,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 264,
              parent: 245,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 265,
              parent: 245,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 266,
              parent: 245,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 267,
              parent: 245,
              text: "素材编辑（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 268,
              parent: 244,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 269,
              parent: 244,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 270,
              parent: 244,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 271,
              parent: 244,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 272,
              parent: 244,
              text: "实施人员（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 273,
              parent: 244,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 274,
              parent: 245,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 275,
              parent: 244,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 276,
              parent: 241,
              text: "运营酒店地图",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 277,
              parent: 241,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 278,
              parent: 241,
              text: "排行榜",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 279,
              parent: 241,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 280,
              parent: 241,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 281,
              parent: 246,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 282,
              parent: 246,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 283,
              parent: 246,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 284,
              parent: 246,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 285,
              parent: 246,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 286,
              parent: 246,
              text: "实施人员（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 287,
              parent: 247,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 289,
              parent: 247,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 290,
              parent: 247,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 291,
              parent: 247,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 293,
              parent: 247,
              text: "实施人员（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 294,
              parent: 247,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 295,
              parent: 247,
              text: "素材编辑（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 296,
              parent: 250,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 297,
              parent: 250,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 298,
              parent: 250,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 299,
              parent: 250,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 300,
              parent: 250,
              text: "申请恢复",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 301,
              parent: 251,
              text: "分配合作伙伴",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 302,
              parent: 251,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 303,
              parent: 243,
              text: "数据概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 306,
              parent: 241,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 307,
              parent: 241,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 308,
              parent: 241,
              text: "素材编辑（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 310,
              parent: 243,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 311,
              parent: 243,
              text: "新建",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 312,
              parent: 243,
              text: "聚屏酒店导入",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 313,
              parent: 243,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 314,
              parent: 243,
              text: "列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 315,
              parent: 243,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 316,
              parent: 243,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 317,
              parent: 251,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 318,
              parent: 240,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 319,
              parent: 241,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 320,
              parent: 244,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 321,
              parent: 245,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 322,
              parent: 246,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 323,
              parent: 247,
              text: "删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 324,
              parent: 241,
              text: "数据走势",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 328,
              parent: 243,
              text: "实施人员（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 329,
              parent: 243,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 331,
              parent: 243,
              text: "素材编辑（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 332,
              parent: 243,
              text: "导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 333,
              parent: 55,
              text: "导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 334,
              parent: 139,
              text: "导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 335,
              parent: 20,
              text: "不计算收益酒店（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 336,
              parent: 247,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 337,
              parent: 246,
              text: "上线情况（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 338,
              parent: 139,
              text: "修改验证码",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 339,
              parent: 20,
              text: "不计算收益酒店",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 340,
              parent: 8,
              text: "待配置酒店列表",
              rulename: "tobeconfiguredhotel",
              ismenu: "1",
            },
            {
              id: 341,
              parent: 340,
              text: "酒店详情（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 342,
              parent: 340,
              text: "计划及分成比例（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 343,
              parent: 10,
              text: "流程列表",
              rulename: "sysprocess",
              ismenu: "1",
            },
            {
              id: 344,
              parent: 243,
              text: "聚屏商导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 345,
              parent: 247,
              text: "导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 346,
              parent: 4,
              text: "待对账管理",
              rulename: "finmanreconimhotal",
              ismenu: "1",
            },
            {
              id: 347,
              parent: 247,
              text: "广告下架",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 348,
              parent: 247,
              text: "添加实施人员",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 349,
              parent: 247,
              text: "删除实施人员",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 350,
              parent: 55,
              text: "渠道导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 351,
              parent: 243,
              text: "虚拟运营导入",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 352,
              parent: 25,
              text: "上传汇款单",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 353,
              parent: 25,
              text: "已付款",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 354,
              parent: 346,
              text: "生成对账单",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 355,
              parent: 22,
              text: "扣款",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 356,
              parent: 55,
              text: "24城导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 357,
              parent: 2,
              text: "验收酒店管理",
              rulename: "hotelmanallwaitforcheckingmenu",
              ismenu: "1",
            },
            {
              id: 358,
              parent: 357,
              text: "可提交验收酒店",
              rulename: "hotelmanallwaitforcheckinglist",
              ismenu: "1",
            },
            {
              id: 359,
              parent: 358,
              text: "发起验收",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 360,
              parent: 55,
              text: "未活跃导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 361,
              parent: 14,
              text: "新建广告位",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 362,
              parent: 0,
              text: "独立影视财务",
              rulename: "independentfinance",
              ismenu: "1",
            },
            {
              id: 363,
              parent: 362,
              text: "对账提现统计",
              rulename: "iddfinmanfin",
              ismenu: "1",
            },
            {
              id: 364,
              parent: 362,
              text: "待对账管理",
              rulename: "iddfinmanreconimhotal",
              ismenu: "1",
            },
            {
              id: 365,
              parent: 362,
              text: "对账管理",
              rulename: "iddfinmanrecon",
              ismenu: "1",
            },
            {
              id: 366,
              parent: 362,
              text: "申请提现",
              rulename: "iddfinmanapply",
              ismenu: "1",
            },
            {
              id: 367,
              parent: 362,
              text: "提现管理",
              rulename: "iddfinmancashout",
              ismenu: "1",
            },
            {
              id: 368,
              parent: 3,
              text: "独立影视收益",
              rulename: "iddincomesta",
              ismenu: "1",
            },
            {
              id: 370,
              parent: 363,
              text: "对账金额统计",
              rulename: "iddfinmanfin/data",
              ismenu: "0",
            },
            {
              id: 371,
              parent: 363,
              text: "通过",
              rulename: "iddfinmanfin/pass",
              ismenu: "0",
            },
            {
              id: 372,
              parent: 363,
              text: "驳回",
              rulename: "iddfinmanfin/reject",
              ismenu: "0",
            },
            {
              id: 373,
              parent: 365,
              text: "数据统计",
              rulename: "iddfinmanrecon/data",
              ismenu: "0",
            },
            {
              id: 374,
              parent: 365,
              text: "列表",
              rulename: "iddfinmanrecon/list",
              ismenu: "0",
            },
            {
              id: 375,
              parent: 366,
              text: "申请提现",
              rulename: "iddfinmanapply/cash",
              ismenu: "0",
            },
            {
              id: 376,
              parent: 366,
              text: "提现记录列表",
              rulename: "iddfinmanapply/record",
              ismenu: "0",
            },
            {
              id: 377,
              parent: 367,
              text: "通过",
              rulename: "iddfinmancashout/pass",
              ismenu: "0",
            },
            {
              id: 378,
              parent: 367,
              text: "驳回",
              rulename: "iddfinmancashout/reject",
              ismenu: "0",
            },
            {
              id: 379,
              parent: 367,
              text: "详情",
              rulename: "iddfinmancashout/info",
              ismenu: "0",
            },
            {
              id: 380,
              parent: 368,
              text: "上线费用统计",
              rulename: "finindex4",
              ismenu: "0",
            },
            {
              id: 381,
              parent: 368,
              text: "集团观影收益统计",
              rulename: "iddindex6",
              ismenu: "0",
            },
            {
              id: 382,
              parent: 368,
              text: "影视收益统计",
              rulename: "iddindex1",
              ismenu: "0",
            },
            {
              id: 383,
              parent: 368,
              text: "广告收益",
              rulename: "iddindex",
              ismenu: "0",
            },
            {
              id: 384,
              parent: 367,
              text: "已付款",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 385,
              parent: 367,
              text: "上传汇款单",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 386,
              parent: 364,
              text: "生成对账单",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 387,
              parent: 363,
              text: "扣款",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 388,
              parent: 225,
              text: "资产报表",
              rulename: "statementassets",
              ismenu: "1",
            },
            {
              id: 389,
              parent: 1,
              text: "独立影视收益",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 390,
              parent: 225,
              text: "数据资产列表",
              rulename: "dataassetlist",
              ismenu: "1",
            },
            {
              id: 391,
              parent: 3,
              text: "独立影视总览",
              rulename: "iddmovieoverview",
              ismenu: "1",
            },
            {
              id: 392,
              parent: 3,
              text: "影视总览",
              rulename: "datamovieoverview",
              ismenu: "1",
            },
            {
              id: 393,
              parent: 54,
              text: "历史收益",
              rulename: "index5",
              ismenu: "0",
            },
            {
              id: 394,
              parent: 21,
              text: "扣款",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 395,
              parent: 0,
              text: "优客财务",
              rulename: "",
              ismenu: "1",
            },
            {
              id: 396,
              parent: 395,
              text: "提现管理",
              rulename: "uokowithdrawalmanage",
              ismenu: "1",
            },
            {
              id: 397,
              parent: 395,
              text: "提现统计",
              rulename: "uokofinmanfin",
              ismenu: "1",
            },
            {
              id: 398,
              parent: 10,
              text: "标签管理",
              rulename: "label",
              ismenu: "1",
            },
            {
              id: 399,
              parent: 398,
              text: "标签记录",
              rulename: "syslabelhistory",
              ismenu: "1",
            },
            {
              id: 400,
              parent: 398,
              text: "新建标签",
              rulename: "syslabel",
              ismenu: "1",
            },
            {
              id: 401,
              parent: 399,
              text: "打标签",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 402,
              parent: 399,
              text: "下架标签",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 403,
              parent: 399,
              text: "导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 404,
              parent: 247,
              text: "标签管理",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 405,
              parent: 400,
              text: "标签列表新建",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 406,
              parent: 400,
              text: "标签列表删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 407,
              parent: 400,
              text: "标签列表导出",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 408,
              parent: 400,
              text: "标签列表编辑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 409,
              parent: 400,
              text: "标签分类编辑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 410,
              parent: 400,
              text: "标签分类新建",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 411,
              parent: 400,
              text: "标签分类删除",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 412,
              parent: 247,
              text: "标签编辑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 413,
              parent: 3,
              text: "合同列表",
              rulename: "usercontractlist",
              ismenu: "1",
            },
            {
              id: 414,
              parent: 247,
              text: "模式修改",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 415,
              parent: 247,
              text: "广告标签编辑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 416,
              parent: 8,
              text: "数据修正管理",
              rulename: "coopdatareset",
              ismenu: "1",
            },
            {
              id: 417,
              parent: 399,
              text: "广告标签编辑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 418,
              parent: 399,
              text: "标签编辑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 419,
              parent: 1,
              text: "渠道收益概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 420,
              parent: 1,
              text: "渠道合同列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 430,
              parent: 243,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 431,
              parent: 238,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 432,
              parent: 240,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 433,
              parent: 241,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 434,
              parent: 244,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 435,
              parent: 245,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 436,
              parent: 246,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 437,
              parent: 247,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 438,
              parent: 1,
              text: "渠道终端概览",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 439,
              parent: 250,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 440,
              parent: 251,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 441,
              parent: 358,
              text: "跳转",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 442,
              parent: 237,
              text: "授权记录",
              rulename: "authorizationrecordlist",
              ismenu: "1",
            },
            {
              id: 443,
              parent: 243,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 444,
              parent: 238,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 445,
              parent: 240,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 446,
              parent: 241,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 447,
              parent: 244,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 448,
              parent: 245,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 449,
              parent: 246,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 450,
              parent: 247,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 451,
              parent: 250,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 452,
              parent: 251,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 453,
              parent: 358,
              text: "终端管理密码（详情）",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 454,
              parent: 247,
              text: "授权管理",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 455,
              parent: 247,
              text: "授权关闭",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 456,
              parent: 247,
              text: "授权生成",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 457,
              parent: 222,
              text: "全部任务",
              rulename: "taskall",
              ismenu: "1",
            },
            {
              id: 458,
              parent: 457,
              text: "详情",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 459,
              parent: 10,
              text: "账户管理",
              rulename: "sysaccountmnagement",
              ismenu: "1",
            },
            {
              id: 460,
              parent: 0,
              text: "运维管理",
              rulename: "operation",
              ismenu: "1",
            },
            {
              id: 461,
              parent: 460,
              text: "内容管理",
              rulename: "content",
              ismenu: "1",
            },
            {
              id: 462,
              parent: 460,
              text: "设备管理",
              rulename: "equipment",
              ismenu: "1",
            },
            {
              id: 463,
              parent: 461,
              text: "批量配置",
              rulename: "mulsetting",
              ismenu: "1",
            },
            {
              id: 464,
              parent: 461,
              text: "云Apk配置",
              rulename: "apkmanage",
              ismenu: "1",
            },
            {
              id: 465,
              parent: 461,
              text: "配置项管理",
              rulename: "settingmanage",
              ismenu: "1",
            },
            {
              id: 466,
              parent: 462,
              text: "签名管理",
              rulename: "signmanage",
              ismenu: "1",
            },
            {
              id: 467,
              parent: 462,
              text: "酒店模板管理",
              rulename: "hotelmodulemanage",
              ismenu: "1",
            },
            {
              id: 468,
              parent: 462,
              text: "eteams设备类型管理",
              rulename: "eteamsmanage",
              ismenu: "1",
            },
            {
              id: 469,
              parent: 237,
              text: "酒店分类",
              rulename: "hotelclassify",
              ismenu: "1",
            },
            {
              id: 470,
              parent: 461,
              text: "launcher配置",
              rulename: "launchersetting",
              ismenu: "1",
            },
            {
              id: 471,
              parent: 247,
              text: "远程维护",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 472,
              parent: 247,
              text: "系统管理",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 473,
              parent: 247,
              text: "发布列表",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 474,
              parent: 247,
              text: "指令发布",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 475,
              parent: 247,
              text: "launcher配置",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 476,
              parent: 247,
              text: "应用配置",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 477,
              parent: 247,
              text: "版本控制",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 478,
              parent: 247,
              text: "操作日志",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 479,
              parent: 247,
              text: "基础设置",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 480,
              parent: 247,
              text: "房间管理",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 481,
              parent: 462,
              text: "终端上报日志",
              rulename: "applog",
              ismenu: "1",
            },
            {
              id: 482,
              parent: 225,
              text: "资产收益统计",
              rulename: "dataincomestatistic",
              ismenu: "1",
            },
            {
              id: 483,
              parent: 0,
              text: "CIBN播控认证",
              rulename: "cibn",
              ismenu: "1",
            },
            {
              id: 484,
              parent: 483,
              text: "认证设置",
              rulename: "cibnsetting",
              ismenu: "1",
            },
            {
              id: 485,
              parent: 460,
              text: "系统配置",
              rulename: "functionsetting",
              ismenu: "1",
            },
            {
              id: 486,
              parent: 462,
              text: "存储位置管理",
              rulename: "saveplacelist",
              ismenu: "1",
            },
            {
              id: 487,
              parent: 247,
              text: "刊位管理",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 488,
              parent: 247,
              text: "虚拟房间",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 489,
              parent: 247,
              text: "新建分组",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 490,
              parent: 247,
              text: "修改名称",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 491,
              parent: 247,
              text: "MAC解绑",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 492,
              parent: 247,
              text: "乐播授权查询",
              rulename: "index",
              ismenu: "0",
            },
            {
              id: 493,
              parent: 247,
              text: "发布",
              rulename: "hotelvisualization/setlauncher",
              ismenu: "0",
            },
            {
              id: 494,
              parent: 460,
              text: "报障列表",
              rulename: "reporting",
              ismenu: "1",
            },
            {
              id: 495,
              parent: 398,
              text: "NO广告管理",
              rulename: "noddvertisement",
              ismenu: "1",
            },
            {
              id: 496,
              parent: 460,
              text: "批量下发",
              rulename: "batchupdata",
              ismenu: "1",
            },
            {
              id: 497,
              parent: 0,
              text: "内容监管",
              rulename: "siadmin",
              ismenu: "1",
            },
            {
              id: 498,
              parent: 497,
              text: "监管大屏",
              rulename: "screenpage",
              ismenu: "1",
            },
            {
              id: 499,
              parent: 497,
              text: "监管执行",
              rulename: "execute",
              ismenu: "1",
            },
            {
              id: 500,
              parent: 497,
              text: "内容监管",
              rulename: "warninglist",
              ismenu: "1",
            },
            {
              id: 501,
              parent: 497,
              text: "信息设置",
              rulename: "warningmsg",
              ismenu: "1",
            },
            {
              id: 502,
              parent: 497,
              text: "信息发布",
              rulename: "msgrelease",
              ismenu: "1",
            },
            {
              id: 503,
              parent: 497,
              text: "广电巡检",
              rulename: "hotelexecute",
              ismenu: "1",
            },
            {
              id: 504,
              parent: 497,
              text: "业务开通",
              rulename: "importlist",
              ismenu: "1",
            },
            {
              id: 505,
              parent: 247,
              text: "发布审核",
              rulename: "setlauncherpro",
              ismenu: "0",
            },
            {
              id: 506,
              parent: 0,
              text: "分组管理",
              rulename: "roomgroupma",
              ismenu: "1",
            },
            {
              id: 507,
              parent: 506,
              text: "分组列表",
              rulename: "hotelgrouplist",
              ismenu: "1",
            },
            {
              id: 508,
              parent: 0,
              text: "信息发布",
              rulename: "sendmsg",
              ismenu: "1",
            },
            {
              id: 509,
              parent: 508,
              text: "定时插播",
              rulename: "hotelmsgreleaselist",
              ismenu: "1",
            },
            {
              id: 510,
              parent: 3,
              text: "商务上线统计",
              rulename: "businessperformancesta",
              ismenu: "1",
            },
            {
              id: 511,
              parent: 460,
              text: "空间不足列表",
              rulename: "spacenotlist",
              ismenu: "1",
            },
          ];
          sessionStorage.setItem("treeData", JSON.stringify(list));
          this.$router.push("/hotelInfoPageApaas");
        }
      });
  },
};
</script>

