import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
//  a列表
export function getList(data) {
  return request({
    url: `${vi}/Hotelparameter/getList`,
    method: 'post',
    data,
  });
}
// 新增
export function create(data) {
  return request({
    url: `${vi}/Hotelparameter/addParameter`,
    method: 'post',
    data,
  });
}
// 修改
export function updateData(data) {
  return request({
    url: `${vi}/Hotelparameter/editParameter`,
    method: 'post',
    data,
  });
}
// 删除
export function allDelete(data) {
  return request({
    url: `${vi}/Hoteltelevision/delTv`,
    method: 'post',
    data,
  });
}
// 获取下拉数据
export function getMenuModule(data) {
  return request({
    url: `${vi}/Content/getMenuModule   `,
    method: 'post',
    data,
  });
}


