<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">广告管理</span>
                <span slot="third">自定义广告管理</span>
                <span slot="fourth">新建</span>
            </top-bread>
        </div>

        <el-card class="box-card">
            <el-tabs v-model="tab" @tab-click="handleClick">
                <el-tab-pane label="关联携旅刊位" name="relation">
                    <ad-cus-tab-rela></ad-cus-tab-rela>
                </el-tab-pane>
                <el-tab-pane label="自定义刊位" name="custom">
                    <ad-cus-tab-cus :type="type"></ad-cus-tab-cus>
                </el-tab-pane>
            </el-tabs>
        </el-card>

    </div>
</template>

<script>
    export default {
        name: "AdManCusNew",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/adManCus'},
            },
            tab:'relation',
            type:'self'
        }),
        methods:{
            handleClick(tab, event) {

            }
        },
        mounted(){
            var query=this.$route.query;

        }
    }
</script>

<style scoped lang="scss">

</style>
