<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">CIBN播控配置</span>
        <span slot="third">认证设置</span>
      </top-bread>
    </div>
    <el-card class="box-card contentBox">
      <el-form :model="setForm" :rules="rules" ref="setForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="弹窗开关" prop="isOn">
          <el-switch v-model="setForm.isOn" active-value="1" active-text="打开" inactive-text="关闭" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="弹窗设置" prop="displayNumber">
          <el-radio-group v-model="setForm.displayNumber">
            <el-radio label="1">单次弹窗</el-radio>
            <el-radio label="-1">多次弹窗</el-radio>
          </el-radio-group>
          <!-- <div class="msg">如选“单次弹窗”，终端显示一次后不再显示；如选“多次弹窗”，终端将不再弹窗，无论是否已经弹过</div> -->
        </el-form-item>

        <el-form-item label="弹窗内容" prop="bkgImage">
          <uploadLogo style="margin-top:15px" v-model="setForm.bkgImage" @change="changeData"></uploadLogo>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('setForm')">提交</el-button>
        </el-form-item>
      </el-form>
      <img class="pic" src="@/assets/CIBN.png" alt="">
    </el-card>

  </div>
</template>

<script>
import qs from "querystring";
import uploadLogo from "@/components/common/uploadLogo.vue";
import { editCibn, getCibn } from "@/api/CIBN.js";
export default {
  name: "CIBNSetting",
  components: {
    uploadLogo,
  },
  data() {
    return {
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },
      setForm: {
        isOn: "0",
        displayNumber: "1",
        bkgImage: "",
      },
      rules: {
        isOn: { required: true, message: "", trigger: "blur" },
        displayNumber: { required: true, message: "", trigger: "blur" },
        bkgImage: {
          required: true,
          message: "请选择弹框内容",
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getCibn()
        .then((res) => {
          if (res.data.code == 100000) {
            this.setForm = {
              isOn: String(res.data.data.isOn),
              displayNumber: String(res.data.data.displayNumber),
              bkgImage: res.data.data.bkgImage,
            };
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("信息获取失败");
        });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确认提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              editCibn(this.setForm)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("修改成功");
                    this.getData();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {
                  this.$message.warning("配置失败");
                });
            })
            .catch(() => {});
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item__content {
  min-height: 40px;
  line-height: 40px;
  margin-left: 120px !important;
  position: relative;
}
.msg {
  position: absolute;
  bottom: -20px;
  left: 0px;
  font-size: 12px;
  color: #999;
}
.contentBox {
  position: relative;
}
.pic {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  width: 384px;
  height: 216px;
}
</style>
