<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="700px" :before-close="handleClose" append-to-body>
      <div class="container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="版本号" prop="emusic_code">
            <el-input v-model="ruleForm.emusic_code" placeholder="请输入版本号"></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="emusic_name">
            <el-input v-model="ruleForm.emusic_name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="资源地址" prop="videoRes">
            <UploadFile :urlFile="ruleForm.videoRes" @uploadFileChange="uploadFileChange"></UploadFile>
            <!-- <el-input v-model="ruleForm.videoRes" placeholder="请输入包名称"></el-input> -->
          </el-form-item>
          <el-form-item label="排序" prop="emusic_order">
            <el-input v-model="ruleForm.emusic_order" placeholder="请输入序号"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="editstate">
            <el-select v-model="ruleForm.editstate" placeholder="请选择">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="属性" prop="emusic_type">
            <el-select v-model="ruleForm.emusic_type" placeholder="请选择">
              <el-option v-for="item in emusicTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { create ,updateData} from "../../api/application/downloadConfig";
import UploadFile from "./uploadFile.vue";
export default {
  components: {
    UploadFile
  },
  data () {
    return {
      title: '',
      database: '',
      dialogVisible: false,
      ruleForm: {
        database: '',
        id: '',
        emusic_code: '', //版本号
        emusic_name: '',
        videoRes: '', 
        editstate: '3', 
        emusic_type: '1',
        emusic_order: '' , // 排序号
      },
      typeList: [
        {value: '0',label: '音乐'},
        {value: '1',label: '字体'},
        {value: '2',label: '视频'},
        {value: '3',label: '其他'},
      ],
      emusicTypeList: [
        {value: '1', label: '中文' },
        {value: '2', label: '英文' },
        {value: '3', label: '日文' },
        {value: '4', label: '韩文' }
      ],
      rules: {
        emusic_code: [
          { required: true, message: '请输入版本号', trigger: 'blur' },
        ],
        emusic_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        videoRes: [
          { required: true, message: '请输入上传资源', trigger: 'blur' }
        ],
        emusic_type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        editstate: [
          { required: true, message: '请选择属性', trigger: 'blur' }
        ],
        emusic_order: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
      },
      btnLoading: false
    }
  },
  methods: {
    open(title,database,row,) {
      this.title = title;
      this.database = database;
      this.dialogVisible = true;
      if (!row || row.id == '' || row.id === null) {
        // 新增
        for (const key in this.ruleForm) {
          if (Object.hasOwnProperty.call(this.ruleForm, key)) {
            this.ruleForm[key] = '';
          }
        }
        this.ruleForm.editstate = '3';
        this.ruleForm.emusic_type = '1';
      } else {
        // 编辑
        console.log(row);
        this.ruleForm = {
          database: this.database,
          id: row.id,
          emusic_code: row.music_code, //版本号
          emusic_name: row.music_name,
          videoRes: row.music_addr, 
          editstate: row.state, 
          emusic_type: row.music_type,
          emusic_order: row.music_order
        }
       
      }
      
    },
    handleClose() {
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = ''
        }
      }
      this.dialogVisible = false
    },
    uploadFileChange(fileUrl) {
      this.ruleForm.videoRes = fileUrl
    },
    async submitForm(formName) {
      this.$refs[formName].validate( async ( valid) => {
        if (valid) {
          let res;
          this.ruleForm.database = this.database;
          this.btnLoading = true;
          if (this.ruleForm.id) {
            // 编辑
            res = await updateData(this.ruleForm);
          } else {
            // 新增
            res = await create(this.ruleForm);
          }
          if (res.data.code === 100000) {
            let tips = this.ruleForm.id? '编辑' : '新增'
            this.$message({
              showClose: true,
              message: `${tips}成功`,
              type: 'success'
            });
            this.dialogVisible = false;
            this.$parent.getList(1);
            this.btnLoading = false;
          } else {
            this.$message.error(res.data.msg);
          }
          this.btnLoading = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style>

</style>