	<template >
  <div>
    <!-- 面包屑 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span v-if='!query' slot="second">数据中心</span>
        <span v-if='!query' slot="third">数字资产</span>
        <span v-if='!query' slot="fourth">数据资产列表</span>
        <span v-if='query' slot="second">数据资产列表</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <!-- 面包屑 -->
    <!-- 标签切换按钮 -->
    <el-tabs class="incomeTab" v-model="activeName" @tab-click="changeTabData">
      <el-tab-pane label="基础数据" name="1"></el-tab-pane>
      <el-tab-pane label="品牌型号" name="2"></el-tab-pane>
      <el-tab-pane label="标签" name="3"></el-tab-pane>
      <el-tab-pane label="eHotel版本" name="4"></el-tab-pane>
      <el-tab-pane label="Upgrade版本" name="5"></el-tab-pane>
    </el-tabs>
    <!-- 标签切换按钮 -->
    <!-- 筛选界面 -->
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <listForSearch ref="listForSearch" :tabName="activeName" @defaultdimension="defaultDimension" :tablePropList="tablePropList" @defaultsearchdata="getSearchDate"></listForSearch>
    </el-card>
    <!-- 筛选界面 -->
    <!-- 列表 -->
    <el-card class="box-card">
      <el-row class="btnRow">
        <div>
          <el-button class="largeBtn" type="warning" size="medium" :loading="exportLoding" @click="exportFile">导出</el-button>
        </div>
      </el-row>
      <el-table class="tableBox" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="table.loading">
        <el-table-column :render-header="headerWidthFit" v-for="(item,index) in tablePropList" :key="index" show-overflow-tooltip :prop="item.field" :width="getTitleWidth(item.field)" :fixed="getFixed(item.field)" :label="item.field_name">
          <template slot-scope="scope">
            <span>{{item.field==='tvos_addtime'?scope.row[item.field].substring(0,10):scope.row[item.field]}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[10, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 列表 -->
  </div>
</template>
<script>
// 引入组件
import listForSearch from "./components/DataAssetForListSearch.vue";
import qs from "querystring";
import { getTableHeader, getDataList, exportFile } from "@/api/dataCenter.js";
export default {
  // 组件名称
  name: "DataAssetForList",
  // 子组件注册
  components: {
    listForSearch,
  },
  data() {
    return {
      query: null,
      bread: {
        level: 4,
      },
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      table: {
        page: 1,
        limit: 10,
        total: 0,
        loading: false,
      },
      tablePropList: [],
      tableList: [],
      // 表格头样式
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      activeName: "1",
      searchForm: {},
      exportLoding: false,
    };
  },

  computed: {
    // 列表项宽度计算
    getTitleWidth() {
      return function (val) {
        if (
          val === "hotel_contract" ||
          val === "hotel_name" ||
          val === "channel_name"
        ) {
          return 300;
        } else if (val === "pms_type" || val === "tvos_name") {
          return 140;
        } else if (
          val === "version_autoservice" ||
          val === "version_datamanager"
        ) {
          return 160;
        } else if (val === "tvlive_app") {
          return 180;
        } else if (val === "server_address" || val === "tvos_focus_menu") {
          return 160;
        } else if (val === "device_label_name") {
          return 170;
        } else if (val === "tag") {
          return 500;
        }
      };
    },
    // 是否固定栏
    getFixed() {
      return function (val) {
        if (val === "hotel_contract" || val === "hotel_name") {
          return true;
        }
      };
    },
  },
  methods: {
    // 标签维度切换
    defaultDimension(val) {
      this.searchForm = val; //将搜索数据赋值
      this.table.page = 1; // 重置页码
      this.getTableHeaderList(this.searchForm.dimension); //重新获取表头映射
      this.getTableList(); //获取列表
    },
    // 导出数据
    exportFile() {
      // 导出二次确认
      this.$confirm("是否确认导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.exportLoding = true;
          this.$message.success(
            "列表导出操作正在处理,文件处理完毕将自动下载,请稍等"
          );
          let json = this.searchForm;
          json.type = this.activeName;
          var param = qs.stringify(json);
          exportFile(param)
            .then((res) => {
              const content = res.data;
              const blob = new Blob([content]);
              const fileName = "数据资产列表.csv";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                // 释放URL 对象
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
                this.exportLoding = false;
                this.$message.success("导出成功");
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
                this.exportLoding = false;
                this.$message.success("导出成功");
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 接收搜索参数
    getSearchDate(val) {
      this.searchForm = val; //将搜索数据赋值
      this.table.page = 1; // 重置页码
      this.getTableList(); //获取列表
    },
    // 获取表格数据
    getTableList() {
      this.table.loading = true; // 加载状态
      this.searchForm.type = this.activeName; //列表类型
      this.searchForm.page = this.table.page; // 页码
      this.searchForm.limit = this.table.limit; //  页条目
      let json = JSON.parse(JSON.stringify(this.searchForm)); // 构建接口参数对象
      let param = qs.stringify(json); //  参数格式转码
      //  获取表格=数据接口
      getDataList(param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.tableList = res.data.data.data; //列表赋值
            this.table.total = res.data.data.total; // 总数赋值
          } else {
            this.$message.warning(res.data.msg);
          }
          this.table.loading = false; // 加载状态
        })
        .catch((err) => {
          this.table.loading = false;
        }); // 加载状态
    },
    // 动态渲染表头
    headerWidthFit(h, { column, index }) {
      if (column.label) {
        //获取到表头数据
        let long = column.label.length; //数据长度
        let size = 14;
        column.minWidth = long * size + 40; // 配置最小宽度
        return h(
          "span",
          { class: "cell-content", style: { "min-width": column.minWidth } },
          column.label
        ); // 渲染表头
      }
    },
    // 获取当前表头数据及筛选数据
    getTableHeaderList(val) {
      // 参数json
      let json = {
        type: this.activeName,
        dimension: val ? val : "",
      };
      // json 格式转化
      let param = qs.stringify(json);
      // 使用获取映射api
      getTableHeader(param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.tablePropList = res.data.data; //表头映射关系列表
          }
        })
        .catch((err) => {});
    },
    // 切换tab栏目 获取对应数据
    changeTabData() {
      this.table.page = 1; // 重置页码
      this.getTableHeaderList(); // 获取表头映射关系
      this.$refs.listForSearch.recetSearchForm(); // 重置筛选界面  刷新列表重新获取数据
    },
    // 分页-- 页条目
    handleSizeChange(val) {
      this.table.limit = val;
      this.getTableList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.table.page = val;
      this.getTableList();
    },
    // 筛选是否展示
    getFoldMeth(val) {
      this.fold = val;
    },
  },
  // 数据挂载阶段
  mounted() {
    // 获取初始化默认数据信息
    this.getTableHeaderList();
    this.getTableList();
    this.query = sessionStorage.getItem("apaasData");
    if(this.query){
      this.bread.level=3
    }
  },
};
</script>
<style scoped>
.rowBox {
  margin-top: 20px;
  box-sizing: border-box;
}
.btnRow {
  display: flex;
  margin-bottom: 20px;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
::v-deep .el-table .cell.el-tooltip {
  min-width: 100% !important;
}
</style>
