<template>
  <div style="width:100%">

    <div class="listBox">
      <div class="listCard" v-for="item in topList" :key="item.sort">
        <span :class="item.sort<4?'sortOne':'sortTwo'">{{item.sort}}</span>
        <div class="valueShow">
          <div class="itemName">{{item.name}}</div>
          <div class="itemWidth">
            <div :class="item.sort<4?'lineOne':'lineTwo'" :style='{width:item.width+"%"}'></div>
          </div>
        </div>
        <span class="timeShow">{{item.dataValue}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "storeTop",
  props: ["type"],
  data() {
    return {
      date: [],
      userInfo: {},
      hotel_contract: null,
      topList: [],
    };
  },
  methods: {
    // 获取今日数据
    getTodayOnLineDuration() {
      let arr = [
        {
          name: "途客中国轻居酒店沈阳长白岛店",
          dataValue: 5850,
        },
        {
          name: "沈阳于洪希尔顿惠庭酒店",
          dataValue: 1650,
        },
        {
          name: "白玉兰酒店沈阳黎明广场地铁站店",
          dataValue: 592,
        },
        {
          name: "希岸酒店沈阳奥体中心地铁站店",
          dataValue: 305,
        },
        {
          name: "格林豪泰智选酒店沈阳中街店",
          dataValue: 150,
        },
      ];
       let arr1 = [
        {
          name: "CCTV-1",
          dataValue: 5850,
        },
        {
          name: "湖南卫视",
          dataValue: 1650,
        },
        {
          name: "浙江卫视",
          dataValue: 592,
        },
        {
          name: "山东卫视",
          dataValue: 305,
        },
        {
          name: "江苏卫视",
          dataValue: 150,
        },
      ];
      if(this.type==1){
        this.topList = this.getValueForWidth(arr);
      }else{
        this.topList = this.getValueForWidth(arr1);
      }
     
      return;
      let json = {
        hotel_contract: this.hotel_contract,
        start_date: this.date[0],
        end_date: this.date[1],
      };
      getTodayOnLineDuration(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (
              res.data.data &&
              res.data.data.rows &&
              res.data.data.rows.length > 0
            ) {
              console.log(res, "有数据");
              this.topList = this.getValueForWidth(res.data.data.rows);
            } else {
              return;
            }
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },

    // 列表数据处理
    getValueForWidth(list) {
      let arr = list;
      let defaultArr = [];
      let max = list[0].dataValue;
      defaultArr = arr.map((i, n) => {
        let obj = {
          sort: n + 1,
          name: i.name,
          dataValue: i.dataValue,
          width: max == 0 ? 0 : (i.dataValue / max).toFixed(2) * 100,
        };
        return obj;
      });
      return defaultArr;
    },
  },
  mounted() {
    this.getTodayOnLineDuration();
  },
};
</script>

<style scoped lang="scss">
.timeBox {
  height: 30px;
  line-height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    margin-right: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 80px;
    border: 1px solid #0f8ee9;
    box-sizing: border-box;
    background: rgba(74, 199, 254, 0.2);
    font-size: 14px;
    color: #81afff;
    cursor: pointer;
    opacity: 0.5;
  }
  span.active {
    opacity: 1;
    border: 1px solid #4978ff;
    background: rgba($color: #4ca7fe, $alpha: 0.2);
    color: #fff;
  }
  span:last-child {
    margin-right: 0px;
  }
}
.listCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding: 0 40px;
  box-sizing: border-box;
  margin-top: 14px;
  .sortOne {
    border: 1px solid #04c5f9;
    color: #04c5f9;
    font-size: 14px;
    height: 24px;
    width: 34px;
    text-align: center;
    line-height: 24px;
    box-sizing: border-box;
    font-family: "DItalic";
  }
  .sortTwo {
    border: 1px solid #7997fe;
    box-sizing: border-box;
    color: #7997fe;
    font-size: 14px;
    height: 24px;
    width: 34px;
    text-align: center;
    line-height: 24px;
    font-family: "DItalic";
  }
  .valueShow {
    width: calc(100% - 140px);
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 3px;
    box-sizing: border-box;
    height: 28px;
    .itemName {
      font-size: 12px;
      color: #d0e8ff;
    }
    .itemWidth {
      height: 14px;
      width: 100%;
      margin-top: 3px;
      .lineOne {
        height: 8px;
        background: linear-gradient(to right, rgba(11, 21, 35, 0.48), #04c5f9);
      }
      .lineTwo {
        height: 8px;
        background: linear-gradient(to right, rgba(11, 17, 35, 0.48), #7997fe);
      }
    }
  }
  .timeShow {
    width: 80px;
    text-align: right;
    font-size: 12px;
    color: #d0e8ff;
  }
}
.listCard:first-child {
  margin-top: 16px;
}
</style>