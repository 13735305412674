<template>
  <div>
    <el-drawer title="明细" :visible.sync="contentShowVisible" size="50%" :modal="false" :wrapperClosable="false">

      <div class="changeType">
        <div :class="{ active: type == index ? true : false }" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="allcontentShow">
        <el-card class="box-card box-card-top searchCard" v-show="type == 0 ? true : false || type == 1 ? true : false">
          <div class="lineContentDialog">
            <el-row :gutter="24" class="sumRowBox" v-loading="ShowLoaing">
              <el-col class="cardShowSum" :span="11">
                <p class="cardShowfirstP">
                  <span class="cardShowSumTitle">{{
                    type == 0 ? "已对账金额" : "已提现金额"
                  }}</span>
                  <span class="cardShowfirstPnum"><span class="cardShowSumnum1">{{
                      type == 0
                        ? pageTopData.datachange.check_amount
                        : pageTopData2.sum_withdraw_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
                <p class="cardShowP" v-show="type == 0 ? true : false">
                  <span class="cardShowSumTitle">所属:</span>
                  <span>
                    <span class="cardShowSumnum">{{
                      pageTopData.datachange.belong_check_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
                <p class="cardShowP" v-show="type == 0 ? true : false">
                  <span class="cardShowSumTitle" v-show="type == 0 ? true : false">归属:</span>
                  <span>
                    <span class="cardShowSumnum">{{
                      pageTopData.datachange.allot_check_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
                <p class="cardShowP" v-show="type == 0 ? true : false">
                  <span class="cardShowSumTitle" v-show="type == 0 ? true : false">下级:</span>
                  <span>
                    <span class="cardShowSumnum">{{
                      pageTopData.datachange.subordinate_check_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col class="cardShowSum" :span="11">
                <p class="cardShowfirstP">
                  <span class="cardShowSumTitle">{{
                    type == 0 ? "未对账金额" : "未提现金额"
                  }}</span>
                  <span class="cardShowfirstPnum"><span class="cardShowSumnum1">{{
                      type == 0
                        ? pageTopData.datachange.not_check_amount
                        : pageTopData2.sum_can_withdraw_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
                <p class="cardShowP" v-show="type == 0 ? true : false">
                  <span class="cardShowSumTitle">所属:</span>
                  <span>
                    <span class="cardShowSumnum">{{
                      pageTopData.datachange.belong_not_check_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
                <p class="cardShowP" v-show="type == 0 ? true : false">
                  <span class="cardShowSumTitle">归属:</span>
                  <span>
                    <span class="cardShowSumnum">{{
                      pageTopData.datachange.allot_not_check_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
                <p class="cardShowP" v-show="type == 0 ? true : false">
                  <span class="cardShowSumTitle">下级:</span>
                  <span>
                    <span class="cardShowSumnum">{{
                      pageTopData.datachange.subordinate_not_check_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-row class="dateSelect">
          <el-date-picker class="fullSelect" v-model="month" type="month" placeholder="选择月" @change="monthChange" value-format="yyyy-MM" :picker-options="pickerOptions">
          </el-date-picker>
        </el-row>
        <div class="duizhang" v-show="type == 0 ? true : false">
          <!-- /////////////// -->
          <div class="StatementAccountTitle">
            <p class="StatementAccountTitlename">
              {{ StatementAccountMonth }}月份对账单
            </p>
            <p class="StatementAccountTitlenum">
              流水号：{{  pageData?pageData.reconciliation_id:"" }}
            </p>
          </div>
          <div class="contentshowTab">
            <el-tabs v-model="activeName" :before-leave="handleClick">
              <el-tab-pane label="影视单片点播" name="001001" v-if="reconciliationType!='2'">
                <div class="showNewBox">
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>对账公司名称：</span>{{ pageData?pageData.partner_name:"" }}
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>合作伙伴终端编码：</span>{{ pageData?pageData.partner_code:"" }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>合同有效期：</span>
                        <span v-if="pageData">{{
                        pageData.contract_date_begin &&
                        pageData.contract_date_end
                          ? pageData.contract_date_begin.substr(0, 10) +
                            " ~ " +
                            pageData.contract_date_end.substr(0, 10)
                          : ""
                      }}</span>
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>对账周期：</span>
                        <span v-if="pageData">
                          {{
                        pageData.reconciliation_cycle_begin &&
                        pageData.reconciliation_cycle_end
                          ? pageData.reconciliation_cycle_begin.substr(0, 10) +
                            " ~ " +
                            pageData.reconciliation_cycle_end.substr(0, 10)
                          : ""
                      }}
                        </span>
                      </p>
                    </el-col>
                  </el-row>
                </div>
                <div class="showNewBox">
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p><span>订单数量（个）：</span>{{ pageData.total_order_num }}</p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>订单金额（元）：</span>{{ pageData.total_order_income }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>退款订单数量（个）：</span>{{pageData.refund_order_num}}
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>退款金额（元）：</span>{{pageData.refund_order_income}}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>对账订单数量（个）：</span>{{pageData.order_num}}
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>对账金额（元）：</span>{{pageData.amount}}
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="影视天卡销售" name="001002" v-if="reconciliationType!='1'">
                <div class="showNewBox">
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>对账公司名称：</span>{{ pageData?pageData.partner_name:"" }}
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>合作伙伴终端编码：</span>{{ pageData?pageData.partner_code:"" }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>合同有效期：</span>
                        <span v-if="pageData">{{
                        pageData.contract_date_begin &&
                        pageData.contract_date_end
                          ? pageData.contract_date_begin.substr(0, 10) +
                            " ~ " +
                            pageData.contract_date_end.substr(0, 10)
                          : ""
                      }}</span>
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>对账周期：</span>
                        <span v-if="pageData">
                          {{
                        pageData.reconciliation_cycle_begin &&
                        pageData.reconciliation_cycle_end
                          ? pageData.reconciliation_cycle_begin.substr(0, 10) +
                            " ~ " +
                            pageData.reconciliation_cycle_end.substr(0, 10)
                          : ""
                      }}
                        </span>
                      </p>
                    </el-col>
                  </el-row>
                </div>
                <div class="showNewBox">
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p><span>订单数量（个）：</span>{{ pageData.total_order_num }}</p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>订单金额（元）：</span>{{ pageData.total_order_income }}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>退款订单数量（个）：</span>{{pageData.refund_order_num}}
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>退款金额（元）：</span>{{pageData.refund_order_income}}
                      </p>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" class="rowContent">
                    <el-col :span="12">
                      <p>
                        <span>对账订单数量（个）：</span>{{pageData.order_num}}
                      </p>
                    </el-col>
                    <el-col :span="12">
                      <p>
                        <span>对账金额（元）：</span>{{pageData.amount}}
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="contentshowTab contentshowTab1">
            <el-table class="duizhangTable" ref="singleTable" :data="tableData" style="width: 100%" :header-cell-style="headerStyle">
              <el-table-column type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column prop="hotel_name" label="酒店名称" min-width="300" show-overflow-tooltip>
              </el-table-column>
              <el-table-column label="操作" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="openContent(scope.row, 1)">详细</el-button>
                  <el-button v-show="scope.row.message" type="text" size="mini" @click="openContent(scope.row, 2)">驳回原因</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期"> </el-table-column>
              <el-table-column label="客房数" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.room_num?scope.row.room_num:"0"}}</span>
                </template>
              </el-table-column>
              <el-table-column width="130" label="激活终端数量" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.active_terminal_num?scope.row.active_terminal_num:"0"}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="valid_active_terminal_num" width="180" label="影视有效激活终端数量" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.valid_active_terminal_num?scope.row.valid_active_terminal_num:"0"}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="order_num" label="订单数">
              </el-table-column>
              <el-table-column prop="amount" label="收益金额（元）" min-width="120">
              </el-table-column>
              <el-table-column prop="refund_order_num" label="退款订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
              <el-table-column prop="refund_order_income" label="退款收益" show-overflow-tooltip></el-table-column>
              <el-table-column prop="reconciliation_order_num" label="对账订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
              <el-table-column prop="reconciliation_order_income" label="对账收益" show-overflow-tooltip></el-table-column>
              <el-table-column prop="day_active_rate" label="日活率">
              </el-table-column>
              <el-table-column prop="income_source_text" label="收益来源">
              </el-table-column>
              <el-table-column prop="partner_name" label="所属合作伙伴" min-width="300" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="tixian" v-show="type == 1 ? true : false">
          <div class="contentshowKk">
            <el-table ref="singleTable1" :data="tableData1" style="width: 100%" :header-cell-style="headerStyle" class="withdrawalStatisticTable">
              <el-table-column label="申请提现金额（元）" min-width="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.withdraw_amount_text }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" min-width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.status_text }}</span>
                </template>
              </el-table-column>
              <el-table-column label="申请时间" min-width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.create_time_text }}</span>
                </template>
              </el-table-column>
              <el-table-column property="apply_admin_name_text" min-width="150" label="申请人" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="huikuan" v-show="type == 2 ? true : false">
          <div class="contentshowKk">
            <el-table ref="singleTable2" :data="tableData2" style="width: 100%" :header-cell-style="headerStyle" class="withdrawalStatisticTable">
              <el-table-column property="amount" label="扣款金额(元)">
                <template slot-scope="scope">
                  <span>{{ scope.row.amount / 100 }}</span>
                </template>
              </el-table-column>
              <el-table-column property="reason" label="扣款原因">
              </el-table-column>
              <el-table-column label="附件">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.attachment_name" type="text" size="mini" @click="donowdFile(scope.row)">{{ scope.row.attachment_name }}
                  </el-button>
                  <el-button v-else type="text" size="mini">暂无数据</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- /////////////// -->
    </el-drawer>
    <div class="contentDialogBlack" v-show="contentClickShow">
      <div class="contentDialog">
        <p class="dialogTitle">{{ contentTitle }}</p>
        <div class="close" @click="closeDialog2"><i class="el-icon-close"></i></div>
        <div class="picShow" v-if="contentType == 1">
          <el-table ref="singleTable" :data="contentTableList" style="width: 100%" :header-cell-style="headerStyle">
            <el-table-column label="时间" min-width="160px" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.order_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="order_room" label="房间号" min-width="150px"></el-table-column>
            <el-table-column show-overflow-tooltip prop="order_paysn" min-width="180px" label="订单号"></el-table-column>
            <el-table-column prop="order_phone" label="手机号" min-width="120px">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="order_goods_name" label="电影名称" min-width="200">
            </el-table-column>
            <el-table-column prop="order_amount" label="单价">
            </el-table-column>
            <el-table-column prop="calculate" label="分成比例">
            </el-table-column>
            <el-table-column prop="income" label="收益"> </el-table-column>
          </el-table>
        </div>
        <div class="picShow picShow1" v-if="contentType == 2">
          <p style="font-size:16px;margin-top:20px">{{ returnMessageShow }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDeductedEventList,
  getPartnerWithdrawEventList,
  getReconciliationList,
  getCheckAmountByPartner,
  getIddReconciliationDetailByContractAndDate,
} from "@/api/iddfinan.js";
import qs from "querystring";
export default {
  props: ["contentId", "partnerId"],
  data() {
    return {
      contentShowVisible: false,
      typeList: [
        {
          name: "对账",
        },
        {
          name: "提现",
        },
        {
          name: "扣款",
        },
      ],
      sumHotelNumber: 0,
      sumMoneyNumber: 0,
      summanyNumber: 0,
      ShowLoaing: true,
      contentType: 1,
      contentClickShow: false,
      type: 0,
      cardtype: "timeCenter",
      contentTitle: "详细",
      contentTableList: [],
      tableData: [],
      tableData1: [],
      pageData: {
        order_num: "",
        refund_order_num: "",
        total_order_num: "",
        amount: "",
        refund_order_income: "",
        total_order_income: "",
      },
      pageDataChange: null,
      tableDataChange: [],
      pageTopData: { datachange: {} },
      pageTopData2: {},
      tableData2: [],
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      StatementAccountMonth: "",
      activeName: "001001",
      oneStatus: false,
      oneText: "更多",
      twoStatus: false,
      twoText: "更多",
      threeStatus: false,
      threeText: "更多",
      pickerOptions: {
        disabledDate(date) {
          let zero = new Date().setHours(0, 0, 0, 0);
          if (date.getTime() > zero) {
            return true;
          }
          return false;
        },
      },
      month: "",
      reconciliationType: "",
      changeBln: false,
    };
  },

  methods: {
    // 文件下载
    donowdFile(row) {
      this.$confirm("是否确认下载或查看当前文件？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.open(row.attachment);
        })
        .catch((err) => {});
    },
    // 更多
    showMore(val) {
      if (val == 1) {
        if (this.oneStatus) {
          this.oneStatus = false;
          this.oneText = "更多";
        } else {
          this.oneStatus = true;
          this.oneText = "收起";
        }
      } else if (val == 2) {
        if (this.twoStatus) {
          this.twoStatus = false;
          this.twoText = "更多";
        } else {
          this.twoStatus = true;
          this.twoText = "收起";
        }
      } else {
        if (this.threeStatus) {
          this.threeStatus = false;
          this.threeText = "更多";
        } else {
          this.threeStatus = true;
          this.threeText = "收起";
        }
      }
    },
    // 酒店订单详情
    openContent(row, num) {
      if (num == 1) {
        this.contentTitle = "明细";
        this.contentType = 1;
        this.contentClickShow = true;
        const json = {
          date: row.date,
          hotelContract: row.hotel_contract,
          partnerId: row.partner_id,
          reconciliationType: this.activeName,
        };
        var param = qs.stringify(json);
        getIddReconciliationDetailByContractAndDate(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.contentTableList = res.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.contentTitle = "驳回原因";
        this.returnMessageShow = row.message;
        this.contentType = 2;
        this.contentClickShow = true;
      }
    },
    getData() {},
    // 类型
    changeType(index) {
      this.type = index;
      this.getTableData();
    },
    // 数据
    getTableData(id) {
      this.tableData = [];
      this.pageTopData2 = {};
      this.pageDataChange = null;
      this.tableDataChange = [];
      this.pageData = {
        order_num: "",
        refund_order_num: "",
        total_order_num: "",
        amount: "",
        refund_order_income: "",
        total_order_income: "",
      };
      this.tableData1 = [];
      this.tableData2 = [];
      if (this.type == 0) {
        const json = {
          partnerId: id ? id : this.contentId,
          // type: this.activeName,
          month: this.month,
        };
        this.timeForChinese(this.month);
        var param = qs.stringify(json);
        getReconciliationList(param)
          .then((res) => {
            if (res.data.code == 100000) {
              //  根据不同的值判断当前对账详情展示数据
              this.reconciliationType = res.data.data.reconciliationType;
              if (res.data.data.reconciliationType == 1) {
                //只有单点
                this.activeName = "001001";
                this.pageData = res.data.data.reconciliationRow.mcu;
                this.tableData = res.data.data.items.mcu;
              } else if (res.data.data.reconciliationType == 2) {
                // 只有天卡
                this.pageData = res.data.data.reconciliationRow.day_card;
                this.tableData = res.data.data.items.day_card;
                this.activeName = "001002";
              } else if (res.data.data.reconciliationType == 3) {
                // 天卡对账共存
                this.activeName = "001001";
                this.pageData = res.data.data.reconciliationRow.mcu;
                this.tableData = res.data.data.items.mcu;
                this.pageDataChange = res.data.data.reconciliationRow.day_card;
                this.tableDataChange = res.data.data.items.day_card;
              } else {
                this.activeName = "001001";
                this.pageData = {
                  order_num: "",
                  refund_order_num: "",
                  total_order_num: "",
                  amount: "",
                  refund_order_income: "",
                  total_order_income: "",
                };
                this.tableData = null;
              }

              // 获取对话框顶部总数据
              const json1 = {
                partnerId: this.contentId,
              };
              var param1 = qs.stringify(json1);
              getCheckAmountByPartner(param1)
                .then((res) => {
                  if (res.data.code == 100000) {
                    const obj = res.data.data;
                    this.pageTopData.datachange = {};
                    for (const key in obj) {
                      this.pageTopData.datachange[key] = Number(obj[key]) / 100;
                    }
                    this.ShowLoaing = false;
                  } else {
                    this.ShowLoaing = false;
                  }
                })
                .catch((err) => {
                  this.ShowLoaing = false;
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.type == 1) {
        // 获取提现数据详情
        const json2 = {
          partner_id: id ? id : this.contentId,
          month: this.month,
        };
        this.ShowLoaing = false;
        var param2 = qs.stringify(json2);
        getPartnerWithdrawEventList(param2)
          .then((res) => {
            if (res.data.code == 100000) {
              var obj = res.data.data.total;
              for (const key in obj) {
                this.pageTopData2[key] = Number(obj[key]) / 100;
              }
              this.ShowLoaing = false;
              this.tableData1 = res.data.data.items;
              var timer = "";
              this.tableData1.forEach((item) => {
                timer =
                  item.partner_contract.contract_date_begin.substr(0, 10) +
                  "~" +
                  item.partner_contract.contract_date_end.substr(0, 10);
                item.timeshow = timer;
              });
            } else {
              this.ShowLoaing = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.ShowLoaing = false;
          });
      } else {
        // 获取扣款数据详情
        const json3 = {
          partner_id: id ? id : this.contentId,
          month: this.month,
        };

        var param3 = qs.stringify(json3);
        getDeductedEventList(param3)
          .then((res) => {
            if (res.data.code == 100000) {
              this.tableData2 = res.data.data.items;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 月份
    monthChange(val) {
      this.month = val;
      this.getTableData();
    },
    // 对账月份动态输出
    timeForChinese(time) {
      var month = time.substring(5, 7);
      if (month == "01") {
        return (this.StatementAccountMonth = "一");
      } else if (month == "02") {
        return (this.StatementAccountMonth = "二");
      } else if (month == "03") {
        return (this.StatementAccountMonth = "三");
      } else if (month == "04") {
        return (this.StatementAccountMonth = "四");
      } else if (month == "05") {
        return (this.StatementAccountMonth = "五");
      } else if (month == "06") {
        return (this.StatementAccountMonth = "六");
      } else if (month == "07") {
        return (this.StatementAccountMonth = "七");
      } else if (month == "08") {
        return (this.StatementAccountMonth = "八");
      } else if (month == "09") {
        return (this.StatementAccountMonth = "九");
      } else if (month == "10") {
        return (this.StatementAccountMonth = "十");
      } else if (month == "11") {
        return (this.StatementAccountMonth = "十一");
      } else if (month == "12") {
        return (this.StatementAccountMonth = "十二");
      } else {
      }
    },

    handleClick(activeName, oldActiveName) {
      var that = this;
      console.log(that.pageData, that.tableData);
      if (that.changeBln) {
        that.changeBln = false;
      } else {
        if (activeName != oldActiveName && that.pageData) {
          if (
            that.reconciliationType == "3" ||
            that.reconciliationType == "0"
          ) {
            var page = JSON.parse(JSON.stringify(that.pageData));
            var table = JSON.parse(JSON.stringify(that.tableData));
            that.pageData = JSON.parse(JSON.stringify(that.pageDataChange));
            that.tableData = JSON.parse(JSON.stringify(that.tableDataChange));
            that.pageDataChange = JSON.parse(JSON.stringify(page));
            that.tableDataChange = JSON.parse(JSON.stringify(table));
          }
        }
      }
    },
    // 关闭
    open() {
      const date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      } else {
        month = month;
      }
      this.month = year + "-" + month;
      this.contentShowVisible = true;
    },
    closeDialog() {
      this.contentShowVisible = false;
    },
    closeDialog2() {
      this.contentClickShow = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep :focus {
  outline: 0;
}
.duizhang,
.tixian,
.huikuan {
  width: 100%;
}
.duizhang {
  display: flex;
  flex-direction: column;
}
.el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
::v-deep .el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.duizhangTable {
  flex: 1;
}
.StatementAccountTitle {
  padding-top: 10px;
  background-color: #fff;
}
.StatementAccountTitle p {
  text-align: center;
}
.showNewBox {
  padding: 20px 0;
  margin-bottom: 10px;
}
.showNewBox:first-child {
  border-bottom: 1px solid #eeeeee;
}
.rowContent {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #70747a;
  font-size: 14px;
}
.firstinlineBlock {
  display: inline-block;
  width: 200px;

  text-align: right;
}
.hotalContentType {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.hotalContentType span {
  flex: 1;
}
.hotalContentType .firstinlineBlock {
  flex: 2;
}
.inlineBlock {
  display: inline-block;
  width: 45px;
  font-size: 12px;
}
.el-tabs__header {
  margin: 0 !important;
}

.rowContent p span:first-child {
  display: inline-block;
  width: 170px;
  font-size: 14px;
  text-align: right;
}
.el-table {
  margin-top: 20px;
}
.btn {
  margin-top: 15px;
  text-align: right;
}
.box-card-top {
  margin-top: 0;
  border-radius: none;
  box-shadow: 0;
}
.box-card {
  position: relative;
  margin-bottom: 8px;
}
.backHistroy {
  position: absolute;
  top: 20px;
  left: 20px;
}
.contentDialogBlack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.21);
}
.contentDialogBlack .contentDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 50%;
  padding: 0 15px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;

  height: 100%;
  width: 50%;
  padding: 0 25px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 9;
  font-size: 12px;
  box-sizing: border-box;
}
.allcontentShow {
  height: 100%;
}
.contentDialogBlack .close {
  right: 15px;
  top: 15px;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;

  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.dialogTitle {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  text-align: left;
  padding: 0 0px;
  border-bottom: 1px solid #ecf3fd;
}
.dialog_title {
  height: 30px;
  line-height: 30px;
  background: #ccc;
  text-align: center;
  margin-bottom: 15px;
}
.dateSelect {
  padding: 20px 20px 0 0;
  text-align: right;
  background-color: #fff;
}
.changeType {
  position: absolute;
  left: -70px;
  top: 0px;
  width: 70px;
  height: 165px;
  border: 1px solid #e2e2e2;
  z-index: 1;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}
.changeType div {
  width: 70px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
  font-size: 14px;
}
.active {
  background-color: #f1f9ff;
  color: #0078ff;
}
.lineContentDialog {
  padding-top: 20px;
  box-sizing: border-box;
}
.sumRowBox {
  display: flex;
  justify-content: space-between;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.cardShowSum {
  color: #70747a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px;
  padding: 0px 30px;
  box-shadow: 0 0 11px 2px rgb(94 151 255 / 15%);
}
.cardShowfirstP {
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}
.cardShowP {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.cardShowP .cardShowSumnum {
  margin-right: 10px;
  font-family: "DINAlternate";
}
.cardShowfirstPnum {
  display: flex;
  font-size: 16px;
  align-items: center;
}
.cardShowSumnum1 {
  color: #00183b;
  font-size: 16px;
  margin-right: 10px;
  font-family: "DINAlternate";
}
.cardShowSumnum {
  font-family: "DINAlternate";
}
.numberShow {
  font-family: "DINAlternate";
  font-size: 12px;
}
.numberShow1 {
  color: #333;
  font-size: 16px;
  font-family: "DINAlternate";
}
.contentshowTab {
  background-color: #fff;
  padding: 0 20px;
}
.contentshowTab1 {
  margin-top: 10px;
}
.contentshowKk {
  background-color: #fff;
  padding: 20px 20px;
}
.StatementAccountTitlename {
  color: #333333;
  font-size: 16px;
  margin-bottom: 17px;
}
.StatementAccountTitlenum {
  color: #adb6c1;
  font-size: 14px;
}
.el-tabs ::v-deep .el-tabs__nav {
  padding: 10px 0 0px !important;
}

.el-tabs ::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 40px;

  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;

  color: #303030;
  position: relative;
}
.el-tabs ::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  z-index: 1;
}

.el-tabs ::v-deep .el-tabs__active-bar {
  width: 84px !important;
}
.more {
  color: skyblue;
  cursor: pointer;
  font-size: 12px;
}
.grayBox {
  height: 20px;
  width: 100%;
  background-color: #f9f9f9;
}
::v-deep .el-drawer {
  overflow: visible;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  overflow: auto;
  background-color: #f9f9f9;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}
.withdrawalStatisticTable {
  margin-top: 0;
}
</style>