<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">独立影视财务</span>
        <span slot="third">对账提现统计</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth" v-if="auth.type == 'htrip'"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
    </el-card>
    <el-card class="box-card searchCard" v-if="showFunModel('对账金额统计')">
      <div class="lineContent">
        <div class="title blueTitle">数据统计</div>
        <el-row :gutter="20" class="rowBox vertical borderBox" v-loading="showLoading">
          <index-four-row :span="5" :type="type">
            <span slot="numTitle">已对账金额</span>
            <span slot="num">{{ showData.sum_check_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="5" :type="type">
            <span slot="numTitle">未对账金额</span>
            <span slot="num">{{ showData.uncheckAmount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="5" :type="type">
            <span slot="numTitle">已提现金额</span>
            <span slot="num">{{ showData.sum_withdraw_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="5" :type="type">
            <span slot="numTitle">未提现金额</span>
            <span slot="num">{{ showData.sum_can_withdraw_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="5" :type="type">
            <span slot="numTitle">扣款金额</span>
            <span slot="num">{{ showData.sum_deducted_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
        </el-row>
      </div>
    </el-card>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">管理列表</div>
      <el-row class="btnRow">
        <div>
          <!-- v-if="showFunModel('扣款')" -->
          <el-button class="largeBtn" type="primary" @click="optDeduction" size="medium" :disabled="tableOperateDisabled" v-if="showFunModel('扣款')">扣款</el-button>
        </div>
      </el-row>
      <div class="lineContent">
        <p class="tablemsg">金额单位：元</p>
        <table-list :table="table" @tableMeth="tableMeth" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading" @showUploadDialog="showUploadDialog"></table-list>
      </div>
    </el-card>
    <recon-dialog ref="ReconDialog" :partnerId="partnerId" :contentShowVisible="contentShowVisible" :contentId="contentId" @closeDialog="closeDialog"></recon-dialog>
    <deduction-dialog ref="DeductionDialog" :incomeTypeList="incomeTypeList" :DeductionShowVisible="DeductionShowVisible" @closeDialog2="closeDialog2" @closeDialog="closeDialog" :ids="ids"></deduction-dialog>
    <div class="allLoading" v-show="false">{{ allLoadingStateCom }}</div>
  </div>
</template>

<script>
import {
  getStatisticalAll,
  getpartnerListAll,
  getTabList,
} from "@/api/iddfinan.js";
import ReconDialog from "./components/withdrawalStatistics";
import DeductionDialog from "./components/DeductionDialog";
import qs from "querystring";
import commonMeth from "../../api/method";
export default {
  components: {
    ReconDialog,
    DeductionDialog,
  },
  name: "adManCustom",
  data: () => ({
    month: "",
    incomeTypeList: null,
    ids: "",
    DeductionShowVisible: false,
    contentShowVisible: false,
    contentId: "",
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    auth: {},
    table: {
      tableHeader: [
        { prop: "company_name", label: "合作伙伴名称", width: "300" },

        { prop: "partner_type_text", label: "合作类型", width: "" },
        { prop: "code", label: "终端编码", width: "" },
        { prop: "timeshow", label: "合同有效期", width: "" },
        { prop: "sum_check_amount", label: "已对账金额", width: "" },
        { prop: "uncheckAmount", label: "未对账金额", width: "" },
        { prop: "sum_withdraw_amount", label: "已提现金额", width: "" },
        { prop: "sum_can_withdraw_amount", label: "未提现金额", width: "" },
        { prop: "sum_deducted_amount", label: "扣款金额", width: "" },
      ],
      tableList: [
        {
          type_text: "1",
        },
      ],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "finManFin",
      name: "finManFin",
      option: true,
      select: true,
      loading: false,
      radio: true,
    },
    topSearchShow: {},
    condition: {},
    operateIds: "",
    operateIdsArr: [],
    showData: {},
    token: "",
    showLoading: true,
    type: "timeCenter",
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    query: {},
    funArr: [],
    allData: {},
    tableOperateDisabled: false,
    topSearchSave: {},
    allLoadingState: false,
    partnerId: "",
    code: "",
    company_name: "",
    code_string: "",
  }),
  methods: {
    // 根据表格点击不同按钮展示不同的数据
   showUploadDialog(val) {
      this.$refs.DeductionDialog.closeDrawer();
      this.contentId = val.id;
      this.$refs.ReconDialog.open();
      this.$refs.ReconDialog.getTableData(this.contentId);
    },
    // 关闭弹框
    closeDialog() {
      this.$refs.ReconDialog.closeDialog();
    },
    closeDialog2() {
      this.contentShowVisible = false;
      this.tableMeth();
      this.getData();
    },
    // 扣款按钮
    optDeduction() {
      if (this.operateIdsArr.length == 0) {
        this.$message.warning("请选择一条选项，进行扣款操作");
      } else {
        this.ids = this.operateIdsArr.join(",");
        this.$refs.DeductionDialog.DeductionShowVisible = true;
      }
    },
    // 表格数据获取
    tableMeth(orderVal) {
      this.table.loading = true;
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop) {
          sortStr = orderVal.prop;
        } else {
          sortStr = "id";
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      const json = {
        page: this.table.page,
        limit: this.table.limit,
        code: this.code,
        company_name: this.company_name,
        code_string: this.code_string,
        sort: sortStr,
        order: orderStr,
      };
      var param = qs.stringify(json);
      getpartnerListAll(param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = tableData.total;
            var timer = "";
            this.table.tableList.forEach((item) => {
              if (item.partner_contract) {
                if (item.partner_contract.contract_date_begin) {
                  timer =
                    item.partner_contract.contract_date_begin.substr(0, 10) +
                    " ~ " +
                    item.partner_contract.contract_date_end.substr(0, 10);
                } else {
                  timer = "";
                }
              } else {
                timer = "";
              }
              item.timeshow = timer;
            });
          } else {
            this.$message.warning(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch(() => {
          this.table.loading = false;
        });
    },
    timeForShow() {},
    // 分页
    getNewPage(newPage) {
      this.contentShowVisible = false;
      this.table.page = newPage;
      this.table.loading = true;
      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.contentShowVisible = false;
      this.table.limit = newLimit;
      this.table.loading = true;
      this.tableMeth();
    },
    // 筛选权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {};
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          coopType: true,
          codekeyword: true,
          codekeywordPlaceholder: "请输入合作编码",
          keyword: true,
          keywordPlaceholder: "请输入合作伙伴关键字",
        };
      }
    },
    // 筛选
    getCondition(val) {
      if (val.keyword) {
        this.company_name = val.keyword;
      } else {
        this.company_name = "";
      }
      if (val.codekeyword) {
        this.code = val.codekeyword;
      } else {
        this.code = "";
      }
      if (val.partner_id) {
        this.code_string = val.partner_id.join(",");
      } else {
        this.code_string = "";
      }
      this.table.page = 1;
      this.tableMeth();
      this.getData();
    },
    // 选择
    getTableSelect(val) {
      this.$refs.DeductionDialog.closeDrawer();
      this.operateIdsArr = [];
      if (this.table.radio) {
        if (val.id) {
          this.operateIds = val.id;
          this.operateIdsArr.push(val.id);
        } else {
          return;
        }
      } else {
        var operateIds = "";
        val.forEach((item, index) => {
          operateIds += item.id + ",";
        });
        this.operateIds = operateIds.slice(0, -1);
        this.operateIdsArr = this.operateIds.split(",");
      }
    },

    getFoldMeth(val) {
      this.fold = val;
    },

    // 获取页面数据
    getData() {
      // debugger
      this.showLoading = true;
          const json = {
  
        code: this.code,
        company_name: this.company_name,
        code_string: this.code_string,
      };
      var param = qs.stringify(json)
      getStatisticalAll(param)
        .then((res) => {
          if (res.data.code == 100000) {
            var obj = res.data.data;
            for (const key in obj) {
              this.showData[key] = Number(obj[key]) / 100;
            }
            this.showLoading = false;
          } else {
            this.showLoading = false;
          }
        })
        .catch((err) => {
          this.showLoading = false;
        });
      getTabList()
        .then((res) => {
          if (res.data.code == 100000) {
            this.incomeTypeList = res.data.data.items;
          }
        })
        .catch((err) => {});
    },
  },
  mounted() {
    var query = this.$route.query;
    this.query = query;
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.getData();
    this.tableMeth();
    commonMeth.getTreeData(this.table.option).then((res) => {
      this.funArr = res.funArr;
      this.table.option = res.flag;
    });
  },
  computed: {
    showDataCom() {
      return function (s, type = "", n = 2) {
        if (type === "money") {
          if (s === null) {
            return 0;
          } else {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
            var l = s.split(".")[0].split("").reverse(),
              r = s.split(".")[1];
            var t = "";
            for (var i = 0; i < l.length; i++) {
              t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
            }
            return t.split("").reverse().join("") + "." + r;
          }
        } else {
          if (s === null) {
            return 0;
          } else {
            return parseFloat(s).toLocaleString();
          }
        }
      };
    },
    showDataTime() {
      if (this.condition.month === "") {
        return "【昨日】";
      } else {
        var time = this.condition.month;
        return time;
      }
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(
        this.table.loading,
        false,
        this.showLoading
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tablemsg {
  font-size: 12px;
  text-align: right;
  color: #ccc;
  padding-bottom: 15px;
}
::v-deep :focus {
  outline: 0;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.lineContent {
  margin-top: 20px;
}
.btnRow {
  display: flex;
  margin-bottom: 20px;
}

.box-card {
  margin-top: 20px;
}
.rowBox {
  margin-top: 20px;
  box-sizing: border-box;
}
</style>
