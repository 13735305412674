import axios from 'axios' //全局配置，导入包 设置请求的根路径
import global_ from '@/api/config'
import globalVar from '@/api/globalVar'
import commonMeth from "@/api/method";
axios.defaults.baseURL = global_.BASE_URL + '/api';
import qs from "querystring";
import { Message } from "element-ui";
// axios.defaults.baseURL='https://sspadmin.ctlife.tv:8899/ssp/api';
Vue.prototype.$http = axios; //包挂载到Vue的原型对象上
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import LoginTwo from '../components/LoginTwo.vue'
import Register from '../components/Register.vue'
import registerSuccess from '../components/RegisterSuccess.vue'
import Examine from '../components/examine/Examine.vue'
import Home from '../components/Home.vue'
import Index from '../components/Index.vue'
import IndexShow from '../components/index/IndexShow.vue'
import IndexDoMore from '../components/index/IndexDoMore.vue'
import IndexDoDetail from '../components/index/IndexDoDetail.vue'
import IndexDoLabel from '../components/index/IndexDoLabel.vue'
import IndexDoQualify from '../components/index/IndexDoQualify.vue'
import IndexDoBrush from '../components/index/IndexDoBrush.vue'
import IndexDoBrushChange from '../components/index/IndexDoBrushChange.vue'
import IndexDoCoop from '../components/index/IndexDoCoop.vue'
import IndexDoRoom from '../components/index/IndexDoRoom.vue'
import IndexDoVisual from '../components/index/IndexDoVisual.vue'
import IndexDoWithdraw from '../components/index/IndexDoWithdraw.vue'
import IndexDoAdOrder from '../components/index/IndexDoAdOrder.vue' //首页-广告订单审核
import IndexDoRecon from '../components/index/IndexDoRecon.vue' //首页-广告订单审核
import IndexDoReconChange from '../components/index/IndexDoReconChange.vue' //首页-广告订单审核
import IndexDoVisualNew from '../components/index/IndexDoVisualNew.vue' //首页-新可视化审核
import HotelManNew from '../components/hotelMan/HotelManNew.vue' //酒店管理
import HotelManAllDetail from '../components/hotelMan/HotelManAllDetail.vue' //酒店管理--所有酒店列表--table详情


//酒店管理三级菜单开始
import HotelManNewList from '../components/hotelMan/hotelManNewMenu/NewList.vue'
import HotelManAllImplementation from '../components/hotelMan/hotelManAllMenu/Implementation.vue' //实施中酒店列表
import HotelManAllUsing from '../components/hotelMan/hotelManAllMenu/Using.vue' //使用中酒店列表
import HotelManAllWaitForChecking from '../components/hotelMan/hotelManAllMenu/WaitForChecking.vue' //待验收酒店列表
import HotelManAllOperate from '../components/hotelMan/hotelManAllMenu/Operate.vue' //可运营酒店列表
import HotelManAllOffline from '../components/hotelMan/hotelManAllMenu/Offline.vue' //离线酒店列表
import HotelManAll from '../components/hotelMan/hotelManAllMenu/All.vue' //所有酒店列表
import onePageHotelManAll from '../components/hotelMan/hotelManAllMenu/onePage.vue' //所有酒店列表 -- apass
import hotelInfoPage from '../components/hotelMan/hotelInfoPage' // apaas酒店跳转页
import hotelInfoPageApaas from '../components/hotelMan/hotelInfoPageApaas' // apaas酒店详情页
import hotelMouldePage from '../components/hotelMan/hotelMouldePage' // apaas 功能模块
import hoteMouldeMenu from '../components/hotelMan/hoteMouldeMenu' // apaas 功能模块
import hotelAllContentInfo from '../components/hotelMan/hotelAllContentInfo' // 酒店详情独立展开界面
import HotelClassify from '../components/operation/HotelClassify/index.vue' //酒店分类列表
import HotelManEditHotelService from '../components/hotelMan/hotelManEditHotelService.vue'
import HotelManDel from '../components/hotelMan/hotelManDelMenu/Del.vue' //删除酒店列表
import authorizationRecordList from '../components/hotelMan/authorizationRecordList.vue' //授权记录列表
import hotelManAllWaitforCheckingList from '../components/hotelMan/hotelManAllWaitforCheckingList.vue' //可提交验收酒店列表
import hotalAcceptancePreview from '../components/hotelMan/hotalAcceptancePreview.vue' //提交验收酒店预览列表
import HotelManAssignTest from '../components/hotelMan/hotelManAssignMenu/Assign.vue' //未分配酒店列表 authorizationRecordList
import MaterialPublishList from '../components/hotelMan/material/MaterialPublishList' //发布列表

import CoopList from '../components/coop/CoopList' //合作伙伴列表--index
import CoopDetail from '../components/coop/CoopDetail' //合作伙伴列表--所有酒店列表--table详情
import CoopHotelConfig from '../components/coop/CoopHotelConfig' //合作伙伴列表--合作伙伴酒店配置
import ToBeConfiguredHotel from '../components/coop/ToBeConfiguredHotel' //合作伙伴列表--待配置酒店列表
import ToBeConfiguredHotelDetail from '../components/coop/ToBeConfiguredHotelDetail' //合作伙伴列表--待配置酒店列表
import coopDataReset from '../components/coop/coopDataReset' //合作伙伴列表--数据重置管理
import testIm from '../components/hotelMan/test.vue' //table详情
import testTwoIm from '../components/hotelMan/TestTwo.vue' //table详情

import AdManCus from '../components/adMan/AdManCus.vue'
import AdManSpace from '../components/adMan/AdManSpace.vue'
import AdManOrder from '../components/adMan/AdManOrder.vue'
import AdManOrderDetail from '../components/adMan/AdManOrderDetail.vue'
import HotelSta from '../components/dataSta/HotelSta.vue' //数据中心
import IncomeSta from '../components/dataSta/IncomeSta.vue'
import IncomeStaMovieDetail from '../components/dataSta/IncomeStaMovieDetail.vue'
import DataAssetSoftIm from '../components/dataAsset/DataAssetSoft.vue' //数据资产-软件资产
import DataAssetPositionIm from '../components/dataAsset/DataAssetPosition.vue' //数据资产-阵地资产
import dataMovieOverview from '../components/dataAsset/dataMovieOverview.vue' //数据资产-阵地资产
import statementAssetsIm from '../components/dataAsset/statementAssets.vue' //数据资产-资产报表
import DataAssetListIm from '../components/dataAsset/DataAssetForList.vue' //数据资产-资产列表
import incomeHistoryIm from "../components/common/incomeTab/incomeHistory"; //数据资产-历史收益

import DataIncomeStatistic from '../components/dataAsset/DataIncomeStatistic.vue' //数据资产-资产收益统计列表
import DataStatisticsDetail from '../components/dataAsset/DataStatisticsDetail.vue' //数据资产-资产收益统计详情
// 1.4.6 合同列表
import userContractList from '../components/dataSta/userContractList.vue'


// 独立收益  
import iddMovieOverview from '../components/independentData/iddMovieOverview.vue' //独立影视收益概览
import iddIncomeSta from '../components/independentData/iddIncomeSta.vue'
import iddIncomeStaMovieDetail from '../components/independentData/iddIncomeStaMovieDetail.vue'

import PerformanceSta from '../components/dataSta/PerformanceSta.vue'
import businessPerformanceSta from '../components/dataSta/businessPerformanceSta.vue'
import DataStaDetailIm from '../components/dataSta/DataStaDetail.vue' //table详情
import AdManCusNew from '../components/adMan/AdManCusNew.vue' //自定义广告位管理-新建
import AdManSpaceNew from '../components/adMan/AdManSpaceNew.vue' //广告位管理-新建

import InfoPubNoticeIm from '../components/infoPub/InfoPubNotice.vue' //信息发布-通知管理

import InfoPubCoopIm from '../components/infoPub/InfoPubCoop.vue' //信息发布-渠道商政策发布
import InfoPubNoNewIm from '../components/infoPub/InfoPubNoNew.vue' //信息发布-通知管理-新建
import InfoPubCoopNewIm from '../components/infoPub/InfoPubCoopNew.vue' //信息发布-合作政策新建
import InfoPubCoopDetailIm from '../components/infoPub/InfoPubCoopDetail.vue' //信息发布-渠道商政策列表
import infoTestIm from '../components/infoPub/test.vue' //信息发布-通知管理-新建

import PolicyListIm from '../components/policy/PolicyList.vue' //渠道商政策-列表
import PolicyDetailIm from '../components/policy/PolicyDetail.vue' //渠道商政策-列表详情

import SysAccInfoIm from '../components/sysSet/SysAccInfo.vue' //系统设置-账户信息
import SysOperaLogIm from '../components/sysSet/SysOperaLog.vue' //系统设置-操作日志
import SysAccNewIm from '../components/sysSet/SysAccNew.vue' //系统设置-新建
import SysConfigIm from '../components/sysSet/SysConfig.vue' //系统设置
import SysConfigNewIm from '../components/sysSet/SysConfigNew.vue' //系统设置-新建
import SysAccManIm from '../components/sysSet/SysAccMan.vue' //系统设置-账号管理
import SysAccManNewIm from '../components/sysSet/SysAccManNew.vue' //系统设置-账号管理，新建
import SysRoleManIm from '../components/sysSet/SysRoleMan.vue' //系统设置-角色管理
import SysRoleManNewIm from '../components/sysSet/SysRoleManNew.vue' //系统设置-角色管理，新建
import SysProcessIm from '../components/sysSet/SysProcess.vue' //系统设置-流程列表
import SysProcessDetailIm from '../components/sysSet/SysProcessDetail.vue' //系统设置-流程列表详情
import sysLabelHistory from '../components/label/sysLabelHistory' //系统设置 标签记录
import SysAccountMnagement from '../components/sysSet/SysAccountMnagement' //系统设置  账户管理

import labelAdd from '../components/label/labelAdd' //系统设置 打标签
import labelSub from '../components/label/labelSub' //系统设置 下架标签
import labelList from '../components/label/labelList' //系统设置 标签管理 
import noDdvertisement from '../components/label/noDdvertisement' //系统设置 标签管理  no广告列表管理
import TopNoticeIm from '../components/topNotice/TopNotice.vue' //头部通知

import DownIndexIm from '../components/down/DownIndex.vue' //下载中心-应用下载



import FinManFinIm from '../components/finMan/FinManFin.vue' //财务管理-财务对账
import FinManReconIm from '../components/finMan/FinManRecon.vue' //财务管理-对账管理
import FinManReconImHotal from '../components/finMan/FinManReconImHotal.vue' //财务管理-待对账管理    ·······///
import StatementAccount from '../components/finMan/components/StatementAccount.vue' //财务管理-待对账酒店    ·······///
import FinManApplyIm from '../components/finMan/FinManApplyChange.vue' //财务管理-申请提现     ·······///
import FinManCashoutIm from '../components/finMan/FinManCashoutChange.vue' //财务管理-提现管理     ······///

//  独立影视
import iddFinManFinIm from '../components/independentFinance/iddFinManFin.vue' //财务管理-财务对账
import iddFinManReconIm from '../components/independentFinance/iddFinManRecon.vue' //财务管理-对账管理
import iddFinManReconImHotal from '../components/independentFinance/iddFinManReconImHotal.vue' //财务管理-待对账管理    ·······///
import iddStatementAccount from '../components/independentFinance/components/StatementAccount.vue' //财务管理-待对账酒店    ·······///
import iddFinManApplyIm from '../components/independentFinance/iddFinManApply.vue' //财务管理-申请提现     ·······///
import iddFinManCashoutIm from '../components/independentFinance/iddFinManCashout.vue' //财务管理-提现管理     ······///


import FinManIncomeIm from '../components/finMan/FinManIncome.vue' //财务管理-收益管理
import FinManReconDetailIm from '../components/finMan/FinManReconDetail.vue' //财务管理-对账单详情


import TaskToDoIm from '../components/task/TaskToDo.vue' //任务中心-待办
import TaskDoneIm from '../components/task/TaskDone.vue' //任务中心-已办
import TaskAllIm from '../components/task/TaskAll.vue' //任务中心-全部任务


import TestOneIm from '../components/mapTest/TestOne.vue' //测试
import chooseRoom from '../components/target/chooseRoom' //选择房间--index
import noAcess from '../components/target/noAcess' //未接入
import noOpen from '../components/target/noOpen' //未开通
// 批量配置
import MulSetting from "../components/operation/MulSetting/index.vue"
// 批量配置-仅主页跳转
import onlyHomeIndex from "../components/operation/MulSetting/onlyHomeIndex.vue"
// 云apk管理
import ApkManage from "../components/operation/ApkManage"
// 配置项管理
import SettingManage from "../components/operation/SettingManage"
// 签名管理
import SignManage from "../components/operation/DeciveManage/signManage"
// 酒店模板管理
import HotelModuleManage from "../components/operation/DeciveManage/hotelModuleManage"
//终端上报日志
import AppLog from "../components/operation/DeciveManage/appLog"
// 签名管理
import EteamsManage from "../components/operation/DeciveManage/eteamsManage"
// 存储位置管理
import savePlaceList from "../components/operation/savePlace/savePlaceList"
//launcher配置
import launcherSetting from "../components/operation/launcherSetting/launcherSetting.vue"

//功能配置（wife  and   homeView Video）
import functionSetting from "../components/operation/functionSetting/functionSetting.vue"
//  报障列表
import reporting from "../components/operation/report/reporting.vue"
//  空间不足列表
import spaceNotList from "../components/operation/spaceNotEnough/spaceNotList.vue"
//  批量更新列表
import batchUpdata from "../components/operation/batchUpdata/batchUpdata.vue"
//  批量更新新建
import addBatchUpdata from "../components/operation/batchUpdata/addBatchUpdata.vue"
//  批量更新新建--单页面
import onlyAddBatchUpdata from "../components/operation/batchUpdata/onlyAddBatchUpdata.vue"
//cibn 播控认证
import CIBNSetting from "../components/CIBN/CIBNSetting.vue" //认证配置
import CIBNLogoList from "../components/CIBN/CIBNLogoList.vue" // 发布列表
// 优客界面
import uokoFinManFin from '../components/uoko/uokoFinManFin.vue' //优客对账提现统计
import uokoWithdrawalManage from '../components/uoko/uokoWithdrawalManage.vue' //优客提现管理 
// import uokoVisualHomeEdit from '../components/uoko/uokoVisualHomeEdit.vue' //优客可视化   非服务
// import uokoVisualServiceEdit from '../components/uoko/uokoVisualServiceEdit.vue' //优客可视化   服务
// import uokoWithdrawalApply from '../components/uoko/uokoWithdrawalApply.vue' //优客提现申请
// import uokoFinManRecon from '../components/uoko/uokoFinManRecon.vue' //优客对账管理 
// import uokoFinManReconHotal from '../components/uoko/uokoFinManReconHotal.vue' //优客对账提现统计
// import uokoIncomeSta from '../components/uoko/uokoIncomeSta.vue' //优客 收益统计
// import uokoHoteLAllList from '../components/uoko/uokoHoteLAllList.vue' //优客  所有酒店列表

//   监管信息界面
import execute from '../components/regulatory/execute.vue' //优客提现管理 
import warningList from '../components/regulatory/warningList.vue' //优客提现管理 
import warningMsg from '../components/regulatory/warningMsg.vue' //优客提现管理 
import msgRelease from '../components/regulatory/msgRelease.vue' //优客提现管理 
import hotelExecute from '../components/regulatory/hotel.vue' //优客提现管理 
import hotelinfo from '../components/regulatory/hotelinfo.vue' //优客提现管理 
import importList from '../components/regulatory/importList.vue' //优客提现管理 
import screenPage from '../components/regulatory/screenPage.vue' //优客提现管理 

// 分组管理
import hotelGroupList from '../components/hotelGroup/hotelGroupList.vue' //分组管理列表
import addHotelGroup from '../components/hotelGroup/addHotelGroup.vue' // 添加酒店分组
// 酒店信息发送
import hotelMsgReleaseList from '../components/hotelMsgRelease/hotelMsgReleaseList.vue' // 酒店插播列表
import hotelMsgReleaseInfo from '../components/hotelMsgRelease/hotelMsgReleaseInfo.vue' // 酒店插播信息
// iot界面
import iotRobot from '../components/iot/iotRobot.vue' // 机器人

Vue.use(VueRouter);

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login/', component: LoginTwo },
    { path: '/LoginTwo/', component: Login },
    { path: '/register/', component: Register },
    { path: '/registerSuccess/', component: registerSuccess },
    { path: '/chooseRoom/', component: chooseRoom },
    { path: '/noAcess/', component: noAcess },
    { path: '/noOpen/', component: noOpen },
    { path: '/hotelInfoPage', component: hotelInfoPage },
    { path: '/hotelInfoPageApaas', component: hotelInfoPageApaas },
    { path: '/hotelMouldePage', component: hotelMouldePage },
    { path: '/hotelAllContentInfo', component: hotelAllContentInfo },
    { path: '/screenPage/', component: screenPage },
    // apass 页面应用单页面
    { path: '/onePage/onePageHotelManAll', component: onePageHotelManAll },
    { path: '/onePage/batchUpdata', component: batchUpdata }, // 批量更新列表
    { path: '/onePage/addBatchUpdata', component: addBatchUpdata }, //新增批量更新
    { path: '/onePage/onlyAddBatchUpdata', component: onlyAddBatchUpdata }, //新增批量更新

    { path: '/onePage/reporting', component: reporting }, //报障列表
    { path: '/onePage/hotelMsgReleaseList', component: hotelMsgReleaseList }, // 酒店插播列表
    { path: '/onePage/hotelMsgReleaseInfo', component: hotelMsgReleaseInfo }, // 酒店插播信息
    { path: '/onePage/MulSetting', component: MulSetting }, //批量设置
    { path: '/onePage/onlyHomeIndex', component: onlyHomeIndex }, //批量设置--仅主页跳转

    { path: '/onePage/CIBNSetting', component: CIBNSetting }, //播控认证  认证设置  
    { path: '/onePage/ApkManage', component: ApkManage }, //云apk管理
    { path: '/onePage/iotRobot', component: iotRobot }, //云apk管理

    // ------------------

    {
        path: '/home/',
        component: Home,
        // redirect:'/indexShow',
        redirect: '/indexShow',
        children: [
            // 分组管理
            { path: '/hotelGroupList', component: hotelGroupList }, //分组管理列表
            { path: '/addHotelGroup', component: addHotelGroup }, //添加酒店分组
            { path: '/hotelMsgReleaseList', component: hotelMsgReleaseList }, // 酒店插播列表
            { path: '/hotelMsgReleaseInfo', component: hotelMsgReleaseInfo }, // 酒店插播信息


            { path: '/execute', component: execute }, // 监管执行界面
            { path: '/warningList', component: warningList },
            { path: '/warningMsg', component: warningMsg },
            { path: '/msgRelease', component: msgRelease },
            { path: '/hotelExecute', component: hotelExecute },
            { path: '/hotelinfo', component: hotelinfo },
            { path: '/importList', component: importList },


            { path: '/examine', component: Examine },

            { path: '/index', component: Index },
            { path: '/indexShow', component: IndexShow }, //首页数据展示
            { path: '/indexDoMore', component: IndexDoMore }, //首页待办更多
            { path: '/indexDoDetail', component: IndexDoDetail }, //首页待办更多
            { path: '/IndexDoQualify', component: IndexDoQualify }, //首页待办更多-资质审核
            { path: '/IndexDoLabel', component: IndexDoLabel }, //首页待办更多-标签审核
            { path: '/IndexDoBrush', component: IndexDoBrush }, //首页待办更多-刷机审核
            { path: '/IndexDoBrushChange', component: IndexDoBrushChange }, //首页待办更多-酒店验收审核
            { path: '/IndexDoCoop', component: IndexDoCoop }, //首页待办更多-合作伙伴分成比例审核
            { path: '/indexDoRoom', component: IndexDoRoom }, //首页待办更多-刷机审核-房间列表
            { path: '/IndexDoVisual', component: IndexDoVisual }, //首页待办更多-可视化审批
            { path: '/IndexDoVisualNew', component: IndexDoVisualNew }, //首页待办更多-新可视化审批
            { path: '/IndexDoWithdraw', component: IndexDoWithdraw }, //首页待办更多-提现申请
            { path: '/IndexDoAdOrder', component: IndexDoAdOrder }, //首页待办更多-广告订单审批
            { path: '/IndexDoRecon', component: IndexDoRecon }, //首页待办更多-广告订单审批
            { path: '/IndexDoReconChange', component: IndexDoReconChange }, //首页待办更多-广告订单审批
            { path: '/hotelManNew', component: HotelManNew },
            { path: '/hotelManAllDetail', component: HotelManAllDetail }, //所有酒店列表--详情
            //酒店管理三级菜单
            { path: '/HotelManNewList', component: HotelManNewList },
            { path: '/HotelManAllImplementation', component: HotelManAllImplementation },
            { path: '/HotelManAllUsing', component: HotelManAllUsing },
            { path: '/HotelManAllWaitForChecking', component: HotelManAllWaitForChecking },
            { path: '/HotelManAllOperate', component: HotelManAllOperate },
            { path: '/HotelManAllOffline', component: HotelManAllOffline },
            { path: '/HotelManAll', component: HotelManAll },


            // 酒店分类
            { path: '/HotelClassify', component: HotelClassify },
            { path: '/authorizationRecordList', component: authorizationRecordList },
            { path: '/hotelManAllWaitforCheckingList', component: hotelManAllWaitforCheckingList },
            { path: '/hotalAcceptancePreview', component: hotalAcceptancePreview },
            { path: '/hotelManEditHotelService', component: HotelManEditHotelService }, // 新增可验收酒店
            { path: '/HotelManDel', component: HotelManDel },
            { path: '/HotelManAssignTest', component: HotelManAssignTest },
            { path: '/MaterialPublishList', component: MaterialPublishList },
            // {path:'/hotelManDetailPart',component:HotelManDetailPart},
            { path: '/test', component: testIm },
            { path: '/testTwo', component: testTwoIm },
            { path: '/adManCus', component: AdManCus },
            { path: '/adManSpace', component: AdManSpace },
            { path: '/adManOrder', component: AdManOrder },
            { path: '/adManOrderDetail', component: AdManOrderDetail },
            { path: '/adManCusNew', component: AdManCusNew }, //自定义广告位管理-新建
            { path: '/adManSpaceNew', component: AdManSpaceNew }, //广告位管理-新建

            { path: '/hotelSta', component: HotelSta },
            { path: '/incomeSta', component: IncomeSta },
            { path: '/incomeStaMovieDetail', component: IncomeStaMovieDetail },
            //独立收益页面  iddMovieOverview
            { path: '/iddMovieOverview', component: iddMovieOverview },
            { path: '/iddIncomeSta', component: iddIncomeSta },
            { path: '/iddincomeStaMovieDetail', component: iddIncomeStaMovieDetail },

            { path: '/performanceSta', component: PerformanceSta },
            { path: '/businessPerformanceSta', component: businessPerformanceSta },

            { path: '/DataStaDetail', component: DataStaDetailIm },
            { path: '/coopList', component: CoopList }, //合作伙伴列表
            { path: '/coopDetail', component: CoopDetail }, //合作伙伴详情
            { path: '/CoopHotelConfig', component: CoopHotelConfig }, //CoopHotelConfig
            { path: '/coopDataReset', component: coopDataReset }, //合作伙伴———数据重置管理
            { path: '/ToBeConfiguredHotel', component: ToBeConfiguredHotel }, //待配置酒店列表
            { path: '/ToBeConfiguredHotelDetail', component: ToBeConfiguredHotelDetail }, //待配置酒店列表详情

            { path: '/infoPubNotice', component: InfoPubNoticeIm }, //信息发布-通知管理
            { path: '/infoPubCoop', component: InfoPubCoopIm }, //信息发布-合作政策
            { path: '/infoPubNoNew', component: InfoPubNoNewIm }, //信息发布-通知管理-新建
            { path: '/InfoPubCoopNew', component: InfoPubCoopNewIm }, //信息发布-通知管理-新建
            { path: '/InfoPubCoopDetail', component: InfoPubCoopDetailIm }, //信息发布-通知管理-新建
            { path: '/infoTest', component: infoTestIm }, //信息发布-通知管理-新建

            { path: '/policyList', component: PolicyListIm },
            { path: '/policyDetail', component: PolicyDetailIm },

            { path: '/sysAccInfo', component: SysAccInfoIm }, //系统设置-账户信息
            { path: '/sysOperaLog', component: SysOperaLogIm }, //系统设置-操作日志
            { path: '/sysAccNew', component: SysAccNewIm }, //系统设置-新建
            { path: '/sysConfig', component: SysConfigIm }, //系统设置-table
            { path: '/sysConfigNew', component: SysConfigNewIm }, //系统设置-新建
            { path: '/sysAccMan', component: SysAccManIm }, //系统设置-账号管理
            { path: '/sysAccManNew', component: SysAccManNewIm }, //系统设置-账号管理，新建
            { path: '/sysRoleMan', component: SysRoleManIm }, //系统设置-角色管理
            { path: '/sysRoleManNew', component: SysRoleManNewIm }, //系统设置-角色管理，新建
            { path: '/SysProcess', component: SysProcessIm },
            { path: '/SysProcessDetail', component: SysProcessDetailIm },
            { path: '/SysAccountMnagement', component: SysAccountMnagement }, //系统设置-账户管理

            { path: '/topNotice', component: TopNoticeIm }, //系统设置-新建
            { path: '/sysLabelHistory', component: sysLabelHistory }, //系统设置-标签记录
            { path: '/labelAdd', component: labelAdd }, //系统设置-打标签
            { path: '/labelSub', component: labelSub }, //系统设置-下架标签
            { path: '/sysLabel', component: labelList }, //系统设置-下架标签
            { path: '/noDdvertisement', component: noDdvertisement }, //系统设置-下架标签
            { path: '/downIndex', component: DownIndexIm }, //下载中心-应用下载



            //  独立影视财务
            { path: '/iddFinManFin', component: iddFinManFinIm }, //财务管理-财务对账  ------ 独立影视
            { path: '/iddFinManReconImHotal', component: iddFinManReconImHotal, }, //财务管理-待对账管理 ------ 独立影视
            { path: '/iddStatementAccount', component: iddStatementAccount, }, // 对账单预览 ------ 独立影视
            { path: '/iddFinManRecon', component: iddFinManReconIm }, //财务管理-对账管理  ------ 独立影视
            { path: '/iddFinManApply', component: iddFinManApplyIm }, //财务管理-申请提现  ------ 独立影视
            { path: '/iddFinManCashout', component: iddFinManCashoutIm }, //财务管理-提现管理  ------ 独立影视
            { path: '/uokoFinManFin', component: uokoFinManFin }, //财务管理-申请提现  ------ 独立影视
            { path: '/uokoWithdrawalManage', component: uokoWithdrawalManage }, //财务管理-提现管理  ------ 独立影视
            { path: '/finManFin', component: FinManFinIm }, //财务管理-财务对账
            { path: '/FinManReconImHotal', component: FinManReconImHotal, }, //财务管理-待对账管理
            { path: '/StatementAccount', component: StatementAccount, }, // 对账单预览
            { path: '/finManRecon', component: FinManReconIm }, //财务管理-对账管理
            { path: '/finManApply', component: FinManApplyIm }, //财务管理-申请提现
            { path: '/finManCashout', component: FinManCashoutIm }, //财务管理-提现管理
            { path: '/finManIncome', component: FinManIncomeIm }, //财务管理-收益管理
            { path: '/FinManReconDetail', component: FinManReconDetailIm }, //财务管理-对账单详情
            { path: '/taskToDo', component: TaskToDoIm }, //任务中心-待办
            { path: '/taskDone', component: TaskDoneIm }, //任务中心-已办
            { path: '/TaskAll', component: TaskAllIm }, //任务中心-全部任务
            { path: '/dataAssetSoft', component: DataAssetSoftIm }, //数据资产-软件资产
            { path: '/dataAssetPosition', component: DataAssetPositionIm }, //数据资产-软件资产
            { path: '/dataMovieOverview', component: dataMovieOverview }, //数据资产-资产报表
            { path: '/DataStatisticsDetail', component: DataStatisticsDetail }, //数据资产-资产收益统计详情
            { path: '/DataIncomeStatistic', component: DataIncomeStatistic }, //数据资产-资产收益统计列表
            { path: '/incomeHistory', component: incomeHistoryIm }, //数据资产-历史收益
            { path: '/statementAssets', component: statementAssetsIm }, //数据资产-资产报表
            { path: '/dataAssetList', component: DataAssetListIm }, //数据资产-数据资产列表 
            { path: '/userContractList', component: userContractList }, //数据中心  合同列表
            { path: '/testOne', component: TestOneIm }, //测试
            { path: '/MulSetting', component: MulSetting }, //批量设置
            { path: '/launcherSetting', component: launcherSetting }, //launcher设置
            { path: '/functionSetting', component: functionSetting }, //功能设置
            { path: '/reporting', component: reporting }, //报障列表
            { path: '/spaceNotList', component: spaceNotList }, //空间不足列表
            { path: '/batchUpdata', component: batchUpdata }, // 批量更新列表
            { path: '/addBatchUpdata', component: addBatchUpdata }, //新增批量更新
            { path: '/ApkManage', component: ApkManage }, //云apk管理
            { path: '/SettingManage', component: SettingManage }, , //配置项管理
            { path: '/SignManage', component: SignManage }, , //签名管理
            { path: '/HotelModuleManage', component: HotelModuleManage }, , //酒店模板UI版式管理
            { path: '/EteamsManage', component: EteamsManage }, //eteams设备类型管理
            { path: '/AppLog', component: AppLog }, //终端日志
            { path: '/savePlaceList', component: savePlaceList }, //存储位置管理

            //  播控配置
            { path: '/CIBNSetting', component: CIBNSetting }, //播控认证  认证设置  
            { path: '/CIBNLogoList', component: CIBNLogoList }, //播控认证  认证设置  
        ]
    },
    {
        path: '/apaas',
        component: hoteMouldeMenu,
        // redirect:'/indexShow',
        redirect: '/apaas/HotelClassify',
        children: [
            { path: '/apaas/noDdvertisement', component: noDdvertisement }, //no 广告
            { path: '/apaas/spaceNotList', component: spaceNotList }, //空间不足列表 
            { path: '/apaas/MulSetting', component: MulSetting }, //批量设置
            { path: '/apaas/SettingManage', component: SettingManage }, , //配置项管理
            { path: '/apaas/dataAssetList', component: DataAssetListIm }, //数据资产-数据资产列表 
            { path: '/apaas/sysLabelHistory', component: sysLabelHistory }, //系统设置-标签记录
            { path: '/apaas/labelAdd', component: labelAdd }, //系统设置-打标签
            { path: '/apaas/labelSub', component: labelSub }, //系统设置-下架标签
            { path: '/apaas/sysLabel', component: labelList }, //系统设置-下架标签
            { path: '/apaas/HotelClassify', component: HotelClassify }, //酒店分类
            { path: '/apaas/authorizationRecordList', component: authorizationRecordList }, //授权记录
            { path: '/apaas/HotelManNewList', component: HotelManNewList }, //新建酒店
            { path: '/apaas/businessPerformanceSta', component: businessPerformanceSta }, //新建酒店
            { path: '/apaas/PerformanceSta', component: PerformanceSta }, //新建酒店
            { path: '/apaas/SignManage', component: SignManage }, //签名管理
            { path: '/apaas/savePlaceList', component: savePlaceList }, //存储位置管理

        ],
    }
];

const router = new VueRouter({
    routes,
    // scrollBehavior (to, from, savedPosition) {
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             resolve({ x: 0, y: 0 })
    //         }, 0)
    //     })
    // }
});


// const router = new VueRouter({
//     routes: [...],
//
// })

//登录方法
function loginMeth(param) {
    console.log(param);
    return new Promise((resolve, reject) => {
        axios.post(globalVar.mobileLogin, param).then((res) => {
            var code = res.data.code;
            if (code === 100000) {
                return resolve(res.data);
            } else {
                alert('账号验证失败')
            }
        });
    });
}

function login(account, to, next) {
    console.log(26141564564654);
    return new Promise((resolve, reject) => {
        //手机号码通过验证
        var param = {
            mobile: account,
            code: '1234',
        };
        param = qs.stringify(param);
        loginMeth(param).then((res) => {
            var token = res.data.userinfo.token;
            var auth = res.auth;
            sessionStorage.setItem("auth", JSON.stringify(auth));
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("defaultActive", "/indexShow");
            if (to.path == '/onePage/HotelManAll') {
                sessionStorage.setItem("defaultActiveId", "247");
            }
            resolve(true)
                // commonMeth.getTreeData();

        })


    });

}
router.beforeEach(async(to, from, next) => {
    let isApaas = to.path.indexOf('/apaas') != -1;
    // if(to.path==='/register') return next();
    if (isApaas || to.path === '/login' || to.path === '/chooseRoom/' || to.path === '/loginTwo' || to.path === '/register' || to.path === '/noAcess' || to.path === '/registerSuccess' || to.path === '/noOpen' || to.path === '/hotelInfoPageApaas') {
        return next();
    } else {
        if (to.query.account) {
            console.log(to.query.account);
            let a = await login(to.query.account, next)
            console.log(sessionStorage.getItem("token"));
            if (a) {
                return next();
            }

        } else if (to.query.data) {
            return next();
        } else {
            const token = window.sessionStorage.getItem('token');
            // const auth=JSON.parse(window.sessionStorage.getItem('auth'));
            var register_review_status = null;
            if (!token) {
                return next('/login');
            } else {
                // axios.get('https://sspadmin.ctlife.tv:8899/ssp/api/v1/Information/message', {
                axios.get(global_.BASE_URL + '/api/v1/Information/message', {
                    params: {
                        token: token,
                        page: '1',
                        per_page: '5',
                        type: 'notice'
                    }
                }).then(res => {
                    console.log(res);
                    if (res.data.code === 100000) {
                        var auth = res.data.auth;
                        if (auth) {
                            register_review_status = auth.register_review_status;
                        }
                        // alert('index路由页面');
                        if (register_review_status === 0 || register_review_status === -1) { //未注册审核
                            if (to.path === '/examine') {
                                next();
                            } else {
                                return next('/examine');
                            }
                        } else { //注册审核通过
                            if (to.path === '/examine') {
                                return next('/indexShow');
                                // next();
                            } else {
                                next();
                            }
                        }
                    } else if (res.data.code === 100003) {
                        Message.error("登录状态以超时，请重新登录");
                        sessionStorage.clear();
                        localStorage.clear();
                        return next('/login');
                    }
                }).catch(() => {
                    Message.warning('进入页面失败');
                })
            }
        }
    }




});


export default router