// const token='f5665cb6-c5fc-46e1-8d65-d4af442a10db';
// const token='1c889093-18ee-4089-84f3-2154b6e87b90';
import global_ from '@/api/config'
const auth = JSON.parse(sessionStorage.getItem('auth'));
const token = sessionStorage.getItem('token');
const type = '';
const getHotelLine = 'v1/datacenter/getHotelLine';
const mobileLogin = 'v1/admin/mobileLogin';
const codeLogin = 'v1/Idaaslogin/login';
const sendNoValidate = 'sms/sendNoValidate'; //修改注册信息-注册发送验证码
const send = 'sms/send'; //注册发送验证码
const editRegisterData = 'v1/admin/editRegisterData'; //注册发送验证码
const resetSmsCode = 'v1/admin/resetSmsCode'; //修改验证码
const upload = global_.BASE_URL + '/api/ajax/upload';
// const upload='https://sspadmin.ctlife.tv:8899/ssp/api/ajax/upload';
const materialUpload = global_.BASE_URL + '/api/ajax/uploadtos3';
// const materialUpload='https://sspadmin.ctlife.tv:8899/ssp/api/ajax/uploadtos3';

// const upload='http://ssp.travelink.com.cn:8000/ssp/api/ajax/upload';
const preview = 'v1/hotel/preview';
const inventedPreview = 'v1/hotel/inventedPreview'; //虚拟酒店导入预览
const importVirtualHotel = 'v1/hotel/importVirtualHotel'; //虚拟酒店导入流程
const inventedRoomPreview = 'v1/hotel/inventedRoomPreview'; //虚拟房间导入预览
const importVirtualRoom = 'v1/hotel/importVirtualRoom'; //虚拟房间导入流程
const getmenulist = 'v1/role/getmenulist';
const importAction = 'v1/hotel/importAction';
const HotelMemberCheck = 'v1/hotel/HotelMemberCheck';
const orderAction = 'v1/review/orderAction';
const withdrawAction = 'v1/review/withdrawAction';
const getregisterdata = 'v1/admin/getregisterdata';
const getAccountManagerList = 'v1/admin/getAccountManagerList'; //获取BD
const getLogo = 'v1/Admin/getLogo'; //获取 logo

const getAccountManagerListWithAlias = 'v1/admin/getAccountManagerListWithAlias'; //新建账号-上级合作伙伴
const getAccountManagerListEditHotel = 'v1/admin/getAccountManagerListEditHotel'; //新建账号-上级合作伙伴
const reviewlist = 'v1/review/reviewlist'; //首页待办-table
const getReviewListToDo = 'v1/review/getReviewListToDo'; //首页待办
const getReviewTypePullDownList = 'v1/review/getReviewTypePullDownList'; //首页待办-筛选下拉
const getIndexStatistics = 'v1/Index/getIndexStatistics'; //首页数据展示
const getIndexContractStatistics = 'v1/Index/getIndexContractStatistics'; //渠道商首页数据展示
const getIndexLine = 'v1/Index/getIndexLine'; //首页折线图
const registerAction = 'v1/review/registerAction'; //注册审核
const partnerAction = 'v1/review/partnerAction'; //注册审核
const rootAction = 'v1/review/rootAction'; //刷机审核
const reconciliationAction = 'v1/review/reconciliationAction'; //待办中-对账审核
const visualAction = 'v1/review/visualAction'; //待办中-可视化审核
const auditResult = 'v2/Hotelvisualization/auditResult'; //待办中-新可视化审核
const registerReviewDetail = 'v1/review/view'; //注册审核
const checkConfigStatus = 'v1/partner/checkConfigStatus' //计划分成比例审核
const getRootDetailByReceiving = 'v1/review/getRootDetailByReceiving'; //待办-刷机审核-获取房间详情
const getAdvertisingIncomeStatistics = 'v1/Advertisingincome/getAdvertisingIncomeStatistics'; //广告数据展示
const getMovieIncomeStatisticsAllot = 'v1/Movieincome/getMovieIncomeStatisticsAllot'; //观影数据统计-归属


const getMovieIncomeStatistics = 'v1/Movieincome/getMovieIncomeStatistics'; //影视收益-单点收益统计
const getMovieIncomeDayStatistics = 'v1/Movieincome/getMovieIncomeDayStatistics'; //影视收益-单点收益统计
const getMovieIncomeLine = 'v1/movieincome/getMovieIncomeLine'; //影视收益-单点折线
const getMovieIncomeDayLine = 'v1/movieincome/getMovieIncomeDayLine' //影视收益-天卡折线
const getMovieIncomeList = 'v1/movieincome/getMovieIncomeList'; //影视收益-单点表格
const getMovieDayIncomeList = 'v1/movieincome/getMovieDayIncomeList'; //影视收益-天卡表格
const getMovieIncomeInfoById = 'v1/Movieincome/getMovieIncomeInfoById '; //影视收益-详情
const getMovieIncomeStatisticsHistory = 'v1/movieincome/getMovieIncomeStatisticsHistory'; //历史收益-数据概览
const getMovieIncomeHistoryInfoByContract = 'v1/movieincome/getMovieIncomeHistoryInfoByContract'; //历史收益-详情
const getMovieIncomeListHistory = 'v1/movieincome/getMovieIncomeListHistory' //历史收益-表格


const iddgetMovieIncomeMcuStatistics = 'v1/Movieincomeindependent/getMovieIncomeMcuStatistics'; //独立影视-单点收益概览
const iddgetMovieIncomeDayStatistics = 'v1/Movieincomeindependent/getMovieIncomeDayStatistics'; //独立影视-天卡收益概览
const iddgetMovieMcuIncomeLine = 'v1/Movieincomeindependent/getMovieMcuIncomeLine'; //独立影视-单点折线图
const iddgetMovieDayIncomeLine = 'v1/Movieincomeindependent/getMovieDayIncomeLine'; //独立影视-天卡折线图
const iddgetMovieMcuIncomeList = 'v1/Movieincomeindependent/getMovieMcuIncomeList'; //独立影视-单点表格
const iddgetMovieDayIncomeList = 'v1/Movieincomeindependent/getMovieDayIncomeList'; //独立影视-天卡表格
const iddgetMovieIncomeInfoByContract = 'v1/Movieincomeindependent/getMovieIncomeInfoByContract'; //独立影视-详情

const getMovieIncomeLineAllot = 'v1/movieincome/getMovieIncomeLineAllot'; //影视收益折线-归属
const getHotelDataStatistics = 'v1/hotel/getHotelDataStatistics'; //酒店数据统计
const getHotelTemplate = 'v1/hotel/getHotelTemplate'; //新建酒店-ehotel版式列表
const getHotelLevelAndQuality = 'v1/hotel/getHotelLevelAndQuality'; //新建酒店-酒店级别性质下拉数据
const getHotelTerminalInfo = 'v1/hotel/getHotelTerminalInfo'; //编辑酒店-获取酒店终端信息
const hotelDestroy = 'v1/hotel/destroy'; //删除酒店
const hotelRecover = 'v1/hotel/recover'; //恢复删除酒店
const putOffLineAdvByHotel = 'v1/hotel/putOffLineAdvByHotel' //根据酒店删除广告
const editMacByHotelIdAndMac = 'v1/hotel/editMacByHotelIdAndMac'; //修改mac地址酒店

const getHotelLineThirdMenu = 'v1/hotel/getHotelLine';
const updateHotelName = 'v1/hotel/updateHotelName'; //根据酒店id修改酒店名称
const updateHotelpattern = 'v1/hotel/synchronizeHotel'; //根据酒店id修改酒店模式
const getHotelLineLegend = 'v1/Hotel/getHotelLineLegend';
const getAdvertisingIncomeList = 'v1/Advertisingincome/getAdvertisingIncomeList'; //广告收益酒店列表
const getAdvertisingIncomeLine = 'v1/Advertisingincome/getAdvertisingIncomeLine'; //广告收益折线图

const getMovieCardIncomeStatistics = 'v1/moviecardincome/getMovieCardIncomeStatistics'; //集团观影数据统计
const getMovieCardIncomeLine = '/v1/moviecardincome/getMovieCardIncomeLine'; //集团观影-观影卡收益折现
const getMovieCardRECLine = '/v1/moviecardincome/getMovieCardRECLine'; //集团观影-酒店前台
const getMovieCardMCULine = '/v1/moviecardincome/getMovieCardMCULine'; //集团观影-单片收益
const addHotelUnsettleMovieIncome = '/v1/movieincome/addHotelUnsettleMovieIncome'; //合作伙伴-添加不收益酒店
const destroyHotelUnsettleMovieIncome = '/v1/movieincome/destroyHotelUnsettleMovieIncome'; //合作伙伴-删除不收益酒店
const getUnsettlehotellist = '/v1/movieincome/getUnsettlehotellist'; //合作伙伴-获取不收益酒店

const getHotelList = 'v1/datacenter/getHotelList'; //酒店数据统计酒店列表
const getHotelDataStatisticsDatacenter = 'v1/datacenter/getHotelDataStatistics'; //酒店数据统计-数据展示
const getAllHotelDataStatistics = 'v1/Hotel/getAllHotelDataStatistics'; //所有酒店-数据展示
const getActiveRoomNumRealTime = 'v1/Performance/getActiveRoomNumRealTime'; //业绩完成-数据展示
const getBusinessActiveRoomNumRealTime = 'v1/Performance/getBusinessActiveRoomNumRealTime'; //商务-业绩完成-数据展示
const getPartner = 'v1/partner/getPartner'; //获取合作伙伴
const getPartnerTypes = 'v1/partner/getPartnerTypes'; //获取合作伙伴
const getPartnersByType = 'v1/partner/getPartnersByType'; //获取合作伙伴
const getPartnersByTypes = 'v1/partner/getPartnersByTypes'; //获取合作伙伴-多选
const setPartner = 'v1/hotel/setPartner'; //获取合作伙伴
const getHotelGroup = 'v2/Hotel/getHotelGroup'; //获取合作伙伴


const hlCases = 'v1/cases/hlCases'; //获取携旅所有
const caseCustomSearch = 'v1/cases/search'; //获取自定义所有刊位
const caseView = 'v1/cases/view'; //刊位查看-自定义刊位接口
// const caseView='v1/cases/view';   //刊位查看-关联携旅刊位接口
const caseUpdate = 'v1/cases/update'; //刊位编辑-自定义携旅刊位接口
// const caseView='v1/cases/view';   //刊位编辑-关联携旅刊位接口
const caseCreate = 'v1/cases/create'; //刊位新建-自定义刊位接口
const caseBatch = 'v1/cases/batch'; //刊位table-启动，暂停，删除
const batchEnable = 'v1/cases/batchEnable'; //刊位table-启动
const batchDisable = 'v1/cases/batchDisable'; //刊位table-暂停
const batchDel = 'v1/cases/batchDel'; //刊位table-删除
const getMediaFormat = 'v1/cases/getMediaFormat'; //刊位创建-支持格式下拉
const getAllHtripCase = 'v1/cases/getAllHtripCase'; //刊位创建-获取携旅所有刊位
const getCaseMaterialType = 'v1/cases/getCaseMaterialType'; //刊位创建-获取刊位素材分类下拉

const getCaseScenType = 'v1/cases/getCaseScenType'; //刊位创建-获取场景下拉
const createScene = 'v1/cases/createScene'; //刊位创建-新增场景
const updateCases = 'v1/cases/updateCases'; //刊位创建-编辑场景
const createCases = 'v1/cases/createCases'; //刊位创建-新增刊例
const checkAdName = 'v1/cases/checkAdName'; //刊位创建-广告位名称判断
const casesIndex = 'v1/cases/index'; //刊位创建-广告位table
const getsCasesInfo = 'v1/cases/getsCasesInfo'; //刊位创建-广告位详情
const getAdType = 'v1/cases/getAdType'; //刊位创建-广告位类型
const orderSearch = 'v1/order/search'; //刊位创建-广告订单
const orderView = 'v1/order/view'; //刊位创建-广告订单审核详情


const getMovieIncomeListAllot = 'v1/movieincome/getMovieIncomeListAllot'; //观影收益酒店列表-归属
const getProvince = 'v1/address/getProvince'; //省份
const getCitys = 'v1/address/getCitys'; //市
const getDistricts = 'v1/address/getDistricts'; //区

//酒店选项  v1/hotel/getOptions
const getOptions = 'v1/hotel/getOptions'; //区api/v1/hotel/getBaiduInfo
const getBaiduInfo = 'v1/hotel/getBaiduInfo'; //百度酒店
const getBaiduSearch = 'v1/hotel/getBaiduSearch'; //百度地址
const getPartnerReviewStatus = 'v1/Partner/getPartnerReviewStatus'; //合作伙伴列表-状态筛选下拉
const create = 'v1/hotel/create'; //创建酒店
const search = 'v1/hotel/search'; //酒店列表获取
const getHotelSearch = 'v2/Hotel/getHotelSearch'; //根据模板酒店列表获取

const getImplementDayAndNum = 'v1/hotel/getImplementDayAndNum'; //新建酒店，提示配置信息
const searchNoPage = 'v1/hotel/searchNoPage'; //酒店列表获取-不分页
const exportCreateHotel = 'v1/hotel/exportCreateHotel'; //酒店导出
const getTerminalBrandPullDownList = 'v1/hotel/getTerminalBrandPullDownList'; //终端品牌下拉
const getWaitCheckHotelList = 'v1/hotel/getWaitCheckHotelList'; //待验收酒店列表
const getWaitCheckHotelStatistics = 'v1/hotel/getWaitCheckHotelStatistics'; //待验收数据统计
const getOperationHotelList = 'v1/hotel/getOperationHotelList'; //可运营酒店列表
const getOperationHotelStatistics = 'v1/hotel/getOperationHotelStatistics'; //可运营酒店数据统计
const exportBootTime = 'v1/hotel/exportBootTime'

const getHotelsListWaitForCheck = 'v1/hotel/getHotelsListWaitForCheck' //可提交验收酒店-页面列表
const getHotelMembers = 'v1/hotel/getHotelMembers'; //所有酒店-实施人员-获取人员列表
const addHotelMember = 'v1/hotel/addHotelMember'; //所有酒店-实施人员-添加人员
const delHotelMember = 'v1/hotel/delHotelMember'; //所有酒店-实施人员-删除人员
const activateHotelMember = 'v1/hotel/activateHotelMember'; //所有酒店-实施人员-激活人员
const getRegisteDetail = 'v1/hotel/getRegisteDetail'; //所有酒店-实施人员-获取刷机明细
const transferAuth = 'v1/hotel/transferAuth'; //所有酒店-实施人员-移交权限
const getRooms = 'v1/hotel/getRooms'; //所有酒店-上线情况-房间列表  
const getRoomsNew = 'v1/hotel/getRoomsNew'; //所有酒店-上线情况-房间列表 新 
const getRoomsNews = 'v1/hotel/getRoomsNews'; //所有酒店-上线情况-房间列表 新 -分页
const getRoomsDetail = '/v2/Hotelrooms/getRoomDetail'; ///所有酒店-上线情况-房间信息设备
const roomManagement = '/v2/Hotelrooms/roomManagement'; ///所有酒店-上线情况-房间信息设备
const getVirtualRooms = 'v1/hotel/getVirtualRooms'; //所有酒店-上线情况-房间列表  
const exportScreenHotel = 'v1/hotel/exportScreenHotel'; //所有酒店-聚屏导出
const exportAllHotel = 'v1/hotel/exportAllHotel'; //所有酒店-导出
const getExportAllHotelList = 'v1/hotel/getExportAllHotelList' //所有酒店-导出列表
const view = 'v1/hotel/view'; //单个酒店详情
const update = 'v1/hotel/update'; //单个酒店详情-编辑
const formal = 'v1/hotel/hotelVirtualToFormal' //虚拟运营酒店转正式
const getHotelCaseStatusById = 'v1/hotel/getHotelCaseStatusById'; //运营酒店列表-广告位列表
const editHotelCaseStatus = 'v1/hotel/editHotelCaseStatus'; //运营酒店列表-修改广告位状态
const getOperationHotelRankingList = 'v1/hotel/getOperationHotelRankingList'; //运营酒店列表-排行榜
const getHotelCurrentVisualInfo = 'v1/Visual/getHotelCurrentVisualInfo'; //运营酒店列表-获取当前配置信息
const getHotelMaterialByType = 'v1/Visual/getHotelMaterialByType'; //运营酒店列表-获取素材库
const destroyHotelMaterial = 'v1/Visual/destroyHotelMaterial'; //运营酒店列表-删除素材
const uploadHotelPicture = 'v1/Visual/uploadHotelPicture'; //运营酒店列表-素材库上传图片
const uploadHotelVideo = 'v1/Visual/uploadHotelVideo'; //运营酒店列表-素材库上传图片
const uploadHotelVisualData = 'v1/Visual/uploadHotelVisualData'; //运营酒店列表-发布
const getModelIsReadOnly = 'v1/Visual/getModelIsReadOnly'; //运营酒店列表-发布


const getPerformanceLine = 'v1/Performance/getPerformanceLine'; //获取业绩统计
const getBusinessPerformanceLine = 'v1/Performance/getBusinessPerformanceLine'; //商务-获取业绩统计
const getAccountMovies = 'v1/Performance/getAccountMovies'; //商务-获取数据统计
// const getPerformanceLine='v1/Performance/getPerformanceLine';   //获取业绩折线图
const getPerformanceBar = 'v1/performance/getPerformanceBar'; //获取业绩柱状图
const getPerformanceList = 'v1/Performance/getPerformanceList'; //获取业绩酒店列表
const getperformancestatistics = 'v1/performance/getperformancestatistics'; //获取业绩酒店列表
// const getPerformanceStatisticsByCMS='v1/Rootincome/getPerformanceStatisticsByCMS';   //数据中心刷机费用统计
const getRootIncomeStatistics = 'v1/rootincome/getRootIncomeStatistics'; //数据中心刷机费用统计
// const getPerformanceStatisticsByCMS='v1/Rootincome/getRootIncomeStatistics';   //数据中心刷机费用统计
const getRootIncomeList = 'v1/Rootincome/getRootIncomeList'; //数据中心刷机费用酒店列表
const getRootIncomeLine = 'v1/rootincome/getRootIncomeLine'; //数据中心刷机费用-折线图
const getValidRoomHotelList = 'v1/rootincome/getValidRoomHotelList'; //数据中心市场费用-表格
//rootincome/getValidRoomHotelList

const partnerSearch = 'v1/partner/search'; //获取合作伙伴列表
const partnerDestory = 'v1/partner/destory'; //批量删除合作伙伴
const getPartnerConfigType = 'v1/partner//getPartnerConfigType'; //合作伙伴-配置类型下拉
const getPartnerConfigTerm = 'v1/partner/getPartnerConfigTerm'; //合作伙伴-配置类型下拉
const partnerExport = 'v1/partner/export'; //合作伙伴-导出

const partnerBatch = 'v1/partner/batch'; //合作伙伴批量配置
const partnerView = 'v1/partner/view'; //合作伙伴-单条详情
const partnerUpdate = 'v1/partner/update'; //合作伙伴-编辑
const updatePartnerConfig = 'v1/partner/updatePartnerConfig'; //合作伙伴-分成比例配置
const updatePartnerHotelConfig = 'v1/partner/updatePartnerHotelConfig'; //修改酒店分成比例配置
const setPartnerTypeById = 'v1/partner/setPartnerTypeById'; //合作伙伴-配置合作伙伴类型以及上级合作伙伴
const getAccountDetailByCurrentAdmin = 'v1/partner/getAccountDetailByCurrentAdmin'; //合作伙伴-当前登录账户的信息
const updateAccountInfoByCurrentAdmin = 'v1/partner/updateAccountInfoByCurrentAdmin'; //合作伙伴-修改当前登录账户的信息

const getBelongPartnerOptions = 'v1/partner/getBelongPartnerOptions'; //获取所属合作伙伴
const getAllotPartnerOptions = 'v1/partner/getAllotPartnerOptions'; //获取归属合作伙伴
const getAllotPartnerDefaultConfigure = 'v1/partner/getAllotPartnerDefaultConfigure'; //获取所属合作伙伴默认分成比例
const getPartnerConfigure = 'v1/partner/getPartnerConfigure'; //获取合作伙伴执行中配置分成比例
const getPartnerPreConfigure = 'v1/partner/getPartnerPreConfigure'; //获取合作伙伴预配置分成比例
const updatePartnerInfo = 'v1/partner/updatePartnerInfo' //修改合作伙伴信息
const getConfigureHotelList = 'v1/partner/getConfigureHotelList'; //获取待配置酒店列表
const getHotelConfigure = 'v1/partner/getHotelConfigure'; //获取酒店执行中配置分成比例
const getHotelPreConfigure = 'v1/partner/getHotelPreConfigure'; //获取酒店预配置分成比例


const infoCreate = 'v1/Information/create'; //消息创建
const infoUpdate = 'v1/Information/update'; //消息编辑
const infoSearch = 'v1/Information/search'; //消息列表
const infoDestory = 'v1/Information/destory'; //消息删除
const infoView = 'v1/Information/view'; //消息查看sdk

const infoPublish = 'v1/Information/publish'; //消息发布
const getInformationByCurrentAdmin = 'v1/Information/getInformationByCurrentAdmin'; //右上角通知列表-废用
const informationMessage = 'v1/Information/message'; //右上角通知列表-新
const alreadyRead = '/v1/Information/alreadyRead'; //右上角通知-标为已读

const getsdklist = '/v1/sdk/getsdklist'; //SDK列表
const addsdk = '/v1/sdk/addsdk'; //SDK新增
const destroySdk = '/v1/sdk/destroySdk'; //SDK删除
const editSdk = '/v1/sdk/editSdk'; //SDK编辑
const addDownloadNum = '/v1/sdk/addDownloadNum'; //SDK下载次数

//财务开始                                              
const getReconciliationList = '/v1/Reconciliation/getReconciliationList'; //财务对账-table
const getReconciliationStatistics = '/v1/Reconciliation/getReconciliationStatistics'; //财务对账-获取金额数据
const editReconcilationStatusByIdList = '/v1/Reconciliation/editReconcilationStatusByIdList'; //财务对账-修改对账单状态
const getReconciliationInfo = '/v1/Reconciliation/getReconciliationInfo'; //财务对账-对账单详情
const getRootReconciliationRoomsInfo = '/v1/Reconciliation/getRootReconciliationRoomsInfo'; //财务对账-上线费用获取明细
const getMarketReconciliationRoomsInfo = '/v1/Reconciliation/getMarketReconciliationRoomsInfo'; //财务对账-市场费用获取明细
const editReconciliationStatusByIdList = '/v1/Reconciliation/editReconciliationStatusByIdList'; //财务对账-审核对账单
const editReconciliationHtripStatusByIdList = '/v1/Reconciliation/editReconciliationHtripStatusByIdList'; //财务对账-审核对账单
// const editReconcilationStatusByIdList='/v1/Reconciliation/editReconcilationStatusByIdList';   //
const applyWithdrawList = '/v1/withdraw/applyWithdrawList'; //提现管理-合作伙伴提现table

const getPreReconciliationList = '/v1/reconciliation/getPreReconciliationList'; //单点待对账酒店-table                修改//////

// 独立影视
const getCardPreReconciliationList = '/v1/Independentreconciliation/getPreReconciliationList'; //天卡待对账酒店-table  


// 提现流程
const partnerwithdraw = '/v1/partnerwithdraw/index'; //提现管理-申请提现列表                                       修改//////
const applyWithdrawShow = '/v1/partnerwithdraw/statistical'; //提现管理-申请提现数据显示                           修改//////
const partnerwithdrawContent = '/v1/partnerwithdraw/view'; //提现管理-申请提现单项详情展示                       修改//////
const partnerwithdrawTrue = '/v1/partnerwithdraw/create'; //提现管理-申请提现                                  修改//////
const approvalWithdrawListAdmin = '/v1/partnerwithdraw/checkList'; //提现管理-提现管理列表                               修改//////
const getWithdrawContent = '/v1/partnerwithdraw/viewByReviewId'; //获取提现审核详情                              修改//////

// 独立提现
const iddPartnerwithdraw = '/v1/partnerwithdrawindependent/index'; //提现管理-申请提现列表                                       修改//////
const iddApplyWithdrawShow = '/v1/partnerwithdrawindependent/statistical'; //提现管理-申请提现数据显示                           修改//////
const iddPartnerwithdrawContent = '/v1/partnerwithdrawindependent/view'; //提现管理-申请提现单项详情展示                       修改//////
const iddPartnerwithdrawTrue = '/v1/partnerwithdrawindependent/create'; //提现管理-申请提现                                  修改//////
const iddApprovalWithdrawListAdmin = '/v1/partnerwithdrawindependent/checkList'; //提现管理-提现管理列表                               修改//////
const iddGetWithdrawContent = '/v1/partnerwithdrawindependent/viewByReviewId'; //获取提现审核详情                              修改//////
// uoko 审核
const ukGetWithdrawContent = '/v1/Partnerwithdrawinyouke/viewByReviewId'; //获取提现审核详情        
const ukApprovalWithdrawListAdmin = '/v1/Partnerwithdrawinyouke/checkList'; //提现管理-提现管理列表  

const applyWithdrawStatement = '/v1/withdraw/applyWithdrawStatement'; //提现申请-合作伙伴提现申请
const getWithdrawAmount = '/v1/withdraw/getWithdrawAmount'; //提现申请-数据展示
const approvalWithdrawList = '/v1/withdraw/approvalWithdrawList'; //提现管理-携旅提现table
const approvalWithdrawStatement = '/v1/withdraw/approvalWithdrawStatement'; //提现申请-携旅审核提现申请
const editWithdrawPaymentStatus = '/v1/withdraw/editWithdrawPaymentStatus'; //提现申请-修改提现单支付状态
const getIncomeStatistics = '/v1/income/getIncomeStatistics'; //收益管理-数据展示
const getIncomePartnerList = '/v1/income/getIncomePartnerList'; //收益管理-table
const getActiveConfigList = '/v1/activeconfig/getActiveConfigList'; //系统配置-table
const editActiveConfig = '/v1/activeconfig/editActiveConfig'; //系统配置-修改

const getHotelMapInfo = '/v1/Hotel/getHotelMapInfo'; //百度地图-获取省市区，酒店信息
const getAddressByCoordinate = '/v1/hotel/getAddressByCoordinate'; //百度地图-坐标转换为省市区

const getSoftwareAssets = '/v1/dataassets/getSoftwareAssets'; // 软件资产
const getSelect = '/v1/dataassets/getSelect'; // 资产类型
const getCitysMut = '/v1/address/getCitysMut'; // 获取城市
const getCitysAndProName = '/v1/address/getCitysAndProName'; // 获取区域
const getPositionAssets = '/v1/dataassets/getPositionAssets'; // 阵地资产

// const getPositionAssets ='/v1/dataassets/getPositionAssetsNew';   // 阵地资产

const groupIndex = '/v1/group/index'; //系统设置-角色管理
const indexWithPage = '/v1/group/indexWithPage'; //系统设置-角色管理分页列表
const roletree = '/v1/group/roletree'; //系统设置-角色管理,权限树
const groupLevel = '/v1/group/groupLevel'; //系统设置-角色管理,角色级别
const findUsers = '/v1/account/findUsers'; //系统设置-角色管理,用户查询
const groupAdd = '/v1/group/add'; //系统设置-角色管理,角色添加
const groupEdit = '/v1/group/edit'; //系统设置-角色管理,角色编辑
const groupDel = '/v1/group/del'; //系统设置-角色管理,角色删除
const ruleIndex = '/v1/rule/index'; //系统设置-角色管理,拥有权限
const accountIndex = '/v1/account/index'; //系统设置-账号管理,账号列表
const getDepartment = '/v1/account/getDepartment'; //系统设置-账号管理,所属渠道中心部门
const getGroup = '/v1/group/group'; //系统设置-账号管理,角色下拉
const getUserRuleLists = '/v1/group/getUserRuleLists'; //判断每个页面展示的模块
const getSupAdmin = '/v1/account/getSupAdmin'; //系统设置-账号管理,上级账号名称
const getSupPartner = '/v1/account/getSupPartner'; //系统设置-账号管理,上级账号名称
const accountAdd = '/v1/account/add'; //系统设置-账号管理,添加账号
const accountEdit = '/v1/account/edit'; //系统设置-账号管理,添加账号
const changeStat = '/v1/account/changeStat'; //系统设置-账号管理,修改账号状态
const accountDel = '/v1/account/del'; //系统设置-账号管理,删除
const accountExportCsv = '/v1/account/exportCsv'; //系统设置-账号管理,导出列表
const getIndexHeader = '/v1/index/getIndexHeader'; //系统设置-账号管理,删除
const DataassetsExport = '/v1/Dataassets/export'; //软件资产-导出
const exportPerformanceHotelList = '/v1/performance/exportPerformanceHotelList'; //上线终端统计-导出
const exportBusinessPerformanceHotelList = '/v1/performance/exportBusinessPerformanceHotelList'; //上线终端统计-导出-商务-财务
const exportBusinessMovie = '/v1/performance/exportBusinessMovie'; //上线终端统计-导出-商务
const getImportantCityYesterday = '/v1/Importantcity/getImportantCityYesterday' //上线终端统计-导出24城
const exportChannelStatistics = '/v1/Performance/exportChannelStatistics' //上线终端统计-渠道导出
const exportInactiveList = 'v1/performance/exportInactiveList' //上线激活终端 未活跃导出

const getFlowList = '/v1/flow/getFlowList'; //获取所有工作流列表
const getFlowProcessByFlowId = '/v1/flow/getFlowProcessByFlowId'; //获取对应类型工作流
const getHtripAccount = '/v1/flow/getHtripAccount'; //获取工作流人员
const addFlowProcess = '/v1/flow/addFlowProcess'; //添加工作流
const destroyFlowProcess = '/v1/flow/destroyFlowProcess'; //删除工作流
const editProcessLinkByFlowId = '/v1/flow/editProcessLinkByFlowId'; //修改工作流


const orange = '#ff7607'; //折线图颜色
const green = '#4ba216'; //折线图颜色
const blue = '#2e5bff'; //折线图颜色
const yellow = '#ffcd0a'; //折线图颜色
const purple = '#8c54ff'; //折线图颜色
const chartColor = ['#409eff', '#ff7607', '#4ba216', '#ffcd0a', '#8c54ff', '#ff7607', '#4ba216', '#409eff', '#ffcd0a', '#8c54ff', '#ff7607', '#4ba216', '#409eff', '#ffcd0a', '#8c54ff'];
const tableHeaderStyle = {
    background: '#f8fbff',
    color: '#606266',
};
const symbolSize = 6;
// const baseUrl='http://test7-1104358706.cn-northwest-1.elb.amazonaws.com.cn:8000/ssp/public';
const baseUrl = global_.BASE_URL;
// const baseUrl = 'https://sspadmin.ctlife.tv:8899/ssp/public';
const materialStyle = {
    templateMenuStyle: {
        focusBorder: [
            '50', '53', '55', '59', '61', '62', '64', '67', '69',
            '70', '72', '73', '74', '75', '76',
            '27', '40', '33', '12', '14', '15', '18', '35', '21', '22', '24', '25', '26', '34', '41'
        ], //骏怡
        focusBg: ['44', '54', '66', '68', '42', '32', '20', '36'],
        focusText: ['51', '65', '19', '38'],
        focusBorderBg: [
            '43', '45', '46', '47', '48', '49', '52', '56', '57', '58', '60', '63',
            '16', '31', '28', '29', '30', '39', '9', '10', '11', '13', '17'
        ],
        focusBottomBorder: ['44', '54', '66', '42', '32', '20'],
        focusNone: ['71', '23'], //欢朋
    },
    templateMenuColor: {
        0: {
            focusColor: '#ccac88',
            blurColor: '#b7b7b7',
            id: ['44', '42'] //携旅金色
        },
        1: {
            focusColor: '#fff',
            blurColor: '#a4a4a4',
            bgColor: '#3b3541',
            borderBlueShadow: true,
            id: ['46', '48', '49', '56', '57', '63',
                    '31', '29', '30', '9', '10', '17'
                ] //统一灰色
        },
        21: {
            focusColor: '#fff',
            blurColor: '#a4a4a4',
            bgColor: '#3b3541',
            id: ['43']
        },
        18: {
            focusColor: '#fff',
            blurColor: '#a4a4a4',
            bgColor: '#0d2549',
            borderBlueShadow: true,
            id: ['45', '16']
        },
        19: {
            focusColor: '#fff',
            blurColor: '#666664',
            bgColor: '#2dadc3',
            blurBorderBg: true,
            id: ['47', '28']
        },
        22: {
            focusColor: '#fff',
            blurColor: '#9d885a',
            smallMenuItem: true,
            id: ['69', '21'] //兰欧版式、君澜版式、丽呈华庭版式
        },
        2: {
            focusColor: '#fff',
            blurColor: '#9d885a',
            id: ['50', '76', '37', '41']
        },
        3: {
            focusColor: '#d4af13',
            blurColor: '#fff',
            iconBorder: true,
            id: ['51', '38'] //骏怡版式
        },
        4: {
            focusColor: '#9b1d21',
            blurColor: '#4e4e4e',
            bgColor: '#ececec',
            id: ['52', '60', '39', '13'] //尚客优精选版式、含舍版式
        },
        5: {
            focusColor: '#fff',
            blurColor: '#b6bcb4',
            id: ['53', '40'] //尚客优品版式
        },
        6: {
            focusColor: '#fdab41',
            blurColor: '#fff',
            bgColor: '#114aa1',
            id: ['54', '32'] //凯里亚德版式（渠道）
        },
        7: {
            focusColor: '#293a94',
            blurColor: '#4d494a',
            id: ['55', '33'] //凯里亚德版式（集团收费版）
        },
        8: {
            focusColor: '#c29b62',
            blurColor: '#4d494a',
            bgColor: '#fff',
            smallMenuItem: true,
            id: ['58', '11'] //巴塞罗版式
        },
        9: {
            focusColor: '#d1b271',
            blurColor: '#fff',
            id: ['59', '64', '74', '12', '18', '26'] //Zsmart智尚版式、智尚臻选版式、
                // 廷泊酒店版式、莫林酒店版式、非繁微笑版式
        },
        23: {
            focusColor: '#d0ad64',
            blurColor: '#fff',
            smallMenuItem: true,
            id: ['72', '73', '24', '25']
        },
        10: {
            focusColor: '#a4774a',
            blurColor: '#fff',
            id: ['59', '12'] //Zsmart智尚版式  #a4774a
        },
        11: {
            focusColor: '#e8ad09',
            blurColor: '#fff',
            id: ['61', '62', '14', '15'] //美程里版式、美程版式
        },
        12: {
            focusColor: '#d09c62',
            blurColor: '#ddd3c7',
            id: ['65', '19'] //全季版式（渠道）
        },
        13: {
            focusColor: '#fc0000',
            blurColor: '#fff',
            bgColor: '#0d3165',
            id: ['66', '20'] //荣盛康旅版式
        },
        14: {
            focusColor: '#fff',
            blurColor: '#fff',
            bgColor: '#d69628',
            id: ['68', '36'] //维也纳国际版式
        },
        15: {
            focusColor: '#f18416',
            blurColor: '#1d1d1d',
            id: ['67', '35'] //维也纳酒店集团版式
        },
        16: {
            focusColor: '#f4395f',
            blurColor: '#c9cbcd',
            borderColor: '#cb3356',
            // borderDiff:true,
            smallMenuItem: true,
            id: ['70', '22'] //宜尚plus版式，焦点边框和文字颜色不一致
        },
        17: {
            focusColor: '#fffffa',
            blurColor: '#fff',
            id: ['71', '23'] //欢朋版式（渠道），焦点和非焦点有亮点
        },
        24: {
            focusColor: '#9c824e',
            blurColor: '#d2dbf7',
            borderColor: '#fff',
            smallMenuItem: true,
            iconBorder: true,
            borderBlueShadow: true,
            borderDiff: true,
            id: ['75', '34']
        },
    },
};
export default {
    type,
    auth,
    token,
    getHotelLine,
    mobileLogin,
    sendNoValidate,
    send,
    editRegisterData,
    resetSmsCode,
    upload,
    materialUpload,
    getSoftwareAssets, // 软件资产
    getSelect, // 资产类型
    getCitysMut,
    getCitysAndProName,
    getPositionAssets, // 阵地资产
    // upload,
    preview,
    inventedPreview,
    inventedRoomPreview,
    getmenulist,
    importAction,
    importVirtualHotel,
    importVirtualRoom,
    orderAction,
    withdrawAction,
    getregisterdata, //修改注册信息-查看详情
    getAccountManagerList, //获取BD
    getAccountManagerListWithAlias, //获取BD
    getAccountManagerListEditHotel, //获取BD-新建酒店
    reviewlist, //首页待办-table
    getReviewListToDo, //首页待办
    getReviewTypePullDownList, //首页待办-筛选下拉
    getIndexStatistics, //首页数据展示
    getIndexContractStatistics, // 渠道商首页数据显示 
    getIndexLine, //首页折线图
    registerAction, //注册审核
    partnerAction, //资质审核
    rootAction, //刷机审核
    reconciliationAction, //待办中-对账单审核
    visualAction,
    registerReviewDetail,
    checkConfigStatus,
    getRootDetailByReceiving,
    getAdvertisingIncomeStatistics,
    getMovieIncomeStatistics,
    getMovieDayIncomeList,
    getMovieIncomeDayLine,
    getMovieIncomeDayStatistics,
    getMovieIncomeStatisticsAllot,
    getMovieIncomeLine,
    getMovieIncomeLineAllot,
    getMovieIncomeInfoById,
    getMovieIncomeStatisticsHistory,
    getMovieIncomeListHistory,
    getMovieIncomeHistoryInfoByContract,
    iddgetMovieIncomeMcuStatistics,
    iddgetMovieIncomeDayStatistics,
    iddgetMovieMcuIncomeLine,
    iddgetMovieDayIncomeLine,
    iddgetMovieMcuIncomeList,
    iddgetMovieDayIncomeList,
    iddgetMovieIncomeInfoByContract,
    getHotelDataStatistics,
    getHotelTemplate,
    getHotelLevelAndQuality,
    getHotelTerminalInfo,
    hotelDestroy,
    hotelRecover,
    putOffLineAdvByHotel,
    editMacByHotelIdAndMac,
    getHotelLineThirdMenu,
    updateHotelName,
    getHotelLineLegend,
    getAdvertisingIncomeList,
    getAdvertisingIncomeLine,
    // getMovieCardIncomeList,
    getMovieCardIncomeStatistics,
    getMovieCardIncomeLine,
    getMovieCardRECLine,
    getMovieCardMCULine,
    addHotelUnsettleMovieIncome,
    destroyHotelUnsettleMovieIncome,
    getUnsettlehotellist,
    getHotelList,
    getHotelDataStatisticsDatacenter,
    getAllHotelDataStatistics,
    getActiveRoomNumRealTime,
    getBusinessActiveRoomNumRealTime,
    getPartner,
    getPartnerTypes,
    getPartnersByType,
    getPartnersByTypes,
    setPartner,
    hlCases,
    caseCustomSearch,
    caseView,
    caseUpdate,
    caseCreate,
    caseBatch,
    batchEnable,
    batchDisable,
    batchDel,
    getMediaFormat,
    getAllHtripCase,
    getCaseMaterialType, //刊位创建-获取刊位素材分类下拉
    getCaseScenType, //刊位创建-获取场景下拉
    createScene, //刊位创建-新增场景
    updateCases, //刊位创建-修改场景
    createCases, //刊位创建-新增刊例
    checkAdName, //刊位创建-广告位名称判断
    casesIndex, //刊位创建-广告位table
    getsCasesInfo, //刊位创建-广告位详情
    getAdType, //刊位创建-广告位类型
    orderSearch, //广告订单
    orderView, //广告订单审核详情
    getMovieIncomeList, //观影收益酒店列表
    getMovieIncomeListAllot, //观影收益酒店列表-归属
    getProvince, //省
    getCitys, //市
    getDistricts, //区
    getOptions, //酒店选项
    getBaiduSearch, //百度酒店
    getBaiduInfo, //酒店地址
    getPartnerReviewStatus, //合作伙伴列表-状态筛选下拉
    create, //创建酒店
    search, //酒店列表获取
    getHotelSearch,
    getImplementDayAndNum,
    searchNoPage, //酒店列表获取-不分页
    exportCreateHotel, //酒店列表导出
    getTerminalBrandPullDownList,
    getWaitCheckHotelList,
    getWaitCheckHotelStatistics,
    getOperationHotelList,
    getOperationHotelStatistics,
    getPerformanceLine,
    getBusinessPerformanceLine,
    getPerformanceBar,
    getPerformanceList,
    getperformancestatistics,
    // getPerformanceStatisticsByCMS,
    getRootIncomeStatistics,
    getRootIncomeList,
    getRootIncomeLine,
    getValidRoomHotelList,
    getHotelMembers,
    addHotelMember,
    delHotelMember,
    activateHotelMember,
    getRegisteDetail,
    transferAuth,
    getRooms,
    getRoomsNew,
    getRoomsNews,
    getRoomsDetail,
    roomManagement,
    getVirtualRooms,
    exportChannelStatistics,
    exportScreenHotel,
    exportAllHotel,
    view,
    update,
    formal,
    getHotelCaseStatusById,
    editHotelCaseStatus,
    getOperationHotelRankingList,
    getHotelCurrentVisualInfo,
    getHotelMaterialByType,
    destroyHotelMaterial,
    uploadHotelPicture,
    uploadHotelVideo,
    uploadHotelVisualData,
    getModelIsReadOnly,
    partnerSearch,
    partnerDestory,
    getPartnerConfigType,
    getPartnerConfigTerm,
    partnerExport,
    partnerBatch,
    partnerView,
    partnerUpdate,
    updatePartnerConfig,
    updatePartnerHotelConfig,
    setPartnerTypeById,
    getAccountDetailByCurrentAdmin,
    updateAccountInfoByCurrentAdmin,
    getBelongPartnerOptions,
    getAllotPartnerOptions,
    getAllotPartnerDefaultConfigure,
    getPartnerConfigure,
    getPartnerPreConfigure,
    updatePartnerInfo,
    getConfigureHotelList,
    getHotelConfigure,
    getHotelPreConfigure,
    infoCreate,
    infoUpdate,
    infoSearch,
    infoDestory,
    infoView,
    infoPublish,
    getInformationByCurrentAdmin,
    informationMessage,
    alreadyRead,
    getsdklist,
    addsdk,
    destroySdk,
    editSdk,
    addDownloadNum,
    getReconciliationList,
    getPreReconciliationList,
    getCardPreReconciliationList,
    getReconciliationStatistics,
    editReconcilationStatusByIdList,
    getReconciliationInfo,
    getRootReconciliationRoomsInfo,
    getMarketReconciliationRoomsInfo,
    editReconciliationStatusByIdList,
    editReconciliationHtripStatusByIdList,
    applyWithdrawList,
    partnerwithdraw,
    applyWithdrawShow,
    partnerwithdrawContent,
    partnerwithdrawTrue,
    applyWithdrawStatement,
    getWithdrawAmount,
    approvalWithdrawList,
    approvalWithdrawListAdmin,
    getWithdrawContent,
    approvalWithdrawStatement,
    editWithdrawPaymentStatus,
    getIncomeStatistics,
    getIncomePartnerList,
    getActiveConfigList,
    editActiveConfig,
    getHotelMapInfo,
    getAddressByCoordinate,
    orange,
    green,
    blue,
    yellow,
    purple,
    groupIndex,
    indexWithPage,
    roletree,
    groupLevel,
    findUsers,
    groupAdd,
    groupEdit,
    groupDel,
    ruleIndex,
    accountIndex,
    getDepartment,
    getGroup,
    getUserRuleLists,
    getSupAdmin,
    getSupPartner,
    accountAdd,
    accountEdit,
    changeStat,
    accountDel,
    accountExportCsv,
    getIndexHeader,
    DataassetsExport,
    exportPerformanceHotelList,
    exportBusinessPerformanceHotelList,
    exportBusinessMovie,
    getImportantCityYesterday,
    getFlowList,
    getFlowProcessByFlowId,
    getHtripAccount,
    addFlowProcess,
    destroyFlowProcess,
    editProcessLinkByFlowId,
    chartColor,
    tableHeaderStyle,
    symbolSize,
    baseUrl,
    materialStyle,
    exportBootTime,
    getHotelsListWaitForCheck,
    HotelMemberCheck,
    exportInactiveList,
    getExportAllHotelList,
    updateHotelpattern, // 修改模式
    iddPartnerwithdraw,
    iddApplyWithdrawShow,
    iddPartnerwithdrawContent,
    iddPartnerwithdrawTrue,
    iddApprovalWithdrawListAdmin,
    iddGetWithdrawContent,
    auditResult,
    ukGetWithdrawContent,
    ukApprovalWithdrawListAdmin,
    codeLogin,
    getLogo,
    getAccountMovies,
    getHotelGroup
}