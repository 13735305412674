<template>
    <div class="numBox" v-if="index===text">
        <div class="item">
            <p class="noWrap">酒店数</p>
            <p class="numBold noWrap">{{showDataCom(hotelNum)}} </p>
        </div>
        <!-- <div class="line"></div> -->
        <div class="item">
            <p class="noWrap">终端数</p>
            <p class="numBold noWrap">{{showDataCom(terNum)}} </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sumBox",
        props:['index','text','hotelNum','terNum'],
        data:()=>({

        }),
        computed:{
            showDataCom() {
                return function (val) {
                    if (val === null) {
                        return 0;
                    } else {
                        return parseFloat(val).toLocaleString();
                    }
                };
            },
        }
    }
</script>

<style scoped lang="scss">
    .noWrap{
        white-space: nowrap;
    }
    .sumBox{
        width:240px!important;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        padding:0 20px;
        .title,.num{
            white-space: nowrap;
        }
        .numBox{
            display:flex;
            padding-top:20px;
            .item{
                display:flex;
                flex-direction: column;
                padding:0 20px;
                width:120px!important;
                box-sizing: border-box;

                p{
                    text-align: center;
                }
            }
        }
        .numBold{
            font-weight: bold;
            font-size: 28px;
            color: rgba(1, 1, 1, 1);
            padding-top: 20px;
            font-family: DIN !important;
        }
        // .line{
        //     width:2px;
        //     height:100%;
        //     background:#ddd;
        // }

        &.terminalTtile.noBack{
            background:none!important;
        }
    }
</style>