<template>
  <div>
    <el-drawer title="批量上传" :visible.sync="drawerVisibale" direction="rtl" :before-close="handleClose" :destroy-on-close="true" size="90%" :modal="false">
      <el-tabs v-model="activeName">
        <!-- 携旅授权书批量 -->
        <el-tab-pane label="携旅授权书" name="1" v-if="false">
          <el-form ref="htripForm" :rules="htripRule" :model="htripForm" label-width="120px">
            <el-form-item prop="contractStr" label="输入合同号">
              <el-input type="textarea" v-model="htripForm.contractStr" resize="none"></el-input>
            </el-form-item>
            <el-form-item label="批量动作" prop="opeareteType">
              <el-select style="width:100%" v-model="htripForm.opeareteType" placeholder="请选择动作">
                <el-option label="生成授权书" value="1"></el-option>
                <el-option label="关闭授权书" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置授权期限" prop="starToEndTime">
              <el-date-picker style="100%" v-model="htripForm.starToEndTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="设置授权日期" prop="starToEndTime">
              <el-input v-model="htripForm.authorizationTime"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="submitBtnBox">
                <el-button type="primary" @click="submitHtripForm('htripForm')">确定</el-button>
              </div>
            </el-form-item>
          </el-form>

        </el-tab-pane>
        <!-- 携旅授权书批量 -->
        <!-- 腾讯授权书批量 -->
        <el-tab-pane label="腾讯授权书" name="2">
          <div style="color:red;font-size:12px;margin-bottom:20px">注：上传文件名称必须为对应酒店合同号，否则会出现上传显示错误。</div>
          <el-form ref="tencentForm" :rules="tencentRule" :model="tencentForm" label-width="90px">
            <!-- <el-form-item prop="contractStr" label="输入合同号">
              <el-input type="textarea" v-model="tencentForm.contractStr" resize="none"></el-input>
            </el-form-item> -->
            <el-form-item prop="fileList" label="上传图片">
              <el-upload class="upload-demo" :before-upload="upLoadBefore" :on-success="upLoadSuccess" :action="global.materialUpload" :headers="headers" multiple :file-list="tencentForm.fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="已上传素材">
              <div class="picShowBox">
                <template v-if="tencentForm.fileList.length!==0">
                  <div v-for="(item,index) in  tencentForm.fileList" class="picCard" :key="index">
                    <img :src="item.url" alt="">
                    <div class="btnBox">
                      <i class="el-icon-search" @click="openImage(item.url)"></i>
                      <i class="el-icon-delete" @click="delImage(index)"></i>
                    </div>
                    <div class="model"></div>
                  </div>
                </template>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="submitBtnBox">
                <el-button type="primary" class="largeBtn"  @click="submitTencentForm('tencentForm')">确 定</el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!-- 腾讯授权书批量 -->
      </el-tabs>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
// api引入
import { createTxCertificate } from "@/api/hotel.js";
// qs引入
import qs from "querystring";
export default {
  // 组件名称
  name: "authorizationMoreOpearate",
  data() {
    return {
      auth:null,
      dialogVisible: false,
      dialogImageUrl: "",
      // 界面显示控制字段
      drawerVisibale: false,
      // tab绑定字段
      activeName: "2",
      // 携旅授权书表单
      htripForm: {
        contractStr: "",
        opeareteType: "",
        authorizationTime: "",
        starToEndTime: [],
      },
      // 携旅授权书表单规则
      htripRule: {
        contractStr: [
          {
            required: true,
            message: "请输入合同号",
            trigger: "blur",
          },
        ],
        opeareteType: [
          {
            required: true,
            message: "请选择批量动作",
            trigger: "blur",
          },
        ],
        authorizationTime: [
          {
            required: true,
            message: "请设置授权日期",
            trigger: "blur",
          },
        ],
        starToEndTime: [
          {
            required: true,
            message: "请设置授权期限",
            trigger: "blur",
          },
        ],
      },
      // 腾讯授权书表单
      tencentForm: {
        contractStr: "",
        fileList: [],
      },
      // 腾讯授权书表单规则
      tencentRule: {
        contractStr: [
          {
            required: true,
            message: "请输入合同号",
            trigger: "blur",
          },
        ],
        fileList: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (value.length === 0) {
                callback(new Error("请上传图片"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },
      // 上传token验证
      headers: {
        token: "",
      },
    };
  },
  mounted() {
    // 获取token
    this.headers.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
  methods: {
    // 查看以上传文件
    openImage(url) {
      this.dialogVisible = true;
      this.dialogImageUrl = url;
    },
    // 删除已上传图片
    delImage(index) {
      // 强制加载页面
      this.$nextTick(function () {
        // 删除数据
        this.tencentForm.fileList.splice(index, 1);
      });
    },
    // 关闭弹框
    handleClose() {
      this.drawerVisibale = false;
    },
    // 打开对话框
    open() {
      this.drawerVisibale = true;
    },
    // 携旅授权书提交
    submitHtripForm(formName) {
      // 表单规则验证
      this.$refs[formName].validate((valid) => {
        // 验证通过
        if (valid) {
        } else {
          // 验证失败
          this.$message.warning("请将表单数据填写完整"); //提示错误信息
          return false;
        }
      });
    },
    // 腾讯授权书提交
    submitTencentForm(formName) {
      // 表单规则验证
      this.$refs[formName].validate((valid) => {
        // 验证通过
        if (valid) {
          // 已选数据处理
          let arr = this.tencentForm.fileList.map((i) => {
            // 将元素重构成以下结构
            let obj = {
              hotel_contract: i.name.split(".")[0],
              url: i.url,
            };
            // 抛出数据
            return obj;
          });
          // 参数 json
          let json = {
            param: JSON.stringify(arr), //将数组转换成json串
             user_name: this.auth.user_name,
          };
          // 格式转换
          var param = qs.stringify(json);
          // 批量生成接口
          createTxCertificate(param)
            .then((res) => {
              if (res.data.code === 100000) {
                this.$message.success("操作成功"); //提示
                this.tencentForm.fileList = []; // 清空上传信息
                this.handleClose(); // 关闭对话框
                this.$emit("opearetesuccess"); //  成功回调函数
              } else {
                this.$message.warning(res.data.msg); //提示错误信息
              }
            })
            .catch((err) => {});
        } else {
          // 验证失败
          this.$message.warning("请将表单数据填写完整"); //提示错误信息
          return false;
        }
      });
    },
    upLoadBefore(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式或者 PNG 格式!");
      }

      return isJPG;
    },
    // 图片上传成功回调
    upLoadSuccess(response, file, fileList) {
      // 强制加载页面
      this.$nextTick(function () {
        // 深克隆已上传图片数据
        this.tencentForm.fileList = JSON.parse(JSON.stringify(fileList));
        // 图片数据的重新构建赋值
        this.tencentForm.fileList.forEach((element) => {
          // 遍历
          element.url = element.response.data.url; // 结构变更赋值
        });
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-upload-list {
  display: none;
}
::v-deep .el-textarea__inner {
  height: 150px;
}
.submitBtnBox {
  text-align: right;
  width: 100%;
}
.picShowBox {
  height: 450px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;

  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: visible;
}
.picCard {
  float: left;
  height: 100px;
  width: 24%;
  margin-right: 1%;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  position: relative;
  box-sizing: border-box;
}
.picCard img {
  max-width: 100%;
  max-height: 100%;
}
.picCard .btnBox {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 16px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: #fff;
  z-index: 11;
}
.picCard .btnBox i {
  margin-left: 5px;
}
.picCard .btnBox i:hover {
  color: aqua;
  transition: 0.3s;
}
.picCard:hover .btnBox {
  display: block;
}
.picCard .model {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.picCard:hover .model {
  background: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
}
.largeBtn{
  width: 100px;
  height: 40px;
}
</style>