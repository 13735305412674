<template>
    <div class="inputColorChange inputColorNone">
        <div class="outTopBox">
            <top-bread :bread="bread" v-if="curPage==='/indexShow'">
                <span slot="second">首页</span>
                <span slot="third">更多</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskToDo" v-if="curPage==='/taskToDo'">
                <span slot="second">任务管理</span>
                <span slot="third">待办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskDone" v-if="curPage==='/taskDone'">
                <span slot="second">任务管理</span>
                <span slot="third">已办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-form label-position="right" :rules="rules" label-width="120px" :model="form"  ref="register">
            <el-card class="box-card">
                <back @backMeth="backMeth"></back>
                <line-title>注册信息</line-title>
                <div class="formBox"  ref="registerForm" v-if="form.type==='register'">
                    <el-form-item label="所属公司名称" class="halfWidth" label-width="200px">
                        <el-input v-model="form.company_name" :disabled="readOnly"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人"  class="halfWidth" label-width="200px">
                        <el-input v-model="form.contact_name" :disabled="readOnly"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" class="getCode halfWidth" label-width="200px">
                        <el-input id="mobile" ref="mobile" :disabled="readOnly" v-model="form.mobile"></el-input>
                    </el-form-item>
                </div>
            </el-card>
            <el-card class="box-card">
                <line-title>审核信息</line-title>
                <el-row>
                    <el-form-item label="合作类型" class="halfWidth " prop="coopType" label-width="200px">
                        <el-select v-model="form.coopType" placeholder="请选择" class="selectItem" :disabled="!btnShow" @change="coopTypeChange($event,'getType')" clearable>
                            <el-option
                                    v-for="item in selectData.coopType"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-col :span="12">
                        <el-form-item label="上级合作伙伴" class="getCode" label-width="200px" v-if="form.coopType==='channel' || form.coopType==='screen' || form.coopType==='factory'">
                            <el-select v-model="form.parentPartnerId" placeholder="请选择"
                                       class="selectItem"
                                       :disabled="!btnShow"
                                       clearable
                                       filterable>
                                <el-option
                                        v-for="item in selectData.parentOption"
                                        :key="item.id"
                                        :label="item.company_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!--<el-form-item prop="isMovieCard" label="是否观影卡合作" label-width="200px" v-if="form.coopType==='group'" @change="radioChange">-->
                            <!--<el-radio-group  v-model="form.isMovieCard" class="radioBox" :disabled="!btnShow">-->
                                <!--<el-radio :label="1">是</el-radio>-->
                                <!--<el-radio :label="0">否</el-radio>-->
                            <!--</el-radio-group>-->
                        <!--</el-form-item>-->
                        <el-form-item prop="hotelBrand" label="集团品牌" label-width="200px" v-if="form.coopType==='group'" >
                            <el-select v-model="form.hotelBrand" multiple  :disabled="!btnShow"
                                       class="hotelBrand fullSelect"
                                       filterable
                                       clearable>
                                <el-option
                                        v-for="item in selectData.hotelGroupList"
                                        :key="item.id"
                                        :label="item.groupname"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="contract365" label="终端门店编码" label-width="200px" v-if="form.coopType==='monomer'">
                            <el-input class="input-with-select fullSelect" :disabled="!btnShow"
                                      v-model="form.contract365"></el-input>
                        </el-form-item>
                        <el-form-item label="" class="getCode" label-width="200px" v-if=" form.coopType==='' || form.coopType==='monomer'" style="visibility: hidden">
                            <el-select v-model="form.parentPartnerId" placeholder="请选择" class="selectItem"  >
                                <el-option
                                        v-for="item in selectData.parentOption"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="btnShow">
                        <el-form-item class="rightBtnGroup">
                            <el-button type="primary" @click="pass" class="largeBtn">通过</el-button>
                            <el-button type="danger" @click="reject" class="largeBtn">驳回</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
    </div>
</template>

<script>
    import commonMeth from '../../api/method'
    import  qs from 'querystring'
    export default {
        name: "IndexDoDetail",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/indexDoMore'},
            },
            breadTaskToDo:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/taskToDo'},
            },
            breadTaskDone:{
                level:4,
                thirdPath:{path: '/taskDone'},
            },
            id:'',   //接收参数
            readOnly:true,
            labelPosition: 'top',
            second:'60',
            showCountDown:'',
            form:{
                username:'',
                company_name:'',
                contact_name:'',
                mobile:'',
                code:'',
                message:'',
                type:'',
                coopType:'',
                validate:false,
                parentPartnerId:'',
                isMovieCard:'',
                hotelBrand:[],
                contract365:'',
            },
            selectData:{
                partner:[],
                coopType:[],
                parentOption:[
                    {id:1,name:'合作伙伴1'},
                    {id:2,name:'合作伙伴2'},
                    {id:3,name:'合作伙伴3'},
                ],
                hotelGroupList:[],
            },
            rules:{
                coopType: [
                    { required: true, message: '请选择合作伙伴类型', trigger: 'blur' },
                ],
                isMovieCard: [
                    { required: true, message: '请选择是否观影卡合作', trigger: 'blur' },
                ],
                hotelBrand: [
                    { required: true, message: '请选择集团品牌', trigger: 'blur' },
                ],
                contract365: [
                    { required: true, message: '请输入终端门店编码', trigger: 'blur' },
                ],
            },
            query:{},
            token:'',
            btnShow:'',
            mainLoading:true,
            curPage:''
        }),
        methods:{
            reject() {
                this.$refs.rejectDialog.dialogShow(true);
            },
            pass(){
                this.submitForm(this.form.type);
                this.$refs['register'].validate((valid)=>{
                    if(valid){
                        this.$confirm('确定通过?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.examine(1);
                        }).catch(() => {

                        });
                    }else{
                        this.$message.warning('请完善审核信息');
                    }
                })
            },
            showMessage(str,type){
                this.$message({
                    message: str,
                    type: type
                });
            },
            getCoopType(){
                this.$http.get(this.global.getPartnerTypes,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.coopType=res.data.data;
                    }
                })
            },
            coopTypeChange(type){
                this.form.isMovieCard='';
                this.form.hotelBrand=[];
                this.form.contract365='';
                this.$http.get(this.global.getPartnersByType,{
                    params:{
                        token:this.token,
                        type:this.form.coopType
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.parentOption=res.data.data;
                    }
                })
            },
            radioChange(){
                this.form.parentPartnerId='';
            },
            examine(status){
                var param=qs.stringify({
                    token:this.token,
                    id:this.query.id,
                    type:this.form.coopType,
                    status:status,
                    message:this.$refs.rejectDialog.dialog.reason,
                    parentPartnerId:this.form.parentPartnerId,
                    isMovieCard:this.form.isMovieCard,
                    brandIds:this.form.hotelBrand.join(','),
                    idList:this.form.hotelBrand.join(','),
                    contract365:this.form.contract365,
                });
                this.$http.post(this.global.registerAction,param).then(res=>{
                    var str='';
                    status===1?str='审核':str='驳回';
                    if(res.data.code===100000){
                        this.showMessage(str+'成功','success');
                        this.$router.push('/indexDoMore')
                    }else{
                        this.showMessage(res.data.msg,'error');
                    }
                });
            },
            getDetail(){
                this.mainLoading=true;
                this.$http.get(this.global.registerReviewDetail,{
                    params:{
                        token:this.token,
                        id:this.query.id,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var formData=res.data.data;
                        this.form.username=formData.username;
                        this.form.company_name=formData.company_name;
                        this.form.contact_name=formData.contact_name;
                        this.form.mobile=formData.mobile;
                        this.form.type=formData.type;
                        this.form.coopType=formData.partner_type;
                        this.form.parentPartnerId=formData.parentPartnerName;
                        this.form.isMovieCard=formData.isMovieCard?formData.isMovieCard:'';
                        this.form.hotelBrand=formData.brand?formData.brand.map(String):[];
                        this.form.contract365=formData.contract365?formData.contract365:'';
                    }else{
                        this.$message.warning(res.data.msg)
                    }
                    this.mainLoading=false;
                }).catch((res)=>{
                    // this.$message.warning('获取信息失败')
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.form.validate=true;
                        //通过验证，执行子组件方法
                        // return 'pass';
                    } else {
                        return false;
                    }
                });
            },
            getPartnerType(){
                this.$http.get(this.global.getPartnerTypes,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.coopType=res.data.data;
                    }
                });
            },
            backMeth(){
                this.$router.push({path:`./${this.query.fromUrl}`})
            },
            getOption(){
                return new Promise((resolve,rejct)=>{
                    this.$http.get(this.global.getOptions,{
                        params:{
                            token:this.token
                        }
                    }).then(res=>{
                        if(res.data.code===100000){
                            var optionData=res.data.data;
                            this.selectData.hotelGroupList=optionData.hotelGroupList;
                        }
                        resolve();
                    });
                })
            },
        },

        mounted(){
            this.token=sessionStorage.getItem('token');
            var query=this.$route.query;
            this.query=query;
            this.curPage=sessionStorage.getItem('defaultActive');
            query.reviewStatus==='待审核'?this.btnShow=true:this.btnShow=false;
            this.getCoopType();
            this.getDetail();
            this.getPartnerType();
            this.getOption();
        }
    }
</script>

<style scoped lang="scss">

    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .btnRow{
        display:flex;
        direction:rtl;
        .el-button:first-child{
            margin-left:10px;
        }
    }
    .formBox{
        width:100%;
        /*form{*/
            /*width:900px;*/
            /*margin:0 auto;*/
        /*}*/
    }
    .el-input>input{
        border:none!important;
    }
    .selectItem{
        width:100%;
    }
    .halfWidth{
        width:50%;
    }
    .radioBox{
        margin-top:12px;
    }
</style>
