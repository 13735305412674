<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="700px" :before-close="handleClose" append-to-body>
      <div class="container" v-if="type=='As'">
        <el-form :model="data" label-width="100px" label-position="top">
            <el-form-item>
            <el-button type="primary" @click="submitAs">提交</el-button>
          </el-form-item>
          <el-form-item label="uninstall">
            <el-button class="addBtn" type="primary" @click="addAsItem('uninstall')">添加</el-button>
            <template v-for="(item,index) in uninstall">
              <div :key="index+'uninstall'" style="margin-top:5px" class="inputBox">
                <el-input v-model="item.value"></el-input>
                <el-button type="danger" @click="delAsItem('uninstall',index)">删除</el-button>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="deletefiles">
            <el-button class="addBtn" type="primary" @click="addAsItem('deletefiles')">添加</el-button>
            <template v-for="(item,index) in deletefiles">
              <div :key="index+'deletefiles'" style="margin-top:5px" class="inputBox">
                <el-input v-model="item.value"></el-input>
                <el-button type="danger" @click="delAsItem('deletefiles',index)">删除</el-button>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="shellList">
            <el-button class="addBtn" type="primary" @click="addAsItem('shellList')">添加</el-button>
            <template v-for="(item,index) in shellList">
              <div :key="index+'shellList'" style="margin-top:5px" class="inputBox">
                <el-input v-model="item.value"></el-input>
                <el-button type="danger" @click="delAsItem('shellList',index)">删除</el-button>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="apkList">
            <el-button class="addBtn" type="primary" @click="addAsItem('apkList')">添加</el-button>
            <template v-for="(item,index) in apkList">
              <div :key="index+'apkList'" style="margin-top:5px" class="inputBox">
                <el-input v-model="item.value"></el-input>
                <el-button type="danger" @click="delAsItem('apkList',index)">删除</el-button>
              </div>
            </template>
          </el-form-item>
        
        </el-form>
      </div>
      <div class="container" v-if="type=='App'">

        <el-form :model="appData" label-width="120px" label-position="left">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="submitApp">提交</el-button>
            <el-button type="primary" @click="addAsItem('app')">添加</el-button>
          </el-form-item>
          <template v-for="(item,index) in appData.data">
            <div :key="index+'appData'" class="appBox">
              <el-form-item label="appName">
                <div class="inputBox">
                  <el-input v-model="item.appName"></el-input>
                  <el-button v-if="appData.data.length>1" type="danger" @click="delAsItem('app',index)">删除</el-button>
                </div>
              </el-form-item>
              <el-form-item label="packageName" :key="index+'appData'">
                <div class="inputBox">
                  <el-input v-model="item.packageName"></el-input>
                </div>
              </el-form-item>
            </div>
          </template>

        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { readTxtFile, dealTxtFile } from "../../api/application/downloadConfig";
import axios from "axios";
import qs from "qs";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      type: "As",
      data: {},
      appData: {},
      id: "",
      database: "",
      item: {},
      uninstall: [],
      deletefiles: [],
      shellList: [],
      apkList: [],
    };
  },
  methods: {
    submitApp() {
      let flag = this.appListSpaceVerification();
      if (flag) {
        this.$confirm("是否提交当前信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let num = this.appData.data.length - 1;
          this.appData.count = [];
          // for (let i = 0; i < num; i++) {
          //   this.appData.count.push({
          //     count: 3 + i,
          //   });
          // }
          let json = {
            id: this.id,
            database: this.database,
            content: JSON.stringify(this.appData),
          };
          let param = qs.stringify(json);
          dealTxtFile(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("保存成功");
                    this.$emit("update")
                this.dialogVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.warning("保存失败");
            });
        });
      } else {
        this.$message.warning("请将信息补充完整");
      }
    },
    // as 未填写字段验证
    appListSpaceVerification() {
      let a = this.appData.data.filter((i) => {
        if (i.appName == "" || i.packageName == "") {
          return i;
        }
      });
      if (a.length !== 0) {
        return false;
      } else {
        return true;
      }
    },
    // 删除项
    delAsItem(type, index) {
      if (type == "app") {
        this.appData.data.splice(index, 1);
        console.log(this.appData.data.length);
      } else {
        this[type].splice(index, 1);
      }
    },
    // 添加项
    addAsItem(type) {
      if (type == "app") {
        this.appData.data.unshift({
          appName: "",
          packageName: "",
        });
      } else {
        this[type].push({
          name: "appName" + this[type].length,
          value: "",
        });
      }
    },
    // 自动化配置提交
    submitAs() {
      let flag = this.ASDataVerification();
      if (flag) {
        this.$confirm("是否提交当前信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.data.uninstall = this.ASArrayToObj(this.uninstall);
          this.data.deletefiles = this.ASArrayToObj(this.deletefiles);
          this.data.shellList = this.ASArrayToObj(this.shellList);
          this.data.apkList = this.ASArrayToObj(this.apkList);
          let json = {
            id: this.id,
            database: this.database,
            content: JSON.stringify(this.data),
          };
          let param = qs.stringify(json);
          dealTxtFile(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("保存成功");
                this.$emit("update")
                this.dialogVisible = false;
                
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.warning("保存失败");
            });
        });
      } else {
        this.$message.warning("请将信息补充完整");
      }
    },
    //  数组数据转格式对象
    ASArrayToObj(list) {
      let obj = {};
      list.forEach((i, index) => {
        obj["packageName" + index] = i.value;
      });
      return obj;
    },
    // as 未填写字段验证
    ASListSpaceVerification(list) {
      let a = list.filter((i) => {
        if (i.value == "") {
          return i;
        }
      });
      if (a.length !== 0) {
        return false;
      } else {
        return true;
      }
    },
    // as 条目数据验证
    ASDataVerification() {
      let uninstallSpace = this.ASListSpaceVerification(this.uninstall);
      let deletefilesSpace = this.ASListSpaceVerification(this.deletefiles);
      let shellListSpace = this.ASListSpaceVerification(this.shellList);
      let apkListSpace = this.ASListSpaceVerification(this.apkList);
      if (
        uninstallSpace &&
        deletefilesSpace &&
        shellListSpace &&
        apkListSpace
      ) {
        return true;
      } else {
        return false;
      }
    },
    // as 数据转对象格式
    asDataToList(list) {
      this[list] = [];
      Object.keys(this.data[list]).forEach((key) => {
        this[list].push({
          name: key,
          value: this.data[list][key],
        });
      });
      console.log(this[list]);
    },
    // 路径截取
    findStr(str, cha, num) {
      var x = str.indexOf(cha);
      for (var i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
        console.log(x);
      }
      return x;
    },
    // 展开界面
    open(type, row, query,msg) {
      if (query) {
        this.database = query.hotelName;
      }
      this.type = type;
      if (type == "As") {
        this.title = "AS自动化配置";
      } else {
        if(msg=='out'){
           this.title = "先卸载后安装应用名单";
        }else{
           this.title = "应用安装白名单";
        }
      }
     
      this.item = row;
      this.id = row.id;
      let json = {
        url: row.music_addr,
      };
      readTxtFile(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (this.type == "As") {
              this.data = res.data.data;
              this.asDataToList("uninstall");
              this.asDataToList("deletefiles");
              this.asDataToList("shellList");
              this.asDataToList("apkList");
            } else {
              this.appData = res.data.data;
            }
            this.dialogVisible = true;
          } else {
            this.$$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("数据读取失败");
        });
    },

    handleClose() {
      this.dialogVisible = false;
    },
    submitForm() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: block !important;
  position: relative;
}
.addBtn {
  position: absolute;
  top: -50px;
  left: 100px;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  .el-button {
    margin-left: 20px;
  }
}
.appBox {
  margin-top: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 15px 15px 0;
}
</style>
