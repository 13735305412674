import request from './request';
import qs from "querystring";

//   上传到图片素材库
export function uploadHotelPicture(param) {
    return request({
        url: `v1/Visual/uploadHotelPicture`,
        method: 'post',
        data: param,
    });
}
//   上传到视频素材库
export function uploadHotelVideo(param) {
    return request({
        url: `v1/Visual/uploadHotelVideo`,
        method: 'post',
        data: param,
    });
}
//   素材库删除资源
export function destroyHotelMaterial(param) {
    return request({
        url: `v1/Visual/destroyHotelMaterial`,
        method: 'post',
        data: param,
    });
}
//   获取素材库不同类型资源
export function getHotelMaterialByType(param) {
    return request({
        url: `v1/Visual/getHotelMaterialByType`,
        method: 'post',
        data: param,
    });
}

//   可视化发布
export function uploadHotelVisualData(param) {
    return request({
        url: `v1/Visual/uploadHotelVisualData`,
        method: 'post',
        data: param,
    });
}
//  发送百度审核
export function auditing(param) {
    return request({
        url: `/content-security/api/v1/auditing`,
        method: 'post',
        data: param,
    });
}

//  发送百度审核
export function verificationResult(param) {
    return request({
        url: `/content-security/api/v1/result`,
        method: 'post',
        data: param,
    });
}
// 版式可视化数据获取
export function getLauncher(param) {
    return request({
        url: `v2/Hotelvisualization/getLauncher`,
        method: 'post',
        data: qs.stringify(param)
    });
}
// 版式可视化数据发布
export function setLauncher(param) {
    return request({
        url: `v2/Hotelvisualization/setAllLauncher`,
        method: 'post',
        data: qs.stringify(param)
    });
}
// 版式可视化数据发布审核
export function setLauncherPro(param) {
    return request({
        url: `v2/Hotelvisualization/setLauncherPro`,
        method: 'post',
        data: qs.stringify(param)
    });
}

//   获取发布完成详情
export function getFinishUpdateRooms(param) {
    return request({
        url: `v2/Content/finishUpdateRooms`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   版本修改
export function editVersion(param) {
    return request({
        url: `v2/Content/editVersion`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//  获取发布更新列表
export function getVersion(param) {
    return request({
        url: `v2/Versionlog/getVersion`,
        method: 'post',
        data: qs.stringify(param),
    });
}