<!-- 签名管理 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span v-if='!query' slot="second">设备管理</span>
        <span v-if='!query' slot="third">签名管理</span>
        <span v-if='query' slot="second">签名管理</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <!-- table -->
      <div>
        <el-button type="primary" class="btn" @click="
            current = {};
            signDia = true;
          ">添 加</el-button>
        <el-button type="danger" @click="handleDel" class="btn">批量删除</el-button>
        <el-table :data="tableData" border style="width: 100%" :header-cell-style="{
            background: '#f8fbff',
            color: '#606266',
          }" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="id" label="编号"></el-table-column>
          <el-table-column prop="name" label="签名名称"></el-table-column>
          <el-table-column prop="sign_short" label="签名简写"></el-table-column>
          <el-table-column prop="sign_md5" label="签名MD5" min-width="220px"></el-table-column>
          <!-- <el-table-column prop="id" label="创建时间"></el-table-column>
          <el-table-column prop="id" label="创建者"></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="230">
            <template slot-scope="scope">
              <el-button @click="
                  current = scope.row;
                  signDia = true;
                " type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="pagination" background layout="total,prev, pager, next" :total="pagination.total" :current-page="pagination.currentPage" v-show="pagination.total != 0" @current-change="
            (val) => {
              pagination.currentPage = val;
              getList();
            }
          ">
        </el-pagination>
      </div>
    </el-card>
    <SignOper :visible.sync="signDia" :current="current" @refresh="
        pagination.currentPage = 1;
        getList();
      "></SignOper>
  </div>
</template>
<script>
import SignOper from "@/components/operation/DeciveManage/components/signOper";
import { getList, delSign } from "./api/sign";
export default {
  name: "params",
  components: { SignOper },
  data() {
    return {
      bread: {
        level: 3,
      },
      tableData: [],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      selected: [],
      signDia: false,
      current: {},
    };
  },
  methods: {
    handleDel() {
      if (this.selected.length == 0) {
        this.$message.error("尚未选择签名");
        return;
      }
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = this.selected
            .map((item) => {
              return item.id;
            })
            .join(",");
          delSign({
            ids: ids,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.getList();
              this.selected = [];
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.selected = val;
    },
    getList() {
      getList({
        page: this.pagination.currentPage,
        pageSize: 10,
      }).then((data) => {
        this.tableData = data.data.data.rows;
        this.pagination.total = parseInt(data.data.data.totalRows);
      });
    },
  },
  mounted() {
    this.query = sessionStorage.getItem("apaasData");
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
.table_img {
  width: 100px;
}
</style>
