<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">信息发布</span>
        <span slot="third">定时插播</span>
        <span slot="fourth">插播信息</span>
      </top-bread>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width='80px'>

      <el-card class="box-card">

        <div class="title chartTitle blueTitle">第一步，填写任务名称</div>
        <el-form-item label="任务名称" prop='name'>
          <el-input style="width:300px" v-model="form.name" :readonly="isEdit" placeholder="请输入任务名称"></el-input>
        </el-form-item>

      </el-card>
      <el-card class="box-card">

        <div class="title chartTitle blueTitle">第二步，设置发布时间</div>
        <el-form-item label="发布周期" prop='release_time'>
          <el-date-picker :disabled="isEdit" v-model="form.release_time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="每日时间">
          <div>
            <template v-for="(item,index) in form.every_day">

              <div :key="index" style="margin-bottom:10px">
                <el-time-picker is-range :clearable='false' format='HH:mm:ss' value-format='HH:mm:ss' v-model="form.every_day[index]" range-separator="到" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
                </el-time-picker>

                <el-button class="smallBtn" v-if="!isEdit" icon="el-icon-plus" circle @click="addEveryTime" style="margin-left:8px"></el-button>
                <el-button class="smallBtn" icon="el-icon-close" v-if="form.every_day.length>1&&!isEdit" circle @click="delEveryTime(index)"></el-button>
              </div>

            </template>
          </div>

        </el-form-item>

      </el-card>
      <el-card class="box-card">

        <div class="title chartTitle blueTitle">第三步，编辑发布内容</div>
        <el-form-item label="发布类型" prop='type'>
          <el-radio-group :disabled="isEdit" v-model="form.type">
            <el-radio-button label="1">弹窗</el-radio-button>
            <el-radio-button label="2">滚动字幕</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <div v-if='form.type == 1'>
          <el-form-item label="内容类型" prop='sourceType'>
            <el-radio-group :disabled="isEdit" v-model="form.sourceType" class="circleBox" @change="changeSourceType">
              <el-radio label="pic">图片</el-radio>
              <el-radio label="video">视频</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传内容" prop='fileList'>
            <div>
              <el-button v-if="!isEdit" class="trueBtn" type="primary" style="margin-bottom:10px" @click="showUploadMore">上传</el-button>
              <div class="fileBox">
                <div class="sourceBox" v-for="(item,index) in form.fileList" :key='index'>
                  <img :src="item.source_url" v-if="form.sourceType=='pic'" alt="">
                  <video :src="item.source_url" alt="" v-if="form.sourceType!='pic'"></video>
                  <i class="el-icon-delete" v-if="!isEdit" @click="delSource(index)"></i>
                  <i class="el-icon-search" @click="showSource(item.source_url)"></i>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-if='form.type == 2'>
          <el-form-item label="编辑内容" prop='scroll_text'>
            <el-input :readonly="isEdit" type="textarea" resize='none' style="width:300px;" @blur='textChange' :rows="6" v-model="form.scroll_text" autosize placeholder="请输入滚动字幕内容" maxlength="100" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="文字大小" prop='size'>
            <el-select :disabled="isEdit" style="width:300px" v-model="form.size" placeholder="请选择">
              <el-option v-for="item in sizeList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文字颜色" prop='color'>
            <el-color-picker :disabled="isEdit" v-model="form.color"></el-color-picker>
          </el-form-item>
        </div>
        <el-form-item v-if="isEdit">
          <div class="rightBtn">

            <el-button class="cancelBtn" @click="back">返 回</el-button>
          </div>

        </el-form-item>
      </el-card>
      <el-card class="box-card" v-if="!isEdit">
        <div class="title chartTitle blueTitle">第四步，选择发布对象</div>
        <el-form-item label="选择酒店" prop='hotel_contract'>
          <el-select v-if="!isEdit" style="width:300px" :disabled="disabledHotel" v-model="form.hotel_contract" filterable remote reserve-keyword placeholder="请输入酒店名称" :remote-method="remoteMethod" :loading="loading" @change="selectChange">
            <el-option v-for="item in hotelList" :key="item.hotel_contract" :label="item.hotel" :value="item.hotel_contract">
            </el-option>
          </el-select>
          <el-input v-if="isEdit" style="width:300px" :disabled="true" v-model="form.hotel_name" placeholder="请输入酒店名称"></el-input>
          <!-- <el-input style="width:300px" v-model="form.hotel_contract" placeholder="请输入酒店合同号"></el-input> -->
        </el-form-item>
        <el-form-item label="发布方式" prop='releaseType'>
          <!--  -->
          <el-radio-group v-model="form.releaseType" @change="changeReleaseTypeType">
            <el-radio-button label="1">全部房间</el-radio-button>
            <el-radio-button label="2">后台</el-radio-button>
            <el-radio-button label="3">分组</el-radio-button>
            <el-radio-button label="4">房间</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择房间" prop="roomStr">
          <checkroom v-if="form.releaseType==4" ref="checkroom" :hotel_contract="form.hotel_contract"></checkroom>
          <el-input v-if="form.releaseType==1" style="width:300px" v-model="allRoomText" :readonly='true'></el-input>
          <checkhotelGroup v-if="form.releaseType==2" ref="checkRoomSys" type='sys' :hotel_contract="form.hotel_contract"></checkhotelGroup>
          <checkRoomGroup v-if="form.releaseType==3" ref="checkRoomGroup" type='group' :hotel_contract="form.hotel_contract"></checkRoomGroup>
        </el-form-item>
        <el-form-item>
          <div class="rightBtn">
            <el-button class="trueBtn" type="primary" @click="save('form')">发 布</el-button>
            <el-button class="cancelBtn" @click="back">返 回</el-button>
          </div>

        </el-form-item>
      </el-card>

    </el-form>
    <uploadSourceMore ref="uploadSourceMore" :sourceType="form.sourceType" @upsuccessmore="upSuccessMore"></uploadSourceMore>
    <el-dialog :visible.sync="sourceShowVisible" :append-to-body="true">
      <img width="100%" v-if="form.sourceType=='pic'" :src="dialogUrl" alt="" />
      <video width="100%" v-if="form.sourceType=='video'" :src="dialogUrl" alt="" controls autoplay muted />
    </el-dialog>
  </div>

</template>
<script>
import checkroom from "../hotelGroup/components/checkroom.vue";
import checkRoomGroup from "./components/checkRoomGroup.vue";
import checkhotelGroup from "./components/checkhotelGroup.vue";

import uploadSourceMore from "../../components/common/operteTab/visualSetVersion/common/uploadSourceMore.vue";
import { verificationResult, auditing } from "@/api/visual.js";
import md5 from "../../components/common/operteTab/visualSetVersion/commonents/md5";
import qs from "qs";
// import { saveRoomGroup, editData } from "./hotelGroupApi";
import { getRoomByHotelContract } from "../hotelGroup/hotelGroupApi";
import { saveSendMessage } from "./hotelMsgReleaseApi";
export default {
  name: "hotelMsgReleaseInfo",
  components: {
    checkroom,
    uploadSourceMore,
    checkRoomGroup,
    checkhotelGroup,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },

      bread: {
        level: 4,
      },
      sourceShowVisible: false,
      dialogUrl: "",
      isEdit: false,
      form: {
        name: "",
        hotel_contract: "",
        hotel_name: "",
        release_time: "",
        type: "1",
        sourceType: "pic",
        fileList: [],
        scroll_text: "",
        releaseType: "1",
        color: "#000000",
        size: 16,
        roomStr: "",
        every_day: [["00:00:00", "23:59:59"]],
      },
      allRoomText: "暂无选择",
      rules: {
        name: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
        release_time: [
          { required: true, message: "请选择发布周期", trigger: "blur" },
        ],
        hotel_contract: [
          { required: true, message: "请选择酒店", trigger: "blur" },
        ],
        scroll_text: [
          { required: true, message: "请输入文字内容", trigger: "blur" },
        ],
        roomStr: [{ required: true, message: "请选择发布对象", trigger: "on" }],
        fileList: [{ required: true, message: "请上传文件", trigger: "blur" }],
      },

      sizeList: [12, 14, 16, 18, 20],
      hotelList: [],
      loading: false,
      disabledHotel: false,
      token: "",
      query: {},
      allRoomList: [],
    };
  },

  methods: {
    showSource(url) {
      this.sourceShowVisible = true;
      this.dialogUrl = url;
    },
    // 获取房间列表
    getRoomList() {
      var params = {
        hotel_contract: this.form.hotel_contract,
      };
      getRoomByHotelContract(params).then((res) => {
        if (res.data.code === 100000 && res.data.data) {
          var tableData = res.data.data;
          this.allRoomList = tableData;
          this.allRoomText = `已选择酒店下所有房间共${this.allRoomList.length}间`;
        } else {
          this.$message.warning(res.data.msg);
          this.roomLoading = false;
        }
      });
    },
    changeReleaseTypeType() {
      if (this.form.releaseType == 2) {
        this.$refs.checkRoomSys.getRoomList();
      } else if (this.form.releaseType == "3") {
        this.$refs.checkRoomGroup.getRoomList();
      }
    },
    async textChange() {
      let res = await this.verificationString("", this.form.scroll_text, this);
      this.showData.intro = res;
    },
    //  获取字符串MD5并验证
    verificationString(oldStr, newStr, v) {
      return new Promise((response, ret) => {
        var newMd5 = md5(newStr);
        verificationResult(qs.stringify({ md5: newMd5 })).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data == 3) {
              return response(newStr);
            } else {
              var auth = JSON.parse(sessionStorage.getItem("auth"));
              var channelName = sessionStorage.getItem("curCompanyName");
              let json = {
                taskName: "可视化文字审核",
                resourcesType: 1,
                resourcesForm: 1,
                product: 5,
                userName: auth.user_name,
                channelName: channelName,
                userPhone: "-",
                txtContent: newStr,
              };
              auditing(qs.stringify(json)).then((res) => {
                if (res.data.code == 200) {
                  if (res.data.data[0].auditingResult == 3) {
                    v.$message.success("审核成功");
                    return response(newStr);
                  } else {
                    v.$message.warning("审核未通过");
                    return response(oldStr);
                  }
                } else {
                  v.$message.warning("审核失败");
                  return response(oldStr);
                }
              });
            }
          } else {
            v.$message.warning("获取审核信息失败");
            return response(oldStr);
          }
        });
      });
    },
    delSource(index) {
      this.form.fileList.splice(index, 1);
    },
    changeSourceType() {
      this.form.fileList = [];
    },
    upSuccessMore(data) {
      console.log(data);
      let obj = {
        source_url: data.url,
        source_md5: data.md5,
      };
      this.form.fileList.push(obj);
    },
    showUploadMore() {
      this.$refs.uploadSourceMore.dialogVisible = true;
    },
    addEveryTime() {
      this.form.every_day.push(["00:00:00", "23:59:59"]);
    },
    delEveryTime(index) {
      this.form.every_day.splice(index, 1);
    },

    selectChange() {
      this.hotelList.forEach((i) => {
        if (i.hotel_contract == this.form.hotel_contract) {
          this.form.hotel_name = i.hotel;
        }
      });
      this.getRoomList();
      console.log(this.form);
    },
    init() {
      console.log(this.query.item);

      this.form.release_time = [
        this.query.item.start_date,
        this.query.item.end_date,
      ];
      this.form.type = this.query.item.publish_type;
      this.form.sourceType =
        this.query.item.resource_type == "1" ? "pic" : "video";

      this.form.every_day = JSON.parse(this.query.item.day_time);

      if (this.form.type == 2) {
        let data = JSON.parse(this.query.item.message_data);
        this.form.scroll_text = data.text_data;
        this.form.color = data.text_color;
        this.form.size = data.text_size;
      } else if (this.form.type == 1) {
        this.form.fileList = this.query.item.send_source;
      }

      this.form.name = this.query.item.name;
      this.form.hotel_contract = this.query.item.hotel_contract;
      this.form.hotel_name = this.query.item.hotel_name;
    },
    back() {
      let currentUrl = this.$route.path;
      if (currentUrl == "/hotelMsgReleaseInfo") {
        this.$router.push("/hotelMsgReleaseList");
      } else {
        this.$router.push("/onePage/hotelMsgReleaseList");
      }
    },
    save(form) {
      let list = [];
      if (this.form.releaseType == 4) {
        list = this.$refs.checkroom.showRoomList.rooms;
      } else if (this.form.releaseType == 2) {
        if (this.$refs.checkRoomSys.showRoomList.rooms.length > 0) {
          this.$refs.checkRoomSys.showRoomList.rooms.forEach((i) => {
            list = [...list, ...i.room_str];
          });
        }
      } else if (this.form.releaseType == 3) {
        if (this.$refs.checkRoomGroup.showRoomList.rooms.length > 0) {
          this.$refs.checkRoomGroup.showRoomList.rooms.forEach((i) => {
            let arr = i.room_str.split(",");
            list = [...list, ...arr];
          });
        }
      } else if (this.form.releaseType == 1) {
        list = this.allRoomList;
      }
      if (list.length > 0) {
        this.form.roomStr = list.join(",");
      }
      // let list = this.$refs.checkroom.showRoomList.rooms;
      // if (list.length == 0) {
      //   this.$message.warning("请选择分组房间");
      //   return;
      // }
      // let timeList = this.form.every_day.map(i=>{
      //    let obj ={
      //       start:i[0],
      //       end:i[1]
      //    }
      //    return obj
      // })
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认发布当前内容？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let params = {
              name: this.form.name,
              hotel_contract: this.form.hotel_contract,
              hotel_name: this.form.hotel_name,
              start_date: this.form.release_time[0],
              end_date: this.form.release_time[1],
              day_time: JSON.stringify(this.form.every_day),
              publish_type: this.form.type,
              resource_type: this.form.sourceType == "pic" ? "1" : "2",
              text_data: this.form.scroll_text,
              text_size: this.form.size,
              text_color: this.form.color,
              source_data: JSON.stringify(this.form.fileList),
              room_data: JSON.stringify(list),
            };
            console.log(params);
            // params.room_str = list.join(",");
            saveSendMessage(params)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("发布成功");
                  this.back();
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch((err) => {});
          });
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    getHotelList() {
      this.disabledHotel = true;
      var sortStr = "id";
      var orderStr = "DESC";
      var params = {
        token: this.token,
        page: 1,
        per_page: 20,
        keyword: "",
        sort: sortStr,
        order: orderStr,
        hotelContract: "",
        screenType: "self,other,videoRoom",
        refresh: false,
      };
      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          console.log(res);
          if (res.data.data.items.length > 0) {
            this.hotelList = res.data.data.items;
            this.form.hotel_contract = this.hotelList[0].hotel_contract;
            this.form.hotel_name = this.hotelList[0].hotel;
            this.getRoomList();
          } else {
            this.$message.warning("无酒店信息，无法进行分组操作");
          }
          this.disabledHotel = false;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
          this.disabledHotel = false;
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        var sortStr = "id";
        var orderStr = "DESC";
        var params = {
          token: this.token,
          page: 1,
          per_page: 20,
          keyword: query,
          sort: sortStr,
          order: orderStr,
          hotelContract: "",
          screenType: "self,other,videoRoom",
          refresh: false,
        };
        this.$http
          .get(this.global.search, {
            params,
          })
          .then((res) => {
            console.log(res);
            this.hotelList = res.data.data.items;
            this.loading = false;
          })
          .catch((res) => {
            this.loading = false;
          });
      } else {
        this.hotelList = [];
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");

    let query = this.$route.query;
    console.log(query);
    if (query.item) {
      this.isEdit = true;
      this.query = query;
      this.init();
    } else {
      this.getHotelList();
    }
    // this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
  padding-bottom: 0;
}
::v-deep .el-textarea__inner {
  height: 150px !important;
}

.rightBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.circleBox {
  padding-top: 14px;
}
.fileBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .sourceBox {
    height: 158px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border: 1px solid #ddd;
    position: relative;

    .el-icon-delete {
      display: block;
      position: absolute;
      top: -12px;
      right: -12px;
      // transform: translate(-50%, -50%);
      font-size: 16px;
      padding: 2px;
      border-radius: 9px;
      color: #fff;
      background: red;
      cursor: pointer;
    }
    .el-icon-search {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: #fff;
      background: rgba($color: #000000, $alpha: 0.4);
      cursor: pointer;
    }
    &:hover .el-icon-search {
      display: block;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
::v-deep .el-button.is-circle {
  padding: 5px !important;
}
::v-deep .el-form-item__label {
  text-align: right;
}
</style>
