<template >

  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">数据中心</span>
        <span slot="third">数字资产</span>
        <span slot="fourth">资产收益统计</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" ref="topSearch"></top-search>
    </el-card>
    <!--除了使用中，酒店模块用的都是这个表格-->
    <el-card class="box-card ">
      <div class="title blueTitle">酒店列表</div>
      <el-table ref="table" :header-cell-style="headerStyle" :data="tableData" v-loading="table.loading" style="width: 100%" class="sixtable">
        <el-table-column prop="hotel" label="酒店名称" min-width="300" show-overflow-tooltip sortable="custom" fixed="left">
        </el-table-column>
        <el-table-column label="操作" min-width="150" fixed="left">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="hotel_brand" label="集团品牌" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="hotel_province" label="省份" min-width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="hotel_city" label="城市" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="hotel_county" label="区县" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="hotel_use_state_text" label="酒店状态" min-width="150"></el-table-column>
        <el-table-column prop="hotel_room" label="客房总量（间）" min-width="200">
          <template slot-scope="scope">
            <span>{{ zeroCom(scope.row.hotel_room) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="n_rooms_connected" label="激活终端数量（台）" min-width="230">
          <template slot-scope="scope">
            <span>{{ zeroCom(scope.row.n_rooms_connected) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="addtime" label="建站时间" min-width="200">
        </el-table-column>
      </el-table>
      <el-pagination class="changePage" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "DataIncomeStatistic",
  data() {
    return {
      bread: {
        level: 4,
      },
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      topSearchShow: {
        hotel_brand: true,
        province: true,
        city: true,
        country: true,
        keyword: true,
        hotelContract: true,
        hotel_use_state: true,
        coopType: true,
        coopPartner: true,
        coopPartnerType: true,
        keywordPlaceholder: "请输入酒店名称关键词",
        hotelContractPlaceholder: "请输入合同号",
      },
      condition: {
        hotel_brand: "",
        citycode: "",
        provinceLabel: "",
        cityLabel: "",
        countyLabel: "",
        keyword: "",
        hotelContract: "",
        hotel_use_state: "",
        hotel_use_state_label: "",
        belongPartnerCode: "",
        allotPartnerCode: "",
      },
      tableData: [],
      table: {
        limit: 5,
        page: 1,
        total: 0,
        loading: false,
      },
      token: "",
    };
  },
  computed: {
    zeroCom() {
      return function (val) {
        var num;
        val ? (num = val) : (num = 0);
        return num;
      };
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getTableList();
  },
  methods: {
    detail(row) {
      this.$router.push({
        path: "/DataStatisticsDetail",
        query: row,
      });
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    // 搜索
    getCondition(val) {
      this.condition.hotel_use_state_label = Array.isArray(
        val.hotel_use_state_label
      )
        ? val.hotel_use_state_label.join(",")
        : ""; //酒店状态字段
      if (Array.isArray(val.hotel_use_state)) {
        this.condition.hotel_use_state = val.hotel_use_state.join(",");
      } else if (hotel_use_state === "10") {
        this.condition.hotel_use_state = val.hotel_use_state;
      } else {
        this.condition.hotel_use_state = "";
      }//酒店状态
      this.condition.hotel_brand = Array.isArray(val.hotel_brand)
        ? val.hotel_brand.join(",")
        : ""; //品牌
      if (typeof val.citycode === "string") {
        this.condition.citycode = val.citycode;
      } else {
        this.condition.citycode = Array.isArray(val.citycode)
          ? val.citycode.join(",")
          : ""; //citycode
      }
      this.condition.provinceLabel = Array.isArray(val.provinceLabel)
        ? val.provinceLabel.join(",")
        : ""; //省
      this.condition.cityLabel = val.cityLabel;//市
      this.condition.countyLabel = val.countyLabel; //区
      this.condition.keyword = val.keyword;  //关键字
      this.condition.hotelContract = val.hotelContract; //合同号
      this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode)
        ? val.belongPartnerCode.join(",")
        : ""; //归属
      this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode)
        ? val.allotPartnerCode.join(",")
        : ""; //所属
      console.log(this.condition, "condition");
      this.getTableList();
    },
    // 分页功能
    handleSizeChange(limit) {
      this.table.limit = limit;
      this.getTableList();
    },
    handleCurrentChange(page) {
      this.table.page = page;
      this.getTableList();
    },
    // 获取表格信息 
    getTableList() {
      this.table.loading = true;
    //   参数配置
      var params = {
        token: this.token,
        page: this.table.page,
        per_page: this.table.limit,
        hotel_brand: this.condition.hotel_brand,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        keyword: this.condition.keyword,
        hotelContract: this.condition.hotelContract,
        hotel_use_state: this.condition.hotel_use_state,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        sort: "id",
        order: "DESC",
        screenType: "self,videoRoom",
        refresh: false,
      };
    //   获取酒店列表
      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.tableData = tableData.items;
            this.table.total = parseInt(tableData.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch((res) => {
             this.$message.warning("数据加载失败");
          this.table.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-table__fixed,
::v-deep .el-table__fixed-right {
  height: auto !important;
  bottom: 10px !important;
  top: 0px;
}
.sixtable {
  margin-top: 20px;
}
.sixtable ::v-deep .el-table__fixed-body-wrapper {
  top: 60px !important;
  box-sizing: border-box;
  height: calc(100% - 65px) !important;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 5px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写*/
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*background-color: rgba(0,0,0,0.19);*/
  /*border-radius: 3px;*/
}
::v-deep .el-table th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  box-sizing: border-box;
  border-bottom: none !important;
}
::v-deep .el-table__footer-wrapper,
::v-deep .el-table__header-wrapper {
  border-radius: 4px !important;

  border: 1px solid #ebeef5 !important;
  border-top: none !important;
}
.el-pagination {
  margin-top: 20px;
}
</style>