import request from '@/api/request';
import qs from "querystring";

// 修改cibn认证配置
export function editCibn(param) {
    return request({
        url: `v2/cibnconfig/globalset`,
        method: 'post',
        data: qs.stringify(param)
    });
}
// 获取cibn认证信息
export function getCibn(param) {
    return request({
        url: `v2/cibnconfig/queryglobal`,
        method: 'get',
    });
}