<template>
    <div>
        <el-row class="btnRow">
            <el-button type="primary" @click="backMeth" size="medium" class="largeBtn ">返回</el-button>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="15">
                <div class="grid-content bg-purple">
                    <el-form  label-width="200px" :model="form" :rules="rules" ref="form">
                        <el-form-item label="广告场景" prop="scene_id_trans">
                            <el-select v-model="form.scene_id_trans"  placeholder="广告场景"  clearable class=" fullSelect" @change="senceChange">
                                <el-option v-if="show"
                                        v-for="item in selectData.scene_id"
                                        :key="item.id"
                                        :label="item.ad_scen"
                                        :value="{value:item.id,type:item.type,resolution:item.resolution,file_size:item.file_size,x_axis:item.x_axis,y_axis:item.y_axis,media_size:item.media_size,ad_type:item.ad_type}">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="" prop="scene_id_input" v-if="scene_id_inputShow">
                            <el-input v-model="form.scene_id_input"  placeholder="广告场景"></el-input>
                        </el-form-item>
                        <el-form-item label="广告类型" prop="ad_type">
                            <el-select v-model="form.ad_type"  placeholder="广告类型"  clearable class=" fullSelect" :disabled="form.scene_id!=='other'?true:false" >
                                <el-option
                                           v-for="item in selectData.ad_type"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="广告位名称" prop="name">
                            <el-input v-model="form.name"  placeholder="广告位名称"></el-input>
                        </el-form-item>
                        <!--<el-form-item label="广告位标识" prop="flag" v-if="isHtrip?true:false">-->
                            <!--<el-input v-model="form.flag"  placeholder="广告位标识" :readonly="isHtrip?false:true"></el-input>-->
                        <!--</el-form-item>-->

                        <el-form-item label="素材格式" prop="media_type" >
                            <el-select v-model="form.media_type"  placeholder="素材格式"  clearable class=" fullSelect" @change="media_typeChange"  :disabled="form.scene_id!=='other'?true:false">
                                <el-option
                                        v-for="item in selectData.media_type"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <!--<el-input v-model="form.media_type"  placeholder="素材格式" :readonly="isHtrip"></el-input>-->
                        </el-form-item>

                        <el-form-item label="素材尺寸(素材分辨率)" prop="resolution" >
                            <el-input v-model="form.resolution" placeholder="例：1920*1080" :readonly="form.scene_id!=='other'?true:false" ></el-input>
                        </el-form-item>
                        <el-form-item label="播放时长" prop="time">
                            <el-input v-model.number="form.time"  placeholder="播放时长">
                                <template slot="append">秒</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="素材大小限制" prop="file_size">
                            <el-input v-model="form.file_size" type="number" :readonly="form.scene_id!==''?form.scene_id!=='other':true"   placeholder="素材大小限制">
                                <template slot="append">M以内</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="支持广告位数量" prop="ad_num">
                            <el-input v-model="form.ad_num" type="number"  placeholder="支持广告位数量">
                                <template slot="append">个</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="价格" prop="price">
                            <el-input v-model="form.price" type="number"  placeholder="价格" >
                                <template slot="append">周/房/元</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="是否支持视频" prop="is_sup_video">
                            <el-radio-group  v-model="form.is_sup_video" class="radioBox">
                                <el-radio label="0" @change="radioChange">否</el-radio>
                                <el-radio label="1" @change="radioChange">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否有封面" prop="is_cover">
                            <el-radio-group  v-model="form.is_cover" class="radioBox">
                                <el-radio label="0" @change="radioChange">否</el-radio>
                                <el-radio label="1" @change="radioChange">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="封面尺寸" prop="cover_size" v-if="form.is_cover==1" >
                            <!--<el-select v-model="form.cover_size"  placeholder="素材名称"  clearable  class="fullSelect" :readonly="true">-->
                            <!--<el-option-->
                            <!--v-for="item in selectData.materialName"-->
                            <!--:key="item.id"-->
                            <!--:label="item.name"-->
                            <!--:value="item.id">-->
                            <!--</el-option>-->
                            <!--</el-select>-->
                            <el-input v-model="form.cover_size"  placeholder="封面尺寸"></el-input>
                        </el-form-item>
                        <el-row class="noMarginBottom">
                            <el-form-item label="广告位展示坐标" prop="x_axis">
                                <el-input v-model="form.x_axis"  placeholder="横轴" :readonly="form.scene_id!==''?form.scene_id!=='other':true" ></el-input>
                            </el-form-item>

                            <el-form-item prop="y_axis">
                                <el-input v-model="form.y_axis"  placeholder="纵轴" :readonly="form.scene_id!==''?form.scene_id!=='other':true" ></el-input>
                            </el-form-item>
                        </el-row>

                        <el-form-item label="广告位展示尺寸" prop="media_size">
                            <el-input v-model="form.media_size"  placeholder="广告位展示尺寸" :readonly="form.scene_id!==''?form.scene_id!=='other':true" ></el-input>
                        </el-form-item>

                        <!--<el-form-item label="样式示意" prop="style_diagram">-->
                        <el-form-item label="样式示意">
                            <el-col :span="24" class="one">
                                <el-upload
                                        :action="global.upload"
                                        :class="{disabled:uploadDisabled}"
                                        :file-list="defaultList"
                                        list-type="picture-card"
                                        :on-preview="uploadPreview"
                                        :on-remove="uploadRemove"
                                        :on-success="uploadSuccess"
                                        :before-upload="uploadBefore"
                                        @change="uploadChange"
                                        :headers="headers"
                                        accept=".jpg,.jpeg,JPG,.JPEG"
                                        :limit="1">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialog.dialogVisible">
                                    <img width="100%" :src="form.img_temp" alt="">
                                </el-dialog>
                                <!--<el-upload-->
                                        <!--class="avatar-uploader"-->
                                        <!--action="https://jsonplaceholder.typicode.com/posts/"-->
                                        <!--:show-file-list="false"-->
                                        <!--:on-success="handleAvatarSuccess"-->
                                        <!--:before-upload="beforeAvatarUpload">-->
                                    <!--<img v-if="imageUrl" :src="imageUrl" class="avatar">-->
                                    <!--<i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                                <!--</el-upload>-->
                            </el-col>
                        </el-form-item>
                        <el-form-item size="large" >
                            <el-button type="primary" @click="submitForm('form')">保存</el-button>
                            <!--<el-button>取消</el-button>-->
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
            <el-col :span="9">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "Custom",
        // props:['id','fromType'],
        props:['id'],
        data(){
            var checkNumber = (rule, value, callback) => {
                var val=parseInt(value,10);
                if (value==='') {
                    return callback(new Error('请填写播放时长'))
                }else if(val===0){
                    return callback(new Error('播放时长不能小于1'))
                } else if(!val){
                    return callback(new Error('播放时长只能为数字'))
                } else if (val<1) {
                    return callback(new Error('播放时长不能小于1'))
                }else{
                    callback()
                }
            };
            return {
                show:false,
                imageUrl:'',
                defaultList:[],
                scene_id_inputShow:false,
                form:{
                    scene_id:'',
                    scene_id_trans:'',
                    scene_id_input:'',
                    ad_type:'',
                    name:'',

                    defaultName:'',
                    materialName:'',
                    media_type:'',
                    resolution:'',
                    time:null,
                    file_size:'',
                    ad_num:'',
                    price:'',
                    is_sup_video:'0',
                    is_cover:'0',
                    cover_size:'',
                    y_axis:'',      //纵轴
                    x_axis:'',  //横轴
                    media_size:'',  //横轴
                    style_diagram:'',
                },
                dialog:{
                    dialogVisible:false,
                },
                rules:{
                    scene_id_trans: [
                        { required: true, message: '请选择广告场景', trigger: 'blur' }
                    ],
                    scene_id_input: [
                        { required: true, message: '请输入广告场景', trigger: 'blur' }
                    ],
                    ad_type: [
                        { required: true, message: '请选择广告类型', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入广告位名称', trigger: 'blur' }
                    ],
                    materialName: [
                        { required: true, message: '请选择素材名称', trigger: 'blur' }
                    ],
                    media_type: [
                        { required: true, message: '请选择素材格式', trigger: 'blur' }
                    ],
                    resolution: [
                        { required: true, message: '请选择素材尺寸', trigger: 'blur' }
                    ],
                    time: [
                        // { required: true, message: '请输入播放时长1', trigger: 'blur' },
                        // {type:'number',message:'10',trigger:'blur'},
                        // { min: 1,  message: '播放时长必须大于0', trigger: 'blur' },
                        {required:true,validator:checkNumber,trigger:'blur'}
                    ],
                    file_size: [
                        { required: true, message: '请输入素材大小限制', trigger: 'blur' }
                    ],
                    ad_num: [
                        { required: true, message: '请输入支持广告位数量', trigger: 'blur' }
                    ],
                    price: [
                        { required: true, message: '请输入价格', trigger: 'blur' }
                    ],
                    is_sup_video: [
                        { required: true, message: '请选择是否支持视频', trigger: 'blur' }
                    ],
                    is_cover: [
                        { required: true, message: '请选择封面尺寸', trigger: 'blur' }
                    ],

                    cover_size: [
                        { required: true, message: '请选择素材名称', trigger: 'blur' }
                    ],
                    style_diagram: [
                        { required: true, message: '请上传样式示例', trigger: 'blur' }
                    ],
                    x_axis: [
                        { required: true, message: '请输入横轴', trigger: 'blur' }
                    ],
                    y_axis: [
                        { required: true, message: '请输入纵轴', trigger: 'blur' }
                    ],
                    media_size: [
                        { required: true, message: '请输入广告位展示尺寸', trigger: 'blur' }
                    ],
                },
                selectData:{
                    media_format:[],
                    resolution:[
                        {id:1,name:'1920*1080'},
                        {id:2,name:'1600*900'},
                        {id:3,name:'1366*768'},
                        {id:4,name:'1280*720'}
                    ],
                    scene_id:[],
                    media_type:[
                        {id:1,name:'图片'},
                        {id:2,name:'视频'},
                    ],
                    ad_type:[]
                },
                headers:{
                    token:'',
                    Authorization:''
                },
                // upload:{
                //     uploadDisabled:false,
                //     list:[]
                // }
                uploadDisabled:false,
                list:[],
                fromType:'',
                token:'',
                radioShow:false,
                auth:'',
                isHtrip:'',
                query:{
                    id:'',
                    fromType:'',
                    type:''
                }

            }
        },
        watch:{
            id(val){
                this.getDetail();
            }
        },
        methods: {
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            judgeType(){
                if(this.query.id){
                    this.submitMeth('edit');
                }else{
                    this.submitMeth('new');
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024  < 500;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过500K!');
                }
                return isJPG && isLt2M;
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定提交?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            if(this.form.scene_id_trans.value){
                                this.form.scene_id=this.form.scene_id_trans.value;
                            }
                            this.isHtrip?this.form.type='hl':this.form.type='self';
                            if(!this.query.id){   //新建，需要查重
                                this.checkAdNameMeth().then(res=>{  //广告位名称没有重复
                                    if(res.data.code===100000){
                                        if(res.data.data.available){
                                            if(this.form.scene_id==='other'){   //先新建场景，再建广告位
                                                this.createSceneMeth().then(res=>{
                                                    this.judgeType();
                                                })
                                            }else{
                                                this.judgeType();
                                            }
                                        }else{
                                            this.$message.error('广告位名称不能重复');
                                        }
                                    }else{
                                        this.$message.error(res.data.data.name);
                                    }

                                })
                            }else{      //编辑，广告位名称没有修改-不需要查重
                                if(this.form.name===this.form.defaultName){
                                    this.isNewBuildScene();
                                }else{  //编辑，广告位名称没有修改-需要查重
                                    this.checkAdNameMeth().then(res=>{  //广告位名称没有重复
                                        if(res.data.data.available){
                                            this.isNewBuildScene();
                                        }else{
                                            this.$message.error('广告位名称不能重复');
                                        }
                                    })
                                }
                            }
                        })
                    } else {
                        this.$message.warning('请完善信息');
                        return false;
                    }
                });
            },
            submitMeth(type){
                var url='';
                this.form.token=this.token;
                this.isHtrip?this.form.type='hl':this.form.type='self';
                // this.form.meida_size='3';
                var formData={
                    type:this.form.type,
                    scene_id:this.form.scene_id,
                    name:this.form.name,
                    time:this.form.time,
                    ad_num:this.form.ad_num,
                    price:this.form.price,
                    is_sup_video:this.form.is_sup_video,
                    is_cover:this.form.is_cover,
                    cover_size:this.form.cover_size,
                    media_type:this.form.media_type,
                    style_diagram:this.form.style_diagram,
                };
                if(type==='edit'){
                    url=this.global.updateCases;
                    formData.id=this.id;
                }else{
                    url=this.global.createCases;
                }
                var param=qs.stringify(formData);
                this.$http.post(url,param,{
                    headers:{
                        token:this.token
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('保存成功');
                        this.$router.push({path:'/adManSpace'})
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{
                    this.$message.error('返回错误');
                })
            },
            createSceneMeth(){
                return new Promise((resolve,reject)=>{
                    var identity=this.isHtrip?1:2;
                    var param=qs.stringify({
                        name:this.form.scene_id_input,
                        ad_type:this.form.ad_type,
                        identity:identity,
                        type:this.form.media_type,   //场景所属
                        // media_format:this.form.media_type,   //素材格式
                        resolution:this.form.resolution,
                        file_size:this.form.file_size,  //素材大小限制
                        x_axis:this.form.x_axis,  //横轴
                        y_axis:this.form.y_axis,  //纵轴
                        media_size:this.form.media_size,  //广告位占用尺寸
                    });
                    this.$http.post(this.global.createScene,param,{
                        headers:{
                            token:this.token
                        }
                    }).then(res=>{
                        if(res.data.code===100000){
                            this.$message.success('场景保存成功');
                            this.form.scene_id=res.data.data.id;
                        }else if(res.data.code===10002){
                            this.$message.error(res.msg);
                        }

                        else{
                            this.$message.error('场景'+res.msg);
                        }
                        return resolve(res);
                    }).catch((res)=>{
                        this.$message.error(res.msg);
                    })
                })
            },
            checkAdNameMeth(){
                return new Promise((resolve,reject)=>{
                    this.$http.get(this.global.checkAdName,{
                        params:{
                            token:this.token,
                            name:this.form.name
                        }
                    }).then(res=>{
                        resolve(res);
                    }).catch(res=>{
                        this.$message.error(res.data.msg);
                    })
                })
            },
            uploadRemove(file, fileList) {
                this.defaultList.length=0;
                this.form.style_diagram='';
                setTimeout(()=>{
                    this.uploadDisabled=false;
                },800)
            },
            uploadPreview(file) {
                this.form.img_temp = file.url;
                this.dialog.dialogVisible = true;
            },
            uploadSuccess(file) {
                this.form.style_diagram = file.data.url;
                this.uploadDisabled=true;
            },
            uploadBefore(file){
                // this.uploadDisabled=true;
                // console.log(file);
                // var testmsg=file.name.substring(file.name.lastIndexOf('.')+1);
                // const extension = testmsg === 'jpg';
                // const extension2 = testmsg === 'jpeg';
                // const extension3 = testmsg === 'png';
                // const extension4 = testmsg === 'JPG';
                // const extension5 = testmsg === 'JPEG';
                // // const isLt2M = file.size / 1024 / 1024 < 10
                // if(!extension && !extension2 && !extension3 && !extension4 && !extension5) {
                //     this.$message({
                //         message: '上传文件只能是图片!',
                //         type: 'warning'
                //     });
                // }
                // return extension || extension2
                const sizeLimit = file.size / 1024 < 500; // 限制小于1M
                if (!sizeLimit) {
                    this.$message.error('图片大小不可超过500K')
                }
                return sizeLimit
            },
            uploadChange(){
                this.uploadDisabled=true;
            },
            getMediaFormat(){
                this.$http.get(this.global.getMediaFormat,{
                    params:{
                        token:this.token
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        // this.selectData.materialNameTest=res.data.data;
                        // this.radioShow=true;
                        // this.materialmedia_format_show=true;
                    }
                });
            },
            getDetail(){
                this.$http.get(this.global.getsCasesInfo,{
                    params:{
                        token:this.token,
                        id:this.query.id,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var formData=res.data.data;
                        if(formData.scen){
                            this.form.scene_id=formData.scen.id;
                            this.form.ad_type=formData.scen.ad_type;
                            this.form.scene_id_trans=formData.scen.ad_scen;
                            this.form.x_axis=formData.scen.x_axis;   //广告位展示坐标-横轴
                            this.form.y_axis=formData.scen.y_axis;   //广告位展示坐标-纵轴
                            this.form.media_size=formData.scen.media_size;   //广告位展示尺寸
                            this.form.file_size=formData.scen.file_size;   //素材大小限制
                            this.form.resolution=formData.scen.resolution;   //素材尺寸(分辨率)
                        }
                        this.form.name=formData.name;
                        this.form.defaultName=formData.name;
                        formData.media_type==='图片'
                        ?this.form.media_type=1
                            :this.form.media_type=2;
                        this.form.time=formData.playing_time;   //播放时长
                        this.form.ad_num=formData.number;  //支持广告位数量
                        this.form.price=formData.price;   //价格
                        this.form.is_sup_video=formData.is_sup_video.toString();   //是否支持视频
                        this.form.is_cover=formData.is_cover.toString();   //是否有封面
                        this.form.is_cover==='1'?this.form.cover_size=formData.cover_resolution:this.form.cover_size='';
                        if(formData.style_diagram){
                            this.defaultList=[{name:'',url:this.global.baseUrl+formData.style_diagram}];
                            this.uploadDisabled=true;
                            this.form.style_diagram=formData.style_diagram;
                        }

                    }
                });
            },
            getMaterialType(){
                this.$http.get(this.global.getCaseMaterialType,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        // this.selectData.materialName=res.data.data;
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                }).catch(()=>{

                })
            },
            radioChange(params){

            },
            senceChange(params){
                const {value,type,resolution,file_size,x_axis,y_axis,media_size,ad_type}=params;
                if(value==='other'){
                    this.scene_id_inputShow=true;
                    this.form.scene_id='other';
                    this.form.ad_type='';
                    this.form.media_type='';
                    this.form.resolution='';
                    this.form.file_size='';  //素材大小限制
                    this.form.x_axis='';  //横轴
                    this.form.y_axis='';  //纵轴
                    this.form.media_size='';  //广告位展示尺寸
                }else{
                    this.scene_id_inputShow=false;
                    this.form.scene_id=value;
                    this.form.ad_type=ad_type;
                    this.form.media_type=type;   //素材格式
                    this.form.resolution=resolution;  //分辨率
                    this.form.file_size=file_size;  //素材大小限制
                    this.form.x_axis=x_axis;  //横轴
                    this.form.y_axis=y_axis;  //纵轴
                    this.form.media_size=media_size;  //广告位展示尺寸
                }
            },
            media_typeChange(){

            },
            getCaseScenTypeMeth(){
                this.$http.get(this.global.getCaseScenType,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        tableData.push({id:'other',ad_scen:'自定义'});
                        this.selectData.scene_id=tableData;
                        this.show=true;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            transObj(data){
                var arr=[];
                for(var i in data){
                    arr.push({id:i,name:data[i]});
                }
                return arr;
            },
            getAdType(){
                this.$http.get(this.global.getAdType,{
                    params:{
                        token:this.token
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.ad_type=this.transObj(res.data.data);
                    }
                });
            },
            isNewBuildScene(){
                if(this.form.scene_id==='other'){   //先新建场景，再建广告位
                    this.createSceneMeth().then(res=>{
                        this.judgeType();
                    })
                }else{
                    this.judgeType();
                }
            },
            backMeth(){
                this.$router.push({path:'./adManSpace'})
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            var auth=JSON.parse(sessionStorage.getItem('auth'));
            auth.type==='htrip'?this.isHtrip=true:this.isHtrip=false;
            this.headers.token=this.token;
            this.headers.Authorization=this.token;
            var query=this.$route.query;
            if(query){
                this.fromType=query.fromType;
                this.query.id=query.id;
                this.query.type=query.type;
                this.getDetail();
            }
            this.getMediaFormat();
            this.getMaterialType();
            this.getCaseScenTypeMeth();

            this.getAdType();
        },

    }
</script>

<style scoped lang="scss">
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        /*background: #99a9bf;*/
    }
    .bg-purple {
        /*background: #d3dce6;*/
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .fullSelect{
        width:100%;
    }
    .disabled .el-upload--picture-card {
        display: none!important;
    }
    .radioBox{
        margin-top:12px;
    }
    .rowBox{
        display:flex;
        align-items: center;
    }
    .noMarginBottom{
        margin-bottom:0;
    }
</style>
