<template>
  <div v-loading="loading">
    <div class="outTopBox" >
      <top-bread :bread="bread">
        <span slot="second">广告管理</span>
        <span slot="third">广告位管理</span>
      </top-bread>
      <!-- <fold-top-search @fold="getFoldMeth"></fold-top-search> -->
    </div>
    <!-- <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search
        :topSearchShow="topSearchShow"
        @condition="getCondition"
        :topSearchSave="topSearchSave"
        :allLoadingState="allLoadingState"
      ></top-search>
    </el-card> -->
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">广告位列表</div>
      <el-row class="btnRow">
        <div>
          <el-button class="largeBtn" type="danger" @click="deleteData" size="medium" v-if="showFunModel('删除')">删除</el-button>
        </div>
        <el-button type="primary" @click="newBuild" size="medium" class="rightBtn largeBtn" v-if="showFunModel('新建广告位')">新建</el-button>
      </el-row>
      <table-list :table="table" @tableMeth="tableMeth" @tableSelect="getTableSelect" @showUploadDialog="showUploadDialog" @swicthChangeForAdvertisement="swicthChangeForAdvertisement" v-loading="table.loading"></table-list>
    </el-card>
    <div class="allLoading" v-show="false">{{ allLoadingStateCom }}</div>

    <el-drawer :title="createTitle" :visible.sync="createAdvertisingVisible" size="50%" :modal="false" :wrapperClosable="false" :destroy-on-close="true" :before-close="handleClose">
      <div class="createContent">
        <div class="createContentLeft">
          <el-form :rules="rules" ref="createForm" label-position="top" :model="createForm">
            <el-form-item label="投放设备名称" prop="device_name">
              <el-input v-model="createForm.device_name"></el-input>
            </el-form-item>
            <el-form-item label="关联携旅标准广告刊位" prop="standard_sign">
              <el-select v-model="createForm.adv_id" placeholder="请选择广告刊位" @change="selectorStandardAdvPosList">
                <el-option :key="index" v-for="(item, index) in StandardAdvPosList" :label="item.standard_name" :value="Number(item.standard_id)"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="素材格式">
              <!-- <span class="cehshi2msg">{{createForm.device_name}}</span> -->
              <el-input v-model="createForm.format" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="素材分辨率" prop="resolution">
              <div class="inpiutBox">
                <el-select v-model="createForm.resolution_id" placeholder="请选择分辨率" @change="resolutionidChange">
                  <el-option label="其他" :value="0"></el-option>
                  <el-option :key="index" v-for="(item, index) in StandardPosResolutionList" :label="item.standard_sign" :value="item.standard_id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item prop="ceshi4" v-if="createForm.resolution_id == '0'">
              <el-input class="other" v-model="createForm.resolution"></el-input>
            </el-form-item>
            <el-form-item label="选择投放方式" prop="type">
              <el-select v-model="createForm.type" placeholder="请选择广告刊位">
                <el-option v-for="(item, index) in standardTypeList" :key="index" :label="item.type_name" :value="item.type_id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="刊例价格" prop="periodical_price">
              <el-input placeholder="请输入内容" v-model="createForm.periodical_price">
                <template slot="append">元/房/间</template>
              </el-input>
            </el-form-item>
            <el-form-item label="编写刊位名称" prop="self_periodical_name">
              <el-input v-model="createForm.self_periodical_name"></el-input>
            </el-form-item>
          </el-form>
          <div class="creatTrue">
            <el-button class="largeBtn" type="primary" @click="submitForm()">{{btnForSub}}</el-button>
          </div>
        </div>
        <div class="createContentRight">
          <div class="picShowCard">
            <p>刊位示意</p>
            <img v-if="createForm.photo" :src="createForm.photo" alt="" />
            <p v-if="!createForm.photo">暂无数据</p>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
  
<script>
import qs from "querystring";
import {
  getAdvertisingListByChannelCode,
  delAdvPosition,
  createOrEditAdvertising,
  operateAdvPosition,
  getStandardAdvPosList,
  getstandardTypeList,
  getStandardPosResolutionList,
} from "@/api/advertisement.js";

import commonMeth from "../../api/method";
export default {
  name: "adManCustom",
  data: () => ({
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    loading:false,
    funArr:[],
    createForm: {
      adv_id: "",
      standard_name: "",
      standard_sign: "",
      device_name: "",
      format: "",
      photo: "",
      resolution: "",
      resolution_id: "",
      type: "",
      periodical_price: "",
      self_periodical_name: "",
    },
    createTitle:"新建广告位",
    btnForSub:"确认",
    drawerUseType: "",
    rules: {
      device_name: [
        { required: true, message: "投放设备名称不能为空", trigger: "blur" },
      ],
      standard_sign: [
        { required: true, message: "请关联携旅标准广告刊位", trigger: "blur" },
      ],
      resolutionid: [
        { required: true, message: "其选择素材分辨率", trigger: "blur" },
      ],
      resolution: [
        { required: true, message: "请填写素材分辨率", trigger: "blur" },
      ],
      type: [{ required: true, message: "请选择投放方式", trigger: "blur" }],
      periodical_price: [
        { required: true, message: "请输入刊例价格", trigger: "blur" },
      ],
      self_periodical_name: [
        { required: true, message: "请编写刊位名称", trigger: "blur" },
      ],
    },
    table: {
      tableHeader: [
        { prop: "self_periodical_name", label: "刊位名称", width: "" },
        { prop: "standard_name", label: "携旅标准刊位名称", width: "" },
        { prop: "device_name", label: "投放设备名称", width: "" },
        { prop: "company_name", label: "所属合作伙伴", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "adManSpace",
      name: "adManSpace",
      option: true,
      pageShow: false,
      select: true,
      loading: true,
    },
    createAdvertisingVisible: false,
    topSearchShow: {},
    condition: {},
    operateIds: "",
    token: "",
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    topSearchSave: {},
    allLoadingState: false,
    StandardAdvPosList: [],
    standardTypeList: [],
    StandardPosResolutionList: [],
  }),
  methods: {
    // 下拉选择
    selectorStandardAdvPosList(val) {
      this.StandardAdvPosList.forEach((item) => {
        if (item.standard_id == val) {
          this.createForm.format = item.standard_format;
          this.createForm.standard_name = item.standard_name;
          this.createForm.standard_sign = item.standard_sign;
          this.createForm.photo =  item.standard_photo;
        }
      });
    },
    // 获取下拉列表信息
    getSelectorList() {
      getStandardPosResolutionList()
        .then((res) => {
          if (res.data.code == 100000) {
            this.StandardPosResolutionList = res.data.data;
          } else {
            this.$message.warning("选项获取失败");
          }
        })
        .catch((err) => {
          this.$message.warning("选项获取失败");
        });
      getStandardAdvPosList()
        .then((res) => {
          if (res.data.code == 100000) {
            this.StandardAdvPosList = res.data.data;
          } else {
            this.$message.warning("选项获取失败");
          }
        })
        .catch((err) => {
          this.$message.warning("选项获取失败");
        });
      getstandardTypeList()
        .then((res) => {
          if (res.data.code == 100000) {
            this.standardTypeList = res.data.data;
          } else {
            this.$message.warning("选项获取失败");
          }
        })
        .catch((err) => {
          this.$message.warning("选项获取失败");
        });
    },
    // 新建and编辑
    submitForm() {
      this.$refs.createForm.validate((valid) => {
        if (valid) {
          if (this.createForm.id) {
            this.createForm.adv_position_id = this.createForm.id;
          }
          var param = qs.stringify(this.createForm);
          createOrEditAdvertising(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("操作成功");
                this.createAdvertisingVisible = false;
                this.$refs.createForm.resetFields();
                this.initializationCreateForm();
                this.tableMeth();
              } else {
                this.$message.warning("操作失败");
              }
            })
            .catch((err) => {
              this.$message.warning("操作失败");
            });
        } else {
          return false;
        }
      });
    },
    // 开关
    swicthChangeForAdvertisement(val) {
      this.loading=true;
      const json = {
        id: val.id,
        channel_code:val.channel_code,
        label_state: val.label_state ? 1 : 0,
      };
      var param = qs.stringify(json);
      operateAdvPosition(param)
        .then((res) => {
          if (res.data.code == 100000) {
            if (json.label_state == 1) {
              this.$message.success("刊位已打开");
            } else {
              this.$message.success("刊位已关闭");
            }
          } else {
            this.$message.warning("操作失败");
          }
            this.loading=false;
        })
        .catch((err) => {
          this.$message.warning("操作失败");
           this.loading=false;
        });
    },
    // 初始化form
    initializationCreateForm() {
      this.createForm = {
        adv_id: "",
        standard_name: "",
        device_name: "",
        standard_sign: "",
        resolution: "",
        resolution_id: "",
        type: "",
        format: "",
        periodical_price: "",
        self_periodical_name: "",
        photo: "",
      };
    },
    // 下拉选择
    resolutionidChange(val) {
      if (val == "0") {
        this.createForm.resolution = "";
      } else {
        this.StandardPosResolutionList.forEach((item) => {
          if (item.standard_id == val) {
            this.createForm.resolution = item.standard_sign;
          }
        });
      }
    },
    // 删除
    deleteData() {
      this.createAdvertisingVisible = false;
      if (this.operateIds !== "") {
        this.$confirm("确认删除选中刊位吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const json = {
              ids: this.operateIds,
            };
            var param = qs.stringify(json);
            delAdvPosition(param)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("删除成功");
                  this.tableMeth();
                } else {
                  this.$message.warning("删除失败");
                }
              })
              .cathc((err) => {
                this.$message.warning("删除失败");
              });
          })
          .catch(() => {});
      } else {
        this.$message({
          message: "请选择刊位",
          type: "warning",
        });
      }
    },
    // 编辑回显
    showUploadDialog(val) {
      Object.assign(this.createForm, val);
      this.createForm.type = Number(this.createForm.type);
      this.drawerUseType = "edit";
      this.selectorStandardAdvPosList(this.createForm.adv_id);
        this.createTitle="编辑广告位",
     this.btnForSub="保存",
      this.createAdvertisingVisible = true;
    },
    // 新建按钮
    newBuild() {
      this.drawerUseType = "create";
        this.createTitle="新建广告位",
     this.btnForSub="确定",
      this.createAdvertisingVisible = true;
    },
    // 关闭新进
    handleClose(done) {
      this.$refs.createForm.resetFields();
      this.initializationCreateForm();
      done();
    },
    // table项选择
    getTableSelect(val) {
      var operateIds = "";
      val.forEach((item, index) => {
        operateIds += item.id + ",";
      });
      this.operateIds = operateIds.slice(0, -1);
    },
    // 表格数据获取
    tableMeth(orderVal) {
      this.table.loading = true;
      getAdvertisingListByChannelCode()
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data;
            this.table.tableList = tableData.data;
          } else {
          }
          this.table.loading = false;
        })
        .catch(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.tableMeth();
    this.getSelectorList();
   commonMeth.getTreeData(this.table.option).then((res) => {
      this.funArr = res.funArr;
    });
  },
  computed: {
     showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(this.table.loading);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-form--label-top .el-form-item__label {
    color: #333 !important;
}
.picShowCard {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  box-sizing: border-box;
  // background-color: white;
  text-align: center;
  padding: 15px;
}
.picShowCard p {
  padding: 20px 0;
}
.picShowCard img {
  width: 100%;
}
.creatTrue {
  margin-top: 20px;
  text-align: right;
}
.cehshi2msg {
  display: block;
  position: absolute;
  top: -35px;
  color: #999;
  right: 16px;
  z-index: 99;
}
.createContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.inpiutBox {
  width: 100%;
}
.el-select {
  width: 100%;
}
::v-deep .el-form-item__label {
  margin: 0;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
.other ::v-deep .el-input__inner {
  display: block;
  width: 100%;
  margin-top: 10px;
}
.createContentLeft,
.createContentRight {
  flex: 1;
  position: relative;
}
::v-deep .el-drawer__body {
  overflow: auto;
  
}
.createContentLeft {
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
}
.createContentRight {
  background-color: rgba(242, 242, 242, 1);
  
}
.rightBtn {
  margin-left: auto;
}
.largeBtn{
  width: 100px;
  height: 40px;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}

</style>
