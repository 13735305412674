<template>
  <el-container style="height: 100vh">
    <div class="asideBox" v-loading="asideLoading">
      <div class="logo">
        <img :src="logoUrl" alt />
      </div>
      <div class="asideTitle">
        <!--<div>携旅合作伙伴</div>-->
        <div>{{sysName}}</div>
      </div>
      <el-aside width="200px" class="aside">
        <el-col>
          <el-menu ref="menu" class="el-menu-vertical-demo" background-color="#1a3563" text-color="rgba(255,255,255,0.6)" active-text-color="#fff" :unique-opened="true" :collapse="false" :collapse-transition="false" :router="true" :menu-trigger="trigger" :default-active="menuAttr.defaultActive">
            <el-menu-item index="/indexShow" @click="saveNavState('/indexShow', 1)" v-if="isShowIndexMenu">
              <i class="iconfont icon-index" style="font-size: 18px; margin-right: 10px"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <!--<el-submenu index="111">-->
            <!--<template slot="title">-->
            <!--<i class="el-icon-menu"></i>-->
            <!--<span>酒店管理</span>-->
            <!--</template>-->
            <!--<el-submenu index="1-4">-->
            <!--<template slot="title">所有酒店管理</template>-->
            <!--<el-menu-item index="/hotelManAll" @click="saveNavState('/hotelManAll',1)">实施中酒店</el-menu-item>-->
            <!--</el-submenu>-->
            <!--</el-submenu>-->

            <el-submenu :index="item.id + ''" :key="item.id" v-for="(item, index) in menuList" v-if="item.path !== 'index'">
              <!--一级菜单模板区域-->
              <template slot="title">
                <i :class="iconObj[item.path]?iconObj[item.path].class:'icon-zhengcezhipei1'" class="submenuIcon iconfont" :style="{fontSize:iconObj[item.path]?iconObj[item.path].size:'20px'}"></i>
                <span>{{item.authName}}</span>
              </template>
              <!--<el-submenu :index="'/'+itemSecond.path"  :key="itemSecond.id" v-if="item.authName==='酒店管理'"-->
              <el-submenu :index="'/'+itemSecond.path" :key="itemSecond.id" v-if="item.authName==='酒店管理'" v-for="(itemSecond,index) in item.children">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{itemSecond.authName}}</span>
                </template>
                <el-menu-item :index="'/'+itemThird.path" :key="itemThird.id" v-for="itemThird in itemSecond.children" @click="saveNavState('/'+itemThird.path,itemThird.id)">
                  <i class="icon-tubiao  iconfont thirdMenu"></i>
                  <span :style="{color:itemThird.id==247&&menuAttr.defaultActive=='/HotelManAll'?'rgba(255, 255, 255)':''}">{{itemThird.authName}}</span>
                </el-menu-item>
              </el-submenu>
              <!--                新的二级菜单放进v-if里，否则会再生成一次-->
              <el-menu-item :index="'/'+itemSecond.path" :key="itemSecond.id" v-for="itemSecond in item.children" v-if="item.children.length>0 && item.authName!=='酒店管理' && itemSecond.authName!=='内容管理' && itemSecond.authName!=='设备管理' && itemSecond.authName!=='数字资产'&& itemSecond.authName!=='标签管理'" @click="saveNavState('/'+itemSecond.path,itemSecond.id)">
                <i class="el-icon-menu"></i>
                <span>{{itemSecond.authName}}</span>
              </el-menu-item>
              <el-submenu :index="'/'+itemSecond.path" :key="itemSecond.id" v-for="itemSecond in item.children" v-if="item.children.length>0 && item.authName==='系统设置' && itemSecond.authName==='标签管理'&& auth.type !== 'group'" @click="saveNavState('/'+itemSecond.path,itemSecond.id)">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{itemSecond.authName}}</span>
                </template>
                <el-menu-item :index="'/'+itemThird.path" :key="itemThird.id" v-for="itemThird in itemSecond.children" @click="saveNavState('/'+itemThird.path,itemThird.id)">
                  <i class="icon-tubiao  iconfont thirdMenu"></i>
                  <span>{{itemThird.authName}}</span>
                </el-menu-item>
              </el-submenu>
              <!-- 数据中心 数字资产列表展示 -->
              <el-submenu :index="'/'+itemSecond.path" :key="itemSecond.id" v-for="itemSecond in item.children" v-if="item.children.length>0 && item.authName==='数据中心' && itemSecond.authName==='数字资产'" @click="saveNavState('/'+itemSecond.path,itemSecond.id)">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{itemSecond.authName}}</span>
                </template>
                <el-menu-item :index="'/'+itemThird.path" :key="itemThird.id" v-for="itemThird in itemSecond.children" @click="saveNavState('/'+itemThird.path,itemThird.id)">
                  <i class="icon-tubiao  iconfont thirdMenu"></i>
                  <span>{{itemThird.authName}}</span>
                </el-menu-item>
              </el-submenu>
              <!-- 数据中心 数字资产列表展示 -->
              <!-- 运维管理 内容及设备列表展示 -->
              <el-submenu :index="'/'+itemSecond.path" :key="itemSecond.id" v-for="itemSecond in item.children" v-if="item.children.length>0 && item.authName==='运维管理' && (itemSecond.authName==='内容管理'||itemSecond.authName==='设备管理')" @click="saveNavState('/'+itemSecond.path,itemSecond.id)">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{itemSecond.authName}}</span>
                </template>
                <el-menu-item :index="'/'+itemThird.path" :key="itemThird.id" v-for="itemThird in itemSecond.children" @click="saveNavState('/'+itemThird.path,itemThird.id)">
                  <i class="icon-tubiao  iconfont thirdMenu"></i>
                  <span>{{itemThird.authName}}</span>
                </el-menu-item>
              </el-submenu>
              <!-- 运维管理 内容及设备列表展示 -->
              <!-- 系统设置 -->
              <!-- <el-menu-item v-if="item.authName === '系统设置' && auth.type !== 'group'">
                <i class="el-icon-menu"></i>
                <a class="operate" target="_blank" :href="operate_url">操作说明</a>
              </el-menu-item> -->
              <!-- 系统设置 -->
            </el-submenu>
          </el-menu>
        </el-col>
      </el-aside>
    </div>
    <el-container class="container">
      <el-header class="header" style="height: 95px" v-loading="topLoading">
        <div class="topInfo">
          <el-row :gutter="10" style="width: 100%" class="topRowBox">
            <el-col :span="6">
              <h1 class="companyName" style="position: relative">
                您好，{{ showData.companyName }}
                <!--                <span class="topNotice">（系统初始化，数据核准中）</span>-->
              </h1>
            </el-col>
            <el-col :span="3">
              <span class="item minFont">合作类型：{{ showData.partnerType }}</span>
            </el-col>
            <el-col :span="4">
              <span class="item minFont ">渠道商编码：{{ showData.accountId }}</span>
            </el-col>
            <el-col :span="6">
              <span class="item minFont">合同有效期：{{ showContractDate(showData.contractLift) }}</span>
            </el-col>
            <el-col :span="6">
              <div style="margin-left: auto" class="headerRight">
                <span style="margin-right: 30px">{{ auth.user_name }}</span>
                <div class="iconBox">
                  <i class="notice el-icon-message-solid pointer" @click="showNotice">
                    <span class="redPoint" v-if="isRed"></span>
                  </i>
                  <i class="loginOut iconfont icon-ai240 pointer" @click="loginOut"></i>
                  <!--<div class="version">版本号： V1.1.1</div>-->
                </div>
                <!--<span @click="loginOut" class="loginOut">退出 | </span>-->
                <!--<spheaderan @click="showNotice" class="notice loginOut" style="margin-left:7px;">通知</spheaderan>-->
              </div>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-container style="overflow: auto">
        <el-main>
          <!--<sub-alert ></sub-alert>-->
          <sub-alert v-if="
              auth.register_review_status === 1 && auth.ca_review_status === 0
            " @toAccInfo="toAccInfo" :title="titleNo">
          </sub-alert>
          <sub-alert v-if="
              auth.register_review_status === 1 && auth.ca_review_status === -1" @toAccInfo="toAccInfo" :title="titleBack"></sub-alert>
          <!--路由占位符-->
            <router-view :funArr="funArr" @getNewMessage="getNewMessage"></router-view>
        </el-main>
      </el-container>
      <el-footer class="footer"> Copyright@Htrip </el-footer>
    </el-container>
  </el-container>
</template>

<script>
// import api from './api/method'
import qs from "querystring";

export default {
  name: "Home",
  data: () => ({
    titleNo:
      "您还未完成资质认证，请前往系统设置->账户信息->认证中心完成资质提交",
    titleBack: "资质信息被驳回，请前往系统设置->账户信息完成资质修改",
    // token:Api.token,
    trigger: "click",
    menuList: [],
    iconObj: {
      index: {
        class: "iconfont icon-index",
        size: "22px",
      },
      hotel: {
        class: "iconfont icon-Hotel",
        size: "18px",
      },
      advertising: {
        class: "iconfont  icon-guanggaoweiguanli",
        size: "18px",
      },
      download: {
        class: "iconfont icon-xiazaizhongxin1",
        size: "18px",
      },
      datacenter: {
        class: "iconfont icon-shuju1",
        size: "20px",
      },
      partner: {
        class: "iconfont icon-hezuohuobanxianxing",
        size: "22px",
      },
      7: {
        class: "iconfont icon-hezuohuobanxianxing",
        size: "24px",
      },
      information: {
        class: "iconfont icon-xinxifabu",
        size: "22px",
      },
      config: {
        class: "iconfont icon-iconset0177",
        size: "20px",
      },
      finance: {
        class: "iconfont icon-finance",
        // class: "iconfont icon-shuju3",
        size: "20px",
      },
      policy: {
        class: "iconfont icon-zhengcezhipei1",
        size: "20px",
      },
      task: {
        class: "iconfont icon-renwu1",
        size: "20px",
      },
      dataAsset: {
        class: "iconfont icon-shuju3",
        size: "20px",
      },
      defaultIcon: {
        class: "iconfont icon-zhengcezhipei1",
        size: "20px",
      },
    },
    collapse: "false",
    menuAttr: {
      collapse: "false",
      defaultActive: "/",
    },
    auth: {},
    token: "",
    show: false,
    asideLoading: false,
    showData: {
      companyName: "",
      partnerType: "",
      accountId: "",
      contractLift: "",
    },
    topLoading: true,
    allData: {},
    test: "test111",
    funArr: [],
    isRed: false,
    isShowIndexMenu: false,
    logoUrl: "",
    sysName: "",
    operate_url: process.env.VUE_APP_OPERATE_URL,
  }),
  methods: {
    toggleCollp() {
      this.collapse = !this.collapse;
    },
    saveNavState(path, menuId) {
      console.log(path);
      this.funArr = [];
      this.menuAttr.defaultActive = path;
      window.sessionStorage.setItem("defaultActive", path);
      window.sessionStorage.setItem("defaultActiveId", menuId);
      var funArr = [];
      this.allData.forEach((item, index) => {
        if (item.parent === menuId) {
          funArr.push(item);
          this.funArr.push(item.text);
        }
      });
      sessionStorage.setItem("funArr", JSON.stringify(this.funArr));
      sessionStorage.setItem("filterCon", JSON.stringify({}));
      sessionStorage.setItem("filterPage", "");
      sessionStorage.setItem("filterLimit", "");
      sessionStorage.removeItem("home");
      sessionStorage.removeItem("welcomePage");
      sessionStorage.removeItem("hotelService");
      sessionStorage.removeItem("hotelServiceTwo");
      sessionStorage.removeItem("jumpUrl");
    },
    toAccInfo(val) {
      this.saveNavState(val.path, val.id);
    },
    loginOut() {
      this.$confirm("确认退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    showNotice() {
      this.$router.push({ path: "/topNotice" });
      // this.$router.push('')
    },
    //判断是否通过-通过后，判断session保存
    //没有通过，调接口
    getMenuList() {
      this.$http
        .get(this.global.getmenulist, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var flag = false;
            this.menuList = res.data.data;
            this.auth = res.data.auth;
            this.show = true;
            this.menuList.forEach((item, index) => {
              if (item.authName.indexOf("首页") !== -1) {
                flag = true;
              }
            });
            flag
              ? (this.isShowIndexMenu = true)
              : (this.isShowIndexMenu = false);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.asideLoading = false;
        })
        .catch(() => {});
    },
    showDataMeth() {
      this.topLoading = true;
      var param = qs.stringify({
        token: this.token,
      });
      this.$http
        .post(this.global.getIndexHeader, param)
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showData = res.data.data;
            sessionStorage.setItem("curCompanyName", this.showData.companyName);
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
          this.topLoading = false;
        })
        .catch(() => {
          this.topLoading = false;
          this.showLoading = false;
        });
    },
    getTreeData() {
      this.$http
        .get(this.global.getUserRuleLists, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var treeData = res.data.data;
            this.allData = treeData;
            sessionStorage.setItem("treeData", JSON.stringify(treeData));
            sessionStorage.setItem("auth", JSON.stringify(res.data.auth));
          }
        });
    },
    getGroupLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.groupLevel, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.curId = res.data.data.pid[0];
            }
            resolve(res.data.data.pid[0]);
          });
      });
    },
    judgeIsRed() {
      this.$http
        .get(this.global.informationMessage, {
          params: {
            token: this.token,
            page: "1",
            limit: "5",
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.isRed = res.data.data.newmessage;
          }
        });
    },
    getNewMessage(val) {
      this.isRed = val;
    },
    // 动态获取 账号logo
    getLogoData() {
      this.$http
        .get(this.global.getLogo, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          this.logoUrl = res.data.data.logo_url;
          this.sysName = res.data.data.system_name;
        })
        .catch(() => {});
    },
  },
  created() {
    this.menuAttr.defaultActive =
      window.sessionStorage.getItem("defaultActive");
  },
  watch: {
    $route(e, a) {
       console.log(e, a);
      var apath = e.fullPath.substr(1);
      if (apath == "incomeSta") {
        this.saveNavState("/incomesta", 54);
      } else if (apath == "performanceSta") {
        this.saveNavState("/performancesta", 55);
      } else if (apath == "HotelManAll") {
        console.log(e, a);
        sessionStorage.setItem("defaultActiveId", 247);
        this.$refs.menu.open("/index");
        this.menuAttr.defaultActive = "/HotelManAll";
        this.saveNavState("/HotelManAll", 247);
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getMenuList();
    this.showDataMeth();
    this.judgeIsRed();
    this.getLogoData();
    var param = qs.stringify({ token: this.token });
    var a = this.iconObj["test"];
    this.getGroupLevel().then((res) => {
      this.getTreeData(res);
    });
  },
  computed: {
    showContractDate() {
      return function (val) {
        var str = "";
        if (val && val !== "") {
          if (val.indexOf("1970") !== -1 || val === "") {
            str = "暂无";
          } else {
            str = val;
          }
        } else {
          str = "暂无";
        }

        return str;
      };
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  img {
    /*width:60%;*/
    /*height:auto;*/
    height: 100%;
    // margin-right: 20px;
  }
}
.container {
  width: 100%;
  /*height:calc(100vh - 60px);*/
}
html {
  background: #f2f2f2 !important;
}
.el-header {
  background: #fff;
  /*color:#3085fc;*/
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.header {
  display: flex;
  justify-content: space-between;
  /*position:fixed;*/
  /*top:0;*/
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  .name {
    font-weight: 600;
  }
}
.headerRight {
  display: flex;
  align-items: center;
  float: right;
  .iconBox {
    border-left: 1px solid #ddd;
    padding-left: 30px;
  }
  .notice,
  .loginOut {
    font-size: 24px;
    color: #3085fc;
  }
  .loginOut {
    font-size: 28px;
  }
  .notice {
    margin-right: 30px;
  }
}
.aside {
  /*height:100vh;*/
  overflow: auto;
  background: #193461 !important;
  flex: 1;
}
.asideBox {
  width: 200px;
  background: #1a3563;
  display: flex;
  flex-direction: column;
}
.pointer {
  cursor: pointer;
}

.loginOut {
}
.el-main {
  background: #f2f2f2;
}
.el-footer,
.el-aside {
  background: #fff;
}
.submenuIcon {
  margin-right: 10px;
}
.el-menu {
  border: none !important;
}
.toggleBtn {
  color: #333;
  font-size: 14px;
  background: #f8f9fb;
  text-align: center;
  letter-spacing: 0.2rem;
  line-height: 32px;
  cursor: pointer;
}
.asideTitle {
  // height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*padding-bottom:20px;*/
}
.asideTitle div:first-child {
  margin-bottom: 10px;
}
.asideTitle,
.asideTitle div {
  color: #fff;
  /*font-weight:600;*/
  font-size: 16px;
  text-align: center;
}
.topInfo {
  width: 100%;
  display: flex;
  align-items: center;
}
.topInfo .item {
  /*margin-left:20px;*/
}
.marginLeft {
  margin-left: 60px;
}
.companyName {
  font-size: 16px;
}
.topRowBox {
  width: 100%;
  display: flex;
  align-items: center;
}
.topNotice {
  color: red;
  font-size: 14px;
  position: absolute;
  top: 30px;
  left: -10px;
}
.notice {
  position: relative;
}
.redPoint {
  display: block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
.version {
  white-space: nowrap;
  font-size: 12px;
  position: absolute;
  bottom: -9px;
}
.footer {
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #3085fc;
}
a {
  text-decoration: none;
}
.operate {
  color: rgba(255, 255, 255, 0.6);
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none !important;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none !important;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none !important;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none !important;
}
.thirdMenu {
  font-size: 12px;
  margin-right: 5px;
}
</style>
