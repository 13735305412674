<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second" v-if="!query">系统设置</span>
        <span slot="third" v-if="!query">NO广告管理</span>
        <span slot="second">NO广告管理</span>
      </top-bread>
    </div>
    <div class="dataCardRow">
      <el-card class="dataCard">
        <div class="title top">所有品牌数量</div>
        <div class="title"> <span class="number"> {{pageData.brand_total}}</span> 个</div>
      </el-card>
      <el-card class="dataCard ">
        <div class="title top">所有酒店数量</div>
        <div class="title"> <span class="number"> {{pageData.brand_hotel_total}}</span> 家</div>
      </el-card>
      <el-card class="dataCard">
        <div class="title top">独立酒店数量</div>
        <div class="title"> <span class="number"> {{pageData.hotel_total}}</span> 家</div>
      </el-card>
    </div>

    <el-tabs style="margin-left:20px" v-model="activeName">
      <el-tab-pane label="品牌列表" name="group">
        <groupNoAdv :funArr="funArr" @updata='getData'> </groupNoAdv>
      </el-tab-pane>
      <el-tab-pane label="酒店列表" name="hotel">
        <hotelNoAdv :funArr="funArr" @updata='getData'> </hotelNoAdv>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import groupNoAdv from "./components/groupNoAdv.vue";
import hotelNoAdv from "./components/hotelNoAdv.vue";
import commonMeth from "@/api/method";
import { getTotal } from "@/api/label.js";
export default {
  name: "NOAdvList",
  components: {
    hotelNoAdv,
    groupNoAdv,
  },
  data() {
    return {
      bread: {
        level: 3,
        firstPath: { path: "/indexShow" },
      },

      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      activeName: "group",
      funArr: [],
      pageData: {
        brand_total: 0,
        brand_hotel_total: 0,
        hotel_total: 0,
      },
      query: null,
    };
  },
  mounted() {
    this.query = sessionStorage.getItem("apaasData");
    if (this.query) {
      this.bread.level = 3;
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      commonMeth.getTreeData().then((res) => {
        this.funArr = res.funArr;
      });
    }
    this.getData();
  },
  methods: {
    getData() {
      getTotal()
        .then((res) => {
          if (res.data.code == 100000) {
            Object.assign(this.pageData, res.data.data);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("页面统计数据数据获取失败");
        });
    },
  },
};
</script>
<style scoped>
.el-tabs ::v-deep .el-tabs__item {
  list-style: none;
  font-size: 16px;
  color: #303030;
}
.el-tabs /deep/ .el-tabs__active-bar {
  width: 64px !important;
}
.outTopBox {
  height: 58px;
}
.dataCardRow {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.dataCard {
  width: 32%;
}
.title {
  color: #a3a7af;
}
.number {
  font-family: DIN !important;
  font-size: 32px;
  color: #333;
}
.top {
  margin-bottom: 20px;
}
</style>