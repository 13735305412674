<template>
  <div>
    <!-- 面包屑及筛选 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">财务管理</span>
        <span slot="third">对账管理</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth" v-if="auth.type!='htrip'"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <!-- 面包屑及筛选 -->
    <!-- 数据卡片 -->
    <el-card class="box-card">
      <div class="lineContent">
        <div class="title blueTitle">数据统计</div>
        <el-row :gutter="20" class="rowBox vertical borderBox" v-loading="showLoading">
          <index-four-row :span="12" :type="type">
            <span slot="numTitle">已对账金额</span>
            <span slot="num">{{ pageData.check_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
          <index-four-row :span="12" :type="type">
            <span slot="numTitle">未对账金额</span>
            <span slot="num">{{ pageData.not_check_amount }}</span>
            <span slot="unit">元</span>
          </index-four-row>
        </el-row>
      </div>
    </el-card>
    <!-- 数据卡片 -->
    <!-- 列表 -->
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">对账列表</div>
      <table-list @showUploadDialog="showUploadDialog" :table="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading"></table-list>
    </el-card>
    <!-- 列表 -->
    <!-- 组件 -->
    <recon-dialog @closedialog="closeDialog" ref="ReconDialog" :allDialogData="allDialogData" :StatementAccountMonth="StatementAccountMonth" :contentLoading="contentLoading" :contentShowVisible="contentShowVisible"></recon-dialog>
    <div class="allLoading" v-show="false">{{ allLoadingStateCom }}</div>
    <!-- 组件 -->
  </div>
</template>

<script>
import qs from "querystring";
import commonMeth from "../../api/method";
import ReconDialog from "./components/ReconDialogLIst";
import {
  getPartnerReconciliationList,
  getReconciliationList,
  getCheckAmountByPartner,
} from "@/api/financialManagement.js";
export default {
  components: {
    ReconDialog,
  },
  name: "adManCustom",
  data: () => ({
    type: "timeCenter",
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    table: {
      tableHeader: [
        { prop: "date", label: "对账月份", width: "300" },
        { prop: "reconciliation_id", label: "对账流水号", width: "" },
        { prop: "status_text", label: "状态", width: "" },
        { prop: "create_time", label: "对账申请时间", width: "" },
        { prop: "hotel_num", label: "对账酒店数", width: "" },
        { prop: "check_amount", label: "对账金额", width: "" },
        { prop: "reject_hotel_num", label: "驳回酒店数", width: "" },
        { prop: "reject_amount", label: "驳回金额", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "finManRecon",
      name: "finManRecon",
      option: false,
      loading: true,
      select: true,
    },
    StatementAccountMonth: "",
    allDialogData: null,
    topSearchShow: {},
    condition: {},
    operateIds: "",
    operateIdsArr: [],
    showData: {
      amount: 0,
      alreadyReconciliation: 0,
      notReconciliation: 0,
    },
    auth: {},
    pageData: {},
    token: "",
    showLoading: true,
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    funArr: [],
    allData: {},
    tableOperateDisabled: false,
    topSearchSave: {},
    allLoadingState: false,
    contentShowVisible: false,
    contentLoading: false,
    month: "",
    status: "",
    reconciliationId: "",
  }),
  methods: {
    // 关闭弹框
    closeDialog() {
      this.contentShowVisible = false;
    },
    // 对账单月份动态输出
    timeForChinese(time) {
      var month = time.substring(5, 7);

      if (month == "01") {
        return (this.StatementAccountMonth = "一");
      } else if (month == "02") {
        return (this.StatementAccountMonth = "二");
      } else if (month == "03") {
        return (this.StatementAccountMonth = "三");
      } else if (month == "04") {
        return (this.StatementAccountMonth = "四");
      } else if (month == "05") {
        return (this.StatementAccountMonth = "五");
      } else if (month == "06") {
        return (this.StatementAccountMonth = "六");
      } else if (month == "07") {
        return (this.StatementAccountMonth = "七");
      } else if (month == "08") {
        return (this.StatementAccountMonth = "八");
      } else if (month == "09") {
        return (this.StatementAccountMonth = "九");
      } else if (month == "10") {
        return (this.StatementAccountMonth = "十");
      } else if (month == "11") {
        return (this.StatementAccountMonth = "十一");
      } else if (month == "12") {
        return (this.StatementAccountMonth = "十二");
      } else {
      }
    },
    //根据不同的表格点击按钮展示数据
    showUploadDialog(val) {
      this.contentLoading = true;
      this.contentShowVisible = true;
      this.timeForChinese(val.date);
      const json = {
        reconciliationId: val.reconciliation_id,
      };
      var param = qs.stringify(json);
      getReconciliationList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.allDialogData = res.data.data;
            this.contentLoading = false;
          }
        })
        .catch((err) => {});
    },
    // 表格数据获取
    tableMeth(orderVal) {
      this.showLoading = true;
      this.table.loading = true;
      const json = {
        month: this.month,
        status: this.status,
        reconciliationId: this.reconciliationId,
      };
      var param = qs.stringify(json);
      getPartnerReconciliationList(param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData;
          } else {
            this.$message.warning(res.data.msg);
          }
          this.showLoading = false;
          this.table.loading = false;
        })
        .catch(() => {
          this.showLoading = false;
          this.table.loading = false;
        });
      this.showLoading = false;
      this.table.loading = false;
    },
    // 分页
    getNewPage(newPage) {
      this.table.page = newPage;
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
    },
    // 筛选权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          coopExamineStatus: true,
          keyword: true,
          keywordPlaceholder: "对账流水号",
          onlymonth: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {};
      }
    },
    // 筛选
    getCondition(val) {
      this.month = val.onlymonth;
      this.status = val.coopExamineStatus;
      this.reconciliationId = val.keyword;
      this.table.page = 1;
      this.tableMeth();
    },
    // 表格选择
    getTableSelect(val) {
      var operateIds = "";
      this.operateIdsArr = val;
      val.forEach((item, index) => {
        operateIds += item.id + ",";
      });
      this.operateIds = operateIds.slice(0, -1);
      this.operateIdsArr = this.operateIds.split(",");
    },
    //数据统计
    showDataMeth() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http
        .get(this.global.getReconciliationStatistics, {
          params: {
            token: this.token,
            status: this.condition.finStatus,
            reconciliationType: this.condition.finType,
            month: this.condition.month,
            keyword: this.condition.keyword,
          },
        })
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.showData = res.data.data;
          } else {
            this.$message.error("返回错误");
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },

    getFoldMeth(val) {
      this.fold = val;
    },
    // 获取 弹框展示数据
    getData() {
      this.showLoading = true;
      getCheckAmountByPartner()
        .then((res) => {
          if (res.data.code == 100000) {
            this.pageData = res.data.data;
            var obj = res.data.data;
            for (const key in obj) {
              this.pageData[key] = Number(obj[key]) / 100;
            }
            this.showLoading = false;
          } else {
            this.showLoading = true;
          }
        })
        .catch((err) => {
          this.showLoading = true;
        });
      this.tableMeth();
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getData();
    commonMeth.getTreeData().then((res) => {
      this.funArr = res.funArr;
    });
  },
  computed: {
    showDataCom() {
      return function (s, type = "", n = 2) {
        if (type === "money") {
          if (s === null) {
            return 0;
          } else {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
            var l = s.split(".")[0].split("").reverse(),
              r = s.split(".")[1];
            var t = "";
            for (var i = 0; i < l.length; i++) {
              t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
            }
            return t.split("").reverse().join("") + "." + r;
          }
        } else {
          if (s === null) {
            return 0;
          } else {
            return parseFloat(s).toLocaleString();
          }
        }
      };
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(
        this.table.loading,
        false,
        this.showLoading
      );
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep :focus {
  outline: 0;
}
.rightBtn {
  margin-left: auto;
}
.rowBox {
  margin-top: 20px;
  box-sizing: border-box;
}
</style>
