<!-- 模板配置添加/修改弹窗 -->
<template>
  <el-dialog title="模块管理" :visible.sync="dialogVisible" width="650px" @open="open" @closed="handleClose">
    <el-form :model="form" ref="form" label-width="100px">
      <el-form-item label="版式：" prop="t_pid">
        <el-select v-model="form.t_pid" placeholder="" style="width:100%">
          <el-option v-for="item in banList" :key="item.id + 'ban'" :label="item.t_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称：" prop="t_name">
        <el-input v-model="form.t_name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="模板酒店id：" prop="id">
        <el-input v-model="form.id" placeholder="" :disabled="current.id"></el-input>
      </el-form-item>
      <el-form-item label="图片：" prop="t_addr">
        <!-- fileName="123" -->
        <UplaodFile :urlFile="form.t_addr" text="选择文件" @uploadFileChange="(val) => (form.t_addr = val)"></UplaodFile>
      </el-form-item>
      <el-form-item label="选择品牌：" prop="groupId">
        <el-select v-model="form.groupId" placeholder="" filterable style="width:100%">
          <el-option v-for="item in groupList" :key="item.id + 'group'" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推荐模板：" prop="module">
        <div>
          <el-radio v-model="form.module" label="1">推荐显示</el-radio>
          <el-radio v-model="form.module" label="0">推荐不显示</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="渠道上显示：" prop="show_channel">
        <div>
          <el-radio v-model="form.show_channel" label="1">显示</el-radio>
          <el-radio v-model="form.show_channel" label="0">不显示</el-radio>
            <el-radio v-model="form.show_channel" label="2">部分显示</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="选择渠道商：" prop="groupId" v-if="form.show_channel==2">
        <el-select v-model="form.channel" placeholder="" style="width:100%" filterable multiple @change="changebb">
          <el-option v-for="(item,index) in channelList" :key="index" :label="item.company_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- collapse-tags -->
      <el-form-item label="显示门店id：" prop="storeId">
        <div>
          <el-radio v-model="form.storeId" label="1">显示</el-radio>
          <el-radio v-model="form.storeId" label="0">不显示</el-radio>
        
        </div>
      </el-form-item>
      <el-form-item label="排序：" prop="morder">
        <el-input v-model="form.morder" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getList } from "../../HotelClassify/api/brand";
import { add, edit } from "../api/module";
import UplaodFile from "../../../../components/common/operation/application/components/UploadImage.vue";
export default {
  name: "paramsOper",
  components: { UplaodFile },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
    banList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      channelList: [],
      groupList: [],
      form: {
        t_pid: "",
        t_name: "",
        id: "",
        t_addr: "",
        groupId: "",
        module: "0",
        storeId: "0",
        morder: "",
        channel: ['HCC009999'],
        show_channel: "1",
      },
    };
  },

  methods: {
    changebb(){
      console.log(this.form.channel)
    },
    getCoop(type) {
      return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("token");
        this.$http
          .get(this.global.getPartnersByType, {
            params: {
              token: token,
              type: "channel",
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var resData = res.data.data;
              this.channelList = resData;
            }
          });
        resolve();
      });
    },
    open() {
      this.getCoop();
      this.getGroupList().then(() => {
        if (JSON.stringify(this.current) != "{}") {
          this.form.t_pid = this.current.t_pid;
          this.form.t_name = this.current.t_name;
          this.form.id = this.current.hotel_id;
          this.form.t_addr = this.current.t_addr;
          this.form.groupId = parseInt(this.current.group_id);
          this.form.module = this.current.recommend;
          this.form.storeId = this.current.showstoreid;
          this.form.morder = this.current.morder;
          this.form.show_channel = this.current.show_channel;
          this.form.channel = this.current.channel
            ? this.current.channel.split(",")
            : [];
        }
      });
    },
    submit() {
      if (this.form.show_channel == 2 && this.form.channel.length == 0) {
        this.$message.warning("以确认渠道商显示此模板，请选择对应渠道商");
      }
      if (JSON.stringify(this.current) == "{}") {
        add({
          pid: this.form.t_pid,
          name: this.form.t_name,
          hotel_id: this.form.id,
          pic_url: this.form.t_addr,
          group_id: this.form.groupId,
          recommend: this.form.module,
          showstoreid: this.form.storeId,
          morder: this.form.morder,
          show_channel: this.form.show_channel,
          channel: this.form.channel.length ? this.form.channel.join(",") : "",
        }).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.dialogVisible = false;
            this.$emit("refresh");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      } else {
        edit({
          id: this.current.id,
          pid: this.form.t_pid,
          name: this.form.t_name,
          hotel_id: this.form.id,
          pic_url: this.form.t_addr,
          group_id: this.form.groupId,
          recommend: this.form.module,
          showstoreid: this.form.storeId,
          morder: this.form.morder,
          show_channel: this.form.show_channel,
          channel: this.form.channel.length ? this.form.channel.join(",") : "",
        }).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.dialogVisible = false;
            this.$emit("refresh");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
    },
    async getGroupList() {
      await getList({
        page: 1,
        size: 999,
      }).then((data) => {
        this.groupList = data.data.data.row;
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
