<template>
  <div>
    <div>
      <div class="itemBox" v-for="(item,index) in dataList" :key="index">
        <i class="el-icon-delete" @click="delItem(index)" v-if="dataList.length>1||!is_first"></i>
        <el-form :model="item">
          <el-row :gutter='24'>
            <el-col :span="3" class="first">
              <el-form-item prop="sort">
                <el-input v-model="item.sort" placeholder="序号" @change="numberTrue(index)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="title">
                <el-input v-model="item.title" :placeholder="sourceType=='pic'?'封面标题':'封面标题'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="is_first?12:16">
              <el-form-item prop="desc">
                <el-input v-model="item.desc" :placeholder="sourceType=='pic'?'封面描述':'封面描述'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="is_first">
              <el-form-item prop="labelValue">
                <el-input v-model="item.labelValue" :placeholder="sourceType=='pic'?'封面标签':'封面标签'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="是否在线" v-if="!is_first&&sourceType=='video'" style="margin-bottom:0">
            <div @click="getUpRef(index)">
              <el-radio-group v-model="item.isOnline" style='line-height:50px' @change="changeOnline">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item v-if="item.isOnline=='0'">
            <div @click="getUpRef(index)">
              <uploadMorePicDivMore :list='item.fileList' :sourceType="sourceType" :is_first="is_first" :upIndex="index" @defaultData="getData"></uploadMorePicDivMore>
            </div>
          </el-form-item>
          <el-form-item v-if="item.isOnline=='1'" class="urlBox">
            <div v-for="(file,t) in item.outFileList" :key="t+'url'" style="width:100%;margin-bottom:10px">
              <el-input v-model="file.url" style="width:calc(100% - 120px)"></el-input>
              <el-button style="margin-left:10px" type="primary" icon="el-icon-plus" circle @click="addfile(index)"> </el-button>
              <el-button type="primary" icon="el-icon-delete" v-if="item.outFileList.length>1" circle @click="delfile(index,t)"></el-button>
            </div>
          </el-form-item>
        </el-form>
        <div @click="getUpRef(index)">
          <uploadPicTextMore2 v-if="is_first" :optTheindex="index" :titleDataList="item.child" :is_first='false' :sourceType="sourceType" @defaulttitledata="gettitleData" @additem='addChildItem' style="margin-bottom:20px"> </uploadPicTextMore2>
        </div>
      </div>
      <el-button type="primary" style="margin-top:20px" @click="addItem">增 加</el-button>
    </div>
  </div>
</template>
<script>
import uploadMorePicDivMore from "./uploadMorePicDivMore";
import uploadPicTextMore2 from "./uploadPicTextMore2";
export default {
  name: "uploadPicTextMore",
  props: ["titleDataList", "sourceType", "is_first", "optTheindex"],
  components: {
    uploadMorePicDivMore,
    uploadPicTextMore2,
  },
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "",
      dataList: [
        {
          title: "",
          desc: "",
          sort: "",
          isOnline: "0",
          labelValue: "",
          fileList: "",
          child: [],
          outFileList: [{ url: "" }],
        },
      ],
      headers: {
        token: "",
      },
      type: "",
      optIndex: "",
    };
  },
  watch: {
    titleDataList: {
      handler: function (newVal) {
        if (newVal && newVal.length > 0) {
          this.dataList = newVal;
        } else {
          this.dataList = [
            {
              title: "",
              desc: "",
              sort: "",
              isOnline: "0",
              labelValue: "",
              fileList: "",
              child: [],
              outFileList: [{ url: "" }],
            },
          ];
        }
      },
      deep: true,
      immediate: true,
    },
    dataList: {
      handler: function (newVal) {
        this.$emit("defaulttitledata", this.dataList, this.optTheindex);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    addChildItem(index) {
      this.dataList[index].child.push({
        title: "",
        desc: "",
        sort: "",
        isOnline: "0",
        labelValue: "",
        fileList: "",
        child: [],
        outFileList: [{ url: "" }],
      });
    },
    // 描述图文数据获取与验证列表
    gettitleData(data, index) {
      if (this.optTheindex) {
        this.dataList[index].child = data;
      }
    },
    changeOnline() {
      this.dataList[this.optIndex].outFileList = [{ url: "" }];
      this.dataList[this.optIndex].fileList = "";
    },
    delfile(index, t) {
      this.dataList[index].outFileList.splice(t, 1);
    },
    addfile(index) {
      this.dataList[index].outFileList.push({ url: "" });
    },
    // 数值验证
    numberTrue(index) {
      let flag = /^[0-9]+$/.test(this.dataList[index].sort);
      if (!flag) {
        this.dataList[index].sort = "";
        this.$message.warning("请输入整数进行排序");
      }
    },
    // 数据更新
    getData(data, index) {
      this.dataList[index].fileList = data;
    },
    // 删除项
    delItem(index) {
      this.$nextTick(() => {
        this.dataList.splice(index, 1);
      });
    },
    // 添加项
    addItem() {
      this.dataList.push({
        title: "",
        desc: "",
        labelValue: "",
        sort: "",
        isOnline: "0",
        fileList: "",
        outFileList: [{ url: "" }],
        child: [],
        uploadPicTrue: false,
      });
    },
    // 操作位置获取
    getUpRef(index) {
      this.optIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
.itemBox {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px 20px 0;
  box-sizing: border-box;
  position: relative;
  .el-icon-delete {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #999;
    position: absolute;
    top: -12px;
    right: -12px;
    text-align: center;
    line-height: 24px;
    color: #999;
    background: #fff;
    transition: 0.3s;
  }
  .el-icon-delete:hover {
    border: 1px solid red;
    color: red;
  }
}

.el-col {
  padding: 0 12px 0 0 !important;
}
.first {
  padding-left: 12px !important;
}
.urlBox ::v-deep .el-form-item__content {
  flex-wrap: wrap;
}
</style>