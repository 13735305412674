<template>
  <div>
    <el-card class="box-card " style="height: 440px;">
      <div class="title blueTitle">{{type}}</div>
      <div class="timeShow">{{searchTime}}</div>
      <div class="radioBox" v-if="type=='收益趋势'">
        <el-radio-group v-model="radioType" text-color='#3688F8' fill="#fff" size='mini' @change="changeRadio">
          <el-radio-button label="dmp,dmp_youzan,adv">全部</el-radio-button>
          <el-radio-button label="dmp">影视</el-radio-button>
          <el-radio-button label="dmp_youzan">商城</el-radio-button>
          <el-radio-button label="adv">广告</el-radio-button>
        </el-radio-group>
      </div>
      <div :id="id" ref="chart1" tyle="width:100%;height:330px;" class=" echarts chart chartBox" v-loading='pageLoading'></div>
    </el-card>
  </div>
</template>
<script>
import echarts from "echarts";
import GLOBAL from "../../../api/globalVar.js";
import {
  getProfitTrend,
  getSysTrend,
  getOnLineTrend,
  getAdvTrend,
} from "@/api/dataCenter.js";
export default {
  name: "LineChart",
  props: {
    id: {
      type: String,
      default: "",
    },
    searchTime: {
      type: String,
      default: "",
    },
    screenName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    searchForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    searchForm: {
      handler: function (newVal) {
        let json = JSON.parse(JSON.stringify(newVal));
        if (this.type == "收益趋势") {
          this.radioType = "dmp,dmp_youzan,adv";
          json.source = this.radioType;
        }
        this.searchData = json;
        if (json.hotelContract && json.roomNo) {
          this.getLineList(json);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      searchData: {},
      radioType: "dmp,dmp_youzan,adv",
      allData: {},
      colorBlue: {
        lineColor: "#4D7CFE",
        itemColor: "#4D7CFE",
        areaColor: [
          {
            offset: 0,
            color: "rgba(85, 130, 254, 0.17)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(77, 124, 254, 0)", // 100% 处的颜色
          },
        ],
      },
      colorOrange: {
        lineColor: "#F0B44E",
        itemColor: "#F0B44E",
        areaColor: [
          {
            offset: 0,
            color: "rgba(240, 180, 78, 0.3)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(240, 180, 78, 0)", // 100% 处的颜色
          },
        ],
      },
      colorGreen: {
        lineColor: "#3AAC9F",
        itemColor: "#3AAC9F",
        areaColor: [
          {
            offset: 0,
            color: "rgba(58, 172, 159, 0.18)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(58, 172, 159, 0)", // 100% 处的颜色
          },
        ],
      },
      yAxis: {},
      xAxisList: [],
      seriesList: [],
      pageLoading: false,
      echartsObj: {},
    };
  },
  methods: {
    // 获取图表数据
    getLineList(form) {
      this.pageLoading = true;
      if (this.type == "收益趋势") {
        this.$emit("moudleloading", this.type);
        // 广告订单列表数据
        getProfitTrend(form)
          .then((res) => {
            if (res.data.code == 100000) {
              let arr = res.data.data.row;
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = arr.map((i) => {
                return (i.dateValue/100).toFixed(2);
              });
              this.getYaxis("元", this.seriesList);
              this.pageLoading = false;
              this.$emit("moudleShow", this.type, (res.data.data.value/100).toFixed(2));
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("收益趋势数据加载失败");
          });
      } else if (this.type == "广告曝光趋势") {
        // 广告曝光趋势数据
        getAdvTrend(form)
          .then((res) => {
            if (res.data.code == 100000) {
              let arr = res.data.data.row;
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = arr.map((i) => {
                return i.dateValue;
              });
              this.getYaxis("次", this.seriesList);
              this.pageLoading = false;
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("广告曝光趋势数据加载失败");
          });
      } else if (this.type == "在线时长") {
        this.$emit("moudleloading", this.type);
        // 在线时长列表数据
        getOnLineTrend(form)
          .then((res) => {
            if (res.data.code == 100000) {
              let arr = res.data.data.row;
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = arr.map((i) => {
                return i.dateValue;
              });
              this.getYaxis("h", this.seriesList);
              this.pageLoading = false;
              this.$emit("moudleShow", this.type, res.data.data.value);
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("在线时长数据加载失败");
          });
      } else if (this.type == "系统交互趋势") {
        // 系统交互列表数据
        getSysTrend(form)
          .then((res) => {
            if (res.data.code == 100000) {
              let arr = res.data.data.row;
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = arr.map((i) => {
                return i.dateValue;
              });
              this.getYaxis("次", this.seriesList);
              this.pageLoading = false;
              this.echartsObj.resize();
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            // this.$message.warning("系统交互趋势数据加载失败");
          });
      }
    },
    changeRadio() {
      this.searchData.source = this.radioType;
      this.getLineList(this.searchData);
    },
    // 图表渲染
    chartRender() {
      this.echartsObj = echarts.init(this.$refs.chart1);
      let color = this.colorBlue;
      // if (this.type == "在线时长" || this.type == "系统交互趋势") {
      //   color = this.colorBlue;
      // } else if (this.type == "收益趋势") {
      //   color = this.colorOrange;
      // } else if (this.type == "广告曝光趋势") {
      //   color = this.colorGreen;
      // }
      var option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#343754",
          padding: 10,
          textStyle: {
            color: "#fff",
          },
        },
        grid:{
          top:20,
          bottom:20
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#D5D9E0",
            },
          },
          axisLabel: {
            color: "#A2ACBB",
          },
          splitLine: {
            show: false,
          },
          data: this.xAxisList,
        },
        yAxis: this.yAxis,

        series: [
          {
            data: this.seriesList,
            type: "line",
            lineStyle: {
              color: color.lineColor,
            },
            itemStyle: {
              color: color.itemColor,
            },
            areaStyle: {
              color: {
                type: "linear",
                y: 0,
                x: 0,
                x2: 0,
                y2: 1,
                colorStops: color.areaColor,
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      this.echartsObj.setOption(option);
      this.watchSize();
    },
    // y轴配置
    getYaxis(unit, list) {
      let arr = [...list];
      arr = arr.sort((a, b) => a - b);
      var minint = Math.floor(arr[0] / 10);
      var minval = minint * 10;
      var maxint = Math.ceil(arr[arr.length - 1] / 9.5);
      var maxval = maxint * 10;
      this.yAxis = {
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#D5D9E0",
          },
        },
        axisLabel: {
          formatter: `{value}${unit}`,
          color: "#A2ACBB",
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        min: minval,
        max: maxval,
        splitNumber: 5,
        interval: (maxval - minval) / 5,
      };
      this.chartRender();
    },
    // 监听时间
    watchSize() {
      this.echartsObj.resize();
    },
  },
  mounted() {
    window.addEventListener("resize", this.watchSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.watchSize);
  },
};
</script>

<style scoped lang="scss">
#chart,
.chart,
.chart2 {
  width: 100%;
  height: 330px;
  overflow: hidden;
}
.timeShow {
  margin-top: 10px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
    color:#a3a7af;
}
.box-card {
  position: relative;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border-color: rgb(54, 136, 248) !important;
  z-index: 99;
  box-shadow: rgb(54, 136, 248) -1px 0px 0px 0px !important;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  box-shadow: none !important;
}
.radioBox {
  position: absolute;
  top: 35px;
  right: 20px;
}
</style>
