<template>
<!--    <hotel-all :pageInfo="pageInfo"></hotel-all>-->
	<one-page-list :pageInfo="pageInfo"></one-page-list>
</template>

<script>
import { onMounted } from 'vue';
    import onePageList from "@/components/common/hotelMan/onePageList.vue";
    export default {
        name: "HotelManNew",
        components:{
            onePageList,
        },
        data:()=>({
            pageInfo:{
                hotelState:'all',
                text:'所有',
                fromUrl:'HotelManAll'
            }
        }),
         mounted(){
         sessionStorage.setItem("defaultActiveId", 247);
    }
    }
   
</script>

<style scoped>

</style>