<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" v-if="curPage === '/indexShow'">
        <span slot="second">首页</span>
        <span slot="third">更多</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskToDo" v-if="curPage === '/taskToDo'">
        <span slot="second">任务管理</span>
        <span slot="third">待办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
      <top-bread :bread="breadTaskDone" v-if="curPage === '/taskDone'">
        <span slot="second">任务管理</span>
        <span slot="third">已办任务</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <div class="btnRow">
        <el-button type="primary" class="largeBtn" @click="backMeth">返回</el-button>
        <el-button type="primary" @click="trueMeth" v-show="btnShow" class="largeBtn">提交</el-button>
      </div>
      <div class="title blueTitle">排期概览</div>
      <div class="tableBox">
        <el-table ref="singleTable" :data="tableData" style="width: 100%" :header-cell-style="headerStyle">
          <el-table-column prop="ad_title" label="排期名称"></el-table-column>
          <el-table-column prop="company_brand_name" label="投放品牌"></el-table-column>
          <el-table-column prop="ad_serving_type" label="投放方式"></el-table-column>
          <el-table-column prop="media_form" label="媒体形式"> </el-table-column>
          <el-table-column prop="adv_num" label="投放刊位数量"></el-table-column>
          <el-table-column label="投放日期" width="200">
            <template slot-scope="scope">
             <span>{{scope.row.ad_start_time+"~"+scope.row.ad_end_time}}</span>
            </template> </el-table-column>
          <el-table-column prop="hotel_num" label="投放酒店数量"></el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-card class="box-card">
      <div class="title blueTitle titleAddSearch">
        <span>酒店明细</span>
        <div class="inputRightBox">
          <el-select v-model="adv_id" placeholder="请选择广告刊位">
            <el-option v-for="(item,index) in selectList" :key="index" :label="item.adv_name" :value="item.adv_id"></el-option>
          </el-select>
          <el-input v-model="hotel_name" style="width:400px;" placeholder="请输入内容"></el-input>
          <el-button type="primary" @click="queryTableList">查询</el-button>
        </div>
      </div>
      <div class="tableBox">  
        <el-table ref="singleTable" :data="contentTableData" style="width: 100%" :header-cell-style="headerStyle">
          <el-table-column min-width="100" v-show="btnShow">
            <template slot-scope="scope">
              <el-switch class="tableScopeSwitch" v-model="scope.row.status_boolean_text" active-color="red" active-text="驳回" inactive-color="#DCDCDC" @change="switchChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="酒店名称" min-width="300">
             <template slot-scope="scope">
             <span>{{scope.row.hotel_name}}</span>
            </template>
             </el-table-column>
          <el-table-column label="品牌">
             <template slot-scope="scope">
             <span>{{scope.row.hotel_brand}}</span>
            </template>
          </el-table-column>
          <el-table-column label="省份">
             <template slot-scope="scope">
                 <span >{{scope.row.province}}</span>
            </template>
          </el-table-column>
          <el-table-column label="城市">
             <template slot-scope="scope">
             <span>{{scope.row.city}}</span>
            </template>
             </el-table-column>
          <el-table-column label="区县">
             <template slot-scope="scope">
             <span >{{scope.row.county}}</span>
            </template>
          </el-table-column>
          <el-table-column label="详细地址" show-overflow-tooltip>
             <template slot-scope="scope">
             <span >{{scope.row.address}}</span>
            </template>
             </el-table-column>
          <el-table-column label="客房数">
             <template slot-scope="scope">
             <span>{{scope.row.room_num}}</span>
            </template>
          </el-table-column>
          <el-table-column label="激活终端数">
              <template slot-scope="scope">
             <span>{{scope.row.active_terminal_num}}</span>
            </template>
          </el-table-column>
          <el-table-column label="投放刊位" min-width="260">
              <template slot-scope="scope">
             <span>{{scope.row.adv_name}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import qs from "querystring";
import {
  getreviewList,
  passCheckAdAdFrequency,
  getReviewAdvList
} from "@/api/advertisement.js";
export default {
  name: "IndexDoVisual",
  data: () => ({
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/indexDoMore" },
    },
    breadTaskToDo: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/taskToDo" },
    },
    breadTaskDone: {
      level: 4,
      thirdPath: { path: "/taskDone" },
    },
    table: {
      page: 1,
      limit: 5,
      total: 0,
    },
    hotel_name:"",
    adv_id: "",
    tableData: [],
    contentTableData: [],
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    query: {},
    returnList:[],
    curPage: "",
    btnShow: false,
    selectList:[]
  }),
  methods: {
    // 返回
    backMeth() {
      this.$router.push({ path: `./${this.query.fromUrl}` });
    },
    queryTableList(){
      this.getTableList();
    },
    // 确认审核结果
    trueMeth() {
      const json = {
        review_id :this.query.id,
        refuse_ids:this.returnList.join(",")
      }
      var param = qs.stringify(json);
      passCheckAdAdFrequency(param).then(res=>{
        if(res.data.code==100000){
          this.$message.success("审核结果提交成功")
          this.backMeth();
        }else{
           this.$message.warning("审核结果提交失败")
        }
        console.log(res);
      }).catch(err=>{
         this.$message.warning("审核结果提交失败")
        console.log(err);
      })
    },
    getSelectionList(){
      const json = {
        review_id:this.query.id,
      }
      var param = qs.stringify(json);
      getReviewAdvList(param).then(res=>{
        if(res.data.code==100000){
          this.selectList = res.data.data;
        }
        console.log("xuanze",res);
      }).catch(err=>{
        console.log(err);
      })
    }, 
    // 分页
    getTableList(){
      const json = {
        page:this.table.page,
        limit:this.table.limit,
        review_id:this.query.id,
        adv_id:this.adv_id,
        hotel_name:this.hotel_name
      }
      var parma = qs.stringify(json);
      getreviewList(parma).then(res=>{
        console.log(res);
          this.tableData=[];
        if(res.data.code==100000){
          if (res.data.data.header) {  
            this.tableData.push(res.data.data.header);
          }
          this.contentTableData = res.data.data.list;
            this.returnList.forEach(element => {
          console.log(element);
          this.contentTableData.forEach(item=>{
            if(item.id == element){
              item.status_boolean_text= true;
              console.log("合规条目",item);
            }
          })
        });
          console.log(this.returnList);
          this.table.total = res.data.data.total;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    handleSizeChange(limit) {
      this.table.limit = limit;
      this.getTableList();
      
    },
    handleCurrentChange(page) {
      this.table.page = page;
      this.getTableList();
     
    },
    // 开关
    switchChange(val) {
      if(val.status_boolean_text){
        this.returnList.push(val.id)
      }else{
        var index = this.returnList.indexOf(val.id);
        this.returnList.splice(index,1)
      }
      console.log( this.returnList);
    },
  },
  mounted() {
    var query = this.$route.query;
    this.query = query;
    this.curPage = sessionStorage.getItem("defaultActive");
    this.token = sessionStorage.getItem("token");
    if (query.reviewStatus === "待审核") {
      this.btnShow = true;
    } else {
      this.btnShow = false;
    }
    this.getTableList();
    this.getSelectionList();
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  margin-top: 20px;
}
.titleAddSearch {
  padding-left: 0;
  border-left: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleAddSearch span {
  padding-left: 10px;
  border-left: 3px solid #909ca7;
}
.inputRightBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputRightBox .el-button {
  margin-left: 20px;
  width: 100px;
  height: 40px;
}
.inputRightBox .el-select {
  margin-right: 20px;
}
.tableScopeSwitch {
  margin-left: 10px;
}
.tableScopeSwitch ::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
.tableScopeSwitch ::v-deep .el-switch__label--right {
  z-index: 1;
  right: 22px;
}
.tableScopeSwitch ::v-deep .el-switch__label.is-active {
  display: block;
}
.tableScopeSwitch.el-switch ::v-deep .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}
.btnRow {
  display: flex;
  justify-content: space-between;
}
</style>
