<template>
  <div class="page">
    <div class="content">
      <img class="pic" src="@/assets/noOpen.png" alt="">
      <span>业务待接入，敬请期待</span>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 20px;
  background: #fff;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
    span {
      font-size: 26px;
      color: #1e1f1c;
    }
  }
}
</style>