<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- <el-tab-pane label="全部" :name="String('all')"></el-tab-pane> -->
      <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.classify_name" :name="item.id" :disabled="loading||loading1"></el-tab-pane>
    </el-tabs>
    <!-- 标签组界面 -->
    <el-card class="box-card" v-loading='loading1'>
      <div class="title chartTitle blueTitle">标签组</div>
      <div class="picBox" v-if="labelGroupList.length==0">
        <img src="@/assets/noDataPic.png" alt="">
      </div>
      <div class="cardShowBox">
        <template v-for="(item,index) in labelGroupList">
          <el-tooltip class="item" :key="index" effect="dark" :content="item.label_name" placement="top" v-if="item.classify_id == activeName||activeName=='all'">
            <div @click="checkGroup(item,index)" :class="[label?'cardShow':'cardShow1',item.check?'cardCheck':'']">
              <span class="icon">
                <span class="iconOne" v-if="item.label_type_text=='阵地标签'||item.label_type_text=='业务标签,阵地标签'">阵</span>
                <span class="iconTwo" v-if="item.label_type_text=='业务标签'||item.label_type_text=='业务标签,阵地标签'">业</span>
              </span>
              {{item.label_name}}
            </div>
          </el-tooltip>
        </template>
      </div>
    </el-card>
    <!-- 标签组界面 -->
    <!-- 标签界面 -->
    <el-card class="box-card" v-loading='loading'>
      <div class="title chartTitle blueTitle">标签</div>
      <div class="picBox" v-if="labelList.length==0">
        <img src="@/assets/noDataPic.png" alt="">
      </div>
      <div class="cardShowBox">
        <template v-for="(item,ind) in labelList">
          <el-tooltip class="item" :key="ind" effect="dark" :content="item.label_name" placement="top" v-if="item.classify_id == activeName||activeName=='all'">
            <div @click="checkLabel(item,ind)" :class="[label?'cardShow':'cardShow1',item.check?'cardCheck':'']">
              <span class="icon">
                <span class="iconOne" v-if="item.label_type_text=='阵地标签'||item.label_type_text=='业务标签,阵地标签'">阵</span>
                <span class="iconTwo" v-if="item.label_type_text=='业务标签'||item.label_type_text=='业务标签,阵地标签'">业</span>
              </span>
              {{item.label_name}}
            </div>
          </el-tooltip>
        </template>
      </div>
    </el-card>
    <div v-if="!label">
      <div style="margin:20px 0">
        已选标签
      </div>
      <div class="cardShowBox">
        <template v-for="(item,ind) in checkLabelText">
          <el-tooltip class="item" :key="ind" effect="dark" :content="item.label_name" placement="top">
            <div class="cardShow2">
              <span class="icon">
                <span class="iconOne" v-if="item.label_type_text=='阵地标签'||item.label_type_text=='业务标签,阵地标签'">阵</span>
                <span class="iconTwo" v-if="item.label_type_text=='业务标签'||item.label_type_text=='业务标签,阵地标签'">业</span>
              </span>
              {{item.label_name}}
            </div>
          </el-tooltip>
        </template>
      </div>
    </div>
    <div class="btn">
      <el-button type="primary" @click="submitLabel" v-if="label&&showFunModel('标签编辑')">保存</el-button>
    </div>
  </div>
</template>
<script>
import qs from "querystring";
import {
  getLabelOneHotel,
  getLabelList,
  getClassifyList,
  setLabelOneHotel,
} from "@/api/label.js";
export default {
  props: {
    id: {
      type: String,
      default: () => "",
    },
    hotel_contract: {
      type: String,
      default: () => "",
    },
    hotel_name: {
      type: String,
      default: () => "",
    },
    label: {
      type: Boolean,
      default: () => true,
    },
    funArr: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    funArr: {
      handler: function (newVal) {
        this.funArr = newVal;
      },
      deep: true,
      immediate: true,
    },
    id: {
      handler: function (newVal) {
        this.id = newVal;
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      auth: null,
      apaasData: null,
      activeName: "1",
      activeIndex: 0,
      tabList: [],
      labelGroupList: [],
      labelList: [],
      checkLabelGroupList: [],
      checkLabelList: [],
      loading: false,
      loading1: false,
      hotelLabel: [],
      hotelLabelGroup: [],
      checkLabelText: [],
      originalHotelLabelGroup: [],
      originalHotelLabel: [],
    };
  },
  created() {
    this.getData();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.apaasData = sessionStorage.getItem("apaasData");
  },
  mounted() {},
  computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    // 获取单酒店标签信息
    getHotelLabel() {
      this.checkLabelGroupList = [];
      this.checkLabelList = [];
      const json = {
        hotel_contract: this.hotel_contract,
      };
      var param = qs.stringify(json);
      getLabelOneHotel(param)
        .then((res) => {
          this.originalHotelLabel = JSON.parse(
            JSON.stringify(res.data.data.label)
          );
          this.originalHotelLabelGroup = JSON.parse(
            JSON.stringify(res.data.data.group_id)
          );
          this.hotelLabelGroup = JSON.parse(
            JSON.stringify(res.data.data.group_id)
          );
          this.hotelLabel = JSON.parse(JSON.stringify(res.data.data.label));
          this.labelList.forEach((i) => {
            this.hotelLabel.forEach((v, n) => {
              if (i.tag_name == v) {
                this.checkLabelList.push(JSON.parse(JSON.stringify(i)));
                this.hotelLabel.splice(n, 1);
              }
            });
          });
          this.labelGroupList.forEach((i) => {
            this.hotelLabelGroup.forEach((v, n) => {
              if (i.id == v) {
                this.checkLabelGroupList.push(JSON.parse(JSON.stringify(i)));
                this.hotelLabelGroup.splice(n, 1);
              }
            });
          });
          console.log(this.hotelLabelGroup);
          console.log(this.labelGroupList);
          this.loadPageData();
        })
        .catch((err) => {});
    },
    // 获取页面信息
    getData() {
      const json = {
        page: 1,
        limit: 99999,
      };
      var param = qs.stringify(json);
      getClassifyList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tabList = res.data.data.data;
            this.tabList.forEach((i) => {
              i.id = String(i.id);
            });
            this.activeName = this.tabList[0].id;
            this.activeIndex = 0;
            if (this.id) {
              this.getLabelGroupList();
              this.getLabelList();
              this.getHotelLabel();
            } else {
              this.getLabelGroupList();
              this.getLabelList();
            }
          }
        })
        .catch((err) => {});
    },
    // 获取标签组信息
    getLabelGroupList() {
      this.loading1 = true;
      const json = {
        page: 1,
        limit: 99999,
        need_num: 0,
        classify_id: this.activeName == "all" ? " " : this.activeName,
        is_group: 1,
        group_need_child: 1,
      };
      var param = qs.stringify(json);
      getLabelList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.labelGroupList = res.data.data.data;
            if (this.id) {
              this.labelGroupList.forEach((i) => {
                this.hotelLabelGroup.forEach((v, n) => {
                  if (i.id == v) {
                    this.checkLabelGroupList.push(
                      JSON.parse(JSON.stringify(i))
                    );
                    this.hotelLabelGroup.splice(n, 1);
                  }
                });
              });
            }
            this.loadPageData();
            this.loading1 = false;
          }
        })
        .catch((err) => {});
    },
    // 获取标签信息
    getLabelList() {
      this.loading = true;
      const json = {
        page: 1,
        limit: 99999,
        need_num: 0,
        classify_id: this.activeName == "all" ? " " : this.activeName,
        is_group: 0,
      };
      var param = qs.stringify(json);
      getLabelList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.labelList = res.data.data.data;
            if (this.id) {
              this.labelList.forEach((i) => {
                this.hotelLabel.forEach((v, n) => {
                  if (i.tag_name == v) {
                    this.checkLabelList.push(JSON.parse(JSON.stringify(i)));
                    this.hotelLabel.splice(n, 1);
                  }
                });
              });
            }

            this.loadPageData();
            this.loading = false;
          }
        })
        .catch((err) => {});
    },
    // 更新 选中信息
    loadPageData() {
      this.$nextTick(function () {
        this.labelList.forEach((i, n) => {
          this.checkLabelList.forEach((t, m) => {
            if (i.id == t.id) {
              this.$set(this.labelList[n], "check", true);
            }
          });
        });
        this.labelGroupList.forEach((i, n) => {
          this.checkLabelGroupList.forEach((t, m) => {
            if (i.id == t.id) {
              this.$set(this.labelGroupList[n], "check", true);
            }
          });
        });
      });
    },
    // tab 切换
    handleClick() {
      this.getLabelGroupList();
      this.getLabelList();
    },
    // 标签组相关逻辑
    groupMeth(item, index) {
      if (this.activeName == "1") {
        if (this.showFunModel("广告标签编辑")) {
          this.$set(
            this.labelGroupList[index],
            "check",
            !this.labelGroupList[index].check
          );
          if (this.labelGroupList[index].check) {
            this.checkLabelGroupList = this.checkLabelGroupList.filter((i) => {
              if (i.classify_id != 1) {
                return i;
              }
            });
            this.checkLabelList = this.checkLabelList.filter((i) => {
              if (i.classify_id != 1) {
                return i;
              }
            });
            this.labelList = this.labelList.map((i) => {
              if (i.classify_id == 1) {
                this.$set(i, "check", false);
                return i;
              } else {
                return i;
              }
            });
            this.labelGroupList = this.labelGroupList.map((i) => {
              if (i.classify_id == 1) {
                this.$set(i, "check", false);
                return i;
              } else {
                return i;
              }
            });
            this.$set(this.labelGroupList[index], "check", true);
            var obj = JSON.parse(JSON.stringify(this.labelGroupList[index]));
            this.checkLabelGroupList.push(obj);
            var list = obj.child.map((itm) => {
              return itm.id;
            });
            var list1 = this.checkLabelList.map((itm) => {
              return itm.id;
            });
            for (var j = 0; j < list.length; j++) {
              if (list1.indexOf(list[j]) === -1) {
                this.labelList.forEach((k, m) => {
                  if (k.id == list[j]) {
                    this.checkLabelList.push(
                      JSON.parse(JSON.stringify(this.labelList[m]))
                    );
                  }
                });
              }
            }
            this.labelList.forEach((itm, idx) => {
              this.checkLabelList.forEach((itm1, idx1) => {
                if (itm.id == itm1.id) {
                  this.$set(this.labelList[idx], "check", true);
                }
              });
            });
          } else {
            this.checkLabelGroupList = this.checkLabelGroupList.filter((i) => {
              if (i.classify_id != 1) {
                return i;
              }
            });
            this.checkLabelList = this.checkLabelList.filter((i) => {
              if (i.classify_id != 1) {
                return i;
              }
            });
            this.labelList = this.labelList.map((i) => {
              if (i.classify_id == 1) {
                this.$set(i, "check", false);
                return i;
              } else {
                return i;
              }
            });
            this.labelGroupList = this.labelGroupList.map((i) => {
              if (i.classify_id == 1) {
                this.$set(i, "check", false);
                return i;
              } else {
                return i;
              }
            });
          }
        } else {
          return;
        }
      } else {
        this.$set(
          this.labelGroupList[index],
          "check",
          !this.labelGroupList[index].check
        );
        if (this.labelGroupList[index].check) {
          var obj = JSON.parse(JSON.stringify(this.labelGroupList[index]));
          var list = obj.child.map((itm) => {
            return itm.id;
          });
          var list1 = this.checkLabelList.map((itm) => {
            return itm.id;
          });
          for (var j = 0; j < list.length; j++) {
            if (list1.indexOf(list[j]) === -1) {
              this.labelList.forEach((k, m) => {
                if (k.id == list[j]) {
                  this.checkLabelList.push(
                    JSON.parse(JSON.stringify(this.labelList[m]))
                  );
                }
              });
            }
          }
          this.labelList.forEach((itm, idx) => {
            this.checkLabelList.forEach((itm1, idx1) => {
              if (itm.id == itm1.id) {
                this.$set(this.labelList[idx], "check", true);
              }
            });
          });
          this.labelGroupList.forEach((itm, idx) => {
            var arr = itm.child.map((itm1) => {
              return itm1.id;
            });
            var arr1 = this.checkLabelList.map((itm2) => {
              return itm2.id;
            });
            let newList = [];
            arr.forEach((i) => {
              if (arr1.indexOf(i) !== -1) {
                newList.push(i);
              }
            });
            if (newList.length == arr.length) {
              this.$set(itm, "check", true);
              var obj = JSON.parse(JSON.stringify(itm));
              var checkList = this.checkLabelGroupList.map((i) => {
                return i.id;
              });
              if (checkList.indexOf(obj.id) == -1) {
                this.checkLabelGroupList.push(obj);
              }
            } else {
              this.$set(itm, "check", false);
            }
          });
        } else {
          this.checkLabelGroupList.forEach((itm, idx) => {
            if (itm.id == this.labelGroupList[index].id) {
              let obj = JSON.parse(
                JSON.stringify(this.checkLabelGroupList[idx])
              );
              this.checkLabelGroupList.splice(idx, 1);
              obj.child.forEach((itm1) => {
                this.checkLabelList.forEach((itm2, idx2) => {
                  if (itm1.id == itm2.id) {
                    this.checkLabelList.splice(idx2, 1);
                  }
                });
              });
              var list = this.checkLabelList.map((itm) => {
                return itm.id;
              });
              var list1 = this.labelList.map((itm) => {
                return itm.id;
              });
              for (var i = 0; i < list1.length; i++) {
                if (list.indexOf(list1[i]) === -1) {
                  this.$set(this.labelList[i], "check", false);
                }
              }
            }
          });
          this.labelGroupList.forEach((itm, idx) => {
            var arr = itm.child.map((itm1) => {
              return itm1.id;
            });
            var arr1 = this.checkLabelList.map((itm2) => {
              return itm2.id;
            });
            let newList = [];
            arr.forEach((i) => {
              if (arr1.indexOf(i) !== -1) {
                newList.push(i);
              }
            });
            if (newList.length == arr.length) {
              this.$set(itm, "check", true);
            } else {
              this.$set(itm, "check", false);
              this.checkLabelGroupList.forEach((k, l) => {
                if (k.id == itm.id) {
                  this.checkLabelGroupList.splice(l, 1);
                }
              });
            }
          });
        }
      }
      if (this.activeName == "1") {
        // 广告标签组添加
        if (this.labelGroupList[index].check == true) {
          // 广告标签是单选，要把之前选的广告标签清掉
          for (var i = 0; i < this.checkLabelText.length; i++) {
            if (this.checkLabelText[i].classify_id == 1) {
              this.checkLabelText.splice(i, 1);
            }
          }
          this.checkLabelText.push(this.labelGroupList[index]);
          // 广告标签组删除
        } else {
          for (var i = 0; i <= this.checkLabelText.length; i++) {
            if (
              this.checkLabelText[i].tag_name ==
              this.labelGroupList[index].tag_name
            ) {
              this.checkLabelText.splice(i, 1);
            }
          }
        }
      } else {
        // 其他标签组添加
        if (this.labelGroupList[index].check == true) {
          for (var i = 0; i < this.labelGroupList[index].child.length; i++) {
            this.checkLabelText.push(this.labelGroupList[index].child[i]);
          }
          // 其他标签组删除
        } else {
          for (var i = 0; i < this.checkLabelText.length; i++) {
            for (var j = 0; j < this.labelGroupList[index].child.length; j++) {
              if (
                this.checkLabelText[i].tag_name ==
                this.labelGroupList[index].child[j].tag_name
              ) {
                this.checkLabelText.splice(i, 1);
              }
            }
          }
        }
      }
      console.log(this.checkLabelText);
    },
    // 选中标签组
    checkGroup(item, index) {
      if (this.id) {
        if (this.showFunModel("标签编辑")) {
          this.groupMeth(item, index);
        } else {
          return;
        }
      } else {
        this.groupMeth(item, index);
      }
    },
    // 标签相关逻辑
    labelMeth(item, index) {
      if (this.activeName == "1") {
        return;
      } else {
        this.$set(this.labelList[index], "check", !this.labelList[index].check);
        if (this.labelList[index].check) {
          this.checkLabelList.push(
            JSON.parse(JSON.stringify(this.labelList[index]))
          );
          this.labelGroupList.forEach((itm, idx) => {
            var arr = itm.child.map((itm1) => {
              return itm1.id;
            });
            var arr1 = this.checkLabelList.map((itm2) => {
              return itm2.id;
            });
            let newList = [];
            arr.forEach((i) => {
              if (arr1.indexOf(i) !== -1) {
                newList.push(i);
              }
            });
            if (newList.length == arr.length) {
              var checkarr = this.checkLabelGroupList.map((i) => {
                return i.id;
              });
              if (checkarr.indexOf(itm.id) == -1) {
                this.checkLabelGroupList.push(JSON.parse(JSON.stringify(itm)));
                this.$set(itm, "check", true);
              }
            }
          });
        } else {
          var obj = this.labelList[index];
          this.checkLabelList.forEach((itm, idx) => {
            if (this.labelList[index].id == itm.id) {
              this.checkLabelList.splice(idx, 1);
            }
          });
          this.labelGroupList.forEach((itm, idx) => {
            var arr = itm.child.map((itm1) => {
              return itm1.id;
            });
            var arr1 = this.checkLabelList.map((itm2) => {
              return itm2.id;
            });
            let newList = [];
            arr.forEach((i) => {
              if (arr1.indexOf(i) !== -1) {
                newList.push(i);
              }
            });
            if (newList.length == arr.length) {
              var checkarr = this.checkLabelGroupList.map((i) => {
                return i.id;
              });
              if (checkarr.indexOf(itm.id) == -1) {
                this.checkLabelGroupList.push(JSON.parse(JSON.stringify(itm)));
                this.$set(itm, "check", true);
              }
            } else {
              var checkarr = this.checkLabelGroupList.map((i) => {
                return i.id;
              });
              if (checkarr.indexOf(itm.id) !== -1) {
                this.checkLabelGroupList.forEach((i, n) => {
                  if (i.id == itm.id) {
                    this.checkLabelGroupList.splice(n, 1);
                  }
                });
                this.labelGroupList.forEach((i, n) => {
                  if (i.id == itm.id) {
                    this.$set(this.labelGroupList[n], "check", false);
                  }
                });
              }
            }
          });
        }
      }
      // 标签已选
      if (this.labelList[index].check == true) {
        this.checkLabelText.push(this.labelList[index]);
      } else {
        for (var i = 0; i <= this.labelList.length + 1; i++) {
          if (
            this.checkLabelText[i].tag_name == this.labelList[index].tag_name
          ) {
            this.checkLabelText.splice(i, 1);
          }
        }
      }
      console.log(this.checkLabelText);
    },
    // 选中标签
    checkLabel(item, index) {
      if (this.id) {
        if (this.showFunModel("标签编辑")) {
          this.labelMeth(item, index);
        } else {
          return;
        }
      } else {
        this.labelMeth(item, index);
      }
    },
    // 提交
    submitLabel() {
      this.$confirm("是否确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 获取增加的标签名
          var label = this.checkLabelList.map((i) => {
            return i.tag_name;
          });
          label = [...label, ...this.hotelLabel];
          var LabelList = [];
          var addLabelList = [];
          for (var i in this.originalHotelLabel) {
            LabelList[this.originalHotelLabel[i]] = true;
          }
          for (var k in label) {
            if (!LabelList[label[k]]) {
              addLabelList.push(label[k]);
            }
          }
          // 获取下架的标签名
          LabelList = [];
          var subLabelList = [];
          subLabelList = this.originalHotelLabel.filter(
            (item1) => !label.includes(item1)
          );
          console.log(addLabelList);
          console.log(subLabelList);
          // 获取增加的标签组id
          var labelGroup = this.checkLabelGroupList.map((i) => {
            return i.id;
          });
          labelGroup = [...labelGroup, ...this.hotelLabelGroup];
          var LabelGroupList = [];
          var addLabelGroupList = [];
          for (var i in this.originalHotelLabelGroup) {
            LabelGroupList[this.originalHotelLabelGroup[i]] = true;
          }
          for (var k in labelGroup) {
            if (!LabelGroupList[labelGroup[k]]) {
              addLabelGroupList.push(labelGroup[k]);
            }
          }
          // 获取下架的标签组id
          LabelGroupList = [];
          var subLabelGroupList = [];
          subLabelGroupList = this.originalHotelLabelGroup.filter(
            (item1) => !labelGroup.includes(item1)
          );
          console.log(addLabelGroupList);
          console.log(subLabelGroupList);
          let json = {
            hotel_contract: this.hotel_contract,
            hotel_name: this.hotel_name,
            event: "",
            adv: label.length == 0 ? "" : label.join(","),
          };
          if (addLabelList.length) {
            json.adv_add = addLabelList.join(",");
            console.log(json.event == "");
            json.event == ""
              ? (json.event = "adv_add")
              : (json.event = json.event + ",adv_add");
          }
          if (subLabelList.length) {
            json.adv_del = subLabelList.join(",");
            json.event == ""
              ? (json.event = "adv_del")
              : (json.event = json.event + ",adv_del");
          }
          if (addLabelGroupList.length) {
            json.group_add = addLabelGroupList.join(",");
            json.event == ""
              ? (json.event = "group_add")
              : (json.event = json.event + ",group_add");
          }
          if (subLabelGroupList.length) {
            json.group_del = subLabelGroupList.join(",");
            json.event == ""
              ? (json.event = "group_del")
              : (json.event = json.event + ",group_del");
          }
          console.log(111);
          let itemData = JSON.parse(sessionStorage.getItem("hoteliitem"));
          console.log();
          json.groupId = itemData.res.data.data.groupId;
          json.user_name = this.auth.user_name;
          if (this.apaasData) {
            json.isApaas = 1;
          }
          var param = qs.stringify(json);
          setLabelOneHotel(param)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("操作成功");
                // 原始数据设为新数据
                this.originalHotelLabelGroup = labelGroup;
                this.originalHotelLabel = label;
                json = {};
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 抛出选中数据
    sendCheckList() {
      var tag_name = "";
      var tag_num = 0;
      for (var i = 0; i < this.checkLabelList.length; i++) {
        tag_name += this.checkLabelList[i].tag_name + ",";
      }
      tag_num = this.checkLabelList.length;
      tag_name = tag_name.substring(0, tag_name.length - 1);
      var group_name = "";
      var group_num = 0;
      for (var j = 0; j < this.checkLabelGroupList.length; j++) {
        group_name += this.checkLabelGroupList[j].label_name + ",";
      }
      group_num = this.checkLabelGroupList.length;
      group_name = group_name.substring(0, group_name.length - 1);
      this.$emit("senddata", tag_name, tag_num, group_name, group_num);
    },
    // 抛出选中数据
    recetCheckList() {
      this.checkLabelList = [];
      this.checkLabelGroupList = [];
      this.checkLabelText = [];
      this.labelGroupList.forEach((i, n) => {
        this.$set(i, "check", false);
      });
      this.labelList.forEach((i, n) => {
        this.$set(i, "check", false);
      });
      var tag_name = "";
      for (var i = 0; i < this.checkLabelList.length; i++) {
        tag_name += this.checkLabelList[i].tag_name + ",";
      }
      tag_name = tag_name.substring(0, tag_name.length - 1);
      this.$emit("senddata", tag_name);
    },
  },
};
</script>
<style scoped>
.cardShowBox {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.cardShow {
  position: relative;
  width: calc(100% / 4 - 1%);
  text-align: center;
  padding: 8px 20px 8px 50px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  background: #f1f8ff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}
.cardShow1 {
  position: relative;
  width: calc(100% / 6 - 1%);
  text-align: center;
  padding: 8px 20px 8px 50px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  background: #f1f8ff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}
.cardShow2 {
  position: relative;
  width: calc(100% / 6 - 1%);
  text-align: center;
  padding: 8px 20px 8px 50px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}
.cardCheck {
  background: #3688f8;
  color: #fff;
  font-size: 14px;
  border: 1px solid #3688f8;
}
.el-card {
  box-shadow: none;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.btn .el-button {
  width: 100px;
  height: 40px;
}
::v-deep .el-tabs__active-bar {
  min-width: 28px !important;
}
.icon {
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.iconOne,
.iconTwo {
  height: 22px;
  width: 22px;
  border-radius: 11px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  background: #6cd431;
  color: #fff;
}
.iconTwo {
  background: #ffac0f;
}
.picBox {
  text-align: center;
}
.picBox img {
  height: 150px;
}

::v-deep .el-tabs__nav-scroll {
  position: relative;
  height: 40px;
}
::v-deep .el-tabs__nav {
  position: absolute;
  width: auto;
}
.label_text {
  font-size: 14px;
  line-height: 16px;
}
</style>

.cardShowBox {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.cardShow {
  position: relative;
  width: calc(100% / 4 - 1%);
  text-align: center;
  padding: 8px 20px 8px 50px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  background: #f1f8ff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}
.cardShow1 {
  position: relative;
  width: calc(100% / 6 - 1%);
  text-align: center;
  padding: 8px 20px 8px 50px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  background: #f1f8ff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}
.cardShow2 {
  position: relative;
  width: calc(100% / 6 - 1%);
  text-align: center;
  padding: 8px 20px 8px 50px;
  margin: 0 0.5% 1%;
  box-sizing: border-box;
  border: 1px solid #3688f8;
  color: #3688f8;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}
.cardCheck {
  background: #3688f8;
  color: #fff;
  font-size: 14px;
  border: 1px solid #3688f8;
}
.el-card {
  box-shadow: none;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.btn .el-button {
  width: 100px;
  height: 40px;
}
::v-deep .el-tabs__active-bar {
  min-width: 28px !important;
}
.icon {
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.iconOne,
.iconTwo {
  height: 22px;
  width: 22px;
  border-radius: 11px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  background: #6cd431;
  color: #fff;
}
.iconTwo {
  background: #ffac0f;
}
.picBox {
  text-align: center;
}
.picBox img {
  height: 150px;
}

::v-deep .el-tabs__nav-scroll {
  position: relative;
  height: 40px;
}
::v-deep .el-tabs__nav {
  position: absolute;
  width: auto;
}
.label_text {
  font-size: 14px;
  line-height: 16px;
}
</style>