<template>

  <div :class="['box',showData?'':'box1']" :style="{width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px',zIndex:style.zIndex}">
    <div class="borderBox"></div>
    <visiualButton class="hide" @openmore='openUppage' @recoverydata="recoverydata" btnShow='picmore'></visiualButton>
    <uploadSourceMore ref="uploadSourceMore" sourceType="pic" @upsuccessmore="upSuccessMore"></uploadSourceMore>

    <el-carousel v-if="showData" :height="style.height+'px'">
      <el-carousel-item v-for="(item,index) in showData" :key="index">
        <img :src="item.source_url" alt="" :style="is_logo? {maxWidth: '100%', maxHeight: '100%'}: {width: '100%', height: '100%'}">
      </el-carousel-item>
    </el-carousel>

    <el-dialog title="修改图片内容" :visible.sync="dialogVisible" :append-to-body='true' width="1200px" :before-close="handleClose">

      <div class="root" style="margin-bottom:50px;">
        <div class="btnMore" @click="showUploadMore" v-if="!isEdit">配置</div>
        <img :src="getCurImgUrl" alt="" v-if="showDataList.length>0">
        <div class="noDataOut" v-if="showDataList.length===0">
          <no-data v-if="showDataList.length===0"><span slot="tips">暂无数据</span></no-data>
        </div>
        <div class="rightBox">
          <div class="topArrow arrow">
            <div class="el-icon-caret-top"></div>
          </div>
          <div class="imgBox">
            <draggable class="draggableBox" group="article" :value="showDataList" animation="300" @input="handleListChange($event)">
              <div class="rightImgItem" :class="{active:curImgIndex===index}" :style="{'backgroundImage':'url('+item.source_url+')'}" :key="index" v-for="(item,index) in showDataList" @click.stop="showCurImg(index)">
                <div class="delIcon">
                  <i class="el-icon-close" v-if="!isEdit" @click.stop="delMeth(item.source_url)"></i>
                </div>
              </div>
            </draggable>
          </div>
          <div class="topArrow arrow">
            <div class="el-icon-caret-bottom"></div>
          </div>
        </div>

      </div>

      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button type="primary" @click="saveTrue">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>

      </span>
    </el-dialog>
  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";
import Draggable from "vuedraggable";
import uploadSourceMore from "../common/uploadSourceMore.vue";
export default {
  name: "picListBox",
  components: {
    visiualButton,
    uploadSourceMore,
    Draggable,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => {},
    },
    historyData: {
      type: Array,
      default: () => {},
    },
    is_logo: {
      type: Boolean,
      default: false,
    },
        isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    getCurImgUrl() {
      var str = "";
      if (this.showDataList) {
        if (this.showDataList.length > 0) {
          str = this.showDataList[this.curImgIndex].source_url;
        }
      }
      return str;
    },
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        this.content = JSON.parse(JSON.stringify(newVal));
        let obj = JSON.parse(JSON.stringify(this.content.position));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      style: {},
      content: {},
      showData: this.value,
      dialogVisible: false,
      curImgIndex: 0,
      showDataList: [],
      showContent: "",
      lid: "",
    };
  },
  mounted() {
    this.showDataList = this.showData;
    this.showContent = this.showDataList[0].content;
    this.lid = this.showDataList[0].lid;
  },
  methods: {
    saveTrue() {
      this.showData = this.showDataList;
      this.dialogVisible = false;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
    },
    handleClose() {
      this.showDataList = this.showData;
      this.dialogVisible = false;
    },
    delMeth(val) {
      this.showDataList.filter((item, index) => {
        if (item.source_url === val) {
          var appointIndex = this.showDataList.indexOf(item);
          this.showDataList.splice(appointIndex, 1);
        }
      });
      this.sortMeth();
    },
    showCurImg(val) {
      this.curImgIndex = val;
    },
    handleListChange(event) {
      this.showDataList = event;
      this.sortMeth();
    },
    sortMeth() {
      var order = 0;
      this.showDataList.forEach((item, index) => {
        order++;
        item.source_sort = order;
        item.update = true;
      });
    },
    upSuccessMore(data) {
      let obj = {
        update: true,
        source_md5: data.md5,
        source_sort: 999,
        source_type: "1",
        source_url: data.url,
        content: this.showContent,
        lid: this.lid,
      };
      this.showDataList.unshift(obj);
      this.sortMeth();

      // showData:this.value,
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
    },
    showUploadMore() {
      this.$refs.uploadSourceMore.dialogVisible = true;
    },
    openUppage() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.btnMore {
  height: 30px;
  width: 80px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  cursor: pointer;
  transition: 0.3s;
  position: absolute;
  top: -40px;
  left: 0;
}
.btnMore:hover {
  /* background: #4093ff; */
  background: rgba(60, 138, 255, 0.8);
}
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  z-index: 4;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
.root {
  margin-top: 60px;
  width: 1152px;
  height: 648px;
  position: relative;
  border: 1px solid #ddd;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }
}
.sortPicBox {
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
}
.rightBox {
  width: 200px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  .title {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  img {
    width: auto;
    height: 100%;
    /*width:170px;*/
    /*height:94px;*/
    object-fit: cover;
    :last-child {
      margin-bottom: 0;
    }
  }
}
ul {
  padding: 0;
  width: 400px;
}
li {
  width: 100px;
  float: left;
}
.draggableBox {
  height: 100%;
}
.noDataOut {
  width: calc(100% - 200px);
  height: 100%;
  /* background: red; */
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.triangleUp {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid red;
}
.arrow {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow > div {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9d99b0;
}
.centerEl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploadBox {
  width: 952px;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
/*swiper开始*/
.swiperBox {
  height: 520px;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}
.swiper-slide {
  background-size: cover;
}
.delIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}
.relativeEl {
  position: relative;
  height: 100%;
}

.scrollBox {
  width: 100%;
}

.rightImgItem {
  width: 90%;
  height: 19.6%;
  margin: 0 auto 2% auto;
  position: relative;
  box-sizing: content-box;
  border: 3px solid transparent;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat !important;
}
.rightImgItem.active {
  border: 3px solid #ffe699;
}
.imgBox {
  flex: 1;
  overflow: auto;
}
</style>