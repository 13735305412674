<template>
    <div class="grid-content bg-purple chartCon">
        <div class="chartTitle">数据走势</div>
        <div id="barChart"  class="chart"></div>
    </div>
</template>

<script>
    import echarts from 'echarts'

    export default {
        name: "BarChart",
        props:['chart'],
        data:()=>({

        }),
        mounted(){
            var chart = echarts.init(document.getElementById('barChart'));
            var barChart=this.chart;
            var option = {
                legend: {
                    data: barChart.legend,
                    x:'center',
                    y:'bottom',
                    icon:'circle'
                },
                calculable: true,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        data: barChart.xAxis
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#ebeef5'
                            }
                        },
                    }
                ],
                series: [
                    {
                        name: '计划量',
                        type: 'bar',
                        barWidth : 20,
                        itemStyle: {
                            normal: {
                                color: '#9d7bf7', //改变折线点的颜色
                                lineStyle: {
                                    color: '#9d7bf7' //改变折线颜色
                                }
                            }
                        },
                        data: barChart.series['计划量']
                    },
                    {
                        name: '完成量',
                        type: 'bar',
                        barWidth : 20,
                        itemStyle: {
                            normal: {
                                color: '#36dfc0', //改变折线点的颜色
                                lineStyle: {
                                    color: '#36dfc0' //改变折线颜色
                                }
                            }
                        },
                        data: barChart.series['完成量']
                    }
                ]
            };
            chart.setOption(option,true);
        }
    }
</script>

<style scoped lang="scss">

    .grid-content {
        height:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
    /*padding:15px 20px;*/
    .leftBox{
        display:flex;
        align-items: center;
    }
    .numTitle{
        width:150px;
    }
    .num{
        font-size:24px;
        margin-right:10px;
    }
    }
    .bg-purple {
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        /*padding:20px 20px;*/
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .chartBox{
        height:500px;
        margin-bottom:20px!important;
        overflow:hidden;
    }
    .grid-content{
        box-sizing:border-box;
    }
    .el-col{
        height:100%;
    .grid-content{
        height:100%;
    }
    }

    .chartCon{
        height:100%;
        display:flex;
        flex-direction:column;
    }
    #chart,.chart{
        width:100%;
        /*height:300px;*/
        flex:1;
    }

</style>
