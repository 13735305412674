<template>
    <el-dialog
            title="提示"
            :visible.sync="confirmDialog.dialogVisible"
            :close-on-click-modal="false"
            width="30%">
        <div class="flexBox">
            <div class="icon">
                <i class="el-icon-warning warningIcon"></i>
            </div>
            <div class="text">
                <p class="firstTips">请及时上线系统！</p>
                <p class="secondTips">如{{implementDay}}天内上线系统终端不足{{implementNum}}台，该酒店后台将自动删除！</p>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="confirmDialog.dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="createMeth">确 定</el-button>
  </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "ConfirmDialog",
        data(){
            return {
                confirmDialog:{
                    dialogVisible:false,
                },
                implementDay:'',
                implementNum:'',
                token:'',
            }
        },
        methods:{
            showDialog(val){
                this.confirmDialog.dialogVisible=val;
            },
            createMeth(){
                this.$emit('createMeth');
                this.showDialog(false);
            },
            getImplementDayAndNum() {
                this.$http.get(this.global.getImplementDayAndNum, {
                    params: {
                        token: this.token,
                    }
                }).then(res => {
                    if (res.data.code === 100000) {
                        var resData=res.data.data;
                       this.implementDay=resData.implementDay;
                       this.implementNum=resData.implementNum;
                    }
                })
            },
        },
        mounted(){
            this.token = sessionStorage.getItem('token');
            this.getImplementDayAndNum();
        }
    }
</script>

<style scoped>
    .firstTips{
        font-size:16px;
        margin-bottom:15px;
    }
    .flexBox{
        display:flex;
        align-items: center;
    }
    .warningIcon{
        color:#e6a23c;
        font-size:24px;
        margin-right:20px;
    }

</style>
