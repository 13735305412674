<template>
    <div>
        <el-col :span="12" class="whiteItem">
            <div class="grid-content bg-purple">
                <div class="leftBox">
                    <div class="numTitle"><slot name="numTitle"></slot></div>
                    <div style="display:flex;align-items: flex-end">
                        <div class="num indexNum"><slot name="num"></slot></div>
                        <slot name="unit"></slot>
                    </div>
                </div>
                <div class="numTime"><slot name="date"></slot></div>
            </div>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: "TwoRow",
        data: () => ({})
    }
</script>

<style scoped lang="scss">
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        padding:20px 20px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .rowBox.horizontal {
        .grid-content {
            height:100%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            padding:15px 20px;
            .leftBox{
                display:flex;
                align-items: center;
            }
            .numTitle{
                width:150px;
            }
            .num{
                /*font-size:24px;*/
                margin-right:10px;
            }
        }
    }
</style>
