<template>
    <div>
        <el-col :span="span">
            <div class="grid-content bg-purple outBox">
                <div class="numTitle">
                    <el-tooltip v-if="toolText!==''" class="item pointer" effect="dark" :content="toolText" placement="right">
                        <slot name="numTitle" class="noWrap"></slot>
                    </el-tooltip>
                    <slot v-if="toolText===''" name="numTitle" class="noWrap"></slot>
                    <slot name="time" class="numTitle noWrap"  v-if="type!=='timeCenter'"></slot>
                </div>
                <div v-if="type==='timeCenter'" class="timeText" >
                    <slot name="time" class="noWrap"></slot>
                </div>
                <div class="bottomBox">
                    <div class="numBox">
                        <span class="indexNum marginRight">
                            <slot name="num" class="noWrap"></slot>
                        </span>
                        <span class="unit">
                            <slot name="unit" class="noWrap"></slot>
                        </span>
                    </div>
                    <i class="iconfont icon-wenjian indexIcon" :class="color" v-if="iconShow"></i>
                </div>
            </div>
        </el-col>
    </div>
</template>

<script>
    // greenColor orangeColor blueColor   redColor
    export default {
        name: "QuarterRow",
        props:{
            span:{
                default:6
            },
            toolText:{
                default:''
            },
            type:{
                default:'timeRight'
            },
            color:{},
            iconShow:{
                default:false
            },
            parShowDate:{},
            parShowUnit:{},
        },
        data: () => ({
            isShowUnit:false,
        }),
        methods:{

        },
        computed:{
            showDataCom(){
                return function(s,type='',n=2){
                    var stringNum=String(s);
                    var pointIndex=stringNum.indexOf('.');
                    var strLength=0;
                    var result=0;
                    if(pointIndex!==-1){  //有小数点
                        var noPointStr=String(s).substring(0,pointIndex);
                        strLength=noPointStr.length;
                    }else{
                        strLength=stringNum.length;
                    }
                    if(s===null){
                        return 0;
                    }else{
                        if(strLength>5){
                            this.isShowUnit=true;
                            result = Math.floor((s /10000) * 100) / 100; //保留小数点两位
                            return result;
                            // if(type==='money'){
                            //     result = Math.floor((s /10000) * 100) / 100; //保留小数点两位
                            //     console.log('是金钱',result);
                            //     return result;
                            // }else{
                            //     result = parseInt((s /10000) * 100 / 100); //取整
                            //     return parseFloat(String(result)).toLocaleString();
                            //
                            // }
                        }else{   //小于5
                            this.isShowUnit=false;
                            if(type==='money'){
                                n = n > 0 && n <= 20 ? n : 2;
                                s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                                var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                                var t = "";
                                for (var i = 0; i < l.length; i++) {
                                    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                                }
                                return t.split("").reverse().join("") + "." + r;
                            }else{
                                return parseFloat(s).toLocaleString();
                            }
                        }

                    }
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .el-col-5{
        width:20%!important;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .borderBox.rowBox .grid-content{
        /*border:1px solid #ddd;*/
    }

    .vertical{
        .grid-content{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            .numTitle{
                margin-bottom:20px;
                display:flex;
                justify-content: space-between;
                color:#a3a7af;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
        .bg-purple {
            /*background: #d3dce6;*/
            background: #fff;
        }
        .grid-content {
            border-radius: 4px;
            padding:20px 20px;
        }
    }
    .indexIcon{
        font-size:40px;
    }
    .indexIcon.green{
        color:#40c2ad;
    }
    .indexIcon.orange{
        color:#ff9846;
    }
    .indexIcon.blue{
        color:#445eee;
    }
    .indexIcon.red{
        color:#f12525;
    }
    .numBox{
        display:flex;
        align-items: flex-end;
    }
    .indexNum.marginRight{
        margin-right:10px;
        font-size: 28px;
    }
    .unit{
        margin-bottom:5px;
        color: #a3a7af;
        font-size:14px;
    }
    .bottomBox{
        display:flex;
        justify-content: space-between;
    }
    .outBox{
        box-shadow:0 0 11px 2px rgba(94,151,255,0.15);
    }
    .timeText{
        margin-left:-6px;
        margin-bottom:20px;
        font-size:12px;
        color:#a3a7af;
    }
    .noWrap{
        white-space:nowrap!important;
    }
    .pointer{
        cursor:pointer;
    }
    .numUnit{
        font-family: DIN!important;
        font-size:32px;
    }
</style>
