<script src="../../api/label.js"></script>
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second" v-if="!query">系统设置</span>
        <span slot="third" v-if="!query"> 标签管理</span>
        <span slot="fourth" v-if="!query"> 标签记录</span>
        <span slot="fiveth" v-if="!query"> 下架标签</span>
        <span slot="second"> 下架标签</span>
      </top-bread>
    </div>
    <el-card>
      <el-button type="primary" class="btn" @click="cancel" style="margin-bottom: 10px">取消</el-button>
      <div class="title" style="margin: 15px 0">第一步，选择标签</div>
      <label-show :label="labeladd" ref="labelshow" @senddata="getLabel" :funArr="funArr"></label-show>
      <div class="bottom_btn_box">
        <el-button type="primary" class="btn" @click="searchHotel">搜索</el-button>
        <el-button class="btn" @click="reset()">重置</el-button>
      </div>

    </el-card>
    <el-card style="margin-top: 10px">
      <div class="title" style="margin-bottom: 15px">第二步，确定酒店</div>

      <el-row :gutter="50" style="margin-top: 30px">
        <el-col :span="24">
          <!-- 右框 -->
          <div>
            <div class="right_box">
              <div class="right_text">
                <span>已选 &nbsp;&nbsp;<span style="color:#3688F8;">{{
                                    infoLength
                                }}</span>&nbsp;&nbsp;家酒店</span>
                <span style="float: right">
                  <el-link :underline="false" @click="clearHotel" type="primary" style="margin-right: 20px">清空</el-link>
                </span>
              </div>
              <div style="margin:10px 10px 15px 10px">
                <el-card v-if="showSearch">
                  <el-row :gutter="20" class="search_box" v-if="showSearch">
                    <el-col :span="7">
                      <el-select v-model="form.hotel_brand" multiple placeholder="集团品牌" class="hotelBrand fullSelect" filterable collapse-tags clearable>
                        <el-option v-for="item in topAttr.hotelGroupList" :key="item.id" :label="item.groupname" :value="item.groupname">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="3">
                      <el-select v-model="form.province" multiple placeholder="省份" @change="provinceChange" collapse-tags clearable class="fullSelect" filterable>
                        <el-option v-for="item in topAttr.province" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="3">
                      <el-select v-model="form.city" placeholder="城市" @change="cityChange($event,'city')" clearable class=" fullSelect" filterable collapse-tags>
                        <el-option v-for="item in topAttr.city" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="3">
                      <el-select v-model="form.country" placeholder="区县" @change="countyChange($event,'county')" clearable class=" fullSelect" filterable>
                        <el-option v-for="item in topAttr.country" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="7">
                      <el-input v-model="form.channelCode" placeholder="请输入渠道商名称"></el-input>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" style="margin-top: 10px" v-if="showSearch">
                    <el-col :span="7">
                      <el-input v-model="form.hotelContract" placeholder="请输入合同号"></el-input>
                    </el-col>
                    <el-col :span="9">
                      <el-input v-model="form.hotelName" placeholder="请输入酒店名称关键词"></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-button type="primary" class="btn" @click="searchHotelBottom" :disabled="selectTop">搜索
                      </el-button>
                      <el-button class="btn" @click="resetSearch" :disabled="selectTop">重置
                      </el-button>
                    </el-col>
                  </el-row>
                </el-card>
                <el-row>
                  <el-col>
                    <el-link :underline="false" type="primary" @click="showSearch=true" style="margin-top: 15px" v-if="!showSearch">显示高级选项
                    </el-link>
                    <el-link :underline="false" type="primary" @click="showSearch=false" style="margin-top: 15px" v-if="showSearch">隐藏高级选项
                    </el-link>
                  </el-col>
                </el-row>
              </div>
              <div class="infinite-list-wrapper" style="overflow:no-display">
                <ul class="rightlist" v-infinite-scroll="load" infinite-scroll-immediate="false" v-loading="listLoading">
                  <li class="list_right checkedLabelBox">
                    <template v-for="(item, index) in info">
                      <el-tooltip class="item" :key="index" effect="dark" placement="top" :content="item.hotelName">
                        <div class="checkedLabel checkedLabel1">{{ item.hotelName }} <span class="delBtn">| <i class="el-icon-close" @click="sub(item)"></i></span></div>
                      </el-tooltip>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="bottom_btn_box">
        <el-button type="primary" class="btn" @click="open">确定</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { labelToManyHotels, searchHotelsFromEs } from "@/api/label.js";
import qs from "querystring";

export default {
  name: "",
  props: [""],
  data: () => ({
    auth: null,
    query: null,
    funArr: [],
    allData: {},
    searchCount: 1,
    selectTop: true,
    showLoading: false,
    listLoading: false,
    searchNewInfoText: "",
    showSearch: false,
    labeladd: false,
    bread: {
      level: 5,
    },
    labelValue: {},
    form: {
      hotel_brand: "",
      citycode: [],
      province: "",
      city: "",
      country: "",
      provinceLabel: [],
      countyLabel: [],
      cityLabel: [],
      channelCode: "",
      hotelContract: "",
      hotelName: "",
    },
    region: {
      prov_code: "",
      city_code: "",
      country: "",
    },
    topAttr: {
      province: "",
      city: "",
      country: "",
      hotelGroupList: "",
    },
    info: [],
    deleteHotel: [],
    infoLength: 0,
    allLabel: false,
    labelNum: "",
    hotel_contract: "",
    hotel_name: "",
    searchInfo: [],
    showNew: [],
    searchHis: [],
    allInfo: [],
    subHotel: [],
    groupValue: "",
    groupNum: "",
    hotel_brand: "",
  }),
  methods: {
    //   获取权限传入labelshow组件
    getGroupLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.groupLevel, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.curId = res.data.data.pid[0];
            }
            resolve(res.data.data.pid[0]);
          });
      });
    },
    getTreeData() {
      this.$http
        .get(this.global.getUserRuleLists, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var treeData = res.data.data;
            this.allData = treeData;
            console.log("123");
            this.allData.forEach((item, index) => {
              var menuId = sessionStorage.getItem("defaultActiveId");
              if (item.parent == menuId) {
                this.funArr.push(item.text);
              }
            });
          }
        });
    },
    load() {
      this.$refs.labelshow.sendCheckList();
      this.listLoading = true;
      var hotel_brand = "";
      for (var i = 0; i < this.form.hotel_brand.length; i++) {
        hotel_brand += this.form.hotel_brand[i] + ",";
      }
      hotel_brand = hotel_brand.substring(0, hotel_brand.length - 1);
      this.hotel_brand = hotel_brand;
      const json = {
        tags: this.labelValue,
        hotel_contract: this.form.hotelContract,
        brand: this.hotel_brand,
        province: this.form.provinceLabel,
        city: this.form.cityLabel,
        district: this.form.countyLabel,
        hotel_name: this.form.hotelName,
        channelCode: this.form.channelCode,
        page: this.searchCount,
        limit: 90,
      };
      var param = qs.stringify(json);
      searchHotelsFromEs(param)
        .then((res) => {
          if (res.data.code == 100000) {
            var hotel = res.data.data.hotels;
            var select = { select: false };
            for (var i = 0; i < hotel.length; i++) {
              hotel[i].select = false;
            }
            this.info = this.info.concat(hotel);
            this.searchHis = this.searchHis.concat(hotel);
            this.infoLength = res.data.data.total;
            this.listLoading = false;
            this.searchCount = this.searchCount + 1;
          }
        })
        .catch((err) => {});
    },
    // 拿酒店品牌
    getOption() {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getOptions, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              var optionData = res.data.data;
              this.topAttr.hotelGroupList = optionData.hotelGroupList;
            }
            resolve();
          });
      });
    },
    getLabel(tag_name, tag_num, group_name, group_num) {
      this.labelValue = tag_name;
      this.labelNum = tag_num;
      this.groupValue = group_name;
      this.groupNum = group_num;
    },
    provinceChange(val) {
      if (val.length === 1) {
        this.region.prov_code = val.value;
      } else {
        this.region.prov_code = "";
      }
      this.form.citycode = [];
      this.form.provinceLabel = [];
      this.form.city = "";
      this.form.country = "";
      this.form.cityLabel = "";
      this.form.countyLabel = "";
      val.forEach((item, index) => {
        this.form.citycode.push(item.value);
        this.form.provinceLabel.push(item.label);
      });
      if (val.length === 1) {
        this.getCity(val[0].value);
        this.region.prov_code = val[0].value;
      } else {
        this.topAttr.city = [];
        this.topAttr.country = [];
      }
    },
    cityChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.prov_code;
        this.form.cityLabel = "";
        this.form.countyLabel = "";
        this.form.country = "";
      } else {
        this.region.city_code = value;
        this.form.citycode = value;
        this.form.country = "";
        this.form.cityLabel = label;
      }
      this.getCountry();
    },
    countyChange(val) {
      var { value, label } = val;
      if (val === "") {
        this.form.citycode = this.region.city_code;
        this.form.countyLabel = "";
      } else {
        this.region.citycode = value;
        this.form.citycode = value;
        this.form.countyLabel = label;
      }
    },
    getProvince() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getProvince, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.province = res.data.data;
            }
            resolve();
          });
      });
    },
    getCity(prov_code) {
      return new Promise((resolve, rejct) => {
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              prov_code: prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.city = res.data.data;
            }
            resolve();
          });
      });
    },
    getCountry() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getDistricts, {
            params: {
              token: this.token,
              prov_code: this.region.prov_code,
              city_code: this.region.city_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.topAttr.country = res.data.data;
            }
            resolve();
          });
      });
    },
    provinceDataInit(val) {
      if (val) {
        if (val.length === 1) {
          this.region.prov_code = val.value;
        } else {
          this.region.prov_code = "";
        }
        val.forEach((item, index) => {
          this.form.citycode.push(item.value);
          this.form.provinceLabel.push(item.label);
        });
        if (val.length === 1) {
          this.region.prov_code = val[0].value;
          this.getCity(val[0].value)
            .then(() => {
              this.form.city = this.topSearchSave.city;
              this.region.city_code = this.form.city.value;
              this.getCountry();
            })
            .then(() => {
              this.form.country = this.topSearchSave.country;
            });
        } else {
          this.topAttr.city = [];
          this.topAttr.country = [];
        }
      }
    },
    getDefaultData(topSearchSave) {
      var form = this.form;
      this.getProvince().then(() => {
        form.province = topSearchSave.province;
        this.provinceDataInit(form.province);
      });
    },
    // 移除数据
    sub(item) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          for (var i = 0; i < this.info.length; i++) {
            if (this.info[i].hotelName == item.hotelName) {
              this.info.splice(i, 1);
              for (var j = 0; j < this.searchHis.length; j++) {
                if (this.searchHis[j].hotelName == item.hotelName) {
                  this.searchHis.splice(j, 1);
                }
              }
            }
          }
          this.deleteHotel.push(item);
          this.infoLength = this.infoLength - 1;
        })
        .catch(() => {});
    },
    cancel() {
      if (this.query) {
        this.$router.push({ path: `/apaas/sysLabelHistory` });
      } else {
        this.$router.push({ path: `./sysLabelHistory` });
      }
    },
    clearHotel() {
      this.deleteHotel = this.info;
      this.info = {};
      this.infoLength = 0;
    },
    searchHotel() {
      this.selectTop = false;
      this.$refs.labelshow.sendCheckList();
      if (this.labelValue == "") {
        this.$message.error("请选择标签");
      } else {
        this.listLoading = true;
        const json = {
          page: 1,
          limit: 90,
          tags: this.labelValue,
        };
        var param = qs.stringify(json);
        searchHotelsFromEs(param)
          .then((res) => {
            if (res.data.code == 100000) {
              var hotel = res.data.data.hotels;
              var select = { select: false };
              for (var i = 0; i < hotel.length; i++) {
                hotel[i].select = false;
              }
              this.info = hotel;
              this.searchHis = hotel;
              this.infoLength = res.data.data.total;
              this.listLoading = false;
              this.searchCount = this.searchCount + 1;
            }
          })
          .catch((err) => {});
      }
    },
    open() {
      this.$confirm("确认操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submit();
        })
        .catch(() => {});
    },
    submit() {
      //拿一遍全数据
      this.$refs.labelshow.sendCheckList();
      if (this.labelNum == 0) {
        this.$message.error("请完善信息");
      } else {
        this.listLoading = true;
        var hotel_brand = "";
        for (var i = 0; i < this.form.hotel_brand.length; i++) {
          hotel_brand += this.form.hotel_brand[i] + ",";
        }
        hotel_brand = hotel_brand.substring(0, hotel_brand.length - 1);
        this.hotel_brand = hotel_brand;
        const json1 = {
          tags: this.labelValue,
          hotel_contract: this.form.hotelContract,
          brand: this.hotel_brand,
          province: this.form.provinceLabel,
          city: this.form.cityLabel,
          district: this.form.countyLabel,
          hotel_name: this.form.hotelName,
          channelCode: this.form.channelCode,
        };
        var param = qs.stringify(json1);
        searchHotelsFromEs(param)
          .then((res) => {
            if (res.data.code == 100000) {
              var hotel = res.data.data.hotels;
              this.allInfo = hotel;
              for (var i = 0; i < this.allInfo.length; i++) {
                for (var j = 0; j < this.deleteHotel.length; j++) {
                  if (
                    this.allInfo[i].hotelContract ==
                    this.deleteHotel[j].hotelContract
                  ) {
                    this.allInfo.splice(i, 1);
                  }
                }
              }
              var eventId = 0;
              var hotelNum = 0;
              this.$refs.labelshow.sendCheckList();
              for (var i = 0; i < this.allInfo.length; i++) {
                this.hotel_contract += this.allInfo[i].hotelContract + ",";
                this.hotel_name += this.allInfo[i].hotelName + ",";
                hotelNum++;
              }
              this.hotel_contract = this.hotel_contract.substring(
                0,
                this.hotel_contract.length - 1
              );
              this.hotel_name = this.hotel_name.substring(
                0,
                this.hotel_name.length - 1
              );
              //判断事件ID
              if (hotelNum == 0 || this.labelNum == 0) {
                this.$message.error("请完善信息");
              } else if (hotelNum == 1 && this.groupNum == 0) {
                eventId = 5;
              } else if (hotelNum == 1 && this.groupNum > 0) {
                eventId = 6;
              } else if (hotelNum > 1 && this.groupNum == 0) {
                eventId = 7;
              } else if (hotelNum > 1 && this.groupNum > 0) {
                eventId = 8;
              }
              const json = {
                token: this.token,
                hotel_contract: this.hotel_contract,
                label: this.labelValue,
                type: 2,
                event: eventId,
                tag: this.labelValue,
                group: this.groupValue,
                hotel_name: this.hotel_name,
                user_name: this.auth.user_name,
              };
              if (this.query) {
                json.isApaas = 1;
              }
              var param = qs.stringify(json);
              if (eventId != 0) {
                labelToManyHotels(param)
                  .then((res) => {
                    if (res.data.code == 100000) {
                      this.hotel_contract = "";
                      eventId = 0;
                      hotelNum = 0;
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.cancel();
                    }
                  })
                  .catch((err) => {
                    this.hotel_contract = "";
                    eventId = 0;
                    hotelNum = 0;
                    console.log(err);
                  });
              }
            }
          })
          .catch((err) => {});
      }
    },
    resetSearch() {
      this.form.hotelContract = "";
      this.form.provinceLabel = "";
      this.form.cityLabel = "";
      this.form.countyLabel = "";
      this.form.hotel_brand = "";
      this.form.province = "";
      this.form.city = "";
      this.form.country = "";
      this.form.channelCode = "";
      this.form.hotelContract = "";
      this.form.hotelName = "";
      this.searchHotel();
    },
    reset() {
      this.$refs.labelshow.recetCheckList();
      this.form.hotelContract = "";
      this.form.provinceLabel = "";
      this.form.cityLabel = "";
      this.form.countyLabel = "";
      this.form.hotel_brand = "";
      this.form.province = "";
      this.form.city = "";
      this.form.country = "";
      this.form.channelCode = "";
      this.form.hotelContract = "";
      this.form.hotelName = "";
      this.info = {};
      this.infoLength = 0;
      this.selectTop = true;
      this.searchCount = 1;
    },
    searchHotelBottom() {
      this.$refs.labelshow.sendCheckList();
      this.listLoading = true;
      var hotel_brand = "";
      for (var i = 0; i < this.form.hotel_brand.length; i++) {
        hotel_brand += this.form.hotel_brand[i] + ",";
      }
      hotel_brand = hotel_brand.substring(0, hotel_brand.length - 1);
      this.hotel_brand = hotel_brand;
      const json = {
        tags: this.labelValue,
        hotel_contract: this.form.hotelContract,
        brand: this.hotel_brand,
        province: this.form.provinceLabel,
        city: this.form.cityLabel,
        district: this.form.countyLabel,
        hotel_name: this.form.hotelName,
        channelCode: this.form.channelCode,
        page: 1,
        limit: 90,
      };
      var param = qs.stringify(json);
      searchHotelsFromEs(param)
        .then((res) => {
          if (res.data.code == 100000) {
            var hotel = res.data.data.hotels;
            var select = { select: false };
            for (var i = 0; i < hotel.length; i++) {
              hotel[i].select = false;
            }
            this.info = hotel;
            this.searchHis = hotel;
            this.infoLength = res.data.data.total;
            this.listLoading = false;
            this.searchCount = this.searchCount + 1;
          }
        })
        .catch((err) => {});
    },
  },

  created() {},
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getOption();
    this.getProvince();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.query = sessionStorage.getItem("apaasData");
    if (this.query) {
      this.bread.level = 3;
      this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
    } else {
      this.getGroupLevel().then((res) => {
        this.getTreeData(res);
      });
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.search_box {
  margin: 10px 0;
}

.rowBox {
  margin-top: 20px;
}

.el-table {
  margin-top: 15px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.btn {
  width: 100px;
  height: 40px;
}

.right_text {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid rgb(220, 223, 230);
  padding: 10px;
  margin: auto;
}

.right_box {
  width: 99.9%;
  border: 1px solid rgb(220, 223, 230);
}

.rightlist {
  width: 99.9%;
  list-style: none;
  overflow: hidden;
  overflow-y: visible;
  height: 400px;
}

.content {
  font-size: 30px;
  margin: 0 20px;
}

.li {
  padding: 5px;
  box-sizing: border-box;
}

.list_right {
  display: flex;
  float: left;
}

.delBtn {
  position: absolute;
  top: 0;
  right: 10px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkedLabelBox {
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.checkedLabel {
  width: calc(100% / 3 - 2%);
  text-align: center;
  height: 32px;
  line-height: 32px;
  padding: 0 43px 0 15px;
  margin: 0 1% 1%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  border: 1px solid #3688f8;
  color: #3688f8;
}

.checkedLabel1 {
  background: #f1f8ff;
}

.bottom_btn_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clear {
  position: absolute;
  right: 50px;
}

.hotelSearch {
  width: 30vw;
  margin-top: 10px;
}

.del {
  color: grey;
  margin-left: auto;
  margin-right: 10px;
}

body .el-scrollbar__wrap {
  overflow-x: hidden;
}

.delBtn span {
  width: 1px;
  height: 18px;
  background: #3688f8;
}

.hotelShowBox {
  height: 400px;
  overflow: hidden;
  overflow-y: visible;
}
</style>