<template>
  <div>
    <!-- 筛选界面处理 -->
    <!-- 筛选界面处理 -->
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" :topSearchSave="topSearchSave"></top-search>
    </el-card>
    <!-- 表格操作区 -->
    <el-card class="box-card">
      <div class="title blueTitle">标签列表</div>
      <div class="btnRow">
        <el-button type="danger" v-if="showFunModel('标签列表删除')" @click="delLabel">删 除</el-button>
        <div>
          <el-button type="primary" v-if="showFunModel('标签列表新建')" @click="openCreatLabel">新 建</el-button>
          <el-button type="warning" v-if="showFunModel('标签列表导出')" @click="importLabelList" :loading="importLoding">导 出</el-button>
          <el-button type="warning" v-if="showFunModel('标签列表导出')" @click="importLabelHotel">酒店导出</el-button>
        </div>
      </div>
      <el-table class="tableBox" ref="singleTable" :row-key="getRowKey" :data="tableList" style="width: 100%" :header-cell-style="headerStyle" v-loading="loading" @selection-change="selectionChange">
        <el-table-column type="selection" width="55" :reserve-selection="true" :selectable="selectable"></el-table-column>
        <el-table-column prop="id" label="ID" width="55"></el-table-column>
        <el-table-column prop="label_name" label="名称" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type='text' @click="openEditContent(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.is_group">标签组</span>
            <span v-else>标签</span>
          </template>
        </el-table-column>
        <el-table-column prop="classify_text" label="分类"></el-table-column>
        <el-table-column prop="label_type_text" label="性质" min-width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="num" label="所属酒店数量" min-width="120" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="status_text" label="状态"></el-table-column> -->
        <el-table-column prop="admin_name" label="创建人"></el-table-column>
        <el-table-column prop="create_time_text" label="创建时间" min-width="160px"></el-table-column>
        <el-table-column prop="update_admin_name" label="最新修改人" min-width="120"></el-table-column>
        <el-table-column prop="update_time_text" label="最新修改时间" min-width="160px"></el-table-column>
      </el-table>
      <div class="block pageBox">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 表格操作区 -->
    <creat-label ref="creatlabel" @loadpagelist="loadPageList"></creat-label>
    <el-drawer title="导出酒店" :visible.sync="dialogTableVisible" size="100%">
      <el-form label-width="100px">
        <el-form-item label="所属分类">
          <el-select v-model="form.labelType" placeholder="请选择标签分类" filterable style="width:100%" @change="getImportLabel()">
            <el-option v-for="item in labelType" :key="item.id" :label="item.classify_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签类型">
          <el-select v-model="form.labelMold" placeholder="请选择标签类型" filterable class="fullSelect" @change="getImportLabel()">
            <el-option v-for="item in labelMold" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择标签" v-if="form.labelMold==0">
          <el-select v-model="form.labelName" placeholder="请选择标签" filterable style="width:100%">
            <el-option v-for="item in labelName" :key="item.id" :label="item.label_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择标签组" v-if="form.labelMold==1">
          <el-select v-model="form.labelName" placeholder="请选择标签组" filterable style="width:100%">
            <el-option v-for="item in labelName" :key="item.id" :label="item.label_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="warning" @click="importHotel" style="float:right;height:40px;width:100px" :loading="hotelImportLoading">导出</el-button>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import {
  getLabelList,
  delLabel,
  importLabel,
  getClassifyList,
  labelImportWithEs,
} from "@/api/label.js";
import creatLabel from "./creatLabel.vue";
import qs from "querystring";
export default {
  name: "labelList",
  props: ["funArr"],
  components: {
    creatLabel,
  },
  data() {
    return {
      auth: null,
      hotelImportLoading: false,
      labelName: [],
      labelMold: [
        { id: "0", name: "标签" },
        { id: "1", name: "标签组" },
        { id: "2", name: "无标签组" },
      ],
      labelType: [],
      form: {
        labelType: "",
        labelMold: "",
        labelName: "",
      },
      dialogTableVisible: false,
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      table: {
        page: 1,
        limit: 5,
        total: 0,
      },
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      topSearchSave: {},
      topSearchShow: {
        labelType: true,
        labelNature: true,
        labelMold: true,
        mediaType: true,
        keyword: true,
        hotel_use_state: true,
        keywordPlaceholder: "请输入标签名称关键字",
      },
      tableList: [{}],
      loading: false,
      classify_id: "",
      label_name: "",
      label_type: "",
      hotelStatus: "",
      mediaType: "",
      is_group: "",
      selectIdArr: [],
      importLoding: false,
    };
  },
  watch: {
    funArr: {
      handler: function (newVal) {
        this.funArr = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getLabelList();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
  computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    // 导出
    importHotel() {
      if (this.form.labelMold == "2" || this.form.labelName != "") {
        this.hotelImportLoading = true;
        this.$message.success(
          "列表导出操作正在处理,文件处理完毕将自动下载,请稍等"
        );
        let json = {
          id: this.form.labelName,
          labelMold: this.form.labelMold,
        };
        var param = qs.stringify(json);
        labelImportWithEs(param)
          .then((res) => {
            const content = res.data;
            const blob = new Blob([content]);
            const fileName = "酒店列表.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
              this.$message.success("导出成功");
              this.hotelImportLoading = false;
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
              this.$message.success("导出成功");
              this.hotelImportLoading = false;
            }
          })
          .catch((err) => {});
      } else {
        this.$message.warning("请选择导出内容");
      }
    },
    // 获取要导出的标签
    getImportLabel() {
      this.form.labelName = "";
      const json = {
        page: 1,
        limit: 9999,
        classify_id: this.form.labelType,
        is_group: this.form.labelMold,
      };
      var param = qs.stringify(json);
      getLabelList(param)
        .then((res) => {
          this.labelName = res.data.data.data;
        })
        .catch((err) => {});
    },
    // 获取分类
    importLabelHotel() {
      this.dialogTableVisible = true;
      let json = {
        page: 1,
        limit: 99999,
      };
      var param = qs.stringify(json);
      getClassifyList(param)
        .then((res) => {
          this.labelType = res.data.data.data;
        })
        .catch((err) => {});
    },
    // 广告禁用
    selectable(row, index) {
      if (row.classify_id == 1) {
        return false;
      } else {
        return true;
      }
    },
    // 获取每一行的key值
    getRowKey(row) {
      return row.id;
    },
    // 数据变化刷新数据
    loadData() {
      this.$refs.creatlabel.getData();
    },
    // 表格导出
    importLabelList() {
      this.importLoding = true;
      this.$message.success(
        "列表导出操作正在处理,文件处理完毕将自动下载,请稍等"
      );
      var token = sessionStorage.getItem("token");
      let json = {
        token: token,
        classify_id: this.classify_id,
        label_name: this.label_name,
        label_type: this.label_type,
        is_group: this.is_group,
        label_text: this.mediaType,
        status_text: this.hotelStatus,
      };
      var param = qs.stringify(json);
      importLabel(param)
        .then((res) => {
          const content = res.data;
          const blob = new Blob([content]);
          const fileName = "标签列表.xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
            this.importLoding = false;
            this.$message.success("导出成功");
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
            this.importLoding = false;
            this.$message.success("导出成功");
          }
        })
        .catch((err) => {});
    },
    // 表格选中
    selectionChange(val) {
      this.selectIdArr = val;
    },
    // 删除标签
    delLabel() {
      if (this.selectIdArr.length == 0) {
        this.$message.warning("请至少选择一条数据进行操作");
      } else {
        this.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var arr = this.selectIdArr.map((i) => {
              return i.id;
            });
            let json = {
              ids: arr.join(","),
              user_name: this.auth.user_name,
            };
            var param = qs.stringify(json);
            delLabel(param)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("删除成功");
                  this.getLabelList();
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch((err) => {});
          })
          .catch(() => {});
      }
    },
    // 刷新列表
    loadPageList() {
      this.getLabelList();
    },
    // 新建
    openCreatLabel() {
      this.$refs.creatlabel.open();
    },
    // 编辑
    openEditContent(row) {
      this.$refs.creatlabel.open(row, this.showFunModel("标签列表编辑"));
    },
    // 分页
    handleSizeChange(val) {
      this.table.limit = val;
      this.getLabelList();
    },
    handleCurrentChange(val) {
      this.table.page = val;
      this.getLabelList();
    },
    // 搜索
    getCondition(val) {
      this.classify_id = val.labelType;
      this.label_name = val.keyword;
      this.label_type = val.labelNature;
      this.is_group = val.labelMold;
      this.mediaType = val.mediaType;
      if (val.hotel_use_state.length !== 0) {
        this.hotelStatus = val.hotel_use_state.join(",");
      } else {
        this.hotelStatus = "";
      }
      this.table.page = 1;
      this.getLabelList();
    },
    // 获取列表
    getLabelList() {
      this.loading = true;
      const json = {
        page: this.table.page,
        limit: this.table.limit,
        classify_id: this.classify_id,
        need_num: 1,
        label_text: this.mediaType,
        label_name: this.label_name,
        label_type: this.label_type,
        is_group: this.is_group,
        status_text: this.hotelStatus,
      };
      var param = qs.stringify(json);
      getLabelList(param)
        .then((res) => {
          this.tableList = res.data.data.data;
          this.table.total = res.data.data.total;
          this.loading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.searchBtn {
  position: fixed;
  top: 110px;
  right: 10px;
}
.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.btnRow .el-button {
  width: 100px;
  height: 40px;
}
</style>