<template>
  <div>
    <el-card class="box-card searchCard">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <div class="allDataCardBox">
      <div :class="['dataCard',checkList.indexOf(index)!==-1?'checkCard':'']" v-for="(item,index) in allData" :key="index" @click="addTableData(item,index)">{{item.name}}</div>
      <div class="dataCardYellow" @click="ExportTable">导出</div>
    </div>
    <el-table :row-key="getRowId" class="tmTable" ref="singleTable" :data="tableList" style="width: 100%" :header-cell-style="headerStyle">
      <el-table-column prop="name" label="类别" min-width="160px"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="openContent(scope.row)">详情</el-button>
          <el-button type="text" size="mini" @click="openEcharts(scope.row)">图表</el-button>
        </template>
      </el-table-column>
      <el-table-column min-width="100px" label="酒店数量" show-overflow-tooltip></el-table-column>
      <el-table-column label="客房数" min-width="100px">
      </el-table-column>
      <el-table-column label="激活终端数" min-width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="日活率">
      </el-table-column>
      <el-table-column label="终端统计数" min-width="120px">
      </el-table-column>
    </el-table>
    <el-drawer title="详情" :visible.sync="drawerShow" direction="rtl" size="130%" :modal="false" :destroy-on-close="true">
      <terminal-box :rowData="rowData"></terminal-box>
    </el-drawer>
    <el-dialog title="图表展示" :visible.sync="dialogFourVisible" width="50%">
      <fourLineecharts ref='fourLineecharts' :chart="lineChart"></fourLineecharts>
    </el-dialog>
  </div>
</template>
<script>
import twoLineecharts from "./components/twoLineEcharts.vue";
import fourLineecharts from "./components/fourLineEcharts.vue";
import Sortable from "sortablejs";
import terminalBox from "./components/terminalBox.vue";
import GLOBAL from "@/api/globalVar.js";
import obj from "@/api/public.js"
export default {
  components: {
    terminalBox,
    twoLineecharts,
    fourLineecharts,
  },
  data() {
    return {
      dialogTwoVisible: false,
      dialogFourVisible: false,
      topSearchShow: {},
      searchData: {
        BD: null,
        acceptanceStatus: null,
        onlyDay: null,
        city: null,
        hotel_brand: null,
        hotel_use_state_tem: null,
        province: null,
        coopType: null,
        noTypePartnerCode: null,
      },
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      allData: [
        { name: "成语接龙"},
        { name: "龙腾四海"},
        { name: "海阔天空"},
        { name: "空穴来风"},
        { name: "风雨同舟"},
        { name: "舟车楫马"},
        { name: "马到成功"},
        { name: "功成身退"},
        { name: "退避三舍"},
        { name: "舍近求远"},
        { name: "远近闻名"},
        { name: "名闻天下"},
        { name: "下里巴人"},
      ],
      rowData: {},
      checkList:[],
      tableList: [],
      drawerShow: false,
      lineChart: {
        id: "terminalChart",
        noData: false,
        option: {
          legend: ["测试1", "测试2", "测试3", "测试4"],
          tooltip: {},
          xData: ["00001", "00002", "00003", "00004","00005", "00006", "00007"],
          series: [
            {
              name: "测试1",
              type: "line",
              smooth: false,
              itemStyle: {
                normal: {
                  color: GLOBAL.chartColor[0], //改变折线点的颜色
                  lineStyle: {
                    color: GLOBAL.chartColor[0], //改变折线颜色
                  },
                },
              },
              data: [50, 100, 600, 46, 156, 15, 961],
            },
            {
              name: "测试2",
              type: "line",
              smooth: false,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: GLOBAL.chartColor[1], //改变折线点的颜色
                  lineStyle: {
                    color: GLOBAL.chartColor[1], //改变折线颜色
                  },
                },
              },
              data: [56, 481, 891, 15, 4, 15, 156],
            },
            {
              name: "测试3",
              type: "line",
              smooth: false,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: GLOBAL.chartColor[2], //改变折线点的颜色
                  lineStyle: {
                    color: GLOBAL.chartColor[2], //改变折线颜色
                  },
                },
              },
              data: [56, 15, 4, 15, 156, 481, 891],
            },
            {
              name: "测试4",
              type: "line",
              smooth: false,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: GLOBAL.chartColor[3], //改变折线点的颜色
                  lineStyle: {
                    color: GLOBAL.chartColor[3], //改变折线颜色
                  },
                },
              },
              data: [56, 481, 4, 15, 156, 891, 15],
            },
          ],
        },
      },
    };
  },
  methods: {
      ExportTable(){
      if(this.tableList.length===0){
        this.$message.warning("表格数据为空，无法导出数据")
      }else{
        obj.assetsTermnalToExcel(this.tableList,"终端健康状态");
      }
    },
    getRowId(row) {
      return row.name;
    },
    openContent(row) {
      this.rowData = row;
      this.drawerShow = true;
    },
    openEcharts(row) {
      this.dialogFourVisible = true;
      this.$nextTick(function () {
          this.$refs.fourLineecharts.getYaxis('元','元','元','元');
          this.$refs.fourLineecharts.transData("测试1","测试2","测试3","测试4");
      });
    },
    rowDrop() {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector(".tmTable .el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        onEnd({ newIndex, oldIndex }) {},
      });
    },
    //点击添加列表数据
    addTableData(data, index) {
     if (this.checkList.indexOf(index) == -1) {
        this.checkList.push(index);
        this.tableList.push(data);
      } else {
        this.checkList.splice(this.checkList.indexOf(index), 1);
        this.tableList.forEach((item, idx) => {
          if (this.allData[index].name == item.name) {
            this.tableList.splice(idx, 1);
          }
        });
      }
    },
    //  获取搜索框权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          acceptanceStatus: true,
          hotel_use_state: true,
          BD: true,
          coopType: true,
          onlyDay: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          hotel_brand: true,
          province: true,
          city: true,
          acceptanceStatus: true,
          hotel_use_state: true,
          BD: true,
          coopType: true,
          onlyDay: true,
        };
      }
    },
    // 搜索
    getCondition(val) {
      this.searchData.BD = val.BD;
      this.searchData.acceptanceStatus = val.acceptanceStatus;
      this.searchData.onlyDay = val.onlyDay;
      this.searchData.city = val.city;
      this.searchData.hotel_brand = val.hotel_brand;
      this.searchData.hotel_use_state_tem = val.hotel_use_state_tem;
      this.searchData.province = val.province;
      this.searchData.coopType = val.coopType;
      this.searchData.noTypePartnerCode = val.noTypePartnerCode;
    },
  },
  mounted() {
    this.rowDrop();
    this.getTopSearchShow();
  },
};
</script>
<style scoped>
.allDataCardBox {
  padding: 10px 0px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.dataCard {
  width: 12%;
  height: 30px;
  font-size: 14px;
  color: #fff;
  background: rgba(58, 137, 230, 1);
  margin-top: 10px;
  text-align: center;
  line-height: 30px;
  margin-right: 0.5%;
  cursor: pointer;
}
.dataCardYellow {
  width: 12%;
  height: 30px;
  font-size: 14px;
  color: #fff;
  background: rgba(245, 154, 35, 1);

  text-align: center;
  line-height: 30px;
  margin-right: 0.5%;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 0%;
}
.checkCard {
  color: rgba(58, 137, 230, 1);
  background: #fff;
  border: 1px solid rgba(58, 137, 230, 1);
  box-sizing: border-box;
}
::v-deep .el-drawer__body {
  padding: 20px;
}
</style>