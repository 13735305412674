import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
    //  apk列表
export function apkList(data) {
    return request({
        url: `${vi}/Hotelapks/apkConfig`,
        method: 'post',
        data,
    });
}
// 新增修改
export function editupdateAPK(data) {
    return request({
        url: `${vi}/Hotelapks/editupdateAPK`,
        method: 'post',
        data,
    });
}
// 删除
export function delallAPK(data) {
    return request({
        url: `${vi}/Hotelapks/delallAPK`,
        method: 'post',
        data,
    });
}
// 获取所有设备
export function getApkPlatform(data) {
    return request({
        url: `${vi}/Hotelapks/getApkPlatform`,
        method: 'post',
        data,
    });
}
//  获取Device列表
export function getDeviceName(param) {
    return request({
        url: `v2/Hotelvisualization/getAllDeviceName`,
        method: "post",
        data: qs.stringify(param),
    });
}
//上传apk校验
export function checkApk(data) {
    return request({
        url: `${vi}/Hotelapks/checkApk`,
        method: 'post',
        data,
    });
}
//上传文件
export function uploadFile(data) {
    return request({
        url: '/ajax/uploadtos3',
        method: 'post',
        data,
    });
}
// 拉取apk
export function pullApkList(data) {
    return request({
        url: `${vi}/Hotelapks/pullApkList`,
        method: 'post',
        data,
    });
}
// 批量apk
export function batchEditAPK(data) {
    return request({
        url: `${vi}/Hotelapks/batchEditAPK`,
        method: 'post',
        data,
    });
}