<template>
<!--    <hotel-man-index :pageInfo="pageInfo"></hotel-man-index>-->
	<hotel-all :pageInfo="pageInfo"></hotel-all>

</template>

<script>
    export default {
        name: "HotelManNew",
        data:()=>({
            pageInfo:{
                hotelState:'implementation',
                text:'实施中',
                fromUrl:'hotelManAllImplementation',
            }

        })
    }
</script>

<style scoped>

</style>