<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">首页</span>
                <span slot="third">更多</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>

        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition" :topSearchSave="topSearchSave"></top-search>
        </el-card>
        <el-card class="box-card">
            <div class="lineContent">
                <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
            </div>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "IndexDoMore",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                secondPath:{path: '/indexShow'},
            },
            table:{
                tableHeader:[
                    {prop:'review_title',label:'名称',width:'350'},
                    {prop:'review_do_msg',label:'任务类型',width:''},
                    {prop:'status',label:'任务状态',width:''},
                    {prop:'createtime',label:'接收时间',width:''},
                    {prop:'reviewtime',label:'处理时间',width:''},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'indexDoMore',
                name:'indexDoMore',
                option:true,
                loading:true
            },
            token:'',
            topSearchShow:{ },
            condition:{
                taskType:'',
                taskStatus:'',
                keyword:'',
            },
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            topSearchSave:{}
        }),
        methods:{
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            tableMeth(orderVal){
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.$http.get(this.global.reviewlist,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        per_page:this.table.limit,
                        // review_type:this.condition.taskType,
                        status:this.condition.taskStatus,
                        // keyword:this.condition.keyword,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=tableData.total;
                    }else if(code===100001){
                        // this.errorText='验证码错误'
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='group'  || auth_type==='factory'){
                    this.topSearchShow={
                        // taskType:true,
                        taskStatus:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        // taskType:true,
                        taskStatus:true,
                    }
                }
            },
            getCondition(val,filterPage,filterLimit,type) {
                // this.condition.taskType=Array.isArray(val.taskType)?val.taskType.join(','):''; //ka
                this.condition.taskStatus=Array.isArray(val.taskStatus)?val.taskStatus.join(','):''; //ka
                this.condition.keyword=val.keyword; //ka
                this.getFilterTableCon(filterPage,filterLimit,type);
                this.tableMeth();
            },
            getFoldMeth(val){
                this.fold=val;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
            },
            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                !filterCon?filterCon={}:'';
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                this.getCondition(filterCon,filterPage*1,filterLimit*1,'mounted');
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getFilterCon();
            this.getTopSearchShow();
        }
    }
</script>

<style scoped lang="scss">
    /* 公共样式 开始*/
    .bg-purple {
        /*background: #d3dce6;*/
        background: #fff;
    }
    .rowBox{
        margin-top:20px;
        box-sizing: border-box;
    }
    .hotelBrand{
        width:100%;
    }

    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        /*background: #d3dce6;*/
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
        padding:20px 20px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    /* 公共样式 结束*/
</style>
