<!-- 模板配置 -->
<template>
  <div>
    <!-- table -->
    <div class="form_box">
      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <el-input v-model="form.package" placeholder="模板名称模糊搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="
              pagination.currentPage = 1;
              getList();
            ">搜 索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="primary" class="btn" @click="
          current = {};
          moduleDia = true;
        ">添 加</el-button>
      <!-- <el-button type="danger" @click="del" class="btn">批量删除</el-button> -->
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{
          background: '#f8fbff',
          color: '#606266',
        }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="id" label="版式">
          <template slot-scope="scope">
            <div v-if="banType.length != 0">
              {{ banType.find((item) => item.id == scope.row.t_pid).t_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="t_name" label="模板名" width="180"></el-table-column>
        <el-table-column prop="hotel_id" label="模板酒店id"></el-table-column>
        <el-table-column prop="id" label="图片地址">
          <template slot-scope="scope">
            <div>
              <el-image style="width: 100px" :src="scope.row.t_addr" :preview-src-list="[scope.row.t_addr]"></el-image>
              <!-- <img :src="scope.row.t_addr" alt="" class="table_img" /> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="groupname" label="所属品牌"></el-table-column>
        <el-table-column prop="recommend" label="推荐模板">
          <template slot-scope="scope">
            <div>
              <!-- 0 1 -->
              {{ scope.row.recommend == 1 ? "推荐显示" : "推荐不显示" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="showstoreid" label="显示门店id">
          <template slot-scope="scope">
            <div>
              <!-- 0 1 -->
              {{ scope.row.showstoreid == 0 ? "不显示" : "显示" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="morder" label="排序"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="
                current = scope.row;
                moduleDia = true;
              " type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="total,prev, pager, next" :total="pagination.total" :current-page="pagination.currentPage" v-show="pagination.total != 0" @current-change="
          (val) => {
            pagination.currentPage = val;
            getList();
          }
        ">
      </el-pagination>
    </div>
    <ModuleOper :visible.sync="moduleDia" :current="current" :banList="banType" @refresh="
        pagination.currentPage = 1;
        getList();
      "></ModuleOper>
  </div>
</template>
<script>
import { getList, getBan, del } from "./api/module";
import ModuleOper from "@/components/operation/SettingManage/components/moduleOper";
export default {
  name: "params",
  components: { ModuleOper },
  data() {
    return {
      form: {
        keyWord: "",
        sign: "",
        package: "",
      },
      banType: [],
      tableData: [],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      selected: [],
      moduleDia: false,
      current: {},
    };
  },
  methods: {
    del() {
      if (this.selected.length == 0) {
        this.$message.error("尚未选择模板");
        return;
      }
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({
            ids: this.selected
              .map((item) => {
                return item.id;
              })
              .join(","),
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.selected = val;
    },
    getBan() {
      getBan().then((data) => {
        this.banType = data.data.data;
      });
    },
    getList(page) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        name: this.form.package,
        page: page ? page : this.pagination.currentPage,
        size: 10,
      }).then((data) => {
        this.tableData = data.data.data.row;
        this.pagination.total = parseInt(data.data.data.totalNum);
      });
    },
  },
  mounted() {
    this.getBan();
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
.table_img {
  width: 100px;
}
</style>
