<template>
  <div style="margin-top:20px">

    <div class="btnRow">
      <el-button type="primary" :disabled="!upState" class="largeBtn" @click="toCreate">获取最新应用
        <span class="redPoint" v-if="upState"></span>
      </el-button>
      <el-button type="primary" class="largeBtn" @click="loadList">刷新列表

      </el-button>
    </div>

    <!-- 表格 -->
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent">\

    </operationTable>
    <!-- 表格 -->

  </div>

</template>
<script>
import { getAppList, getAppUpdate } from "@/api/hotelGroup";
export default {
  name: "DownIndex",

  data() {
    return {
      bread: {
        level: 3,
      },
      signList: [],
      btnLoading: false,
      itemData: {},
      upState: false,
      // 表格数据信息
      tableData: {
        tableList: [{}],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "版本号",
            prop: "version",
            minWidth: "280",
            type: "text",
          },
          {
            label: "生成日期",
            prop: "update_time",
            minWidth: "100",
            type: "text",
          },
          {
            label: "大小(MB)",
            prop: "size",
            minWidth: "120",
            type: "text",
          },

          {
            label: "操作",
            minWidth: "280",
            type: "button",
            prop: "num",
            // 详情
            log: (item) => {
              const h = this.$createElement;
              this.$msgbox({
                title: "更新日志",
                message: h("div", null, [h("p", null, [h("span", item.log)])]),

                confirmButtonText: "确定",
                beforeClose: (action, instance, done) => {
                  done();
                },
              }).then((action) => {});
            },
            down: (item) => {
              console.log(item);
              if (item.url && item.url.indexOf(".apk") != -1) {
                window.open(item.url, "_blank");
              } else {
                this.$message.warning("下载路径错误");
              }
            },
          },
        ],
      },
      // 已选项
      checkList: [],
    };
  },

  methods: {
    toCreate() {
      const json = {
        // channel_code: "HCC001546",
        // hotel_contract: "faec2ad89dc14827a292021ed92b9b7f",
        channel_code: this.itemData.res.data.data.channel_code,
        hotel_contract: this.itemData.hotel_contract,
      };
      getAppUpdate(json)
        .then((res) => {
          console.log(res, "操作结果");
          if (res.data.code == 100000) {
            this.$message.success("操作成功");
            this.getTableData();
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.warning("操作失败");
        });
    },

    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },
    loadList() {
      this.tableData.page = 1;
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p ? p : 1,
        limit: s ? s : 10,
        // channel_code: "HCC001546",
        // hotel_contract: "faec2ad89dc14827a292021ed92b9b7f",
        channel_code: this.itemData.res.data.data.channel_code,
        hotel_contract: this.itemData.hotel_contract,
      };
      getAppList(json)
        .then((res) => {
          if (res.data.code == 100000) {
            console.log(res, "列表数据");
             if (res.data.data.state == 0) {
              this.upState = true;
            }else{
                this.upState = false;
            }
            if (res.data.data.list.data && res.data.data.list.data.length > 0) {
              this.tableData.tableList = res.data.data.list.data.map((i) => {
                i.size = (i.size / 1024 / 1024 ).toFixed(2);
                return i
              });
            } else {
              this.tableData.tableList = res.data.data.list.data;
            }
            this.$refs.operationTable.changeTotal(res.data.data.list.total);
           
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {
    this.itemData = JSON.parse(sessionStorage.getItem("hoteliitem"));
    console.log(this.itemData, "999999999999999-----------------------------");
    this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
.el-form-item {
  margin-bottom: 0px;
}
.largeBtn {
  position: relative;
}
.redPoint {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 5px;
}
</style>
