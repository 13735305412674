<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      append-to-body>
      <div style="line-height: 20px" class="container">
       {{logDetail}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      title: '',
      database: '',
      dialogVisible: false,
      logDetail: ''
    }
  },
  methods: {
    open(title,database,row,) {
      this.title = title;
      this.database = database;
      this.dialogVisible = true;
      this.logDetail = row.operate;
    },
    handleClose() {
      this.dialogVisible = false
    },
  }
}
</script>

<style>

</style>