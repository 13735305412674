<template>
<!--    <hotel-man-index :pageInfo="pageInfo" :showQuickSearch="true"></hotel-man-index>-->
	<hotel-all  :pageInfo="pageInfo" :showQuickSearch="true"></hotel-all >
</template>

<script>
    export default {
        name: "HotelManNew",
        data:()=>({
            pageInfo:{
                hotelState:'del',
                text:'删除',
                fromUrl:'hotelManDel'
            }
        })
    }
</script>

<style scoped>

</style>