<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">系统设置</span>
        <span slot="third">账号管理</span>
        <span slot="fourth" v-if="query.type!=='edit'">新建</span>
        <span slot="fourth" v-if="query.type==='edit'">详情</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <back @backMeth="backMeth"></back>
      <el-row :gutter="20">
        <el-col :span="15">
          <div class="grid-content bg-purple">
            <el-form label-width="200px" :model="form" :rules="rules" ref="form">
              <el-form-item label="合作伙伴名称" prop="partnerName">
                <el-input v-model="companyName" :readonly="true"></el-input>
              </el-form-item>
              <el-form-item label="账号名称" prop="account_name" v-if="curType==='htrip'">
                <el-input v-model="form.account_name"></el-input>
              </el-form-item>
              <el-form-item label="所属部门" prop="department_id" v-if="curType==='htrip'">
                <el-select v-model="form.department_id" clearable class=" fullSelect" @change="departmentChange">
                  <el-option v-for="item in selectData.departmentSelect" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="联系人" prop="contact_name">
                <el-input v-model="form.contact_name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="contact_phone">
                <el-input v-model="form.contact_phone"></el-input>
              </el-form-item>
              <el-form-item label="所属角色" prop="group_id">
                <!-- v-if="showRole" -->
                <el-select v-model="form.group_id" clearable class="fullSelect" @change="roleChange">
                  <el-option v-for="item in selectData.groupSelect" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="携旅上级账号名称" v-if="curType==='htrip'">
                <el-select v-model="form.parent_id" clearable class="fullSelect" filterable>
                  <el-option v-for="item in selectData.parentSelect" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!--<el-form-item label="上级合作伙伴" v-if="curType!=='htrip'">-->
              <!--&lt;!&ndash;<el-input v-model="form.partner_id" :readonly="true"></el-input>&ndash;&gt;-->
              <!--<el-select v-model="form.partner_id" clearable filterable class=" fullSelect" :readonly="true">-->
              <!--<el-option-->
              <!--v-for="item in selectData.partnerSelect"-->
              <!--:key="item.id"-->
              <!--:label="item.company_name"-->
              <!--:value="item.id">-->
              <!--</el-option>-->
              <!--</el-select>-->
              <!--</el-form-item>-->
              <el-form-item size="large">
                <el-button type="primary" class="largeBtn rightBtn" @click="submitForm('form')">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="9">
          <div class="grid-content bg-purple"></div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import qs from "querystring";

export default {
  name: "AdManCusNew",
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入联系电话"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
        return callback(new Error("请填写正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      bread: {
        level: 4,
        firstPath: { path: "/indexShow" },
        thirdPath: { path: "/sysAccMan" },
      },
      form: {
        partnerName: "",
        account_name: "",
        department_id: "",
        contact_name: "",
        contact_phone: "",
        group_id: "",
        parent_id: "",
        partner_id: "",
      },
      rules: {
        account_name: [
          { required: true, message: "请输入账号名称", trigger: "blur" },
        ],
        department_id: [
          { required: true, message: "请选择所属部门", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contact_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        group_id: [{ required: true, message: "请选择角色", trigger: "blur" }],
        parent_id: [
          {
            required: true,
            message: "请选择携旅上级账号名称",
            trigger: "blur",
          },
        ],
        partner_id: [
          { required: true, message: "请输入上级合作伙伴", trigger: "blur" },
        ],
      },
      selectData: {
        departmentSelect: [],
        groupSelect: [],
        parentSelect: [],
        partnerSelect: [],
      },
      query: {
        id: "",
        type: "",
      },
      auth: {},
      companyName: "",
      curType: "",
      showRole: true,
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.query.type === "edit" ? this.editMeth() : this.createMeth();
          });
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    editMeth() {
      var param = qs.stringify(this.form);
      this.$http
        .post(this.global.accountEdit, param, {
          headers: { token: this.token },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("编辑成功");
            this.$router.push({ path: "./sysAccMan" });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    createMeth() {
      var param = qs.stringify(this.form);
      this.$http
        .post(this.global.accountAdd, param, { headers: { token: this.token } })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("创建成功");
            this.$router.push({ path: "./sysAccMan" });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    isEmptyObj(obj) {
      for (let i in Object.keys(obj)) {
        return false; // 进入循环即不为空
      }
      return true;
    },
    getDepartmentMeth() {
      this.$http
        .get(this.global.getDepartment, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          var arr = [];
          if (res.data.code === 100000) {
            var data = res.data.data;
            this.selectData.departmentSelect = this.transObj(data);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    getGroupMeth() {
      this.$http
        .get(this.global.getGroup, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var data = res.data.data;
            var a = this.transObj(data);
            this.selectData.groupSelect = this.transObj(data);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    // getParentMeth(val){
    departmentChange(val) {
      return new Promise((resolve, reject) => {
        this.form.parent_id = "";
        if (val === "") {
          this.selectData.parentSelect = [];
        } else {
          this.$http
            .get(this.global.getAccountManagerListWithAlias, {
              params: {
                token: this.token,
                accountManagerTypeId: val,
              },
            })
            .then((res) => {
              if (res.data.code === 100000) {
                this.selectData.parentSelect = res.data.data;
              } else {
                // this.$message.error(res.data.msg);
              }
              resolve(res);
            })
            .catch((res) => {
              // this.$message.error(res.data.msg);
            });
        }
      });
    },
    getPartnerMeth() {
      this.$http
        .get(this.global.getSupPartner, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.partnerSelect = res.data.data;
          } else {
            // this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          // this.$message.error(res.data.msg);
        });
    },
    transObj(data) {
      var arr = [];
      for (var i in data) {
        arr.push({ id: i, name: data[i] });
      }
      return arr;
    },
    getDetail() {
      this.$http
        .get(this.global.accountEdit, {
          params: {
            token: this.token,
            ids: this.query.id,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            var formData = this.form;
            if (resData.account_alias) {
              formData.account_name = resData.account_alias;
            }
            formData.contact_name = resData.nickname;
            formData.contact_phone = resData.mobile;
            if (resData.groupid) {
              formData.group_id = resData.groupid.toString();
            }
            formData.ids = this.query.id;
            this.companyName = resData.partner.company_name;
            if (resData.department == 0) {
              formData.parent_id =
                resData.partner.parent_id == 0 ? "" : resData.partner.parent_id;
              formData.department_id = "";
            } else {
              formData.department_id = resData.department.toString();
              this.departmentChange(formData.department_id).then(() => {
                // if(resData.parent_id!==0){   //携旅
                formData.parent_id =
                  resData.partner.parent_id == 0
                    ? ""
                    : resData.partner.parent_id;
                // }
              });
            }

            // if(resData.partner.parent_id!==0){   //非携旅
            //     formData.partner_id=resData.partner.parent_id;
            // }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          // this.$message.error(res.data.msg);
        });
    },
    getCompanyName() {
      this.$http
        .get(this.global.accountAdd, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    roleChange(val) {},
    backMeth() {
      this.$router.push({ path: "./sysAccMan" });
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    console.log(this.auth);
    var query = this.$route.query;
    this.companyName = sessionStorage.getItem("curCompanyName");
    if (!this.isEmptyObj(query)) {
      this.query.id = query.id;
      this.query.type = query.type;
      this.curType = query.curType;
      if (this.auth.type == "htrip" && this.curType != "htrip") {
        this.showRole = false;
      }
      this.getDetail();
    } else {
      this.getCompanyName();
      this.curType = this.auth.type;
    }
    if (this.auth.type === "htrip") {
      this.getDepartmentMeth();
      // this.getParentMeth();
    }
    this.getGroupMeth();
    this.getPartnerMeth();
  },
};
</script>

<style scoped lang="scss">
.fullSelect {
  width: 100%;
}
</style>
