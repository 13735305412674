<template>
  <div>
    <el-upload :class="[uploadPicTrue?'hide':'show']" :action="global.materialUpload" :headers="headers"  :on-remove="handleRemove" :on-success="handleSuccess" :on-change="handleChange" :before-remove='beforeRemove' :before-upload="beforeAvatarUpload"   :limit="1" :file-list="fileList" ref="uploadPic" :accept="acceptStr">
    <el-button  type="primary" class="largeBtn" >{{text}}</el-button>
    </el-upload>
  </div>
</template>
<script>
export default {
  name: "uploadOneMedia",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    //   数据
    value: {
      type: String,
      default: () => "",
    },
    // 上传类型
    acceptStr:{
      type: String,
      default: () => "",
    },
    // 文字
    text:{
      type: String,
      default: () => "上传文件",
    }
  },
  data() {
    return {
      fileList: [],
      uploadPicTrue: false,
      headers: {
        token: "",
      },
      fileSrc: this.value,
    };
  },
  watch: {
    //   数据显示处理
    value: {
      handler: function (newVal) {
        this.fileSrc = newVal;
        if (this.fileSrc) {
          let obj = {
            url: this.fileSrc,
          };
          this.fileList = [];
          this.fileList.push(obj);
          this.uploadPicTrue = true;
        } else {
          this.fileList = [];
          this.uploadPicTrue = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    
    // 删除上传文件确认
    beforeRemove(file, fileList) {
      if (!this.upNoPic) {
      
            this.uploadPicTrue = false;
        
      } else {
        this.uploadPicTrue = false;
      }
    },
     // 上传文件时钩子函数
    beforeAvatarUpload(file) {
        this.uploadPicTrue = true;
        return true;
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.uploadPicTrue = false;
      this.fileList = [];
      this.fileSrc = "";
      this.$emit("change", this.fileSrc);
    },
    // 文件列表变化
    handleChange() {
      if (this.fileList.length >= 1) {
        this.uploadPicTrue = true;
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      this.fileList = fileList;
      this.fileSrc = file.response.data.url;
      this.$emit("change", this.fileSrc);
    },
  },
};
</script>
<style lang="scss" scoped>
.hide ::v-deep .el-upload--text {
  display: none;
}
.show ::v-deep .el-upload-list--picture-card {
  display: none;
}
::v-deep .el-upload--picture-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
</style>