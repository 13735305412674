import axios from 'axios';
import global_ from '@/api/config'

const service = axios.create({
    // baseURL: process.env.BASE_API, // api 的 base_url
    baseURL: global_.BASE_URL + "api/",
    // timeout: 30000 // 请求超时时间,
});
service.interceptors.request.use(function(config) {
    // 在发送请求之前把token放置在请求头
    const token = sessionStorage.getItem('token');
    config.headers['token'] = token;
    return config;
}, function(error) {
    console.log(333333333333333333333333);
    return Promise.reject(error);
});
export default service;