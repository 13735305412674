<!-- 包名配置 -->
<template>
  <div>
    <div class="form_box">
      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <el-input v-model="form.parameter_input" placeholder="参数模糊搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="
              pagination.currentPage = 1;
              getList();
            ">搜 索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- table -->
    <div>
      <el-button type="primary" class="btn" @click="
          current = {};
          paramDia = true;
        ">添 加</el-button>
      <el-button type="danger" @click="del" class="btn">批量删除</el-button>
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{
          background: '#f8fbff',
          color: '#606266',
        }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="参数名"></el-table-column>
        <el-table-column fixed="right" label="操作" width="230">
          <template slot-scope="scope">
            <el-button @click="
                current = scope.row;
                paramDia = true;
              " type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="total,prev, pager, next" :total="pagination.total" v-show="pagination.total != 0" :current-page="pagination.currentPage" @current-change="
          (val) => {
            pagination.currentPage = val;
            getList();
          }
        ">
      </el-pagination>
    </div>
    <ParamsOper :visible.sync="paramDia" :current="current" @refresh="
        pagination.currentPage = 1;
        getList();
      "></ParamsOper>
  </div>
</template>
<script>
import { getList, del } from "./api/params";
import ParamsOper from "@/components/operation/SettingManage/components/paramsOper";
export default {
  name: "params",
  components: { ParamsOper },
  data() {
    return {
      form: {
        parameter_input: "",
      },
      tableData: [{ id: 1 }],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      selected: [],
      paramDia: false,
      current: {},
    };
  },
  methods: {
    del() {
      if (this.selected.length == 0) {
        this.$message.error("尚未选择参数");
        return;
      }
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({
            arr:
              this.selected
                .map((item) => {
                  return item.id;
                })
                .join(",") + ",",
            type: "parameter",
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.selected = val;
    },
    getList(page) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        parameter_input: this.form.parameter_input,
        page: page ? page : this.pagination.currentPage,
        per_page: 10,
      }).then((data) => {
        this.tableData = data.data.data.row;
        this.pagination.total = parseInt(data.data.data.count);
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
</style>
