import request from '@/api/request';
import qs from "querystring";


//   获取属性列表
export function getMenuAttrList(param) {
    return request({
        url: `v2/Contentbatch/menuAttrList`,
        method: 'post',
        data: qs.stringify(param),
    });
}

//   获取广告列表
export function getAdsModule(param) {
    return request({
        url: `v2/Content/getAdsModule`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取跳转列表
export function getMenuModule(param) {
    return request({
        url: `v2/Content/getMenuModule`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取资源模板列表-批量launcher接口
export function getTemplateList(param) {
    return request({
        url: `v2/Contentbatch/templateList`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取资源模板laucnher数据-批量launcher接口
export function getLauncher(param) {
    return request({
        url: `v2/Contentbatch/launcher`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   添加资源模板节点-批量launcher接口
export function addTemplateMenu(param) {
    return request({
        url: `v2/Contentbatch/addMenu`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   获取资源模板节点信息-批量launcher接口
export function getTemplateEditMenuJson(param) {
    return request({
        url: `v2/Contentbatch/getEditMenuJson`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   删除资源模板节点-批量launcher接口
export function delTemplateAllMenu(param) {
    return request({
        url: `v2/Contentbatch/delAllMenu`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   节点详情获取1或者删除资源-批量launcher接口
export function showMenuTemplateDetail(param) {
    return request({
        url: `v2/Contentbatch/showMenuContentDetail`,
        method: 'post',
        data: qs.stringify(param),
    });
}
//   节点详情获取1或者删除资源-批量launcher接口
export function releaseVersion(param) {
    return request({
        url: `v2/Contentbatch/releaseVersion`,
        method: 'post',
        data: qs.stringify(param),
    });
}