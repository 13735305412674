import request from "@/api/request";
import qs from "querystring";

//  apk配置列表
export function getList(param) {
    return request({
        url: `v2/batch_set/apkConfig`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  apk配置新增
export function apkAdd(param) {
    return request({
        url: `v2/batch_set/editupdateAPK`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  apk上传检查
export function apkCheck(param) {
    return request({
        url: `v2/batch_set/checkApk`,
        method: "post",
        data: param,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
//  apk配置删除
export function delApk(param) {
    return request({
        url: `v2/batch_set/delallAPK`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  apk配置入库
export function apkIn(param) {
    return request({
        url: `v2/batch_set/insertTable`,
        method: "post",
        data: qs.stringify(param),
    });
}
export function getBrandList(param) {
    return request({
        url: `v2/Managebrand/getList`,
        method: "post",
        data: qs.stringify(param),
    });
}