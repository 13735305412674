<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">信息发布</span>
                <span slot="third">通知管理</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card">
            <div class="title chartTitle blueTitle">通知管理列表</div>
            <el-row class="btnRow">
                <div>
                    <el-button type="success" @click="confirmMeth('publish','发布')" size="medium" class=" largeBtn" v-if="showFunModel('发布')" :disabled="tableOperateDisabled">发布</el-button>
                    <el-button class="largeBtn" type="danger" @click="confirmMeth('del','删除')" size="medium" v-if="showFunModel('删除')">删除</el-button>
                </div>
                <el-button class="largeBtn rightBtn" type="primary" @click="newBuild" size="medium" v-if="showFunModel('新建')">新建</el-button>
            </el-row>
            <table-list @showUploadDialog="showUploadDialog" :table="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
        </el-card>

         <el-drawer
        title="通知详情"
        :visible.sync="contentShow"
        
        size="95%" :modal="false" :before-close="handleClose" :wrapperClosable="false" :destroy-on-close="true"
        >
               <infoPubNoNew ref="infoPubNoNew" @editSuccess="editSuccess" @createSuccess="createSuccess" :query='query'></infoPubNoNew>
        </el-drawer>


        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    </div>
</template>
<script>
    import  qs from 'querystring'
    import commonMeth from '../../api/method'
     import infoPubNoNew  from "./InfoPubNoNew"
    export default {
        components:{
            infoPubNoNew
        },
        name: "hotelManAll",
        data: () => ({
            contentShow:false,
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
            },
            topAttr:{
                terminal:'',
                terminalOption:[
                    {label:'country1',value:'1'},
                    {label:'country2',value:'2'},
                    {label:'country3',value:'3'},
                ],
            },
            table:{
                tableHeader:[
                    {prop:'title',label:'标题',width:''},
                    {prop:'publish_time',label:'发布时间',width:''},
                    {prop:'publish_man',label:'发布人',width:''},
                    {prop:'status_text',label:'状态',width:''},
                ],
                tableList:[ ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'infoPubNotice',
                name:'infoPubNotice',
                option:true,
                select:true,
                loading:true
            },
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px',
            dialogExport:{
                dialog: false,
            },
            active: 0,
            topSearchShow:{ },
            condition:{},
            operateIds:'',
            operateIdsArr:[],
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            query:{},
            funArr:[],
            allData:{},
            tableOperateDisabled:false,
            topSearchSave:{},
            allLoadingState:false,
        }),
        methods:{
               handleClose(done) {
            done();
            },
             createSuccess(){
                 this.contentShow=false;
                 this.tableMeth();
            },
             editSuccess(){
                 this.contentShow=false;
                 this.tableMeth();
            },
            showUploadDialog(val){
                this.query = val;
                console.log(this.query);
                this.contentShow=true;
                this.$refs.infoPubNoNew.getInfoDetail(this.query.id);
                
            },
            newBuild(){
                this.query={
                    fromUrl:'new',
                    fromType:'new',
                    showType:'noticeMan'
                }
                  this.contentShow=true;
            },
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                            // this.delMeth();
                        if(type==='del'){
                            this.delMeth('del','删除');
                        }else if(type==='publish'){
                            this.delMeth('publish','发布');
                        }

                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '请选择消息',
                        type: 'warning'
                    });
                }
            },
            delMeth(type,str){
                var param='';
                var url='';
                var obj={};
                if(type==='del'){
                    url=this.global.infoDestory;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };

                }else if(type==='publish'){
                    url=this.global.infoPublish;
                    obj={
                        token:this.token,
                        ids:this.operateIds
                    };
                }
                param=qs.stringify(obj);
                this.$http.post(url,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success(str+'成功');
                        this.tableMeth();
                    }else if(res.data.code===100002){
                        this.$message.error(res.msg);
                    }
                })
                    .catch(()=>{
                    this.$message.error('该信息已发布');
                })
            },

            next() {
                if (this.active++ > 2) this.active = 0;
            },
            tableMeth(orderVal){
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.$http.get(this.global.infoSearch,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        limit:this.table.limit,
                        per_page:this.table.limit,
                        type:'notice',
                        status:this.condition.info_status,
                        keyword:this.condition.keyword,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getCondition(val,filterPage,filterLimit,type) {
                this.condition.info_status=Array.isArray(val.noticeStatus)?val.noticeStatus.join(','):''; //ka
                this.condition.keyword=val.keyword; //ka
                this.getFilterTableCon(filterPage,filterLimit,type);
                this.tableMeth();
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='group'  || auth_type==='factory'){
                    this.topSearchShow={
                        noticeStatus:true,
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        noticeStatus:true,
                        keyword:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder='请输入标题关键词';
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getTableSelect(val){
                this.operateIds=commonMeth.getTableSelect(val).operateIds;
                this.operateIdsArr=commonMeth.getTableSelect(val).operateIdsArr;
                this.judgeIsPass();
            },
            getFoldMeth(val){
                this.fold=val;
            },
            judgeIsPass(){
                var flag='';
                this.table.tableList.forEach((item,index)=>{
                    if(item.status_text!=='草稿' && this.operateIdsArr .indexOf(item.id.toString())!==-1 ){
                        flag='已处理';
                    }
                });
                flag==='已处理'?this.tableOperateDisabled=true:this.tableOperateDisabled=false;
                return flag;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
            },
            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                this.getCondition(filterCon,filterPage*1,filterLimit*1,'mounted');
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getTopSearchShow();
            this.getFilterCon();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
                this.table.option=res.flag;
            });
        },
        computed:{
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading,false,false);
            },
        }

    }
</script>

<style scoped lang="scss">
    .hotelBrand{
        width:100%;
    }

    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }

    .rightBtn{
        margin-left:auto;
    }
    .largeBtn{
        width: 100px;
        height: 40px;
    }
      ::v-deep .el-drawer__body {
      overflow: auto;
        background-color: #f9f9f9;
        }
</style>
