<template>
  <div>
    <div class="model" v-if="drawerVisible" @click="handleClose"></div>
    <el-drawer class="drawerBox" title="素材配置" size="100%" :visible.sync="drawerVisible" direction="rtl" :modal="false" :before-close="handleClose">
      <el-row :gutter="24" class="rowBox">
        <span class="labelBox" style="">模块名称：</span>
        <el-input style="width:80%;" v-model="moduleChangeTitle" @blur="nameChange" placeholder="请输入内容"></el-input>
      </el-row>
      <el-row :gutter="24" class="rowBox">
        <span class="labelBox" style="">横版封面：</span>
        <div class="uploadBox">
          <el-upload :class="{ picUpload: true, hide: uploadPic }" :action="global.materialUpload" list-type="picture-card"  :headers="headers" :on-preview="handlePictureCardPreview" :on-change="uploadPicChange" :on-success="uploadpicsuccess" :on-remove="uploadPicremove" :before-upload="beforepicUpload" :limit="1" :file-list="nowFileListH">
            <i class="el-icon-plus iconColor"></i>
          </el-upload>
        </div>
      </el-row>
      <el-row :gutter="24" class="rowBox">
        <span class="labelBox" style="">竖版封面：</span>
        <div class="uploadBox">
          <el-upload :action="global.materialUpload" list-type="picture-card"  :headers="headers" :class="{ picUpload: true, hide: uploadPic1 }" :on-preview="handlePictureCardPreview" :on-change="uploadPicChange1" :on-success="uploadpicsuccess1" :on-remove="uploadPicremove1" :before-upload="beforepicUpload" :limit="1" :file-list="nowFileListW">
            <i class="el-icon-plus iconColor"></i>
          </el-upload>
        </div>
      </el-row>
      <div class="root" style="margin-bottom:50px;">
        <el-button class="listBtnStyle" @click="openUpDialog">上传图片</el-button>
        <div class="rootContentBox">
          <img v-if="showUrl!==''" :src="showUrl" alt="">
           <div class="noDataOut" v-if="showUrl===''">
                    <no-data v-if="showUrl===''"><span slot="tips">暂无数据</span></no-data>
            </div>
          <div class="rightBox">
            <div class="title">{{nowAlldata.mname}}</div>
            <div class="topArrow arrow">
              <div class="el-icon-caret-top"></div>
            </div>
            <div class="imgBox" >
              <draggable  class="draggableBox" group="article" :value="nowAlldata.picIn" animation="300" @input="handleListChange($event)" @update="datadragEnd">
                <transition-group class="animatBox">
                  <div class="rightImgItem" :class="{active:curImgIndex===index}" :style="{'backgroundImage':'url('+item.url+')'}" :key="index" v-for="(item,index) in nowAlldata.picIn" @click.stop="showCurImg(index)">
                    <div class="delIcon">
                      <i class="el-icon-close" @click.stop="delMeth(index)"></i>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
            <div class="topArrow arrow">
              <div class="el-icon-caret-bottom"></div>
            </div>
          </div>
        </div>
        </div>
      <div class="btnRow">
        <el-button @click="cancelEdit">取消</el-button>
         <el-button @click="trueEdit" type="primary">保存</el-button>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
     <el-dialog :visible.sync="uploadVisible" width="40%" :modal-append-to-body="false" title="上传图片">
       <div class="uploadBoxForDrawer" >
         <!-- :before-upload="uploadBefore"  -->
            <el-upload class="upload-demo" 
            drag 
            :action="global.materialUpload" 
            :on-success="listSuccess" 
            :headers="headers" 
            :show-file-list="false" 
            :auto-upload="true">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip"></div>
            </el-upload>
          </div>
    </el-dialog>
    <!-- <to-upload :hotelContract="hotelContract" @upsuccessdrawer="upSuccess" ></to-upload> -->
  </div>
</template>
<script>
import editBtn from "../common/editBtn";
import toUpload from "../common/toUpload"
import Draggable from "vuedraggable";
import bus from "../bus";
export default {
  name: "UploadPicSort",
  components: {
    Draggable,
    editBtn,
    toUpload
  },
  props: {
      hotelContract:{
          type:String
      },
      allPicAata: {
      type: Object,
      defanlt: () => {},
    },
  },
  data: () => ({
      headers: {
        token: "",
        Authorization: "",
      },
      btnHidden:false,
    dialogImageUrl: "",
    dialogVisible: false,
    drawerVisible: false,
    headers: {
      token: "",
      Authorization: "",
    },
    itemName: "",
    msg: "",
    nowFileListH: [],
    nowFileListW: [],
    nowAlldata: [],
    historyData:[],
    uploadPic: false,
    uploadPic1: false,
    curImgIndex: 0,
    showUrl: "",
    upNewData:null,
    uploadVisible:false,
    moduleTitle:"",
    moduleChangeTitle:"",
  }),
  created() {
    bus.$on("opendrawer", (itemName, msg) => { 
      this.drawerVisible = true; 
      this.itemName = itemName;
      this.msg = msg;
      this.nowFileListH = [];
      this.nowFileListW = [];
      const jsonH = {
        url: this.allPicAata[itemName]&&this.allPicAata[itemName].picHorizontal
          ? this.allPicAata[itemName].picHorizontal.url
          : null,
      };
      if (jsonH.url&&jsonH.url!=='') {
        this.nowFileListH.push(jsonH);
        this.uploadPic = true;
      }else{
         this.uploadPic = false;
      }
      const jsonW = {
        url: this.allPicAata[itemName]&&this.allPicAata[itemName].picVertical
          ? this.allPicAata[itemName].picVertical.url
          : null,
      };
      if (jsonW.url&&jsonW.url!=='') {
        this.nowFileListW.push(jsonW);
        this.uploadPic1 = true;
      }else{
         this.uploadPic1 = false;
      }
      this.moduleTitle = this.allPicAata[itemName]? this.allPicAata[itemName].mname:null;
       this.moduleChangeTitle = this.allPicAata[itemName]? this.allPicAata[itemName].mname:null;
      this.saveHistoryData();
      if(this.allPicAata[itemName]){
         Object.assign(this.nowAlldata, this.allPicAata[itemName]);
      this.showUrl = this.nowAlldata.picIn[0].url;
      }
    });
    bus.$on("closedrawer", (data) => {
      this.drawerVisible = data;
    });
  },
  computed: {},
  methods: {
    openUpDialog(){
      this.uploadVisible=true;
    },
    listSuccess(res,file,flieList){
        const json ={
              md5:res.data.md5,
              url:res.data.url,
          }
          this.allPicAata[this.itemName].picIn.unshift(json);
           this.$forceUpdate();   
           this.uploadVisible=false;
    },
    saveHistoryData(){
      if(this.allPicAata[this.itemName]){
         this.historyData=JSON.parse(JSON.stringify(this.allPicAata[this.itemName]))
      }
    },
    nameChange(){
      this.allPicAata[this.itemName].mname = this.moduleChangeTitle;
    },
    cancelEdit(){
      this.$emit("canceledit",this.historyData,this.itemName);
      bus.$emit("closedrawer",false)
    },
    trueEdit(){
        this.allPicAata[this.itemName].mname=this.moduleChangeTitle;
        if(this.allPicAata[this.itemName].picHorizontal){
          this.allPicAata[this.itemName].picHorizontal.url=this.nowFileListH[0]?this.nowFileListH[0].url:'';
        }else{
           this.allPicAata[this.itemName].picHorizontal={};
           this.allPicAata[this.itemName].picHorizontal.url=this.nowFileListH[0]?this.nowFileListH[0].url:'';
        }
        if(this.allPicAata[this.itemName].picVertical){
          this.allPicAata[this.itemName].picVertical.url=this.nowFileListW[0]?this.nowFileListW[0].url:'';
        }else{
          this.allPicAata[this.itemName].picVertical={},
          this.allPicAata[this.itemName].picVertical.url=this.nowFileListW[0]?this.nowFileListW[0].url:'';
        }
        console.log(this.allPicAata);
        this.$emit("trueedit",this.allPicAata);
        bus.$emit("closedrawer",false)
    },
    isHidden(){
      this.btnHidden=!this.btnHidden;
    },
    //拖拽结束
    datadragEnd(e) {
      console.log(e);
      console.log( this.nowAlldata.picIn);
      this.allPicAata[this.itemName].picIn =  JSON.parse(JSON.stringify(this.nowAlldata.picIn));
      this.showCurImg(e.newIndex);
    },
    handleListChange(event) {
      this.nowAlldata.picIn = event;
    },
    delMeth(val) {
      this.nowAlldata.picIn.splice(val, 1);
      this.showCurImg(0);
      this.$forceUpdate();
    },
    showCurImg(val) {
      this.curImgIndex = val;
      this.showUrl = this.nowAlldata.picIn[this.curImgIndex]
        ? this.nowAlldata.picIn[this.curImgIndex].url
        : "";
        this.nowAlldata.picIn.forEach((item,index) => {
          item.picorder = (index+1);
        });
         this.allPicAata[this.itemName].picIn =  JSON.parse(JSON.stringify(this.nowAlldata.picIn));
    },
    beforepicUpload(file) {
      const isJPG = file.type === "image/jpeg";
      if (!isJPG) {
        this.$message.error("请上传 JPG 格式图片!");
      }
      return isJPG;
    },
    uploadPicChange(file, fileList) {
      if (this.nowFileListH.length >= 1) {
        this.uploadPic = true;
      }
    },
    uploadPicremove(file) {
      this.uploadPic = false;
      this.nowFileListH = [];
    },
    uploadpicsuccess(response, file, fileList) {
      this.nowFileListH = [];
      const json= {
        md5:response.data.md5,
        url:response.data.url
      }
      this.nowFileListH.push(json);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadPicChange1(file, fileList) {
      if (this.nowFileListW.length >= 1) {
        this.uploadPic1 = true;
      }
    },
    uploadPicremove1(file) {
      this.uploadPic1 = false;
      this.nowFileListW = [];
    },
    uploadpicsuccess1(response, file, fileList) {
      console.log(response);
      this.nowFileListW = [];
      const json= {
        md5:response.data.md5,
        url:response.data.url
      }
      this.nowFileListW.push(json);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          bus.$emit("closedrawer", false);
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  watch: {
    allPicAata: {
      handler: function (newVal) {
        this.allPicAata = newVal;
        Object.assign(this.nowAlldata, this.allPicAata[this.itemName]); 
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
};
</script>

<style scoped >
.listBtnStyle{
     position: absolute;
    top: -40px;
    left: 0px;
   width: 80px;
   height:30px;
   padding: 0;
   text-align: center;
   border-radius: 0;
   line-height: 30px;
   transition: .3s;
   background: rgba(0, 0, 0, .4);
   color: #fff;
}
.listBtnStyle:hover{
  background: rgba(60, 138, 255, .8);
}
/*去除upload组件过渡效果*/
>>> .el-upload-list__item {
  transition: none !important;
}
.model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2000;
}
.drawerBox {
  z-index: 2555;
}
>>> .el-drawer__body {
  padding: 20px;
  overflow: auto;
}
.rowBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.labelBox {
  width: 100px;
  text-align: right;
  margin-right: 15px;
}
/deep/.el-input__inner {
  height: 40px;
}
.hide /deep/ .el-upload--picture-card {
  display: none !important;
}
.root {
  margin: 60px 0;
  width: 100%;
    max-width: 1150px;
    max-height: 500px;
      padding-bottom: 60%;
      box-sizing: border-box;
  position: relative;
  
  box-sizing: border-box;
}
.rootContentBox{
position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; 
      
       border:  none;
      transition: all 1s ease ;
    
      box-sizing: content-box;
}
.rootContentBox > img {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
}
.noDataOut{
    width: 100%;
  height: 100%;
    border: 1px solid #ddd;
}
.rightBox {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.rightBox .title {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
.rightBox img {
  width: 100%;
  height: 100%;
  /*width:170px;*/
  /*height:94px;*/
  object-fit: cover;
}
.rightBox img:last-child {
  margin-bottom: 0;
}
.arrow {
  height: 20px;
  width: 100%;
  text-align: center;
}
.arrow > div {
  font-size: 20px;
  text-align: center;
  color: #9d99b0;
}
.imgBox {
  flex: 1;
  width: 100%;
   overflow: auto;
}
.draggableBox {
  width: 100%;
  height: 100%;
}
.animatBox {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.rightImgItem {
  width: 90%;
  height: 22%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  border: 3px solid transparent;
  cursor: pointer;
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
}
.rightImgItem.active {
  border: 3px solid #ffe699;
}
/*配置按钮样式结束*/
.draggableBox {
  height: 100%;
}
.delIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}
.btnRow{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.btnRow .el-button{
  width: 100px;
  height: 40px;
  margin-left: 30px;
}
.uploadBoxForDrawer{
  text-align: center;
  margin: 20px; 
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
