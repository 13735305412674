<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="700px" :before-close="handleClose" append-to-body>
      <div class="container">
        <template>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <template v-if="!ruleForm.id">
              <el-form-item label="参数名" prop="canshuName">
                 <el-select style="width:100%" v-model="ruleForm.canshuName" placeholder="请输入参数名" filterable>
                    <el-option v-for="item in paramsList" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="参数名称">
                <div>{{ruleForm.canshuName}}</div>
              </el-form-item>
              <!-- 普通类型 -->
              <template v-if="type == 1">
                <el-form-item label="top值" prop="x">
                  <el-input v-model="ruleForm.x" placeholder="请输入top值"></el-input>
                </el-form-item>
                <el-form-item label="left值" prop="y">
                  <el-input v-model="ruleForm.y" placeholder="请输入left值"></el-input>
                </el-form-item>
              </template>
              <!-- 下拉框类型 x绑定 -->
              <template v-else-if="type == 2 ">
                <el-form-item label="选择" prop="x">
                  <el-select v-model="ruleForm.x" placeholder="请选择">
                    <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
              <!-- 下拉框类型 y绑定 -->
              <template v-else-if="type == 6">
                <el-form-item label="选择" prop="y">
                  <el-select v-model="ruleForm.y" placeholder="请选择">
                    <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
              <!-- 留言 -->
              <template v-else-if="type == 3">
                <el-form-item label="留言" prop="x">
                  <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ruleForm.x">
                  </el-input>
                </el-form-item>
                <el-form-item label="属性" prop="y">
                  <el-select v-model="ruleForm.y" placeholder="请选择">
                    <el-option value='1' label="中文"></el-option>
                    <el-option value='2' label="英文"></el-option>
                    <el-option value='3' label="日文"></el-option>
                    <el-option value='4' label="韩文">韩文</el-option>
                  </el-select>
                </el-form-item>
              </template>
              <!-- 音乐 -->
              <template v-else-if="type == 4">
                <el-form-item label="选择" prop="">
                  <el-select v-model="ruleForm.y" placeholder="请选择">
                    <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="播放次数" prop="y">
                  <el-input type="" :rows="2" placeholder="请输入播放次数" v-model="ruleForm.y">
                  </el-input>
                </el-form-item>
              </template>

            </template>
          </el-form>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  create,
  updateData,
  getMenuModule,
} from "../../api/infrastructure/Parameter";
// import UploadImage from "./UploadImage.vue";
import { getList } from "@/components/operation/SettingManage/api/params";
export default {
  components: {
    // UploadImage
  },
  data() {
    return {
      title: "",
      database: "",
      dialogVisible: false,
      ruleForm: {
        database: "",
        id: "",
        canshuName: "",
        x: "",
        y: "",
      },
      type: 0, // 0 不可修改, 1 普通类型 ，2 下拉框类型,3 留言 ,4 音乐,5 电视信号类型,6 获取动态数据下拉框
      rules: {
        canshuName: [
          { required: true, message: "请输入中文名称", trigger: "blur" },
        ],
      },
      btnLoading: false,
      selectList: [],
      selectRoomList: [], // 电视信号类型
      menuModuleList: [], // 主页默认跳转,兑换默认跳转,消费默认跳转 类型选择
      typeList: [
        {
          name: "电视信号类型", // 条件有这个名称字符串存在，但是不等于这个名称，
          type: 2,
          apiData: "selectRoomList",
          option: [
            { value: "ATV", label: "ATV" },
            { value: "DTMB", label: "DTMB" },
            { value: "DTV", label: "DTV" },
            { value: "CVBS", label: "CVBS" },
            { value: "HDMI1", label: "HDMI1" },
            { value: "HDMI2", label: "HDMI2" },
            { value: "HDMI3", label: "HDMI3" },
            { value: "HDMI", label: "HDMI" },
            { value: "LOCAL", label: "LOCAL" },
            { value: "IPTV", label: "IPTV" },
          ],
        },
        {
          name: "空房是否收费", // 不可修改
          type: 0,
        },
        {
          name: "电脑选择",
          type: 2,
          option: [
            { label: "默认", value: "默认" },
            { label: "Andriod", value: "Andriod" },
            { label: "PC", value: "PC" },
          ],
        },
        {
          name: "电脑选择",
          type: 2,
          option: [
            { value: "默认", label: "默认" },
            { value: "视频1强制", label: "视频1强制" },
            { value: "视频1", label: "视频1" },
            { value: "视频2强制", label: "视频2强制" },
            { value: "视频2", label: "视频2" },
            { value: "全部显示强制", label: "全部显示强制" },
            { value: "全部显示", label: "全部显示" },
            { value: "视频1强制视频2", label: "视频1强制视频2" },
            { value: "视频1视频2强制", label: "视频1视频2强制" },
          ],
        },
        {
          name: "电视消息",
          type: 2,
          option: [
            { value: "弹出", label: "弹出" },
            { value: "滚动", label: "滚动" },
          ],
        },
        {
          name: "模版选择",
          type: 2,
          option: [
            { value: "默认", label: "默认" },
            { value: "十个pad", label: "十个pad" },
          ],
        },
        {
          name: "开房选择",
          type: 2,
          option: [
            { value: "默认", label: "默认" },
            { value: "手动", label: "手动" },
            { value: "自动", label: "自动" },
          ],
        },
        {
          name: "天气选择",
          type: 2,
          option: [
            { value: "默认", label: "默认" },
            { value: "隐藏", label: "隐藏" },
          ],
        },
        {
          name: "logo选择",
          type: 2,
          option: [
            { value: "默认", label: "默认" },
            { value: "隐藏", label: "隐藏" },
          ],
        },
        {
          name: "设备连接",
          type: 2,
          option: [
            { value: "LAN", label: "LAN" },
            { value: "WAN", label: "WAN" },
          ],
        },
        {
          name: "广告启用",
          type: 2,
          option: [
            { value: "1", label: "启用" },
            { value: "2", label: "禁用" },
          ],
        },
        {
          name: "客户端启用",
          type: 2,
          option: [
            { value: "1", label: "启用" },
            { value: "2", label: "禁用" },
          ],
        },
        {
          name: "电影模式",
          type: 2,
          option: [
            { value: "mont", label: "mont" },
            { value: "http", label: "http" },
          ],
        },
        {
          name: "主页模式",
          type: 2,
          option: [
            { value: "1", label: "通用" },
            { value: "2", label: "小帅影院" },
          ],
        },
        {
          name: "界面选择",
          type: 2,
          option: [
            { value: "1", label: "弹窗式" },
            { value: "2", label: "卡片式" },
          ],
        },
        {
          name: "电影下载类型",
          type: 2,
          option: [
            { value: "xs", label: "小帅影院" },
            { value: "m1905", label: "m1905" },
            { value: "all", label: "全部" },
          ],
        },
        {
          name: "电影下载类型",
          type: 2,
          option: [
            { value: "xs", label: "小帅影院" },
            { value: "m1905", label: "m1905" },
            { value: "all", label: "全部" },
          ],
        },
        {
          name: "主页默认跳转",
          type: 6,
          apiData: "menuModuleList",
          option: [], //动态获取/system/getMenuModule
        },
        {
          name: "兑换默认跳转",
          type: 6,
          apiData: "menuModuleList",
          option: [], //动态获取/system/getMenuModule
        },
        {
          name: "消费默认跳转",
          type: 6,
          apiData: "menuModuleList",
          option: [], //动态获取/system/getMenuModule
        },
        {
          name: "电视信号类型",
          type: 2,
          option: [
            { value: "ATV", label: "ATV" },
            { value: "DTMB", label: "DTMB" },
            { value: "DTV", label: "DTV" },
            { value: "CVBS", label: "CVBS" },
            { value: "HDMI1", label: "HDMI1" },
            { value: "HDMI2", label: "HDMI2" },
            { value: "HDMI3", label: "HDMI3" },
            { value: "HDMI", label: "HDMI" },
            { value: "LOCAL", label: "LOCAL" },
            { value: "IPTV", label: "IPTV" },
          ],
        },
        {
          name: "酒店留言滚动",
          type: 3,
        },
        // {
        //   type: 4,
        //   name: '背景音乐模式',  //显示音乐模板
        //   option: [
        //     { value: '-1' ,label: '循环播放'},
        //     { value: '0' ,label: '禁止播放'},
        //     { value: '1' ,label: '播放次数'},
        //    ],
        // },
      ],
      paramsList: [],
    };
  },
  mounted() {},
  methods: {
    getParamsList() {
      getList({
        page: 1,
        per_page: 9999,
      }).then((data) => {
        this.paramsList = data.data.data.row;
      });
    },
    async open(title, database, row) {
      this.title = title;
      this.database = database;
      this.getParamsList();
      if (this.menuModuleList.length <= 0) {
        let {
          data: { data },
        } = await getMenuModule({ database: this.database });
        let list = [];
        if (data) {
          data.forEach((item) => {
            let obj = {
              value: item.mname,
              label: item.mname,
            };
            list.push(obj);
          });
        }
        this.menuModuleList = list;
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (!row || row.id == "" || row.id === null) {
          // 新增
          for (const key in this.ruleForm) {
            if (Object.hasOwnProperty.call(this.ruleForm, key)) {
              this.ruleForm[key] = "";
            }
          }
        } else {
          // 编辑

          this.ruleForm = {
            database: "",
            id: row.id,
            canshuName: row.name,
            x: row.x,
            y: row.y,
          };
          for (let index = 0; index < this.typeList.length; index++) {
            const item = this.typeList[index];
            // console.log(item.name ,row.name);
            if (row.name.indexOf("电视信号类型") != -1) {
              this.type = item.type;
              if (row.name != "电视信号类型") {
                // 条件: 有这个名称字符串存在，但是不等于这个名称,则获取下拉框数据
              }
              this.selectList = item.option;
              return;
            } else if (item.name == row.name) {
              // console.log(item.type);
              this.type = item.type;
              if (item.type == 0) {
                this.$message.error("此项目不可修改");
              } else if (item.type == 1) {
                // 普通类型
              } else if (item.type == 2) {
                this.selectList = item.option;
                console.log(item.option, 555555555555);
              } else if (item.type === 3) {
                // 留言
              } else if (item.type === 4) {
                // 音乐
              } else if (item.type == 6) {
                // 动态获取下拉框数据
                this.selectList = this[item.apiData];
                // console.log(this[item.apiData]);
              }
              return;
            } else {
              this.type = 1;
            }
          }
        }
      });
    },
    handleClose() {
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = "";
        }
      }
      this.dialogVisible = false;
    },
    async submitForm(formcanshuName) {
      this.$refs[formcanshuName].validate(async (valid) => {
        if (valid) {
          let res;
          this.ruleForm.database = this.database;
          this.btnLoading = true;
          if (this.ruleForm.id) {
            // 编辑
            console.log(this.ruleForm);
            this.edit();
            console.log(this.ruleForm.x);
            let json = {
              database: this.ruleForm.database
                ? this.ruleForm.database.trim()
                : "",
              id: this.ruleForm.id ? this.ruleForm.id.trim() : "",
              canshuName: this.ruleForm.canshuName
                ? this.ruleForm.canshuName.trim()
                : "",
              x: this.ruleForm.x ? this.ruleForm.x.trim() : "",
              y: this.ruleForm.y ?this.ruleForm.y.trim() : "",
            };
            console.log(json);
            res = await updateData(json);
          } else {
            let json = {
              database: this.ruleForm.database
                ? this.ruleForm.database.trim()
                : "",
              canshuName: this.ruleForm.canshuName
                ? this.ruleForm.canshuName.trim()
                : "",
              x: this.ruleForm.x ? this.ruleForm.x.trim() : "",
              y: this.ruleForm.y ? this.ruleForm.y.trim() : "",
            };
            // 新增
            res = await create(json);
          }
          if (res.data.code === 100000) {
            let tips = this.ruleForm.id ? "编辑" : "新增";
            this.$message({
              showClose: true,
              message: `${tips}成功`,
              type: "success",
            });
            this.dialogVisible = false;
            this.$parent.getList();
            this.btnLoading = false;
          } else {
            this.$message.error(res.data.msg);
          }
          this.btnLoading = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit() {
      let x = this.ruleForm.x;
      let y = this.ruleForm.y;
      switch (this.ruleForm.canshuName) {
        case "广告启用":
        case "客户端启用":
          y = "启用";
          if (x == 2) {
            y = "禁用";
          }
          break;
        case "主页模式":
          y = "通用";
          if (x == 2) {
            y = "影院";
          }
          break;
        case "界面选择":
          y = "弹窗式";
          if (x == 2) {
            y = "卡片式";
          }
          break;
        case "电影下载类型":
          y = "影院";
          if (x == "m1905") {
            y = "m1905";
          } else if (x == "all") {
            y = "全部";
          }
          break;
        case "主页默认跳转":
        case "兑换默认跳转":
        case "消费默认跳转":
          this.selectList.forEach((item, index) => {
            if (y == item.value) {
              if(index ==0){
                x = '0';
              }else{
                  x =String(index) ;
              }
              
            }
          });
          break;
        case "电视信号类型":
          break;
      }
      this.ruleForm.x = x;
      this.ruleForm.y = y;
    },
  },
};
</script>

<style>
</style>