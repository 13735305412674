import request from "@/api/request";
import qs from "querystring";

// 模板配置列表
export function getList(param) {
  return request({
    url: `v2/Managetemplate/getList`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 版式列表
export function getBan(param) {
  return request({
    url: `v2/Managetemplate/getTemplateType`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 模板配置新增
export function add(param) {
  return request({
    url: `v2/Managetemplate/addTemplate`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 模板配置修改
export function edit(param) {
  return request({
    url: `v2/Managetemplate/editTemplate`,
    method: "post",
    data: qs.stringify(param),
  });
}
// 模板配置删除
export function del(param) {
  return request({
    url: `v2/Managetemplate/delTemplate`,
    method: "post",
    data: qs.stringify(param),
  });
}
