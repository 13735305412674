<template>
  <div>
    <!-- 合同列表 -->
    <el-table :data="tableData" ref="accountTable" style="width: 100%; margin-top: 10px" :header-cell-style="headerStyle" v-loading="tableLoading">
      <el-table-column prop="contract_number" label="合同号" show-overflow-tooltip width="200" fixed></el-table-column>
      <el-table-column prop="company_name" label="渠道商名称" show-overflow-tooltip width="200" fixed></el-table-column>
      <el-table-column prop="contract_date_begin" label="合同开始时间" show-overflow-tooltip min-width="160"></el-table-column>
      <el-table-column prop="contract_date_end" label="合同结束时间" show-overflow-tooltip min-width="160"></el-table-column>
      <el-table-column prop="status" label="状态" show-overflow-tooltip min-width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.status=='1'">生效</span>
          <span v-if="scope.row.status=='0'">未生效</span>
        </template>
      </el-table-column>
      <el-table-column prop="calculate_start_time" label="阶梯计算开始时间" show-overflow-tooltip min-width="160"></el-table-column>
      <el-table-column prop="calculate_end_time" label="阶梯计算结束时间" show-overflow-tooltip min-width="160"></el-table-column>
      <el-table-column prop="contract_num" label="合同拓展数量目标" show-overflow-tooltip min-width="140"></el-table-column>
      <el-table-column prop="base_on_text" label="分成阶梯计算标准" show-overflow-tooltip min-width="180"></el-table-column>
      <!-- <el-table-column label="合同期末激活终端数" show-overflow-tooltip min-width="210">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'7','contract_room_number')">{{scope.row.contract_room_number}}</el-button>
          </template>
        </el-table-column> -->
      <!-- <el-table-column label="合同期末有效激活终端数" show-overflow-tooltip min-width="210">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'1','contract_valid_number')">{{scope.row.contract_valid_number}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="合同期末影视有效激活终端数" min-width="220" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'2','contract_valid_movie_number')">{{scope.row.contract_valid_movie_number}}</el-button>
          </template>
        </el-table-column>
           <el-table-column label="累计丢失激活终端数" min-width="180">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'8','loss_room_number')">{{scope.row.loss_room_number}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="累计丢失有效激活终端数" min-width="180">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'3','loss_valid_number')">{{scope.row.loss_valid_number}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="累计丢失有效影视激活终端数" min-width="220" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'4','loss_valid_movie_number')">{{scope.row.loss_valid_movie_number}}</el-button>
          </template>
        </el-table-column>
          <el-table-column label="当前激活终端数" min-width="140" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'9','current_room_number')">{{scope.row.current_room_number}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="当前有效激活终端数" min-width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'5','current_valid_number')">{{scope.row.current_valid_number}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="当前有效影视激活终端数" min-width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row,'6','current_valid_movie_number')">{{scope.row.current_valid_movie_number}}</el-button>
          </template>
        </el-table-column> -->
      <el-table-column prop="" label="目前分成比例" min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.calculate_text!==''">单点：{{scope.row.calculate_text===0?"0.00%":scope.row.calculate_text}}</div>
          <div v-if="scope.row.calculate_card_text!==''">天卡：{{scope.row.calculate_card_text===0?"0.00%":scope.row.calculate_card_text}}</div>
          <div v-if="scope.row.calculate_text===''">单点：未配置</div>
          <div v-if="scope.row.calculate_card_text===''">天卡：未配置</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="remarks" label="备注" min-width="200" show-overflow-tooltip></el-table-column> -->

    </el-table>
    <div class="msgText">备注：状态为实施中、使用中或已离线且影视合作方式为极光TV。</div>
    <div class="msgText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如没有历史年份合同代表该年未完成业绩要求。</div>
    <!-- 合同列表 -->
    <!-- 详情界面 -->
    <el-drawer title="数据详情" :visible.sync="numberContentVisible" size="110%" :destroy-on-close="true" :modal="false" :wrapperClosable="false">
      <el-table :data="contentTableList" v-loading='contentTableLoding' ref="accountTable" style="width: 100%; margin-top: 10px" :header-cell-style="headerStyle">
        <el-table-column prop='hotel_name' label="酒店名称" show-overflow-tooltip min-width="240px"></el-table-column>
        <el-table-column prop='hotel_brand' label="品牌" show-overflow-tooltip min-width="100px"></el-table-column>
        <el-table-column prop='hotel_create_time_text' label="建站时间" show-overflow-tooltip min-width="160"></el-table-column>
        <el-table-column prop='province' label="省份" show-overflow-tooltip min-width="120px"></el-table-column>
        <el-table-column prop='city' label="城市" show-overflow-tooltip min-width="120px"></el-table-column>
        <el-table-column prop='county' label="区县" show-overflow-tooltip min-width="120px"></el-table-column>
        <el-table-column v-if="contentTableType=='5'||contentTableType=='6'" prop='room_num' label="客房数" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="contentTableType=='5'||contentTableType=='6'" prop='active_terminal_num' label="激活终端数" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='contract_valid_number' v-if="contentTableType=='1'" label="合同期末有效激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='contract_valid_movie_number' v-if="contentTableType=='2'" label="合同期末影视有效激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='loss_valid_number' v-if="contentTableType=='3'" label="累计丢失有效激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='loss_valid_movie_number' v-if="contentTableType=='4'" label="累计丢失影视有效激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='current_valid_number' v-if="contentTableType=='5'||contentTableType=='6'" label="当前有效激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='current_valid_movie_number' v-if="contentTableType=='5'||contentTableType=='6'" label="当前影视有效激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='contract_room_number' v-if="contentTableType=='7'" label="合同期末激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='loss_room_number' v-if="contentTableType=='8'" label="累计丢失激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column prop='current_room_number' v-if="contentTableType=='9'" label="当前激活终端数" min-width="260px" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination class="pageChange" @size-change="numberContentSizeChange" @current-change="numberContentCurrentChange" :current-page.sync="contentTable.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="contentTable.limit" layout="total, sizes, prev, pager, next, jumper" :total="contentTable.total">
      </el-pagination>
    </el-drawer>
    <!-- 详情界面 -->
  </div>
</template>
<script>
import { getTrackingList, getTrackingInfo } from "@/api/dataCenter.js";
import qs from "querystring";
export default {
  data() {
    return {
      bread: {
        level: 3,
      },
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      tableData: [],
      tableLoading: false,
      contentTableList: [],
      contentTable: {
        page: 1,
        limit: 5,
        total: 0,
      },
      openId: "",
      numberContentVisible: false,
      contentTableLoding: false,
      contentTableType: "",
      auth: {},
      topSearchShow: {
        keyword: true,
        onlyYear: true,
        keywordPlaceholder: "渠道商名称关键字",
      },
      company_name: "",
      contract_year: "",
      field: "",
    };
  },
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getTableList();
  },
  methods: {
    // 获取详情列表
    getContentList(id) {
      this.contentTableLoding = true;
      let json = {
        contract_id: this.openId,
        page: this.contentTable.page,
        limit: this.contentTable.limit,
        field: this.field,
      };
      var param = qs.stringify(json);
      getTrackingInfo(param)
        .then((res) => {
          this.contentTableList = res.data.data.data;
          this.contentTable.total = res.data.data.total;
          this.contentTableLoding = false;
        })
        .catch((err) => {});
    },
    // 打开详情界面
    openContent(row, type, field) {
      this.numberContentVisible = true;
      this.openId = row.contract_id;
      this.contentTable.page = 1;
      this.contentTableType = type;
      this.field = field;
      this.getContentList(this.openId);
    },
    // 关闭详情界面
    closeDrawer() {
      this.contentTableType = "";
      this.field = "";
      this.numberContentVisible = false;
    },
    // 获取合同列表数据
    getTableList() {
      this.tableLoading = true;
      let json = {
        page: 1,
        limit: 10,
      };
      var param = qs.stringify(json);
      getTrackingList(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData = res.data.data.data;
            for (var i = 0; i < this.tableData.length; i++) {
              var label = this.tableData[i].base_on;
              if (label == "active_terminal_num") {
                this.tableData[i].base_on_text = "激活终端数量";
              } else if (label == "valid_active_terminal_num") {
                this.tableData[i].base_on_text = "有效激活终端数量";
              } else if (label == "new_movie_terminal_num") {
                this.tableData[i].base_on_text = "新增影视有效激活终端";
              } else if (label == "dau") {
                this.tableData[i].base_on_text = "日活量";
              } else if (label == "dar") {
                this.tableData[i].base_on_text = "日活率";
              } else if (label == "hotel") {
                this.tableData[i].base_on_text = "酒店";
              } else if (label == "order") {
                this.tableData[i].base_on_text = "订单";
              }
            }
          }
          this.tableLoading = false;
        })
        .catch((err) => {});
    },
    // 详情分页
    numberContentSizeChange(limit) {
      this.contentTable.limit = limit;
      this.getContentList(this.openId);
    },

    numberContentCurrentChange(page) {
      this.contentTable.page = page;
      this.getContentList(this.openId);
    },
  },
};
</script>
<style scope>
.el-drawer__body {
  overflow: hidden;
  overflow-y: visible;
  padding: 15px 20px 0 !important;
}
.msgText {
  margin-top: 10px;
  color: #a3a7af;
  font-size: 12px;
}
</style>