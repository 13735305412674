<template>
    <div class="main">
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">酒店管理</span>
                <span slot="third">{{bread.thirdName}}</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card cardCenter">

        </el-card>
    </div>
</template>

<script>
    export default {
        name: "ToBeConfiguredHotelDetail"
    }
</script>

<style scoped>

</style>
