<template>
    <div class="container">
        <div class="fullBg">
            <div class="left">
                <div class="title">
                    <div class="titleImg">
                        <img src="../assets/img/login/logo.png" alt="">
                    </div>
                    <span class="titleText">合作伙伴收益管理平台</span>
                </div>
                <div style="" class="imgOutBox">
                    <div class="imgBox">
                        <!--<img src="../assets/img/login/illustration.png" alt="">-->
                    </div>
                </div>
            </div>
            <form :model="registerForm" class="loginForm" :rules="rules">
                <div class="right">
                    <div class="formTitle">
                        欢迎注册
                    </div>
                    <div class="formCon">
                        <div class="input_item" >
                            <el-input max="11" class="inputBox phone_input" style="" v-model="registerForm.company_name" placeholder="请输入所属公司名称" @focus="inputFocus('company_name')" @blur="inputBlur('company_name')">
                                <i slot="prefix" class="el-input__icon el-icon-s-shop icon" :class="iconColor.company_name" ></i>
                            </el-input>
                            <div class="code_error error" v-if="companyErrorText!==''">{{companyErrorText}}</div>
                        </div>
                        <div class="input_item" >
                            <el-input max="11" class="inputBox phone_input" style="" v-model="registerForm.contact_name" placeholder="请输入联系人" @focus="inputFocus('contact_name')" @blur="inputBlur('contact_name')">
                                <i slot="prefix" class="el-input__icon el-icon-s-custom icon" :class="iconColor.contact_name"  ></i>
                            </el-input>
                            <div class="code_error error" v-if="contactNameErrorText!==''">{{contactNameErrorText}}</div>
                        </div>
                        <div class="input_item" >
                            <el-input max="11" class="inputBox phone_input" style="" v-model="registerForm.mobile" placeholder="请输入手机号" @focus="inputFocus('mobile')" @blur="inputBlur('mobile')">
                                <i slot="prefix" class="el-input__icon el-icon-phone icon" :class="iconColor.mobile"></i>
                            </el-input>
                            <div class="code_error error" v-if="mobileErrorText!==''">{{mobileErrorText}}</div>
                        </div>
                        <div class="input_item">
                            <!--<input type="text" class="input_attr code_input" placeholder="请输入验证码" @focus="inputFocus" @input="codeVerify" v-model="registerForm.code">-->
                            <div>
                                <el-input max="11" class="inputBox" style="" v-model="registerForm.code" placeholder="请输入验证码" @focus="inputFocus('code')" @blur="inputBlur('code')">
                                    <i slot="prefix" class="el-input__icon el-icon-s-claim icon" :class="iconColor.code"></i>
                                </el-input>
                                <div class="code_error error" v-if="codeErrorText!==''">{{codeErrorText}}</div>
                            </div>

                            <!--<span class="send_code" v-if="!showCountDown" @click="sendCode">获取验证码</span>-->
                            <el-button class="send_code" v-if="!showCountDown" type="primary" @click="sendCode" size="mini">点击获取验证码</el-button>
                            <span class="send_code countDown" v-if="showCountDown">{{second}}s后重新获取</span>
                            <i class="del_icon iconfont icon-shanchu"></i>
                            <!--<div class="code_error error" v-if="errorText!==''">{{errorText}}</div>-->
                            <!--<div class="code_tips">验证码有效期5分钟</div>-->
                        </div>
                    </div>
                    <div class="formBtn">
                        <el-button class="btnItem whiteBtn" @click="toLogin">登录</el-button>
                        <el-button class="btnItem blueBtn"  type="primary" @click="submitForm">注册</el-button>
                    </div>
                </div>
            </form>
        </div>
        <el-dialog
                :visible.sync="dialogVisible"
                width="30%"
                :show-close="false"
                >
            <div class="successImgBox">
                <img src="../assets/img/login/successIcon.png" alt="">
            </div>
            <div class="successTitle">信息提交成功</div>
            <div class="successText">系统将在{{successSecond}}秒之后跳转到首页，如果没有请 <span @click="toIndex" class="toIndex">点击手动跳转</span></div>
        </el-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "login",
        data: () => ({
            registerForm:{
                username:'',
                company_name:'',
                contact_name:'',
                mobile:'',
                code:'',
                token:''
            },
            errorText:'',
            phoneErrorText:'',
            codeErrorText:'',
            companyErrorText:'',
            contactNameErrorText:'',
            mobileErrorText:'',
            second:'60',
            successSecond:'5',
            showCountDown:'',
            show:false,
            iconColor:{
                company_name:'',
                contact_name:'',
                mobile:'',
                code:''
            },
            rules:{
                mobile: [
                    { required: true, message: '111', trigger: 'change' }
                ],
            },
            dialogVisible: false
        }),

        methods:{
            sendCodeMeth(){
                var param=qs.stringify({mobile:this.registerForm.mobile,event:'register'});
                this.$http.post(this.global.send,param).then(res=>{
                    if(res.data.code===1){
                        this.showCountDown=true;
                        if(res.data.code===0){
                            this.$message(res.data.msg);
                        }
                        var interval = setInterval(() => {
                            --this.second
                        }, 1000);
                        setTimeout(() => {
                            clearInterval(interval);
                            this.showCountDown=false;
                            this.second = 60;
                        }, 60000);
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                })
            },
            loginMeth(param){
                return new Promise((resolve,reject)=>{
                    this.$http.post(this.global.mobileLogin,param).then(res=>{
                        var code=res.data.code;
                        if(code===100000){
                            return resolve(res.data);
                        }else if(code===100001){
                            this.codeErrorText='验证码错误'
                        }
                    });
                })
            },
            mobileRule(){
                if(this.registerForm.mobile===''){
                    this.mobileErrorText='请输入手机号';
                    return false;
                }else if(!(/^1[3456789]\d{9}$/.test(this.registerForm.mobile))){
                    this.mobileErrorText='手机号码格式不正确！';
                    return false;
                }else{
                    return true;
                }
            },
            allRule(){
                this.clearError();
                if(this.registerForm.company_name===''){
                    this.companyErrorText='请输入所属公司名称';
                    return false;
                }else  if(this.registerForm.contact_name===''){
                    this.contactNameErrorText='请输入联系人';
                    return false;
                }else if(this.registerForm.mobile===''){
                    this.mobileErrorText='请输入手机号';
                    return false;
                }else if(!(/^1[3456789]\d{9}$/.test(this.registerForm.mobile))){
                    this.mobileErrorText='手机号码格式不正确！';
                    return false;
                }else if(this.registerForm.code===''){
                    this.codeErrorText='请输入验证码';
                    return false;
                }else{
                    return true;
                }
            },
            clearError(){
                this.companyErrorText='';
                this.contactNameErrorText='';
                this.mobileErrorText='';
                this.mobileErrorText='';
                this.codeErrorText='';
            },

            submitForm(formName) {
                if(this.allRule()){
                    this.registerForm.token=this.token;
                    var param=qs.stringify(this.registerForm);
                    this.registerMeth(param);
                }
            },
            toIndex(){
                this.$router.push({path:'/indexShow'})
            },
            toLogin(){
                this.$router.push({path:'/login'})
            },
            successCountDown(){
                var interval = setInterval(() => {
                    --this.successSecond
                }, 1000);
                setTimeout(() => {
                    clearInterval(interval);
                    this.successSecond = 5;
                    this.toIndex();
                }, 5000);
            },
            registerMeth(param){
                return new Promise((resolve,reject)=>{
                    this.$http.post('v1/admin/register',param).then(res=>{
                        if(res.data.code==='Captcha is incorrect'){
                            this.codeErrorText='验证码错误';
                        }else if(res.data.code===100002){
                            this.codeErrorText=res.data.msg;
                        }else if(res.data.code===100000){
                            var token=res.data.data.userinfo.token;
                            var auth=res.data.auth;
                            this.dialogVisible=true;
                            this.successCountDown();
                            // this.$router.push({path:'/registerSuccess'});
                            sessionStorage.setItem('auth',JSON.stringify(auth));
                            sessionStorage.setItem('token',token);
                        }
                    });
                })
            },
            sendCode(){
                if(this.mobileRule()){   //手机号码通过验证
                    this.sendCodeMeth();
                }
            },
            inputFocus(val){
                var color='blue';
                switch(val){
                    case 'company_name':
                        this.iconColor.company_name=color;
                        break;
                    case 'contact_name':
                        this.iconColor.contact_name=color;
                        break;
                    case 'mobile':
                        this.iconColor.mobile=color;
                        break;
                    case 'code':
                        this.iconColor.code=color;
                        break;
                }
                this.clearError();
            },
            inputBlur(val){
                var color='gray';
                switch(val){
                    case 'company_name':
                        this.iconColor.company_name=color;
                        break;
                    case 'contact_name':
                        this.iconColor.contact_name=color;
                        break;
                    case 'mobile':
                        this.iconColor.mobile=color;
                        break;
                    case 'code':
                        this.iconColor.code=color;
                        break;
                }
                this.clearError();
            },

            login(){
                if(this.mobileRule()){   //手机号码通过验证
                    if(this.registerForm.code===''){
                        this.codeErrorText='请输入验证码';
                    }else{
                        var param={mobile:this.registerForm.mobile,code:this.registerForm.code};
                        param=qs.stringify(param);
                        // return false;
                        this.loginMeth(param).then(res=>{
                            var token=res.data.userinfo.token;
                            var auth=res.auth;
                            sessionStorage.setItem('auth',JSON.stringify(auth));
                            sessionStorage.setItem('token',token);
                            this.$message({
                                message: '登录成功',
                                type: 'success'
                            });
                            this.$router.push({path:'/home',params:{token:token}});
                        })

                    }
                }
            },
            // return false;
            toRegiter(){
                this.$router.push({path:'/register'})
            }
        }
    }
</script>

<style scoped>
    /*@import "../style/basic.css";*/
    @import "../assets/css/basic.css";
    body,html{width:100%;height:100%;}
    input:-webkit-autofill {-webkit-box-shadow: 0 0 0px 1000px white inset;}
    .container{
        width:100vw;
        height:100vh;
        display:flex;
    }

    h3{
        font-size:26px;
    }
    /*右边登录框开始*/
    .input_item{
        height:75px;
        margin-top:15px;
        position:relative;
        box-sizing:border-box;
    }
    .el-input{
        font-size:18px;
    }
    .input_item>input{
        width:100%;
        height:100%;
        float:left;
        position:relative;
        top:50%;
        transform:translateY(-50%);
        font-size:18px;
        letter-spacing: 1px;
        padding:0 30px;
        box-sizing:border-box;

    }
    .del_icon{
        float:right;
        position:relative;
        top:50%;
        transform:translateY(-50%);
        opacity:0;
        transition:0.5s;
        cursor:pointer;
    }
    .del_icon.active{
        opacity:1;
        transition:0.5s;
    }
    .send_code{
        /*float:right;*/
        height:38px;
        font-size:16px;
        display:inline-block;
        vertical-align:middle;
        margin-left:58px;
        cursor:pointer;
        position:absolute;
        right:10px;
        top:20px;
    }
    .send_code.countDown{
        color:#999;
    }
    .send_code.gray{
        color:#808080;
    }

    .btnItem{
        width:45%;
        height:60px;
        /*background:#169688;*/
        /*color:#fff;*/
        font-size:18px;
    }
    .whiteBtn{
        color:#0066ff;
        border:1px solid #0066ff;
    }
    .blueBtn{
        box-shadow:0 5px 15px 0 rgba(0,102,255,0.5);
    }


    /*错误提示*/
    .error{
        position:absolute;
        bottom:-20px;
        left:0;
        z-index:999;
        color:red;
        font-size:16px;
        transition:0.5s;
        /*opacity:0;*/
    }
    .error.active{
        /*opacity:1;*/
        transition:0.5s;
    }

    .inputBox{
        height:70px!important;
    }
    input.el-input__inner{
        height:70px!important;
    }
    /deep/ .el-input__inner{
        height:70px!important;
    }
    /*.el-button--primary{*/
    /*background:#169688!important;*/
    /*}*/
    /*.el-button--primary:hover{*/
    /*background:#137F72!important;*/
    /*}*/

    /*全屏登录页面开始*/
    .fullBg{
        background-image:url("../assets/img/login/bg.png");
        /*background-size:cover;*/
        background-size:100% 100%;
        background-repeat:no-repeat;
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        /*background-size:10% auto;   //调整大小*/
        /*background-position:bottom center;  //调整背景图片的位置*/
    }
    .title{
        display:flex;
        align-items: center;
        margin-top:5%;
    }
    .titleImg{
        width:100px;
        margin-right:20px;
    }
    .left{
        width:660px;
        height:100%;
        /*background:red;*/
        display:flex;
        flex-direction: column;
        margin-right:15%;
        justify-content: center;
    }
    .imgOutBox{
        flex:1;
        display:flex;
        align-items: center
    }
    .imgBox{
        width:100%;
        height:450px;
    }
    img{
        width:100%;
        height:auto;
    }

    .titleText{
        font-weight:600;
        font-size:22px;
        letter-spacing: 1px;
        font-family: Microsoft YaHei;
    }
    .right{
        width:470px;
        height:560px;
        box-shadow:0 5px 24px 0 rgba(71,137,198,0.29);
        border-radius:20px;
        padding:40px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        background:#fff;
    }
    /*.icon*/
    .icon{
        font-size:22px;
    }
    .icon.blue{
        color:#0066ff;
    }
    .icon.gray{
        color:#d7dee3;
    }

    .formTitle{
        font-size:20px;
        color:#2d81ff;
        text-align: center;
    }
    .formBtn{
        display:flex;
        justify-content: space-between;
    }
    .phone_input{
        position:relative;
    }

    /*全屏登录页面结束*/
    .successTitle{
        width:100%;
        text-align: center;
        font-weight:600;
        font-size:18px;
        padding:30px 0;
    }
    .successText{
        width:100%;
        text-align: center;
        padding-bottom:30px;

    }
    .toIndex{
        color:#66b1ff;
        margin-left:5px;
        cursor:pointer;
    }
    .successImgBox{
        height:130px;
        width:100%;
        display:flex;
        justify-content: center;
        /*width:auto;*/
        position:absolute;
        top:-65px;
    }
    .successImgBox img{
        width:auto;
        height:100%;
    }
</style>
