<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">广告管理</span>
                <span slot="third">自定义广告位管理</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
        </el-card>
        <el-card class="box-card">
            <el-row style="margin-top:20px" class="btnRow">
                <div>
                    <el-button class="largeBtn" @click="confirmMeth('enable','启用')" size="medium" v-if="showFunModel('启用')">启用</el-button>
                    <el-button class="largeBtn" type="primary" @click="confirmMeth('disable','暂停')" size="medium" v-if="showFunModel('停用')">停用</el-button>
                    <el-button class="largeBtn" type="success" @click="confirmMeth('del','删除')" size="medium" v-if="showFunModel('删除')">删除</el-button>
                </div>
                <el-button type="primary" @click="newBuild" size="medium" class="rightBtn largeBtn" v-if="showFunModel('删除')">新建</el-button>
            </el-row>
            <table-list :table="table" @tableMeth="tableMeth" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading"></table-list>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "adManCustom",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAll'},
                fourthPath:{path: '/hotelManAll'},
            },
            table:{
                tableHeader:[
                    {prop:'name_alias',label:'刊位名称',width:''},
                    {prop:'media_type_text',label:'媒体格式',width:''},
                    {prop:'resolution',label:'规格尺寸',width:''},
                    {prop:'relation_case_name',label:'关联刊位名称',width:''},
                    {prop:'status_text',label:'状态',width:''},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'adManCus',
                option:true,
                select:true,
                loading:true
            },
            topSearchShow:{ },
            condition:{},
            operateIds:'',
            token:'',
            funArr:[],
            allData:{},
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
        }),
        methods:{
            confirmMeth(type,str){
                if(this.operateIds!==''){
                    this.$confirm('确定'+str+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if(type==='enable'){
                            this.operateMeth('enable','启用');
                        }else if(type==='disable'){
                            this.operateMeth('disable','暂停');
                        }else if(type==='del'){
                            this.operateMeth('del','删除');
                        }
                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '请选择刊位',
                        type: 'warning'
                    });
                }
            },
            operateMeth(type,str){
                var param='';
                param=qs.stringify({
                    token:this.token,
                    ids:this.operateIds,
                    type:type
                });
                this.$http.post(this.global.caseBatch,param).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success(str+'成功');
                        this.tableMeth();
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(()=>{
                    this.$message.error('返回错误');
                })
            },
            newBuild(){
                this.$router.push({path:'/adManCusNew',query:{fromType:'ordinary'}})
            },
            tableMeth(){
                this.table.loading=true;
                this.$http.get(this.global.caseCustomSearch,{
                    params:{
                        token:this.token,
                        page:this.table.page,
                        per_page:this.table.limit,
                        adStatus:this.condition.adStatus,
                        adFormat:this.condition.adFormat,
                        keyword:this.condition.keyword
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=tableData.total;
                        // this.table.tableList=tableData;
                        // this.table.total=parseInt(tableData.total);
                    }else{
                        this.$message.warning(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.table.loading=true;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.table.loading=true;
                this.tableMeth();
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='group'  || auth_type==='factory'){
                    this.topSearchShow={
                        adStatus:true,
                        adFormat:true,
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        adStatus:true,
                        adFormat:true,
                        keyword:true,
                    }
                }
            },
            getCondition(val){
                this.condition.adStatus=Array.isArray(val.adStatus)?val.adStatus.join(','):''; //ka
                this.condition.adFormat=Array.isArray(val.adFormat)?val.adFormat.join(','):''; //ka
                this.condition.keyword=val.keyword; //ka
                this.tableMeth();
            },
            getTableSelect(val){
                var operateIds='';
                val.forEach((item,index)=>{
                    operateIds+=item.id+','
                });
                this.operateIds=operateIds.slice(0,-1);
            },
            getGroupLevel(){
                return new Promise((resolve,reject)=>{
                    this.$http.get(this.global.groupLevel,{
                        params:{
                            token:this.token
                        }
                    }).then(res=>{
                        if(res.data.code===100000){
                            this.curId=res.data.data.pid[0];
                        }
                        resolve(res.data.data.pid[0]);
                    });
                })
            },
            getTreeData(pid){
                var param=qs.stringify({
                    token:this.token,
                    pid:pid,
                });
                this.$http.post(this.global.roletree,param).then(res=>{
                    if(res.data.code===100000){
                        this.allData=res.data.data;
                        this.allData.forEach((item,index)=>{
                            var menuId=sessionStorage.getItem('defaultActiveId');
                            if(item.parent==menuId){
                                this.funArr.push(item.text);
                            }
                        });
                        this.showFunModelCondition();
                    }
                });
            },
            showFunModelCondition(){
                this.funArr.indexOf('详情')!==-1
                    ? this.table.option=true
                    : this.table.option=false
            },
            getFoldMeth(val){
                this.fold=val;
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            // alert(this.global.token);
            this.getTopSearchShow();
            this.tableMeth();
            this.getGroupLevel().then(res=>{
                this.getTreeData(res);
            });
        },
        computed:{
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .btnRow{
        display:flex;
        /*justify-content: space-between;*/
    }
    .rightBtn{
        margin-left:auto;
    }

</style>
