<!-- mac解绑弹窗 -->
<template>
  <div>
    <el-dialog title="乐播授权查询" :visible.sync="dialogVisible" custom-class="oper-dia" :close-on-click-modal="false" @closed="close">
      <!-- 表单 -->
      <div>
        <span>MAC：</span>
        <el-input v-model="keyWord" placeholder="" class="dia_input"></el-input>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" @click="dialogExport=true">导入</el-button>
      </div>
      <!-- 搜索数据 -->
      <div class="table_row">
        <el-table :data="tableData" style="width: 100%" border height="362">

          <el-table-column prop="mac" label="mac名称">

          </el-table-column>
          <el-table-column label="授权状态">
            <template slot-scope="scoped">
              <span v-if="scoped.row.state==1">已授权</span>
              <span v-else>未授权</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="授权日期">
            <template slot-scope="scoped">
              <span v-if="scoped.row.date">{{scoped.row.date}}</span>
              <span v-else>暂无信息</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 按钮 -->
      <span slot="footer" class="dialog-footer">
        <!--   <el-button
        type="primary"
        @click="unbind"
        :disabled="selectData.length == 0"
        >解 绑</el-button
      > -->
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="导入" :visible.sync="dialogExport" :close-on-click-modal="false" width="60%">
      <div class="container">
        <div class="first stepItem">
          <div class="uploadBox ">
            <el-upload class="upload-demo" drag accept=".xlsx" :action="global.upload" :headers="headers" :on-success="uploadSuccess" :show-file-list='false'>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div>

          <div class="notice">
            <div class="title">*Excel文件请符合以下标准</div>
            <div class="item">
              请按照模板格式导入
              <span class="downLoad"><a href="./static/ItvMac.xlsx" download="">下载导入模板</a></span>
            </div>
            <div class="item">后缀名需为xls或者xlsx</div>
            <div class="item">数据请勿放在合并的单元格中</div>
            <div class="item">单次导入条数最大不可超过100条</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import { getItvMac } from "./api/macBund";
export default {
  name: "LBSearch",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      dialogVisible: this.visible,
      keyWord: "",
      tableData: [],
      selectData: [],
      headers: {
        token: "",
      },
      dialogExport: false,
    };
  },
  mounted() {
    let token = sessionStorage.getItem("token");
    this.headers.token = token;
  },
  methods: {
    uploadSuccess(response, file, fileList) {
      let fileUrl = response.data.url;
     this.keyWord='';
      this.search(fileUrl);
    },

    close() {
      this.selectData = [];
      this.tableData = [];
      this.keyWord = "";
    },
    search(url) {
      getItvMac({
        mac_str: this.keyWord,
        file_path: url ? url : "",
      }).then((data) => {
        console.log(data);
        if (data.data.code != 100000) {
          this.$message.error(data.data.msg);
        } else {
          if (data.data.data && data.data.data.length != 0) {
            let list = data.data.data;
            list.forEach((element) => {
              element.mac = element.mac.replace(/(0){15}/g, "");
            });
            this.tableData = list;
            console.log(this.tableData);
          } else {
            this.$message.error("暂无数据");
          }
          
        }
        this.dialogExport = false;
      });
    },
    handleSelectionChange(val) {
      this.selectData = val;
    },
  },
  mounted() {},
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.oper-dia {
  .dia_input {
    width: 50%;
    margin-right: 10px;
  }
  .table_row {
    margin-top: 20px;
  }
}
.container {
  /*height:400px;*/
  /*background:red;*/
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
}
.stepItem {
  width: 100%;
}
.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}
.imgBox {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  img {
    height: 100%;
    width: 100%;
  }
}
.none {
  display: none;
}
.notice {
  width: 100%;
  background: #eef2f7;
  padding: 20px 20px;
  margin-top: 30px;
  box-sizing: border-box;
  div {
    color: #909ca7;
  }
  .title {
    font-size: 16px;
    padding-bottom: 20px;
  }
  .item {
    margin-bottom: 20px;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .downLoad > a {
    color: #418efa;
    margin-left: 20px;
    cursor: pointer;
  }
}
.two {
  .showCon {
    width: 100%;
    height: 300px;
    border-radius: 4px;
    border: 1px dashed #ddd;
    overflow: auto;
  }
  .bottom {
    .show {
      display: flex;
    }
    .infoIncomplete {
      margin-left: 20px;
    }
    div {
      margin-bottom: 15px;
    }
  }
}
.three {
  width: 100%;
  height: 300px;
  .dataShow {
    display: flex;
    justify-content: center;
  }
  .error {
    color: #dd2139;
  }
}
.four {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .notice {
    width: 100%;
    text-align: center;
    background: none;
  }
  .iconBox {
    text-align: center;
    font-size: 80px;
    color: #20b759;
    margin-top: 30px;
  }
}
</style>
