<template>
    <div v-loading="loading">
        <div class="outTopBox">
            <top-bread :bread="bread" v-if="curPage==='/indexShow'">
                <span slot="second">首页</span>
                <span slot="third">更多</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskToDo" v-if="curPage==='/taskToDo'">
                <span slot="second">任务管理</span>
                <span slot="third">待办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>
            <top-bread :bread="breadTaskDone" v-if="curPage==='/taskDone'">
                <span slot="second">任务管理</span>
                <span slot="third">已办任务</span>
                <span slot="fourth">详情</span>
            </top-bread>

        </div>
        <el-card class="box-card">
            <back @backMeth="backMeth"></back>
            <el-form :model="form" ref="form" label-width="160px" class="demo-form">
                <!-- <line-title>基础信息</line-title> -->
                <el-col :span="12" >
                    <el-form-item label="发送人" label-width="200px" >
                        {{form.partner_name}}
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="发送时间" label-width="200px">
                        {{form.create_time_text}}
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="任务类型" label-width="200px">
                        可视化配置审核
                    </el-form-item>
                </el-col>
                <el-col :span="12" >
                    <el-form-item label="酒店名称" label-width="200px" >
                        {{form.hotel_name}}
                    </el-form-item>
                </el-col>
            </el-form>
            <el-col class="btnRow" :span="24" v-if="btnShow">
                <el-button type="danger" @click="reject" class="largeBtn redBtn">驳回</el-button>
                <el-button type="primary" @click="pass" class="largeBtn ">通过</el-button>
            </el-col>
        </el-card>
        <el-card class="box-card">
            <op-tab-edit :hotelContract="hotelContract"
                         v-if="editTabShow"
                         :configData="configData" :noData="noData" :hotelTemplateType="hotelTemplateType"
                         v-loading="editTabLoading"
                         :element-loading-text="loadingText"
                         :userNameProp="userName"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(0, 0, 0, 0.8)" :onlyShowModule="true" showType="action"></op-tab-edit>
        </el-card>
        <reject-dialog ref="rejectDialog" @examine="examine"></reject-dialog>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "IndexDoVisual",
        data:()=>({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/indexDoMore'},
            },
            breadTaskToDo:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/taskToDo'},
            },
            breadTaskDone:{
                level:4,
                thirdPath:{path: '/taskDone'},
            },
            form:{
                person:'张三',
                time:'2020-07-29',
                type:'内容分发审批',
                hotelName:'酒店1',
            },
            configData:{},
            allData:{},
            hotelTemplateType:'',
            hotelContract:'',
            editTabLoading:false,
            loadingText:'拼命发布中……',
            userName:'',
            noData:false,
            editTabShow:false,
            query:{},
            curPage:'',
            btnShow:false,
            loading:false,

        }),
        methods:{
            reject() {
                this.$refs.rejectDialog.dialogShow(true);
            },
            pass(){
                this.$confirm('确定通过?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.examine(1);
                }).catch(()=>{

                })
            },
            examine(status){
                this.loading=true;
                var param=qs.stringify({
                    token:this.token,
                    id:this.query.id,
                    status:status,
                    message:this.$refs.rejectDialog.dialog.reason,
                });
                this.$http.post(this.global.visualAction,param).then(res=>{
                    var str='';
                    status===1?str='审核':str='驳回';
                    if(res.data.code===100000){
                        this.showMessage(str+'成功','success');
                    }else{
                        this.showMessage(res.data.msg);
                    }
                    this.$router.push('/indexDoMore');
                    this.loading=false;
                }).catch(err=>{
                    this.$message.warning("接口调用失败")
                     this.loading=false;
            });
            },
            showMessage(str,type){
                this.$message({
                    message: str,
                    type: type
                });
            },
            isEmptyObj(obj){
                for (let i in Object.keys(obj)) {
                    return false // 进入循环即不为空
                }
                return true
            },
            getHotelDetail(){
                return new Promise((resolve,reject)=>{
                    this.$http.get(this.global.view,{
                        params:{
                            token:this.token,
                            id:this.query.hotelId
                        }
                    }).then(res=>{
                        if(res.data.code===100000){
                            var formData=res.data.data;
                            this.form=res.data;
                            this.hotelContract=formData.hotel_contract;
                            this.hotelTemplateType=formData.template_type;
                            this.userName=res.data.auth.user_name;
                        }else{
                            this.$message.error(res.data.msg);
                        }
                        resolve();
                    });
                })

            },
            getHotelConfigInfo() {
                return new Promise((resolve,reject)=>{
                    var param = qs.stringify({
                        token: this.token,
                        hotelContract: this.hotelContract,
                    });
                    this.$http.post(this.global.getHotelCurrentVisualInfo, param).then(res => {
                        if (res.data.code === 100000) {
                            Object.assign(this.configData,res.data.data)
                        //    this.configData = JSON.parse(JSON.stringify(data))
                            // console.log(this.configData);
                            // console.log('111父组件config',res.data.data);
                            // this.configData=res.data.data;
                            // this.editTabShow=true;
                        }else{
                            this.$message.error(res.data.msg);
                        }
                        resolve();
                    }).catch(() => {
                        // debugger;
                    })
                })
            },
            getDetail(){
                this.$http.get(this.global.registerReviewDetail,{
                    params:{
                        token:this.token,
                        id:this.query.id,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.form=res.data.data;
                        var visualData=JSON.parse(JSON.stringify(res.data.data.visual_data));
                        delete visualData.info;
                        this.configData.tab=visualData;
                        this.editTabShow=true;
                    }
                })
            },
            backMeth(){
                this.$router.push({path:`./${this.query.fromUrl}`})
            },
        },
        mounted(){
            var query=this.$route.query;
            this.query=query;
            query.reviewStatus==='待审核'?this.btnShow=true:this.btnShow=false;
            this.curPage=sessionStorage.getItem('defaultActive');
            this.token=sessionStorage.getItem('token');
            this.getHotelDetail().then(()=>{
                return this.getHotelConfigInfo()
            }).then(()=>{
                this.getDetail();
            })
        },
    }
</script>

<style scoped lang="scss">
    .btnRow{
        /*display:flex;*/
        direction:rtl;
    .el-button:first-child{
        margin-left:10px;
    }
    }
    .outShowBox{
        width:100%;
        display:flex;
        justify-content: center;
    }

    .showBox{
        width:65%;

    }
</style>
