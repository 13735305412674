<template>
  <div v-loading="contentLoading" class="main">
    <el-drawer class="box" :before-close="handleClose" :destroy-on-close="true"  :visible.sync="contentShow"  size="123%" :title="title" v-if="!query.is_newPage">
      <div class="changeType" >
        <div v-for="(item, index) in typeList" v-show="item.show" :key="index" :class="{ active: type == item.msg ? true : false }" @click="changeType(item.msg,item.name)">
          {{ item.name }}
        </div>
      </div>
      <div v-if="type==='releaseList'">
        <ReleaseList :query="query"></ReleaseList>
      </div>
      <div v-if="type==='toRelease'">
        <instructionRelease :query="query"></instructionRelease>
      </div>
    </el-drawer>
    <div v-if="query.is_newPage">
      <!-- <el-tabs v-model="type">
        <el-tab-pane v-for="(item, index) in typeList" v-show="item.show" :key="index" :class="{ active: type == item.msg ? true : false }" :label="item.name " :name="item.msg"></el-tab-pane>
      </el-tabs> -->
      <div v-if="type==='releaseList'">
        <ReleaseList :query="query"></ReleaseList>
      </div>
      <div v-if="type==='toRelease'">
        <instructionRelease :query="query"></instructionRelease>
      </div>
    </div>
  </div>
</template>
<script>
import instructionRelease from "./components/instruction/instructionRelease.vue";
import ReleaseList from "./components/instruction/releaseList.vue";
export default {
  name: "remoteMaintenance",
  components: {
    instructionRelease,
    ReleaseList,
  },
  data: () => ({
    funArr: [],
    contentLoading: false,
    type: "toRelease",
    title: "指令发布",
    contentShow: false,
    query: {},
    typeList: [
      // {
      //   msg: "releaseList",
      //   name: "发布列表",
      //   show: true,
      // },
      {
        msg: "toRelease",
        name: "指令发布",
        show: true,
      },
    ],
    auth: {},
    token: "",
    funArr: [],
    hotelContract: "",
  }),

  methods: {
    changeType(msg, name) {
      this.type = msg;
      this.title = name;
    },
    open(query, arr) {
      this.type = "toRelease"
      console.log(arr);
      this.funArr = [...arr]
      console.log(this.funArr);
      this.typeList.forEach((i) => {
        console.log(i,this.funArr.indexOf(i.name));
        if (this.funArr.indexOf(i.name)!=-1) {
          i.show = true;
        } else {
          i.show = false;
        }
      });
      this.$emit('intlist',this.typeList)
      this.query = query;
      this.contentShow = true;
    },
    // 开关
    handleClose(done) {
      this.contentShow = false;
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body::-webkit-scrollbar {
  //   width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}
.main {
  height: 100%;
}
.el-table {
  margin-top: 15px;
}
.changeType {
  position: absolute;
  left: -120px;
  top: 0px;
  width: 120px;

  border: 1px solid #e2e2e2;
  z-index: 999;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}

.changeType div {
  width: 120px;
  height: 55px;
  font-size: 14px;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
}

.active {
  background-color: #f1f9ff;
  color: #0078ff;
}

::v-deep .el-drawer__body {
  overflow: scroll;
  padding: 15px 20px 0;
}
</style>

