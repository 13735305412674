<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">酒店管理</span>
                <span slot="third">未分配酒店列表</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <el-card class="box-card">
            <div class="title chartTitle blueTitle">未分配酒店列表</div>
            <el-row class="btnRow" v-if="showFunModel('分配合作伙伴')">
                <el-button class="largeBtn" type="primary" @click="assignMeth" size="medium">分配合作伙伴</el-button>
            </el-row>
            <table-list @showUploadDialog="showUploadDialog" :table="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
        </el-card>
        <el-dialog title="分配合作伙伴" :visible.sync="dialog.dialogFormVisible" width='500px' :close-on-click-modal="false">
            <el-form :model="dialog" ref="form" :rules="rules" class="noFixedWidthForm ">
                <el-form-item prop="coopType" label="合作伙伴类型" label-width="200px">
                    <el-select v-model="dialog.coopType" placeholder="请选择" @change="coopTypeChange" clearable class="fullSelect" >
                        <el-option
                                v-for="item in selectData.coopType"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="coopPartner" label="分配合作伙伴" label-width="200px" class="formItemNoMarginBottom">
                    <el-select v-model="dialog.coopPartner" placeholder="请选择" clearable filterable class="fullSelect" >
                        <el-option
                                v-for="item in selectData.coopPartner"
                                :key="item.id"
                                :label="item.company_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.dialogFormVisible = false">取 消</el-button>
                <!--<el-button type="primary" @click="dialog.dialogFormVisible = false">确 定</el-button>-->
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </div>
        </el-dialog>
          <hotelAllContent ref="hotelAllContent" :query="query"></hotelAllContent>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    </div>
</template>
<script>
    import  qs from 'querystring'
    import commonMeth from '../../../api/method'
    import hotelAllContent from '../content/hotelAllContent'
    export default {
         components:{
          
            hotelAllContent
        },
        name: "hotelManAll",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelManAssignTest'},
            },
            topAttr:{
                terminal:'',
                terminalOption:[
                    {label:'country1',value:'1'},
                    {label:'country2',value:'2'},
                    {label:'country3',value:'3'},
                ],
            },
            table:{
                tableHeader:[
                    {prop:'hotel',label:'酒店名称',width:'280'},
                    {prop:'hotel_brand',label:'集团品牌',width:''},
                    {prop:'hotel_use_state',label:'客户终端状态',width:''},
                    {prop:'h_province',label:'省份',width:'110'},
                    {prop:'h_city',label:'城市',width:'110'},
                    {prop:'h_county',label:'区县',width:'110'},
                    {prop:'hotel_room',label:'客房总量（间）',width:''},
                    {prop:'n_connected',label:'激活终端数量（台）',width:''},
                    {prop:'addtime',label:'上线时间',width:'180'},
                ],
                tableList:[ ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                fromUrl:'hotelManAssignTest',
                name:'hotelManAssignTest',
                option:true,
                select:true,
                loading:true,
            },
            selectData: {
                coopType: [],
                coopPartner: [],
                city: [],
                country: [],
            },
            dialogFormVisible: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            rules:{
                coopType: [
                    { required: true, message: '请选择合作伙伴类型', trigger: 'blur' }
                ],
                coopPartner: [
                    { required: true, message: '请选择合作伙伴', trigger: 'blur' }
                ],
            },
            formLabelWidth: '120px',
            testShow:true,
            dialog:{
                coopType:'',
                coopPartner: '',
                dialogFormVisible:false,
                hotel_ids:''
            },
            topSearchShow:{ },
            condition:{},
            token:'',
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            query:{},
            funArr:[],
            allData:{},
            topSearchSave:{},
            allLoadingState:false,
        }),
        methods:{
             showUploadDialog(val){
                this.query = val;
                this.$refs.hotelAllContent.contentShow=true;
                this.$refs.hotelAllContent.type="detail";
                 this.$refs.hotelAllContent.getHotelDetail(this.query.id);
                this.$refs.hotelAllContent.getHotalContentList(this.query.hotel_contract);
            },
            tableMeth(orderVal){
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.table.loading=true;
                var param=qs.stringify({token:this.token,no_partner:1});
                this.$http.get(this.global.search,{
                    params:{
                        token:this.token,
                        no_partner:1,  //未分配
                        page:this.table.page,
                        per_page:this.table.limit,
                        hotel_brand:this.condition.hotel_brand,
                        hotelBrand:this.condition.hotel_brand,
                        citycode:this.condition.citycode,
                         check_state:this.condition.acceptanceType,
                        province:this.condition.provinceLabel,
                        city:this.condition.cityLabel,
                        county:this.condition.countyLabel,
                        hotel_use_state:this.condition.hotel_use_state,
                        accountManagerId:this.condition.BD,
                        screenType: this.condition.screenType,
                        keyword:this.condition.keyword,
                        sort:sortStr,
                        order:orderStr,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        var tableData=res.data.data;
                        // this.table.tableList=[{name:"111"}];
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getTableSelect(val){
                var hotel_ids='';
                val.forEach((item,index)=>{
                    hotel_ids+=item.id+','
                });
                this.dialog.hotel_ids=hotel_ids.slice(0,-1);
            },
            newBuild(){
                this.$router.push('/hotelManNew');
            },
            assignMeth() {
                this.dialog.coopType='';
                this.dialog.coopPartner='';
                if(this.dialog.hotel_ids!==''){
                    this.getPartnerType();
                    this.dialog.dialogFormVisible=true;
                }else{
                    this.$message({
                        message: '请选择酒店',
                        type: 'warning'
                    });
                }

            },
            submitForm(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.assignConfirm();
                    } else {
                        return false;
                    }
                });
            },
            showMessage(str,type){
                this.$message({
                    message: str,
                    type: type
                });
            },
            assignConfirm(){
                var param=qs.stringify({
                    token:this.token,
                    partner_id:this.dialog.coopPartner,
                    hotel_ids:this.dialog.hotel_ids
                });
                this.$http.post(this.global.setPartner,param).then(res=>{
                    if(res.data.code===100000){
                        this.showMessage('分配成功','success');
                        this.tableMeth();
                    }else{
                        this.showMessage('分配失败','success');
                    }
                    this.dialog.dialogFormVisible = false;
                })
            },
            getPartnerType(partner_id,type){   //获取类型
                this.$http.get(this.global.getPartnerTypes,{
                    params:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        if(!partner_id){
                            this.selectData.coopType=res.data.data;
                        }else{
                            this.selectData.coopPartner=res.data.data;
                        }
                    }else{
                    }
                })
            },
            getPartner(type){   //获取类型
                this.$http.get(this.global.getPartnersByType,{
                    params:{
                        token:this.token,
                        type:type
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.selectData.coopPartner=res.data.data;
                    }
                })
            },
            coopTypeChange(val){
                this.dialog.coopPartner='';
                this.getPartner(val);
            },
            getCondition(val,filterPage,filterLimit,type){
                this.condition.hotel_brand=Array.isArray(val.hotel_brand)?val.hotel_brand.join(','):''; //ka
                if(typeof val.citycode==='string'){
                    this.condition.citycode=val.citycode;
                }else{
                    this.condition.citycode=Array.isArray(val.citycode)?val.citycode.join(','):''; //citycode
                }
                this.condition.provinceLabel=Array.isArray(val.provinceLabel)?val.provinceLabel.join(','):''; //provinceLabel
                this.condition.cityLabel=val.cityLabel;
                this.condition.acceptanceType =  val.acceptanceType,
                this.condition.countyLabel=val.countyLabel;
                // this.condition.citycode=val.citycode; //ka
                this.condition.hotel_use_state=Array.isArray(val.hotel_use_state)?val.hotel_use_state.join(','):''; //ka
                this.condition.BD=Array.isArray(val.BD)?val.BD.join(','):''; //BD
                this.condition.screenType = Array.isArray(val.screenType) ? val.screenType.join(',') : ''; //酒店性质
                this.condition.keyword=val.keyword; //ka
                this.getFilterTableCon(filterPage,filterLimit,type);
                this.tableMeth();
            },
            getTopSearchShow(){
                this.topSearchShow={
                    hotel_brand:true,
                    province:true,
                    city:true,
                    country:true,
                    hotel_use_state:true,
                    BD:true,
                    keyword:true,
                    screenType:true,
                    acceptanceType:true,
                };
                this.topSearchShow.keywordPlaceholder='请输入酒店名称关键词';
            },
            getFoldMeth(val){
                this.fold=val;
            },
            getFilterTableCon(filterPage,filterLimit,type){
                if(type==='mounted'){
                    !filterPage || filterPage===''
                        ?this.table.page=1
                        :this.table.page=filterPage;

                    !filterLimit || filterLimit===''
                        ?this.table.limit=5
                        :this.table.limit=filterLimit;
                }else{
                    this.table.page=1;
                    this.table.limit=5;
                    sessionStorage.setItem('filterPage','1');
                    sessionStorage.setItem('filterLimit','5');
                }
            },
            getFilterCon(){
                var filterCon=JSON.parse(sessionStorage.getItem('filterCon'));
                this.topSearchSave=filterCon;
                var filterPage=sessionStorage.getItem('filterPage');
                var filterLimit=sessionStorage.getItem('filterLimit');
                this.getCondition(filterCon,filterPage*1,filterLimit*1,'mounted');
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.getFilterCon();
            this.getTopSearchShow();
            commonMeth.getTreeData(this.table.option).then((res)=>{
                this.funArr=res.funArr;
                this.table.option=res.flag;
            });
        },
        computed:{
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading,false,false);
            }
        }

    }
</script>

<style scoped lang="scss">
    .hotelBrand{
        width:100%;
    }

    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .largeBtn{
        min-width: 100px;
        height: 40px;
    }
</style>
