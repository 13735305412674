import request from '@/api/request';
import qs from "querystring";
let vi = '/v2'
//  列表
export function getList(data, responseType) {
  return request({
    url: `${vi}/cmdtask/getDeviceLog`,
    method: 'post',
    data,
    ...responseType
  });
}
// 处理
export function dealLog(data) {
  return request({
    url: `${vi}/cmdtask/dealLog`,
    method: 'post',
    data,
  });
}


