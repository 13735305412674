<template>

  <div class="box" v-if="show" :style="{width:style.width+'px',height:style.height+'px',bottom:style.bottom+'px',top:style.top+'px',left:style.left+'px',background:`url(${publicSource.menu_background.source_url})`,justifyContent:style.justifyContent}">

    <visiualButton class="hide" btnShow='picmore' @openmore='openMore'></visiualButton>
    <draggable class="draggableBox" group="article" :disabled='notPage||isEdit' :value="menu" animation="300" :style="{justifyContent:style.justifyContent}" @input="handleListChange($event)">
      <div class="menuItem" :style="getBg(item)" v-if="index<=6&&item.menu_hide=='1'" v-for="(item,index) in menu" :key="index" @click="clickItem(item)">
        <div class="imgBox">
          <img v-if="active!=item.menu_id" :src="item.default_point.source_url" alt="">
          <img v-if="active==item.menu_id" :src="item.focal_point.source_url" alt="">
        </div>
        <span class="textBox" :style="{color:active==item.menu_id?publicSource.menu_focal_color.x:publicSource.menu_text_color.x}">{{item.menu_name.content}}</span>
        <span v-if="style.nameShowType==2" class="textBox" :style="{marginTop:'2px',color:active==item.menu_id?publicSource.menu_focal_color.x:publicSource.menu_text_color.x}">{{item.menu_enname.content}}</span>
      </div>
    </draggable>

  </div>

</template>

<script>
import qs from "qs";
import visiualButton from "../common/editBtn.vue";
import Draggable from "vuedraggable";
export default {
  name: "menuBox",
  components: {
    Draggable,
    visiualButton,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    publicSource: {
      type: Object,
      default: () => {},
    },
    backpic: {
      type: String,
      default: "",
    },
    activeChange: {
      type: Boolean,
      default: false,
    },
    notPage: {
      type: Boolean,
      default: false,
    },
    activeId: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    getBg() {
      return function (item) {
        let obj = {
          width: this.style.itemWidth + "px",
          height: this.style.itemHeight + "px",
        };
        if (this.active == item.menu_id) {
          obj.background = `url(${this.publicSource.menu_point.source_url})`;
          obj.backgroundRepeat = "no-repeat";
          obj.backgroundPosition = "0 0";
          obj.backgroundSize = "100% 100%";
        }
        return obj;
      };
    },
  },
  watch: {
    public: {
      handler: function (newVal) {
        this.public = newVal;
 
      },
      deep: true,
      immediate: true,
    },
    activeId: {
      handler: function (newVal) {
        this.active = newVal;
      },
      deep: true,
      immediate: true,
    },
    boxStyle: {
      handler: function (newVal) {
        let obj = JSON.parse(JSON.stringify(newVal));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
    activeChange: {
      handler: function (newVal, older) {
        this.active = this.menu[0].menu_id;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (!this.active) {
      this.active = this.menu[0].menu_id;
    }
  },
  data() {
    return {
      style: {},
      active: "",
      menu: this.value,
      show:true,
    };
  },
  methods: {
    openMore() {
      this.$emit("changemenupic");
    },
    handleListChange(event) {
      this.menu = event;
      this.sortMeth();
    },
    sortMeth() {
      var order = 0;
      this.menu.forEach((item, index) => {
        order++;
        item.menu_morder = order;
        item.update = true;
      });
      this.$emit("change", this.menu);
      this.$emit("canrelease");
      this.$emit("changehistory", this.menu);
    },
    clickItem(item) {
      this.active = item.menu_id;
      this.$emit("clickitem", item);
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  transition: 0.1s;
  background-size: 100% 100% !important;
  .draggableBox {
    display: flex;
    justify-content: center;
    align-items: center;
    .menuItem {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-size: 100% 100% !important;
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 50px;
        margin-bottom: 6px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

.textBox {
  color: #fff;
  font-size: 14px;
  font-family: "微软雅黑" !important;
  cursor: pointer;
}
.btnMore {
  height: 30px;
  width: 80px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  cursor: pointer;
  transition: 0.3s;
  position: absolute;
  top: -40px;
  left: 0;
}
.btnMore:hover {
  /* background: #4093ff; */
  background: rgba(60, 138, 255, 0.8);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
</style>