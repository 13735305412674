<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">酒店管理</span>
        <span slot="third">验收酒店管理</span>
        <span slot="fourth">可验收酒店</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-if="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition"></top-search>
    </el-card>
    <el-card>
      <div class="title blueTitle">可验收酒店列表</div>
      <div style="margin-top: 15px">
        <el-button class="largeBtn" type="primary" @click="readycheck">发起验收</el-button>
        <el-button class="largeBtn" type="primary" @click.stop="acceptanceMonitoring">验收检测</el-button>
      </div>
      <el-table :data="tableData" ref="accountTable" style="width: 100%; margin-top: 10px" @selection-change="selectionChange" :row-key="getRowKey" :header-cell-style="headerStyle">
        <el-table-column :reserve-selection="true" type="selection" width="55" :selectable="selectable">
        </el-table-column>
        <el-table-column prop="hotel" label="酒店名称" min-width="280" show-overflow-tooltip>
        </el-table-column>
        <el-table-column min-width="220" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
            <el-button type="text" size="mini" @click="skipToManage(scope.row)">跳转</el-button>
            <el-button type="text" size="mini" @click="returnShow(scope.row)" v-if="scope.row.refuse_reason">驳回原因</el-button>
            <el-button type="text" size="mini" @click="uploadTrue(scope.row)" v-if="
                !scope.row.video_str &&
                !scope.row.acceptance_form_str &&
                !scope.row.store_photo_json
              ">上传附件</el-button>

          </template>
        </el-table-column>
        <el-table-column prop="hotelName" label="程序名称" min-width='100'>
        </el-table-column>
        <el-table-column label="验收状态">
          <template slot-scope="scope">
            <span :style="{ background: statusCircleColor(scope.row.check_state) }" v-if="scope.row.check_state" class="statusCircle"></span>
            <span>{{ scope.row.check_state_text }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150" prop="total_num" label="应上线终端数量">
        </el-table-column>
        <el-table-column min-width="150" prop="n_rooms_connected" label="激活终端数量">
        </el-table-column>
        <el-table-column min-width="130" prop="dar" label="平均日活率">
        </el-table-column>
        <el-table-column min-width="130" prop="update_version_num" label="更新版本数量">
        </el-table-column>
        <el-table-column min-width="150" prop="max_update_nums" label="最大更新终端数量">
        </el-table-column>
        <el-table-column prop="channel_name" width="300" label="所属合作伙伴" show-overflow-tooltip>
        </el-table-column>
      </el-table>

      <el-pagination class="pageChange" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </el-card>
    <el-drawer title="驳回原因" :visible.sync="result_drawer" :direction="direction" :before-close="handleClose" size="50%" :modal="false" :wrapperClosable="false">
      {{result}}
      <!-- <el-input
        type="textarea"
        v-model="result"
        :rows="8"
        style="margin: 10px"
      ></el-input> -->
    </el-drawer>
    <el-drawer title="上传附件" :visible.sync="upload_drawer" :direction="direction" :before-close="uploadClose" size="90%" :destroy-on-close="true" :modal="false" :wrapperClosable="false">
      <uploadfile ref="uploadfile" :hotelContract="hotelContract" @uploadSuccess="uploadSuccess" :upload_drawer="upload_drawer"></uploadfile>
    </el-drawer>
    <el-drawer title="详情" :visible.sync="detail_drawer" :direction="direction" :modal-append-to-body="true" :before-close="handleClose" size="120%" :destroy-on-close="true" :modal="false" :wrapperClosable="false">
      <div class="changeType">
        <div :class="{ active: type == index ? true : false }" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="contentShow" v-show="type == 0">
        <detail-no :edit="true" :id="query.id" v-if="auth.type !== 'htrip' && auth.type !== 'screen'" @topInfo="getTopInfo"></detail-no>
        <detail-juping :edit="true" :id="query.id" v-if="auth.type === 'screen'" @topInfo="getTopInfo"></detail-juping>

        <detail-has :edit="true" :id="query.id" v-if="auth.type === 'htrip'" @topInfo="getTopInfo">
        </detail-has>
      </div>
      <div class="contentShow" v-show="type == 1">
        <all-tab-person :id="query.id" v-if="type == 1"></all-tab-person>
      </div>
      <div class="contentShow" v-show="type == 2">
        <all-tab-online :id="query.id" :showData="true" v-if="type == 2"></all-tab-online>
      </div>
      <div class="contentShow" v-show="type == 3">
        <editFile ref="editFile" @uploadSuccess="uploadSuccess" :hotelContract="hotelContract" :showFileData="showFileData"></editFile>
      </div>
    </el-drawer>
    <el-drawer title="验收检测" :visible.sync="inputHotalVisible" size="85%" :modal="false" :wrapperClosable="false" :before-close="handleClose">
      <p style="color:red; ">仅限状态为“使用中”酒店检测</p>
      <div class="inputHotalVisibleBox">
        <el-input placeholder="请输入合同号" v-model="inputHatalContract" @keyup.enter.native="trueReturn"></el-input>
        <el-button class="largeBtn" type="primary" @click="trueReturn">确 定</el-button>
      </div>
      <div v-if="testContentData" class="testContentBox">
        <div class="testContentRow" v-for="(value, key, index) in testContentData" :key="index">
          <span>{{ key }}</span>
          <span v-if="value==2"><i class="el-icon-close" :style="{ color: 'red' }"></i></span>
          <span v-if="value==1"><i class="el-icon-check" :style="{ color: 'green' }"></i></span>
          <span v-if="value==''">{{value}}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getEnclosureUrls,
  getReasonWaitForCheck,
  generateHotelPreview,
  sponsorCheckFlow
} from "@/api/hotel.js";
import uploadfile from "./CPTForUpload/uploadFile";
editFile;
import showfile from "./CPTForUpload/fileShow";
import editFile from "./CPTForUpload/editFile";
import qs from "querystring";
export default {
  components: {
    uploadfile,
    showfile,
    editFile,
  },
  data() {
    return {
      bread: {
        level: 4,
      },
      query: {
        id: "",
      },
      fold: {
        showSearch: false,
        foldText: "展开筛选项",
      },
      topSearchShow: {},
      typeList: [
        {
          name: "酒店信息",
        },
        {
          name: "实施人员",
        },
        {
          name: "上线情况",
        },
        {
          name: "附件验收",
        },
      ],
      table: {
        limit: 5,
        page: 1,
        total: 0,
      },
      showFileData: {},
      hotelContract: "",
      tableData: [],
      topInfo: {
        hotel: "",
        hotel_use_state_text: "",
        contract_end: "",
        hotel_server_key: "",
      },
      type: 0,
      auth: {},
      check_state: "",
      hotel_contract: "",
      hotelName: "",
      hotel: "",
      channel_code: "",
      coopType: "",
      result_drawer: false,
      contentVisible: false,
      detail_drawer: false,
      direction: "rtl",
      result: "",
      show_detail: true,
      show_result: true,
      show_upload: true,
      upload_drawer: false,
      selectIdArr: [],
      testContentData: {
        "激活终端数量达到“应上线终端数量”的95%": "",
        酒店后台已生成的版本数量大于等于4个: "",
        已激活的房间已全部上传相应凭据图片: "",
        "最近45日内，某一更新版本的更新终端数量不小于激活终端数量的40%": "",
        酒店信息中的必填项已全部填写: "",
        酒店信息中的百度经纬度地址与所选省市区一致: "",
        酒店后台影视模块配置包名与高清影视合作方式一致: "",
        酒店后台商城模块配置包名与商城属性一致: "",
        酒店后台影视模块配置包名与应用列表中需配置包名一致: "",
        酒店后台应用列表已配置全部标准应用: "",
      },
      inputHatalContract: "",
      inputHotalVisible: false,
      checkDataList: [],
      alloperateIdsArr: [],
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
    };
  },
  methods: {
    getFoldMeth(val) {
      this.fold = val;
    },
    // 默认选中
    checked() {
      this.checkDataList.filter((checked) => {
        this.$refs.tableHotal.toggleRowSelection(checked, true);
      });
    },
    // 获取每一行的key值
    getRowKey(row) {
      return row.id;
    },
    // 审核状态标记颜色 抛出
    statusCircleColor(txt) {
      if (txt == 0) {
        return "#FE8637";
      } else if (txt == 3) {
        return "#FE8637";
      } else if (txt == 7) {
        return "#3774FE";
      } else if (txt == 6) {
        return "#53D439";
      }
    },
    // 获取页面列表
    hoteltable() {
      this.$http
        .get(this.global.getHotelsListWaitForCheck, {
          params: {
            token: this.token,
            per_page: this.table.limit,
            page: this.table.page,
            check_state: this.check_state,
            hotel_contract: this.hotel_contract,
            hotelName: this.hotelName,
            hotel: this.hotel,
            channel_code: this.channel_code,
            coopType: this.coopType,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.tableData = res.data.data.data;
            this.table.total = res.data.data.total;
            this.tableData.filter((item) => {
              this.alloperateIdsArr.forEach((item1) => {
                if (item1.id === item.courseId) {
                  this.checkDataList.push(item);
                }
              });
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 筛选
    getCondition(val) {
      this.table.page = 1;
      this.check_state = val.acceptanceStatus;
      this.hotel_contract = val.codekeyword;
      this.hotelName = val.namekeyword;
      this.hotel = val.keyword;
      if (val.partner_id) {
        this.channel_code = val.partner_id.join(",");
      } else {
        this.channel_code = val.partner_id;
      }
      this.coopType = val.coopType;
      this.hoteltable();
    },
    //  获取搜索框权限
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          acceptanceStatus: true,
          keyword: true,
          keywordPlaceholder: "请输入酒店名称关键字",
          codekeyword: true,
          codekeywordPlaceholder: "请输入合同号",
          namekeyword: true,
          namekeywordPlaceholder: "请输入程序名称",
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          acceptanceStatus: true,
          keyword: true,
          keywordPlaceholder: "请输入酒店名称关键字",
          codekeyword: true,
          codekeywordPlaceholder: "请输入合同号",
          namekeyword: true,
          namekeywordPlaceholder: "请输入程序名称",
          coopType: true,
        };
      }
    },
    // 取消检测
    cancelReturn() {
      this.inputHotalVisible = false;
      this.inputHatalContract = "";
    },
    // 自动检测
    trueReturn() {
      if (this.inputHatalContract == "") {
        this.$message.warning("请输入检测合同号");
      } else {
        const json = {
          hotel_contract: this.inputHatalContract,
        };
        var param = qs.stringify(json);
        getReasonWaitForCheck(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.testContentData = res.data.data;
              this.$message.success("验收检测操作成功");
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.warning("验收检测操作失败");
            console.log(err);
          });
      }
    },
    // 验收监测
    acceptanceMonitoring() {
      this.inputHotalVisible = true;
      this.result_drawer = false;
      this.detail_drawer = false;
      this.upload_drawer = false;
    },
    // 附件上传成功
    uploadSuccess() {
      this.detail_drawer = false;
      this.upload_drawer = false;
      this.hoteltable();
    },
    // 上传附件
    uploadTrue(row) {
      this.hotelContract = row.hotel_contract;
      this.query.id = 97;
      this.upload_drawer = true;
      this.result_drawer = false;
      this.detail_drawer = false;

      this.inputHotalVisible = false;
    },
    // 详情列表点击
    changeType(index) {
      if (index == 3) {
        this.type = index;
        var json = {
          hotel_contract: this.hotelContract,
        };
        var param = qs.stringify(json);
        getEnclosureUrls(param)
          .then((res) => {
            if ((res.data.code = 100000)) {
              this.showFileData = res.data.data;

              this.$emit("showFileData", this.showFileData);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.type = index;
        return;
      }
    },
    // 分页
    handleSizeChange(limit) {
      this.table.limit = limit;
      this.hoteltable();
    },

    handleCurrentChange(page) {
      this.table.page = page;
      this.hoteltable();
    },

    getTopInfo() {},
    // 查看详情
    detail(val) {
      this.hotelContract = val.hotel_contract;
      this.query.id = val.manage_hotel_id;
      this.type = 0;
      this.detail_drawer = true;
      this.result_drawer = false;
      this.upload_drawer = false;
      this.inputHotalVisible = false;
    },
    // 查看驳回原因
    returnShow(row) {
      this.result = row.refuse_reason;
      this.result_drawer = true;
      this.detail_drawer = false;
      this.upload_drawer = false;
      this.inputHotalVisible = false;
    },
    //  跳转功能 按钮
    skipToManage(data) {
      if (data.skipAddress !== "127.0.0.1//admin") {
        window.open("http://" + data.skipAddress);
      } else {
      }
    },
    // 关闭上传页面
    uploadClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          this.upload_drawer = false;
        })
        .catch((_) => {});
    },
    // 关闭弹框通用
    handleClose(done) {
      done();
      this.inputHatalContract = "";
      this.testContentData = {
        "激活终端数量达到“应上线终端数量”的95%": "",
        酒店后台已生成的版本数量大于等于4个: "",
        已激活的房间已全部上传相应凭据图片: "",
        "最近45日内，某一更新版本的更新终端数量不小于激活终端数量的40%": "",
        酒店信息中的必填项已全部填写: "",
        酒店信息中的百度经纬度地址与所选省市区一致: "",
        酒店后台影视模块配置包名与高清影视合作方式一致: "",
        酒店后台商城模块配置包名与商城属性一致: "",
        酒店后台影视模块配置包名与应用列表中需配置包名一致: "",
        酒店后台应用列表已配置全部标准应用: "",
      };
    },
    // table 选取功能
    selectionChange(val) {
      this.selectIdArr = val;
      this.alloperateIdsArr = this.selectIdArr;
    },
    // 禁用
    selectable(row, index) {
      if (
        row.acceptance_form_str &&
        row.store_photo_json &&
        row.video_str &&
        row.check_state != 6
      ) {
        return true;
      } else {
        return false;
      }
    },
    //  验收按钮
    readycheck() {
      if (this.alloperateIdsArr.length == 0) {
        this.$message.warning("请选择验收酒店发起验收操作");
      } else {
        var list = [];
        this.alloperateIdsArr.forEach((item) => {
          list.push(item.id);
        });
        var ids = list.join(",");
        const json = {
          ids: ids,
        };
        var param = qs.stringify(json);
        generateHotelPreview(param)
          .then((res) => {
            if (res.data.code == 100000) {
              var id = res.data.data.id;
              const json = {
                id: id,
              };
              var param = qs.stringify(json);
              sponsorCheckFlow(param)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("发起成功");
                   this.hoteltable();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {
                  this.$message.warning("发起失败");
                });
              // this.$router.push({
              //   path: "./hotalAcceptancePreview",
              //   query: { id: id },
              // });
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.warning("预览单生成失败");
          });
      }
    },
  },
 
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.hoteltable();
    this.getTopSearchShow();
    
  },
  computed: {},
};
</script>
<style scoped lang="scss">
::v-deep :focus {
  outline: 0;
}
.pageChange {
  margin-top: 20px;
}
.bread {
  flex: 1;
}
.result_btn {
  margin-left: 38vw;
  width: 100px;
}
::v-deep .el-drawer__body {
  overflow: scroll;
  padding: 15px 20px 0;
}
.changeType {
  position: absolute;
  left: -85px;
  top: 0px;
  width: 85px;
  height: 220px;
  border: 1px solid #e2e2e2;
  z-index: 999;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}
.changeType div {
  width: 85px;
  height: 55px;
  font-size: 14px;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
}

.active {
  background-color: #f1f9ff;
  color: #0078ff;
}

.inputHotalVisibleBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.inputHotalVisibleBox .el-input ::v-deep .el-input__inner {
  width: 95%;
  margin-right: 30px;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.testContentBox {
  padding: 20px 0;
  font-size: 14px;
}
.testContentRow {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  height: 40px;
  color: #333;
  line-height: 40px;
}
.testContentRow i {
  font-size: 20px;
}
.statusCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>