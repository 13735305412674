<!-- 签名管理 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">设备管理</span>
        <span slot="third">系统配置</span>
      </top-bread>
    </div>
    <!-- 标签切换按钮 -->
    <el-tabs class="incomeTab" v-model="activeName" @tab-click="changeTabData">
      <el-tab-pane label="热点配置" name="hotspot"></el-tab-pane>
      <el-tab-pane label="开机视频" name="bootVideo"></el-tab-pane>
    </el-tabs>
    <el-card class="box-card">
      <!-- 按钮功能 -->
      <div class="btnRow">
        <el-button type="primary" class="largeBtn" @click="openAdd">{{activeName=='hotspot'?'热点配置':'视频配置'}}</el-button>
      </div>
      <!-- 按钮功能 -->
      <!-- 表格信息 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent">
        <template slot="play" slot-scope="{row}">
          <span v-if="row.state==1">开启</span>
          <span v-if="row.state==0">关闭</span>
        </template>
        <template slot="open" slot-scope="{row}">
          <span v-if="row.isOpen==1">开启</span>
          <span v-if="row.isOpen==0">关闭</span>
        </template>
        <template slot="add" slot-scope="{row}">
          <span v-if="row.isConcatRoom==1">拼接</span>
          <span v-if="row.isConcatRoom==0">不拼接</span>
        </template>
      </operationTable>
      <!-- 表格信息 -->
    </el-card>

    <!-- 功能表单 -->
    <el-dialog :title="title" :visible.sync="contentVisible" width="60%" :before-close="contentClose" :append-to-body="true">
      <el-form ref="createForm" :rules="rules" :model="createForm" label-width="100px">
        <el-form-item label="指定酒店" prop='hotelStr'>
          <hotelSelect ref="hotelSelect" v-model="createForm.hotelStr" :noInsert="true" :readonly="false" type='yaduo' /> <span style="margin-left:10px" >已选酒店 {{createForm.hotelStr.length}} 家</span>
        </el-form-item>
        <div v-if="activeName=='hotspot'">
          <el-form-item label="热点开关" prop='WIFI_switch'>
            <el-switch style="height:40px" v-model="createForm.WIFI_switch" active-color="#658BFF" inactive-color="rgba(191, 191, 191, 1)" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="SSID" prop='SSID'>
            <el-input v-model="createForm.SSID" placeholder="请输入SSID"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop='password'>
            <el-input v-model="createForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="拼接房间号" prop='addRoom'>
            <el-switch style="height:40px" v-model="createForm.addRoom" active-color="#658BFF" inactive-color="rgba(191, 191, 191, 1)" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>
        </div>
        <div v-if="activeName=='bootVideo'">
          <el-form-item label="是否播放" prop='is_play'>
            <el-radio-group style="line-height:50px" v-model="createForm.is_play">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开机视频" prop='video_url'>
            <uploadVideo v-model="createForm.video_url"></uploadVideo>
          </el-form-item>

        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="optTrue('createForm')" :loading='trueLoading'>确 定</el-button>
        <el-button @click="contentClose">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 功能表单 -->
    <el-dialog title="发布详情" :visible.sync="infoVisible" width="60%" :before-close="infoClose" :append-to-body="true">
      <p style="text-align:center" v-if="infoList.length==0">暂无数据</p>
      <el-collapse v-if="infoList.length>0" v-model="activeIndex" accordion v-loading="collapseloading">
        <el-collapse-item :title="item.name" :name="index" v-for="(item,index) in infoList" :key="index+'show'">
          <div class="showBox">
            <div class="card cardFirst">
                <p class="title">已完成 ：{{item.room.finish.length}} 间</p>
                  <p class="content">{{item.room.finish.length==0?'':item.room.finish.join(',')}}</p>
           
            </div>
            <div class="card">
                 <p class="title">未完成 ：{{item.room.undone.length}} 间</p>
              <p class="content">{{item.room.undone.length==0?'':item.room.undone.join(',')}}</p>
            </div>
          </div>
         
        </el-collapse-item>
  
      </el-collapse>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import uploadVideo from "./components/uploadVideo.vue";
import { setHotspot, setBootVideo, updateLogs,updateLogsDetail} from "./api/function";
export default {
  name: "functionSetting",
  components: {
    uploadVideo,
  },
  data() {
    return {
      bread: {
        level: 3,
      },
      activeIndex:'',
      collapseloading:false,
      infoVisible: false,
      infoList: [],
      activeName: "hotspot",
      title: "WIFI配置",
      contentVisible: false,
      trueLoading: false,
      createForm: {
        hotelStr: "", // 酒店信息json
        WIFI_switch: "1", // WIFI 开关
        SSID: "",
        password: "",
        addRoom: "0",
        is_play: "1",
        video_url: "",
      },
      rules: {
        hotelStr: {
          required: true,
          message: "请选择配置酒店",
          trigger: "blur",
        },

        SSID: { required: true, message: "请输入SSID", trigger: "blur" },
        password: {
          required: true,
          message: "请输入wifi密码",
          trigger: "blur",
        },
        video_url: {
          required: true,
          message: "请上传开机视频",
          trigger: "blur",
        },
      },
      // 表格配置
      tableData: {
        tableList: [],
        checked: false, //多选
        tableLoading: false, //加载
        noPagination: false,
        page: 1,
        limit: 5,
        props: [],
      },
      videoProp: [
        {
          label: "ID",
          prop: "id",
          minWidth: "80",
          type: "text",
        },
        {
          label: "是否播放",
          prop: "state",
          minWidth: "80",
          type: "text",
          isSlot: true,
        },
        {
          label: "开机视频",
          prop: "videoUrl",
          minWidth: "120",
          type: "video",
        },
         {
          label: "推送酒店数",
          prop: "pullHotelCount",
          minWidth: "100",
          type: "text",
        }, {
          label: "更新酒店数",
          prop: "updateHotelCount",
          minWidth: "100",
          type: "text",
        }, {
          label: "推送房间数",
          prop: "pullRoomCount",
          minWidth: "100",
          type: "text",
        }, {
          label: "更新房间数",
          prop: "updateRoomCount",
          minWidth: "100",
          type: "text",
        },
        {
          label: "创建时间",
          prop: "time",
          minWidth: "200",
          type: "text",
        },

        {
          label: "操作者",
          prop: "uname",
          minWidth: "96",
          type: "text",
        },
        {
          label: "操作",
          minWidth: "60",
          type: "button",
          prop: "num",
          // 详情
          info: (item) => {
           
            this.infoVisible = true;
            let json ={
              dataName:item.dataName,
             version_id:item.version_id
            }
            this.collapseloading = true;
            updateLogsDetail(json).then(res=>{
                if(res.data.code == 100000){
                    this.infoList = res.data.data;
                }else{
                  this.$message.warning(res.data.msg)
                }
                  this.collapseloading = false;
            }).catch(err=>{

            })
          },
        },
      ],
      wifiProp: [
        {
          label: "ID",
          prop: "id",
          minWidth: "80",
          type: "text",
        },
        {
          label: "热点开关",
          prop: "isOpen",
          minWidth: "80",
          type: "text",
          isSlot: true,
        },
        {
          label: "SSID",
          prop: "ssid",
          minWidth: "80",
          type: "text",
        },
        {
          label: "密码",
          prop: "password",
          minWidth: "80",
          type: "text",
        },
        {
          label: "拼接房间号",
          prop: "isConcatRoom",
          minWidth: "120",
          type: "text",
          isSlot: true,
        },
                {
          label: "推送酒店数",
          prop: "pullHotelCount",
          minWidth: "100",
          type: "text",
        }, {
          label: "更新酒店数",
          prop: "updateHotelCount",
          minWidth: "100",
          type: "text",
        }, {
          label: "推送房间数",
          prop: "pullRoomCount",
          minWidth: "100",
          type: "text",
        }, {
          label: "更新房间数",
          prop: "updateRoomCount",
          minWidth: "100",
          type: "text",
        },

        {
          label: "创建时间",
          prop: "time",
          minWidth: "200",
          type: "text",
        },
        {
          label: "操作者",
          prop: "uname",
          minWidth: "96",
          type: "text",
        },
        {
          label: "操作",
          minWidth: "60",
          type: "button",
          prop: "num",
          // 详情
          info: (item) => {
          
            this.infoVisible = true;
            let json ={
              dataName:item.dataName,
             version_id:item.version_id
            }
            this.collapseloading = true;
            updateLogsDetail(json).then(res=>{
                if(res.data.code == 100000){
                    this.infoList = res.data.data;
                }else{
                  this.$message.warning(res.data.msg)
                }
                  this.collapseloading = false;
            }).catch(err=>{

            })
          },
        },
      ],
    };
  },
  methods: {
    infoClose() {
      this.infoVisible = false;
      this.activeIndex ="";
    },
    changeTabData() {
      this.changeFunction();
      if (this.activeName == "bootVideo") {
        this.title = "视频配置";
        this.tableData.props = this.videoProp;
      } else {
        this.title = "WIFI配置";
        this.tableData.props = this.wifiProp;
      }
      this.getTableList(this.activeName, this.tableData.limit, 1);
    },
    optTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.trueLoading = true;
            let arr = this.createForm.hotelStr.map((i) => {
              return (
                i.id +
                "@#" +
                i.hotel_contract +
                "@#" +
                i.ipaddr +
                "/" +
                i.hotelName
              );
            });
            if (this.activeName == "hotspot") {
              let json = {
                isOpen: this.createForm.WIFI_switch,
                ssid: this.createForm.SSID,
                password: this.createForm.password,
                isConcatRoom: this.createForm.addRoom,
                selectedIDs: arr.join(","),
              };
              setHotspot(json)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("操作成功");
                    this.changeFunction();
                    this.contentClose();
                    this.getTableList(this.activeName, this.tableData.limit, 1);
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                  this.trueLoading = false;
                })
                .catch((err) => {
                  this.$message.warning("操作失败");
                  this.trueLoading = false;
                });
            } else {
              let json = {
                videoUrl: this.createForm.video_url.split("#")[0],
                isPlayBootVideo: this.createForm.is_play,
                videoMd5: this.createForm.video_url.split("#")[1],
                selectedIDs: arr.join(","),
              };
              setBootVideo(json)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("操作成功");
                    this.changeFunction();
                    this.contentClose();
                    this.getTableList(this.activeName, this.tableData.limit, 1);
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                  this.trueLoading = false;
                })
                .catch((err) => {
                  this.$message.warning("操作失败");
                  this.trueLoading = false;
                });
            }
          });
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
    openAdd() {
      this.contentVisible = true;
    },
    contentClose() {
      this.$refs.createForm.resetFields();
      this.$refs.hotelSelect.clear();
      this.contentVisible = false;
    },
    changeFunction() {
      this.createForm = {
        hotelStr: "",
        WIFI_switch: "1",
        SSID: "",
        password: "",
        addRoom: "0",
        is_play: "1",
        video_url: "",
      };
    },
    getTableList(type, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p,
        pageSize: s,
        logType: type,
      };
      updateLogs(json)
        .then((res) => {
          if (res.data.code == 100000 && res.data.data.rows) {
            // 数据赋值
            this.tableData.tableList = res.data.data.rows;
            // 总数传递
            this.$refs.operationTable.changeTotal(
              Number(res.data.data.totalNum)
            );
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据获取失败");
          this.tableData.tableLoading = false;
        });
    },
    // limit变化
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableList(this.activeName, s, p);
    },
    // page 变化
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableList(this.activeName, s, p);
    },
  },
  mounted() {
    this.tableData.props = this.wifiProp;
    this.getTableList(this.activeName, 5, 1);
  },
};
</script>
<style lang="scss" scoped>
.outTopBox {
  margin: 20px 0;
}
.showBox{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .card{
    flex: 1;
    height: 300px;
  
    border: 1px solid #ddd;
    .title{
      padding: 15px;
      height: 50px;
      box-sizing: border-box;
      line-height: 20px;
      background: #eee;
    }
    .content{
        height: 250px;
          box-sizing: border-box;
        padding: 15px;
          overflow-y: auto;
    box-sizing: border-box;
    }
  }
  .cardFirst{
    margin-right: 10px;
  }
}
</style>
