import request from "@/api/request";
import qs from "querystring";

//  酒店集团列表
export function getGroupList(param) {
  return request({
    url: `v2/Managegroup/getList`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  添加集团
export function addGroup(param) {
  return request({
    url: `v2/Managegroup/addManageGroup`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  修改集团
export function editGroup(param) {
  return request({
    url: `v2/Managegroup/editManageGroup`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  修改集团
export function delGroup(param) {
  return request({
    url: `v2/Managegroup/delManageGroup`,
    method: "post",
    data: qs.stringify(param),
  });
}