<template>
  <div>
    <div>
      <el-row class="filterBox" :gutter="20">
        <el-col :span="14">
          <el-input placeholder="请输入版式名称" class="input-with-select fullSelect" v-model="filter.keyword" :clearable="true">
            <i slot="suffix" class="el-input__icon el-icon-search pointer" @click="searchMeth"></i>
          </el-input>
        </el-col>
        <el-col :span="10">
          <el-select v-model="filter.brand" placeholder="请选择品牌" class="selectItem fullSelect" filterable clearable multiple @change="filterMeth" collapse-tags>
            <el-option v-for="item in brandList" :key="item.id" :label="item.groupname" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="hotelItemBox flexCenterBox" v-if="searchList.length===0">
        <div class="tabShowBox noDataBox flexBox">
          <div class="noData">
            <img src="../../../assets/img/noData.png" alt="">
            <div class="text flexBox">暂无数据</div>
          </div>
        </div>
      </div>
      <div class="hotelItemBox" v-if="searchList.length>0">
        <div class="occupyBox">
          <div class="hotelItem" :key="index" v-for="(item,index) in searchList" @click="chooseHotel(hotelType,item.id,item.label,item.buildingProjectName)">
            <div class="hotelItemImg">
              <img :src="item.picurl" alt="">
            </div>
            <div class="hotelItemText">{{item.label}}</div>
            <div class="shadow" v-if="selectedHotelProp.id===item.id">
              <i class="el-icon-success shadowIcon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hotelItem",
  props: ["hotelList", "hotelType", "selectedHotelProp", "brandList"],
  watch: {
    selectedHotelProp: {
      handler: function (newVal) {
        Object.assign(this.selectedHotelProp, newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      filter: {
        keyword: "",
        brand: "",
      },
      selectedHotel: {
        type: "",
        id: "",
        label: "",
        buildingProjectName:"",
      },

      searchList: [],
      test: false,
      test1: true,
    };
  },
  methods: {
    chooseHotel(type, id, label,buildingProjectName) {
      this.selectedHotel = {
        type,
        id,
        label,
        buildingProjectName,
      };
      this.$emit("chooseHotel", this.selectedHotel);
    },
    // searchMeth(){
    //     this.searchList=[];
    //     var keyword=this.filter.keyword;
    //     this.hotelList.filter((item,index)=>{
    //         if(item.text.indexOf(keyword)!==-1){
    //             this.searchList.push(item);
    //         }
    //     });
    //     console.log('筛选和',this.searchList);
    // },
    searchMeth() {
      this.filterMeth(this.hotelList);
    },
    change(val) {
      console.log("筛选", val);
    },
    filterMeth() {
      var arr = [];
      var arrSecond = [];
      var selectedBrandArr = this.filter.brand;
      var list = this.hotelList;
      if (list) {
        list.filter((item, index) => {
          if (item.label.indexOf(this.filter.keyword) !== -1) {
            arr.push(item);
          }
        });
        arr.filter((item, index) => {
          if (selectedBrandArr.length > 0) {
            if (selectedBrandArr.indexOf(item.group_id) !== -1) {
              arrSecond.push(item);
            }
          } else {
            arrSecond = arr;
          }
        });
        this.searchList = arrSecond;
      }
    },
  },
  mounted() {
    this.searchList = this.hotelList;
  },
};
</script>

<style scoped>
.hotelItemBox {
  padding: 20px 80px 0 80px;
  height: 350px;
}
.flexCenterBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.occupyBox {
  display: flex;
  flex-wrap: wrap;
}
.shadow {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shadowIcon {
  font-size: 30px;
  color: #fff;
}
.hotelItem {
  width: 49%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #c3d1e2;
  box-sizing: border-box;
  background: #f8fbff;
  cursor: pointer;
}

.hotelItem:nth-child(odd) {
  margin-right: 2%;
}
.hotelItemImg {
  width: 100%;
  /*min-height:140px;*/
  height: auto;
}
.hotelItemImg img {
  width: 100%;
  height: 100%;
}
.hotelItemText {
  padding: 10px 0;
  text-align: center;
  color: #326993;
  font-size: 14px;
}
.whiteBg {
  background: #fff;
}
.pointer {
  cursor: pointer;
}
.noDataBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noData {
  width: 300px;
  position: relative;
}
.text {
  width: 100%;
  color: #a3a7af;
  text-align: center;
  position: absolute;
  bottom: 10px;
}
img {
  width: 100%;
  height: 100%;
}
.fullSelect {
  width: 100%;
}
</style>
