<template>
  <!-- 筛选 -->
  <div class="searcContent">
    <!-- 日期 -->
    <div class="timeInput">
      <el-date-picker style="width: 300px;margin-right:10px;" v-model="defaultForm.date" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
    </div>
    <!-- 日期 -->
    <!-- 维度 -->
    <div class="dimensionInput" style="margin-top:20px" v-if="tabName==='3'">
      <el-select style="width: 300px;margin-right:10px;" v-model="defaultForm.dimensionType" placeholder="请选择数据维度" @change="changeDimension">
        <el-option label="酒店维度" value="hotel"></el-option>
        <el-option label="标签维度" value="tag"></el-option>
      </el-select>
    </div>
    <!-- 维度 -->
    <!-- 列表项 -->
    <div class="listItemCondition">
      <template v-for="(item,index) in defaultForm.itemCondition">
        <div :key="index" class="itemBox">
          <el-select style="width: 300px;margin-right:10px;" v-model="item.itemName" placeholder="请选择列表项">
            <el-option v-for="(title,ide) in itemList" :label="title.field_name" :value="title.field" :key="ide"></el-option>
          </el-select>
          <el-select style="width:100px;margin-right:10px;" v-model="item.itemOperate" placeholder="请选择对比条件">
            <el-option v-for="(operate,idx) in operateList" :label="operate.label" :value="operate.value" :key="idx"></el-option>
          </el-select>
          <el-input style="width:300px;margin-right:10px;" v-model="item.itemContent" placeholder="请输入搜索内容"></el-input>
          <span v-if="index===defaultForm.itemCondition.length-1"> <i class="el-icon-circle-plus-outline" @click="addItem"></i></span>
          <span v-if="defaultForm.itemCondition.length!==1" style="margin-right:10px;"> <i class="el-icon-remove-outline" @click="delItem(index)"></i></span>
        </div>
      </template>
    </div>
    <!-- 列表项 -->
    <div class="btnSearchBox">
      <el-button class="largeBtn" type="primary" @click="defaultSearchForm">搜索</el-button>
      <el-button class="largeBtn recetBtn" @click="recetSearchForm">重置</el-button>
    </div>
  </div>
  <!-- 筛选 -->
</template>
<script>
export default {
  name: "DataAssetForListSearch",
  // 接收列表项筛选数组
  props: {
    tablePropList: {
      type: Array,
      default: () => [],
    },
    tabName: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      // 筛选表单
      defaultForm: {
        date: "", // 日期
        dimensionType: "hotel", // 维度
        itemCondition: [
          // 列表项条件
          {
            itemName: "", // 列表项名称
            itemOperate: "like", //  列表项条件
            itemContent: "", // 列表项内容
          },
        ],
      },
      itemList: [],
      operateList: [
        {
          label: "包含",
          value: "like",
        },
        {
          label: "不包含",
          value: "not like",
        },
        {
          label: "等于",
          value: "=",
        },
        {
          label: "不等于",
          value: "!=",
        },
        {
          label: "小于",
          value: "<",
        },
        {
          label: "大于",
          value: ">",
        },
      ],
    };
  },
  watch: {
    // 监听列表项数据变动
    tablePropList: {
      // 深度监听并将获取到的新数据赋值渲染
      handler: function (newVal) {
        this.itemList = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 切换标签维度
    changeDimension() {
      // 构建一个新的对象，进行抛出数据赋值
      let obj = {
        record_date: this.defaultForm.date,
        dimension: this.defaultForm.dimensionType,
      };
      // 若列表项没有填写内容跳过此操作
      // 若列表项填写内容遍历生成键值对
      this.defaultForm.itemCondition = [
        {
          itemName: "", // 列表项名称
          itemOperate: "like", //  列表项条件
          itemContent: "", // 列表项内容
        },
      ];

      // 向外排除数据
      this.$emit("defaultdimension", obj);
    },
    // 抛出搜索条件
    defaultSearchForm() {
      // 构建一个新的对象，进行抛出数据赋值
      let obj = {
        record_date: this.defaultForm.date,
        dimension: this.defaultForm.dimensionType,
      };
      // 若列表项没有填写内容跳过此操作
      // 若列表项填写内容遍历生成键值对
      this.defaultForm.itemCondition.forEach((i) => {
        if (i.itemName !== "") {
          // 当前对象字段已选择
          let rex = /^[0-9]+$/;
          if (i.itemOperate === "like" || i.itemOperate === "not like") {
            //运算类型

            obj[i.itemName] = i.itemOperate + " '%" + i.itemContent + "%'";
          } else {
            if (rex.test(i.itemContent)) {
              obj[i.itemName] = i.itemOperate + " " + i.itemContent;
            } else {
              obj[i.itemName] = i.itemOperate + " '" + i.itemContent + "'";
            }
          }
        }
      });
      // 向外排除数据
      this.$emit("defaultsearchdata", obj);
    },
    // 重置搜索条件
    recetSearchForm() {
      // 重置搜索表单
      this.defaultForm = {
        date: "", // 日期
        dimensionType: "", // 维度
        itemCondition: [
          // 列表项条件   保留一条作为最基本的展示
          {
            itemName: "", // 列表项名称
            itemOperate: "like", //  列表项条件
            itemContent: "", // 列表项内容
          },
        ],
      };
      // 向外抛出数据
      this.$emit("defaultsearchdata", {});
    },
    // 删除当前列表项
    delItem(index) {
      // 删除当前点击项
      this.defaultForm.itemCondition.splice(index, 1);
    },
    // 增加列表项搜索
    addItem() {
      // 构建一个列表项对象
      let obj = {
        itemName: "", // 列表项名称
        itemOperate: "like", //  列表项条件
        itemContent: "", // 列表项内容
      };
      // 将构建的对象放进列表项筛选条件中
      this.defaultForm.itemCondition.push(obj);
    },
  },
};
</script>
<style scoped>
.searcContent {
  margin-top: 20px;
}
.itemBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.el-icon-circle-plus-outline,
.el-icon-remove-outline {
  font-size: 40px;
  color: #eee;
  cursor: pointer;
}
.btnSearchBox {
  margin-top: 20px;
}
.largeBtn {
  width: 100px;
  height: 40px;
}
.recetBtn {
  border: 1px solid #409eff;
  color: #409eff;
  background: #fff;
}
</style>