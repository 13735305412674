<template>
  <div>

    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">内容管理</span>
        <span slot="third">报障记录</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <!-- 操作栏 -->
      <div class="btnRow">
        <el-form ref="form" :inline="true" :model="form" label-position="left">
          <el-form-item v-if="$route.path=='/reporting'">
            <el-input v-model="form.name" placeholder="请输入酒店名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.room_number" placeholder="请输入房间号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.hotel_contract" placeholder="请输入酒店合同号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy - MM - dd " value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>

          <el-form-item>

            <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>
            <el-button type="primary" class="largeBtn" @click="reset">重置</el-button>
            <el-button type="primary" class="largeBtn" :loading='btnLoading' @click="importTable">导出</el-button>
          </el-form-item>
        </el-form>

      </div>
      <!-- 操作栏 -->
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" />
      <!-- 表格 -->

    </el-card>
  </div>

</template>
<script>
import { getNetwork, exportNetwork } from "./api";
export default {
  name: "reporting",

  data() {
    return {
      form: {
        name: "",
        room_number: "",
        phone: "",
        hotel_contract: "",
        time: "",
      },
      bread: {
        level: 3,
      },
      btnLoading: false,
      // 表格数据信息
      tableData: {
        tableList: [],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "酒店id",
            prop: "id",
            minWidth: "60",
            type: "text",
          },
          {
            label: "酒店名称",
            prop: "name",
            minWidth: "280",
            type: "text",
          },
          {
            label: "酒店合同号",
            prop: "hotel_contract",
            minWidth: "200",
            type: "text",
          },
          {
            label: "手机号",
            prop: "phone",
            minWidth: "160",
            type: "text",
          },
          {
            label: "房间号",
            prop: "room_num",
            minWidth: "80",
            type: "text",
          },
          {
            label: "创建日期",
            prop: "created_date",
            minWidth: "160",
            type: "text",
          },
        ],
      },
      // 已选项
      checkList: [],
    };
  },

  methods: {
    // 搜索
    search() {
      // 重置组件信息
      this.$refs.operationTable.resetTable();
      // 重置当前间信息
      this.tableData.limit = 10;
      this.tableData.page = 1;
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    reset() {
      this.tableData.limit = 10;
      this.tableData.page = 1;
      this.form.name = "";
      this.form.phone = "";
      this.form.room_number = "";
      this.form.hotel_contract = "";
      this.form.time = [];
      // 刷新列表
      this.getTableData(this.tableData.limit, this.tableData.page);
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, this.tableData.page);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(this.tableData.limit, p);
    },
    importTable() {
      this.btnLoading = true;
        this.$message.success("操作成功，结果下载需要时间，请稍后....");
      console.log(this.form);
      const json = {
        page: 1,
        size: 10,
        name: this.form.name,
        phone: this.form.phone,
        room_number: this.form.room_number,
        hotel_contract: this.form.hotel_contract,
        start_date: this.form.time[0] ? this.form.time[0] + " 00:00:00" : "",
        end_date: this.form.time[1] ? this.form.time[1] + " 23:59:59" : "",
        export: 1,
      };
      exportNetwork(json)
        .then((res) => {
          console.log(res.data);
          const blobUrl = window.URL.createObjectURL(res.data);
          const title = `报障列表.xlsx`;
          // const title = `${this.tableName}.csv`;
          const a = document.createElement("a");
          a.style.display = "none";
          a.download = title;
          a.href = blobUrl;
          a.click();
          this.btnLoading = false;
          this.$message.success("导出成功");
          document.body.removeChild(a);
        })
        .catch((err) => {
          // this.$message.warning("导出失败");
        });
    },
    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      console.log(this.form);
      const json = {
        page: p ? p : 1,
        size: s ? s : 10,
        name: this.form.name,
        phone: this.form.phone,
        room_number: this.form.room_number,
        hotel_contract: this.form.hotel_contract,
        start_date: this.form.time[0] ? this.form.time[0] + " 00:00:00" : "",
        end_date: this.form.time[1] ? this.form.time[1] + " 23:59:59" : "",
      };
      getNetwork(json)
        .then((res) => {
          if (res.data.code == 100000) {
            console.log(res, "列表数据");
            this.tableData.tableList = res.data.data.row;

            this.$refs.operationTable.changeTotal(res.data.data.totalNum);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
  },
  mounted() {
    if(this.$route.path=='/onePage/reporting'){
      this.form.name ='丽呈'
    }
    this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.marginLeft {
  margin-left: 8px;
}
</style>
