<template>
  <div>
    <el-dialog title="上传图片" :visible.sync="uplaodVisible" width="60%" :before-close="handleClose" :append-to-body="true">
      <el-upload :class="['show']" multiple :action="global.materialUpload" :headers="headers" list-type="picture-card" :on-remove="handleRemove" :on-success="handleSuccess" :on-change="handleChange" :on-preview="handlePictureCardPreview" :before-remove='beforeRemove' :on-error="handleError" :before-upload="beforeAvatarUpload" :file-list="fileList" ref="uploadPic">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitData">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "uploadMorePic",

  data() {
    return {
      uplaodVisible: false,
      fileList: [],
      uploadPicTrue: false,
      dialogVisible: false,
      dialogImageUrl: "",
      headers: {
        token: "",
      },
      type: "",
    };
  },

  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    // 确认上传结果
    submitData() {
      let list = this.fileList.map((i) => {
        if (i.response) {
          const json = {
            url: i.response.data.url,
          };
          return json;
        } else {
          return i;
        }
      });
      this.$emit("defaultData", list, this.type);
      this.handleClose();
    },
    // 界面展示 数据显示处理
    open(list, num) {
      this.type = num;
      if (list.length == 0) {
        this.fileList = [];
      } else {
        const arr = list.split(",");
        arr.forEach((i) => {
          let json = {
            url: i,
          };
          this.fileList.push(json);
        });
      }
      this.uplaodVisible = true;
    },
    // 关闭此界面
    handleClose() {
      this.fileList = [];
      this.uplaodVisible = false;
    },
    // 删除上传文件确认
    beforeRemove(file, fileList) {
      if (!this.upNoPic) {
      
            this.uploadPicTrue = false;
     
      } else {
        this.uploadPicTrue = false;
      }
    },
    // 上传文件时钩子函数
    beforeAvatarUpload(file) {
      var jpeg = file.type === "image/jpeg";
      var png = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!jpeg) {
        if (!png) {
          this.$message.warning("请上传图片文件");
          return false;
        } else {
          if (isLt2M) {
            this.uploadPicTrue = true;
            return true;
          } else {
            this.$message.warning("图片限制大小2M");
            return false;
          }
        }
      } else {
        this.uploadPicTrue = true;
        return true;
      }
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.uploadPicTrue = false;
      this.fileList = this.fileList.filter((i) => {
        if (i.url != file.url) {
          return i;
        }
      });
      this.$emit("defaultData", this.fileList, this.type);
    },
    // 文件预览
    handlePictureCardPreview(file, fileList) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    // 文件列表变化
    handleChange(file, fileList) {
      if (this.fileList.length >= 1) {
        this.uploadPicTrue = true;
      }
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      this.fileList = [...fileList];
    },
    handleError(res, file, fileList) {},
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card span {
  margin-top: 10px;
  height: 16px;
  font-size: 12px;
  color: #aaa;
  line-height: 16px;
}
</style>