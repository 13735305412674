import request from "@/api/request";
import qs from "querystring";

// 获取mac列表
export function getList(param) {
    return request({
        url: `v2/Roommac/getMacList`,
        method: "post",
        data: qs.stringify(param),
    });
}
// 解绑
export function unbind(param) {
    return request({
        url: `v2/Roommac/macUntying`,
        method: "post",
        data: qs.stringify(param),
    });
}
// 查询授权
export function getItvMac(param) {
    return request({
        url: `v2/Hotel/getItvMac`,
        method: "post",
        data: qs.stringify(param),
    });
}