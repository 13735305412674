<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">酒店管理</span>
                <span slot="third">所有酒店</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card" v-loading="loading">
            <back @backMeth="backMeth" style="margin-bottom:20px"></back>
            <div id="table-container">
                <el-table :data="tableData" style="width: 100%" fit>
                    <el-table-column prop="dataVersion" label="编号" width="60px" ></el-table-column>
                    <el-table-column prop="intro" label="发布说明">
                        <template slot-scope="scope">
                            <span>{{scope.row.intro}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="module" label="发布模式" width="200px"></el-table-column>
                    <el-table-column prop="roomStr" label="发布房间"></el-table-column>
                    <el-table-column prop="stime" label="发布时间" ></el-table-column>
                    <el-table-column prop="state3" label="已更新终端数" width="150px">
                        <template slot-scope="scope">
                            <span>{{scope.row.state3.length}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="uname" label="发布人" width="180"></el-table-column>
                    <el-table-column prop="operate" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="detail(scope.row),dialogdetail=true">详情</el-button>
                            <el-button type="text" size="small" @click="partMeth(scope.row)">部分</el-button>
                            <el-button type="text" size="small" @click="allMeth(scope.row)">全部</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div>
                    <el-dialog title="更新状态" :visible.sync="dialogdetail">
                        <div class="det_table">
                            <template>
                                <el-table
                                        :data="details"
                                        border width="900"
                                        class="detail">
                                    <el-table-column prop="detail" label="状态"  width="200" height="200"></el-table-column>
                                    <el-table-column prop="room_num" label="房间号"></el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </el-dialog>
                </div>
            </div>
            <el-dialog :visible.sync="part" title="部分升级">
                <el-form >
                    <el-form-item label="选择房间" style="width:500px">
                        <el-input></el-input>
                    </el-form-item>
                    <el-button class="chose_room">选择房间</el-button>
                </el-form>
                <div>
                    <el-button @click="part = false">取 消</el-button>
                    <el-button type="primary" @click="part = false">确 定</el-button>
                </div>
            </el-dialog>
        </el-card>
        <choice-room-dialog ref="choiceRoomDialog" :hotelContract="query.hotelContract"
                            @confirmMeth="confirmMeth" :userNameProp="userName" parentUrl="materialPublishList" :curInfoProp="curInfo">
        </choice-room-dialog>
    </div>
</template>
<script>
    import qs from 'querystring'
    export default {
        name: "MaterialPublishList",
        data() {
            return {
                bread:{
                    level:4,
                    firstPath:{path: '/indexShow'},
                    thirdPath:{path: '/hotelManAll'},
                },
                tableData: [{
                    dataVersion:'',
                    intro:'',
                    module:'',
                    roomStr:'',
                    stime:'',
                    state3:'',
                    uname:'',
                }],
                details:[],
                dialogdetail:false,
                part: false,
                token: '',
                query:{},
                configData:{},
                loading:false,
                userName:'',
                curInfo:{
                    module:'',
                    id:''
                }
            }
        },
        methods: {
            detail(data){
                var state0=data.state0;
                var state1=data.state1;
                var state2=data.state2;
                var state3=data.state3;
                    this.details=[
                        {
                            detail:`未执行（${state0.length}间）`,
                            room_num:state0.join(',')
                        },
                        {
                            detail:`已执行（${state1.length}间）`,
                            room_num:state1.join(',')
                        },
                        {
                            detail:`已获取（${state2.length}间）`,
                            room_num:state2.join(',')
                        },
                        {
                            detail:`已完成（${state3.length}间）`,
                            room_num:state3.join(',')
                        },
                    ]
            },
            getHotelConfigInfo() {
                this.loading=true;
                var param = qs.stringify({
                    token: this.token,
                    hotelContract: this.query.hotelContract,
                });
                this.$http.post(this.global.getHotelCurrentVisualInfo, param).then(res => {
                    if (res.data.code === 100000) {
                        this.tableData = res.data.data.roomList;
                        this.userName=res.data.auth.user_name;
                    }
                    this.loading=false;
                }).catch(() => {
                    // debugger;
                })
            },
            backMeth(){
                this.$router.push({path:`./hotelManAllDetail`,
                    query:{
                        fromUrl:'hotelManAll',
                        id:this.query.hotelId,
                        hotelContract:this.query.hotelContract,
                        type:'edit',
                        fromEditType:'publishList',
                    }})
            },
            confirmMeth(info) {
                this.$confirm(`确认发布?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.publishPostMeth(info);
                });
            },
            publishPostMeth(info) {
                this.loading=true;
                var str=JSON.stringify({info:info});
                this.$refs.choiceRoomDialog.cancelDialog(false);
                this.$refs.choiceRoomDialog.removeSessionData();
                var param = qs.stringify({
                    token: this.token,
                    hotelContract: this.query.hotelContract,
                    data: str
                });
                this.$http.post(this.global.uploadHotelVisualData, param).then(res => {
                    if (res.data.code === 100000) {
                        this.$message.success('发布成功');
                    } else {
                        this.$message.error('发布失败');
                    }
                    this.loading=false;
                }).catch(() => {
                })
            },
            partMeth(data){
                this.curInfo={
                    intro:data.intro,
                    module:'part',
                    id:data.id,
                };
                this.$refs.choiceRoomDialog.dialogShowMeth(true);
            },
            allMeth(data){
                this.$confirm('确定更新所有房间吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var info={
                        intro:data.intro,
                        module:'all',
                        roomStr:'',
                        uname:this.userNameProp,
                        id:data.id
                    };
                    this.publishPostMeth(info);
                }).catch(() => {
                });
            }
        },
        computed:{
            publishIntroCom(){
                return function(val){
                    var str=val.substring(8);
                    return str;
                }
            }
        },
        mounted(){
            this.token = sessionStorage.getItem('token');
            this.query=this.$route.query;
            this.getHotelConfigInfo();
            if(this.query.fromEditType==='choiceRoom'){
                this.$nextTick(()=>{
                    this.$refs.choiceRoomDialog.getSessionData();
                });
            }
        }
    }
</script>
<style scoped>
    .backbtn{
        margin-right:10px
    }
    .close{
        position: relative;
        left: 940px;
    }
    .det_table{
        padding: 40px 20px;
    }
    .chose_room{
        position: absolute;
        right: 300px;
        top:85px

    }
    .deteail{

    }
</style>
