<template>
  <div>
    <el-form :rules="rules" :model="form" class="tabContent" style="margin-top: 10px" ref="form" v-if="auth.type!=='htrip'" v-loading="formLoading">
      <el-col :span="12">
        <el-form-item prop="groupId" label="门店品牌" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.groupId" placeholder="请选择" class="selectItem" filterable @change="groupChange">
            <el-option v-for="item in selectData.hotelGroupList" :key="item.id" :label="item.groupname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12" style="display:flex;">
        <el-form-item prop="provinceLabel" label="所属地区" label-width="200px"></el-form-item>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="provinceLabel">
            <el-select :disabled="hotel_is_children" class="fullSelect" placeholder="省" v-model="form.provinceLabel" @change="provinceChange" filterable>
              <el-option v-for="item in selectData.province" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">

              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="cityLabel">
            <el-select :disabled="hotel_is_children" class="tenMarginLeft fullSelect" placeholder="市" v-model="form.cityLabel" @change="cityChange" filterable>
              <el-option v-for="item in selectData.city" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width:100%">
          <el-form-item prop="countryLabel">
            <el-select :disabled="hotel_is_children" class="tenMarginLeft fullSelect" placeholder="区" v-model="form.countryLabel" @change="countryChange" filterable>
              <el-option v-for="item in selectData.country" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-col>

      <!-- <el-col :span="12">
        <el-form-item label="集团门店ID" label-width="200px" prop="hotel_store_id">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_store_id"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="12">
        <el-form-item prop="hotel" label="门店名称" label-width="200px">
          <!-- <el-input :disabled="hotel_is_children" v-if="!form.city" v-model="form.hotel" name="hotel"></el-input> -->
          <el-autocomplete :disabled="!form.countryShow" style="width:100%" popper-class="my-autocomplete" v-model="form.hotel" :fetch-suggestions="querySearch" :placeholder="form.countryShow?'请输入内容':'请先选择省市区'" @select="hotelChange" @input="hotelInput">
            <template slot-scope="{ item }">
              <div class="name">{{ item.hotel_name }}</div>
              <!-- <span class="addr">{{ item.address }}</span> -->
            </template>
          </el-autocomplete>
          <!-- <el-select style="width:100%" :disabled="hotel_is_children" v-if="form.city" v-model="hotelItem" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" @change="hotelChange">
            <el-option v-for="(item) in hotelSearchList" :key="item.hotel_id" :label="item.hotel_name" :value="item.hotel_id">
            </el-option>
          </el-select> -->
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="address" label="门店地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_level" label="门店级别" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.hotel_level" placeholder="请选择" class="selectItem" filterable>
            <el-option v-for="item in selectData.hotel_level_select" :key="item.code_value" :label="item.code_label" :value="item.code_value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_quality" label="门店性质" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.hotel_quality" placeholder="请选择" class="selectItem" filterable>
            <el-option v-for="item in selectData.hotel_quality_select" :key="item.code_value" :label="item.code_label" :value="item.code_value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_room" label="房间数量" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_room" name="hotel_room" type="number"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="h_ota_price" label="最低房价" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.h_ota_price" name="h_ota_price" type="number"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12" v-if="!id">
        <el-form-item label="终端门店编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.contract_365" placeholder=""></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="id">
        <el-form-item prop="contract_365" label="终端门店编码" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.contract_365" placeholder=""></el-input>
        </el-form-item>
      </el-col> -->
      <!--<el-col :span="12" :class="query.id?'noneEl':''">-->
      <!--<el-form-item prop="selectedHotelTemLabel"  label="终端门店系统版式" label-width="200px">-->
      <!--<el-button type="primary" @click="showTemplateDialog">选择</el-button>-->
      <!--<el-input v-model="form.demand" class="hideEl" ></el-input>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <template v-if="!edit">
        <el-col :span="12" style="display:flex;">
          <!--<el-form-item :prop="id?'':'selectedHotelTemLabel'" label="终端门店系统版式" label-width="200px"> </el-form-item>-->
          <el-form-item prop="selectedHotelTemLabel" label="系统版式" label-width="200px"> </el-form-item>
          <el-form-item prop="selectedHotelTemLabel">
            <el-button type="primary" @click="showTemplateDialog">选择</el-button>
          </el-form-item>
          <el-form-item class="fullFormItem">
            <el-input :readonly="true" v-model="form.selectedHotel.label" class="selectItem tenMarginLeft" placeholder="该内容在选择系统版式后自动读取，无需填写"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <el-col :span="12" v-if="edit">
        <el-form-item label="所选版式" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.selectedHotel.label" :readonly="true"></el-input>
        </el-form-item>
      </el-col>

      <!--<el-col :span="12">-->
      <!--<el-form-item prop="selectedHotelTemLabel" label="所选版式" label-width="200px">-->
      <!--<el-input v-model="form.selectedHotel.label" :readonly="true"></el-input>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <!--<el-col :span="12" class="occupyEl">-->
      <!--<el-form-item  label-width="200px">-->
      <!--<el-input v-model="form.selectedHotel.label" :readonly="true"-->
      <!--placeholder="该内容在选择系统版式后自动读取，无需填写"></el-input>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <!-- <el-col :span="12" class="twoRow">
        <div>
          <el-form-item prop="hotel_template_id" label="终端门店模板" label-width="200px">
            <el-select :disabled="hotel_is_children"  v-model="form.hotel_template_id" placeholder="请选择" class="selectItem" filterable>
              <el-option v-for="item in selectData.hotelTemplateList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-col> -->

      <!-- <el-col :span="12">
        <el-form-item label="聚屏合作类型" label-width="200px">
          <el-select :disabled="hotel_is_children" v-model="form.juping_type" class="selectItem" filterable>
            <el-option v-for="item in selectData.juping_type_select" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col> -->

      <el-col :span="12">
        <el-form-item prop="total_num" label="系统上线数量" label-width="200px">
          <el-input :disabled="hotel_is_children" placeholder="请填写上线终端数量" v-model="form.total_num" name="total_num" type="number"></el-input>
        </el-form-item>
      </el-col>

      <!-- <el-col :span="12">
        <el-form-item prop="pms_brand" label="PMS品牌" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.pms_brand" name="address"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="12">
        <el-form-item prop="hotel_charger" label="门店负责人" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_charger"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="hotel_charger_phone" label="门店电话" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.hotel_charger_phone"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12">
        <el-form-item prop="bu_status" label="门店开业时间" label-width="200px">
          <el-date-picker :disabled="hotel_is_children" class="selectItem" v-model="form.bu_status" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否美团" label-width="200px">
          <el-select :disabled="true" v-model="form.is_ctrip" placeholder="请选择" @change="ctripChange" class="selectItem" >
            <template v-for="(item,index) in selectData.is_ctrip_select">
              <el-option :key="index" :label="item.name" :value="item.id">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-col> -->

      <!-- <el-col :span="12">
        <el-form-item prop="maintain_name" label="终端门店系统维护人" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.maintain_name" name="maintain_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="maintain_phone" label="终端门店系统维护人电话" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.maintain_phone" name="maintain_phone"></el-input>
        </el-form-item>
      </el-col> -->

      <!--  <el-col :span="12">
        <el-form-item prop="address" label="工勘表地址" label-width="200px">
          <el-input :disabled="hotel_is_children" v-model="form.address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" class="flexBox">
        <el-form-item prop="occupyRules" label="百度地图终端门店地址" label-width="200px"></el-form-item>
        <el-col :span="8" class="relativeEl">
          <el-form-item prop="baidu_address">
            <el-input :disabled="hotel_is_children" v-model="form.baidu_address">
              <i slot="suffix" class="el-input__icon el-icon-search pointer " @click="searchMeth"></i>
            </el-input>
          </el-form-item>
          <div class="searchList">
            <div class="searchListBox" v-if="searchListBoxShow">
              <div class="searchListItem" :key="index" v-for="(item,index) in selectData.addressSearchList" @click="getCoordinate(item.uid,item.adcode,item.province,item.city,item.district)">{{item.name}}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input :disabled="hotel_is_children" v-model="map.center.lat" class="selectItem tenMarginLeft"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-input :disabled="hotel_is_children" v-model="map.center.lng" class="selectItem tenMarginLeft"></el-input>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="baidu_hotel_name" label="百度地图终端门店名称" label-width="200px" style="position:relative">
          <el-input :disabled="hotel_is_children" v-model="form.baidu_hotel_name" :readonly="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-if="edit">
        <el-form-item prop="maintain_name" label="终端信息" label-width="200px">
          <el-button type="primary" @click="showTerInfo">查看</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-if="terInfoShow" :key="index" v-for="(item,index) in terInfo">
        <el-form-item prop="maintain_name" :label="index===0?'终端信息':''" label-width="200px">
          <el-row :gutter="20" class="terInfoRow">
            <el-col :span="6">
              <div class="terInfoItem">
                终端品牌：{{item.terBrandLabel}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端类型：{{item.terTypeVal}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端型号：{{item.terModelLabel}}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="terInfoItem">
                终端数量：{{item.terNumVal}}
              </div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col> -->
      <el-col :span="24" class="btnFormItem" v-show="!hotel_is_children" :class="searchListBoxShow?'active':''">
        <el-form-item label-width="200px">
          <el-button type="primary" @click="confirmMeth('form')" class="submit largeBtn rightBtn">提交</el-button>
        </el-form-item>
      </el-col>
      <div style='clear:both'></div>
      <!--<el-button type="primary" style="position:fixed;bottom:200px;right:200px;" @click="submitForm('form')" class="submit">提交</el-button>-->
    </el-form>
    <ehotel-dialog ref="ehotelDialog" @getSelectedHotel="getSelectedHotel">
    </ehotel-dialog>
    <confirm-dialog @createMeth="createMeth" ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import qs from "querystring";
import comMeth from "../../../api/method";
import { getSearchMt } from "../../../api/hotel";
export default {
  name: "OperateTableTab",
  props: ["id", "edit", "hotelName", "is_children"],
  data() {
    var validatePass2 = (rule, value, callback) => {
      let str1 = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      console.log(str1.test(value), value);
      if (value.indexOf(" ") !== -1) {
        callback(new Error("门店名称中不能有空格"));
      } else if (!str1.test(value)) {
        callback(new Error("门店名称中不能有标点符号!"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      let str1 = /[\u4e00-\u9fa5_a-zA-Z]/;
      console.log(value);
      if (!value) {
        return callback(new Error("请填写号码"));
        //    不能是英文或汉字
      } else if (str1.test(value)) {
        return callback(new Error("号码中不能包含汉字或字母"));
      } else {
        callback();
      }
    };
    var peopleName = (rule, value, callback) => {
      let str1 = /[\u4e00-\u9fa5]/;
      console.log(value);
      if (!value) {
        return callback(new Error("请填写名称"));
        //
      } else if (!str1.test(value)) {
        return callback(new Error("名称中只能输入汉字"));
      } else {
        callback();
      }
    };
    var checkNumber = (rule, value, callback) => {
      var a = "";
      if (rule.field == "hotel_room") {
        a = "客房总量";
      } else if (rule.field == "total_num") {
        a = "应上线终端数量";
      }
      if (value < 0) {
        return callback(new Error(a + "不能小于0"));
      } else if (value == 0) {
        callback();
      } else if (!Number.isInteger(Number(value))) {
        return callback(new Error(a + "为整数"));
      } else {
        callback();
      }
    };
    return {
      hotelItem: "",
      hotel_is_children: false,
      formLoading: false,
      hotelId: "",
      auth: {},
      selectData: {
        is_ctrip_select: [
          { id: "1", name: "是" },
          { id: "0", name: "否" },
        ],
        juping_type_select: [
          { id: 1, name: "广告" },
          { id: 2, name: "影视" },
          { id: 3, name: "广告和影视" },
          { id: 4, name: "独立影视 " },
        ],
        hotelGroupList: [],
        hotelTemplateList: [],
        province: [],
        city: [],
        country: [],
        hotel_level_select: [],
        hotel_quality_select: [],
        addressSearchList: [],
      },
      form: {
        juping_type: "",
        hotel_brand: "",
        groupId: "",
        hotel_store_id: "",
        hotel: "",
        contract_365: "",
        hotel_template_id: "",
        templateType: "hihotel",
        templateId: "",
        hotel_level: "",
        hotel_quality: "",
        hotel_room: "",
        total_num: "",
        h_ota_price: "",
        pms_brand: "",
        hotel_charger: "",
        hotel_charger_phone: "",
        maintain_name: "",
        maintain_phone: "",
        baidu_address: "",
        occupyRules: "",
        address: "",
        baidu_hotel_name: "",
        citycode: "",
        is_ctrip: "",
        provinceShow: "",
        province: "",
        provinceLabel: "",
        cityShow: "",
        city: "",
        cityLabel: "",
        countryShow: "",
        country: "",
        countryLabel: "",
        baidu_uid: "",
        selectedHotelTemLabel: "",
        selectedHotel: {
          type: "",
          id: "",
          label: "",
        },
      },
      loading: false,
      templateLabel: "",
      region: {
        prov_code: "",
        city_code: "",
        country: "",
      },
      rules: {
        groupId: [
          { required: true, message: "请输入门店品牌", trigger: "blur" },
        ],
        hotel_store_id: [
          {
            pattern: /^[^ ]+$/,
            message: "门店ID中不能有空格",
            trigger: "blur",
          },
        ],
        hotel: [
          { required: true, message: "请输入门店名称", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        contract_365: [
          { required: true, message: "请输入门店编码", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "终端门店编码中不能有空格",
            trigger: "blur",
          },
        ],
        templateType: [
          {
            required: true,
            message: "请选择门店系统版式",
            trigger: "blur",
          },
        ],
        hotel_level: [
          { required: true, message: "请选择门店级别", trigger: "blur" },
        ],
        hotel_quality: [
          { required: true, message: "请选择门店性质", trigger: "blur" },
        ],
        hotel_room: [
          { required: true, message: "请输入客房总量", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        total_num: [
          { required: true, message: "系统上线数量", trigger: "blur" },
          { required: true, validator: checkNumber, trigger: "blur" },
        ],
        h_ota_price: [
          { required: true, message: "请输入房价", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "房价中不能有空格",
            trigger: "blur",
          },
          {
            pattern: /^((?!0)\d+(\.\d{1,2})?)$/g,
            message: "房价最多保留两位小数且不能小于0",
            trigger: "blur",
          },
        ],
        pms_brand: [
          {
            pattern: /^[^ ]+$/,
            message: "PMS品牌中不能有空格",
            trigger: "blur",
          },
        ],
        hotel_charger: [
          { required: true, message: "请输入门店负责人", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "门店负责人中不能有空格",
            trigger: "blur",
          },
          { required: true, validator: peopleName, trigger: "blur" },
        ],
        hotel_charger_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        maintain_name: [
          {
            required: true,
            message: "请输入门店系统维护人",
            trigger: "blur",
          },
          {
            pattern: /^[^ ]+$/,
            message: "门店系统维护人中不能有空格",
            trigger: "blur",
          },
        ],
        maintain_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入地址", trigger: "blur" },
          {
            pattern: /^[^ ]+$/,
            message: "地址中不能有空格",
            trigger: "blur",
          },
        ],
        baidu_address: [
          {
            required: true,
            message: "请输入百度地图终端门店地址",
            trigger: "blur",
          },
          {
            pattern: /^[^ ]+$/,
            message: "百度地图终端门店地址中不能有空格",
            trigger: "blur",
          },
        ],
        occupyRules: [{ required: true, message: "", trigger: "blur" }],
        baidu_hotel_name: [
          {
            required: true,
            message: "请输入百度地图终端门店名称",
            trigger: "blur",
          },
          {
            pattern: /^[^ ]+$/,
            message: "百度地图终端门店名称中不能有空格",
            trigger: "blur",
          },
        ],
        provinceLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        cityLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        countryLabel: [{ required: true, message: "请选择", trigger: "blur" }],
        selectedHotelTemLabel: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
      templateDialog: {
        visible: false,
        brand: "",
        templateFormat: "",
        templateFormatLabel: "",
        brandSelect: [
          { id: 1, name: "品牌1" },
          { id: 2, name: "品牌2" },
        ],
        templateFormatRadioList: [
          // {label:'标准版',picurl:'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1751720145,557584557&fm=26&gp=0.jpg'},
        ],
        keyword: "",
      },
      token: "",
      lng: "",
      lat: "",
      map: {
        center: { lng: 116.404, lat: 39.915 },
        zoom: 3,
      },
      query: {},
      terInfo: [],
      terInfoBtn: false,
      terInfoShow: false,
      hotel_contract: "",
      showLoading: true,
      searchListBoxShow: false,
      templateList: [],
      templateList1: [],
      hotelSearchList: [],
    };
  },
  methods: {
    ctripChange() {
      this.$forceUpdate();
    },
    hotelInput() {
      this.form.is_ctrip = "0";
    },
    hotelChange(item) {
      console.log(item);
      this.form.hotel = item.hotel_name;
      this.form.address = item.hotel_addr;
      this.form.hotel_level = String(item.hotel_level);
      this.form.hotel_quality = String(item.hotel_nature);
      this.form.hotel_room = item.room_num;
      this.form.h_ota_price = item.hotel_price;
      this.map.center.lat = item.hotel_lat;
      this.map.center.lng = item.hotel_lon;
      this.form.bu_status = item.bu_status;
      this.form.hotel_charger_phone = item.hotel_phone;
      this.form.is_ctrip = String(item.is_ctrip);
    },
    querySearch(queryString, cb) {
      if (queryString !== "") {
        this.loading = true;
        setTimeout(() => {
          getSearchMt({ name: queryString, city_code: this.region.city_code })
            .then((res) => {
              this.loading = false;
              this.hotelSearchList = res.data.data;
              cb(this.hotelSearchList);
            })
            .catch((err) => {
              this.loading = false;
              console.log(res, "this.hotelSearchList");
              this.$message.waring("酒店信息加载失败");
              cb([]);
            });
        }, 600);
      } else {
        cb([]);
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          getSearchMt({ name: query, city_code: this.region.city_code })
            .then((res) => {
              this.loading = false;
              this.hotelSearchList = res.data.data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(res, "this.hotelSearchList");
              this.$message.waring("酒店信息加载失败");
            });
        }, 600);
      } else {
        this.hotelSearchList = [];
      }
    },
    groupChange(val) {
      if (this.edit) return;
      this.form.brand_name = val
        ? this.selectData.hotelGroupList.find((ele) => ele.id === val).groupname
        : "";
      this.defaultCheckTemplate(this.form.groupId);
    },
    getAllTemplateList() {
      this.$http
        .get(this.global.getHotelTemplate, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var resData = res.data.data;
            this.assignTemplateData(resData);
          }
        });
    },
    assignTemplateData(obj) {
      var that = this;
      Object.keys(obj).forEach(function (key) {
        switch (obj[key].label) {
          case "标准版":
            that.templateList = [...obj[key].list];
            break;
          case "hihotel":
            that.templateList1 = [...obj[key].list];
            break;
        }
      });
    },
    defaultCheckTemplate(group_id) {
      console.log(group_id, this.templateList);
      let arr = this.templateList.map((i) => {
        return i.group_id;
      });
      let arr1 = this.templateList1.map((i) => {
        return i.group_id;
      });
      if (arr.includes(group_id)) {
        let list = this.templateList.filter((i) => {
          if (i.group_id == group_id) {
            return i;
          }
        });
        this.form.selectedHotel = {
          id: list[0].id,
          label: list[0].label,
          type: "标准版",
        };
        this.form.selectedHotelTemLabel = this.form.selectedHotel.label;
        let type =
          this.form.selectedHotel.type === "标准版" ? "标准" : "hihotel";
        this.selectData.hotelTemplateList.filter((item, index) => {
          if (item.name.indexOf(type) !== -1) {
            this.form.hotel_template_id = item.id;
          }
        });
      }
      if (arr1.includes(group_id)) {
        let list = this.templateList1.filter((i) => {
          if (i.group_id == group_id) {
            return i;
          }
        });

        this.form.selectedHotel = {
          id: list[0].id,
          label: list[0].label,
          type: "hihotel",
        };
        this.form.selectedHotelTemLabel = this.form.selectedHotel.label;
        let type =
          this.form.selectedHotel.type === "标准版" ? "标准" : "hihotel";
        this.selectData.hotelTemplateList.filter((item, index) => {
          if (item.name.indexOf(type) !== -1) {
            this.form.hotel_template_id = item.id;
          }
        });
      }
      if (!arr.includes(group_id) && !arr1.includes(group_id)) {
        this.form.selectedHotel = {
          id: "",
          label: "",
          type: "",
        };
        this.form.selectedHotelTemLabel = "";
        this.form.hotel_template_id = "";
      }
    },
    handler({ BMap, map }) {
      this.map.center.lng = 116.404;
      this.map.center.lat = 39.915;
      this.map.zoom = 15;
    },
    confirmMeth(formName) {
    
      this.form.occupyRules = this.form.baidu_address;
      this.$refs[formName].validate((valid, test) => {
        if (valid) {
          if (this.id) {
            this.$confirm("确定保存？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.editMeth();
              })
              .catch(() => {});
          } else {
            this.$refs.confirmDialog.showDialog(true);
          }
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    judgeType() {
      if (this.edit) {
        this.editMeth();
      } else {
        this.createMeth();
      }
    },
    getProvince() {
      this.$http
        .get(this.global.getProvince, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.province = res.data.data;
          }
        });
    },
    getCity() {
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
          prov_code: this.region.prov_code,
        });
        this.$http
          .get(this.global.getCitys, {
            params: {
              token: this.token,
              prov_code: this.region.prov_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.selectData.city = res.data.data;
            }
            resolve(res);
          });
      });
    },
    getCountry() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.getDistricts, {
            params: {
              token: this.token,
              prov_code: this.region.prov_code,
              city_code: this.region.city_code,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.selectData.country = res.data.data;
            }
            resolve();
          });
      });
    },
    getOption() {
      return new Promise((resolve, reject) => {
        var param = qs.stringify({ token: this.token });
        this.$http.post(this.global.getOptions, param).then((res) => {
          if (res.data.code === 100000) {
            this.selectData.hotelGroupList = res.data.data.hotelGroupList;
            this.selectData.hotelTemplateList = res.data.data.hotelTemplateList;
            this.selectData.hotelUseState = res.data.data.hotelUseState;
            this.selectData.networkCondition = res.data.data.networkCondition;
            this.selectData.networkMode = res.data.data.networkMode;
            this.selectData.operatingState = res.data.data.operatingState;
            this.selectData.starLevel = res.data.data.starLevel;
            this.selectData.supplierId = res.data.data.supplierId;
            this.selectData.terminalCustomerFrom =
              res.data.data.terminalCustomerFrom;
            this.selectData.terminalStoreType = res.data.data.terminalStoreType;
            this.selectData.terminalType = res.data.data.terminalType;
            this.form.brand_name = res.data.data.brand_name;
            this.getProvince();
            resolve();
          }
        });
      });
    },
    getLevelQuality() {
      var param = qs.stringify({ token: this.token });
      this.$http
        .post(this.global.getHotelLevelAndQuality, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.selectData.hotel_level_select = res.data.data.level;
            this.selectData.hotel_quality_select = res.data.data.quality;
          }
        });
    },
    provinceChange(val) {
      this.region.prov_code = val.value;
      this.region.city_code = "";
      this.form.provinceShow = val.value;
      this.form.province = val.value;
      this.form.provinceLabel = val.label;
      this.form.cityShow = "";
      this.form.city = "";
      this.form.cityLabel = "";
      this.form.countryShow = "";
      this.form.country = "";
      this.form.countryLabel = "";
      this.selectData.country = [];
      this.getCity();
    },
    cityChange(val) {
      console.log(val);
      this.region.city_code = val.value;
      this.region.country = "";
      this.form.cityShow = val.value;
      this.form.city = val.value;
      this.form.cityLabel = val.label;
      this.form.countryShow = "";
      this.form.country = "";
      this.form.countryLabel = "";
      this.getCountry();
    },
    countryChange(val) {
      this.form.countryShow = val.value;
      this.form.countryLabel = val.label;
      this.form.country = val.value;
      this.form.citycode = val.value;
      this.$forceUpdate();
    },
    getDetail() {
      this.formLoading = true;
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.hotelId,
            hotel_project: this.query.hotelName,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var formData = res.data.data;
            this.form = formData;
            this.hotelItem = this.form.hotel;
            this.form.hotel_brand = formData.groupId;
            this.form.juping_type = parseInt(formData.juping_type);
            this.form.groupId = formData.groupId; //ka
            if (formData.bu_status) {
              this.form.bu_status =
                formData.bu_status.length !== 10 ? "" : formData.bu_status; //开业时间
            }
            this.form.starlevel = parseInt(formData.starlevel); //
            this.form.hotel_use_state = parseInt(formData.hotel_use_state); //终端客户状态
            this.form.ter_store_type = formData.ter_store_type
              ? parseInt(formData.ter_store_type)
              : ""; //终端门店类型
            this.form.is_coop_offline = parseInt(formData.is_coop_offline); //线下场景合作
            this.form.power_state = parseInt(formData.power_state); //上电开机
            this.region.prov_code = formData.citycode.substr(0, 2);
            this.form.provinceShow = formData.hotel_province;
            this.form.provinceLabel = formData.hotel_province;
            this.form.cityShow = formData.hotel_city;
            this.form.cityLabel = formData.hotel_city;
            this.form.is_ctrip = String(formData.is_ctrip);
            this.form.countryShow = formData.hotel_county;
            this.form.countryLabel = formData.hotel_county;
            this.hotel_contract = formData.hotel_contract;
            this.getCity().then((res) => {
              this.region.city_code = formData.citycode.substr(0, 4);
              this.getCountry().then((res) => {
                this.region.country_code = formData.citycode.substr(0, 6);
              });
            });
            var topInfo = {
              hotel: formData.hotel,
              hotel_use_state_text: formData.hotel_use_state_text,
              contract_end: formData.contract_end,
            };
            this.$emit("topInfo", topInfo);
            this.form.selectedHotel = {
              id: 11,
              label: formData.template_name,
              type: "tabStandard",
            };
            this.form.selectedHotelTemLabel = this.form.selectedHotel.label;
            this.formLoading = false;
          } else {
            this.$message.warning(res.data.msg);
            this.formLoading = false;
          }
          this.showLoading = false;
          this.$emit("loading", this.showLoading);
        });
    },
    selectZeroData(val) {
      var data;
    },

    confirm(e, target, item) {
      this.map.center.lat = e.currentTarget.map.re.lat;
      this.map.center.lng = e.currentTarget.map.re.lng;
    },
    getHotelTemplate() {
      this.$http
        .get(this.global.getHotelTemplate, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var arr = [];
            arr.push(res.data.data[1]);
            arr.push(res.data.data[2]);
            this.templateDialog.templateFormatRadioList = arr;
          }
        });
    },

    templateTypeChange(val) {
      if (this.query.id === "" || !this.query.id) {
        if (val === "ehotel") {
          this.form.templateId = "";
          this.$refs.ehotelDialog.showDialog();
        } else {
          this.form.templateLabel = "";
          this.getHotelTemplateId("hihotel");
          this.$refs.ehotelDialog.clearDialogData();
          this.templateLabel = "";
        }
      }
    },
    getHotelTemplateId(val) {
      this.selectData.hotelTemplateList.filter((item, index) => {
        if (item.name.indexOf(val) !== -1) {
          this.form.hotel_template_id = item.id;
        }
      });
    },
    setTemplateType(val) {
      this.form.templateType = val;
    },
    getTemplateLabel(val) {
      this.templateLabel = val;
    },
    getParam() {
      var paramData = {
        token: this.token,
        hotel_brand: this.form.groupId,
        juping_type: this.form.juping_type,
        groupId: this.form.groupId,
        hotel_store_id: this.form.hotel_store_id,
        hotel: this.form.hotel,
        contract_365: this.form.contract_365,
        hotel_template_id: this.form.hotel_template_id, //终端门店模板，少
        hotel_level: this.form.hotel_level,
        hotel_quality: this.form.hotel_quality, //终端门店性质
        hotel_room: this.form.hotel_room,
        total_num: this.form.total_num,
        h_ota_price: this.form.h_ota_price,
        pms_brand: this.form.pms_brand,
        hotel_use_state: this.form.hotel_use_state, //终端客户状态
        hotel_charger: this.form.hotel_charger,
        hotel_charger_phone: this.form.hotel_charger_phone,
        maintain_name: this.form.maintain_name,
        maintain_phone: this.form.maintain_phone,
        citycode: this.form.citycode,
        hotel_province: this.form.provinceLabel,
        hotel_city: this.form.cityLabel,
        hotel_county: this.form.countryLabel,
        baidu_address: this.form.baidu_address,
        address: this.form.address,
        baidu_hotel_name: this.form.baidu_hotel_name,
        baidu_uid: this.form.baidu_uid,
        lat: this.map.center.lat,
        lng: this.map.center.lng,
        brand_name: this.form.brand_name,
        bu_status: this.form.bu_status,
        is_ctrip: this.form.is_ctrip,
      };
      for (var i = 0; i < this.selectData.hotelGroupList.length; i++) {
        if (this.selectData.hotelGroupList[i].id == paramData.groupId) {
          paramData.brand_name = this.selectData.hotelGroupList[i].groupname;
        }
      }
      return paramData;
    },
    createMeth() {
      var param = this.getParam();
      var ehotelDialog = this.$refs.ehotelDialog;
      var templateId = "";
      // if(this.form.templateType!=='hihotel'){
      //     templateId=ehotelDialog.templateDialog.templateId;
      // }
      var template = JSON.stringify(this.form.selectedHotel);
      param.template = template;
      param = qs.stringify(param);
      this.$http.post(this.global.create, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
            sessionStorage.setItem("defaultActiveId", 247);
      this.$router.push({ path: "/HotelManAll" });
        } else if (res.data.code === 100001) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100002) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100003) {
          this.$message.error(res.data.msg);
        }
      });
    },
    editMeth() {
      var param = this.getParam();
      param.id = this.id;
      param = qs.stringify(param);
      this.$http.post(this.global.update, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          // this.$router.push({path:'/hotelManAll'});
        } else if (res.data.code === 100001) {
          this.$message.error(res.data.msg);
        } else if (res.data.code === 100002) {
          this.$message.error(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    isEmptyObj(obj) {
      for (let i in Object.keys(obj)) {
        return false; // 进入循环即不为空
      }
      return true;
    },
    getSelectedHotel(val, type) {
      this.form.selectedHotel = val;
      this.form.selectedHotelTemLabel = val.label;
      this.selectData.hotelTemplateList.filter((item, index) => {
        if (item.name.indexOf(type) !== -1) {
          this.form.hotel_template_id = item.id;
        }
      });
    },
    showTerInfo() {
      this.terInfoShow = true;
      var param = qs.stringify({
        token: this.token,
        hotelContract: this.hotel_contract,
      });
      this.$http.post(this.global.getHotelTerminalInfo, param).then((res) => {
        if (res.data.code === 100000) {
          if (res.data.data.length === 0) {
            this.$message.warning("暂无终端信息");
          } else {
            this.terInfo = res.data.data;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    searchMeth() {
      this.selectData.addressSearchList = [];
      this.searchListBoxShow = true;
      var param = qs.stringify({
        token: this.token,
        keyword: this.form.baidu_address,
      });
      this.$http
        .post(this.global.getBaiduSearch, param)
        .then((res) => {
          if (res.data.code === 100000) {
            if (res.data.data.length === 0) {
              this.selectData.addressSearchList = [{ name: "暂无数据" }];
            } else {
              this.selectData.addressSearchList = res.data.data;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    getCoordinate(uid, adcode, province, city, district) {
      if (uid) {
        var param = qs.stringify({
          token: this.token,
          uid: uid,
        });
        this.$http
          .post(this.global.getBaiduInfo, param)
          .then((res) => {
            if (res.data.code === 100000) {
              var resData = res.data.data;
              this.map.center.lat = resData.location.lat;
              this.map.center.lng = resData.location.lng;
              this.form.baidu_address = resData.address;
              this.form.baidu_hotel_name = resData.name;
              this.form.baidu_uid = resData.uid;
            } else {
              this.$message.error(res.msg);
            }
            this.searchListBoxShow = false;
          })
          .catch(() => {
            this.$message.error("返回错误");
            this.searchListBoxShow = false;
          });
        this.form.provinceShow = province;
        this.form.provinceLabel = province;
        this.form.cityShow = city;
        this.form.cityLabel = city;
        this.form.countryShow = district;
        this.form.countryLabel = district;
        this.form.citycode = adcode;
      }
    },
    showTemplateDialog(val) {
      this.$refs.ehotelDialog.showDialog();
    },
  },

  watch: {
    id: {
      handler: function (newVal) {
        if (this.edit) {
          this.hotelId = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    is_children(newVal) {
      this.is_children = newVal;
      this.hotel_is_children = newVal;
    },
    hotelName(newVal) {
      if (this.edit) {
        this.hotelProgram = newVal;
        if (this.hotelProgram) {
          this.tabShow = true;
          this.token = sessionStorage.getItem("token");
          this.getDetail();
        }
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    var query = {
      id: this.id,
      fromUrl: "",
    };
    // if(this.$route.query){
    //      var query = this.$route.query;
    //         if (!this.isEmptyObj(query)) {
    //             this.query = query;
    //         }
    // }else{
    //
    //     }
    // }
    this.getOption();
    if (!this.isEmptyObj(query)) {
      this.hotelId = this.id;
      this.query.id = query.id;
      this.query.id = query.id;
      this.query.fromUrl = query.fromUrl;
      if (this.hotelId) {
        this.getOption().then(() => {
          this.getDetail();
        });
      }
    } else {
      this.getOption();
    }
    this.getProvince();
    this.getLevelQuality();
    this.getAllTemplateList();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>

<style scoped lang="scss">
/*地图容器*/
.bm-view {
  width: 100%;
  height: 420px;
}
.selectItem {
  width: 100%;
}
.radioBox {
  margin-top: 12px;
}
.coordinateBox {
  display: flex;
}
.coordinate {
  width: 50%;
}
.searchBox {
  display: none !important;
}
.terInfoRow {
  width: 100%;
}
.noneEl {
  display: none;
}
.occupyEl {
  visibility: hidden;
}
.hideEl {
  visibility: hidden;
}
.alignCenter {
  margin-top: 25px;
}
.rightBtn {
  margin-left: auto;
}
.searchList {
  width: 100%;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  z-index: 3;
  box-sizing: border-box;
  /*padding-left:200px;*/
  top: 40px;
}
.searchListBox {
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  box-sizing: border-box;
}
.searchListItem {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 14px;
}
.relativeEl {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.tenMarginLeft {
  margin-left: 10px;
}
.flexBox {
  display: flex;
}
.btnFormItem.active {
  margin-bottom: 260px;
}
.fullFormItem {
  width: 100%;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
