<template>
	<hotel-all :pageInfo="pageInfo" ></hotel-all>
<!--    <hotel-man-index :pageInfo="pageInfo" :showQuickSearch="true"></hotel-man-index>-->
</template>

<script>
    export default {
        name: "HotelManNew",
        data:()=>({
            pageInfo:{
                hotelState:'newList',
                text:'新建',
                fromUrl:'hotelManNewList'
            }
        })
    }
</script>

<style scoped>

</style>