<template>
  <div>
    <el-button style="margin-left:10px" :loading="loading" @click="exportMeth">导出</el-button>
  </div>
</template>
<script>
import { getList } from "../operation/DeciveManage/api/appLog"
export default {
  props: {
    listQuery: {
      type: Object,
      default: ()=> {
        return {}
      }
    },
    apiExpor: {
      type: Function,
      default: ()=> {
        
      }
    }
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    exportMeth() {
      let params = {
        ...this.listQuery,
        import_excel: 1
      }
      this.$message({
        message: "导出请求已提交，导出所需时间较长，请耐心等待……",
        type: "success",
        duration: 6000,
      });
      this.loading = true;
      getList(params,{responseType: "blob"}).then(res => {
        const tmpDown = new Blob([res.data]);
        const a = document.createElement("a");
        a.href = URL.createObjectURL(tmpDown);
        a.download = "终端日志.xlsx";
        a.click();
        this.loading = false;
      })
    }
  },
  mounted () {
  },
  watch: {
    queryList: {
      handler: function (newVal) {
       this.queryList=newVal;
       
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style>

</style>