<!-- 酒店品牌添加/编辑 弹窗 -->
<template>
  <el-dialog
    :title="JSON.stringify(current) == '{}' ? '添加品牌' : '品牌编辑'"
    :visible.sync="dialogVisible"
    width="550px"
    @open="open"
    @close="close"
  >
    <el-form :model="form" label-width="100px" ref="branForm" :rules="rules">
      <el-form-item label="选择集团：" prop="group_id">
        <el-select v-model="form.group_id" placeholder="" style="width:100%">
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="酒店品牌：" prop="brand">
        <el-input v-model="form.brand"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { addBrand, editBrand } from "../api/brand";
import { getGroupList } from "../api/group";
export default {
  name: "brandGroupOperDia",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      groupList: [],
      form: {
        group_id: "",
        brand: "",
      },
      rules: {
        group_id: [{ required: true, message: "酒店集团不能为空" }],
        brand: [{ required: true, message: "酒店品牌不能为空" }],
      },
    };
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.branForm.resetFields();
        let obj = { ...this.current };
        if (JSON.stringify(this.current) != "{}") {
          this.form.group_id = obj.groupId;
          this.form.brand = obj.name;
        }
        getGroupList({
          page: 1,
          size: 9999,
        }).then((data) => {
          this.groupList = data.data.data.row;
        });
      });
    },
    close() {
      // this.$refs.branForm.resetFields();
    },
    submit() {
      this.$refs.branForm.validate((valid) => {
        if (valid) {
          // 新增
          if (JSON.stringify(this.current) == "{}") {
            addBrand({
              name: this.form.brand,
              group_id: this.form.group_id,
            }).then((data) => {
              if (data.data.code == 100000) {
                this.$message.success(data.data.msg);
                this.dialogVisible = false;
                this.$emit("refresh");
              } else {
                this.$message.error(data.data.msg);
              }
            });
          }
          // 编辑
          else {
            editBrand({
              id: this.current.id,
              name: this.form.brand,
              group_id: this.form.group_id,
            }).then((data) => {
              if (data.data.code == 100000) {
                this.$message.success(data.data.msg);
                this.dialogVisible = false;
                this.$emit("refresh");
              } else {
                this.$message.error(data.data.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
