<!-- 参数配置添加/修改弹窗 -->
<template>
  <el-dialog
    title="参数名管理"
    :visible.sync="dialogVisible"
    width="550px"
    @open="open"
    @closed="handleClose"
  >
    <el-form :model="form" ref="form" label-width="100px">
      <el-form-item
        label="参数名："
        prop="name"
        v-if="JSON.stringify(this.current) == '{}'"
      >
        <el-input v-model="form.name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item
        label="原参数名："
        prop="name"
        v-if="JSON.stringify(this.current) != '{}'"
      >
        <el-input v-model="form.oldName" placeholder=""></el-input>
      </el-form-item>
      <el-form-item
        label="新参数名："
        prop="name"
        v-if="JSON.stringify(this.current) != '{}'"
      >
        <el-input v-model="form.newName" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { add, edit } from "../api/params";
export default {
  name: "paramsOper",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      form: {
        name: "",
        oldName: "",
        newName: "",
      },
    };
  },
  methods: {
    open() {
      if (JSON.stringify(this.current) != "{}") {
        this.form.oldName = this.current.name;
      } else {
        this.form = {
          name: "",
          oldName: "",
          newName: "",
        };
      }
    },
    submit() {
      if (JSON.stringify(this.current) != "{}") {
        edit({
          id: this.current.id,
          type: "parameter",
          newPackageName: this.form.newName,
          oldPackageName: this.form.oldName,
        }).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.$emit("refresh");
            this.dialogVisible = false;
          } else {
            this.$message.error(data.data.msg);
          }
        });
      } else {
        add({
          newPackageName: this.form.name,
          type: "parameter",
        }).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.$emit("refresh");
            this.dialogVisible = false;
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
