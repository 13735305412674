<template>
    <hotel-all :pageInfo="pageInfo"></hotel-all>
</template>

<script>
    export default {
        name: "HotelManNew",
        data:()=>({
            pageInfo:{
                hotelState:'offline',
                text:'离线',
                fromUrl:'hotelManAllOffline'
            }
        })
    }
</script>

<style scoped>

</style>