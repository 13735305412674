<template>
  <div v-if="topcard" style="padding: 0;margin: 0">
    <el-card v-if="showPartProp.self || showPartProp.other || showPartProp.videoRoom || showPartProp.virtual || showPartProp.date|| showPartProp.allot|| showPartProp.belong ||showPartProp.historybtn||showPartProp.historyselect" class="box-card">
      <div class="quickSearchBox">
        <div v-if="showPartProp.date" class="quickDateSearch">
          <div :class="dateCurActive('yesDay')" class="item" @click="dateSearch('yesDay')">昨日</div>
          <div :class="dateCurActive('week')" class="item" @click="dateSearch('week')">本周</div>
          <div :class="dateCurActive('month')" class="item" @click="dateSearch('month')">本月</div>
          <div :class="dateCurActive('year')" class="item" @click="dateSearch('year')">本年</div>
        </div>
        <div :class="{rightBtn:showPartProp.date}" class="quickDateSearch">
          <div v-if="showPartProp.self || showPartProp.other || showPartProp.videoRoom || showPartProp.virtual ||showPartProp.allot|| showPartProp.belong" class="groupFilter">
            <div v-if="btn.belong" :class="[natureCurActive('belong'),]" class="item" @click="natureSearch('belong')">
              所属
            </div>
            <div v-if="btn.allot" :class="[natureCurActive('allot')]" class="item" @click="natureSearch('allot')">归属
            </div>
            <div v-if="btn.subordinate" :class="[natureCurActive('subordinate')]" class="item" @click="natureSearch('subordinate')">下级
            </div>
          </div>
          <div v-if="showPartProp.self || showPartProp.other || showPartProp.videoRoom || showPartProp.virtual ||showPartProp.allot|| showPartProp.belong" class="groupFilter" style="margin-right: 20px">
            <div v-if="btn.self" :class="[natureCurActive('self'),]" class="item" @click="  natureSearch('self')">自有
            </div>
            <div v-if="btn.other&&showPartProp.other" :class="[natureCurActive('other')]" class="item" @click="  natureSearch('other')">聚屏
            </div>
            <div v-if="btn.videoRoom" :class="[natureCurActive('videoRoom')]" class="item" @click="  natureSearch('videoRoom')">影音房
            </div>
            <!--					<div class="item" v-if="btn.virtual"  :class="[natureCurActive('virtual')]"@click="  natureSearch('virtual')">虚拟运营</div>-->
          </div>
        </div>
        <div class="quickDateSearch rightBtn" style="float:right" v-if="showPartProp.historybtn&&nowYears==2021">
          <div class="item active" :class="[natureCurActive(2020),]" @click="showYear(2020)">
            2020年
          </div>
          <el-select v-model="yearSelect" placeholder="请选择年份" v-if="showPartProp.historyselect&&nowYears!==2021" @change="showYearSelect(yearSelect)">
            <el-option v-for="item in yearsData" :key="item.name" :label="item.label" :value="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="!allLoadingState" class="shade"></div>
    </el-card>
  </div>
</template>
<script>
import SearchBus from "./searchBus";
import qs from "querystring";

export default {
  name: "ScreenTypeSearch",
  props: {
    showPartProp: {},
    pageInfo: {
      type: Object,
      default: () => {
        return {
          hotelState: "",
        };
      },
    },
    allLoadingState: {},
    setSessionDataProp: {
      default: true,
    },
    dateCurProp: {
      default: "",
    },
  },
  data: () => ({
    apaasData: null,
    yearSelect: "",
    yearsData: [],
    yearsCur: ["2020"],
    screenType: "",
    token: "",
    accountType: "",
    topcard: true,
    btn: {},
    natureCur: [],
    curDate: {
      yesDay: "",
      curDay: "",
      startWeek: "",
      endWeek: "",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    },
    dateCur: "",
    startTime: "",
    endTime: "",
    year: "2020",
    yearbtn: false,
    nowYears: "",
  }),
  methods: {
    showYear(year) {
      if (this.allLoadingState) {
        var index = this.yearsCur.indexOf(year);
        if (index !== -1) {
          this.yearsCur.splice(index, 1);
        } else {
          this.yearsCur.push(year);
        }
        var type = JSON.parse(JSON.stringify(this.yearsCur));
        if (type.length == 0) {
          var date = new Date();
          var nowYears = date.getFullYear();
          type.push(nowYears);
        }
        this.year = type;
        this.setSessionData();
        this.searchMeth();
      }
    },
    showYearSelect(year) {
      var date = new Date();
      var nowYears = date.getFullYear();
      this.year = year;
      this.setSessionData();
      this.searchMeth();
    },
    yearTabs() {
      var smallYears = 2020;
      var date = new Date();
      var nowYears = date.getFullYear();
      this.nowYears = nowYears;
      var Years = nowYears - smallYears - 1;
      var arrYear = [];
      for (var i = 0; i <= Years; i++) {
        arrYear.push(nowYears--);
      }
      arrYear.forEach((ele) => {
        //下拉框的数组
        this.yearsData.push({
          name: ele,
          label: ele + "年",
        });
      });
    },
    natureSearch(type) {
      if (this.allLoadingState) {
        this.setTopSearchDefault(type);
        var index = this.natureCur.indexOf(type);
        if (index !== -1) {
          this.natureCur.splice(index, 1);
        } else {
          this.natureCur.push(type);
        }
        var type = JSON.parse(JSON.stringify(this.natureCur));
        this.screenType = type.join(",");
        this.setSessionData();
        this.searchMeth();
      }
    },
    dateSearch(type) {
      var curDate = this.curDate;
      this.setTopSearchDefault("date");
      if (this.dateCur === type) {
        this.dateCur = "";
        if (this.pageInfo.hotelState === "newList") {
          this.startTime = curDate.curDay;
          this.endTime = curDate.curDay;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else {
        this.dateCur = type;
        if (type === "week") {
          this.startTime = curDate.startWeek;
          this.endTime = curDate.endWeek;
        } else if (type === "month") {
          this.startTime = curDate.startMonth;
          this.endTime = curDate.endMonth;
        } else if (type === "year") {
          this.startTime = curDate.startYear;
          this.endTime = curDate.endYear;
        } else if (type === "yesDay") {
          this.startTime = curDate.yesDay;
          this.endTime = curDate.yesDay;
        }
      }
      this.setSessionData("date");
      this.searchMeth();
    },
    setTopSearchDefault(type) {
      if (type === "virtual") {
        SearchBus.$emit("setTopSearchDefault", "hotel_use_state");
      } else if (type === "date") {
        SearchBus.$emit("setTopSearchDefault", "date");
      }
    },
    setSessionData(type) {
      if (this.setSessionDataProp) {
        var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
        if (filterCon) {
          filterCon.screenType = this.natureCur;
          if (type === "date") {
            if (this.pageInfo.hotelState === "newList") {
              filterCon.hotelCreateTimeStart = this.startTime;
              filterCon.hotelCreateTimeEnd = this.endTime;
            } else {
              filterCon.startTime = this.startTime;
              filterCon.endTime = this.endTime;
            }
            filterCon.dateCur = this.dateCur;
          }
          sessionStorage.setItem("filterCon", JSON.stringify(filterCon));
        }
      }
    },
    searchMeth() {
      this.$emit(
        "screenTypeSearch",
        this.natureCur.join(","),
        this.startTime,
        this.endTime,
        this.belongCur,
        this.year
      );
    },
    getYesDay() {
      var time = new Date().getTime() - 24 * 60 * 60 * 1000;
      var yesterday = new Date(time);
      var month = yesterday.getMonth() + 1;
      var day = yesterday.getDate();
      this.curDate.yesDay =
        yesterday.getFullYear() +
        "-" +
        (month > 9 ? month : "0" + month) +
        "-" +
        (yesterday.getDate() > 9
          ? yesterday.getDate()
          : "0" + yesterday.getDate());
    },
    getCurDay() {
      var time = new Date().getTime();
      var today = new Date(time);
      var month = today.getMonth() + 1;
      var day = today.getDate();
      this.curDate.curDay =
        today.getFullYear() +
        "-" +
        (month > 9 ? month : "0" + month) +
        "-" +
        (today.getDate() > 9 ? today.getDate() : "0" + today.getDate());
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    getCurWeek() {
      var startStop = new Array();
      //获取当前时间
      var currentDate = new Date();
      //返回date是一周中的某一天
      var week = currentDate.getDay();
      //返回date是一个月中的某一天
      var month = currentDate.getDate();
      //一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      //减去的天数
      var minusDay = week != 0 ? week - 1 : 6;
      //alert(minusDay);
      //本周 周一
      var monday = new Date(currentDate.getTime() - minusDay * millisecond);
      //本周 周日
      var sunday = new Date(monday.getTime() + 6 * millisecond);
      var sy = monday.getFullYear();
      var sm = monday.getMonth() + 1;
      var sd = monday.getDate();
      var ey = sunday.getFullYear();
      var em = sunday.getMonth() + 1;
      var ed = sunday.getDate();
      var s = sy + "-" + this.add0(sm) + "-" + this.add0(sd); //开始
      var e = ey + "-" + this.add0(em) + "-" + this.add0(ed); //结束
      //收益中心、删除酒店展示昨日，其他酒店模块展示即时
      if (
        this.pageInfo.hotelState == "incomesta" ||
        this.pageInfo.hotelState == "del"
      ) {
        if (week == "1") {
          this.curDate.endWeek = s;
          this.curDate.startWeek = s;
        } else {
          this.curDate.startWeek = s;
          this.curDate.endWeek = this.curDate.yesDay;
        }
      } else if (
        this.pageInfo.hotelState == "newList" ||
        this.pageInfo.hotelState == "waitForChecking"
      ) {
        if (week == "1") {
          this.curDate.endWeek = s;
          this.curDate.startWeek = s;
        } else {
          this.curDate.startWeek = s;
          this.curDate.endWeek = this.curDate.curDay;
        }
      }
    },
    getCurMonth(type, months) {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      if (Math.abs(months) > 12) {
        months = months % 12;
      }
      if (months != 0) {
        if (month + months > 12) {
          year++;
          month = (month + months) % 12;
        } else if (month + months < 1) {
          year--;
          month = 12 + month + months;
        } else {
          month = month + months;
        }
      }
      month = month < 10 ? "0" + month : month;
      var date = d.getDate();
      var firstday = year + "-" + month + "-" + "01";
      var lastday = "";
      if (
        month == "01" ||
        month == "03" ||
        month == "05" ||
        month == "07" ||
        month == "08" ||
        month == "10" ||
        month == "12"
      ) {
        lastday = year + "-" + month + "-" + 31;
      } else if (month == "02") {
        if (
          (year % 4 == 0 && year % 100 != 0) ||
          (year % 100 == 0 && year % 400 == 0)
        ) {
          lastday = year + "-" + month + "-" + 29;
        } else {
          lastday = year + "-" + month + "-" + 28;
        }
      } else {
        lastday = year + "-" + month + "-" + 30;
      }
      var day = "";
      if (
        this.pageInfo.hotelState == "incomesta" ||
        this.pageInfo.hotelState == "del"
      ) {
        if (firstday == this.curDate.curDay) {
          if (type == "s") {
            day = firstday;
          } else if (type == "e") {
            day = this.curDate.curDay;
          }
        } else {
          if (type == "s") {
            day = firstday;
          } else if (type == "e") {
            day = this.curDate.yesDay;
          }
        }
      } else if (
        this.pageInfo.hotelState == "newList" ||
        this.pageInfo.hotelState == "waitForChecking"
      ) {
        if (type == "s") {
          day = firstday;
        } else if (type == "e") {
          day = this.curDate.curDay;
        }
      }
      return day;
    },
    getCurYear(type, dates) {
      var dd = new Date();
      var n = dates || 0;
      var year = dd.getFullYear() + Number(n);
      var firstday = year + "-01-01";
      if (
        this.pageInfo.hotelState == "incomesta" ||
        this.pageInfo.hotelState == "del"
      ) {
        if (firstday == this.curDate.curDay) {
          if (type == "s") {
            var day = year + "-01-01";
          }
          if (type == "e") {
            var day = this.curDate.curDay;
          }
        } else {
          if (type == "s") {
            var day = year + "-01-01";
          }
          if (type == "e") {
            // var day = year + "-12-31";
            var day = this.curDate.yesDay;
          }
        }
      } else if (
        this.pageInfo.hotelState == "newList" ||
        this.pageInfo.hotelState == "waitForChecking"
      ) {
        if (type == "s") {
          var day = year + "-01-01";
        } else if (type == "e") {
          day = this.curDate.curDay;
        }
      }
      if (!type) {
        var day = year + "-01-01/" + year + "-12-31";
      }
      return day;
    },
    getAllTimeMeth() {
      this.getYesDay();
      this.getCurDay();
      this.getCurWeek();
      this.curDate.startMonth = this.getCurMonth("s", 0);
      this.curDate.endMonth = this.getCurMonth("e", 0);
      this.curDate.startYear = this.getCurYear("s", 0);
      this.curDate.endYear = this.getCurYear("e", 0);
    },
    getScreenTypeSearchShow() {
      var param = qs.stringify({
        token: this.token,
      });
      if (this.apaasData) {
        this.btn = {
          self: true,
          other: true,
          videoRoom: true,
        };
      } else {
        this.$http.post(this.global.getIndexHeader, param).then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.accountType = res.data.data.accountType;
          } else {
          }
          switch (this.accountType) {
            case 1:
              this.btn = {
                self: true,
                other: true,
                videoRoom: true,
              };
              break;
            case 2:
              this.btn = {
                self: true,
                other: true,
                videoRoom: true,
              };
              break;
            case 3:
              this.topcard = false;
              this.screenType = "";
              break;
            case 4:
              this.topcard = false;
              this.screenType = "";
              break;
            case 5:
              this.btn = {
                belong: true,
                subordinate: true,
              };
              break;
            case 6:
              this.btn = {
                allot: true,
                belong: true,
                subordinate: true,
              };
          }
        });
      }
    },
    //按钮开屏选中
    screenTypeSearch() {
      var param = qs.stringify({
        token: this.token,
      });
      if (this.apaasData) {
        this.natureCur = ["self"];
      } else {
        this.$http.post(this.global.getIndexHeader, param).then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.accountType = res.data.data.accountType;
          } else {
          }
          switch (this.accountType) {
            case 1:
              this.natureCur = ["self"];
              break;
            case 2:
              this.natureCur = ["self"];
              break;
            case 3:
              this.accountType = "3";
              this.topcard = false;
              break;
            case 4:
              this.topcard = false;
              accountType: this.accountType;
              break;
            case 5:
              this.natureCur = ["belong"];
              break;
            case 6:
              this.natureCur = ["belong"];
          }
        });
      }
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.apaasData = sessionStorage.getItem("apaasData");
    var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
    if (filterCon) {
      var screenType = JSON.parse(
        sessionStorage.getItem("filterCon")
      ).screenType;
      var dateCur = JSON.parse(sessionStorage.getItem("filterCon")).dateCur;
      if (screenType) {
        this.natureCur = screenType;
      }
      if (dateCur) {
        this.dateCur = dateCur;
      }
    }
    this.getAllTimeMeth();
    this.dateCur = this.dateCurProp;
    this.getScreenTypeSearchShow();
    this.screenTypeSearch();
    this.yearTabs();
  },
  created() {
    SearchBus.$on("setScreenTypeSearchDefault", (type) => {
      if (type === "hotel_use_state") {
        var index = this.natureCur.indexOf("virtual");
        if (index !== -1) {
          this.natureCur.splice(index, 1);
        }
      } else if (type === "date") {
        this.dateCur = "";
        this.startTime = "";
        this.endTime = "";
      } else {
        this.setSessionData();
      }
    });
  },
  computed: {
    natureCurActive() {
      return function (val) {
        var str = "";
        if (this.natureCur) {
          if (this.natureCur.indexOf(val) !== -1) {
            str = "active";
          }
        }
        if (this.yearsCur) {
          if (this.yearsCur.indexOf(val) !== -1) {
            str = "active";
          }
        }
        return str;
      };
    },
    dateCurActive() {
      return function (val) {
        var str = "";
        if (this.dateCur) {
          this.dateCur === val ? (str = "active") : "";
        }
        return str;
      };
    },
    belongCurActive() {
      return function (val) {
        var str = "";
        if (this.belongCurActive) {
          this.belongCur === val ? (str = "active") : "";
        }
        return str;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.quickSearch {
  display: flex;
}

.quickSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}

.quickDateSearch .item.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.quickDateSearch .item.disabled {
  border-color: #ebeef5 !important;
  color: #c0c4cc !important;
  cursor: wait;
}

.quickDateBox {
  display: flex;
  align-items: center;
}

.quickDateSearch {
  display: flex;
}

.quickDateSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  // padding: 12px 20px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-right: 15px;
}

.quickDateSearch .item:last-child {
  margin-right: 0px;
}

.quickSearchBox {
  display: flex;
}

.groupFilter {
  /*margin-right: 10px;*/
  display: flex;
}

.groupFilter:last-child {
  margin-right: 0;
}

.box-card {
  position: relative;
  height: 80px;
}

.shade {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  cursor: wait;
}
</style>
