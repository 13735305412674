import { render, staticRenderFns } from "./hoteMouldeMenu.vue?vue&type=template&id=1392e0fa&scoped=true"
import script from "./hoteMouldeMenu.vue?vue&type=script&lang=js"
export * from "./hoteMouldeMenu.vue?vue&type=script&lang=js"
import style0 from "./hoteMouldeMenu.vue?vue&type=style&index=0&id=1392e0fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1392e0fa",
  null
  
)

export default component.exports