<template>
  <div>
    <div class="msgShowBox">
      <!-- 授权管理界面 -->
      <div class="msgShowBoxLeft">
        <div class="picBox">
          <img src="@/assets/contentIcon.png" alt="">
        </div>
        <div class="textBox">
          <p>影视内容授权书</p>
          <p style="line-height:16px">状态为“使用中”并安装使用携旅极光TV APP的酒店可在线生成携旅影视内容授权书。授权书自生成日起有效期一年。符合条件酒店授权到期后自动续期一年。如酒店不再使用携旅OS系统或携旅极光TV APP，授权书自动失效。</p>
        </div>
      </div>
      <el-button class="largeBtn" type="primary" @click="seeAuthorizationBook">查 看</el-button>
    </div>
    <div class="codeShowBox">
      <!-- <img src="@/assets/code.png" alt="">
      <span>如有疑问，请联系客服</span> -->
    </div>
    <!-- 授权管理界面 -->
    <!-- 未达到授权资格弹框 -->
    <el-dialog title="提示" :visible.sync="noBookVisible" width="30%" :modal="false" :before-close="handleClose">
      <div style="font-size:14px;text-align:center;padding-bottom:20px;margin-top:-10px">
        <span>该酒店未达到授权资格，无法提供授权书。</span>
      </div>
    </el-dialog>
    <!-- 未达到授权资格弹框 -->
    <!-- 达到授权资格授权详情对话框 -->
    <el-dialog class="hasBookVisible" title="查看授权书" :visible.sync="hasBookVisible" width="50%" :modal="false" :before-close="handleClose">
      <div class="cooperationBook" v-if="(auth.type!=='htrip'&&htripData.ce_state!==3)||auth.type==='htrip'">
        <div class="hasBooktitle">
          <span>合作授权书</span>
          <div>
            <el-button class="largeBtn" type="primary" v-if="htripData.ce_state===1&&showFunModel('授权关闭')" @click="closeBook('1')">关闭授权书</el-button>
            <el-button class="largeBtn" type="primary" v-if="htripData.ce_state===2&&showFunModel('授权关闭')" @click="openBook('1')">打开授权书</el-button>
            <el-button class="largeBtn" type="primary" v-if="htripData.ce_state!==1&&showFunModel('授权生成')" @click="creatHtrip">生成授权书</el-button>
          </div>

        </div>
        <el-form :rules="cooperationBookRule" v-if="htripData.ce_state!==1&&showFunModel('授权生成')" style="margin-top:20px" ref="cooperationBookForm" :model="cooperationBookForm" label-width="110px">
          <el-form-item label="编辑酒店名称" prop="name">
            <el-input style="width:500px" v-model="cooperationBookForm.name"></el-input>
          </el-form-item>
          <el-form-item label="编辑授权期限" prop="time">
            <el-date-picker style="width:500px" v-model="cooperationBookForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="编辑授权日期" prop="date">
            <el-date-picker style="width:500px" v-model="cooperationBookForm.date" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div style="text-align:center;color:red" v-if="htripData.ce_state===2">关闭原因：{{htripData.remarks}}</div>
        <div class="bookBox" v-if="htripData.ce_state===1">

          <img id="htripPic" :src="htripData.ce_url" alt="">
          <div class="btnBox">
            <el-button class="largeBtn smallBtn" type="primary" @click="downLoadPic(htripData.ce_url)">下 载</el-button>
            <el-button class="largeBtn smallBtn" type="primary" @click="printPic(htripData.ce_url)">打 印</el-button>
          </div>
        </div>

      </div>
      <div class="cooperationTVBook" v-if="(auth.type!=='htrip'&&tencentData.ce_state!==3)||auth.type==='htrip'">
        <div class="hasBooktitle">
          <span>“极光TV”合作授权书</span>
          <div>
            <el-button class="largeBtn" type="primary" v-if="tencentData.ce_state===1&&showFunModel('授权关闭')" @click="closeBook('2')">关闭授权书</el-button>
            <el-button class="largeBtn" type="primary" v-if="tencentData.ce_state===2&&showFunModel('授权关闭')" @click="openBook('2')">打开授权书</el-button>
            <el-button v-if="tencentData.ce_state!==1&&showFunModel('授权生成')&&fileList.length !== 0" class="largeBtn " type="primary" @click="upLoadBook">确 定</el-button>
          </div>
        </div>
        <div class="bookBox" style="margin-top:20px" v-if="fileList.length !== 0">
          <img v-if="fileList[0]" :src="fileList[0].url" alt="">
          <div class="btnBox">
            <el-button class="largeBtn smallBtn" type="primary" @click="downLoadPic(tencentData.ce_url)">下 载</el-button>
            <el-button class="largeBtn smallBtn" type="primary" @click="printPic(tencentData.ce_url)">打 印</el-button>
          </div>
        </div>
        <div class="bookBox" style="margin-top:20px" v-if="fileList.length === 0">
          <div style="text-align:center;color:red" v-if="tencentData.ce_state===2">关闭原因：{{tencentData.remarks}}</div>
          <el-upload class="upload-demo" v-if="showFunModel('授权生成')" :file-list="fileList" :on-success="handleSuccess" :action="global.materialUpload" :headers="headers" drag multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
      </div>
    </el-dialog>
    <!-- 达到授权资格授权详情对话框 -->
    <!-- 驳回组件 -->
    <reject-dialog ref="rejectDialog" @examine="trueReject"></reject-dialog>
    <!-- 驳回组件 -->
    <!-- 遮罩处理 -->
    <div class="modal" v-if="noBookVisible||hasBookVisible"></div>
    <!-- 遮罩处理 -->
  </div>
</template>
<script>
// 接口API引入
import {
  searchCertificate,
  uploadCertificate,
  closeCertificate,
  produceCertificate,
  openCertificate,
} from "@/api/hotel.js";
// qs 引入
import qs from "querystring";
export default {
  name: "authorization", // 组件名称
  props: ["id", "hotel_name", "hotel_contract", "funArr"], //字段接收
  data() {
    return {
      // 生成授权书表单
      cooperationBookForm: {
        name: "",
        time: [],
        date: "",
      },
      // 生成授权书表单规则
      cooperationBookRule: {
        name: [
          {
            required: true,
            message: "请输入酒店名称",
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: "请设置授权期限",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择授权日期",
            trigger: "blur",
          },
        ],
      },
      // 界面显示控制字段
      noBook: false,
      noBookVisible: false,
      hasBookVisible: false,
      // 上传验证token
      headers: {
        token: "",
      },
      fileList: [],
      // 初始化携旅界面字段
      htripData: {
        ce_no: "",
        ce_state: 3,
        ce_type: 1,
        ce_type_text: "",
        ce_url: "",
        id: "",
        remarks: "",
      },
      // 初始化腾讯界面字段
      tencentData: {
        ce_no: "",
        ce_state: 3,
        ce_type: 1,
        ce_type_text: "",
        ce_url: "",
        id: "",
        remarks: "",
      },
      optType: "",
      auth: {},
    };
  },
  watch: {
    hotel_name: function (newVal) {
      this.cooperationBookForm.name = newVal;
    },
  },
  mounted() {
    // 获取token
    this.headers.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.cooperationBookForm.name = this.hotel_name;
    console.log(this.cooperationBookForm.name);
  },
  computed: {
    // 权限计算
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          //是否有权限
          return true; //  有 true
        } else {
          return false; //  无 false
        }
      };
    },
  },

  methods: {
    // 上传授权书
    upLoadBook() {
      this.$confirm("确认上传?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 生成参数json
          let json = {
            hotelContract: this.hotel_contract,
            hotel_name: this.hotel_name,
            ce_type: 2,
            ce_url: this.fileList[0].url,
            operator: this.auth.user_name,
          };
          // json转格式
          let param = qs.stringify(json);
          // 上传授权数接口
          uploadCertificate(param)
            .then((res) => {
              if (res.data.code === 100000) {
                // 接口正确
                this.$message.success("上传成功"); // 提示充公
                this.fileList = []; //  清空已上传数据
                this.seeAuthorizationBook(); // 刷新列表
              } else {
                this.$message.warning(res.data.msg); // 接口失败报错提示
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 关闭授权书按钮
    closeBook(num) {
      this.optType = num; // 保存当前操作类型
      this.$refs.rejectDialog.dialogShow(true); //打开驳回界面
    },
    // 打开授权书按钮
    openBook(num) {
      this.$confirm("确定打开?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 参数Json
          let json = {
            hotelContract: this.hotel_contract,
            hotel_name: this.hotel_name,
            ce_type: num,
            ce_no: num === "1" ? this.htripData.ce_no : this.tencentData.ce_no,
            operator: this.auth.user_name,
          };
          // json 格式转换
          let param = qs.stringify(json);
          // 关闭接口调用
          openCertificate(param)
            .then((res) => {
              if (res.data.code === 100000) {
                // 返回正确
                this.$message.success("打开成功"); // 提示
                this.fileList = []; //  清空当前缓存数据
                this.seeAuthorizationBook(); //  刷新界面
              } else {
                this.$message.warning(res.data.msg); // 接口失败报错提示
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 确认关闭授权书
    trueReject(msg, res) {
      this.$confirm("确定关闭?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 参数Json
          let json = {
            hotelContract: this.hotel_contract,
            hotel_name: this.hotel_name,
            ce_type: this.optType,
            ce_no:
              this.optType === "1"
                ? this.htripData.ce_no
                : this.tencentData.ce_no,
            remarks: res,
            operator: this.auth.user_name,
          };
          // json 格式转换
          let param = qs.stringify(json);
          // 关闭接口调用
          closeCertificate(param)
            .then((res) => {
              if (res.data.code === 100000) {
                // 返回正确
                this.$message.success("关闭成功"); // 提示
                this.fileList = []; //  清空当前缓存数据
                this.seeAuthorizationBook(); //  刷新界面
              } else {
                this.$message.warning(res.data.msg); // 接口失败报错提示
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    // 生成授权书
    creatHtrip() {
      // 二次确认
      this.$confirm("确认生成?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 表单验证
          this.$refs["cooperationBookForm"].validate((valid) => {
            if (valid) {
              // 参数json
              let json = {
                hotelContract: this.hotel_contract,
                hotel_name: this.cooperationBookForm.name,
                ce_type: 1,
                ce_start_time: this.cooperationBookForm.time[0],
                ce_end_time: this.cooperationBookForm.time[1],
                authorizatio_date: this.cooperationBookForm.date,
                operator: this.auth.user_name,
              };
              // json 格式转换
              let param = qs.stringify(json);
              // 生成授权书接口
              produceCertificate(param)
                .then((res) => {
                  if (res.data.code === 100000) {
                    // this.$message.success("生成成功");   提示信息
                    // 清空表单信息
                    this.cooperationBookForm.time = [];
                    this.cooperationBookForm.date = "";
                    //  刷新数据
                    this.seeAuthorizationBook();
                  } else {
                    this.$message.warning(res.data.msg); // 接口失败报错提示
                  }
                })
                .catch((err) => {});
            } else {
              console.log("请将数据补充完整"); // 表单验证失败报错提示
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 路径截取
    findStr(str, cha, num) {
      var x = str.indexOf(cha);
      for (var i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
        console.log(x);
      }
      return x;
    },
    // 下载授权书
    downLoadPic(url, name) {
      let image = new Image(); //生成image对象
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        //  图片加载完毕事件
        let canvas = document.createElement("canvas"); // 创建canvas
        canvas.width = image.width; // 配置canvas宽度
        canvas.height = image.height; // 配置canvas高度
        let context = canvas.getContext("2d"); // 配置canvas为  2d
        context.drawImage(image, 0, 0, image.width, image.height); //  画图
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "授权书"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      // 更改图片地址反向代理地址
      var origin = location.origin; // 获取本地地址
      var num = this.findStr(url, "/", 2); // 获取截取位置
      var str = String(url).substring(num); //截取图片路径
      var changeUrl = origin + "/pic/" + str; // 图片路径拼接
      image.src = changeUrl; // 图片地址赋值
      console.log(image.src);
    },
    // 打印图片
    printPic(url) {
      // html片段
      var str = `<div style="width:100%;height:100%" class="bookBox">
          <img style="max-width:1230px;max-height:1782px"  src="${url}" alt="">
        </div>`;
      // 打开新窗口
      var wind = window.open(
        "",
        "newwindow",
        "height=300, width=700, top=100, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
      // 新页面写入html图片片段
      wind.document.body.innerHTML = str;
      //  等待页面加载时间,执行打印操作
      setTimeout(function () {
        wind.print();
      }, 500);
      return false;
    },
    //   查看授权书
    seeAuthorizationBook() {
      // 参数json
      let json = {
        hotelContract: this.hotel_contract,
      };
      // json格式转换
      let param = qs.stringify(json);
      // 查询接口
      searchCertificate(param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.hasBookVisible = true;
            // 页面数据初始化
            this.htripData = {
              ce_no: "",
              ce_state: 3,
              ce_type: 1,
              ce_type_text: "",
              ce_url: "",
              id: "",
              remarks: "",
            };
            this.tencentData = {
              ce_no: "",
              ce_state: 3,
              ce_type: 1,
              ce_type_text: "",
              ce_url: "",
              id: "",
              remarks: "",
            };
            // 遍历新数据
            res.data.data.forEach((element) => {
              this.$nextTick(function () {
                //  加载页面后执行
                // 腾讯数据赋值
                if (element.ce_type_text === "腾讯") {
                  Object.assign(this.tencentData, element);
                  if (element.ce_state === 1) {
                    // 图片回显处理
                    const json = {
                      url: this.tencentData.ce_url,
                    };
                    this.$set(this.fileList, 0, json);
                  }
                } else if (element.ce_type_text === "携旅") {
                  // 携旅数据赋值
                  Object.assign(this.htripData, element);
                }
              });
            });
          } else if (res.data.code === 100001 && this.auth.type === "htrip") {
            //管理员 无授权资格判断
            this.hasBookVisible = true;
            // 初始化管理员未配置信息数据
            this.htripData = {
              ce_no: "",
              ce_state: 3,
              ce_type: 1,
              ce_type_text: "",
              ce_url: "",
              id: "",
              remarks: "",
            };
            this.tencentData = {
              ce_no: "",
              ce_state: 3,
              ce_type: 1,
              ce_type_text: "",
              ce_url: "",
              id: "",
              remarks: "",
            };
          } else {
            //非管理员 无授权资格判断
            this.noBookVisible = true;
          }
        })
        .catch((err) => {});
    },
    // 关闭授权书对话框
    handleClose() {
      this.noBookVisible = false;
      this.hasBookVisible = false;
    },
    // 文件上传成功
    handleSuccess(res, file, fileList) {
      const json = {
        url: res.data.url,
      };
      this.$set(this.fileList, 0, json);
    },
  },
};
</script>
<style scoped>
.msgShowBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.msgShowBoxLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.picBox {
  height: 60px;
  width: 60px;
  margin-right: 10px;
}
.picBox img {
  height: 60px;
  width: 60px;
}
.textBox p:first-child {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}
.textBox {
  max-width: 85%;
  font-size: 12px;
  color: #aaa;
}
.largeBtn {
  height: 40px;
}
.codeShowBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  padding-top: 100px;
  color: #aaa;
}
.codeShowBox img {
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.hasBookVisible ::v-deep .el-dialog__body {
  padding: 10px 20px 20px;
}
.cooperationBook,
.cooperationTVBook {
  border: 1px solid #eee;

  padding: 15px;
  margin-bottom: 10px;
}
.hasBooktitle {
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.bookBox {
  width: 420px;
  margin: 0 auto;
  text-align: center;
}
.bookBox img {
  width: 420px;
  height: 594px;
}
.btnBox {
  /* display: flex;

  justify-content: space-around;
  align-items: center; */
  text-align: center;
  margin: 10px;
}

.upload-demo {
  margin-top: 20px;
}
::v-deep .el-upload-list {
  display: none;
}
.smallBtn {
  min-width: 65px;
  width: 65px;
  height: 30px;
  padding: 0;
  text-align: center;
}
</style>