<template >

  <div style="height:100%">
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">数据中心</span>
        <span slot="third">数字资产</span>
        <span slot="fourth">资产收益统计</span>
        <span slot="fiveth">资产收益统计详情</span>
      </top-bread>
    </div>
    <!-- 酒店信息模块 -->
    <el-card class="box-card hotelContentBox">
      <el-button class="largeBtn backBtn" type="primary" @click="backLast">返回</el-button>
      <div class="hotelName">{{query.hotel}}</div>
      <div class="hotelInfo">
        <div class="hotelInfoBox" v-loading="basicLoading">
          <div class="hotelInfoRow">
            <div class="hotelInfoName">品牌：</div>
            <div class="hotelInfoValue">{{query.hotel_brand}}</div>
          </div>
          <!-- <div class="hotelInfoRow">
            <div class="hotelInfoName">商圈：</div>
            <div class="hotelInfoValue">{{businessDistrict}}</div>
          </div> -->
          <div class="hotelInfoRow">
            <div class="hotelInfoName">地址：</div>
            <div class="hotelInfoValue hotelInfoValueadd">
               <!-- {{hotelAddress}} -->
             
              <el-tooltip class="item" effect="dark" :content="hotelAddress" placement="top-start">
               <span> {{hotelAddress}}</span>
              </el-tooltip>
              <span class="showMap" @click="openMap">查看</span>
            </div>
          </div>
        </div>
        <div class="hotelInfoBox" v-loading='hotelMsgLoading'>
          <div class="hotelInfoRow">
            <div class="hotelInfoName large">销售代理商：</div>
            <div class="hotelInfoValue">{{hotelContent.sales}}</div>
          </div>
          <div class="hotelInfoRow">
            <div class="hotelInfoName large">渠道商：</div>
            <div class="hotelInfoValue">{{hotelContent.channel}}</div>
          </div>
          <div class="hotelInfoRow">
            <div class="hotelInfoName large">区域广告代理商：</div>
            <div class="hotelInfoValue">{{hotelContent.region_advertising}}</div>
          </div>
        </div>
        <div class="hotelInfoBox" v-loading='hotelMsgLoading'>
          <div class="hotelInfoRow">
            <div class="hotelInfoName large2">技术服务商：</div>
            <div class="hotelInfoValue">{{hotelContent.technical_service}}</div>
          </div>
          <div class="hotelInfoRow">
            <div class="hotelInfoName large2">城市运营商：</div>
            <div class="hotelInfoValue">{{hotelContent.city_operate}}</div>
          </div>
          <div class="hotelInfoRow">
            <div class="hotelInfoName large2">城市拓展商：</div>
            <div class="hotelInfoValue">{{hotelContent.city_expand}}</div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="mainBottom">
      <div class="mainBottomLeft">
        <img class="mainBottomLeftPic" v-if="!roomAllList||roomAllList.length==0" src="@/assets/noDataPic.png" alt="">
        <!-- 房间基本信息模块 -->
        <div class="roomBox" v-if="roomAllList&&roomAllList.length>0">

          <el-card class="box-card right">
            <div class="hotelName">{{checkRoom}} <span class="seePic" @click="openSeePic">查看截图</span></div>

            <div class="timeShow">激活日期：{{activeTag=='guest'?checkRoomObj.addTime:checkRoomObj.register_time}} <span style="margin-left:20px">{{'最近上线日期：'+onlineTime}}</span></div>

            <div class="roomCardBox">
              <div class="roomCard" v-loading="incomeLoading" v-if="activeTag=='guest'">
                <div class="top">
                  <!-- <div class="icon">
                    <img src="@/assets/inMoney.png" alt="">
                  </div> -->
                  <div class="text">
                    <div>收益金额</div>
                    <div class="timeShow">{{dataStr}}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="showNumber">{{incomeNumber?incomeNumber:0}}</div>
                  <div class="unit">元</div>
                </div>
              </div>
              <div class="roomCard" v-loading="timeLoading">
                <div class="top">
                  <!-- <div class="icon">
                    <img src="@/assets/onLineTime.png" alt="">
                  </div> -->
                  <div class="text">
                    <div>在线时长</div>
                    <div class="timeShow">{{dataStr}}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="showNumber">{{timeNumber?timeNumber:0}}</div>
                  <div class="unit">小时</div>
                </div>
              </div>
              <div class="roomCard" v-loading="sysLoading">
                <div class="top">
                  <!-- <div class="icon">
                    <img src="@/assets/openNum.png" alt="">
                  </div> -->
                  <div class="text">
                    <div>开机次数</div>
                    <div class="timeShow">{{dataStr}}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="showNumber">{{sysNumber?sysNumber:0}}</div>
                  <div class="unit">次</div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 房间基本信息模块 -->
        <!-- 数据信息模块 -->
        <div class="dataContent" v-if="roomAllList&&roomAllList.length>0">
          <div class="tableBox tableBoxOne">
            <el-card class="box-card " style="height:440px">
              <div class="title blueTitle">设备信息</div>
              <div class="timeShow">{{dataStr}}</div>
              <div class="msgBox" v-loading="equipmentLoading">
                <div class="msgRow">

                  <div class="msgName">
                    设备类型：
                  </div>
                  <div class="msgValue">
                    {{equipmentForm.factory_name}}
                  </div>

                </div>
                <div class="msgRow">
                  <div class="msgName">
                    设备型号：
                  </div>
                  <div class="msgValue">
                    {{equipmentForm.platform}}
                  </div>
                </div>
                <div class="msgRow">
                  <div class="msgName">
                    MAC地址：
                  </div>
                  <div class="msgValue">
                    {{equipmentForm.mac}}
                  </div>
                </div>
                <div class="msgRow">
                  <div class="msgName">
                    IP地址：
                  </div>
                  <div class="msgValue">
                    {{equipmentForm.clientip}}
                  </div>
                </div>
                <div class="msgRow">
                  <div class="msgName">
                    总空间：
                  </div>
                  <div class="msgValue">
                    {{(equipmentForm.totalSpace/1024/1024).toFixed(2)}} MB
                  </div>
                </div>
                <div class="msgRow">
                  <div class="msgName">
                    剩余空间：
                  </div>
                  <div class="msgValue">
                    {{(equipmentForm.remainingSpace/1024/1024).toFixed(2)}} MB
                  </div>
                </div>
                <div class="msgRow" v-if="activeTag=='guest'">
                  <div class="msgName">
                    CTLifeOS：
                  </div>
                  <div class="msgValue">
                    {{equipmentForm.ehotelVer}}
                  </div>
                </div>
                <div class="msgRow" v-if="activeTag=='public'">
                  <div class="msgName">
                    ihotel：
                  </div>
                  <div class="msgValue">
                    {{equipmentForm.ihotel_version}}
                  </div>
                </div>
              </div>
            </el-card>
          </div>
          <tableCard class="tableBox tableBoxTwo" v-if="activeTag=='guest'" ref="versionTableCard" type="版本信息" :searchForm='searchForm' :screenName='checkRoom' :searchTime="dataStr"></tableCard>
          <lineCard id="time" ref="timeLineCard" :class="['tableBox',activeTag=='guest'?'':'tableBoxTwo']" type="在线时长" @moudleShow='moudleShow' @moudleloading="roomGetStatus" :searchForm='searchForm' :screenName='checkRoom' :searchTime="linedDataStr"></lineCard>
          <lineCard id="sys" ref="sysLineCard" :class="activeTag=='guest'?'tableBox':'tableBoxBig'" type="系统交互趋势" :searchForm='searchForm' :screenName='checkRoom' :searchTime="linedDataStr"></lineCard>
          <lineCard id="ads" ref="adsLineCard" class="tableBox" v-if="activeTag=='guest'" type="收益趋势" @moudleShow='moudleShow' @moudleloading="roomGetStatus" :searchForm='searchForm' :screenName='checkRoom' :searchTime="linedDataStr"></lineCard>
          <tableCard class="tableBox" v-if="activeTag=='guest'" ref="incomeTableCard" type="收益订单" :searchForm='searchForm' :screenName='checkRoom' :searchTime="dataStr"></tableCard>
          <lineCard id="income" v-if="activeTag=='guest'" ref="incomeLineCard" class="tableBox" type="广告曝光趋势" :searchForm='searchForm' :screenName='checkRoom' :searchTime="linedDataStr"></lineCard>
          <tableCard class="tableBox" v-if="activeTag=='guest'" ref="adsTableCard" type="广告订单" :searchForm='searchForm' :screenName='checkRoom' :searchTime="dataStr"></tableCard>
        </div>
        <!-- 数据信息模块 -->
      </div>
      <div :class="['mainBottomRight',showRight?'contentRightWidth':'contentRightWidthNone']">
        <div :class="['rightShow',showRight?'':'rightShowNone']" @click="showRightState"><i :class="[showRight?'el-icon-arrow-right':'el-icon-arrow-left']"></i></div>
        <el-card class="box-card rightCard">
          <el-date-picker style="width:100%;margin-bottom:20px" :picker-options="pickerOptions" v-model="searchData" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeTime">
          </el-date-picker>
          <el-tabs v-model="activeTag" @tab-click="handleClick" class="incomeTab" style="margin:0 0 20px -20px;">
            <el-tab-pane label="客房场景" name="guest">
            </el-tab-pane>
            <el-tab-pane label="公区场景" name="public">
            </el-tab-pane>
          </el-tabs>
          <div class="searchBox " style="margin-bottom:10px">
            <el-input placeholder="请输入房间号" v-model="roomStr" :clearable="true" class="input-with-select fullSelect ">
              <el-button slot="append" icon="el-icon-search" @click="searchRoom"></el-button>
            </el-input>
            <!-- <el-button class="largeBtn backBtn" type="primary" @click="refreshList">刷新</el-button> -->
            <el-button type="primary" class="roomBtn" @click="checkOnline">在线</el-button>
            <el-button type="primary" icon="el-icon-refresh-left" class="roomBtn" @click="refreshList">状态刷新</el-button>
          </div>
          <div class="lenged">
            <span class="isOnline"></span> 在线
          </div>
          <div class="roomAllBox" v-loading="roomLoading">

            <div :class="['roomCard', 
           activeIndex == index? 'active' : '']" v-for="(item,index) in roomShowList" :key="index + '95'" @click="switchCheck(item,index)"><span v-if="item.isOnline" class="isOnline"></span>{{activeTag=='guest'?item.roomNumber:item.name}}
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 酒店信息模块 -->
    <!-- 地图显示 -->
    <el-dialog title="屏幕截图" :visible.sync="picVisible" width="40%">
      <div class="picShowBox" v-loading="picLoading">
        <div class="picBox">
          <img v-if="imgUrl" :src="imgUrl" alt="">
          <img v-if="!imgUrl" src="@/assets/shortPic.png" alt="">

        </div>
        <div class="btnRow">
          <el-button type="primary" :disabled="!checkRoomObj.isOnline" icon="el-icon-full-screen" @click="shotScreen">截屏</el-button>
          <el-button type="primary" :disabled="!checkRoomObj.isOnline" icon="el-icon-refresh-left" @click="uploadScreen">刷新</el-button>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="picVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 地图显示 -->
    <el-dialog title="地图位置" :visible.sync="mapVisible" width="30%">
      <div id="map" style="width:100%">
        <baidu-map class="baiduMap" :double-click-zoom="false" :pinch-to-zoom="false" :continuous-zoom="false" :inertial-dragging="false" :scroll-wheel-zoom="false" :keyboard="false" :center="mapPoint" :zoom="13" :dragging='false'>
          <bm-marker :position="mapPoint" animation="BMAP_ANIMATION_BOUNCE">
            <bm-label :content="query.hotel" :labelStyle="{color: '#fff', fontSize : '12px',backgroundColor:'rgba(0,0,0,.5)',border:'none',padding:'5px 10px',borderRadius:'5px'}" :offset="{width: -35, height: 30}" />
          </bm-marker>
        </baidu-map>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mapVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 地图显示 -->
  </div>
</template>
<script>
import methods from "@/api/method.js";
import tableCard from "./components/tableCard.vue";
import lineCard from "./components/lineCard.vue";
import {
  getHotelInfo,
  getHotelRoom,
  getEquipmentInfo,
  getPublicDeviceList,
  doInstruction,
  publicScreenShot,
  getPublicScreenPic,
  getRoomScreenPic,
  getStartTimes,
  getDeviceOnlineTime,
  getBusinessesagents,
} from "@/api/dataCenter.js";
export default {
  name: "DataStatisticsDetail",
  components: {
    tableCard,
    lineCard,
  },
  data() {
    return {
      picVisible: false,
      showRight: true,
      basicLoading: false,
      roomLoading: false,
      equipmentLoading: false,
      picLoading: false,
      hotelMsgLoading: false,
      businessDistrict: "",
      hotelAddress: "",
      query: {},
      mapPoint: { lng: 116.404, lat: 39.915 },
      mapVisible: false,
      centerLabel: "中国",
      hotelContent: {
        sales: "无信息",
        technical_service: "无信息",
        channel: "无信息",
        city_operate: "无信息",
        region_advertising: "无信息",
        city_expand: "无信息",
      },
      bread: {
        level: 5,
      },
      query: {},
      dataStr: "昨日",
      linedDataStr: "",
      imgUrl: "",
      searchData: "",
      activeTag: "guest",
      roomStr: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      roomMsgList: [],
      roomAllList: [],
      roomShowList: [],
      checkRoom: "",
      checkRoomObj: {
        addTime: "",
        register_time: "",
      },
      activeIndex: "",
      searchForm: {
        startDate: "",
        endDate: "",
        hotelContract: "",
        roomNo: "",
        sceneType: "",
      },
      equipmentForm: {
        clientip: "",
        dataFlag: "",
        ehotelVer: "",
        factory_name: "",
        mac: "",
        platform: "",
        remainingSpace: "",
        totalSpace: "",
        upgradeVer: "",
        ihotel_version: "",
      },
      incomeLoading: false,
      incomeNumber: "",
      sysLoading: false,
      sysNumber: "",
      timeLoading: false,
      timeNumber: "",
      onlineTime: "",
    };
  },
  mounted() {
    this.query = this.$route.query;
    this.getHotelInfo(this.query.id);
    this.getBusinessesagents(this.query.hotel_contract);
    this.getHotelRoomList();
    this.linedDataStr = this.getdefaultTime();
  },
  methods: {
    openSeePic() {
      this.picVisible = true;
      this.uploadScreen();
    },
    showRightState() {
      this.showRight = !this.showRight;
      this.$nextTick(() => {
        this.$refs.timeLineCard.watchSize();
        this.$refs.sysLineCard.watchSize();
        if (this.$refs.adsLineCard) this.$refs.adsLineCard.watchSize();
        if (this.$refs.incomeLineCard) this.$refs.incomeLineCard.watchSize();
      });
    },
    //  刷新 当前屏幕类别 列表
    refreshList() {
      if (this.activeTag == "guest") {
        this.getHotelRoomList();
      } else {
        this.getPublicDeviceList();
      }
    },
    // 截屏
    shotScreen() {
      // 客房截屏
      if (this.activeTag == "guest") {
        let json = {
          selected_hotel_name: this.query.hotel,
          selected_hotel_contract: this.query.hotel_contract,
          command_param: "",
          command: "screenshot",
          selected_room_num: this.checkRoom,
        };
        doInstruction(json).then((data) => {
          if (data.data.code == -1) {
            this.$message.error(data.data.msg);
          } else {
            this.$message.success("指令发送成功");
          }
        });
      } else {
        // 公区截屏
        let json = {
          account_id: this.searchForm.roomNo,
        };
        publicScreenShot(json).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success("指令发送成功");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
    },
    // 截屏图片获取
    uploadScreen() {
      this.picLoading = true;
      // 客房
      if (this.activeTag == "guest") {
        let json = {
          hotel_contract: this.query.hotel_contract,
          roomNo: this.checkRoom,
        };
        getRoomScreenPic(json).then((data) => {
          if (data.data.code == 100000 && data.data.data) {
            this.imgUrl = data.data.data.info;
          }
          this.picLoading = false;
        });
      } else {
        // 公区
        let json = {
          account_id: this.searchForm.roomNo,
        };
        getPublicScreenPic(json).then((data) => {
          if (data.data.code == 100000 && data.data.data) {
            this.imgUrl = data.data.data.picurl;
          }
          this.picLoading = false;
        });
      }
    },
    // 房间信息加载加载完毕
    moudleShow(t, v) {
      if (t == "收益趋势") {
        this.incomeLoading = false;
        this.incomeNumber = v;
      } else if (t == "在线时长") {
        this.timeLoading = false;
        this.timeNumber = v;
      }
    },
    // 开始加载房间信息
    roomGetStatus(t) {
      if (t == "收益趋势") {
        this.incomeLoading = true;
      } else if (t == "在线时长") {
        this.timeLoading = true;
      }
    },
    // 获取酒店商信息
    getBusinessesagents(hotel_contract) {
      this.hotelMsgLoading = true;
      let json = {
        hotelContract: hotel_contract,
      };
      getBusinessesagents(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (res.data.data) {
              Object.assign(this.hotelContent, res.data.data);
            }
          } else {
            this.$message.warning(res.data.msg);
          }
          this.hotelMsgLoading = false;
        })
        .catch((err) => {
          this.hotelMsgLoading = false;
          this.$message.warning("代理商信息获取失败");
        });
    },
    // 获取酒店基本信息详情
    getHotelInfo(id) {
      this.basicLoading = true;
      let json = {
        hotelId: id,
      };
      getHotelInfo(json).then((res) => {
        if (res.data.code == 100000) {
          this.businessDistrict = res.data.data.businessDistrict;
          this.hotelAddress = res.data.data.hotelAddress;
          this.mapPoint = {
            lng: res.data.data.lng / 100000000,
            lat: res.data.data.lat / 100000000,
          };
        } else {
          this.$message.warning(res.data.msg);
        }
        this.basicLoading = false;
      });
    },
    // 获取房间设备信息
    getEquipmentInfo(form) {
      this.equipmentLoading = true;
      getEquipmentInfo(form)
        .then((res) => {
          if (res.data.code == 100000) {
            if (res.data.data) {
              Object.assign(this.equipmentForm, res.data.data);
            } else {
              this.equipmentForm = {
                clientip: "",
                dataFlag: "",
                ehotelVer: "",
                factory_name: "",
                mac: "",
                platform: "",
                remainingSpace: "",
                totalSpace: "",
                upgradeVer: "",
                ihotel_version: "",
              };
            }
          } else {
            this.equipmentForm = {
              clientip: "",
              dataFlag: "",
              ehotelVer: "",
              factory_name: "",
              mac: "",
              platform: "",
              remainingSpace: "",
              totalSpace: "",
              upgradeVer: "",
              ihotel_version: "",
            };
            this.$message.warning(res.data.msg);
          }
          this.equipmentLoading = false;
        })
        .catch((err) => {
          this.equipmentLoading = false;
          this.$message.warning("设备信息获取失败");
        });
    },
    // 获取最近上线时间  changeRoom
    getDeviceOnlineTime(form) {
      getDeviceOnlineTime(form)
        .then((res) => {
          if (res.data.code == 100000) {
            this.onlineTime = res.data.data.substring(0, 10);
            if (this.onlineTime == "1970-01-01") this.onlineTime = "-";
          } else {
            this.$message.warning(res.data.msg);
            this.onlineTime = "获取失败";
          }
        })
        .catch((err) => {
          this.onlineTime = "获取失败";
          this.$message.warning("在线日期获取失败");
        });
    },
    // 获取开机次数
    getOpenMachineNumber(form) {
      this.sysLoading = true;
      getStartTimes(form)
        .then((res) => {
          if (res.data.code == 100000) {
            this.sysNumber = res.data.data.dataValue;
          } else {
            this.$message.warning(res.data.msg);
          }
          this.sysLoading = false;
        })
        .catch((err) => {
          this.sysLoading = false;
          this.$message.warning("开机次数获取失败");
        });
    },
    // 获取公区列表  changeRoom
    getPublicDeviceList() {
      this.roomLoading = true;
      let json = {
        page: 1,
        limit: 9999,
        hotel_contract: this.query.hotel_contract,
      };
      getPublicDeviceList(json).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data.data && res.data.data.data.length > 0) {
            this.roomMsgList = res.data.data.data;
            this.roomAllList = res.data.data.data;
            this.searchForm.hotelContract = this.query.hotel_contract;
            this.roomShowList = this.roomAllList;
            this.checkRoom = this.roomShowList[0].name;
            this.roomInfo(this.checkRoom);
            this.searchForm.roomNo = this.checkRoomObj.account_id;
            this.searchForm.sceneType = this.activeTag;
            this.activeIndex = 0;
            this.getEquipmentInfo(this.searchForm);
            this.uploadScreen();
            this.getOpenMachineNumber(this.searchForm);
            this.getDeviceOnlineTime(this.searchForm);
          } else {
            this.roomAllList = [];
            this.roomShowList = [];
            this.roomMsgList = [];
            this.checkRoom = "";
            this.activeIndex = 0;
          }
        } else {
          this.$message.warning(res.data.msg);
          this.roomAllList = [];
          this.roomShowList = [];
          this.roomMsgList = [];
          this.checkRoom = "";
          this.activeIndex = 0;
        }
        this.roomLoading = false;
      });
    },
    // 获取酒店房间列表 changeRoom
    getHotelRoomList() {
      this.roomLoading = true;
      let json = {
        page: 1,
        pageSize: 9999,
        hotelContract: this.query.hotel_contract,
      };
      getHotelRoom(json).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data.rows && res.data.data.rows.length > 0) {
            this.roomMsgList = res.data.data.rows;
            this.roomAllList = res.data.data.rows;
            this.searchForm.hotelContract = this.query.hotel_contract;
            this.roomShowList = this.roomAllList;
            this.checkRoom = this.roomShowList[0].roomNumber;
            this.searchForm.sceneType = this.activeTag;
            this.searchForm.roomNo = this.checkRoom;
            this.roomInfo(this.checkRoom);
            this.activeIndex = 0;
            this.getEquipmentInfo(this.searchForm);
            this.uploadScreen();
            this.getOpenMachineNumber(this.searchForm);
            this.getDeviceOnlineTime(this.searchForm);
          } else {
            this.roomAllList = [];
            this.roomShowList = [];
            this.roomMsgList = [];
            this.checkRoom = "";
            this.activeIndex = 0;
          }
        } else {
          this.$message.warning(res.data.msg);
          this.roomAllList = [];
          this.roomShowList = [];
          this.roomMsgList = [];
          this.checkRoom = "";
          this.activeIndex = 0;
        }
        this.roomLoading = false;
      });
    },
    // 切换屏幕tab
    handleClick() {
      this.searchForm.roomNo = "";
      this.roomAllList = [];
      this.roomShowList = [];
      this.roomMsgList = [];
      this.checkRoomObj = {
        addTime: "",
        register_time: "",
      };
      if (this.activeTag == "guest") {
        this.getHotelRoomList();
      } else {
        this.getPublicDeviceList();
      }
    },
    // 展示地图位置
    openMap() {
      this.mapVisible = true;
    },
    // 选择时间
    changeTime() {
      if (this.searchData) {
        let star = this.getSearchShowDate(this.searchData[0]);
        let end = this.getSearchShowDate(this.searchData[1]);
        this.searchForm.startDate = star;
        this.searchForm.endDate = end;
        this.dataStr = star + " ~ " + end;
        this.linedDataStr = star + " ~ " + end;
      } else {
        this.dataStr = "昨日";
        this.linedDataStr = this.getdefaultTime();
        this.searchForm.startDate = "";
        this.searchForm.endDate = "";
      }
      this.getEquipmentInfo(this.searchForm);
      this.getOpenMachineNumber(this.searchForm);
      this.getDeviceOnlineTime(this.searchForm);
    },
    // 时间格式显示处理
    getSearchShowDate(time) {
      let date = new Date(time);
      let year = date.getFullYear(); //获取完整的年份(4位,1970-????)
      let month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      month = month < 10 ? "0" + month : month;
      let day = date.getDate(); //获取当前日(1-31)
      day = day < 10 ? "0" + day : day;
      let ShowDate = year + "-" + month + "-" + day;
      return ShowDate;
    },
    // 默认显示时间处理
    getdefaultTime() {
      let nowDate = methods.getCurrentDate().yesDate;
      let lastWeekTime = new Date(nowDate).getTime() - 60 * 60 * 24 * 1000 * 6;
      let lastDate = new Date(lastWeekTime);
      let lastWeekYear = lastDate.getFullYear(); //获取完整的年份(4位,1970-????)
      let lasWeektMonth = lastDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      lasWeektMonth = lasWeektMonth < 10 ? "0" + lasWeektMonth : lasWeektMonth;
      let lasWeektDay = lastDate.getDate(); //获取当前日(1-31)
      lasWeektDay = lasWeektDay < 10 ? "0" + lasWeektDay : lasWeektDay;
      let lastShowDate = lastWeekYear + "-" + lasWeektMonth + "-" + lasWeektDay;
      return lastShowDate + " ~ " + nowDate;
    },
    // 房间数据获取 changeRoom
    roomInfo(room) {
      if (this.activeTag == "guest") {
        let arr = this.roomMsgList.filter((i) => {
          if (i.roomNumber == room) {
            return i;
          }
        });
        this.checkRoomObj = arr[0];
        this.checkRoomObj.addTime = this.checkRoomObj.addTime
          ? this.checkRoomObj.addTime.substring(0, 10)
          : "暂无数据";
      } else {
        let arr = this.roomMsgList.filter((i) => {
          if (i.name == room) {
            return i;
          }
        });
        this.checkRoomObj = arr[0];
        this.checkRoomObj.register_time = this.checkRoomObj.register_time
          ? this.checkRoomObj.register_time.substring(0, 10)
          : "暂无数据";
      }
    },
    // 房间选择 changeRoom
    switchCheck(item, index) {
      let room = this.activeTag == "guest" ? item.roomNumber : item.name;
      if (this.checkRoom == room) {
        return;
      } else {
        this.activeIndex = index;
        this.checkRoom = room;

        this.roomInfo(room);
        if (this.activeTag == "guest") {
          this.searchForm.roomNo = this.checkRoom;
        } else {
          this.searchForm.roomNo = this.checkRoomObj.account_id;
        }
        this.getEquipmentInfo(this.searchForm);
        this.getOpenMachineNumber(this.searchForm);
        this.uploadScreen();
        this.getDeviceOnlineTime(this.searchForm);
      }
    },
    // 搜索房间  changeRoom
    searchRoom() {
      this.roomShowList = [];
      console.log(this.roomStr);
      this.roomShowList = this.roomAllList.filter((i) => {
        if (this.activeTag == "guest") {
          if (i.roomNumber.indexOf(this.roomStr) != -1) {
            return i;
          }
        } else {
          if (i.name.indexOf(this.roomStr) != -1) {
            return i;
          }
        }
      });
      if (this.activeTag == "guest") {
        this.checkRoom = this.roomShowList[0].roomNumber;
      } else {
        this.checkRoom = this.roomShowList[0].name;
      }
      this.roomInfo(this.checkRoom);
      this.activeIndex = 0;
      if (this.activeTag == "guest") {
        this.searchForm.roomNo = this.checkRoom;
      } else {
        this.searchForm.roomNo = this.checkRoomObj.account_id;
      }
      this.getEquipmentInfo(this.searchForm);
      this.uploadScreen();
      this.getOpenMachineNumber(this.searchForm);
      this.getDeviceOnlineTime(this.searchForm);
    },
    // 筛选在线
    checkOnline() {
      this.roomShowList = this.roomShowList.filter((i) => {
        if (i.isOnline) {
          return i;
        }
      });
      if (this.activeTag == "guest") {
        this.checkRoom = this.roomShowList[0].roomNumber;
      } else {
        this.checkRoom = this.roomShowList[0].name;
      }
      this.roomInfo(this.checkRoom);
      this.activeIndex = 0;
      if (this.activeTag == "guest") {
        this.searchForm.roomNo = this.checkRoom;
      } else {
        this.searchForm.roomNo = this.checkRoomObj.account_id;
      }
      this.getEquipmentInfo(this.searchForm);
      this.uploadScreen();
      this.getOpenMachineNumber(this.searchForm);
      this.getDeviceOnlineTime(this.searchForm);
    },
    // 返回酒店列表页
    backLast() {
      this.$router.push("/DataIncomeStatistic");
    },
  },
};
</script>
<style scoped lang='scss'>
.baiduMap {
  height: 400px;
}
.picShowBox {
  text-align: center;
  .picBox {
    width: 100%;
    min-height: 200px;
    background: #f8f8f8;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .btnRow {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button {
      background: #fff;
      border: none;
      color: #3688f8;
    }
  }
}

.mainBottom {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 220px);
}
.rightCard ::v-deep .el-card__body {
  height: 100%;
}
.contentRightWidth {
  width: calc(30% - 10px);
  min-width: 300px;
  max-width: 450px;
  margin-left: 8px;
}
.contentRightWidthNone {
  width: 0;
  margin-left: 0px;
}
.rightShow {
  position: absolute;
  top: 12px;
  left: -5px;
  width: 7px;
  height: 45px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  border-radius: 4px 0 0 4px;
  background: rgba($color: #000000, $alpha: 0.3);
  transition: 0.3s;
  i {
    margin-left: -3px;
  }
}
.rightShowNone {
  left: -13px;
}
.rightShow:hover {
  background: rgba($color: #000000, $alpha: 0.7);
}
.mainBottomRight {
  height: 100%;
  position: relative;
  .rightCard {
    overflow: hidden;
    width: 100%;
    height: calc(100% - 12px);
    .roomAllBox {
      width: 100%;
      height: calc(100% - 230px);
      overflow: auto;
      padding-bottom: 10px;
      .roomCard {
        padding: 5px 12px;
        text-align: center;
        border-radius: 15px;
        background: #fafcff;
        border: 1px solid #ccd3dc;
        box-sizing: border-box;
        display: inline-block;
        color: #343754;
        font-size: 14px;
        margin-top: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
      .roomCard.active {
        background: #f1f8ff;
        border: 1px solid #3688f8;
        color: #3688f8;
      }
    }
  }
}
.lenged {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}
.isOnline {
  height: 8px;
  width: 8px;
  display: inline-block;
  border-radius: 4px;
  background: #43a047;
  margin-right: 5px;
}
.mainBottomLeft {
  height: 100%;
  overflow: auto;
  flex: 1;
  position: relative;
  .mainBottomLeftPic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
  }
}
.searchBox .roomBtn.el-button {
  background: #fff;
  border: none;
  color: #3688f8;
  padding: 10px 0px;
}
// 房间信息
.roomBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  .left {
    width: 435px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 285px;
  }
  .right {
    .seePic {
      color: #3688f8;
      font-size: 12px;
      cursor: pointer;
      background: #fff;
      margin-left: 20px;
    }
    flex: 1;
    height: 285px;
    .roomCardBox {
      margin-top: 15px;
      height: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .roomCard {
        flex: 1;
        margin-right: 10px;
        border: 1px solid #eff5fc;
        height: 130px;
        padding: 20px;
        box-sizing: border-box;
        .top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            width: 30px;
            height: 30px;
            img {
              width: 30px;
              height: 30px;
            }
          }
          .text {
            // margin-left: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            font-size: 12px;
            color: #00183b;
            div:last-child {
              margin-top: 5px;
            }
          }
        }
        .bottom {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          color: #00183b;
          .showNumber {
            font-size: 24px;
          }
          .unit {
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
      .roomCard:last-child {
        margin-right: 0;
      }
    }
  }
}
// 酒店信息
.hotelContentBox {
  position: relative;
  .backBtn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.hotelName {
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  margin-bottom: 0px;
}
.hotelInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  .hotelInfoBox {
    width: 33%;
    padding: 0 10px 0 0px;
    box-sizing: border-box;
    // border-right: 1px solid #ddd;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .hotelInfoRow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 30px;
      line-height: 30px;
      width: 100%;

      .hotelInfoName {
        width: 40px;
        text-align: left;
      }
      .hotelInfoName.large {
        width: 115px;
        text-align: left;
      }
      .hotelInfoName.large2 {
        width: 75px;
        text-align: left;
      }
      .hotelInfoValue {
        width: calc(100% - 70px);
        text-align: left;
        display: flex;
        align-items: center;
      }
      .hotelInfoValueadd {
        position: relative;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        .showMap {
          margin-left: 10px;
          color: #3688f8;
          font-size: 12px;
          cursor: pointer;
          background: #fff;
        }
      }
      .hotelInfoValue.large {
        width: calc(100% - 180px);
        text-align: left;
      }
    }
  }
  .hotelInfoBox:last-child {
    border-right: 0;
  }
}
// 数据信息
.dataContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .tableBox {
    width: calc(50% - 5px);
    // margin-bottom: 10px;
  }
  .tableBoxOne {
    width: 437px;
  }
  .tableBoxTwo {
    width: calc(100% - 447px);
  }
  .tableBox:nth-child(2n-1) {
    margin-right: 10px;
  }
  .tableBoxBig {
    width: 100%;
    margin-right: 0px;
  }
}

.timeShow {
  margin-top: 10px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #a3a7af;
}

.msgBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  .msgRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    line-height: 50px;
    width: 100%;
    .msgCol {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      .msgName {
        width: 70px;
        text-align: left;
      }
      .msgValue {
        width: calc(100% - 70px);
        text-align: left;
      }
    }
  }
}
.searchBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .el-input {
    margin-right: 8px;
  }
}
</style>