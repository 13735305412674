<template>
    <div>
        <fold-top-search @fold="getFoldMeth" class="absoluteFoldBox"></fold-top-search>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :allLoadingState="allLoadingState"></top-search>
        </el-card>
        <div class="lineContent" v-loading="showLoading">
            <el-card class="box-card">
                <div class=" rowBox vertical">
                    <div class="title blueTitle">上线费用预计</div>
                    <el-row :gutter="20" class="rowBox horizontal borderBox">
                        <index-four-row-num-unit :span="12" :type="type" :parShowDate="showData.root_cost" parShowUnit="money">
                            <span slot="numTitle">上线费用</span>
                            <span slot="unit">元</span>
                            <span slot="time" >{{showDataTime}}</span>
                        </index-four-row-num-unit>
                        <index-four-row-num-unit :span="12" :type="type" :parShowDate="showData.newOnlineRoomNum">
                            <span slot="numTitle">新激活终端数量</span>
                            <span slot="time">{{showDataTime}}</span>
                            <span slot="unit">台</span>
                        </index-four-row-num-unit>
                    </el-row>
                </div>
            </el-card>
        </div>
        <el-card class="box-card">
            <div class="lineContent chartBox" v-loading="lineLoading">
                <index-line-chart :chart="lineChart" v-if="lineChart.flag && !lineChart.noData" ></index-line-chart>
                <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">暂无数据</div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div class="lineContent">
                <div class="title chartTitle flexBox">
                    <div>新增激活终端酒店列表</div>
                    <div class="titleTime">{{showDataTime}}</div>
                </div>
                <!--<div class="title chartTitle">新增激活终端酒店列表</div>-->


                <table-list :table="table" @page="getNewPage" @limit="getNewLimit" v-loading="table.loading" @tableMeth="tableMeth"></table-list>
            </div>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
    </div>
</template>

<script>
    import qs from 'querystring'
    import GLOBAL from '../../../api/globalVar.js'
    import commonMeth from '../../../api/method'

    export default {
        name: "movie",
        data: () => ({
            type:'timeCenter',
            typeRight:'timeRight',
            lineChart:{
                id:'brushChart',
                showTitle:true,
                flag:false,
                noData:false,
                fromUrl:'inTabBrush',
                option:{
                    legend:[],
                    xData:[],
                    series:[
                        {
                            name: '上线费用',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[0], //改变折线点的颜色
                                    lineStyle: {
                                        color: GLOBAL.chartColor[0] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                    ],
                    noDataLoadingOption: {
                        text: '暂无数据',
                        effect: 'bubble',
                        effectOption: {
                            effect: {
                                n: 0
                            }
                        }
                    },
                }
            },
            table:{
                tableHeader:[
                    {prop:'datetime_text',label:'时间',width:''},
                    {prop:'hotel_name',label:'酒店名称',width:''},
                    {prop:'active_terminal_num',label:'激活终端数量（台）',width:''},
                    {prop:'valid_active_terminal_num',label:'有效激活终端数（台）',width:''},
                    {prop:'hotel_create_time_text',label:'上线日期',width:''},
                ],
                tableList:[
                    {id:1,name:'长沙泰牛网络科技有限公司',type:'渠道商申请',state:0,getTime:'2020-1-2',handleTile:'2020-1-2'},
                    {id:1,name:'长沙泰牛网络科技有限公司',type:'渠道商申请',state:0,getTime:'2020-1-2',handleTile:'2020-1-2'},
                    {id:1,name:'长沙泰牛网络科技有限公司',type:'渠道商申请',state:0,getTime:'2020-1-2',handleTile:'2020-1-2'},
                ],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                option:false,
                loading:true,
                name:'incomeStaBrush'
            },
            showData:{
                newOnlineHotelNum: 0,
                newOnlineRoomNum: 0,
                active_room_num: 0,
                market_cost: 0,
                new_hotel_num: 0,
                root_cost: 0,
                valid_active_terminal_num: 0,
                day_active_rate: 0,
                room_cost: 0,
            },
            condition:{
                startTime:'',
                endTime:'',
                province:'',
                city:'',
                country:'',
                hotelBrand:'',
                accountManagerId:''
            },
            topSearchShow:{ },
            token:'',
            showLoading:true,
            lineLoading:true,
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            allLoadingState:false,
        }),
        methods:{
            //监听pageSize改变
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
            },
            //监听页码值改变
            handleCurrentChange(newPage){
                this.queryInfo.currentPage=newPage;
            },
            handleClick(tab, event) {
            },
            //折线图
            getLineChart(){
                this.lineLoading=true;
                this.lineChart.flag=false;
                var param=qs.stringify({
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    hotel_use_state:this.condition.hotel_use_state,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                });
                this.$http.post(this.global.getRootIncomeLine,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        this.lineChart.flag=true;
                        if(chartData.series){
                            // this.lineChart.option.legend=chartData.legend;  //图例
                            this.lineChart.option.legend=['上线费用'];  //图例
                            this.lineChart.option.xData=chartData.xAxis;  //x轴
                            var dataTransEd=commonMeth.dataTrans(chartData);
                            this.lineChart.option.series[0].data=dataTransEd.series['上线费用'];  //x轴
                            // this.lineChart.option.series[1].data=dataTransEd.series['市场费用'];  //x轴
                            // this.lineChart.option.series[2].data=dataTransEd.series['上线酒店数'];  //x轴
                            // this.lineChart.option.series[3].data=dataTransEd.series['激活终端数'];  //x轴
                            // this.lineChart.option.series[4].data=dataTransEd.series['有效激活终端数'];  //x轴
                            // this.lineChart.option.series[5].data=dataTransEd.series['平均日活量'];  //x轴
                        }else{
                            this.lineChart.noData=true;
                        }
                    }else if(code===100001){
                        // this.errorText='验证码错误'
                    }
                    this.lineLoading=false;
                });
            },
            tableMeth(orderVal){
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }else{
                    sortStr='id';
                    orderStr='DESC';
                }
                this.table.loading=true;
                var param=qs.stringify({
                    token:this.token,
                    page:this.table.page,
                    limit:this.table.limit,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    hotel_use_state:this.condition.hotel_use_state,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    sort:sortStr,
                    order:orderStr,
                    // endTime:this.table.endTime,
                });
                this.$http.post(this.global.getRootIncomeList,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=parseInt(tableData.total);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            showDataMeth(){
                this.showLoading=true;
                var param=qs.stringify({
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    hotel_use_state:this.condition.hotel_use_state,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    countyLabel:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                });
                this.$http.post(this.global.getRootIncomeStatistics,param).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        this.showData=res.data.data;
                    }
                    this.showLoading=false;
                }).catch(()=>{
                    this.showLoading=false;
                })

            },
            getCondition(val){
                this.condition.hotel_brand=Array.isArray(val.hotel_brand)?val.hotel_brand.join(','):'';
                this.condition.hotel_use_state=Array.isArray(val.hotel_use_state)?val.hotel_use_state.join(','):'';
                this.condition.BD=Array.isArray(val.BD)?val.BD.join(','):'';
                this.condition.partner_id=Array.isArray(val.noTypePartnerCode)?val.noTypePartnerCode.join(','):'';
                if(typeof val.citycode==='string'){
                    this.condition.citycode=val.citycode;
                }else{
                    this.condition.citycode=Array.isArray(val.citycode)?val.citycode.join(','):''; //citycode
                }
                this.condition.provinceLabel=Array.isArray(val.provinceLabel)?val.provinceLabel.join(','):'';
                this.condition.cityLabel=val.cityLabel;
                this.condition.countyLabel=val.countyLabel;
                this.condition.startTime=val.startTime; //ka
                this.condition.endTime=val.endTime; //ka
                this.condition.keyword=val.keyword; //ka
                this.table.page=1;
                this.getLineChart();
                this.showDataMeth();
                this.tableMeth();
            },
            getTopSearchShow(){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen'  || auth_type==='factory'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                        hotel_use_state:true,
                    }
                } if(auth_type==='group'){
                    this.topSearchShow={
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                        hotel_use_state:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        coopType:true,
                        coopPartner:true,
                        BD:true,
                        date:true,
                        keyword:true,
                        hotel_use_state:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder='请输入酒店名称关键词';
            },
            dataTrans(chartData){
                for(let key in chartData.series){
                    for(let a in chartData.series[key]){
                        if(!chartData.series[key][a]){
                            chartData.series[key][a]=0;
                        }
                    }
                }
                return chartData;
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getFoldMeth(val){
                this.fold=val;
            }
        },
        computed:{
            showDataCom(){
                return function(s,type='',n=2){
                    if(type==='money'){
                        if(s===null){
                            return 0;
                        }else{
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    }else{
                        if(s===null){
                            return 0;
                        }else{
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            showDataTime(){
                if(this.condition.startTime===''){
                    return '【昨日】'
                }else{
                    var time='【'+this.condition.startTime+'~'+this.condition.endTime+'】';
                    return time;
                }
            },
            showRateText(){
                if(this.condition.startTime==='' ||this.condition.startTime===this.condition.endTime ){
                    return '日活率'
                }else{
                    // var time=this.condition.startTime+'~'+this.condition.endTime;
                    return '平均日活率';
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading,this.lineLoading,this.showLoading);
            }
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.showDataMeth();
            this.lineChart.option.series.forEach((item,index)=>{
                item.symbolSize=this.global.symbolSize;
            });
            this.getLineChart();
            this.tableMeth();
            this.getTopSearchShow();
        }
    }
</script>

<style scoped lang="scss">
    .chartBox{
        height:400px;
        overflow:hidden;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .foldBox{
        padding:10px 0;
        overflow:hidden;
    }
    .flexBox{
        display:flex;
    }
</style>
