import request from "@/api/request";
import qs from "querystring";

//  品牌列表
export function getList(param) {
  return request({
    url: `v2/Managebrand/getList`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  添加品牌
export function addBrand(param) {
  return request({
    url: `v2/Managebrand/addManageBrand`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  修改品牌
export function editBrand(param) {
  return request({
    url: `v2/Managebrand/editManageBrand`,
    method: "post",
    data: qs.stringify(param),
  });
}
//  删除品牌
export function delBrand(param) {
  return request({
    url: `v2/Managebrand/delManageBrand`,
    method: "post",
    data: qs.stringify(param),
  });
}
