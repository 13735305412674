<!-- 任务创建弹窗 -->
<template>
  <el-dialog title="任务创建" :visible.sync="dialogVisible" custom-class="oper-dia" width="550px" @open="open" @closed="handleClose">
    <el-form :model="form" label-width="100px" ref="form" :rules="rules">
      <el-form-item label="版本说明：" prop="desc" required>
        <el-input v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item label="apk选择：" prop="apk" required>
        <el-select v-model="form.apk" placeholder="" style="width:100%" value-key="nameApk" filterable :disabled="JSON.stringify(current) != '{}'" @change="
            currentApkName = form.apk.nameApk;
            form.hotels = [];
            $refs.apkHotel.reset();
          ">
          <el-option v-for="item in apkList" :key="item.apk_md5 + item.nameApk" :label="item.nameApk" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="酒店选择：" prop="package" v-show="form.apk != ''">
        <HotelSelect v-model="form.hotels" ref="apkHotel" type="apk" :noInsert="JSON.stringify(current) == '{}'" :signList="signList" :signId="form.apk.signed_id" :id="current.id" :apk="currentApkName" @platfrom="(val) => (form.platfrom = val)"></HotelSelect>
        <span>（已选择{{ form.hotels.length }}家）</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { taskSend,setBatchApk } from "../api/apkSend";
import HotelSelect from "../../../../components/common/operation/components/hotelSelect";
export default {
  name: "apkSetOper",
  components: { HotelSelect },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
    apkList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    signList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      currentApkName: "",
      form: {
        desc: "",
        apk: "",
        hotels: [],
        platfrom: [],
      },
      rules: {
        desc: [{ required: true, message: "版本说明不能为空" }],
        apk: [{ required: true, message: "尚未选择apk" }],
      },
    };
  },
  methods: {
    open() {
      if (JSON.stringify(this.current) != "{}") {
        let obj = this.apkList.find(
          (item) => item.nameApk == this.current.apk_name
        );
        this.$nextTick(() => {
          this.form.apk = obj;
          this.form.desc = this.current.name;
          this.form.hotels = [];
          this.currentApkName = this.current.apk_name;
          this.$forceUpdate();
        });
      }
    },
    handleClose() {
      this.$refs.apkHotel.reset();
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.hotels.length == 0) {
            this.$message.error("尚未选择酒店");
            return;
          }
          let signed_name = "";
          this.signList.forEach((i) => {
            if (i.id == this.form.apk.signed_id) {
              signed_name = i.name;
            }
          });
          let param = {
            intro: this.form.desc,
            hotel_str: JSON.stringify(this.form.hotels),
            device_name: this.form.platfrom.join(","),
            apk_name: this.form.apk.nameApk,
            apk_url: this.form.apk.apk_url,
            apk_md5: this.form.apk.apk_md5,
            signed_id: this.form.apk.signed_id,
            signed_name:signed_name,
            apk_package: this.form.apk.apkPackage,
            version_apk: this.form.apk.versionApk,
          };
          setBatchApk(param).then((data) => {
            if (data.data.code==100000) {
              this.$message.success("发布成功");
              this.dialogVisible= false
              this.$emit("loadpage")
            } else {
              this.$message.error(data.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
