import request from '@/api/request';
import { materialUpload } from "@/api/globalVar";
import qs from "querystring";
let vi = '/v2'
    //  a列表
export function getList(data) {
    return request({
        url: `${vi}/Hoteldownloadconfig/downloadConfig`,
        method: 'post',
        data,
    });
}
// 新增
export function create(data) {
    return request({
        url: `${vi}/Hoteldownloadconfig/addDownloadConfig`,
        method: 'post',
        data,
    });
}
// 修改
export function updateData(data) {
    return request({
        url: `${vi}/Hoteldownloadconfig/editDownloadConfig`,
        method: 'post',
        data,
    });
}
// 删除
export function allDelete(data) {
    return request({
        url: `${vi}/Hoteldownloadconfig/delallDownloadConfig`,
        method: 'post',
        data,
    });
}
// 获取所有设备
export function getApkPlatform(data) {
    return request({
        url: `${vi}/Hotelapks/getApkPlatform`,
        method: 'post',
        data,
    });
}
// 获取txt数据信息
export function readTxtFile(data) {
    return request({
        url: `${vi}/Hoteldownloadconfig/readTxtFile`,
        method: 'post',
        data: qs.stringify(data)
    });

}
// 编辑txt文件
export function dealTxtFile(data) {
    return request({
        url: `${vi}/Hoteldownloadconfig/dealTxtFile`,
        method: 'post',
        data,
    });
}