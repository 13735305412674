<template >
  <div>
    <el-dialog title="素材上传" :visible.sync="dialogVisible" :append-to-body='true' width="1000px" :before-close="closeUp">
      <div class="uploadBox">
        <el-upload class="upload" ref="upload" drag :action="global.materialUpload" :on-success="handleSuccess" :on-change="handleChange" :on-error="onError" :accept="sourceType=='pic'?picShow:videoShow" :limit='20' list-type="picture-card" :headers="headers" :file-list="fileList" :show-file-list="false" :auto-upload="false" :multiple='true' :on-progress="handleprogress">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__text" v-if="sourceType=='pic'">图片上传尺寸应为1920*1080，大小为2M</div>
          <div class="el-upload__text" v-if="sourceType=='video'">视频上传比例应为16:9，大小为50M</div>
          <div class="el-upload__tip" slot="tip"></div>
        </el-upload>
      </div>
      <div v-if="sourceType=='pic'">
        <div class="sourceItem" v-loading='item.loading' v-for="(item,index) in sourceList" :key="index">
          <div class="sourceInfo">
            <div class="sourceBox">
              <img :src="item.url" alt="">
            </div>
            <div class="sourceName">{{item.name}}</div>
          </div>
          <el-progress style="width:200px" v-if="item.showPrcent" show-text :percentage="item.percentage"></el-progress>
          <div>
            <el-button type="primary" size="mini" v-if="!item.is_pass" @click="toExamine(item,index)">审核</el-button>
            <!-- <el-button type="primary" size="mini" v-if="item.is_pass&&!item.showPrcent" @click="toUpload(item,index)">上传</el-button> -->
            <el-button type="danger" size="mini" v-if="!item.showPrcent" @click="toDel(item,index)">删除</el-button>
          </div>
        </div>
      </div>
      <div v-if="sourceType=='video'">
        <div class="sourceItem" v-loading='item.loading' v-for="(item,index) in sourceList" :key="index">
          <div class="sourceInfo">
            <div class="sourceBox">
              <video :src="item.url" alt=""></video>
            </div>
            <div>
              <div class="sourceName">{{item.name}}</div>
            </div>
          </div>
          
          <el-progress style="width:200px" v-if="item.showPrcent" show-text :percentage="item.percentage"></el-progress>
          <div>
            <el-button type="primary" size="mini" v-if="!item.is_pass" @click="toExamine(item,index)">审核</el-button>
            <!-- <el-button type="primary" size="mini" v-if="item.is_pass" @click="toUpload(item,index)">上传</el-button> -->
            <el-button type="danger" size="mini" v-if="!item.showPrcent" @click="toDel(item,index)">删除</el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toUpload">上传</el-button>
        <el-button @click="closeUp">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import SparkMD5 from "spark-md5";
import {
  uploadHotelPicture,
  uploadHotelVideo,
  verificationResult,
  auditing,
} from "@/api/visual.js";
import bus from "../bus";
import qs from "querystring";

export default {
  props: ["hotelContract", "sourceType"],
  data() {
    return {
      picShow: "image/jpeg,image/png",
      videoShow: "video/mp4,video/MP4",
      loading: false,
      dialogVisible: false,
      headers: {
        token: "",
        Authorization: "",
      },
      fileList: [],
      sourceList: [],
      num: 0,
    };
  },
  created() {},
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    // 关闭上传
    closeUp() {
      this.dialogVisible = false;
      this.fileList = [];
      this.sourceList = [];
      this.$refs.upload.uploadFiles = [];
      this.num = 0;
    },
    // 上传成功回调
    handleSuccess(response, file, fileList) {
      this.$emit("upsuccessmore", response.data);
    },
    onError(err, file, fileList) {},
    handleprogress(event, file, fileList) {
      this.sourceList.forEach((element, index) => {
        if (element.name == file.name) {
          this.$set(this.sourceList[index], "showPrcent", true);
        }
      });
      if (event.percent == 100) {
        this.num++;
      }
      if (this.num == this.sourceList.length) {
        setTimeout(this.closeUp, 1000);
      }
    },
    handlePreview(file) {},
    handleChange(file, fileList) {},
    toUpload(item, index) {
      // this.$set(this.sourceList[index], "loading", true);
      // var formdata = new FormData();
      // formdata.append("file", item.raw);
      // formdata.append("fileName", item.raw.name);
      // formdata.append("token", this.headers.token);
      // this.$http.post(this.global.materialUpload, formdata).then((res) => {
      //   if (res.data.code == 100000) {
      //     this.$emit("upsuccessmore", res.data.data);
      // this.sourceList.splice(index, 1);
      // } else {
      //   this.$message.warning("上传失败");
      // }
      // this.$set(this.sourceList[index], "loading", false);
      // });
      let list = this.sourceList.filter((i) => {
        if (!i.is_pass) {
          return i;
        }
      });
      if (list.length > 0) {
        this.$message.warning("存在未审核文件，请审核后进行批量操作");
        return;
      }
      this.$refs.upload.submit();
    },
    toDel(item, index) {
      this.fileList = this.fileList.filter((i) => {
        if (i.uid != item.uid) {
          return i;
        }
      });
      this.sourceList = this.sourceList.filter((i) => {
        if (i.uid != item.uid) {
          return i;
        }
      });
      this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(
        (i) => {
          if (i.uid != item.uid) {
            return i;
          }
        }
      );
    },
    toExamine(file, index, type) {
      this.$set(this.sourceList[index], "loading", true);
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var channelName = sessionStorage.getItem("curCompanyName");
      var formdata = new FormData();
      formdata.append("uploadFile", file.raw);
      formdata.append("resourcesName", file.name);
      formdata.append("userName", auth.user_name);
      formdata.append("channelName", channelName);
      formdata.append("userPhone", "-");
      formdata.append("product", 5);
      formdata.append("taskName", "素材库内容审核");
      formdata.append("resourcesForm", 1);
      if (this.sourceType == "pic") {
        formdata.append("resourcesType", 2);
      } else {
        formdata.append("resourcesType", 4);
      }
      auditing(formdata).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data[0].auditingResult == 3) {
            this.$message.success("审核成功");
            this.$set(this.sourceList[index], "is_pass", true);
            this.$set(this.sourceList[index], "loading", false);
          } else {
            this.$message.warning("审核未通过");
            this.$set(this.sourceList[index], "loading", false);
          }
        } else {
          this.$message.warning("审核失败");
          this.$set(this.sourceList[index], "loading", false);
        }
      });
    },
    // 读取文件md5
    ReadFileMD5(param) {
      return new Promise((res, ret) => {
        const file = param.raw;
        const sliceLength = 10;
        const chunkSize = Math.ceil(file.size / sliceLength);
        const fileReader = new FileReader();
        const md5 = new SparkMD5();
        let index = 0;
        const loadFile = () => {
          const slice = file.slice(index, index + chunkSize);
          fileReader.readAsBinaryString(slice);
        };
        loadFile();
        fileReader.onload = (e) => {
          md5.appendBinary(e.target.result);
          if (index < file.size) {
            index += chunkSize;
            loadFile();
          } else {
            // md5.end() 就是文件md5码
            let md5Num = md5.end();
            res(md5Num);
          }
        };
      });
    },
    // 上传文件之前校检文件格式
    async handleChange(file, fileList) {
      if (this.sourceType == "pic") {
        var type =
          file.raw.type == "image/png" ||
          file.raw.type == "image/jpg" ||
          file.raw.type == "image/jpeg";
        var isLt500K = file.raw.size / 1024 / 1024 < 2;

        if (!type) {
          this.$message.error(
            "您选择的图片" +
              file.raw.name +
              "格式不正确，图片格式为PNG,JPG格式文件"
          );
          this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(
            (i) => {
              if (i.uid != file.uid) {
                return i;
              }
            }
          );
          return;
        }
        if (!isLt500K) {
          this.$message.error(
            "您选择的图片" +
              file.raw.name +
              "大小超过限制，上传图片大小不能超过 2M!"
          );
          this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(
            (i) => {
              if (i.uid != file.uid) {
                return i;
              }
            }
          );
          return;
        }
        let md5 = await this.ReadFileMD5(file);
        if (file.status == "ready") {
          verificationResult(qs.stringify({ md5: md5 })).then((res) => {
            if (res.data.code == 200) {
              if (res.data.data == 3) {
                this.$set(file, "is_pass", true);
                file.is_pass = true;
                this.fileList.push(file);
              } else {
                this.$set(file, "is_pass", false);
                this.fileList.push(file);
              }
              // this.sourceList = this.fileList;
              this.sourceList = this.fileList;
            } else {
              this.$message.warning("获取审核信息失败");
              this.$refs.upload.uploadFiles =
                this.$refs.upload.uploadFiles.filter((i) => {
                  if (i.uid != file.uid) {
                    return i;
                  }
                });
            }
          });
        }
        return type && isLt500K;
      } else {
        const isVideo = file.raw.type === "video/mp4";
        if (!isVideo) {
          this.$message.error(
            "您选择的视频" + file.raw.name + "格式不正确，,请上传mp4格式视频!"
          );
          this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(
            (i) => {
              if (i.uid != file.uid) {
                return i;
              }
            }
          );
          return;
        }
        var isLt50m = file.raw.size / 1024 / 1024 < 50;
        if (!isLt50m) {
          this.$message.error(
            "您选择的视频" +
              file.raw.name +
              "大小超过限制，上传图片大小不能超过 50M!"
          );
          this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(
            (i) => {
              if (i.uid != file.uid) {
                return i;
              }
            }
          );
          return;
        }
        let md5 = await this.ReadFileMD5(file);
        if (file.status == "ready") {
          verificationResult(qs.stringify({ md5: md5 })).then((res) => {
            if (res.data.code == 200) {
              if (res.data.data == 3) {
                this.$set(file, "is_pass", true);
                file.is_pass = true;
                this.fileList.push(file);
              } else {
                this.$set(file, "is_pass", false);
                this.fileList.push(file);
              }
              this.sourceList = this.fileList;
            } else {
              this.$message.warning("获取审核信息失败");
              this.$refs.upload.uploadFiles =
                this.$refs.upload.uploadFiles.filter((i) => {
                  if (i.uid != file.uid) {
                    return i;
                  }
                });
            }
          });
        }
        return isVideo;
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.upload {
  display: flex;

  justify-content: center;
  align-items: center;
}
::v-deep .el-upload--picture-card {
  width: auto !important;
  height: auto !important;
}
::v-deep .el-upload-dragger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 240px;
  background: rgba(0, 0, 0, 0.05);
  .el-upload__text {
    height: 20px;
    line-height: 20px;
  }
}
.sourceItem {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  border-radius: 6px;
  border: 1px dashed rgba(0, 0, 0, 0.23);
  padding: 10px;
  box-sizing: border-box;
  .sourceInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .sourceBox {
      height: 80px;
      width: 140px;
      display: flex;

      justify-content: center;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 100%;
      }
      video {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .sourceName {
      margin-left: 10px;
      width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.tabShowBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.tabShowBox.localUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  padding-bottom: 30px;
  background: #fff;
  width: 50%;
  height: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5001;
}

.dialog-footer {
  position: relative;
  z-index: 9999;
}
.model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.23);
  z-index: 5000;
}
.outBtn {
  width: 100px;
  height: 40px;
  position: absolute;
  bottom: 18px;
  right: 20px;
}
</style>