<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span v-if='!query' slot="second">内容管理</span>
        <span v-if='!query' slot="third">存储位置管理</span>
        <span v-if='query' slot="second">存储位置管理</span>
      </top-bread>
    </div>
    <el-card class="boxCard">
      <div class="btnRow">
        <el-input style="width:300px;margin-right:12px" placeholder="关键字搜索" v-model="keyword" :clearable="true" class="input-with-select fullSelect "></el-input>
        <el-button type="primary" class="largeBtn marginLeft" @click="search">搜 索</el-button>

        <el-button type="primary" class="largeBtn" @click="addBasicBox">添 加</el-button>
      </div>
      <!-- 表格 -->
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" />
      <!-- 表格 -->
    </el-card>
    <!-- 添加表单 -->
    <el-dialog title="添加设备" :visible.sync="addVisible" width="500px" :before-close="addClose" :append-to-body="true">
      <el-form ref="addForm" :rules="rules" :model="addForm" label-width="80px">
        <el-form-item label="设备名称" prop='device'>
          <el-input v-model="addForm.device" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="path">
          <el-select v-model="addForm.path" placeholder="请选择路径" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option label="data" value="data">
            </el-option>
            <el-option label="sdcard" value="sdcard">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addTrue">确 定</el-button>
        <el-button @click="addClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加表单 -->
  </div>
</template>

<script>
import { getList, addDevice, delDevice } from "./api/save";
export default {
  name: "savePlaceList",
  data() {
    return {
      bread: {
        level: 3,
      },
      addVisible: false,
      keyword: "",
      addForm: {
        device: "",
        path: "",
      },
      query:null,
      // 表单字段验证
      rules: {
        device: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
        path: [{ required: true, message: "请输入路径", trigger: "blur" }],
      },
      // 表格数据信息
      tableData: {
        tableList: [],
        checked: false,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "ID",
            prop: "id",
            minWidth: "60",
            type: "text",
          },
          {
            label: "设备名称",
            prop: "device",
            minWidth: "80",
            type: "text",
          },
          {
            label: "路径",
            prop: "path",
            minWidth: "80",
            type: "text",
          },

          {
            label: "操作",
            minWidth: "60",
            type: "button",
            prop: "num",
            // 删除
            del: (item) => {
              // 确认
              this.$confirm("是否确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  // 字段
                  const json = {
                    id: item.id,
                  };
                  delDevice(json)
                    .then((res) => {
                      if (res.data.code == 100000) {
                        this.$message.success("删除成功");
                        this.tableData.page = 1;
                        this.getTableData(
                          this.tableData.limit,
                          this.tableData.page
                        );
                      } else {
                        this.$message.warning(res.data.msg);
                      }
                    })
                    .catch((err) => {
                      this.$message.warning("删除失败");
                    });
                })
                .catch(() => {});
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.query = sessionStorage.getItem("apaasData");
    this.getTableData();
  },
  methods: {
    search() {
      this.tableData.page = 1;
      this.getTableData();
    },
    addTrue() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认添加？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let json = {};
            Object.assign(json, this.addForm);
            addDevice(json)
              .then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("添加成功");
                  this.tableData.page = 1;
                  this.getTableData(this.tableData.limit, this.tableData.page);
                  this.addClose();
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch((err) => {
                this.$message.warning("添加失败");
              });
          });
        } else {
          this.$message.warning("请将信息补充完整");
          return false;
        }
      });
    },
    // 打开添加界面
    addBasicBox() {
      this.addVisible = true;
    },
    addClose() {
      this.addForm = {
        device: "",
        path: "",
      };
      this.$refs.addForm.resetFields();
      this.addVisible = false;
    },
    // 获取表格数据
    getTableData(s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p ? p : 1,
        size: s ? s : 10,
        device: this.keyword,
      };
      getList(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data.data;
            // 组件数据总数
            this.$refs.operationTable.changeTotal(res.data.data.total);
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData(s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData(s, p);
    },
  },
};
</script>

<style>
</style>