<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      append-to-body>
      <div class="container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="版本号" prop="version">
            <el-input v-model="ruleForm.version" placeholder="请输入版本号"></el-input>
          </el-form-item>
          <el-form-item label="apk名称" prop="apkName">
            <el-input v-model="ruleForm.apkName" placeholder="请输入apk名称"></el-input>
          </el-form-item>
          <el-form-item label="包名称" prop="package">
            <el-input v-model="ruleForm.package" placeholder="请输入包名称"></el-input>
          </el-form-item>
          <el-form-item  label="服务名称" prop="serviceName">
            <el-input v-model="ruleForm.serviceName" placeholder="请输入服务名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { create ,updateData} from "../../api/application/serviceConfig";
export default {
  data () {
    return {
      title: '',
      database: '',
      dialogVisible: false,
      ruleForm: {
        database: '',
        id: '',
        version: '', //版本号
        apkName: '',
        package: '', 
        serviceName: '', 
        
      },
      rules: {
        version: [
          { required: true, message: '请输入版本号', trigger: 'blur' },
        ],
        apkName: [
          { required: true, message: '请输入apk名称', trigger: 'blur' }
        ],
        package: [
          { required: true, message: '请输入包名称', trigger: 'blur' }
        ],
        serviceName: [
          { required: true, message: '请输入服务名称', trigger: 'blur' }
        ],
      },
      btnLoading: false
    }
  },
  methods: {
    open(title,database,row,) {
      this.title = title;
      this.database = database;
      this.dialogVisible = true;
      if (!row || row.id == '' || row.id === null) {
        // 新增
        for (const key in this.ruleForm) {
          if (Object.hasOwnProperty.call(this.ruleForm, key)) {
            this.ruleForm[key] = '';
          }
        }
      } else {
        // 编辑
        for (const key in this.ruleForm) {
          if (Object.hasOwnProperty.call(this.ruleForm, key)) {
            this.ruleForm[key] = row[key]
          }
        }
      }
      
    },
    handleClose() {
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = ''
        }
      }
      this.dialogVisible = false
    },
    async submitForm(formName) {
      this.$refs[formName].validate( async ( valid) => {
        if (valid) {
          let res;
          this.ruleForm.database = this.database;
          this.btnLoading = true;
          if (this.ruleForm.id) {
            // 编辑
            res = await updateData(this.ruleForm);
          } else {
            // 新增
            res = await create(this.ruleForm);
          }
          if (res.data.code === 100000) {
            let tips = this.ruleForm.id? '编辑' : '新增'
            this.$message({
              showClose: true,
              message: `${tips}成功`,
              type: 'success'
            });
            this.dialogVisible = false;
            this.$parent.getList(1);
            this.btnLoading = false;
          } else {
            this.$message.error(res.data.msg);
          }
          this.btnLoading = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style>

</style>