<template>
  <div style="padding: 0 15px" v-loading.full="fullLoading">
    <div class="btnRow btnRowbox">
      <el-select style="width:300px" v-model="templateId" placeholder="选择模板" class="hotelBrand fullSelect " filterable collapse-tags clearable>
        <el-option v-for="item in templateList" :key="item.id" :label="item.t_name" :value="item.id"></el-option>
      </el-select>
      <el-input style="width:300px" placeholder="版本说明" v-model="desc" :clearable="true" class="input-with-select fullSelect marginLeft"></el-input>
      <el-button type="primary" class="largeBtn marginLeft" @click="
          pagination.currentPage = 1;
          getList();
        ">搜 索</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%;" v-loading="loading" :header-cell-style="{
        background: '#f8fbff',
        color: '#606266',
      }">
      <el-table-column prop="id" label="版本号id"> </el-table-column>
      <el-table-column prop="intro" label="版本说明" show-overflow-tooltip width="250">
      </el-table-column>
      <el-table-column prop="t_name" label="模板"> </el-table-column>
      <el-table-column prop="name" label="推送酒店数/房间数">
        <template slot-scope="scope">
          <div>{{ scope.row.hotelcount }} / {{ scope.row.roomCount }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="更新酒店数/房间数">
        <template slot-scope="scope">
          <div>
            {{ scope.row.updatehotelcount }} / {{ scope.row.updateroomcount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="stime" label="时间" min-width="160"> </el-table-column>
      <el-table-column prop="uname" label="umane"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="resend(scope.row)">重发</el-button>
          <el-button type="text" size="small" @click="
              current = scope.row;
              visible = true;
            ">+酒店</el-button>
          <el-button type="text" size="small" @click="overTime(scope.row)">过期</el-button>
          <el-button type="text" size="small" @click="exportData(scope.row)">导出</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" background layout="total,prev, pager, next" :total="pagination.total" v-show="pagination.total != 0" :current-page="pagination.currentPage" @current-change="
        (val) => {
          pagination.currentPage = val;
          getList();
        }
      ">
    </el-pagination>
    <el-dialog title="版本创建" :visible.sync="visible" width="500px" @close="
        $refs.hotelSelect.reset();
        $refs.form.resetFields();
      ">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="版本说明：" prop="versionDesc">
          {{ current.intro }}
        </el-form-item>
        <el-form-item label="酒店选择：" prop="hotelStr">
          <hotelSelect v-model="form.hotelStr" ref="hotelSelect" />
          <span>（已选择{{ form.hotelStr.length }}）</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" @click="send()">发 布</el-button>
        <el-button class="cancelBtn" @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getTemplateList } from "../api/launcherTemplate";
import {
  getList,
  reSend,
  hotelSend,
  overTime,
  exportData,
} from "../api/launcherRealease";
export default {
  name: "launcherRealeaseList",
  data() {
    return {
      fullLoading: false,
      loading: false,
      tableData: [],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      desc: "",
      templateId: "",
      templateList: [],
      checkList: [],
      visible: false,
      form: {
        versionDesc: "",
        hotelStr: [],
      },
      current: {},
    };
  },
  watch: {},
  mounted() {
    this.getTemplateList();
    this.getList();
  },
  methods: {
    exportData(row) {
      this.fullLoading = true;
      exportData({
        id: row.id,
      }).then((data) => {
        this.fullLoading = false;
        // 下载
        const blob = new Blob([data.data]);
        const elink = document.createElement("a");
        elink.download = row.intro + "数据导出.xlsx";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      });
    },
    overTime(row) {
      overTime({
        id: row.id,
      }).then((data) => {
        if (data.data.code == 100000) {
          this.$message.success(data.data.msg);
          this.getList();
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
    send() {
      let params = {};
      params.id = this.current.id;
      params.selectedIDs = this.form.hotelStr
        .map((item) => {
          return item.id + "@#" + item.hotel_contract + "@#" + item.ipaddr;
        })
        .join(",");
      hotelSend(params).then((data) => {
        if (data.data.code == 100000) {
          this.$message.success(data.data.msg);
          this.visible = false;
          this.getList();
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
    resend(row) {
      console.log(row);
      reSend({
        id: row.id,
      }).then((data) => {
        if (data.data.code == 100000) {
          this.$message.success(data.data.msg);
          this.getList();
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
    getList(page) {
      this.loading = true;
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        page: page ? page : this.pagination.currentPage,
        limit: 10,
        templateId: this.templateId,
        number: this.desc,
      }).then((data) => {
        this.loading = false;
        this.tableData = data.data.data.items;
        this.pagination.total = parseInt(data.data.data.total);
      });
    },
    // 获取筛选模板
    getTemplateList() {
      getTemplateList()
        .then((res) => {
          if (res.data.code == 100000) {
            this.templateList = res.data.data;
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.warning("模板列表获取失败");
        });
    },
    // 发布确认
    releaseVisibleTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 确认
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.releaseVisible = false;
              this.$message.success("部分房间更新成功");
              // 重置分页数据
              this.tableData.page = 1;
              this.$refs.operationTable.resetTable(
                this.tableData.limit,
                this.tableData.page
              );
            })
            .catch(() => {});
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
    // 取消发布
    releaseClose() {
      this.versionForm = {
        versionDesc: "",
        hotelStr: "",
      };
      this.releaseVisible = false;
    },
    changeSize(s, p) {
      this.tableData.limit = s;
    },
    changeCurrent(s, p) {
      this.tableData.page = p;
    },
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return id;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btnRowbox {
  display: flex;
  align-items: center;
}
.marginLeft {
  margin-left: 20px;
}
.pagination {
  margin-top: 15px;
}
</style>
