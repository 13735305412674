<template>
  <div>
    <div class="form_box">
      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <el-input v-model="form.keyWord" placeholder="apk模糊搜索"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.sign" placeholder="签名模糊搜索"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="form.package" placeholder="包名模糊搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="
              pagination.currentPage = 1;
              getList();
            ">搜 索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- table -->
    <div>
      <el-button type="primary" class="btn" @click="
          current = {};
          apkDia = true;
        ">新 增</el-button>
      <el-button type="danger" @click="del" class="btn">批量删除</el-button>
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{
          background: '#f8fbff',
          color: '#606266',
        }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="versionApk" label="版本号" width="100"></el-table-column>
        <el-table-column prop="apk_url" label="apk" show-overflow-tooltip min-width="650"></el-table-column>
        <el-table-column prop="signedApk" label="签名" width="140"></el-table-column>
        <el-table-column prop="apkPackage" label="包名" show-overflow-tooltip min-width="300"></el-table-column>
        <el-table-column prop="apk_md5" label="md5" min-width="280" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="
                current = scope.row;
                apkDia = true;
              " type="text" size="small">修改</el-button>

            <el-button type="text" :disabled="scope.row.if_insert_table=='1'" @click="openApkIn(scope.row)" size="small">{{scope.row.if_insert_table==1?"已入库":"入库"}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination" background layout="total,prev, pager, next" :total="pagination.total" :current-page="pagination.currentPage" v-show="pagination.total != 0" @current-change="
          (val) => {
            pagination.currentPage = val;
            getList();
          }
        ">
      </el-pagination>
    </div>

    <el-dialog title="apk入库" :visible.sync="setVisible" width="600px" :before-close="cancel" :modal-append-to-body="false">

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">

        <el-form-item label="品牌" prop="brand">
          <el-select v-model="ruleForm.brand" placeholder="请选择" clearable multiple collapse-tags filterable>
            <el-option label="标准" value="0">
            </el-option>
            <el-option v-for="(item,index) in brandList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="DeviceName" prop="platform">
          <el-select v-model="ruleForm.platform" placeholder="请选择" clearable multiple collapse-tags filterable>
            <el-option v-for="(item,index) in equipmentList" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="apkIn">确 定</el-button>
        <el-button class="cancelBtn" @click="cancel">取 消</el-button>
      </span>
    </el-dialog>

    <ApkSetOper :visible.sync="apkDia" :current="current" :signList="signList" @refresh="
        pagination.currentPage = 1;
        getList();
      "></ApkSetOper>
  </div>
</template>
<script>
import { getList, delApk, apkIn, getBrandList } from "./api/apkSet";
import { getDeviceName } from "@/components/common/operation/components/hotelSelect/api/hotel";
import ApkSetOper from "@/components/operation/ApkManage/components/apkSetOper";
export default {
  name: "apkSet",
  components: { ApkSetOper },
  data() {
    return {
      form: {
        keyWord: "",
        sign: "",
        package: "",
      },
      ruleForm: {
        barnd: "",
        platform: "",
      },
      rules: {
        brand: [{ required: true, message: "请选择品牌信息", trigger: "blur" }],
        // platform: [
        //   { required: true, message: "请选择DeviceName", trigger: "blur" },
        // ],
      },
      signList: [],
      tableData: [],
      pagination: {
        total: 0,
        currentPage: 1,
      },
      selected: [],
      apkDia: false,
      current: {},
      setVisible: false,
      item: null,
      brandList: [],
      equipmentList: [],
    };
  },
  methods: {
    cancel() {
      this.setVisible = false;
      this.ruleForm.platform = "";
      this.ruleForm.brand = "";
    },
    openApkIn(row) {
      this.item = row;
      this.setVisible = true;
      // getDeviceName({
      //   sign_id: row.signed_id,
      // }).then((data) => {
      //   if (data.data.code == 100000) {
      //     this.equipmentList = data.data.data;
      //   } else {
      //     this.equipmentList = [];
      //   }
      // });
    },
    apkIn() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认入库", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let param = {
              val: this.item.id + ",",
              status: 1,
              signedApk: this.item.signedApk,
              apkPackage: this.item.apkPackage,
              brandId: this.ruleForm.brand.join(","),
              // platform: this.ruleForm.platform,
            };
            console.log(param);
            apkIn(param).then((data) => {
              if (data.data.code == 100000) {
                if (data.data.data.msg == 1) {
                  this.$message.success("入库成功");
                  this.getList();
                } else if (data.data.data.msg == -1) {
                  this.$message.error("入库失败，数据已存在");
                } else {
                  this.$message.error("入库失败");
                }
                this.getList();
              } else {
                this.$message.error(data.data.msg);
              }
            });
          });
        } else {
          this.$message.warning("请将信息补充完整");
        }
      });
    },
    del() {
      if (this.selected.length == 0) {
        this.$message.error("尚未选择apk配置");
        version;
        return;
      }
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delApk({
            arr:
              this.selected
                .map((item) => {
                  return item.id;
                })
                .join(",") + ",",
            status: 0,
          }).then((data) => {
            if (data.data.code == 100000) {
              this.$message.success(data.data.msg);
              this.selected = [];
              this.getList();
            } else {
              this.$message.error(data.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.selected = val;
    },
    init() {
      this.getList();
      getBrandList({
        page: 1,
        size: 9999,
      }).then((data) => {
        this.brandList = data.data.data.row;
        console.log(this.brandList);
      });
    },
    getList(page) {
      if (page) {
        this.pagination.currentPage = page;
      }
      getList({
        page: page ? page : this.pagination.currentPage,
        per_page: 10,
        url_input: this.form.keyWord,
        signed_input: this.form.sign,
        package_input: this.form.package,
      }).then((data) => {
        this.tableData = data.data.data.row || [];
        this.pagination.total = parseInt(data.data.data.count);
        this.signList = data.data.data.rowSignArray;
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
</style>
