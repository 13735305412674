<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">数据中心</span>
                <span slot="third">酒店数据统计</span>
            </top-bread>
            <fold-top-search @fold="getFoldMeth"></fold-top-search>
        </div>
        <el-card class="box-card searchCard" v-if="fold.showSearch">
            <top-search :topSearchShow="topSearchShow" @condition="getCondition"
                        :allLoadingState="allLoadingState">
            </top-search>
        </el-card>
        <screen-type-search @screenTypeSearch="screenTypeSearch"
                            :showPartProp="showPart" :allLoadingState="allLoadingState" :setSessionDataProp="false">
        </screen-type-search>
        <el-card class="box-card" v-if="showFunModel('数据统计')">
            <div class=" rowBox vertical noMarginTop">
                <div class="titleBox">
                    <div class="title blueTitle">数据概览</div>
                    <div class="stateBox minFont numTitle">（使用中）</div>
                </div>
                <el-row :gutter="20" class="rowBox vertical borderBox" v-loading="showLoading">
                    <index-four-row-num-unit :type="type" :parShowDate="showData.hotel_num">
                        <span slot="numTitle">运营酒店数量</span>
                        <span slot="time">{{showDataTime('oneDay')}}</span>
                        <span slot="unit">家</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :type="type" :parShowDate="showData.room_num">
                        <span slot="numTitle">客房数量</span>
                        <span slot="time">{{showDataTime('oneDay')}}</span>
                        <span slot="unit">间</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :type="type" :parShowDate="showData.active_terminal_num">
                        <span slot="numTitle">激活终端数量</span>
                        <span slot="time">{{showDataTime('oneDay')}}</span>
                        <span slot="unit">台</span>
                    </index-four-row-num-unit>
                    <index-four-row-num-unit :type="type" :parShowDate="showData.day_active_rate" parShowUnit="money">
                        <span slot="numTitle">{{showRateText}}</span>
                        <span slot="time">{{showDataTime('manyDay')}}</span>
                        <span slot="unit">%</span>
                    </index-four-row-num-unit>
                </el-row>
            </div>
        </el-card>
        <el-card class="box-card" v-if="showFunModel('数据走势')" v-loading="lineLoading">
            <div class="lineContent chart">
                <div class="outChartBox">
                    <index-line-chart v-if="lineChart.flag && !lineChart.noData" :chart="lineChart"></index-line-chart>
                </div>
                <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">暂无数据</div>
            </div>
        </el-card>
        <el-card class="box-card" v-if="showFunModel('列表')">
            <div class="lineContent">
                <div class="title chartTitle blueTitle">酒店列表</div>
                <table-list :table="table" @page="getNewPage" @limit="getNewLimit" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
            </div>
        </el-card>
        <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>

    </div>
</template>

<script>
    import echarts from 'echarts'
    import  qs from 'querystring'
    import GLOBAL from '../../api/globalVar.js'
    import commonMeth from '../../api/method'
    export default {
        name: "hotelSta",
        data: () => ({
            bread:{
                level:3,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/hotelSta'},
            },
            table:{
                tableHeader:[
                    {prop:'hotel_name',label:'酒店名称',width:'400px'},
                    {prop:'hotel_brand',label:'集团品牌',width:'90px'},
                    {prop:'province',label:'省份',width:'90px'},
                    {prop:'ty',label:'城市',width:'90px'},
                    {prop:'county',label:'区县',width:'90px'},
                    {prop:'room_num',label:'客房总量（间）',width:'120px'},
                    {prop:'active_terminal_num',label:'激活终端数量（台）',width:''},
                    {prop:'day_active_rate',label:'日活率（%）',width:'120px',unit:'%'},
                    {prop:'partner_code_text',label:'所属合作伙伴',width:''},
                    {prop:'allot_partner_code_text',label:'归属合作伙伴',width:''},
                ],
                tableList:[],
                page:1,
                limit:5,   //当前每页显示多少条
                total:0,
                name:'hotelSta',
                startTime:'',
                endTime:'',
                loading:true,
                headerStyle:GLOBAL.tableHeaderStyle,
                darText:'日活率（%）',
            },
            lineChart:{
                id:'lineChart',
                showTitle:true,
                flag:false,
                noData:false,
                fromUrl:'hotelSta',
                option:{
                    legend:[],
                    xData:[],
                    series:[
                        {
                            name: '运营酒店数量',
                            type: 'line',
                            smooth:true,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[0], //改变折线点的颜色
                                    lineStyle: {
                                        color:GLOBAL.chartColor[0]//改变折线颜色
                                    },
                                },
                            },
                            data: []
                        },
                        {
                            name: '日活率',
                            type: 'line',
                            smooth:true,
                            yAxisIndex:1,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[1], //改变折线点的颜色
                                    lineStyle: {
                                        color: GLOBAL.chartColor[1]//改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                        {
                            name: '激活终端数量',
                            type: 'line',
                            smooth:true,
                            yAxisIndex:2,
                            itemStyle: {
                                normal: {
                                    color: GLOBAL.chartColor[2], //改变折线点的颜色
                                    lineStyle: {
                                        color:  GLOBAL.chartColor[2] //改变折线颜色
                                    }
                                }
                            },
                            data: []
                        },
                    ]
                }
            },
            showData:{
                hotel_num:0,
                room_num:0,
                active_terminal_num:0,
                day_active_rate:0,

            },
            topAttr:{
                timeRange:''
            },
            condition:{
                startTime:'',
                endTime:'',
                province:'',
                city:'',
                country:'',
                hotelBrand:'',
                accountManagerId:''
            },
            topSearchShow:{},
            token:'',
            auth:{},
            showLoading:true,
            lineLoading:true,
            type:'timeCenter',
            fold:{
                showSearch:false,
                foldText:'展开筛选项'
            },
            belongPartnerCodeAllStr:'',
            allotPartnerCodeAllStr:'',
            funArr:[],
            allData:{},
            quickNatureCur:['self'],
            screenType:'self',
            allLoadingState:false,
            showPart:{
                self:true,
                other:true,

            },

        }),
        methods:{
            getLineChart(){
                this.lineChart.flag=false;
                this.lineLoading=true;
                var params={
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                };
                if(this.auth.type==='htrip'){
                    params.screenType=this.screenType
                }
                this.$http.post(this.global.getHotelLine,qs.stringify(params)).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var chartData=res.data.data;
                        if(chartData.series){
                            this.lineChart.option.legend=chartData.legend;  //图例
                            this.lineChart.option.xData=chartData.xAxis;  //x轴
                            var dataTransEd=commonMeth.dataTrans(chartData);
                            this.lineChart.option.series[0].data=dataTransEd.series['运营酒店数量'];  //x轴
                            this.lineChart.option.series[1].data=dataTransEd.series['日活率'];  //x轴
                            this.lineChart.option.series[2].data=dataTransEd.series['激活终端数量'];  //x轴
                        }else{
                            this.lineChart.noData=true;
                        }
                        this.lineChart.flag=true;
                        this.lineLoading=false;
                    }else if(code===100001){
                        this.$message.error('折线图获取失败');
                    }
                    this.lineLoading=false;
                });
            },
            tableMeth(orderVal){
                this.table.loading=true;
                var sortStr='';
                var orderStr='';
                if(orderVal){
                    if(orderVal.prop.indexOf('_text')!==-1){
                        var index=orderVal.prop.indexOf('_text');
                        sortStr=orderVal.prop.substring(0,index);
                    }else{
                        sortStr=orderVal.prop;
                    }
                    orderVal.order==='ascending'?orderStr='ASC':orderStr='DESC';
                }
                var params={
                    token:this.token,
                    page:this.table.page,
                    limit:this.table.limit,
                    per_page:this.table.limit,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                    sort:sortStr,
                    order:orderStr,
                };
                if(this.auth.type==='htrip'){
                    params.screenType=this.screenType
                }
                this.$http.post(this.global.getHotelList,qs.stringify(params)).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        var tableData=res.data.data;
                        this.table.tableList=tableData.items;
                        this.table.total=tableData.total;
                    }
                    this.table.loading=false;
                }).catch(()=>{
                    this.table.loading=false;
                })
            },
            showDataMeth(){
                this.showLoading=true;
                var params={
                    token:this.token,
                    hotelBrand:this.condition.hotel_brand,
                    citycode:this.condition.citycode,
                    province:this.condition.provinceLabel,
                    city:this.condition.cityLabel,
                    county:this.condition.countyLabel,
                    accountManagerId:this.condition.BD,
                    partner_id:this.condition.partner_id,
                    startTime:this.condition.startTime,
                    endTime:this.condition.endTime,
                    keyword:this.condition.keyword,
                    belongPartnerCode: this.condition.belongPartnerCode,
                    allotPartnerCode: this.condition.allotPartnerCode,
                };
                if(this.auth.type==='htrip'){
                    params.screenType=this.screenType
                }
                this.$http.post(this.global.getHotelDataStatisticsDatacenter,qs.stringify(params)).then(res=>{
                    var code=res.data.code;
                    if(code===100000){
                        this.showData=res.data.data;
                    }
                    this.showLoading=false;
                }).catch(()=>{
                    this.showLoading=false;
                })
            },
            getTopSearchShow(type){
                var auth=JSON.parse(sessionStorage.getItem('auth'));
                var auth_type=auth.type;
                if(auth_type==='channel' || auth_type==='screen' || auth_type==='factory'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                        coopType:true,
                    }
                }else if(auth_type==='group'){
                    this.topSearchShow={
                        province:true,
                        city:true,
                        country:true,
                        date:true,
                        keyword:true,
                    }
                }else if(auth_type==='htrip'){
                    this.topSearchShow={
                        hotel_brand:true,
                        province:true,
                        city:true,
                        country:true,
                        coopType:true,
                        coopPartner:true,
                        BD:true,
                        date:true,
                        keyword:true,
                        coopPartnerType:true,
                    }
                }
                this.topSearchShow.keywordPlaceholder='请输入酒店名称关键词';
            },
            getScreenTypeSearchShow(){
                if(this.auth.type==='htrip'){
                    this.showPart={
                        self:true,
                        other:true,
                        videoRoom:true,
                        virtual:true,
                    }
                }else{
                    this.showPart={
                        self:false,
                        other:false,
                    }
                }
            },
            getCondition(val){
                this.condition.hotel_brand=Array.isArray(val.hotel_brand)?val.hotel_brand.join(','):''; //ka
                this.condition.hotel_use_state=Array.isArray(val.hotel_use_state)?val.hotel_use_state.join(','):''; //ka
                this.condition.BD=Array.isArray(val.BD)?val.BD.join(','):''; //ka
                this.condition.partner_id=Array.isArray(val.partner_id)?val.partner_id.join(','):''; //ka
                this.condition.belongPartnerCode=Array.isArray(val.belongPartnerCode)?val.belongPartnerCode.join(','):''; //ka
                this.condition.allotPartnerCode=Array.isArray(val.allotPartnerCode)?val.allotPartnerCode.join(','):''; //ka
                if(typeof val.citycode==='string'){
                    this.condition.citycode=val.citycode;
                }else{
                    this.condition.citycode=Array.isArray(val.citycode)?val.citycode.join(','):''; //citycode
                }
                this.condition.provinceLabel=Array.isArray(val.provinceLabel)?val.provinceLabel.join(','):''; //provinceLabel
                this.condition.cityLabel=val.cityLabel;
                this.condition.countyLabel=val.countyLabel;
                this.condition.startTime=val.startTime;
                this.condition.endTime=val.endTime;
                this.table.darText=commonMeth.setTableDarText(this.condition.startTime,this.condition.endTime);
                this.condition.keyword=val.keyword;
                this.table.page=1;
                this.getLineChart();
                this.showDataMeth();
                this.tableMeth();
            },
            getNewPage(newPage){
                this.table.page=newPage;
                this.tableMeth();
            },
            getNewLimit(newLimit){
                this.table.limit=newLimit;
                this.tableMeth();
            },
            getFoldMeth(val){
                this.fold=val;
            },
            screenTypeSearch(screenType){
                this.screenType=screenType;
                this.tableMeth();
                this.getLineChart();
                this.showDataMeth();
            },
        },
        created(){
            // this.queryInfo.total=this.hotelList.length;
        },
        computed:{
            showDataCom(){
                return function(s,type='',n=2){
                    if(type==='money'){
                        if(s===null){
                            return 0;
                        }else{
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    }else{
                        if(s===null){
                            return 0;
                        }else{
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            showDataTime(){
                return function(val){
                    if(val==='manyDay'){   //日活率
                        if(this.condition.startTime===''){
                            return '【昨日】'
                        }else{
                            var time='【'+this.condition.startTime+'~'+this.condition.endTime+'】';
                            return time;
                        }
                    }else {   //其他数据
                        if(this.condition.startTime===''  ){
                            return '【昨日】'
                        }else if(this.condition.startTime!==this.condition.endTime){
                            var time='【'+this.condition.endTime+'】';
                            return time;
                        }else{
                            var time='【'+this.condition.startTime+'~'+this.condition.endTime+'】';
                            return time;
                        }
                    }

                }
            },
            showRateText(){
                if(this.condition.startTime==='' ||this.condition.startTime===this.condition.endTime ){
                    return '日活率'
                }else{
                    return '平均日活率';
                }
            },
            showFunModel(){
                return function(val){
                    if(this.funArr.indexOf(val)!==-1){
                        return true;
                    }else{
                        return false;
                    }
                }
            },
            twoPointCom(){
                return function(x){
                    !x?x=0:'';
                    var f = parseFloat(x);
                    if (isNaN(f)) {
                        return false;
                    }
                    var f = Math.round(x*100)/100;
                    var s = f.toString();
                    var rs = s.indexOf('.');
                    if (rs < 0) {
                        rs = s.length;
                        s += '.';
                    }
                    while (s.length <= rs + 2) {
                        s += '0';
                    }
                    return s;
                }
            },
            quickNatureCurActive(){
                return function(val){
                    var str='';
                    if(this.quickNatureCur.indexOf(val)!==-1){
                        str='active'
                    }
                    return str;
                }
            },
            allLoadingStateCom(){
                this.allLoadingState=commonMeth.getAllLoading(this.table.loading,this.lineLoading,this.showLoading);
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.auth=JSON.parse(sessionStorage.getItem('auth'));
            this.getLineChart();
            this.tableMeth();
            this.showDataMeth();
            this.getTopSearchShow();
            this.getScreenTypeSearchShow();
            commonMeth.getTreeData().then((res)=>{
                this.funArr=res.funArr;
            });
            this.lineChart.option.series.forEach((item,index)=>{
                item.symbolSize=this.global.symbolSize;
            });
        }
    }
</script>

<style scoped lang="scss">
    .hotelBrand{
        width:100%;
    }
    .rowBox{
        margin-top:20px;
        box-sizing: border-box;
    }
    .vertical{
        .grid-content{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            border:1px solid #ddd;
            .numTitle{
                margin-bottom:20px;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
    }
    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .bg-purple {
        /*background: #d3dce6;*/
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        padding:0!important;
        border-radius: 4px;
        /*min-height: 36px;*/
        /*padding:20px 20px;*/
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .lineContent{
        /*margin-top:20px;*/
        .lineTitle{
            width:100%;
            display:flex;
            align-items:center;
            padding:15px 0;
            .line{
                width:3px;
                /*height:100%;*/
                height:25px;
                background:#797979;
                margin-right:10px;
            }
        }
    }
    .chartBox{
        width:100%;
        height:400px;
        display:flex;
        flex-direction:column;
        #chart{
            flex:1;
        }
        .chartTitle{
            padding-left:20px;
        }
    }
    .outChartBox{
        height:540px;
    }
    .stateBox{
        margin-left:20px;
    }

</style>
