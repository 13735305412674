<!-- 酒店模板UI版式添加/修改弹窗 -->
<template>
  <el-dialog
    title="酒店模板"
    :visible.sync="dialogVisible"
    width="550px"
    @open="open"
    @closed="handleClose"
  >
    <el-form :model="form" ref="form" label-width="100px">
      <el-form-item label="模板名称：" prop="name">
        <el-input v-model="form.name" placeholder=""></el-input>
      </el-form-item>
      <!-- <el-form-item label="选择版式：" prop="type">
        <el-select v-model="form.type" placeholder="" style="width:100%">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { addModule, editModule } from "../api/hotelModule";
export default {
  name: "hotelModuleOper",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      form: {
        name: "",
        // type: "",
      },
    };
  },
  methods: {
    open() {
      if (JSON.stringify(this.current) != "{}") {
        this.form.name = this.current.name;
      } else {
        this.form = { name: "" };
      }
    },
    submit() {
      // 新增
      if (JSON.stringify(this.current) == "{}") {
        addModule({
          name: this.form.name,
        }).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.$emit("refresh");
            this.dialogVisible = false;
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
      // 编辑
      else {
        editModule({
          id: this.current.id,
          name: this.form.name,
        }).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.$emit("refresh");
            this.dialogVisible = false;
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
