<template>
    <div class="lineTitle">
        <!--<div class="line"></div>-->
        <div class="titleText"><slot></slot></div>
        <el-date-picker
                v-if="showDate"
                v-model="date"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateChange"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions">
        </el-date-picker>
        <!--运营酒店列表-->
    </div>
</template>

<script>
    export default {
        name: "LineTitle",
        // prop:{
        //     showDate:{ }
        // },
        props:['showDate'],

        data: () => ({
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value1: '',
            date: ''
        }),
        methods:{
            dateChange(val){
                this.$emit('lineTime',val);
            }
        },
        mounted(){

        }
    }
</script>

<style scoped lang="scss">
    .lineTitle{
        width:100%;
        display:flex;
        align-items:center;
        padding:20px 0;
        .line{
            width:3px;
            /*height:100%;*/
            height:25px;
            background:#797979;
            margin-right:10px;
        }
    }
    .titleText{
        margin-right:40px;
    }
</style>
