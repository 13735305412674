<template>
    <div>
        <div class="outTopBox">
            <top-bread>
                <span slot="first">数据中心</span>
                <span slot="second">{{bread.first}}</span>
                <span slot="second">{{bread.second}}</span>
            </top-bread>
        </div>

        <el-card class="box-card">
            <table-list :option="false"></table-list>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "DataStaDetail",
        data: () => ({
            bread:{
                first:'',
                second:""
            }
        })
    }
</script>

<style scoped lang="scss">

    .el-table{
        margin-top:15px;
    }
    .pageBox{
        margin-top:15px;
    }
    .bg-purple {
        /*background: #d3dce6;*/
        background: #fff;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        /*min-height: 36px;*/
        padding:20px 20px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
</style>
