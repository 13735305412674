
<template>
  <div>
    <!-- <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">合作伙伴</span>
                <span slot="third">合作伙伴列表</span>
                <span slot="fourth">详情</span>
            </top-bread>
        </div> -->
    <!-- <el-row class="btnRow">
                <el-button type="primary" @click="backMeth" size="medium" class="largeBtn ">返回</el-button>
            </el-row> -->

    <el-drawer title="合作伙伴详情" :visible.sync="contentShow" :size="drawerSize" :modal="false" :before-close="handleClose" :wrapperClosable="false" :destroy-on-close="true">
      <div class="changeType">
        <div v-if="item.show" :class="{ active: type == index ? true : false }" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>
      <div v-show="type===0">
        <coop-tab-basic @successreturn="successreturn"  ref="coopTabBasic" v-if="rateDataShow" :readonly="true" :data="allData.info" :allData="allData" :sysaccinfo="false"></coop-tab-basic>
      </div>
      <div  v-show="type===1">
        <coop-tab-finance ref="coopTabFinance" :id="query.id" v-if="rateDataShow" :readonly="readOnly" :bankData="allData.bank" :contractData="allData.contract" :allData="allData" :isEditCoopInfo="true"></coop-tab-finance>
      </div>
      <div  v-show="type===2">
        <coop-tab-rate ref="cooprate" v-if="rateDataShow" :id="getMsg.id"  hotelOrPartner="partner"></coop-tab-rate>
      </div>
      <div  v-show="type===3">
        <coop-tab-hotel :condition="condition"></coop-tab-hotel>
      </div>

    </el-drawer>

  </div>
</template>

<script>
  import qs from "querystring";
  import commonMeth from "../../api/method";
  export default {
    name: "basic",
    props: {
      query: {
        type: Object,
        default: {},
      },
    },
    data: () => ({
      contentShow: false,
      getMsg: {},
      bread: {
        level: 4,
        firstPath: { path: "/indexShow" },
        thirdPath: { path: "/coopList" },
      },
      tab: "basic",

      allData: {},
      rateDataShow: false,
      token: "",
      authType: "",
      funArr: [],
      allFunData: {},
      readOnly: false,
      condition: {
        hotel_brand: "",
        hotelBrand: "",
        citycode: "",
        provinceLabel: "",
        cityLabel: "",
        countyLabel: "",
        startTime: "",
        endTime: "",
        keyword: "",
      },
      drawerSize:'60%',
      type: 0,
      typeList: [
        {
          name: "基础信息",
          show:false,
        },
        {
          name: "认证信息",
          show:false,
        },
        {
          name: "计划及分成比例",
          show:false,
        },
        {
          name: "不计算收益酒店",
          show:false,
        },
      ],
    }),
    created: function () {
      this.getMsg = this.query;
    },
    watch: {
      query(newValue, oldValue) {
        this.getMsg = newValue;
      },
    },
    methods: {
      successreturn(){
        this.$emit("successreturn");
      },
      // 详情列表点击
      changeType(index) {
        this.type = index;
        if(this.type===3||this.type===2){
          this.drawerSize = "120%";
        }else{
          this.drawerSize = "75%"
        }
      },
      handleClose(done) {
        done();
        this.type = 0;
      },
      handleClick(tab, event) {},
      getDetail(id) {
        this.$http
                .get(this.global.partnerView, {
                  params: {
                    token: this.token,
                    id: id,
                    test: "test1",
                  },
                })
                .then((res) => {
                  if (res.data.code === 100000) {
                    var resData = res.data.data;
                    this.allData = resData;
                    this.$nextTick(()=>{
                      this.$refs.coopTabBasic.getDetail(this.allData);
                      this.$refs.coopTabFinance.getData(this.allData);
                      this.$refs.cooprate.getDetail(id).then(()=>{
                        return  this.$refs.cooprate.getPreDetail(id);
                      }).then(()=>{
                        this.$refs.cooprate.tabExecutingShow=true;
                      });

                    })
                    this.rateDataShow = true;
                    if (resData.review_status === "审核通过") {
                      this.readOnly = false;
                    } else {
                      this.allData.bank = {};
                      // this.allData.config={};
                      this.allData.contract = {};
                      this.readOnly = true;
                    }
                  }
                });
      },
      backMeth() {
        this.$router.push({ path: "./coopList" });
      },
    },
    mounted() {
      this.token = sessionStorage.getItem("token");
      this.authType = JSON.parse(sessionStorage.getItem("auth")).type;
      this.authType === "htrip" ? (this.tab = "basic") : (this.tab = "rate");
      // var query=this.$route.query;
      this.getMsg.id = this.query.id;
      this.getMsg.reviewStatus = this.query.reviewStatus;
      this.getDetail(this.getMsg.id);
      commonMeth.getTreeData().then((res) => {
        this.funArr = res.funArr;
        this.typeList.forEach(item=>{
          this.funArr.forEach(item1=>{
            if(item1.indexOf(item.name)!==-1){
              item.show=true;
            }
          })
        })
      });
    },
    computed: {
      showDataCom() {
        return function (val) {
          if (!val) {
            return [];
          } else {
            return parseFloat(val).toLocaleString();
          }
        };
      },
      showFunModel() {
        return function (val) {
          if (this.funArr.indexOf(val) !== -1) {
            return true;
          } else {
            return false;
          }
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .changeType {
    position: absolute;
    left: -120px;
    top: 0px;
    width: 120px;
    height: 220px;
    border: 1px solid #e2e2e2;
    z-index: 999;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
    border-radius: 4px 0px 0px 4px;
    overflow: hidden;
  }
  .changeType div {
    width: 120px;
    height: 55px;
    font-size: 14px;
    text-align: center;
    line-height: 55px;
    cursor: pointer;
  }

  .active {
    background-color: #f1f9ff;
    color: #0078ff;
  }
  ::v-deep .el-drawer__body {
    overflow: scroll;
    padding: 15px 20px 0;
  }
</style>
