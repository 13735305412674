<template>
  <div class="tableBox">
    <el-table ref="table" v-if="!table.name" id="export" class="table" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->
      <el-table-column type="selection" width="55" v-if="table.select"></el-table-column>
      <el-table-column :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip v-for="(item, index) in table.tableHeader" v-if="index === 0"></el-table-column>
      <el-table-column label="操作" v-if="table.option && table.fromUrl === 'personConfig'">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="personDetail(scope.row.id)">实施明细</el-button>
          <el-button v-if="scope.row.status !== '正常' " type="text" size="mini" @click="personConfirm('activate', '激活', scope.row.id)">激活</el-button>
          <el-button v-if="scope.row.weight !== '管理员' && table.isManager" type="text" size="mini" @click="personConfirm('transfer', '移交权限', scope.row.id)">移交权限</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="
          table.option &&
          (!table.fromUrl ||
            table.fromUrl === 'indexDoMore' ||
            table.fromUrl === 'sysConfig')
        ">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>

      <el-table-column :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip v-for="(item, index) in table.tableHeader" v-if="index !== 0"></el-table-column>
      <el-table-column label="状态" v-if="table.switch" width="70">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" :active-value="0" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" @change="switchChange(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-table ref="table" v-if="table.name === 'taskToDo' || table.name === 'taskDone'" class="table" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->
      <el-table-column :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip v-for="(item, index) in table.tableHeader" v-if="index === 0"></el-table-column>
      <el-table-column label="操作" v-if="table.option">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip v-for="(item, index) in table.tableHeader" v-if="index !== 0"></el-table-column>
    </el-table>

    <!--首页-待办table开始-->
    <el-table ref="table" v-if="table.name === 'indexDoMore'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="review_title" label="名称" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" v-if="table.option">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">通过</el-button>-->
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">驳回</el-button>-->
        </template>
      </el-table-column>
      <el-table-column prop="review_do_msg" label="任务类型" provinceWith></el-table-column>
      <el-table-column prop="status" label="任务状态" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="createtime" label="接收时间" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroToStrCom(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewtime" label="处理时间" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroToStrCom(scope.row.reviewtime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--首页-待办table结束-->

    <!--首页-全部任务table开始-->
    <el-table ref="table" v-if="table.name === 'taskAll'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="review_title" label="名称" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" v-if="table.option">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">通过</el-button>-->
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">驳回</el-button>-->
        </template>
      </el-table-column>
      <el-table-column prop="review_do_msg" label="任务类型" provinceWith></el-table-column>
      <el-table-column prop="status" label="任务状态" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createtime" label="接收时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ zeroToStrCom(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewtime" label="处理时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ zeroToStrCom(scope.row.reviewtime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--首页-全部任务table开始结束-->

    <!--酒店数据统计table开始-->
    <el-table ref="table" v-if="table.name === 'hotelSta'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->

      <el-table-column prop="hotel_name" label="酒店名称" show-overflow-tooltip width="360px" sortable="custom" fixed="left"></el-table-column>
      <el-table-column label="操作" v-if="table.option" width="70" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">通过</el-button>-->
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">驳回</el-button>-->
        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="集团品牌" provinceWith width="150" sortable="custom"></el-table-column>
      <el-table-column prop="province" label="省份" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column prop="city" label="城市" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column prop="county" label="区县" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column prop="active_room_num" label="客房总量（间）" width="200" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.active_room_num) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="active_terminal_num" label="激活终端数量（台）" width="200" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.active_terminal_num) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="day_active_rate" sortable="custom" :label="table.darText" width="300">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.day_active_rate) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="partner_code_text" label="所属合作伙伴" show-overflow-tooltip width="300" sortable="custom"></el-table-column>
      <el-table-column prop="allot_partner_code_text" label="归属合作伙伴" show-overflow-tooltip width="300" sortable="custom"></el-table-column>
    </el-table>
    <!--酒店数据统计table开始-->

    <!--收益统计-影视table开始1-->
    <el-table ref="table" v-if="table.name === 'incomeStaMovie'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55" v-if="table.select"></el-table-column>
      <el-table-column prop="watch_film_date" label="日期" min-width="200px" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column prop="hotel_name" min-width="360" label="酒店名称" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" v-if="table.option" min-width="200px">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_create_time" min-width="160" label="终端上线时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="watch_film_num" label="单点订单数量（个）" min-width="230" v-if="table.tabType=='单点'">
        <template slot-scope="scope">
          <span>{{
            scope.row.watch_film_num ? scope.row.watch_film_num : 0
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="watch_film_num" label="天卡订单数量（个）" min-width="230" v-if="table.tabType=='天卡'">
        <template slot-scope="scope">
          <span>{{
            scope.row.watch_film_num ? scope.row.watch_film_num : 0
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="income_original" label="单点订单金额（元）" min-width="230" v-if="table.tabType=='单点'">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.income_original) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="income_original" label="天卡订单金额（元）" min-width="230" v-if="table.tabType=='天卡'">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.income_original) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="watch_film_income" label="分成收益（元）" min-width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.watch_film_income }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="partner_code_text" label="所属合作伙伴" show-overflow-tooltip min-width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.partner_code_text }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="allot_partner_code_text" label="归属合作伙伴" min-width="600">
        <template slot-scope="scope">
          <span>{{ scope.row.allot_partner_code_text }}</span>
        </template>
      </el-table-column>

    </el-table>
    <!--收益统计-影视table结束-->

    <!--历史收益-影视table开始-->
    <el-table ref="table" v-if="table.name === 'historyIncome'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="partner_code_text" label="合作伙伴名称" min-width="300px" fixed v-if="getIndexHeader.partnerType==='携旅'"></el-table-column>
      <el-table-column prop="hotel_name" label="酒店名称" min-width="300px" fixed v-if="getIndexHeader.partnerType!=='携旅'"></el-table-column>
      <el-table-column label="操作" v-if="getIndexHeader.partnerType!=='携旅'" width="70" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="active_terminal_num" min-width="120px" label="激活终端数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="movie_income_total_total" min-width="150px" label="订单总金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="watch_film_num_day_card" label="天卡订单数" min-width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="movie_income_day_card" label="天卡订单金额" min-width="160px"></el-table-column>
      <el-table-column prop="order_price_day_card" label="天卡分成收益" min-width="160px"></el-table-column>
      <el-table-column label="天卡分成比例" min-width="160px">
        <template slot-scope="scope">
          <span v-if="scope.row.day_card_calculate">{{scope.row.calculate_day_card+"%"}}</span>
          <span v-else>0.00%</span>
        </template>
      </el-table-column>
      <el-table-column prop="watch_film_num_mcu" label="单点订单数" min-width="120px"></el-table-column>
      <el-table-column prop="movie_income_mcu" label="单点订单金额" min-width="160px"></el-table-column>
      <el-table-column prop="order_price_mcu" label="单点分成收益" min-width="160px"></el-table-column>
      <el-table-column label="单点分成比例" min-width="160px">
        <template slot-scope="scope">
          <span v-if="scope.row.mcu_calculate">{{scope.row.mcu_calculate+"%"}}</span>
          <span v-else>0.00%</span>
        </template>
      </el-table-column>
    </el-table>
    <!--历史收益-影视table结束-->

    <!--收益统计详情-影视table开始-->
    <el-table ref="table" v-if="table.name === 'incomeStaMovieDetail'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="orderDate" label="时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orderRoom" label="房间号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="videoName" label="影片名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orderAmount" label="影片价格（元）" show-overflow-tooltip></el-table-column>
      <el-table-column prop="calculateProportion" label="分成比例" show-overflow-tooltip></el-table-column>
      <el-table-column prop="calculateAmount" label="收益金额（元）" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--收益统计详情-影视table结束-->

    <!--独立收益统计-影视table开始-->
    <el-table ref="table" v-if="table.name === 'iddincomeStaMovie'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55" v-if="table.select"></el-table-column>
      <el-table-column prop="order_datetime" label="日期" width="200px" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column prop="order_room" width="360" label="MAC地址" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" v-if="table.option">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="watch_film_num" label="订单数量" width="230">
        <template slot-scope="scope">
          <span>{{
            scope.row.watch_film_num ? scope.row.watch_film_num : 0
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="watch_film_income" label="订单金额" width="230">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.watch_film_income) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="calculate" label="分成比例" min-width="230">
        <template slot-scope="scope">
          <span>{{ scope.row.calculate  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="income" label="分成收益" width="230">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.income) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="partner_code_text" label="所属合作伙伴" show-overflow-tooltip width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.partner_code_text }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="allot_partner_code_text" label="归属合作伙伴" width="600">
        <template slot-scope="scope">
          <span>{{ scope.row.allot_partner_code_text }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--独立收益统计-影视table结束-->

    <!--独立收益统计详情-影视table开始-->
    <el-table ref="table" v-if="table.name === 'iddincomeStaMovieDetail'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="orderDate" label="时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orderRoom" label="房间号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="videoName" label="影片名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orderAmount" label="影片价格（元）" show-overflow-tooltip></el-table-column>
      <el-table-column prop="calculateProportion" label="分成比例" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orderPhone" label="手机号" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--独立收益统计详情-影视table结束-->

    <!--收益统计-上线费用table开始-->
    <el-table ref="table" v-if="table.name === 'incomeStaBrush'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->
      <el-table-column prop="hotel" label="酒店名称" show-overflow-tooltip width="400" fixed="left"></el-table-column>
      <el-table-column label="操作" v-if="table.option" width="70" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">通过</el-button>-->
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">驳回</el-button>-->
        </template>
      </el-table-column>
      <el-table-column prop="total" label="新增激活终端数量（台）" width="" sortable="custom" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.total) }}</span>
        </template>
      </el-table-column>
      <!--<el-table-column prop="day_active_rate" label="日活率（%）" width="" >-->
      <!--<template   slot-scope="scope">-->
      <!--<span>{{twoPointCom(scope.row.day_active_rate)}}</span>-->
      <!--</template>-->
      <!--</el-table-column>-->
      <el-table-column prop="settle_type" label="上线结算类型" show-overflow-tooltip></el-table-column>
      <el-table-column prop="addtime" label="酒店上线日期" width="" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--收益统计-上线费用table结束-->

    <!--收益统计-市场费用table开始-->
    <el-table ref="table" v-if="table.name === 'incomeStaMarket'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->
      <el-table-column prop="hotel_name" label="酒店名称" show-overflow-tooltip width="400" fixed="left"></el-table-column>
      <el-table-column label="操作" v-if="table.option" width="100" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showRoomDetail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="品牌" width="" sortable="custom" show-overflow-tooltip></el-table-column>
      <el-table-column prop="province" label="省份" show-overflow-tooltip></el-table-column>
      <el-table-column prop="city" label="城市" width="" show-overflow-tooltip></el-table-column>
      <el-table-column prop="county" label="区县" width="" show-overflow-tooltip></el-table-column>
      <el-table-column prop="valid_room_num" label="有效激活终端数" width="" show-overflow-tooltip></el-table-column>
      <el-table-column prop="hotel_create_time" label="酒店上线时间" width="" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--收益统计-市场费用table结束-->

    <!--收益统计-运营酒店列表开始-->
    <el-table ref="table" class="sixtable" v-if="table.name === 'hotelManAllUsing'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.limit>=20 &&table.total>10?'550px':'330px'" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55" v-if="table.select" sortable="custom" fixed="left"></el-table-column>
      <el-table-column prop="hotel" label="酒店名称" width="360" show-overflow-tooltip sortable="custom" fixed="left">
        <!-- <template slot-scope="{ row, $index }">
          <div @click.stop="changeNum($index, 'editRemarkInput', row.hotel)">
            <el-input ref="editRemarkInput" v-if="editable[$index]" v-model="row.hotel" size="mini" :onfocus="true" class="editRemarkInput" @blur="blurMeth($event, $index, row.id, row.hotel)"></el-input>
            <span v-else>{{ row.hotel }}</span>
          </div>
        </template> -->
      </el-table-column>
      <el-table-column label="操作" v-if="table.option" width="100" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="集团" width="150" sortable="custom">
      </el-table-column>
      <el-table-column prop="hotel_province" label="省份" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column prop="hotel_city" label="城市" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column prop="hotel_county" label="区县" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column label="验收类型" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.check_state_text" style="display:block;width:60px;border-radius:3px;background:#ecf2fe;height:20px;font-size:12px;text-align:center;line-height:20px;color:#7caaf9;">{{scope.row.check_state_text}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_room" label="客房总量（间）" width="200" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.hotel_room) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="n_rooms_connected" label="激活终端数量（台）" width="230" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.n_rooms_connected) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dar" :label="table.darText" :width="auth.type !== 'group' ? '300' : '300'">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.dar) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channel_code_text" label="所属合作伙伴" show-overflow-tooltip width="300" sortable="custom" v-if="auth.type !== 'group'">
        <template slot-scope="scope">
          <span>{{ scope.row.channel_code_text }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="allot_channel_code_text" label="归属合作伙伴" show-overflow-tooltip width="300" sortable="custom" v-if="auth.type !== 'group'">
        <template slot-scope="scope">
          <span>{{ scope.row.allot_channel_code_text }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--收益统计-运营酒店列表开始-->

    <!--收益统计-所有酒店列表开始-->
    <el-table ref="table" v-if="table.name === 'hotelManAssignTest'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->
      <el-table-column type="selection" width="55" v-if="table.select" sortable="custom"></el-table-column>
      <el-table-column prop="hotel" label="酒店名称" width="360" sofrtable="custom" show-overflow-tooltip fixed="left">
        <!-- <template slot-scope="{ row, $index }">
          <div @click.stop="changeNum($index, 'editRemarkInput', row.hotel)">
            <el-input ref="editRemarkInput" v-if="editable[$index]" v-model="row.hotel" size="mini" :onfocus="true" class="editRemarkInput" @blur="blurMeth($event, $index, row.id, row.hotel)"></el-input>
            <span v-else>{{ row.hotel }}</span>
          </div>
        </template> -->
      </el-table-column>
      <el-table-column label="操作" v-if="table.option" fixed="left" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="集团品牌" show-overflow-tooltip title="集团品牌" width="150" sortable="custom"></el-table-column>

      <el-table-column prop="hotel_use_state" label="客户终端状态" width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="hotel_province" label="省份" show-overflow-tooltip width="150" sortable="custom">
      </el-table-column>
      <el-table-column prop="hotel_city" label="城市" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column prop="hotel_county" label="区县" show-overflow-tooltip width="150" sortable="custom"></el-table-column>
      <el-table-column label="验收类型" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.check_state_text" style="display:block;width:60px;border-radius:3px;background:#ecf2fe;height:20px;font-size:12px;text-align:center;line-height:20px;color:#7caaf9;">{{scope.row.check_state_text}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_room" label="客房总量（间）" width="200" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.hotel_room) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="n_rooms_connected" label="激活终端数量（台）" width="230" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.n_rooms_connected) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="addtime" label="建站时间" width="200">
      </el-table-column>
      <el-table-column prop="account_manager" label="客户经理" width="200" sortable="custom">
      </el-table-column>
      <el-table-column prop="tech_service_personnel" label="技术服务" width="200" sortable="custom">
      </el-table-column>
      <el-table-column prop="cus_service_personnel" label="客户服务" width="200" sortable="custom">
      </el-table-column>
    </el-table>
    <!--收益统计-所有酒店列表结束-->

    <!--除了使用中，酒店模块用的都是这个表格-->
    <el-table ref="table" v-if="table.name === 'hotelManAl'" :row-key="getRowKey" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" style="width: 100%" :indent='8' @sort-change="sortChange" :height="table.limit>=20 &&table.total>10?'550px':'330px'" class="sixtable" lazy :load="loadChild" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column type="selection" min-width="55" :selectable="selecTableTrue" v-if="table.select" sortable="custom"></el-table-column>
      <el-table-column prop="hotel" label="酒店名称" min-width="400" show-overflow-tooltip sortable="custom" fixed="left">
        <!-- <template slot-scope="{ row, $index }"> -->
        <!-- <div @click.stop="changeNum($index, 'editRemarkInput', row.hotel)">
            <el-input ref="editRemarkInput" v-if="editable[$index]" v-model="row.hotel" size="mini" :onfocus="true" class="editRemarkInput" @blur="blurMeth($event, $index, row.id, row.hotel)"></el-input>
            <span v-else>{{ row.hotel }}</span>
          </div> -->
        <!-- </template> -->
        <!-- <template slot-scope="scoped">
          <span>{{scoped.row.hotel}}</span><i style="margin-left:10px;display:inline-block" class="el-icon-info"></i>
        </template> -->
      </el-table-column>
      <!-- <el-table-column prop="hotel_contract" label="酒店合同号" min-width="300" show-overflow-tooltip sortable="custom" fixed="left">
      </el-table-column>
        <el-table-column prop="hotelName" label="终端密码" width="100" show-overflow-tooltip sortable="custom" fixed="left"> </el-table-column> -->
      <el-table-column prop="hotelName" label="程序名称" width="100" show-overflow-tooltip fixed="left"> </el-table-column>
      <el-table-column label="操作" v-if="table.option" min-width="360" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showInfo(scope.row)">信息</el-button>

          <el-button type="text" size="mini" @click="detail(scope.row,'info')">详情</el-button>
          <el-button type="text" size="mini" v-if="table.specialCell.instructionBtn&& path== '/onePage/HotelManAll'" @click="detail(scope.row,'dr')">远程维护</el-button>
          <el-button type="text" size="mini" v-if="table.specialCell.systemBtn&& path== '/onePage/HotelManAll'" @click="detail(scope.row,'sys')">系统管理</el-button>
          <el-button type="text" size="mini" v-if="table.specialCell.patternBtn&&scope.row.children" @click="changePattern(scope.row)">模式修改</el-button>
          <el-button type="text" size="mini" v-if="table.specialCell.addBackstage&&scope.row.children" @click="detail(scope.row,'add')">新建分组</el-button>
          <el-button type="text" size="mini" v-if="table.specialCell.editName&&!scope.row.children" @click="detail(scope.row,'edit')">修改名称</el-button>
          <el-button type="text" size="mini" @click="skipToManage(scope.row)" v-if=" scope.row.isSkip == 1 && scope.row.hotel_use_state_text !== '虚拟运营'&&showFunModel('跳转')">跳转</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="集团品牌" min-width="150" show-overflow-tooltip sortable="custom"></el-table-column>

      <el-table-column prop="hotel_province" label="省份" min-width="150" show-overflow-tooltip sortable="custom">
      </el-table-column>
      <el-table-column prop="hotel_city" label="城市" min-width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="hotel_county" label="区县" min-width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="hotel_use_state_text" label="酒店状态" min-width="150" v-if="use_state"></el-table-column>
      <!-- <el-table-column label="验收类型" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.supplier_id}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="验收类型" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.check_state_text" style="display:block;width:60px;border-radius:3px;background:#ecf2fe;height:20px;font-size:12px;text-align:center;line-height:20px;color:#7caaf9;">{{scope.row.check_state_text}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_room" label="客房总量（间）" min-width="200" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.hotel_room) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="n_rooms_connected" label="激活终端数量（台）" min-width="230" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.n_rooms_connected) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="account_manager" label="客户经理" min-width="150" sortable="custom" v-if="table.specialCell.BD">
      </el-table-column>
      <el-table-column prop="build_person" label="建站人" min-width="150" sortable="custom" v-if="table.specialCell.build_person">
      </el-table-column>
      <el-table-column prop="addtime" label="建站时间" :min-width="
          !table.specialCell.dar &&
          !(table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group')
            ? ''
            : '200'
        ">
      </el-table-column>
      <el-table-column prop="dar" :label="table.specialCell.darText" :min-width="auth.type !== 'group' ? '300' : ''" v-if="table.specialCell.dar">
      </el-table-column>
      <el-table-column prop="channel_code_text" label="所属合作伙伴" min-width="300" v-if="table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group'"></el-table-column>
      <el-table-column prop="allot_channel_code_text" label="归属合作伙伴" min-width="300" v-if="table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group'">
      </el-table-column>
    </el-table>
    <!-- onePAGE-   -------------- -->
    <el-table ref="table" v-if="table.name === 'onePageHotelManAll'" :row-key="getRowKey" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" style="width: 100%" :indent='8' @sort-change="sortChange" :height="table.limit>=20 &&table.total>10?'550px':'330px'" class="sixtable" lazy :load="loadChild" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column type="selection" min-width="55" :selectable="selecTableTrue" v-if="table.select" sortable="custom"></el-table-column>
      <el-table-column prop="hotel" label="酒店名称" min-width="250" sortable="custom" fixed="left">

      </el-table-column>

      <el-table-column prop="hotelName" label="程序名称" width="100" show-overflow-tooltip fixed="left"> </el-table-column>
      <el-table-column label="操作" v-if="table.option" min-width="200" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showInfo(scope.row)">信息</el-button>

          <el-button type="text" size="mini" @click="detail(scope.row,'info')">设备列表</el-button>
          <el-button type="text" size="mini" @click="detail(scope.row,'dr')">终端运维</el-button>

        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="集团品牌" min-width="150" show-overflow-tooltip sortable="custom"></el-table-column>

      <el-table-column prop="hotel_province" label="省份" min-width="150" show-overflow-tooltip sortable="custom">
      </el-table-column>
      <el-table-column prop="hotel_city" label="城市" min-width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="hotel_county" label="区县" min-width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="hotel_use_state_text" label="酒店状态" min-width="150" v-if="use_state"></el-table-column>

      <el-table-column prop="hotel_room" label="客房总量/间 " min-width="180" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.hotel_room) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="n_rooms_connected" label="激活终端/台" min-width="190" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.n_rooms_connected) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dar" :label="table.specialCell.darText" :min-width="auth.type !== 'group' ? '300' : ''" v-if="table.specialCell.dar">
      </el-table-column>
      <el-table-column prop="account_manager" label="客户经理" min-width="150" sortable="custom" v-if="table.specialCell.BD">
      </el-table-column>
      <el-table-column prop="build_person" label="建站人" min-width="150" sortable="custom" v-if="table.specialCell.build_person">
      </el-table-column>
      <el-table-column prop="addtime" label="建站时间" :min-width="
          !table.specialCell.dar &&
          !(table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group')
            ? ''
            : '200'
        ">
      </el-table-column>

      <el-table-column prop="channel_code_text" label="所属合作伙伴" min-width="300" v-if="table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group'"></el-table-column>
      <el-table-column prop="allot_channel_code_text" label="归属合作伙伴" min-width="300" v-if="table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group'">
      </el-table-column>
    </el-table>
    <!-- //---------------- -->
    <el-table class="sixtable" ref="table" v-if="table.name === 'hotelManAll'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" style="width: 100%" @sort-change="sortChange" :height="table.limit>=20 &&table.total>10?'550px':'330px'">
      <el-table-column type="selection" width="55" v-if="table.select" sortable="custom"></el-table-column>
      <el-table-column prop="hotel" label="酒店名称" width="340" show-overflow-tooltip sortable="custom" fixed="left">

        <!-- <template slot-scope="{ row, $index }">
          <div @click.stop="changeNum($index, 'editRemarkInput', row.hotel)">
            <el-input ref="editRemarkInput" v-if="editable[$index]" v-model="row.hotel" size="mini" :onfocus="true" class="editRemarkInput" @blur="blurMeth($event, $index, row.id, row.hotel)"></el-input>
            <span v-else>{{ row.hotel }}</span>
          </div>
        </template> -->
      </el-table-column>

      <el-table-column label="操作" v-if="table.option" width="110" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          <el-button type="text" size="mini" @click="skipToManage(scope.row)" v-if=" scope.row.isSkip == 1 && scope.row.hotel_use_state_text !== '虚拟运营'&&showFunModel('跳转')">跳转</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_brand" label="集团品牌" width="150" show-overflow-tooltip sortable="custom"></el-table-column>

      <el-table-column prop="hotel_province" label="省份" width="150" show-overflow-tooltip sortable="custom">
      </el-table-column>
      <el-table-column prop="hotel_city" label="城市" width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="hotel_county" label="区县" width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column label="验收类型" width="150" v-if="table.specialCell.typeshow">
        <template slot-scope="scope">
          <span v-if="scope.row.check_state_text" style="display:block;width:60px;border-radius:3px;background:#ecf2fe;height:20px;font-size:12px;text-align:center;line-height:20px;color:#7caaf9;">{{scope.row.check_state_text}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hotel_room" label="客房总量（间）" width="200" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.hotel_room) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="n_rooms_connected" label="激活终端数量（台）" width="230" sortable="custom">
        <template slot-scope="scope">
          <span>{{ zeroCom(scope.row.n_rooms_connected) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="account_manager" label="客户经理" width="150" sortable="custom" v-if="table.specialCell.BD">
      </el-table-column>

      <el-table-column prop="build_person" label="建站人" width="150" sortable="custom" v-if="table.specialCell.build_person">
      </el-table-column>
      <el-table-column prop="addtime" label="建站时间" :width="
          !table.specialCell.dar &&
          !(table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group')
            ? ''
            : '200'
        ">
      </el-table-column>
      <el-table-column prop="dar" :label="table.specialCell.darText" :width="auth.type !== 'group' ? '300' : ''" v-if="table.specialCell.dar">
      </el-table-column>
      <el-table-column prop="channel_code_text" label="所属合作伙伴" width="300" v-if="table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group'"></el-table-column>
      <el-table-column prop="allot_channel_code_text" label="归属合作伙伴" width="300" v-if="table.fromUrl !== 'noCalcIncomeHotelAll' && auth.type !== 'group'">
      </el-table-column>
    </el-table>
    <!--收益统计-未分配酒店列表结束-->
    <!--合作伙伴列表开始-->
    <el-table ref="table" v-if="table.name === 'coopList'" :height="table.limit>=20 &&table.total>10?'550px':'330px'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="合作伙伴名称" show-overflow-tooltip width="300" fixed="left"></el-table-column>
      <el-table-column label="操作" v-if="table.option" width="70" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="partner_type_text" label="合作类型" show-overflow-tooltip width="120"></el-table-column>
      <el-table-column prop="status_text" label="资质审核状态" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="apply_date" label="注册时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="check_time" label="审批时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="code" label="合作伙伴终端编码" width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="contact_name" label="联系人" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="contact_phone" label="手机号码" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <!--合作伙伴列表结束-->

    <!--待配置酒店列表开始-->
    <el-table ref="table" v-if="table.name === 'toBeConfiguredHotel'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="hotel_name" label="酒店名称" width="300"></el-table-column>
      <el-table-column label="操作" v-if="table.option">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="channel_code_text" label="公司名称" width="300"></el-table-column>
      <el-table-column prop="contract_365" label="终端门店编码" show-overflow-tooltip width="130">
      </el-table-column>
      <el-table-column prop="hotel_brand" label="品牌"></el-table-column>
      <el-table-column prop="province" label="省份"></el-table-column>
      <el-table-column prop="city" label="城市"> </el-table-column>
      <el-table-column prop="county" label="区县"> </el-table-column>
      <el-table-column prop="room_num" label="客房数"> </el-table-column>
      <el-table-column prop="active_terminal_num" label="激活终端数">
      </el-table-column>
    </el-table>
    <!--待配置酒店列表结束-->

    <!--收益统计-广告位管理列表开始-->
    <el-table ref="table" v-if="table.name === 'adManSpace'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!--<el-table-column type="selection" width="55" v-if="table.fromUrl==='adManCus'"></el-table-column>-->
      <el-table-column type="selection" width="55" v-if="table.select"></el-table-column>
      <el-table-column prop="self_periodical_name" label="刊位名称" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column label="操作" v-if="table.option" fixed="left">
        <template slot-scope="scope">

          <el-button type="text" size="mini" @click="detail(scope.row)">编辑</el-button>
          <el-switch class="tableScopeSwitch" v-model="scope.row.label_state" active-color="red" inactive-color="#DCDCDC" @change="switchChangeAdvertisement(scope.row)">
          </el-switch>
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">通过</el-button>-->
          <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="detail(scope.row)" v-if="table.isPass">驳回</el-button>-->
        </template>
      </el-table-column>
      <el-table-column prop="standard_name" label="携旅标准刊位名称"></el-table-column>
      <el-table-column prop="device_name" label="投放设备名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="company_name" label="所属合作伙伴"></el-table-column>

    </el-table>
    <!--收益统计-广告位管理列表结束-->

    <!--sdk-列表开始-->
    <el-table ref="table" v-if="table.name === 'downMan'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="SDK名称" width="350" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" v-if="table.option">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="update_time_text" label="发布日期" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="sdk_size" label="大小（M）" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ twoPointCom(fileSizeNum(scope.row.sdk_size)) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sdk_download_num" label="下载次数（次）" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--sdk-列表结束-->

    <!--不计算收益酒店详情开始-->
    <el-table ref="table" v-if="table.name === 'noCalcIncomeHotel'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="hotel_name" label="酒店名称" width="350" show-overflow-tooltip></el-table-column>
      <el-table-column prop="province" label="省份" show-overflow-tooltip></el-table-column>
      <el-table-column prop="city" label="城市" show-overflow-tooltip></el-table-column>
      <el-table-column prop="county" label="区县" show-overflow-tooltip></el-table-column>
      <el-table-column prop="effective_time" label="生效时间" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--不计算收益酒店详情结束-->

    <!--财务-提现管理列表开始-->
    <el-table ref="table" v-if="table.name === 'finManCashOut'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="partner" label="合作伙伴" width="300" show-overflow-tooltip fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.partner.company_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="left" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.pay_status == 2 && scope.row.pay_voucher == ''">上传汇款单</el-button>
          <el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.pay_voucher != ''">汇款单</el-button>
          <el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.check_reason != ''">驳回原因</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="withdraw_amount_text" label="申请提现金额（元）" show-overflow-tooltip width="160">
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.withdraw_amount_text) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="status_text" label="审核状态" show-overflow-tooltip></el-table-column>
      <el-table-column prop="confirm_admin_name_text" label="审核人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="confirm_time_text" label="审核时间" show-overflow-tooltip width="170">
      </el-table-column>
      <el-table-column prop="pay_status_text" label="汇款状态" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="pay_admin_name_text" label="汇款人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pay_time_text" label="汇款时间" show-overflow-tooltip width="170">
      </el-table-column>
    </el-table>
    <!--财务-提现管理列表结束-->

    <!--财务-收益管理列表开始-->
    <el-table ref="table" v-if="table.name === 'finManIncome'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="partner_name" label="合作伙伴名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="totalWithdraw" label="总收益" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.totalWithdraw) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alreadyWithdraw" label="已提现金额" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.alreadyWithdraw) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="notWithdraw" label="未提现金额" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.notWithdraw) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--财务-收益管理列表结束-->

    <!--财务-财务对账列表开始-->
    <el-table ref="table" v-if="table.name === 'finManFin'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55" fixed></el-table-column>
      <el-table-column prop="company_name" label="合作伙伴名称" show-overflow-tooltip min-width='300' fixed></el-table-column>
      <el-table-column label="操作" min-width="70" fixed>
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="partner_type_text" label="合作类型" show-overflow-tooltip></el-table-column>
      <el-table-column prop="code" label="终端编码" show-overflow-tooltip></el-table-column>
      <el-table-column prop="timeshow" label="合同有效期" min-width="250" show-overflow-tooltip>
        <!-- <template slot-scope="scope">
             <span>{{scope.row.partner_contract.contract_date_begin+'~'+scope.row.partner_contract.contract_date_end}}</span>
        </template>  -->
      </el-table-column>
      <el-table-column prop="sum_check_amount" label="已对账金额" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.sum_check_amount/100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="uncheckAmount" label="未对账金额" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.uncheckAmount/100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sum_withdraw_amount" label="已提现金额" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.sum_withdraw_amount/100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sum_can_withdraw_amount" label="未提现金额" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.sum_can_withdraw_amount/100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sum_deducted_amount" label="扣款金额" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.sum_deducted_amount/100}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--财务-财务对账列表结束-->

    <!--财务-对账管理列表开始-->
    <el-table ref="table" v-if="table.name === 'finManRecon'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="date" label="对账月份" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column label="操作" width="70" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="reconciliation_id" label="对账流水号" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column label="对账类型" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.type==='001001'">影视单点</span>
          <span v-else>影视天卡</span>
        </template>
      </el-table-column>
      <el-table-column prop="status_text" label="状态" show-overflow-tooltip></el-table-column>
      <el-table-column prop="create_time" label="对账申请时间" width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="hotel_num" label="对账酒店数" show-overflow-tooltip></el-table-column>
      <el-table-column label="对账金额（元）" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.check_amount/100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reject_hotel_num" label="驳回酒店数" show-overflow-tooltip></el-table-column>
      <el-table-column label="驳回金额（元）" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.reject_amount/100}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--财务-对账管理列表结束-->

    <!--财务-对账酒店管理列表开始-->
    <el-table ref="tableHotal" v-if="table.name === 'finManReconHotal'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" style="width: 100%" @sort-change="sortChange" :row-key="getRowKey" :height="table.limit>=20 &&table.total>10?'550px':'330px'">
      <el-table-column :reserve-selection="true" type="selection" width="50" :selectable="selectable"> </el-table-column>
      <el-table-column prop="hotel_name" label="酒店名称" width="300" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column label="操作" width="150" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row,1)">明细</el-button>
          <el-button v-if="scope.row.status==3" type="text" size="mini" @click="detail(scope.row,2)">驳回原因</el-button>
        </template>
      </el-table-column>
      <el-table-column label="客房数" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.room_num?scope.row.room_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column width="130" label="激活终端数量" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.active_terminal_num?scope.row.active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="valid_active_terminal_num" width="180" label="影视有效激活终端数量" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.valid_active_terminal_num?scope.row.valid_active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="day_active_rate" label="日活率" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{background:statusCircleColor(scope.row.status)}" v-if="scope.row.status_text" class="statusCircle"></span>
          <span>{{scope.row.status_text}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="收益月份" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order_num" label="订单数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="amount" label="收益金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="refund_order_num" label="退款订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="refund_order_income" label="退款收益" show-overflow-tooltip></el-table-column>
      <el-table-column prop="reconciliation_order_num" label="对账订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="reconciliation_order_income" label="对账收益" show-overflow-tooltip></el-table-column>
      <el-table-column prop="income_source_text" label="收益来源" show-overflow-tooltip></el-table-column>
      <el-table-column prop="partner_name" label="所属合作伙伴" width="300" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--财务-对账酒店管理列表结束-->

    <!--财务-独立影视对账酒店管理列表开始-->
    <el-table ref="tableHotal" v-if="table.name === 'iddFinManReconHotal'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" style="width: 100%" @sort-change="sortChange" :row-key="getRowKey" :height="table.limit>=20 &&table.total>10?'550px':'330px'">
      <el-table-column :reserve-selection="true" type="selection" width="50" :selectable="selectable"> </el-table-column>
      <el-table-column prop="hotel_name" label="酒店名称" width="300" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column label="操作" width="150" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row,1)">明细</el-button>

          <el-button v-if="scope.row.status==3" type="text" size="mini" @click="detail(scope.row,2)">驳回原因</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="日期"> </el-table-column>
      <el-table-column label="客房数" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.room_num?scope.row.room_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column width="130" label="激活终端数量" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.active_terminal_num?scope.row.active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="valid_active_terminal_num" width="180" label="影视有效激活终端数量" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.valid_active_terminal_num?scope.row.valid_active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="day_active_rate" label="日活率" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{background:statusCircleColor(scope.row.status)}" v-if="scope.row.status_text" class="statusCircle"></span>
          <span>{{scope.row.status_text}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="收益月份" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order_num" label="订单数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="amount" label="收益金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="refund_order_num" label="退款订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="refund_order_income" label="退款收益" show-overflow-tooltip></el-table-column>
      <el-table-column prop="reconciliation_order_num" label="对账订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="reconciliation_order_income" label="对账收益" show-overflow-tooltip></el-table-column>
      <el-table-column prop="income_source_text" label="收益来源" show-overflow-tooltip></el-table-column>
      <el-table-column prop="partner_name" label="所属合作伙伴" width="300" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--财务-独立影视对账酒店管理列表开始-->

    <!--财务-申请提现列表开始-->
    <el-table ref="table" v-if="table.name === 'finManApply'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column prop="create_time_text" label="申请时间" sortable="custom" show-overflow-tooltip width="260"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!--<el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.status_text==='通过' && scope.row.pay_status_text==='未支付'">已汇款</el-button>-->
          <el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.pay_status === 1">查看</el-button>
          <el-button type="text" size="mini" @click="detail(scope.row)" v-else></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="withdraw_amount_text" label="提现金额（元）" sortable="custom" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ twoPointCom(scope.row.withdraw_amount_text) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status_text" label="状态" sortable="custom" show-overflow-tooltip></el-table-column>
      <el-table-column prop="confirm_name" label="审核人" sortable="custom" show-overflow-tooltip></el-table-column>
      <el-table-column prop="confirm_time_text" label="审核时间" sortable="custom" show-overflow-tooltip width="260"></el-table-column>
      <el-table-column prop="pay_status_text" label="支付状态" sortable="custom" show-overflow-tooltip width="230"></el-table-column>
    </el-table>
    <!--财务-申请提现列表结束-->

    <!--财务-申请提现列表开始-->
    <el-table ref="table" v-if="table.name === 'finManApplyChange'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <!-- <el-table-column prop="withdraw_type_name_text" label="收益类型" show-overflow-tooltip></el-table-column> -->
      <el-table-column prop="create_time_text" label="申请时间" show-overflow-tooltip min-width="200"></el-table-column>
      <el-table-column prop="withdraw_amount_text" min-width="120" label="申请提现金额" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!--<el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.status_text==='通过' && scope.row.pay_status_text==='未支付'">已汇款</el-button>-->
          <el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.pay_voucher !== ''">汇款单</el-button>

          <el-button type="text" size="mini" @click="detail(scope.row)" v-if="scope.row.check_reason !== ''">驳回原因</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="status_text" label="审核状态" show-overflow-tooltip></el-table-column>
      <el-table-column prop="confirm_admin_name_text" label="审核人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="confirm_time_text" label="审核时间" show-overflow-tooltip width="200"></el-table-column>
      <el-table-column prop="pay_status_text" label="付款状态" show-overflow-tooltip></el-table-column>
      <!-- <el-table-column prop="pay_admin_name_text" label="付款人"  sortable="custom" show-overflow-tooltip ></el-table-column> -->
      <el-table-column prop="pay_time_text" label="付款时间" show-overflow-tooltip></el-table-column>
    </el-table>
    <!--财务-申请提现列表结束-->

    <!--消息-政策开始-->
    <el-table ref="table" v-if="table.name === 'infoPubCoop' || table.name === 'infoPubNotice'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="title" label="标题" width="500"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="publish_time" label="发布时间"></el-table-column>
      <el-table-column prop="publish_man" label="发布人" @click="cellNumClick"></el-table-column>
      <el-table-column prop="status_text" label="状态" width="170"></el-table-column>
    </el-table>
    <!--消息-政策结束-->

    <!--账号列表开始-->
    <el-table ref="table" v-if="table.name === 'sysAccMan'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55" fixed="left"></el-table-column>
      <el-table-column prop="username" label="账号名称" width="250" show-overflow-tooltip fixed="left"></el-table-column>
      <el-table-column label="操作" fixed="left">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
          <!--<el-button type="text" size="mini" @click="editCodeMeth(scope.row)" >修改验证码</el-button>-->
        </template>
      </el-table-column>
      <el-table-column prop="company_name" label="合作伙伴名称" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column prop="contact_name" label="联系人" @click="cellNumClick" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="contact_phone" label="手机号码" width="250" show-overflow-tooltip></el-table-column>
      <el-table-column prop="group_name" label="所属角色" width="250" show-overflow-tooltip></el-table-column>
      <el-table-column prop="parent_account" label="上级名称" width="250" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态" v-if="table.switch" width="140">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" :active-value="0" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" @change="switchChange(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <!--账号列表结束-->

    <!--角色-角色列表开始-->
    <el-table ref="table" v-if="table.name === 'sysRoleMan'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="角色名称">
        <template slot-scope="scope">
          <span v-html="scope.row.name"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="partner_account" label="所属合作伙伴"></el-table-column>
      <el-table-column prop="user_count" label="用户数" @click="cellNumClick">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showUserDetail(scope.row)">{{ scope.row.user_count }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--角色-角色列表结束-->
    <!--流程列表开始-->
    <el-table ref="table" v-if="table.name === 'sysProcess'" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="table.tableList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="流程名称"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
      <!--<el-table-column prop="buildPerson" label="创建人"></el-table-column>-->
      <el-table-column prop="create_time_text" label="创建时间" @click="cellNumClick"></el-table-column>
      <el-table-column prop="update_time_text" label="最新编辑时间" @click="cellNumClick"></el-table-column>
      <!--<el-table-column prop="editPerson" label="最新编辑人" @click="cellNumClick" ></el-table-column>-->
    </el-table>
    <!--流程列表结束-->

    <div class="block pageBox" v-if="table.pageShow !== false && table.total !== 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, 20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total">
      </el-pagination>
    </div>

    <!--刷机明细弹窗开始-->
    <div class="boxBlack" v-if="personDialog.visible"></div>
    <el-dialog title="实施明细" :visible.sync="personDialog.visible" width="50%" :modal="false">
      <el-row :gutter="20" class="brushNum">
        已刷总数：{{ brushDetail.length }}间
      </el-row>
      <el-row :gutter="20">
        <el-col :span="2" :key="index" v-for="(item, index) in brushDetail">
          <div class="brushDetailItem">{{ item }}</div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="personDialogCancel">取 消</el-button>
      </span>
    </el-dialog>
    <!--刷机明细弹窗结束-->

    <!--角色-角色列表弹窗开始-->
    <div v-if="table.dialogShow">
      <el-dialog title="提示" :visible.sync="roleDialog.dialogVisible" width="50%">
        <!-- <el-row :gutter="20" class="roleAccount">
          <el-col :span="12" :key="index" v-for="(item, index) in userList">
            <span v-if="
                curRoleName === 'BD' ||
                curRoleName === '客服' ||
                curRoleName === '技服'
              ">{{ item.account_alias }}——{{ item.account }}</span>
            <span v-else>{{ item.company_name }}——{{ item.account }}</span>
          </el-col>
        </el-row> -->
        <el-row :gutter="24" class="roleAccount">
          <el-col :span="6">
            <el-input v-model="companyName" placeholder="请输入公司名"></el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="userName" placeholder="请输入联系人"></el-input>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" @click="searchUsers" class="btn">搜索</el-button>
            <el-button type="" @click="resetUsers" class="btn">重置</el-button>
          </el-col>
        </el-row>
        <el-table v-loading=userLoading v-if="curRoleName === 'BD' ||curRoleName === '客服' ||curRoleName === '技服'" ref="table" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="userList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
          <el-table-column prop="account_alias" label="账户名称"></el-table-column>
          <el-table-column prop="account" label="联系人"></el-table-column>
        </el-table>
        <el-table v-loading=userLoading v-else ref="table" :header-cell-style="table.headerStyle ? table.headerStyle : headerStyle" :data="userList" @selection-change="handleSelectionChange" @current-change="radioChange" :height="table.height" style="width: 100%" @sort-change="sortChange">
          <el-table-column prop="company_name" label="公司名" min-width="150"></el-table-column>
          <el-table-column prop="account" label="联系人" min-width="80"></el-table-column>
          <el-table-column prop="mobile" label="电话号码"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="cancelMeth">取 消</el-button>
        </span>
      </el-dialog>
    </div>
    <!--角色-角色列表弹窗结束-->

    <!-- <div class="block pageBox" v-if="table.pageShow!==false && table.total!==0">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
                    :current-page.sync="table.page"
                    :page-sizes="[5,20,50,100,150,200,250,300]"
                    :page-size="table.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="table.total">
            </el-pagination>
        </div>
       -->

    <!--修改验证码弹窗开始-->
    <el-dialog title="修改验证码" :visible.sync="editCodeDialog.dialogVisible" :close-on-click-modal="false" width="440px">
      <el-form :model="editCodeForm" :rules="editCodeRules" ref="editCodeForm" style="width: 400px">
        <el-form-item prop="event">
          <el-select class="fullSelect" v-model="editCodeForm.event" clearable collapse-tags placeholder="请选择事件类型">
            <el-option v-for="item in selectData.editCodeEvent" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="code" class="formItemNoMarginBottom">
          <el-input v-model="editCodeForm.code" placeholder="请输入验证码" type="number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelEditCodeMeth">取 消</el-button>
        <el-button type="primary" @click="editCodeConfirmMeth">提交</el-button>
      </div>
    </el-dialog>
    <!--修改验证码弹窗结束-->

    <!--修改模式开始-->
    <el-dialog title="修改模式类型" :visible.sync="patternDialogVisible" :close-on-click-modal="false" width="440px">
      <el-form :model="patternForm" :rules="patternRules" ref="patternForm" style="width: 400px">
        <el-form-item prop="type">
          <el-select class="fullSelect" v-model="patternForm.type" clearable collapse-tags placeholder="请选择模式类型">
            <el-option v-for="item in selectData.patternEvent" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelpatternMeth">取 消</el-button>
        <el-button type="primary" :loading="patternLoading" @click="patternConfirmMeth">提交</el-button>
      </div>
    </el-dialog>
    <!--修改模式结束-->

  </div>
</template>

<script>
import commonMeth from "../../api/method";
let row = "";
let column = "";
import GLOBAL from "../../api/globalVar.js";
import qs from "querystring";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { getHotelGroup, getHotelInfo } from "@/api/hotelGroup.js";
export default {
  name: "test",
  props: {
    table: {
      type: Object,
      default: function () {
        return {
          path: "",
          tableHeader: [
            { prop: "hotelName", label: "酒店名称", width: "" },
            { prop: "KA", label: "集团品牌", width: "" },
            { prop: "state", label: "客户终端状态", width: "" },
            { prop: "province", label: "省份", width: "" },
            { prop: "city", label: "城市", width: "" },
            { prop: "country", label: "区县", width: "" },
            { prop: "active_room_num", label: "客房总量（间）", width: "" },
            { prop: "terNum", label: "激活终端数量（台）", width: "" },
            { prop: "onlineTime", label: "建站时间", width: "" },
          ],
          tableList: [
            {
              id: 1,
              hotelName: "酒店1",
              KA: "2",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
            {
              id: 1,
              hotelName: "酒店2",
              KA: "3",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
            {
              id: 1,
              hotelName: "酒店1",
              KA: "2",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
            {
              id: 1,
              hotelName: "酒店2",
              KA: "3",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
            {
              id: 1,
              hotelName: "酒店2",
              KA: "3",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
            {
              id: 1,
              hotelName: "酒店1",
              KA: "2",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
            {
              id: 1,
              hotelName: "酒店2",
              KA: "3",
              state: 0,
              province: "北京",
              city: "北京",
              country: "北京",
              roomNum: "5",
              terNum: "3",
              onlineTime: "2020-1-2",
            },
          ],
          page: 1,
          limit: 1,
          pageSize: 5, //当前每页显示多少条
          total: 0,
          fromUrl: "",
          option: "",
          headerStyle: {
            background: "red",
            color: "#606266",
            border: "1px solid rgba(226, 230, 235, 1) 9px ",
            //         border:1px solid rgba(226, 230, 235, 1),
            // border-radius:9px 9px 9px 9px;
          },
          specialCell: {
            BD: false,
            build_person: false,
            jumpBtn: false,
          },
          brushDetail: [],
        };
      },
    },

    option: {
      type: Boolean,
      default: true,
    },
    roleDialog: {},
  },
  data: () => ({
    userLoading: "",
    ids: "",
    userName: "",
    companyName: "",
    funArr: [],
    novr: "",
    use_state: true,
    multipleSelection: [],
    radio: "",
    headerStyle: GLOBAL.tableHeaderStyle,
    provinceWith: "90px",
    brandWidth: "120px",
    hotelWidth: "340px",
    userList: [],
    curRoleName: "",
    curRoomNumber: [],
    //修改验证码
    selectData: {
      editCodeEvent: [
        { value: "login", name: "登录" },
        { value: "register", name: "注册" },
      ],
      patternEvent: [
        { value: "mp", name: "key模式 " },
        { value: "eng", name: "工程模式" },
        { value: "mem", name: "会员模式" },
        { value: "thp", name: "第三方会员" },
        { value: "keydmp", name: "key单点会员" },
        { value: "dmp", name: "单点会员同步" },
      ],
    },
    editCodeDialog: {
      dialogVisible: false,
    },
    editCodeForm: {
      event: "",
      mobile: "",
      code: "",
    },
    editCodeRules: {
      event: [
        { required: true, message: "请选择修改事件x类型", trigger: "blur" },
      ],
      mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      code: [
        { required: true, message: "请输入验证码", trigger: "blur" },
        { min: 4, max: 4, message: "长度为4个字符", trigger: "blur" },
      ],
    },
    token: "",
    auth: {},
    editable: [],
    defaultHotelName: "",
    brushDetail: ["0001", "0002"],
    personDialog: {
      visible: false,
    },
    marketDrawer: {
      visible: false,
      direction: "rtl",
    },
    query: {},
    checkDataList: [],
    getIndexHeader: {
      partnerType: "",
    },

    patternDialogVisible: false,
    patternForm: {
      type: "",
    },
    patternRules: {
      type: [{ required: true, message: "请选择模式类型", trigger: "blur" }],
    },
    patternEditId: "",
    patternLoading: false,
    maps: new Map(),
  }),
  watch: {
    checkedData(arr) {
      this.checkDataList = arr;
      this.checked();
    },
    "personDialog.visible"(newVal) {
      if (!newVal) {
        this.brushDetail = [];
      }
    },
  },
  methods: {
    showInfo(item) {
      getHotelInfo({
        database: item.hotelName,
        hotel_contract: item.hotel_contract,
      })
        .then((res) => {
          if (res.data.code == 100000) {
            const h = this.$createElement;
            this.$msgbox({
              title: "酒店信息",
              message: h("div", null, [
                h("p", null, [
                  h("span", null, "酒店ID："),
                  h("span", res.data.data.hotel_id),
                ]),
                h("p", null, [
                  h("span", null, "酒店名称："),
                  h("span", item.hotel),
                ]),
                h("p", null, [
                  h("span", null, "酒店程序名："),
                  h("span", res.data.data.project_name),
                ]),
                h("p", null, [
                  h("span", null, "酒店状态："),
                  h("span", res.data.data.hotel_use_state),
                ]),
                h("p", null, [
                  h("span", null, "酒店合同号："),
                  h("span", res.data.data.hotel_contract),
                ]),
                h("p", null, [
                  h("span", null, "终端管理密码："),
                  h("span", res.data.data.upgrade_password),
                ]),
                h("p", null, [
                  h("span", null, "电视信号类型："),
                  h("span", res.data.data.iptv_name),
                ]),
                h("p", null, [
                  h("span", null, "点播cp名："),
                  h("span", res.data.data.supplier_name),
                ]),
                h("p", null, [
                  h("span", null, "有无投屏："),
                  h("span", res.data.data.projection_str),
                ]),
                h("p", null, [
                  h("span", null, "新版本更新房间数量："),
                  h("span", res.data.data.version_num),
                ]),
              ]),

              confirmButtonText: "确定",
              beforeClose: (action, instance, done) => {
                done();
              },
            }).then((action) => {});
          } else {
            this.$message.warning("信息获取失败");
          }
        })
        .catch((err) => {});
    },
    uploadChildren(hotel_contract) {
      const { tree, treeNode, resolve } = this.maps.get(hotel_contract);
      this.$set(
        this.$refs.table.store.states.lazyTreeNodeMap,
        hotel_contract,
        []
      );
      this.loadChild(tree, treeNode, resolve);
    },
    selecTableTrue(row, index) {
      if (row.children) {
        return true;
      } else {
        return false;
      }
    },
    loadChild(tree, treeNode, resolve) {
      let json = {
        hotel_contract: tree.hotel_contract,
      };
      getHotelGroup(json).then((res) => {
        if (res.data.code == 100000) {
          let list = res.data.data;
          list.forEach((i) => {
            i.parentName = tree.hotel;
          });
          resolve(list);
        } else {
          resolve([]);
        }
        this.maps.set(tree.hotel_contract, { tree, treeNode, resolve });
      });
    },
    // 所有酒店操作栏修改模式界面打开
    changePattern(row) {
      this.patternLoading = false;
      this.patternEditId = row.id;
      this.patternDialogVisible = true;
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.patternEditId,
          },
        })
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var num = res.data.data.synUpdateState;
            if (num == 99 || num == 0) {
              this.patternForm.type = "eng";
            } else if (num > 0 && num < 10) {
              this.patternForm.type = "mp";
            } else if (num > 10 && num < 20) {
              this.patternForm.type = "dmp";
            } else if (num > 20 && num < 30) {
              this.patternForm.type = "mem";
            } else if (num > 30 && num < 40) {
              this.patternForm.type = "thp";
            } else if (num > 40 && num < 50) {
              this.patternForm.type = "keydmp";
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 关闭修改界面
    cancelpatternMeth() {
      this.patternEditId = "";
      this.patternDialogVisible = false;
      this.patternLoading = false;
    },
    // 确认修改结果
    patternConfirmMeth() {
      this.$refs["patternForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确定修改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.patternLoading = true;
            var param = qs.stringify({
              token: this.token,
              flag: this.patternForm.type,
              arr: this.patternEditId,
            });
            this.$http
              .post(this.global.updateHotelpattern, param)
              .then((res) => {
                var code = res.data.code;
                if (code === 100000) {
                  this.$message.success("修改成功");
                  this.patternDialogVisible = false;
                } else {
                  this.$message.error(res.data.msg);
                }
                this.patternLoading = false;
              });
          });
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
    // 获取用户类型
    getUserType() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.getIndexHeader.partnerType = res.data.data.partnerType;
        } else {
        }
      });
    },
    // 广告位管理 开关按钮事件
    switchChangeAdvertisement(row) {
      this.$emit("swicthChangeForAdvertisement", row);
    },
    checked() {
      this.checkDataList.filter((checked) => {
        this.$refs.tableHotal.toggleRowSelection(checked, true);
      });
    },
    // 获取每一行的key值
    getRowKey(row) {
      return row.id;
    },
    selectable(row, index) {
      if (row.status == 2) {
        return false;
      } else {
        return true;
      }
    },
    // 审核状态标记颜色 抛出
    statusCircleColor(txt) {
      if (txt == 2) {
        return "#53D439";
      } else if (txt == 1) {
        return "#FE8637";
      } else if (txt == 3) {
        return "#3774FE";
      } else {
        return "black";
      }
    },
    // stateFormat(row, column){
    //     if (row.hotel_use_state_text === '虚拟运营') {
    //         this.novr=false
    //     } else  {
    //         this.novr=true
    //     }
    // },
    detail(val, num) {
      console.log(val);
      var fromUrl = this.table.fromUrl;
      var name = this.table.name;
      if (fromUrl === "indexDoMore" || fromUrl === "taskAll") {
        if (val.type === "register") {
          this.$router.push({
            path: "./indexDoDetail",
            query: {
              id: val.id,
              reviewStatus: val.status,
              type: val.type,
              fromUrl: name,
            },
          });
        } else if (val.type === "labeladv") {
          this.$router.push({
            path: "./IndexDoLabel",
            query: {
              id: val.id,
              reviewStatus: val.status,
              type: val.type,
              fromUrl: name,
            },
          });
        } else if (val.type === "partner") {
          this.$router.push({
            path: "./indexDoQualify",
            query: { id: val.id, reviewStatus: val.status, fromUrl: name },
          });
        } else if (val.type === "visual") {
          this.$router.push({
            path: "./indexDoVisual",
            query: {
              id: val.id,
              reviewStatus: val.status,
              fromUrl: name,
              hotelId: val.hotel_id,
              fromEditType: "test",
            },
          });
        } else if (val.type === "visualPublish") {
          this.$router.push({
            path: "./indexDoVisualNew",
            query: {
              id: val.id,
              reviewStatus: val.status,
              fromUrl: name,
              hotelId: val.hotel_id,
              fromEditType: "test",
            },
          });
        } else if (val.type === "rootReceiving") {
          this.$router.push({
            path: "./indexDoBrush",
            query: { id: val.id, reviewStatus: val.status, fromUrl: name },
          });
        } else if (val.type === "check") {
          this.$router.push({
            path: "./indexDoBrushChange",
            query: { id: val.id, reviewStatus: val.status, fromUrl: name },
          });
        } else if (
          val.type === "partnerWithdraw" ||
          val.type === "independentWithdraw" ||
          val.type === "youkeWithdraw"
        ) {
          this.$router.push({
            path: "./indexDoWithdraw",
            query: {
              id: val.id,
              reviewStatus: val.status,
              type: val.type,
              fromUrl: name,
            },
          });
        } else if (val.type === "partnerConfig") {
          this.$router.push({
            path: "./indexDoCoop",
            query: {
              id: val.id,
              reviewStatus: val.status,
              fromUrl: name,
            },
          });
        } else if (val.type === "adFrequency") {
          this.$router.push({
            path: "./indexDoAdOrder",
            query: {
              order_no: val.order_no,
              reviewStatus: val.status,
              id: val.id,
              fromUrl: name,
            },
          });
        } else if (
          val.type === "reconciliation" ||
          val.type === "independentReconciliation"
        ) {
          //reconciliation
          var editType = "";
          if (val.status === "待审核") {
            editType = "check";
          } else {
            editType = "onlyShow";
          }
          this.$router.push({
            path: "./IndexDoReconChange",
            query: {
              id: val.id,
              reviewStatus: val.status,
              type: val.type,
              createType: val.review_do_msg,
              editType: editType,
              fromUrl: name,
              test: "test",
            },
          });
        }
      } else if (
        fromUrl === "incomeStaAd" ||
        fromUrl === "incomeStaViewMovie"
      ) {
        this.$router.push({ path: "./dataStaDetail", query: { id: val.id } });
      } else if (
        fromUrl === "hotelManAll" ||
        fromUrl === "hotelManAl" ||
        fromUrl === "hotelManAllUsing" ||
        fromUrl === "toBeConfiguredHotel" ||
        fromUrl === "onePageHotelManAll"
      ) {
        //所有酒店列表
        var weight = 1; //携旅权限，看到全部
        console.log(val, "hotel");
        var query = {
          id: val.id,
          fromUrl: this.table.otherFromUrl,
          type: "edit",
          hotel_contract: val.hotel_contract,
          hotel: val.hotel,
          parentName: val.parentName ? val.parentName : "",
          hotelName: val.program_name ? val.program_name : val.hotelName,
          ipaddr: val.ipaddr,
        };
        if (!val.children) {
          query.hotel_id = val.hotel_id;
        }
        console.log(query);
        this.$emit("showUploadDialog", query, num);

        // this.$router.push({
        //   path: "./hotelManAllDetail",
        //   query: {
        //     id: val.id,
        //     fromUrl: this.table.otherFromUrl,
        //     type: "edit",
        //     hotel_contract: val.hotel_contract,
        //   },
        // });
      } else if (fromUrl === "hotelManAssignTest") {
        var query = {
          id: val.id,
          fromUrl: "hotelManAssignTest",
          type: "edit",
        };
        this.$emit("showUploadDialog", query);
        // this.$router.push({
        //   path: "./hotelManAllDetail",
        //   query: { id: val.id, fromUrl: "hotelManAssignTest", type: "edit" },
        // });
      } else if (fromUrl === "incomeStaMovie") {
        var { startTime, endTime, incomeSource } = this.table;
        // this.$router.push({
        //   path: "./incomeStaMovieDetail",
        //   query: {
        //     id: val.id,
        //     fromUrl: "incomeStaMovie",
        //     hotel_contract: val.hotel_contract,
        //     startTime,
        //     endTime,
        //     incomeSource,
        //     order_goods_type:'channel_card'
        //   },
        // });
        var query = {
          id: val.id,
          fromUrl: "incomeStaMovie",
          hotel_contract: val.hotel_contract,
          endTime: val.watch_film_date,
          is_special: val.is_special,
          incomeSource,
          order_goods_type: val.order_goods_type,
        };
        this.$emit("showUploadDialog", query);
      } else if (fromUrl === "historyIncome") {
        var { startTime, endTime, incomeSource } = this.table;
        var query = {
          id: val.id,
          fromUrl: "historyIncome",
          hotel_contract: val.hotel_contract,
          endTime: val.watch_film_date,
          incomeSource,
          order_goods_type: val.order_goods_type,
        };
        this.$emit("showUploadDialog", query);
      } else if (fromUrl === "iddincomeStaMovie") {
        var { startTime, endTime, incomeSource } = this.table;
        // this.$router.push({
        //   path: "./iddincomeStaMovieDetail",
        //   query: {
        //     fromUrl: "iddincomeStaMovie",
        //     hotel_contract: val.order_hotel_contract,
        //     startTime,
        //     endTime,
        //     order_room:val.order_room,
        //     order_goods_type:val.order_goods_type
        //   },
        // });
        var query = {
          fromUrl: "iddincomeStaMovie",
          hotel_contract: val.order_hotel_contract,
          endTime: val.order_datetime,
          order_status: val.order_status,
          calculate: val.calculate,
          order_room: val.order_room,
          order_goods_type: val.order_goods_type,
        };
        console.log(val);
        this.$emit("showUploadDialog", query);
      } else if (fromUrl === "adManOrder") {
        this.$router.push({
          path: "./adManOrderDetail",
          query: { id: val.id },
        });
      } else if (fromUrl === "coopList") {
        var query = {
          id: val.id,
          reviewStatus: val.status_text,
          type: "edit",
          partnerCode: val.code,
        };
        this.$emit("showUploadDialog", query);
      } else if (fromUrl === "toBeConfiguredHotel") {
        this.$router.push({
          path: "./toBeConfiguredHotelDetail",
          query: { id: val.id },
        });
      } else if (fromUrl === "performanceSta") {
        // this.$router.push({path:'./coopDetail',query:{id:val.id}})
      } else if (fromUrl === "downMan") {
        // this.$router.push({name:'./downNew',params:{id:val.id,name:val.name,sdk_url:val.sdk_url}})
        this.$router.push({
          path: "downNew",
          query: {
            id: val.id,
            type: "edit",
            name: val.name,
            version: val.version,
            sdk_url: val.sdk_url,
            sdk_document: val.sdk_document,
            sdk_log: val.sdk_log,
          },
        });
      } else if (fromUrl === "adManOrder") {
        this.$router.push({ path: "adManOrderDetail", query: { id: val.id } });
      } else if (fromUrl === "adManSpace") {
        this.$emit("showUploadDialog", val);
      } else if (fromUrl === "adManCus") {
        this.$router.push({
          path: "adManSpaceNew",
          query: { id: val.id, fromType: "ordinary" },
        });
      } else if (fromUrl === "infoPubNotice") {
        var query = {
          id: val.id,
          fromType: "detail",
          showType: "noticeMan",
          type: "edit",
          status: val.status,
        };
        this.$emit("showUploadDialog", query);
      } else if (fromUrl === "infoPubCoop") {
        const query = {
          id: val.id,
          fromType: "detail",
          showType: "coopPolicy",
          type: "edit",
          status: val.status,
        };
        this.$emit("showUploadDialog", query);
      } else if (fromUrl === "policyList") {
        this.$router.push({
          path: "policyDetail",
          query: {
            id: val.id,
            fromType: "detail",
            showType: "coopPolicy",
            type: "edit",
          },
        });
      } else if (fromUrl === "sysConfig") {
        this.$router.push({
          path: "sysConfigNew",
          query: {
            id: val.id,
            calculate_max_value: val.calculate_max_value,
            calculate_min_value: val.calculate_min_value,
            settlement_value: val.settlement_value,
            fromType: "detail",
            showType: "coopPolicy",
            type: "edit",
            name: val.name,
          },
        });
      } else if (fromUrl === "sysAccMan") {
        this.$router.push({
          path: "sysAccManNew",
          query: { id: val.id, type: "edit", curType: val.partner_type },
        });
      } else if (fromUrl === "sysRoleMan") {
        this.$router.push({
          path: "sysRoleManNew",
          query: { id: val.id, val: val.rules, name: val.name, type: "edit" },
        });
      } else if (fromUrl === "finManRecon") {
        this.$emit("showUploadDialog", val);
      } else if (fromUrl === "finManFin") {
        // this.$router.push({
        //   path: "finManReconDetail",
        //   query: {
        //     id: val.id,
        //     reconciliationType: val.reconciliationType,
        //     fromUrl: "finManFin",
        //   },
        // });
        this.$emit("showUploadDialog", val);
      } else if (fromUrl === "sysProcess") {
        this.$router.push({
          path: "sysProcessDetail",
          query: { id: val.id, fromUrl: "sysProcess" },
        });
      } else if (fromUrl === "finManCashOut") {
        this.$emit("showUploadDialog", val);
      } else if (fromUrl === "finManApply") {
        this.$emit("showUploadDialog", val);
      } else if (
        fromUrl === "finManReconHotal" ||
        fromUrl === "iddFinManReconHotal"
      ) {
        const param = {
          val: val,
          num: num,
        };
        this.$emit("showUploadDialog", param);
      }
      console.log(fromUrl);
    },
    editCodeMeth(val) {
      this.editCodeForm = {
        event: "",
        mobile: "",
        code: "",
      };
      this.editCodeDialog.dialogVisible = true;
      this.editCodeForm.mobile = val.contact_phone;
    },
    editCodePost(mobile) {
      var param = qs.stringify({
        token: this.token,
        event: this.editCodeForm.event,
        mobile: this.editCodeForm.mobile,
        code: this.editCodeForm.code,
      });
      this.$http.post(this.global.resetSmsCode, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    skipToManage(data) {
      if (data.building_state == "1" || data.building_state == "2") {
        window.open("", "_blank");
        // this.$message.warning("建站流程中，请稍后在尝试跳转功能");
      } else if (data.building_state == "3") {
        if (data.skipAddress !== "127.0.0.1//admin") {
          window.open("http://" + data.skipAddress);
        } else {
          window.open("", "_blank");
        }
      } else {
        // this.$message.error("建站失败，请联系管理员处理此问题");
        window.open("", "_blank");
      }
    },
    //监听pageSize改变，每页显示多少条
    handleSizeChange(newLimit) {
      sessionStorage.setItem("filterLimit", newLimit);
      this.table.limit = newLimit;
      this.$emit("limit", newLimit);
      this.$emit("tableMeth");
    },
    //监听页码值改变，当前页是多少
    handleCurrentChange(newPage) {
      sessionStorage.setItem("filterPage", newPage);
      this.table.page = newPage;
      this.$emit("page", newPage);
      this.$emit("tableMeth");
    },
    //表格select选中事件
    handleSelectionChange(val) {
      console.log(val);
      if (this.table.radio) {
        if (val.length > 1) {
          this.$refs.table.clearSelection();
          this.$refs.table.toggleRowSelection(val[1]);
          this.$emit("tableSelect", val[1]);
        } else if (val.length === 1) {
          this.$emit("tableSelect", val[0]);
        } else if (val.length === 0) {
          this.$emit("tableSelect", "");
        }
      } else {
        this.multipleSelection = val;
        this.$emit("tableSelect", val);
      }
    },
    radioChange(row) {
      if (this.table.radio === true) {
        if (row) {
          // 切换数据时判断当前过度有没有数据 再执行接下来的操作
          this.table.tableList.forEach((item) => {
            if (item.id !== row.id) {
              item.checked = false;
            }
          });
        }
      }
    },
    getTemplateRow(index, row) {
      //获取选中数据
      this.radio = row;
    },
    cellNumClick(row, column, cell, event) {
      var id = row.id;
    },
    showUserDetail(val) {
      this.curRoleName = val.name;
      val.user_id === "" || !val.user_id
        ? this.$message.warning("该角色暂无用户")
        : this.getRoleUser(val.user_id, val.name);
    },
    showRoomDetail(val) {
      this.marketDrawer.visible = true;
      this.curRoomNumber = val.room_number.split(",");
    },
    cancelMeth() {
      this.$emit("dialogState", false);
    },
    personDialogCancel() {
      this.personDialog.visible = false;
    },
    cancelEditCodeMeth() {
      this.editCodeDialog.dialogVisible = false;
    },
    getRoleUser(ids, type) {
      this.userLoading = true;
      this.ids = ids;
      this.userName = "";
      this.companyName = "";
      this.$http
        .get(
          this.global.findUsers,
          {
            params: {
              ids: ids,
              token: this.token,
            },
          },
          { headers: { token: this.token } }
        )
        .then((res) => {
          if (res.data.code === 100000) {
            this.userLoading = false;
            this.userList = res.data.data;
            this.$emit("dialogState", true);
          } else {
            this.userLoading = false;
            this.userList = [];
            this.$message.error("获取失败");
          }
        });
    },
    // 搜索用户
    searchUsers() {
      this.userLoading = true;
      this.$http
        .get(
          this.global.findUsers,
          {
            params: {
              username: this.userName,
              company_name: this.companyName,
              ids: this.ids,
              token: this.token,
            },
          },
          { headers: { token: this.token } }
        )
        .then((res) => {
          if (res.data.code === 100000) {
            this.userLoading = false;
            this.userList = res.data.data;
            this.$emit("dialogState", true);
          } else {
            this.userLoading = false;
            this.userList = [];
            this.$message.error("获取失败");
          }
        });
    },
    // 重置搜索
    resetUsers() {
      this.userName = "";
      this.companyName = "";
      this.searchUsers();
    },
    switchChange(val) {
      this.$http
        .get(this.global.changeStat, {
          params: {
            token: this.token,
            id: val.id,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success(res.data.msg);
            this.$emit("tableMeth");
          } else {
            this.$message.error(res.data.msg);
          }
          this.$emit("tableMeth");
        })
        .catch((res) => {
          // this.$message.error(res.data.msg);
        });
    },
    sortChange(val, test) {
      this.$emit("tableMeth", val);
    },
    exportExcel() {
      // 设置当前日期
      let time = new Date();
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let name = year + "" + month + "" + day;
      /* generate workbook object from table */
      //  .table要导出的是哪一个表格
      var wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    invertSelect() {},
    editCodeConfirmMeth() {
      this.$refs["editCodeForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确定修改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.editCodePost();
            this.editCodeDialog.dialogVisible = false;
          });
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
    changeNum(index, className, defaultHotelName) {
      this.defaultHotelName = defaultHotelName;
      //设置是否可以编辑
      // tableList为表格数据
      this.editable = new Array(this.table.tableList.length);
      this.editable[index] = true;
      this.saveItem = this.table.tableList[index];
      this.$set(this.editable, index, true);
      //让input自动获取焦点
      this.$nextTick(function () {
        var editInputList = document.getElementsByClassName(className);
        editInputList[0].children[0].focus();
        this.$refs.editRemarkInput.focus();
      });
    },
    blurMeth(e, index, id, hotelName) {
      this.editable = [];
      if (this.defaultHotelName !== hotelName) {
        this.$emit("showTableLoading");
        var param = qs.stringify({
          token: this.token,
          id,
          hotelName,
        });
        this.$http.post(this.global.updateHotelName, param).then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("编辑成功");
            this.$emit("tableMeth");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    personConfirm(type, str, id) {
      this.$confirm(`确定${str}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          switch (type) {
            case "activate":
              this.personActivate(id);
              break;
            case "detail":
              this.personDetail(id);
              break;
            case "transfer":
              this.personTransfer(id);
              break;
          }
        })
        .catch(() => {});
    },
    personActivate(id) {
      var param = qs.stringify({
        token: this.token,
        hotel_contract: this.table.hotel_contract,
        member_id: id,
      });
      this.$http
        .post(this.global.activateHotelMember, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("激活成功");
            this.$emit("getTableData");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$emit("ediTabLoading", false);
        });
    },
    personDetail(id) {
      this.personDialog.visible = true;
      var param = qs.stringify({
        token: this.token,
        hotel_contract: this.table.hotel_contract,
        member_id: id,
      });
      this.$http
        .post(this.global.getRegisteDetail, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.brushDetail = res.data.data.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$emit("ediTabLoading", false);
        });
    },
    personTransfer(id) {
      var param = qs.stringify({
        token: this.token,
        hotel_contract: this.table.hotel_contract,
        member_id: id,
        admin_id: this.table.adminId,
      });
      this.$http
        .post(this.global.transferAuth, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("移交成功");
            this.$emit("getTableData");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$emit("ediTabLoading", false);
        });
    },
    drawerBeforeClose() {},
  },
  created() {
    // this.table.total=this.table.tableList.length;
  },
  mounted() {
    this.path = this.$route.path;
    this.$forceUpdate();
    var { page } = this.table;
    this.token = sessionStorage.getItem("token");
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.query = this.$route.query;
    this.$forceUpdate();
    let itemData = JSON.parse(sessionStorage.getItem("hoteliitem")) || {
      isApaas: false,
    };
    let apaasData = sessionStorage.getItem("apaasData");
    if (!itemData.isApaas && !apaasData) {
      this.getUserType();
      commonMeth.getTreeData(this.table.option).then((res) => {
        this.funArr = res.funArr;
      });
    } else {
      let str = sessionStorage.getItem("funArr");
      if (str.indexOf("[") != -1) {
        this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
      } else {
        this.funArr = str.split(",");
      }
    }

    // this.showusestate()
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    test() {
      return function (allData, val) {
        var a = eval(val);
        if (allData.a) {
          return allData.a;
        } else {
          return "";
        }
      };
    },
    twoPointCom() {
      return function (x) {
        !x ? (x = 0) : "";
        var f = parseFloat(x);
        if (isNaN(f)) {
          return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf(".");
        if (rs < 0) {
          rs = s.length;
          s += ".";
        }
        while (s.length <= rs + 2) {
          s += "0";
        }
        return s;
      };
    },
    zeroCom() {
      return function (val) {
        var num;
        val ? (num = val) : (num = 0);
        return num;
      };
    },
    fileSizeNum() {
      return function (val) {
        var str = "";
        str = val / 1024 / 1024;
        return str;
      };
    },
    zeroToStrCom() {
      return function (val) {
        var str = "";
        val == 0 ? "" : (str = val);
        return str;
      };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table__placeholder {
  width: 7px;
}
::v-deep .el-table__fixed,
::v-deep .el-table__fixed-right {
  height: auto !important;
  bottom: 10px !important;
  top: 0px;
}
.sixtable ::v-deep .el-table__fixed-body-wrapper {
  top: 60px !important;
  box-sizing: border-box;
  height: calc(100% - 65px) !important;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 5px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写*/
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*background-color: rgba(0,0,0,0.19);*/
  /*border-radius: 3px;*/
}
::v-deep .el-table th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  box-sizing: border-box;
  border-bottom: none !important;
}
::v-deep .el-table__footer-wrapper,
::v-deep .el-table__header-wrapper {
  border-radius: 4px !important;

  border: 1px solid #ebeef5 !important;
  border-top: none !important;
}
.el-pagination {
  margin-top: 20px;
}
.roleAccount .el-col {
  margin-bottom: 15px;
  padding-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noMarginBottom {
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
}
.formItemNoMarginBottom {
  margin-bottom: 0 !important;
}
.brushDetailItem {
  width: 100%;
  padding: 10px;
  color: #3085fc;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.brushNum {
  margin-bottom: 20px;
  padding-left: 10px;
}
/*市场drawer开始*/
.drawerContent {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
}
.roomItem {
  text-align: center;
  color: #3085fc;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
/*市场drawer结束*/

.statusCircle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background: burlywood;
}
::v-deep :focus {
  outline: 0;
}
.tableScopeSwitch {
  margin-left: 10px;
}
.tableScopeSwitch ::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.tableScopeSwitch ::v-deep .el-switch__label--right {
  z-index: 1;
  right: 22px;
}

.tableScopeSwitch ::v-deep .el-switch__label.is-active {
  display: block;
}
.tableScopeSwitch.el-switch ::v-deep .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}
.boxBlack {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.btn {
  height: 40px;
  width: 100px;
}
</style>
