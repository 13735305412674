<template>
    <div>
        <el-col :span="span">
            <div class="grid-content bg-purple outBox">
                <div class="left">
                    <div class="numTitle">
                        <slot name="numTitle" class="noWrap"></slot>
                        <slot v-if="toolText===''" name="numTitle" class="noWrap"></slot>
                        <slot name="time" class="numTitle noWrap"  v-if="type!=='timeCenter'"></slot>
                    </div>
                    <div v-if="type==='timeCenter'" class="timeText" >
                        <slot name="time" class="noWrap"></slot>
                    </div>
                    <div class="bottomBox">
                        <div class="numBox">
                        <span class="indexNum marginRight">
                            <span class="1" v-if="showType!=='percent'">{{showDataCom(allData.number,'','','number')}}</span>
                            <span  class="2"  v-if="showType==='percent'">{{showDataCom(allData.total,'','','number')}}</span>
                        </span>
                            <span class="unit">
                                <span v-if="numberUnit">万</span>
                             <slot name="unit" class="noWrap"></slot>
                        </span>
                        </div>
                        <i class="iconfont icon-wenjian indexIcon" :class="color" v-if="iconShow"></i>
                    </div>
                </div>
                <!--<div class="right" v-if="showType!=='percent'">-->
                <div class="right" v-if="showType!==''">
                    <div class="borderBox" v-if="showType!=='percent'">
                        <div class="item">
                            <div class="title">电影媒资：</div>
                            {{showDataCom(allData.movie,'','','film')}}
                            <span v-if="filmUnit" class="unit">万</span>
                        </div>
                        <div class="item">
                            <div class="title">商城：</div>
                            {{showDataCom(allData.shop,'','','shop')}}
                            <span v-if="shopUnit" class="unit">万</span>
                        </div>
                        <div class="item">
                            <div class="title">广告：</div>
                            {{showDataCom(allData.ad,'','','ad')}}
                            <span v-if="adUnit" class="unit">万</span>
                        </div>
                    </div>
                    <!--环比-->
                    <div class="borderBox" v-if="showElCom('chain') && showType==='yearOrChain'">
                        <div class="item" :class="[rateTextStyleCom(1,0.00)]">
                            <div class="title">环比：</div>
                            <slot name="film">{{allData.yearPercent}}%</slot>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='redFonts'">&#xe633;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='greenFonts'">&#xe631;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='grayFonts'">-</i>
                        </div>
                        <div class="item" :class="[rateTextStyleCom(1,0.00)]">
                            <div class="title">环比：</div>
                            <slot name="shop">{{allData.yearPercent}}%</slot>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='redFonts'">&#xe633;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='greenFonts'">&#xe631;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='grayFonts'">-</i>
                        </div>
                        <div class="item" :class="[rateTextStyleCom(1,0.00)]">
                            <div class="title">环比：</div>
                            <slot name="ad">{{allData.yearPercent}}%</slot>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='redFonts'">&#xe633;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='greenFonts'">&#xe631;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='grayFonts'">-</i>
                        </div>
                    </div>
                    <!--同比-->
                    <div class="borderBox" v-if="showElCom('year') && showType==='yearOrChain'">
                        <div class="item" :class="[rateTextStyleCom(1,0.00)]">
                            <div class="title">同比：</div>
                            <slot name="film">{{allData.yearPercent}}%</slot>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='redFonts'">&#xe633;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='greenFonts'">&#xe631;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='grayFonts'">-</i>
                        </div>
                        <div class="item" :class="[rateTextStyleCom(1,0.00)]">
                            <div class="title">同比：</div>
                            <slot name="shop">{{allData.yearPercent}}%</slot>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='redFonts'">&#xe633;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='greenFonts'">&#xe631;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='grayFonts'">-</i>
                        </div>
                        <div class="item" :class="[rateTextStyleCom(1,0.00)]">
                            <div class="title">同比：</div>
                            <slot name="ad">{{allData.yearPercent}}%</slot>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='redFonts'">&#xe633;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='greenFonts'">&#xe631;</i>
                            <i class="iconfont" v-if="rateTextStyleCom(1,0.00)==='grayFonts'">-</i>
                        </div>
                    </div>
                    <div class="borderBox" v-if=" showType==='percent'">
                        <div class="item" v-if="offLineShowProp">
                            <div class="title" >已离线：</div>
                            <slot name="zero">{{showDataCom(allData.offline)}}</slot>
                        </div>
                        <div class="item">
                            <div class="title">0%：</div>
                            <slot name="zero">{{showDataCom(allData.zero)}}</slot>
                        </div>
                        <div class="item">
                            <div class="title">1%-10%：</div>
                            <slot name="ten">{{showDataCom(allData.ten)}}</slot>
                        </div>
                        <div class="item">
                            <div class="title">11%-30%：</div>
                            <slot name="thirty">{{showDataCom(allData.thirty)}}</slot>
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: "QuarterRow",
        props:{
            span:{
                default:6
            },
            toolText:{
                default:''
            },
            type:{
                default:'timeRight'
            },
            color:{},
            iconShow:{
                default:false
            },
            parShowDate:{},
            parShowUnit:{},
            filterGroup:{},
            showType:{},
            offLineShowProp:{},
            allData:{
                type:Object,
                default:function(){
                    return {
                        ad:"0",
                        chainLevel: 2,
                        chainPercent: "0.00",
                        movie: "0",
                        number: "-",
                        shop: "0",
                        yearLevel: 2,
                        yearPercent: "0.00",
                    }
                }
            },
        },
        data: () => ({
            isShowUnit:false,
            numberUnit:false,
            filmUnit:false,
            shopUnit:false,
            adUnit:false,
        }),
        methods:{
            modelTypeUnit(type,val){
                switch(type){
                    case 'number':
                        this.numberUnit=val;
                        break;
                    case 'film':
                        this.filmUnit=val;
                        break;
                    case 'shop':
                        this.shopUnit=val;
                        break;
                    case 'ad':
                        this.adUnit=val;
                        break;
                }
            }
        },
        computed:{
            showDataComTest(){
                return function(s,type='',n=2){
                    if(type==='money'){
                        if(s===null){
                            return 0;
                        }else{
                            n = n > 0 && n <= 20 ? n : 2;
                            s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                            var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                            var t = "";
                            for (var i = 0; i < l.length; i++) {
                                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                            }
                            return t.split("").reverse().join("") + "." + r;
                        }
                    }else{
                        if(s===null){
                            return 0;
                        }else{
                            return parseFloat(s).toLocaleString();
                        }
                    }
                }
            },
            showDataCom(){
                return function(s,type='',n=2,unitType){
                    if(s=='-'){
                        return s;
                    }else{
                        var stringNum=String(s);
                        var pointIndex=stringNum.indexOf('.');
                        var strLength=0;
                        var result=0;
                        if(pointIndex!==-1){  //有小数点
                            var noPointStr=String(s).substring(0,pointIndex);
                            strLength=noPointStr.length;
                        }else{
                            strLength=stringNum.length;
                        }
                        if(s===null){
                            return 0;
                        }else{
                            if(strLength>5){
                                this.modelTypeUnit(unitType,true);
                                result = Math.floor((s /10000) * 100) / 100; //保留小数点两位
                                return result.toFixed(2);
                            }else{   //小于5
                                this.modelTypeUnit(unitType,false);
                                if(type==='money'){
                                    n = n > 0 && n <= 20 ? n : 2;
                                    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                                    var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
                                    var t = "";
                                    for (var i = 0; i < l.length; i++) {
                                        t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                                    }
                                    return t.split("").reverse().join("") + "." + r;
                                }else{
                                    return parseFloat(s).toLocaleString();
                                }
                            }
                        }
                    }
                }
            },
            showElCom(){
                return function(val){
                    var flag=false;
                    if(this.filterGroup){
                        if(this.filterGroup.yearOrChain.indexOf(val)!==-1){
                            flag=true;
                        }
                    }
                    return flag;
                }
            },
            rateTextStyleCom(){
                return function(level,percent){
                    var color='';
                    if(level && percent){
                        if(level == 1 && percent &&  percent!=0.00){
                            color='redFonts'
                        }else if(level == 1 && percent==0.00){
                            color='grayFonts'
                        }else if(level != 1){
                            color='greenFonts'
                        }
                    }else{
                        color='grayFonts'
                    }
                    return color;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .el-col-5{
        width:20%!important;
    }
    .el-row {
        margin-top: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .borderBox.rowBox .grid-content{
        /*border:1px solid #ddd;*/
    }

    .vertical{
        .grid-content{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            .numTitle{
                margin-bottom:20px;
                display:flex;
                justify-content: space-between;
                color:#a3a7af;
            }
            .num{
                font-size:24px;
                margin-right:10px;
            }
        }
        .bg-purple {
            /*background: #d3dce6;*/
            background: #fff;
        }
        .grid-content {
            border-radius: 4px;
            padding:20px 20px;
        }
    }
    .indexIcon{
        font-size:40px;
    }
    .indexIcon.green{
        color:#40c2ad;
    }
    .indexIcon.orange{
        color:#ff9846;
    }
    .indexIcon.blue{
        color:#445eee;
    }
    .indexIcon.red{
        color:#f12525;
    }
    .numBox{
        display:flex;
        align-items: flex-end;
    }
    .indexNum.marginRight{
        margin-right:10px;
        font-size: 28px;
    }
    .unit{
        margin-bottom:5px;
        font-size: 14px;
        color: #a3a7af;
    }
    .bottomBox{
        display:flex;
        justify-content: space-between;
    }
    .outBox{
        box-shadow:0 0 11px 2px rgba(94,151,255,0.15);
        display:flex;
        flex-direction: row!important;
        .left{
            width:60%;
        }
        .right{
            display: flex;
            box-sizing: border-box;
            align-items: flex-end;
            .borderBox{
                border-left: 1px solid #e3e3e3;
                padding: 0 20px;

            }
            .item{
                font-size:14px;
                margin-bottom: 10px;
                display:flex;
                color:#b1b1b1;
                .title{
                    font-size:14px;
                    width:90px;
                    text-align: right;
                    color:#b1b1b1!important;
                    white-space: nowrap;
                }
            }
            .item:last-child{
                margin-bottom:0!important;
            }
        }
    }
    .timeText{
        margin-left: -6px;
        margin-bottom:20px;
        font-size:12px;
        color:#a3a7af;
    }
    .noWrap{
        white-space:nowrap!important;
    }
    .pointer{
        cursor:pointer;
    }
    .numUnit{
        font-family: DIN!important;
        font-size:32px;
    }
    .redFonts {
        font-family: DIN !important;
        color: rgba(255, 8, 8, 1);
    }
    .greenFonts {
        font-family: DIN !important;
        color: rgba(57, 187, 40, 1)!important;
    }
    .grayFonts {
        font-family: DIN !important;
        color: #939495;
    }
    .unit{
        margin-left:5px;
    }

</style>
