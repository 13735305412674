<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">角色管理</span>
                <span slot="fourth" v-if="query.type!=='edit'">新建</span>
                <span slot="fourth" v-if="query.type==='edit'">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card" >
            <back @backMeth="backMeth"></back>
            <el-row :gutter="20" class="marginTop">
                <el-col :span="15">
                    <el-form  flabel-width="" :model="form" :rules="rules" ref="form">
                        <el-form-item label="角色名称" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row class="treeRowBox" :gutter="20">
                <el-col :span="10" class="colBox">
                    <div class="menuBox outBox">
                        <div class="title">
                            目录权限
                        </div>
                        <div class="con" v-loading="treeLoading">
                            <el-tree ref="tree"
                                    :data="menuList"
                                    show-checkbox
                                    node-key="id"
                                     :default-expand-all="true"
                                    @node-click="nodeClick"
                                    @check-change="nodeChange"
                                    :props="defaultProps">
                            </el-tree>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="colBox">
                    <div class="menuBox outBox">
                        <div class="title">
                            功能权限
                        </div>
                        <div class="con">
                            <el-checkbox-group v-model="checkList" class="checkBox" style="height:400px" @change="checkChange">
                                <el-col :span="12" v-for="(item,index) in funList">
                                    <el-checkbox :label="item.id" :key="index" >{{item.text}}</el-checkbox>
                                </el-col>
                            </el-checkbox-group>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="btnRow" :gutter="20">
                <el-col :span="22" class="colBox flexBox">
                    <el-button type="primary" class="largeBtn rightBtn" @click="submitMeth('form')">保存</el-button>
                </el-col>
            </el-row>
            <!--<el-form-item>-->
                <!--<el-button type="primary" class="largeBtn" @click="submitMeth('form')">保存</el-button>-->
            <!--</el-form-item>-->
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "finance",
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/sysRoleMan'},
            },
            form:{
                name:'',
            },
            rules:{
                name: [
                    { required: true, message: '请输入账号名称', trigger: 'blur' }
                ],
            },
            treeList:[
                {
                    id:101,
                    authName:'酒店管理',
                    path:null,
                    pid:0,
                    children:[
                        {
                            id:104,
                            authName:'所有酒店列表',
                            path:null,
                            pid:101,
                        },
                        {
                            id:105,
                            authName:'运营酒店列表',
                            path:null,
                            pid:101,
                        },
                        {
                            id:106,
                            authName:'未分配酒店列表',
                            path:null,
                            pid:101,
                        }
                    ]

                },
                {
                    id:201,
                    authName:'数据中心',
                    path:null,
                    pid:0,
                    children:[
                        {
                            id:204,
                            authName:'酒店数据统计',
                            path:null,
                            pid:201,
                        },
                        {
                            id:205,
                            authName:'收益统计',
                            path:null,
                            pid:201,
                        },
                        {
                            id:206,
                            authName:'业绩完成统计',
                            path:null,
                            pid:201,
                        }
                    ]

                }
            ],
            data: [{
                id: 1,
                label: '一级 1',
                children: [{
                    id: 4,
                    label: '二级 1-1',
                    children: [{
                        id: 9,
                        label: '三级 1-1-1'
                    }, {
                        id: 10,
                        label: '三级 1-1-2'
                    }]
                }]
            }, {
                id: 2,
                label: '一级 2',
                children: [{
                    id: 5,
                    label: '二级 2-1'
                }, {
                    id: 6,
                    label: '二级 2-2'
                }]
            }, {
                id: 3,
                label: '一级 3',
                children: [{
                    id: 7,
                    label: '二级 3-1'
                }, {
                    id: 8,
                    label: '二级 3-2'
                }]
            }],
            defaultProps: {
                children: 'children',
                label: 'text'
            },
            checkList: [],
            token:'',
            curId:'',
            defaultTreeList:[
                {
                    "id": 1,
                    "parent": "#",
                    "text": "首页",
                    "state": {
                        "selected": false
                    },
                    "ismenu": 1
                },
                {
                    "id": 33,
                    "parent": 1,
                    "text": "首页统计数据",
                    "state": {
                        "selected": true
                    },
                    "ismenu": 0
                },
                {
                    "id": 40,
                    "parent": 1,
                    "text": "获取城市列表",
                    "state": {
                        "selected": true
                    },
                    "ismenu": 1
                }
            ],
            menuList:[],
            allData:[],
            funList:[],
            oneLevelMenuAllId:[],
            secondMenuList:[],
            finalId:'',
            menuTreeDataAllId:[],
            query:{
                id:'',
                val:{},
                type:''
            },
            treeLoading:true
        }),
        methods:{
            getTreeData(pid){
                this.treeLoading=true;
                var param=qs.stringify({
                    token:this.token,
                    pid:pid,
                    id:this.query.id
                });
                this.$http.post(this.global.roletree,param).then(res=>{
                    if(res.data.code===100000){
                       
                        var treeData=res.data.data;
                        this.allData=treeData;
                        var filterTreeData=[];
                        var menuTreeData=[];
                        var secondMenuData=[];
                        var menuTreeDataAllId=[];
                        var selectedAllId=[];
                        var menuSelectedAllId=[];
                        var funSelectedAllId=[];
                        var oneLevelMenuAllId=[];
                        var secondLevelMenuAllId=[];
                           
                        //编辑，转换接收来的数据
                        if(this.query.type==='edit'){
                            selectedAllId=this.query.val.split(',');
                        }
                    
                        treeData.filter((item,index)=>{   //获取全部目录数据
                            if(item.ismenu===1){
                                filterTreeData.push(item);
                            }
                            if(item.ismenu===1 && item.parent==='#'){
                                oneLevelMenuAllId.push(item.id)
                            }
                        });
                        this.oneLevelMenuAllId=oneLevelMenuAllId;
                        this.treeTrans(filterTreeData);   //全部目录数据-转换s为树状
                        treeData.filter((item,index)=>{   //全部目录数据-转换为两级树状
                            if(item.ismenu===1 && item.parent==='#'){
                                menuTreeData.push(item);
                            }
                        });
                        this.menuList=menuTreeData;   //菜单目录
                        //菜单选中目录的全部id
                        menuTreeData.forEach((itemOut,index)=>{
                            if(itemOut.state.selected){
                                menuSelectedAllId.push(itemOut.id);
                            }
                            menuTreeDataAllId.push(itemOut.id);
                            if(itemOut.children.length>0){
                                itemOut.children.forEach((itemSecond,index)=>{  //二级
                                    menuTreeDataAllId.push(itemSecond.id);
                                    if(itemSecond.children.length>0){
                                        itemSecond.children.forEach((itemThird,index)=>{
                                            menuTreeDataAllId.push(itemThird.id);
                                        });
                                    }
                                })
                            }
                        });
                        this.menuTreeDataAllId=menuTreeDataAllId;   //菜单全部id
                        //菜单选中状态的全部id
                        treeData.filter((item,index)=>{   //获取全部二级目录
                            if(item.parent!=='#' && item.ismenu===1){
                                secondMenuData.push(item);
                            }
                        });
                        if(this.query.type==='edit'){
                            var selectedOneLevelMenuId=this.devideIntoId(selectedAllId).selectedOneLevelMenuId;
                            var selectedTwoLevelMenuId=this.devideIntoId(selectedAllId).selectedTwoLevelMenuId;
                            var selectedMinLevelMenuId=this.devideIntoId(selectedAllId).selectedMinLevelMenuId;
                            var selectedFunId=this.devideIntoId(selectedAllId).funId;
                            this.setTreeDefault(selectedMinLevelMenuId,selectedFunId);
                        }
                        
                        this.treeLoading=false;
                    }else{
                        this.treeLoading=false;
                        this.$message.error(res.data.msg);
                    }
                });
            },
            getGroupLevel(){
                return new Promise((resolve,reject)=>{
                    this.$http.get(this.global.groupLevel,{
                        params:{
                            token:this.token
                        }
                    }).then(res=>{
                        if(res.data.code===100000){
                            this.curId=res.data.data.pid[0];
                        }
                        resolve(res.data.data.pid[0]);
                    });
                })
            },
            treeTrans(data,options){
                options = options || {};
                var ID_KEY = options.idKey || 'id';
                var PARENT_KEY = options.parentKey || 'parent';
                var CHILDREN_KEY = options.childrenKey || 'children';

                var tree = [],
                    childrenOf = {};
                var item,
                    id,
                    parentId;

                for (var i = 0, length = data.length; i < length; i++) {
                    item = data[i];
                    id = item[ID_KEY];
                    parentId = item[PARENT_KEY] || 0;
                    // every item may have children
                    childrenOf[id] = childrenOf[id] || [];
                    // init its children
                    item[CHILDREN_KEY] = childrenOf[id];
                    if (parentId != 0) {
                        // init its parent's children object
                        childrenOf[parentId] = childrenOf[parentId] || [];
                        // push it into its parent's children object
                        childrenOf[parentId].push(item);
                    } else {
                        tree.push(item);
                    }
                }
                return tree;
            },
            nodeClick(val,e){
                if(e.checked){
                    this.getFunList(val);
                }
            },
            getFunList(val){
                var funArr=[];
                if(val.children.length===0){
                    this.allData.filter((item,index)=>{
                        if(item.parent===val.id){
                            funArr.push(item);
                        }
                    })
                }
                this.funList=funArr;
                var a=[];
                funArr.forEach((item,index)=>{
                    a.push(item.id);
                });
            },
            nodeChange(val,e){
                if(e){
                    this.getFunList(val);
                }
                if(!e){
                    var filterData=[];
                    this.allData.filter((item,index)=>{
                        if(item.parent===val.id && item.ismenu===0){
                            filterData.push(item);
                        }
                    });
                    filterData.forEach((item,index)=>{
                        var poIndex=this.checkList.indexOf(item.id);
                        if(poIndex!==-1){
                            this.checkList.splice(poIndex,1);
                        }
                    });
                    this.funList=[];  //过滤出所有该菜单下面的功能项，然后数组中剔除
                }
            },
            confirmMeth(type,str){

            },
            submitMeth(formName){
                var test=this.$refs.tree.getCheckedKeys();
                var treeAllNode=[];
                var treeNode=this.$refs.tree.getCheckedNodes();
                treeNode.forEach((item,index)=>{
                    treeAllNode.push(item.id);
                    if(item.parent!=='#'){
                        treeAllNode.push(item.parent);
                        this.allData.forEach(item1=>{
                            if(item1.id==item.parent){
                                if(item1.parent!=='#'){
                                     treeAllNode.push(item1.parent);
                                }
                            }
                        })
                    }
                    if(item.children.length>0){
                        item.children.forEach((itemInner,index)=>{
                            treeAllNode.push(itemInner.id);
                        })
                    }
                });
              
                this.finalId=this.noRepeat(treeAllNode).join(',')+','+this.checkList.join(',');
                var a=this.finalId.substring(0,-1);
                this.finalId = (this.finalId .substring(this.finalId .length - 1) == ',')
                    ? this.finalId .substring(0, this.finalId .length - 1) :
                    this.finalId ;
                this.$refs[formName].validate((valid) => {
                    if(valid && this.finalId!==''){
                        this.$confirm('确定保存？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            if(this.query.type==='edit' ){
                                this.editMeth();
                            }else{
                                this.createMeth()
                            }
                        }).catch(()=>{

                        })
                    }else{
                        this.$message.warning('请完善信息');
                    }
                });
            },
            editMeth(){
                var param=qs.stringify({
                    id:this.query.id,
                    rules:this.finalId,
                    pid:this.curId,
                    name:this.form.name,
                    status:'normal',
                });
                this.$http.post(this.global.groupEdit,param,{headers:{token:this.token}}).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('编辑成功');
                        this.$router.push({path:'./sysRoleMan'});
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{

                })
            },
            createMeth(){
                var param=qs.stringify({
                    rules:this.finalId,
                    pid:this.curId,
                    name:this.form.name.trim(),
                    status:'normal',
                });
                this.$http.post(this.global.groupAdd,param,{headers:{token:this.token}}).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('创建成功');
                        this.$router.push({path:'./sysRoleMan'});
                    }else if(res.data.code===0){
                        this.$message.error('name只能是汉字、字母和数字');
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).catch(()=>{

                })
            },
            checkChange(val){

            },
            setTreeDefault(treeData,funData){
                this.$refs.tree.setCheckedKeys(treeData);
                var a=this.$refs.tree.getCheckedKeys();
                this.checkList=funData.map(Number);
            },
            noRepeat(arr){
                return Array.from(new Set(arr))
            },
            devideIntoId(selectedAllId){
                var menuId=[];
                var selectedOneLevelMenuId=[];
                var selectedTwoLevelMenuId=[];
                var selectedMinLevelMenuId=[];
                var funId=[];
                var obj={};
                selectedAllId.forEach((itemOut,index)=>{
                    if(this.menuTreeDataAllId.indexOf(parseInt(itemOut))!==-1){
                        menuId.push(itemOut);
                    }else{
                        funId.push(itemOut);
                    }
                });
                
                menuId=this.noRepeat(menuId.map(Number));
                funId=this.noRepeat(funId.map(Number));
                menuId.forEach((itemOut,index)=>{
                    this.oneLevelMenuAllId.forEach((itemInner,index)=>{
                        if(itemOut===itemInner){
                            selectedOneLevelMenuId.push(itemOut);
                        }
                    })
                });
                menuId.forEach((itemOut,index)=>{
                    if(selectedOneLevelMenuId.indexOf(itemOut)===-1){
                        selectedTwoLevelMenuId.push(itemOut)
                    }
                });
                obj.menuId=menuId;
                obj.selectedOneLevelMenuId=this.noRepeat(selectedOneLevelMenuId);
                obj.selectedTwoLevelMenuId=this.noRepeat(selectedTwoLevelMenuId);
                this.allData.filter((itemOut,index)=>{
                    obj.selectedTwoLevelMenuId.forEach((itemInner,index)=>{
                        if(itemOut.id==itemInner && itemOut.isMinMenu){
                            selectedMinLevelMenuId.push(itemInner);
                        }
                    })
                });
                obj.selectedMinLevelMenuId=this.noRepeat(selectedMinLevelMenuId);
                if(selectedOneLevelMenuId.indexOf(1)!==-1){
                    obj.selectedMinLevelMenuId.push(1);
                }
                obj.funId=funId;
                return obj;
            },
            isEmptyObj(obj){
                for (let i in Object.keys(obj)) {
                    return false // 进入循环即不为空
                }
                return true
            },
            backMeth(){
                this.$router.push({path:'./sysRoleMan'})
            },
        },
        mounted(){
            var query=this.$route.query;
            if(!this.isEmptyObj(query)){
                var index=query.name.lastIndexOf(';');
                // this.form.name=query.name.substring(index+2);
                this.form.name=query.name;
                this.query.id=query.id ;
                this.query.val=query.val ;
                this.query.type=query.type;
            }
            this.token=sessionStorage.getItem('token');
            this.getGroupLevel().then(res=>{
                this.getTreeData(res);
            });
        }
    }
</script>

<style scoped lang="scss">
    .rightBtn{
        margin-left:auto;
    }
    .outBox{
        border: 1px solid #ebeef5;
        border-radius: 4px;
    }
    .treeRowBox{
        min-height:500px;
        margin-bottom:20px;
    }
    .treeRowBox .colBox{
        height:500px;
    }
    .treeRowBox .outBox{
        display:flex;
        flex-direction: column;
        height:100%;
    }
    .outBox .title{
        height: 40px;
        line-height: 40px;
        background: #f5f7fa;
        padding-left: 15px;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
        color: #000;
    }
    .outBox .con{
        padding:15px;
        flex:1;
        overflow:auto;
    }
    .checkBox .el-checkbox{
        width:80px;
        margin-bottom:10px;
    }
    .marginTop{
        margin-top:20px;
    }
    .flexBox{
        display:flex;
    }

</style>
