<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="bread">
                <span slot="second">系统设置</span>
                <span slot="third">系统配置</span>
                <!--<span slot="fourth" v-if="query.id==='' || !query.id" >新建</span>-->
                <span slot="fourth">详情</span>
            </top-bread>
        </div>

        <el-card class="box-card">
            <el-row class="btnRow">
                <el-button type="primary" @click="backMeth" class="largeBtn ">返回</el-button>
            </el-row>
            <el-row :gutter="20">
                <!--<el-col :span="15">-->
                    <div class="grid-content bg-purple">
                        <el-form  label-width="200px" :model="form" :rules="rules" ref="form" class="form">
                            <!--<el-form-item :label="showText()" prop="calculate_max_value">-->
                            <div class="formBox">
                                <div class="tips" v-if="query.name==='默认上线费用'">默认上线费用单价为</div>
                                <div class="tips" v-if="query.name==='默认市场费用'">默认市场费用单价为</div>
                                <div class="tips" v-if="query.name==='所属合作伙伴默认分成比例'">所属合作伙伴默认分成比例</div>
                                <div class="tips" v-if="query.name==='广告刊期审核自动确认时间'">广告刊期审核自动确认时间</div>
                                <div class="tips" v-if="query.name==='影视收益可提现最低金额'">影视收益可提现最低金额</div>
                                <div class="tips" v-if="query.name==='独立影视收益可提现最低金额'">独立影视收益可提现最低金额</div>
                                <div class="tips" v-if="query.name==='优客可提现最低金额'">优客可提现最低金额</div>
                                <el-form-item v-if="minInputShow" :label="showText()" prop="calculate_max_value" class="elFormItemNoMarginLeft elFormItemNoMarginBottom formItem">
                                    <el-input  v-if="minInputShow"  v-model="form.calculate_min_value"  placeholder="">
                                        <template slot="append">{{firstInputUnit}}</template>
                                    </el-input>
                                    <span class="tips1" v-if="minInputShow"  >最高</span>
                                </el-form-item>
                                <el-form-item :label="showText()" prop="calculate_max_value" class="elFormItemNoMarginLeft elFormItemNoMarginBottom formItem">
                                    <span class="tips1" v-if="showSecondInput2">建站</span>
                                    <el-input  v-model="form.calculate_max_value"  placeholder="">
                                        <template slot="append">{{firstInputUnit}}</template>
                                    </el-input>
                                </el-form-item>
                                <div  class="tips" v-if="showSecondInput">内，任意有</div>
                                <el-form-item  v-if="showSecondInput" class="elFormItemNoMarginLeft elFormItemNoMarginBottom formItem">
                                    <el-input v-model="form.settlement_value"  placeholder="任意天数有心跳终端">
                                        <template slot="append">天</template>
                                    </el-input>
                                </el-form-item>
                                  <div class="tips" v-if="showSecondInput2">内，上线终端数不足</div>
                                <el-form-item  v-if="showSecondInput2" class="elFormItemNoMarginLeft elFormItemNoMarginBottom formItem">
                                    <el-input v-model="form.settlement_value"  placeholder="任意天数有心跳终端">
                                        <template slot="append">间</template>
                                    </el-input>
                                </el-form-item>
                                <div class="tips" v-if="showSecondInput2">，系统将自动删除该站点。</div>
                            </div>
                            <el-button type="primary" class="largeBtn submitBtn" @click="submitForm('form')">保存</el-button>
                        </el-form>
                        <!--<el-form  label-width="200px" :model="form" :rules="rules" ref="form">-->
                            <!--<el-form-item :label="showText()" prop="calculate_max_value">-->
                                <!--<el-input v-model="form.calculate_max_value"  placeholder="">-->
                                    <!--<template slot="append">{{firstInputUnit}}</template>-->
                                <!--</el-input>-->
                            <!--</el-form-item>-->
                            <!--<el-form-item label="任意天数有心跳终端" v-if="showSecondInput">-->
                                <!--<el-input v-model="form.settlement_value"  placeholder="任意天数有心跳终端">-->
                                    <!--<template slot="append">天</template>-->
                                <!--</el-input>-->
                            <!--</el-form-item>-->
                            <!--<el-form-item size="large" >-->
                                <!--<el-button type="primary" class="largeBtn" @click="submitForm('form')">保存</el-button>-->
                            <!--</el-form-item>-->
                        <!--</el-form>-->
                    </div>
                <!--</el-col>-->
                <!--<el-col :span="9">-->
                    <!--<div class="grid-content bg-purple"></div>-->
                <!--</el-col>-->
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "Custom",
        props:['id'],
        data: () => ({
            bread:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/sysConfig'},
            },
            form:{
                id:'',
                group:1,
                calculate_min_value:0,
                calculate_max_value:'',
                settlement_value:'',
                settlement_type:1,  //配置项结算类型1数值2百分比
            },
            input1: '',
            input2: '',
            input3: '',
            input4: '',
            rules:{
                settlement_value: [
                    { required: true, message: '请输入配置项结算值', trigger: 'blur' }
                ],
            },
            query:{
                id:''
            },
            showSecondInput:true,
            showSecondInput2:true,
            minInputShow:false,
            firstInputUnit:'',
        }),

        methods:{
            // getDetail(){
            //     this.$http.get(this.global.caseView,{
            //         params:{
            //             token:this.token,
            //             id:this.id,
            //         }
            //     }).then(res=>{
            //         if(res.data.code===100000){

            //         }
            //     });
            // },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定提交?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.editMeth();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editMeth(){
                this.form.id=this.query.id;
                var param=qs.stringify(this.form);
                this.$http.post(this.global.editActiveConfig,param,{
                    headers:{
                        token:this.token
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('保存成功');
                        this.$router.push({path:'/sysConfig'})
                    }else{
                        this.$message.error('保存失败');
                    }
                }).catch(()=>{
                    this.$message.error('返回错误');
                })
            },
            backMeth(){
                this.$router.push({path:'./sysConfig'})
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            var query=this.$route.query;
            console.log(query);
            if(query){
                this.query.id=query.id;
                this.query.name=query.name;
                this.form.calculate_max_value=query.calculate_max_value;
                this.form.calculate_min_value=query.calculate_min_value;
                this.form.settlement_value=query.settlement_value;
                // this.getDetail();
            }
               
        },
        computed:{
            showText(){
                return function(val){
                    var str='';
                    var query=this.query;
                 
                    if(query.name==='有效激活终端标准设置'){
                        this.showSecondInput=true;
                         this.showSecondInput2=false;
                        this.firstInputUnit='天';
                        // str= '天数范围'
                        str= ''
                    }else if (query.name==='审批默认同意时间标准设置'){
                        this.showSecondInput=false;
                          this.showSecondInput2=false;
                        this.firstInputUnit='天';
                        // str= '天数范围'
                        str= ''
                    }else if (query.name==='默认市场费用' || query.name==='默认上线费用'){
                        this.showSecondInput=false;
                          this.showSecondInput2=false;
                        this.firstInputUnit='元 / 台';
                        // str= '价格'
                        str= ''
                    }else if (query.name==='自动删除酒店规则设置'){
                        this.showSecondInput=false;
                          this.showSecondInput2=true;
                        this.firstInputUnit='天';
                        // str= '价格'
                        str= ''
                    }else if (query.name==='所属合作伙伴默认分成比例'){
                        this.showSecondInput=false;
                          this.showSecondInput2=false;
                        this.firstInputUnit='%';
                        // str= '价格'
                        str= ''
                    }else if (query.name==='广告刊期审核自动确认时间'){
                        this.showSecondInput=false;
                          this.showSecondInput2=false;
                        this.firstInputUnit='天';
                        // str= '价格'
                        str= ''
                    }else if (query.name==='影视收益可提现最低金额'||query.name==='独立影视收益可提现最低金额'||query.name==='优客可提现最低金额'){
                        this.showSecondInput=false;
                          this.showSecondInput2=false;
                          this.minInputShow = true;
                        this.firstInputUnit='元';
                        // str= '价格'
                        str= ''
                    }else{
                        this.showSecondInput=false;
                          this.showSecondInput2=true;
                        this.firstInputUnit='天';
                        // str= '天数范围'
                        str= ''
                    }
                    return str;
                }
            }
        }

    }
</script>

<style scoped lang="scss">
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        /*background: #99a9bf;*/
    }
    .bg-purple {
        /*background: #d3dce6;*/
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .fullSelect{
        width:100%;
    }
    .disabled .el-upload--picture-card {
        display: none!important;
    }
    .radioBox{
        margin-top:12px;
    }
    .rowBox{
        display:flex;
        align-items: center;
    }
    .formBox{
        display:flex;
    }
    .tips{
        padding:0 10px;
        display:flex;
        align-items: center;
        font-size:14px;
    }
    .tips1{
        width: 50px;
          padding:0 10px;
        display:flex;
        align-items: center;
        font-size:14px;
    }
    .formItem{
        width:200px;
    }
    .submitBtn{
        margin-top:20px;
        margin-left:10px;
    }

</style>
