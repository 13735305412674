<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="apk配置" name="first">
        <ApkList :query="query"></ApkList>
      </el-tab-pane>
      <el-tab-pane label="服务配置" name="second">
        <ServiceConfig :query="query"></ServiceConfig>
      </el-tab-pane>
      <el-tab-pane label="下载配置" name="third">
        <DownloadConfig :query="query"></DownloadConfig>
      </el-tab-pane>
      <el-tab-pane label="电视列表" name="fourth">
        <TvList :query="query"></TvList>
      </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import ApkList from "./ApkList.vue";
import ServiceConfig from "./ServiceConfig.vue";
import DownloadConfig from "./DownloadConfig.vue";
import TvList from "./TvList.vue";
export default {
  props: {
    database: {
      type: String,
      default: 'hr800042'
    },
    query: {
      type: Object,
      default: ()=> {
        return {}
      }
    }
  },
  components: {
    ApkList,
    ServiceConfig,
    DownloadConfig,
    TvList
  },
  data () {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick() {}
  }
}
</script>

<style>

</style>