<template>
  <div>
    <div class="btnRow">
      <el-button type="primary" class="largeBtn" @click="dialogVisible = true">添 加</el-button>
      <el-button type="danger" class="largeBtn" @click="delApk()">批量删除</el-button>
      <el-button type="" class="largeBtn" @click="$refs.ApkBatchDialog.open(query.hotelName,apkPlatform,query)">批量管理</el-button>
    </div>
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <div class="models flexBox">
      <div class="models-item" v-for="(item,index) in roomManagementList" :key="index">
        <el-button class="btn" type="primary" size="small" @click="openRoomIofo(item.platform)">{{item.platform}}</el-button>
        <span>={{item.num}}间;</span>
      </div>
    </div>
    <el-dialog :title="ruleForm.id ? '编辑apk': '新增apk'" :visible.sync="dialogVisible" width="800px" :before-close="handleClose" append-to-body>
      <div class="container">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="版本号" prop="eapk_code">
            <el-input disabled v-model="ruleForm.eapk_code"></el-input>
          </el-form-item>
          <el-form-item label="apk">
            <UploadApk :database="ruleForm.database" :apkAddr="ruleForm.apkAddr" @uploadApkInfo="uploadApkInfo"></UploadApk>
          </el-form-item>
          <el-form-item v-if="ruleForm.apkAddr" label="apk地址" prop="apkAddr">
            <div>{{ruleForm.apkAddr}}</div>
          </el-form-item>
          <el-form-item v-if="ruleForm.apkMd5" label="apkMd5值" prop="apkMd5">
            <div>{{ruleForm.apkMd5}}</div>
          </el-form-item>
          <el-form-item label="包名" prop="eapk_package">
            <div>{{ruleForm.eapk_package || '--'}}</div>
            <!-- <el-input v-model="ruleForm.eapk_package"></el-input> -->
          </el-form-item>
          <el-form-item label="DeviceName" prop="eapk_platform">
            <el-select v-model="ruleForm.eapk_platform" :disabled="ruleForm.id!=''" placeholder="请选择" filterable>
              <el-option label="通用" value="0">
              </el-option>
              <el-option v-for="(item,index) in apkPlatform" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
    <ApkBatchDialog ref="ApkBatchDialog" @getlist='getApkList'></ApkBatchDialog>
  </div>
</template>
<script>
import {
  apkList,
  getApkPlatform,
  editupdateAPK,
  delallAPK,
  getDeviceName,
} from "../api/application/apkList";
import { roomManagement, getRoomByPlatform } from "@/api/allTab/Online";
import UploadApk from "./components/uploadApk.vue";
import ApkBatchDialog from "./components/ApkBatchDialog.vue";
export default {
  props: {
    query: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "ApkList",
  components: {
    UploadApk,
    ApkBatchDialog,
  },
  data() {
    return {
      roomManagementList: [],
      tableData: {
        tableList: [],
        checked: true,
        tableLoading: false,
        props: [
          {
            label: "版本号",
            prop: "apk_code",
            minWidth: "80",
            type: "text",
          },
          {
            label: "apk",
            prop: "apk_name",
            minWidth: "650",
            type: "text",
          },
          {
            label: "包名",
            prop: "apk_package",
            minWidth: "300",
            type: "text",
          },
          {
            label: "DeviceName",
            prop: "platform",
            minWidth: "300",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "50",
            type: "button",
            prop: "num",
            edit: (item) => {
              // setTimeout(() => {
              this.ruleForm = {
                database: this.query.hotelName,
                id: item.id,
                eapk_code: item.apk_code, //版本号
                apkAddr: "", // apk地址
                eapk_package: item.apk_package, // 包名
                eapk_platform: String(item.platform), // DeviceName
                apkMd5: "",
              };
              this.dialogVisible = true;
            },
          },
        ],
      },
      listQuery: {
        database: this.query.hotelName,
        cur_page: 1,
        size: 10,
      },
      checkList: [],
      apkPlatform: [],
      dialogVisible: false,
      ruleForm: {
        database: this.query.hotelName,
        id: "",
        eapk_code: "", //版本号
        apkAddr: "", // apk地址
        eapk_package: "", // 包名
        eapk_platform: "", // DeviceName
        apkMd5: "",
      },
      rules: {
        eapk_code: [
          { required: true, message: "版本号不能为空", trigger: "blur" },
        ],
        apkAddr: [{ required: true, message: "上传apk", trigger: "blur" }],
        eapk_platform: [
          { required: true, message: "请选择设备", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    openRoomIofo(platform) {
      let params = {
        database: this.query.hotelName,
        platform: platform,
      };
      getRoomByPlatform(params).then((res) => {
        if (res.data.code == 100000) {
          console.log(res);
          this.$alert(res.data.data.room_str, "房间信息", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
        } else {
          this.$message.warning("房间信息获取失败");
        }
      });
    },
    getRoomManagement() {
      let params = {
        database: this.query.hotelName,
      };
      roomManagement(params).then((res) => {
        if (res.data.code == 100000) {
          this.roomManagementList = res.data.data;
        }
      });
    },
    changeSize(s, p) {
      this.listQuery.cur_page = p;
      this.listQuery.size = s;
      this.getApkList();
    },
    changeCurrent(s, p) {
      this.listQuery.cur_page = p;
      this.listQuery.size = s;
      this.getApkList();
    },
    changeSelection(val) {
      this.checkList = val.map((v) => {
        return v.id;
      });
    },
    getApkList(page) {
      if (page) {
        this.listQuery.cur_page = page;
        this.$refs.operationTable.resetTable();
      }
      this.tableData.tableLoading = true;
      apkList(this.listQuery).then((res) => {
        if (res.data.code == 100000) {
          this.tableData.tableList = res.data.data.row || [];
          this.$refs.operationTable.changeTotal(Number(res.data.data.totalNum));
          this.tableData.tableLoading = false;
        }
        this.tableData.tableLoading = false;
      });
    },
    delApk() {
      if (this.checkList.length <= 0) {
        return this.$message.error("请选择apk");
      }
      this.$confirm("此操作将永久删除apk, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delallAPK({
            ids: `${this.checkList.join()}`,
            database: this.listQuery.database,
          }).then((res) => {
            if (res.data.code === 100000) {
              this.getApkList(1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message.error(es.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleClose() {
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          this.ruleForm[key] = "";
        }
      }
      this.dialogVisible = false;
    },
    uploadApkInfo(apk) {
      this.ruleForm = {
        ...this.ruleForm,
        ...apk,
      };
    },

    getApkPlatform() {
      getDeviceName({
        database: this.listQuery.database,
      }).then((data) => {
        console.log(data, "dwadawaawa");
        if (data.data.code == 100000) {
          this.apkPlatform = data.data.data.filter((i) => {
            if (i.platform != 0) {
              return i;
            }
          });
        } else {
          this.apkPlatform = [];
        }
      });
    },
    submitForm(formName) {
      if (!this.ruleForm.id && this.ruleForm.apkAddr == "") {
        this.$message.warning("请上传apk配置");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.database = this.query.hotelName;
          editupdateAPK(this.ruleForm).then((res) => {
            if (res.data.code === 100000) {
              let tips = this.ruleForm.id ? "编辑" : "新增";
              this.$message({
                showClose: true,
                message: `${tips}成功`,
                type: "success",
              });
              this.dialogVisible = false;

              this.getApkList();
              for (const key in this.ruleForm) {
                if (Object.hasOwnProperty.call(this.ruleForm, key)) {
                  this.ruleForm[key] = "";
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    this.getRoomManagement();
    this.getApkList();
    this.getApkPlatform();
    this.tableData.tableList = this.tableData.tableList.map((i) => {
      if (i.ltype == 1) {
        i.language = "中文";
      } else if (i.ltype == 2) {
        i.language = "英文";
      } else if (i.ltype == 3) {
        i.language = "日文";
      } else if (i.ltype == 4) {
        i.language = "韩文";
      }
      return i;
    });
  },
  watch: {
    query: {
      handler: function (newVal) {
        this.query = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.flexBox {
  display: flex;
  flex-wrap: wrap;
}
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.models {
  .models-item {
    padding: 0 20px 20px 0;
    .btn {
      margin-right: 10px;
    }
  }
  padding: 10px;
}
</style>
