<!-- 包名添加/修改弹窗 -->
<template>
  <el-dialog title="包名管理" :visible.sync="dialogVisible" width="550px" @open="open" @closed="handleClose">
    <el-form :model="form" ref="form" label-width="100px">
      <el-form-item label="包名：" prop="package_name">
        <el-input v-model="form.package_name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="所属类型：" prop="type_id">
        <el-select v-model="form.type_id" placeholder="" style="width:100%">
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属属性：" prop="attr">
        <el-select v-model="form.attr" placeholder="" multiple style="width:100%" clearable>
          <el-option v-for="item in attrList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否通用：" prop="is_common">
        <div>
          <el-radio v-model="form.is_common" label="1">是</el-radio>
          <el-radio v-model="form.is_common" label="0">否</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="备注：" prop="remarks">
        <el-input v-model="form.remarks" placeholder="" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="排序：" prop="morder">
        <el-input v-model="form.morder" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { add, getAttr, getType, edit } from "../api/packageName";
export default {
  name: "packageNameOper",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      auth: null,
      dialogVisible: this.visible,
      attrList: [],
      typeList: [],
      form: {
        package_name: "",
        type_id: "",
        attr: [],
        is_common: "1",
        remarks: "",
        morder: "",
      },
    };
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        // 编辑回显
        if (JSON.stringify(this.current) != "{}") {
          let current = { ...this.current };
          current.attr = this.current.attr.split(",");
          current.package_name = this.current.name;
          let { package_name, type_id, attr, is_common, remarks, morder } =
            current;
          this.form = {
            package_name: package_name,
            type_id: type_id,
            attr: attr,
            is_common: is_common,
            remarks: remarks,
            morder: morder,
          };
        }
      });
    },
    init() {
      this.getAttr();
      this.getType();
    },
    submit() {
      let params = { ...this.form };
      params.attr = this.form.attr.join(",");
      params.user_name = this.auth.user_name;
      // 新增
      if (JSON.stringify(this.current) == "{}") {
        add(params).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.dialogVisible = false;
            this.$emit("refresh");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
      // 编辑
      else {
        params.id = this.current.id;
        edit(params).then((data) => {
          if (data.data.code == 100000) {
            this.$message.success(data.data.msg);
            this.dialogVisible = false;
            this.$emit("refresh");
          } else {
            this.$message.error(data.data.msg);
          }
        });
      }
    },
    getAttr() {
      this.attrList = [];
      getAttr().then((data) => {
        for (let key in data.data.data) {
          this.attrList.push({
            label: data.data.data[key],
            value: key,
          });
        }
      });
    },
    getType() {
      getType().then((data) => {
        this.typeList = [];
        for (let key in data.data.data) {
          this.typeList.push({
            label: data.data.data[key],
            value: key,
          });
        }
      });
    },
    handleClose() {
      // this.$refs.form.resetFields();
    },
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.dialogVisible = val;
    },
  },
  mounted() {
    this.init();
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>
<style lang="scss" scoped></style>
