<template>
    <div class="">
        <div class="box" @click="showSearchMeth">
            <span class="foldBtn">{{fold.foldText}}</span>
            <i class=" showIcon"  :class="fold.iconClass"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FoldTopSearch",
        props:{
            type:{
                default:''
            },
            foldState:{}
        },
        data: () => ({
            fold:{
                showSearch:false,
                foldText:'显示筛选',
                iconClass:'el-icon-arrow-down'
            },
        }),
        methods:{
            showSearchMeth(){
                var foldData=this.fold;
                if(foldData.showSearch){
                    foldData.showSearch=!foldData.showSearch;
                    foldData.iconClass='el-icon-arrow-down';
                    foldData.foldText='显示筛选';

                }else{
                    foldData.showSearch=!foldData.showSearch;
                    foldData.iconClass='el-icon-arrow-up';
                    foldData.foldText='收起筛选';
                }
                this.$emit('fold',this.fold);
            }
        },
        mounted(){
            if(this.foldState==='展开'){
                this.fold={
                    showSearch:true,
                    foldText:'隐藏筛选',
                    iconClass:'el-icon-arrow-up'
                }
            }
        }
    }
</script>

<style scoped>
    .box{
        display:flex;
        align-items: center;
        padding:10px 10px;
        cursor:pointer;
        border:1px solid #3085fc;
        border-radius:4px;
        transition:0.5s;
    }
    .box:hover .foldBtn,.box:hover i{
        color:#3085fc;
        transition:0.5s;
    }
    .foldBtn{
        font-size:14px;
        color:#606266;
    }
    .showIcon{
        font-size:16px;
        margin-left:6px;
    }
    .box{
        float:right;
    }
</style>
