<template>

  <div :class="['box',showData?'':'box1']" :style="{zIndex:style.zIndex?style.zIndex:zIndex,width:style.width+'px',height:style.height+'px',top:style.top+'px',left:style.left+'px',bottom:style.bottom+'px',right:style.right+'px',}">
    <div class="borderBox"></div>
    <visiualButton class="hide" :msg="content.name" v-if="!content.nochange&&!isEdit" @openuppage='openUppage' @recoverydata="recoverydata" @openmore='openParamUppage' btnShow='picone'></visiualButton>
    <uploadSource ref="uploadSource" sourceType="pic" :size='content.sourceDesc?content.sourceDesc:""' @upsuccess='upSuccess'></uploadSource>
    <img v-if="showData" :src="showData.source_url" alt="" :style="is_logo? {maxWidth: '100%', maxHeight: '100%'}: {width: '100%', height: '100%'}">
    <el-dialog title="编辑参数" class="info" :visible.sync="infoVisible" width="40%" :append-to-body="true" :before-close='cannel'>
      <el-form ref="form" :model="form"  class="formBox">
        <el-form-item label="跳转地址" prop='SSID'>
          <el-input :readonly="isEdit" v-model="form.bljlm"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!isEdit">
        <el-button class="largeBtn" type="primary" @click="save('form')">保 存</el-button>
        <el-button class="cancelBtn" @click="cannel">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import visiualButton from "../common/editBtn.vue";
import uploadSource from "../common/uploadSource.vue";
export default {
  name: "picBox",
  components: {
    visiualButton,
    uploadSource,
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    historyData: {
      type: Object,
      default: () => {},
    },
    is_logo: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 4,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    boxStyle: {
      handler: function (newVal) {
        this.content = JSON.parse(JSON.stringify(newVal));
        let obj = JSON.parse(JSON.stringify(this.content.position));
        for (var i in obj) {
          this.style[i] = obj[i];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      form: {
        bljlm: "",
      },
      style: {},
      content: {},
      infoVisible:false,
      showData: this.value,
    };
  },
  mounted() {
    this.form = {
      bljlm: this.showData && this.showData.bljlm ? this.showData.bljlm : "",
    };
  },
  methods: {
    openParamUppage() {
      this.infoVisible = true;
    },
    save() {
      this.$refs["form"].validate((valid, obj) => {
        if (valid) {
          this.showData.bljlm = this.form.bljlm;
          this.showData.update = true;
          this.infoVisible = false;
          this.$emit("change", this.showData);
          this.$emit("canrelease");
        } else {
          this.$message.warning("请将表单信息补充完整");
        }
      });
    },
    cannel() {
      this.form.bljlm = this.showData.bljlm;
      this.showData.update = false;
      this.infoVisible = false;
    },
    upSuccess(data) {
      this.showData.source_md5 = data.md5;
      this.showData.source_url = data.url;
      this.showData.update = true;
      this.$emit("change", this.showData);
      this.$emit("canrelease");
      // showData:this.value,
    },
    recoverydata() {
      this.showData = JSON.parse(JSON.stringify(this.historyData));
      this.$emit("change", this.showData);
    },
    openUppage() {
      this.$refs.uploadSource.dialogVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  position: absolute;
  // background: rgba($color: #efefef, $alpha: 0.5);
  transition: 0.1s;
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .borderBox {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
// .box:hover {
//   z-index: 5;
// }
.box1 {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.5);
}
.box:hover .borderBox {
  border: 2px dashed #2e63a6;
  box-shadow: 0 0 5px 3px rgba($color: #2e63a6, $alpha: 0.9);
}
.box:hover .hide {
  display: flex;
  transition: 0.3s;
}

.hide {
  display: none;
}
</style>