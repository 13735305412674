<template>
    <div class="root" :class="[$attrs.onlyShowModule?'onlyShowModule':'']">
        <div class='imgBox borderBox'
             :class="[modelShowBtnName==='modelImg'?'active':'']">
            <img :src="jumpUrl.picurl"  alt="">
            <div class="configBox modelImg highIndex animate__animated" style="z-index:102!important;">
                <div class="slideBox animate__animated" v-if="modelImgData.configShow">
                    <div class="btnGroup">
                        <div class="btnGroupItem">
                            <div class="btnItem" @click.stop="uploadMaterial('modelImg','img')">上传图片</div>
                        </div>
                        <div class="btnGroupItem">
                            <div class="btnItem" @click="resetItemMeth('modelImg')">恢复</div>
                        </div>
                    </div>
                </div>
                <div class="btnItem highIndex configBtn" @click.stop="slideConfigBox('modelImg')" >配置</div>
            </div>
        </div>
        <upload-dialog
                :dialogParData="dialogUpload"
                @uploadMaterialUrl="getUploadMaterialUrl" @changeDialogVisible="changeDialogVisible" @editTabLoadingMeth="editTabLoadingMeth"
                :hotelContract="hotelContract"></upload-dialog>
    </div>
</template>

<script>
    export default {
        name: "EditEelDetail",
        props:['hotelContract','jumpUrlProp','onlyShow','hotelTemplateType','modelReadOnly'],
        data:()=>({
            modelImgData:{
                configShow:false,
                moreType:'',
                moreDetailShow:false,
                arrowDirection:'up'
            },
            jumpUrl:{
                picurl:'',
                md5:'',
                configShow:false,
            },
            defaultData:{},
            modelShowBtnName:'',
            dialogUpload:{
                visible:false,
                agreenActive:'',
                chooseItemIndex:'',
                uploadModel:'',
                uploadMaterialType:'',
                uploadMaterialUrl:''
            },
            shadeShow:false,
            uploadAccept:'',
            uploadAcceptImg:'.jpg,.jpeg,.png,JPG,.JPEG',
            uploadAcceptVideo:'.mp4',
            textAlign:'center',
            curFocusTextBox:'',
            query:{},
        }),
        methods:{
            slideConfigBox(type){
                this.modelImgData.configShow=!this.modelImgData.configShow;
            },
            clearShowBtnName(){
                this.shadeShow=false;
                this.modelImgData.configShow=false;
            },
            uploadMaterial(modelType,materialType){
                this.modelImgData.moreType=materialType;
                var dialogUpload=this.dialogUpload;
                dialogUpload.uploadModel=modelType;
                dialogUpload.uploadMaterialType=materialType;
                dialogUpload.visible=true;
            },
            resetDialog(){
                var dialogUpload=this.dialogUpload;
                dialogUpload.chooseItemIndex='';
                dialogUpload.uploadModel='';
                dialogUpload.uploadMaterialType='';
                dialogUpload.uploadMaterialUrl='';
            },
            getUploadMaterialUrl(url,fileName,md5){
                this.jumpUrl.picurl=url;
                this.jumpUrl.fileName=fileName;
                this.jumpUrl.md5=md5;
                this.dialogUpload.visible=false;
            },
            hideShade(){
                this.shadeShow=false;
                this.modelImgData.configShow=false;
            },
            cancelMeth(){
                this.dialogUpload.visible=false;
            },
            clearFileName(){
                this.jumpUrl.fileName='';
            },
            editTabLoadingMeth(val,type){
                this.$emit('editTabLoadingMeth', val,type);
            },
            changeDialogVisible(val){
                this.dialogUpload.visible=val;
            },
            showShadeMeth(){
                this.shadeShow=true;
            },
            resetItemMeth(){
                console.log('默认数据',this.defaultData);
                this.jumpUrl.picurl=this.defaultData.picurl;
                this.jumpUrl.md5=this.defaultData.md5;
            },
            getData(){
                console.log(this.hotelContract);
                var jumpUrlProp=this.jumpUrlProp;
                var defaultData=this.defaultData;
                defaultData.md5=jumpUrlProp.md5;
                defaultData.order=jumpUrlProp.order;
                defaultData.picurl=jumpUrlProp.picurl;
                defaultData.title=jumpUrlProp.title;
            },
            defaultDataMeth(){
                var jumpUrl=this.jumpUrl;
                var defaultData=this.defaultData;
                jumpUrl.picurl=defaultData.picurl;
                jumpUrl.order=defaultData.order;
                jumpUrl.title=defaultData.title;
            },
            mergeData(){
                var jumpUrlMerge={};
                var jumpUrl=this.jumpUrl;
                var defaultData=this.defaultData;
                jumpUrlMerge.picurl=jumpUrl.picurl;
                jumpUrlMerge.md5=jumpUrl.md5;
                jumpUrlMerge.order=defaultData.order;
                jumpUrlMerge.title=defaultData.title;
                return jumpUrlMerge;
            },

        },
        mounted(){
            this.getData();
            this.query=this.$route.query;
            this.defaultDataMeth();
        },
        watch:{
             hotelContract:{
                  handler: function (newVal) {
                          this.hotelContract=newVal
                        
                },
                deep: true,
                immediate: true, 
            },
             jumpUrlProp:{
                 handler: function (newVal) {
                          this.welPageProp=newVal
                        this.getData();
                        this.defaultDataMeth();
                },
                deep: true,
                immediate: true, 
            },
             onlyShow:{
                 handler: function (newVal) {
                          this.onlyShow=newVal
                },
                deep: true,
                immediate: true, 
            },
             hotelTemplateType:{
                 handler: function (newVal) {
                          this.hotelTemplateType=newVal
                },
                deep: true,
                immediate: true, 
            },
             modelReadOnly:{
                 handler: function (newVal) {
                          this.modelReadOnly=newVal
                },
                deep: true,
                immediate: true, 
            },
            activeName(newVal){
                this.$nextTick(()=>{
                    this.$refs[`${newVal}Chart`].resize();
                })
            },
            jumpUrlProp(newVal){
                this.jumpUrl=newVal;
            }
        }
    }
</script>

<style scoped>
    @import "../../../../../node_modules/animate.css/animate.css";
    .root{

    }
    .imgBox {
        /*width: 80%;*/
        /*height: 596px;*/
        /*overflow: hidden;*/
        width:1152px;
        height:648px;
        position: relative;
        
    }
    .imgBox img,.imgBox video {
        width: 100%;
        height: 100%;
    }
    .textBox {
        width: 100%;
        height: 140px;
        padding: 0 30px;
        display: flex;
        /*align-items: space-around;*/
        position: absolute;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        transition: 0.5s;
        z-index:5;
    }
    .textBox.test{
        width:100px;
        height:100px;
        background:red!important;
        position:absolute;
        top:0!important;
        right:0!important;
    }
    .textBox.whiteBorder{
        border: 2px dashed #fff;
    }
    .textBox.noBorder {
        border: none;
    }
    .textBox .firstLine {
        margin-bottom: 20px;
    }

    .textBox .textLine {
        width: 100%;
        color: white;
        font-size: 18px;
        letter-spacing: 2px;
        transition: 0.5s;
        outline:none;
    }

    /* 背景色 */
    .textBox.coTrans {
        background: none!important;
        transition: 0.5s;
    }

    .textBox.coBlack {
        background: rgba(0, 0, 0, 0.6);
        transition: 0.5s;
    }

    .textBox.coWhite {
        background: rgba(0, 0, 0, 0.4);
        transition: 0.5s;
    }

    /* 文字对齐 */
    .textBox.alignLeft input {
        text-align: left;
        transition: 0.5s;
    }

    .textBox.alignCenter input {
        text-align: center;
        transition: 0.5s;
    }

    .textBox.alignRight input {
        text-align: right;
        transition: 0.5s;
    }

    /* 文字位置 */
    .textBox.poTop {
        top: 0;
        transition: 0.5s;
    }

    .textBox.poCenter {
        top: 248px;
        transition: 0.5s;
    }
    .textBox{
        transition: 0.5s;
    }

    .textBox.poBottom {
        top:433px;
        transition: 0.5s;
    }
    .configBox{
        display:flex;
        position:absolute;
    }

    .configBox.modelImg{
        top:0;
        left: 10px;
    }
    .configBox .btnItem{
        font-weight:500;
        font-size:14px;
        padding:12px 20px;
        display:inline-block;
        color:#fff;
        background-color:rgba(0,0,0,0.3)!important;
        cursor:pointer;
    }
    .slideBox{
        transition:2s;
    }
    .btnGroup{
        display:inline-block;
        margin-right:10px;
    }
    .btnGroupItem{
        display:inline-block;
        position:relative;
    }

    .detail_box{
        /*background:rgba(0,0,0,0.6);*/
        background:#252527;
        padding:5px 0;
    }
    .detail_box.up{
        position:absolute;
        left:0;
        bottom:40px;
    }
    .detail_box .item{
        color:#fff;
        cursor:pointer;
    }

    /*配色详情*/
    /*.color_match,.position_mode{*/
    /*overflow:hidden;*/
    /*padding:5px 0;*/
    /*background:rgba(0,0,0,0.4);*/
    /*position:absolute;*/
    /*!*top:-130px;*!*/
    /*display:none;*/
    /*border-radius:2px;*/

    /*}*/
    /*.more_file{*/
    /*overflow:hidden;*/
    /*padding:5px 0;*/
    /*background:rgba(0,0,0,0.4);*/
    /*position:absolute;*/
    /*!*top:-130px;*!*/
    /*display:none;*/
    /*border-radius:2px;*/
    /*}*/
    /*.color_match.active,.more_file.active{*/
    /*!*display:block;*!*/
    /*}*/
    .color_match>.item,.more_file>.item,.position_mode>.item{
        width:100%;
        height:36px;
        padding:12px 20px;
        display:flex;
        align-items: center;
        font-size:14px;
        box-sizing:border-box;
    }
    .color_match>.item.active,.more_file>.item.active,.position_mode>.item.active{
        background:#1b90ed;
    }
    .item.active .check_icon{
        visibility: visible;
    }

    .item .check_icon{
        visibility: hidden;
    }

    .color_match>.item>.check_icon,.position_mode>.item>.check_icon{
        width:18px;
        height:20px;
        margin-right:10px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .color_match>.item>.show_icon,.position_mode>.item>.show_icon{
        width:44px;
        height:24px;
        margin-right:10px;
        display:inline-block;
    }
    .none{
        display:none!important;
    }
    /*对齐方式*/
    .align_mode{
        width:82px;
        /*height:86px;*/
        padding:5px 5px;
        box-sizing:border-box;
        background:rgba(0,0,0,0.4);
        overflow:hidden;
        /*display:none;*/
        border-radius:2px;
    }
    .align_mode>.item{
        width:36px;
        height:36px;
        border-radius:5px;
        float:left;
        box-sizing:border-box;
        padding:5px;
    }
    .align_mode>.item.active{
        background:#fff;
        color:#333;
    }
    /*位置按钮*/
    .position_mode{
        /*display:none;*/
    }
    /*显示按钮详情*/
    .detail_box.active{
        /*display:block;*/
    }
    .detail_box{
        background:rgba(0,0,0,0.6);
    }
    div.upload_tab_box{
        width:100%;
        /*height:400px;*/
        /*background:#b39ddb;*/
        display:flex!important;
        flex-direction:column!important;
        padding:0!important;
        margin:0!important;

    }
    .itemText{
        white-space: nowrap;
    }
    .cloud_icon img{
        width:100%;
        height:100%;
    }
    .gray_tips img{
        width:100%;
        height:100%;
    }
    .highIndex{
        z-index:100;
    }
    .noWarp{
        white-space:nowrap;
    }
    .borderBox{
        border:2px dashed transparent;
        transition: 0.5s;
        cursor:pointer;
    }
    /*.borderBox:hover{*/
    /*border:4px dashed #1b90ed;*/
    /*transition: 0.5s*/
    /*}*/
    .borderBox:hover{
        border:2px dashed #1b90ed;
        transition: 0.5s
    }
    .borderBox.noHover:hover{
        border:2px dashed transparent;
        transition: 0.5s
    }
    .configBtn{
       
        transition:0.5s;
    }
    .configBtn:hover{
        background:#1c90ee!important;
        transition:0.5s;
    }
    .shade{
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.6);
        position:absolute;
        top:0;
        left:0;
        z-index:99;
        cursor:pointer;
        transition:2s;
    }
    .noneEl{
        visibility:hidden;
    }
    .editWelBox{
        width:80%;
    }
    .po_center i{
        font-size:24px;
    }
    .po_left i,.po_right i{
        font-size:20px;
    }
    .align_mode>.item{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .btnGroupItem>.btnItem{
        z-index:9999;
    }
    .tips{
        position:absolute;
        bottom:15px;
        right:0;
        height:50px;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right:15px;
        color:#fff;
    }
    .redText{
        color:red;
    }
    .tipsFirst{
        font-size:14px;
        text-align: center;
    }
    .tipsSecond{
        font-size:12px;
        text-align: center;
    }


</style>
