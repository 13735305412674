<!-- 配置项管理 -->
<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
           <span v-if='!query' slot="second">内容管理</span>
        <span v-if='!query' slot="third">配置项管理</span>
        <span v-if='query' slot="second">配置项管理</span>
      </top-bread>
    </div>
    <el-card class="box-card">
      <el-tabs v-model="activeTag" @tab-click="clickTab">
        <el-tab-pane label="包名配置" name="name">
          <PackageName ref="name"></PackageName>
        </el-tab-pane>
        <el-tab-pane label="包名类型配置" name="type">
          <PackageType ref="type"></PackageType>
        </el-tab-pane>
        <el-tab-pane label="参数配置" name="params">
          <Params ref="params"></Params>
        </el-tab-pane>
        <el-tab-pane label="模板配置" name="module">
          <Module ref="module"></Module>
        </el-tab-pane>
        <el-tab-pane label="属性配置" name="attr">
          <Attr ref="attr"></Attr>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import PackageName from "@/components/operation/SettingManage/packageName";
import PackageType from "@/components/operation/SettingManage/packageType";
import Params from "@/components/operation/SettingManage/params";
import Module from "@/components/operation/SettingManage/module";
import Attr from "@/components/operation/SettingManage/attr";
export default {
  components: { PackageName, PackageType, Params, Module, Attr },
  data() {
    return {
      bread: {
        level: 3,
      },
      activeTag: "name",
      query : null,
    };
  },
  methods: {
    clickTab() {
      this.$refs[this.activeTag].getList(1);
    },
  },
  mounted() {
    this.query = sessionStorage.getItem("apaasData");
  },
};
</script>
<style lang="scss" scoped></style>
