<template>
  <div v-loading="treeLoading" class="main">

    <el-drawer class="box" :before-close="handleClose" :destroy-on-close="true" :visible.sync="contentShow" size="123%" :title="title" v-if="!query.is_newPage">
      <div class="topInfo" v-loading="topInfoLoading">
        <div class="name" v-if="topInfo.hotel_alias=='默认'">{{ topInfo.hotel }}</div>
        <div class="name" v-if="topInfo.hotel_alias!='默认'">{{ topInfo.hotel +"（"+topInfo.hotel_alias+"）"  }}</div>
        <div class="bottom">
          <div class="state">
            <div> 合同号：{{ topInfo.hotel_contract }}</div>
            <div> 终端门店程序名：{{ topInfo.program_name }}</div>
          </div>
          <div class="state">
            <div> 上线登入密码：{{ topInfo.project_code }}</div>
            <div v-if="showFunModel('终端管理密码（详情）')"> 终端管理密码：{{ topInfo.upgrade_password }}</div>
          </div>
          <!--<div class="time">合同到期时间：{{showContractText(topInfo.contract_end )}}</div>-->
        </div>
      </div>
      <div class="changeType">
        <div v-for="(item, index) in typeList" v-show="item.show" :key="index" :class="{ active: type == item.msg ? true : false }" @click="changeType(item.msg)">
          {{ item.name }}
        </div>
      </div>
      <div v-if="type==='rate'">
        <coop-tab-rate :hotel_contract="getMsg.hotel_contract" hotelOrPartner="hotel"></coop-tab-rate>
      </div>
      <div v-show="type==='detail'" v-if="!this.itemData.isApaas">
        <detail-no :edit="true" v-if="
              auth.type !== 'htrip' && auth.type !== 'screen'
            " :id="getMsg.id" @topInfo="getTopInfo" :hotelName="getMsg.hotelName" :is_children="getMsg.is_children"></detail-no>
        <detail-juping :edit="true" v-if="auth.type === 'screen'" :is_children="getMsg.is_children" :hotelName="getMsg.hotelName" :id="getMsg.id" @topInfo="getTopInfo"></detail-juping>
        <detail-has ref="detailHas" v-if="auth.type === 'htrip' " :is_children="getMsg.is_children" :hotelName="getMsg.hotelName" :id="getMsg.id" :edit="true" @topInfo="getTopInfo"></detail-has>
      </div>
      <div v-show="type==='person'">
        <all-tab-person :id="getMsg.id" :hotel_contract="getMsg.hotel_contract"></all-tab-person>
      </div>
      <div v-show="type==='online'">
        <all-tab-online ref="onlineTab" :hotelInFo="topInfo" :id="getMsg.id" :showData="true" :query='query'></all-tab-online>
      </div>
      <div v-show="type==='onlineroom'">
        <all-tab-online-room :id="getMsg.id" :hotel_contract="getMsg.hotel_contract" :showData="true"></all-tab-online-room>
      </div>
      <div v-show="type==='materialEdit'">
        <op-tab-edit v-if="materialEdit" v-loading="editTabLoading" :configData="configData" :element-loading-text="loadingText" :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" :noData="noData" :userNameProp="userName" element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-spinner="el-icon-loading" @ediTabLoading="editTabLoadingMeth" @getHotelConfigInfo="getHotelConfigInfo(this.getMsg.hotel_contract)"></op-tab-edit>
        <!-- <op-tab-edit-change ref='editChange' :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType"></op-tab-edit-change> -->
      </div>
      <div v-show="type==='management'">
        <el-table ref="singleTable" :data="tableData" :header-cell-style="headerStyle" style="width: 100%">
          <el-table-column label="刊位名称" prop="self_periodical_name" min-width="200">
          </el-table-column>
          <el-table-column label="状态" prop="state" min-width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.label_state" active-color="#DCDCDC" class="tableScopeSwitch" inactive-color="red" @change="switchChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="关联携旅标准刊位名称" prop="standard_name" min-width="500">
            <template slot-scope="scope">
              <span v-if="scope.row.standard_name">{{ scope.row.standard_name }}</span>
              <span v-if="!scope.row.standard_name">{{ scope.row.standard_sign }}</span>
            </template>
          </el-table-column>
          <el-table-column label="媒体类型" prop="type">
          </el-table-column>
          <el-table-column label="尺寸" prop="resolution" min-width='100'>
          </el-table-column>
          <el-table-column label="场景" prop="scene">
          </el-table-column>
        </el-table>
      </div>
      <div v-show="type==='labelEdit'">
        <label-show :id="getMsg.id" :hotel_name="topInfo.hotel" :hotel_contract="getMsg.hotel_contract" :funArr="funArr"></label-show>
      </div>
      <div v-show="type==='authorization'">
        <all-tab-authorization-im :id="getMsg.id" :hotel_name="topInfo.hotel" :funArr="funArr" :hotel_contract="getMsg.hotel_contract"></all-tab-authorization-im>
      </div>
      <div v-show="type==='guestRoom'">
        <all-tab-guest-room :hotelInFo="topInfo" :id="getMsg.id" :database="topInfo.program_name" :hotel_contract="getMsg.hotel_contract"></all-tab-guest-room>
      </div>
    </el-drawer>
    <div v-show="false">{{ changeConfigData }}</div>
    <div v-if="query.is_newPage&&loadPage">

      <!-- <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane v-for="(item, index) in typeList" v-if="item.show" :key="index" :class="{ active: type == item.msg ? true : false }" :label="item.name " :name="item.msg"></el-tab-pane>

      </el-tabs> -->

      <div v-if="type==='rate'">
        <coop-tab-rate :hotel_contract="getMsg.hotel_contract" hotelOrPartner="hotel"></coop-tab-rate>
      </div>
      <div v-if="type==='detail'">
        <detail-no :edit="true" v-if="
              auth.type !== 'htrip' && auth.type !== 'screen'
            " :id="getMsg.id" @topInfo="getTopInfo" :hotelName="getMsg.hotelName" :is_children="getMsg.is_children"></detail-no>
        <detail-juping :edit="true" v-if="auth.type === 'screen'" :is_children="getMsg.is_children" :hotelName="getMsg.hotelName" :id="getMsg.id" @topInfo="getTopInfo"></detail-juping>
        <detail-has ref="detailHas" v-if="auth.type === 'htrip' " :is_children="getMsg.is_children" :hotelName="getMsg.hotelName" :id="getMsg.id" :edit="true" @topInfo="getTopInfo"></detail-has>
      </div>
      <div v-if="type==='person'">
        <all-tab-person :id="getMsg.id" :hotel_contract="getMsg.hotel_contract"></all-tab-person>
      </div>
      <div v-if="type==='online'">
        <all-tab-online :hotelInFo="topInfo" :id="getMsg.id" :showData="true"></all-tab-online>
      </div>
      <div v-if="type==='onlineroom'">
        <all-tab-online-room :id="getMsg.id" :hotel_contract="getMsg.hotel_contract" :showData="true"></all-tab-online-room>
      </div>
      <div v-if="type==='materialEdit'" style="display: flex;">
        <!-- <op-tab-edit v-if="materialEdit" v-loading="editTabLoading" :configData="configData" :element-loading-text="loadingText" :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType" :noData="noData" :userNameProp="userName" element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-spinner="el-icon-loading" @ediTabLoading="editTabLoadingMeth" @getHotelConfigInfo="getHotelConfigInfo(this.getMsg.hotel_contract)"></op-tab-edit> -->
        <!-- <op-tab-edit-change style="width:1280px" ref='editChange' :hotelContract="hotelContract" :hotelTemplateType="hotelTemplateType"></op-tab-edit-change> -->
        <visiual-setting></visiual-setting>
      </div>
      <div v-if="type==='management'">
        <el-table ref="singleTable" :data="tableData" :header-cell-style="headerStyle" style="width: 100%">
          <el-table-column label="刊位名称" prop="self_periodical_name" min-width="200">
          </el-table-column>
          <el-table-column label="状态" prop="state" min-width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.label_state" active-color="#DCDCDC" class="tableScopeSwitch" inactive-color="red" @change="switchChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="关联携旅标准刊位名称" prop="standard_name" min-width="500">
            <template slot-scope="scope">
              <span v-if="scope.row.standard_name">{{ scope.row.standard_name }}</span>
              <span v-if="!scope.row.standard_name">{{ scope.row.standard_sign }}</span>
            </template>
          </el-table-column>
          <el-table-column label="媒体类型" prop="type">
          </el-table-column>
          <el-table-column label="尺寸" prop="resolution" min-width='100'>
          </el-table-column>
          <el-table-column label="场景" prop="scene">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="type==='labelEdit'">
        <label-show :id="getMsg.id" :hotel_name="topInfo.hotel" :hotel_contract="getMsg.hotel_contract" :funArr="funArr"></label-show>
      </div>
      <div v-if="type==='authorization'">
        <all-tab-authorization-im :id="getMsg.id" :hotel_name="topInfo.hotel" :funArr="funArr" :hotel_contract="getMsg.hotel_contract"></all-tab-authorization-im>
      </div>
      <div v-if="type==='guestRoom'">
        <all-tab-guest-room :hotelInFo="topInfo" :id="getMsg.id" :database="topInfo.program_name" :hotel_contract="getMsg.hotel_contract"></all-tab-guest-room>
      </div>
      <div v-if="type==='apkDown'">
        <apk-down></apk-down>
      </div>
      <div v-if="false">{{ changeConfigData }}</div>
    </div>
  </div>
</template>
<script>
import qs from "querystring";
import {
  getJournalPositionByHotelContract,
  updateJournalPositionByHotelContract,
} from "@/api/advertisement.js";
import Bus from "@/api/Bus";

export default {
  name: "hotelManNew",
  props: ["query", "topInfoLoading"],
  data: () => ({
    show: false,
    check: "",
    type: "detail",
    title: "酒店详情",
    getMsg: {},
    contentShow: false,
    typeList: [
      {
        msg: "detail",
        name: "酒店详情",
        show: false,
      },
      {
        msg: "person",
        name: "实施人员",
        show: false,
      },
      // {
      //   msg: "rate",
      //   name: "计划及分成比例",
      //   show: false,
      // },

      {
        msg: "online",
        name: "上线情况",
        show: false,
      },
      {
        msg: "guestRoom",
        name: "房间管理",
        show: false,
      },
      {
        msg: "materialEdit",
        name: "素材编辑",
        show: false,
      },
      {
        msg: "onlineroom",
        name: "虚拟房间",
        show: false,
      },

      {
        msg: "management",
        name: "刊位管理",
        show: false,
      },
      {
        msg: "authorization",
        name: "授权管理",
        show: false,
      },
      {
        msg: "labelEdit",
        name: "标签管理",
        show: false,
      },
      {
        msg: "apkDown",
        name: "应用下载",
        show: false,
      },
    ],
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    tableData: [],
    activeName: "detail",
    topInfo: {
      hotel: "",
      hotel_use_state_text: "",
      contract_end: "",
      hotel_server_key: "",
    },
    dynamicValidateForm: {
      domains: [],
      email: "",
    },
    screentype: "",
    auth: {},
    childShow: {
      flag: false,
    },
    token: "",
    funArr: [],
    allData: {},
    hotelContract: "",
    configData: {},
    hotelTemplateType: "",
    editTabLoading: false,
    loadingText: "拼命发布中……",
    userName: "",
    noData: false,
    editTabShow: false,
    notSupportTemplate: [],
    isVisual: "",
    hotelServiceData: {},
    treeLoading: false,
    materialEdit: false,
    loadPage: true,
    itemData: {},
  }),
  watch: {
    query(newValue, oldValue) {
      this.getMsg = newValue;
      if (this.$route.path == "/onePage/onePageHotelManAll") {
        this.$refs.onlineTab.init(this.getMsg);
      }
      this.hotelContract = this.getMsg.hotel_contract;
    },
  },
  methods: {
    changeTab(tab, event) {
      console.log(tab);
      this.type = tab.name;

      if (this.type === "materialEdit") {
        this.loadPage = false;
        this.getHotelConfigInfo(this.getMsg.hotel_contract);
        // this.$refs.editChange.getHotelConfigInfo(this.hotelContract,this.token);
        this.title = "素材编辑";
      } else if (this.type === "rate") {
        this.title = "计划及分成比例";
      } else if (this.type === "detail") {
        this.title = "酒店详情";
      } else if (this.type === "person") {
        this.title = "实施人员";
      } else if (this.type === "online") {
        this.title = "上线情况";
      } else if (this.type === "management") {
        this.title = "刊位管理";
      } else if (this.type === "labelEdit") {
        this.title = "标签管理";
      } else if (this.type === "guestRoom") {
        this.title = "房间管理";
      }
    },
    changeType(msg) {
      this.type = msg;
      if (this.type === "materialEdit") {
        this.getHotelConfigInfo(this.getMsg.hotel_contract);
        // this.$refs.editChange.getHotelConfigInfo(this.hotelContract,this.token);
        this.title = "素材编辑";
      } else if (this.type === "rate") {
        this.title = "计划及分成比例";
      } else if (this.type === "detail") {
        this.title = "酒店详情";
      } else if (this.type === "person") {
        this.title = "实施人员";
      } else if (this.type === "online") {
        this.title = "上线情况";
      } else if (this.type === "management") {
        this.title = "刊位管理";
      } else if (this.type === "labelEdit") {
        this.title = "标签管理";
      } else if (this.type === "guestRoom") {
        this.title = "房间管理";
      }
    },
    // 开关
    handleClose(done) {
      this.contentShow = false;
      this.type = 0;
      if (this.$route.path != "/onePage/onePageHotelManAll") {
        //每次点详情重新判断实施人员展示逻辑
        this.typeList[1].show = false;
      }
    },
    switchChange(row) {
      const json = {
        id: row.id,
        standard_sign: row.standard_sign,
        resolution: row.resolution,
        change_to: row.label_state,
        hotel_contract: this.getMsg.hotel_contract,
      };
      var param = qs.stringify(json);
      updateJournalPositionByHotelContract(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.$message.success("操作成功");
          }
        })
        .catch((err) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      // this.dynamicValidateForm.domains.push({
      //     // value: '',
      //     key: Date.now()
      // });
    },
    handleClick(tab, event) {
      if (tab.paneName === "materialEdit") {
        this.getHotelConfigInfo(this.getMsg.hotel_contract);
      }
    },
    getHotelDetail(res, query) {
      if (this.$route.path != "/onePage/onePageHotelManAll") {
        if (typeof query.hotelState == "undefined") {
          this.typeList[1].show = false;
        } else if (
          query.hotelState === "all" ||
          query.hotelState === "newList"
        ) {
          if (query.hotelState == 10) {
            this.typeList[1].show = false;
          } else if (this.auth.type === "screen") {
            this.typeList[1].show = false;
          } else {
            this.typeList[1].show = true;
          }
        } else {
          this.typeList[1].show = false;
        }
      }
      if (res.data.code === 100000) {
        this.topInfo = res.data.data;
        this.hotelTemplateType = res.data.data.template_type;
        var obj = {
          hotelContract: this.topInfo.hotel_contract,
          token: this.token,
        };
        var params = qs.stringify(obj);
        this.$http.post(this.global.HotelMemberCheck, params).then((res) => {
          if (res.data.code === 100000) {
            this.check = res.data.data.checkStatue;
          } else {
          }
        });
      } else {
        this.$message.error(res.data.msg);
      }
    },
    getTopInfo(val) {},
    getGroupLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.groupLevel, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.curId = res.data.data.pid[0];
            }
            resolve(res.data.data.pid[0]);
          });
      });
    },
    getTreeData() {
      this.treeLoading = true;
      this.$http
        .get(this.global.getUserRuleLists, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var treeData = res.data.data;
            this.allData = treeData;
            this.allData.forEach((item, index) => {
              var menuId = sessionStorage.getItem("defaultActiveId");
              if (item.parent == menuId) {
                this.funArr.push(item.text);
              }
            });
            //账户权限，固定死，不做变动
            this.typeList.forEach((item) => {
              this.funArr.forEach((item1) => {
                if (item1.indexOf(item.name) !== -1) {
                  item.show = true;
                }
              });
            });
            this.$emit("contentlist", this.typeList);
          }

          this.treeLoading = false;
        });
    },
    backMeth() {
      this.$router.push({ path: `./${this.getMsg.fromUrl}` });
    },
    // 获取酒店刊位数据列表
    getHotalContentList(hotel_contract) {
      const json = {
        hotel_contract: hotel_contract,
      };
      var param = qs.stringify(json);
      getJournalPositionByHotelContract(param)
        .then((res) => {
          if (res.data.code == 100000) {
            if (res.data.data) {
              this.tableData = res.data.data;
            }
          }
        })
        .catch((err) => {});
    },
    editTabLoadingMeth(val, type) {
      this.editTabLoading = val;
      if (type === "upload") {
        this.loadingText = "上传中……";
      } else if (type === "loading") {
        this.loadingText = "加载中……";
      } else {
        this.loadingText = "拼命发布中……";
      }
    },
    getHotelConfigInfo(hotelContract) {
      this.noData = false;
      // this.editTabLoadingMeth(true, "loading");
      var param = qs.stringify({
        token: this.token,
        hotelContract: hotelContract,
      });
      this.$http
        .post(this.global.getHotelCurrentVisualInfo, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$nextTick(() => {
              this.$store.commit("getConfigData", res.data.data);
              this.configData = this.$store.state.configData;
              if (
                this.notSupportTemplate.indexOf(res.data.data.template_id) !==
                -1
              ) {
              }
              this.editTabShow = true;
            });
          } else if (res.data.msg === "暂不支持") {
            this.noData = true;
          } else {
            this.$message.error(res.data.msg);
          }
          this.materialEdit = true;
          this.editTabShow = true;
          this.loadPage = true;
          // this.editTabLoadingMeth(false, "loading");
        })
        .catch(() => {
          // debugger;
        });
    },
  },
  mounted() {
    if (this.$route.path == "/onePage/onePageHotelManAll") {
      this.typeList = [
        {
          msg: "online",
          name: "上线情况",
          show: false,
        },
      ];
    }
    this.itemData = JSON.parse(sessionStorage.getItem("hoteliitem"));
    if (!this.itemData) {
      this.itemData = {
        isApaas: false,
      };
    }
    this.token = sessionStorage.getItem("token");
    this.getMsg = this.query;
    this.getMsg.fromUrl === "toBeConfiguredHotel"
      ? (this.type = "rate")
      : "detail";
    this.hotelContract = this.getMsg.hotel_contract;
    this.params = this.$route.params;
    this.apaasData = sessionStorage.getItem("apaasData");
    if (!this.itemData.isApaas && !this.apaasData) {
      this.getGroupLevel().then((res) => {
        this.getTreeData(res);
      });
    } else {
      let str = sessionStorage.getItem("funArr");
      if (str.indexOf("[") != -1) {
        this.funArr = JSON.parse(sessionStorage.getItem("funArr"));
      } else {
        this.funArr = str.split(",");
      }
    }
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    // this.getHotelDetail(this.getMsg.id);
    // this.getHotalContentList(this.getMsg.hotel_contract);
    if (this.getMsg.id) {
      this.childShow.flag = true;
    }
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    showContractText() {
      return function (val) {
        if (!val || val === "") {
          return "暂无";
        } else {
          return val;
        }
      };
    },
    changeConfigData() {
      return this.$store.state.configData;
    },
  },
  created() {
    this.getMsg = this.query;
    Bus.$on("watchConfigData", (obj) => {
      this.configData = JSON.parse(JSON.stringify(obj));
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body::-webkit-scrollbar {
  //   width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}

.main {
  height: 100%;
}

.tabContent {
  margin-top: 20px;
}

.box-card {
  min-height: calc(100% - 35px);
}

.el-table {
  margin-top: 15px;
}

.pageBox {
  margin-top: 15px;
}

.topInfo {
  padding-bottom: 30px;
  margin-top: 20px;

  .name {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .bottom {
    margin-top: 30px;
    .state {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 14px;
      margin-bottom: 10px;
      .point {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #78affd;
        margin-right: 10px;
      }
    }
  }
}
.state div {
  width: 50%;
  text-align: left;
}
.tableScopeSwitch {
  margin-left: 0px;
}

.tableScopeSwitch ::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.tableScopeSwitch ::v-deep .el-switch__label--right {
  z-index: 1;
  right: 22px;
}

.tableScopeSwitch ::v-deep .el-switch__label.is-active {
  display: block;
}

.tableScopeSwitch.el-switch ::v-deep .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}

.changeType {
  position: absolute;
  left: -120px;
  top: 0px;
  width: 120px;

  border: 1px solid #e2e2e2;
  z-index: 999;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}

.changeType div {
  width: 120px;
  height: 55px;
  font-size: 14px;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
}

.active {
  background-color: #f1f9ff;
  color: #0078ff;
}

::v-deep .el-drawer__body {
  overflow: scroll;
  padding: 15px 20px 0;
}
</style>
