<template>
    <div>
        <div class="outTopBox">
            <top-bread :bread="breadOrdinary" v-if="fromType==='ordinary'">
                <span slot="second">广告管理</span>
                <span slot="third">自定义广告管理</span>
                <span slot="fourth" v-if="query.id==='' || !query.id" >新建</span>
                <span slot="fourth"  v-if="query.id!=='' ">详情</span>
            </top-bread>

            <top-bread :bread="breadSuper" v-if="fromType==='super'" >
                <span slot="second">广告管理</span>
                <span slot="third">广告位管理</span>
                <span slot="fourth" v-if="!query.id" >新建</span>
                <span slot="fourth"  v-if=" query.id">详情</span>
            </top-bread>
        </div>
        <el-card class="box-card">
            <ad-cus-tab-cus :id="query.id" :fromType="fromType" ></ad-cus-tab-cus>
        </el-card>

    </div>
</template>

<script>
    export default {
        name: "AdManCusNew",
        data: () => ({
            breadSuper:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/adManSpace'},
            },
            breadOrdinary:{
                level:4,
                firstPath:{path: '/indexShow'},
                thirdPath:{path: '/adManCus'},
            },
            tab:'custom',
            query:{
                id:'',
                fromType:'',
                type:''
            },
            fromType:''
        }),
        methods:{
            handleClick(tab, event) {

            },
        },
        mounted(){
            var query=this.$route.query;
            if(query){
                this.query.id=query.id;
                this.fromType=query.fromType;
                this.query.type=query.type;
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
