<template>
  <div>
    <el-drawer title="明细" :visible.sync="contentShowVisible" size="50%" :modal="false" :wrapperClosable="false">

      <div class="changeType">
        <div :class="{ active: type == index ? true : false }" v-for="(item, index) in typeList" :key="index" @click="changeType(index)">
          {{ item.name }}
        </div>
      </div>

      <div class="allcontentShow">
        <el-card class="box-card box-card-top searchCard" v-show="type == 0 ? true : false ">
          <div class="lineContentDialog">
            <el-row :gutter="24" class="sumRowBox" v-loading="ShowLoaing">
              <el-col class="cardShowSum" :span="11">
                <p class="cardShowfirstP">
                  <span class="cardShowSumTitle">
                    已提现金额
                  </span>
                  <span class="cardShowfirstPnum"><span class="cardShowSumnum1">{{
                         pageTopData2.sum_withdraw_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>

              </el-col>
              <el-col :span="2">
              </el-col>
              <el-col class="cardShowSum" :span="11">
                <p class="cardShowfirstP">
                  <span class="cardShowSumTitle">
                    未提现金额
                  </span>
                  <span class="cardShowfirstPnum"><span class="cardShowSumnum1">{{
                  
                         pageTopData2.sum_can_withdraw_amount
                    }}</span>
                    <span class="cardShowSumunit">元</span></span>
                </p>

              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-row class="dateSelect">
          <el-date-picker class="fullSelect" v-model="month" type="month" placeholder="选择月" @change="monthChange" value-format="yyyy-MM" :picker-options="pickerOptions">
          </el-date-picker>
        </el-row>

        <div class="tixian" v-show="type == 0 ? true : false">
          <div class="contentshowKk">
            <el-table ref="singleTable1" :data="tableData1" style="width: 100%" :header-cell-style="headerStyle" class="withdrawalStatisticTable">
              <el-table-column property="withdraw_type_name_text" label="收益类型">
              </el-table-column>
              <el-table-column label="申请提现金额（元）" min-width="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.withdraw_amount_text }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" min-width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.status_text }}</span>
                </template>
              </el-table-column>
              <el-table-column label="申请时间" min-width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.create_time_text }}</span>
                </template>
              </el-table-column>
              <el-table-column property="apply_admin_name_text" label="申请人" min-width="150" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="huikuan" v-show="type == 1 ? true : false">
          <div class="contentshowKk">
            <el-table ref="singleTable2" :data="tableData2" style="width: 100%" :header-cell-style="headerStyle" class="withdrawalStatisticTable">
              <el-table-column property="type_text" label="收益类型">
              </el-table-column>
              <el-table-column property="amount" label="扣款金额(元)">
                <template slot-scope="scope">
                  <span>{{ scope.row.amount / 100 }}</span>
                </template>
              </el-table-column>
              <el-table-column property="reason" label="扣款原因">
              </el-table-column>
              <el-table-column label="附件">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.attachment_name" type="text" size="mini" @click="donowdFile(scope.row)">{{ scope.row.attachment_name }}
                  </el-button>
                  <el-button v-else type="text" size="mini">暂无数据</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- /////////////// -->
    </el-drawer>
    <div class="contentDialogBlack" v-show="contentClickShow">
      <div class="contentDialog">
        <p class="dialogTitle">{{ contentTitle }}</p>
        <div class="close" @click="closeDialog2"><i class="el-icon-close"></i></div>
        <div class="picShow" v-if="contentType == 1">
          <el-table ref="singleTable" :data="contentTableList" style="width: 100%" :header-cell-style="headerStyle">
            <el-table-column label="时间" min-width="160px" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.order_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="order_room" label="房间号"></el-table-column>
            <el-table-column show-overflow-tooltip prop="order_paysn" min-width="180px" label="订单号"></el-table-column>
            <el-table-column prop="order_phone" label="手机号" min-width="120px">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="order_goods_name" label="电影名称" min-width="200">
            </el-table-column>
            <el-table-column prop="order_amount" label="单价">
            </el-table-column>
            <el-table-column prop="calculate" label="分成比例">
            </el-table-column>
            <el-table-column prop="income" label="收益"> </el-table-column>
          </el-table>
        </div>
        <div class="picShow picShow1" v-if="contentType == 2">
          <span>{{ returnMessageShow }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDeductedEventList,
  getPartnerWithdrawEventList,
} from "@/api/uokofinan.js";
import qs from "querystring";
export default {
  props: ["contentId", "month", "partnerId"],
  data() {
    return {
      contentShowVisible: false,
      typeList: [
        {
          name: "提现",
        },
        {
          name: "扣款",
        },
      ],
      sumHotelNumber: 0,
      sumMoneyNumber: 0,
      summanyNumber: 0,
      ShowLoaing: true,
      contentType: 1,
      contentClickShow: false,
      type: 0,
      cardtype: "timeCenter",
      contentTitle: "详细",
      contentTableList: [],
      tableData: [],
      tableData1: [],
      pageData: {},
      pageTopData: { datachange: {} },
      pageTopData2: {},
      tableData2: [],
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      StatementAccountMonth: "",
      activeName: "001001",
      oneStatus: false,
      oneText: "更多",
      twoStatus: false,
      twoText: "更多",
      threeStatus: false,
      threeText: "更多",
      pickerOptions: {
        disabledDate(date) {
          let zero = new Date().setHours(0, 0, 0, 0);
          if (date.getTime() > zero) {
            return true;
          }
          return false;
        },
      },
    };
  },
  methods: {
    // 文件下载
    donowdFile(row) {
      window.open(this.global.baseUrl + row.attachment);
    },
    // 更多
    showMore(val) {
      if (val == 1) {
        if (this.oneStatus) {
          this.oneStatus = false;
          this.oneText = "更多";
        } else {
          this.oneStatus = true;
          this.oneText = "收起";
        }
      } else if (val == 2) {
        if (this.twoStatus) {
          this.twoStatus = false;
          this.twoText = "更多";
        } else {
          this.twoStatus = true;
          this.twoText = "收起";
        }
      } else {
        if (this.threeStatus) {
          this.threeStatus = false;
          this.threeText = "更多";
        } else {
          this.threeStatus = true;
          this.threeText = "收起";
        }
      }
    },
   
    getData() {},
    // 类型
    changeType(index) {
      this.type = index;
      this.getTableData();
    },
    // 数据
    getTableData(id) {
      this.tableData = [];

      this.pageTopData2 = {};
      this.pageData = {};
      this.tableData1 = [];
      this.tableData2 = [];
    if (this.type == 0) {
        const json2 = {
          partner_id: id ? id : this.contentId,
          month: this.month,
        };
        this.ShowLoaing = false;
        var param2 = qs.stringify(json2);
        getPartnerWithdrawEventList(param2)
          .then((res) => {
            if (res.data.code == 100000) {
              var obj = res.data.data.total;
              for (const key in obj) {
                this.pageTopData2[key] = Number(obj[key]) / 100;
              }
              this.ShowLoaing = false;
              this.tableData1 = res.data.data.items;
              var timer = "";
              this.tableData1.forEach((item) => {
                timer =
                  item.partner_contract.contract_date_begin.substr(0, 10) +
                  "~" +
                  item.partner_contract.contract_date_end.substr(0, 10);
                item.timeshow = timer;
              });
            } else {
              this.ShowLoaing = false;
            }
          })
          .catch((err) => {
            this.ShowLoaing = false;
          });
      } else if (this.type == 1) {
        const json3 = {
          partner_id: id ? id : this.contentId,
          month: this.month,
        };

        var param3 = qs.stringify(json3);
        getDeductedEventList(param3)
          .then((res) => {
            if (res.data.code == 100000) {
              this.tableData2 = res.data.data.items;
            }
          })
          .catch((err) => {
          });
      }
    },
    // 月份
    monthChange(val) {
      this.month = val;
      this.getTableData();
    },
    // 对账月份动态输出
    timeForChinese(time) {
      var month = time.substring(5, 7);

      if (month == "01") {
        return (this.StatementAccountMonth = "一");
      } else if (month == "02") {
        return (this.StatementAccountMonth = "二");
      } else if (month == "03") {
        return (this.StatementAccountMonth = "三");
      } else if (month == "04") {
        return (this.StatementAccountMonth = "四");
      } else if (month == "05") {
        return (this.StatementAccountMonth = "五");
      } else if (month == "06") {
        return (this.StatementAccountMonth = "六");
      } else if (month == "07") {
        return (this.StatementAccountMonth = "七");
      } else if (month == "08") {
        return (this.StatementAccountMonth = "八");
      } else if (month == "09") {
        return (this.StatementAccountMonth = "九");
      } else if (month == "10") {
        return (this.StatementAccountMonth = "十");
      } else if (month == "11") {
        return (this.StatementAccountMonth = "十一");
      } else if (month == "12") {
        return (this.StatementAccountMonth = "十二");
      } else {
      }
    },

    handleClick() {
      this.activeName = val;
      this.getTableData();
    },
    // 关闭
    open() {
      this.contentShowVisible = true;
    },
    closeDialog() {
      this.contentShowVisible = false;
    },
    closeDialog2() {
      this.contentClickShow = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep :focus {
  outline: 0;
}
.duizhang,
.tixian,
.huikuan {
  width: 100%;
}
.duizhang {
  display: flex;
  flex-direction: column;
}
.el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
::v-deep .el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.duizhangTable {
  flex: 1;
}
.StatementAccountTitle {
  padding-top: 10px;
  background-color: #fff;
}
.StatementAccountTitle p {
  text-align: center;
}
.showNewBox {
  padding: 20px 0;
  margin-bottom: 10px;
}
.showNewBox:first-child {
  border-bottom: 1px solid #eeeeee;
}
.rowContent {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #70747a;
  font-size: 14px;
}
.firstinlineBlock {
  display: inline-block;
  width: 200px;

  text-align: right;
}
.hotalContentType {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.hotalContentType span {
  flex: 1;
}
.hotalContentType .firstinlineBlock {
  flex: 2;
}
.inlineBlock {
  display: inline-block;
  width: 45px;
  font-size: 12px;
}
.el-tabs__header {
  margin: 0 !important;
}

.rowContent p span:first-child {
  display: inline-block;
  width: 170px;
  font-size: 14px;
  text-align: right;
}
.el-table {
  margin-top: 20px;
}
.btn {
  margin-top: 15px;
  text-align: right;
}
.box-card-top {
  margin-top: 0;
  border-radius: none;
  box-shadow: 0;
}
.box-card {
  position: relative;
  margin-bottom: 8px;
}
.backHistroy {
  position: absolute;
  top: 20px;
  left: 20px;
}
.contentDialogBlack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.21);
}
.contentDialogBlack .contentDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 50%;
  padding: 0 15px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;

  height: 100%;
  width: 50%;
  padding: 0 25px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 9;
  font-size: 12px;
  box-sizing: border-box;
}
.allcontentShow {
  height: 100%;
}
.contentDialogBlack .close {
  right: 15px;
  top: 15px;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;

  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.dialogTitle {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  text-align: left;
  padding: 0 0px;
  border-bottom: 1px solid #ecf3fd;
}
.dialog_title {
  height: 30px;
  line-height: 30px;
  background: #ccc;
  text-align: center;
  margin-bottom: 15px;
}
.dateSelect {
  padding: 20px 20px 0 0;
  text-align: right;
  background-color: #fff;
}
.changeType {
  position: absolute;
  left: -70px;
  top: 0px;
  width: 70px;
  height: 110px;
  border: 1px solid #e2e2e2;
  z-index: 1;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px 12px 38px 0px rgba(57, 57, 57, 0.11);
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
}
.changeType div {
  width: 70px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
  font-size: 14px;
}
.active {
  background-color: #f1f9ff;
  color: #0078ff;
}
.lineContentDialog {
  padding-top: 20px;
  box-sizing: border-box;
}
.sumRowBox {
  display: flex;
  justify-content: space-between;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.cardShowSum {
  color: #70747a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px;
  padding: 0px 30px;
  box-shadow: 0 0 11px 2px rgb(94 151 255 / 15%);
}
.cardShowfirstP {
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}
.cardShowP {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.cardShowP .cardShowSumnum {
  margin-right: 10px;
  font-family: "DINAlternate";
}
.cardShowfirstPnum {
  display: flex;
  font-size: 16px;
  align-items: center;
}
.cardShowSumnum1 {
  color: #00183b;
  font-size: 16px;
  margin-right: 10px;
  font-family: "DINAlternate";
}
.cardShowSumnum {
  font-family: "DINAlternate";
}
.numberShow {
  font-family: "DINAlternate";
  font-size: 12px;
}
.numberShow1 {
  color: #333;
  font-size: 16px;
  font-family: "DINAlternate";
}
.contentshowTab {
  background-color: #fff;
  padding: 0 20px;
}
.contentshowTab1 {
  margin-top: 10px;
}
.contentshowKk {
  background-color: #fff;
  padding: 20px 20px;
}
.StatementAccountTitlename {
  color: #333333;
  font-size: 16px;
  margin-bottom: 17px;
}
.StatementAccountTitlenum {
  color: #adb6c1;
  font-size: 14px;
}
.el-tabs ::v-deep .el-tabs__nav {
  padding: 10px 0 0px !important;
}

.el-tabs ::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 40px;

  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;

  color: #303030;
  position: relative;
}
.el-tabs ::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  z-index: 1;
}

.el-tabs ::v-deep .el-tabs__item:last-child {
  color: #ccc;
}
.el-tabs ::v-deep .el-tabs__active-bar {
  width: 84px !important;
}
.more {
  color: skyblue;
  cursor: pointer;
  font-size: 12px;
}
.grayBox {
  height: 20px;
  width: 100%;
  background-color: #f9f9f9;
}
::v-deep .el-drawer {
  overflow: visible;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  overflow: auto;
  background-color: #f9f9f9;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}
.withdrawalStatisticTable {
  margin-top: 0;
}
</style>