<template>
  <div v-loading="pageLoading">
    <!-- 面包屑 -->
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">财务管理</span>
        <span slot="third">对账管理</span>
        <span slot="fourth">生成对账单</span>
      </top-bread>
    </div>
    <!-- 面包屑 -->
    <el-card class="box-card">
      <!-- 头部栏 -->
      <div class="backHistroy">
        <el-button class="largeBtn" type="primary" @click="backPage">返回</el-button>
        <el-button class="largeBtn" type="primary" @click="toReconciliation">发起对账</el-button>
      </div>
      <div class="StatementAccountTitle">
        <p class="monthTitle">{{ monthName }}月份对账单</p>
        <p class="AccountNumber">
          流水号：<span v-if="pageData.reconciliation_id">{{
            pageData.reconciliation_id
          }}</span>
        </p>
      </div>
      <!-- 头部栏 -->
      <!-- tab栏 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="影视单片点播" name="001001" v-if="ids.length!==0">
          <div class="showNewBox" v-if="pageData.partner_name">
            <el-row :gutter="24" class="rowContent">
              <el-col :span="10" class="rowContentLeft">
                <p><span>对账公司名称：</span>{{ pageData.partner_name }}</p>
                <p>
                  <span>合作伙伴终端编码：</span>{{ pageData.partner_code }}
                </p>
                <p>
                  <span>合同有效期：</span>
                  <span v-if="pageData.contract_date_begin">{{
                    pageData.contract_date_begin.substr(0, 10) +
                    " ~ " +
                    pageData.contract_date_end.substr(0, 10)
                  }}</span>
                </p>
                <p>
                  <span>对账周期：</span>{{
                    pageData.reconciliation_cycle_begin.substr(0, 10) +
                    " ~ " +
                    pageData.reconciliation_cycle_end.substr(0, 10)
                  }}
                </p>
              </el-col>
              <el-col :span="14" class="rowContentRight">
                <p class="rowContentShow">
                  <span>订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.total_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">订单金额（元）：</span><span class="numberShow">{{
                    pageData.total_order_income
                  }}</span>
                </p>
                <p class="rowContentShow">
                  <span>退款订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.refund_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">退款金额（元）：</span><span class="numberShow">{{ pageData.refund_order_income }}</span>
                 
                </p>
                <p class="rowContentShow">
                  <span>对账订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">对账金额（元）：</span><span class="numberShow">{{
                    pageData.amount
                  }}</span>
                 
                </p>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="影视天卡销售" name="001002" v-if="dayIds.length!==0">
          <div class="showNewBox" v-if="pageData.partner_name">
            <el-row :gutter="24" class="rowContent">
              <el-col :span="10" class="rowContentLeft">
                <p><span>对账公司名称：</span>{{ pageData.partner_name }}</p>
                <p>
                  <span>合作伙伴终端编码：</span>{{ pageData.partner_code }}
                </p>
                <p>
                  <span>合同有效期：</span>
                  <span v-if="pageData.contract_date_begin">{{
                    pageData.contract_date_begin.substr(0, 10) +
                    " ~ " +
                    pageData.contract_date_end.substr(0, 10)
                  }}</span>
                </p>
                <p>
                  <span>对账周期：</span>{{
                    pageData.reconciliation_cycle_begin.substr(0, 10) +
                    " ~ " +
                    pageData.reconciliation_cycle_end.substr(0, 10)
                  }}
                </p>
              </el-col>
               <el-col :span="14" class="rowContentRight">
                <p class="rowContentShow">
                  <span>订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.total_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">订单金额（元）：</span><span class="numberShow">{{
                    pageData.total_order_income
                  }}</span>
                </p>
                <p class="rowContentShow">
                  <span>退款订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.refund_order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">退款金额（元）：</span><span class="numberShow">{{ pageData.refund_order_income }}</span>
                 
                </p>
                <p class="rowContentShow">
                  <span>对账订单数量（个）：</span><span class="numberShow numberShow1">{{
                    pageData.order_num
                  }}</span>
                  <span class="inlineBlock firstinlineBlock">对账金额（元）：</span><span class="numberShow">{{
                    pageData.amount
                  }}</span>
                 
                </p>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- tab栏 -->
    </el-card>
    <!-- 列表 -->
    <el-card class="box-card">
      <el-table class="dataTable" ref="singleTable" :data="tableData" style="width: 100%" :header-cell-style="headerStyle">
        <el-table-column fixed type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="hotel_name" label="酒店名称" min-width="300">
        </el-table-column>
        <el-table-column label="操作" min-width="120">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="openContent(scope.row)">详细</el-button>
          </template>
        </el-table-column>
          <el-table-column prop="date" label="日期"> </el-table-column>
       <el-table-column  label="客房数" show-overflow-tooltip>
           <template slot-scope="scope">
              <span>{{scope.row.room_num?scope.row.room_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column  width="130" label="激活终端数量" show-overflow-tooltip>
         <template slot-scope="scope">
              <span>{{scope.row.active_terminal_num?scope.row.active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="valid_active_terminal_num" width="180" label="影视有效激活终端数量" show-overflow-tooltip>
          <template slot-scope="scope">
              <span>{{scope.row.valid_active_terminal_num?scope.row.valid_active_terminal_num:"0"}}</span>
        </template>
      </el-table-column>
        <el-table-column prop="order_num" label="订单数"> </el-table-column>
        <el-table-column prop="amount" label="收益金额（元）" min-width="120">
        </el-table-column>
        <el-table-column prop="refund_order_num" label="退款订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="refund_order_income" label="退款收益" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reconciliation_order_num" label="对账订单数量" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reconciliation_order_income" label="对账收益" show-overflow-tooltip></el-table-column>

        <el-table-column prop="day_active_rate" label="日活率">
        </el-table-column>
        <el-table-column prop="income_source_text" label="收益来源">
        </el-table-column>
        <el-table-column prop="partner_name" label="所属合作伙伴" min-width="300">
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 列表 -->
    <!-- 详情 -->
    <el-drawer title="明细" :visible.sync="contentShowVisible" size="50%" :modal="false" :wrapperClosable="false">
      <el-table ref="singleTable" :data="contentTableList" :header-cell-style="headerStyle" v-loading="Loading">
        <el-table-column label="时间" min-width="160px">
          <template slot-scope="scope">
            <span>{{ scope.row.order_date }}</span>
          </template>
        </el-table-column>
         <el-table-column prop="room_addtime" label="上线时间" min-width="200px"></el-table-column>
        <el-table-column prop="order_room" label="房间号" min-width="150px"></el-table-column>
        <el-table-column prop="order_paysn" min-width="180px" label="订单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="order_phone" label="手机号" min-width="120px">
        </el-table-column>
        <el-table-column prop="order_goods_name" label="电影名称" min-width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="order_amount" label="单价">
        </el-table-column>
        <el-table-column prop="calculate" label="分成比例">
        </el-table-column>
        <el-table-column prop="income" label="收益"> </el-table-column>
      </el-table>
    </el-drawer>
    <!-- 详情 -->
  </div>
</template>
<script>
import {
  InitiateReconciliation,
  getReconciliationPreview,
  getReconciliationDetailByContractAndDate,
  sponsorReconciliationFlow,
} from "@/api/financialManagement.js";

import qs from "querystring";
export default {
  data() {
    return {
      Loading: false,
      ids: "",
      bread: {
        level: 4,
        firstPath: { path: "/indexShow" },
      },
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      monthName: "",
      pageData: {},
      tableData: [],
      reconciliationId: "",
      contentTableList: [],
      contentShowVisible: false,
      activeName: "001001",
      dayIds: [],
      pageLoading: false,
    };
  },
  methods: {
    // 对账单发起
    toReconciliation() {
      this.$confirm("是否确认发起对账流程？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const json = {
          reconciliationId: this.reconciliationId,
        };
        var param = qs.stringify(json);
        sponsorReconciliationFlow(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.$message.success("对账发起成功");
              this.backPage();
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error("对账发起失败");
          });
      });
    },
    // 返回
    backPage() {
      this.$router.push("/FinManReconImHotal");
    },
    // 详情
    openContent(row) {
      this.Loading = true;
      const json = {
        date: row.date,
        hotelContract: row.hotel_contract,
        partnerId: row.partner_id,
        reconciliationType: this.activeName,
      };
      var param = qs.stringify(json);
      getReconciliationDetailByContractAndDate(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.contentTableList = res.data.data;
            this.Loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.contentShowVisible = true;
    },
    // 关闭
    closeDialog() {
      this.contentShowVisible = false;
      this.contentTableList = [];
    },
    handleClick() {
      this.getPageData(this.reconciliationId);
    },
    // 月份获取
    getMonth() {
      var date = new Date();
      const month = date.getMonth();
      this.monthName = month;
    },
    getPageData(reconciliationId) {
      const json = {
        reconciliationId: reconciliationId,
        reconciliationType: this.activeName,
      };
      var param = qs.stringify(json);
      getReconciliationPreview(param)
        .then((res) => {
          if (res.data.code == 100000) {
            console.log(res,"预览界面");
            this.pageData = res.data.data.reconciliationRow;
            this.tableData = res.data.data.items;
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取流水号
    getReconciliationID(ids) {
      this.pageLoading = true;
      const json = {
        ids: ids,
      };
      var param = qs.stringify(json);
      InitiateReconciliation(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.reconciliationId = res.data.data.reconciliationId;
            this.getPageData(this.reconciliationId);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    var query = this.$route.query;
    this.ids = query.ids;
    this.dayIds = query.dayids;
    var arr = [...this.ids, ...this.dayIds].join(",");
    this.getMonth();
    this.getReconciliationID(arr);

    if (this.ids.length !== 0) {
      this.activeName = "001001";
    } else {
      this.activeName = "001002";
    }
  },
};
</script>
<style scoped>
.tableBox {
  width: 100%;
  height: calc(100% - 140px);
  overflow: auto;
}
.tableBoxContent {
  background-color: #fff;
  padding: 20px;
}
.StatementAccountTitle p {
  text-align: center;
  margin-bottom: 17px;
}
.showNewBox {
  padding: 0 15px;
}

.inlineBlock {
  display: inline-block;
  width: 60px;
  font-size: 12px;
  text-align: right;
}
.el-tabs__header {
  margin: 0 !important;
}
.rowContent {
  padding: 25px;
  height: 200px;
  box-sizing: border-box;
}

.rowContentLeft,
.rowContentRight {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.rowContentLeft {
  font-size: 14px;
  color: #333;
}
.rowContentLeft p span:first-child {
  display: inline-block;
  width: 170px;
  text-align: right;
  font-size: 14px;
  color: #70747a;
}
.rowContentRight p span:first-child {
  display: inline-block;
  text-align: right;
  font-size: 14px;
  color: #70747a;
}
.rowContentRight .numberShow1 {
  font-size: 14px !important;
}
.rowContentRight .numberShow {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 10px;
  font-family: "DINAlternate";
  font-weight: 400;
  color: #333;
  font-size: 12px;
}
.rowContentRight p span {
  font-size: 14px;
  color: #70747a;
  display: inline-block;
}
.rowContentShow {
  display: flex;
  align-items: center;
}
.rowContentShow span {
  flex: 1;
}
.rowContentShow span:first-child {
  flex: 2;
  text-align: right;
}
.dataTable {
  margin-top: 20px;
}

.box-card {
  position: relative;
}
.backHistroy {
  display: flex;
  justify-content: space-between;
}
.contentDialog {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 100%;
  width: 50%;
  padding: 10px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.21);
  z-index: 999;
  font-size: 12px;
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background: #b6babf;
}
.dialogTitle {
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-align: left;
  padding: 0 10px;
  border-bottom: 1px solid #ecf3fd;
}
.largeBtn.el-button {
  width: 100px;
  height: 40px;
}
.monthTitle {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.grayBox {
  height: 20px;
  width: 112%;
  margin-left: -5%;
  background-color: #f9f9f9;
}
.AccountNumber {
  color: #adb6c1;
  font-size: 14px;
}
.el-tabs /deep/ .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  color: #303030;
  position: relative;
}
.el-tabs /deep/ .el-tabs__active-bar {
  width: 84px !important;
}
.el-tabs /deep/ .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #e4e7ed;
  z-index: 1;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow: visible;
  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  background-color: #f9f9f9;
  overflow: auto;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}
::v-deep :focus {
  outline: 0;
}
</style>