<template>
  <div>
    <el-button style="margin: 0 20px" @click="virtualRoomExport.dialog = true">电视导入</el-button>
       <el-dialog append-to-body title="导入电视" :visible.sync="virtualRoomExport.dialog" :close-on-click-modal="false" width="60%">
			<el-steps :active="virtualRoomData.active" align-center class="blueStep">
				<el-step title="上传Excel"></el-step>
				<el-step title="数据预览"></el-step>
				<el-step title="导入数据"></el-step>
			</el-steps>
			<div class="container">
				<div class="first stepItem" v-if="virtualRoomData.active===0" >
					<div class="uploadBox "  v-if="virtualRoomData.active===0 ">
						<!--	接口是:action="global.upload"，是一个完整的地址	  -->
						<el-upload
							class="upload-demo"
							drag
							accept=".xlsx"
							:action="global.upload"
							:headers="headers"
							:on-success="virtualRoomSuccess"
							:before-upload="virtualRoombeforeUpload">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
					</div>
					<el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning" v-if="virtualRoomData.active===0 &&virtualRoomData.oneProgress===1"></el-progress>
					<div class="notice" v-if="virtualRoomData.active===0 && virtualRoomData.oneProgress===0">
						<div class="title">*Excel文件请符合以下标准</div>
						<div class="item">请按照模板格式导入 <span class="downLoad"><a href="./static/tvtemplate.xlsx" download="">下载导入模板</a></span></div>
						<div class="item">后缀名需为xls或者xlsx</div>
						<div class="item">数据请勿放在合并的单元格中</div>
						<div class="item">单次导入条数最大不可超过100条</div>
					</div>
				</div>
				<div class="two stepItem" v-if="virtualRoomData.active===1 ">
					<div class="top paddingBox">预览数据：{{virtualRoomData.fileName}}</div>
					<div class="showCon" >
						<table-list :table="virtualRoomTable" v-loading="virtualRoomData.loading"></table-list>
					</div>
					<div class="bottom">
						<div class="show paddingBox">
							<span >信息补全：{{virtualRoomData.infoComplete}}</span>
							<span class="infoIncomplete">信息不完整：{{virtualRoomData.infoIncomplete}}</span>
						</div>
						<div>房间信息填写不完整会导致部分功能无法正常使用。可选择重新编辑文件后再次导入或导入后及时在系统中补全房间信息。</div>
					</div>
				</div>
				<div class="three stepItem" v-if="virtualRoomData.active===2" v-loading="virtualRoomData.loading">
					<!--<el-progress :text-inside="true" :stroke-width="20" :percentage="importData.percentage" status="exception"></el-progress>-->
					<!--<div class="dataShow">-->
					<!--<span>准备导入数据：{{importData.readyCount}}</span>-->
					<!--<span>成功：{{importData.successCount}}</span>-->
					<!--<span class="error">失败：{{importData.errorCount}}</span>-->
					<!--</div>-->
				</div>
				<div class="four stepItem" v-if="virtualRoomData.active===3" >
					<div class="iconBox">
						<i class="el-icon-success"></i>
					</div>
					<div class="notice">
						数据导入完成，已导入{{virtualRoomData.successCount}}条数据，失败{{virtualRoomData.errorCount}}条数据。
						<div style="margin-top: 15px" v-if="virtualRoomData.repeat_hotel">
							重复房间名称：{{virtualRoomData.repeat_hotel}}
						</div>
						<div  style="margin-top: 20px">
								<span class="downLoad">
								<a  v-if="virtualRoomData.repeat_hotel"  :href=virtualRoomData.file_path download="">下载重复房间</a>
						</span>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="virtualRoomcancelMeth" :class="virtualRoomData.active===3 ? 'none':''">取 消</el-button>
				<el-button type="primary" @click="virtualRoomNext" v-if="virtualRoomData.active>0 && virtualRoomData.active<3">下一步</el-button>
				<el-button type="primary" @click="virtualRoomNext" v-if="virtualRoomData.active===3">完成</el-button>
			</div>
		</el-dialog>

  </div>
</template>

<script>
import GLOBAL from '@/api/globalVar.js'
import { TVPreview ,importTv} from "@/api/TvExport";
export default {
    props: {
        hotelInFo: {
            type: Object,
            default: ()=> {
                return {
                    hotelName: '',
                }
            }
        }
    },
  data () {
    return {
      virtualRoomExport:{
        dialog: false,
      },
      virtualRoomData:{
        active: 0,
        oneProgress:0,
        percentage:40,
        fileName:'',
        infoComplete:'',
        infoIncomplete:'',
        readyCount:'',
        successCount:'',
        errorCount:'',
        file:'',
        filePath:'',
        loading:true,
        repeat_hotel:'',
        file_path:''
      },
      headers:{
        token:'',
        Authorization:''
      },
      cache_key: '',
      virtualRoomTable:{ //没有区
        tableHeader:[
            {prop:'name',label:'中午名称',width:''},
            {prop:'nameEn',label:'英文名称',width:''},
            {prop:'frequency',label:'频点(地址)',width:''},
            {prop:'freNew',label:'模拟频点(地址)',width:''},
          ],
        tableList:[
          
        ],
        page:1,
        limit:5,   //当前每页显示多少条
        total:0,
        fromUrl:'hotelManAssign',
        pageShow:false
      },
    }
  },
  methods: {
     // 虚拟房间 上传
    virtualRoombeforeUpload(val) {
      this.virtualRoomData.file = val;
      this.virtualRoomData.fileName = val.name;
    },
  // 虚拟房间上传 成功
    virtualRoomSuccess(response, file, fileList) {
      this.virtualRoomData.file = response.data.url;
      this.virtualRoomData.file = response.data.url;
      this.virtualRoomData.active = 1;
        this.virtualRoompreviewMeth();
    },
    // 虚拟房间流程1
    virtualRoomNext() {
      if (this.virtualRoomData.active++ > 2) {
          this.virtualRoomExport.dialog = false;
          this.virtualRoomData.active = 0;
          this.$emit('UpdateList')
      }
      if (this.virtualRoomData.active === 1) {
          this.virtualRoompreviewMeth();
      } else if (this.virtualRoomData.active === 2) {
          this.virtualRoomconfirmMeth();
      } else if (this.virtualRoomData.active === 3) {
          this.$parent.getList();
          this.virtualRoomExport.dialog = false;
          
      }
  },
    //虚拟房间返回第二步数据的接口
    virtualRoompreviewMeth() {
      let param = {
          file_path: this.virtualRoomData.file,
          database: this.hotelInFo.hotelName,
      };
      TVPreview(param).then(res => {
          if (res.data.code === 100000) {
              var tableData = res.data.data;
              this.virtualRoomTable.tableList = tableData.items;
              this.virtualRoomData.infoComplete = tableData.success_count;
              this.virtualRoomData.infoIncomplete = tableData.error_count;
              this.virtualRoomData.loading = false;
              this.cache_key = tableData.cache_key;
          } else {
              this.virtualRoomData.loading = false;
              this.$message.error('预览失败');
          }
      }).catch(() => {
          this.$message.error('返回错误');
      })
    },
     // 虚拟房间流程3
    virtualRoomconfirmMeth() {
      this.virtualRoomData.loading = true;
      let  param = {
        database: this.hotelInFo.hotelName,
        cache_key : this.cache_key,
      };
      importTv(param).then(res => {
          if (res.data.code === 100000) {
              var tableData = res.data.data;
              this.virtualRoomData.successCount = tableData.success_count;
              this.virtualRoomData.errorCount = tableData.error_count;
              this.virtualRoomData.loading = false;
              this.virtualRoomData.active = 3;
             
              if(this.virtualRoomData.errorCount != 0){
              this.virtualRoomData.file_path=this.GLOBAL.BASE_URL+tableData.file_path;
              var a = document.createElement('a');
                a.download = "导入失败酒店列表";
                a.href =this.virtualRoomData.file_path;
              document.body.appendChild(a);
              a.click();
                document.body.removeChild(a)
              }
              console.log("this.$parent.getList");
                 this.$emit('getlist');
          } else {
              this.$message.error('导入失败');
          }
      }).catch(() => {
          this.$message.error('返回错误');
      })
  },
    // 虚拟房间 清空
    virtualRoomcancelMeth() {
        this.virtualRoomData.active = 0;
        this.virtualRoomData.fileName = '';
        this.virtualRoomData.infoComplete = '';
        this.virtualRoomData.infoIncomplete = '';
        this.virtualRoomData.readyCount = '';
        this.virtualRoomData.successCount = '';
        this.virtualRoomData.errorCount = '';
        this.virtualRoomData.file = '';
        this.virtualRoomData.filePath = '';
        this.virtualRoomExport.dialog = false;
    },
    watch: {
        hotelInFo: {
            handler: function (newVal) {
            this.hotelInFo=newVal;
            },
            deep: true,
            // immediate: true,
        },
    },
  }
}
</script>

<style lang="scss" scoped>
   .paddingBox{
        padding:15px 0;
    }
    .container{
        /*height:400px;*/
        /*background:red;*/
        display:flex;
        /*justify-content: center;*/
        align-items:center;
        flex-direction:column;
    }
    .stepItem{
        width:100%;
    }
    .uploadBox{

        display:flex;
        justify-content: center;
        align-items: center;
        cursor:pointer;
        margin-top:30px;
    }
    .imgBox{
        width:50px;
        height:50px;
        margin-bottom:5px;
        img{
            width:100%;
            height:100%;
        }
    }
    .none{
        display:none;
    }
    .notice{
        width:100%;
        background:#eef2f7;
        padding:20px 20px;
        margin-top:30px;
        box-sizing:border-box;
        div{
            color:#909ca7;
        }
        .title{
            font-size:16px;
            padding-bottom:20px;
        }
        .item{
            margin-bottom:20px;
        }
        .item:last-child{
            margin-bottom:0;
        }
        .downLoad>a{
            color:#418efa;
            margin-left:20px;
            cursor:pointer;
        }
    }
    .two{
        .showCon{
            width:100%;
            height:300px;
            border-radius:4px;
            border:1px dashed #ddd;
            overflow:auto;
        }
        .bottom{
            .show{
                display:flex;
            }
            .infoIncomplete{
                margin-left:20px;
            }
            div{
                margin-bottom:15px;
            }
        }
    }
    .four{
        display:flex;
        justify-content: center;
        align-content: center;
        flex-direction:column;
        .notice{
            width:100%;
            text-align: center;
            background:none;
        }
        .iconBox{
            text-align:center;
            font-size:80px;
            color:#20b759;
            margin-top:30px;
        }
    }
    .paddingBox{
        padding:15px 0;
    }
    .foldBtn{
        cursor:pointer;
    }
    .outTopBox{
        /*padding:0;*/
    }
    .btnRow{
        display:flex;
        margin-top:20px;
    }
    .rightBtn{
        margin-left:auto;
    }
    .quickDateBox{
        display:flex;
        align-items: center;
    }
</style>