<template>
  <div>
    <el-drawer title="发布信息" :visible.sync="addShowVisible" size="50%" :modal="false" :wrapperClosable="false" :before-close="handleClose">
      <el-form :rules="rules" ref="addForm" :model="addForm" label-width="80px">
        <el-form-item label="测试" prop="reason">
          <el-input type="textarea" resize="none" :rows="15" v-model="addForm.reason"></el-input>
        </el-form-item>
      </el-form>
      <div class="btnSumnitRow">
        <el-button class="btnSumnit" type="primary" @click="submitForm('addForm')">提交</el-button>
        <el-button class="btnSumnit" type="primary" @click="submitForm('addForm')">提交</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import qs from "querystring";
export default {
  name: "CIBNform",
  data() {
    return {
      addShowVisible: false,
      addForm: {
        reason: "",
      },
      rules: {
        reason: [
          { required: true, message: "请填写扣款原因", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.headers.token = sessionStorage.getItem("token");
  },
  methods: {
    // 扣款确认操作
    submitForm() {
      this.$confirm("是否确认当前操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch((err) => {});
    },
    open() {
      this.addShowVisible = true;
    },
    // 关闭弹框
    handleClose() {
      this.addShowVisible = false;

      this.fileList = [];
    },
  },
};
</script>
<style scoped>
::v-deep :focus {
  outline: 0;
}

::v-deep .el-drawer {
  overflow: visible !important;
}
::v-deep .el-drawer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;

  margin: 0;
  left: unset;
  bottom: unset;
}
::v-deep .el-drawer__container {
  position: absolute;
  top: 0;
  left: unset;
  bottom: unset;
  right: 0;
  height: 100%;
  width: 100% !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 0px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #ecf3fd;
}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
::v-deep .el-drawer.rtl {
  width: 95% !important;
}
</style>