import request from "@/api/request";
import qs from "querystring";

//   获取初始化数据
export function getInitData(param) {
    return request({
        url: `v2/batch_set/selectHotels`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  城市查询
export function searchCity(param) {
    return request({
        url: `v2/batch_set/getCity2`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  酒店查询
export function searchHotel(param) {
    return request({
        url: `v2/batch_set/do_search_hotels`,
        method: "post",
        data: qs.stringify(param),
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}
//  excel导入
export function insertExcel(param) {
    return request({
        url: `ajax/upload`,
        method: "post",
        data: param,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
//  获取酒店列表
export function getHotelSearch(param) {
    return request({
        url: `v2/Hotel/getHotelSearch`,
        method: "post",
        data: qs.stringify(param),
    });
}
//  获取酒店列表
export function getDeviceName(param) {
    return request({
        url: `v2/Hotelvisualization/getDeviceName`,
        method: "post",
        data: qs.stringify(param),
    });
}