import global_ from '@/api/config'
Vue.prototype.GLOBAL = global_;

import axios from 'axios' //全局配置，导入包 设置请求的根路径
// axios.defaults.baseURL='http://showLoading.travelink.com.cn:19999/ssp/api/';
axios.defaults.baseURL = global_.BASE_URL + '/api';


// axios.defaults.headers.post["Content-type"]="application/x-www-form-urlencoded";
// axios.defaults.headers['token'] = sessionStorage.getItem('token');

Vue.prototype.$http = axios; //包挂载到Vue的原型对象上
import Vue from 'vue' //向外暴露Vue 对象
import App from './App.vue'
import router from './router'

import store from './store'
import './plugins/element.js'
import ElementUI from 'element-ui' //element-ui的全部组件
// import 'element-ui/lib/theme-chalk/index.css'  //element-ui 的css
// var link=document.createElement('link');
// link.href='element-ui/lib/theme-chalk/index.css';
// link.rel='stylesheet';
// link.type='text/css';
// head.appendChild(link);
import 'element-ui/lib/theme-chalk/index.css'
import 'video.js/dist/video-js.css'
// import 'static/element-ui/li/'
import qs from 'querystring'

import animate from 'animate.css'
Vue.use(animate);

Vue.use(ElementUI); //使用elementU

Vue.config.productionTip = false;

import '../src/assets/font/iconfont.css'
import '../src/assets/fontCus/font.css'
//公共组件
import Common from './components/common'
Vue.use(Common);

// import Meta from 'vue-meta'
// Vue.use(Meta);

import echarts from 'echarts'
import china from 'echarts/map/json/china'
echarts.registerMap('china', china);
import globalVar from './api/globalVar'
Vue.prototype.global = globalVar;

// require('swiper/dist/css/swiper.css');
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper);

//百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    // ak: 'YOUR_APP_KEY'
    ak: 'l1SSg0EvB9jsxk741X4GYDBBokfjVmdV'
});



// import 'default-passive-events'
//
new Vue({
    router,
    store,
    qs,
    Common,
    echarts,

    render: h => h(App)
}).$mount('#app');



//解决路由报错
//