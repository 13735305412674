<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread">
        <span slot="second">合作伙伴</span>
        <span slot="third">合作伙伴列表</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" :topSearchSave="topSearchSave" :allLoadingState="allLoadingState"></top-search>
    </el-card>
    <el-card class="box-card">
      <div class="title chartTitle blueTitle">合作伙伴列表</div>
      <el-row class="btnRow">
        <div>
          <!--<el-button class="largeBtn" type="primary" @click="configConfirm" size="medium" v-if="showFunModel('批量配置')">批量配置</el-button>-->
          <el-button class="largeBtn" type="danger" @click="delConfirm('del','删除')" size="medium" v-if="showFunModel('删除')">删除</el-button>
          <el-button class="largeBtn" type="primary" @click="newBuild" size="medium" v-if="showFunModel('不计算收益酒店')">不计算收益酒店</el-button>
        </div>
        <export-btn class="rightBtn " :exportUrlProp="global.partnerExport" :conditionProp="condition" parentProp="coopList" v-if="showFunModel('导出')"></export-btn>
      </el-row>
      <table-list @showUploadDialog="showUploadDialog" :table="table" ref="table" @page="getNewPage" @limit="getNewLimit" @tableSelect="getTableSelect" @tableMeth="tableMeth" v-loading="table.loading"></table-list>
      <el-dialog title="批量配置" :visible.sync="dialog.dialogFormVisible" :close-on-click-modal="false">
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item prop="type">
            <el-select v-model="form.type" placeholder="请选择类型" class="hotelBrand" clearable @change="typeChange">
              <el-option v-for="item in selectData.configType" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-row :gutter="40" v-for="(domain, index) in form.domains" :key="domain.key">
            <el-col :span="6" v-if="domain.showFlag">
              <el-form-item :prop="'domains.' + index + '.num'" :label="'大于' " :rules="{
      required: true, message: '不能为空', trigger: 'blur'
    }">
                <el-input v-model="domain.num" type="number" placeholder="请输入数量"></el-input>

              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="domain.showFlag">
              <el-form-item :prop="'domains.' + index + '.base_on'" :rules="{
      required: true, message: '类型不能为空', trigger: 'blur'
    }">
                <el-select v-model="domain.base_on" placeholder="请选择类型" class="hotelBrand" clearable>
                  <el-option v-for="item in selectData.configTerm" :key="item.key" :label="item.value" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="按" :prop="'domains.' + index + '.price'" :rules="{
      required: true, message: '金额不能为空', trigger: 'blur'
    }">
                <el-input placeholder="请输入金额" v-model="domain.price" type="number">
                  <template slot="append">元</template>
                </el-input>
                <!--结算-->
              </el-form-item>

            </el-col>
            <el-col :span="4" style="display:flex;align-items: center" v-if="domain.showFlag">
              <el-form-item style="display:flex;align-items: center">
                <!--<el-button @click.prevent="addDomain">新增</el-button>-->
                <el-button icon="el-icon-plus" size="mini" circle @click.prevent="addDomain"></el-button>
                <el-button icon="el-icon-minus" size="mini" circle @click.prevent="removeDomain(domain)" v-if="index!==0"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelMeth('form')">取 消</el-button>
          <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
    <coopDetail @successreturn='successreturn' ref="coopDetail" :query="query"></coopDetail>
    <div class="allLoading" v-show="false">{{allLoadingStateCom}}</div>
  </div>
</template>

<script>
import qs from "querystring";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import commonMeth from "../../api/method";
import coopDetail from "./CoopDetail";
export default {
  name: "CoopList",
  components: {
    coopDetail,
  },
  data: () => ({
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
    },
    topAttr: {
      coopType: [
        { label: "渠道商申请", value: "1" },
        { label: "内容分发审批", value: "2" },
        { label: "提现申请", value: "3" },
        { label: "广告订单确认", value: "4" },
      ],
      status: [
        { label: "待审核", value: "1" },
        { label: "正常", value: "2" },
        { label: "待补全", value: "3" },
        { label: "待分配", value: "4" },
      ],
    },
    table: {
      tableHeader: [
        { prop: "name", label: "合作伙伴名称 ", width: "350" },
        { prop: "partner_type_text", label: "合作类型", width: "" },
        { prop: "status_text", label: "资质审核状态", width: "" },
        { prop: "apply_date", label: "注册时间", width: "" },
        { prop: "review_date", label: "审批时间", width: "" },
        { prop: "code", label: "合作伙伴终端编码", width: "" },
        { prop: "contact_name", label: "联系人", width: "" },
        { prop: "contact_phone", label: "手机号码", width: "" },
      ],
      tableList: [],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      name: "coopList",
      fromUrl: "coopList",
      option: true,
      select: true,
      loading: true,
    },
    topSearchShow: {},
    condition: {},
    token: "",
    delIds: "",
    partnerCode: "",
    dialog: {
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "200px",
    },
    selectData: {
      configType: [
        { id: 1, name: "刷机费用" },
        { id: 2, name: "市场费用" },
        { id: 3, name: "电影分成比例" },
        { id: 4, name: "广告分成比例" },
      ],
      configTerm: [
        { id: 1, name: "有效激活终端数量" },
        { id: 2, name: "激活终端数量" },
        { id: 3, name: "日活量" },
        { id: 4, name: "日活率" },
      ],
    },
    query: {},
    form: {
      domains: [
        {
          num: "",
          base_on: "",
          type: "F", //数字
          price: "",
          showFlag: true,
        },
      ],
      email: "",
      type: "",
    },
    rules: {
      type: [{ required: true, message: "类型不能为空" }],
    },
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    funArr: [],
    allData: {},
    topSearchSave: {},
    operateIds: "",
    allLoadingState: false,
  }),
  methods: {
    successreturn() {
      this.tableMeth();
    },
    showUploadDialog(val) {
      this.query = val;
      this.$refs.coopDetail.contentShow = true;
      this.$refs.coopDetail.getDetail(this.query.id);
    },
    configMeth() {},
    getTopSearchShow() {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "group" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          onlyCoopType: true,
          review_status: true,
          coopCompleStatus: true,
          keyword: true,
          coopName: true,
          coopPhone: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          onlyCoopType: true,
          review_status: true,
          coopCompleStatus: true,
          keyword: true,
          coopName: true,
          coopPhone: true,
        };
      }
      this.topSearchShow.coopNamePlaceholder = "请输入联系人关键词";
      this.topSearchShow.coopPhonePlaceholder = "请输入电话号码";
      this.topSearchShow.keywordPlaceholder = "请输入合作伙伴名称关键词";
    },
    getCondition(val, filterPage, filterLimit, type) {
      console.log(val)
      this.condition.onlyCoopType = Array.isArray(val.onlyCoopType)
        ? val.onlyCoopType.join(",")
        : ""; //ka
      this.condition.coopCompleStatus = Array.isArray(val.coopCompleStatus)
        ? val.coopCompleStatus.join(",")
        : ""; //ka
      this.condition.keyword = val.keyword; //ka
      this.condition.coopName=val.coopName;
      this.condition.coopPhone=val.coopPhone;
      this.getFilterTableCon(filterPage, filterLimit, type);
      this.tableMeth(type);
    },
    getNewPage(newPage) {
      this.table.page = newPage;
      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
      this.tableMeth();
    },
    getTableSelect(val) {
      var delIds = "";
      var partnerCode = "";
      val.forEach((item, index) => {
        delIds += item.id + ",";
        partnerCode += item.code + ",";
      });
      this.delIds = delIds.slice(0, -1);
      this.partnerCode = partnerCode.slice(0, -1);
    },
    tableMeth(type) {
      this.table.loading = true;
      var sortStr = "id";
      var orderStr = "DESC";
      this.$http
        .get(this.global.partnerSearch, {
          params: {
            token: this.token,
            page: this.table.page,
            per_page: this.table.limit,
            contact_name:this.condition.coopName,
            contact_phone:this.condition.coopPhone,
            type: this.condition.onlyCoopType,
            review_status: this.condition.coopCompleStatus,
            name: this.condition.keyword,
            sort: sortStr,
            order: orderStr,
          },
        })
        .then((res) => {
          if (type === "mounted") {
            this.table.page = sessionStorage.getItem("filterPage") * 1;
          }
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = parseInt(tableData.total);
          } else {
            this.$message.error(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.table.loading = false;
        });
    },
    delConfirm(type, str) {
      if (this.delIds !== "") {
        this.$confirm("确定" + str + "？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (type === "del") {
              this.delMeth("del", "删除");
            } else if (type === "export") {
              this.exportMeth();
            }
          })
          .catch(() => {});
      } else {
        this.$message({
          message: "请选择合作伙伴",
          type: "warning",
        });
      }
    },
    newBuild() {
      if (this.delIds !== "" && this.delIds) {
        this.$router.push({
          path: `./coopHotelConfig`,
          query: { partnerCode: this.partnerCode },
        });
      } else {
        this.$message({
          message: "请选择合作伙伴",
          type: "warning",
        });
      }
    },
    delMeth() {
      var param = qs.stringify({
        token: this.token,
        ids: this.delIds,
      });
      this.$http.post(this.global.partnerDestory, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message.success("删除成功");
          this.tableMeth();
        } else {
          this.$message.success("删除失败");
        }
      });
    },
    getPartnerType(partner_id, type) {
      //获取类型
    },
    submitMeth(formName) {
      var obj = {};
      var objStr = JSON.stringify(this.form.domains);
      if (this.form.type === "flash") {
        //刷机
        obj = {
          ids: this.delIds,
          flash: objStr,
        };
      } else if (this.form.type === "market") {
        obj = {
          ids: this.delIds,
          market: objStr,
        };
      } else if (this.form.type === "vodonline") {
        //电影分成比例
        obj = {
          ids: this.delIds,
          vodonline: objStr,
        };
      } else if (this.form.type === "vodoffline") {
        //电影分成比例
        obj = {
          ids: this.delIds,
          vodoffline: objStr,
        };
      } else if (this.form.type === "ad") {
        obj = {
          ids: this.delIds,
          ad: objStr,
        };
      }
      this.$http
        .post(this.global.partnerBatch, obj, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.$message.success("配置成功");
            this.tableMeth();
          } else {
            this.$message.success("配置失败");
          }
        });
      this.form.domains = [
        {
          num: "",
          base_on: "",
          price: "",
          type: "F",
          showFlag: true,
        },
      ];
      this.dialog.dialogFormVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确定提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.submitMeth();
            })
            .catch(() => {});
        } else {
          this.$message.warning("请完善信息");
          return false;
        }
      });
    },
    cancelMeth(formName) {
      this.dialog.dialogFormVisible = false;
      this.resetMeth("form");
      this.$refs[formName].resetFields();
    },
    resetMeth() {
      this.form.domains = [
        {
          num: "",
          base_on: "",
          price: "",
          type: "F",
          showFlag: true,
        },
      ];
    },
    addDomain() {
      if (this.form.type === "flash") {
        this.form.domains.push({
          value: "",
          key: Date.now(),
          type: "F",
          showFlag: false,
        });
      } else {
        this.form.domains.push({
          value: "",
          type: "F",
          key: Date.now(),
          showFlag: true,
        });
      }
      // this.form.domains.push({
      //     value: '',
      //     key: Date.now(),
      //     type:'F'
      // });
    },
    removeDomain(item) {
      var index = this.form.domains.indexOf(item);
      if (index !== -1) {
        this.form.domains.splice(index, 1);
      }
    },
    getConfigType() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getPartnerConfigType, param).then((res) => {
        if (res.data.code === 100000) {
          this.selectData.configType = res.data.data;
        }
      });
    },
    getPartnerConfigTerm() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getPartnerConfigTerm, param).then((res) => {
        if (res.data.code === 100000) {
          this.selectData.configTerm = res.data.data;
        }
      });
    },
    configConfirm() {
      if (this.delIds !== "") {
        this.resetMeth();
        this.form.type = "";
        this.dialog.dialogFormVisible = true;
      } else {
        this.$message.warning("请选择合作伙伴");
      }
    },
    typeChange(val) {
      if (val === "flash") {
        this.form.domains.forEach((item, index) => {
          item.showFlag = false;
        });
      } else {
        this.form.domains.forEach((item, index) => {
          item.showFlag = true;
        });
      }
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    getFilterTableCon(filterPage, filterLimit, type) {
      if (type === "mounted") {
        !filterPage || filterPage === ""
          ? (this.table.page = 1)
          : (this.table.page = filterPage);

        !filterLimit || filterLimit === ""
          ? (this.table.limit = 5)
          : (this.table.limit = filterLimit);
      } else {
        this.table.page = 1;
        this.table.limit = 5;
        sessionStorage.setItem("filterPage", "1");
        sessionStorage.setItem("filterLimit", "5");
      }
      this.$forceUpdate();
    },
    getFilterCon() {
      var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
      this.topSearchSave = filterCon;
      var filterPage = sessionStorage.getItem("filterPage");
      var filterLimit = sessionStorage.getItem("filterLimit");
      this.getCondition(filterCon, filterPage * 1, filterLimit * 1, "mounted");
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.getTopSearchShow();
    this.getFilterCon();
    this.getConfigType();
    this.getPartnerConfigTerm();
    commonMeth.getTreeData(this.table.option).then((res) => {
      this.funArr = res.funArr;
      this.table.option = res.flag;
    });
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    allLoadingStateCom() {
      this.allLoadingState = commonMeth.getAllLoading(this.table.loading);
    },
  },
};
</script>

<style scoped lang="scss">
.el-col .el-select {
  width: 100%;
}
.rightBtn {
  margin-left: auto;
  width: 100px;
  height: 40px;
}
.largeBtn {
  min-width: 100px;
  height: 40px;
}
</style>
