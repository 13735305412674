<template>
  <div style="height: 100%;;width:100%">
    <div :id="id" ref="chart1" tyle="width:100%;height:100%" class=" echarts chart chartBox"></div>
  </div>
</template>
<script>
import echarts from "echarts";
export default {
  name: "pieCard",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      yAxis: {},
      xAxisList: [],
      seriesList: [],
      echartsObj: {},
      userInfo: {},
      hotel_contract: null,
    };
  },
  methods: {
    // 获取图表数据
    getPieList(form) {
      if(this.id=='pie'){
          this.xAxisList = [
        "2021-10-20",
        "2021-10-21",
        "2021-10-22",
        "2021-10-23",
      
      ];

      this.seriesList = [
        {
          name: "电影",
          value: 241,
        },
        {
          name: "电视剧",
          value: 128,
        },
        {
          name: "综艺",
          value: 396,
        },
        {
          name: "少儿",
          value: 412,
        },
      
      ];

      }else{
          this.xAxisList = [
        "2021-10-20",
        "2021-10-21",
        "2021-10-22",
        "2021-10-23",
        "2021-10-24",
      ];

      this.seriesList = [
        {
          name: "信息",
          value: 241,
        },
        {
          name: "警告",
          value: 128,
        },
        {
          name: "危险",
          value: 396,
        },
      
      ];

      }
    
      this.chartRender();

      return;
      let json = {
        hotel_contract: this.hotel_contract,
      };
      getChannelData(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (
              res.data.data &&
              res.data.data.rows &&
              res.data.data.rows.length > 0
            ) {
              let arr = res.data.data.rows;
              let data = arr.map((i) => {
                let obj = {
                  name: i.channel_name,
                  value: i.num,
                };
                return obj;
              });
              this.xAxisList = arr.map((i) => {
                return i.date;
              });
              this.seriesList = data;
            } else {
              this.xAxisList = [];
              this.seriesList = [];
            }
            this.chartRender();
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((err) => {});
    },

    // 图表渲染
    chartRender() {
      this.echartsObj = echarts.init(this.$refs.chart1);
      let _that = this;
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: 30,
          icon: "circle",
          itemWidth: 8,
          itemHeight: 8,
          textStyle: { color: "#fff", fontSize: 14 },
        },
        series: [
          {
            name: this.id=='pie'?"播放内容类型占比":'酒店警报类型占比',
            type: "pie",
            radius: ["45%", "65%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: (param) => {
                return param.data.value;
              },
              color: "#00ffff",
              fontSize: "14px",
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              normal: {
                //颜色渐变
                color: function (params) {
                  var colorList = [
                    {
                      c1: " #04c5f9",
                      c2: "#195589",
                    },
                    {
                      c1: " #ffaba2",
                      c2: "#886966",
                    },

                    {
                      c1: " #ffae08",
                      c2: "#5a3c02",
                    },
                    {
                      c1: " #4b49ba",
                      c2: "#7090f7",
                    },

                    {
                      c1: " #07a282",
                      c2: "#2cedac",
                    },
                    {
                      c1: " #ffcf5f",
                      c2: "#8e7525",
                    },
                    {
                      c1: " #fe8093",
                      c2: "#cf001e",
                    },
                    {
                      c1: " #9ffc81",
                      c2: "#409015",
                    },
                    {
                      c1: " #ff9f5f",
                      c2: "#c63d00",
                    },
                    {
                      c1: " #dea8ff",
                      c2: "#9213b6",
                    },
                  ];
                  let num = params.dataIndex;
                  if (num >= 10) {
                    num = params.dataIndex - 10;
                  }
                  // 这里注意：this.$echarts是不是可以取到，注意 ‘this’ 的指向问题
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: colorList[num].c1 },
                    { offset: 1, color: colorList[num].c2 },
                  ]);
                },
              },
            },
            data: this.seriesList,
          },
        ],
      };
      this.echartsObj.setOption(option);
      this.watchSize();
    },

    // 监听界面
    watchSize() {
      this.echartsObj.resize();
    },
  },
  mounted() {
    this.getPieList();
    window.addEventListener("resize", this.watchSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.watchSize);
  },
};
</script>

<style scoped lang="scss">
#chart,
.chart,
.chart2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
