<template>
    <div>
        <div class="noDataBox flexBox" v-if="auth.type==='htrip' && readonly && type!=='examine'">
            <div class="noData">
                <img src="../../../assets/img/noData.png" alt="">
                <div class="text flexBox">资质审核暂未通过</div>
            </div>
        </div>
        <el-form :model="form" :rules="readonly?{}:rules" ref="form" label-width="160px" class="demo-form" v-else>
            <line-title>资质提交</line-title>
            <el-form-item label="驳回原因" v-if="onlyShow===true && backReason!==''">
                <el-input v-model="backReason" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="company_name">
                <el-input v-model="form.company_name" :readonly="isEditCompanyName"></el-input>
            </el-form-item>
            <el-form-item label="社会信用代码" prop="uscc">
                <el-input v-model="form.uscc" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="公司联系地址" prop="company_address">
                <el-input v-model="form.company_address" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="合同有效期" prop="dateRange">
                <div class="block">
                    <el-date-picker :readonly="readonly"
                            v-model="form.dateRange"
                            type="daterange"
                            range-separator="至"
                            value-format="yyyy-MM-dd"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="dateChange"
                    >
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item label="法人姓名" prop="corporation">
                <el-input v-model="form.corporation" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="法人身份证号" prop="corporation_id">
                <el-input v-model="form.corporation_id" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="法人联系电话" prop="corporation_phone">
                <el-input v-model="form.corporation_phone" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="法人身份证正面" prop="corporation_id_front" class="idCard">
                <el-upload :disabled="readonly"
                        :action="global.upload"
                        list-type="picture-card"
                        :on-preview="frontPreview"
                        :on-remove="frontRemove"
                        :on-success="frontSuccess"
                        :on-before="frontBefore"
                        :on-change="frontChange"
                        :before-upload="uploadBefore"
                         accept=".jpg,.jpeg,.png,JPG,.JPEG"
                        :file-list="corporation_id_front.defaultList"
                        :headers="headers"
                        :limit="1"
                        :class="{disabled:corporation_id_front.uploadDisabled}"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="corporation_id_front.dialogVisible" :modal="false">
                    <img width="100%" :src="corporation_id_front.img_url" alt="">
                </el-dialog>
            </el-form-item>
            <el-form-item label="法人身份证反面" prop="corporation_id_back" class="idCard">
                <el-upload  :disabled="readonly"
                            :action="global.upload"
                        list-type="picture-card"
                        :on-preview="backPreview"
                        :on-remove="backRemove"
                        :on-success="backSuccess"
                        :on-before="backBefore"
                        :on-change="backChange"
                        :before-upload="uploadBefore"
                         accept=".jpg,.jpeg,.png,JPG,.JPEG"
                        :file-list="corporation_id_back.defaultList"
                        :headers="headers"
                        :limit="1"
                        :class="{disabled:corporation_id_back.uploadDisabled}"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="corporation_id_back.dialogVisible" :modal="false">
                    <img width="100%" :src="corporation_id_back.img_url" alt="">
                </el-dialog>
            </el-form-item>
            <el-form-item label="营业执照正本" prop="license_img" >
                <el-upload  :disabled="readonly"
                        :action="global.upload"
                        list-type="picture-card"
                        :on-preview="licensePreview"
                        :on-remove="licenseRemove"
                        :on-success="licenseSuccess"
                        :on-before="licenseBefore"
                        :on-change="licenseChange"
                        :before-upload="uploadBefore"
                         accept=".jpg,.jpeg,.png,JPG,.JPEG"
                        :file-list="license_img.defaultList"
                        :headers="headers"
                        :limit="1"
                        :class="{disabled:license_img.uploadDisabled}">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="license_img.dialogVisible" :modal="false">
                    <img width="100%" :src="license_img.img_url" alt="">
                </el-dialog>
            </el-form-item>
            <line-title>财务信息提交</line-title>
            <el-form-item label="收款方" prop="company_name">
                <el-input v-model="form.company_name" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="开户行" prop="bank_name">
                <el-input v-model="form.bank_name" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item label="银行账号" prop="bank_account">
                <el-input v-model="form.bank_account" :readonly="readonly"></el-input>
            </el-form-item>
            <el-form-item v-if="auth.type!=='htrip' && allData.isShowSave">
                <el-button type="primary" class="largeBtn" @click="submitForm('form')">提交</el-button>
                <el-button @click="resetForm('form')" class="largeBtn">重置</el-button>
            </el-form-item>
            <el-form-item v-if="auth.type==='htrip' && !readonly">
                <el-button type="primary" class="largeBtn" @click="submitForm('form')">提交</el-button>
                <el-button @click="resetForm('form')" class="largeBtn">重置</el-button>
            </el-form-item>
            <el-form-item v-if="showEditInfoTips && !allData.isShowSave">
                <span class="tips"><i class="el-icon-warning-outline redFont"></i>如需更改信息，请拨打40000-77988联系管理员进行修改。</span>
            </el-form-item>
        </el-form>
        <div class="modal" v-if="license_img.dialogVisible||corporation_id_back.dialogVisible||corporation_id_front.dialogVisible"></div>
    </div>
</template>

<script>
    import  qs from 'querystring'
    export default {
        name: "finance",
        props:['id','readonly','bankData','contractData','infoData','onlyShow','saveBtnShow','test','allData','isEditCoopInfo','showEditInfoTips','type'],
        data(){
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请填写手机号码'))
                } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(value)) {
                    return callback(new Error('请填写正确的手机号码'))
                }else{
                    callback()
                }
            };
            return {
                licenseImgUrl:'',
                corporation_id_front:{
                    img_url:'',
                    dialogVisible:false,
                    uploadDisabled:false,
                    defaultList:[
                        // {name:'front',url:"http://test7-1104358706.cn-northwest-1.elb.amazonaws.com.cn:8000/ssp/public/uploads/20200529/460df5abfc94fdb77028fa163f5c40bd.jpg"}
                    ]
                },
                corporation_id_back:{
                    img_url:'',
                    dialogVisible:false,
                    uploadDisabled:false,
                    defaultList:[
                        // {name:'front',url:"http://test7-1104358706.cn-northwest-1.elb.amazonaws.com.cn:8000/ssp/public/uploads/20200529/460df5abfc94fdb77028fa163f5c40bd.jpg"}
                    ]
                },
                license_img:{
                    img_url:'',
                    dialogVisible:false,
                    uploadDisabled:false,
                    defaultList:[]
                },
                form: {
                    company_name: '',
                    test:'',
                    uscc:'',
                    company_address:'',
                    contract_date_end:'',
                    corporation:'',
                    corporation_id:'',
                    corporation_phone:'',
                    corporation_id_front:'',
                    corporation_id_back:'',
                    license_img:'',
                    // company_name:'',  //财务信息
                    bank_name:'',
                    branch_name:'',
                    bank_account:'',
                    dateRange:'',
                },
                rules: {
                    uscc: [
                        { required: true, message: '请输入社会信用代码', trigger: 'blur' },
                    ],
                    company_address: [
                        { required: true, message: '请输入公司联系地址', trigger: 'blur' },
                    ],
                    dateRange: [
                        { required: true, message: '请选择合同有效期', trigger: 'blur' },
                    ],
                    corporation: [
                        { required: true, message: '请输入法人姓名', trigger: 'blur' },
                    ],
                    corporation_id: [
                        { required: true, message: '请输入法人身份证号', trigger: 'blur' },
                    ],
                    corporation_phone: [
                        { required: true, validator:checkPhone, trigger: 'blur' }
                    ],
                    corporation_id_front: [
                        { required: true, message: '请上传法人身份证正面', trigger: 'blur' },
                    ],
                    corporation_id_back: [
                        { required: true, message: '请上传法人身份证反面', trigger: 'blur' },
                    ],
                    license_img: [
                        { required: true, message: '请上传营业执照正本', trigger: 'blur' },
                    ],
                    company_name: [
                        { required: true, message: '请输入收款方', trigger: 'blur' },
                    ],
                    bank_name: [
                        { required: true, message: '请输入开户行', trigger: 'blur' },
                    ],

                    bank_account: [
                        { required: true, message: '请输入银行账号', trigger: 'blur' },
                    ],

                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
                headers:{
                    token:'',
                    Authorization:''
                },
                backReason:'',
                token:'',
                auth:{},
                url:'',
                query:{}
            }
        },
        watch:{
            license_img(val){
                if(val.defaultList.length>0){
                    // this.license_img.uploadDisabled=true;
                }
            },
             allData:{
                  handler: function (newVal) {
                         this.allData = newVal;
                         this.getData(this.allData);       
                },
                deep: true,
                immediate: true, 
            },
            readonly:function(newVal,oldVal){
               this.readonly = newVal;
            },
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定保存', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.submitMeth();
                        }).catch(() => {

                        });
                    } else {
                        this.$message.warning('请完善信息');
                        return false;
                    }
                });
            },
            submitMeth(){
                var url='';
                var param='';
                if(this.isEditCoopInfo){   //携旅修改
                    this.url=this.global.partnerUpdate;
                    this.form.id=this.id;
                }else{                     //合作伙伴修改
                    this.url=this.global.updateAccountInfoByCurrentAdmin
                }
                this.form.contract_date_begin=this.form.dateRange[0];
                this.form.contract_date_end=this.form.dateRange[1];
                param=qs.stringify(this.form);
                this.$http.post(this.url,param,{
                    headers:{
                        token:this.token,
                    }
                }).then(res=>{
                    if(res.data.code===100000){
                        this.$message.success('保存成功');
                          this.$emit("successreturn")
                        this.$emit('getDetail');
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.corporation_id_front='';
                this.form.corporation_id_back='';
                this.form.license_img='';
                this.corporation_id_front.defaultList=[];
                this.corporation_id_back.defaultList=[];
                this.license_img.defaultList=[];
                this.corporation_id_front.uploadDisabled=false;
                this.corporation_id_back.uploadDisabled=false;
                this.license_img.uploadDisabled=false;
            },
            handleAvatarSuccess(res, file) {
                this.licenseImgUrl = URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
              
                const isLt2M = file.size / 1024  < 500;

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过500K!');
                }
                return isJPG && isLt2M;
            },
            licensePreview(file) {
                this.license_img.img_url = file.url;
                this.license_img.dialogVisible = true;
            },
            licenseSuccess(res,fileList,type,test1,test2){
                this.form.license_img=res.data.url;
                this.license_img.uploadDisabled=true;
            },
            licenseRemove(file, fileList) {
                setTimeout(()=>{
                    this.license_img.uploadDisabled=false;
                    this.form.license_img='';
                },600)
            },
            licenseBefore(){
                this.license_img.uploadDisabled=true;
            },
            licenseChange(){
                this.license_img.uploadDisabled=true;
            },
            //身份证正面
            frontPreview(file) {
                this.corporation_id_front.img_url = file.url;
                this.corporation_id_front.dialogVisible = true;
            },
            frontSuccess(res,file,fileList){
                this.form.corporation_id_front=res.data.url;
                this.corporation_id_front.uploadDisabled=true;
            },
            frontRemove(file, fileList) {
                setTimeout(()=>{
                    this.corporation_id_front.uploadDisabled=false;
                    this.form.corporation_id_front='';
                },600)
            },
            frontBefore(){
                this.corporation_id_front.uploadDisabled=true;
            },
            frontChange(){
                this.corporation_id_front.uploadDisabled=true;
            },
            uploadBefore(file){
                const sizeLimit = file.size / 1024 < 500; // 限制小于1M
                if (!sizeLimit) {
                    this.$message.error('图片大小不可超过500K')
                }
                return sizeLimit
            },
            //身份证反面
            backPreview(file) {
                this.corporation_id_back.img_url = file.url;
                this.corporation_id_back.dialogVisible = true;
            },
            backSuccess(res,file,fileList){
                this.form.corporation_id_back=res.data.url;
                this.corporation_id_back.uploadDisabled=true;
            },
            backRemove(file, fileList) {
                setTimeout(()=>{
                    this.corporation_id_back.uploadDisabled=false;
                    this.form.corporation_id_back='';
                },600)
            },
            backBefore(){
                this.corporation_id_back.uploadDisabled=true;
            },
            backChange(){
                this.corporation_id_back.uploadDisabled=true;
            },
            dateChange(val){
                this.form.contract_date_begin=val[0];
                this.form.contract_date_end=val[1];
            },
            isEmptyObj(obj){
                for (let i in Object.keys(obj)) {
                    return false // 进入循环即不为空
                }
                return true
            },
            getData(data){
                  var allData=data;
                  if(allData.info){
                         this.headers.token=this.token;
            this.headers.Authorization=this.token;
            this.form.company_name=allData.info.company_name;
            this.form.uscc=allData.bank.uscc;
            this.form.company_address=allData.bank.company_address;
            if(allData.contract.contract_date_begin && allData.contract.contract_date_end){
                this.form.contract_date_begin=allData.contract.contract_date_begin.substring(0,10);
                this.form.contract_date_end=allData.contract.contract_date_end.substring(0,10);
            }
            this.form.corporation=allData.bank.corporation;
            this.form.corporation_id=allData.bank.corporation_id;
            this.form.corporation_phone=allData.bank.corporation_phone;
            if(allData.contract.contract_date_begin && allData.contract.contract_date_end){
                this.$set(this.form,'dateRange',[this.form.contract_date_begin,this.form.contract_date_end])
            }
            if(allData.bank.corporation_id_front && allData.bank.corporation_id_front!==''){ 
                 this.corporation_id_front.defaultList=[];  //正面
                this.form.corporation_id_front=allData.bank.corporation_id_front;
                this.corporation_id_front.defaultList.push({name:'front',url:this.global.baseUrl+allData.bank.corporation_id_front});
                this.corporation_id_front.uploadDisabled=true;
            }
            if(allData.bank.corporation_id_back && allData.bank.corporation_id_back!==''){ 
                 this.corporation_id_back.defaultList=[];  //反面
                this.form.corporation_id_back=allData.bank.corporation_id_back;
                this.corporation_id_back.defaultList.push({name:'front',url:this.global.baseUrl+allData.bank.corporation_id_back});
                this.corporation_id_back.uploadDisabled=true;
            }
            if(allData.bank.license_img && allData.bank.license_img!==''){
                 this.license_img.defaultList=[];
                this.form.license_img=allData.bank.license_img;
                this.license_img.defaultList.push({name:'license',url:this.global.baseUrl+allData.bank.license_img});
                this.license_img.uploadDisabled=true;
            }
            this.form.bank_name=allData.bank.bank_name;
            this.form.bank_account=allData.bank.bank_account;
                  }
            },
        },
        mounted(){
            this.token=sessionStorage.getItem('token');
            this.auth=JSON.parse(sessionStorage.getItem('auth'));
            var query=this.$route.query;
            if(!this.isEmptyObj(query)){
                this.query=query;
            }
        },
        computed:{
            isEditCompanyName(){
                var flag=false;
                //是携旅，并且 readonly 为 true
                if(this.isEditCoopInfo && !this.readonly){  //携旅修改，
                    flag=false;
                }else{
                    flag=this.readonly;
                }
                return flag;
            }
        }
    }
</script>

<style scoped lang="scss">

    .idCard>.one{
        margin-right:40px;
    }
    .imgBox{
        height:150px;
        /*border-radius:4px;*/
        /*border:1px dashed #ddd;*/
        /*background:#f2f2f2;*/
        border:1px dashed #ddd;
    }
    .idCard .one{
        margin-right:20px;
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        /*background: #d3dce6;*/
        background: #f2f2f2;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .fullInput{
        width:100%;
    }
    .tips{
        color:#a3a7af;
    }
    .redFont{
        color:red;
        margin-right:5px;
        font-size:14px;
    }
    .flexBox{
        display:flex;
        justify-content: center!important;
        align-items: center!important;
    }
    .noDataBox{
        .noData{
            width:300px;
            position:relative;
            img{
                width:100%;
                height:auto;
            }
        }
        .text{
            width:100%;
            color:#a3a7af;
            text-align: center;
            position:absolute;
            bottom:10px;
      
        }
    }
    .modal{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.6);
        z-index: 1000;
    }
</style>
