<template>
  <div class="main" v-loading="treeLoading">
    <div class="outTopBox" v-if="query.fromUrl !== 'toBeConfiguredHotel'">
      <top-bread :bread="bread">
        <span slot="second">酒店管理</span>
        <span slot="third">{{ bread.thirdName }}</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <div class="outTopBox" v-if="query.fromUrl === 'toBeConfiguredHotel'">
      <top-bread :bread="bread">
        <span slot="second">合作伙伴管理</span>
        <span slot="third">待配置酒店列表</span>
        <span slot="fourth">详情</span>
      </top-bread>
    </div>
    <el-card class="box-card cardCenter">
      <back @backMeth="backMeth"></back>
      <div class="topInfo">
        <div class="name">{{ topInfo.hotel }}</div>
        <div class="bottom">
          <div class="state">
            合同号：{{topInfo.hotel_contract}}<div style="margin-left: 5vw"> 终端门店程序名：{{topInfo.hotel_server_key}}</div>
          </div>
          <!--<div class="time">合同到期时间：{{showContractText(topInfo.contract_end )}}</div>-->
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="计划及分成比例" name="rate" v-if="showFunModel('计划及分成比例（详情）')">
          <coop-tab-rate :hotel_contract="query.hotel_contract" hotelOrPartner="hotel"></coop-tab-rate>
        </el-tab-pane>
        <el-tab-pane label="酒店信息" name="detail" v-if="showFunModel('酒店详情（详情）')">
          <detail-no :id="query.id" v-if="
              auth.type !== 'htrip' && auth.type !== 'screen' && childShow.flag
            " @topInfo="getTopInfo"></detail-no>
          <detail-juping :id="query.id" v-if="auth.type === 'screen'" @topInfo="getTopInfo"></detail-juping>
          <detail-has :id="query.id" v-if="auth.type === 'htrip' && childShow.flag" @topInfo="getTopInfo"></detail-has>
        </el-tab-pane>
        <el-tab-pane label="实施人员" name="person" v-if="showFunModel('实施人员（详情）')&&check===true">
          <all-tab-person :id="query.id" v-if="childShow.flag"></all-tab-person>
        </el-tab-pane>
        <el-tab-pane label="上线情况" name="online" v-if="showFunModel('上线情况（详情）')">
          <all-tab-online :id="query.id" v-if="childShow.flag" :showData="true"></all-tab-online>
        </el-tab-pane>
        <el-tab-pane label="素材编辑" name="materialEdit" v-if="showFunModel('素材编辑（详情）')">
          <op-tab-edit :hotelContract="hotelContract" v-if="materialEdit" :configData="configData" :noData="noData" :hotelTemplateType="hotelTemplateType" v-loading="editTabLoading" :element-loading-text="loadingText" :userNameProp="userName" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" @ediTabLoading="editTabLoadingMeth" @getHotelConfigInfo="getHotelConfigInfo(this.hotelContract)"></op-tab-edit>
        </el-tab-pane>
        <!-- v-if="auth.type == 'screen'" -->
        <el-tab-pane label="刊位管理">
          <el-table ref="singleTable" :data="tableData" style="width: 100%" :header-cell-style="headerStyle">
            <el-table-column prop="name" label="刊位名称" width="200">
            </el-table-column>
            <el-table-column width="120" prop="state" label="状态">
              <template slot-scope="scope">
                <el-switch class="tableScopeSwitch" v-model="scope.row.state" active-color="#DCDCDC" inactive-color="red" @change="switchChange(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="standard_name" label="关联携旅标准刊位名称" width="500"> </el-table-column>
            <el-table-column prop="type" label="媒体类型">
            </el-table-column>
            <el-table-column prop="size" label="尺寸">
            </el-table-column>
            <el-table-column prop="scene" label="场景">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div v-show="false">{{ changeConfigData }}</div>
  </div>
</template>
<script>
import qs from "querystring";
import {
  getJournalPositionByHotelContract,
  updateJournalPositionByHotelContract,
} from "@/api/advertisement.js";
import Bus from "../../api/Bus";
export default {
  name: "hotelManNew",
  data: () => ({
    check: "",
    bread: {
      level: 4,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/hotelManAll" },
      thirdName: "",
    },
    headerStyle: {
      background: "#f8fbff",
      color: "#606266",
    },
    tableData: [],
    activeName: "detail",
    topInfo: {
      hotel: "",
      hotel_use_state_text: "",
      contract_end: "",
      hotel_server_key: "",
    },
    dynamicValidateForm: {
      domains: [],
      email: "",
    },
    query: {
      fromUrl: "",
      id: "",
    },
    auth: {},
    childShow: {
      flag: false,
    },
    token: "",
    funArr: [],
    allData: {},
    hotelContract: "",
    configData: {},
    hotelTemplateType: "",
    editTabLoading: false,
    loadingText: "拼命发布中……",
    userName: "",
    noData: false,
    editTabShow: false,
    notSupportTemplate: [],
    isVisual: "",
    hotelServiceData: {},
    treeLoading: false,
    materialEdit: false,
  }),
  methods: {
    // 开关

    switchChange(row) {
      if (row.state) {
      } else {
        const json = {
          journal_name: row.name,
          hotel_contract: this.query.hotel_contract,
        };
        var param = qs.stringify(json);
        updateJournalPositionByHotelContract(param)
          .then((res) => {
            if (res.data.code == 100000) {
              this.tableData = res.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      // this.dynamicValidateForm.domains.push({
      //     // value: '',
      //     key: Date.now()
      // });
    },
    handleClick(tab, event) {
      if (tab.paneName === "materialEdit") {
        this.getHotelConfigInfo(this.hotelContract);
      }
    },
    getHotelDetail() {
      this.$http
        .get(this.global.view, {
          params: {
            token: this.token,
            id: this.query.id,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            this.topInfo = res.data.data;
            this.hotelTemplateType = res.data.data.template_type;
            var param = qs.stringify({
              hotelContract: this.topInfo.hotel_contract,
              token: this.token,
            });
            this.$http.post(this.global.HotelMemberCheck, param).then((res) => {
              if (res.data.code === 100000) {
                this.check = res.data.data.checkStatue;
              } else {
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getTopInfo(val) {
      // this.topInfo = val;
      // console.log(val)
    },
    getGroupLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.global.groupLevel, {
            params: {
              token: this.token,
            },
          })
          .then((res) => {
            if (res.data.code === 100000) {
              this.curId = res.data.data.pid[0];
            }
            resolve(res.data.data.pid[0]);
          });
      });
    },
    getTreeData() {
      this.treeLoading = true;
      this.$http
        .get(this.global.getUserRuleLists, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var treeData = res.data.data;
            this.allData = treeData;
            this.allData.forEach((item, index) => {
              var menuId = sessionStorage.getItem("defaultActiveId");
              if (item.parent == menuId) {
                this.funArr.push(item.text);
              }
            });
          }
          this.treeLoading = false;
        });
    },
    backMeth() {
      this.$router.push({ path: `./${this.query.fromUrl}` });
    },
    getBreadData() {
      this.bread.thirdPath = { path: `/${this.query.fromUrl}` };
      switch (this.query.fromUrl) {
        case "hotelManNewList":
          this.bread.thirdName = "新建酒店";
          break;
        case "hotelManAllImplementation":
          this.bread.thirdName = "实施中酒店";
          break;
        case "hotelManAllUsing":
          this.bread.thirdName = "使用中酒店";
          break;
        case "hotelManAllWaitforChecking":
          this.bread.thirdName = "待验收酒店";
          break;
        case "hotelManAllOperate":
          this.bread.thirdName = "可运营酒店";
          break;
        case "hotelManAllOffline":
          this.bread.thirdName = "离线酒店";
          break;
        case "hotelManAll":
          this.bread.thirdName = "所有酒店";
          break;
        case "HotelManAll":
          this.bread.thirdName = "所有酒店";
          break;
        case "hotelManAssignTest":
          this.bread.thirdName = "未分配酒店";
          break;
      }
    },
    // 获取酒店刊位数据列表
    getHotalContentList() {
      const json = {
        hotel_contract: this.query.hotel_contract,
      };
      var param = qs.stringify(json);
      getJournalPositionByHotelContract(param)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editTabLoadingMeth(val, type) {
      this.editTabLoading = val;
      if (type === "upload") {
        this.loadingText = "上传中……";
      } else if (type === "loading") {
        this.loadingText = "加载中……";
      } else {
        this.loadingText = "拼命发布中……";
      }
    },
    getHotelConfigInfo(hotelContract) {
      this.editTabLoadingMeth(true, "loading");
      var param = qs.stringify({
        token: this.token,
        hotelContract: this.topInfo.hotel_contract,
      });
      this.$http
        .post(this.global.getHotelCurrentVisualInfo, param)
        .then((res) => {
          if (res.data.code === 100000) {
            this.$store.commit("getConfigData", res.data.data);
            this.configData = this.$store.state.configData;
            if (
              this.notSupportTemplate.indexOf(res.data.data.template_id) !== -1
            ) {
              // this.noData=true;
            }
            this.editTabShow = true;
          } else if (res.data.msg === "暂不支持") {
            this.noData = true;
          } else {
            this.$message.error(res.data.msg);
          }
          this.materialEdit = true;
          this.editTabShow = true;
          this.editTabLoadingMeth(false, "loading");
        })
        .catch(() => {
          // debugger;
        });
    },
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
    this.query = this.$route.query;
    this.query.fromUrl === "toBeConfiguredHotel"
      ? (this.activeName = "rate")
      : "detail";
    this.params = this.$route.params;
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.getBreadData();
    this.getHotelDetail();
    this.getHotalContentList();
    if (this.query.id) {
      this.childShow.flag = true;
    }
    this.getGroupLevel().then((res) => {
      this.getTreeData(res);
    });
  },
  computed: {
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    showContractText() {
      return function (val) {
        if (!val || val === "") {
          return "暂无";
        } else {
          return val;
        }
      };
    },
    changeConfigData() {
      return this.$store.state.configData;
    },
  },
  created() {
    Bus.$on("watchConfigData", (obj) => {
      this.configData = JSON.parse(JSON.stringify(obj));
    });
  },
};
</script>

<style scoped lang="scss">
.main {
  height: 100%;
}
.tabContent {
  margin-top: 20px;
}
.box-card {
  min-height: calc(100% - 35px);
}
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.topInfo {
  padding-bottom: 30px;
  margin-top: 20px;
  .name {
    font-size: 18px;
    letter-spacing: 1px;
  }
  .bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .state {
      display: flex;
      .point {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #78affd;
        margin-right: 10px;
      }
    }
  }
}
.tableScopeSwitch {
  margin-left: 0px;
}
.tableScopeSwitch ::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.tableScopeSwitch ::v-deep .el-switch__label--right {
  z-index: 1;
  right: 22px;
}

.tableScopeSwitch ::v-deep .el-switch__label.is-active {
  display: block;
}
.tableScopeSwitch.el-switch ::v-deep .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}
</style>
