<template>
    <div>
        <el-card class="box-card">
            <baidu-map class="map"
                       :center="centerLabel"
                       :scroll-wheel-zoom="true"
                       @zoomend="zoomMeth"
                       @ready="handler"
                       @click="clickMeth"
                       v-if="mapShow">
                <div class="btn" @click="allMeth">中国</div>
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                <bm-overlay :key="index" v-for="(item,index) in showList"
                        pane="labelPane"
                        :class="{sample: true, active}"
                        @draw="draw($event,item.lng,item.lat)"
                        @mouseover.native="active = true"
                        @mouseleave.native="active = false"
                        @click="markerClick(item.label,item.detailShow)">
                    <!--<div class="province" @click="markerClick(item.label)">{{item.label}}</div>-->
                    <div class="province" @click="markerClick(item.label,item.detailShow,item.lng,item.lat,item.detailText)">{{item.label}}</div>
                    <div @click="markerClick(item.label,item.detailShow,item.lng,item.lat,item.detailText)">{{item.num}}</div>
                    <bm-info-window
                            :position="infoWindow.position"
                            :show="infoWindow.show"
                            @click="showDetail">
                        <p v-text="infoWindow.detailText"></p>
                    </bm-info-window>
                </bm-overlay>
            </baidu-map>
        </el-card>
    </div>
</template>

<script>
    import echarts from 'echarts'
    export default {
        data () {
            return {
                // zoom:3,
                a:123,
                b:41,
                test:'测试111',
                centerLabel:'中国',
                infoWindow: {
                    show: false,
                    contents: '123.41911277834251,41.808487415519224',
                    position:{
                        lng:'',
                        lat:''
                    },
                    detailText:''
                },
                markers: [
                    {
                        lng: 123,
                        lat: 41,
                        name: 'A',
                        info:'',
                        showFlag: false
                    },
                    {
                        lng: 122,
                        lat: 42,
                        name: 'B',
                        info:'',
                        showFlag: false
                    }
                ],
                active: false,
                showList:[],
                provinceList:[
                    {lng:'125.526791',lat:'43.87088',num:'111',label:'北京市',detailShow:false,detailText:'北京测试1'},
                    {lng:'103.624464',lat:'29.552611',num:'22',label:'云南省',detailShow:false,detailText:'云南省测试1'},
                    {lng:'130.50001',lat:'32.648863',num:'33',label:'安徽省',detailShow:false,detailText:'安徽省测试1'},
                ],
                cityListAll:[
                    {lng:'115.961095',lat:'40.282347',num:'33',label:'北京1',detailShow:false},
                    {lng:'116.890734',lat:'39.810434',num:'44',label:'北京2',detailShow:false},
                    {lng:'116.687501',lat:'39.999506',num:'55',label:'北京3',detailShow:false},

                ],
                countyListAll:[
                    {lng:'103.80035',lat:'27.30503',num:'1',label:'第一个',detailText:'神木市滨河大酒店',detailShow:true},
                    {lng:'103.342717',lat:'27.411808',num:'1',label:'第二个',detailText:'谧堂青丘客栈',detailShow:true},
                    {lng:'103.729348',lat:'27.900465',num:'1',label:'第三个',detailText:'台州安颐酒店',detailShow:true},
                    {lng:'103.758991',lat:'25.743407',num:'33',label:'',detail:''},
                    {lng:'103.788312',lat:'25.593866',num:'33',label:'',detail:''},
                    {lng:'103.815333',lat:'25.284809',num:'33',label:'',detail:''},
                ],
                cityListBei:[
                    {lng:'115.961095',lat:'40.282347',num:'33',label:'北京1',detailShow:false},
                    {lng:'116.890734',lat:'39.810434',num:'44',label:'北京2',detailShow:false},
                    {lng:'116.687501',lat:'39.999506',num:'55',label:'北京3',detailShow:false}
                ],
                cityListAn:[   //118.41139,31.425873   117.851278,30.968488     118.396263,29.748963
                    {lng:'118.41139',lat:'31.425873',num:'33',label:'',detailShow:false},
                    {lng:'117.851278',lat:'30.968488',num:'44',label:'',detailShow:false}, //,
                    {lng:'118.396263',lat:'29.748963',num:'55',label:'',detailShow:false},  //,
                ],
                cityListYun:[   //103.725323,27.344827  邵桐
                    {lng:'100.503872',lat:'25.417625',num:'33',label:'昭通市',detailShow:false},
                    {lng:'103.79981',lat:'25.497372',num:'44',label:'曲靖市',detailShow:false}, //曲靖 103.79981,25.497372
                    {lng:'102.550825',lat:'24.357512',num:'55',label:'玉溪市',detailShow:false},  //,玉溪 102.550825,24.357512
                ],
                countyListYunShao:[  //昭通市,   103.342717,27.411808  103.729348,27.900465
                    {lng:'103.80035',lat:'27.30503',num:'1',label:'第一个',detailText:'神木市滨河大酒店',detailShow:true},
                    {lng:'103.342717',lat:'27.411808',num:'1',label:'第二个',detailText:'谧堂青丘客栈',detailShow:true},
                    {lng:'103.729348',lat:'27.900465',num:'1',label:'第三个',detailText:'台州安颐酒店',detailShow:true},
                ],
                countyListYunQu:[  //曲靖,  103.758991,25.743407    103.788312,25.593866  103.815333,25.284809
                    {lng:'103.758991',lat:'25.743407',num:'33',label:'',detail:''},
                    {lng:'103.788312',lat:'25.593866',num:'33',label:'',detail:''},
                    {lng:'103.815333',lat:'25.284809',num:'33',label:'',detail:''},
                ], //102.721863,24.314578
                countyListYunYu:[  //玉溪,  102.639362,24.315105    102.520067,24.283225  102.104116,24.254763
                    {lng:'102.639362',lat:'24.315105',num:'33',label:'',detail:''},
                    {lng:'102.520067',lat:'24.283225',num:'33',label:'',detail:''},
                    {lng:'102.104116',lat:'24.254763',num:'33',label:'',detail:''},
                ], //102.721863,24.314578
                mapShow:false
            }
        },
        methods: {
            draw ({el, BMap, map},lng,lat) {
                const pixel = map.pointToOverlayPixel(new BMap.Point( lng, lat));
                el.style.left = pixel.x - 60 + 'px';
                el.style.top = pixel.y - 20 + 'px'
            },
            initChart(){
                var chart = echarts.init(document.getElementById('chart'));
                var option = {
                    title: {
                        text: '漏斗图',
                        subtext: '纯属虚构'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c}%"
                    },
                    toolbox: {
                        feature: {
                            dataView: {readOnly: false},
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    legend: {
                        data: ['展现','点击','访问','咨询','订单']
                    },

                    series: [
                        {
                            name:'漏斗图',
                            type:'funnel',
                            left: '10%',
                            top: 60,
                            //x2: 80,
                            bottom: 60,
                            width: '80%',
                            // height: {totalHeight} - y - y2,
                            min: 0,
                            max: 100,
                            minSize: '0%',
                            maxSize: '100%',
                            sort: 'descending',
                            gap: 2,
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            labelLine: {
                                length: 10,
                                lineStyle: {
                                    width: 1,
                                    type: 'solid'
                                }
                            },
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 1
                            },
                            emphasis: {
                                label: {
                                    fontSize: 20
                                }
                            },
                            data: [
                                {value: 60, name: '访问'},
                                {value: 40, name: '咨询'},
                                {value: 20, name: '订单'},
                                {value: 80, name: '点击'},
                                {value: 100, name: '展现'}
                            ]
                        }
                    ]
                };
                chart.setOption(option,true);
            },
            markerClick(label,show,lng,lat,detailText){
                this.centerLabel=label;
                if(label==='云南省'){
                    this.showList=this.cityListYun;
                }else if(label==='北京市'){
                    this.showList=this.cityListBei;
                }else if(label==='安徽省'){
                    this.showList=this.cityListAn;
                }else if(label==='昭通市'){
                    this.showList=this.countyListYunShao;
                }
                if(show){
                    this.infoWindow.position={lng,lat};
                    this.infoWindow.detailText=detailText;
                    this.infoWindow.show=true;
                }
            },
            infoWindowClose (e) {
                this.infoWindow.show = false
            },
            infoWindowOpen (e) {
                this.infoWindow.show = true
            },
            clear () {
                this.infoWindow.contents = ''
            },
            showDetail(){
                this.infoWindow.show=true;
            },
            allMeth(){
                this.centerLabel='中国';
            },
            zoomMeth(e){
                var zoom=e.target.getZoom();
                if(zoom<=6){
                    this.showList=this.provinceList;
                }else if(zoom>7 && zoom<6){
                    this.showList=this.provinceList;
                }
            },
            handler ({BMap, map}) {
                // 自动获取展示的比例
                var view = map.getViewport(eval(this.showList));
                this.zoom = view.zoom;
                this.center = view.center;
            },
            clickMeth(e){
            }
        },
        watch:{
            centerLabel(newVal){
                if(newVal==='中国'){
                    this.showList=this.provinceList;
                }
            },
            zoom(newVal){

            },
            showList(newVal){
                this.mapShow=false;
                this.$nextTick(()=>{
                    this.mapShow=true;
                })
            },
        },
        mounted(){
            // this.initChart();
            this.showList=this.provinceList;
        }
    }


</script>

<style scoped>

    .map{
        width:100%;
        height:600px;
        position:relative;
    }
    .sample {
        width: 25px;
        height: 25px;
        /*line-height: 20px;*/
        /*background: rgba(0,0,0,0.5);*/
        overflow: hidden;
        color: #fff;
        text-align: center;
        padding: 10px;
        position: absolute;
        background-image:url(../../assets/img/coordinate.png);
        background-size:100% 100%;
        display:flex;
        flex-direction: column;
    }
    .sample.active {
        /*background: rgba(0,0,0,0.75);*/
        /*color: #fff;*/
    }
    .province{
        font-size:12px;
        white-space: nowrap;
        margin-bottom:3px;
    }

    .chart{
        width:500px;
        height:600px;
    }
    .btn{
        position:absolute;
        top:0;
        left:50px;
        cursor:pointer;
    }
</style>
